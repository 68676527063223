import { Component } from "react";
import { connect } from "react-redux";
import requiresProps from "../../utils/requiresProps";
import { requestEvent } from "../../actions/ImportActionCreators";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Loader from "../../components/shared/Loader.react";
import { Form } from "react-bootstrap";
import { fetchGuestCategory, updateGuestCategory } from "../../actions/GuestCategoryActionCreators";
import RichTextEditor from "../../components/shared/RichTextEditor.react";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import GuestCategoryLabel from "../../components/guest_category/GuestCategoryLabel.react";
import { pathToGuestCategoryShow } from "../../utils/pathUtils";

class GuestCategoryEmailLiveCheckForm extends Component {
  constructor(props) {
    super(props);
    [
      "onActionChange",
      "onMessageTitleChange",
      "updateRTEEditableField",
      "onSubmit"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {};

    const { guestCategory, event } = props;
    if (!isEmpty(guestCategory) && event._id) {
      this.state = this.propsToState(props);
    }
  }

  i18n(key, opts = {}) {
    return I18n.t(`guest_categories.email_live_check.${key}`, opts);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.allRequirementsMet && this.props.allRequirementsMet && !this.props.submitting) {
      this.setState(this.propsToState(this.props));
    }
  }

  propsToState(props = this.props) {
    const { guestCategory } = props;
    const { email_live_check_action } = guestCategory;

    if (email_live_check_action === "none") {
      return { email_live_check_action };
    }

    return { ...this.defaultTitleAndContent(email_live_check_action, props), email_live_check_action };
  }

  defaultTitleAndContent(action, props = this.props) {
    if (action === "none") {
      return {};
    }

    const { guestCategory, event } = props;
    const currentTitle = get(guestCategory, "email_live_check_message_title", null);
    const currentContent = get(guestCategory, "email_live_check_message_content.html", null);
    const opts = { locale: event.locale };

    return {
      email_live_check_message_title: currentTitle || this.i18n(`email_live_check_${action}_message_title_default`, opts),
      email_live_check_message_content: {
        html: currentContent || this.i18n(`email_live_check_${action}_message_content_default_html`, opts)
      }
    };
  }

  onActionChange(e) {
    const update = { ...this.defaultTitleAndContent(e.target.value), email_live_check_action: e.target.value };
    this.setState(update);
  }

  onMessageTitleChange(e) {
    this.setState({
      email_live_check_message_title: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { updateGuestCategory, match } = this.props;
    const { event_id, guest_category_id } = match.params;

    updateGuestCategory(
      event_id,
      guest_category_id,
      this.state,
      pathToGuestCategoryShow(guest_category_id),
      { notice: this.i18n("successfully_saved"), noticeType: "success" }
    );
  }

  renderActionOnDuplicatedEmailDetected() {
    const { email_live_check_action } = this.state;
    const options = ["none", "resend_email", "find_contact"].map(value => {
      return <Form.Group key={value} controlId={value}>
        <Form.Check
          type="radio"
          value={value}
          onChange={this.onActionChange}
          checked={email_live_check_action === value}
          label={ this.i18n(value) }
        />
      </Form.Group>;
    });

    return <div className="mb-3">
      <Form.Label>{ this.i18n("email_live_check_action_label") }</Form.Label>
      { options }
    </div>;
  }

  updateRTEEditableField(html, draftjs_content) {
    this.setState({
      email_live_check_message_content: {
        html,
        draftjs_content
      }
    });
  }

  renderModalMessage() {
    const { email_live_check_action, email_live_check_message_content } = this.state;
    if (!email_live_check_message_content) return;

    const { html, draftjs_content } = email_live_check_message_content;

    return <div className="mb-3 row">
      <div className="col-12">
        <Form.Label>{ this.i18n("email_live_check_message_content") }</Form.Label>
        <RichTextEditor content={html}
          draftjsContent={draftjs_content}
          onBlur={this.updateRTEEditableField}
          key={email_live_check_action}
          textGeneratorPrePromptKey="organizer_content_context"
          toolbarSize="small"
        />
      </div>
    </div>;
  }

  renderModalTitle() {
    const { email_live_check_message_title } = this.state;
    if (!email_live_check_message_title) return;

    return <div className="mb-3 row">
      <div className="col-12">
        <Form.Label>{ this.i18n("email_live_check_message_title") }</Form.Label>
        <Form.Control value={ email_live_check_message_title } onChange={this.onMessageTitleChange} />
      </div>
    </div>;
  }

  renderErrorsMessage() {
    return <ErrorMessage errors={ this.props.guestCategoryErrors } model="guest_category" />;
  }

  renderModalConfiguration() {
    return <div className="mb-3">
      { this.renderModalTitle() }
      { this.renderModalMessage() }
    </div>;
  }

  render() {
    const { guestCategory, event, match } = this.props;
    const { guest_category_id } = match.params;

    if (isEmpty(guestCategory) && !event) {
      return <Loader />;
    }
    const { email_live_check_action } = this.state;
    return <div className="row">
      <div className="col-md-8 offset-md-2">
        <div className="header-page">
          <div className="header-page-title">
            <h1>
              <a href={pathToGuestCategoryShow(guest_category_id)}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></a>
              { this.i18n("category") }
              <GuestCategoryLabel guestCategory={guestCategory} isLink={false} cssClasses="big guest-category ml-5 mr-5" />
            </h1>
          </div>
        </div>

        <form className="guest-category-form form-horizontal" onSubmit={this.onSubmit}>
          <div className="card mb-10">
            <div className="card-header">
              <h4 className="card-title">{ this.i18n("title") }</h4>
            </div>
            <div className="card-body">
              { this.renderErrorsMessage() }
              { this.renderActionOnDuplicatedEmailDetected() }
              { email_live_check_action !== "none" && this.renderModalConfiguration() }
            </div>
          </div>

          <button type="submit" className="btn btn-primary">{ I18n.t("validate") }</button>
        </form>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    guestCategoryErrors: state.guestCategory.errors,
    submitting: state.guestCategory.pending
  };
}

const mapDispatchToProps = {
  fetchGuestCategory,
  updateGuestCategory,
  requestEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(requiresProps(GuestCategoryEmailLiveCheckForm, {
  requirements: {
    event: {
      fn: ({ requestEvent }) => {
        requestEvent();
      },
      desiredState: "has_underscore_id"
    },
    guestCategory: {
      fn: ({ fetchGuestCategory, match }) => {
        const { event_id, guest_category_id } = match.params;
        fetchGuestCategory(event_id, guest_category_id);
      },
      statePath: "guestCategory.data"
    }
  }
}));
