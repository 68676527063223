import { Component } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

interface Props extends videojs.PlayerOptions {
  width?: number|string;
  height?: number|string;
  onVideoDimensionsKnown?: (width: number, height: number) => void;
}

export default class VideoPlayer extends Component<Props> {
  private player?: videojs.Player;
  private videoNode?: HTMLVideoElement;

  constructor(props: Props) {
    super(props);
    this.player = undefined;
    this.videoNode = undefined;
  }

  componentDidMount(): void {
    // instantiate video.js
    this.player = videojs(this.videoNode, this.props);
    this.player.on("loadedmetadata", () => {
      const { onVideoDimensionsKnown } = this.props;
      onVideoDimensionsKnown && onVideoDimensionsKnown(this.player.videoWidth(), this.player.videoHeight());
    });
  }

  // destroy player on unmount
  componentWillUnmount(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render(): JSX.Element {
    const { width, height } = this.props;

    return (
      <div data-vjs-player="true">
        <video ref={(node: HTMLVideoElement): HTMLVideoElement => this.videoNode = node} width={width} height={height} className="video-js vjs-big-play-centered" />
      </div>
    );
  }
}
