import { useEffect, useState, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import { urlEventId } from "../../utils/pathUtils";
import { GuestProductCollection } from "../../types/GuestProductCollection";
import { GuestProductCollectionField } from "../../types/GuestProductCollectionField";
import { fetchGuestProductCollections } from "../../actions/GuestProductCollectionsActionCreators";
import Loader from "../../components/shared/Loader.react";
import GuestProductCollectionsTable from "./GuestProductCollectionsTable.react";
import GuestProductCollectionSlidePane from "./GuestProductCollectionSlidePane.react";
import GuestProductCollectionTranslations from "./GuestProductCollectionTranslations.react";
import CreateNewGuestProductCollectionForm from "./CreateNewGuestProductCollectionForm.react";
import $ from "jquery";


const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`events.edit_advanced.guest_product_collections.${key}`, options);
};

const DEFAULT_COLLECTION_FIELDS = [
  { name: i18n("default_fields.name"), type: "text", isDefault: true },
  { name: i18n("default_fields.description"), type: "text_area", isDefault: true },
  { name: i18n("default_fields.illustration"), type: "image", isDefault: true },
  { name: i18n("default_fields.thematics"), type: "values_list", isDefault: true }
];

export const DEFAULT_GUEST_PRODUCT_COLLECTION = {
  name: i18n("guest_products"),
  isDefault: true,
  collection_fields: [
    ...DEFAULT_COLLECTION_FIELDS,
    { name: i18n("default_fields.video_link"), type: "text" },
    { name: i18n("default_fields.document"), type: "file" },
  ]
} as GuestProductCollection;

interface Props {
  match: any;
  location: any;
  history: any;
}

const GuestProductCollectionsList: FC<Props> = ({ match, location, history }) => {
  const guestProductCollections: GuestProductCollection[] = useSelector((state: any) => state.guestProductCollections.data);
  const isFetching = useSelector((state: any) => state.guestProductCollections.isFetching);
  const errorsCreation: string[] = useSelector((state: any) => state.guestProductCollections.errors);
  const errors: string[] = useSelector((state: any) => state.guestProductCollection.errors);
  const successfullyUpdated: boolean = useSelector((state: any) => state.guestProductCollection.successfullyUpdated);
  const event = useSelector((state: any) => state.event);

  const [isSlidingPaneOpen, setIsSlidingPaneOpen] = useState<boolean>(false);
  const [isTranslationsPaneOpen, setIsTranslationsPaneOpen] = useState<boolean>(false);
  const [currentGuestProductCollection, setCurrentGuestProductCollection] = useState<GuestProductCollection>(null);
  const [guestProductCollectionName, setGuestProductCollectionName] = useState<string>("");

  const notice = successfullyUpdated ? I18n.t("successfully_saved") : null;
  const translationsEnabled = event && event.available_frontend_locales && event.available_frontend_locales.length > 0;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!guestProductCollections) dispatch(fetchGuestProductCollections(urlEventId()));
  }, []);

  useEffect(() => {
    if (errors) $(".full-height-form").animate({ scrollTop: 0 }, 500, "swing");
    if (notice && isSlidingPaneOpen) setTimeout(toggleSlidingPane, 1000);
  }, [errors, notice]);

  if (isFetching || !guestProductCollections) return <div className="text-center"><Loader size="medium" /></div>;

  const toggleSlidingPane = (): void => {
    setIsSlidingPaneOpen(!isSlidingPaneOpen);
  };

  const toggleSlidingPaneTranslations = (): void => {
    setIsTranslationsPaneOpen(!isTranslationsPaneOpen);
    // remove focus after slidepane close
    document.getElementById("translations-btn").blur();
  };

  const onFieldsChange = (newGuestProductCollectionFields: GuestProductCollectionField[]): void => {
    const newFields = newGuestProductCollectionFields.filter(field => field._id || !field.hidden && !field._id);

    setCurrentGuestProductCollection({ ...currentGuestProductCollection, collection_fields: newFields });
  };

  return <>
    <ErrorMessage errors={errorsCreation} />
    <GuestProductCollectionsTable
      guestProductCollections={[DEFAULT_GUEST_PRODUCT_COLLECTION, ...guestProductCollections]}
      toggleSlidingPane={toggleSlidingPane}
      setCurrentGuestProductCollection={setCurrentGuestProductCollection}
      setGuestProductCollectionName={setGuestProductCollectionName}
      errors={errors}
      notice={notice}
    />
    <CreateNewGuestProductCollectionForm />
    <GuestProductCollectionSlidePane
      isSlidingPaneOpen={isSlidingPaneOpen}
      guestProductCollection={currentGuestProductCollection}
      guestProductCollectionName={guestProductCollectionName}
      toggleSlidingPane={toggleSlidingPane}
      toggleSlidingPaneTranslations={toggleSlidingPaneTranslations}
      onFieldsChange={onFieldsChange}
      onCollectionNameChange={setGuestProductCollectionName}
      errors={errors}
      notice={notice}
      translationsEnabled={translationsEnabled}
    />

    {translationsEnabled &&
      <GuestProductCollectionTranslations
        match={match}
        location={location}
        history={history}
        collectionId={currentGuestProductCollection?._id}
        isPaneOpen={isTranslationsPaneOpen}
        toggleSlidingPane={toggleSlidingPaneTranslations}
      />
    }
  </>;
};

export default GuestProductCollectionsList;
