import { Component } from "react";
import PropTypes from "prop-types";
import uniqBy from "lodash/uniqBy";
import EditableFieldsForm from "../guest_campaign/EditableFieldsForm.react";
import { StaticGuestFieldToExport, EventDropFields } from "../../constants/Constants";

class EditableFieldsLayout extends Component {

  render() {
    const { guestFields, model, errors, updateEditableField, deleteFileEditableField, changeImageEditableField, name } = this.props;
    let dropFields = [];
    StaticGuestFieldToExport.forEach(field => { dropFields.push({ id: `guest.${field}`, name: `guest.${field}` }); });
    EventDropFields.forEach(field => { dropFields.push({ id: `event.${field}`, name: `event.${field}` }); });
    guestFields.forEach(field => { dropFields.push({ id: `guest.${field.key}`, name: `guest.${field.key}` }); });

    return <EditableFieldsForm
      dropFields={uniqBy(dropFields, "id")}
      errors={errors}
      editableElements={model.editable_elements}
      updateEditableField={updateEditableField}
      deleteFile={deleteFileEditableField}
      updateImageEditableField={changeImageEditableField}
      name={name}
    />;
  }
}


EditableFieldsLayout.propTypes = {
  model: PropTypes.object.isRequired,
  errors: PropTypes.object,
  guestFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateEditableField: PropTypes.func.isRequired,
  deleteFileEditableField: PropTypes.func.isRequired,
  changeImageEditableField: PropTypes.func.isRequired
};

EditableFieldsLayout.defaultProps = {
  errors: {}
};

export default EditableFieldsLayout;
export { EditableFieldsLayout };
