import { ChangeEvent } from "react";
import InputLabelWithVisibilityButton from "../InputLabelWithVisibilityButton.react";
import { Program, GuestRole } from "../../../types/Program";
import DeletablePanel from "../DeletablePanel.react";
import GuestFieldPicker from "../../shared/GuestFieldPicker.react";
import Checkbox from "../../shared/Checkbox.react";

interface Props {
  program: Program;
  guestRole: GuestRole;

  onChangeConfiguration(program: Program): void;
  onDeleteOption(optionType: string): void;
}

const GuestDisplayOptions: React.FC<Props> = ({ program, guestRole, onChangeConfiguration, onDeleteOption }) => {
  function i18n(key, opts = {}): string {
    return I18n.t(`react.programs.${guestRole}_display_options.${key}`, opts);
  }

  const checkboxChanged = (key: string): ((e: ChangeEvent<HTMLInputElement>) => void) => {
    return (e: ChangeEvent<HTMLInputElement>): void => {
      const newProgram = { ...program };
      newProgram[key] = e.target.checked;
      onChangeConfiguration(newProgram);
    };
  };

  const illustrationFieldChanged = (value): void => {
    const newProgram = { ...program };
    newProgram[`${guestRole}_illustration_field`] = value;
    onChangeConfiguration(newProgram);
  };

  const labelWithVisibilityButtonChanged = (label: string, isVisible: boolean): void => {
    const newProgram = { ...program };
    newProgram[`${guestRole}_label`] = label;
    newProgram[`${guestRole}_label_displayed`] = isVisible;
    onChangeConfiguration(newProgram);
  };

  return <DeletablePanel
    title={I18n.t(`react.programs.program_options.${guestRole}_option_label`)}
    onDelete={(): void => onDeleteOption(guestRole)}
    draggable={true}>
    <InputLabelWithVisibilityButton
      label={i18n("label")}
      value={program[`${guestRole}_label`]}
      name={`${guestRole}_label`}
      isDisplayed={program[`${guestRole}_label_displayed`]}
      onChangeHandler={labelWithVisibilityButtonChanged}
    />
    <div className="mt-3">
      <Checkbox checked={program[`${guestRole}_illustration_displayed`]} text={i18n("illustration")} onChange={checkboxChanged(`${guestRole}_illustration_displayed`)} />
    </div>
    {program[`${guestRole}_illustration_displayed`] && <div className="mt-10">
      <GuestFieldPicker
        label={i18n("illustration_field")}
        selectedValue={program[`${guestRole}_illustration_field`]}
        onSelect={illustrationFieldChanged}
      />
    </div>}
    <Checkbox checked={program[`${guestRole}_name_displayed`]} text={i18n("name")} onChange={checkboxChanged(`${guestRole}_name_displayed`)} />
    <Checkbox checked={program[`${guestRole}_company_name_displayed`]} text={i18n("company_name")} onChange={checkboxChanged(`${guestRole}_company_name_displayed`)} />
    <Checkbox checked={program[`${guestRole}_position_displayed`]} text={i18n("position")} onChange={checkboxChanged(`${guestRole}_position_displayed`)} />
  </DeletablePanel>;
};

export default GuestDisplayOptions;
