import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AtlasChart from "../../components/AtlasChart.react";
import HeaderStats from "../../components/dashboard/HeaderStats.react";
import { redirectIfUnauthorized } from "../../utils/aclUtils";
import { isEnabled } from "../../utils/featureSetUtils";
import DashboardNavTab from "../../components/dashboard/DashboardNavTab.react";
import { DASHBOARD_HEADER_NO_VALUE_STR } from "../../constants/Constants";
import { EXHIBITOR_LEADS } from "../../constants/FeaturesSet";
import moment from "moment";
import DateRangePicker from "../../components/DateRangePicker.react";
import { buildFilter } from "../../utils/AtlasChartUtils";
import withDatabaseDefaultRegionRestriction from "../../components/shared/WithDatabaseDefaultRegionRestriction.react";
import HelpSection from "../../components/shared/HelpSection.react";

const LEAD_SOURCES_CHART_ID = "6346dd26-8094-40d2-87aa-826aeef54c76";
const LEAD_SOURCES_BY_DAY_CHART_ID = "854f63d0-a8d1-4414-9f8d-d9231b748338";

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`react.dashboard.leads.${key}`, options);
};

const LeadsDashboard: React.FC = () => {
  const event = useSelector((state: any) => state.event);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    redirectIfUnauthorized("reports", "read");
  }, []);

  const [total, setTotal] = useState(null);
  const [topDay, setTopDay] = useState(null);
  const onChartDataChanged = (data): void => {
    if (!data) {
      setTotal(null);
      return;
    }

    let newTopDay = DASHBOARD_HEADER_NO_VALUE_STR;
    let newTopDayValue = 0;
    let currentDay;
    let currentDayValue = 0;

    const total = data.documents.reduce((partialSum, document) => {
      if (currentDay === document.x) {
        currentDayValue += document.y;
      } else {
        currentDay = document.x;
        currentDayValue = document.y;
      }
      if (currentDayValue > newTopDayValue) {
        newTopDay = currentDay;
        newTopDayValue = currentDayValue;
      }
      return partialSum + document.y;
    }, 0);
    setTotal(total);
    setTopDay(currentDay ? { date: moment(newTopDay).format("ll"), value: newTopDayValue } : null);
  };

  const buildCustomDateFilter = (): any => {
    const filters = [
      { type: "startDate", field: "created_at", value: startDate },
      { type: "endDate", field: "created_at", value: endDate },
    ];

    return buildFilter(filters);
  };

  if (!isEnabled(EXHIBITOR_LEADS) || !event._id) return null;

  return <>
    <DashboardNavTab active="leads" event_has_exits={event.has_exits} />
    <HeaderStats
      dataFetched={true}
      headers={
        [
          { i18nKey: "total", value: total || DASHBOARD_HEADER_NO_VALUE_STR, nbCols: 6 },
          { i18nKey: "top_day", value: topDay?.value || DASHBOARD_HEADER_NO_VALUE_STR, labelInfoValue: topDay?.date || DASHBOARD_HEADER_NO_VALUE_STR, nbCols: 6 }
        ]
      }
      tabKey="leads"
    />
    <HelpSection help={i18n("hint_message")} />

    <div className="mb-3">
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onDateRangeChanged={(start, end): void => { setStartDate(start); setEndDate(end); }}
      />
    </div>
    <div className="card">
      <AtlasChart
        eventId={event._id}
        title={i18n("lead_sources_by_day")}
        onChartDataChanged={ onChartDataChanged }
        chartId={ LEAD_SOURCES_BY_DAY_CHART_ID }
        exportOptions={{
          columnsMapping: { day: { documentKey: "x" }, lead_source_type: { documentKey: "color" }, count: { documentKey: "y", total: true } },
          type: "lead_sources_by_day"
        }}
        customFilter={buildCustomDateFilter()}
      />
    </div>
    <div className="card">
      <AtlasChart
        eventId={event._id}
        title={i18n("lead_sources")}
        chartId={ LEAD_SOURCES_CHART_ID }
        exportOptions={{
          columnsMapping: { lead_source_type: { documentKey: "y" }, count: { documentKey: "x", total: true } },
          type: "lead_sources"
        }}
        customFilter={buildCustomDateFilter()}
      />
    </div>
  </>;
};

export default withDatabaseDefaultRegionRestriction(LeadsDashboard);
