import { ActionTypes } from "../constants/Constants";

function registrationFormFocus(state = {}, action) {
  switch (action.type) {
  case ActionTypes.REGISTRATION_FORM_SUCCESS: {
    const { formSteps } = action.data.entities;
    const firstStep = formSteps[Object.keys(formSteps)[0]];
    return firstStep ? focusStep(state, firstStep) : state;
  }
  case ActionTypes.REGISTRATION_FORM_ITEM_DESTROY_SUCCESS: {
    const { registrationFormSteps } = state;
    const step = stepForItem(registrationFormSteps, action.formItem);
    const previousItem = previousItemInStep(state, action.formItem, step);
    if (previousItem) {
      return focusItem(state, previousItem);
    }
    return step ? focusStep(state, step) : state;
  }

  case ActionTypes.REGISTRATION_FORM_ITEM_CREATE_SUCCESS:
    return focusItem(state, action.data);
  case ActionTypes.REGISTRATION_FORM_ITEM_NEW:
  case ActionTypes.REGISTRATION_FORM_ITEM_TOGGLE_MODE:
    return focusItem(state, action.formItem);

  case ActionTypes.REGISTRATION_FORM_STEP_CREATE_SUCCESS: {
    const { formSteps } = action.data.entities;
    return focusStep(state, formSteps[Object.keys(formSteps)[0]]);
  }

  case ActionTypes.REGISTRATION_FORM_STEP_DELETE_SUCCESS: {
    const { registrationFormSteps } = state;
    const firstStep = registrationFormSteps[0];
    return firstStep ? focusStep(state, firstStep) : state;
  }
  case ActionTypes.REGISTRATION_FORM_STEP_TOGGLE_FOCUS:
    return focusStep(state, action.formStep);

  case ActionTypes.REGISTRATION_FORM_ITEM_UPDATE_REQUEST:
    if (action.optimistic && action.params.mode === "edit") {
      // item may have change step, ensure step is focused
      return focusItem(state, action.params);
    }
    return state;
  default:
    return state;
  }

}

export default registrationFormFocus;

function focusItem(state, item) {
  const { registrationFormItems, registrationFormSteps } = state;

  const newItems = registrationFormItems.map(it => {
    const mode = item._id === it._id ? "edit" : "read";
    return Object.assign({}, it, { mode });
  });

  const itemStep = stepForItem(registrationFormSteps, item);
  const newSteps = registrationFormSteps.map(step => {
    const focused = itemStep._id === step._id;
    return Object.assign({}, step, { focused });
  });
  return Object.assign({}, state, { registrationFormItems: newItems, registrationFormSteps: newSteps });
}

function focusStep(state, step) {
  const { registrationFormItems, registrationFormSteps } = state;

  const newSteps = registrationFormSteps.map(st => {
    const focused = step._id === st._id;
    return Object.assign({}, st, { focused });
  });

  let newItems = null;
  const stepItem = firstItemForStep(registrationFormItems, step);
  if (!stepItem) {
    newItems = registrationFormItems.map(item => { return Object.assign({}, item, { mode: "read" }); });
  } else {
    newItems = registrationFormItems.map(item => {
      const mode = item._id === stepItem._id ? "edit" : "read";
      return Object.assign({}, item, { mode });
    });
  }
  return Object.assign({}, state, { registrationFormItems: newItems, registrationFormSteps: newSteps });
}

//helper
function stepForItem(steps, item) {
  return steps.find(step => {
    return step.form_sections.indexOf(item.form_section_id) > -1;
  });
}

function firstItemForStep(items, step) {
  return items.find(item => {
    return step.form_sections.indexOf(item.form_section_id) > -1;
  });
}

function previousItemInStep(state, formItem, step) {
  const { registrationFormItems } = state;
  const itemsInStep = registrationFormItems.filter(item => step.form_sections.includes(item.form_section_id));
  const previousItems = itemsInStep.filter(item => item.rank < formItem.rank);
  const sortedPreviousItems = previousItems.sort((a, b) => b.rank - a.rank);
  return sortedPreviousItems[0];
}
