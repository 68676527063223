import { Component } from "react";
import PropTypes from "prop-types";

import { GuestStatusRegistered, GuestStatusPendingRegistration } from "../../constants/Constants";
import { pathToGuestEmail } from "../../utils/pathUtils";

const noEmail = I18n.t("shared.table_guest.no_email");

class ConfirmationEmailCell extends Component {

  render() {
    const { guest } = this.props;
    let emailType = null;
    if (guest.status == GuestStatusRegistered && guest.confirmation_email_enabled) {
      emailType = "confirmation";
    } else if (guest.status == GuestStatusPendingRegistration && guest.moderation_email_enabled) {
      emailType = "moderation";
    }

    let content = noEmail;
    if (emailType) {
      content = <a href={pathToGuestEmail(guest, emailType)} target="_blank">{I18n.t(`shared.table_guest.${emailType}`)}</a>;
    }

    return (
      <td className="cell-default d-none d-sm-table-cell">
        { content }
      </td>
    );
  }
}

ConfirmationEmailCell.propTypes = {
  guest: PropTypes.object.isRequired
};

export default ConfirmationEmailCell;
