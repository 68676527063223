import { ActionTypes } from "../constants/Constants";
import { updateLiquidField, DocumentTemplateName } from "../utils/LiquidFieldUtils";
import { DocumentTemplateStore } from "../types/DocumentTemplate";

const defaultState: DocumentTemplateStore = {
  data: null,
  errors: {},
  isFetching: false,
  isSaving: false,
  generalSettings: {},
  reloadingTheme: false,
  reloadThemeError: null
};

function documentTemplate(state = defaultState, action: any): DocumentTemplateStore {
  switch (action.type) {
  case ActionTypes.FETCH_DOCUMENT_TEMPLATE_PENDING:
    return { ...state, data: null, isFetching: true, errors: {} };
  case ActionTypes.FETCH_DOCUMENT_TEMPLATE_SUCCESS: {
    const settings_data = action.data.settings_data;
    delete(action.data.settings_data);
    return { ...state,
      data: action.data,
      generalSettings: settings_data.general,
      isFetching: false,
      errors: {}
    };
  }
  case ActionTypes.UPDATE_DOCUMENT_TEMPLATE_PENDING:
    return { ...state, isSaving: true };
  case ActionTypes.UPDATE_DOCUMENT_TEMPLATE_SUCCESS: {
    const settingsData = action.data.settings_data;
    delete(action.data.settings_data);
    return { ...defaultState, data: action.data, generalSettings: settingsData.general };
  }
  case ActionTypes.UPDATE_DOCUMENT_TEMPLATE_FAILURE:
    return { ...state, isSaving: false, errors: action.data.errors };
  case ActionTypes.UPDATE_APPEARANCE:
    return { ...state, generalSettings: { ...action.settings } };
  case ActionTypes.RELOAD_DOCUMENT_TEMPLATE_THEME_PENDING:
    return { ...state, reloadingTheme: true, reloadThemeError: null };
  case ActionTypes.RELOAD_DOCUMENT_TEMPLATE_THEME_SUCCESS: {
    const { updated_at, theme_name, theme_updated_at, reloaded_at, reload_mean, settings_data } = action.data;
    return { ...state,
      data: { ...state.data,
        updated_at,
        theme_name,
        theme_updated_at,
        reloaded_at,
        reload_mean
      },
      reloadingTheme: false,
      generalSettings: settings_data.general
    };
  }
  case ActionTypes.RELOAD_DOCUMENT_TEMPLATE_THEME_FAILURE:
    return { ...state, reloadingTheme: false, reloadThemeError: action.data.error };
  case ActionTypes.OFFLINE_UPDATE_RICHTEXT_EDITOR_SUCCESS:
    return updateLiquidField(state, action, DocumentTemplateName);
  default:
    return state;
  }
}

export default documentTemplate;
