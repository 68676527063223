import { FormEvent, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import SlidingPane from "react-sliding-pane";
import ProgramSelection from "./ProgramSelection.react";
import ProgramOptions from "./ProgramOptions.react";
import ProgramUIFilters from "./ProgramUIFilters.react";
import Loader from "../shared/Loader.react";
import ErrorMessage from "../shared/ErrorMessage.react";
import TranslationTable from "../../containers/translations/TranslationTable.react";
import NoticeBlock from "../templates_builder/NoticeBlock.react";
import Icons from "../../constants/Icons";
import { Program, ProgramFilter, FilterContext } from "../../types/Program";
import { urlEventId } from "../../utils/pathUtils";
import { ProgramFilterTypes } from "../../constants/Constants";
import { Event } from "../../types/Event";

const DEFAULT_PRESETS = {
  "session_type": { "session_types": [] },
  "attendance_type": { "attendance_types": [] },
  "thematic": { "thematics": [] },
  "session_room": { "session_rooms": [] },
  "date_range": { "min_date": null, "max_date": null },
  "trait": { "default": [] },
  "bookmarks_only": { "bookmarks_only": true },
  "missed_sessions": { "missed_sessions": true },
  "sort_by_recommendation": { "sort_by_recommendation": true }
};

interface Props {
  program?: Program;
  errors?: any[];
  isSaving: boolean;
  displayTitle?: boolean;
  event: Event;

  onChangeConfiguration(program: Program): void;
  onSubmit(): void;
  onProgramNameChange(name: string): void;
}

const ProgramForm: React.FC<Props> = ({ program, errors, isSaving, displayTitle, event, onChangeConfiguration, onSubmit, onProgramNameChange }) => {
  const [translationsPaneShown, setTranslationsPaneShown] = useState(false);
  const [editingProgramName, setEditingProgramName] = useState(false);

  const notice = useSelector((state: any) => state.notifications.currentNotice);
  const noticeType = useSelector((state: any) => state.notifications.noticeType);

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const inputNameRef = useRef<HTMLInputElement>(null);

  const setDefaultLabels = (filter: ProgramFilter): void => {
    if (filter._destroy) return;

    if (filter._type === "date_range") {
      filter.label = I18n.t("react.programs.date_range_filter.title");
      filter.time_search_label = I18n.t("react.programs.date_range_filter.time_search_label_placeholder");
    } else {
      filter.label = I18n.t(`react.programs.${filter._type}_filter.title`);
    }
  };

  // since filters are handled in 2 different sections of the form,
  // sometimes adding a filter to a section is just updating an existing filter
  const addFilter = (type: string, context: FilterContext): void => {
    const existingFilter = program.program_filters[type];
    const newRank = Math.max(...Object.values(program.program_filters).map(filter => filter.rank), 0) + 1000;
    if (existingFilter) {
      if (context === "ui") {
        existingFilter.displayed_to_user = true;
        existingFilter.rank = newRank;
      } else if (context === "selection") {
        existingFilter.preset_args = { ...DEFAULT_PRESETS[type] };
      }
      existingFilter._destroy = false; // in case existing filter is in pending destroy and has been added again
    }

    const filter = existingFilter || {
      "_type": type,
      "displayed_to_user": context === "ui",
      "preset_args": context === "selection" ? { ...DEFAULT_PRESETS[type] } : {},
      "label_displayed": true,
      "rank": newRank,
      "_destroy": false
    };
    setDefaultLabels(filter);

    const uniqKey = type === "trait" ? `trait_${Math.floor(Math.random() * 1000)}` : type;
    const newProgram = { ...program, program_filters: { ...program.program_filters, [uniqKey]: filter } };
    onChangeConfiguration(newProgram);
  };

  const updateFilter = (programFilter: ProgramFilter, filterKey: string): void => {
    const newProgram = { ...program, program_filters: {
      ...program.program_filters, [filterKey]: programFilter
    } };
    onChangeConfiguration(newProgram);
  };

  const removeFilter = (programFilter: ProgramFilter, filterKey: string, context: FilterContext): void => {
    if (context === "ui") {
      programFilter.displayed_to_user = false;
    } else if (context === "selection") {
      programFilter.preset_args = {};
    }

    // mark filter as destroyable since it is not used in configuration anymore
    if (programFilter._id && !filterInSelection(programFilter) && !filterInUISearch(programFilter)) {
      programFilter._destroy = true;
    }

    const newProgramFilters = { ...program.program_filters, [filterKey]: programFilter };

    // remove filter completely since it does not exist in DB yet
    if (!programFilter._id && !filterInSelection(programFilter) && !filterInUISearch(programFilter)) {
      delete newProgramFilters[filterKey];
    }

    const newProgram = { ...program, program_filters: newProgramFilters };

    // navigation by date depends on date range filter
    if (programFilter._type === ProgramFilterTypes.DATE_RANGE) {
      newProgram.navigation_by_date_enabled = false;
    }

    onChangeConfiguration(newProgram);
  };

  const filterInSelection = (programFilter: ProgramFilter) : boolean => {
    return Object.keys(programFilter.preset_args).length > 0;
  };

  const filterInUISearch = (programFilter: ProgramFilter) : boolean => {
    return programFilter.displayed_to_user;
  };

  const toggleTranslationsPane = (): void => {
    setTranslationsPaneShown(!translationsPaneShown);
  };

  const submit = (e: FormEvent): void => {
    e.preventDefault();
    onSubmit();
  };

  const toggleEditProgramName = (): void => {
    setEditingProgramName(!editingProgramName);
  };

  const changeProgramName = (): void => {
    const newProgramName = inputNameRef.current.value;

    if (program.name !== newProgramName) {
      onProgramNameChange(newProgramName);
    }
    toggleEditProgramName();
  };

  const renderTranslations = (): JSX.Element[] => {
    if (!program.translations_enabled) return null;

    return [
      renderTranslationsButton(),
      renderTranslationsPane()
    ];
  };

  const renderTranslationsButton = (): JSX.Element => {
    return <button type="button" className="btn btn-secondary" onClick={toggleTranslationsPane} key="translations-button">
      <i className="fa-regular fa-language fa-fw"></i> {I18n.t("shared.global_menu.translations")}
    </button>;
  };

  const renderTranslationsPane = (): JSX.Element => {
    return <SlidingPane
      isOpen={translationsPaneShown}
      title={I18n.t("shared.global_menu.translations")}
      from="right"
      width='90%'
      onRequestClose={toggleTranslationsPane}
      className="width-100-xs width-90-sm"
      closeIcon={Icons.close()}
      key="translations-pane"
    >
      <div style={{ padding: "0 20px 20px" }}>
        <NoticeBlock notice={notice} noticeType={noticeType} />
        <TranslationTable
          type="programs"
          eventId={urlEventId()}
          id={program._id}
          inSlidingPane={true}
          onlySimpleValidateButton={true}
          history={history}
          match={match}
          location={location}
        />
      </div>
    </SlidingPane>;
  };

  const renderErrorsMessage = (): JSX.Element => {
    return <ErrorMessage errors={errors} model="program" />;
  };

  const renderName = (): JSX.Element => {
    if (!displayTitle) return null;

    if (editingProgramName) {
      return <form onSubmit={changeProgramName} style={{ margin: "0px", padding: "0px", width: "400px" }}>
        <input ref={inputNameRef} type="text" defaultValue={program.name} autoFocus={true} className="form-control" onBlur={changeProgramName} />
      </form>;
    }

    return <div className="form-control-static" onClick={toggleEditProgramName}>
      { program.name } <i className="fa-regular fa-pencil ml-5"></i>
    </div>;
  };

  if (!program) return null;

  return <>
    <h2 className="program-title mt-10 mb-10 d-flex justify-content-between align-items-center">
      {renderName()}
      {renderTranslations()}
    </h2>
    <form onSubmit={submit}>
      {renderErrorsMessage()}
      <ProgramSelection
        program={program}
        event={event}
        addFilter={addFilter}
        updateFilter={updateFilter}
        removeFilter={removeFilter} />
      <ProgramOptions
        program={program}
        onChangeConfiguration={onChangeConfiguration} />
      <ProgramUIFilters
        program={program}
        event={event}
        onChangeConfiguration={onChangeConfiguration}
        addFilter={addFilter}
        updateFilter={updateFilter}
        removeFilter={removeFilter} />
      <div style={{ display: "flex", justifyContent: "right" }}>
        <button type="submit" disabled={isSaving} className="btn btn-primary">
          {I18n.t("react.programs.save_changes")}
          {isSaving && <Loader size="small" color="white" />}
        </button>
      </div>
    </form>
  </>;
};

export default ProgramForm;
