import { ActionTypes } from "../constants/Constants";

const defaultState = {};

function guestCount(state = defaultState, action) {
  switch (action.type) {
  case ActionTypes.GUESTS_COUNT_TOTAL_SUCCESS:
    return mergeActionDataInState(state, action, action.keyStore || "total");
  case ActionTypes.GUESTS_COUNT_SINCE_SUCCESS:
    return mergeActionDataInState(state, action, "since");
  case ActionTypes.GUESTS_COUNT_BETWEEN_SUCCESS:
    return mergeActionDataInState(state, action, "between_since_and_before");
  case ActionTypes.GUEST_COUNT_PER_REGISTER_DATE_SUCCESS:
    return mergeActionDataInState(state, action, "per_day", "per_day");
  case ActionTypes.GUESTS_COUNT_CLEAR:
    return defaultState;
  default:
    return state;
  }
}

function mergeActionDataInState(state, { eventId, data }, stateKey, actionKey = "count") {
  const newData = { ...(state[eventId] || {}), [stateKey]: data[actionKey] };
  return { ...state, [eventId]: newData };
}

export default guestCount;
