import { ActionTypes } from "../constants/Constants";

function savedSearch(state = null, action) {
  switch (action.type) {
  case ActionTypes.SAVED_SEARCH_SUCCESS:
  case ActionTypes.UPDATE_SAVED_SEARCH_SUCCESS:
    return Object.assign({}, action.data);
  case ActionTypes.UPDATE_SAVED_SEARCH_FAILURE:
    return { ...(state || {}), errors: action.data };
  default:
    return state;
  }
}

export default savedSearch;
