import { CALL_API, ActionTypes } from "../constants/Constants";

export function fetchBlogArticle(eventId: string, blogArticleId: string): any {
  const { FETCH_BLOG_ARTICLE_PENDING, FETCH_BLOG_ARTICLE_SUCCESS, DEV_NULL } = ActionTypes;

  return {
    [CALL_API]: {
      types: [FETCH_BLOG_ARTICLE_PENDING, FETCH_BLOG_ARTICLE_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/website/blog_articles/${blogArticleId}.json?builder=true`
    }
  };
}

export function createBlogArticle(eventId: string, blogArticleAttributes: any, sendFile?: boolean, notificationsOptions?: any): any {
  const { CREATE_BLOG_ARTICLE_PENDING, CREATE_BLOG_ARTICLE_SUCCESS, CREATE_BLOG_ARTICLE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_BLOG_ARTICLE_PENDING, CREATE_BLOG_ARTICLE_SUCCESS, CREATE_BLOG_ARTICLE_FAILURE],
      method: "POST",
      body: sendFile ? blogArticleAttributes : { blog_article: blogArticleAttributes },
      sendFile: sendFile,
      endpoint: `/api/v1/events/${eventId}/website/blog_articles.json?builder=true`,
      notificationsOptions
    }
  };
}

export function updateBlogArticle(eventId: string, blogArticleId: string, blogArticleAttributes: any, sendFile?: boolean, notificationsOptions?: any): any {
  const { UPDATE_BLOG_ARTICLE_PENDING, UPDATE_BLOG_ARTICLE_SUCCESS, UPDATE_BLOG_ARTICLE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_BLOG_ARTICLE_PENDING, UPDATE_BLOG_ARTICLE_SUCCESS, UPDATE_BLOG_ARTICLE_FAILURE],
      method: "PUT",
      body: sendFile ? blogArticleAttributes : { blog_article: blogArticleAttributes },
      sendFile: sendFile,
      endpoint: `/api/v1/events/${eventId}/website/blog_articles/${blogArticleId}.json?builder=true`,
      notificationsOptions
    }
  };
}

export function clearBlogArticle(): any {
  return {
    type: ActionTypes.CLEAR_BLOG_ARTICLE
  };
}

export function deleteBlogArticle(eventId: string, blogArticleId: string, notificationsOptions?: any): any {
  const { DELETE_BLOG_ARTICLE_PENDING, DELETE_BLOG_ARTICLE_SUCCESS, DELETE_BLOG_ARTICLE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DELETE_BLOG_ARTICLE_PENDING, DELETE_BLOG_ARTICLE_SUCCESS, DELETE_BLOG_ARTICLE_FAILURE],
      method: "DELETE",
      endpoint: `/api/v1/events/${eventId}/website/blog_articles/${blogArticleId}.json`,
      notificationsOptions
    },
    blogArticleId
  };
}

export function duplicateBlogArticle(eventId: string, blogArticleId: string, blogArticleAttributes: any, targetEventId: string): any {
  const { DUPLICATE_BLOG_ARTICLE_PENDING, DUPLICATE_BLOG_ARTICLE_SUCCESS, DUPLICATE_BLOG_ARTICLE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DUPLICATE_BLOG_ARTICLE_PENDING, DUPLICATE_BLOG_ARTICLE_SUCCESS, DUPLICATE_BLOG_ARTICLE_FAILURE],
      method: "POST",
      body: { blog_article: blogArticleAttributes, target_event_id: targetEventId },
      endpoint: `/api/v1/events/${eventId}/website/blog_articles/${blogArticleId}/duplicate.json`
    },
    duplicateInCurrentEvent: eventId === targetEventId
  };
}

export function clearBlogArticleDuplicationErrors(): any {
  return {
    type: ActionTypes.CLEAR_BLOG_ARTICLE_DUPLICATION_ERRORS
  };
}