import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import { urlEventId, urlWithQuery } from "../utils/pathUtils";
import Table from "../components/Table.react";
import { updateUI } from "../actions/UIAppearanceActionCreators";
import { fetchSavedSearches, sortSavedSearches } from "../actions/SavedSearchActionCreators";
import withModal from "./withModal.react";
import { queryStringAndSelectedGuests } from "../utils/QueryStringUtils";
import ErrorMessage from "../components/shared/ErrorMessage.react";
import { OverlayTrigger } from "react-bootstrap";
import { addTooltip } from "../utils/templatesBuilderUtils";

class SavedSearches extends Component {

  constructor(props) {
    super(props);
    this.renderReportQuery = this.renderReportQuery.bind(this);
    this._sortByCreatedAt = this._sortByCreatedAt.bind(this);

    this.state = {
      searchQuery: queryStringAndSelectedGuests(props.location)
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const updates = {
      "modalTitle": I18n.t("react.report_list.title")
    };
    dispatch(fetchSavedSearches(urlEventId()));
    dispatch(updateUI(updates));
  }

  _createdAtIsInAscendingOrder() {
    const { savedSearches } = this.props;
    if (savedSearches.length < 2) {
      return true;
    }
    const first_search = savedSearches[0];
    const last_search = savedSearches[savedSearches.length - 1];

    return first_search.created_at < last_search.created_at;
  }

  _sortByCreatedAt() {
    this.props.dispatch(sortSavedSearches("created_at", !this._createdAtIsInAscendingOrder()));
  }

  renderEditLink(reportId) {
    const { searchQuery } = this.state;

    return <Link style={{ marginLeft: "5px" }} to={urlWithQuery(searchQuery, `guests/modal/edit_saved_search/${reportId}`)} className="btn btn-secondary btn-sm">
      { I18n.t("react.report_list.edit") }
    </Link>;
  }

  renderDeleteLink(report) {
    const { searchQuery } = this.state;
    if (report.can_delete) {
      return <Link style={{ marginLeft: "5px" }} to={urlWithQuery(searchQuery, `guests/modal/delete_saved_search/${report._id}`)} className="btn btn-danger btn-sm">
        { I18n.t("react.report_list.delete") }
      </Link>;
    } else {
      return <OverlayTrigger placement="top" overlay={addTooltip(I18n.t("react.report_list.delete_info"))}>
        <span>
          <Link style={{ marginLeft: "5px" }} to="#" className="btn btn-danger btn-sm disabled">
            { I18n.t("react.report_list.delete") }
          </Link>
        </span>
      </OverlayTrigger>;
    }
  }

  renderReportQuery(report) {
    const to = urlWithQuery(report.search_query, "guests");
    return (
      <tr key={report._id}>
        <td> <Link to={to}>{report.name}</Link> </td>
        <td> {report.user_name} </td>
        <td> {report.formatted_created_at} </td>
        <td>
          <Link to={to}>
            <button className="btn btn-primary btn-sm"> {I18n.t("react.report_list.use")} </button>
          </Link>
          {this.renderEditLink(report._id)}
          {this.renderDeleteLink(report)}
        </td>
      </tr>
    );
  }

  render() {
    let reportList = "";
    const { savedSearches, errors } = this.props;
    const list = map(savedSearches, (report) => {
      return this.renderReportQuery(report);
    });
    const linkStyle = {
      cursor: "pointer"
    };
    let createdAtSortArrow = "fa-regular fa-arrow-down";
    if (this._createdAtIsInAscendingOrder()) {
      createdAtSortArrow = "fa-regular fa-arrow-up";
    }
    const headers = [
      I18n.t("react.report_list.header"),
      I18n.t("react.report_list.creator_header"),
      <div style={linkStyle} onClick={this._sortByCreatedAt.bind(this)}> <i className={createdAtSortArrow}></i> {I18n.t("react.report_list.created_at_header")} </div>,
      I18n.t("react.report_list.actions")
    ];
    if (isEmpty(this.props.savedSearches)) {
      reportList = I18n.t("react.exports_list.no_saved_search");
    } else {
      reportList = (
        <Table headers={headers}>
          {list}
        </Table>
      );
    }

    return (
      <div>
        <ErrorMessage errors={errors}/>
        { reportList }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSavedSearch: state.currentSavedSearch,
    savedSearches: state.savedSearches.data,
    errors: state.savedSearches.errors
  };
}

export default connect(mapStateToProps)(withModal(SavedSearches));
