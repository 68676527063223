import { Component } from "react";
import { connect } from "react-redux";
import { importGuestCategories, importGuestCategoriesFromEvent } from "../actions/ImportGuestCategoryActionCreators";
import { requestEvent } from "../actions/ImportActionCreators";
import { fetchEvents, updateEvent } from "../actions/EventActionCreators";
import ErrorMessage from "../components/shared/ErrorMessage.react";
import ExtractFromOtherEvents from "../components/checkin_rules/ExtractFromOtherEvents.react";
import CreateGuestOnCheckin from "../components/checkin_rules/CreateGuestOnCheckin.react";

import { isEnabled } from "../utils/featureSetUtils";
import { CHECKIN_RULES } from "../constants/FeaturesSet";

class CheckinRules extends Component {
  constructor() {
    super();
    [
      "submitCreateGuestOnCheckin",
      "submitExtractFromOtherEvents",
      "submit"
    ].forEach((item) => {
      this[item] = this[item].bind(this);
    });
  }

  componentDidMount() {
    const { requestEvent, importGuestCategories } = this.props;
    requestEvent();
    importGuestCategories();
  }

  submitExtractFromOtherEvents(params) {
    this.submit({
      extract_guest_target_events: params
    });
  }

  submitCreateGuestOnCheckin(rules) {
    this.submit({
      create_guest_on_checkin_rules: rules
    });
  }

  submit(updateParams) {
    const { updateEvent, event, match } = this.props;
    const { location } = window;
    updateEvent(event.id, updateParams, `${location.protocol}//${location.host}/${match.params.locale}/events/:id/accesspoints/checkin_rules`);
  }

  render() {
    if (!isEnabled(CHECKIN_RULES)) return null;

    const { event, events, importGuestCategoriesFromEvent, guestCategories, fetchEvents } = this.props;
    return (
      <>
        <ErrorMessage errors={event.errors}/>
        <ExtractFromOtherEvents
          event={event}
          events={events}
          fetchTargetEvents={fetchEvents}
          fetchGuestCategoriesFromTargetEvent={importGuestCategoriesFromEvent}
          guestCategories={guestCategories}
          onSubmit={this.submitExtractFromOtherEvents}
        />
        <hr/>
        <CreateGuestOnCheckin
          event={event}
          guestCategories={guestCategories}
          onSubmit={this.submitCreateGuestOnCheckin}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    guestCategories: state.guestCategories.data,
    event: state.event,
    events: state.events.data
  };
}

function mapDispatchToProps() {
  return { fetchEvents, importGuestCategories, importGuestCategoriesFromEvent, requestEvent, updateEvent };
}

export default connect(mapStateToProps, mapDispatchToProps()) (CheckinRules);
