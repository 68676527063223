import { Component } from "react";
import PropTypes from "prop-types";
import { pathToGuestsImport } from "../utils/pathUtils";
import truncate from "lodash/truncate";
import map from "lodash/map";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import every from "lodash/every";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  StandardLinkColumns,
  EMAIL_CELL,
  BADGES_CELL,
  PRINT_BADGES_CELL,
  PAYMENT_CELL,
  COLUMN_STANDARD_FIELD_TYPE,
  COLUMN_LINK_TYPE,
  COLUMN_GUEST_FIELD_TYPE,
  COLUMN_QUARTILE_THEMATIC_TYPE,
  COLUMN_STANDARD_SCORE_THEMATIC_TYPE
} from "../constants/Constants";

import Loader from "./shared/Loader.react";
import GuestCell from "./GuestCell.react";
import { isAuthorized } from "../utils/aclUtils";

const COL_TITLE_MAX_CHAR = 20;

class GuestsTable extends Component {
  constructor(props) {
    super(props);
    this._toggleGuest = this._toggleGuest.bind(this);
    this.renderNoGuest = this.renderNoGuest.bind(this);
    this.stripNonDisplayableColumns = this.stripNonDisplayableColumns.bind(this);
    this.launchSampleGuestsCreation = this.launchSampleGuestsCreation.bind(this);
    this.state = { importSampleLaunched: false };
  }

  _toggleGuest(idOrAll) {
    return () => {
      const { guests, toggleGuest } = this.props;
      if (idOrAll === "all") {
        const forceCheck = !every(guests, guest => {
          return guest.checked;
        });
        toggleGuest(map(guests, g => {return { id: g.id, status: g.status };}), forceCheck);
      } else {
        const foundGuest = find(guests, g => {return g.id == idOrAll;});
        if (foundGuest) {
          toggleGuest([{ id: foundGuest.id, status: foundGuest.status }]);
        }
      }
    };
  }

  stripNonDisplayableColumns() {
    const { columns, event } = this.props;
    let nonDisplayable = [];
    if (!event.has_badge) nonDisplayable = nonDisplayable.concat([BADGES_CELL, PRINT_BADGES_CELL]);
    if (!event.has_confirmation_email) nonDisplayable.push(EMAIL_CELL);
    if (!event.has_payment) nonDisplayable.push(PAYMENT_CELL);
    return columns.filter(col => !nonDisplayable.includes(col.identifier));
  }

  titleForColumn(col) {
    const { guestFields, documentTemplates } = this.props;
    switch (col.type) {
    case COLUMN_STANDARD_FIELD_TYPE:
      return I18n.t(`mongoid.attributes.guest.${col.identifier}`);
    case COLUMN_LINK_TYPE: {
      const cell = Object.keys(StandardLinkColumns).find(c => c === col.identifier);
      if (cell) {
        return I18n.t(`mongoid.attributes.guest.${col.identifier}`);
      }
      const template = (documentTemplates || []).find(template => template._id === col.identifier);
      return template ? template.name : col.identifier;
    }
    case COLUMN_GUEST_FIELD_TYPE: {
      const field = guestFields.find(field => field.key === col.identifier);
      return field ? field.label : col.identifier;
    }
    default:
      return col.identifier;
    }
  }

  launchSampleGuestsCreation() {
    const { launchSampleGuestsCreation } = this.props;
    const { importSampleLaunched } = this.state;
    if (!importSampleLaunched) {
      launchSampleGuestsCreation();
      this.setState({ importSampleLaunched: true });
    }
  }

  renderNoGuest() {
    const { isDefaultQuery } = this.props;
    const { importSampleLaunched } = this.state;
    const I18nConfirmationMessageKey = I18n.t("guests.table_guest.sample_creation_confirmation");

    return isDefaultQuery ? (
      <div>
        <div className="card nothing-yet mt-10">
          <div>
            <i class="fa-regular fa-users fa-xl"></i>
          </div>
          <div>
            <h4>
              { I18n.t("guests.table_guest.no_registered_guest_to_show") }
            </h4>
            <p className="sub-title">
              { I18n.t("guests.table_guest.no_registered_guest_desc") }
            </p>
          </div>
          <div className="btn-wrapper">
            <a href={pathToGuestsImport()} className="btn btn-secondary"> {I18n.t("guests.table_guest.import")} </a>
            <a
              href="#"
              className="btn btn-secondary ml-10"
              disabled={importSampleLaunched}
              onClick={() => {
                if (window.confirm(I18nConfirmationMessageKey)) {
                  this.launchSampleGuestsCreation();
                }
              }}
            >
              {I18n.t("guests.table_guest.import_sample")}
              { importSampleLaunched ? <Loader size="small" /> : null }
            </a>
          </div>
        </div>
      </div>
    ) : (
      <div>
        <div className="card nothing-yet mt-10">
          <div>
            <i class="fa-regular fa-users fa-xl"></i>
          </div>
          <h4>
            { I18n.t("guests.table_guest.no_guest_to_show") }
          </h4>
          <div>
            <a href={pathToGuestsImport()} className="btn btn-secondary"> {I18n.t("guests.table_guest.import")} </a>
          </div>
        </div>
      </div>
    );
  }

  headerTooltipAndLabel(column, content) {
    const { event, thematics } = this.props;
    let tooltipMessage = content;
    let headerLabel = truncate(content, COL_TITLE_MAX_CHAR);
    let relevantData, totalData, typeMessageKey;

    if (["engagement_std_score", "engagement_quartile"].includes(column.identifier)) {
      relevantData = event.engagement_nb_people_included_in_calculation || {};
      totalData = event.engagement_nb_people || {};
      typeMessageKey = column.identifier === "engagement_quartile" ? "quartile" : "std";
    } else if ([COLUMN_STANDARD_SCORE_THEMATIC_TYPE, COLUMN_QUARTILE_THEMATIC_TYPE].includes(column.type) && thematics) {
      const thematicId = column.identifier.split("_")[1];
      const thematic = thematics.find(th => th._id === thematicId);
      typeMessageKey = column.type.split("_")[0];
      relevantData = thematic.nb_people_included_in_calculation || {};
      totalData = thematic.nb_people || {};
    }

    if (typeMessageKey) {
      tooltipMessage += ` (${ I18n.t(`react.reports.guests_table_columns_picker.${ typeMessageKey }`) })`;
    }

    if (relevantData && totalData) {
      const relevant = Object.values(relevantData).reduce((a, b) => a + b, 0);
      const total = Object.values(totalData).reduce((a, b) => a + b, 0);
      const percentage = total === 0 ? 0 : (relevant * 100 / total).toFixed(0);
      tooltipMessage += `, ${I18n.t("guests.table_guest.relevant_people", { percentage, relevant, total })}`;
      headerLabel = <span style={{ whiteSpace: "nowrap" }}>{ headerLabel } <i className="fa-regular fa-info-circle"></i></span>;
    }

    return { tooltipMessage, headerLabel };
  }

  headerContent(content, column) {
    const { tooltipMessage, headerLabel } = this.headerTooltipAndLabel(column, content);
    const tooltip = <Tooltip id="tooltip">{tooltipMessage}</Tooltip>;

    return <OverlayTrigger placement="top" overlay={tooltip}>
      <span> { headerLabel } </span>
    </OverlayTrigger>;
  }

  headers(thematicsColumns) {
    return this.stripNonDisplayableColumns().reduce((acc, column) => {
      let title = this.titleForColumn(column);

      if ([COLUMN_STANDARD_SCORE_THEMATIC_TYPE, COLUMN_QUARTILE_THEMATIC_TYPE].includes(column.type)) {
        if (!thematicsColumns[column.identifier]) {
          return acc;
        }
        title = thematicsColumns[column.identifier];
      }

      if (column.identifier === "invitations_quota_mapping") {
        acc.push(this.invitationsQuotaMappingHeaders(column));
        return acc;
      }

      if (column.identifier === "collections_quota") {
        acc.push(this.collectionsQuotaHeaders(column));
        return acc;
      }

      acc.push(this.header(title, column));
      return acc;
    }, []);
  }

  header(title, column, key = "") {
    return (
      <th key={key || `header-${column.identifier}`} className="d-none d-sm-table-cell">
        { this.headerContent(title, column) }
      </th>
    );
  }

  thematicsColumns(columns) {
    const { thematics } = this.props;

    if (!thematics) return {};
    const thematicsColumns = columns.filter(col => col.type === COLUMN_QUARTILE_THEMATIC_TYPE || col.type === COLUMN_STANDARD_SCORE_THEMATIC_TYPE) || [];

    return thematicsColumns.reduce((acc, thematicColumn) => {
      const thematicId = thematicColumn.identifier.split("_")[1];
      const thematic = thematics.find(thematic => thematicId === thematic._id);
      if (thematic) {
        acc[thematicColumn.identifier] = thematic.name;
      }
      return acc;
    }, {});
  }

  invitationsQuotaMappingHeaders(column) {
    const { guestCategories } = this.props;
    if (!guestCategories) return [];

    return guestCategories.filter(gc => gc.invitations_enabled).map(gc => {
      const title = I18n.t("guests.table_guest.invitations_quota_mapping", { category: gc.name });
      return this.header(title, column, `header-${column.identifier}_${gc.id}`);
    });
  }

  collectionsQuotaHeaders(column) {
    const { guestProductCollections } = this.props;
    if (!guestProductCollections) return [];

    return guestProductCollections.map(collection => {
      const title = I18n.t("guests.table_guest.collections_quota", { name: collection.name });
      return this.header(title, column, `header-${column.identifier}_${collection._id}`);
    });
  }

  cells(columns, thematicsColumns, guest) {
    const { guestFields } = this.props;

    return columns.reduce((acc, col) => {
      let allowMultipleValues = false;
      if (col.type === COLUMN_QUARTILE_THEMATIC_TYPE || col.type === COLUMN_STANDARD_SCORE_THEMATIC_TYPE) {
        if (!thematicsColumns || !thematicsColumns[col.identifier]) return acc;
      } else {
        const guestField = guestFields.find(f => f.key == col.identifier) || {};
        allowMultipleValues = guestField.allow_multiple_values;
      }

      if (col.identifier === "invitations_quota_mapping") {
        acc.push(this.invitationsQuotaMappingCells(col, guest));
        return acc;
      }

      if (col.identifier === "collections_quota") {
        acc.push(this.collectionsQuotaCells(col, guest));
        return acc;
      }

      acc.push(this.cell(col, guest, allowMultipleValues));
      return acc;
    }, []);
  }

  cell(column, guest, allowMultipleValues, key = "", identifier = null) {
    const { event, labels, guestCategories, setFilteredGuestCategories, updateSearchBar } = this.props;

    return (
      <GuestCell
        key={key || `${guest.id}-${column.identifier}`}
        guest={guest} type={column.type}
        identifier={identifier || column.identifier}
        labels={labels}
        allowMultipleValues={allowMultipleValues}
        guestCategories={guestCategories}
        setFilteredGuestCategories={setFilteredGuestCategories}
        updateSearchBar={updateSearchBar}
        event={event}
      />
    );
  }

  invitationsQuotaMappingCells(column, guest) {
    const { guestCategories } = this.props;
    if (!guestCategories) return [];

    const res = guestCategories.filter(gc => gc.invitations_enabled).map(gc =>
      this.cell(column, guest, false, `${guest.id}-${column.identifier}_${gc.id}`, `${column.identifier}_${gc.id}`)
    );
    return res;
  }

  collectionsQuotaCells(column, guest) {
    const { guestProductCollections } = this.props;
    if (!guestProductCollections) return [];

    const res = guestProductCollections.map(collection =>
      this.cell(column, guest, false, `${guest.id}-${column.identifier}_${collection._id}`, `${column.identifier}_${collection._id}`)
    );
    return res;
  }

  rows(columns, thematicsColumns) {
    const { guests } = this.props;

    return guests.map((guest, index) => {
      return (
        <tr key={index}>
          { isAuthorized("guest", "mass_actions") && <td className="bulk-cell-react" style={{ maxWidth: "100px" }}>
            <input type="checkbox" className="form-check-input" checked={guest.checked || false} onChange={this._toggleGuest(guest._id)}/>
          </td> }
          { this.cells(columns, thematicsColumns, guest) }
        </tr>
      );
    });
  }

  render() {
    const { event, guests, guestsCount, unexpectedApiError } = this.props;

    if (!isEmpty(unexpectedApiError)) {
      return <div className="alert alert-danger">{ unexpectedApiError[0].message } </div>;
    }

    if (!event) {
      return <Loader />;
    }

    if (guestsCount === 0) {
      return this.renderNoGuest();
    }

    if (isEmpty(guests) || isEmpty(event)) {
      return <Loader />;
    }
    const columns = this.stripNonDisplayableColumns();
    const thematicsColumns = this.thematicsColumns(columns);

    const globalBulkCheckbox = isAuthorized("guest", "mass_actions") ? (<th className="bulk-cell-react d-none d-sm-table-cell">
      <input type="checkbox" className="form-check-input" checked={every(guests, guest => {return guest.checked;})} onChange={this._toggleGuest("all")}/>
    </th>) : null;

    return (
      <div className="table-responsive table-container">
        <table className="table table-light table-hover table-guest">
          <thead>
            <tr>
              { globalBulkCheckbox }
              { this.headers(thematicsColumns) }
            </tr>
          </thead>
          <tbody>
            { this.rows(columns, thematicsColumns) }
          </tbody>
        </table>
      </div>
    );
  }
}

GuestsTable.propTypes = {
  event: PropTypes.object.isRequired,
  guests: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.arrayOf(PropTypes.object).isRequired,
  guestsCount: PropTypes.number,
  guestCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleGuest: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  guestFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  documentTemplates: PropTypes.arrayOf(PropTypes.object),
  setFilteredGuestCategories: PropTypes.func.isRequired,
  updateSearchBar: PropTypes.func,
  thematics: PropTypes.arrayOf(PropTypes.object)
};

GuestsTable.defaultProps = {
  updateSearchBar: () => {}
};

export default GuestsTable;
