import { ActionTypes } from "../constants/Constants";

function uiAppearance(state = {}, action) {
  switch (action.type) {
  case ActionTypes.UPDATE_UI: {
    const { updates } = action;
    return Object.assign({}, state, updates);
  }
  default:
    return state;
  }
}


export default uiAppearance;
