import { FEATURES } from "../../constants/FeaturesSet";
import { featureName } from "../../utils/featureSetUtils";
import GroupFeatures from "./GroupFeatures.react";

const Groups: React.FC = () => {
  return <> {
    Object.keys(FEATURES).map(groupKey => {
      return <div key={ groupKey } className="mt-3">
        <h2 id={ groupKey }>{ featureName(groupKey) }</h2>
        <GroupFeatures groupKey={groupKey} />
      </div>;
    })
  }
  </>;
};

export default Groups;
