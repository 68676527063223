import { ActionTypes } from "../constants/Constants";

function formatDate(data) {
  return data.map(item => {
    const date = item._id;
    return { _id: `${date.year}-${date.month}-${date.day} ${date.hour}:00:00`, count: item.count };
  });
}

function accessControlCount(state = { total: null, since: null, between_since_and_before: null, per_hour: null }, action) {
  switch (action.type) {
  case ActionTypes.ACCESS_CONTROL_COUNT_TOTAL_SUCCESS:
    return Object.assign({}, state, { total: action.data.count });
  case ActionTypes.ACCESS_CONTROL_COUNT_SINCE_SUCCESS:
    return Object.assign({}, state, { since: action.data.count });
  case ActionTypes.ACCESS_CONTROL_COUNT_BETWEEN_SUCCESS:
    return Object.assign({}, state, { between_since_and_before: action.data.count });
  case ActionTypes.ACCESS_CONTROL_COUNT_PER_HOUR_SUCCESS:
    return Object.assign({}, state, { per_hour: formatDate(action.data.count_per_hour) });
  default:
    return state;
  }
}

export default accessControlCount;
