import { Component } from "react";
import { Modal } from "react-bootstrap";
import TargetEventSelector from "../TargetEventSelector.react";
import { Event } from "../../types/Event";

interface Props {
  isVisible: boolean;
  title?: string;
  onClose(): void;
  onEventSelected(eventId: string): any;
  fetchMoreEvents?: () => void;
  currentEvent?: Event;
  doNotDisplayCurrentEvent?: boolean;
  targetReadableName?: string;
  model?: string;
}

class TargetEventModal extends Component<Props> {
  static defaultProps = {
    isVisible: false
  };

  render(): JSX.Element {
    const { isVisible, onClose, targetReadableName, title } = this.props;
    const modalTitle = title || I18n.t("guest_categories.event_target_modal.title", { targetReadableName });

    return <Modal show={isVisible} onHide={onClose} size="lg">
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
        <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
      </Modal.Header>
      <Modal.Body id="choose-event">
        <TargetEventSelector {...this.props} />
      </Modal.Body>
    </Modal>;
  }
}

export default TargetEventModal;
