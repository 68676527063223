import { Component, MouseEvent } from "react";
import { connect } from "react-redux";
import truncate from "lodash/truncate";

import Loader from "../../components/shared/Loader.react";
import DashboardNavTab from "../../components/dashboard/DashboardNavTab.react";
import HalfCircleCounter from "../../components/shared/HalfCircleCounter.react";
import { isAuthorized } from "../../utils/aclUtils";
import { numberWithLimitedDecimals } from "../../utils/NumberUtils";

import { AccesspointsOccupancyState, AccesspointOccupancy } from "../../types/Accesspoint";
import { fetchAccesspointsOccupancy } from "../../actions/AccesspointsStatisticsActionCreators";

import { isEnabled } from "../../utils/featureSetUtils";
import { REAL_TIME_CAPACITY_MONITORING } from "../../constants/FeaturesSet";
import withDatabaseDefaultRegionRestriction from "../../components/shared/WithDatabaseDefaultRegionRestriction.react";

interface Props {
  match: any;
  accesspointsOccupancy: AccesspointsOccupancyState;
  fetchAccesspointsOccupancy: (eventId: string) => any;
}

const MAX_ACCESSPOINT_NAME_LENGTH = 50;

class OccupancyDashboard extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.refresh = this.refresh.bind(this);
  }

  componentDidMount(): void {
    this.refresh();
  }

  i18n(key: string, opts: any = {}): string {
    return I18n.t(`react.dashboard.occupancy_dashboard.${key}`, opts);
  }

  refresh(e?: MouseEvent<any>): void {
    if (e) e.preventDefault();

    const { match, fetchAccesspointsOccupancy } = this.props;
    fetchAccesspointsOccupancy(match.params.event_id);
  }

  colorForPercent(percent: number): ("green" | "orange" | "red") {
    if (percent > 0.7 && percent < 0.9) {
      return "orange";
    } else if (percent >= 0.9) {
      return "red";
    } else {
      return "green";
    }
  }

  renderOccupancyCard(occupancy: AccesspointOccupancy): JSX.Element {
    const percent = occupancy.occupancy / occupancy.max_occupancy;

    return (
      <div key={occupancy.accesspoint_name} className="col-sm-3 col-6">
        <div className="card">
          <div className="card-body">
            <div style={{ overflow: "hidden" }}>
              <span className="badge rounded-pill bg-secondary">
                {truncate(occupancy.accesspoint_name, { length: MAX_ACCESSPOINT_NAME_LENGTH })}
              </span>
            </div>
            <HalfCircleCounter
              percent={percent}
              count={occupancy.occupancy}
              foregroundColor={this.colorForPercent(percent)}
              subtitle={this.i18n("limited_to", { count: occupancy.max_occupancy })}
            />
            {occupancy.square_meters_per_person && (
              <div className="text-center">
                {numberWithLimitedDecimals(occupancy.square_meters_per_person, 2).toString().replace(".", ",")} m<sup>2</sup> {this.i18n("per_person")}</div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderContent(): JSX.Element {
    const { accesspointsOccupancy } = this.props;

    if (accesspointsOccupancy.fetchPending) {
      return <Loader />;
    }

    const { occupancies } = accesspointsOccupancy;

    if (occupancies.length === 0) {
      return (
        <div className="card nothing-yet">
          <h4>{this.i18n("no_data")}</h4>
        </div>
      );
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <p><a href="#" onClick={this.refresh}><i className="fa-regular fa-arrows-rotate"></i> {this.i18n("refresh")}</a></p>
        </div>
        {occupancies.map(occ => this.renderOccupancyCard(occ))}
      </div>
    );
  }

  render(): JSX.Element {
    if (!isAuthorized("reports", "read")) return;
    if (!isEnabled(REAL_TIME_CAPACITY_MONITORING)) return null;

    return (
      <div>
        <DashboardNavTab active="occupancy" />
        <div className="row mt-10">
          <div className="col-md-12">
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any): any {
  return {
    accesspointsOccupancy: state.accesspointsOccupancy
  };
}

const mapDispatchToProps = {
  fetchAccesspointsOccupancy
};

export default withDatabaseDefaultRegionRestriction(connect(mapStateToProps, mapDispatchToProps)(OccupancyDashboard));
