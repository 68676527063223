import { ActionTypes } from "../constants/Constants";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";

const initialState = {
  assetsFolders: null,
  assetsFolderSelected: null,
  assets: null,
  errors: null,
  isFetching: false
};

const assetsManager = (state = initialState, action) => {
  switch (action.type) {
  case ActionTypes.FETCH_ASSETS_FOLDERS_REQUEST:
    return Object.assign({}, state, { errors: null, assetsFolders: null, isFetching: true });
  case ActionTypes.FETCH_ASSETS_FOLDERS_SUCCESS:
    return Object.assign({}, state, { errors: null, assetsFolders: action.data, isFetching: false });
  case ActionTypes.FETCH_ASSETS_FOLDERS_FAILURE:
    return Object.assign({}, state, { errors: action.data, assetsFolders: null, isFetching: false });

  case ActionTypes.FETCH_ASSETS_FOLDER_REQUEST:
    return Object.assign({}, state, { errors: null, assetsFolderSelected: null });
  case ActionTypes.FETCH_ASSETS_FOLDER_SUCCESS:
    return Object.assign({}, state, { errors: null, assetsFolderSelected: action.data });
  case ActionTypes.FETCH_ASSETS_FOLDER_FAILURE:
    return Object.assign({}, state, { errors: action.data, assetsFolderSelected: null });

  case ActionTypes.CREATE_ASSETS_FOLDER_REQUEST:
    return Object.assign({}, state, { errors: null, assetsFolderSelected: null });
  case ActionTypes.CREATE_ASSETS_FOLDER_SUCCESS: {
    const assetsFoldersUpdated = state.assetsFolders ? insertOrUpdateObject(state.assetsFolders, action.data, "_id", false) : [action.data];
    return Object.assign({}, state, { assetsFolderSelected: action.data, assetsFolders: assetsFoldersUpdated, errors: {}, assets: null });
  }
  case ActionTypes.CREATE_ASSETS_FOLDER_FAILURE:
    return Object.assign({}, state, { errors: action.data, assetsFolderSelected: null, assetsFiles: null });

  case ActionTypes.FETCH_ASSETS_REQUEST:
    return Object.assign({}, state, { errors: null, assets: null });
  case ActionTypes.FETCH_ASSETS_SUCCESS:
    return Object.assign({}, state, { errors: null, assets: action.data });
  case ActionTypes.FETCH_ASSETS_FAILURE:
    return Object.assign({}, state, { errors: action.data, assets: null });

  case ActionTypes.CREATE_ASSET_REQUEST:
    return Object.assign({}, state, { errors: null });
  case ActionTypes.CREATE_ASSET_SUCCESS: {
    const assetsUpdated = state.assets ? insertOrUpdateObject(state.assets, action.data, "_id", false) : [action.data];
    return Object.assign({}, state, { assets: assetsUpdated, errors: {} });
  }
  case ActionTypes.CREATE_ASSET_FAILURE:
    return Object.assign({}, state, { errors: action.data });

  case ActionTypes.UPDATE_ASSET_REQUEST:
    return Object.assign({}, state, { errors: null });
  case ActionTypes.UPDATE_ASSET_SUCCESS: {
    const assetsUpdated = state.assets ? insertOrUpdateObject(state.assets, action.data, "_id", false) : [action.data];
    return Object.assign({}, state, { assets: assetsUpdated, errors: {} });
  }
  case ActionTypes.UPDATE_ASSET_FAILURE:
    return Object.assign({}, state, { errors: action.data });

  case ActionTypes.DELETE_ASSET_REQUEST:
    return Object.assign({}, state, { errors: null });
  case ActionTypes.DELETE_ASSET_SUCCESS: {
    const assetsUpdated = state.assets.filter(file => file._id !== action.assetId);
    return Object.assign({}, state, { assets: assetsUpdated, errors: {} });
  }
  case ActionTypes.DELETE_ASSET_FAILURE:
    return Object.assign({}, state, { errors: action.data });

  default:
    return state;
  }
};

export default assetsManager;