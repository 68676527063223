import { Component } from "react";
import PropTypes from "prop-types";
import ImageCell from "./ImageCell.react";
import { pathToGuestShow, pathToGuestEdit } from "../../utils/pathUtils";

class IdentityCell extends Component {

  renderLabels() {
    const { labels, guest } = this.props;
    if (!labels) {
      return;
    }

    const guestLabels = labels.filter(label => guest.label_ids && guest.label_ids.includes(label._id));
    return guestLabels.map(label => {
      return (
        <span key={label._id} className="badge rounded-pill float-start mr-5" style={{ backgroundColor: label.color }}>
          { label.name }
        </span>
      );
    });
  }

  render() {
    const { guest } = this.props;

    if (guest.takeout) return <td>{guest.uid}</td>;

    return (
      <td className="cell-guest guest">
        <span className="identity">
          <a className="text-default fw-bold" href={pathToGuestShow(guest.id)}>
            <ImageCell guest={guest} field="avatar_thumb" />
            {guest.identity}
          </a>
        </span> { guest.is_online && <i className="fa-solid fa-circle" style={{ color: "#65b343" }}></i> }

        <a className="edit d-none d-sm-inline" href={pathToGuestEdit(guest)}>
          <i className="fa-regular fa-pencil"></i>
        </a>
        {guest.rsvp_status === "unsure" ? (<span className="badge rounded-pill bg-warning">{I18n.t("unsure")}</span>) : ""}
        <div className="mt-5">{ this.renderLabels() }</div>
      </td>
    );
  }
}

IdentityCell.propTypes = {
  guest: PropTypes.object.isRequired,
  labels: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default IdentityCell;
