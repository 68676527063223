import { ActionTypes, CALL_API } from "../constants/Constants";
import { removeSchemeAndHost } from "../utils/pathUtils";

function fetchActionWithEndpoint(endpoint) {
  const { GUEST_CAMPAIGNS_SUCCESS, GUEST_CAMPAIGNS_FAILURE, DEV_NULL } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, GUEST_CAMPAIGNS_SUCCESS, GUEST_CAMPAIGNS_FAILURE],
      method: "GET",
      endpoint
    }
  };
}

export function fetchGuestCampaigns(eventId, page = 1, per_page = 20, sortBy = "created_at", sortDirection = "desc") {
  return fetchActionWithEndpoint(`/api/v1/events/${eventId}/guest_campaigns.json?page=${page}&per_page=${per_page}&sort_by=${sortBy}&sort_direction=${sortDirection}`);
}

export function fetchGuestCampaignsPage(pageURL) {
  return fetchActionWithEndpoint(removeSchemeAndHost(pageURL));
}

export function replicateGuestCampaign(eventId, guestCampaignId, redirectTo) {
  const { DEV_NULL, GUEST_CAMPAIGN_DUPLICATE_SUCCESS, GUEST_CAMPAIGN_DUPLICATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, GUEST_CAMPAIGN_DUPLICATE_SUCCESS, GUEST_CAMPAIGN_DUPLICATE_FAILURE],
      method: "POST",
      body: { fake: "fake" },
      endpoint: `/api/v1/events/${eventId}/guest_campaigns/${guestCampaignId}/replicate.json`,
      redirectTo
    }
  };
}
