import { Component } from "react";

class TextFieldType extends Component {
  render() {
    const { defaultValue } = this.props;
    return (
      <div className="mb-3 row">
        <div className="col-sm-7">
          <input type="text" className="form-control form-control-sm" value={ defaultValue } placeholder={ I18n.t("react.form_text_field.answer") } readOnly/>
        </div>
      </div>
    );
  }
}

export default TextFieldType;
