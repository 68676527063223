import { ActionTypes, CALL_API } from "../constants/Constants";
import { WorkflowStepType, WorkflowStepStrategy } from "../types/Workflow";

export function fetchWorkflows(eventId: string): any {
  const { WORKFLOWS_REQUEST, WORKFLOWS_SUCCESS, WORKFLOWS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [WORKFLOWS_REQUEST, WORKFLOWS_SUCCESS, WORKFLOWS_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/automation/workflows.json`
    }
  };
}

export function fetchWorkflow(eventId: string, workflowId: string): any {
  const { FETCH_WORKFLOW_REQUEST, FETCH_WORKFLOW_SUCCESS, FETCH_WORKFLOW_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_WORKFLOW_REQUEST, FETCH_WORKFLOW_SUCCESS, FETCH_WORKFLOW_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/automation/workflows/${workflowId}.json?builder=true`
    }
  };
}

export function fetchWorkflowVersions(eventId: string, workflowId: string): any {
  const { FETCH_WORKFLOW_VERSIONS_REQUEST, FETCH_WORKFLOW_VERSIONS_SUCCESS, DEV_NULL } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_WORKFLOW_VERSIONS_REQUEST, FETCH_WORKFLOW_VERSIONS_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/automation/workflows/${workflowId}/workflow_versions.json`
    }
  };
}

export function fetchWorkflowVersion(eventId: string, versionId: string): any {
  const { FETCH_WORKFLOW_VERSION_REQUEST, FETCH_WORKFLOW_VERSION_SUCCESS, FETCH_WORKFLOW_VERSION_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_WORKFLOW_VERSION_REQUEST, FETCH_WORKFLOW_VERSION_SUCCESS, FETCH_WORKFLOW_VERSION_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/automation/workflow_versions/${versionId}.json?builder=true`
    }
  };
}

export function fetchWorkflowStrategy(eventId: string, type: WorkflowStepType, strategy: WorkflowStepStrategy): any {
  const { FETCH_WORKFLOW_STRATEGY_REQUEST, FETCH_WORKFLOW_STRATEGY_SUCCESS, FETCH_WORKFLOW_STRATEGY_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_WORKFLOW_STRATEGY_REQUEST, FETCH_WORKFLOW_STRATEGY_SUCCESS, FETCH_WORKFLOW_STRATEGY_FAILURE],
      method: "GET",
      body: { type, strategy },
      endpoint: `/api/v1/events/${eventId}/automation/workflows/strategy.json`
    },
    strategy
  };
}

export function createWorkflow(eventId: string, workflowAttributes: any, notificationsOptions?: any): any {
  const { CREATE_WORKFLOW_PENDING, CREATE_WORKFLOW_SUCCESS, CREATE_WORKFLOW_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_WORKFLOW_PENDING, CREATE_WORKFLOW_SUCCESS, CREATE_WORKFLOW_FAILURE],
      method: "POST",
      body: { workflow: workflowAttributes },
      endpoint: `/api/v1/events/${eventId}/automation/workflows.json`,
      notificationsOptions
    }
  };
}

export function createWorkflowVersion(eventId: string, workflowId: string, notificationsOptions?: any): any {
  const { CREATE_WORKFLOW_VERSION_PENDING, CREATE_WORKFLOW_VERSION_SUCCESS, CREATE_WORKFLOW_VERSION_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_WORKFLOW_VERSION_PENDING, CREATE_WORKFLOW_VERSION_SUCCESS, CREATE_WORKFLOW_VERSION_FAILURE],
      method: "POST",
      body: {},
      endpoint: `/api/v1/events/${eventId}/automation/workflows/${workflowId}/workflow_versions.json?builder=true`,
      notificationsOptions
    }
  };
}

export function updateWorkflowVersion(eventId: string, versionId: string, workflowVersionAttributes: any, notificationsOptions?: any): any {
  const { UPDATE_WORKFLOW_VERSION_PENDING, UPDATE_WORKFLOW_VERSION_SUCCESS, UPDATE_WORKFLOW_VERSION_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_WORKFLOW_VERSION_PENDING, UPDATE_WORKFLOW_VERSION_SUCCESS, UPDATE_WORKFLOW_VERSION_FAILURE],
      method: "PUT",
      body: { workflow_version: workflowVersionAttributes },
      endpoint: `/api/v1/events/${eventId}/automation/workflow_versions/${versionId}.json`,
      notificationsOptions
    },
    workflowVersionAttributes
  };
}

export function publishWorkflowVersion(eventId: string, versionId: string, workflowVersionAttributes: any, notificationsOptions?: any): any {
  const { PUBLISH_WORKFLOW_VERSION_PENDING, PUBLISH_WORKFLOW_VERSION_SUCCESS, PUBLISH_WORKFLOW_VERSION_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [PUBLISH_WORKFLOW_VERSION_PENDING, PUBLISH_WORKFLOW_VERSION_SUCCESS, PUBLISH_WORKFLOW_VERSION_FAILURE],
      method: "PUT",
      body: { workflow_version: workflowVersionAttributes },
      endpoint: `/api/v1/events/${eventId}/automation/workflow_versions/${versionId}/publish.json`,
      notificationsOptions
    }
  };
}

export function updateWorkflow(eventId: string, workflowId: string, workflowAttributes: any, notificationsOptions?: any): any {
  const { UPDATE_WORKFLOW_PENDING, UPDATE_WORKFLOW_SUCCESS, UPDATE_WORKFLOW_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_WORKFLOW_PENDING, UPDATE_WORKFLOW_SUCCESS, UPDATE_WORKFLOW_FAILURE],
      method: "PUT",
      body: { workflow: workflowAttributes },
      endpoint: `/api/v1/events/${eventId}/automation/workflows/${workflowId}.json?builder=true`,
      notificationsOptions
    },
    workflowAttributes
  };
}

export function deleteWorkflow(eventId: string, workflowId: string, notificationsOptions?: any): any {
  const { DELETE_WORKFLOW_PENDING, DELETE_WORKFLOW_SUCCESS, DELETE_WORKFLOW_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DELETE_WORKFLOW_PENDING, DELETE_WORKFLOW_SUCCESS, DELETE_WORKFLOW_FAILURE],
      method: "DELETE",
      endpoint: `/api/v1/events/${eventId}/automation/workflows/${workflowId}.json`,
      notificationsOptions
    },
    workflowId
  };
}
