import { Component } from "react";

import GuestCategoryLabel from "../guest_category/GuestCategoryLabel.react";
import { pathToGuestCategoryShow, pathToTicketingEdit } from "../../utils/pathUtils";

class GuestCategoriesAndTicketsLinked extends Component {
  guestCategoryTooltipLabel(category) {
    const { guestCategories } = this.props;

    if (category.type == "ticket") {
      const ticketingCategory = guestCategories.find((ticketingCategory) => ticketingCategory._id == category.ticketing_category_id);
      return `${category.name} - ${ticketingCategory.name}`;
    }
    return category.name;
  }

  pathToGuestCategory(category) {
    if (category.type == "ticket") {
      return pathToTicketingEdit(category.ticketing_category_id);
    } else if (category.type == "ticketing") {
      return pathToTicketingEdit(category._id);
    }
    return pathToGuestCategoryShow(category._id);
  }

  isObjectLinkedToCategory(category) {
    const { form, document } = this.props;

    if (form) {
      return category.registration_form_id === form._id;
    }

    if (document) {
      return (category.document_template_ids || []).includes(document._id);
    }

    return false;
  }

  render() {
    const { guestCategories, labelLinkable } = this.props;

    const linkedGuestCategories = guestCategories.reduce((array, category, idx) => {
      if (this.isObjectLinkedToCategory(category)) {
        array.push(
          <GuestCategoryLabel
            key={idx}
            isLink={labelLinkable}
            link={this.pathToGuestCategory(category)}
            truncateLenght={45}
            guestCategory={category}
            tooltip={this.guestCategoryTooltipLabel(category)}
            cssClasses="mr-5"
          />
        );
      }
      return array;
    }, []);

    if (linkedGuestCategories.length < 1) {
      linkedGuestCategories.push(<i key="none" className="fa-regular fa-ban"></i>);
    }

    return <>{linkedGuestCategories}</>;
  }
}

GuestCategoriesAndTicketsLinked.defaultProps = {
  labelLinkable: true
};


export default GuestCategoriesAndTicketsLinked;
