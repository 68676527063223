import { CALL_API, ActionTypes } from "../constants/Constants";
import { GuestProductCollection } from "../types/GuestProductCollection";

export function updateGuestProductCollection(eventId: string, guestProductCollection: GuestProductCollection, guestProductCollectionParams: any): any {
  const { UPDATE_GUEST_PRODUCT_COLLECTION_REQUEST, UPDATE_GUEST_PRODUCT_COLLECTION_SUCCESS, UPDATE_GUEST_PRODUCT_COLLECTION_ERROR } = ActionTypes;

  return {
    [CALL_API]: {
      types: [UPDATE_GUEST_PRODUCT_COLLECTION_REQUEST, UPDATE_GUEST_PRODUCT_COLLECTION_SUCCESS, UPDATE_GUEST_PRODUCT_COLLECTION_ERROR],
      method: "PUT",
      endpoint: `/api/v1/events/${eventId}/guest_product_collections/${guestProductCollection._id}.json`,
      body: guestProductCollectionParams,
    },
    currentGuestProductCollection: guestProductCollection
  };
}

export function createGuestProductCollection(eventId: string, guestProductCollectionParams: any): any {
  const { CREATE_GUEST_PRODUCT_COLLECTION_REQUEST, CREATE_GUEST_PRODUCT_COLLECTION_SUCCESS, CREATE_GUEST_PRODUCT_COLLECTION_ERROR } = ActionTypes;

  return {
    [CALL_API]: {
      types: [CREATE_GUEST_PRODUCT_COLLECTION_REQUEST, CREATE_GUEST_PRODUCT_COLLECTION_SUCCESS, CREATE_GUEST_PRODUCT_COLLECTION_ERROR],
      method: "POST",
      endpoint: `/api/v1/events/${eventId}/guest_product_collections.json`,
      body: guestProductCollectionParams,
    }
  };
}

export function deleteGuestProductCollection(eventId: string, guestProductCollectionId: string): any {
  const { DEV_NULL, DELETE_GUEST_PRODUCT_COLLECTION_SUCCESS, DELETE_GUEST_PRODUCT_COLLECTION_ERROR } = ActionTypes;

  return {
    [CALL_API]: {
      types: [DEV_NULL, DELETE_GUEST_PRODUCT_COLLECTION_SUCCESS, DELETE_GUEST_PRODUCT_COLLECTION_ERROR],
      method: "DELETE",
      endpoint: `/api/v1/events/${eventId}/guest_product_collections/${guestProductCollectionId}.json`,
    },
    guestProductCollectionId
  };
}

export function fetchItemsCollectionCount(eventId: string, guestProductCollectionId: string): any {
  const { FETCH_ITEMS_COLLECTION_COUNT_SUCCESS, DEV_NULL } = ActionTypes;

  return {
    [CALL_API]: {
      types: [DEV_NULL, FETCH_ITEMS_COLLECTION_COUNT_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guest_product_collections/${guestProductCollectionId}/guest_products_count.json`,
    }
  };
}

export function exportItemsCollection(eventId: string, guestProductCollectionId: string): any {
  const { EXPORT_ITEMS_COLLECTION_SUCCESS, EXPORT_ITEMS_COLLECTION_ERROR, EXPORT_ITEMS_COLLECTION_REQUEST } = ActionTypes;

  return {
    [CALL_API]: {
      types: [EXPORT_ITEMS_COLLECTION_REQUEST, EXPORT_ITEMS_COLLECTION_SUCCESS, EXPORT_ITEMS_COLLECTION_ERROR],
      method: "POST",
      endpoint: `/api/v1/events/${eventId}/guest_product_collections/${guestProductCollectionId}/export`,
      body: {}
    }
  };
}

export function clearGuestProductCollectionErrors(): any {
  return {
    type: ActionTypes.RESET_GUEST_PRODUCT_COLLECTION_STATE
  };
}
