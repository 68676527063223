import { Component } from "react";
import ReactS3Uploader from "react-s3-uploader";
import { ProgressBar } from "react-bootstrap";
import { defaultModalSmallStyles } from "../../constants/Style";
import Modal from "react-modal";
import validFileFormat from "../../utils/validFileFormat";

interface Props {
  acceptedFiles: string[];
  classNameUploader: string;
  classNameWrapper?: string;
  disabled?: boolean;
  idUploader?: string;
  onFinishUpload(signedUrl: string): void;
  publicRead?: boolean;
  s3UploadURL: string;
  signingUrlQueryParams?: any;
  styleUploader?: any;
}

interface State {
  uploadModalIsDisplayed: boolean;
  isUploading: boolean;
  uploadingFiles: any;
}

class GenericS3Uploader extends Component<Props, State> {
  public static defaultProps = {
    publicRead: true
  };
  private inputFileRef: HTMLInputElement;

  constructor(props: Props) {
    super(props);

    [
      "closeUploadingModal",
      "onFinishUpload",
      "onUploadProgress",
      "onUploadStart"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      uploadModalIsDisplayed: false,
      isUploading: false,
      uploadingFiles: {}
    };
  }

  onUploadStart(file: File, next: any): void {
    const { uploadingFiles } = this.state;
    const { acceptedFiles } = this.props;
    uploadingFiles[file.name] = 0;

    if (!validFileFormat(file, acceptedFiles)) {
      delete uploadingFiles[file.name];
      return;
    }

    this.setState({
      uploadModalIsDisplayed: true,
      isUploading: true,
      uploadingFiles
    });

    next(file);
  }

  onUploadProgress(percent: number, _message: string, file: any): void {
    const { uploadingFiles } = this.state;

    if (percent !== uploadingFiles[file.name]) {
      uploadingFiles[file.name] = percent;
      this.setState({ uploadingFiles });
    }
  }

  getFileName(imageUrl: string): string {
    const fileName = imageUrl.split("/").pop();
    const extension = fileName.split(".").pop();
    const tmp = fileName.split("_");
    tmp.pop();
    return `${tmp.join("_")}.${extension}`;
  }

  onFinishUpload(result: any): void {
    const { uploadingFiles } = this.state;
    const { onFinishUpload } = this.props;

    onFinishUpload(result.signedUrl);

    if (Object.values(uploadingFiles).find(value => value !== 100) === undefined) {
      this.setState({ isUploading: false });
    }
    this.clearInputFile();
  }

  clearInputFile(): void {
    this.inputFileRef.value = "";
  }

  renderReactS3Uploader(): JSX.Element {
    const { isUploading } = this.state;
    const { disabled, s3UploadURL, acceptedFiles, publicRead, signingUrlQueryParams } = this.props;
    const optHeaders = { "Access-Control-Allow-Origin": "*" };
    if (publicRead) {
      optHeaders["x-amz-acl"] = "public-read";
    }

    return (
      <ReactS3Uploader
        signingUrl={ s3UploadURL }
        signingUrlQueryParams={ signingUrlQueryParams || {} }
        onProgress={ this.onUploadProgress }
        onFinish={ this.onFinishUpload }
        accept={ acceptedFiles.join(",") }
        uploadRequestHeaders={ optHeaders }
        disabled={ isUploading || disabled }
        multiple
        preprocess={ this.onUploadStart }
        inputRef={(ref): HTMLInputElement => this.inputFileRef = ref}
      />
    );
  }

  closeUploadingModal(): void {
    this.setState({ uploadModalIsDisplayed: false, uploadingFiles: {} });
  }

  renderProgressBar(percent: number): JSX.Element {
    if (percent === 100) return null;
    return (
      <ProgressBar
        striped={ true }
        now={ percent }
        animated={ true }
        label={ `${percent}%` }
      />
    );
  }

  renderCurrentFile(name: string, percent: number): JSX.Element {
    let squareClass = "share-from-square";
    if (percent === 0) {
      squareClass = "square";
    } else if (percent === 100) {
      squareClass = "square-check";
    }
    return (
      <span>
        <i key={ name } className={ `fa-regular fa-${squareClass} mr-5` }></i>
        { name }
      </span>
    );
  }

  renderUploadProgressBars(): JSX.Element {
    const { uploadingFiles } = this.state;
    let progressPercent = 0;

    return (
      <div>
        {Object.keys(uploadingFiles)
          .sort((a, b) => {
            const x = a.toLowerCase();
            const y = b.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          })
          .map((key, index) => {
            progressPercent = uploadingFiles[key];
            return (
              <div key={ index }>
                { this.renderCurrentFile(key, progressPercent) }
                { this.renderProgressBar(progressPercent) }
              </div>
            );
          })
        }
      </div>
    );
  }

  renderUploadFinished(): JSX.Element {
    const { uploadingFiles } = this.state;
    const counterLabel = I18n.t("front_office.react.streaming_archives_manager.modal_uploading.files_counter", { count: Object.keys(uploadingFiles).length });
    return (
      <div style={{ textAlign: "center" }}>
        <div className="mb-10">
          <i className={ "fa-regular fa-check fa-4x text-success" } />
        </div>
        <b>
          { counterLabel }
        </b>
      </div>
    );
  }

  renderUploadingModal(): JSX.Element {
    const { uploadModalIsDisplayed, isUploading } = this.state;

    return (
      <Modal isOpen={ uploadModalIsDisplayed } style={ defaultModalSmallStyles } contentLabel="Modal">
        <div className="modal-header">
          <h4 className="modal-title">
            { I18n.t("front_office.react.streaming_archives_manager.modal_uploading.title") }
          </h4>
        </div>
        <div className="modal-body">
          { isUploading ? this.renderUploadProgressBars() : this.renderUploadFinished() }
        </div>
        <div className="modal-footer" style={{ textAlign: "center" }}>
          { !isUploading && <button className="btn btn-primary" onClick={ this.closeUploadingModal }>
            { I18n.t("front_office.react.streaming_archives_manager.modal_uploading.ok_button") }
          </button> }
        </div>
      </Modal>
    );
  }

  renderUploader(): JSX.Element {
    const { disabled, classNameUploader, idUploader, styleUploader, children } = this.props;
    return (
      <div
        id={ idUploader }
        className={ `${classNameUploader} ${disabled ? "disabled" : ""}` }
        style={ styleUploader }
      >
        { this.renderReactS3Uploader() }
        { children }
      </div>
    );
  }

  render(): JSX.Element {
    const { classNameWrapper } = this.props;
    return (
      <div className={ classNameWrapper }>
        { this.renderUploader() }
        { this.renderUploadingModal() }
      </div>
    );
  }
}

export default GenericS3Uploader;
