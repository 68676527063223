import { Component } from "react";
import PropTypes from "prop-types";
import { Nav, NavItem } from "react-bootstrap";

class NavTab extends Component {

  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(e) {
    const { onChange } = this.props;
    onChange(e);
  }

  render() {
    const { items, selected } = this.props;
    const navItems = items.map(item => {
      return (<NavItem eventKey={item} key={item}>{I18n.t("react.guest_campaign.navTab." + item)} </NavItem>);
    });
    return (
      <Nav variant="tabs" activeKey={selected} onSelect={this.handleSelect}>
        {navItems}
      </Nav>
    );
  }
}

export default NavTab;

NavTab.propTypes = {
  items: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
