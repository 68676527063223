import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import SlidingPane from "react-sliding-pane";
import { useParams } from "react-router-dom";
import Icons from "../../constants/Icons";
import BlogArticleBuilder from "../../containers/blog_articles/BlogArticleBuilder.react";
import { clearBlogArticle } from "../../actions/BlogArticleActionCreators";
import { clearBuilder } from "../../actions/CommonBuilderActionCreators";
import { BLOG } from "../../constants/FeaturesSet";
import { isEnabled } from "../../utils/featureSetUtils";
import { pathToBlogArticlesList } from "../../utils/pathUtils";

interface Props {
  match: any;
  location: any;
  history: any;
}

const BlogArticleBuilderSlidingPane: React.FC<Props> = ({
  match,
  location,
  history,
}) => {
  if (!isEnabled(BLOG)) return null;

  const { article_id: blogArticleId } = useParams();
  const isEdit = !!blogArticleId;
  const [isBuilderOpened, setIsBuilderOpened] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isBuilderOpened) return;

    // to avoid warnings linked to asynchronous redux actions
    setTimeout(() => {
      history.push({ ...location, pathname: pathToBlogArticlesList() });
    }, 1000);
  }, [isBuilderOpened]);

  const disableBodyScroll = (): void => {
    document.body.style.overflow = "hidden";
  };

  const enableBodyScroll = (): void => {
    document.body.removeAttribute("style");
  };

  const closeSlidingPane = (): void => {
    enableBodyScroll();
    dispatch(clearBlogArticle());
    dispatch(clearBuilder());
    setIsBuilderOpened(false);
  };

  const builderProps = {
    slidingPane: true,
    mode: isEdit ? "edit" : "new",
    blogArticleId,
    match,
    location,
    history
  };

  return <SlidingPane
    isOpen={isBuilderOpened}
    width={isEdit ? "90%" : "35%"}
    title={I18n.t("react.blog_articles.blog_articles_list.sliding_pane_title")}
    onAfterOpen={disableBodyScroll}
    onRequestClose={closeSlidingPane}
    className="width-100-xs width-100-sm width-transition"
    closeIcon={Icons.close()}
  >
    <BlogArticleBuilder {...builderProps} />
  </SlidingPane>;
};

export default BlogArticleBuilderSlidingPane;
