import { HasMeetingAvailabilities } from "./HasMeetingAvailabilities";
import { MeetingLocation } from "./MeetingLocation";
import { EmailTemplate } from "./EmailTemplate";
import { DocumentTemplate } from "./DocumentTemplate";

export interface Event extends HasMeetingAvailabilities {
  _id: string;
  id: string;
  account_id: string;
  title: string;
  available_frontend_locales?: string[];
  engagement_max: any;
  engagement_avg: any;
  engagement_std_dev: any;
  fetched: boolean;
  start_date: string;
  end_date: string;
  photo: any;
  population_types: string[];
  video_call_enabled: boolean;
  seatsio_api_key: string;
  seatsio_public_workspace_key: string;
  seatsio_secret_workspace_key: string;
  scoring_and_engagement_enabled: boolean;
  published: boolean;
  networking_messaging_enabled: boolean;
  networking_meetings_enabled: boolean;
  networking_meetings_type: string;
  networking_rules: any;
  guest_view_rules: any;
  meeting_sms_notification_enabled: boolean;
  meeting_email_notification_enabled: boolean;
  networking_meetings_imported_by_organizer: boolean;
  reply_to_email: string;
  organizer: string;
  mobinetwork_contacts_export_email_body_default_url: string;
  extract_guest_target_events: any;
  guest_categories?: any[];
  guestCategories?: any[];
  strict_networking_rules_enabled: boolean;
  meeting_locations: MeetingLocation[];
  all_sender_email_addresses?: string[];
  sender_email_address?: string;
  has_payment?: boolean;
  account_contacts_enabled?: boolean;
  meeting_default_slot_duration: number;
  meeting_simultaneous_slots_enabled: boolean;
  locale?:string;
  emailTemplates: EmailTemplate[];
  documentTemplates: DocumentTemplate[];
  awaiting_meeting_requests_limit: number;
}

export function fieldI18n(key: string): string {
  return I18n.t(`mongoid.attributes.event.${key}`);
}

export interface EventStoreProps extends Event {
  fetched: boolean;
  pendingRequest: boolean;
  errors?: any;
}
