import { ActionTypes, CALL_API } from "../constants/Constants";

export function createCustomEmail(eventId, body): any {
  const { CREATE_CUSTOM_EMAIL_REQUEST, CREATE_CUSTOM_EMAIL_SUCCESS, CREATE_CUSTOM_EMAIL_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_CUSTOM_EMAIL_REQUEST, CREATE_CUSTOM_EMAIL_SUCCESS, CREATE_CUSTOM_EMAIL_FAILURE],
      method: "POST",
      endpoint: `/api/v1/events/${eventId}/custom_emails.json`,
      body
    }
  };
}

export function updateCustomEmail(eventId, customEmailId, body): any {
  const { UPDATE_CUSTOM_EMAIL_REQUEST, UPDATE_CUSTOM_EMAIL_SUCCESS, UPDATE_CUSTOM_EMAIL_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_CUSTOM_EMAIL_REQUEST, UPDATE_CUSTOM_EMAIL_SUCCESS, UPDATE_CUSTOM_EMAIL_FAILURE],
      method: "PUT",
      endpoint: `/api/v1/events/${eventId}/custom_emails/${customEmailId}.json`,
      body
    }
  };
}

export function clearCustomEmail(): any {
  const { CLEAR_CUSTOM_EMAIL_STATE } = ActionTypes;
  return { type: CLEAR_CUSTOM_EMAIL_STATE };
}
