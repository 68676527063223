import { ActionTypes } from "../constants/Constants";

const initialState = {
  data: null,
  errors: null,
  delivered: false,
  creating: false,
  delivering: false
};

function directEmail(state = initialState, action) {
  switch (action.type) {
  case ActionTypes.CREATE_DIRECT_EMAIL_REQUEST:
    return Object.assign({}, state, { creating: true });
  case ActionTypes.CREATE_DIRECT_EMAIL_SUCCESS:
    return Object.assign({}, state, { data: action.data, errors: null, creating: false });
  case ActionTypes.DELIVER_DIRECT_EMAIL_REQUEST:
    return Object.assign({}, state, { delivering: true });
  case ActionTypes.DELIVER_DIRECT_EMAIL_SUCCESS:
    return Object.assign({}, state, { data: action.data, errors: null, delivered: true });
  case ActionTypes.DELIVER_BATCH_DIRECT_EMAIL_SUCCESS:
    return initialState;
  case ActionTypes.CREATE_DIRECT_EMAIL_FAILURE:
  case ActionTypes.DELIVER_DIRECT_EMAIL_FAILURE:
  case ActionTypes.DELIVER_BATCH_DIRECT_EMAIL_FAILURE:
    return Object.assign({}, state, { errors: action.data, creating: false, delivering: false, delivered: false });
  case ActionTypes.CLEAR_DIRECT_EMAIL_STATE:
    return initialState;
  default:
    return state;
  }
}

export default directEmail;
