import { Component } from "react";
import SlidingPane from "react-sliding-pane";
import { connect } from "react-redux";
import { fetchStreamingArchives, createStreamingArchive, deleteStreamingArchive, updateStreamingArchive } from "../actions/StreamingArchivesManagerActionCreator";
import Icons from "../constants/Icons";
import { urlEventId } from "../utils/pathUtils";
import StreamingArchivesForm from "../components/streaming_archives_manager/StreamingArchivesForm.react";
import Loader from "../components/shared/Loader.react";
import { StreamingArchive, StreamingArchivesState } from "../types/StreamingArchive";
import { Accesspoint } from "../types/Accesspoint";

interface Props extends StreamingArchivesState {
  accesspointId: string;
  accesspoint: Accesspoint;
  createStreamingArchive(eventId: string, accesspointId: string, params: any): any;
  deleteStreamingArchive(eventId: string, accesspointId: string, streamingArchiveId: string): any;
  fetchStreamingArchives(eventId: string, accesspointId: string): any;
  isOpen: boolean;
  onClose(): void;
  updateStreamingArchive(eventId: string, accesspointId: string, streamingArchiveId: string, params: StreamingArchive): any;
}

class StreamingArchivesManagerPane extends Component<Props> {

  componentDidUpdate(previousProps: any): void {
    const { fetchStreamingArchives, accesspointId, isOpen } = this.props;
    if (!previousProps.isOpen && isOpen && accesspointId && accesspointId !== "") {
      fetchStreamingArchives(urlEventId(), accesspointId);
    }
  }

  renderStreamingArchivesForm(): JSX.Element {
    const { onClose, accesspoint, accesspointId, isFetching, streamingArchives, deleteStreamingArchive, updateStreamingArchive, createStreamingArchive } = this.props;
    if (!streamingArchives) return <div></div>;

    return (
      <StreamingArchivesForm
        accesspointId={ accesspointId }
        accesspoint={ accesspoint }
        createStreamingArchive={ createStreamingArchive }
        deleteStreamingArchive={ deleteStreamingArchive }
        isFetching={ isFetching }
        onClose={ onClose }
        streamingArchives={ streamingArchives }
        updateStreamingArchive={ updateStreamingArchive }
      />
    );
  }

  render(): JSX.Element {
    const { accesspointId, isFetching, isOpen, onClose, streamingArchives } = this.props;
    if (accesspointId === "") {
      return null;
    }

    const numberOfArchives = streamingArchives ? streamingArchives.length : 0;
    return (
      <SlidingPane
        className="width-100-sm width-60-md"
        closeIcon={ Icons.close() }
        from="right"
        isOpen={ isOpen }
        onRequestClose={ onClose }
        subtitle={ I18n.t("front_office.react.streaming_archives_manager.sliding_pane.files_counter", { count: numberOfArchives }) }
        title={ I18n.t("front_office.react.streaming_archives_manager.sliding_pane.title") }
      >
        { !streamingArchives && isFetching ? <Loader size="large" inline={false} /> : this.renderStreamingArchivesForm()}
      </SlidingPane>
    );
  }
}

const mapStateToProps = (state: any): any => {
  const { streamingArchives, errors, isFetching } = state.streamingArchives;
  return {
    errors,
    isFetching,
    streamingArchives
  };
};

const mapDispatchToProps = {
  createStreamingArchive,
  deleteStreamingArchive,
  fetchStreamingArchives,
  updateStreamingArchive
};

export default connect(mapStateToProps, mapDispatchToProps)(StreamingArchivesManagerPane);
