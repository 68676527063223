import SlidingPane from "react-sliding-pane";
import Icons from "../../../constants/Icons";
import Networking from "../../../containers/engagement/Networking.react";

interface Props {
  isOpen: boolean;
  hideMessagingSettings?: boolean;
  hideMeetingSettings?: boolean;
  hide: () => void;
}

const NetworkingPane: React.FC<Props> = ({ isOpen, hideMessagingSettings, hideMeetingSettings, hide }) => {
  const closePane = (): void => {
    setTimeout(() => { hide(); }, 500);
  };

  return (
    <SlidingPane
      isOpen={isOpen}
      width="60%"
      onRequestClose={hide}
      closeIcon={Icons.close()}>
      <Networking inPane={true} closePane={closePane} hideMessagingSettings={hideMessagingSettings} hideMeetingSettings={hideMeetingSettings} />
    </SlidingPane>
  );
};

export default NetworkingPane;
