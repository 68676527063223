import { ActionTypes } from "../constants/Constants";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";

function websiteMenus(state = { data: null, errors: {} }, action) {
  switch (action.type) {
  case ActionTypes.RECEIVE_WEBSITE_MENUS_SUCCESS:
    return Object.assign({}, state, { data: action.data });
  case ActionTypes.CREATE_MENU_SUCCESS:
  case ActionTypes.UPDATE_MENU_SUCCESS: {
    const data = insertOrUpdateObject(state.data, action.data, "_id");
    return Object.assign({}, state, { data, errors: {} });
  }
  case ActionTypes.DELETE_MENU_SUCCESS: {
    const { menuId } = action;
    const menusAfterRemove = state.data.filter(menu => { return menu._id !== menuId; });
    return Object.assign({}, state, { data: menusAfterRemove, errors: {} });
  }
  default:
    return state;
  }
}

export default websiteMenus;
