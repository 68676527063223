import { ActionTypes } from "../constants/Constants";

function guestsPaymentStatistics(state = {}, action) {
  switch (action.type) {
  case ActionTypes.GUESTS_PAYMENT_STATISTICS_SUCCESS:
    return Object.assign({}, state, { [action.stats_type]: action.data });
  case ActionTypes.GUESTS_PAYMENT_STATISTICS_CLEAR:
    return {};
  default:
    return state;
  }
}

export default guestsPaymentStatistics;
