import { Component, ChangeEvent } from "react";
import { connect } from "react-redux";
import querystring from "querystring";
import moment from "moment";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import { pathToAccesspointEdit } from "../../utils/pathUtils";
import { redirectIfUnauthorized, isAuthorized } from "../../utils/aclUtils";
import { eventDates } from "../../utils/DateUtils";
import { fetchAccesspointsLiveConsumption } from "../../actions/AccesspointsStatisticsActionCreators";
import ReportHeader from "../../components/event_reports/ReportHeader.react";
import Paginate from "../../components/Paginate.react";
import Loader from "../../components/shared/Loader.react";
import HelpSection from "../../components/shared/HelpSection.react";
import { AccesspointLiveConsumption } from "../../types/Accesspoint";
import { ExcelExporter } from "../../types/ExcelExporter";
import { Event } from "../../types/Event";

const DATE_FORMAT = "LL";
const API_DATE_FORMAT = "YYYY-MM-DD";

interface Props {
  fetchPending: boolean;
  accesspoints: AccesspointLiveConsumption[];
  previousURL?: string;
  nextURL?: string;
  event: Event;
  match: any;
  location: any;
  history: any;
  fetchAccesspointsLiveConsumption(eventId: string, searchQuery: string): any;
}

interface State {
  type: string;
  search: string;
  searchDate: string;
}

class LiveConsumptionByAccesspoint extends Component<Props, State> {
  fetchAccesspointsTimeout?: number;

  constructor(props: Props) {
    redirectIfUnauthorized("reports", "read");
    super(props);
    ["goToPage",
      "onChangeSearch",
      "onChangeDate",
    ].forEach(fn => this[fn] = this[fn].bind(this));

    const query = querystring.parse(props.location.search.substring(1));

    this.fetchAccesspointsTimeout = null;
    this.state = {
      type: query.type as string || "session",
      search: query.search as string || "",
      searchDate: query.date as string || ""
    };
  }

  componentDidMount(): void {
    this.fetchAccesspoints(1);
  }

  fetchAccesspoints(nbPage: number): void {
    const { fetchAccesspointsLiveConsumption, match, history, location } = this.props;
    const { type, search, searchDate } = this.state;
    const formattedDate = searchDate != "" ? moment(searchDate).format(API_DATE_FORMAT) : "";
    const searchQuery = `type=${type}&search=${search}&dates[]=${formattedDate}&page=${nbPage}`;

    history.push({ ...location, search: searchQuery });
    fetchAccesspointsLiveConsumption(match.params.event_id, searchQuery);
  }

  goToPage(url: string): (e: ChangeEvent<HTMLInputElement>) => void {
    return (e: ChangeEvent<HTMLInputElement>): void => {
      e.preventDefault();
      const query = querystring.parse(url.substring(1));
      this.fetchAccesspoints(parseInt(query.page as string));
    };
  }

  onChangeSearch(e: ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.clearTimeout();
    this.setState({ search: e.target.value }, () => {
      this.fetchAccesspointsTimeout = setTimeout(() => {
        this.fetchAccesspoints(1);
      }, 600);
    });
  }

  onChangeDate(date: string): () => void {
    return (): void => {
      this.setState({ searchDate: date }, () => {
        this.fetchAccesspoints(1);
      });
    };
  }

  clearTimeout(): void {
    if (this.fetchAccesspointsTimeout) {
      clearTimeout(this.fetchAccesspointsTimeout);
    }
  }

  i18n(key: string, opts: any = {}): string {
    return I18n.t(`react.event_reports.live_consumption.${key}`, opts);
  }

  formattedLiveDuration(accesspoint: AccesspointLiveConsumption): string {
    const seconds = accesspoint.live_duration_in_seconds;

    if (!seconds) return "-";

    if (seconds >= 60) {
      return `${Math.round(seconds / 60)} min.`;
    } else {
      return `${seconds} sec.`;
    }
  }

  averageAttendeeConsumption(accesspoint: AccesspointLiveConsumption): string {
    if (accesspoint.avg_time_spent_by_attendee === null) {
      return "-";
    }

    return `${accesspoint.avg_time_spent_by_attendee} min.`;
  }

  replayCount(accesspoint: AccesspointLiveConsumption): string {
    if (accesspoint.live_session_type === "video_conference") return "-";
    if (!accesspoint.replay_count) return "0";

    return accesspoint.replay_count.toString();
  }

  averageReplayConsumption(accesspoint: AccesspointLiveConsumption): string {
    if (accesspoint.live_session_type === "video_conference") return "-";
    if (!accesspoint.replay_count) return "0 min.";

    return `${Math.round(accesspoint.replay_minutes / accesspoint.replay_count).toString()} min.`;
  }

  excelExporterProps(): ExcelExporter {
    const { accesspoints } = this.props;
    const columns = ["name", "live_session_type", "live_session_duration", "publisher_count", "subscriber_count",
      "avg_time_spent_by_attendee", "replay_count", "avg_replay_consumption", "chat_messages_count"];
    const data = accesspoints.map(accesspoint => {
      return {
        name: accesspoint.name,
        live_session_type: I18n.t(`react.accesspoint.form.live_session_type_${accesspoint.live_session_type}`),
        live_session_duration: this.formattedLiveDuration(accesspoint),
        publisher_count: accesspoint.publisher_count,
        subscriber_count: accesspoint.subscriber_count,
        avg_time_spent_by_attendee: this.averageAttendeeConsumption(accesspoint),
        replay_count: this.replayCount(accesspoint),
        avg_replay_consumption: this.averageReplayConsumption(accesspoint),
        chat_messages_count: accesspoint.live_session_chat_messages_count
      };
    });

    return {
      data: { data },
      columns,
      options: { excludeTotal: true }
    };
  }

  renderHeader(): JSX.Element {
    const { type, searchDate } = this.state;
    const { accesspoints } = this.props;

    let title = "";
    if (type == "session") {
      title = this.i18n("sessions_title");
    } else if (type == "meeting") {
      title = this.i18n("meetings_title");
    }

    const dateDropDownProps = {
      title: searchDate != "" ? moment(searchDate).format(DATE_FORMAT) : this.i18n("all_days"),
      menuItemRows: this.renderDatesDropdownItems()
    };

    return (
      <ReportHeader
        title={title}
        exporterToExcelProps={accesspoints?.length > 0 ? this.excelExporterProps() : null}
        dateDropDownProps={dateDropDownProps}
        exportType="live_consumption" />
    );
  }

  renderDatesDropdownItems(): JSX.Element {
    const { event } = this.props;
    if (!event) return null;

    const rows = eventDates(event).map(date => {
      return <Dropdown.Item key={date} onClick={this.onChangeDate(date)}>{ moment(date).format(DATE_FORMAT) }</Dropdown.Item>;
    });

    rows.push(<Dropdown.Divider key="divider" />);
    rows.push(<Dropdown.Item key="cumul" onClick={this.onChangeDate("")}>{ this.i18n("all_days") }</Dropdown.Item>);

    return rows;
  }

  renderSearchAndPagination(): JSX.Element {
    const { search } = this.state;
    const { previousURL, nextURL } = this.props;

    return (
      <div className="row">
        <div className="col-12">
          <HelpSection help={this.i18n("help")} classNames="mb-10" />
        </div>
        <div className="col-sm-6 mt-20">
          <input type="text" className="form-control" value={search} placeholder="Rechercher" onChange={this.onChangeSearch} />
        </div>
        <div className="col-sm-6 mt-20">
          <Paginate handlePrevious={this.goToPage(previousURL)}
            handleNext={this.goToPage(nextURL)}
            previousEnabled={!!previousURL}
            nextEnabled={!!nextURL}
            pull="end"
            classNames="mb-0"
          />
        </div>
      </div>
    );
  }

  renderEmptyMsg(): JSX.Element {
    return (
      <div className="card nothing-yet mt-10">
        <h4>{this.i18n("no_data")}</h4>
      </div>
    );
  }

  renderTable(): JSX.Element {
    const { accesspoints } = this.props;

    if (accesspoints?.length <= 0) return this.renderEmptyMsg();

    return (
      <div className="table-responsive table-container mb-10">
        <table className="table table-light table-hover table-sm">
          <thead>
            { this.renderTableHeader() }
          </thead>
          <tbody>
            { this.renderTableBody() }
          </tbody>
        </table>
      </div>
    );
  }

  renderTableHeader(): JSX.Element {
    return (
      <tr>
        <th></th>
        <th>{ this.i18n("live_session_type") }</th>
        <th>{ this.i18n("live_session_duration") }</th>
        <th>{ this.i18n("publisher_count") }</th>
        <th>{ this.i18n("subscriber_count") }</th>
        <th>{ this.i18n("avg_time_spent_by_attendee") }</th>
        <th>{ this.i18n("replay_count") }</th>
        <th>{ this.i18n("avg_replay_consumption") }</th>
        <th>{ this.i18n("chat_messages_count") }</th>
      </tr>
    );
  }

  renderTableBody(): JSX.Element[] {
    const { accesspoints } = this.props;

    return accesspoints.map(accesspoint => {
      const tooltip = <Tooltip id={`tooltip-bar-${accesspoint._id}`}>{ accesspoint.name }</Tooltip>;

      return (
        <tr key={accesspoint._id}>
          <td>
            <OverlayTrigger placement="top" overlay={tooltip}>
              <a href={pathToAccesspointEdit(accesspoint._id)} className="badge rounded-pill bg-secondary accesspoint">
                { accesspoint.name.substring(0, 150) }
              </a>
            </OverlayTrigger>
          </td>
          <td>{ I18n.t(`react.accesspoint.form.live_session_type_${accesspoint.live_session_type}`) }</td>
          <td>{ this.formattedLiveDuration(accesspoint) }</td>
          <td>{ accesspoint.publisher_count }</td>
          <td>{ accesspoint.subscriber_count }</td>
          <td>{ this.averageAttendeeConsumption(accesspoint) }</td>
          <td>{ this.replayCount(accesspoint) }</td>
          <td>{ this.averageReplayConsumption(accesspoint) }</td>
          <td>{ accesspoint.live_session_chat_messages_count }</td>
        </tr>
      );
    });
  }

  render(): JSX.Element {
    if (!isAuthorized("reports", "read")) return;
    const { fetchPending } = this.props;

    return (
      <div>
        { this.renderHeader() }
        { this.renderSearchAndPagination() }
        { fetchPending ? <Loader /> : this.renderTable() }
      </div>
    );
  }
}

function mapStateToProps(state: any): any {
  return {
    fetchPending: state.accesspointsLiveConsumption.fetchPending,
    accesspoints: state.accesspointsLiveConsumption.accesspoints,
    previousURL: state.accesspointsLiveConsumption.previousURL,
    nextURL: state.accesspointsLiveConsumption.nextURL,
    event: state.event
  };
}

const mapDispatchToProps = {
  fetchAccesspointsLiveConsumption
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveConsumptionByAccesspoint);
