import { ChangeEvent } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
  formItem: {
    options: {
      text_completion?: {
        guided_prompt_enabled?: boolean;
        free_prompt_enabled?: boolean;
        prompt?: string;
        placeholder?: string;
        text_length?: string;
        custom_text_length?: number;
      };
    };
  };
  updateFormItem: (options: any) => void;
}

function i18n(key: string): string {
  return I18n.t(`react.form_items.text_completion.${key}`);
}

const TextFieldCompletion: React.FC<Props> = ({
  formItem,
  updateFormItem,
}) => {

  const textCompletion = formItem.options.text_completion || {};
  const {
    guided_prompt_enabled,
    free_prompt_enabled,
    prompt,
    placeholder,
    text_length,
    custom_text_length,
  } = textCompletion;

  const updateFormItemFromLocalChanges = (key: string): ((e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void) => {
    return (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
      const target = e.target as HTMLInputElement;
      const newValue = target.type === "checkbox" ? target.checked : target.value;
      const options = {
        ...formItem.options,
        text_completion: {
          ...formItem.options.text_completion,
          [key]: newValue,
        },
      };
      updateFormItem({ options });
    };
  };

  const renderGuidedPromptParameters = (): JSX.Element => {
    if (!guided_prompt_enabled) return null;

    return (
      <>
        <div className="mb-3 row">
          <label htmlFor="prompt" className="col-sm-3 col-form-label text-end">
            {i18n("prompt")}
          </label>
          <div className="col-sm-8">
            <input
              defaultValue={prompt || ""}
              name="prompt"
              type="text"
              className="form-control"
              onBlur={updateFormItemFromLocalChanges("prompt")}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="placeholder" className="col-sm-3 col-form-label text-end">
            {i18n("placeholder")}
          </label>
          <div className="col-sm-8">
            <input
              defaultValue={placeholder || ""}
              name="placeholder"
              type="text"
              className="form-control"
              onBlur={updateFormItemFromLocalChanges("placeholder")}
            />
          </div>
        </div>
      </>
    );
  };

  const renderHelpTooltip = (): JSX.Element => {
    const overlay = (
      <Tooltip id="tooltip">
        <p>
          {i18n("ai_assistant_help_text")}
        </p>
      </Tooltip>
    );

    return (
      <span>
        <OverlayTrigger placement="top" overlay={overlay}>
          <i className="fa-regular fa-info-circle" aria-hidden="true"></i>
        </OverlayTrigger>
      </span>
    );
  };

  const renderLengthSelection = (): JSX.Element => {
    const values = ["brief", "short", "medium", "long", "very_long", "custom"];
    const options = values.map(value => {
      return <option value={value} key={value}>{i18n(`length_selection.${value}`)}</option>;
    });
    const customInput = (
      <input type="number"
        className="form-control"
        value={ custom_text_length }
        placeholder={ i18n("custom_length_placeholder") }
        onChange={updateFormItemFromLocalChanges("custom_text_length")}
        min="0"
        step="1"
        style={{ width: "auto", display: "inline-block" }} />
    );
    return (
      <div className="col-sm-12">
        <div className="mb-3">
          <label htmlFor="text_length" className="form-label mr-10" style={{ display: "inline-block", marginLeft: "20px" }}>{ i18n("text_length") }:</label>
          <select name="text_length"
            className="form-select mr-10"
            style={{ display: "inline-block", width: "30%" }}
            value={text_length || "medium"}
            onChange={updateFormItemFromLocalChanges("text_length")}>
            { options }
          </select>
          { text_length == "custom" && customInput }
        </div>
      </div>
    );
  };

  return (
    <div>
      <hr/>
      <div className="mb-3">
        <label className="form-label">
          { i18n("ai_assistant") } { " " } { renderHelpTooltip() }
        </label>
        <div className="form-check">
          <label className="form-check-label">
            <input className="form-check-input" checked={guided_prompt_enabled} type="checkbox" name="guided_prompt" onChange={updateFormItemFromLocalChanges("guided_prompt_enabled")} />
            { i18n("enable_guided_prompt") }
          </label>
        </div>
        { renderGuidedPromptParameters() }
        <div className="form-check">
          <label className="form-check-label">
            <input className="form-check-input" checked={free_prompt_enabled} type="checkbox" name="free_prompt" onChange={updateFormItemFromLocalChanges("free_prompt_enabled")} />
            { i18n("enable_free_prompt") }
          </label>
        </div>
        { renderLengthSelection() }
      </div>
    </div>
  );
};

export default TextFieldCompletion;
