import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { pathToEmailBuilder, pathToDocumentBuilder } from "../../utils/pathUtils";

interface Props {
  templateId: string;
  templateType: "email_template" | "document_template";
  enableEdit: boolean;
  enableDelete?: boolean;
  builderInSlidingPane?: boolean;
  inModal?: boolean;
  enableDuplication: boolean;
  openSelectEventModal(templateId: string): void;
  onEditRequest?(): void;
  deleteTemplate?(templateId: string): void;
  openBuilder?(): void;
}

const TemplateActionButtons: React.FC<Props> = (props) => {
  const cancelEventAndRun = (f): ((e) => void) => {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      f();
    };
  };

  const i18n = (key: string): string => {
    switch (props.templateType) {
    case "email_template":
      return I18n.t(`react.email_templates.tooltip.${key}`);
    case "document_template":
      return I18n.t(`react.document_templates.tooltip.${key}`);
    }
  };

  const pathToBuilder = (): string => {
    const { templateType, templateId } = props;

    switch (templateType) {
    case "email_template":
      return pathToEmailBuilder(templateId);
    case "document_template":
      return pathToDocumentBuilder(templateId);
    }
  };

  const renderEditButton = (): JSX.Element => {
    const { enableEdit, builderInSlidingPane, openBuilder, onEditRequest, inModal } = props;

    if (!enableEdit) return null;

    const target = inModal ? "_blank" : null;
    const tooltipEdit = <Tooltip id="tooltip-edit-template">{ i18n("edit") }</Tooltip>;

    return <OverlayTrigger placement="top" overlay={tooltipEdit}>
      <a target={target} className="btn btn-link"
        onClick={onEditRequest || (builderInSlidingPane && openBuilder)}
        href={!onEditRequest && !builderInSlidingPane ? pathToBuilder() : null}>
        <i className="fa-regular fa-pen-to-square"></i>
      </a>
    </OverlayTrigger>;
  };

  const renderDuplicateButton = (): JSX.Element => {
    const { enableDuplication, openSelectEventModal } = props;

    if (!enableDuplication) return null;

    const tooltipDuplicate = <Tooltip id="tooltip-duplicate-template">{ i18n("duplicate") }</Tooltip>;

    return <OverlayTrigger placement="top" overlay={tooltipDuplicate}>
      <a href="#" className="btn btn-link" onClick={cancelEventAndRun(openSelectEventModal)}>
        <i className="fa-regular fa-clone"></i>
      </a>
    </OverlayTrigger>;
  };

  const renderDeleteButton = (): JSX.Element => {
    const { enableDelete, deleteTemplate, templateId } = props;

    if (!enableDelete || !deleteTemplate) return null;

    const tooltipDelete = <Tooltip id="tooltip-delete-template">{ i18n("delete") }</Tooltip>;

    return <OverlayTrigger placement="top" overlay={tooltipDelete}>
      <a href="#" className="btn btn-link" onClick={cancelEventAndRun(deleteTemplate(templateId))}>
        <i className="fa-regular fa-trash-can"></i>
      </a>
    </OverlayTrigger>;
  };

  return (
    <div className="action-buttons">
      {renderEditButton()} {renderDuplicateButton()} {renderDeleteButton()}
    </div>
  );
};

export default TemplateActionButtons;
