import { useState, useEffect } from "react";
import ConditionalDisplayOption from "../../components/form_item_question/ConditionalDisplayOption.react";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import { WorkflowStep, Context } from "../../types/Workflow";
import { conditionsHaveMissingPath } from "../../utils/workflowUtils";

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.workflows.conditions.${key}`, opts);
}

interface Props {
  workflowStep: WorkflowStep;
  isSavingStep: boolean;
  isDisabled: boolean;
  context: Context;
  onReceiveChanges(workflowStep: WorkflowStep): void;
  onSave(newSettings: any, newConditions: any[]): void;
  onSwitchVersionRequest(): void;
}

const WorkflowStepConditions: React.FC<Props> = ({
  workflowStep,
  isSavingStep,
  isDisabled,
  context,
  onReceiveChanges,
  onSave,
  onSwitchVersionRequest
}) => {
  const [localConditions, setLocalConditions] = useState(workflowStep.conditions || []);
  const [localErrors, setLocalErrors] = useState({});

  useEffect(() => {
    setLocalConditions(workflowStep.conditions || []);
    setLocalErrors(workflowStep.errors || {});
  }, [workflowStep._id]);

  useEffect(() => {
    setLocalErrors(workflowStep.errors || {});
  }, [workflowStep.errors]);

  const receiveChanges = (conditions: any): void => {
    setLocalConditions(conditions.display_conditions);

    if (workflowStep.hasChanges) return;

    onReceiveChanges(workflowStep);
  };

  const isStepValid = (): boolean => {
    const errors = {};

    if (localConditions.length === 0) {
      errors["base"] = i18n("missing_condition");
    } else {
      localConditions.map(conditionsGroup => {
        conditionsGroup.map(andCondition => {
          if (!andCondition.field || andCondition.field === "") {
            errors["base"] = i18n("incomplete_conditions");
          }
        });
      });
    }

    if (conditionsHaveMissingPath(localConditions, context)) {
      errors["base"] = I18n.t("react.workflows.settings.missing_path_error");
    }

    setLocalErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const saveStep = (): void => {
    if (!isStepValid()) return;

    onSave(null, localConditions);
  };

  const renderConditions = (): JSX.Element => {
    return <ConditionalDisplayOption
      guestFields={[]}
      displayConditions={localConditions}
      updateHandler={receiveChanges}
      workflowStep={workflowStep}
      isDisabled={isDisabled}
      context="automation"
    />;
  };

  const renderGlobalErrorMessage = (): JSX.Element => {
    if (Object.keys(localErrors).length === 0) return;

    return <span className="text-danger ms-2">
      <i className="fa-regular fa-warning" /> {i18n("global_error_message")}
    </span>;
  };

  const renderSaveButton = (): JSX.Element => {
    if (isDisabled) return;

    return <button type="button" onClick={saveStep} className="btn btn-success" disabled={!workflowStep.hasChanges || isSavingStep}>
      <i className="fa-regular fa-card"></i> {i18n("save")}
    </button>;
  };

  const renderEditDraftButton = (): JSX.Element => {
    if (!isDisabled) return;

    return <button type="button" onClick={onSwitchVersionRequest} className="btn btn-success">
      <i className="fa-regular fa-card"></i> {i18n("edit")}
    </button>;
  };

  return <>
    <ErrorMessage
      errors={localErrors}
      i18nCustomPath="react.workflows.conditions"
    />
    {renderConditions()}
    <div className="mb-3">
      {renderSaveButton()}
      {renderEditDraftButton()}
      {renderGlobalErrorMessage()}
    </div>
  </>;
};

export default WorkflowStepConditions;
