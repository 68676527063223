import difference from "lodash/difference";
import { GuestCategory } from "../types/GuestCategory";
import FilterDropdown from "./FilterDropdown.react";
import { populationI18n } from "../utils/miscUtils";

interface Props {
  disabled?: boolean;
  guestCategories: GuestCategory[],
  selectedGuestCategoryIds: string[],
  setSelectedGuestCategoryIds: (selectedGuestCategoryIds: string[]) => void,
  translationKey?: string,
  showCells: boolean,
  showBadgeNbItems: boolean
}

const PopulationTypePicker: React.FC<Props> = ({ disabled, guestCategories, selectedGuestCategoryIds, setSelectedGuestCategoryIds, translationKey, showCells, showBadgeNbItems }) => {
  const populations = guestCategories.reduce((populationTypes, guestCategory) => {
    if (guestCategory.population_type && guestCategory.population_type != "") populationTypes.add(guestCategory.population_type);

    return populationTypes;
  }, new Set());

  const availablePopulationTypes = Array.from(populations);

  const populationFilterItems = availablePopulationTypes.map((populationType: string) => {
    return { key: populationType, label: populationI18n(populationType) };
  });

  const onPopulationChange = (newPopulations): void => {
    let newSelectedGuestCategoryIds = Array.from(selectedGuestCategoryIds);

    const removedPopulations = difference(selectedPopulations, newPopulations);
    const removedCategoryIds = guestCategories.filter(category => removedPopulations.includes(category.population_type)).map(cat => cat._id);
    newSelectedGuestCategoryIds = newSelectedGuestCategoryIds.filter(id => !removedCategoryIds.includes(id));

    const addedPopulations = difference(newPopulations, selectedPopulations);
    const addedCategoryIds = guestCategories.filter(category => addedPopulations.includes(category.population_type)).map(cat => cat._id);
    newSelectedGuestCategoryIds = newSelectedGuestCategoryIds.concat(addedCategoryIds);

    setSelectedGuestCategoryIds(newSelectedGuestCategoryIds);
  };

  const selectedPopulations = availablePopulationTypes.filter(populationType => {
    return !guestCategories.find(category => category.population_type == populationType && !selectedGuestCategoryIds.includes(category._id));
  });

  return <FilterDropdown
    id={translationKey}
    disabled={disabled}
    items={populationFilterItems}
    itemIdKey="key"
    itemNameKey="label"
    selectedItemIds={selectedPopulations}
    translationKey={translationKey}
    onChange={onPopulationChange}
    showCells={showCells}
    showBadgeNbItems={showBadgeNbItems}
  />;
};

PopulationTypePicker.defaultProps = {
  translationKey: "population_types",
  disabled: false,
  showCells: true,
  showBadgeNbItems: false,
  guestCategories: [],
  selectedGuestCategoryIds: []
};

export default PopulationTypePicker;
