import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchExhibitorsByNumberOfInterests } from "../../actions/ExhibitorActionCreators";
import Loader from "../../components/shared/Loader.react";
import ReportHeader from "../../components/event_reports/ReportHeader.react";
import { isAuthorized } from "../../utils/aclUtils";
import { identity } from "../../utils/personUtils";
import Select from "react-select";
import { Dropdown } from "react-bootstrap";

const ExhibitorsByNumberOfInterests: React.FC = () => {
  const eventId = useSelector((state: any) => state.event.id);
  const dataFetched = useSelector((state: any) => state.exhibitorsStatistics.byNumberOfInterests);
  const isFetching = useSelector((state: any) => state.exhibitorsStatistics.isFetching);
  const dispatch = useDispatch();
  const [guestIdSelected, setGuestIdSelected] = useState(null);
  const [result, setResult] = useState([]);
  const [sortField, setSortField] = useState("nb_interests");

  useEffect(() => {
    if (!eventId) return;
    dispatch(fetchExhibitorsByNumberOfInterests(eventId, sortField));
  }, [eventId, sortField]);

  useEffect(() => {
    setResult(dataFetched);
  }, [dataFetched]);

  useEffect(() => {
    if (!guestIdSelected) {
      setResult(dataFetched);
      return;
    }

    const resultFiltered = dataFetched.filter(item => {
      return !guestIdSelected || item._id === guestIdSelected;
    });
    setResult(resultFiltered);
  }, [guestIdSelected]);

  useEffect(() => {
    setGuestIdSelected(null);
  }, []);

  if (!isAuthorized("reports", "read")) return null;

  const i18n = (key: string, options: any = {}): string => {
    return I18n.t(`react.event_reports.exhibitors_by_number_of_interests.${key}`, options);
  };

  const exporterProps = (): any => {
    return {
      data: {
        data: result?.map(
          (guest) => {
            return {
              exhibitor: identity(guest),
              company: guest.company_name,
              nb_bookmarks: guest.nb_interests
            };
          }
        )
      },
      options: { excludeTotal: true },
      columns: ["exhibitor", "company", "nb_bookmarks"]
    };
  };

  const renderHeader = (): JSX.Element => {
    return <ReportHeader
      title={I18n.t("react.event_reports.event_reports.exhibitors_by_number_of_interests")}
      exporterToExcelProps={result?.length > 0 ? exporterProps() : null}
      exportType="exhibitors_by_number_of_interests"
    />;
  };

  const renderSortDropdown = (): JSX.Element => {
    const rows = ["nb_interests", "last_name", "company_name"].map(sort => {
      return <Dropdown.Item key={sort} onClick={(): void => setSortField(sort)}>{i18n(`sort_by_${sort}`)}</Dropdown.Item>;
    });

    return <div className="float-end">
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-sort">
          {i18n(`sort_by_${sortField}`)}
        </Dropdown.Toggle>
        <Dropdown.Menu
          align="end"
          popperConfig={{
            strategy: "fixed",
            onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll"))
          }}
        >
          {rows}
        </Dropdown.Menu>
      </Dropdown>
    </div>;
  };

  const renderTableHeader = (): JSX.Element => {
    const guests = result?.map((guest) => {
      let label = guest?.company_name || "";
      const guestIdentity = identity(guest);
      if (label != guestIdentity) label += label == "" ? guestIdentity : ` - ${guestIdentity}`;
      return {
        value: guest._id,
        label: label
      };
    });

    return (
      <>
        <tr>
          <th style={{ fontWeight: "normal" }}>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              options={guests}
              value={guests?.filter(guest => guestIdSelected?.includes(guest.value))}
              placeholder={i18n("select_an_exhibitor")}
              noOptionsMessage={(): any => i18n("no_exhibitor")}
              onChange={(option): any => setGuestIdSelected(option?.value)}
              isClearable={true}
              menuPosition="fixed"
              styles={{
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                menuPortal: ({ left, ...provided }, state) => ({
                  ...provided
                })
              }}
            />
          </th>
          <th>{renderSortDropdown()}</th>
        </tr>
        <tr>
          <th>{I18n.t("react.event_reports.report_table.exhibitor_identity")}</th>
          <th>{I18n.t("react.event_reports.report_table.nb_interests")}</th>
        </tr>
      </>
    );
  };

  const renderTable = (): JSX.Element => {
    if (!dataFetched) return null;
    if (result?.length === 0) return renderEmptyMsg();

    return <div className="row">
      <div className="col-12">
        <div className="table-responsive table-container">
          <table className="table table-light table-hover table-sm">
            <thead>
              {renderTableHeader()}
            </thead>
            <tbody>
              {renderTableBody()}
            </tbody>
          </table>
        </div>
      </div>
    </div>;
  };

  const renderTableBody = (): JSX.Element[] => {
    return result?.map(
      (guest) => {
        return (
          <tr key={guest._id}>
            <td>
              <b>{identity(guest)}</b>
              <div><small>{guest.company_name}</small></div>
            </td>
            <td>{guest.nb_interests}</td>
          </tr>
        );
      }
    );
  };

  const renderEmptyMsg = (): JSX.Element => {
    return <div className="card nothing-yet">
      <h4>{i18n("no_data")}</h4>
    </div>;
  };

  return <>
    { renderHeader() }
    { isFetching ? <Loader/> : renderTable() }
  </>;
};

export default ExhibitorsByNumberOfInterests;
