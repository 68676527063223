import { Component } from "react";
import Loader from "../shared/Loader.react";
import { DASHBOARD_HEADER_NO_VALUE_STR } from "../../constants/Constants";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

class HeaderStats extends Component {

  i18n(key, opts = {}) {
    const { tabKey } = this.props;
    return I18n.t(`react.dashboard.${tabKey}.header_stats.${key}`, opts);
  }

  renderStatValueOrLoader(value) {
    const { sizeLoader, dataFetched } = this.props;

    return dataFetched ? <p className="main">{ value }</p> : <Loader size={sizeLoader} inline={false} containerHeight="48px"/>;
  }

  valueOrNoHeaderValue(value) {
    return value === null ? DASHBOARD_HEADER_NO_VALUE_STR : value;
  }

  render() {
    const { headers } = this.props;

    const renderHeaders = headers.map(header => {
      let hint = null;
      if (header.hintI18nKey) {
        const tooltip = <Tooltip id="info">{this.i18n(header.hintI18nKey)}</Tooltip>;
        hint = <OverlayTrigger placement="bottom" overlay={tooltip}><i className="fa-regular fa-info-circle mr-5" aria-hidden="true"></i></OverlayTrigger>;
      }
      return <div className={`col-md-${header.nbCols || 3}`} key={header.key || header.i18nKey}>
        <div className="card card-sm card-global-stat text-center">
          <div>{ this.renderStatValueOrLoader(this.valueOrNoHeaderValue(header.value)) }</div>
          <p className="event">
            {hint}{ header.label || this.i18n(header.i18nKey, { value: this.valueOrNoHeaderValue(header.labelInfoValue) }) }
          </p>
        </div>
      </div>;
    });

    return <div className="row mt-10">
      { renderHeaders }
    </div>;
  }

}

HeaderStats.defaultProps = {
  sizeLoader: "medium"
};

export default HeaderStats;
