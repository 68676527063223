import { Component } from "react";
import { connect } from "react-redux";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";

import { pathToGuestCategoriesNew } from "../../utils/pathUtils";

import { fetchEventsFromUrl } from "../../actions/EventActionCreators";
import { requestEvent } from "../../actions/ImportActionCreators";
import { copyGuestCategory } from "../../actions/GuestCategoryActionCreators";
import { importGuestCategoriesFromEvent } from "../../actions/ImportGuestCategoryActionCreators";

import TargetEventModal from "../../components/duplication_modals/TargetEventModal.react";
import DuplicationRunningModal from "../../components/duplication_modals/DuplicationRunningModal.react";
import GuestCategoryPickerModal from "../../components/duplication_modals/GuestCategoryPickerModal.react";
import { redirectIfUnauthorized } from "../../utils/aclUtils";

class GuestCategoryCreationButtons extends Component {
  constructor(props) {
    redirectIfUnauthorized("configuration", "manage");
    super(props);

    [
      "toggleEventSourceModal",
      "onEventSourceSelected",
      "duplicate",
      "fetchMoreEvents"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      showEventSourcePickerModal: false,
      showGuestCategoryPickerModal: false,
      showDuplicationRunningModal: false
    };
  }

  componentDidMount() {
    const { event, requestEvent } = this.props;
    if (!event.fetched) requestEvent();
  }

  toggleEventSourceModal(e) {
    e.preventDefault();
    this.setState({ showEventSourcePickerModal: !this.state.showEventSourcePickerModal });
  }

  onEventSourceSelected(eventId) {
    const { importGuestCategoriesFromEvent } = this.props;
    importGuestCategoriesFromEvent(eventId);
    this.setState({
      showEventSourcePickerModal: false,
      showGuestCategoryPickerModal: true,
      sourceEventId: eventId
    });
  }

  duplicate(sourceGuestCategoryId, newName) {
    this.setState({
      showGuestCategoryPickerModal: false,
      showDuplicationRunningModal: true
    });

    const { copyGuestCategory, event } = this.props;
    const { sourceEventId } = this.state;

    const guestCategoryParam = {
      new_name: newName,
      target_event_id: event._id
    };
    copyGuestCategory(sourceEventId, sourceGuestCategoryId, guestCategoryParam);
  }

  fetchMoreEvents() {
    const { fetchEventsFromUrl, eventsNextURL } = this.props;
    fetchEventsFromUrl(eventsNextURL);
  }

  renderDuplicateFromModals() {
    const { event, events, duplicationErrors, duplicatedCategory, eventsNextURL } = this.props;
    const { showEventSourcePickerModal, showGuestCategoryPickerModal, showDuplicationRunningModal, sourceEventId } = this.state;

    const sourceEvent = events && events.find(ev => ev._id == sourceEventId);

    return (
      <div>
        {/* TargetEventModal should probably be renamed EventPickerModal */}
        <TargetEventModal
          isVisible={showEventSourcePickerModal}
          onClose={() => this.setState({ showEventSourcePickerModal: false }) }
          currentEvent={event}
          fetchMoreEvents={eventsNextURL ? this.fetchMoreEvents : null}
          targetReadableName={I18n.t("react.email_templates.target_name")}
          onEventSelected={this.onEventSourceSelected}
          title={ I18n.t("react.guest_category.creation_buttons.create_guest_category_from_modal_event_title") }
        />

        <GuestCategoryPickerModal
          isVisible={showGuestCategoryPickerModal}
          onClose={() => this.setState({ showGuestCategoryPickerModal: false }) }
          onSubmit={this.duplicate}
          event={sourceEvent}
          guestCategories={sourceEvent && sourceEvent.guestCategories}
          modalTitle={ I18n.t("react.guest_category.creation_buttons.create_guest_category_from_modal_title") }
        />

        <DuplicationRunningModal
          isVisible={showDuplicationRunningModal}
          onClose={() => this.setState({ showDuplicationRunningModal: false }) }
          model="guest_category"
          currentEventId={event._id}
          targetEventId={event._id}
          duplicatedData={duplicatedCategory}
          onContinue={() => window.location.reload()}
          duplicationErrors={duplicationErrors}
        />
      </div>
    );
  }

  render() {
    return <>
      <Dropdown as={ButtonGroup}>
        <Button variant="primary" href={pathToGuestCategoriesNew()}>
          <i className="fa-regular fa-plus"></i> { I18n.t("react.guest_category.creation_buttons.add_guest_category") }
        </Button>
        <Dropdown.Toggle variant="primary" aria-haspopup="true"></Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item eventKey="1" onClick={(e) => this.toggleEventSourceModal(e)}>
            { I18n.t("react.guest_category.creation_buttons.create_guest_category_from") }
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      { this.renderDuplicateFromModals() }
    </>;
  }
}

function mapStateToProps(state) {
  return {
    event: state.event,
    events: state.events.data,
    eventsNextURL: state.events.nextURL,
    duplicatedCategory: state.guestCategory.duplicated,
    duplicationErrors: state.guestCategory.duplicationErrors
  };
}

const mapDispatchToProps = {
  copyGuestCategory,
  fetchEventsFromUrl,
  importGuestCategoriesFromEvent,
  requestEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestCategoryCreationButtons);
