import { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Icons from "../../constants/Icons";
import paths from "../../utils/pathUtils";

class GuestCategoryFeature extends Component {

  featureEnabled() {
    const { guestCategory, feature } = this.props;
    return guestCategory[feature.field];
  }

  featurePath() {
    const { guestCategory, feature } = this.props;
    return paths[feature.pathMethod](guestCategory._id);
  }

  renderRibbon() {
    if (this.featureEnabled()) return null;

    return <div className="ribbon-wrapper">
      <div className="ribbon">{ I18n.t("disabled") }</div>
    </div>;
  }

  renderFeatureIcon() {
    const { feature } = this.props;

    return <div className="feature-content">
      { feature.content || (feature.key == "invoice" ? Icons["zohoIcon"]() : <i className={feature.icon} />) }
    </div>;
  }

  renderFeatureLabel() {
    const { feature } = this.props;

    return <div className="feature-label">{ feature.name }</div>;
  }

  renderContent() {
    const { onClick } = this.props;

    return <a onClick={onClick} href={onClick ? null : this.featurePath()}>
      { this.renderRibbon() }
      { this.renderFeatureIcon() }
      { this.renderFeatureLabel() }
    </a>;
  }

  render() {
    const { feature, children } = this.props;

    return <div className={classNames("card", "feature", this.featureEnabled() || "feature-disabled", feature.key == "invoice" && "feature-img")}>
      <div className="card-body">
        { this.renderContent() }
        { children }
      </div>
    </div>;
  }
}

GuestCategoryFeature.propTypes = {
  guestCategory: PropTypes.object.isRequired,
  feature: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default GuestCategoryFeature;
