import { ActionTypes } from "../constants/Constants";

function emailActivities(state = initialState, action) {
  switch (action.type) {
  case ActionTypes.SET_SELECTED_EMAIL_ACTIVITY: {
    return Object.assign({}, state, { [action.filter]: action.value });
  }
  default:
    return state;
  }
}

const initialState = {
  "email_sent": null,
  "email_delivered": null,
  "email_opened": null,
  "email_clicked": null,
  "email_bounced": null,
  "email_spammed": null,
  "email_blocked": null
};

export default emailActivities;