import {
  CalculatedType,
  LazyCalculatedType,
  FormItemsWithFile,
  GuestFieldTypesWithFile,
  StaticGuestFieldToExport,
  StaticGuestFieldsWithFile
} from "../constants/Constants";

import { itemsByStep, stepBeingEdited } from "./itemsBySectionUtils.js";

const ADD_GUEST_FIELD = "add-guest-field";
const PAYMENT_PROMO_CODE = "payment_promo_code";

export function splitStandardFromCustomGuestFieldsForExport(guestFields) {
  const { standardFields, customFields } = guestFields.reduce((acc, field) => {
    if (StaticGuestFieldToExport.includes(field.key)) {
      acc.standardFields.push(field);
    } else if (field.type !== LazyCalculatedType) {
      acc.customFields.push(field);
    }
    return acc;
  }, {
    standardFields: [],
    customFields: []
  });
  return [standardFields, customFields];
}

// return the list of guest fields for a specific type
// fields may not be used twice in a same step but may on 2 differents steps
export function availableGuestFieldsByType(guestFields, registrationFormItems, sections, steps, formItem = null, excludeCalculatedFields = true) {
  const step = stepBeingEdited(steps);
  const usedGuestFields = itemsByStep(registrationFormItems, sections, step).reduce((acc, item) => {
    if (item.form_item_options) {
      item.form_item_options.forEach(subItem => {
        acc.push(subItem.key);
      });
    }
    acc.push(item.key);
    return acc;
  }, []);

  return guestFields.filter(field => {
    if (field.key === PAYMENT_PROMO_CODE) {
      return false;
    }

    if (formItem && formItem.key == field.key) {
      return true;
    }

    if (FormItemsWithFile.includes(formItem.type) && !GuestFieldTypesWithFile.includes(field.type) && !StaticGuestFieldsWithFile.includes(field.key)) {
      return false;
    }

    if (excludeCalculatedFields && [CalculatedType, LazyCalculatedType].includes(field.type)) {
      return false;
    }

    if (formItem && formItem.form_item_options) {
      const guestFieldToPreserve = formItem.form_item_options.find(option => {
        return option.key == field.key;
      });
      if (guestFieldToPreserve) {
        return true;
      }
    }
    return !usedGuestFields.includes(field.key);
  });
}

export function nbGuestFieldByType(guestFields, type) {
  return guestFields.filter(guestField => {
    if (GuestFieldTypesWithFile.includes(guestField.type) || StaticGuestFieldsWithFile.includes(guestField.key)) {
      return FormItemsWithFile.includes(type);
    }
    return guestField.type == type;
  });
}

export function injectDefaultsGuestFields(guestFields) {
  const guestFieldsWithDefault = guestFields.slice();
  guestFieldsWithDefault.unshift({ key: ADD_GUEST_FIELD, label: I18n.t("react.form_question_item.add_a_new_guest_field") });
  guestFieldsWithDefault.unshift({ key: "default", label: I18n.t("react.form_question_item.pick_a_guest_field") });
  return guestFieldsWithDefault;
}

export function isAddGuestFieldKey(value) {
  return value === ADD_GUEST_FIELD;
}
