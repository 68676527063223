import { Component } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import { pathToGuestBadgePermalink } from "../../utils/pathUtils";

const noBadge = I18n.t("shared.table_guest.no_badge");
const undefinedValue = " - ";

class BadgesCell extends Component {
  render() {
    const { guest, guestCategories, autoPrint } = this.props;
    const category = guestCategories.find(category => category.id === guest.guest_category_id);
    const { badgeTemplates, have_or_should_have_badge } = guest;

    if (isEmpty(badgeTemplates) || !category.badge_enabled || !have_or_should_have_badge) {
      return <td className="cell-badge d-none d-sm-table-cell">{noBadge}</td>;
    }

    const badges = badgeTemplates.map((template, index) => {
      const { id, name } = template;
      let link = pathToGuestBadgePermalink(guest, id);
      if (autoPrint) link += "&auto_print=true";

      return (
        <span key={id}>
          <a href={ link } target="blank">{ name }</a>
          {(badgeTemplates.length > 1 && index + 1 < badgeTemplates.length) ? undefinedValue : null}
        </span>
      );
    });

    return <td className="cell-default d-none d-sm-table-cell">{badges}</td>;
  }
}

BadgesCell.propTypes = {
  guest: PropTypes.object.isRequired,
  guestCategories: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default BadgesCell;
