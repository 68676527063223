import { ActionTypes, CALL_API } from "../constants/Constants";

export function createGuestCampaign(eventId, params = {}, redirectTo = null) {
  const { CREATE_GUEST_CAMPAIGN_REQUEST, CREATE_GUEST_CAMPAIGN_SUCCESS, CREATE_GUEST_CAMPAIGN_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_GUEST_CAMPAIGN_REQUEST, CREATE_GUEST_CAMPAIGN_SUCCESS, CREATE_GUEST_CAMPAIGN_FAILURE],
      method: "POST",
      body: { guest_campaign: params },
      endpoint: `/api/v1/events/${eventId}/guest_campaigns.json`,
      redirectTo
    }
  };
}

export function fetchGuestCampaign(eventId, guestCampaignId) {
  const { DEV_NULL, RECEIVE_GUEST_CAMPAIGN_SUCCESS, RECEIVE_GUEST_CAMPAIGN_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_GUEST_CAMPAIGN_SUCCESS, RECEIVE_GUEST_CAMPAIGN_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guest_campaigns/${guestCampaignId}.json`
    }
  };
}

export function sendTestEmail(eventId, guestCampaignId, email) {
  const { DEV_NULL, SHOW_NOTICE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, SHOW_NOTICE, SHOW_NOTICE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guest_campaigns/${guestCampaignId}/send_test_email.json?email=${email}`
    }
  };
}

export function fetchGuestCampaignGlobalStatistics(eventId, guestCampaignId) {
  const { DEV_NULL, GUEST_CAMPAIGN_GLOBAL_STATISTICS_SUCCESS, GUEST_CAMPAIGN_GLOBAL_STATISTICS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, GUEST_CAMPAIGN_GLOBAL_STATISTICS_SUCCESS, GUEST_CAMPAIGN_GLOBAL_STATISTICS_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guest_campaigns/${guestCampaignId}/global_statistics.json`
    }
  };
}

export function updateGuestCampaign(eventId, GuestCampaignId, params, sendFile = false, redirectTo = null) {
  const { UPDATE_GUEST_CAMPAIGN_REQUEST, UPDATE_GUEST_CAMPAIGN_SUCCESS, UPDATE_GUEST_CAMPAIGN_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_GUEST_CAMPAIGN_REQUEST, UPDATE_GUEST_CAMPAIGN_SUCCESS, UPDATE_GUEST_CAMPAIGN_FAILURE],
      method: "PUT",
      body: sendFile ? params : { guest_campaign: params },
      sendFile: sendFile,
      endpoint: `/api/v1/events/${eventId}/guest_campaigns/${GuestCampaignId}.json`,
      redirectTo
    }
  };
}

export function offlineUpdateGuestCampaign(guestCampaign, newParams) {
  return {
    type: ActionTypes.OFFLINE_UPDATE_GUEST_CAMPAIGN_SUCCESS,
    data: Object.assign({}, guestCampaign, newParams)
  };
}

export function fetchStandardTheme() {
  const { DEV_NULL, RECEIVE_STANDARD_THEME_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_STANDARD_THEME_SUCCESS, DEV_NULL],
      method: "GET",
      //FIXME: this will probably cause CORS issues ...
      endpoint: "https://applidget-templates.herokuapp.com/mobicheckin/config/campaign_email.templates.yml"
    }
  };
}

export function fetchAccountTemplates(accountId) {
  const { DEV_NULL, RECEIVE_ACCOUNT_TEMPLATES_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_ACCOUNT_TEMPLATES_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/accounts/${accountId}/templates.json?type=campaign_email`
    }
  };
}

export function fetchEventEmailTemplates(eventId) {
  const { DEV_NULL, RECEIVE_EVENT_EMAIL_TEMPLATES_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_EVENT_EMAIL_TEMPLATES_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/email_templates.json`
    }
  };
}

export function createLiquidTemplate(locale, accountId, createParams) {
  const { DEV_NULL, LIQUID_TEMPLATE_CREATE_SUCCESS, LIQUID_TEMPLATE_CREATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, LIQUID_TEMPLATE_CREATE_SUCCESS, LIQUID_TEMPLATE_CREATE_FAILURE],
      method: "POST",
      body: createParams,
      endpoint: `/${locale}/accounts/${accountId}/templates.json`
    }
  };
}

export function deliverGuestCampaign(eventId, guestCampaignId, redirectTo, redirectToOptions = {}, locale = "en") {
  const { DELIVER_CAMPAIGN_REQUEST, DELIVER_CAMPAIGN_SUCCESS, DELIVER_CAMPAIGN_FAILLURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DELIVER_CAMPAIGN_REQUEST, DELIVER_CAMPAIGN_SUCCESS, DELIVER_CAMPAIGN_FAILLURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guest_campaigns/${guestCampaignId}/deliver.json?locale=${locale}`,
      redirectTo,
      redirectToOptions
    }
  };
}

export function deleteFileEditableElement(eventId, guestCampaignId, params) {
  const { DEV_NULL, DELETE_FILE_EDITABLE_FIELD } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, DELETE_FILE_EDITABLE_FIELD, DEV_NULL],
      method: "DELETE",
      body: params,
      endpoint: `/api/v1/events/${eventId}/guest_campaigns/${guestCampaignId}/delete_editable_element_file`
    }
  };
}

export function clearGuestCampaignStore() {
  const { CLEAR_GUEST_CAMPAIGN_STORE } = ActionTypes;
  return {
    type: CLEAR_GUEST_CAMPAIGN_STORE
  };
}

export function deleteGuestCampaign(eventId, guestCampaignId) {
  const { GUEST_CAMPAIGN_DELETE_SUCCESS, DEV_NULL } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, GUEST_CAMPAIGN_DELETE_SUCCESS, DEV_NULL],
      method: "DELETE",
      endpoint: `/api/v1/events/${eventId}/guest_campaigns/${guestCampaignId}.json`
    },
    guestCampaignId
  };
}
