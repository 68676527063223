import { Component } from "react";
import url from "url";

import ReactS3Uploader from "react-s3-uploader";
import { ProgressBar } from "react-bootstrap";

const PNG_FORMAT = "image/png";
const JPEG_FORMAT = "image/jpeg";

class ImageUploader extends Component {
  constructor(props) {
    super(props);
    [
      "removeImage",
      "onUploadProgress",
      "onFinishUpload",
      "resizeImage",
      "preProcessFile"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      progressPercent: null,
      showUploader: false,
      url: this.props.url
    };
  }

  componentDidUpdate(prevProps) {
    const { url } = this.props;
    if (url && prevProps.url !== url) {
      this.setState({ url });
    }
  }

  onUploadProgress(percent) {
    this.setState({ progressPercent: percent, showUploader: true });
  }

  onFinishUpload(result) {
    const { name, onFinishUpload } = this.props;
    const uri = url.parse(result.signedUrl);
    const imageUrl = uri.protocol + "//" + uri.host + uri.pathname;
    onFinishUpload(name, imageUrl);
    this.setState({ showUploader: false, url: imageUrl, progressPercent: null });
  }

  removeImage() {
    const { name, onRemoveImage } = this.props;
    onRemoveImage(name);
    this.setState({ showUploader: true, url: null });
  }

  renderUploadProgress() {
    const { progressPercent } = this.state;
    return (
      <div className="uploader-zone">
        <ProgressBar striped={true} now={progressPercent} active={true} label={`${progressPercent}%`} />
      </div>
    );
  }

  renderImageUploader() {
    return (
      <div className="uploader-zone">
        <div className="uploader-zone-content">
          {this.renderReactS3Uploader()}
          <i className="fa-regular fa-plus-circle"></i>
        </div>
      </div>
    );
  }

  renderReactS3Uploader() {
    const headers = { additional: "headers" };
    const { qParams, field, s3UploadUrl } = this.props;
    const optHeaders = { "x-amz-acl": "public-read", "Access-Control-Allow-Origin": "*" };
    const acceptedFiles = ["image/*"];

    return (
      <div className="custom-file row">
        <div className="input-group">
          <div className="form-control">{I18n.t("no_file_selected")}</div>
          <div className="input-group-text">{I18n.t("choose_file")}</div>
        </div>
        <ReactS3Uploader
          id={field}
          preprocess={this.preProcessFile}
          signingUrl={s3UploadUrl}
          onProgress={this.onUploadProgress}
          onFinish={this.onFinishUpload}
          accept={acceptedFiles}
          signingUrlHeaders={headers}
          signingUrlQueryParams={qParams}
          uploadRequestHeaders={optHeaders}
          disabled={false} />
      </div>

    );
  }

  preProcessFile(file, next) {
    const { maxWidth, maxHeight } = this.props;
    const shouldResizeImage = maxWidth != undefined || maxHeight != undefined;

    if (shouldResizeImage && [PNG_FORMAT, JPEG_FORMAT].includes(file.type)) {
      this.resizeImage(file, next);
    } else {
      next(file);
    }
  }

  resizeImage(file, next) {
    const { maxWidth, maxHeight } = this.props;
    const image = new Image();
    const self = this;

    image.onload = () => {
      const [width, height] = self.newImageSize(image, maxWidth, maxHeight);
      const canvas = document.createElement("canvas");

      canvas.width = width;
      canvas.height = height;
      canvas.getContext("2d").drawImage(image, 0, 0, width, height);

      const dataURL = canvas.toDataURL(file.type, 1.0);
      next(new File([this.dataURItoBlob(dataURL)], file.name, { type: file.type }));
    };

    image.src = URL.createObjectURL(file);
  }

  newImageSize(image, maxWidth, maxHeight) {
    let width = image.width;
    let height = image.height;

    if (maxWidth && width > maxWidth) {
      const ratio = image.height / image.width;
      width = maxWidth;
      height = ratio * maxWidth;
    }
    if (maxHeight && height > maxHeight) {
      const ratio = image.width / image.height;
      height = maxHeight;
      width = ratio * maxHeight;
    }
    return [width, height];
  }

  dataURItoBlob(dataURI, format) {
    const binary = atob(dataURI.split(",")[1]);
    const array = [];

    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: format });
  }

  renderUploadedImage() {
    const { onRemoveImage } = this.props;
    const styles = { maxWidth: "100%" };

    // Image is not removable unless there is onRemoveImage props
    let removeButton;
    if (onRemoveImage) {
      removeButton = <span className="btn btn-danger btn-sm" onClick={this.removeImage} style={{ marginLeft: "10px" }}>{I18n.t("image_uploader.remove")}</span>;
    }

    return (
      <div className="image-setting text-center img-bg-btn">
        <img src={ this.state.url } style={styles} />
        <div className="links">
          <span className="btn btn-secondary btn-sm">
            {I18n.t("image_uploader.change")}
            {this.renderReactS3Uploader()}
          </span>
          { removeButton }
        </div>
      </div>
    );
  }

  render() {
    const { showUploader, progressPercent, url } = this.state;
    const { mode } = this.props;

    if (showUploader || !url) {
      if (progressPercent) {
        return this.renderUploadProgress();
      }
      return mode === "templates_builder" ? this.renderImageUploader() : this.renderReactS3Uploader();
    }
    return this.renderUploadedImage();
  }
}

export default ImageUploader;
