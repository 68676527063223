import { CALL_API, ActionTypes } from "../constants/Constants";


export function fetchDuplicationOperation(accountId: string, duplicationOperationId: string): any {
  const { FETCH_DUPLICATION_OPERATION_REQUEST, FETCH_DUPLICATION_OPERATION_SUCCESS, FETCH_DUPLICATION_OPERATION_ERROR } = ActionTypes;

  return {
    [CALL_API]: {
      types: [FETCH_DUPLICATION_OPERATION_REQUEST, FETCH_DUPLICATION_OPERATION_SUCCESS, FETCH_DUPLICATION_OPERATION_ERROR],
      method: "GET",
      endpoint: `/api/v1/accounts/${accountId}/duplication_operations/${duplicationOperationId}.json`
    }
  };
}
