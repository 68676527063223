import { ActionTypes } from "../constants/Constants";
import { BuilderStore } from "../types/Builder";
import { mergeLocalChanges } from "../utils/templatesBuilderUtils";

const defaultState: BuilderStore = {
  sections: {},
  orderedSections: [],
  hasChanges: false,
  globalChanges: {},
  blocksToRemove: {},
  sectionsToRemove: []
};

function builder(state = defaultState, action: any): BuilderStore {
  let sections, orderedSections, sectionsToRemove, blocksToRemove, localChanges, globalChanges;

  switch (action.type) {
  case ActionTypes.FETCH_BLOG_ARTICLE_SUCCESS:
  case ActionTypes.FETCH_DOCUMENT_TEMPLATE_SUCCESS:
    ({ sections, sections_order: orderedSections } = action.data.settings_data);
    return { ...state, sections, orderedSections };
  case ActionTypes.UPDATE_BLOG_ARTICLE_SUCCESS:
  case ActionTypes.UPDATE_DOCUMENT_TEMPLATE_SUCCESS:
    ({ sections, sections_order: orderedSections } = action.data.settings_data);
    return { ...defaultState, sections, orderedSections };
  case ActionTypes.UPDATE_BUILDER_SECTIONS_ORDER:
    localChanges = { sections_order: action.objects };
    globalChanges = mergeLocalChanges(state.globalChanges, localChanges);
    return { ...state, orderedSections: action.objects, globalChanges, hasChanges: true };
  case ActionTypes.UPDATE_BUILDER_SECTION:
    sections = { ...state.sections, [action.sectionKey]: action.settings };
    localChanges = { sections: { [action.sectionKey]: action.changes } };
    globalChanges = mergeLocalChanges(state.globalChanges, localChanges);
    return { ...state, sections, globalChanges };
  case ActionTypes.CREATE_NEW_BUILDER_SECTION:
    sections = { ...state.sections, [action.sectionKey]: action.sectionData };
    orderedSections = [...state.orderedSections, action.sectionKey];
    localChanges = {
      sections: { [action.sectionKey]: action.sectionData },
      sections_order: orderedSections
    };
    globalChanges = mergeLocalChanges(state.globalChanges, localChanges);
    return { ...state, sections, orderedSections, globalChanges, hasChanges: true };
  case ActionTypes.REMOVE_BUILDER_SECTION:
    sections = { ...state.sections };
    delete sections[action.sectionKey];
    orderedSections = [...state.orderedSections];
    orderedSections.splice(orderedSections.indexOf(action.sectionKey), 1);
    sectionsToRemove = [...state.sectionsToRemove, action.sectionKey];
    localChanges = { sections_order: orderedSections };
    globalChanges = mergeLocalChanges(state.globalChanges, localChanges);
    return { ...state, sections, orderedSections, sectionsToRemove, globalChanges, hasChanges: true };
  case ActionTypes.CREATE_NEW_BUILDER_BLOCK:
    sections = { ...state.sections };
    sections[action.sectionKey]["blocks"][action.blockKey] = action.blockData;
    sections[action.sectionKey]["blocks_order"].push(action.blockKey);
    return { ...state, sections, hasChanges: true };
  case ActionTypes.REMOVE_BUILDER_BLOCK:
    blocksToRemove = { ...state.blocksToRemove };
    blocksToRemove[action.sectionKey] = [...(blocksToRemove[action.sectionKey] || []), action.blockKey];
    return { ...state, blocksToRemove, hasChanges: true };
  case ActionTypes.UPDATE_APPEARANCE:
    return { ...state, globalChanges: mergeLocalChanges(state.globalChanges, { general: action.changes }) };
  case ActionTypes.RECEIVE_BUILDER_CHANGES:
    return { ...state, hasChanges: true };
  case ActionTypes.CLEAR_BUILDER:
    return { ...defaultState };
  default:
    return state;
  }
}

export default builder;
