import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DashboardNavTab from "../../components/dashboard/DashboardNavTab.react";
import HeaderStats from "../../components/dashboard/HeaderStats.react";
import DateRangePicker from "../../components/DateRangePicker.react";
import { authorizedCategories } from "../../utils/aclUtils";
import FilterDropdown from "../../components/FilterDropdown.react";
import PopulationTypePicker from "../../components/PopulationTypePicker.react";
import { importGuestCategories } from "../../actions/ImportGuestCategoryActionCreators";
import useStateFromEndpoint from "../../utils/hooks/useStateFromEndpoint";
import Chart from "../../components/dashboard/Chart.react";
import EventPicker from "../../containers/shared/EventPicker.react";
import { Dataset, ColoringStrategy, compressDataset } from "../../types/ChartDataset";
import { dateRangeRelativeToEvent, convertDatesToRelativeDaysToEvent } from "../../utils/DateUtils";

const i18n = (key: string, options = {}): string => {
  return I18n.t(`react.dashboard.sales.${key}`, options);
};

const typeShouldIgnoreFilters = (type: string): boolean => {
  return ["total", "best_day", "by_currency"].includes(type);
};

const AMOUNT_KEY = "total_incl_taxes";
const DATE_KEY = "_id";
const PAYMENT_METHOD_KEY = "_id";
const NAME_KEY = "name";
const BY_ITEM_MAX_NUMBER_OF_ITEMS = 6;
const DEFAULT_CURRENCY = "EUR";
const COLOR_KEY = "color";
const BY_DAY_DATE_DISPLAY_FMT = "l";

const SalesDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const event = useSelector((state: any) => state.event);
  const categories = useSelector((state: any) => authorizedCategories(state.guestCategories.data));

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [eventToCompare, setEventToCompare] = useState(null);

  const [readyToQuery, setReadyToQuery] = useState(false);

  const statEndpoint = (type: string, query = {}, evt = event): string|null => {
    if (!evt?._id) return null;

    const params = { ...endpointParams(type), ...query };

    return `/api/v1/events/${evt._id}/guests/payment_statistics.json?${new URLSearchParams(params).toString()}`;
  };

  const endpointParams = (type: string): any => {
    const params = { stats_type: type };
    if (!typeShouldIgnoreFilters(type)) {
      if (startDate) params["start_date"] = startDate;
      if (endDate) params["end_date"] = endDate;
      if (selectedCategoryIds && selectedCategoryIds.length > 0) params["q"] = `guest_category:${selectedCategoryIds.join(",")}`;
    }
    return params;
  };

  const [currency] = useStateFromEndpoint(statEndpoint("by_currency"), {
    canLoad: readyToQuery,
    postProcessData: (data): string => {
      if (data.length === 0) return DEFAULT_CURRENCY;

      const mostUsed = data.reduce((max, obj) => {
        return (obj.count > max.count) ? obj : max;
      }, data[0]);

      return mostUsed["_id"];
    },
    default: "-"
  });
  const [totalStat, totalStatLoaded] = useStateFromEndpoint(statEndpoint("total"), { canLoad: readyToQuery });
  const [bestDayStat, bestDayStatLoaded] = useStateFromEndpoint(statEndpoint("best_day"), { canLoad: readyToQuery });

  useEffect(() => {
    if (!event.fetched) return;

    if (categories.length === 0) {
      dispatch(importGuestCategories());
    }

    setStartDate(moment(event.start_date).subtract(1, "month"));
    setEndDate(moment(event.end_date));

    setReadyToQuery(true);
  }, [event]);

  const renderHeaderStats = (): JSX.Element => {
    const total = totalStatLoaded && totalStat[AMOUNT_KEY];
    const bestDayAmount = bestDayStatLoaded && bestDayStat[AMOUNT_KEY];
    const bestDay = (bestDayStatLoaded && moment(bestDayStat[DATE_KEY]).format("LL")) || "-";

    return (
      <HeaderStats
        dataFetched={totalStatLoaded && bestDayStatLoaded}
        headers={
          [
            { label: i18n("total", { currency: currency }), value: formatAmount(total), nbCols: 6, key: "total" },
            { label: i18n("top_day", { currency: currency, day: bestDay }), value: formatAmount(bestDayAmount), nbCols: 6, key: "top-day" }
          ]
        }
        tabKey="sales"
      />
    );
  };

  const renderPeriodDatePicker = (): JSX.Element => {
    return <div className="col-auto">
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onDateRangeChanged={(start, end): void => {
          setStartDate(start);
          setEndDate(end);
        }}
      />
    </div>;
  };

  const renderGuestCategoriesPicker = (): JSX.Element => {
    return (
      <div className="col-auto">
        <FilterDropdown
          id="guest_category"
          disabled={!!eventToCompare} // can't filter by categories when comparing events
          items={categories}
          selectedItemIds={selectedCategoryIds}
          translationKey="guest_category"
          onChange={(ids): void => setSelectedCategoryIds(ids)}
          showCells={false}
          itemColorKey="label_color"
          showBadgeNbItems={true}
        />
      </div>
    );
  };

  const renderPopulationPicker = (): JSX.Element => {
    return <div className="col">
      <PopulationTypePicker
        disabled={!!eventToCompare} // can't filter by population when comparing events
        guestCategories={categories}
        selectedGuestCategoryIds={selectedCategoryIds}
        setSelectedGuestCategoryIds={(ids): void => setSelectedCategoryIds(ids)}
        showCells={false}
        showBadgeNbItems={true}/>
    </div>;
  };

  const renderEventToComparePicker = (): JSX.Element => {
    if (!event.fetched) return null;

    return <div className="col-auto">
      <EventPicker
        onSelectEvent={(event): void => {
          setSelectedCategoryIds([]);
          setEventToCompare(event);
        }}
        accountId={event.account_id}
        emptyTitle={I18n.t("react.event_reports.report_header.pick_event_to_compare")}
        ignoreEventsWithIds={[event._id]}
        initialEventId={eventToCompare?._id}
      />
    </div>;
  };

  const renderFilters = (): JSX.Element => {
    return <div className="mb-3 row g-2">
      {renderPeriodDatePicker()}
      {renderGuestCategoriesPicker()}
      {renderPopulationPicker()}
      {renderEventToComparePicker()}
    </div>;
  };

  const formatAmount = (amount: number): string => {
    return ` ${Intl.NumberFormat(I18n.locale).format(amount)}`;
  };

  const emptyDataset = (rawData: any, coloringStrategy: ColoringStrategy): Dataset => {
    return { documents: rawData, labels: [], data: [], yAxesValueFormatter: formatAmount, coloringStrategy, colors: [] };
  };

  const comparisonDataEndpoint = (): string|null => {
    if (!eventToCompare || !event) return null;

    const [start, end] = dateRangeRelativeToEvent(startDate, endDate, event, eventToCompare);
    const params = {};
    if (start) params["start_date"] = start;
    if (end) params["end_date"] = end;

    return statEndpoint("by_day", params, eventToCompare);
  };

  const renderByDayChart = (): JSX.Element => {
    return <div className="card">
      <Chart
        title={i18n("by_day")}
        dataEndpoint={statEndpoint("by_day")}
        comparedDataEndpoint={comparisonDataEndpoint()}
        type="bar"
        convertToDataset={(data: any, isComparisonData: boolean): Dataset => {
          const ds = data.reduce((acc, entry) => {
            acc.labels.push(moment(entry[DATE_KEY]).format(BY_DAY_DATE_DISPLAY_FMT));
            acc.data.push(entry[AMOUNT_KEY]);
            return acc;
          }, emptyDataset(data, ColoringStrategy.Same));

          ds.label = isComparisonData ? eventToCompare.title : event.title;

          const baseEvent = isComparisonData ? eventToCompare : event;
          ds.multiDatasetsLabels = convertDatesToRelativeDaysToEvent(ds.labels, BY_DAY_DATE_DISPLAY_FMT, baseEvent);

          return ds;
        }}
        exportOptions={{
          columnsMapping: { day: { documentKey: DATE_KEY }, amount: { documentKey: AMOUNT_KEY, total: true } },
          type: "sales_by_day"
        }}
        xScaleHelp={eventToCompare && i18n("by_day_comparison_x_scale_help")}
      />
    </div>;
  };

  const renderByPaymentMethodChart = (): JSX.Element => {
    return <div className="card">
      <Chart
        title={i18n("by_payment_method")}
        dataEndpoint={statEndpoint("by_payment_method")}
        type="doughnut"
        chartHeight={400}
        convertToDataset={(data: any): Dataset => {
          const total = data.reduce((sum, entry) => sum + entry[AMOUNT_KEY], 0);

          return data.reduce((acc, entry) => {
            const amount = entry[AMOUNT_KEY];
            const percent = ((amount / total) * 100.0).toFixed(2);

            acc.labels.push(`${I18n.t(entry[PAYMENT_METHOD_KEY])} (${percent}%)`);
            acc.data.push(entry[AMOUNT_KEY]);
            return acc;
          }, emptyDataset(data, ColoringStrategy.Random));
        }}
        exportOptions={{
          columnsMapping: { payment_mean: { documentKey: PAYMENT_METHOD_KEY, i18nPrefix: "" }, amount: { documentKey: AMOUNT_KEY, total: true } },
          type: "sales_by_payment_method"
        }}
      />
    </div>;
  };

  const renderByItemChart = (): JSX.Element => {
    return <div className="card">
      <Chart
        title={i18n("by_item")}
        dataEndpoint={statEndpoint("count_paid", { inject_names_and_colors: true })}
        type="bar"
        chartHeight={400}
        convertToDataset={(data: any): Dataset => {
          const dataset = data.reduce((acc, entry) => {
            acc.labels.push(entry[NAME_KEY]);
            acc.data.push(entry["total_price_incl_taxes"]);
            acc.colors.push(entry[COLOR_KEY]);
            return acc;
          }, emptyDataset(data, ColoringStrategy.Defined));

          return compressDataset(dataset, BY_ITEM_MAX_NUMBER_OF_ITEMS, i18n("others"));
        }}
        exportOptions={{
          columnsMapping: { name: { documentKey: NAME_KEY }, amount: { documentKey: "total_price_incl_taxes", total: true } },
          type: "sales_by_item"
        }}
      />
    </div>;
  };

  return <div>
    <DashboardNavTab active="sales" event_has_exits={event.has_exits} />
    {renderHeaderStats()}
    {renderFilters()}
    {readyToQuery && (
      <>
        {renderByDayChart()}
        <div className="row mt-30">
          <div className="col-md-3">{renderByPaymentMethodChart()}</div>
          <div className="col-md-9">{renderByItemChart()}</div>
        </div>
      </>

    )}
  </div>;
};

export default SalesDashboard;
