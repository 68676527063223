import { Component } from "react";
import PropTypes from "prop-types";

class NothingYet extends Component {
  render() {
    const { title, buttonTitle, action, href } = this.props;
    return (
      <div className="row">
        <div className="col-md-12 mt-10">
          <div className="card nothing-yet">
            <div className="row">
              <div className="col-md-12">
                {title}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {href ? (
                  <a href={href} className="btn btn-success">{buttonTitle}</a>
                ) : (
                  <button className="btn btn-success" onClick={action}>{buttonTitle}</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


NothingYet.propTypes = {
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  action: PropTypes.func,
  href: PropTypes.string
};

export default NothingYet;
