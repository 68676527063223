import { Component } from "react";
import { FEATURES } from "../../constants/FeaturesSet";
import { featureName } from "../../utils/featureSetUtils";

class Menu extends Component {

  scrollToFeaturesGroup(groupKey): void {
    const featureOffsetTop = document.getElementById(groupKey).offsetTop;
    const slidingContent = document.getElementById("feature-set").closest(".slide-pane__content") || window;

    slidingContent.scrollTo({
      top: featureOffsetTop - 120,
      left: 0,
      behavior: "smooth"
    });
  }

  render(): JSX.Element {
    return <div id="feature-side-menu" className="feature-side-menu mt-2">
      <div className="card">
        <ul className="list-group">
          {Object.keys(FEATURES).map((groupKey) => {
            return <button
              key={ groupKey }
              onClick={ (): void => this.scrollToFeaturesGroup(groupKey) }
              className="list-group-item list-group-item-action capitalize">
              { featureName(groupKey) }
            </button>;
          })}
        </ul>
      </div>
    </div>;
  }
}

export default Menu;
