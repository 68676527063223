import { Component } from "react";
import { connect } from "react-redux";
import { DuplicationOperation } from "../../types/DuplicationOperation";
import { fetchDuplicationOperation } from "../../actions/DuplicationOperationActionCreator";
import { createFromEvent } from "../../actions/EventActionCreators";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import isEmpty from "lodash/isEmpty";

const WATCH_INTERVAL = 1000;

interface StoreProps {
  duplicationOperation: DuplicationOperation;
  duplicationOperationId: string;
  duplicationOperationErrors: boolean;
  duplicationOperationWarnings: boolean;
  duplicationOperationFetched: boolean;
  duplicationOperationPendingRequest: boolean;
}

interface DispatchProps {
  fetchDuplicationOperation(accountId: string, duplicationOperationId: string): void;
  createFromEvent(accountId: string, body: any, redirectTo?: string): any;
}

interface OwnProps {
  match: any;
  onDuplicationDone(newEventId: string, hasErrors: boolean, hasWarnings: boolean): void;
  sourceEventId: string;
  overrides: any;
  children: JSX.Element;
}

interface Props extends StoreProps, DispatchProps, OwnProps {}

class WaitingForEventDuplication extends Component<Props> {
  watchDuplicationOperationTimeout?: number;

  constructor(props) {
    super(props);
    this.watchDuplicationOperationTimeout = null;
  }

  componentDidMount(): void {
    this.startDuplication();
    this.waitForDuplicatedEvent();
  }

  componentDidUpdate(prevProps): void {
    const { onDuplicationDone } = this.props;
    if (!this.isDuplicationOver(prevProps) && this.isDuplicationOver(this.props) && onDuplicationDone) {
      const { duplicationOperation, duplicationOperationErrors, duplicationOperationWarnings } = this.props;
      onDuplicationDone(duplicationOperation.target_object_id, !isEmpty(duplicationOperationErrors), !isEmpty(duplicationOperationWarnings));
    }
  }

  startDuplication(): void {
    const { sourceEventId, createFromEvent, overrides, match } = this.props;

    createFromEvent(match.params.account_id, { event_id: sourceEventId, overrides });
  }

  waitForDuplicatedEvent(): void {
    clearTimeout(this.watchDuplicationOperationTimeout);
    const { fetchDuplicationOperation, duplicationOperationId, duplicationOperationPendingRequest, match } = this.props;

    setTimeout(() => {
      if (this.isDuplicationOver(this.props)) return;

      if (duplicationOperationId && !duplicationOperationPendingRequest) {
        fetchDuplicationOperation(match.params.account_id, duplicationOperationId);
      }

      this.waitForDuplicatedEvent();
    }, WATCH_INTERVAL);
  }

  isDuplicationStarted(): boolean {
    const { duplicationOperation, duplicationOperationPendingRequest, duplicationOperationFetched } = this.props;

    return !!duplicationOperation || duplicationOperationFetched || duplicationOperationPendingRequest;
  }

  isDuplicationOver(props): boolean {
    const { duplicationOperation, duplicationOperationFetched, duplicationOperationErrors } = props;

    return duplicationOperationFetched && ["success", "error"].includes(duplicationOperation.status) || !!duplicationOperationErrors;
  }

  render(): JSX.Element {
    const { duplicationOperationErrors, duplicationOperationWarnings } = this.props;

    return <>
      { window["ReactGlobalProps"]["super_admin"] ? <ErrorMessage errors={duplicationOperationErrors} /> : "" }
      <ErrorMessage errors={duplicationOperationWarnings} errorType="warning" errorsHeaderMessage={I18n.t("warnings_header_message")} />
      {this.props.children}
    </>;
  }
}

function mapStateToProps(state: any): StoreProps {
  return {
    duplicationOperation: state.duplicationOperation.data,
    duplicationOperationId: state.duplicationOperation.data && state.duplicationOperation.data._id,
    duplicationOperationErrors: state.duplicationOperation.errors,
    duplicationOperationWarnings: state.duplicationOperation.warnings,
    duplicationOperationFetched: state.duplicationOperation.fetched,
    duplicationOperationPendingRequest: state.duplicationOperation.pendingRequest
  };
}

const mapDispatchToProps: DispatchProps = {
  fetchDuplicationOperation,
  createFromEvent
};

export default connect<StoreProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(WaitingForEventDuplication);
