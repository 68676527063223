import { Component } from "react";

class PromoCodeType extends Component {

  render() {
    return (
      <div className="mb-3 row">
        <div className="col-sm-7">
          <input type="text" className="form-control form-control-sm" placeholder={ I18n.t("react.form_text_field.answer") } readOnly/>
        </div>
      </div>
    );
  }
}

export default PromoCodeType;
