import React from "react";
import IntegrationsData from "../../../config/integration_set.yml";
import { IMAGE_URLS } from "../../constants/ImageUrls";
import { IntegrationData, IntegrationConfigurations } from "./types/IntegrationTypes";
import { Link } from "react-router-dom";
import { pathToNewOrEditIntegration, pathToReactEditIntegration, pathToZapierIntegration } from "../../utils/pathUtils";
import TextWithLineBreaks from "../shared/TextWithLineBreaks.react";

interface Props {
  integrationsGroup: string[];
  integrationConfigurations: IntegrationConfigurations;
  i18n: (key: string) => string;
}

function i18n(key: string): string {
  return I18n.t(`react.integrations.index.${key}`);
}

function renderStatus(enabled: boolean, integration: string): JSX.Element {
  if (integration === "salesforce") {
    return <span className="badge rounded-pill" style={{ backgroundColor: "#F5F5F5", color: "#585858" }}>{i18n("upcoming")}</span>;
  } else if (enabled) {
    return <span className="badge rounded-pill bg-success"><i className="fa fa-regular fa-check"></i> {i18n("enabled")}</span>;
  }
}

const Group: React.FC<Props> = ({ integrationsGroup, integrationConfigurations, i18n }) => {
  const newOrEditIntegrationLink = (integrationData: IntegrationData, integration: string): JSX.Element => {
    if (integration === "salesforce") return null;
    if (integration === "zapier") return <a href={pathToZapierIntegration()} className="stretched-link"></a>;
    if (!integrationConfigurations) return;

    const integrationConfiguration = integrationConfigurations[integration];
    if (!integrationConfiguration) return;

    const action = integrationConfiguration.enabled ? "edit" : "new";

    return integrationData.react_path
      ? <Link to={pathToReactEditIntegration(integrationData.endpoint)} className="stretched-link"></Link>
      : <a href={pathToNewOrEditIntegration(`${integrationData.endpoint}/${action}`)} className="stretched-link"></a>;
  };

  const subGroups = integrationsGroup.map((integration: string) => {
    const integrationData = IntegrationsData.integrations[integration];
    const enabled = !!integrationConfigurations[integration].enabled;
    const activeOrInactiveCard = (enabled: boolean, integration: string): string => {
      if (integration === "salesforce") return "card inactive";

      return `card ${enabled ? "active" : ""}`;
    };

    return <div key={ integration } className="col-md-4 col-sm-12">
      <div className="d-flex flex-column">
        <div className={activeOrInactiveCard(enabled, integration)}>
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <span className="card mb-0 icon-feature d-flex" style={{ overflow: "hidden" }}>
                  <img src={IMAGE_URLS[integration]} style={{ width: "48px" }}/>
                </span>
                <div className="d-flex">
                  <h3 className="ml-10 mb-0">{ integrationData.name }</h3>
                </div>
              </div>
              <div>{ renderStatus(enabled, integration) }</div>
            </div>
            <p><TextWithLineBreaks text={i18n(`short_description.${integration}`)} /></p>
            {newOrEditIntegrationLink(integrationData, integration)}
          </div>
        </div>
      </div>
    </div>;
  });

  return <div className="row">
    { subGroups }
  </div>;
};

export default Group;
