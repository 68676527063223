import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isAuthorized } from "../../utils/aclUtils";
import { isEnabled } from "../../utils/featureSetUtils";
import { PAYMENT } from "../../constants/FeaturesSet";
import { fetchCartItemsStatistics, clearCartItemsStatistics } from "../../actions/GuestsStatisticsActionCreators";
import CartItemsStatTable from "../../components/event_reports/CartItemsStatTable.react";
import Loader from "../../components/shared/Loader.react";
import { pathToReports } from "../../utils/pathUtils";
import { Link } from "react-router-dom";
import ExportToExcelLink from "../../components/ExportToExcelLink.react";
import SegmentPicker from "../shared/SegmentPicker.react";
import DateRangePicker from "../../components/DateRangePicker.react";
import moment, { Moment } from "moment";
import { CartItemStatType } from "./types/CartItemStatType";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import { CART_ITEMS_STATS_HEADERS } from "../../constants/Constants";

const DEFAULT_DATE_FORMAT = "YYYY-MM-DD HH:mm";
const READABLE_DATE_FORMAT = "LLLL";
export const COLUMNS_WITH_CURRENCY = [
  "price_excl_vat",
  "vat",
  "price_incl_taxes",
  "discount_amount",
  "refund_amount",
  "total_excl_taxes",
  "total_incl_taxes"
];

interface Props {
  match: any;
}

function i18n(key: string): string {
  return I18n.t(`react.event_reports.event_reports.${key}`);
}

const CartItemsStat: React.FC<Props> = ({ match }) => {
  if (!isAuthorized("reports", "read")) return null;
  if (!isEnabled(PAYMENT)) return null;

  const [startDate, setStartDate] = useState<Moment>(null);
  const [endDate, setEndDate] = useState<Moment>(null);
  const [query, setQuery] = useState<string>(null);
  const [excludeFreeItems, setExcludeFreeItems] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCartItemsStatistics(match.params.event_id, { exclude_free_items: excludeFreeItems }));
  }, []);

  useEffect(() => {
    const start = startDate ? startDate.format(DEFAULT_DATE_FORMAT) : null;
    const end = endDate ? endDate.format(DEFAULT_DATE_FORMAT) : null;
    dispatch(fetchCartItemsStatistics(match.params.event_id, { start_date: start, end_date: end, q: query, exclude_free_items: excludeFreeItems }));
  }, [startDate, endDate, query, excludeFreeItems]);

  const exportLink = (stats: CartItemStatType[]): JSX.Element => {
    return <div className="col-auto">
      <ExportToExcelLink data={{ data: stats }} columnKeys={CART_ITEMS_STATS_HEADERS} type="cart_item_sales" options={{ excludeTotal: true }}/>
    </div>;
  };

  const changeQuery = (segment: any): any => {
    const nextQuery = segment ? segment.search_query : null;
    if (nextQuery === query) {
      return;
    }
    dispatch(clearCartItemsStatistics());
    setQuery(nextQuery);
  };

  const renderDateRangePicker = (): JSX.Element => {
    return (
      <DateRangePicker
        startDate={startDate ? moment(startDate) : startDate}
        endDate={endDate ? moment(endDate) : endDate}
        onDateRangeChanged={(start, end): void => {
          setStartDate(start && moment(start.format(DEFAULT_DATE_FORMAT)));
          setEndDate(end && moment(end.format(DEFAULT_DATE_FORMAT)));
        }}
        showClearButton={false}
      />
    );
  };

  const renderExcludeFreeItemsCheckbox = (): JSX.Element => {
    return <div className="form-check mb-0 d-flex align-items-center">
      <label className="form-check-label">
        <input type="checkbox" className="form-check-input" checked={excludeFreeItems} onChange={(): void => setExcludeFreeItems(!excludeFreeItems)} />
        {i18n("exclude_free_items")}
      </label>
    </div>;
  };

  const renderHeader = (stats: CartItemStatType[]): JSX.Element => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="subtitle-page">
            <div className="subtitle-page-content mb-3">
              <div className="row align-items-center g-2">
                <div className="col-auto">
                  <h2 className="mb-0">
                    <Link to={pathToReports()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></Link>
                    {i18n("sales_by_cart_item")}
                  </h2>
                </div>
                {exportLink(stats)}
              </div>
              <div className="row align-items-center g-2">
                <div className="col-auto">
                  <SegmentPicker onSelectSegment={changeQuery} defaultSegmentQuery={query} eventId={match.params.event_id} />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap">
              {renderDateRangePicker()}
              {renderExcludeFreeItemsCheckbox()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const stats = useSelector((state: any) => state.cartItemsStatistics.items);
  const errors = useSelector((state: any) => state.cartItemsStatistics.errors);

  const processStats = (stats: CartItemStatType[]): CartItemStatType[] => {
    if (!stats) return null;

    const copiedStats = structuredClone(stats); //to provide passing processed datas to ExportToExcelLink

    return copiedStats.map((stat: CartItemStatType) => {
      const formatter = new Intl.NumberFormat(I18n.currentLocale(), { style: "currency", currency: stat.currency });

      stat["date"] = moment(stat.date).format(READABLE_DATE_FORMAT);
      COLUMNS_WITH_CURRENCY.map((columnName: string) => {
        stat[columnName] = formatter.format(parseFloat(stat[columnName]));
      });
      return stat;
    });
  };

  const processedStats = processStats(stats);

  const renderErrorsMessage = (): JSX.Element => {
    if (!errors) return null;

    return <ErrorMessage errors={errors} />;
  };

  return (
    <div>
      {renderErrorsMessage()}
      {renderHeader(stats)}
      {!stats && !errors ? <Loader /> : <CartItemsStatTable stats={processedStats || []} />}
    </div>
  );
};

export default CartItemsStat;
