import { ActionTypes } from "../constants/Constants";

const initialState = {
  data: null,
  pendingRequest: false,
  errors: null,
  isUpdating: false,
};

function featureSet(state = initialState, action) {
  switch (action.type) {

  case ActionTypes.FETCH_FEATURE_SET_REQUEST: {
    return { ...initialState, pendingRequest: true };
  }
  case ActionTypes.CREATE_FEATURE_SET_SUCCESS:
  case ActionTypes.UPDATE_FEATURE_SET_SUCCESS:
  case ActionTypes.FETCH_FEATURE_SET_SUCCESS: {
    return { ...state, data: action.data, pendingRequest: false, isUpdating: false };
  }
  case ActionTypes.CREATE_FEATURE_SET_FAILURE:
  case ActionTypes.UPDATE_FEATURE_SET_FAILURE:
  case ActionTypes.FETCH_FEATURE_SET_FAILURE: {
    return { ...state, errors: action.data, pendingRequest: false, isUpdating: false };
  }
  case ActionTypes.UPDATE_FEATURE_SET_REQUEST:
  case ActionTypes.CREATE_FEATURE_SET_REQUEST: {
    return { ...state, pendingRequest: true, errors: null, isUpdating: true };
  }
  default:
    return state;
  }
}

export default featureSet;
