import { Program, FilterContext } from "../types/Program";
import { ProgramFilterTypes } from "../constants/Constants";

export function availableFilterTypes(program: Program, context: FilterContext): string[] {
  const filterTypes: string[] = Object.values(ProgramFilterTypes);

  return filterTypes.filter(filterType => {
    const programFilter = program.program_filters[filterType];
    if (!programFilter || programFilter._destroy) return true; // not used

    if (context === "selection") {
      // used as a UI filter only
      return Object.keys(programFilter.preset_args).length == 0 && programFilter.displayed_to_user;
    } else if (context === "ui") {
      // used as a selection filter only
      return Object.keys(programFilter.preset_args).length > 0 && !programFilter.displayed_to_user;
    }
  });
}
