import { useState, useMemo, useEffect } from "react";
import { fabric } from "fabric";
import { FabricEditorFunctions, StackingOrder } from "../types";
import { addText, addImage, addDottedLines, addBackground, createDefaultElementsOnCanvas, removeSelectedObject, updateGroupAlignment,
  selectObject, updateActiveObjectStacking } from "../tools/editorFunctions";
import { defineQrcodeClass } from "../qrcodeClass";
import { BadgeImage } from "../../../../types/BadgeTemplate";
import { initAlignGuidelines } from "../tools/alignGuidelines";
import { BADGE_IMAGE_POSITIONS } from "./../../Constants";

interface FabricEditorHook {
  selectedObjects?: fabric.Object[];
  editor?: FabricEditorFunctions;
  onReady(canvas: fabric.Canvas): void;
}

const buildEditor = (canvas: fabric.Canvas, defaultEditorProperties): FabricEditorFunctions => {
  return {
    addText: (text: string): void => {
      addText(canvas, defaultEditorProperties, text);
    },
    addImage: (url: string, imageKey: string): void => {
      addImage(canvas, url, imageKey);
    },
    addDottedLines: (): void => {
      addDottedLines(canvas);
    },
    unselectAll: (): void => {
      canvas.discardActiveObject().renderAll();
    },
    addBackground: (url: string, imageKey: string, badgeFormat: string): void => {
      addBackground(canvas, url, imageKey, badgeFormat);
    },
    addQRCode: (): void => {
      const qrCode = new fabric.Qrcode();
      canvas.add(qrCode);
    },
    removeSelectedObject: (): void => {
      removeSelectedObject(canvas);
    },
    exportToJSON: (): any => {
      const json = canvas.toObject();
      json.objects = json.objects.filter(item => item.type !== "line");
      return json;
    },
    loadFromJSON: (canvasJSONString: string): void => {
      canvas.loadFromJSON(canvasJSONString, canvas.renderAll.bind(canvas));
    },
    updateProperty: (selectedObject: fabric.Object, propertyName: string, value: string, isNumeric: boolean): void => {
      selectedObject.set(propertyName, isNumeric ? parseInt(value) : value);
      canvas.requestRenderAll();
    },
    updateGroupAlignment: (selectedObjects: fabric.Object[], value: string): void => {
      updateGroupAlignment(canvas, selectedObjects, value);
    },
    selectObject: (key: string): boolean => {
      return selectObject(canvas, key);
    },
    updateActiveObjectStacking: (action: StackingOrder): void => {
      updateActiveObjectStacking(canvas, action);
    },
    createDefaultElementsOnCanvas: (elements, badgeImages: BadgeImage[], badgeFormat: string): void => {
      createDefaultElementsOnCanvas(canvas, elements, badgeImages, badgeFormat);
    }
  };
};

const useFabricEditor = (
  defaultEditorProperties = {}
): FabricEditorHook => {
  const [canvas, setCanvas] = useState<null | fabric.Canvas>(null);
  const [selectedObjects, setSelectedObject] = useState<fabric.Object[]>([]);

  const bindEvents = (canvas: fabric.Canvas): void => {
    canvas.on("selection:cleared", () => {
      setSelectedObject([]);
    });
    canvas.on("selection:created", (e: any) => {
      setSelectedObject(e.selected);
    });
    canvas.on("selection:updated", (e: any) => {
      // If we select several elements with the mouse, selected contains all the elements.
      // But if we add them one by one using the Shift key, this is not the case.
      // In this case, we need to test the group property of the first element.
      setSelectedObject(e.selected[0]?.group ? e.selected[0]?.group._objects : e.selected);
    });
    canvas.on("object:modified", (e: any) => {
      setSelectedObject([e.target]);
      canvas.setActiveObject(e.target);
    });
    canvas.on("object:added", (e: any) => {
      setSelectedObject([e.target]);
      if (BADGE_IMAGE_POSITIONS.includes(e.target.imageKey)) {
        e.target.set({
          lockMovementY: true,
          lockMovementX: true,
          hasControls: false,
          borderColor: "red"
        });
      } else if (e.target.type === "textbox") {
        e.target.setControlsVisibility({
          mb: false,
          mt: false,
          bl: false,
          br: false,
          tl: false,
          tr: false,
          mtr: false
        });
      }
      canvas.setActiveObject(e.target);
    });
    canvas.on("object:scaling", (e: any) => {
      setSelectedObject([e.target]);
      // Keep image ratio on scaling
      const object = e.target;
      object.scaleX = object.scaleY;
    });
  };

  useEffect(() => {
    if (!canvas) return;

    bindEvents(canvas);
  }, [canvas]);

  const editor = useMemo(() => {
    if (!canvas) return undefined;

    initAlignGuidelines(canvas);
    return buildEditor(canvas, defaultEditorProperties);
  }, [canvas]);

  const onReady = (canvasReady: fabric.Canvas): void => {
    setCanvas(canvasReady);
    defineQrcodeClass(canvasReady);
    // Change rounded value to fix scale issue on save
    fabric.Object.NUM_FRACTION_DIGITS = 17;
  };

  return {
    selectedObjects,
    onReady,
    editor,
  };
};

export default useFabricEditor;
