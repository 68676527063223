import { Component, ChangeEvent } from "react";
import { CreateNewAccesspointId } from "../../constants/Constants";
import { FormItemOption } from "../../types/FormItemOption";
import { Accesspoint } from "../../types/Accesspoint";

interface Props {
  accesspoints: Accesspoint[];
  itemOption: FormItemOption;
  updateHandler(params: any): void;
  destroyHandler(id: string): void;
  allowDestroy: boolean;
  shouldBeDisplayed: boolean;
}

interface State {
  key: string;
}

class AccommodationOption extends Component<Props, State> {
  constructor(props) {
    super(props);
    ["onKeyChange", "destroy"].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      key: props.itemOption.key,
    };
  }

  onKeyChange(e: ChangeEvent<any>): void {
    const { updateHandler, accesspoints, itemOption } = this.props;
    const accesspoint = accesspoints.find(accesspoint => accesspoint.id == e.target.value);

    if (!accesspoint)
      return; // when hitting the pick a value in the dropdown, just do nothing

    const key = accesspoint.id;
    const label = accesspoint.display_name;
    updateHandler({ ...itemOption, key, label });

    // don't need to change the state with this value
    if (CreateNewAccesspointId !== e.target.value)
      this.setState({ key });
  }

  destroy(): void {
    const { itemOption, destroyHandler } = this.props;
    destroyHandler(itemOption._id);
  }

  renderAccesspointsDropdown(): JSX.Element {
    const { accesspoints, shouldBeDisplayed } = this.props;
    const { key } = this.state;
    const disabledClass = shouldBeDisplayed ? "" : "disabled";

    const options = accesspoints.map(accesspoint => {
      return <option key={ accesspoint.id } value={ accesspoint.id }>{ accesspoint.name }</option>;
    });

    return <select className={`form-select ${disabledClass}`} value={key} onChange={this.onKeyChange}>
      <option key="default" value=""> { I18n.t("react.form_question_item.pick_an_accommodation") }</option>
      {options}
    </select>;
  }

  renderDestroyButton(): JSX.Element {
    const { allowDestroy } = this.props;

    if (!allowDestroy) return null;

    return <a className="btn btn-secondary" onClick={this.destroy} style={{ cursor: "pointer" }}>
      <i className="fa-regular fa-close"></i>
    </a>;
  }

  render(): JSX.Element {
    return <div className="row mb-10">
      <div className="col-11">
        { this.renderAccesspointsDropdown() }
      </div>
      <div className="col-1">
        { this.renderDestroyButton() }
      </div>
    </div>;
  }
}

export default AccommodationOption;
