import { ActionTypes } from "../constants/Constants";

const defaultState = {
  data: null,
  selectedTranslation: null,
  currentTranslatableFieldsHash: null,
  selectedLocale: null,
  errors: null,
  availableLocales: null
};

function translations(state = defaultState, action) {
  switch (action.type) {
  case ActionTypes.DELETE_TRANSLATIONS:
  case ActionTypes.FETCH_TRANSLATIONS:
    return Object.assign({}, state, { data: null, errors: null });
  case ActionTypes.FETCH_TRANSLATIONS_SUCCESS:
    return Object.assign({}, state, { data: action.data, errors: null });
  case ActionTypes.FETCH_TRANSLATIONS_FAILURE:
    return Object.assign({}, state, { errors: action.errors });
  case ActionTypes.AUTO_TRANSLATIONS:
    return { ...state, loading: true, errors: null };
  case ActionTypes.CREATE_TRANSLATIONS:
  case ActionTypes.UPDATE_TRANSLATIONS:
    return Object.assign({}, state, { errors: null });
  case ActionTypes.AUTO_TRANSLATIONS_SUCCESS:
  case ActionTypes.UPDATE_TRANSLATIONS_SUCCESS: {
    const data = state.data.slice();
    const translationIndex = data.findIndex(el => el._id == action.data._id);
    if (translationIndex > -1) {
      data[translationIndex] = action.data;
    } else {
      data.push(action.data);
    }
    return Object.assign({}, state, {
      data,
      selectedTranslation: action.data,
      errors: null,
      loading: false,
    });
  }
  case ActionTypes.DELETE_TRANSLATIONS_SUCCESS:
    return Object.assign({}, state, { errors: null, selectedTranslation: null });
  case ActionTypes.CLEAR_TRANSLATION_STATE:
    return Object.assign({}, state, { errors: null, selectedTranslation: null, selectedLocale: null, currentTranslatableFieldsHash: null });
  case ActionTypes.CREATE_TRANSLATIONS_SUCCESS: {
    let data = state.data.slice() || [];
    data.push(action.data);
    return Object.assign({}, state, { data, selectedTranslation: action.data, errors: null });
  }
  case ActionTypes.AUTO_TRANSLATIONS_FAILURE:
  case ActionTypes.DELETE_TRANSLATIONS_FAILURE:
  case ActionTypes.UPDATE_TRANSLATIONS_FAILURE:
  case ActionTypes.CREATE_TRANSLATIONS_FAILURE:
    return Object.assign({}, state, { errors: action.data, loading: false });
  case ActionTypes.RECEIVE_EVENT:
    return Object.assign({}, state, { availableLocales: action.event.available_frontend_locales.filter(key => key != action.event.locale) });
  case ActionTypes.UPDATE_EVENT_SUCCESS: {
    const newAvailableFrontendLocales = action.data.available_frontend_locales.filter(key => key !== action.data.locale);
    if (state.availableLocales !== newAvailableFrontendLocales) {
      return { ...state, availableLocales: newAvailableFrontendLocales };
    } else {
      return state;
    }
  }
  case ActionTypes.OFFLINE_SET_TRANSLATION_TABLE: {
    let selectedTranslation = Object.assign({}, state.selectedTranslation);
    selectedTranslation.table = action.table;
    return Object.assign({}, state, { selectedTranslation });
  }
  case ActionTypes.FETCH_TRANSLATABLE_FIELDS_SUCCESS: {
    const data = action.data || action.object;
    return Object.assign({}, state, {
      currentTranslatableFieldsHash: data.translatable_fields,
      parentObject: data
    });
  }
  case ActionTypes.SELECT_TRANSLATION: {
    const selectedLocale = action.locale;
    const selectedTranslation = state.data && state.data.find(translation => translation.locale === selectedLocale) || { table: {} };
    return Object.assign({}, state, {
      selectedLocale,
      selectedTranslation,
      errors: null
    });
  }
  default:
    return state;
  }
}

export default translations;
