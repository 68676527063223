import { useState, FormEvent, ChangeEvent } from "react";
import { Workflow } from "../../types/Workflow";
import { UI_COLORS } from "../../constants/Constants";

interface Props {
  workflow: Workflow;
  disabled?: boolean;
  onSave(attributes: any): void;
}

const WorkflowNameForm: React.FC<Props> = ({ workflow, disabled, onSave }) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [name, setName] = useState<string>(workflow.name);

  const toggleForm = (): void => {
    if (editing) {
      onSave({ name });
    }
    setEditing(!editing);
  };

  const changeName = (e: ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value);
  };

  const submitForm = (e: FormEvent): void => {
    e.preventDefault();
    toggleForm();
  };

  if (disabled) {
    return <span className="d-sm-inline ml-15">
      <span style={{ color: UI_COLORS.grey900, fontWeight: 500 }}>{name}</span>
    </span>;
  }

  if (!editing) {
    return <span className="d-sm-inline ml-15" onClick={toggleForm} style={{ cursor: "pointer" }}>
      <span style={{ color: UI_COLORS.grey900, fontWeight: 500 }}>{name}</span> <i className="fa-regular fa-pencil" />
    </span>;
  }

  return <form className="row g-2 align-items-center ml-15" onSubmit={submitForm}>
    <div className="col-auto">
      <input type="text"
        className="form-control"
        value={name}
        onBlur={toggleForm}
        onChange={changeName}
        autoFocus={true}
        style={{ width: "450px", height: "28px", padding: "5px" }}
        required={true} />
    </div>
  </form>;
};

export default WorkflowNameForm;
