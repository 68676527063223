import { ActionTypes } from "../constants/Constants";
import { GuestPromoCodeInvitationStatsState } from "../types/GuestPromoCodeInvitationStats";

const defaultState: GuestPromoCodeInvitationStatsState = {
  errors: null,
  isFetching: false,
  data: null
};

export function guestPromoCodeInvitationStatistics(state = defaultState, action: any): GuestPromoCodeInvitationStatsState {
  switch (action.type) {
  case ActionTypes.FETCH_GUEST_PROMO_CODE_INVITATION_STATS_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.FETCH_GUEST_PROMO_CODE_INVITATION_STATS_SUCCESS:
    return { ...state, data: action.data, isFetching: false, errors: null };
  case ActionTypes.FETCH_GUEST_PROMO_CODE_INVITATION_STATS_FAILURE:
    return { ...state, data: [], isFetching: false, errors: action.data };
  default:
    return state;
  }
}

export default guestPromoCodeInvitationStatistics;
