import { Component } from "react";
import { translatedTextFromJson } from "../../utils/templatesBuilderUtils";

class AppearanceCategories extends Component {
  constructor(props) {
    super(props);
    [
      "onSelectAppearanceCategory",
      "close"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
  }

  onSelectAppearanceCategory(key) {
    return () => {
      this.props.onSelectAppearanceCategory(key);
    };
  }

  close() {
    this.props.toggleView();
  }

  mainSettingIcon(component) {
    return (
      <span><i className={`${component["icon"]} mr-10`}></i></span>
    );
  }

  render() {
    const { isShown } = this.props;
    if (!isShown) {
      return <div className="website-appearance"></div>;
    }
    const { mainSettingsSchema } = this.props;

    return (
      <div className="website-appearance in">
        <div className="sidebar-header">
          <p className="lead">
            <a onClick={ this.close } className="btn-back mr-5 text-center"><i className="fa-regular fa-angle-left"></i></a>
            { I18n.t("react.website.main_appearance") }
          </p>
        </div>

        <div className="sidebar-body">
          <div className="card">
            <ul className="list-group">
              { mainSettingsSchema.map(component => {
                return (
                  <li className="list-group-item" onClick={this.onSelectAppearanceCategory(component.id)} key={component.id}>
                    { this.mainSettingIcon(component) } { translatedTextFromJson(component, "name") }
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default AppearanceCategories;
