// JavaScript store the delay as a 32-bit signed Integer internally.
// This causes an Integer overflow when using delays larger than 2147483647 ms, resulting in the timeout being executed immediately.
export function setSafeTimeout(delay: number, callback: any): number {
  if (delay >= Math.pow(2, 31)) return;

  setTimeout(() => {
    callback();
  }, delay);
}

export function populationI18n(population: string): string {
  return I18n.t(`population_type.${population}`, { defaultValue: population });
}
