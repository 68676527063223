import { Component } from "react";
import { connect } from "react-redux";
import { EventStoreProps } from "../../types/Event";
import { fetchSavedSearches } from "../../actions/SavedSearchActionCreators";
import NetworkingRulesForm from "../../components/engagement/networking_rules/NetworkingRulesForm.react";
import { SavedSearch } from "../../types/SavedSearch";
import Checkbox from "../../components/shared/Checkbox.react";
import HelpSection from "../../components/shared/HelpSection.react";

interface Props {
  event: EventStoreProps;
  segments?: SavedSearch[];
  featureScopes: string[];
  rules: any;
  strictNetworkingRulesEnabled: boolean;

  onChange: (values: { strictNetworkingRulesEnabled?: boolean, rules?: any }) => void;

  // dispatch
  fetchSavedSearches?: (eventId: string) => void;
}

class NetworkingRules extends Component<Props> {
  i18n(key: string): string {
    return I18n.t(`react.networking.networking_rules.${key}`);
  }

  componentDidMount(): void {
    const { fetchSavedSearches, event } = this.props;
    fetchSavedSearches(event.id);
  }

  render(): JSX.Element {
    const { event, segments, rules, featureScopes, onChange, strictNetworkingRulesEnabled } = this.props;
    return <>
      <HelpSection help={this.i18n("help")} classNames="mb-20" />

      <NetworkingRulesForm
        populations={event.population_types}
        featureScopes={featureScopes}
        segments={segments}
        networkingRules={rules}
        onChange={(rules: any): void => onChange({ rules })}
      />

      <HelpSection help={this.i18n("strict_networking_rules_help")} classNames="mt-20" />

      <Checkbox
        checked={strictNetworkingRulesEnabled}
        text={this.i18n("strict_networking_rules")}
        onChange={(): void => onChange({ strictNetworkingRulesEnabled: !strictNetworkingRulesEnabled })}
      />
    </>;
  }
}

function mapStateToProps(state: any): any {
  return {
    segments: state.savedSearches.data
  };
}

const mapDispatchToProps = {
  fetchSavedSearches
};

export default connect(mapStateToProps, mapDispatchToProps)(NetworkingRules);
