import { Component } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";

import { pathToGuestShow } from "../../utils/pathUtils";

class StatisticsTable extends Component {

  renderHead() {
    const ths = this.props.emailEvents.map(emailEvent => {
      return <th key={emailEvent} className={`${emailEvent}-color`}>{ I18n.t(`react.guest_campaigns.statistics.email_event.${emailEvent}`) }</th>;
    });
    return (
      <thead>
        <tr>
          <th>To</th>
          { ths }
          <th>{ I18n.t("react.guest_campaigns.statistics.timeline") }</th>
        </tr>
      </thead>
    );
  }

  renderTimelinePopover(activity) {
    const { timeline, guest } = activity;

    if (!timeline) {
      return <p>{ I18n.t("react.guest_campaigns.statistics.no_timeline") }</p>;
    }

    const popoverContent = timeline.map((info, index) => {
      return <li key={index}><strong>{ I18n.t(`react.guest_campaigns.statistics.email_event.${info.event}`) }</strong> - { I18n.l("time.formats.short", info.time) } { this.renderCcEmailIcon(guest, info) }</li>;
    });

    return (
      <Popover id="popver-id" title={ I18n.t("react.guest_campaigns.statistics.timeline") }>
        <ul className="list-unstyled" style={{ paddingRight: "10px", paddingLeft: "10px", marginTop: "15px" }}>
          { popoverContent }
        </ul>
      </Popover>
    );
  }

  renderCcEmailIcon(guest, info) {
    if (guest && guest.email_cc && info.email && guest.email_cc.split(",").includes(info.email)) {
      return <i className="fa-regular fa-cc"></i>;
    }

    return null;
  }

  renderBodyRows() {
    const { receiverActivitiesWithGuests, emailEvents } = this.props;
    if (receiverActivitiesWithGuests.length === 0) {
      return <tr><td colspan="100%" className="text-center"><em>{ I18n.t("react.guest_campaigns.statistics.no_guest") }</em></td></tr>;
    }
    return receiverActivitiesWithGuests.map((activityWithGuest, index) => {
      const tds = emailEvents.map((emailEvent, index) => {
        return <td key={index}>{ activityWithGuest[emailEvent] }</td>;
      });
      const { guest } = activityWithGuest;
      let firstTdContent = activityWithGuest.guest_id;
      if (guest) {
        firstTdContent = <a href={pathToGuestShow(guest.id)}>{ guest.email }</a>;
      }
      return (
        <tr key={index}>
          <td>{ firstTdContent }</td>
          { tds }
          <td>
            <OverlayTrigger trigger="click" placement="left" overlay={this.renderTimelinePopover(activityWithGuest)} rootClose={true}>
              <a className="btn btn-link">{ I18n.t("react.guest_campaigns.statistics.see_timeline") }</a>
            </OverlayTrigger>
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <div className="table-responsive table-container">
        <table className="table table-light table-hover table-sm">
          { this.renderHead() }
          <tbody>
            { this.renderBodyRows() }
          </tbody>
        </table>
      </div>
    );
  }
}

export default StatisticsTable;

StatisticsTable.propTypes = {
  receiverActivitiesWithGuests: PropTypes.array.isRequired,
  emailEvents: PropTypes.array.isRequired
};
