import { Component, ChangeEvent } from "react";
import { Button } from "react-bootstrap";
import { EmailTemplate } from "../../types/EmailTemplate";

interface Props {
  emailTemplate: EmailTemplate;
  isOpen: boolean;
  hide(): void;
  isSubmitting: boolean;
  onSubmit(data: any): void;
  errors: any;
}

interface State {
  name: string;
}

class EmailTemplateForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    [
      "saveEmailTemplate",
      "hideView"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      name: props.emailTemplate.name
    };
  }

  componentDidUpdate(prevProps: Props): void {
    const { emailTemplate } = this.props;

    if (prevProps.emailTemplate && emailTemplate.updated_at != prevProps.emailTemplate.updated_at) {
      this.setState({ name: emailTemplate.name });
    }
  }

  changeStandardField(field: string): ((e: ChangeEvent<any>) => void) {
    return (e: ChangeEvent<any>): void => {
      this.setState({
        [field]: e.target.value
      } as Pick<State, keyof State>);
    };
  }

  saveEmailTemplate(e): void {
    e.preventDefault();
    this.props.onSubmit(this.state);
  }

  hideView(): void {
    this.props.hide();
  }

  renderFieldError(field: string): JSX.Element {
    const { errors } = this.props;

    if (!errors[field])
      return null;

    return <span className="text-danger" style={{ "marginTop": "5px", "display": "inline-block" }}>{ errors[field] }</span>;
  }

  render(): JSX.Element {
    const { isOpen, isSubmitting } = this.props;

    if (!isOpen) {
      return <div><div className="website-page-form"></div></div>;
    }

    const { name } = this.state;

    return (
      <div>
        <div className="website-page-form in">
          <div className="sidebar-header">
            <p className="lead">
              <a onClick={ this.hideView } className="btn-back mr-5 text-center"><i className="fa-regular fa-angle-left"></i></a>
              { I18n.t("react.email_template.edit_email") }
            </p>
          </div>

          <div className="sidebar-body">
            <div className="card">
              <div className="card-body">
                <div className="mb-3">
                  <label className="form-label">{ I18n.t("react.email_template.name") }</label>
                  <input type="text" className="form-control" value={name || ""} onChange={ this.changeStandardField("name") } />
                  { this.renderFieldError("name") }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sidebar-footer">
          <Button variant="primary" onClick={ this.saveEmailTemplate } disabled={ isSubmitting }>
            { I18n.t("react.email_template.save") }
          </Button>
        </div>
      </div>
    );
  }
}

export default EmailTemplateForm;
