import DidomiPartnersPicker from "./DidomiPartnersPicker.react";
import StandardParners from "../../../../config/standard_partners.yml";
import { Partner } from "../../../types/Partner";

interface Props {
  eventId: string;
  existingPartners: Partner[];
  onNewPartner: (partner: Partner) => void;
}

const i18n = (key: string, opts = {}): string => {
  return I18n.t(`react.consent_notice.add_partner.${key}`, opts);
};

const generatePartnerId = (): string => {
  return `custom-partner-${(Math.random() + 1).toString(36).substring(7)}`;
};

const SELECT_STYLE = {
  marginLeft: "5px",
  marginRight: "5px",
  display: "inline-block",
  width: "200px"
};

const AddPartner: React.FC<Props> = ({
  eventId,
  existingPartners,
  onNewPartner
}) => {

  const createEmptyPartner = (): Partner => {
    const id = generatePartnerId();
    return {
      id,
      sdk_id: `c:${id}`,
      name: "",
      allow_edit: true,
      integration_script: "",
      default_purposes_id: [],
      user_defined_purposes_id: [],
      links: { privacypolicy: "" }
    };
  };

  const existingPartnerSdkIds = existingPartners.map(p => p.sdk_id);
  const standardPartners = StandardParners.filter(p => !existingPartnerSdkIds.includes(p.sdk_id));

  return (
    <>
      <label className="form-label">{i18n("title")}</label>
      <div className="flex-wrap d-flex align-items-center">
        {i18n("from_eventmaker_list")}
        <select
          style={SELECT_STYLE}
          className="form-control"
          value="empty"
          onChange={(e): void => {
            const partner = StandardParners.find(p => p.sdk_id === e.target.value);
            partner && onNewPartner(partner);
          }}
        >
          <option value="empty" key="empty">{I18n.t("please_choose")}</option>
          {standardPartners.map(p => {
            return <option key={p.sdk_id} value={p.sdk_id}>{p.name}</option>;
          })}
        </select>

        {i18n("or_from_the")} <a href="https://iabeurope.eu/transparency-consent-framework/" target="_blank" className="ml-5 mr-5">{i18n("iab_tcf_list")}</a>
        <DidomiPartnersPicker
          style={SELECT_STYLE}
          eventId={eventId}
          excludeSdkIds={existingPartnerSdkIds}
          onSelect={(partner): void => {
            onNewPartner(partner);
          }}
        />
        {i18n("or")} <a href="#" className="btn btn-secondary ml-5" onClick={(e): void => {
          e.preventDefault();
          onNewPartner(createEmptyPartner());
        }}><i className="fa-regular fa-plus"></i> {i18n("create_one")}</a>
      </div>
    </>
  );
};

export default AddPartner;
