import { Component } from "react";
import { connect } from "react-redux";
import findKey from "lodash/findKey";
import pickBy from "lodash/pickBy";
import startsWith from "lodash/startsWith";
import indexOf from "lodash/indexOf";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import { urlEventId, pathToWebsiteBuilder, pathToWebsiteView } from "../utils/pathUtils";
import { addTooltip, addSection } from "../utils/templatesBuilderUtils";
import { isEnabled } from "../utils/featureSetUtils";
import { FORM_PAGE_REGEX, CONFIRMATION_PAGE_REGEX, OFFLINE_COLOR, RESOURCE_LIST_SECTIONS } from "../constants/Constants";
import { MOBILE_APP } from "../constants/FeaturesSet";
import Icons from "../constants/Icons";
import withTemplatesBuilder from "./withTemplatesBuilder.react";
import Loader from "../components/shared/Loader.react";
import SectionView from "../components/templates_builder/SectionView.react";
import SectionsTypesList from "../components/templates_builder/SectionsTypesList.react";
import SectionsList from "../components/templates_builder/SectionsList.react";
import WebsitePageForm from "../components/website/WebsitePageForm.react";
import WebsitePagesList from "../components/website/WebsitePagesList.react";
import WebsiteMenusList from "../components/website/WebsiteMenusList.react.tsx";
import WebsiteMenuForm from "../containers/WebsiteMenuForm.react";
import MobileAppHomeScreen from "../components/website/MobileAppHomeScreen.react";
import NoticeBlock from "../components/templates_builder/NoticeBlock.react";
import AppearanceCategories from "../components/templates_builder/AppearanceCategories.react";
import AppearanceSubsection from "../components/templates_builder/AppearanceSubsection.react";
import ThemeReloader from "../components/templates_builder/ThemeReloader.react";
import PageSectionDuplicationView from "../components/website/PageSectionDuplicationView.react";
import * as WebsiteActionCreators from "../actions/WebsiteActionCreators";
import { fetchEventGuestFields } from "../actions/GuestFieldsActionCreators";
import { fetchGuestProductCollections } from "../actions/GuestProductCollectionsActionCreators";
import { fetchSavedSearches } from "../actions/SavedSearchActionCreators";
import { OverlayTrigger, Button } from "react-bootstrap";
import SlidingPane from "react-sliding-pane";
import { redirectIfUnauthorized } from "../utils/aclUtils";
import { hasAvailableFeature } from "../utils/availableFeature";

class WebsiteBuilder extends Component {
  constructor(props) {
    redirectIfUnauthorized("configuration", "manage");
    super(props);
    [
      "updateSectionsOrder",
      "saveWebsite",
      "receiveNewChanges",
      "receiveSectionChanges",
      "receiveAppHomeScreenChanges",
      "createNewSection",
      "togglePagesSwitchView",
      "toggleWebsiteMenusListView",
      "toggleWebsiteMenuView",
      "toggleMobileAppHomeScreenView",
      "toggleAppearanceView",
      "togglePageSectionDuplicationView",
      "displayTranslationsPane",
      "removeSectionHandler",
      "onSubmitPageForm",
      "changeIframeSize",
      "switchCurrentPage",
      "onPageClick",
      "removePage",
      "removeBlock",
      "duplicatePageSection",
      "iframeMessageHandler",
      "reloadTheme",
      "removeLoader",
      "forceReloadPreview"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      currentPage: props.pages.length > 0 ? this.initialPage(props.pages) : null,
      website: null,
      showPagesSwitch: false,
      showPageFormView: null, // value 'new' or 'edit'
      showWebsiteMenusList: false,
      showPageSectionDuplicationView: false,
      showMobileAppHomeScreenView: false,
      isSubmittingPage: false,
      newSectionsAfterPageCreate: [], // used to automatically add sections on new page
      websiteMenuViewForKey: null,
      showWebsiteMenuPane: false,
      duplicatePageSectionViewForKey: null,
      previousListPage: null,
      iframeSize: "default", // default / mobile / full
      reloadIframe: true,
      iframeLoading: true
    };
    this.forceSave = false;
  }

  componentDidMount() {
    const { fetchWebsite, fetchWebsitePages, fetchWebsiteSectionTypes, fetchWebsiteMenus,
      fetchSavedSearches, fetchEventGuestFields, noPageSwitch, fetchGuestProductCollections,
      website, pages, menus, sectionTypes, segments, guestFields, guestProductCollections,
      fetchingGuestProductCollections } = this.props;

    if (!website) fetchWebsite(urlEventId());
    if (pages.length == 0) fetchWebsitePages(urlEventId());
    if (!menus) fetchWebsiteMenus(urlEventId());
    if (sectionTypes.length == 0) fetchWebsiteSectionTypes(urlEventId(), "website");
    if (segments.length == 0) fetchSavedSearches(urlEventId());
    if (guestFields.length == 0) fetchEventGuestFields(urlEventId(), { website: true });
    if (guestProductCollections === null && !fetchingGuestProductCollections) fetchGuestProductCollections(urlEventId());

    if (window.postMessage && !noPageSwitch) {
      if (window.addEventListener) {
        window.addEventListener("message", this.iframeMessageHandler, false);
      } else if (window.attachEvent) {
        window.attachEvent("onmessage", this.iframeMessageHandler);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { website, pages, showNotice, reloadPreview, isPendingRequest, pendingDuplicationInPageId } = this.props;
    const { currentPage, isSubmittingPage } = this.state;

    if (pages.length == 0 && nextProps.pages.length > 0) {
      this.setState({ currentPage: this.initialPage(nextProps.pages) });
    }

    // website update
    if (website && isPendingRequest && !nextProps.isPendingRequest) {
      if (isEmpty(nextProps.errors)) {
        showNotice(I18n.t("react.website.update_success"), "success", 2500);
        // if CurrentPage has been modified since the iframe navigation, we need to change the iframe source
        $("#template-preview-iframe").attr("src", this.currentPageURL());
        this.forceReloadPreview();
      } else {
        showNotice(I18n.t("react.website.an_error_occurred"), "error", 2500);
      }
    }

    // page create / update
    if (isSubmittingPage) {
      if (Object.keys(nextProps.pageErrors).length > 0) { // on page error
        showNotice(I18n.t("react.website.invalid_page"), "error", 2500);
        this.setState({ isSubmittingPage: false });
      } else if (pages.length > 0 && nextProps.pages.length > pages.length) { // on page create
        const createdPage = nextProps.pages.find(page => page._id == nextProps.lastCreatedPageId);
        this.afterPageCreate(createdPage);
      } else if (pages.length > 0 && nextProps.pages.length == pages.length) { // on page update
        const updatedPage = nextProps.pages.find(page => page.filename == currentPage.filename);
        this.afterPageUpdate(updatedPage);
      }
    }

    // after page section duplication
    if (pendingDuplicationInPageId && !nextProps.pendingDuplicationInPageId) {
      this.togglePageSectionDuplicationView();

      if (isEmpty(nextProps.errors)) {
        showNotice(I18n.t("react.website.page_section_duplicated_success"), "success", 2500);
        if (currentPage._id === pendingDuplicationInPageId) {
          $("#template-preview-iframe").attr("src", this.currentPageURL());
        }
      } else {
        showNotice(I18n.t("react.website.an_error_occurred"), "error", 2500);
      }
    }

    if (!reloadPreview && nextProps.reloadPreview) {
      this.forceReloadPreview();
    }
  }

  componentDidUpdate() {
    const { hasChanges } = this.props;
    if (this.forceSave && hasChanges) {
      this.forceSave = false;
      this.saveWebsite(); // in order to receive sections data of new page
    }
  }

  initialPage(pages) {
    let initialPage;
    if (this.initialPageId() != null)
      initialPage = pages.find(page => page._id == this.initialPageId());
    if (!initialPage)
      initialPage = pages.find(page => page.is_homepage);
    return initialPage;
  }

  initialPageId() {
    const { initialPageId, match } = this.props;
    return initialPageId || match.params.page_id;
  }

  iframeMessageHandler(e) {
    switch (e.data.type) {
    case "iframeSrcChange": {
      const iframeURL = new URL(e.data.url);
      const locale = iframeURL.pathname.substr(1, 2); // fr, en, etc
      let pagePath;
      // check whether iframe URL contains locale or not and return the part not containing the locale
      if (I18n.available_locales.includes(locale) && iframeURL.pathname.substr(3, 1) === "/") {
        pagePath = iframeURL.pathname.substr(4);
      } else {
        pagePath = iframeURL.pathname.substr(1);
      }

      const { currentPage, showMobileAppHomeScreenView } = this.state;

      if (currentPage.path_name !== pagePath && !showMobileAppHomeScreenView) {
        let newPage;
        if (pagePath == "") {
          newPage = this.props.pages.find(page => page.filename == "index");
        } else {
          newPage = this.props.pages.find(page => page.path_name == pagePath);

          if (!newPage) {
            const pagePathWithGenericId = pagePath.replace(/\/[a-z0-9]{24}/, "/__id__"); // guests based page
            newPage = this.props.pages.find(page => page.path_name == pagePathWithGenericId);

            // check if browsing from list page to detailed view page
            const { pagesSections, sections } = this.props;
            const currentPageSectionsNames = pagesSections[currentPage.filename] || [];
            const resourceListSectionName = currentPageSectionsNames.find(sectionName => {
              return RESOURCE_LIST_SECTIONS.some(resourceListSectionName => startsWith(sectionName, resourceListSectionName));
            });
            const resourceListSection = sections[resourceListSectionName];
            if (resourceListSection) {
              const infoPagePathField = this.findInfoPagePathFieldFromSectionType(resourceListSection.type);

              if (resourceListSection.settings[infoPagePathField] === pagePathWithGenericId) {
                this.switchCurrentPage(newPage, false, currentPage);
                break;
              }
            }
          }
        }

        this.switchCurrentPage(newPage, false);
      }
      break;
    }
    }
  }

  forceReloadPreview() {
    const rand = Math.floor((Math.random() * 1000000));
    // we cannot use JS reload method because of same-origin constraint
    // so modifying current url does the job
    this.refs["template-preview-iframe"].src += "&uid=" + rand;
    this.setState({ iframeLoading: true });
  }

  removeLoader() {
    this.setState({ iframeLoading: false });
  }

  saveWebsite() {
    const { updateWebsite, globalChanges, otherChanges, pagesToRemove, sectionsToRemove, blocksToRemove } = this.props;
    let params = {
      remove_pages: pagesToRemove,
      remove_sections: sectionsToRemove,
      remove_blocks: blocksToRemove,
      settings_data: globalChanges,
      ...otherChanges
    };
    updateWebsite(urlEventId(), params);
  }

  translationsEnabled() {
    const { event } = this.props;
    return event && event.available_frontend_locales && event.available_frontend_locales.length > 0;
  }

  afterPageCreate(newPage) {
    const { addPageToSettings, showNotice, sections, sectionTypes, event } = this.props;
    const { newSectionsAfterPageCreate } = this.state;

    addPageToSettings(newPage.filename);
    showNotice(I18n.t("react.website.page_created"), "success", 2500);

    if (newSectionsAfterPageCreate.length == 0) {
      this.switchCurrentPage(newPage, true);
    } else {
      this.setState({ isSubmittingPage: false, showPageFormView: null, showPagesSwitch: false, newSectionsAfterPageCreate: [] }, () => {
        newSectionsAfterPageCreate.forEach((newSectionKey, index) => {
          if (index === newSectionsAfterPageCreate.length - 1) {
            // as the addition of sections is asynchronous, you can't save them just after adding sections
            this.forceSave = true;
          }
          addSection(newSectionKey, sections, sectionTypes, this.createNewSection, event.locale, newPage.filename);
        });
      });
    }
  }

  afterPageUpdate(updatedPage) {
    const { showNotice, fetchWebsitePages } = this.props;
    const { currentPage } = this.state;

    if (currentPage.updated_at != updatedPage.updated_at) {
      showNotice(I18n.t("react.website.page_updated"), "success", 2500);
      // reload iframe when page layout changes
      if (currentPage.layout_id != updatedPage.layout_id)
        $("#template-preview-iframe").attr("src", $("#template-preview-iframe").attr("src"));
    }

    if (currentPage.is_homepage !== updatedPage.is_homepage) {
      fetchWebsitePages(urlEventId());
    }

    this.setState({ isSubmittingPage: false, currentPage: updatedPage });
  }

  createNewSection(sectionId, sectionData, pageFilename) {
    const { createNewSection } = this.props;
    createNewSection(sectionId, sectionData, pageFilename);
  }

  updateSectionsOrder(contentForPage) {
    const { updatePageSections } = this.props;
    const { currentPage } = this.state;
    updatePageSections(currentPage.filename, contentForPage);
  }

  duplicatePageSection(pageSectionKey, page) {
    const { duplicatePageSection } = this.props;
    duplicatePageSection(urlEventId(), pageSectionKey, page._id);
  }

  displayPageForm(mode) {
    return () => {
      this.setState({ showPageFormView: mode });
    };
  }

  togglePagesSwitchView() {
    const { showPagesSwitch } = this.state;
    this.resetAndChangeView({ showPagesSwitch: !showPagesSwitch }, "pages");
  }

  togglePageSectionDuplicationView(pageSectionKey) {
    const { showPageSectionDuplicationView } = this.state;
    this.setState({ showPageSectionDuplicationView: !showPageSectionDuplicationView, duplicatePageSectionViewForKey: pageSectionKey });
  }

  toggleWebsiteMenusListView() {
    const { showWebsiteMenusList } = this.state;
    const menuKey = showWebsiteMenusList ? "pages" : "menus";
    this.resetAndChangeView({ showWebsiteMenusList: !showWebsiteMenusList }, menuKey);
  }

  toggleWebsiteMenuView(e, menuId) {
    this.setState({ websiteMenuViewForKey: menuId, showWebsiteMenuPane: !this.state.showWebsiteMenuPane });
  }

  toggleMobileAppHomeScreenView() {
    const { showMobileAppHomeScreenView } = this.state;
    this.resetAndChangeView({ showMobileAppHomeScreenView: !showMobileAppHomeScreenView, iframeLoading: true, iframeSize: "mobile" }, "app_home_screen");
  }

  displayTranslationsPane(e, pageId, table, menuKey) {
    this.resetAndChangeView({});
    this.props.displayTranslationsPane(e, pageId, table, menuKey);
  }

  toggleAppearanceView() {
    this.resetAndChangeView({});
    this.props.toggleAppearanceView();
  }

  resetAndChangeView(newState, menuKey) {
    this.props.resetAndChangeParentView({}, menuKey);
    this.setState({
      showPagesSwitch: false,
      showPageFormView: null,
      showWebsiteMenusList: false,
      showMobileAppHomeScreenView: false,
      ...newState
    });
  }

  changeIframeSize() {
    const { iframeSize } = this.state;
    this.setState({ iframeSize: iframeSize == "default" ? "mobile" : "default" });
  }

  switchCurrentPage(page, reloadIframe, previousListPage = null) {
    const { history, match } = this.props;
    if (history && match && match.url.includes("website/builder"))
      history.push(`${pathToWebsiteBuilder()}/page/${page._id}`);

    this.setState({
      currentPage: page,
      showPagesSwitch: false,
      showPageFormView: null,
      sectionViewForKey: null,
      isSubmittingPage: false,
      reloadIframe: reloadIframe,
      iframeLoading: reloadIframe,
      previousListPage
    });
  }

  onPageClick(page, previousListPage = false) {
    return (e) => {
      e.preventDefault();
      this.switchCurrentPage(page, true, previousListPage);
    };
  }

  removeBlock(blockKey, sectionKey) {
    const { removeBlock } = this.props;
    removeBlock(blockKey, sectionKey);
  }

  removePage() {
    const { currentPage } = this.state;
    const { destroyWebsitePage, showNotice, pages, removePageFromSettings } = this.props;
    if (confirm(I18n.t("react.website.page_remove_confirmation"))) {
      destroyWebsitePage(urlEventId(), currentPage._id);
      removePageFromSettings(currentPage.filename);
      showNotice(I18n.t("react.website.page_removed"), "success", 2500);
      const homepagePage = pages.find(page => page.is_homepage);
      this.switchCurrentPage(homepagePage, true);
    }
  }

  reloadTheme(templateProjectRoot, newThemeStructure) {
    const { reloadTheme } = this.props;
    reloadTheme(urlEventId(), templateProjectRoot, newThemeStructure, { nextAction: WebsiteActionCreators.fetchWebsiteSectionTypes(urlEventId(), "website") });
  }

  renderSectionsConfig() {
    const { sectionViewForKey, pages, website, sections, sectionTypes, createNewBlock, pagesSections, menus,
      liquidTemplatePendingRequest, toggleSectionView, highlightBlockInIframe, segments, guestFields, event,
      updateLiquidTemplate, createLiquidTemplate, liquidTemplates, liquidTemplateErrors, clearErrors,
      assets, hasChanges, guestProductCollections } = this.props;

    if (!sectionViewForKey) {
      return <SectionView />;
    }
    const { currentPage } = this.state;
    const sectionLocalConfiguration = sections[sectionViewForKey];
    const sectionTypeKey = sectionLocalConfiguration["type"];
    const sectionType = sectionTypes.find(s => s.filename == sectionTypeKey);
    const dynamicSectionsNames = pagesSections[currentPage.filename] || [];
    const sectionLocked = currentPage.locked && sectionType.schema.lockable;

    return (
      <SectionView sectionKey={sectionViewForKey}
        guestProductCollections={guestProductCollections}
        schema={sectionType.schema}
        configuration={sectionLocalConfiguration}
        hide={toggleSectionView}
        detectChanges={this.receiveNewChanges}
        sendChanges={this.receiveSectionChanges}
        addBlock={createNewBlock}
        removeBlock={this.removeBlock}
        highlightBlockInIframe={highlightBlockInIframe}
        deletable={indexOf(dynamicSectionsNames, sectionViewForKey) != -1 && !sectionLocked}
        removeSection={this.removeSectionHandler}
        fullGuestCategories={website.guest_categories}
        guestCategories={website.event_guest_categories}
        guestCategoriesByPopulation={website.event_guest_categories_by_population}
        segments={segments}
        menus={menus}
        guestFields={guestFields}
        sectionLocked={sectionLocked}
        websitePages={pages}
        documentNames={website.document_templates_names}
        accesspointsTraits={website.accesspoints_traits}
        accesspointsSessionTypes={website.accesspoints_session_types}
        page={currentPage}
        switchPage={this.onPageClick}
        submitPage={this.onSubmitPageForm}
        liquidTemplates={liquidTemplates}
        updateLiquidTemplate={updateLiquidTemplate}
        createLiquidTemplate={createLiquidTemplate}
        liquidTemplatePendingRequest={liquidTemplatePendingRequest}
        liquidTemplateErrors={liquidTemplateErrors}
        accountId={event.account_id}
        reloadPreview={this.forceReloadPreview}
        clearErrors={clearErrors}
        builderType="website"
        templateId={website._id}
        saveBuilder={this.saveWebsite}
        event={event}
        assets={assets}
        hasChanges={hasChanges}
        withSidebar={true}
        translationTableId={currentPage.layout_id}
        displayTranslationsPane={this.translationsEnabled() && this.displayTranslationsPane} />
    );
  }

  removeSectionHandler() {
    const { currentPage } = this.state;
    const { removeSection, sectionViewForKey, toggleSectionView } = this.props;
    toggleSectionView();
    removeSection(sectionViewForKey, currentPage.filename);
    this.refs["template-preview-iframe"].contentWindow.postMessage({ type: "removeSection", sectionId: sectionViewForKey }, "*");
  }

  receiveNewChanges() {
    const { sendChangesNotification, hasChanges } = this.props;
    if (!hasChanges) {
      sendChangesNotification();
    }
  }

  receiveSectionChanges(key, newConfig, changes) {
    const { updateSection } = this.props;
    updateSection(key, newConfig, changes);
  }

  receiveAppHomeScreenChanges(_key, newConfig, changes) {
    const { updateAppHomeScreen } = this.props;
    updateAppHomeScreen(newConfig, changes);
  }

  renderPageForm() {
    const { showPageFormView, currentPage, isSubmittingPage } = this.state;
    const { fetchWebsitePageTemplates, pageTemplates, fetchWebsiteLayouts, layouts, segments,
      pageErrors, website, pages, event } = this.props;

    return <WebsitePageForm mode={showPageFormView}
      page={["edit", "duplicate"].includes(showPageFormView) ? currentPage : null}
      fetchWebsitePageTemplates={fetchWebsitePageTemplates}
      templates={pageTemplates}
      fetchWebsiteLayouts={fetchWebsiteLayouts}
      layouts={layouts}
      hide={ this.displayPageForm(null) }
      isSubmitting={isSubmittingPage}
      onSubmit={ this.onSubmitPageForm }
      savedSearches={segments}
      pageErrors={pageErrors}
      pwaEnabled={website.pwa_enabled}
      seoEnabled={website.seo_enabled}
      event={event}
      websitePages={pages} />;
  }

  onSubmitPageForm(page, action, newSectionsAfterPageCreate = []) {
    const { createWebsitePage, updateWebsitePage, duplicateWebsitePage } = this.props;
    if (action == "edit") {
      updateWebsitePage(urlEventId(), page._id, page);
    } else if (action == "duplicate") {
      duplicateWebsitePage(urlEventId(), page._id, page);
    } else {
      createWebsitePage(urlEventId(), page);
    }
    this.setState({ isSubmittingPage: true, newSectionsAfterPageCreate });
  }

  renderDeletePageButton() {
    const { currentPage } = this.state;
    if (!currentPage.is_homepage && !currentPage.locked) {
      return (
        <p><span className="btn btn-danger d-grid" onClick={ this.removePage }>{ I18n.t("react.website.remove_page") }</span></p>
      );
    }
  }

  currentPageURL() {
    const { website } = this.props;
    const { currentPage, showMobileAppHomeScreenView } = this.state;
    const pathName = showMobileAppHomeScreenView ? "app_home_screen" : currentPage.path_name;
    const params = ["preview=true", `user_token=${window.ReactGlobalProps.user_token}`];
    if (showMobileAppHomeScreenView) {
      params.push("x-vx-src=app");
    }
    const query = params.join("&");

    return window.location.port != "" ?
      `http://${website.fallback_domain}:${window.location.port}/${pathName}?${query}`
      :
      `https://${website.fallback_domain}/${pathName}?${query}`;
  }

  currentPageName() {
    const { currentPage } = this.state;
    const matchConfirmationPage = currentPage.path_name.match(CONFIRMATION_PAGE_REGEX);

    if (!matchConfirmationPage)
      return currentPage.name;

    const { pages } = this.props;
    const formPage = pages.find(page => page.path_name == `registration/${matchConfirmationPage[1]}`);
    return formPage.name;
  }

  renderFormPagesNavigation() {
    const { currentPage } = this.state;
    const matchFormPage = currentPage.path_name.match(FORM_PAGE_REGEX);
    const matchConfirmationPage = currentPage.path_name.match(CONFIRMATION_PAGE_REGEX);

    if (!currentPage || (!matchFormPage && !matchConfirmationPage))
      return null;

    const guestCategoryId = matchFormPage ? matchFormPage[1] : matchConfirmationPage[1];
    const { pages } = this.props;
    const formPage = matchFormPage ? currentPage : pages.find(page => page.path_name == `registration/${guestCategoryId}`);
    const confirmationPage = matchConfirmationPage ? currentPage : pages.find(page => page.path_name == `registration/${guestCategoryId}/confirmation`);

    if (!formPage || !confirmationPage)
      return null;

    return (
      <ul className="nav nav-pills nav-justified" style={{ marginBottom: "10px" }}>
        <li role="presentation" className="nav-item">
          <a href="#" className={`nav-link ${matchFormPage ? "active" : ""}`} onClick={this.onPageClick(formPage)}>{ I18n.t("react.website.switch_to_form_page") }</a>
        </li>
        <li role="presentation" className="nav-item">
          <a href="#" className={`nav-link ${matchConfirmationPage ? "active" : ""}`} onClick={this.onPageClick(confirmationPage)}>{ I18n.t("react.website.switch_to_confirmation_page") }</a>
        </li>
      </ul>
    );
  }

  // Quite complex algorithm :
  // we have to check within settings data if guests list page has a corresponding guest based page
  // and vice versa to be sure to display navigation
  renderResourceBasedPagesNavigation() {
    const { pagesSections, sections } = this.props;
    const { currentPage } = this.state;

    if (currentPage.filename == "index")
      return null;

    const currentPageSectionsNames = pagesSections[currentPage.filename] || [];
    const resourceListSectionName = currentPageSectionsNames.find(sectionName => {
      return RESOURCE_LIST_SECTIONS.some(resourceListSectionName => startsWith(sectionName, resourceListSectionName));
    });
    const liveSectionName = currentPageSectionsNames.find(sectionName => startsWith(sectionName, "session-live"));

    if (currentPage.resource_based) {
      if (liveSectionName) {
        return this.renderNavigationForActiveLivePage();
      } else
        return this.renderNavigationForActiveResourceInfoPage(currentPage.resource_name);
    } else if (resourceListSectionName) {
      const resourceListSection = sections[resourceListSectionName];
      return this.renderNavigationForActiveListPage(resourceListSection);
    }
  }

  findResourceListSectionNames(resourceName) {
    switch (resourceName) {
    case "guest":
      return ["guests-list"];
    case "guest_product":
      return ["guest-products-list"];
    case "blog_article":
      return ["blog-articles-list"];
    default:
      return ["sessions-list", "program"];
    }
  }

  findInfoPagePathField(resourceName) {
    switch (resourceName) {
    case "guest":
      return "guest_info_page_path";
    case "guest_product":
      return "product_info_page_path";
    case "blog_article":
      return "blog_article_info_page_path";
    default:
      return "session_info_page_path";
    }
  }

  renderNavigationForActiveResourceInfoPage(resourceName) {
    const { pagesSections, sections, pages } = this.props;
    const { currentPage, previousListPage } = this.state;
    const resourceListSectionTemplateNames = this.findResourceListSectionNames(resourceName);
    const infoPagePathField = this.findInfoPagePathField(resourceName);
    const livePagePathField = "session_live_page_path";
    const resourceListPageSectionNames = Object.keys(pickBy(sections, section => {
      return resourceListSectionTemplateNames.includes(section.type) && section.settings[infoPagePathField] == currentPage.path_name;
    }));

    if (resourceListPageSectionNames.length == 0)
      return null;

    const listPageFilename = previousListPage && previousListPage.filename || findKey(omit(pagesSections, "index"), pageSections => {
      return resourceListPageSectionNames.some(sectionName => pageSections.includes(sectionName));
    });
    const listPage = pages.find(page => page.filename == listPageFilename);

    let livePage;
    if (resourceName == "session") {
      const currentPageSectionsNames = pagesSections[currentPage.filename] || [];
      const sessionInfoSectionName = currentPageSectionsNames.find(sectionName => startsWith(sectionName, "session-info"));
      const sessionInfoSection = sections[sessionInfoSectionName];
      livePage = sessionInfoSection && pages.find(page => page.path_name == sessionInfoSection.settings[livePagePathField]);
    }

    if (!listPage && !livePage)
      return null;

    return <ul className="nav nav-pills nav-justified" style={{ marginBottom: "10px" }}>
      { listPage &&
        <li role="presentation" className="nav-item">
          <a href="#" className="nav-link" onClick={ this.onPageClick(listPage) }>{ I18n.t("react.website.switch_to_resource_list") }</a>
        </li>
      }
      <li role="presentation" className="nav-item ">
        <a href="#" className="nav-link active" onClick={ (e) => e.preventDefault() }>{ I18n.t("react.website.switch_to_resource_info") }</a>
      </li>
      { livePage &&
        <li role="presentation" className="nav-item">
          <a href="#" className="nav-link" onClick={this.onPageClick(livePage, previousListPage)}>{ I18n.t("react.website.switch_to_session_live") }</a>
        </li>
      }
    </ul>;
  }

  findInfoPagePathFieldFromSectionType(resourceListSectionType) {
    switch (resourceListSectionType) {
    case "guests-list":
      return "guest_info_page_path";
    case "guest-products-list":
      return "product_info_page_path";
    case "blog-articles-list":
      return "blog_article_info_page_path";
    default:
      return "session_info_page_path";
    }
  }

  renderNavigationForActiveListPage(resourceListSection) {
    if (!resourceListSection)
      return null;

    const { pages } = this.props;
    const { currentPage } = this.state;
    const livePagePathField = "session_live_page_path";
    const infoPagePathField = this.findInfoPagePathFieldFromSectionType(resourceListSection.type);

    let resourceInfoPage;
    if (resourceListSection.settings[infoPagePathField] != "")
      resourceInfoPage = pages.find(page => page.path_name == resourceListSection.settings[infoPagePathField]);

    let livePage;

    if (resourceListSection.settings[livePagePathField])
      livePage = pages.find(page => page.path_name == resourceListSection.settings[livePagePathField]);

    if (!resourceInfoPage && !livePage)
      return;

    return <ul className="nav nav-pills nav-justified" style={{ marginBottom: "10px" }}>
      <li role="presentation" className="nav-item">
        <a href="#" className="nav-link active" onClick={ (e) => e.preventDefault() }>{ I18n.t("react.website.switch_to_resource_list") }</a>
      </li>
      { resourceInfoPage &&
        <li role="presentation" className="nav-item">
          <a href="#" className="nav-link" onClick={this.onPageClick(resourceInfoPage, currentPage)}>{ I18n.t("react.website.switch_to_resource_info") }</a>
        </li>
      }
      { livePage &&
        <li role="presentation" className="nav-item">
          <a href="#" className="nav-link" onClick={this.onPageClick(livePage, currentPage)}>{ I18n.t("react.website.switch_to_session_live") }</a>
        </li>
      }
    </ul>;
  }

  renderNavigationForActiveLivePage() {
    const { pagesSections, sections, pages } = this.props;
    const { currentPage, previousListPage } = this.state;
    const sessionsListSectionTemplateNames = ["sessions-list", "program"];
    const livePagePathField = "session_live_page_path";
    const infoPagePathField = "session_info_page_path";
    const sessionsListPageSectionNames = Object.keys(pickBy(sections, section => {
      return sessionsListSectionTemplateNames.includes(section.type) && section.settings[livePagePathField] == currentPage.path_name;
    }));

    if (sessionsListPageSectionNames.length == 0)
      return null;

    const sessionsListPageFilename = previousListPage && previousListPage.filename || findKey(pagesSections, pageSections => {
      return sessionsListPageSectionNames.some(sectionName => pageSections.includes(sectionName));
    });
    const sessionsListPage = pages.find(page => page.filename == sessionsListPageFilename);

    const sessionsListPageSectionsNames = pagesSections[sessionsListPageFilename] || [];
    const sessionInfoSectionName = sessionsListPageSectionsNames.find(sectionName => {
      return sessionsListSectionTemplateNames.some(sessionsListSectionName => startsWith(sectionName, sessionsListSectionName));
    });
    const sessionInfoSection = sections[sessionInfoSectionName];
    const sessionInfoPage = sessionInfoSection && pages.find(page => page.path_name == sessionInfoSection.settings[infoPagePathField]);

    if (!sessionInfoPage && !sessionsListPage)
      return null;

    return <ul className="nav nav-pills nav-justified" style={{ marginBottom: "10px" }}>
      { sessionsListPage &&
        <li role="presentation" className="nav-item">
          <a href="#" className="nav-link" onClick={ this.onPageClick(sessionsListPage) }>{ I18n.t("react.website.switch_to_resource_list") }</a>
        </li>
      }
      { sessionInfoPage &&
        <li role="presentation" className="nav-item">
          <a href="#" className="nav-link" onClick={this.onPageClick(sessionInfoPage, previousListPage)}>{ I18n.t("react.website.switch_to_resource_info") }</a>
        </li>
      }
      <li role="presentation" className="nav-item">
        <a href="#" className="nav-link active">{ I18n.t("react.website.switch_to_session_live") }</a>
      </li>
    </ul>;
  }

  renderCurrentPageViewLink() {
    const { website } = this.props;
    const { currentPage } = this.state;

    // no view link for pages based on a guest ID
    if (currentPage.path_name.match(CONFIRMATION_PAGE_REGEX) || currentPage.resource_based)
      return null;

    const locale = this.translationsEnabled() ? `${I18n.locale}/` : "";
    const currentPageURL = pathToWebsiteView(website.custom_domain, `${locale}${currentPage.i18n_path_name}`);

    return <OverlayTrigger placement="bottom" overlay={addTooltip(I18n.t("react.website.view_page_in_another_tab"))}>
      <a href={ currentPageURL } className="btn btn-link ps-0 pe-0 ml-10" target="_blank" style={{ color: "#333" }}><i className="fa-regular fa-external-link" /></a>
    </OverlayTrigger>;
  }

  renderCurrentPageOfflineIcon() {
    const { currentPage } = this.state;

    if (currentPage.online)
      return;

    return <OverlayTrigger placement="bottom" overlay={addTooltip(I18n.t("react.website.page_offline"))}>
      <i className="fa-solid fa-circle ml-10" style={{ color: OFFLINE_COLOR, fontSize: "16px" }}></i>
    </OverlayTrigger>;
  }

  render() {
    const { currentPage, showPagesSwitch, showWebsiteMenusList, showWebsiteMenuPane, websiteMenuViewForKey,
      iframeSize, reloadIframe, iframeLoading, showPageSectionDuplicationView, duplicatePageSectionViewForKey,
      showMobileAppHomeScreenView } = this.state;

    const { website, sectionTypes, sections, pagesSections, generalSettings, pages, menus,
      sectionsListShown, appearanceShown, showSectionsList, hideSectionsList, hasChanges,
      receiveAppearanceChanges, toggleSectionView, toggleAppearanceSubsectionView,
      appearanceViewForKey, notice, noticeType, reloadingTheme, reloadThemeError, noPageSwitch,
      event, sidebarMenuClasses, translationsPaneShown, guestFields,
      displayTranslationsPane, pendingDuplicationInPageId, segments } = this.props;

    if (!currentPage || !website || sectionTypes.length == 0) {
      return <Loader size="large" inline={false} message={I18n.t("react.loader.loading")} />;
    }

    // Prevents iframe from being loaded twice when navigating inside preview
    let iframeUrl;
    if (!reloadIframe) {
      iframeUrl = this.refs["template-preview-iframe"].src;
    } else {
      iframeUrl = this.currentPageURL();
    }

    const iframeClasses = classNames({
      "mobile": iframeSize == "mobile"
    });

    const changeSizeIconClasses = classNames({
      "fa-regular": true,
      "fa-desktop": iframeSize == "mobile",
      "fa-mobile": iframeSize == "default"
    });

    const matchFormPage = currentPage.path_name.match(FORM_PAGE_REGEX);
    const matchConfirmationPage = currentPage.path_name.match(CONFIRMATION_PAGE_REGEX);

    return (
      <div className="row website-builder-row">
        <div className="col-lg-3 col-md-4 col-sm-5 col-6 website-builder-sidebar">
          <div className="sidebar-menu">
            <ul className="list-group">
              { noPageSwitch ||
                <OverlayTrigger placement="right" overlay={addTooltip(I18n.t("react.website.switch_page"))}>
                  <li className={sidebarMenuClasses("pages")} onClick={ this.togglePagesSwitchView }><i className="fa-regular fa-file fa-fw"></i></li>
                </OverlayTrigger>
              }
              <OverlayTrigger placement="right" overlay={addTooltip(I18n.t("react.website.edit_general_appearance"))}>
                <li className={sidebarMenuClasses("appearance")} onClick={ this.toggleAppearanceView }><i className="fa-regular fa-paintbrush-pencil fa-fw"></i></li>
              </OverlayTrigger>
              <OverlayTrigger placement="right" overlay={addTooltip(I18n.t("react.website.manage_menus"))}>
                <li className={sidebarMenuClasses("menus")} onClick={ this.toggleWebsiteMenusListView }><i className="fa-regular fa-bars fa-fw"></i></li>
              </OverlayTrigger>
              { this.translationsEnabled() &&
                <OverlayTrigger placement="right" overlay={addTooltip(I18n.t("react.registration_form.manage_translations"))}>
                  <li className={sidebarMenuClasses("translations")} onClick={ (e) => this.displayTranslationsPane(e, currentPage._id) }><i className="fa-regular fa-language fa-fw"></i></li>
                </OverlayTrigger>
              }
              { isEnabled(MOBILE_APP) &&
                <OverlayTrigger placement="right" overlay={addTooltip(I18n.t("react.website.manage_app_home_screen"))}>
                  <li className={sidebarMenuClasses("app_home_screen")} onClick={ this.toggleMobileAppHomeScreenView }><i className="fa-regular fa-mobile-phone fa-fw"></i></li>
                </OverlayTrigger>
              }
            </ul>
          </div>

          <div className="sidebar-page-sections">
            <div className="sidebar-header">
              { !matchFormPage && !matchConfirmationPage &&
                <OverlayTrigger placement="bottom" overlay={addTooltip(I18n.t("react.website.duplicate_page"))}>
                  <a onClick={ this.displayPageForm("duplicate") } className="btn btn-secondary btn-sm float-end"><i className="fa-regular fa-clone"></i></a>
                </OverlayTrigger>
              }
              <OverlayTrigger placement="bottom" overlay={addTooltip(I18n.t("react.website.edit_page"))}>
                <a onClick={ this.displayPageForm("edit") } className="btn btn-secondary btn-sm float-end mr-5"><i className="fa-regular fa-gear"></i></a>
              </OverlayTrigger>
              <p className="lead">
                { noPageSwitch ||
                  <a onClick={ this.togglePagesSwitchView } className="btn-back mr-5 text-center"><i className="fa-regular fa-angle-left"></i></a>
                }
                { this.currentPageName() }
                { this.renderCurrentPageViewLink() }
                { this.renderCurrentPageOfflineIcon() }
              </p>
            </div>

            <div className="sidebar-body">
              { this.renderFormPagesNavigation() }
              { this.renderResourceBasedPagesNavigation() }
              <SectionsList layoutStaticSections={currentPage.layout.sections}
                templateStaticSections={currentPage.sections}
                dynamicSectionsNames={pagesSections && pagesSections[currentPage.filename] || []}
                sectionTypes={sectionTypes}
                sectionsConfiguration={sections}
                showEditSection={toggleSectionView}
                updateSectionsOrder={this.updateSectionsOrder}
                showSectionsList={showSectionsList}
                showPageSectionDuplicationView={this.togglePageSectionDuplicationView}
                builderType="website" />
              { this.renderDeletePageButton() }
            </div>
          </div>

          <div className="sidebar-footer">
            <Button variant="primary" disabled={!hasChanges} onClick={ this.saveWebsite }>
              { I18n.t("react.website.save_all") }
            </Button>
            <OverlayTrigger placement="top" overlay={addTooltip(iframeSize == "mobile" ? I18n.t("react.website.switch_to_default_view") : I18n.t("react.website.switch_to_mobile_view"))}>
              <Button variant="secondary" onClick={ this.changeIframeSize } className="float-end"><i className={ changeSizeIconClasses }></i></Button>
            </OverlayTrigger>
            {window.ReactGlobalProps.super_admin || hasAvailableFeature("reload_website_theme") ? (
              <ThemeReloader
                className="float-end"
                style={{ marginRight: "5px" }}
                reloadTheme={this.reloadTheme}
                reloading={reloadingTheme}
                reloadError={reloadThemeError}
                hasThemeLoader={website}
                resourceType="website"
              />
            ) : null}
          </div>

          { translationsPaneShown || showWebsiteMenuPane || <NoticeBlock notice={notice} noticeType={noticeType} /> }

          <SectionsTypesList layoutStaticSections={currentPage.layout.sections}
            sectionsGroups={website.sections_groups}
            sectionTypes={sectionTypes}
            sections={sections}
            isShown={sectionsListShown}
            hideComponent={hideSectionsList}
            createNewSection={this.createNewSection}
            newSectionAddedTo={currentPage.filename}
            showEditSection={toggleSectionView}
            event={event}
            withSidebar={true}
          />

          { this.renderSectionsConfig() }
          <AppearanceCategories isShown={appearanceShown}
            mainSettingsSchema={website.main_settings_schema}
            toggleView={this.toggleAppearanceView}
            onSelectAppearanceCategory={toggleAppearanceSubsectionView} />
          <AppearanceSubsection subsectionKey={appearanceViewForKey}
            mainSettingsSchema={website.main_settings_schema}
            mainSettingsData={generalSettings}
            toggleView={toggleAppearanceSubsectionView}
            detectChanges={this.receiveNewChanges}
            sendChanges={receiveAppearanceChanges}
            templateId={website._id}
            event={event}
            hasChanges={hasChanges} />
          <WebsitePagesList isShown={showPagesSwitch}
            pages={pages}
            currentPage={currentPage}
            toggleView={this.togglePagesSwitchView}
            switchCurrentPage={this.switchCurrentPage}
            displayNewPageForm={this.displayPageForm("new")} />
          <PageSectionDuplicationView isShown={showPageSectionDuplicationView}
            toggleView={this.togglePageSectionDuplicationView}
            pages={pages}
            currentPage={currentPage}
            duplicatePageSection={this.duplicatePageSection}
            pageSectionKey={duplicatePageSectionViewForKey}
            sectionTypes={sectionTypes}
            sectionsConfiguration={sections}
            pendingDuplicationInPageId={pendingDuplicationInPageId} />
          { this.renderPageForm() }
          <WebsiteMenusList isShown={showWebsiteMenusList}
            menus={menus}
            toggleView={this.toggleWebsiteMenusListView}
            onSelectWebsiteMenu={this.toggleWebsiteMenuView} />
          <SlidingPane isOpen={showWebsiteMenuPane}
            title={websiteMenuViewForKey ? I18n.t("react.website.edit_menu") : I18n.t("react.website.new_menu")}
            from="right"
            width='90%'
            onRequestClose={this.toggleWebsiteMenuView}
            className="width-100-xs width-90-sm"
            closeIcon={Icons.close()}>
            <div style={{ padding: "20px", overflow: "hidden" }}>
              <WebsiteMenuForm menuId={websiteMenuViewForKey}
                event={event}
                pages={pages}
                guestCategories={website.event_guest_categories}
                documentNames={website.document_templates_names}
                afterSave={this.toggleWebsiteMenuView}
                afterDestroy={this.toggleWebsiteMenuView}
                reloadPreview={this.forceReloadPreview}
                displayTranslationsPane={this.translationsEnabled() && displayTranslationsPane} />
            </div>
          </SlidingPane>
          <MobileAppHomeScreen
            isShown={showMobileAppHomeScreenView}
            toggleView={this.toggleMobileAppHomeScreenView}
            hasChanges={hasChanges}
            detectChanges={this.receiveNewChanges}
            sendChanges={this.receiveAppHomeScreenChanges}
            removeBlock={this.removeBlock}
            event={event}
            guestFields={guestFields}
            website={website}
            websitePages={pages}
            segments={segments}
            displayTranslationsPane={this.translationsEnabled() && displayTranslationsPane} />
        </div>

        <div className="col-lg-9 col-md-8 col-sm-7 col-6 website-builder-preview">
          { iframeLoading ?
            <Loader size="large" inline={false} withOverlay={true} message={I18n.t("react.loader.preview")} />
            : null }
          <iframe src={ iframeUrl } frameBorder="0" id="template-preview-iframe" ref="template-preview-iframe" className={iframeClasses} onLoad={this.removeLoader} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    blocksToRemove: state.website.blocksToRemove,
    errors: state.website.errors,
    generalSettings: state.website.generalSettings,
    globalChanges: state.website.globalChanges,
    otherChanges: state.website.otherChanges,
    guestFields: state.guestFields.guestFields,
    guestProductCollections: state.guestProductCollections.data,
    fetchingGuestProductCollections: state.guestProductCollections.isFetching,
    hasChanges: state.website.hasChanges,
    isPendingRequest: state.website.isPendingRequest,
    lastCreatedPageId: state.websitePages.lastCreatedPageId,
    layouts: state.websiteLayouts.data,
    menus: state.websiteMenus.data,
    pageErrors: state.websitePages.errors,
    pages: state.websitePages.items,
    pagesSections: state.website.pagesSections,
    pagesToRemove: state.website.pagesToRemove,
    pageTemplates: state.websitePageTemplates.data,
    reloadingTheme: state.website.reloadingTheme,
    reloadThemeError: state.website.reloadThemeError,
    sections: state.website.sections,
    sectionsToRemove: state.website.sectionsToRemove,
    sectionTypes: state.sectionTypes.items,
    segments: state.savedSearches.data,
    website: state.website.data,
    pendingDuplicationInPageId: state.website.pendingDuplicationInPageId,
    event: state.event,
    assets: state.assetsManager.assets
  };
}

const mapDispatchToProps = Object.assign({}, WebsiteActionCreators, {
  fetchEventGuestFields,
  fetchGuestProductCollections,
  fetchSavedSearches
});

export default connect(mapStateToProps, mapDispatchToProps)(withTemplatesBuilder(WebsiteBuilder, "website"));
