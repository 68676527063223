import { Component } from "react";
import BottomOptions from "./form_item_question/BottomOptions.react";
import conditionalDisplay from "../utils/conditionalDisplayUtils.js";
import { priceWithDigits } from "../utils/NumberUtils";
import { GuestCategory } from "../types/GuestCategory";
import { FormItem } from "../types/FormItem";

interface Props {
  updateHandler(item: FormItem, sendFile?: boolean, optimistic?: boolean): void;
  ticketingCategory: GuestCategory;
  formItem: FormItem;
  mode: string;
}

class TicketsSelector extends Component<Props> {

  i18n(key: string, options: any = {}): string {
    return I18n.t(`react.form_items.tickets_selector.${key}`, options);
  }

  renderTicketsTable(): JSX.Element {
    const { ticketingCategory } = this.props;
    const price_excl_vat = ticketingCategory.display_prices_excl_taxes;

    if (Object.keys(ticketingCategory).length == 0)
      return null;

    return <div className="table-container">
      <table className="table table-light">
        <thead>
          <tr>
            <th></th>
            { ticketingCategory.payment_enabled && <th>{ price_excl_vat ? this.i18n("price_excl_taxes") : this.i18n("price_incl_taxes") }</th> }
            <th>{ this.i18n("quantity") }</th>
          </tr>
        </thead>
        <tbody>
          { ticketingCategory.tickets_attributes.map(ticket => {
            return this.renderTicketLine(ticket);
          }) }
        </tbody>
      </table>
    </div>;
  }

  renderTicketLine(ticket: GuestCategory): JSX.Element {
    const { ticketingCategory } = this.props;
    const price_excl_vat = ticketingCategory.display_prices_excl_taxes;
    const ticketPrice = price_excl_vat ? priceWithDigits(ticket.price_excluding_taxes.toString()) : priceWithDigits(ticket.price.toString());

    return <tr key={ticket._id}>
      <td>{ ticket.name }</td>
      { ticketingCategory.payment_enabled && <td>{ ticketPrice } { ticket.currency }</td> }
      <td>
        <select className="form-select form-select-sm" disabled>
          <option value="0">0</option>
        </select>
      </td>
    </tr>;
  }

  render(): JSX.Element {
    const { mode, updateHandler, formItem } = this.props;

    if (mode == "edit") {
      return (
        <div>
          { this.renderTicketsTable() }

          <BottomOptions
            formItem={formItem}
            destroyable={false}
            updateHandler={updateHandler} />
        </div>
      );
    } else if (mode == "read") {
      return (
        <div>{ this.renderTicketsTable() }</div>
      );
    }
  }
}

export default conditionalDisplay(TicketsSelector);
