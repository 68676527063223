import { Component } from "react";
import { FormItemTypeHiddenField, FormItemGrouped, FormItemWithoutGuestField, FormItemsWithDataValidation, FormItemWithTextCompletion,
  FormItemsWithoutConditionalDisplay, OptionsForCheckinPointGroupAdvancedMode, OptionsForAccommodationAdvancedMode,
  DecoratingFormItem, CheckinPointGroupType, ProductGroupType, PromoCodeType, FormItemReadonlyOptions,
  GuestFieldFile, CountryType, ValueList } from "../../constants/Constants";
import { DropdownButton, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { pathToGuestField } from "../../utils/pathUtils";
import Icons from "../../constants/Icons";
import classNames from "classnames";
import ConfirmableButton from "../shared/ConfirmableButton.react";
import { isTicket } from "../../utils/formBuilderUtils.ts";

const optionsFilter = {
  "validations": [],
  "normalizations": [],
  "override_label": {},
  "override_quantity": {},
  "quantity_range": {},
  "order": {},
  "display_illustration": false,
  "display_text_completion": false,
  "search_enabled": false,
  "display_option_description": false,
  "filter": null
};

class BottomOptions extends Component {

  constructor(props) {
    super(props);

    [
      "_checkboxOptionHandler",
      "updateDisplayQuota",
      "toggleQuantityRange",
      "handleReadonlySelect"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    const { display } = props;
    this.state = {
      display
    };
  }

  renderDisplayOptionsDropdown() {
    const { formItem } = this.props;
    const { key, type } = formItem;
    let options = [];

    if (this.isGuestFieldHidden()) return null;

    if (!DecoratingFormItem.includes(type)) {
      options.push("description");
    }

    if (FormItemsWithDataValidation.includes(type)) {
      options.push("validations");
    }

    if (FormItemWithTextCompletion.includes(type)) {
      options.push("display_text_completion");
    }

    if (type == ProductGroupType) {
      if (formItem.options.advanced_mode) options.push("override_quantity");
      options.push("display_illustration");
      options.push("search_enabled");
      options.push("display_option_description");
    }

    if ([CheckinPointGroupType, ProductGroupType].includes(type)) {
      if (formItem.options.advanced_mode) {
        options.push.apply(options, OptionsForCheckinPointGroupAdvancedMode);
      }
      options.push("quota");
      options.push("quantity_range");
    }

    if (formItem.type == "accommodation" && formItem.options.advanced_mode)
      options.push.apply(options, OptionsForAccommodationAdvancedMode);

    if (type == PromoCodeType) {
      options.push("display_label");
      options.push("display_remaining_capacity");
    }

    if (!FormItemsWithoutConditionalDisplay.includes(type) && !isTicket(formItem.options)) {
      options.push("conditional_display");
    }

    if (options.length == 0) {
      return;
    }

    const optionsMenu = options.map(option => {
      const classes = classNames({
        fa: true,
        "fa-check": this.state.display[option]
      });
      return (
        <Dropdown.Item key={"option-data-" + option} onClick={this._toggleDisplayOption.bind(this, option)}>
          <i className={classes}></i> { I18n.t(`react.display_option_${option}`) }
        </Dropdown.Item>
      );
    });

    return <div className="col-auto">
      <DropdownButton variant="secondary" title="Options" id={`${key}-format-select`} align="end">
        <Dropdown.Header key={"option-data-header"}>
          { I18n.t("react.display_options_header") }
        </Dropdown.Header>
        {optionsMenu}
      </DropdownButton>
    </div>;
  }

  _toggleDisplayOption(option, e) {
    e.preventDefault();
    const { display } = this.state;
    let newDisplay = Object.assign({}, display);
    newDisplay[option] = !newDisplay[option];
    this.setState({ display: newDisplay }, () => {
      const keys = Object.keys(optionsFilter);
      if (keys.includes(option) && !newDisplay[option]) {
        this._clearOption(option, optionsFilter[option]);
      }
      //not refactoring this one
      if (option === "description") this._addOrClearDescription();
      if (["search_enabled", "display_illustration", "display_option_description", "display_text_completion"].includes(option)) this.updateOptions(option);
      if (option === "quota") this.updateDisplayQuota();
      if (option === "display_label") this.updateDisplayLabel();
      if (option === "display_remaining_capacity") this.updateDisplayRemainingCapacity();
      if (option === "quantity_range") this.toggleQuantityRange();
      if (option === "conditional_display" && !newDisplay[option]) this._clearConditionalDisplay();
      if (option === "text_completion") this._addOrClearTextCompletion();

      const { updateParentDisplay } = this.props;

      updateParentDisplay({ ...newDisplay });
    });
  }

  _clearOption(key, value) {
    const { updateHandler, formItem } = this.props;
    let newValue = { [key]: value };

    switch (key) {
    // Special case, if we clear validations, we need to clear normalizations, prefill_company_fields and prefill_address_fields
    // All are displayed under same option checkbox "validations"
    case "validations":
      newValue["normalizations"] = optionsFilter["normalizations"];
      newValue["prefill_company_fields"] = false;
      newValue["force_prefill_company_fields"] = false;
      newValue["prefill_address_fields"] = false;
      newValue["force_prefill_address_fields"] = false;
      break;
    case "display_text_completion":
      newValue["text_completion"] = {};
      break;
    }
    const options = Object.assign({}, formItem.options, newValue);
    updateHandler({ options });
  }

  _addOrClearDescription() {
    const { updateHandler, formItem } = this.props;
    const options = Object.assign({}, formItem.options, { help: "" });
    updateHandler({ options });
  }

  _clearConditionalDisplay() {
    const { updateHandler } = this.props;
    updateHandler({ display_conditions: [] });
  }

  updateDisplayQuota() {
    const { updateHandler } = this.props;
    const { display } = this.state;
    updateHandler({
      options: { display_quota: display.quota }
    });
  }

  toggleQuantityRange() {
    const { updateHandler } = this.props;
    updateHandler({
      options: { quantity_range: { min: "-1", max: "-1" } }
    });
  }

  updateOptions(optionName) {
    const { updateHandler } = this.props;
    const { display } = this.state;

    updateHandler({
      options: { [optionName]: display[optionName] }
    });
  }

  updateDisplayLabel() {
    const { updateHandler } = this.props;
    const { display } = this.state;
    updateHandler({
      options: { display_label: display.display_label }
    });
  }

  updateDisplayRemainingCapacity() {
    const { updateHandler } = this.props;
    const { display } = this.state;
    updateHandler({
      options: { display_remaining_capacity: display.display_remaining_capacity }
    });
  }

  _checkboxOptionHandler(option) {
    return (e) => {
      const { updateHandler, formItem } = this.props;
      const options = Object.assign({}, formItem.options, { [option]: e.target.checked });
      updateHandler({ options });
    };
  }

  handleReadonlySelect(value) {
    const { formItem, updateHandler } = this.props;
    return () => {
      const options = { ...formItem.options, readonly: value };
      updateHandler({ options });
    };
  }

  noNeedGuestField() {
    const { formItem } = this.props;
    return FormItemGrouped.includes(formItem.type) || FormItemWithoutGuestField.includes(formItem.type);
  }

  isGuestFieldHidden() {
    const { formItem } = this.props;
    const { type, options } = formItem;

    return type == FormItemTypeHiddenField || (type == CountryType && options.display_as == "hidden_field");
  }

  nonDestroyableItemTooltip() {
    const { formItem } = this.props;

    if (formItem.type == "tickets_selector")
      return <Tooltip id="destroy-tooltip">{I18n.t("tickets_selector_mandatory")}</Tooltip>;
    else
      return <Tooltip id="destroy-tooltip">{I18n.t("guest_field_mandatory")}</Tooltip>;
  }

  renderDestroyItem() {
    const { destroyable, destroyHandler } = this.props;
    if (destroyable) {
      return (
        <div className="col-auto">
          <ConfirmableButton
            btnType="secondary"
            confirmBtnType="link"
            cancelBtnType="link"
            faIcon="trash-can"
            style={{ color: "#333" }}
            onConfirm={destroyHandler}
            tooltipPlacement="left"
            tooltipMessage={I18n.t("react.bottom_options.remove_item_tooltip")}
          />
        </div>
      );
    } else {
      return (
        <div className="col-auto">
          <OverlayTrigger placement="top" overlay={this.nonDestroyableItemTooltip()}>
            <p className="float-end">
              <a className="btn btn-link" style={{ color: "#333" }} disabled>
                <i className="fa-regular fa-trash-can" aria-hidden="true"></i>
              </a>
            </p>
          </OverlayTrigger>
        </div>
      );
    }
  }

  renderOtherOptions() {
    const { formItem } = this.props;
    const { type } = formItem;

    if (DecoratingFormItem.includes(type) || this.isGuestFieldHidden())
      return null;

    return <div className="col-auto">
      <div className="row g-2 align-items-center">
        <div className="col-auto">
          { Icons["vertical_divider"]() }
        </div>
        <div className="col-auto">
          <div className="form-check mb-0">
            <label className="form-check-label">
              <input className="form-check-input" checked={ formItem.options.required && formItem.options.required.toString() === "true" } type="checkbox" onChange={this._checkboxOptionHandler("required")} />
              { I18n.t("react.form_question_item.required") }
            </label>
          </div>
        </div>
        { ![CheckinPointGroupType, ProductGroupType, GuestFieldFile].includes(type) &&
          <div className="col-auto">
            { this.renderReadonlyOptions() }
          </div>
        }
      </div>
    </div>;
  }

  renderReadonlyOptions() {
    const { formItem } = this.props;
    const readonly = formItem.options.readonly || "no";

    const options = FormItemReadonlyOptions.map(readonlyOption => {
      return <Dropdown.Item key={readonlyOption} onClick={this.handleReadonlySelect(readonlyOption)}>
        <i className={`fa-regular fa-${readonlyOption == "no" ? "pencil" : "ban"}`}></i> { I18n.t(`react.input_readonly_${readonlyOption}`) }
      </Dropdown.Item>;
    });

    return <Dropdown>
      <Dropdown.Toggle variant="secondary" id="form-item-readonly">
        <i className={`fa-regular fa-${readonly == "no" ? "pencil" : "ban"}`}></i> { I18n.t(`react.input_readonly_${readonly}`) }
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
        {options}
      </Dropdown.Menu>
    </Dropdown>;
  }

  renderLinkToEditGuestField() {
    if (this.noNeedGuestField()) return null;

    const { formItem, guestFields } = this.props;
    const guestField = formItem.key && guestFields.find(gf => gf.key === formItem.key);

    if (!guestField || !guestField._id || guestField.type !== ValueList)
      return null;

    return <a href={pathToGuestField(guestField._id)} target="_blank">{I18n.t("react.form_question_item.update_guest_field")}</a>;
  }

  render() {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div>{ this.renderLinkToEditGuestField() }</div>
        <div className="row g-2 align-items-center">
          { this.renderDestroyItem() }
          { this.renderOtherOptions() }
          { this.renderDisplayOptionsDropdown() }
        </div>
      </div>
    );
  }
}

BottomOptions.defaultProps = {
  destroyable: true
};

export default BottomOptions;
