import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchColumnsSets } from "../actions/ColumnsSetsActionCreators";
import FilterDropdown from "../components/FilterDropdown.react";

class ColumnsSetPicker extends Component {

  constructor(props) {
    super(props);
    this.onSelectColumnsSet = this.onSelectColumnsSet.bind(this);
  }

  componentDidMount() {
    const { fetchColumnsSets, eventId, columnsSets } = this.props;
    if (!columnsSets || columnsSets.length === 0) {
      fetchColumnsSets(eventId);
    }
  }

  onSelectColumnsSet(columnsSetIds) {
    const { columnsSets, onSelectColumnsSet } = this.props;
    const selectedColumnsSet = columnsSets.find(columnsSet => columnsSet._id === columnsSetIds[0]);
    onSelectColumnsSet(selectedColumnsSet);
  }

  render() {
    const { columnsSets, style, title, selectedColumnsSetId } = this.props;

    if (!columnsSets || columnsSets.length === 0) {
      return null;
    }
    const selectedColumnsSet = selectedColumnsSetId && columnsSets.find(cs => cs._id === selectedColumnsSetId);
    const filterDropDownTitle = selectedColumnsSet ? selectedColumnsSet.name : title;
    return (
      <div style={Object.assign({}, style, { display: "inline-block" })}>
        <FilterDropdown
          id="columns_set"
          translationKey="columns_set"
          items={columnsSets}
          selectedItemIds={[]} // always empty, this is just a one time picker
          showCells={false}
          hasSelectAll={false}
          multipleSelect={false}
          sortItems={false}
          selectedFirst={false}
          itemIdKey="_id"
          onChange={this.onSelectColumnsSet}
          allowUnselect={true}
          title={filterDropDownTitle}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    columnsSets: state.columnsSets.data
  };
};

const mapDispatchToProps = {
  fetchColumnsSets
};

ColumnsSetPicker.defaultProps = {
  title: "Select",
  style: {},
  selectedColumnsSetId: null
};

ColumnsSetPicker.propTypes = {
  onSelectColumnsSet: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  title: PropTypes.node,
  style: PropTypes.object,
  selectedColumnsSetId: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(ColumnsSetPicker);

