import { Component, MouseEvent } from "react";
import moment from "moment";
import SearchInput from "./shared/SearchInput.react";
import Loader from "./shared/Loader.react";
import { Event } from "../types/Event";
import { connect } from "react-redux";
import { fetchEvents } from "../actions/EventActionCreators";
import HelpSection from "./shared/HelpSection.react";

interface StoreProps {
  events: Event[];
}

interface DispatchProps {
  fetchEvents: (options?: { page?: number, perPage?: number, published?: any, accountId?: any, search?: any }) => void;
}

interface OwnProps {
  onEventSelected(eventId: string): any;
  currentEvent?: Event;
  doNotDisplayCurrentEvent?: boolean;
  model?: string;
  confirmOnSelection?: boolean;
  fetchMoreEvents?: () => void;
}

type Props = StoreProps & DispatchProps & OwnProps

class TargetEventSelector extends Component<Props> {
  constructor(props) {
    super(props);
    [
      "submit"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    moment.locale(I18n.currentLocale());
  }

  static defaultProps = {
    confirmOnSelection: false
  };

  componentDidMount(): void {
    const { events, fetchEvents } = this.props;

    if (!events) {
      fetchEvents({ page: 1, perPage: 100 });
    }
  }

  submit(eventId: string): (e: MouseEvent<HTMLInputElement>) => void {
    return (e: MouseEvent<HTMLInputElement>): void => {
      e.preventDefault();
      const { confirmOnSelection, onEventSelected } = this.props;

      if (confirmOnSelection && confirm(I18n.t("are_u_sur")) || !confirmOnSelection)
        onEventSelected(eventId);
    };
  }

  localizedDate(date: string): any {
    return moment(date);
  }

  eventDatetimeDisplay(event: Event): string {
    if (!event.start_date || !event.end_date) {
      return;
    }
    const startDate = this.localizedDate(event.start_date);
    const endDate = this.localizedDate(event.end_date);
    let eventDatetime = "";
    if (startDate.day() === endDate.day()) {
      eventDatetime += `${I18n.t("on_before_date")} `;
    }
    eventDatetime += startDate.format("LL");
    eventDatetime += (startDate != endDate) ? ` ${I18n.t("at_time")} ` : ` ${I18n.t("from")} `;
    eventDatetime += `${startDate.hours()}:00`;
    if (startDate != endDate) {
      eventDatetime += ` ${I18n.t("to")} ${endDate.format("LL")}`;
    }
    eventDatetime += (startDate != endDate) ? ` ${I18n.t("at_time")} ` : ` ${I18n.t("to_time")} `;
    eventDatetime += `${endDate.hours()}:00`;

    return eventDatetime;
  }

  renderEvent(event: Event): JSX.Element {
    return <div className="list-group-item list-group-item-action d-flex" onClick={this.submit(event._id)} key={event._id}>
      <span className="event-logo d-none d-sm-inline">
        { event.photo && <img src={ event.photo.thumb.url } /> }
      </span>
      <div className="d-flex flex-column justify-content-center">
        <div>
          <h2 className="mb-5">{ event.title }</h2>
          <div className="text-muted"><i className="fa-regular fa-clock lg"></i> { this.eventDatetimeDisplay(event) }</div>
        </div>
      </div>
    </div>;
  }

  renderEvents(): JSX.Element {
    const { events, currentEvent } = this.props;

    return <div className="events">
      <div className="filter-card list-group on-event-list">
        { events.map(event => {
          if (currentEvent && event._id == currentEvent._id) {
            return null;
          }
          return this.renderEvent(event);
        }) }
      </div>
    </div>;
  }

  renderInfoDataNotDuplicated(): JSX.Element {
    return <HelpSection
      help={I18n.t("react.website.duplication_running_modal.information")}
      badgeText={I18n.t("react.website.duplication_running_modal.help")}
      classNames="d-none d-sm-block"
    />;
  }

  renderCurrentEvent(): JSX.Element {
    const { doNotDisplayCurrentEvent, currentEvent, model } = this.props;

    if (doNotDisplayCurrentEvent || !currentEvent) return null;
    if (model) return this.renderInfoDataNotDuplicated();

    return <div>
      <div className="mb-3">
        <label className="form-label">{I18n.t("events.event.current_event")}</label>
        <div className="filter-card list-group on-event-list">
          {this.renderEvent(currentEvent)}
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">{I18n.t("events.event.another_event")}</label>
      </div>
    </div>;
  }

  renderLoadMore(): JSX.Element {
    const { fetchMoreEvents } = this.props;

    if (!fetchMoreEvents) return;

    return <a href="#" onClick={fetchMoreEvents}>
      { I18n.t("events.target_event_selector.load_more") }
    </a>;
  }

  render(): JSX.Element {
    const { events, fetchEvents } = this.props;

    if (!events)
      return <Loader size="large" inline={false} containerHeight="500px" message={I18n.t("react.loader.loading")} />;

    return <div>
      {this.renderCurrentEvent()}
      <div className="mt-5">
        <SearchInput fetchEvents={fetchEvents} />
      </div>
      {this.renderEvents()}
      {this.renderLoadMore()}
    </div>;
  }
}

function mapStateToProps(state): StoreProps {
  return {
    events: state.events.data
  };
}

const mapDispatchToProps: DispatchProps = {
  fetchEvents
};

export default connect<StoreProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(TargetEventSelector);

