import { fabric } from "fabric";
import { BadgeImage } from "../../../types/BadgeTemplate";
import ImageProperties from "./ImageProperties.react";

interface Props {
  badgeImages: BadgeImage[];
  newBackgroundKey: string;
  textValue: string;
  selectedObjects?: fabric.Object[];
  imageFormatSettings: any;
  onChangeText: (e: any) => void;
  onChangeImage(badgeImage: BadgeImage): void;
  onDeleteImage(position: string): void;
}

function i18n(key, opts = {}): string {
  return I18n.t(`react.badge_builder.properties_panel.${key}`, opts);
}

const ContentProperties: React.FC<Props> = ({
  badgeImages,
  newBackgroundKey,
  textValue,
  selectedObjects,
  imageFormatSettings,
  onChangeText,
  onChangeImage,
  onDeleteImage
}) => {
  const renderTextArea = (): JSX.Element => {
    if (selectedObjects[0]?.type !== "textbox") return null;

    return <div>
      <textarea
        id="textValue"
        className="form-control"
        value={textValue}
        onChange={onChangeText}
      />
    </div>;
  };

  const renderImageProperties = (): JSX.Element => {
    if (!newBackgroundKey && selectedObjects[0]?.type !== "image") return null;

    const imageKey = newBackgroundKey || selectedObjects[0]?.imageKey;
    const imageSelected = badgeImages.find(badgeImage => badgeImage.position === imageKey && !badgeImage.destroy);

    return <ImageProperties
      imageSelected={imageSelected}
      newBackgroundKey={newBackgroundKey}
      imageFormatSettings={imageFormatSettings}
      onChangeImage={onChangeImage}
      onDeleteImage={onDeleteImage}
    />;
  };

  return <div>
    <label htmlFor="textValue" className="form-label">{i18n("content")}</label>
    <div className="d-flex flex-column gap-3">
      {renderTextArea()}
      {renderImageProperties()}
    </div>
  </div>;
};

export default ContentProperties;
