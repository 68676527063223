import { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { defaultModalStyles } from "../../constants/Style";

class Breadcrumb extends Component {

  constructor(props) {
    super(props);
    const privateMethods = ["_backLink", "_stepList", "_nextStepLink", "_openModal", "_closeModal", "_campaign_saved"];
    privateMethods.forEach((item) => { this[item] = this[item].bind(this); });
    this.state = { modalIsOpen: false };
  }

  _backLink() {
    const { backUrl, backName } = this.props;

    return (
      backUrl ? (
        <div>
          <a onClick={this._openModal} style={{ cursor: "pointer" }}>
            <i className="fa-regular fa-chevron-left fa-fw fa-xs"></i> { backName || I18n.t("back") }
          </a>
          <Modal className="modal-content"
            isOpen={this.state.modalIsOpen}
            onRequestClose={this._closeModal}
            style={defaultModalStyles}
            contentLabel="Modal"
          >
            <div className="modal-header">
              <h4 className="modal-title">{I18n.t("back_to_guest_list_modal_title")}</h4>
            </div>
            <div className="modal-body" style={{ maxHeight: "550px", overflow: "auto" }}>
              {this._campaign_saved() ? I18n.t("campaign_is_saved_and_can_be_seen") : I18n.t("campaign_is_not_saved_yet")}
            </div>
            <div className="modal-footer">
              <Link to={backUrl} className="btn btn-primary">OK</Link>
              <button onClick={this._closeModal} type="button" className="btn btn-secondary">{I18n.t("cancel")}</button>
            </div>
          </Modal>
        </div>
      ) : null
    );
  }

  _stepList() {
    const { steps, activeStep } = this.props;
    const nbStep = steps.length;
    let list = [];
    steps.forEach((step, index) => {
      const activeClass = (step.label == activeStep.label) ? "active" : null;
      const link = step.url ? (<Link to={step.url}>{ step.label }</Link>) : <a style={{ textDecoration: "none" }}>{ step.label }</a>;
      list.push(<li key={index} className={activeClass}>{link}</li>);
      if (nbStep > index + 1) {
        list.push(<li key={`${index}_chevron`}><i className="fa-regular fa-chevron-right"></i></li>);
      }
    });
    return <ul className="list-inline">{list}</ul>;
  }

  // Null steps' urls mean that the campaign is not saved yet
  // (if it was, you could go back and forth with the campaign registration steps)
  _campaign_saved() {
    return this.props.steps[0].url != null;
  }

  _nextStepLink() {
    const { nextUrl, nextName } = this.props;
    if (nextUrl == null) return null;

    const className = "btn btn-primary btn-lg float-end";
    const label = nextName || I18n.t("next");
    if (typeof nextUrl === "function") {
      return (
        <span className={ className } onClick={ nextUrl }>
          { label }
        </span>
      );
    }
    return (
      <Link to={ nextUrl } className={ className }>
        { label }
      </Link>
    );
  }

  _openModal() {
    this.setState({ modalIsOpen: true });
  }

  _closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <div className="row campaign-breadcrumb">
        <div className="col-md-3">
          {this._backLink()}
        </div>
        <div className="col-md-6">
          {this._stepList()}
        </div>
        <div className="col-md-3">
          {this._nextStepLink()}
        </div>
      </div>
    );
  }

}

export default Breadcrumb;

Breadcrumb.propTypes = {
  steps: PropTypes.array.isRequired,
  modalIsOpen: PropTypes.bool.isRequired
};
