import { ActionTypes, CALL_API } from "../constants/Constants";
import { MOST_USED_VALUES_LIMIT } from "../constants/Constants";
import { cleanUpQueryString } from "../utils/QueryStringUtils";

module.exports = {

  clearStatistics() {
    return {
      type: ActionTypes.CLEAR_STATISTICS
    };
  },

  guestFieldsBatchCreate(eventId, guestFields) {
    const { GUEST_FIELDS_BATCH_CREATE_REQUEST, GUEST_FIELDS_BATCH_CREATE_SUCCESS, GUEST_FIELDS_BATCH_CREATE_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [GUEST_FIELDS_BATCH_CREATE_REQUEST, GUEST_FIELDS_BATCH_CREATE_SUCCESS, GUEST_FIELDS_BATCH_CREATE_FAILURE],
        method: "POST",
        body: { guest_fields: guestFields },
        endpoint: `/api/v1/events/${eventId}/guest_fields/batch_create.json`
      }
    };
  },

  createGuestField(eventId, guestField, redirectTo = null) {
    const { DEV_NULL, GUEST_FIELD_CREATE_SUCCESS, GUEST_FIELD_CREATE_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, GUEST_FIELD_CREATE_SUCCESS, GUEST_FIELD_CREATE_FAILURE],
        method: "POST",
        body: { guest_field: guestField },
        endpoint: `/api/v1/events/${eventId}/guest_fields.json`,
        redirectTo: redirectTo
      }
    };
  },

  updateGuestField(eventId, guestFieldId, guestField, redirectTo = null) {
    const { DEV_NULL, GUEST_FIELD_UPDATE_SUCCESS, GUEST_FIELD_UPDATE_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, GUEST_FIELD_UPDATE_SUCCESS, GUEST_FIELD_UPDATE_FAILURE],
        method: "PUT",
        body: { guest_field: guestField },
        endpoint: `/api/v1/events/${eventId}/guest_fields/${guestFieldId}.json`,
        redirectTo
      }
    };
  },

  fetchGuestFieldTypes(eventId) {
    const { DEV_NULL, FETCH_GUEST_FIELDS_TYPES_SUCCESS } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, FETCH_GUEST_FIELDS_TYPES_SUCCESS, DEV_NULL],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/guest_fields/types.json`
      }
    };
  },

  receiveGuestField(eventId, guestFieldId) {
    const { DEV_NULL, RECEIVE_GUEST_FIELD } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, RECEIVE_GUEST_FIELD, DEV_NULL],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/guest_fields/${guestFieldId}.json`
      }
    };
  },

  calculateFieldValue(eventId, calculateParams) {
    const { CALCULATED_FIELD_PREVIEW_REQUEST, CALCULATED_FIELD_PREVIEW_SUCCESS, CALCULATED_FIELD_PREVIEW_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [CALCULATED_FIELD_PREVIEW_REQUEST, CALCULATED_FIELD_PREVIEW_SUCCESS, CALCULATED_FIELD_PREVIEW_FAILURE],
        method: "PUT",
        body: calculateParams,
        endpoint: `/api/v1/events/${eventId}/guest_fields/calculated_field_preview.json`
      }
    };
  },

  fetchEventGuestFields(eventId, options = {}) {
    const { DEV_NULL, FETCH_GUEST_FIELDS_SUCCESS } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, FETCH_GUEST_FIELDS_SUCCESS, DEV_NULL],
        method: "GET",
        endpoint: `/events/${eventId}/guest_fields.json`,
        body: options
      }
    };
  },

  fetchGuestFieldsMostUsedValues(eventId, completeSearchQuery, fieldKey) {
    const searchQuery = cleanUpQueryString(completeSearchQuery);
    const body = { q: searchQuery, field: fieldKey, limit: MOST_USED_VALUES_LIMIT };
    const { DEV_NULL, FETCH_EVENT_GUEST_FIELD_MOST_USED_VALUES_SUCCESS, SEARCH_GUEST_FIELD_MOST_USED_VALUES_SUCCESS } = ActionTypes;
    const action_type = (searchQuery.length === 0) ? FETCH_EVENT_GUEST_FIELD_MOST_USED_VALUES_SUCCESS : SEARCH_GUEST_FIELD_MOST_USED_VALUES_SUCCESS;
    return {
      [CALL_API]: {
        types: [DEV_NULL, action_type, DEV_NULL],
        method: "GET",
        endpoint: `/events/${eventId}/guest_fields/guest_fields_most_used_values.json`,
        body
      }
    };
  }
};
