import { exportBadges, exportQRCodes, exportGuestList, exportDocumentsArchive } from "../utils/APIUtils";
import { CALL_API, ActionTypes } from "../constants/Constants";

module.exports = {
  triggerBadgesExport(completeSearchQuery, fileNaming = "last_name", oneBadgePerPage = false) {
    return (dispatch) => {
      return exportBadges(dispatch, completeSearchQuery, fileNaming, oneBadgePerPage);
    };
  },

  triggerQRCodesExport(completeSearchQuery, fileNaming = "last_name") {
    return (dispatch) => {
      return exportQRCodes(dispatch, completeSearchQuery, fileNaming);
    };
  },

  triggerGuestsListExport(sheets, exportFormat, completeSearchQuery, options = {}) {
    return (dispatch) => {
      return exportGuestList(dispatch, sheets, exportFormat, completeSearchQuery, options);
    };
  },

  triggerDocumentsExport(completeSearchQuery) {
    return (dispatch) => {
      return exportDocumentsArchive(dispatch, completeSearchQuery);
    };
  },

  fetchExportableGuestFields(eventId) {
    const { DEV_NULL, GUEST_FIELDS_TO_EXPORT_SUCCESS } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, GUEST_FIELDS_TO_EXPORT_SUCCESS, DEV_NULL],
        method: "GET",
        endpoint: `/events/${eventId}/guest_fields.json?export=true`
      }
    };
  }
};
