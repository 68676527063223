import { ActionTypes } from "../constants/Constants";

function contactFields(state = { data: null, fetching: false, errors: null }, action) {
  switch (action.type) {
  case ActionTypes.FETCH_CONTACT_FIELDS_REQUEST:
    return Object.assign({}, state, { fetching: true });
  case ActionTypes.FETCH_CONTACT_FIELDS_SUCCESS:
    return Object.assign({}, state, { data: action.data, fetching: false, errors: null });
  case ActionTypes.FETCH_CONTACT_FIELDS_FAILURE:
    return Object.assign({}, state, { data: null, fetching: false, errors: action.data });
  default:
    return state;
  }
}

export default contactFields;
