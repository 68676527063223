import { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { withRouter } from "react-router-dom";

import { updateUI } from "../actions/UIAppearanceActionCreators";
import { defaultModalStyles } from "../constants/Style";

class ModalContainer extends Component {
  constructor(props) {
    super(props);
    this._closeModal = this._closeModal.bind(this);

    if (this.props.customStyle) {
      const overlayStyle = Object.assign({}, defaultModalStyles.overlay, this.props.customStyle.overlay);
      const contentStyle = Object.assign({}, defaultModalStyles.content, this.props.customStyle.content);
      this.customStyle = Object.assign({}, defaultModalStyles, { overlay: overlayStyle, content: contentStyle });
    } else {
      this.customStyle = defaultModalStyles;
    }
  }

  componentDidMount() {
    const updates = {
      "hideNavBar": true
    };
    this.props.updateUI(updates);
  }

  _closeModal() {
    const { location, history } = this.props;
    const { pathname } = location;
    const path = pathname.split("/modal")[0];
    const newLocation = Object.assign({}, location, { pathname: path });
    history.push(newLocation);
  }

  render() {
    const { appearance } = this.props;
    let { children } = this.props;
    if (children && Array.isArray(children) == false) {
      const props = Object.assign({}, children.props, { closeModal: this._closeModal });
      children = Object.assign({}, children, { props });
    }
    return (
      <div>
        <Modal className="modal-content"
          isOpen={true}
          onRequestClose={ this._closeModal }
          style={ this.customStyle }
          contentLabel="Modal">
          <div className="modal-header">
            <h4 className="modal-title">{ appearance.modalTitle }</h4>
            <button type="button" onClick={ this._closeModal } className="btn-close" aria-label={I18n.t("close")}></button>
          </div>
          <div className="modal-body" style={{ overflow: "auto" }}>
            {children}
          </div>
        </Modal>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    appearance: state.appearance
  };
}

const mapDispatchToProps = {
  updateUI
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalContainer));
