import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMeetingByNumberOfAppointments } from "../../actions/MeetingsActionCreators";
import Loader from "../../components/shared/Loader.react";
import ReportHeader from "../../components/event_reports/ReportHeader.react";
import { isAuthorized } from "../../utils/aclUtils";
import { identity } from "../../utils/personUtils";
import Select from "react-select";
import { Dropdown } from "react-bootstrap";
import uniqBy from "lodash/uniqBy";

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`react.event_reports.meetings_by_number_of_appointments.${key}`, options);
};

const MeetingsbyNumberOfAppointments: React.FC = () => {
  const eventId = useSelector((state: any) => state.event.id);
  const dataFetched = useSelector((state: any) => state.meetingsStatistics.byNumberOfAppointments);
  const isFetching = useSelector((state: any) => state.meetingsStatistics.isFetching);
  const dispatch = useDispatch();
  const [guestIdSelected, setGuestIdSelected] = useState(null);
  const [categoryIdSelected, setCategoryIdSelected] = useState(null);
  const [result, setResult] = useState([]);
  const [sortField, setSortField] = useState("nb_requested_reservations");

  if (!isAuthorized("reports", "read")) return null;

  useEffect(() => {
    if (!eventId) return;
    dispatch(fetchMeetingByNumberOfAppointments(eventId, sortField));
  }, [eventId, sortField]);

  useEffect(() => {
    if (!dataFetched) return;
    setResult(dataFetched);
  }, [dataFetched]);

  useEffect(() => {
    if (!guestIdSelected && !categoryIdSelected) {
      dataFetched && setResult(dataFetched);
      return;
    }

    const resultFiltered = dataFetched.filter(item => {
      let res = guestIdSelected ? item.guest._id === guestIdSelected : true;
      res &&= categoryIdSelected ? item.guest_category._id === categoryIdSelected : true;
      return res;
    });
    setResult(resultFiltered);
  }, [guestIdSelected, categoryIdSelected]);

  const exporterProps = (): any => {
    return {
      data: {
        data: result.map(
          line => {
            return {
              exhibitor: identity(line.guest),
              company_name: line.guest.company_name,
              guest_category: line.guest_category.name,
              nb_requested_reservations: line.nb_requested_reservations,
              nb_confirmed_reservations: line.nb_confirmed_reservations,
              nb_appointments_with_checkin: line.nb_appointments_with_checkin,
              ratio_of_appointment_made: line.ratio_of_appointment_made
            };
          }
        )
      },
      options: { excludeTotal: true },
      columns: ["exhibitor", "company_name", "guest_category", "nb_requested_reservations", "nb_confirmed_reservations", "nb_appointments_with_checkin", "ratio_of_appointment_made"]
    };
  };

  const renderHeader = (): JSX.Element => {
    return <ReportHeader
      title={I18n.t("react.event_reports.event_reports.meetings")}
      exporterToExcelProps={result?.length > 0 ? exporterProps() : null}
      exportType="meetings_by_number_of_appointments"
    />;
  };

  const renderSortDropdown = (): JSX.Element => {
    const rows = ["nb_requested_reservations", "nb_confirmed_reservations", "nb_appointments_with_checkin", "ratio_of_appointment_made"].map(sort => {
      return <Dropdown.Item key={sort} onClick={(): void => setSortField(sort)}>{i18n(`sort_by_${sort}`)}</Dropdown.Item>;
    });

    return <div className="float-end">
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-sort">
          {i18n(`sort_by_${sortField}`)}
        </Dropdown.Toggle>
        <Dropdown.Menu
          align="end"
          popperConfig={{
            strategy: "fixed",
            onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll"))
          }}
        >
          {rows}
        </Dropdown.Menu>
      </Dropdown>
    </div>;
  };

  const renderGuestsSelect = (): JSX.Element => {
    const guests = uniqBy(
      result?.map((line) => {
        let label = line.guest?.company_name || "";
        const guestIdentity = identity(line.guest);
        if (label != guestIdentity) label += label == "" ? guestIdentity : ` - ${guestIdentity}`;
        return {
          value: line.guest?._id,
          label: label
        };
      }),
      function (object) {
        return object.value;
      }
    );

    return <Select
      className="react-select"
      classNamePrefix="react-select"
      options={guests}
      value={guests?.filter(guest => guestIdSelected?.includes(guest.value))}
      placeholder={i18n("select_a_guest")}
      noOptionsMessage={(): any => i18n("no_guest")}
      onChange={(option): any => setGuestIdSelected(option?.value)}
      isClearable={true}
      menuPosition="fixed"
      styles={{
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        menuPortal: ({ left, ...provided }, state) => ({
          ...provided
        })
      }}
    />;
  };

  const renderCategorySelect = (): JSX.Element => {
    const categories = uniqBy(
      result?.map((line) => {
        return {
          value: line.guest_category._id,
          label: line.guest_category.name
        };
      }),
      function (object) {
        return object.value;
      }
    );

    return <Select
      className="react-select"
      classNamePrefix="react-select"
      options={categories}
      value={categories?.filter(category => categoryIdSelected?.includes(category.value))}
      placeholder={i18n("select_a_guest_category")}
      noOptionsMessage={(): any => i18n("no_guest_category")}
      onChange={(option): any => setCategoryIdSelected(option?.value)}
      isClearable={true}
      menuPosition="fixed"
      styles={{
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        menuPortal: ({ left, ...provided }, state) => ({
          ...provided
        })
      }}
    />;
  };

  const renderTableHeader = (): JSX.Element => {
    return (
      <>
        <tr>
          <th style={{ fontWeight: "normal" }} colSpan={2}>
            {renderGuestsSelect()}
          </th>
          <th style={{ fontWeight: "normal" }}>
            {renderCategorySelect()}
          </th>
          <th colSpan={3}>
            {renderSortDropdown()}
          </th>
        </tr>
        <tr>
          <th>{I18n.t("react.event_reports.report_table.exhibitor")}</th>
          <th>{I18n.t("react.event_reports.report_table.guest_category")}</th>
          <th>{I18n.t("react.event_reports.report_table.nb_requested_reservations")}</th>
          <th>{I18n.t("react.event_reports.report_table.nb_confirmed_reservations")}</th>
          <th>{I18n.t("react.event_reports.report_table.nb_appointments_with_checkin")}</th>
          <th>{I18n.t("react.event_reports.report_table.ratio_of_appointment_made")}</th>
        </tr>
      </>
    );
  };

  const renderTable = (): JSX.Element => {
    if (!dataFetched) return null;
    if (result?.length === 0) return renderEmptyMsg();

    return <div className="row">
      <div className="col-12">
        <div className="table-responsive table-container">
          <table className="table table-light table-hover table-sm">
            <thead>
              {renderTableHeader()}
            </thead>
            <tbody>
              {renderTableBody()}
            </tbody>
          </table>
        </div>
      </div>
    </div>;
  };

  const renderTableBody = (): JSX.Element[] => {
    return result.map((line, index) => {
      return (
        <tr key={index}>
          <td>
            <b>{identity(line.guest)}</b>
            <div><small>{line.guest.company_name}</small></div>
          </td>
          <td>
            <span className="badge rounded-pill" style={{ backgroundColor: line.guest_category.label_color }}>{line.guest_category.name}</span>
          </td>
          <td>{line.nb_requested_reservations}</td>
          <td>{line.nb_confirmed_reservations}</td>
          <td>{line.nb_appointments_with_checkin}</td>
          <td>{line.ratio_of_appointment_made}</td>
        </tr>
      );
    });
  };

  const renderEmptyMsg = (): JSX.Element => {
    return <div className="card nothing-yet">
      <h4>{i18n("no_data")}</h4>
    </div>;
  };

  return <>
    { renderHeader() }
    { isFetching ? <Loader/> : renderTable() }
  </>;
};

export default MeetingsbyNumberOfAppointments;
