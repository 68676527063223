import { ActionTypes } from "../constants/Constants";
import { SessionRoomsState } from "../types/SessionRoom";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";

const defaultState: SessionRoomsState = {
  errors: null,
  isFetching: false,
  data: [],
};

function sessionRooms(state = defaultState, action: any): SessionRoomsState {
  switch (action.type) {

  case ActionTypes.FETCH_SESSION_ROOMS_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.FETCH_SESSION_ROOMS_SUCCESS:
    return { ...state, data: action.data, isFetching: false, errors: null };
  case ActionTypes.FETCH_SESSION_ROOMS_FAILURE:
    return { ...state, data: null, isFetching: false, errors: action.data };

  case ActionTypes.UPDATE_SESSION_ROOM_SUCCESS:
  case ActionTypes.CREATE_SESSION_ROOM_SUCCESS: {
    const rooms = insertOrUpdateObject(state.data, action.data, "_id");
    return { ...state, data: rooms, isFetching: false, errors: null };
  }

  default:
    return state;
  }
}

export default sessionRooms;
