import { Component } from "react";

class TextAreaType extends Component {

  render() {
    return (
      <div className="mb-3">
        <input type="text" className="form-control form-control-sm" placeholder={ I18n.t("react.form_text_area.answer") } readOnly/>
      </div>
    );
  }
}

export default TextAreaType;
