import { useState } from "react";
import { Website } from "../types/Website";
import PartnerView from "./website/consent_notice/PartnerView.react";
import { insertOrUpdateObject, mergeObjectWithExistingObject } from "../utils/StateOperationUtils";
import WebsiteFormSaveButton from "./WebsiteFormSaveButton.react";
import AddPartner from "./website/consent_notice/AddPartner.react";
import CustomPurposes from "./website/consent_notice/CustomPurposes.react";
import { pathToWebsiteGdpr, pathToWebsiteConfig } from "../utils/pathUtils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import HelpSection from "./shared/HelpSection.react";

type WebsiteConsentNotice = Pick<Website, "use_utm_data_tracking" | "partners" | "consent_notice_type" | "didomi_consent_notice_enabled" | "privacy_policy_url" | "custom_purposes">;

const i18n = (key: string, opts = {}): string => {
  return I18n.t(`react.consent_notice.${key}`, opts);
};

interface Props {
  website: Website;
  pendingRequest: boolean;
  migratingFromLegacyNotice: boolean;
  onSubmit: (data: WebsiteConsentNotice) => void;
  showTranslationPane?: () => void;
}

const WebsiteConsentNoticeForm: React.FC<Props> = ({
  website,
  pendingRequest,
  onSubmit,
  migratingFromLegacyNotice,
  showTranslationPane
}) => {

  const [partners, setPartners] = useState(website.partners || []);
  const [integrationScripts, setIntegrationScripts] = useState(partners.reduce((acc, p) => {
    return { ...acc, [p.sdk_id]: p.integration_script };
  }, {}));

  const [userPurposesMapping, setUserPurposesMapping] = useState(partners.reduce((acc, p) => {
    return { ...acc, [p.sdk_id]: p.user_defined_purposes_id };
  }, {}));

  const [customPurposes, setCustomPurposes] = useState(website.custom_purposes || []);

  const [useUTMDataTracking, setUseUTMDataTracking] = useState(website.use_utm_data_tracking || false);
  const [noticeType, setNoticeType] = useState(website.consent_notice_type);

  const [didomiConsentNoticeEnabled, setDidomiConsentNoticeEnabled] = useState(website.didomi_consent_notice_enabled || migratingFromLegacyNotice || false);

  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState(website.privacy_policy_url);

  const handleSubmit = (): void => {
    onSubmit({
      use_utm_data_tracking: useUTMDataTracking,
      partners: partners.map(p => {
        return {
          ...p,
          integration_script: integrationScripts[p.sdk_id],
          user_defined_purposes_id: userPurposesMapping[p.sdk_id]
        };
      }),
      consent_notice_type: noticeType,
      didomi_consent_notice_enabled: didomiConsentNoticeEnabled,
      privacy_policy_url: privacyPolicyUrl,
      custom_purposes: customPurposes
    });
  };

  const renderFormInputHelp = (help: string|JSX.Element): JSX.Element => {
    return (
      <div className="form-text">
        <i className="fa-regular fa-info-circle"></i> {help}
      </div>
    );
  };

  const renderHelp = (message: string | JSX.Element): JSX.Element => {
    return <HelpSection key={`help-${message}`} help={message} />;
  };

  return (
    <>
      <div className="header-page">
        <div className="header-page-title">
          <h1>
            <a href={pathToWebsiteConfig()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></a>
            {i18n("title")}
          </h1>
        </div>
      </div>
      <div className="card mb-10">
        <div className="card-body">
          <p><strong>{i18n("why_consent_needed")}</strong></p>
          <p>{i18n("introduction")}</p>
          <p>{i18n("partner_mention")} <a href="https://www.didomi.io/" target="_blank">{i18n("didomi")} <i className="fa-regular fa-external-link"></i></a></p>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="card mb-10">
          <div className="card-header">
            <h4 className="card-title">{i18n("setup_consent_notice")}</h4>
          </div>
          <div className="card-body">
            {renderHelp(<a href={(i18n("help_center_link"))} target="_blank">{i18n("help_center_link_label")}</a>)}
            <div className="form-check">
              <label className="form-check-label">
                <input type="checkbox" className="form-check-input" checked={didomiConsentNoticeEnabled} onChange={(e): void => {
                  setDidomiConsentNoticeEnabled(e.target.checked);
                }}/>
                {i18n("setup_notice")}
              </label>
            </div>

            {didomiConsentNoticeEnabled && <div className="mb-3">
              <label className="form-label">{i18n("privacy_policy_url")}</label>
              <input type="text" value={privacyPolicyUrl || ""} className="form-control" onChange={(e): void => setPrivacyPolicyUrl(e.target.value)}/>
            </div>}
          </div>
        </div>

        {didomiConsentNoticeEnabled && (<>
          <div className="card mb-10">
            <div className="card-header">
              <h4 className="card-title">
                {i18n("configure_purposes") + " "}
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="tooltip-bar-step">{ i18n("configure_purposes_help") }</Tooltip>}>
                  <i className="fa-regular fa-info-circle" aria-hidden="true"/>
                </OverlayTrigger>
              </h4>
            </div>
            <div className="card-body">
              <CustomPurposes
                customPurposes={customPurposes}
                onPurposeChange={(purpose): void => {
                  setCustomPurposes(insertOrUpdateObject(customPurposes, purpose, "id"));
                }}
                removePurpose={(purpose): void => {
                  setCustomPurposes(customPurposes.filter(p => p.id !== purpose.id));
                }}
              />
            </div>
          </div>

          <div className="card mb-10">
            <div className="card-header">
              <h4 className="card-title">
                {i18n("configure_partners") + " "}
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="tooltip-bar-step">{ i18n("configure_partners_help") }</Tooltip>}>
                  <i className="fa-regular fa-info-circle" aria-hidden="true"/>
                </OverlayTrigger>
              </h4>
            </div>
            <div className="card-body">
              {partners.map(p => {
                return (
                  <PartnerView
                    key={p.sdk_id}
                    partner={p}
                    customPurposes={customPurposes}
                    userDefinedPurposeIds={userPurposesMapping[p.sdk_id] || []}
                    integrationScript={integrationScripts[p.sdk_id] || ""}
                    onPurposeIdsChange={(purposes): void => {
                      setUserPurposesMapping({ ...userPurposesMapping, [p.sdk_id]: purposes });
                    }}
                    onIntegrationScriptChange={(script): void => {
                      setIntegrationScripts({ ...integrationScripts, [p.sdk_id]: script });
                    }}
                    onPartnerChange={(partner): void => {
                      setPartners(mergeObjectWithExistingObject(partners, partner, "id"));
                    }}
                    removePartner={(): void => {
                      setPartners(partners.filter(partner => p.sdk_id !== partner.sdk_id));
                    }}
                  />
                );
              })}
              <div className="mb-3">
                <AddPartner
                  eventId={website.event_id}
                  existingPartners={partners}
                  onNewPartner={(partner): void => {
                    if (partner.user_defined_purposes_id) {
                      setUserPurposesMapping({
                        ...userPurposesMapping,
                        [partner.sdk_id]: partner.user_defined_purposes_id
                      });
                    }
                    if (partner.integration_script?.length > 0) {
                      setIntegrationScripts({
                        ...integrationScripts,
                        [partner.sdk_id]: partner.integration_script
                      });
                    }
                    setPartners([...partners, partner]);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{i18n("use_utm_data_tracking")}</label>
                <div className="form-check">
                  <label className="form-check-label">
                    <input type="checkbox" className="form-check-input" checked={useUTMDataTracking} onChange={(e): void => {
                      setUseUTMDataTracking(e.target.checked);
                    }}/>
                    {i18n("use_utm_data_tracking_checkbox")}
                  </label>
                </div>
                {renderFormInputHelp(i18n("use_utm_data_tracking_help"))}
              </div>
              <div className="mb-3">
                <label className="form-label">{i18n("notice_look_and_feel")}</label>
                <select className="form-select" value={noticeType} style={{ maxWidth: "250px" }} onChange={(e): void => {
                  setNoticeType(e.target.value);
                }}>
                  {["banner", "popup"].map(option => {
                    return <option key={option} value={option}>{i18n(`notice_type.${option}`)}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>
        </>)}

        <div className="card mb-10">
          <div className="card-body">
            {showTranslationPane && (
              <span><i className="fa-regular fa-language"></i> <a href="#" onClick={showTranslationPane}>{i18n("translate")}</a></span>
            )}
          </div>
        </div>

        <WebsiteFormSaveButton
          pendingRequest={pendingRequest}
        />
        {migratingFromLegacyNotice && (
          <a href={pathToWebsiteGdpr()} className="btn btn-secondary" style={{ marginLeft: "5px" }}>
            {i18n("migrate_later")}
          </a>
        )}
      </form>
    </>
  );
};

export default WebsiteConsentNoticeForm;
