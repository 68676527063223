import { createSelector } from "reselect";
import isEmpty from "lodash/isEmpty";

const salesStatisticsData = (state) => {
  // this selector requires that guestCategories and accesspoints will at some point be in the state
  return [
    state.guestsPaymentStatistics,
    state.guestCategories.data,
    state.accesspoints.data,
    state.guestCategories.fetched,
    state.accesspoints.fetched
  ];
};

export const salesStatisticsDerivedData = createSelector(
  salesStatisticsData,
  (salesStatisticsData) => {
    const [statistics, guestCategories, accesspoints, guestCategoriesFetched, accesspointsFetched] = salesStatisticsData;
    if (isEmpty(statistics) || !guestCategoriesFetched || !accesspointsFetched) {
      return {};
    }
    const finalStats = {};
    const eventCurrency = defaultCurrency(guestCategories);
    const items = priceableItems(accesspoints, guestCategories, eventCurrency);
    Object.keys(statistics).forEach(key => {
      finalStats[key] = {};
      finalStats[key]["data"] = computeDerivedData(sortCountDescending(statistics[key]), items, eventCurrency);
      finalStats[key]["total"] = computeTotal(finalStats[key]["data"]);
    });
    return finalStats;
  }
);

function sortCountDescending(stats) {
  return stats.sort((a, b) => {
    return b.count - a.count;
  });
}

function defaultCurrency(guestCategories) {
  const firstDefinedCategory = guestCategories.find(category => category.currency);
  return firstDefinedCategory && firstDefinedCategory.currency || "EUR";
}

function priceableItems(accesspoints, guestCategories, eventCurrency) {
  const result = {};
  accesspoints.concat(guestCategories).forEach(item => {
    const id = item._id || item.id;
    result[id] = {
      name: item.name,
      price: item.price,
      taxes: (isNaN(item.vat) ? item.tax : item.vat) + item.other_tax,
      currency: item.currency || eventCurrency, // accesspoint do not have currency
      label_color: item.label_color
    };
  });
  return result;
}

function deletedItem(stat, eventCurrency) {
  const price = stat.total_price_incl_vat / stat.count;
  const taxes = ((-1 + (price / stat.total_price_excl_taxes)) * 100).toFixed(0);
  return { currency: eventCurrency, name: I18n.t("react.sales_statistics_selector.deleted_item"), price, taxes };
}

function computeDerivedData(stats, items, eventCurrency) {
  const result = [];
  stats.forEach(stat => {
    const { _id } = stat; // might be {"accesspoint_id" : xxx} or {"guest_category_id" : yyy}
    const itemType = Object.keys(_id)[0]; // accesspoint_id or guest_category_id
    const id = _id[itemType];
    if (!id) return stat; // not sure why, API may returns without ids
    const item = items[id] || deletedItem(stat, eventCurrency); // if guest category or accesspoint is deleted
    result.push(Object.assign({},
      stat,
      item,
      computePromoCodeRatio(stat),
      { _id: id, type: itemType },
    ));
  });
  return result;
}

function computePromoCodeRatio(stat) {
  return {
    ...stat,
    promo_code_ratio: stat.count === 0 ? 0 : ((stat.promo_code_quantity / stat.count) * 100).toFixed(0)
  };
}

function computeTotal(stats) {
  // one total by currency
  const currencies = new Set();
  stats.forEach(stat => {
    currencies.add(stat.currency);
  });

  const result = {};
  currencies.forEach(currency => {
    const items = stats.filter(stat => stat.currency === currency);
    let resultForCurrency = {
      count: 0,
      refund_count: 0,
      refund_amount: 0,
      discount_amount: 0,
      total_price_excl_taxes: 0,
      total_price_incl_taxes: 0
    };
    items.forEach(stat => {
      const updatedResult = {
        count: resultForCurrency.count + (stat.count || 0),
        refund_count: resultForCurrency.refund_count + (stat.refund_count || 0),
        refund_amount: resultForCurrency.refund_amount + (stat.refund_amount || 0),
        discount_amount: resultForCurrency.discount_amount + (stat.discount_amount || 0),
        total_price_excl_taxes: resultForCurrency.total_price_excl_taxes + (stat.total_price_excl_taxes || 0),
        total_price_incl_taxes: resultForCurrency.total_price_incl_taxes + (stat.total_price_incl_taxes || 0)
      };
      resultForCurrency = updatedResult;
    });
    result[currency] = resultForCurrency;
  });
  return result;
}
