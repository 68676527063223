import { ActionTypes } from "../constants/Constants";
import { EventAtlasJwtTokensState } from "../types/EventAtlasJwtToken";

const defaultState: EventAtlasJwtTokensState = {
  errors: null,
  isFetching: {},
  data: {},
};

function eventAtlasJwtTokens(state = defaultState, action: any): EventAtlasJwtTokensState {
  const { eventId, data } = action;
  switch (action.type) {
  case ActionTypes.FETCH_EVENT_ATLAS_JWT_TOKEN_REQUEST:
    return { ...state, isFetching: { ...state.isFetching, [eventId]: true }, errors: null };
  case ActionTypes.FETCH_EVENT_ATLAS_JWT_TOKEN_SUCCESS:
    return { ...state, data: { ...state.data, [eventId]: data.token }, isFetching: { ...state.isFetching, [eventId]: false }, errors: null };
  default:
    return state;
  }
}

export default eventAtlasJwtTokens;
