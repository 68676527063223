import { ActionTypes } from "../constants/Constants";

module.exports = {
  setSelectedShowedUpStatuses(objectIds) {
    return {
      type: ActionTypes.SET_SELECTED_SHOWED_UP_STATUSES,
      objectIds
    };
  }

};
