import { Component } from "react";
import PropTypes from "prop-types";

const noPayment = I18n.t("shared.table_guest.no_payment_required");

const cssClassMapping = {
  PAID: "badge rounded-pill bg-payment-paid",
  FREE: "badge rounded-pill bg-payment-paid",
  REFUNDED: "badge rounded-pill bg-payment-refunded",
  PARTIALLY_PAID: "badge rounded-pill bg-payment-partially-paid",
  UNPAID: "badge rounded-pill bg-payment-not-paid",
  CANCELLED: "badge rounded-pill bg-payment-not-paid",
  PENDING: "badge rounded-pill bg-payment-not-paid"
};

class PaymentCell extends Component {

  render() {
    const { guest } = this.props;
    const { payment_status } = guest;

    const spanClassName = cssClassMapping[payment_status];

    return (
      <td className="cell-payment d-none d-sm-table-cell">
        <span className={spanClassName}>
          {payment_status == "PAID" ? <><i className="fa-regular fa-check"></i>{"\u00A0"}</> : ""}
          {payment_status ? I18n.t(`react.${payment_status}`) : noPayment }
        </span>
      </td>
    );
  }
}

PaymentCell.propTypes = {
  guest: PropTypes.object.isRequired
};

export default PaymentCell;
