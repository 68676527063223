import { Component, ChangeEvent, FormEvent } from "react";
import { Website } from "../types/Website";
import { pathToWebsiteConfig } from "../utils/pathUtils";
import WebsiteFormSaveButton from "./WebsiteFormSaveButton.react";

type WebsiteGoToRegistration = Pick<Website, "help_registration_after_signin_enabled" | "help_registration_after_signin_title" | "help_registration_after_signin_message">;

interface Props extends WebsiteGoToRegistration {
  onSubmit: (data: State) => void;
  isPendingRequest: boolean;
  showTranslationPane?: () => void;
}

type State = WebsiteGoToRegistration

function i18n(key, opts = {}): string {
  return I18n.t(`react.help_registration_after_signin.${key}`, opts);
}

class WebsiteHelpRegistrationAfterSigninConfigForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    [
      "onSubmit",
      "toggle",
      "onTitleChange",
      "onDescriptionChange",
    ].forEach(fn => this[fn] = this[fn].bind(this));

    const { help_registration_after_signin_enabled, help_registration_after_signin_title, help_registration_after_signin_message } = props;

    this.state = {
      help_registration_after_signin_enabled,
      help_registration_after_signin_title,
      help_registration_after_signin_message
    };
  }

  onTitleChange(e: ChangeEvent<HTMLInputElement>): void {
    this.setState({
      help_registration_after_signin_title: e.target.value
    });
  }

  onDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({
      help_registration_after_signin_message: e.target.value
    });
  }

  toggle(): void {
    this.setState({
      help_registration_after_signin_enabled: !this.state.help_registration_after_signin_enabled
    });
  }

  onSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { help_registration_after_signin_enabled, help_registration_after_signin_title, help_registration_after_signin_message } = this.state;

    onSubmit({
      help_registration_after_signin_enabled,
      help_registration_after_signin_title,
      help_registration_after_signin_message,
    });
  }

  renderEnabledCheckbox(): JSX.Element {
    const { help_registration_after_signin_enabled } = this.state;

    return (
      <div className="mb-3">
        <div className="form-check">
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input" checked={help_registration_after_signin_enabled} onChange={this.toggle}/>
            {i18n("help_registration_after_signin_enabled")}
          </label>
        </div>
      </div>
    );
  }

  renderTitleInput(): JSX.Element {
    const { help_registration_after_signin_title } = this.state;

    return (
      <div className="mb-3">
        <label className="form-label">{i18n("help_registration_after_signin_title")}</label>
        <input
          type="text"
          className="form-control"
          value={help_registration_after_signin_title || ""}
          onChange={this.onTitleChange}
        />
      </div>
    );
  }

  renderContentInput(): JSX.Element {
    const { help_registration_after_signin_message } = this.state;

    return (
      <div className="mb-3">
        <label className="form-label">{i18n("help_registration_after_signin_message")}</label>
        <textarea
          value={help_registration_after_signin_message || ""}
          onChange={this.onDescriptionChange}
          className="form-control"
          rows={4}
          style={{ resize: "none" }}
        />
      </div>
    );
  }

  render(): JSX.Element {
    const { help_registration_after_signin_enabled } = this.state;
    const { showTranslationPane, isPendingRequest } = this.props;

    return <>
      <div className="header-page">
        <div className="header-page-title">
          <h1>
            <a href={pathToWebsiteConfig()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></a>
            {i18n("title")}
          </h1>
        </div>
      </div>

      <form onSubmit={this.onSubmit}>
        <div className="card mb-10">
          <div className="card-body">
            {this.renderEnabledCheckbox()}
            {help_registration_after_signin_enabled && (
              <>
                {this.renderTitleInput()}
                {this.renderContentInput()}
              </>
            )}
            {showTranslationPane && (
              <div><i className="fa-regular fa-language"></i> <a href="#" onClick={showTranslationPane}>{i18n("translate")}</a></div>
            )}
          </div>
        </div>
        <WebsiteFormSaveButton
          pendingRequest={isPendingRequest}
        />
      </form>
    </>;
  }
}

export default WebsiteHelpRegistrationAfterSigninConfigForm;
