import { useContext } from "react";
import { featureSetTranslation, childFeatures, isSingleFeature, enabledByDefault } from "../../utils/featureSetUtils";
import { FeatureDeckContext } from "../../containers/features/FeatureSetFormContainer.react";

interface Props {
  featureKey: string;
}

function counter(groupKey: string): string {
  if (isSingleFeature(groupKey)) return;

  const { enabledGroupFeatures } = useContext(FeatureDeckContext);

  const totalFeaturesCount = childFeatures(groupKey).length;
  const enabledFeaturesCount = enabledGroupFeatures(groupKey).length;

  if (enabledFeaturesCount > 0) {
    return `${enabledFeaturesCount}/${totalFeaturesCount} ${featureSetTranslation("features_enabled")}`;
  }
  return `${totalFeaturesCount} ${featureSetTranslation("features_available")}`;
}

function singleStatus(featureKey: string): string {
  const { featureEnabled, featureBeingToggled, featureBeingEnabled } = useContext(FeatureDeckContext);

  const status = featureBeingToggled(featureKey) ?
    (featureBeingEnabled(featureKey) ? "will_be_enabled" : "will_be_disabled") :
    (featureEnabled(featureKey) || enabledByDefault(featureKey) ? "enabled" : "disabled");

  return featureSetTranslation(status);
}

const FeatureStatus: React.FC<Props> = ({ featureKey }) => {
  return <span className="label-status d-flex flex-column justify-content-center">{ isSingleFeature(featureKey) ? singleStatus(featureKey) : counter(featureKey) }</span>;
};

export default FeatureStatus;
