import { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import GuestCategoryForm from "../../components/shared/GuestCategoryForm.react";

import { fetchGuestCategory, createGuestCategory, updateGuestCategory, fetchPopulationTypes } from "../../actions/GuestCategoryActionCreators";
import { requestEvent } from "../../actions/ImportActionCreators";
import { fetchAccesspoints } from "../../actions/AccesspointActionCreators";
import { fetchGuestProductCollections } from "../../actions/GuestProductCollectionsActionCreators";

import { showNotice } from "../../actions/NoticeActionCreators";
import { pathToGuestCategoryShow } from "../../utils/pathUtils";
import Loader from "../../components/shared/Loader.react";

class GuestCategoryFormContainer extends Component {
  constructor(props) {
    super(props);
    const methods = [
      "onSubmit"
    ];
    methods.forEach((item) => {
      this[item] = this[item].bind(this);
    });
  }

  componentDidMount() {
    const { fetchGuestCategory, fetchGuestProductCollections, guestProductCollections, isFetchingGuestProductCollections, match, accesspoints, fetchAccesspoints, fetchPopulationTypes, requestEvent } = this.props;
    const { event_id, guest_category_id } = match.params;
    if (!guest_category_id && isEmpty(accesspoints)) {
      fetchAccesspoints(event_id);
    }

    if (event_id && !isFetchingGuestProductCollections && guestProductCollections === null) {
      fetchGuestProductCollections(event_id);
    }

    if (guest_category_id) {
      fetchGuestCategory(event_id, guest_category_id);
    }

    fetchPopulationTypes(event_id);
    requestEvent();
  }

  onSubmit() {
    return (guestCategory) => {
      const { createGuestCategory, match, updateGuestCategory } = this.props;
      const { params } = match;
      if (!params.guest_category_id) {
        createGuestCategory(params.event_id, guestCategory, pathToGuestCategoryShow(":id"));
      } else {
        updateGuestCategory(params.event_id, params.guest_category_id, guestCategory, pathToGuestCategoryShow(params.guest_category_id));
      }
      showNotice(I18n.t("react.guest_field.form.guest_field_modified_notice"), "success");
    };
  }

  render() {
    const { guestCategoryErrors, guestCategory, accesspoints, guestProductCollections, match, populationTypes, accesspointsFetched, event } = this.props;
    const { params } = match;
    if (params.guest_category_id && !guestCategory || !params.guest_category_id && isEmpty(accesspoints) && !accesspointsFetched) {
      return <Loader />;
    }

    return <GuestCategoryForm
      guestCategory={guestCategory}
      guestProductCollections={guestProductCollections}
      guestCategoryId={params.guest_category_id}
      populationTypes={populationTypes}
      accesspoints={accesspoints}
      event={event}
      isModal={false}
      errors={guestCategoryErrors}
      onSubmit={this.onSubmit()}
      isEdit={!!params.guest_category_id}/>;
  }
}

function mapStateToProps(state) {
  return {
    guestCategory: state.guestCategory.data,
    guestProductCollections: state.guestProductCollections.data,
    isFetchingGuestProductCollections: state.guestProductCollections.isFetching,
    guestCategoryErrors: state.guestCategory.errors,
    accesspoints: state.accesspoints.data,
    accesspointsFetched: state.accesspoints.fetched,
    populationTypes: state.guestCategory.populationTypes,
    event: state.event
  };
}

const mapDispatchToProps = {
  fetchGuestCategory,
  fetchGuestProductCollections,
  createGuestCategory,
  updateGuestCategory,
  fetchAccesspoints,
  showNotice,
  fetchPopulationTypes,
  requestEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestCategoryFormContainer);
