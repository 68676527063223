import { ActionTypes } from "../constants/Constants";

module.exports = {
  setSelectedGuestStatuses(objectIds) {
    return {
      type: ActionTypes.SET_SELECTED_GUEST_STATUSES,
      objectIds
    };
  }

};
