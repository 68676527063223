import { Component } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import DatePicker from "./DatePicker.react";
import moment from "moment";

const NB_HOURS_IN_DAY = 24;
const NB_MINUTES_IN_HOUR = 60;

class DateTimePicker extends Component {
  constructor(props) {
    super(props);
    const methods = [
      "preventDefault",
      "renderDateTimePopover",
      "renderDateTimeSelected",
      "renderTime",
      "renderOptions",
      "renderPopoverContent",
      "closePopover",
      "onToggle",
    ];
    methods.forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = {
      displayPopover: false,
    };
  }

  preventDefault(e) {
    e.preventDefault();
  }

  closePopover(e) {
    const { onValidate } = this.props;
    e.preventDefault();
    this.setState({ displayPopover: false });

    if (onValidate) onValidate();
  }

  renderOptions(time, limit) {
    const options = [];
    for (let i = 0; i <= limit; i++) {
      const valueToDisplay = `${i}${I18n.t(`react.registration_date.${time.toLowerCase()}`)}`;
      options.push(<option key={valueToDisplay} value={i}>{valueToDisplay}</option>);
    }
    return options;
  }

  renderTime() {
    const { onChangeHour, onChangeMinute, selectedDate } = this.props;
    const currentHour = parseInt(moment(selectedDate).format("HH"));
    const currentMinute = parseInt(moment(selectedDate).format("mm"));
    let disabled = false;
    if (!selectedDate) disabled = true;
    return (
      <div style={{ marginTop: "10px" }}>
        <select disabled={disabled} style={{ float: "left", width: "auto", marginRight: "5px" }} className="form-select" onChange={onChangeHour} value={currentHour}>
          {this.renderOptions("H", NB_HOURS_IN_DAY - 1)}
        </select>
        <select disabled={disabled} style={{ float: "left", width: "auto", marginRight: "5px" }} className="form-select" onChange={onChangeMinute} value={currentMinute}>
          {this.renderOptions("m", NB_MINUTES_IN_HOUR - 1)}
        </select>
        <a href="" onClick={this.closePopover} className="btn btn-secondary" style={{ float: "right" }}>Ok</a>
        <div className="clearfix" />
      </div>
    );
  }

  renderPopoverContent() {
    const { selectedDate } = this.props;
    const { onChangeDate, calendarPlacement, minDate } = this.props;
    return (
      <div style={{ padding: "10px" }}>
        <DatePicker
          selectedDate={selectedDate ? new Date(selectedDate) : null}
          onChange={onChangeDate}
          isClearable={true}
          minDate={new Date(minDate)}
          calendarPlacement={calendarPlacement}
        />
        { this.renderTime() }
      </div>
    );
  }

  renderDateTimePopover() {
    const { title } = this.props;
    return (
      <Popover id="datetime-popover">
        <div className="popover-title">{title}</div>
        <div className="popover-content">
          { this.renderPopoverContent() }
        </div>
      </Popover>
    );
  }

  renderDateTimeSelected() {
    const { selectedDate, reset, locale, labelDateFormat } = this.props;
    if (!selectedDate) return null;
    return (
      <div className="col-12">
        <span className="badge rounded-pill bg-secondary">
          {moment(selectedDate).locale(locale).format(labelDateFormat)}
          {
            reset && <a href="" onClick={reset} style={{ marginLeft: "4px", color: "#FFF" }}>
              <i className="fa-regular fa-xmark" aria-hidden="true"></i>
            </a>
          }
        </span>
      </div>
    );
  }

  onToggle() {
    const { displayPopover } = this.state;
    this.setState({ displayPopover: !displayPopover });
  }

  render() {
    const { title, extraClass } = this.props;
    const { displayPopover } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <OverlayTrigger trigger="click" show={displayPopover} onToggle={this.onToggle} placement="top" overlay={this.renderDateTimePopover()} >
            <Button variant="secondary" className={`text-start ${extraClass}`} id="date-time" onClick={this.preventDefault}>{ title }</Button>
          </OverlayTrigger>
        </div>
        {this.renderDateTimeSelected()}
      </div>
    );
  }
}

export default DateTimePicker;

DateTimePicker.propTypes = {
  title: PropTypes.string,
  selectedDate: PropTypes.object,
  locale: PropTypes.string,
  onChangeDate: PropTypes.func,
  onChangeHour: PropTypes.func,
  onChangeMinute: PropTypes.func,
  reset: PropTypes.func,
  labelDateFormat: PropTypes.string,
  minDate: PropTypes.string,
  extraClass: PropTypes.string
};

DateTimePicker.defaultProps = {
  title: "Title",
  locale: "en",
  selectedDate: null,
  labelDateFormat: "LLL",
  extraClass: ""
};
