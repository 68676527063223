import { ActionTypes } from "../constants/Constants";
import yaml from "js-yaml";

const initialState = {
  guestCampaign: {},
  errors: [],
  templates: [],
  accountTemplates: [],
  eventTemplates: [],
  deliveryError: "",
  persisted: false,
  isUpdating: false,
  createTemplateError: null,
  pendingRequest: false
};

function guestCampaign(state = initialState, action) {
  switch (action.type) {
  case ActionTypes.CREATE_GUEST_CAMPAIGN_SUCCESS:
  case ActionTypes.UPDATE_GUEST_CAMPAIGN_SUCCESS:
    return Object.assign({}, state, { guestCampaign: action.data, errors: [], persisted: true, isUpdating: false });
  case ActionTypes.CREATE_GUEST_CAMPAIGN_REQUEST:
  case ActionTypes.UPDATE_GUEST_CAMPAIGN_REQUEST:
    return Object.assign({}, state, { errors: [], persisted: true, isUpdating: true });
  case ActionTypes.OFFLINE_UPDATE_GUEST_CAMPAIGN_SUCCESS:
    return Object.assign({}, state, { guestCampaign: action.data, errors: [], persisted: false, isUpdating: false });
  case ActionTypes.DELETE_FILE_EDITABLE_FIELD:
    return Object.assign({}, state, { guestCampaign: action.data, errors: [] });
  case ActionTypes.CREATE_GUEST_CAMPAIGN_FAILURE:
  case ActionTypes.RECEIVE_GUEST_CAMPAIGN_FAILURE:
  case ActionTypes.UPDATE_GUEST_CAMPAIGN_FAILURE:
    return Object.assign({}, state, { errors: action.data.errors, isUpdating: false });
  case ActionTypes.RECEIVE_GUEST_CAMPAIGN_SUCCESS:
    return Object.assign({}, state, { guestCampaign: action.data, errors: [], persisted: true });
  case ActionTypes.RECEIVE_STANDARD_THEME_SUCCESS:
    return Object.assign({}, state, { templates: parseTemplate(action.data) });
  case ActionTypes.RECEIVE_ACCOUNT_TEMPLATES_SUCCESS:
    return Object.assign({}, state, { accountTemplates: action.data });
  case ActionTypes.RECEIVE_EVENT_EMAIL_TEMPLATES_SUCCESS:
    return Object.assign({}, state, { eventTemplates: action.data });
  case ActionTypes.DELIVER_CAMPAIGN_REQUEST:
    return Object.assign({}, state, { pendingRequest: true, deliveryError: "" });
  case ActionTypes.DELIVER_CAMPAIGN_SUCCESS:
    return Object.assign({}, state, { pendingRequest: false, deliveryError: "" });
  case ActionTypes.DELIVER_CAMPAIGN_FAILLURE:
    return Object.assign({}, state, { pendingRequest: false, deliveryError: action.data.error });
  case ActionTypes.GUEST_CAMPAIGN_GLOBAL_STATISTICS_SUCCESS:
    return Object.assign({}, state, { globalStatistics: action.data.global_statistics });
  case ActionTypes.CLEAR_GUEST_CAMPAIGN_STORE:
    return initialState;
  case ActionTypes.LIQUID_TEMPLATE_CREATE_SUCCESS: {
    const { accountTemplates } = state;
    const nextAccountTemplates = accountTemplates.slice();
    nextAccountTemplates.push(action.data);
    return Object.assign({}, state, { accountTemplates: nextAccountTemplates, createTemplateError: null });
  }
  case ActionTypes.LIQUID_TEMPLATE_CREATE_FAILURE:
    return Object.assign({}, state, { createTemplateError: action.data });
  default:
    return state;
  }
}

function parseTemplate(data) {
  const template = yaml.load(data);
  return Object.keys(template).map(key => {
    return template[key];
  });
}

export default guestCampaign;
