"use strict";
import { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { pathToSessionsListFilteredBySessionType } from "../utils/pathUtils";
import ColoredObjectForm from "../components/ColoredObjectForm.react";

class ColoredObjectRow extends Component {
  constructor(props) {
    super(props);
    [
      "handleFocus",
      "handleUpdate",
      "handleDelete",
      "cancel"
    ].forEach((item) => { this[item] = this[item].bind(this); });
  }

  handleFocus() {
    const { item, handleFocus } = this.props;
    handleFocus(item);
  }

  cancel() {
    const { handleFocus } = this.props;
    handleFocus(null);
  }

  handleUpdate(newValue, newColor) {
    const { handleUpdate, item } = this.props;
    handleUpdate(item, newValue, newColor);
  }

  handleDelete() {
    const { handleDelete, item } = this.props;
    handleDelete(item);
  }

  i18n(key, options = {}) {
    return I18n.t(`react.${this.props.i18nKey}.${key}`, options);
  }

  renderEdit() {
    const { item, errorMessage, i18nKey, modelName, depthLevel } = this.props;

    return <ColoredObjectForm
      handleSave={this.handleUpdate}
      handleCancel={this.cancel}
      name={item.name}
      color={item.color}
      errorMessage={errorMessage}
      submitButtonName={I18n.t("update")}
      i18nKey={i18nKey}
      actionButtonsRight={true}
      allowColorEdition={modelName !== "thematic" || depthLevel === 0}
    />;
  }

  renderThematicAvgScore() {
    const { item, event, selectedPopulation } = this.props;

    if (!event.id || !event.matchmaking_enabled || !selectedPopulation) return null;

    const averageScore = item.avg_score[selectedPopulation] || 0;
    const tooltip = <Tooltip id="score-tooltip">{this.i18n("score_tooltip", { name: item.name })}</Tooltip>;

    return <OverlayTrigger placement="top" overlay={tooltip}>
      <span className="badge rounded-pill bg-secondary big mr-40">
        { Math.round(averageScore) }
      </span>
    </OverlayTrigger>;
  }

  renderSessionTypesSessionsLink(sessionTypeId) {
    return <a href={pathToSessionsListFilteredBySessionType(sessionTypeId)} className="mr-10">{this.i18n("see_sessions")}</a>;
  }

  renderAddSubItemButton() {
    const { setupEmptyChildItemHandler, item } = this.props;
    const tooltip = <Tooltip id="score-tooltip">{this.i18n("add_sub_item")}</Tooltip>;

    return <OverlayTrigger placement="top" overlay={tooltip}>
      <button className="btn btn-sm btn-secondary ml-10" onClick={setupEmptyChildItemHandler(item)}>
        <i className="fa-regular fa-plus"></i>
      </button>
    </OverlayTrigger>;
  }

  renderError() {
    const { errorMessage } = this.props;
    return errorMessage && <span className="ml-10 mb-3 text-danger">{ errorMessage }</span>;
  }

  renderShow() {
    const { item, modelName, allowSubItemCreation } = this.props;

    return <div>
      <span className="badge rounded-pill big" style={{ backgroundColor: item.color }}>
        <i className="fa-regular fa-tag"></i> {item.name}
      </span>
      {allowSubItemCreation && this.renderAddSubItemButton()}
      {this.renderError()}
      <div className="float-end">
        { modelName == "thematic" && this.renderThematicAvgScore() }
        { modelName == "session_type" && this.renderSessionTypesSessionsLink(item._id) }
        <a className="btn btn-sm btn-secondary mr-10" onClick={ this.handleFocus } >
          <i className="fa-regular fa-pen-to-square"></i> {I18n.t("edit")}
        </a>
        <a className="btn btn-sm btn-danger" onClick={ this.handleDelete } >
          <i className="fa-regular fa-trash-can"></i> {I18n.t("remove")}
        </a>
      </div>
    </div>;
  }

  render() {
    const { updating, depthLevel } = this.props;
    const result = updating ? this.renderEdit() : this.renderShow();

    return <tr>
      <td style={{ padding: "15px", paddingLeft: `calc(${depthLevel} * 50px + 15px)` }}>{ result }</td>
    </tr>;
  }
}

export default ColoredObjectRow;
