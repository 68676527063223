import { ActionTypes, CALL_API } from "../constants/Constants";
import { stringify } from "querystring";

export function fetchSessionTypes(eventId, options = {}) {
  const { DEV_NULL, RECEIVE_SESSION_TYPES_REQUEST, RECEIVE_SESSION_TYPES_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [RECEIVE_SESSION_TYPES_REQUEST, RECEIVE_SESSION_TYPES_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/session_types.json?${stringify(options)}`
    }
  };
}

export function createSessionType(eventId, sessionTypeParams) {
  const { CREATE_SESSION_TYPE_REQUEST, CREATE_SESSION_TYPE_SUCCESS, SESSION_TYPE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_SESSION_TYPE_REQUEST, CREATE_SESSION_TYPE_SUCCESS, SESSION_TYPE_FAILURE],
      method: "POST",
      body: { session_type: sessionTypeParams },
      endpoint: `/api/v1/events/${eventId}/session_types.json`
    }
  };
}

export function updateSessionType(eventId, sessionTypeId, sessionTypeParams) {
  const { UPDATE_SESSION_TYPE_REQUEST, UPDATE_SESSION_TYPE_SUCCESS, SESSION_TYPE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_SESSION_TYPE_REQUEST, UPDATE_SESSION_TYPE_SUCCESS, SESSION_TYPE_FAILURE],
      method: "PUT",
      body: { session_type: sessionTypeParams },
      endpoint: `/api/v1/events/${eventId}/session_types/${sessionTypeId}.json`
    },
    sessionTypeId
  };
}

export function deleteSessionType(eventId, sessionTypeId) {
  const { DEV_NULL, REMOVE_SESSION_TYPE_SUCCESS, SESSION_TYPE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, REMOVE_SESSION_TYPE_SUCCESS, SESSION_TYPE_FAILURE],
      method: "DELETE",
      endpoint: `/api/v1/events/${eventId}/session_types/${sessionTypeId}.json`
    },
    sessionTypeId
  };
}

export function clearSessionTypeErrors() {
  return { type: ActionTypes.CLEAR_SESSION_TYPE_ERRORS };
}
