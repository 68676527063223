import { ActionTypes } from "../constants/Constants";
import { GuestProductCollectionState } from "../types/GuestProductCollection";

const defaultState: GuestProductCollectionState = {
  errors: null,
  isFetching: false,
  data: null,
  successfullyCreated: false,
  successfullyUpdated: false,
  isSaving: false,
  itemsCollectionCount: null,
  exportNotice: false
};

function guestProductCollection(state = defaultState, action: any): GuestProductCollectionState {
  switch (action.type) {
  case ActionTypes.CREATE_GUEST_PRODUCT_COLLECTION_REQUEST:
    return { ...state, ...defaultState, isSaving: true };
  case ActionTypes.UPDATE_GUEST_PRODUCT_COLLECTION_REQUEST:
    return { ...state, ...defaultState, isFetching: true };
  case ActionTypes.EXPORT_ITEMS_COLLECTION_REQUEST:
    return { ...state, ...defaultState };
  case ActionTypes.CREATE_GUEST_PRODUCT_COLLECTION_SUCCESS:
    return { ...state, data: action.data, isSaving: false, errors: null, successfullyCreated: true };
  case ActionTypes.UPDATE_GUEST_PRODUCT_COLLECTION_SUCCESS:
    return { ...state, data: action.data, isFetching: false, errors: null, successfullyUpdated: true };
  case ActionTypes.DELETE_GUEST_PRODUCT_COLLECTION_SUCCESS:
    return { ...defaultState, data: action.data, errors: null };
  case ActionTypes.FETCH_ITEMS_COLLECTION_COUNT_SUCCESS:
    return { ...state, itemsCollectionCount: action.data };
  case ActionTypes.EXPORT_ITEMS_COLLECTION_SUCCESS:
    return { ...state, exportNotice: true };
  case ActionTypes.EXPORT_ITEMS_COLLECTION_ERROR:
    return { ...state, errors: action.data };
  case ActionTypes.CREATE_GUEST_PRODUCT_COLLECTION_ERROR:
    return { ...state, isSaving: false, errors: action.data, successfullyCreated: false };
  case ActionTypes.UPDATE_GUEST_PRODUCT_COLLECTION_ERROR:
    return { ...state, isFetching: false, errors: action.data, successfullyUpdated: false };
  case ActionTypes.DELETE_GUEST_PRODUCT_COLLECTION_ERROR:
    return { ...state, errors: action.data };
  case ActionTypes.RESET_GUEST_PRODUCT_COLLECTION_STATE:
    return defaultState;
  default:
    return state;
  }
}

export default guestProductCollection;
