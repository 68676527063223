import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { urlWithQuery, urlEventId } from "../utils/pathUtils";
import ColumnsFilter from "../components/ColumnsFilter.react";
import { splitStandardFromCustomGuestFieldsForExport } from "../utils/guestFieldsUtils";
import ExportSortOptions from "../components/ExportSortOptions.react";
import querystring from "querystring";

import { updateUI } from "../actions/UIAppearanceActionCreators";
import { triggerGuestsListExport, triggerQRCodesExport, triggerBadgesExport, triggerDocumentsExport, fetchExportableGuestFields } from "../actions/ExportsActionCreators";
import { fetchUserSettings, updateUserSettings } from "../actions/UserSettingsActionCreators";
import { fillStateFromSearchQuery } from "../actions/SearchQueryActionCreators";
import { fetchAccesspoints } from "../actions/AccesspointActionCreators";
import withModal from "./withModal.react";
import { createColumnsSet, updateColumnsSet, deleteColumnsSet } from "../actions/ColumnsSetsActionCreators";
import ErrorMessage from "../components/shared/ErrorMessage.react";
import Loader from "../components/shared/Loader.react";

import { queryStringAndSelectedGuests } from "../utils/QueryStringUtils";
import { redirectIfUnauthorized, isAuthorized } from "../utils/aclUtils";
import {
  StandardLinkColumns,
  COLUMN_SECTION_STANDARD_TYPE,
  COLUMN_SECTION_GUEST_FIELD_TYPE,
  COLUMN_SECTION_ACCESSPOINT_TYPE,
  COLUMN_SECTION_LINK_TYPE
} from "../constants/Constants";
import { allEnabled } from "../utils/featureSetUtils";


const SECTIONS = [COLUMN_SECTION_STANDARD_TYPE, COLUMN_SECTION_GUEST_FIELD_TYPE, COLUMN_SECTION_ACCESSPOINT_TYPE, COLUMN_SECTION_LINK_TYPE];

class Exports extends Component {
  constructor(props) {
    redirectIfUnauthorized("guest", "mass_actions");
    super(props);
    [
      "_checkinExport",
      "_clearSearch",
      "_clearSearchLink",
      "_deleteColumnsSet",
      "_exportBadges",
      "_exportDocuments",
      "_exportGuests",
      "_exportLink",
      "_exportQRCodes",
      "_guestsMessageToggle",
      "_helpMessage",
      "_infoMessage",
      "_launchExport",
      "_noSelectedGuestsMessage",
      "_renderMainView",
      "_renderSelectExportedColumn",
      "_renderSortOptions",
      "_saveColumnsSet",
      "_selectedGuestsMessage",
      "_toggleView"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = { viewName: "main" };
  }

  componentDidMount() {
    const { fetchExportableGuestFields, fetchUserSettings, fetchAccesspoints, updateUI } = this.props;
    const updates = {
      "modalTitle": I18n.t("react.reports.export_type")
    };
    fetchExportableGuestFields(urlEventId());
    fetchUserSettings(urlEventId());
    fetchAccesspoints(urlEventId());
    updateUI(updates);
  }

  _exportGuests(sheet, exportFormat) {
    const { triggerGuestsListExport, location, history, selectedGuests } = this.props;
    const { q } = querystring.parse(location.search.substring(1));
    history.push(urlWithQuery(q, "guests"));
    triggerGuestsListExport([sheet], exportFormat, queryStringAndSelectedGuests(location, selectedGuests), {});
  }

  _exportBadges(fileNaming = "last_name", oneBadgePerPage = false) {
    const { triggerBadgesExport, location, history, selectedGuests } = this.props;
    const { q } = querystring.parse(location.search.substring(1));
    history.push(urlWithQuery(q, "guests"));
    triggerBadgesExport(queryStringAndSelectedGuests(location, selectedGuests), fileNaming, oneBadgePerPage);
  }

  _exportQRCodes(fileNaming = "last_name") {
    const { triggerQRCodesExport, location, history, selectedGuests } = this.props;
    const { q } = querystring.parse(location.search.substring(1));
    history.push(urlWithQuery(q, "guests"));
    triggerQRCodesExport(queryStringAndSelectedGuests(location, selectedGuests), fileNaming);
  }

  _exportDocuments(e) {
    e.preventDefault();
    const { triggerDocumentsExport, location, history, selectedGuests } = this.props;
    const { q } = querystring.parse(location.search.substring(1));
    history.push(urlWithQuery(q, "guests"));
    triggerDocumentsExport(queryStringAndSelectedGuests(location, selectedGuests));
  }

  // can be a component
  _exportLink(name, icon, column, footerItems = false, displayed = true) {
    const links = [];
    const classnames = (displayed) ? null : "disabled";
    // footerItems example [{name: "Excel", icon: "fa-download", actionClick: this._exportGuests}]
    if (footerItems) {
      let key = 1;
      const { q } = querystring.parse(this.props.location.search.substring(1));
      footerItems.forEach(item => {
        const actionOnClick = (displayed) ? item.actionClick : null;
        const to = (!displayed) ? urlWithQuery(q, "guests") : "#";
        const link = (
          <Link to={to} key={key} className={classNames(classnames)} onClick={actionOnClick} type="button" style={{ marginRight: "10px" }}>
            <i className={classNames("fa-regular", item.icon)}></i> {item.name}
          </Link>
        );
        links.push(link);
        key += 1;
      });
    }

    const panel_footer = (links.length > 0) ? (
      <div className="card-footer">{links}</div>
    ) : null;

    const link = (
      <div className={column} >
        <div className={classNames("card", classnames)}>
          <div className={classNames("card-body", classnames)}>
            <div className="d-flex align-items-center">
              <i className={classNames("fa-regular", "fa-2x", icon)}></i>
              <span className="ml-10">{name}</span>
            </div>
          </div>
          {panel_footer}
        </div>
      </div>
    );
    return link;
  }

  // can be a component
  _infoMessage(message, classes = "col-md-12") {
    return (
      <div className={classes}>
        <span className="badge rounded-pill bg-info">info</span> <span className="text-muted">{message}</span>
      </div>
    );
  }

  _clearSearch() {
    const { fillStateFromSearchQuery } = this.props;
    fillStateFromSearchQuery("", 1);
  }

  _clearSearchLink() {
    return (
      <Link to="#" onClick={this._clearSearch}>
        <i className="fa-regular fa-circle-xmark"></i> {I18n.t("react.exports.clear_search")}
      </Link>
    );
  }

  _selectedGuestsMessage() {
    const { location, selectedGuests, appearance } = this.props;
    const guest_count = appearance.selectedGuestCount;
    const has_search = queryStringAndSelectedGuests(location, selectedGuests);
    const message = (has_search) ? (
      <div className="d-flex">
        <p className="fw-bold mr-5">{I18n.t("react.exports.search_used")}</p> {this._clearSearchLink()}
      </div>
    ) : (
      <p className="fw-bold">{I18n.t("react.exports.guests_in_event", { nb_guests: guest_count })}</p>
    );
    return <div className="col-md-12">{message}</div>;
  }

  _noSelectedGuestsMessage() {
    return (
      <div className="col-md-12">
        <p className="fw-bold">{I18n.t("react.exports.not_selected_guests")} {this._clearSearchLink()}</p>
      </div>
    );
  }

  _guestsMessageToggle() {
    const nb_guests = this.props.guests.guests.length;
    const message = (nb_guests < 1) ? (
      this._noSelectedGuestsMessage()
    ) : this._selectedGuestsMessage();
    return message;
  }

  _helpMessage() {
    const message = I18n.t("react.exports.no_show_uniq_showed_help");
    const export_message = I18n.t("react.exports.csv_export_help");
    return (
      <div>
        {this._infoMessage(message)}
        {this._infoMessage(export_message)}
      </div>
    );
  }

  _checkinExport() {
    const { event } = this.props;
    const has_checkin = event.has_checkin;

    const footer_all_checkin = [
      { name: "xlsx", icon: "fa-file", actionClick: this._toggleView("all_checkins", "xlsx", "selectColumns") },
      { name: "xls", icon: "fa-file", actionClick: this._toggleView("all_checkins", "xls", "selectColumns") },
      { name: "csv", icon: "fa-file", actionClick: this._toggleView("all_checkins", "csv", "selectColumns") }
    ];
    const footer_attended_guests_per_checkin = [
      { name: "xlsx", icon: "fa-file", actionClick: this._toggleView("access_points", "xlsx", "selectColumns") },
      { name: "xls", icon: "fa-file", actionClick: this._toggleView("access_points", "xls", "selectColumns") },
      { name: "csv", icon: "fa-file", actionClick: this._toggleView("access_points", "csv", "selectColumns") }
    ];

    const checkin_export_link = (
      <div className="mt-3">
        <span className="fw-bold">{I18n.t("react.exports.export_checkins")}</span><hr/>
        <div className="row">
          {this._exportLink(I18n.t("react.exports.all_checkins"), "fa-check-square", "col-md-4", footer_all_checkin, has_checkin)}
          {this._exportLink(I18n.t("react.exports.attended_guests_per_checkin_point"), "fa-check-square", "col-md-8", footer_attended_guests_per_checkin, has_checkin)}
        </div>
      </div>
    );
    return checkin_export_link;
  }

  _toggleView(sheet, format, viewName = "main") {
    return (e) => {
      this.setState({ viewName: viewName, sheet: sheet, format: format });
      e.preventDefault();
    };
  }

  _launchExport(columns) {
    const { sheet, format } = this.state;
    const { updateUserSettings } = this.props;
    updateUserSettings(urlEventId(), { guest_export_columns: columns });
    this._exportGuests(sheet, format, columns);
  }

  _saveColumnsSet(name, columns, columnsSetId = null) {
    const { createColumnsSet, updateColumnsSet } = this.props;
    const eventId = urlEventId();
    if (columnsSetId) {
      updateColumnsSet(eventId, columnsSetId, { columns });
    } else {
      createColumnsSet(eventId, { name, columns });
    }
  }

  _deleteColumnsSet(columnsSetId) {
    const { deleteColumnsSet, match } = this.props;
    const { event_id } = match.params;
    deleteColumnsSet(event_id, columnsSetId);
  }

  isColumnsFilterDisplayable() {
    const { accesspoints, guestFieldsToExport } = this.props;
    return accesspoints.fetched && guestFieldsToExport.fetched;
  }

  _renderMainView() {
    const { event, guests } = this.props;
    const has_guests = (guests.guests.length > 0);
    const display_guests_list_link = has_guests;
    const display_qrcodes_link = has_guests;
    const display_documents_link = (event.has_document_template && has_guests);
    const display_carts_link = (event.has_payment && has_guests);
    const display_badges_link = (event.has_badge && has_guests);

    const footer_guest_list = [
      { name: "xlsx", icon: "fa-file", actionClick: this._toggleView("guest_list", "xlsx", "selectColumns") },
      { name: "xls", icon: "fa-file", actionClick: this._toggleView("guest_list", "xls", "selectColumns") },
      { name: "csv", icon: "fa-file", actionClick: this._toggleView("guest_list", "csv", "selectColumns") }
    ];
    const footer_qrcodes = [{ name: "png", icon: "fa-file", actionClick: this._toggleView("qr_codes", "png", "sortOptions") }];
    const footer_documents = [{ name: "pdf", icon: "fa-file", actionClick: this._exportDocuments }];
    const footer_carts = [
      { name: "xlsx", icon: "fa-file", actionClick: this._toggleView("carts", "xlsx", "selectColumns") },
      { name: "xls", icon: "fa-file", actionClick: this._toggleView("carts", "xls", "selectColumns") },
      { name: "csv", icon: "fa-file", actionClick: this._toggleView("carts", "csv", "selectColumns") }
    ];
    const footer_badges = [{ name: "pdf", icon: "fa-file", actionClick: this._toggleView("badges", "pdf", "sortOptions") }];

    return (
      <div className="row">
        <div className="col-md-12">
          <span className="fw-bold">{I18n.t("react.exports.export_guests")}</span><hr/>
          <div className="row">
            { this._guestsMessageToggle() }
            { this._exportLink(I18n.t("react.exports.guests_list"), "fa-users", "col-md-4", footer_guest_list, display_guests_list_link) }
            { this._exportLink(I18n.t("react.exports.qrcodes"), "fa-qrcode", "col-md-4", footer_qrcodes, display_qrcodes_link) }
            { this._exportLink(I18n.t("react.exports.documents"), "fa-file", "col-md-4", footer_documents, display_documents_link) }
            { this._exportLink(I18n.t("react.exports.carts"), "fa-shopping-cart", "col-md-4", footer_carts, display_carts_link) }
            { this._exportLink(I18n.t("react.exports.badges"), "fa-file", "col-md-4", footer_badges, display_badges_link) }
            { this._helpMessage() }
          </div>
          { this._checkinExport() }
        </div>
      </div>
    );
  }

  _renderSelectExportedColumn() {
    if (!this.isColumnsFilterDisplayable()) {
      return <Loader size="xlarge" inline={false} containerHeight="350px" />;
    }

    const { guestFieldsToExport, accesspoints, columnsSets, userSettings } = this.props;
    const [standardFields, guestFields] = splitStandardFromCustomGuestFieldsForExport(guestFieldsToExport.data);
    const { errors, pendingWriteRequest } = columnsSets;

    const enabledStandardLinkColumns = Object.keys(StandardLinkColumns).filter(key => allEnabled(StandardLinkColumns[key].requiredFeatures));
    const links = enabledStandardLinkColumns.map(identifier => {
      return {
        identifier,
        sortableItemId: identifier,
        name: I18n.t(`mongoid.attributes.guest.${identifier}`)
      };
    });

    return <div>
      <ErrorMessage errors={errors}/>
      <ColumnsFilter
        guestFields={guestFields.map(field => Object.assign({}, field, { sortableItemId: field.key }))}
        standardFields={standardFields.map(field => Object.assign({}, field, { sortableItemId: field.key }))}
        accesspoints={accesspoints.data.map(ap => Object.assign({}, ap, { sortableItemId: ap._id }))}
        links={links}
        onSubmit={this._launchExport}
        eventId={urlEventId()}
        saveColumnsSet={this._saveColumnsSet}
        deleteColumnsSet={this._deleteColumnsSet}
        savingColumnsSet={pendingWriteRequest}
        initialColumns={userSettings.guest_export_columns}
        sections={SECTIONS}
      />
    </div>;
  }

  _renderSortOptions() {
    const { sheet, format } = this.state;
    let handlerSubmitButton;
    switch (sheet) {
    case "badges":
      handlerSubmitButton = this._exportBadges;
      break;
    case "qr_codes":
      handlerSubmitButton = this._exportQRCodes;
      break;

    }
    return <ExportSortOptions
      sheet={sheet}
      format={format}
      handlerSubmitButton={handlerSubmitButton}
      handlerCancelButton={this._toggleView(null, null, "main")}
    />;
  }

  render() {
    if (!isAuthorized("guest", "mass_actions")) return null;
    const { viewName } = this.state;
    switch (viewName) {
    case "main":
      return this._renderMainView();
    case "selectColumns":
      return this._renderSelectExportedColumn();
    case "sortOptions":
      return this._renderSortOptions();
    default:
      return null;
    }
  }
}

const mapStateToProps = state => {
  return {
    appearance: state.appearance,
    event: state.event,
    guests: state.guests,
    columnsSets: state.columnsSets,
    accesspoints: state.accesspoints,
    guestFieldsToExport: state.guestFieldsToExport,
    selectedGuests: state.guests.selectedGuests,
    userSettings: state.userSettings
  };
};

const mapDispatchToProps = {
  createColumnsSet,
  deleteColumnsSet,
  fetchAccesspoints,
  fetchExportableGuestFields,
  fetchUserSettings,
  fillStateFromSearchQuery,
  triggerBadgesExport,
  triggerDocumentsExport,
  triggerGuestsListExport,
  triggerQRCodesExport,
  updateColumnsSet,
  updateUI,
  updateUserSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withModal(Exports)));
