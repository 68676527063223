import { Component } from "react";
import { connect } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { fetchSessionRooms } from "../actions/SessionRoomsActionCreator";
import { createSessionRoom } from "../actions/SessionRoomActionCreator";
import { SessionRoom, SessionRoomUpdateAttributes } from "../types/SessionRoom";
import { urlEventId } from "../utils/pathUtils";
import SessionRoomFormModal from "./session_room/SessionRoomFormModal.react";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";

interface Props {
  sessionRooms: SessionRoom[];
  value: string;

  createSessionRoom: (eventId: string, data: SessionRoomUpdateAttributes) => void;
  fetchSessionRooms: (eventId: string) => void;
  onChange: (option: any) => void;
}

interface State {
  selectedRoomId: string;
  roomModalDisplayed: boolean;
  roomCreating: boolean;
}

class SessionRoomsDropdown extends Component<Props, State> {

  constructor(props) {
    super(props);

    [
      "changeSessionRoom",
      "createSessionRoom",
      "toogleRoomModal",
    ].forEach(fn => this[fn] = this[fn].bind(this));

    const { value } = this.props;
    this.state = {
      roomModalDisplayed: false,
      roomCreating: false,
      selectedRoomId: value,
    };
  }

  componentDidMount(): void {
    const { fetchSessionRooms } = this.props;
    fetchSessionRooms(urlEventId());
  }

  componentDidUpdate(prevProps: Props): void {
    const { roomCreating } = this.state;
    const { sessionRooms } = this.props;
    if (prevProps.sessionRooms.length !== sessionRooms.length && roomCreating) {
      const newSessionRoom = differenceWith(sessionRooms, prevProps.sessionRooms, isEqual)[0];
      this.setState({
        roomCreating: false,
        selectedRoomId: newSessionRoom._id,
      });

      const { onChange } = this.props;
      onChange(this.sessionRoomOption(newSessionRoom));
    }
  }

  i18n(key: string, options = {}): string {
    return I18n.t(`react.session_rooms_dropdown.${key}`, options);
  }

  sessionRoomOption(sessionRoom: SessionRoom): any {
    return {
      value: sessionRoom._id,
      label: sessionRoom.name,
    };
  }

  sessionRoomOptions(): any {
    const { sessionRooms } = this.props;
    return (sessionRooms || []).map(room => this.sessionRoomOption(room));
  }

  toogleRoomModal(): void {
    this.setState({
      roomModalDisplayed: !this.state.roomModalDisplayed
    });
  }

  createSessionRoom(optionLabel: string): void {
    this.setState({
      roomCreating: true,
    });

    const { createSessionRoom } = this.props;
    createSessionRoom(urlEventId(), { name: optionLabel });
  }

  changeSessionRoom(option: any): void {
    const { onChange } = this.props;
    onChange(option);

    this.setState({
      selectedRoomId: option?.value,
    });
  }

  render(): JSX.Element {
    const { roomModalDisplayed, selectedRoomId } = this.state;
    const roomOptions = this.sessionRoomOptions();
    const selectedRoom = roomOptions.find(room => selectedRoomId == room.value);

    return <>
      <CreatableSelect
        value={[selectedRoom]}
        options={roomOptions}
        isClearable={true}
        onChange={this.changeSessionRoom}
        onCreateOption={this.createSessionRoom}
        formatCreateLabel={(label): string => this.i18n("create_option", { label: label })}
        noOptionsMessage={(): string => this.i18n("no_session_rooms")}
        placeholder={this.i18n("create_select_placeholder")}
        className={"react-select"}
        classNamePrefix={"react-select"} />
      {selectedRoomId && <div className="mt-5">
        <i className="fa-regular fa-pencil mr-5"></i>
        <span>
          <a href="#" onClick={this.toogleRoomModal}>{this.i18n("edit")}</a>
        </span>
      </div>}
      <SessionRoomFormModal
        sessionRoomId={selectedRoom?.value}
        isVisible={roomModalDisplayed}
        onClose={this.toogleRoomModal} />
    </>;
  }
}

function mapStateToProps(state: any): any {
  return {
    sessionRooms: state.sessionRooms.data,
  };
}

const mapDispatchToProps = {
  fetchSessionRooms,
  createSessionRoom,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionRoomsDropdown);
