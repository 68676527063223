import { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { urlWithQuery } from "../../utils/pathUtils";

class TemplateList extends Component {

  constructor(props) {
    super(props);
    const privateMethods = ["_changeTemplate", "_templateRows"];
    privateMethods.forEach((item) => { this[item] = this[item].bind(this); });

  }

  _changeTemplate(template) {
    return () => {
      const { onChange } = this.props;
      onChange(this._templateParams(template));
    };
  }

  _templateParams(template) {
    const { isAccountTemplate, isEventEmailTemplate } = this.props;
    if (isAccountTemplate) {
      return { liquid_template_id: template._id, replace_editable_elements_values: true, email_template_id: null };
    } else if (isEventEmailTemplate) {
      return { email_template_id: template._id, liquid_template_id: null, body_url: null };
    } else {
      return template.url ? { body_url: template.url, liquid_template_id: null, email_template_id: null } : { body: template.body, liquid_template_id: null, email_template_id: null };
    }
  }

  _templateRows() {
    const { templates, queryString, guestCampaignId, keyName, selected } = this.props;
    return (
      templates.map(template => {
        const name = template[keyName];
        let disableClassName = null;
        let btnName = I18n.t("react.guest_campaign.templates.choose");
        let btnPreview = null;
        if (name == (selected && selected[keyName])) {
          disableClassName = "disabled";
          btnName = I18n.t("react.guest_campaign.templates.your_template");
          btnPreview = (
            <Link className="btn btn-link float-end" to={urlWithQuery(queryString, `campaign/${guestCampaignId}/email?tab=preview`)}>
              {I18n.t("react.guest_campaign.preview")} <i className="fa-regular fa-long-arrow-right"></i>
            </Link>
          );
        }
        return (
          <tr className={disableClassName} key={name}>
            <td>{name}</td>
            <td>
              {btnPreview}
              <a className={classNames(disableClassName, "btn", "btn-secondary", "float-end")} onClick={this._changeTemplate(template)}>
                {btnName}
              </a>
            </td>
          </tr>
        );
      })
    );
  }

  render() {
    return (
      <div className="table-responsive table-container">
        <table className="table table-hover">
          <tbody>
            { this._templateRows() }
          </tbody>
        </table>
      </div>
    );
  }
}

export default TemplateList;

TemplateList.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.object
};
