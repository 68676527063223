import Modal from "react-modal";
import { Component } from "react";
import { companyPrefilledGuestFields, addressPrefilledGuestFields } from "../../constants/Constants";
import { defaultModalStyles } from "../../constants/Style";
import { Form } from "react-bootstrap";

class FieldsModal extends Component {
  constructor(props) {
    super(props);
    [
      "onSubmit",
      "toggleItemsToCreate",
      "toggleModal",
      "toggleCheckboxHandler"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      isOpen: false,
      itemsToCreate: [],
      prefillFields: this.optionEnabled(`prefill_${this.prefillFieldsType()}`),
      forcePrefillUsage: this.optionEnabled(`force_prefill_${this.prefillFieldsType()}`),
      useOrganizationsDatabase: this.optionEnabled("use_organizations_database"),
      organizationFields: []
    };
  }

  componentDidMount() {
    const { accountId } = this.props;
    this.fetchOrganizationFields(accountId);
  }

  fetchOrganizationFields(accountId) {
    fetch(`/api/v1/accounts/${accountId}/organization_fields.json`)
      .then(res => res.json())
      .then(res => {
        this.setState({ organizationFields: res });
      });
  }

  optionEnabled(optionKey) {
    const { formItem } = this.props;
    return formItem.options && formItem.options[optionKey];
  }

  toggleModal(e) {
    if (e) e.preventDefault();
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  toggleItemsToCreate(itemKey, itemLabel) {
    return () => {
      const { itemsToCreate } = this.state;
      let newitemsToCreate = itemsToCreate.slice();
      const index = newitemsToCreate.map(item => item.key).indexOf(itemKey);
      if (index > -1) {
        newitemsToCreate.splice(index, 1);
      } else {
        newitemsToCreate.push({
          key: itemKey,
          label: itemLabel
        });
      }
      this.setState({ itemsToCreate: newitemsToCreate });
    };
  }

  renderMissingPrefilledGuestFields() {
    const { allItems } = this.props;
    const { itemsToCreate } = this.state;
    const itemKeys = allItems.map(item => item.key);
    let missingPrefilledGuestFields = [];
    this.prefilledGuestFields().forEach(item => {
      if (itemKeys.indexOf(item.key) < 0) {
        missingPrefilledGuestFields.push(
          <tr key={item.key} onClick={ this.toggleItemsToCreate(item.key, item.label) }>
            <td class="bulk-cell">
              <input type="checkbox"
                checked={ itemsToCreate.map(it => it.key).indexOf(item.key) > -1 }
                className="form-check-input"
              />
            </td>
            <td>{ item.key }</td>
            <td>{ item.label }</td>
          </tr>
        );
      }
    });
    return missingPrefilledGuestFields;
  }

  onSubmit(e) {
    e && e.preventDefault();
    const { createFormItems, updateFormItem } = this.props;
    const { prefillFields, itemsToCreate, forcePrefillUsage, useOrganizationsDatabase } = this.state;
    const formItemsToCreate = itemsToCreate.map(item => {
      return { key: item.key, label: item.label, type: "text_field", input_format: "simple" };
    });
    updateFormItem({
      options: {
        [`prefill_${this.prefillFieldsType()}`]: prefillFields,
        [`force_prefill_${this.prefillFieldsType()}`]: forcePrefillUsage,
        ["use_organizations_database"]: useOrganizationsDatabase
      }
    });
    createFormItems(formItemsToCreate);
    this.setState({ itemsToCreate: [] }, this.toggleModal());
  }

  toggleCheckboxHandler(key) {
    return () => {
      this.setState(
        { [key]: !this.state[key] },
        () => { this.toggleUseOrganizationsDatabaseIfNeeded();}
      );
    };
  }

  toggleUseOrganizationsDatabaseIfNeeded() {
    const { prefillFields } = this.state;

    if (!prefillFields) {
      this.setState({
        useOrganizationsDatabase: false,
        forcePrefillUsage: false
      });
    }
  }

  renderFieldsTable() {
    const { prefillFields } = this.state;
    if (!prefillFields) return null;
    const lines = this.renderMissingPrefilledGuestFields();

    if (lines.length == 0) return null;
    return <div className="company-fields-table mt-10">
      <table className="table table-light table-hover table-sm">
        <thead>
          <tr>
            <th></th>
            <th>{ I18n.t("react.common_prefilled_fields.key") }</th>
            <th>{ I18n.t("react.common_prefilled_fields.label") }</th>
          </tr>
        </thead>
        <tbody>
          { lines }
        </tbody>
      </table>
    </div>;
  }

  renderModalContent() {
    const { prefillFields } = this.state;
    return <div>
      <div className="modal-header">
        <h4 className="modal-title">{ I18n.t(`react.${this.prefillFieldsType()}.modal_title`) }</h4>
        <button type="button" onClick={ this.toggleModal } className="btn-close" aria-label={I18n.t("close")}></button>
      </div>
      <div className="modal-body">
        <p>{ I18n.t(`react.${this.prefillFieldsType()}.modal_subtitle`) }</p>
        <div className="mt-20 mb-20">
          <Form.Group controlId="enable_auto_prefill">
            <Form.Check
              type="checkbox"
              checked={ prefillFields || false }
              onChange={this.toggleCheckboxHandler("prefillFields")}
              value="prefillFields"
              label={ I18n.t(`react.${this.prefillFieldsType()}.enable_auto_prefill`) }
            />
          </Form.Group>
          { this.renderUseOrganizationsDatabaseCheckbox() }
          { this.renderForcePrefillUsageCheckbox() }
        </div>
        { this.renderFieldsTable() }
      </div>
      <div className="modal-footer">
        <button type="button" onClick={ this.onSubmit } className="btn btn-primary" aria-label={I18n.t("close")}>{I18n.t("react.common_prefilled_fields.validate")}</button>
      </div>
    </div>;
  }

  prefillFieldsType() {
    const { formItem } = this.props;
    if (formItem.key == "company_name") return "company_fields";
    if (formItem.key == "address") return "address_fields";
  }

  prefilledGuestFields() {
    const { useOrganizationsDatabase, organizationFields } = this.state;

    if (useOrganizationsDatabase === true) return organizationFields;
    if (this.prefillFieldsType() == "company_fields") {
      return this.keyAndLabelFromField(companyPrefilledGuestFields);
    }
    if (this.prefillFieldsType() == "address_fields") {
      return this.keyAndLabelFromField(addressPrefilledGuestFields);
    }
  }

  keyAndLabelFromField(fields) {
    return fields.map(key => {
      return { key, label: I18n.t(`react.${this.prefillFieldsType()}.${key}`) };
    });
  }

  renderUseOrganizationsDatabaseCheckbox() {
    const { prefillFields, useOrganizationsDatabase } = this.state;
    if (!prefillFields) return null;

    return <Form.Group controlId="use_organizations_database">
      <Form.Check
        type="checkbox"
        checked={ useOrganizationsDatabase || false }
        onChange={this.toggleCheckboxHandler("useOrganizationsDatabase")}
        value="prefillFields"
        label={ I18n.t(`react.${this.prefillFieldsType()}.use_organizations_database`) }
      />
    </Form.Group>;
  }

  renderForcePrefillUsageCheckbox() {
    const { prefillFields, forcePrefillUsage } = this.state;
    if (!prefillFields) return null;

    return <Form.Group controlId="force_prefilling_usage">
      <Form.Check
        type="checkbox"
        checked={ forcePrefillUsage || false }
        onChange={this.toggleCheckboxHandler("forcePrefillUsage")}
        value="prefillFields"
        label={ I18n.t(`react.${this.prefillFieldsType()}.force_prefilling_usage`) }
      />
    </Form.Group>;
  }

  render() {
    const { formItem } = this.props;
    if (["company_name", "address"].indexOf(formItem.key) == -1) return null;
    const { isOpen, prefillFields } = this.state;
    const prefillFieldsState = prefillFields ? "enabled" : "disabled";
    return <div className=" mt-10">
      <a href="#" onClick={this.toggleModal}>
        { I18n.t(`react.${this.prefillFieldsType()}.manage_prefilling`) } ({ I18n.t(`react.common_prefilled_fields.prefilling_${prefillFieldsState}`) })
      </a>
      <Modal isOpen={isOpen} onRequestClose={this.toggleModal} style={defaultModalStyles} contentLabel="Modal">
        { this.renderModalContent() }
      </Modal>
    </div>;
  }
}

export default FieldsModal;
