import { ActionTypes } from "../constants/Constants";
import { updateLiquidField, LiquidTemplateName } from "../utils/LiquidFieldUtils";

function LiquidTemplate(state = { data: {}, errors: {}, all: null, pendingRequest: null }, action) {
  switch (action.type) {
  case ActionTypes.RECEIVE_LIQUID_TEMPLATES_SUCCESS:
    return Object.assign({}, state, { all: action.data });
  case ActionTypes.RECEIVE_LIQUID_TEMPLATE_SUCCESS:
    return Object.assign({}, state, { data: action.data });
  case ActionTypes.UPDATE_LIQUID_TEMPLATE_REQUEST:
  case ActionTypes.CREATE_LIQUID_TEMPLATE_REQUEST:
    return Object.assign({}, state, { pendingRequest: true, errors: {} });
  case ActionTypes.OFFLINE_UPDATE_RICHTEXT_EDITOR_SUCCESS:
    return updateLiquidField(state, action, LiquidTemplateName);
  case ActionTypes.UPDATE_LIQUID_TEMPLATE_SUCCESS:
  case ActionTypes.CREATE_LIQUID_TEMPLATE_SUCCESS: {
    let all = state.all ? state.all.slice() : [];
    let templateIndex = all.findIndex(t => t._id == action.data._id);
    if (templateIndex > -1) all.splice(templateIndex, 1);
    all.push(action.data);
    return Object.assign({}, state, { pendingRequest: false, all, data: action.data });
  }
  case ActionTypes.UPDATE_LIQUID_TEMPLATE_FAILURE:
  case ActionTypes.CREATE_LIQUID_TEMPLATE_FAILURE:
    return Object.assign({}, state, { errors: action.data, pendingRequest: false });
  case ActionTypes.CLEAR_ERRORS:
    return Object.assign({}, state, { errors: {} });
  default:
    return state;
  }
}

export default LiquidTemplate;
