import { Component } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";

const syncSourceLocalStorageKey = "themeSyncSource";

class ThemeReloader extends Component {
  constructor(props) {
    super(props);
    [
      "syncSourceChanged",
      "useOldThemeStructureChanged",
      "submit",
      "onToggle"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      syncSource: localStorage.getItem(syncSourceLocalStorageKey) || "",
      displayList: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reloading && !this.props.reloading) {
      this.reloadCompleted(this.props);
    }
  }

  onToggle() {
    const { displayList } = this.state;
    this.setState({ displayList: !displayList });
  }

  reloadCompleted(nextProps) {
    if (!nextProps.reloadError) {
      this.setState({ displayList: false });
      // save last successful sync source in local storage
      const { syncSource } = this.state;
      localStorage.setItem(syncSourceLocalStorageKey, syncSource);
    }
  }

  syncSourceChanged(e) {
    this.setState({
      syncSource: e.target.value
    });
  }

  useOldThemeStructureChanged(e) {
    this.setState({
      useOldThemeStructure: e.target.checked
    });
  }

  submit(e) {
    e.preventDefault();
    const { syncSource } = this.state;
    this.props.reloadTheme(syncSource);
  }

  i18n(key, opts = {}) {
    return I18n.t(`react.theme_reloader.${key}`, opts);
  }

  lastReloadInfo() {
    const { reloaded_at, reload_mean } = this.props.hasThemeLoader;
    if (!reloaded_at) {
      return null;
    }
    const opts = { date: I18n.l("time.formats.default", reloaded_at) };
    const i18nKey = reload_mean === "template" ? "reload_info_from_template" : "reload_info_from_local";
    return this.i18n(i18nKey, opts);
  }

  renderPopover() {
    const { reloading, reloadError, hasThemeLoader } = this.props;
    const iconClasses = reloading ? ["fa-regular", "fa-arrows-rotate", "fa-spin"] : ["fa-regular", "fa-arrows-rotate"];
    const { syncSource } = this.state;
    const submitEnabled = !reloading;
    return (
      <Popover id="theme-reloader-popover" style={{ padding: "10px" }}>
        <p>
          <strong>{this.i18n("title")}</strong> <small className="text-warning">{this.i18n("admin_only")}</small><br/>
          <small>
            {this.i18n("help_no_input")}<br />
            <strong>{this.i18n("theme_name")}</strong> <code>{hasThemeLoader.theme_name}</code> {this.lastReloadInfo()}
          </small>
        </p>
        <form onSubmit={this.submit}>
          <div className="mb-3">
            <div className="input-group">
              <input className="form-control" type="text" value={syncSource} onChange={this.syncSourceChanged}/>
              <button className="btn btn-primary" type="submit" disabled={!submitEnabled}>
                <i className={iconClasses.join(" ")}></i>
              </button>
            </div>
          </div>
        </form>
        <small className="text-danger" style={{ wordBreak: "break-all" }}>{ reloadError }</small>
      </Popover>
    );
  }

  render() {
    const { className, style } = this.props;
    const { displayList } = this.state;
    return (
      <OverlayTrigger placement="top" overlay={this.renderPopover()} show={displayList}>
        <button type="button" className={`btn btn-secondary ${className}`} style={style} onClick={this.onToggle}>
          <i className="fa-regular fa-arrows-rotate"></i>
        </button>
      </OverlayTrigger>
    );
  }
}

ThemeReloader.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  reloadTheme: PropTypes.func.isRequired,
  reloading: PropTypes.bool,
  reloadError: PropTypes.string,
  hasThemeLoader: PropTypes.object.isRequired,
  resourceType: PropTypes.string.isRequired
};

ThemeReloader.defaultProps = {
  style: {},
  reloading: false
};

export default ThemeReloader;
