import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import ExtractFromOtherEventItem from "./ExtractFromOtherEventItem.react";
import { Event } from "../../types/Event";
import { GuestCategory } from "../../types/GuestCategory";
import MessageModal from "../MessageModal.react";
import flatten from "lodash/flatten";
import HelpSection from "../shared/HelpSection.react";

interface Props {
  event: Event;
  guestCategories: GuestCategory[];

  fetchGuestCategoriesFromTargetEvent: (targetEventId: string) => void;
  fetchTargetEvents: (options: { page?: number; perPage?: number; published?: boolean; accountId?: string; }) => void;
  onSubmit: (params: any) => void;
}

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`react.extract_form_other_events.${key}`, options);
};

const ExtractFromOtherEvents: React.FC<Props> = ({
  event,
  guestCategories,
  fetchGuestCategoriesFromTargetEvent,
  fetchTargetEvents,
  onSubmit,
}) => {
  const events = useSelector((state: any) => state.events.data);

  const [selectedEventId, setSelectedEventId] = useState("");
  const [extractGuestTargetEvents, setExtractGuestTargetEvents] = useState({});
  const [targetEventIdWithIncompleteMapping, setTargetEventIdWithIncompleteMapping] = useState("");
  const [warningModalDisplayed, setWarningModalDisplayed] = useState(false);

  useEffect(() => {
    if (!event.fetched) return;

    fetchTargetEvents({ published: true, accountId: event.account_id });
    setExtractGuestTargetEvents(event.extract_guest_target_events);
  }, [event]);

  const clearTargetEventSelect = (): void => {
    setSelectedEventId("");
  };

  const addTargetEvent = (): void => {
    setExtractGuestTargetEvents({
      ...extractGuestTargetEvents,
      [selectedEventId]: {},
    });
    clearTargetEventSelect();
  };

  const updateGuestCategoriesMapping = (targetEventId: string, newMapping: Record<string, string[]>): void => {
    setExtractGuestTargetEvents({
      ...extractGuestTargetEvents,
      [targetEventId]: newMapping,
    });
  };

  const showWarningModal = (warningEventId: string): void => {
    setTargetEventIdWithIncompleteMapping(warningEventId);
    setWarningModalDisplayed(true);
  };

  const removeTargetEvent = (targetEventId: string): void => {
    const newData = { ...extractGuestTargetEvents };
    delete newData[targetEventId];
    const warningEventId = findEventIdWithIncompleteMapping(newData);
    if (warningEventId) {
      showWarningModal(warningEventId);
    } else {
      setExtractGuestTargetEvents({
        ...newData
      });
      onSubmit(newData);
    }
  };

  const findEventIdWithIncompleteMapping = (data: any): string => {
    return Object.keys(data).find(event_id => {
      const guestCategoriesMappingCount = flatten(Object.values(data[event_id])).length;
      const targetEventGuestCategoriesCount = events.find(e => e.id === event_id).guestCategories.length;
      return guestCategoriesMappingCount !== targetEventGuestCategoriesCount;
    });
  };

  const handleSubmit = (data: any): void => {
    const warningEventId = findEventIdWithIncompleteMapping(data);
    if (warningEventId) {
      showWarningModal(warningEventId);
    } else {
      onSubmit(data);
    }
  };

  const renderTargetEventSelect = (): JSX.Element => {
    const options = events
      ?.filter(e => !Object.keys(extractGuestTargetEvents).includes(e.id) && e.id !== event.id)
      ?.map(e => ({ value: e._id, label: e.title }))
      ?.sort((a, b) => {
        return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1;
      });
    const selectedOption = options?.find(o => selectedEventId == o.value);
    return <div className="col-md-6" style={{ flexGrow: 1 }}>
      <Select
        className="react-select"
        classNamePrefix="react-select"
        options={options}
        placeholder={i18n("placeholder")}
        value={selectedOption || []}
        noOptionsMessage={(): string => i18n("no_event_selected")}
        onChange={(option): void => setSelectedEventId(option.value)}
        isClearable={true}
      />
    </div>;
  };

  const renderSubmitButton = (): JSX.Element => {
    return <a href="#" className="btn btn-primary" onClick={(): void => handleSubmit(extractGuestTargetEvents)}>
      {i18n("validate")}
    </a>;
  };

  const renderAddTargetEventButton = (): JSX.Element => {
    return <button className="btn btn-secondary" onClick={addTargetEvent} disabled={selectedEventId.length === 0}>
      <i className="mr-5 fa-regular fa-plus"></i>{i18n("add_a_target_event")}
    </button>;
  };

  const renderList = (): JSX.Element => {
    if (!(events?.length > 0)) return null;

    return <>
      {Object.keys(extractGuestTargetEvents).map(eventId => {
        return (
          <ExtractFromOtherEventItem
            key={eventId}
            event={event}
            targetEvent={events.find(e => e._id === eventId)}
            guestCategoryMapping={extractGuestTargetEvents[eventId]}
            fetchGuestCategoriesFromTargetEvent={fetchGuestCategoriesFromTargetEvent}
            guestCategories={guestCategories}
            updateGuestCategoriesMapping={updateGuestCategoriesMapping}
            removeTargetEvent={removeTargetEvent}
            mappingIncomplete={eventId === targetEventIdWithIncompleteMapping}
          />
        );
      })}
    </>;
  };

  const renderSubtitle = (): JSX.Element => {
    return <div className="card-header">
      <h4 className="card-title">{i18n("title")}</h4>
    </div>;
  };

  const renderWarningModal = (): JSX.Element => {
    if (!targetEventIdWithIncompleteMapping) return null;

    const warningEvent = events.find(event => event.id === targetEventIdWithIncompleteMapping);
    const message = <strong>
      {i18n("mapping_incompleted_message", { event_title: warningEvent.title })}
    </strong>;
    return <MessageModal
      isOpen={warningModalDisplayed}
      message={message}
      onRequestClose={(): void => setWarningModalDisplayed(false)}
      iconClassName="fa-regular fa-circle-xmark fa-4x text-danger"
    />;
  };

  return <>
    <div className="card mb-10 mt-10">
      {renderSubtitle()}
      <div className="card-body">
        <HelpSection help={i18n("warning_message")} badgeText={i18n("warning")} type="warning" noIcon={true} />
        {renderList()}
        <div className="row" style={{ gap: "10px 0" }}>
          {renderTargetEventSelect()}
          <div className="col-md-6 d-flex align-items-center">
            {renderAddTargetEventButton()}
          </div>
        </div>
      </div>
    </div>

    {renderSubmitButton()}
    {renderWarningModal()}
  </>;
};

export default ExtractFromOtherEvents;
