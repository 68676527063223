import { ActionTypes } from "../constants/Constants";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";

function guestFields(state = { guestFields: [], types: [], pendingRequest: false, errors: {}, calculatedFieldPreview: null, hasChanges: false, fetched: false }, action) {
  switch (action.type) {
  case ActionTypes.FETCH_GUEST_FIELDS_SUCCESS:
    return { ...state, guestFields: action.data, hasChanges: false, fetched: true };
  case ActionTypes.GUEST_FIELDS_BATCH_CREATE_SUCCESS: {
    let guestFields = state.guestFields;
    action.data.forEach(field => {
      guestFields = insertOrUpdateObject(guestFields, field, "_id");
    });
    return Object.assign({}, state, { guestFields, errors: {}, hasChanges: false });
  }
  case ActionTypes.FETCH_GUEST_FIELDS_TYPES_SUCCESS:
    return Object.assign({}, state, { types: action.data, hasChanges: false });
  case ActionTypes.RECEIVE_GUEST_FIELD: {
    const guestFields = insertOrUpdateObject(state.guestFields, action.data, "_id");
    return Object.assign({}, state, { guestFields, errors: {}, hasChanges: false });
  }
  case ActionTypes.GUEST_FIELD_UPDATE_SUCCESS:
  case ActionTypes.GUEST_FIELD_CREATE_SUCCESS: {
    const guestFields = insertOrUpdateObject(state.guestFields, action.data, "_id");
    return Object.assign({}, state, { guestFields, errors: {}, hasChanges: true });
  }
  case ActionTypes.GUEST_FIELD_CREATE_FAILURE:
  case ActionTypes.GUEST_FIELD_UPDATE_FAILURE: {
    return Object.assign({}, state, { errors: action.data, hasChanges: false });
  }
  case ActionTypes.CALCULATED_FIELD_PREVIEW_REQUEST: {
    return Object.assign({}, state, { pendingRequest: true, errors: {}, calculatedFieldPreview: null });
  }
  case ActionTypes.CALCULATED_FIELD_PREVIEW_SUCCESS: {
    return Object.assign({}, state, { pendingRequest: false, errors: {}, calculatedFieldPreview: action.data.preview });
  }
  case ActionTypes.CALCULATED_FIELD_PREVIEW_FAILURE: {
    return Object.assign({}, state, { pendingRequest: false, errors: action.data, calculatedFieldPreview: null });
  }
  default:
    return state;
  }
}

export default guestFields;
