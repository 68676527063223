import { fabric } from "fabric";
import { BADGE_IMAGE_POSITIONS, DEFAULT_CUSTOMISATION_FABRIC_STRUCTURE } from "../../Constants";
import { BadgeImage } from "../../../../types/BadgeTemplate";
import { Alignment, StackingOrder } from "../types";

const MAX_WIDTH_IMG = 150;

export const addText = (canvas: fabric.Canvas, defaultEditorProperties, text: string): void => {
  const object = new fabric.Textbox(
    text, {
      fontFamily: defaultEditorProperties["fontFamily"],
      fill: defaultEditorProperties["fontColor"] || "#000000",
      fontSize: defaultEditorProperties["fontSize"] || 24,
      padding: 5,
      left: 0,
      right: 0,
      textAlign: defaultEditorProperties["textAlign"] || "left",
    }
  );
  canvas.add(object);
};

export const addImage = (canvas: fabric.Canvas, url: string, imageKey: string): void => {
  fabric.Image.fromURL(url, (img) => {
    const scaleValue = MAX_WIDTH_IMG / img.width;

    img.toObject = function(this: fabric.Image, propertiesToInclude): any {
      return fabric.util.object.extend(fabric.Image.prototype.toObject.call(this, propertiesToInclude), {
        imageKey: imageKey
      });
    };

    canvas.add(img.set({
      left: 0,
      top: 0,
      scaleX: scaleValue,
      scaleY: scaleValue,
      imageKey: imageKey
    }));
  });
};

export const removeSelectedObject = (canvas: fabric.Canvas): void => {
  const activeObject = canvas.getActiveObject();
  if (!activeObject) return;

  if (activeObject.type === "activeSelection") {
    activeObject.getObjects().forEach((object) => {
      canvas.remove(object);
    });
    canvas.discardActiveObject();
  } else {
    canvas.remove(activeObject);
  }
  canvas.discardActiveObject();
  canvas.requestRenderAll();
};

export const updateGroupAlignment = (canvas: fabric.Canvas, selectedObjects: fabric.Object[], value: string): void => {
  const { width: groupWidth, height: groupHeight } = selectedObjects[0].group;

  selectedObjects.forEach((selectedObject: fabric.Object) => {
    const { width: itemWidth, height: itemHeight, scaleX, scaleY } = selectedObject;
    let left = selectedObject.left;
    let top = selectedObject.top;
    let originX = selectedObject.originX;
    let originY = selectedObject.originY;
    switch (value) {
    case Alignment.HLeft:
      left = -(groupWidth / 2);
      originX = "left";
      break;
    case Alignment.HCenter:
      left = (0 - (itemWidth * scaleX) / 2);
      originX = "left";
      break;
    case Alignment.HRight:
      left = (groupWidth / 2 - (itemWidth * scaleX) / 2);
      originX = "center";
      break;
    case Alignment.VTop:
      top = -(groupHeight / 2);
      originY = "top";
      break;
    case Alignment.VCenter:
      top = (0 - (itemHeight * scaleY) / 2);
      originY = "top";
      break;
    case Alignment.VBottom:
      top = (groupHeight / 2 - (itemHeight * scaleY) / 2);
      originY = "center";
      break;
    }
    selectedObject.set({ left, originX, top, originY });
    selectedObject.setCoords();
  });
  canvas.requestRenderAll();
};

export const addDottedLines = (canvas: fabric.Canvas): void => {
  const MARGIN = 15;

  const x = canvas.width / 2;
  const y = canvas.height / 2;

  canvas.add(new fabric.Line([x, MARGIN * 2, x, canvas.height - MARGIN * 2], {
    selectable: false,
    stroke: "black"
  }));

  canvas.add(new fabric.Line([MARGIN * 2, y, canvas.width - MARGIN * 2, y], {
    selectable: false,
    stroke: "black"
  }));
};

export const addBackground = (canvas: fabric.Canvas, url: string, imageKey: string, badgeFormat: string): void => {
  const fabricStructure = DEFAULT_CUSTOMISATION_FABRIC_STRUCTURE[badgeFormat];
  const bgParams = fabricStructure.filter(data => data.position === imageKey)[0];

  const singleBackground = fabricStructure.filter(data => data.type === "image").length === 1;
  const banner = imageKey.includes("right") && bgParams.height;
  const card = canvas.width < 260;

  fabric.Image.fromURL(url, img => {
    const MARGIN = 15;
    const BORDER_CANVAS = 1;

    // Define width and height
    let bgWidth = canvas.width / 2 - MARGIN * 2;
    let bgHeight = bgParams.height || canvas.height / 2 - MARGIN * 2;
    if (singleBackground) {
      bgWidth = canvas.width + MARGIN;
      bgHeight = canvas.height + MARGIN;
    }
    if (singleBackground && banner) {
      bgWidth = canvas.width - MARGIN * 2;
      bgHeight = bgParams.height || canvas.height;
    }

    // Resize images to fit the content
    const scaleX = bgWidth / img.width;
    const scaleY = bgHeight / img.height;
    let scaleValue = scaleX > scaleY ? scaleY : scaleX;
    if (singleBackground) scaleValue = scaleX;
    if (singleBackground && banner || card && img.width < img.height) scaleValue = scaleY;

    // Center image verticaly
    const marginTop = img.width >= img.height && !banner ? (bgHeight - img.height * scaleValue) / 2 : 0;

    // New background positions
    let top = 0;
    let left = 0;
    if (imageKey.includes("top") && !singleBackground || banner) top = MARGIN;
    if (imageKey.includes("bottom")) top = canvas.height - bgHeight - MARGIN + BORDER_CANVAS;
    if (imageKey.includes("left")) left = MARGIN;
    if (imageKey.includes("right") && !singleBackground || banner) {
      const marginLeft = bgWidth > bgHeight ? (bgWidth - img.width * scaleValue) / 2 : 0;
      left = canvas.width - bgWidth - MARGIN + BORDER_CANVAS + marginLeft;
    }
    if (singleBackground && !banner) {
      left = - MARGIN;
    }
    if (card) {
      const marginLeft = img.width < img.height ? (bgWidth - img.width * scaleValue) / 2 : 0;
      left = canvas.width - bgWidth + marginLeft + MARGIN / 2;
    }

    img.toObject = function(this: fabric.Image, propertiesToInclude): any {
      return fabric.util.object.extend(fabric.Image.prototype.toObject.call(this, propertiesToInclude), {
        imageKey: imageKey
      });
    };

    img.set({
      left: left,
      top: top + marginTop,
      scaleX: scaleValue,
      scaleY: scaleValue,
      imageKey: imageKey
    });
    canvas.add(img);
    canvas.sendToBack(img);
  });
};

export const selectObject = (canvas: fabric.Canvas, key: string): boolean => {
  if (!key) return false;

  canvas.discardActiveObject();
  const object = canvas.getObjects().find(object => {
    return object.type === "image" && object.imageKey === key;
  });
  if (object) {
    canvas.setActiveObject(object);
  }
  canvas.requestRenderAll();
  return object ? true : false;
};

export const updateActiveObjectStacking = (canvas: fabric.Canvas, action: StackingOrder): void => {
  switch (action) {
  case StackingOrder.BringToFront:
    canvas.bringToFront(canvas.getActiveObject());
    break;
  case StackingOrder.SendToBack:
    canvas.sendToBack(canvas.getActiveObject());
    break;
  case StackingOrder.BringForward:
    canvas.bringForward(canvas.getActiveObject());
    break;
  case StackingOrder.SendBackward:
    canvas.sendBackwards(canvas.getActiveObject());
    break;
  }
};

export const createDefaultElementsOnCanvas = (canvas: fabric.Canvas, elements, badgeImages: BadgeImage[], badgeFormat: string): void => {
  if (!elements) return;

  elements.forEach(element => {
    if (element.type === "text") {
      const textObject = new fabric.Textbox(element.text, {
        left: element.left,
        top: element.top,
        fontFamily: element.font,
        fontSize: element.fontSize,
        fill: element.color,
        textAlign: element.textAlign,
        width: element.width
      });
      canvas.add(textObject);
    } else if (element.type === "image") {
      if (BADGE_IMAGE_POSITIONS.includes(element.position)) {
        const url = badgeImages.find(badgeImage => badgeImage.position === element.position)?.image.medium.url;
        if (url) {
          addBackground(canvas, url, element.position, badgeFormat);
        }
      }
    } else if (element.type === "qrcode") {
      const qrCode = new fabric.Qrcode(element);
      canvas.add(qrCode);
    }
  });
};
