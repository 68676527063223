import { Component } from "react";
import Icons from "../constants/Icons";
import { connect } from "react-redux";
import SlidingPane from "react-sliding-pane";
import TranslationTable from "./translations/TranslationTable.react";
import NoticeBlock from "../components/templates_builder/NoticeBlock.react";

interface Props {
  errors: any;
  notice?: string;
  noticeType?: string;
  match: any;
  location: any;
  history: any;
  toggleTranslationsPane(): void;
  requestEvent(): void;
}

interface State {
  displayTranslationsPane: boolean;
}

class GuestProductTranslationsSlidingPane extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    [
      "toggleTranslationsPane"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      displayTranslationsPane: false
    };
  }

  toggleTranslationsPane(): void {
    this.setState({
      displayTranslationsPane: !this.state.displayTranslationsPane
    });
  }

  renderTranslations(): JSX.Element {
    const { match, location, history, notice, noticeType } = this.props;
    const { displayTranslationsPane } = this.state;

    return displayTranslationsPane && <SlidingPane
      isOpen={true}
      title={I18n.t("react.translations.parent_navigation.guest_product")}
      from="right"
      width='90%'
      onRequestClose={this.toggleTranslationsPane}
      className="width-100-xs width-90-sm"
      closeIcon={Icons.close()}
    >
      <div style={{ padding: "0 20px 20px" }}>
        <NoticeBlock notice={notice} noticeType={noticeType} />
        <TranslationTable
          match={match}
          location={location}
          history={history}
          type="guest_product"
          eventId={match.params.event_id}
          guestId={match.params.guest_id}
          id={match.params.guest_product_id}
          hideTabMenu={true}
          onlySimpleValidateButton={true}
        />
      </div>
    </SlidingPane>;
  }

  render(): JSX.Element {
    return <span>
      <a href="#" className="list-group-item list-group-item-action" onClick={this.toggleTranslationsPane}>
        <i className="fa-regular fa-language fa-fw" />{ " " }
        { I18n.t("react.guest_product_translations_sliding_pane.translate") }
      </a>
      { this.renderTranslations() }
    </span>;
  }

}

function mapStateToProps(state: any): any {
  return {
    notice: state.notifications.currentNotice,
    noticeType: state.notifications.noticeType
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GuestProductTranslationsSlidingPane);
