import { Modal } from "react-bootstrap";
import GuestCategoryLabel from "../guest_category/GuestCategoryLabel.react";
import { pathToEmailBuilder, pathToDocumentBuilder } from "../../utils/pathUtils";

interface Props {
  isOpen: boolean;
  template: any;
  templateType: "email_template" | "document_template";
  builderInSlidingPane?: boolean;
  inModal?: boolean;
  openBuilder(): void;
  closeModal(): void;
  duplicate(): void;
}

const i18n = (key: string): string => {
  return I18n.t(`react.templates.edit_warning_modal.${key}`);
};

const EditTemplateWarningModal: React.FC<Props> = (props) => {
  const duplicateTemplate = (): void => {
    const { duplicate, closeModal } = props;
    duplicate();
    closeModal();
  };

  const editTemplate = (): string | void => {
    const { builderInSlidingPane, openBuilder, closeModal } = props;

    if (!builderInSlidingPane)
      return window.location.href = pathToBuilder();

    openBuilder();
    closeModal();
  };

  const pathToBuilder = (): string => {
    const { templateType, template } = props;

    switch (templateType) {
    case "email_template":
      return pathToEmailBuilder(template._id);
    case "document_template":
      return pathToDocumentBuilder(template._id);
    }
  };

  const renderGuestCategories = (): JSX.Element => {
    const { template } = props;

    if (!template.guest_categories_uses || template.guest_categories_uses.length === 0) return null;

    return <>
      <p key="categories-title">{ i18n("already_used_in_categories") }</p>
      <p key="categories-list">
        { template.guest_categories_uses.map((guestCategory, index) => {
          return <GuestCategoryLabel guestCategory={guestCategory} isLink={false} truncate={30} key={`${guestCategory._id}-${index}`} cssClasses="mr-5" />;
        }) }
      </p>
    </>;
  };

  const renderGuestCampaigns = (): JSX.Element => {
    const { template } = props;

    if (!template.guest_campaigns_uses || template.guest_campaigns_uses.length === 0) return null;

    return <>
      <p key="campaigns-title">{ i18n("already_used_in_campaigns") }</p>
      <p key="campaigns-list">
        { template.guest_campaigns_uses.map(guestCampaign => {
          return <GuestCategoryLabel guestCategory={guestCampaign} isLink={false} truncate={30} key={guestCampaign._id} cssClasses="bg-secondary mr-5" />;
        }) }
      </p>
    </>;
  };

  const { isOpen, closeModal, inModal } = props;
  const target = inModal ? "_blank" : null;

  const editLink = inModal ? (
    <a target={target} href={pathToBuilder()} className="btn btn-secondary float-start">
      <i className="fa-regular fa-pen-to-square" /> { i18n("edit_anyway") }
    </a>
  ) : (
    <a onClick={editTemplate} className="btn btn-secondary float-start">
      <i className="fa-regular fa-pen-to-square" /> { i18n("edit_anyway") }
    </a>
  );

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header>
        <h4 className="modal-title"><i className="fa-regular fa-triangle-exclamation" /> { i18n("warning") }</h4>
        <button type="button" onClick={closeModal} className="btn-close" aria-label={I18n.t("close")}></button>
      </Modal.Header>
      <Modal.Body>
        { renderGuestCategories() }
        { renderGuestCampaigns() }
        <p>{ i18n("edit_will_be_propagated") }</p>
        <p><strong>{ i18n("what_would_you_rather_do") }</strong></p>
      </Modal.Body>
      <Modal.Footer>
        {editLink}
        <a onClick={duplicateTemplate} className="btn btn-primary">
          <i className="fa-regular fa-clone" /> { i18n("duplicate") }
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTemplateWarningModal;
