import { Component } from "react";
import PropTypes from "prop-types";

class MetadataForm extends Component {
  constructor(props) {
    super(props);
    [
      "addTrait",
      "removeTrait",
      "updateTrait"
    ].forEach(fn => {
      this[fn] = this[fn].bind(this);
    });
  }

  addTrait() {
    const { traits, updateParent } = this.props;
    let newTraits = traits.slice();
    newTraits.push({ key: "", value: "" });
    updateParent(newTraits);
  }

  updateTrait(index, data) {
    const { traits, updateParent } = this.props;
    return (e) => {
      let newTraits = traits.slice();
      newTraits[index][data] = e.target.value;
      updateParent(newTraits);
    };
  }

  removeTrait(index) {
    const { traits, updateParent, markDestroy, updateTraits } = this.props;
    return () => {
      let newTraits = traits.slice();
      if (markDestroy)
        newTraits[index]._destroy = true;
      else
        newTraits.splice(index, 1);
      updateTraits();
      updateParent(newTraits);
    };
  }

  render() {
    const { traits, displayLegend, updateTraits } = this.props;
    const traitsArray = traits.map((trait, index) => {
      if ("_destroy" in trait) return null;

      return (
        <div key={`metadata-${index}`} className="mb-3 row g-2 align-items-center form-traits">
          <div className="col-auto">
            <input type="text" className="form-control" placeholder={I18n.t("guest_categories.form.metadata_key")} value={trait.key} onChange={this.updateTrait(index, "key")} onBlur={updateTraits}/>
          </div>
          <div className="col-auto">
            <input type="text" className="form-control" placeholder={I18n.t("guest_categories.form.metadata_value")} value={trait.value || ""} onChange={this.updateTrait(index, "value")} onBlur={updateTraits}/>
          </div>
          <div className="col-auto">
            <a className="btn btn-danger" onClick={this.removeTrait(index)}>
              <i className="fa-regular fa-trash-can"></i>
            </a>
          </div>
        </div>
      );
    });
    return (
      <fieldset>
        {
          displayLegend && <>
            <div className="subtitle-page">
              <div className="subtitle-page-title">
                <h2>{I18n.t("guest_categories.form.metadata")}</h2>
              </div>
            </div>
          </>
        }
        {traitsArray}
        <a className="btn btn-secondary" onClick={this.addTrait}>
          <i className="fa-regular fa-plus"></i>
        </a>
      </fieldset>
    );
  }
}

MetadataForm.propTypes = {
  updateParent: PropTypes.func.isRequired,
  updateTraits: PropTypes.func,
  traits: PropTypes.array.isRequired,
  markDestroy: PropTypes.bool,
  displayLegend: PropTypes.bool
};

MetadataForm.defaultProps = {
  traits: [],
  markDestroy: false,
  displayLegend: true
};

export default MetadataForm;
