import { ActionTypes } from "../constants/Constants";

function guestPage(state = { isFillingfilters: false, isHydrating: false, currentPage: 1 }, action) {
  switch (action.type) {
  case ActionTypes.SET_HYDRATATION_STATE:
    return Object.assign({}, state, { isHydrating: action.value });
  case ActionTypes.SET_GUEST_PAGE:
    return Object.assign({}, state, { currentPage: action.value });
  case ActionTypes.SET_FILLING_FILTER_STATE:
    return Object.assign({}, state, { isFillingfilters: action.value });
  case ActionTypes.SET_SELECTED_GUEST_CATEGORY_IDS:
  case ActionTypes.SET_SELECTED_GUEST_STATUSES:
  case ActionTypes.SET_SELECTED_PAYMENT_STATUSES:
  case ActionTypes.SET_SELECTED_SHOWED_UP_STATUSES:
  case ActionTypes.SET_EXPECTED_AT_ACCESSPOINT_IDS:
  case ActionTypes.SET_CHECKED_IN_AT_ACCESSPOINT_IDS:
  case ActionTypes.SET_SELECTED_GUEST_SORT_OPTION:
  case ActionTypes.SET_SELECTED_GUEST_LABEL_IDS:
  case ActionTypes.SET_SELECTED_EMAIL_ACTIVITY:
  case ActionTypes.SET_SELECTED_ENGAGEMENT_LEVELS:
  case ActionTypes.SET_IN_GUESTS_THEMATICS_QUARTILES_SELECTED_THEMATICS_IDS:
  case ActionTypes.SET_IN_GUESTS_THEMATICS_QUARTILES_SELECTED_QUARTILES_IDS:
  case ActionTypes.SET_SELECTED_OPTINS:
    if (state.isHydrating == false) {
      return Object.assign({}, state, { currentPage: 1 });
    }
    return state;
  default:
    return state;
  }
}

export default guestPage;
