import { Component } from "react";
import truncate from "lodash/truncate";

import { pathToGuestCategoryShow, pathToAccesspointEdit } from "../../utils/pathUtils";

const COUNT_PAID_STAT_TYPE = "count_paid";

class SalesTable extends Component {

  constructor(props) {
    super(props);
  }

  paidStatTypeIsCurrentStat() {
    const { currentStat } = this.props;
    return currentStat == COUNT_PAID_STAT_TYPE;
  }

  refundValues(stat, formatter) {
    if (this.paidStatTypeIsCurrentStat()) {
      return (
        <>
          <td>{ stat.refund_count }</td>
          <td>{ formatter.format(stat.refund_amount) }</td>
        </>
      );
    }
  }

  refundHeaders() {
    if (this.paidStatTypeIsCurrentStat()) {
      return (
        <>
          <th>{ I18n.t("react.event_reports.sales_table.refund_count") }</th>
          <th>{ I18n.t("react.event_reports.sales_table.refund_amount") }</th>
        </>
      );
    }
  }

  refundTotals(currencyTotal) {
    if (this.paidStatTypeIsCurrentStat()) {
      return (
        <>
          <td>{ currencyTotal.refund_count }</td>
          <td>{ currencyTotal.refund_amount }</td>
        </>
      );
    }
  }

  row(stat, index) {
    const formatter = new Intl.NumberFormat(I18n.currentLocale(), { style: "currency", currency: stat.currency });
    const labelColor = stat.label_color || "#777";
    const link = stat.type === "guest_category_id" ? pathToGuestCategoryShow(stat._id) : pathToAccesspointEdit(stat._id);
    return (
      <tr key={ index }>
        <td>
          <a href={link} className="badge rounded-pill" style={{ backgroundColor: labelColor }}>
            { truncate(stat.name, { "length": 80 }) }
          </a>
        </td>
        <td>{ stat.count }</td>
        <td>{ stat.promo_code_ratio }%</td>
        <td>{ formatter.format(stat.discount_amount) }</td>
        { this.refundValues(stat, formatter) }
        <td>{ formatter.format(stat.price) }</td>
        <td>{ stat.taxes }%</td>
        <td>{ formatter.format(stat.total_price_excl_taxes) }</td>
        <td>{ formatter.format(stat.total_price_incl_taxes) }</td>
      </tr>
    );
  }

  totalRow() {
    const { total } = this.props;
    if (!total) {
      return null;
    }

    return Object.keys(total).map(currency => {
      const formatter = new Intl.NumberFormat(I18n.currentLocale(), { style: "currency", currency });
      const currencyTotal = total[currency];
      return (
        <tr key={`total_${currency}`} className={ "info" }>
          <td>{ I18n.t("react.event_reports.sales_table.total") } ({currency})</td>
          <td>{ currencyTotal.count }</td>
          <td></td>
          <td>{ formatter.format(currencyTotal.discount_amount) }</td>
          { this.refundTotals(currencyTotal) }
          <td></td>
          <td></td>
          <td>{ formatter.format(currencyTotal.total_price_excl_taxes) }</td>
          <td>{ formatter.format(currencyTotal.total_price_incl_taxes) }</td>
        </tr>
      );
    });
  }

  render() {
    const { stats } = this.props;
    const rows = stats.map((stat, index) => {
      return this.row(stat, index);
    });
    return (
      <div className="table-responsive table-container mb-10">
        <table className="table table-light table-hover table-sm">
          <thead>
            <tr>
              <th>{ I18n.t("react.event_reports.sales_table.item") }</th>
              <th>{ I18n.t("react.event_reports.sales_table.quantity") }</th>
              <th>{ I18n.t("react.event_reports.sales_table.promo_code_ratio") }</th>
              <th>{ I18n.t("react.event_reports.sales_table.discount_amount") }</th>
              { this.refundHeaders() }
              <th>{ I18n.t("react.event_reports.sales_table.price") }</th>
              <th>{ I18n.t("react.event_reports.sales_table.tax") }</th>
              <th>{ I18n.t("react.event_reports.sales_table.total_excl_taxes") }</th>
              <th>{ I18n.t("react.event_reports.sales_table.total_incl_taxes") }</th>
            </tr>
          </thead>
          <tbody>
            { rows }
            { this.totalRow() }
          </tbody>
        </table>
      </div>
    );
  }
}

SalesTable.defaultProps = {
  stats: []
};

export default SalesTable;
