import { ActionTypes } from "../constants/Constants";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";

const defaultState = {
  isFetching: false,
  data: null,
  guestCategoryAccesspointIds: null,
  errorsMassAction: null,
};

function guestCategoryAccesspoints(state = defaultState, action) {
  switch (action.type) {
  case ActionTypes.FETCH_GUEST_CATEGORY_ACCESSPOINTS_SUCCESS:
    return { ...state, data: action.data };
  case ActionTypes.CREATE_GUEST_CATEGORY_ACCESSPOINT_FAILURE: {
    const data = state.data;
    data.push({ errors: action.data.errors, accesspoint_id: action.accesspointId, guest_category_id: action.guestCategoryId });
    return { ...state, data: [...data] };
  }
  case ActionTypes.CREATE_GUEST_CATEGORY_ACCESSPOINT_SUCCESS: {
    const data = insertOrUpdateObject(state.data, action.data, "_id");
    return { ...state, data };
  }
  case ActionTypes.DELETE_GUEST_CATEGORY_ACCESSPOINT_SUCCESS: {
    const data = state.data.filter(item => item._id != action.guest_category_accesspoint_id);
    return { ...state, data };
  }
  case ActionTypes.UPDATE_GUEST_CATEGORY_ACCESSPOINT_SUCCESS: {
    const data = state.data.map(item => {
      return item._id === action.guest_category_accesspoint_id ? Object.assign({}, item, action.params) : item;
    });
    return { ...state, data };
  }

  case ActionTypes.CREATE_OR_UPDATE_GUEST_CATEGORY_ACCESSPOINTS_REQUEST:
  case ActionTypes.DELETE_GUEST_CATEGORY_ACCESSPOINTS_REQUEST:
    return { ...state, performingMassAction: true, errorsMassAction: null };
  case ActionTypes.CREATE_OR_UPDATE_GUEST_CATEGORY_ACCESSPOINTS_SUCCESS: {
    const data = action.data.reduce((acc, item) => {
      return insertOrUpdateObject(acc, item, "_id");
    }, state.data);
    return { ...state, data, performingMassAction: false };
  }
  case ActionTypes.DELETE_GUEST_CATEGORY_ACCESSPOINTS_FAILURE: {
    return { ...state, data: null, performingMassAction: false, errorsMassAction: Object.entries(action.data.errors).map(([key, value]) => { return { [key]: value }; }) };
  }
  case ActionTypes.CREATE_OR_UPDATE_GUEST_CATEGORY_ACCESSPOINTS_FAILURE: {
    const errors = [];
    const data = action.data.reduce((acc, item) => {
      if (item.errors) {
        errors.push({ message: item.errors, accesspoint_id: item.accesspoint_id, guest_category_id: item.guest_category_id });
        return acc;
      }
      return insertOrUpdateObject(acc, item, "_id");
    }, state.data);
    return { ...state, data, performingMassAction: false, errorsMassAction: errors };
  }
  case ActionTypes.DELETE_GUEST_CATEGORY_ACCESSPOINTS_SUCCESS: {
    const data = state.data.filter(item => !action.guestCategoryAccesspointIds.includes(item._id));
    return { ...state, data, performingMassAction: false };
  }
  default:
    return state;
  }
}

export default guestCategoryAccesspoints;
