import { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import ErrorMessage from "../shared/ErrorMessage.react";
import Loader from "../shared/Loader.react";

class AskModelNameModal extends Component {
  constructor(props) {
    super(props);
    [
      "handleChange",
      "onSelectPresetsName",
      "onSubmit"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = {
      modelName: "",
      selectedPresetsName: "default"
    };
  }

  i18n(key) {
    const { i18nRootKey } = this.props;
    return I18n.t(`react.${i18nRootKey}.ask_model_name_modal.${key}`);
  }

  handleChange(e) {
    this.setState({ modelName: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const { modelName, selectedPresetsName } = this.state;
    this.props.onSubmit(modelName, selectedPresetsName);
  }

  onSelectPresetsName(e) {
    this.setState({ selectedPresetsName: e.target.value });
  }

  renderPresetsDropdown() {
    const { selectedPresetsName } = this.state;
    const { presetsList } = this.props;

    if (!presetsList || presetsList.length == 0) return;

    return (
      <Form.Group className="mt-3" controlId="form-presets-name">
        <select className="form-select" onChange={this.onSelectPresetsName} defaultValue={selectedPresetsName}>
          { presetsList.map(presetsItem => {
            return <option value={presetsItem.key} key={presetsItem.key}>
              {presetsItem.name_translations[I18n.locale] || presetsItem.name}
            </option>;
          }) }
        </select>
        <Form.Text>{this.i18n("presets_hint")}</Form.Text>
      </Form.Group>
    );
  }

  renderBody() {
    const { modelName } = this.state;
    const { errors } = this.props;

    return (
      <>
        <ErrorMessage errors={errors}/>
        <Form.Group controlId="form-name">
          <Form.Control
            type="text"
            value={modelName}
            placeholder={this.i18n("placeholder")}
            onChange={this.handleChange}
          />
          <Form.Text>{this.i18n("hint")}</Form.Text>
        </Form.Group>
        {this.renderPresetsDropdown()}
      </>
    );
  }

  renderFooter() {
    const { onClose, showLoader } = this.props;
    const { modelName } = this.state;
    const extraProps = modelName === "" || showLoader ? { disabled: "disabled" } : {};

    return (
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>{this.i18n("cancel")}</Button>
        <button type="submit" className="btn btn-primary" {...extraProps} onClick={this.onSubmit}>
          {showLoader ? <Loader size="small" color="white" /> : null} {this.i18n("submit")}
        </button>
      </Modal.Footer>
    );
  }

  render() {
    const { isVisible, onClose, modalSize } = this.props;

    return (
      <Modal show={isVisible} onHide={onClose} size={modalSize}>
        <Modal.Header>
          <Modal.Title>{this.i18n("title")}</Modal.Title>
          <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
        </Modal.Header>
        <form>
          <Modal.Body>
            {this.renderBody()}
          </Modal.Body>
          {this.renderFooter()}
        </form>
      </Modal>
    );
  }
}

export default AskModelNameModal;

AskModelNameModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  i18nRootKey: PropTypes.string.isRequired,
  showLoader: PropTypes.bool,
  modalSize: PropTypes.string,
  presetsList: PropTypes.arrayOf(PropTypes.object)
};

AskModelNameModal.defaultProps = {
  isVisible: false,
  showLoader: false,
  modalSize: "lg"
};
