import Loader from "./shared/Loader.react";

interface Props {
  pendingRequest: boolean;
}

const WebsiteFormSaveButton: React.FC<Props> = ({
  pendingRequest
}) => {
  return (
    <button
      type="submit"
      disabled={pendingRequest}
      className="btn btn-primary"
    >
      {I18n.t("save")}
      {pendingRequest && <Loader size="small" color="white" />}
    </button>
  );
};

export default WebsiteFormSaveButton;
