import { Component, createRef } from "react";
import { connect } from "react-redux";

import Loader from "../../components/shared/Loader.react";
import Checkbox from "../../components/shared/Checkbox.react";
import { requestEvent } from "../../actions/ImportActionCreators";
import { EventStoreProps, fieldI18n } from "../../types/Event";
import { updateEvent } from "../../actions/EventActionCreators";
import NetworkingRules from "./NetworkingRules.react";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import { isEnabled, anyEnabled } from "../../utils/featureSetUtils";
import {
  ONE_TO_ONE_CHAT_AND_CONNECTIONS,
  VIDEO_CHAT, SCHEDULED_1TO1_MEETING,
  EXHIBITOR_MEETINGS
} from "../../constants/FeaturesSet";
import { redirectIfUnauthorized } from "../../utils/aclUtils";
import NotificationAlert, { Notification } from "../../components/shared/NotificationAlert.react";
import FormSectionPanel from "../../components/engagement/FormSectionPanel.react";

interface StoreProps {
  event: EventStoreProps;
  notification: Notification;
}

interface DispatchProps {
  updateEvent: (id: string, params: any, redirectTo: string|null, notificationsOptions: any) => void;
  requestEvent: () => void;
}

interface OwnProps {
  inPane?: boolean;
  hideMessagingSettings?: boolean;
  hideMeetingSettings?: boolean;
  closePane?: () => void;
}

interface Props extends StoreProps, DispatchProps, OwnProps {}

interface State {
  meetingsEnabled: boolean;
  messagingEnabled: boolean;
  messagingVideoCallEnabled: boolean;
  networkingRules: any;
  strictNetworkingRulesEnabled: boolean;
}

class Networking extends Component<Props, State> {
  private nodeRef: React.RefObject<HTMLDivElement>;

  static defaultProps = {
    hideMessagingSettings: false,
    hideMeetingSettings: false
  };

  constructor(props: Props) {
    redirectIfUnauthorized("configuration", "manage");
    super(props);

    this.nodeRef = createRef();
    this.state = this.stateFromProps(props);

    [
      "submit"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  componentDidMount(): void {
    this.props.requestEvent();
  }

  componentDidUpdate(prevProps: Props): void {
    const { event, inPane, closePane } = this.props;
    if (!prevProps.event.fetched && event.fetched) {
      this.setState(this.stateFromProps());
    } else if (prevProps.event.pendingRequest && !event.pendingRequest) {
      if (inPane && !event?.errors) {
        closePane();
      }

      this.scrollToTop();

      if (this.shouldReloadPage(prevProps)) {
        window.location.reload();
      }
    }
  }

  // when enabling/disabling meetings, reload the page so the meeting tab appears/disappears
  shouldReloadPage(prevProps: Props): boolean {
    const { event } = this.props;
    return prevProps.event?.networking_meetings_enabled !== event?.networking_meetings_enabled && !event?.errors;
  }

  scrollToTop(): void {
    if (this.props.inPane) {
      this.nodeRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  stateFromProps(props = this.props): State {
    const { event } = props;
    return {
      meetingsEnabled: event.networking_meetings_enabled,
      messagingEnabled: event.networking_messaging_enabled,
      networkingRules: event.networking_rules || {},
      messagingVideoCallEnabled: event.video_call_enabled,
      strictNetworkingRulesEnabled: event.strict_networking_rules_enabled,
    };
  }

  i18n(key: string): string {
    return I18n.t(`react.networking.networking.${key}`);
  }

  submit(e: React.MouseEvent): void {
    e.preventDefault();

    const { event, updateEvent } = this.props;
    const payload = {
      networking_meetings_enabled: this.state.meetingsEnabled,
      networking_messaging_enabled: this.state.messagingEnabled,
      networking_rules: this.state.networkingRules,
      video_call_enabled: this.state.messagingVideoCallEnabled,
      strict_networking_rules_enabled: this.state.strictNetworkingRulesEnabled
    };

    const notifications = { notice: I18n.t("successfully_saved"), noticeType: "success" };
    updateEvent(event._id, payload, null, notifications);
  }

  networkingRulesFeatureScopes(): string[] {
    const { messagingEnabled, meetingsEnabled } = this.state;
    const { hideMessagingSettings, hideMeetingSettings } = this.props;
    const scopes = [];
    if (messagingEnabled && isEnabled(ONE_TO_ONE_CHAT_AND_CONNECTIONS) && !hideMessagingSettings) scopes.push("global");
    if (meetingsEnabled && anyEnabled([SCHEDULED_1TO1_MEETING, EXHIBITOR_MEETINGS]) && !hideMeetingSettings) scopes.push("meetings");

    return scopes;
  }

  renderMessagingCheckbox(): JSX.Element {
    const { hideMessagingSettings } = this.props;
    if (!isEnabled(ONE_TO_ONE_CHAT_AND_CONNECTIONS) || hideMessagingSettings) return null;

    const { messagingEnabled } = this.state;
    return <Checkbox
      checked={messagingEnabled}
      text={fieldI18n("networking_messaging_enabled")}
      onChange={(): void => {
        this.setState({
          messagingEnabled: !messagingEnabled
        });
      }}
    />;
  }

  renderMeetingsCheckbox(): JSX.Element {
    const { hideMeetingSettings } = this.props;
    if (!anyEnabled([SCHEDULED_1TO1_MEETING, EXHIBITOR_MEETINGS]) || hideMeetingSettings) return null;

    const { meetingsEnabled } = this.state;
    return <Checkbox
      checked={meetingsEnabled}
      text={fieldI18n("networking_meetings_enabled")}
      onChange={(): void => {
        this.setState({
          meetingsEnabled: !meetingsEnabled
        });
      }}
    />;
  }

  renderNetworkingRulesPanel(): JSX.Element {
    const { networkingRules, messagingEnabled, meetingsEnabled, strictNetworkingRulesEnabled } = this.state;

    if (!anyEnabled([ONE_TO_ONE_CHAT_AND_CONNECTIONS, SCHEDULED_1TO1_MEETING, EXHIBITOR_MEETINGS]) || (!messagingEnabled && !meetingsEnabled)) return null;

    const { event } = this.props;
    return (
      <FormSectionPanel title={this.i18n("networking_rules")}>
        <NetworkingRules
          event={event}
          strictNetworkingRulesEnabled={strictNetworkingRulesEnabled}
          rules={networkingRules}
          featureScopes={this.networkingRulesFeatureScopes()}
          onChange={(values: { strictNetworkingRulesEnabled?: boolean, rules?: any }): void => {
            const { rules, strictNetworkingRulesEnabled } = values;
            if (rules !== undefined) this.setState({ networkingRules: rules });
            if (strictNetworkingRulesEnabled !== undefined) this.setState({ strictNetworkingRulesEnabled });
          }}
        />
      </FormSectionPanel>
    );
  }

  renderNetworkingPanel(): JSX.Element {
    return (
      <FormSectionPanel title={this.i18n("networking_types")}>
        {this.renderMessagingCheckbox()}
        {this.renderMeetingsCheckbox()}
      </FormSectionPanel>
    );
  }

  renderMessagingVideoCallPanel(): JSX.Element {
    const { hideMessagingSettings } = this.props;
    const { messagingEnabled, messagingVideoCallEnabled } = this.state;

    if (!isEnabled(VIDEO_CHAT) || !messagingEnabled || !window["ReactGlobalProps"]["super_admin"] || hideMessagingSettings) return null;

    return (
      <FormSectionPanel title={this.i18n("messaging")}>
        <Checkbox
          checked={messagingVideoCallEnabled}
          text={<>{fieldI18n("video_call_enabled")} <small className="text-warning">{I18n.t("admin_only")}</small></>}
          onChange={(): void => {
            this.setState({
              messagingVideoCallEnabled: !messagingVideoCallEnabled
            });
          }}
        />
      </FormSectionPanel>
    );
  }

  renderErrors(): JSX.Element {
    const { errors } = this.props.event;

    if (!errors) return null;

    return <ErrorMessage errors={errors} model={"event"}/>;
  }

  render(): JSX.Element {
    if (!anyEnabled([ONE_TO_ONE_CHAT_AND_CONNECTIONS, SCHEDULED_1TO1_MEETING, EXHIBITOR_MEETINGS])) return null;

    const { event, inPane, notification } = this.props;
    if (!event?.fetched) return <Loader />;

    return <div ref={this.nodeRef} className="row" style={ inPane ? { margin: "10px" } : {} }>
      <div className="col-12">
        <NotificationAlert notification={notification} />
        {this.renderErrors()}
        {this.renderNetworkingPanel()}
        {this.renderNetworkingRulesPanel()}
        {this.renderMessagingVideoCallPanel()}

        <button className="btn btn-primary btn-lg float-end" onClick={this.submit} disabled={event.pendingRequest}>
          {this.i18n("save")}
        </button>
      </div>
    </div>;
  }
}

function mapStateToProps(state: any): StoreProps {
  return {
    event: state.event,
    notification: state.notifications
  };
}

const mapDispatchToProps: DispatchProps = {
  updateEvent,
  requestEvent
};

export default connect<StoreProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(Networking);
