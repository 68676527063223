// insert or update in an array state the given object, based on the given id. It keeps the array order on update add at the end on insert
export function insertOrUpdateObject(state, object, id, insertAtEnd = true) {
  let updated = false;
  state = state.map(item => {
    if (item[id] == object[id]) {
      updated = true;
      return Object.assign({}, item, object);
    } else {
      return item;
    }
  });

  if (object.companyFieldsErrors) object.companyFieldsErrors = null;

  if (updated) {
    return state;
  }

  insertAtEnd ? state.push(object) : state.unshift(object);
  return state;
}

// merge an object with another one in the state, based on the given id, preserving the order
export function mergeObjectWithExistingObject(state, object, id) {
  const currentItem = state.find(item => { return item[id] == object[id]; });
  if (currentItem == undefined) {
    return state;
  }

  const newItem = Object.assign(currentItem, object);
  return insertOrUpdateObject(state, newItem, id);
}
