import Checkbox from "../../shared/Checkbox.react";
import HelpSection from "../../shared/HelpSection.react";
import LimitSelector from "../../shared/LimitSelector.react";
import { fieldI18n } from "../../../types/Event";
import { SCHEDULED_1TO1_MEETING, EXHIBITOR_MEETINGS } from "../../../constants/FeaturesSet";
import { isEnabled } from "../../../utils/featureSetUtils";
import { hasAvailableFeature } from "../../../utils/availableFeature.js";

interface Props {
  networkingMeetingsImportedByOrganizer: boolean;
  networkingMeetingsType: "physical"|"virtual"|"physical_or_virtual";
  defaultSlotDurationMinutes: number;
  simultaneousSlotsEnabled: boolean;
  awaitingMeetingRequestsLimit:number;

  onCheckboxChange: (key: string) => void;
  onRadioChange: (key: string, value: string) => void;
  onDefaultSlotDurationChange: (value: number) => void;
  onAwaitingMeetingRequestsLimitChange: (value: number) => void;
}

const i18n = (key: string): string => {
  return I18n.t(`react.networking.meetings.type.${key}`);
};

const TypeSettings: React.FC<Props> = ({
  networkingMeetingsImportedByOrganizer,
  networkingMeetingsType,
  defaultSlotDurationMinutes,
  simultaneousSlotsEnabled,
  awaitingMeetingRequestsLimit,
  onCheckboxChange,
  onRadioChange,
  onDefaultSlotDurationChange,
  onAwaitingMeetingRequestsLimitChange
}) => {
  const renderRadio = (prop: string, key: string, value: string, i18nKey: string): JSX.Element => {
    return <div className="form-check">
      <label htmlFor={value} className="form-check-label">
        <input
          type="radio"
          id={value}
          className="form-check-input"
          name={key}
          value={value}
          checked={networkingMeetingsType === value}
          onChange={(): void => {
            onRadioChange(prop, value);
          }}
        /> {fieldI18n(i18nKey)}
      </label>
    </div>;
  };

  const renderDefaultSlotDurationField = (): JSX.Element => {
    return <div className="col-md-3">
      <label className="form-label">{i18n("meeting_default_slot_duration")}</label>
      <input
        type="number"
        className="form-control"
        placeholder={i18n("meeting_default_slot_duration")}
        required={false}
        value={defaultSlotDurationMinutes}
        onChange={(e): void => {
          onDefaultSlotDurationChange(parseInt(e.target.value));
        }}
      />
    </div>;
  };

  const renderSimultaneousSlotsCheckbox = (key: string, checked: boolean, i18nKey: string): JSX.Element => {
    return <div className="mb-3 mt-3">
      <Checkbox
        checked={checked}
        text={i18n(i18nKey)}
        onChange={(): void => onCheckboxChange(key) }
      />
    </div>;
  };

  const renderAwaitingMeetingRequestsLimit = (): JSX.Element => {
    if (!hasAvailableFeature("pending_meetings_limit")) return null;

    return <div className="col-md-12 mt-3">
      <LimitSelector
        value={awaitingMeetingRequestsLimit}
        onChange={onAwaitingMeetingRequestsLimitChange}
        controlLabel={i18n("awaiting_meeting_requests_limit")}
        i18nRootPath="react.networking.meetings.type"
      />
      <p className="text-muted">{i18n("awaiting_meeting_requests_limit_help")}</p>
    </div>;
  };

  return <>
    {isEnabled(EXHIBITOR_MEETINGS) && (
      <div className="mb-3">
        <Checkbox
          checked={networkingMeetingsImportedByOrganizer}
          text={fieldI18n("networking_meetings_imported_by_organizer")}
          onChange={(): void => onCheckboxChange("networkingMeetingsImportedByOrganizer") }
        />
        {networkingMeetingsImportedByOrganizer && (
          <HelpSection help={i18n("networking_meetings_imported_by_organizer_help")} />
        )}
      </div>
    )}

    {!networkingMeetingsImportedByOrganizer && isEnabled(SCHEDULED_1TO1_MEETING) && (
      <div>
        <HelpSection help={i18n("help")} />
        {renderRadio("networkingMeetingsType", "networking_meetings_type", "physical_or_virtual", "networking_meetings_type_physical_or_virtual")}
        {renderRadio("networkingMeetingsType", "networking_meetings_type", "physical", "networking_meetings_type_physical")}
        {renderRadio("networkingMeetingsType", "networking_meetings_type", "virtual", "networking_meetings_type_virtual")}
        {renderSimultaneousSlotsCheckbox("simultaneousSlotsEnabled", simultaneousSlotsEnabled, "meeting_simultaneous_slots_enabled")}
        {renderDefaultSlotDurationField()}
        {renderAwaitingMeetingRequestsLimit()}
      </div>
    )}
  </>;
};

export default TypeSettings;
