import { Component } from "react";
import { connect } from "react-redux";
import { Event } from "../../types/Event";
import { fetchEvents } from "../../actions/EventActionCreators";
import FilterDropdown from "../../components/FilterDropdown.react";

interface Props {
  events?: Event[];
  accountId?: string;
  ignoreEventsWithIds?: string[];
  initialEventId?: string;
  emptyTitle?: string;
  onSelectEvent(event: Event): void;
  fetchEvents?(options: { page?: number; perPage?: number; published?: boolean; accountId?: string; }): void;
}

interface State {
  selectedEvent?: Event;
}

class EventPicker extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      selectedEvent: null
    };
  }

  componentDidMount(): void {
    const { fetchEvents, events, accountId } = this.props;
    if (!events || events.length === 0) {
      fetchEvents({ accountId });
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { events, initialEventId, onSelectEvent } = this.props;
    if (!prevProps.events && events && initialEventId) {
      // just received event we may have the event passed in props
      const selectedEvent = events.find(e => e._id === initialEventId);
      if (selectedEvent) {
        this.setState({
          selectedEvent
        });
        onSelectEvent(selectedEvent);
      }
    }
  }

  onChange(data: string[]): void {
    const selectedId = data[0];
    const { events, onSelectEvent } = this.props;
    const selectedEvent = selectedId ? events.find(e => e._id === selectedId) : null;
    this.setState({
      selectedEvent
    });
    onSelectEvent(selectedEvent);
  }

  render(): JSX.Element {
    const { events, emptyTitle, ignoreEventsWithIds } = this.props;
    if (!events || events.length === 0) {
      return null;
    }

    let items = events || [];
    if (ignoreEventsWithIds && ignoreEventsWithIds.length > 0) {
      items = events.filter(e => !ignoreEventsWithIds.includes(e._id));
    }

    const { selectedEvent } = this.state;
    const title = selectedEvent ? selectedEvent.title : emptyTitle;
    return (
      <div>
        <FilterDropdown
          id="events"
          translationKey="events"
          itemNameKey="title"
          items={items}
          selectedItemIds={[selectedEvent && selectedEvent._id]}
          showCells={false}
          hasSelectAll={false}
          multipleSelect={false}
          sortItems={false}
          selectedFirst={false}
          itemIdKey="_id"
          onChange={this.onChange}
          allowUnselect={true}
          title={title}
        />
      </div>
    );
  }
}

function mapStateToProps(state: any): any {
  return {
    events: state.events.data
  };
}

const mapDispatchToProps = {
  fetchEvents
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPicker);
