import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";
import { fetchEvents } from "../../actions/EventActionCreators";
import { requestEvent } from "../../actions/ImportActionCreators";
import { fetchEmailTemplatesFromEvent, duplicateEmailTemplate } from "../../actions/EmailBuilderActionCreators";
import { fetchDocumentTemplatesFromEvent } from "../../actions/DocumentTemplatesActionCreators";
import { duplicateDocumentTemplate } from "../../actions/DocumentTemplateActionCreators";
import TargetEventModal from "../../components/duplication_modals/TargetEventModal.react";
import DuplicationRunningModal from "../../components/duplication_modals/DuplicationRunningModal.react";
import TemplatePickerModal from "../../components/duplication_modals/TemplatePickerModal.react";
import { redirectIfUnauthorized } from "../../utils/aclUtils";
import { Event } from "../../types/Event";

interface Props {
  templateType: string;
  defaultCreateButton?: boolean;
  duplicatedTemplate?: any;
  duplicationErrors?: any;
  duplicationWarnings?: any;
  onNewTemplate(): void;
}

const TemplateCreationButtons: React.FC<Props> = ({
  templateType,
  defaultCreateButton,
  duplicatedTemplate,
  duplicationErrors,
  duplicationWarnings,
  onNewTemplate
}) => {
  redirectIfUnauthorized("configuration", "manage");

  const [showSourceEventPickerModal, setShowSourceEventPickerModal] = useState<boolean>(false);
  const [sourceEventId, setSourceEventId] = useState<string>(null);
  const [showTemplatePickerModal, setShowTemplatePickerModal] = useState<boolean>(false);
  const [showDuplicationRunningModal, setShowDuplicationRunningModal] = useState<boolean>(false);

  const event = useSelector((state: any): Event => state.event);
  const events = useSelector((state: any): Event[] => state.events.data);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchEvents({ page: 1, perPage: 100 });
    requestEvent();
  }, []);

  const toggleSourceEventModal = (e): void => {
    e && e.preventDefault();
    setShowSourceEventPickerModal(!showSourceEventPickerModal);
    setShowTemplatePickerModal(false);
  };

  const onSourceEventSelected = (eventId: string): void => {
    fetchTemplates(eventId);
    setShowSourceEventPickerModal(false);
    setShowTemplatePickerModal(true);
    setSourceEventId(eventId);
  };

  const i18n = (key: string): string => {
    switch (templateType) {
    case "email_template":
      return I18n.t(`react.email_templates.creation_buttons.${key}`);
    case "document_template":
      return I18n.t(`react.document_templates.creation_buttons.${key}`);
    }
  };

  const fetchTemplates = (eventId: string): void => {
    switch (templateType) {
    case "email_template":
      return dispatch(fetchEmailTemplatesFromEvent(eventId));
    case "document_template":
      return dispatch(fetchDocumentTemplatesFromEvent(eventId));
    }
  };

  const templates = (event: Event): any[] => {
    switch (templateType) {
    case "email_template":
      return event.emailTemplates;
    case "document_template":
      return event.documentTemplates;
    }
  };

  const duplicate = (sourceTemplateId: string, newName: string): void => {
    setShowTemplatePickerModal(false);
    setShowDuplicationRunningModal(true);

    const templateParams = {
      new_name: newName,
      target_event_id: event._id
    };

    switch (templateType) {
    case "email_template":
      return dispatch(duplicateEmailTemplate(sourceEventId, sourceTemplateId, templateParams, true));
    case "document_template":
      return dispatch(duplicateDocumentTemplate(sourceEventId, sourceTemplateId, templateParams, true));
    }
  };

  const renderDuplicateFromModals = (): JSX.Element => {
    const sourceEvent = events && events.find(ev => ev._id === sourceEventId);

    return <>
      {/* TargetEventModal should probably be renamed EventPickerModal */}
      <TargetEventModal
        isVisible={showSourceEventPickerModal}
        onClose={(): void => setShowSourceEventPickerModal(false) }
        currentEvent={event}
        onEventSelected={onSourceEventSelected}
        title={ i18n("choose_event_modal_title") }
      />
      <TemplatePickerModal
        templateType={templateType}
        isVisible={showTemplatePickerModal}
        event={sourceEvent}
        templates={sourceEvent && templates(sourceEvent)}
        onClose={(): void => setShowTemplatePickerModal(false) }
        onSubmit={duplicate}
        onBack={toggleSourceEventModal}
      />
      <DuplicationRunningModal
        isVisible={showDuplicationRunningModal}
        onClose={(): void => setShowDuplicationRunningModal(false) }
        model={`${templateType}s`}
        currentEventId={event._id}
        targetEventId={event._id}
        duplicatedData={duplicatedTemplate}
        duplicationErrors={duplicationErrors}
        duplicationWarnings={duplicationWarnings}
      />
    </>;
  };

  const dropdownVariant = defaultCreateButton ? "secondary" : "primary";

  return (
    <>
      <Dropdown as={ButtonGroup}>
        <Button variant={dropdownVariant} onClick={onNewTemplate}>
          <i className="fa-regular fa-plus"></i> { i18n("create_new_template") }
        </Button>
        <Dropdown.Toggle variant={dropdownVariant} aria-haspopup="true"></Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item eventKey="1" onClick={toggleSourceEventModal}>
            { i18n("create_template_from") }
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      { renderDuplicateFromModals() }
    </>
  );
};

export default TemplateCreationButtons;
