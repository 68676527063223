"use strict";
import { Component } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";

import Loader from "../shared/Loader.react";

import { EtCaetera } from "../../constants/Constants.js";

/**
 This component is reusable for any import in Applidget products.
 rows = data to display
 mapping_prototype = content of the select (action, fields)
 currentMapping = mapping between columns and mapping_protoptye values

 more details in https://gist.github.com/guillaume-gomez/92a01cf145db95c3bc605c16ec592608
**/

class ImportWizard extends Component {
  constructor(props) {
    super(props);
    [
      "renderMappingHeader",
      "renderMappingPrototype",
      "renderColumnHeader",
      "renderContent",
      "renderEmptyLine",
      "renderLineNumber",
      "getDefaultOption",
      "changeOption",
      "changeMappingFirstLine",
      "fieldAlreadySelected"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
  }

  getDefaultOption() {
    const { defaultOption, mappingPrototype } = this.props;
    if (defaultOption) {
      return defaultOption;
    }
    if (!mappingPrototype || !mappingPrototype[0] || !mappingPrototype[0].data || !mappingPrototype[0].data[0] || !mappingPrototype[0].data[0].key) {
      throw "Cannot set a default Option. Please set a defaultOption with the props 'defaultOption'";
    }
    return mappingPrototype[0].data[0].key;
  }

  changeMappingFirstLine(line) {
    return () => {
      const { selectFirstLine, onChange } = this.props;
      if (selectFirstLine) {
        onChange(null, null, line);
      }
    };
  }

  changeOption(column) {
    const { onChange, mappingPrototype } = this.props;
    return (e) => {
      let onClickFn = null;
      mappingPrototype.some(option => {
        const value = option.data.find(data => {
          return data.key == e.target.value && data.onClick;
        });
        if (value) {
          onClickFn = value.onClick;
          return true;
        }
      });
      onClickFn ? onClickFn(column) : onChange(e.target.value, column);
    };
  }

  fieldAlreadySelected(d, column) {
    const { currentMapping } = this.props;
    let resultCol = 0;
    const result = Object.keys(currentMapping).find(index => {
      if (currentMapping[index].key == d.key) {
        resultCol = index;
        return true;
      }
    });
    return resultCol != column && result && !result.allowMultiple;
  }

  renderMappingPrototype(column) {
    const { mappingPrototype, currentMapping } = this.props;

    const renderDataOption = (data) => {
      return data.map(datum => {
        if (this.fieldAlreadySelected(datum, column)) {
          return (<option value={datum.key} key={datum.key} disabled>{datum.value}</option>);
        }
        return (<option value={datum.key} key={datum.key}>{datum.value}</option>);
      });
    };

    const options = mappingPrototype.map((option, index) => {
      if (option.title) {
        return (
          <optgroup key={option.title || index} label={option.title}>
            {renderDataOption(option.data)}
          </optgroup>
        );
      }
      return renderDataOption(option.data);
    });

    const selectedValue = currentMapping[column] ? currentMapping[column].key : this.getDefaultOption();
    return (
      <select className="form-select" value={selectedValue} onChange={this.changeOption(column)}>
        {options}
      </select>
    );
  }

  renderMappingHeader() {
    const { rows } = this.props;
    return rows[0].map((_, column) => {
      return (
        <th key={column} className="text-center">
          {this.renderMappingPrototype(column)}
        </th>
      );
    });
  }

  renderColumnHeader() {
    const { rows } = this.props;

    const writeLetters = (columnIndex) => {
      let letters = "";
      let quotient = Math.trunc(columnIndex / 26);
      let remainder = columnIndex - quotient * 26;
      do {
        if (quotient > 0) {
          letters = letters + String.fromCharCode(97 + ((quotient - 1) % 26));
        }
        quotient = Math.trunc(quotient / 26);
      } while (quotient > 26);
      letters = letters + String.fromCharCode(97 + (remainder % 26));

      return letters;
    };

    return rows[0].map((_, index) => {
      const letters = writeLetters(index);

      return (<th key={letters} className="text-center">{letters}</th>);
    });
  }

  renderEmptyLine() {
    const { rows, disableDefaultColumnCss, currentMapping } = this.props;
    const emptyTd = rows[0].map((_, index) => {
      const className = classNames({
        "disabled-cell": !disableDefaultColumnCss && !Object.keys(currentMapping).includes(index.toString())
      });
      return (<td key={index} className="text-center" ><span className={className}>{EtCaetera}</span></td>);
    });
    return (
      <tr className="selected-guest-row">
        <th></th>
        {emptyTd}
      </tr>
    );
  }

  renderContent() {
    const { rows, selectFirstLine, mappingFirstLine, disableDefaultColumnCss, currentMapping } = this.props;
    const renderLine = (row, line) => {
      return row.map((cell, column) => {
        const className = classNames({
          "disabled-cell": !disableDefaultColumnCss && !Object.keys(currentMapping).includes(column.toString())
        });
        return (<td key={column + (line * row.length)}><span className= {className}>{cell}</span></td>);
      });
    };

    const isSelectedClassName = (line) => {
      return classNames({
        "selected-guest-row": selectFirstLine && line >= mappingFirstLine
      });
    };

    return rows.map((row, line) => {
      return (
        <tr key={line} data-line={line} className={isSelectedClassName(line)}>
          {this.renderLineNumber(line)}
          {renderLine(row, line)}
        </tr>
      );
    });
  }

  renderLineNumber(line) {
    const { selectFirstLine, mappingFirstLine } = this.props;

    if (!selectFirstLine) {
      return (
        <th className="text-end">{line + 1}.</th>
      );
    }

    const firstGuest = (line) => {
      return classNames({
        "first-guest": true,
        "first-guest-ghost": mappingFirstLine != line // hide arrow if true
      });
    };
    return (
      <th className="right first-guest-cell" onClick={this.changeMappingFirstLine(line)}>
        <span className={firstGuest(line)}>
          <i className="fa-regular fa-arrow-right"></i>
        </span>
        {" " + (line + 1)}.
      </th>
    );
  }

  render() {
    const { rows } = this.props;
    if (isEmpty(rows[0])) {
      return (<Loader / >);
    }
    return (
      <div className="table-responsive table-container mt-10" id="user-excel-import-container">
        <table className="table table-light table-bordered" id="user-excel-import">
          <thead>
            <tr>
              <th style={{ width: "3em" }}></th>
              {this.renderMappingHeader()}
            </tr>
            <tr>
              <th></th>
              {this.renderColumnHeader()}
            </tr>
          </thead>
          <tbody>
            {this.renderContent()}
            {this.renderEmptyLine()}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ImportWizard;

ImportWizard.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.array).isRequired,
  mappingFirstLine: PropTypes.number,
  selectFirstLine: PropTypes.bool,
  currentMapping: PropTypes.shape(
    PropTypes.oneOfType([
      PropTypes.oneOf([null]),
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ]).isRequired
  ),
  mappingPrototype: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  defaultOption: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disableDefaultColumnCss: PropTypes.bool,
};

ImportWizard.defaultProps = {
  selectFirstLine: true,
  initialMappingFirstLine: 0,
  rows: [[]],
  disableDefaultColumnCss: false,
  currentMapping: {}
};
