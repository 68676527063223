export const DEFAULT_EDITOR_PROPERTIES = {
  fontFamily: "Arial",
  fontSize: 24,
  fontColor: "#000000",
  textAlign: "left",
};

export const BADGE_IMAGE_POSITIONS = ["background", "top_left", "top_right", "bottom_left", "bottom_right"];

export const DEFAULT_CUSTOMISATION_FABRIC_STRUCTURE = {
  A4_single_background: [
    {
      type: "image",
      position: "background"
    },
    {
      type: "text",
      text: "Name",
      color: "#000000",
      font: "Arial",
      fontSize: 18,
      left: 309.5,
      top: 100,
      width: 263.5,
      textAlign: "center",
    },
    {
      type: "text",
      text: "Position",
      color: "#000000",
      font: "Arial",
      fontSize: 13,
      left: 309.5,
      top: 135,
      width: 263.5,
      textAlign: "center",
    },
    {
      type: "qrcode",
      left: 401,
      top: 223.5,
      width: 88,
    },
    {
      type: "text",
      text: "{{{category}}}",
      color: "#000000",
      font: "Arial",
      fontSize: 20,
      left: 309.5,
      top: 365,
      width: 263.5,
      textAlign: "center",
    }
  ],
  A4_single_background_advanced: [
    {
      type: "image",
      position: "background"
    },
    {
      type: "text",
      text: "privileges",
      color: "#000000",
      font: "Arial",
      fontSize: 12,
      left: 37,
      top: 90,
      width: 252,
      textAlign: "center",
    },
    {
      type: "text",
      text: "Name",
      color: "#000000",
      font: "Arial",
      fontSize: 20,
      left: 300,
      top: 160,
      width: 260,
      textAlign: "center",
    },
    {
      type: "qrcode",
      left: 390,
      top: 242,
      width: 90,
    },
    {
      type: "text",
      text: "Company",
      color: "#000000",
      font: "Arial",
      fontSize: 16,
      left: 300,
      top: 212,
      width: 260,
      textAlign: "center",
    },
    {
      type: "text",
      text: "{{{category}}}",
      color: "#000000",
      font: "Arial",
      fontSize: 32,
      left: 300,
      top: 357,
      width: 260,
      textAlign: "center",
    }
  ],
  A4_single_background_symetric_identity: [
    {
      type: "image",
      position: "background"
    },
    {
      type: "text",
      text: "Name",
      color: "#000000",
      font: "Arial",
      fontSize: 18,
      left: 15,
      top: 100,
      width: 260,
      textAlign: "center",
    },
    {
      type: "text",
      text: "Position",
      color: "#000000",
      font: "Arial",
      fontSize: 13,
      left: 15,
      top: 135,
      width: 260,
      textAlign: "center",
    },
    {
      type: "qrcode",
      left: 100,
      top: 223.5,
      width: 88,
    },
    {
      type: "text",
      text: "{{{category}}}",
      color: "#000000",
      font: "Arial",
      fontSize: 20,
      left: 15,
      top: 365,
      width: 260,
      textAlign: "center",
    },
    {
      type: "text",
      text: "Name",
      color: "#000000",
      font: "Arial",
      fontSize: 18,
      left: 309.5,
      top: 100,
      width: 263.5,
      textAlign: "center",
    },
    {
      type: "text",
      text: "Position",
      color: "#000000",
      font: "Arial",
      fontSize: 13,
      left: 309.5,
      top: 135,
      width: 263.5,
      textAlign: "center",
    },
    {
      type: "qrcode",
      left: 401,
      top: 223.5,
      width: 88,
    },
    {
      type: "text",
      text: "{{{category}}}",
      color: "#000000",
      font: "Arial",
      fontSize: 20,
      left: 309.5,
      top: 365,
      width: 263.5,
      textAlign: "center",
    }
  ],
  A6: [
    {
      type: "image",
      position: "top_right",
      height: 98
    },
    {
      type: "text",
      text: "Name",
      color: "#000000",
      font: "Arial",
      fontSize: 18,
      left: 15,
      top: 136,
      width: 260,
      textAlign: "center",
    },
    {
      type: "text",
      text: "Position",
      color: "#000000",
      font: "Arial",
      fontSize: 13,
      left: 15,
      top: 175,
      width: 260,
      textAlign: "center",
    },
    {
      type: "qrcode",
      left: 89,
      top: 250,
      width: 115,
    },
    {
      type: "text",
      text: "{{{category}}}",
      color: "#000000",
      font: "Arial",
      fontSize: 20,
      left: 15,
      top: 375,
      width: 260,
      textAlign: "center",
    },
  ],
  A6_background: [
    {
      type: "image",
      position: "top_right",
    },
    {
      type: "text",
      text: "Name",
      color: "#000000",
      font: "Arial",
      fontSize: 18,
      left: 15,
      top: 136,
      width: 260,
      textAlign: "center",
    },
    {
      type: "text",
      text: "Position",
      color: "#000000",
      font: "Arial",
      fontSize: 13,
      left: 15,
      top: 175,
      width: 260,
      textAlign: "center",
    },
    {
      type: "qrcode",
      left: 89,
      top: 250,
      width: 115,
    },
    {
      type: "text",
      text: "{{{category}}}",
      color: "#000000",
      font: "Arial",
      fontSize: 20,
      left: 15,
      top: 375,
      width: 260,
      textAlign: "center",
    },
  ],
  A4_without_dotted_lines: [
    {
      type: "image",
      position: "top_left"
    },
    {
      type: "image",
      position: "bottom_left"
    },
    {
      type: "image",
      position: "top_right",
      height: 98
    },
    {
      type: "image",
      position: "bottom_right"
    },
    {
      type: "text",
      text: "Name",
      color: "#000000",
      font: "Arial",
      fontSize: 18,
      left: 309.5,
      top: 136,
      width: 263.5,
      textAlign: "center",
    },
    {
      type: "text",
      text: "Position",
      color: "#000000",
      font: "Arial",
      fontSize: 13,
      left: 309.5,
      top: 165,
      width: 263.5,
      textAlign: "center",
    },
    {
      type: "qrcode",
      left: 387,
      top: 250,
      width: 115,
    },
    {
      type: "text",
      text: "{{{category}}}",
      color: "#000000",
      font: "Arial",
      fontSize: 20,
      left: 309.5,
      top: 375,
      width: 263.5,
      textAlign: "center",
    }
  ],
  A4: [
    {
      type: "image",
      position: "top_left"
    },
    {
      type: "image",
      position: "bottom_left"
    },
    {
      type: "image",
      position: "top_right",
      height: 98
    },
    {
      type: "image",
      position: "bottom_right"
    },
    {
      type: "text",
      text: "Name",
      color: "#000000",
      font: "Arial",
      fontSize: 18,
      left: 309.5,
      top: 136,
      width: 263.5,
      textAlign: "center",
    },
    {
      type: "text",
      text: "Position",
      color: "#000000",
      font: "Arial",
      fontSize: 13,
      left: 309.5,
      top: 165,
      width: 263.5,
      textAlign: "center",
    },
    {
      type: "qrcode",
      left: 387,
      top: 250,
      width: 115,
    },
    {
      type: "text",
      text: "{{{category}}}",
      color: "#000000",
      font: "Arial",
      fontSize: 20,
      left: 309.5,
      top: 375,
      width: 263.5,
      textAlign: "center",
    }
  ],
  card: [
    {
      type: "image",
      position: "background"
    },
    {
      type: "qrcode",
      left: 12,
      top: 65,
      width: 58,
    },
    {
      type: "text",
      text: "Name",
      color: "#000000",
      font: "Arial",
      fontSize: 14,
      left: 75,
      top: 65,
      width: 140,
      textAlign: "center",
    },
    {
      type: "text",
      text: "Company",
      color: "#000000",
      font: "Arial",
      fontSize: 11,
      left: 75,
      top: 85,
      width: 140,
      textAlign: "center",
    },
    {
      type: "text",
      text: "Position",
      color: "#000000",
      font: "Arial",
      fontSize: 11,
      left: 75,
      top: 100,
      width: 140,
      textAlign: "center",
    },
  ],
  card_background: [
    {
      type: "image",
      position: "background"
    },
    {
      type: "qrcode",
      left: 29,
      top: 62,
      width: 58,
    },
    {
      type: "text",
      text: "Name",
      color: "#000000",
      font: "Arial",
      fontSize: 10,
      left: 95,
      top: 65,
      width: 140,
      textAlign: "center",
    },
    {
      type: "text",
      text: "Company",
      color: "#000000",
      font: "Arial",
      fontSize: 8,
      left: 95,
      top: 80,
      width: 140,
      textAlign: "center",
    },
    {
      type: "text",
      text: "Position",
      color: "#000000",
      font: "Arial",
      fontSize: 8,
      left: 95,
      top: 95,
      width: 140,
      textAlign: "center",
    },
    {
      type: "text",
      text: "{{{category}}}",
      color: "#000000",
      font: "Arial",
      fontSize: 12,
      left: 0,
      top: 125,
      width: 249,
      textAlign: "center",
    },
  ],
  vertical_card: [
    {
      type: "image",
      position: "background"
    },
    {
      type: "qrcode",
      left: 41,
      top: 70,
      width: 68,
    },
    {
      type: "text",
      text: "Name",
      color: "#000000",
      font: "Arial",
      fontSize: 14,
      left: 0,
      top: 155,
      width: 150,
      textAlign: "center",
    },
    {
      type: "text",
      text: "company",
      color: "#000000",
      font: "Arial",
      fontSize: 11,
      left: 0,
      top: 175,
      width: 150,
      textAlign: "center",
    },
    {
      type: "text",
      text: "Position",
      color: "#000000",
      font: "Arial",
      fontSize: 11,
      left: 0,
      top: 190,
      width: 150,
      textAlign: "center",
    },
  ],
  billet: [
    {
      type: "image",
      position: "background"
    },
    {
      type: "qrcode",
      left: 510,
      top: 730,
      width: 50,
    },
    {
      type: "text",
      text: "UID",
      color: "#000000",
      font: "Arial",
      fontSize: 7,
      left: 510,
      top: 780,
      width: 50,
      textAlign: "center",
    },
  ]
};
