import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BundledAccesspoint } from "../types/Accesspoint";
import BundledAccesspointItem from "./BundledAccesspointItem.react";
import { fetchAccesspoints } from "../actions/AccesspointActionCreators";
import uuid from "tiny-uuid4";
import { urlEventId } from "../utils/pathUtils";

interface Props {
  items: BundledAccesspoint[];

  onDeleted: (item: BundledAccesspoint) => void;
  onChanged: (item: BundledAccesspoint) => void;
  onAdded: () => void;
}

const BundledAccesspointsList: React.FC<Props> = ({ items, onDeleted, onChanged, onAdded }) => {
  const [uniqueKeyInSiloedData] = useState(uuid());

  const accesspoints = useSelector((state: any) => state.accesspoints.siloedData[uniqueKeyInSiloedData]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAccesspoints(urlEventId(), "", 0, { uniqueKeyInSiloedData: uniqueKeyInSiloedData }));
  }, []);

  const selectedAccesspointIds = items.filter(item => item.accesspoint_id).map(acp => acp.accesspoint_id);
  const disableAddButton = items.length != selectedAccesspointIds.length;

  return <div>
    {items.map((item, index) => (
      <BundledAccesspointItem
        key={`item-${index}`}
        item={item}
        onDeleted={onDeleted}
        onChanged={onChanged}
        creationMode={!item.accesspoint_id}
        selectedAccesspointIds={selectedAccesspointIds}
        accesspoints={accesspoints} />
    ))}
    <button type="button" className="btn btn-secondary" disabled={disableAddButton} onClick={(): void => onAdded()}><i className="fa-regular fa-plus" /> {I18n.t("react.bundled_accesspoint_list.add")}</button>
  </div>;
};

export default BundledAccesspointsList;
