import { Component } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import filter from "lodash/filter";
import pick from "lodash/pick";
import map from "lodash/map";
import { updateUI } from "../actions/UIAppearanceActionCreators";
import { requestCountGuests } from "../actions/ImportActionCreators";
import { fetchGuestFieldsMostUsedValues } from "../actions/GuestFieldsActionCreators";
import { launchBatchUpdate } from "../actions/GuestUpdatesActionCreators";
import { clearStatistics } from "../actions/GuestFieldsActionCreators";
import UpdateGuestFormComponent from "../components/UpdateGuestFormComponent.react";
import { queryStringAndSelectedGuests } from "../utils/QueryStringUtils";
import withModal from "./withModal.react";
import { urlEventId } from "../utils/pathUtils";

const GUEST_CATEGORY_KEY = "guest_category_id";

class ChangeGuestCategory extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      displayResendConfirmationEmailCheckbox: false,
      newGuestCategoryName: null,
      sendEmailOnGuestCategoryChange: false,
    };
  }

  componentDidMount() {
    const { selectedGuests, fetchGuestFieldsMostUsedValues, updateUI, requestCountGuests } = this.props;
    const updates = {
      "modalTitle": I18n.t("react.reports.modify_guest_category")
    };
    updateUI(updates);
    fetchGuestFieldsMostUsedValues(urlEventId(), "", GUEST_CATEGORY_KEY);
    const query = queryStringAndSelectedGuests(this.props.location, selectedGuests);
    fetchGuestFieldsMostUsedValues(urlEventId(), query, GUEST_CATEGORY_KEY);
    requestCountGuests(query, "nbGuestsWillBeUpdated", true);
  }

  componentWillUnmount() {
    const { clearStatistics } = this.props;
    clearStatistics();
  }

  submit(value) {
    const { closeModal, location, selectedGuests, launchBatchUpdate } = this.props;
    const { sendEmailOnGuestCategoryChange } = this.state;
    launchBatchUpdate(urlEventId(), queryStringAndSelectedGuests(location, selectedGuests), GUEST_CATEGORY_KEY, value, { send_email_on_guest_category_change: sendEmailOnGuestCategoryChange });
    closeModal();
  }

  guestCategoryIdsToNames(ids) {
    const { guestCategories } = this.props;
    const names = map(ids, id => {
      return pick(filter(guestCategories, category => {
        return category.id == id;
      })[0], "id", "name");
    });
    return names;
  }

  setDisplayResendConfirmationEmailCheckbox(guestCategoryId) {
    const { guestCategories } = this.props;
    const selectedGuestCategory = guestCategories.find(gc => gc._id === guestCategoryId);

    this.setState({
      displayResendConfirmationEmailCheckbox: selectedGuestCategory.confirmation_email_enabled,
      newGuestCategoryName: selectedGuestCategory.name,
    });
  }

  sendConfirmationEmailChange(e) {
    this.setState({ sendEmailOnGuestCategoryChange: e.target.checked });
  }

  render() {
    const { eventFieldMostUsedValues, searchFieldMostUsedValues, selectedGuestCount, guestCategories } = this.props;
    const { newGuestCategoryName } = this.state;
    const options = map(guestCategories, category => {
      return { value: category.id, label: category.name };
    });

    return (
      <UpdateGuestFormComponent
        options={options}
        eventFieldMostUsedValues={this.guestCategoryIdsToNames(eventFieldMostUsedValues)}
        searchFieldMostUsedValues={this.guestCategoryIdsToNames(searchFieldMostUsedValues)}
        selectedGuestCount={selectedGuestCount}
        fieldLabel={I18n.t("mongoid.models.guest_category")}
        selectPlaceholder={I18n.t("react.reports.guest_category.filter_placeholder")}
        submitAction={this.submit}
        onChange={(guestCategoryId) => this.setDisplayResendConfirmationEmailCheckbox(guestCategoryId)}>
        {this.state.displayResendConfirmationEmailCheckbox && <>
          <p>{I18n.t("shared.guest_category_changed_popin.explain_html_1")} <strong id="new-guest-category-id">{newGuestCategoryName}</strong>{I18n.t("shared.guest_category_changed_popin.explain_html_2")}</p>
          <Form.Group controlId="send_email_on_guest_category">
            <Form.Check
              type="checkbox"
              onChange={e => this.sendConfirmationEmailChange(e)}
              label={I18n.t("shared.guest_category_changed_popin.send_email_on_guest_category_change")}
            />
          </Form.Group>
          <hr />
        </>}
      </UpdateGuestFormComponent>
    );
  }
}

function mapStateToProps(state) {
  return {
    guestCategories: state.guestCategories.data,
    eventFieldMostUsedValues: state.guestFieldStatistics.eventFieldMostUsedValues,
    searchFieldMostUsedValues: state.guestFieldStatistics.searchFieldMostUsedValues,
    selectedGuestCount: state.appearance.nbGuestsWillBeUpdated,
    selectedGuests: state.guests.selectedGuests
  };
}

const mapDispatchToProps = {
  fetchGuestFieldsMostUsedValues,
  launchBatchUpdate,
  clearStatistics,
  requestCountGuests,
  updateUI
};

export default connect(mapStateToProps, mapDispatchToProps)(withModal(ChangeGuestCategory));
export { ChangeGuestCategory };
