import { Component } from "react";
import Modal from "react-modal";
import { defaultModalSmallStyles } from "../../constants/Style";
import PropTypes from "prop-types";
import SendDirectEmail from "../../containers/direct_emails/SendDirectEmail.react";

class DirectEmailModal extends Component {
  render() {
    const { isVisible, close } = this.props;
    return (
      <Modal isOpen={isVisible} onRequestClose={close} style={defaultModalSmallStyles} contentLabel="Modal">
        <div className="modal-header" key="modal-header">
          <h4 className="modal-title">{ I18n.t("react.reports.send_direct_email") }</h4>
          <button type="button" onClick={close} className="btn-close" aria-label={I18n.t("close")}></button>
        </div>
        <div className="modal-body" key="modal-body">
          <div className="row">
            <div className="col-md-12">
              <SendDirectEmail {...this.props} closeModal={close} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

DirectEmailModal.requiresProps = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  rejectionEmail: PropTypes.bool
};

export default DirectEmailModal;
