import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { fetchWorkflowVersion, createWorkflowVersion } from "../../actions/WorkflowsActionCreators";
import { Workflow } from "../../types/Workflow";
import { urlEventId } from "../../utils/pathUtils";

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.workflows.switch_version_modal.${key}`, opts);
}

interface Props {
  workflow: Workflow;
  isVisible: boolean;
  onClose(): void;
}

const SwitchVersionModal: React.FC<Props> = ({
  workflow,
  isVisible,
  onClose
}) => {
  const dispatch = useDispatch();

  const switchToDraftVersion = (): void => {
    dispatch(fetchWorkflowVersion(urlEventId(), workflow.draft_version_id));
    onClose();
  };

  const createDraftVersion = (): void => {
    dispatch(createWorkflowVersion(urlEventId(), workflow._id, { notice: i18n("workflow_version_created"), noticeType: "success" }));
    onClose();
  };

  return <Modal show={isVisible} onHide={onClose} restoreFocus={false}>
    <Modal.Header>
      <Modal.Title>{i18n("title")}</Modal.Title>
      <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
    </Modal.Header>
    <Modal.Body>
      {i18n("message")}
    </Modal.Body>
    <Modal.Footer>
      { workflow.draft_version_id &&
        <button className="btn btn-primary" onClick={switchToDraftVersion}>{i18n("edit_draft")}</button>
      }
      <button className="btn btn-primary" onClick={createDraftVersion}>{i18n("create_draft")}</button>
      <button className="btn btn-secondary" onClick={onClose}>{i18n("remain_on_published_version")}</button>
    </Modal.Footer>
  </Modal>;
};

export default SwitchVersionModal;
