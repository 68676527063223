import { useSelector } from "react-redux";
import Select from "react-select";
import withProgramFilter from "../withProgramFilter.react";
import InputLabelWithVisibilityButton from "../InputLabelWithVisibilityButton.react";
import { FilterProps, FilterOption } from "../../../types/Program";

const TraitFilter: React.FC<FilterProps> = ({ programFilter, onChangeFilter, onChangeLabelOptions }) => {
  const metadata = useSelector((state: any) => state.programs.metadata);

  const onChangeTraitKey = (option: FilterOption) : void => {
    programFilter.trait_key = option.value;
    onChangeFilter(programFilter);
  };

  const buildKeyOptions = (): FilterOption[] => {
    if (!metadata[programFilter._type]) return [];

    return Object.keys(metadata[programFilter._type]).map((traitKey) => {
      return { label: traitKey, value: traitKey };
    });
  };

  const selectedTraitKey = (): FilterOption => {
    if (!programFilter.trait_key) return null;

    return { label: programFilter.trait_key, value: programFilter.trait_key };
  };

  return <>
    <InputLabelWithVisibilityButton
      label={I18n.t("react.programs.label")}
      value={programFilter.label}
      placeholder={I18n.t("react.programs.trait_filter.title")}
      name="trait_label"
      isDisplayed={programFilter.label_displayed}
      onChangeHandler={onChangeLabelOptions}
    />
    <div className="mt-3">
      <label htmlFor="trait" className="form-label">{I18n.t("react.programs.trait_filter.title")}</label>
      <Select
        className="react-select"
        classNamePrefix="react-select"
        options={buildKeyOptions()}
        value={selectedTraitKey()}
        inputId="trait"
        placeholder={I18n.t("react.programs.trait_filter.key_dropdown_placeholder")}
        onChange={onChangeTraitKey}
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            width: "300px"
          })
        }} />
      <div className="form-text">{I18n.t("react.programs.trait_filter.key_dropdown_ui_help")}</div>
    </div>
  </>;
};

export default withProgramFilter(TraitFilter, "ui");
