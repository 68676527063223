import { CALL_API, ActionTypes } from "../constants/Constants";
import { registrationFormStepsSchemas } from "./RegistrationFormsActionCreators";

export function updateRegistrationFormStep(formId, formStepId, params) {
  const { DEV_NULL, REGISTRATION_FORM_STEP_UPDATE_SUCCESS, REGISTRATION_FORM_STEP_UPDATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, REGISTRATION_FORM_STEP_UPDATE_SUCCESS, REGISTRATION_FORM_STEP_UPDATE_FAILURE],
      method: "PUT",
      body: { form_step: params },
      endpoint: `/api/v1/registration_forms/${formId}/form_steps/${formStepId}.json`
    },
    formStepId,
    params
  };
}

export function createRegistrationFormStep(formId, params) {
  const { DEV_NULL, REGISTRATION_FORM_STEP_CREATE_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, REGISTRATION_FORM_STEP_CREATE_SUCCESS, DEV_NULL],
      method: "POST",
      body: { form_step: params },
      endpoint: `/api/v1/registration_forms/${formId}/form_steps.json`,
      schema: registrationFormStepsSchemas()
    }
  };
}

export function destroyRegistrationFormStep(formId, formStepId) {
  const { DEV_NULL, REGISTRATION_FORM_STEP_DELETE_SUCCESS, REGISTRATION_FORM_STEP_DELETE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, REGISTRATION_FORM_STEP_DELETE_SUCCESS, REGISTRATION_FORM_STEP_DELETE_FAILURE],
      method: "DELETE",
      endpoint: `/api/v1/registration_forms/${formId}/form_steps/${formStepId}.json`
    },
    formStepId
  };
}

export function toggleRegistrationFormStepFocus(formStep) {
  const { REGISTRATION_FORM_STEP_TOGGLE_FOCUS } = ActionTypes;
  return {
    type: REGISTRATION_FORM_STEP_TOGGLE_FOCUS,
    formStep
  };
}

export function cleanRegistrationFormStepErrors(formStepId) {
  const { REGISTRATION_FORM_STEP_CLEAN_ERRORS } = ActionTypes;
  return {
    type: REGISTRATION_FORM_STEP_CLEAN_ERRORS,
    formStepId
  };
}
