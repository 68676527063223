import flattenDeep from "lodash/flatMapDeep";
import map from "lodash/map";
import each from "lodash/each";
import includes from "lodash/includes";

import { countGuests } from "../utils/APIUtils";
import { buildSearchQueryAndSelectedGuestsFromState, extractTermsTypes } from "../utils/QueryStringUtils";
import { ActionTypes, SearchQueryKeywords, emailActivityFilters, optinsKeywords } from "../constants/Constants";
import { updateRegisteredAtDates } from "./RegistrationDateActionCreators";
import { updateSelectedEmailActivity } from "./EmailActivitiesActionCreators";
import { updateSelectedOptin } from "./OptinActionCreators";
import { startHydratingState, stopHydratingState, setGuestPage } from "./GuestPageActionCreators";

function _setSimpleSearchQuery(value) {
  return {
    type: ActionTypes.SET_SIMPLE_SEARCH_QUERY,
    value
  };
}

function _fillStateFromQueryFilter(objectIds, filterType) {
  return {
    type: filterType,
    objectIds
  };
}

function _findByName(items, names) {
  //TODO(robin) this is really strict, if the search is done by the search bar. If the name doesn't match exactly, no result are found. This is a shame, the backend works nice if not the exact name.
  const selectedItems = items.filter(item => {
    return names.includes(item.name);
  });
  return selectedItems.map(item => { return (item.id || item._id); });
}

module.exports = {
  updateGuestCount(appearanceFieldTarget = "selectedGuestCount", countAll = false) {
    return (dispatch, getState) => {
      const completeSearchQuery = buildSearchQueryAndSelectedGuestsFromState(getState());
      return countGuests(dispatch, completeSearchQuery, appearanceFieldTarget, countAll);
    };
  },

  guestCountFromQS(completeSearchQuery, countAll = false) {
    return (dispatch) => {
      const key = countAll ? "selectedGuestCountAll" : "selectedGuestCount";
      return countGuests(dispatch, completeSearchQuery, key, countAll);
    };
  },

  countGuestsInQS(q, countAll, appearanceFieldTarget) {
    return (dispatch) => {
      return countGuests(dispatch, q, appearanceFieldTarget, countAll);
    };
  },

  fillStateFromSearchQuery(completeSearchQuery, page) {
    return (dispatch, getState) => {
      const [singleTerms, composedTerms] = extractTermsTypes(completeSearchQuery);
      const Fields = map(getState().guestFields.guestFields, "key");
      let simpleSearchQuery = singleTerms.join(" ");
      let expectedAtAccesspointIds = [];
      let acceptedAtAccespointIds = [];
      let rejectedAtAccespointIds = [];
      let checkedInAtAccespointIds = [];
      let thematicIds = [];
      let ThematicsIdsInGuestsThematicsQuartiles = [];
      let QuartilesIdsInGuestsThematicsQuartiles = [];

      //clear registration date
      let registeredAtAfter = "";
      let registeredAtBefore = "";

      dispatch(startHydratingState(page));
      dispatch(setGuestPage(page));
      const filterKeywordAction = {
        [SearchQueryKeywords.GUEST_CATEGORY]: ActionTypes.SET_SELECTED_GUEST_CATEGORY_IDS,
        [SearchQueryKeywords.GUEST_STATUS]: ActionTypes.SET_SELECTED_GUEST_STATUSES,
        [SearchQueryKeywords.SSO_STATUS]: ActionTypes.SET_SELECTED_SSO_STATUSES,
        [SearchQueryKeywords.PAYMENT_STATUS]: ActionTypes.SET_SELECTED_PAYMENT_STATUSES,
        [SearchQueryKeywords.SHOWED_UP_STATUS]: ActionTypes.SET_SELECTED_SHOWED_UP_STATUSES,
        [SearchQueryKeywords.EXPECTED_AT]: ActionTypes.SET_EXPECTED_AT_ACCESSPOINT_IDS,
        [SearchQueryKeywords.CHECKED_IN_AT]: ActionTypes.SET_CHECKED_IN_AT_ACCESSPOINT_IDS,
        [SearchQueryKeywords.ACCEPTED_AT]: ActionTypes.SET_ACCEPTED_AT_ACCESSPOINT_IDS,
        [SearchQueryKeywords.REJECTED_AT]: ActionTypes.SET_REJECTED_AT_ACCESSPOINT_IDS,
        [SearchQueryKeywords.THEMATICS]: ActionTypes.SET_SELECTED_THEMATIC_IDS,
        [SearchQueryKeywords.SORT]: ActionTypes.SET_SELECTED_GUEST_SORT_OPTIONS,
        [SearchQueryKeywords.LABEL]: ActionTypes.SET_SELECTED_GUEST_LABEL_IDS,
        [SearchQueryKeywords.EVENT_CAMPAIGN_OPTIN]: ActionTypes.SET_SELECTED_OPTINS,
        [SearchQueryKeywords.ACCOUNT_CAMPAIGN_OPTIN]: ActionTypes.SET_SELECTED_OPTINS,
        [SearchQueryKeywords.ENGAGEMENT_LEVELS]: ActionTypes.SET_SELECTED_ENGAGEMENT_LEVELS,
        [SearchQueryKeywords.CAMPAIGN_OPTIN]: ActionTypes.SET_SELECTED_OPTINS,
        [SearchQueryKeywords.INTEREST_IN]: ActionTypes.SET_IN_GUESTS_THEMATICS_QUARTILES_SELECTED_THEMATICS_IDS
      };

      const filterKeywords = Object.keys(filterKeywordAction);

      // First we need to clear state, if not only present filters will be updated (it means that nothing can be cleared)
      filterKeywords.forEach(key => {
        dispatch(_fillStateFromQueryFilter([], filterKeywordAction[key]));
      });

      // Clear email activities
      each(emailActivityFilters, filter => {
        dispatch(updateSelectedEmailActivity(filter, null));
      });

      each(optinsKeywords, filter => {
        dispatch(updateSelectedOptin(filter, null));
      });

      composedTerms.forEach(term => {
        const { searchValue } = term;
        let searchField = term.searchField;
        // check if it's a filter
        if (filterKeywords.includes(searchField) || searchField.includes(SearchQueryKeywords.INTEREST_IN)) {
          // check if searchField look like : no_high_interest_in
          if (searchField.includes(SearchQueryKeywords.INTEREST_IN)) {
            searchField = SearchQueryKeywords.INTEREST_IN;
          }
          //remove " in guest category name
          const regex = /((?:(?:[^\s,'"]+)|(?:"(?:\\"|[^"])*")|(?:'(?:\\'|[^'])*'))+)/g;

          const searchValueWithoutQuotes = searchValue.match(regex).map((value) => {
            if (value[0] == "\"" && value[value.length - 1] == "\"" || value[0] == "'" && value[value.length - 1] == "'") {
              value = value.slice(1, value.length - 1);
            }
            return value.replace(/\\"/g, "\"");
          });

          switch (searchField) {
          case SearchQueryKeywords.GUEST_CATEGORY: {
            const guestCategoryIds = _findByName(getState().guestCategories.data, searchValueWithoutQuotes);
            dispatch({ type: filterKeywordAction[searchField], objectIds: guestCategoryIds });
            break;
          }

          case SearchQueryKeywords.LABEL: {
            const guestLabelIds = _findByName(getState().labels.items, searchValueWithoutQuotes);
            dispatch({ type: filterKeywordAction[searchField], objectIds: guestLabelIds });
            break;
          }

          case SearchQueryKeywords.EXPECTED_AT:
            expectedAtAccesspointIds.push(_findByName(getState().accesspoints.data, searchValueWithoutQuotes));
            break;

          case SearchQueryKeywords.CHECKED_IN_AT:
            checkedInAtAccespointIds.push(_findByName(getState().accesspoints.data, searchValueWithoutQuotes));
            break;

          case SearchQueryKeywords.ACCEPTED_AT:
            acceptedAtAccespointIds.push(_findByName(getState().accesspoints.data, searchValueWithoutQuotes));
            break;

          case SearchQueryKeywords.REJECTED_AT:
            rejectedAtAccespointIds.push(_findByName(getState().accesspoints.data, searchValueWithoutQuotes));
            break;

          case SearchQueryKeywords.THEMATICS:
            thematicIds.push(_findByName(getState().thematics.items, searchValueWithoutQuotes));
            break;

          case SearchQueryKeywords.EVENT_CAMPAIGN_OPTIN:
          case SearchQueryKeywords.ACCOUNT_CAMPAIGN_OPTIN:
          case SearchQueryKeywords.CAMPAIGN_OPTIN:
            dispatch(updateSelectedOptin(searchField, searchValueWithoutQuotes[0]));
            break;

          case SearchQueryKeywords.INTEREST_IN: {
            const quartiles = term.searchField.replace(SearchQueryKeywords.INTEREST_IN, "").split("_").filter(n => n);
            const thematicsIds = _findByName(getState().thematics.items, searchValueWithoutQuotes);
            if (quartiles.length > 0 && thematicsIds.length > 0) {
              QuartilesIdsInGuestsThematicsQuartiles.push(quartiles);
              ThematicsIdsInGuestsThematicsQuartiles.push(thematicsIds);
            }
            break;
          }

          case SearchQueryKeywords.GUEST_STATUS:
          case SearchQueryKeywords.SSO_STATUS:
          case SearchQueryKeywords.PAYMENT_STATUS:
          case SearchQueryKeywords.SHOWED_UP_STATUS:
          case SearchQueryKeywords.ENGAGEMENT_LEVELS:
          case SearchQueryKeywords.SORT:
            dispatch(_fillStateFromQueryFilter(searchValueWithoutQuotes, filterKeywordAction[searchField]));
            break;
          default:
            break;
          }
          return;
        }

        // check if it's a registration date
        if (searchField == SearchQueryKeywords.REGISTERED_AFTER) {
          registeredAtAfter = searchValue;
          return;
        }

        if (searchField == SearchQueryKeywords.REGISTERED_BEFORE) {
          registeredAtBefore = searchValue;
          return;
        }

        // check if it's a guest_field
        if (includes(Fields, searchField)) {
          simpleSearchQuery = simpleSearchQuery + " " + (searchField + ":" + searchValue);
          return;
        }

        if (emailActivityFilters.includes(searchField)) {
          dispatch(updateSelectedEmailActivity(searchField, searchValue));
        }

        // Check if it's a no:something or additional standard guest fields
        const ADDITIONAL_STANDARD_COMPOSED_TERMS = [
          SearchQueryKeywords.NO,
          SearchQueryKeywords.PROMO_CODE,
          SearchQueryKeywords.ATTENDED_DATE,
          SearchQueryKeywords.ARRIVAL_DATE,
          SearchQueryKeywords.UTM_SOURCE,
          SearchQueryKeywords.UTM_MEDIUM,
          SearchQueryKeywords.UTM_CAMPAIGN,
          SearchQueryKeywords.COUNTRY,
          SearchQueryKeywords.COUNTRY_NAME,
          SearchQueryKeywords.ORDER_UID,
          SearchQueryKeywords.LOCALE,
          SearchQueryKeywords.POPULATION,
          SearchQueryKeywords.SSO_STATUS,
          SearchQueryKeywords.PRESENT_AT,
          SearchQueryKeywords.ATTENDANCE_TYPE,
          SearchQueryKeywords.INCLUDE_TAKEOUT
        ];

        if (ADDITIONAL_STANDARD_COMPOSED_TERMS.includes(searchField)) {
          simpleSearchQuery = `${simpleSearchQuery} ${searchField}:${searchValue}`;
          return;
        }

        // Check if it's an exclusion (-field)
        if (searchField[0] === "-") {
          simpleSearchQuery = `${simpleSearchQuery} ${searchField}:${searchValue}`;
        }
      });

      dispatch(_fillStateFromQueryFilter(flattenDeep(expectedAtAccesspointIds), filterKeywordAction[SearchQueryKeywords.EXPECTED_AT]));
      dispatch(_fillStateFromQueryFilter(flattenDeep(checkedInAtAccespointIds), filterKeywordAction[SearchQueryKeywords.CHECKED_IN_AT]));
      dispatch(_fillStateFromQueryFilter(flattenDeep(acceptedAtAccespointIds), filterKeywordAction[SearchQueryKeywords.ACCEPTED_AT]));
      dispatch(_fillStateFromQueryFilter(flattenDeep(rejectedAtAccespointIds), filterKeywordAction[SearchQueryKeywords.REJECTED_AT]));
      dispatch(_fillStateFromQueryFilter(flattenDeep(thematicIds), filterKeywordAction[SearchQueryKeywords.THEMATICS]));
      dispatch(_fillStateFromQueryFilter(flattenDeep(ThematicsIdsInGuestsThematicsQuartiles), filterKeywordAction[SearchQueryKeywords.INTEREST_IN]));
      dispatch(_fillStateFromQueryFilter(flattenDeep(QuartilesIdsInGuestsThematicsQuartiles), ActionTypes.SET_IN_GUESTS_THEMATICS_QUARTILES_SELECTED_QUARTILES_IDS));
      dispatch(updateRegisteredAtDates(registeredAtBefore, registeredAtAfter));
      dispatch(_setSimpleSearchQuery(simpleSearchQuery));
      dispatch(stopHydratingState(page));
    };
  },

  setSimpleSearchQuery(value) {
    return _setSimpleSearchQuery(value);
  }
};
