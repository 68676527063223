import { Component } from "react";
import { FormGroup, FormControl } from "react-bootstrap";

class SearchInput extends Component {

  constructor(props) {
    super(props);
    const methods = [
      "onChange"
    ];
    methods.forEach((item) => {
      this[item] = this[item].bind(this);
    });

    this.fetchEventTimeout = null;
    this.state = { searchValue: "" };
  }

  onChange(e) {
    const { fetchEvents } = this.props;
    this.clearTimeout();
    this.setState({ searchValue: e.target.value }, () => {
      this.fetchEventTimeout = setTimeout(() => {
        fetchEvents({ page: 1, perPage: 100, published: null, accountId: null, search: e.target.value });
      }, 600);
    });
  }

  clearTimeout() {
    if (this.fetchEventTimeout) {
      clearTimeout(this.fetchEventTimeout);
    }
  }

  render() {
    const { searchValue } = this.state;
    return (
      <FormGroup>
        <FormControl type="text" placeholder={I18n.t("react.reports.search")} value={searchValue} onChange={this.onChange} />
      </FormGroup>
    );
  }
}

export default SearchInput;

