import { Component } from "react";
import { connect } from "react-redux";

import { requestEvent } from "../actions/ImportActionCreators";
import { importGuestCategories } from "../actions/ImportGuestCategoryActionCreators";
import { updateEvent } from "../actions/EventActionCreators";

import FilterDropdown from "../components/FilterDropdown.react";
import ErrorMessage from "../components/shared/ErrorMessage.react";
import NothingYet from "../components/shared/NothingYet.react";
import Loader from "../components/shared/Loader.react";
import ConfirmableButton from "../components/shared/ConfirmableButton.react";
import { isEnabled } from "../utils/featureSetUtils";
import { WELCOME_SMS } from "../constants/FeaturesSet";

const MAX_NB_CHAR = 160;

class SmsOnCheckin extends Component {
  constructor() {
    super();
    [
      "onSelectedCategoriesChange",
      "handleTemplateChange",
      "submit",
      "toggleFeature",
      "isSaved"
    ].forEach((item) => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      selectedCategoryIds: [],
      template: I18n.t("react.sms_on_checkin.default_text") + " {{ guest.first_name }} {{ guest.last_name }} !"
    };
  }

  componentDidMount() {
    const { importGuestCategories, requestEvent } = this.props;
    requestEvent();
    importGuestCategories();
  }

  componentDidUpdate(prevProps) {
    const { event } = this.props;
    if (prevProps.event !== event) {
      const { template } = this.state;
      this.setState({
        selectedCategoryIds: event.sms_on_checkin_guest_category_ids,
        template: event.sms_on_checkin_body_template || template
      });
    }
  }

  isSaved() {
    const { template, selectedCategoryIds } = this.state;
    const { sms_on_checkin_body_template, sms_on_checkin_guest_category_ids } = this.props.event;
    return template == sms_on_checkin_body_template && selectedCategoryIds == sms_on_checkin_guest_category_ids;
  }

  handleTemplateChange(e) {
    if (e.target.value.length > MAX_NB_CHAR) {
      return;
    }
    this.setState({
      template: e.target.value
    });
  }

  onSelectedCategoriesChange(selectedCategoryIds) {
    this.setState({
      selectedCategoryIds
    });
  }

  toggleFeature() {
    const { updateEvent, event } = this.props;
    const params = { sms_on_checkin_enabled: !event.sms_on_checkin_enabled };
    updateEvent(event.id, params);
  }

  submit() {
    const { event, updateEvent } = this.props;
    const { selectedCategoryIds, template } = this.state;

    const params = {
      sms_on_checkin_guest_category_ids: selectedCategoryIds,
      sms_on_checkin_body_template: template
    };

    updateEvent(event.id, params);
  }

  renderGuestCategoryPicker() {
    const { guestCategories } = this.props;
    const { selectedCategoryIds } = this.state;
    const selectedCategories = guestCategories.reduce((acc, category) => {
      if (selectedCategoryIds.includes(category.id)) {
        acc.push(
          <span key={category.id} className="badge rounded-pill" style={{ backgroundColor: category.label_color, marginRight: "5px" }}>
            { category.name }
          </span>
        );
      }
      return acc;
    }, []);

    return (
      <div>
        <div style={{ display: "inline-block" }}>
          <FilterDropdown
            id="guest_category_picker"
            items={guestCategories}
            multipleSelect={true}
            hasSelectAll={true}
            itemColorKey="label_color"
            onChange={this.onSelectedCategoriesChange}
            showCells={false}
            translationKey="guest_category_picker"
            selectedItemIds={selectedCategoryIds}
            title={I18n.t("react.sms_on_checkin.pick_categories")}
          />
        </div>
        <p className="mt-5">
          { selectedCategories }
        </p>
      </div>
    );
  }

  renderTextArea() {
    const { template } = this.state;
    let classes = "float-end";
    classes = template.length < MAX_NB_CHAR ? classes : `${classes} text-danger`;
    return (
      <div>
        <textarea value={template} onChange={this.handleTemplateChange} className="form-control" rows="4" style={{ resize: "none" }} />
        <p>
          <a target="_blank" href={process.env.APP_DOCUMENTATION_HOST}>{I18n.t("react.sms_on_checkin.see_liquid_doc")}</a>
          <strong className={classes}>{ template.length } / { MAX_NB_CHAR }</strong>
        </p>
      </div>
    );
  }

  renderEnableButton() {
    return (
      <NothingYet
        title={I18n.t("react.sms_on_checkin.enable")}
        buttonTitle={I18n.t("react.sms_on_checkin.enable_button")}
        action={this.toggleFeature}
      />
    );
  }

  renderAskAdminToEnableButton() {
    return (
      <NothingYet
        title={I18n.t("react.sms_on_checkin.request_activation")}
        buttonTitle={I18n.t("react.sms_on_checkin.request_activation_button")}
        href="mailto:ecrire@eventmaker.io"
      />
    );
  }

  renderFeatureDisabled() {
    return this.renderEnableButton();
  }

  render() {
    const { event } = this.props;

    if (!isEnabled(WELCOME_SMS)) return null;

    if (!event.id) return <Loader />;

    if (!event.sms_on_checkin_enabled) return this.renderFeatureDisabled();

    return (
      <div>
        <div className="card mb-10 mt-10">
          <div className="card-header">
            <h4 className="card-title">{ I18n.t("react.sms_on_checkin.title") }</h4>
          </div>
          <div className="card-body">
            <ErrorMessage errors={event.errors}/>

            <div className="d-flex justify-content-between">
              { this.renderGuestCategoryPicker() }
              <ConfirmableButton
                title={I18n.t("react.sms_on_checkin.disable")}
                faIcon=""
                btnType="danger"
                onConfirm={this.toggleFeature}
                tooltipMessage={I18n.t("react.sms_on_checkin.disable_tooltip")}
              />
            </div>
            { this.renderTextArea() }
          </div>
        </div>
        {this.isSaved() ? null : (
          <button className="btn btn-primary" onClick={this.submit}>{I18n.t("react.sms_on_checkin.submit")}</button>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    event: state.event,
    guestCategories: state.guestCategories.data
  };
}

function mapDispatchToProps() {
  return {
    importGuestCategories,
    requestEvent,
    updateEvent
  };
}

export default connect(mapStateToProps, mapDispatchToProps()) (SmsOnCheckin);
