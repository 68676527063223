import { ActionTypes } from "../constants/Constants";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";

const defaultState = {
  data: [],
  errors: {},
  fetched: false,
  fetching: false,
  nextURL: null,
  previousURL: null,
};

function guestCategories(state = defaultState, action) {
  switch (action.type) {
  case ActionTypes.FETCH_GUEST_CATEGORIES:
    return { ...state, data: [], fetched: false, fetching: true };
  case ActionTypes.RECEIVE_GUEST_CATEGORIES:
    return { ...state, data: action.data, fetched: true, fetching: false, nextURL: action.nextURL, previousURL: action.previousURL };
  case ActionTypes.GUEST_CATEGORY_UPDATE_SUCCESS:
  case ActionTypes.GUEST_CATEGORY_CREATE_SUCCESS: {
    const dataModified = { ...action.data, id: action.data._id, errors: {} };
    const data = insertOrUpdateObject(state.data, dataModified, "_id");
    return { ...state, data, errors: {} };
  }
  case ActionTypes.GUEST_CATEGORY_CREATE_FAILURE: {
    return { ...state, errors: action.data };
  }
  case ActionTypes.GUEST_CATEGORY_UPDATE_FAILURE: {
    const category = state.data.find(c => c._id === action.id);
    if (category) {
      return {
        ...state,
        data: insertOrUpdateObject(state.data, { ...category, errors: action.data, pending: false }, "_id")
      };
    }

    return Object.assign({}, state, { errors: action.data });
  }
  default:
    return state;
  }
}

export default guestCategories;
