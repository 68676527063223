import { Component } from "react";
import PropTypes from "prop-types";
import truncate from "lodash/truncate";
import FrontendLocales from "../../../config/frontend_locales.yml";

import { isURL } from "../../utils/StringUtils" ;

const undefinedValue = " - ";
const NoLinkFields = ["uid", "registered_at", "updated_at", "created_at", "rank", "invitations_quota", "products_quota", "invitations_quota_mapping", "collections_quota"];

class TextCell extends Component {

  constructor(props) {
    super(props);
    this.updateQuery = this.updateQuery.bind(this);
  }

  valueToDisplay(guest, field) {
    if (!guest) return [null, null];

    let value = guest[field];
    if (value !== undefined && value !== null) { // false value must be returned as false
      return [value, "field"];
    }

    const metadata = (guest.guest_metadata || []).find(metadata => metadata.name === field);
    if (metadata) {
      return [metadata.value, "field"];
    }

    const file = (guest.files || []).find(file => file.filetype === field);
    if (file) {
      return [file.url, "file"];
    }
    return [null, null];
  }

  updateQuery(value) {
    const { field, updateSearchBar, allowMultipleValues } = this.props;
    const finalValue = value && allowMultipleValues ? value.replace(/;/g, ",") : value;
    return () => {
      updateSearchBar(`${field}:"${finalValue}"`);
    };
  }

  labelClass() {
    const { guest, field } = this.props;

    if (field !== "status") return "bg-secondary";

    switch (guest.status) {
    case "registered":
      return "bg-success";
    case "pending_registration":
      return "bg-warning";
    case "rejected":
    case "declined":
    case "cancelled":
      return "bg-danger";
    default:
      return "bg-secondary";
    }
  }

  render() {
    const { guest, field, directValue, options } = this.props;
    let value = null;
    let src = null;

    if (directValue) {
      value = directValue;
    } else {
      [value, src] = this.valueToDisplay(guest, field);
    }

    if (!value && options.emptyIndicator) {
      value = undefinedValue;
    }

    let valueToDisplay = options.translateFn && value ? options.translateFn(value) : value;
    valueToDisplay = truncate(valueToDisplay, options.truncateLength);

    if (options.labeled) {
      valueToDisplay = <span className={`badge rounded-pill ${this.labelClass()}`}>{valueToDisplay}</span>;
    } else if (field == "locale") {
      valueToDisplay = FrontendLocales.find(locale => locale.code == value).flag;
    }

    if (isURL(value)) {
      let label = valueToDisplay;
      if (src === "file") {
        label = I18n.t("react.guest_field.type.file");
      }
      valueToDisplay = <a href={value} target="_blank">{ label }</a>;
    } else if (!NoLinkFields.includes(field)) {
      valueToDisplay = <a onClick={this.updateQuery(value)} style={{ cursor: "pointer" }} className={ options.labeled ? "label-link" : null } > {valueToDisplay} </a>;
    }

    return (
      <td title={options.title} className="cell-default d-none d-sm-table-cell">
        { valueToDisplay }
      </td>
    );
  }
}

TextCell.defaultProps = {
  options: {
    truncateLength: 24,
    labeled: false,
    translateFn: null,
    title: null,
    emptyIndicator: false
  }
};

TextCell.propTypes = {
  guest: PropTypes.object,
  field: PropTypes.string,
  directValue: PropTypes.string,
  options: PropTypes.object,
  thematics_quartile: PropTypes.number
};

export default TextCell;
