import { ChangeEvent } from "react";
import GuestProductCollectionFieldShow from "./GuestProductCollectionFieldShow.react";
import GuestProductCollectionFieldEdit from "./GuestProductCollectionFieldEdit.react";
import { GuestProductCollectionField } from "../../../types/GuestProductCollectionField";
import { GuestProductCollectionFieldPossibleValues } from "../../../types/GuestProductCollectionFieldPossibleValues";
import { DEFAULT_GUEST_PRODUCT_COLLECTION } from "../GuestProductCollectionsList.react";
import { useSelector } from "react-redux";

interface Props {
  guestProductCollectionFields: GuestProductCollectionField[];
  focusedFieldIndex: number;
  defaultCollection: boolean;

  onFieldsChange(newGuestProductCollectionFields: GuestProductCollectionField[]): void;
  onFieldFocus(index: number): void;
}

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`events.edit_advanced.guest_product_collections.${key}`, options);
};

const GuestProductCollectionFieldsForm: React.FC<Props> = ({
  guestProductCollectionFields,
  focusedFieldIndex,
  defaultCollection,
  onFieldsChange,
  onFieldFocus
}) => {

  const onFieldParamsChange = (e: ChangeEvent<HTMLInputElement>, guestProductCollectionField: GuestProductCollectionField, index: number): void => {
    const { target } = e;

    if (index === focusedFieldIndex) {
      if (target.type === "text") guestProductCollectionField.name = target.value;
      if (target.type === "checkbox") guestProductCollectionField.is_required = target.checked;
    }

    onFieldsChange(guestProductCollectionFields);
  };

  const onFieldPossibleValuesChange = (guestProductCollectionField: GuestProductCollectionField, new_possible_values: GuestProductCollectionFieldPossibleValues[], index: number): void => {
    if (index === focusedFieldIndex) {
      guestProductCollectionField.possible_values = new_possible_values;
    }
    onFieldsChange(guestProductCollectionFields);
  };

  const hasThematics = useSelector((state: any) => state.event.has_thematics);

  const defaultCollectionFields = (): any[] => {
    const fields = DEFAULT_GUEST_PRODUCT_COLLECTION.collection_fields;
    const allFields = hasThematics
      ? fields
      : fields.filter(field => field.name !== i18n("default_fields.thematics"));

    return defaultCollection ? allFields : allFields.filter(field => field.isDefault);
  };

  return <>
    {defaultCollectionFields().map((defaultGuestProductCollectionField, index) => {
      return <GuestProductCollectionFieldShow
        key={index}
        isDefaultField={true}
        guestProductCollectionField={defaultGuestProductCollectionField}
      />;
    })}

    { !defaultCollection && guestProductCollectionFields?.map((guestProductCollectionField, index) => {
      return <div key={index} id={`guest_product_collection_field_${index}`} onClick={(): void => onFieldFocus(index)}>
        { index === focusedFieldIndex ?
          <GuestProductCollectionFieldEdit
            fieldIndex={focusedFieldIndex}
            guestProductCollectionField={guestProductCollectionField}
            onFieldParamsChange={onFieldParamsChange}
            onFieldPossibleValuesChange={onFieldPossibleValuesChange} />
          : <GuestProductCollectionFieldShow
            guestProductCollectionField={guestProductCollectionField}
          /> }
      </div>;
    })}
  </>;
};

export default GuestProductCollectionFieldsForm;
