import { Component } from "react";
import { connect } from "react-redux";
import "moment/locale/fr";
import { withRouter } from "react-router-dom";

import ReportHeader from "../../components/event_reports/ReportHeader.react";
import Loader from "../../components/shared/Loader.react";
import FilterDropdown from "../../components/FilterDropdown.react";
import { urlWithQuery, pathToAccesspointEdit } from "../../utils/pathUtils";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { fetchAccesspointsStatistics } from "../../actions/AccesspointsStatisticsActionCreators";
import { createReportConfiguration } from "../../actions/ReportConfigurationsActionCreators";
import { fetchAccesspoints } from "../../actions/AccesspointActionCreators";
import requiresProps from "../../utils/requiresProps";
import { redirectIfUnauthorized, isAuthorized } from "../../utils/aclUtils";
import { isEnabled } from "../../utils/featureSetUtils";
import { CHECKIN } from "../../constants/FeaturesSet";
import querystring from "querystring";
import SubmitInputButton from "../../components/shared/SubmitInputButton.react";

const URL_SAVABLE_STATES = ["includeTakeoutGuests", "selectedItemIds"]; //these states values will be saved in the URL
const DEFAULT_COLUMNS = ["accesspoint", "nb_reserved", "unique_visitors", "refused_visitors"];

class AttendanceByAccesspoint extends Component {

  constructor(props) {
    redirectIfUnauthorized("reports", "read");
    super(props);
    [
      "createReportConfiguration",
      "mapAccesspoints",
      "onChangeSelectedAccesspoints",
      "onChangeIncludeTakeout",
      "renderHeader",
      "renderTableHeader",
      "renderTable",
      "renderBody",
      "saveAnotherReportConfiguration",
    ].forEach(fn => {
      this[fn] = this[fn].bind(this);
    });

    const query = querystring.parse(location.search.substring(1));
    this.state = {
      statsField: query.field || query.statsField,
      selectedItemIds: query.selectedItemIds && query.selectedItemIds.split(",") || [],
      includeTakeoutGuests: query.includeTakeoutGuests || false,
      query: query.query,
      columns: query.columns ? query.columns.split(",") : DEFAULT_COLUMNS,

      reportConfigurationSaved: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { accesspoints, reportConfigurations } = this.props;
    const { selectedItemIds } = this.state;
    if (selectedItemIds.length == 0 && prevProps.accesspoints.length != accesspoints.length) {
      this.setState({ selectedItemIds: accesspoints.map(acc => acc.id) });
    }

    if (prevProps.reportConfigurations.data.length < reportConfigurations.data.length) {
      this.setState({ reportConfigurationSaved: true });
    }
  }

  saveAnotherReportConfiguration(e) {
    e.preventDefault();
    this.setState({
      reportConfigurationSaved: false
    });
  }

  nbReservations(accesspoint) {
    const { includeTakeoutGuests } = this.state;

    return includeTakeoutGuests ? accesspoint.nb_reserved_including_takeout_guests : accesspoint.nb_reserved;
  }

  includeTakeoutForQS() {
    const { includeTakeoutGuests } = this.state;

    return includeTakeoutGuests ? "include_takeout" : "";
  }

  mapAccesspoints(fn, filter = false) {
    const { accesspoints, greenStats, redStats } = this.props;
    const { selectedItemIds } = this.state;
    let total = { nb_reserved: 0, unique_visitors: 0, refused_visitors: 0, transformation_rate: 0.00 };
    const data = accesspoints.map(accesspoint => {
      if (filter && !selectedItemIds.includes(accesspoint.id)) return null;
      let nbAcceptedPeople = 0;
      const greenAccessStats = greenStats.find(acc => {
        return acc._id == accesspoint.id;
      });
      if (greenAccessStats) nbAcceptedPeople = greenAccessStats.count;

      let nbRefusedPeople = 0;
      const redAccessStats = redStats.find(acc => {
        return acc._id == accesspoint.id;
      });
      if (redAccessStats) nbRefusedPeople = redAccessStats.count;

      total["nb_reserved"] += this.nbReservations(accesspoint);
      total["unique_visitors"] += nbAcceptedPeople;
      total["refused_visitors"] += nbRefusedPeople;

      if (fn) return fn(accesspoint, nbAcceptedPeople, nbRefusedPeople);
    }).filter(stat => stat);

    return { data, total };
  }

  renderHeader() {
    const { accesspointsFetched, greenStats, redStats } = this.props;
    const { includeTakeoutGuests } = this.state;
    const title = I18n.t("react.event_reports.event_reports.attendance_by_accesspoint");
    let exporterToExcelProps = {};

    if (accesspointsFetched && greenStats && redStats) {
      exporterToExcelProps["data"] = this.mapAccesspoints(
        (accesspoint, nbAcceptedPeople, nbRefusedPeople) => {
          return {
            accesspoint: accesspoint.name,
            nb_reserved: this.nbReservations(accesspoint),
            unique_visitors: nbAcceptedPeople,
            refused_visitors: nbRefusedPeople
          };
        },
        true
      );

      exporterToExcelProps["columns"] = DEFAULT_COLUMNS;
    }

    return <>
      <ReportHeader
        title={title}
        exporterToExcelProps={exporterToExcelProps}
      />

      <div className="form-check">
        <label className="form-check-label">
          <input type="checkbox" className="form-check-input" onChange={this.onChangeIncludeTakeout} checked={(String(includeTakeoutGuests) === "true")} />
          {I18n.t("react.event_reports.report_table.include_takeout")}
        </label>
      </div>
    </>;
  }

  renderTable() {
    const { accesspointsFetched, greenStats, redStats } = this.props;
    if (!accesspointsFetched || !greenStats || !redStats) return <Loader />;

    return (
      <div className="table-responsive table-container mb-10">
        <table className="table table-light table-hover table-sm">
          <thead >
            { this.renderTableHeader() }
          </thead>
          <tbody>
            { this.renderBody() }
          </tbody>
        </table>
      </div>
    );
  }

  renderAccesspointsDropdown() {
    const { selectedItemIds } = this.state;
    const stats = this.mapAccesspoints(acc => {
      return {
        id: acc.id,
        name: acc.name
      };
    });

    return <span className="float-start">
      <FilterDropdown
        id="statistic_filter"
        items={stats.data}
        multipleSelect={true}
        hasSelectAll={true}
        onChange={this.onChangeSelectedAccesspoints}
        showCells={false}
        translationKey="statistic_filter"
        selectedItemIds={selectedItemIds}
        title={I18n.t("react.event_reports.event_reports.accesspoint")}
      />
    </span>;
  }

  renderTableHeader() {
    return (
      <tr>
        <th>{this.renderAccesspointsDropdown()}</th>
        <th>{ I18n.t("react.event_reports.report_table.nb_reserved") }</th>
        {
          isEnabled(CHECKIN) && <>
            <th>{ I18n.t("react.event_reports.report_table.unique_visitors") }</th>
            <th>{ I18n.t("react.event_reports.report_table.refused_visitors") }</th>
          </>
        }
      </tr>
    );
  }

  onChangeSelectedAccesspoints(selectedItemIds) {
    this.setState({ selectedItemIds });
    this.refreshURL({ selectedItemIds });
  }

  onChangeIncludeTakeout(e) {
    const { fetchAccesspoints, match } = this.props;

    this.setState({ includeTakeoutGuests: e.target.checked });
    this.refreshURL({ includeTakeoutGuests: e.target.checked });
    fetchAccesspoints(match.params.event_id, "", 0, { include: e.target.checked ? ["nb_reserved_including_takeout_guests"] : [] });
  }

  refreshURL(params) {
    const { location, history } = this.props;
    const query = querystring.parse(location.search.substring(1));
    const nextQuery = { ...query };
    URL_SAVABLE_STATES.forEach(key => {
      if (![null, undefined].includes(params[key])) {
        nextQuery[key] = params[key].toString();
      }
    });
    const nextLocation = { ...location };
    nextLocation.search = `?${querystring.stringify(nextQuery)}`;

    history.replace(nextLocation);
  }

  renderBody() {
    const stats = this.mapAccesspoints(
      (accesspoint, nbAcceptedPeople, nbRefusedPeople) => {
        const tooltip = <Tooltip id={`tooltip-bar-${accesspoint.id}`}>{ accesspoint.name }</Tooltip>;
        return (
          <tr key={ accesspoint.id }>
            <td>
              <OverlayTrigger placement="top" overlay={tooltip}>
                <a href={pathToAccesspointEdit(accesspoint.id)} className="badge rounded-pill bg-secondary accesspoint">
                  {accesspoint.name.substring(0, 150)}
                </a>
              </OverlayTrigger>
            </td>
            <td>
              <Link to={ urlWithQuery(`expected_at:"${accesspoint.name}" status:registered,pending_registration ${this.includeTakeoutForQS()}`, "guests") }>{this.nbReservations(accesspoint)}</Link>
            </td>
            {
              isEnabled(CHECKIN) && <>
                <td>
                  <Link to={ urlWithQuery(`accepted_at:"${accesspoint.name}" ${this.includeTakeoutForQS()}`, "guests") }>{nbAcceptedPeople}</Link>
                </td>
                <td>
                  <Link to={ urlWithQuery(`rejected_at:"${accesspoint.name}" ${this.includeTakeoutForQS()}`, "guests") }>{nbRefusedPeople}</Link>
                </td>
              </>
            }
          </tr>
        );
      },
      true
    );
    return stats.data;
  }

  createReportConfiguration(name) {
    const { statsField, columns, selectedItemIds, includeTakeoutGuests } = this.state;
    const { createReportConfiguration, event } = this.props;
    const params = {
      name,
      search_query: `selectedItemIds=${selectedItemIds}&includeTakeoutGuests=${includeTakeoutGuests}`,
      field: statsField,
      columns,
      overall: false,
      report_by_accesspoint: true,
    };
    createReportConfiguration(event.account_id, params);
  }

  renderSaveReportConfiguration() {
    const { reportConfigurations } = this.props;
    const { createPending, createErrors } = reportConfigurations;
    return (
      <div className="row">
        <div className="col-md-3 offset-md-9">
          {this.state.reportConfigurationSaved ? (
            <p className="text-end">
              <i className="fa-regular fa-check text-success"></i> {I18n.t("react.event_reports.attendance_by_field.report_saved")}<br />
              <a href="#" onClick={this.saveAnotherReportConfiguration}>({I18n.t("react.event_reports.attendance_by_field.save_another_report")})</a>
            </p>
          ) : (
            <SubmitInputButton
              className="btn btn-secondary float-end"
              submitInputButtonTitle={I18n.t("react.event_reports.attendance_by_field.create")}
              submitButtonClassName="btn btn-primary"
              inputPlaceholder={I18n.t("react.event_reports.attendance_by_field.report_name")}
              onSubmit={this.createReportConfiguration}
              loading={createPending}
              disabled={createPending}
              error={createErrors && createErrors.name}>
              <i className="fa-regular fa-plus"></i> {I18n.t("react.event_reports.attendance_by_field.save_report")}
            </SubmitInputButton>
          )}
        </div>
      </div>
    );
  }

  render() {
    if (!isAuthorized("reports", "read")) return;

    return (
      <div>
        { this.renderHeader() }
        { this.renderTable() }
        { this.renderSaveReportConfiguration() }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    accesspointsFetched: state.accesspoints.fetched,
    reportConfigurations: state.reportConfigurations,
    event: state.event,
  };
}

const mapDispatchToProps = {
  fetchAccesspoints,
  fetchAccesspointsStatistics,
  createReportConfiguration,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  requiresProps(withRouter(AttendanceByAccesspoint), {
    requirements: {
      accesspoints: {
        fn: ({ fetchAccesspoints, match }) => {
          fetchAccesspoints(match.params.event_id);
        },
        statePath: "accesspoints.data"
      },
      greenStats: {
        fn: ({ fetchAccesspointsStatistics, match }) => {
          fetchAccesspointsStatistics(match.params.event_id);
        },
        statePath: "accesspointsStats.data.greenAccessControls",
        desiredState: "present"
      },
      redStats: {
        waitFor: ["greenStats"],
        fn: ({ fetchAccesspointsStatistics, match }) => {
          fetchAccesspointsStatistics(match.params.event_id, "red");
        },
        statePath: "accesspointsStats.data.redAccessControls"
      }
    }
  })
);
