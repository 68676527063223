import { ActionTypes } from "../constants/Constants";

function notifications(state = { currentNotice: "", noticeType: "info", customDisplay: false }, action) {
  switch (action.type) {
  case ActionTypes.SHOW_NOTICE: {
    const message = action.data && action.data.message || action.message;
    const noticeType = action.data && action.data.notice_type || action.noticeType;

    if (state.currentNotice != message) {
      return Object.assign({}, state, { currentNotice: message, noticeType, customDisplay: !!action.customDisplay });
    } else {
      return state;
    }
  }
  default:
    return state;
  }
}

export default notifications;
