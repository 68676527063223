import { Component } from "react";
import PropTypes from "prop-types";


class TabRegistrationForm extends Component {
  constructor(props) {
    super(props);
    [
      "toggleFocus"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
  }

  toggleFocus() {
    const { canUnfocusItem, toggleStepFocus, step } = this.props;
    if (step.focused || !canUnfocusItem()) {
      return;
    }
    toggleStepFocus(step);
  }

  render() {
    const { currentStep, step } = this.props;
    const labelClass = step.focused ? "form-step-label-focused" : "";
    return (
      <div className="row">
        <div className={`col-md-12 form-step-label ${labelClass}`} style={{ cursor: "move" }} onClick={this.toggleFocus}>
          <i className="fa-solid fa-grip-dots-vertical mr-5"></i> {I18n.t("react.registration_form.step")} { currentStep }
        </div>
      </div>
    );
  }
}
export default TabRegistrationForm;


TabRegistrationForm.propTypes = {
  currentStep: PropTypes.number,
  step: PropTypes.object,
  canUnfocusItem: PropTypes.func.isRequired
};
