import { Component } from "react";
import PropTypes from "prop-types";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import RichTextEditor from "../shared/RichTextEditor.react";

class EditableFieldsForm extends Component {

  constructor(props) {
    super(props);
    [
      "renderEditableElements",
      "updateSimpleEditableField",
      "updateRTEEditableField",
      "updateImageEditableField",
      "deleteImage",
      "fieldHint",
      "fieldInput",
      "inputLabel",
      "renderFileField"
    ].forEach((item) => { this[item] = this[item].bind(this); });
    this.state = { deleteFileOption: {} };
  }

  updateSimpleEditableField(id) {
    return (e) => {
      const { updateEditableField } = this.props;
      updateEditableField({ _id: id, content: e.target.value });
    };
  }

  updateRTEEditableField(id) {
    return (html, draftjsContent) => {
      const { updateEditableField } = this.props;
      updateEditableField({ _id: id, content: html, draftjs_content: draftjsContent });
    };
  }

  updateImageEditableField(id) {
    return (e) => {
      const { updateImageEditableField } = this.props;
      let data = new FormData();
      data.append("guest_campaign[editable_elements_attributes][][_id]", id);
      data.append("guest_campaign[editable_elements_attributes][][file]", e.target.files[0]);
      updateImageEditableField(data, id);
    };
  }

  deleteImage(id) {
    return (e) => {
      const { deleteFile } = this.props;
      const { deleteFileOption } = this.state;
      const params = { _id: id };
      deleteFile({ editable_elements_attributes: params }, e);
      //necessary for rails forms
      this.setState({ deleteFileOption: { ...deleteFileOption, [id]: true } });
    };
  }

  fieldHint(element) {
    return element.hint ? (<div className="form-text">{element.hint}</div>) : null;
  }

  renderFileField(element, index) {
    const { name } = this.props;
    if (!element.file.url) {
      return (<div className=" custom-file row">
        <div className="input-group">
          <div className="form-control">{I18n.t("no_file_selected")}</div>
          <div className="input-group-text">{I18n.t("choose_file")}</div>
        </div>
        <input type="file" accept="image/*" name={`${name}[${index}][file]`} id={`${name}[${index}][file]`} onChange={this.updateImageEditableField(element._id)}/>
      </div>
      );
    }
    const input = (<div className="custom-file row">
      <div className="input-group">
        <div className="form-control">{I18n.t("no_file_selected")}</div>
        <div className="input-group-text">{I18n.t("choose_file")}</div>
      </div>
      <input type="file" accept="image/*" name={`${name}[${index}][file]`} id={`${name}[${index}][file]`} onChange={this.updateImageEditableField(element._id)}/>
    </div>);
    const img = (<img src={element.file.url} className="img-thumbnail mt-10 mb-10"></img>);
    const deleteImg = (<a href="#" className="btn btn-secondary btn-sm" onClick={this.deleteImage(element._id)}>{I18n.t("react.editable_fields_form.remove_img")}</a>);
    return (
      <div>
        <div>
          {input}{img}
        </div>
        <div>
          {deleteImg}
        </div>
      </div>
    );
  }

  fieldInput(element, index) {
    const { dropFields, name } = this.props;
    let value = element.default_value;
    let draftJsContent = null;
    if (element.content || element.content == "") {
      value = element.content;
      draftJsContent = element.draftjs_content;
    }
    switch (element.type) {
    case "text_field":
      return (<input className="form-control" type="text" defaultValue={value} onChange={this.updateSimpleEditableField(element._id)} name={`${name}[${index}][content]`}></input>);
    case "select_field":
      return (
        <select className="form-select" defaultValue={value} onChange={this.updateSimpleEditableField(element._id)} name={`${name}[${index}][content]`}>
          {
            element.options.map((option, i) => {
              return (
                <option value={ option["value"] } key={ `${i}-${option["value"]}}` }>
                  { option["label"] }
                </option>
              );
            })
          }
        </select>
      );
    case "file_field":
      return this.renderFileField(element, index);
    case "text_area":
      return (<textarea className="form-control" defaultValue={value} onBlur={this.updateSimpleEditableField(element._id)} name={`${name}[${index}][content]`}></textarea>);
    case "cktext_area":
    case "rich_text_area":
      return <RichTextEditor baseKey={index.toString()}
        content={value}
        draftjsContent={draftJsContent}
        onBlur={this.updateRTEEditableField(element._id)}
        dropFields={dropFields}
        name={`${name}[${index}][content]`}
        context="backoffice"
        toolbarSize="small"
        textGeneratorPrePromptKey="organizer_content_context"
      />;
    default:
      return null;
    }
  }

  hiddenFieldInput(element, index) {
    const { name } = this.props;
    const { deleteFileOption } = this.state;
    let addtionalInfos = "";
    switch (element.type) {
    case "cktext_area":
    case "rich_text_area":
      addtionalInfos = (
        <div>
          <input type="hidden" value={element.content} name={`${name}[${index}][content]`} />
          <input type="hidden" value={JSON.stringify(element.draftjs_content)} name={`${name}[${index}][draftjs_content]`} />
        </div>
      );
      break;
    case "file_field":
      addtionalInfos = element.file.url ? null : <input type="hidden" value={deleteFileOption[element._id || false]} name={`${name}[${index}][remove_file]`} />;
      break;
    }
    return (
      <div>
        {addtionalInfos}
        <input type="hidden" value={element._id} name={`${name}[${index}][id]`} />
      </div>
    );
  }

  inputLabel(element) {
    return <label className="form-label">{element.slug}</label>;
  }

  renderEditableElements() {
    const { editableElements } = this.props;
    // Sort by priority then alphanumeric order
    editableElements.sort((a, b) => {
      const byPriority = parseInt(b.priority) - parseInt(a.priority);
      if (byPriority != 0) return byPriority;
      if (a.slug.toLowerCase() < b.slug.toLowerCase()) return -1;
      if (a.slug.toLowerCase() > b.slug.toLowerCase()) return 1;
      return 0;
    });
    const editableElementHtml = editableElements.map((element, index) => {
      return element.should_be_displayed ? (
        <div className="mb-3" key={element._id}>
          {this.inputLabel(element)}
          {this.fieldInput(element, index)}
          {this.hiddenFieldInput(element, index)}
          {this.fieldHint(element, index)}
        </div>
      ) : null;
    });
    return editableElementHtml;
  }

  render() {
    const { errors, editableElements } = this.props;

    if (editableElements.length == 0) return null;

    return (
      <>
        <ErrorMessage errors={errors}/>
        <div className="card mb-10">
          <div className="card-body">
            {this.renderEditableElements()}
          </div>
        </div>
      </>
    );
  }
}

EditableFieldsForm.propTypes = {
  editableElements: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      should_be_displayed: PropTypes.bool.isRequired,
      slug: PropTypes.string.isRequired,
      content: PropTypes.string,
      draftjs_content: PropTypes.object,
      default_value: PropTypes.string,
      file: PropTypes.shape({
        url: PropTypes.string
      })
    }))
};

EditableFieldsForm.defaultProps = {
  editableElements: []
};

export default EditableFieldsForm;
