import { Component } from "react";
import { connect } from "react-redux";
import { urlEventId } from "../utils/pathUtils";
import { populationI18n } from "../utils/miscUtils";
import { updateUI } from "../actions/UIAppearanceActionCreators";
import * as actions from "../actions/ThematicsActionCreators";
import { requestEvent } from "../actions/ImportActionCreators";
import ColoredOjectsTable from "../components/ColoredOjectsTable.react";
import ColoredObjectForm from "../components/ColoredObjectForm.react";
import Loader from "../components/shared/Loader.react";
import HelpSection from "../components/shared/HelpSection.react";
import FilterDropdown from "../components/FilterDropdown.react";
import { UI_COLORS } from "../constants/Constants";

class Thematics extends Component {
  constructor(props) {
    super(props);
    [
      "handleCreate",
      "handleDelete",
      "handleUpdate",
      "handleCancel",
      "handlePopulationChange"
    ].forEach((item) => { this[item] = this[item].bind(this); });

    this.state = {
      sortField: "name",
      sortDirection: "asc",
      selectedPopulation: null
    };
  }

  componentDidMount() {
    const { updateUI, requestEvent } = this.props;
    const { sortField, sortDirection } = this.state;
    const updates = { "hideNavBar": true };

    updateUI(updates);
    requestEvent();
    this.fetchSortedThematics(sortField, sortDirection);
  }

  componentDidUpdate(prevProps) {
    const { event } = this.props;
    // as soon as event is received, we display avg scores on first population found
    if (!prevProps.event.id && event.id && event.matchmaking_enabled && event.matchmaking_population_types.length > 0) {
      this.setState({ selectedPopulation: event.matchmaking_population_types[0] });
    }
  }

  fetchSortedThematics(sortField, sortDirection) {
    const { fetchThematics } = this.props;
    const { selectedPopulation } = this.state;
    fetchThematics(urlEventId(), { sort: sortField, direction: sortDirection, population: selectedPopulation });
  }

  changeSortHandler(sortField, sortDirection) {
    return (e) => {
      e.preventDefault();
      this.setState({ sortField, sortDirection });
      this.fetchSortedThematics(sortField, sortDirection);
    };
  }

  handleCreate(value, color, parentThematicId = null, temporaryId = null) {
    this.props.createThematic(urlEventId(), value, color, parentThematicId, temporaryId);
  }

  handleUpdate(thematic, newValue, newColor) {
    this.props.updateThematic(urlEventId(), thematic._id, newValue, newColor);
  }

  handleDelete(thematic) {
    this.props.deleteThematic(urlEventId(), thematic._id);
  }

  handleCancel() {
    const { errors, clearThematicErrors } = this.props;
    if (errors === undefined) return;

    clearThematicErrors();
  }

  handlePopulationChange(selectedPopulations) {
    const { sortField, sortDirection } = this.state;

    this.setState({ selectedPopulation: selectedPopulations && selectedPopulations[0] || null }, () => {
      // if list is sorted by average score, we need to reorder it when changing population
      if (sortField == "avg_score")
        this.fetchSortedThematics(sortField, sortDirection);
    });
  }

  renderForm() {
    const { errors, lastUpdatedItemId, fetching } = this.props;
    const errorMessage = errors && !lastUpdatedItemId && errors.name;

    return <ColoredObjectForm handleSave={this.handleCreate}
      errorMessage={errorMessage}
      i18nKey="thematics"
      fetching={fetching} />;
  }

  renderSortFilters() {
    const { thematics, event } = this.props;

    if (!thematics || thematics.length <= 1)
      return null;

    return [
      this.renderSortFilter("name"),
      event.id && event.matchmaking_enabled && event.matchmaking_population_types.length > 0 && this.renderSortFilter("avg_score")
    ];
  }

  renderSortFilter(field) {
    const { sortField, sortDirection } = this.state;

    return <a href="#"
      key={`sort-${field}`}
      className="ml-10"
      style={{ color: UI_COLORS.grey700, fontWeight: "bold", textDecoration: "none" }}
      onClick={this.changeSortHandler(field, sortDirection == "asc" ? "desc" : "asc")}>
      { sortField == field && <i style={{ fontWeight: "bold" }} className={`fa-regular fa-angle-${ sortDirection == "asc" ? "up" : "down" }`}></i> }
      { " " }
      { I18n.t(`react.thematics.${field}`) }
    </a>;
  }

  renderPopulationPicker() {
    const { event } = this.props;

    if (!event.id || !event.matchmaking_enabled || event.matchmaking_population_types.length <= 0)
      return null;

    const { selectedPopulation } = this.state;
    const translationKey = "population_picker_for_avg_score";
    const items = event.matchmaking_population_types.reduce((acc, population) => {
      acc.push({ id: population, name: populationI18n(population) });
      return acc;
    }, []);

    return <div className="ml-10">
      <FilterDropdown
        id={translationKey}
        translationKey={translationKey}
        items={items}
        selectedItemIds={[selectedPopulation]}
        showCells={false}
        hasSelectAll={false}
        multipleSelect={false}
        sortItems={false}
        itemIdKey="id"
        onChange={this.handlePopulationChange}
        title={selectedPopulation && I18n.t(`react.reports.${translationKey}.filter_title_for_selection`, { name: populationI18n(selectedPopulation) })}
      />
    </div>;
  }

  renderTable() {
    const { thematics, event, lastUpdatedItemId, errors } = this.props;
    const { selectedPopulation } = this.state;

    return <ColoredOjectsTable
      items={thematics}
      parentItemIdKey="parent_thematic_id"
      lastUpdatedItemId={lastUpdatedItemId}
      errors={errors}
      handleUpdate={this.handleUpdate}
      handleDelete={this.handleDelete}
      handleCreate={this.handleCreate}
      handleCancel={this.handleCancel}
      i18nKey="thematics"
      modelName="thematic"
      event={event}
      selectedPopulation={selectedPopulation}
    />;
  }

  noItems() {
    return <div className="card nothing-yet mt-10">
      <div><i className="fa-regular fa-tags fa-xl"></i></div>
      <h4>{I18n.t("react.thematics.no_items")}</h4>
    </div>;
  }

  render() {
    const { thematics } = this.props;

    const content = !thematics ? <Loader /> : (thematics.length > 0 ? this.renderTable() : this.noItems());

    return <div>
      <div className="header-page">
        <div className="header-page-title">
          <h1>{I18n.t("react.thematics.title")}</h1>
        </div>
        <HelpSection help={I18n.t("react.thematics.help")} />
      </div>

      <div className="row mb-10">
        <div className="col-sm-6 d-flex flex-column justify-content-center">
          {this.renderForm()}
        </div>

        <div className="col-sm-6 d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center justify-content-end">
            {this.renderPopulationPicker()}
            {this.renderSortFilters()}
          </div>
        </div>
      </div>
      {content}
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    appearance: state.appearance,
    thematics: state.thematics.items,
    errors: state.thematics.errors,
    lastUpdatedItemId: state.thematics.lastUpdatedThematicId,
    event: state.event,
    fetching: state.thematics.fetching
  };
}

const mapDispatchToProps = Object.assign({}, actions, { updateUI, requestEvent });
export default connect(mapStateToProps, mapDispatchToProps)(Thematics);
