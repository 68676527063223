import Modal from "react-modal";
import { Component } from "react";
import get from "lodash/get";
import { defaultModalStyles } from "../../constants/Style";

class PhoneNumberPrefixModal extends Component {
  constructor(props) {
    super(props);
    [
      "createPrefixGuestField",
      "cancelPhoneNumberValidation"
    ].forEach((fn) => {
      this[fn] = this[fn].bind(this);
    });

    this.state = {
      open: false
    };
  }

  componentDidUpdate(prevProps) {
    const hasPhoneNumberValidation = get(this.props, ["formItem", "options", "validations"], []).find((obj) => obj.key == "phone_number");
    const hadPhoneNumberValidation = get(prevProps, ["formItem", "options", "validations"], []).find((obj) => obj.key == "phone_number");
    if (!hadPhoneNumberValidation && hasPhoneNumberValidation) {
      this.setState({ open: true });
    }
  }

  createPrefixGuestField(e) {
    e.preventDefault();
    const { allGuestFields, createGuestField } = this.props;
    const prefixGuestField = allGuestFields.find(gf => gf.key === this.prefixFieldKey());
    if (!prefixGuestField) {
      createGuestField({ label: this.prefixFieldLabel(), key: this.prefixFieldKey(), type: "text" });
    }
    this.setState({ open: false });
  }

  cancelPhoneNumberValidation(e) {
    e.preventDefault();
    const { removeValidation } = this.props;
    removeValidation("phone_number");
    this.setState({ open: false });
  }

  prefixFieldKey() {
    const { formItem } = this.props;
    return `${formItem.key}_prefix`;
  }

  prefixFieldLabel() {
    const { formItem, allGuestFields } = this.props;
    const guestField = allGuestFields.find(gf => gf.key === formItem.key);
    return `${guestField.label} (${I18n.t("react.phone_number_validations.call_prefix")})`;
  }

  renderPrefixGuestFieldContent() {
    const { allGuestFields } = this.props;
    const prefixGuestField = allGuestFields.find(gf => gf.key === this.prefixFieldKey());

    if (prefixGuestField) {
      return I18n.t("react.phone_number_validations.prefix_field_exists_description", { label: prefixGuestField.label, key: prefixGuestField.key });
    } else {
      return I18n.t("react.phone_number_validations.prefix_field_create_description", { label: this.prefixFieldLabel(), key: this.prefixFieldKey() });
    }
  }

  renderModalContent() {
    const { open } = this.state;
    if (!open) {
      return;
    }

    return <div>
      <div className="modal-header">
        <h4 className="modal-title">{ I18n.t("react.phone_number_validations.modal_title") }</h4>
      </div>
      <div className="modal-body">
        <p>{ I18n.t("react.phone_number_validations.modal_description") }</p>
        <p dangerouslySetInnerHTML={{ __html: this.renderPrefixGuestFieldContent() }}></p>
      </div>
      <div className="modal-footer">
        <a href="#" onClick={ this.cancelPhoneNumberValidation } className="btn btn-secondary" > { I18n.t("react.phone_number_validations.cancel") } </a>
        <a href="#" onClick={ this.createPrefixGuestField } className="btn btn-primary" > { I18n.t("react.phone_number_validations.validate") } </a>
      </div>
    </div>;
  }

  render() {
    const { open } = this.state;

    return <Modal isOpen={open} onRequestClose={this.toggleModal} style={defaultModalStyles} contentLabel="Modal">
      { this.renderModalContent() }
    </Modal>;
  }
}

export default PhoneNumberPrefixModal;
