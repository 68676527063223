import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import pick from "lodash/pick";
import truncate from "lodash/truncate";

import EmailTemplateSelector from "../components/email_templates/EmailTemplateSelector.react";
import ErrorMessage from "../components/shared/ErrorMessage.react";
import Loader from "../components/shared/Loader.react";
import { updateGuestCategory, fetchCustomEmail, clearCustomEmail, addOfflineErrors } from "../actions/GuestCategoryActionCreators";
import { updateEmailSelectedId } from "../actions/EmailBuilderActionCreators";
import { pathToCustomEmailByType } from "../utils/pathUtils";
import SenderEmailAddressesField from "../components/SenderEmailAddressesField.react";

const EMAIL_TYPES_NOT_ALLOWING_LIQUID = ["invitation"];

class GuestCategoryEmailForm extends Component {
  constructor(props) {
    super(props);
    [
      "toggleAdvancedOptions",
      "onChangeTextField",
      "onSelectTemplate",
      "onSelectSenderEmail",
      "toggleOption",
      "submit"
    ].forEach(item => { this[item] = this[item].bind(this); });

    this.state = {
      isAdvancedOptionsOpen: false,
      custom: false,
      localCustomEmail: props.customEmailDefaultState,
      localGuestCategory: props.guestCategoryDefaultState
    };
  }

  componentDidMount() {
    const { fetchCustomEmail, guestCategory, emailType, event } = this.props;
    const { localCustomEmail } = this.state;

    this.setState({
      localGuestCategory: this.guestCategoryStateFromAPI(guestCategory),
      localCustomEmail: event ? Object.assign({}, localCustomEmail, { subject: this.subjectPlaceholder() }) : localCustomEmail
    });
    fetchCustomEmail(guestCategory.event_id, guestCategory._id, emailType);
  }

  componentWillUnmount() {
    const { clearCustomEmail } = this.props;
    clearCustomEmail();
  }

  componentDidUpdate(prevProps) {
    const { customEmail, errors, updateEmailSelectedId, selectedTemplateId } = this.props;
    const { localCustomEmail } = this.state;

    if (!prevProps.customEmail && customEmail && Object.keys(customEmail).length > 0) {
      this.setState({
        localCustomEmail: this.customEmailStateFromAPI(customEmail),
        custom: !customEmail.email_template_id && prevProps.guestCategory[prevProps.featureEnabledField],
        isAdvancedOptionsOpen: this.atLeastOneAdvancedOptionEnabled(customEmail)
      });
      updateEmailSelectedId(customEmail.email_template_id);
    }

    if (Object.keys(prevProps.errors).length == 0 && Object.keys(errors).length > 0) {
      if ($(".slide-pane__content").length > 0)
        $(".slide-pane__content").animate({ scrollTop: 0 }, 500);
    }

    if (selectedTemplateId != prevProps.selectedTemplateId) {
      this.setState({ localCustomEmail: Object.assign({}, localCustomEmail, { email_template_id: selectedTemplateId }) });
    }
  }

  customEmailStateFromAPI(customEmailFromAPI) {
    const { customEmailDefaultState } = this.props;
    const fieldsForState = Object.keys(customEmailDefaultState);

    return pick(customEmailFromAPI, fieldsForState);
  }

  guestCategoryStateFromAPI(guestCategoryFromAPI) {
    const { guestCategoryDefaultState } = this.props;
    const fieldsForState = Object.keys(guestCategoryDefaultState);

    return pick(guestCategoryFromAPI, fieldsForState);
  }

  atLeastOneAdvancedOptionEnabled(customEmail) {
    const { emailType, guestCategory } = this.props;

    return customEmail.attached_icalendar ||
           customEmail.attached_badge ||
           customEmail.attached_invoice ||
           (emailType == "confirmation" && guestCategory.update_confirmation_email_enabled);
  }

  toggleAdvancedOptions() {
    const { isAdvancedOptionsOpen } = this.state;
    this.setState({ isAdvancedOptionsOpen: !isAdvancedOptionsOpen });
  }

  onChangeTextField(e) {
    const { localCustomEmail } = this.state;
    this.setState({ localCustomEmail: Object.assign({}, localCustomEmail, { [e.target.name]: e.target.value }) });
  }

  onSelectTemplate(emailTemplateId) {
    const { localCustomEmail } = this.state;
    this.setState({ localCustomEmail: Object.assign({}, localCustomEmail, { email_template_id: emailTemplateId }) });
  }

  onSelectSenderEmail(senderEmail) {
    const { localCustomEmail } = this.state;
    this.setState({ localCustomEmail: { ...localCustomEmail, sender_email: senderEmail } });
  }

  toggleOption(e) {
    const { localCustomEmail, localGuestCategory } = this.state;
    const optionNewState = { [e.target.name]: e.target.checked };

    if (e.target.name == "custom") {
      this.setState(optionNewState);
    } else if (e.target.name == "update_confirmation_email_enabled") {
      this.setState({ localGuestCategory: Object.assign({}, localGuestCategory, optionNewState) });
    } else {
      this.setState({ localCustomEmail: Object.assign({}, localCustomEmail, optionNewState) });
    }
  }

  submit(e) {
    if (e) e.preventDefault();

    if (!this.isFormValid())
      return false;

    const { guestCategory, updateGuestCategory, featureEnabledField, emailType } = this.props;
    const { localCustomEmail, localGuestCategory, custom } = this.state;

    const customEmailAttributes = Object.assign({}, localCustomEmail, { type: emailType });
    const guestCategoryAttributes = Object.assign({}, localGuestCategory, { [featureEnabledField]: true, custom_email: customEmailAttributes });
    const redirectUrl = custom && pathToCustomEmailByType(guestCategory._id, emailType) || null;

    updateGuestCategory(guestCategory.event_id, guestCategory._id, guestCategoryAttributes, redirectUrl);
  }

  isFormValid() {
    const { localCustomEmail, custom } = this.state;
    const { addOfflineErrors } = this.props;
    let isValid = true;

    if (!custom && !localCustomEmail.email_template_id) {
      addOfflineErrors({ email_template_id: this.i18n("choose_email_template_error") });
      isValid = false;
    }

    if (localCustomEmail.subject == "") {
      addOfflineErrors({ subject: this.i18n("subject_blank_error") });
      isValid = false;
    }

    return isValid;
  }

  i18n(key) {
    return I18n.t(`shared.custom_email_fields.${key}`);
  }

  subjectPlaceholder() {
    const { event, emailType } = this.props;
    return `${I18n.t(`react.guest_category.${emailType}_email.subject`)} - ${event.title}`;
  }

  senderPlaceholder() {
    const { event } = this.props;
    return event.organizer;
  }

  replyToEmailPlaceholder() {
    const { event } = this.props;
    return event.reply_to_email;
  }

  submitLabel() {
    const { updatePending } = this.props;
    return <span>{ updatePending && <Loader size="small" color="white" /> } { I18n.t("save") }</span>;
  }

  renderSlidingPaneTitle() {
    const { guestCategory, emailType } = this.props;

    return (
      <div className="d-flex">
        <span className="d-flex align-items-center mr-5">
          { I18n.t(`react.guest_category.${emailType}_email.sliding_pane_email_title`) } { " " }
        </span>
        <span className="badge rounded-pill big guest-category" style={{ backgroundColor: guestCategory.label_color }}>
          {truncate(guestCategory.name, { "length": 45 })}
        </span>
      </div>
    );
  }

  render() {
    const { isAdvancedOptionsOpen, localGuestCategory, localCustomEmail, custom } = this.state;
    const { guestCategory, emailType, customEmail, updatePending, errors, event, match, location, history } = this.props;
    const senderEmailAddress = localCustomEmail.sender_email ? localCustomEmail.sender_email : event.sender_email_address;
    const allSenderEmailAddresses = event.all_sender_email_addresses || [];

    if (!customEmail)
      return <Loader inline={false} size="large" />;

    return <form onSubmit={this.submit} className="sliding-pane-form">
      <div className="form-container card mb-0">
        <fieldset>
          <ErrorMessage errors={errors} noLabelKeys={["email_template_id"]} model="custom_email" />
          <div className="mb-3">
            <label className="form-label">{ this.i18n("subject") }</label>
            <input className="form-control" type="text" name="subject" value={localCustomEmail.subject} onChange={this.onChangeTextField} placeholder={this.subjectPlaceholder()} />
          </div>
          <div className="mb-3">
            <label className="form-label">{ this.i18n("sender") }</label>
            <input className="form-control" type="text" name="sender" value={localCustomEmail.sender} onChange={this.onChangeTextField} placeholder={this.senderPlaceholder()} />
          </div>

          <EmailTemplateSelector
            slidingPaneTitle={this.renderSlidingPaneTitle()}
            selectedEmailTemplateId={localCustomEmail.email_template_id}
            persistedEmailTemplateId={customEmail.email_template_id}
            onSelectTemplate={this.onSelectTemplate}
            afterEmailTemplatesClose={this.submit}
            event={event}
            enableDelete={false}
            match={match}
            location={location}
            history={history} />

          <div>
            <div onClick={this.toggleAdvancedOptions} className="advanced-options-toggle d-flex justify-content-between">
              <label className="form-label mb-0">{ this.i18n("advanced_options") }</label>
              <div className="d-flex flex-column justify-content-center">
                <i className={`fa-regular fa-chevron-${isAdvancedOptionsOpen ? "up" : "down"} float-end`} />
              </div>
            </div>
            <div style={{ display: isAdvancedOptionsOpen ? "block" : "none" }}>
              <div className="mb-3 mt-3">
                <div className="form-check">
                  <label className="form-check-label">
                    <input type="checkbox" className="form-check-input" checked={localCustomEmail.attached_icalendar} name="attached_icalendar" onChange={this.toggleOption}/>
                    { this.i18n("attach_icalendar") }
                  </label>
                </div>
                { guestCategory.badge_enabled && emailType == "confirmation" &&
                  <div className="form-check">
                    <label className="form-check-label">
                      <input type="checkbox" className="form-check-input" checked={localCustomEmail.attached_badge} name="attached_badge" onChange={this.toggleOption}/>
                      { this.i18n("attach_badge") }
                    </label>
                  </div>
                }
                { guestCategory.payment_enabled && guestCategory.zoho_enabled &&
                  <div className="form-check">
                    <label className="form-check-label">
                      <input type="checkbox" className="form-check-input" checked={localCustomEmail.attached_invoice} name="attached_invoice" onChange={this.toggleOption} disabled={!localCustomEmail.attached_invoice}/>
                      { this.i18n("attach_invoice") }
                      {!localCustomEmail.attached_invoice && `' '${this.i18n("attach_invoice_feature_disabled")}` }
                    </label>
                  </div>
                }
                { emailType == "confirmation" &&
                  <div className="form-check">
                    <label className="form-check-label">
                      <input type="checkbox" className="form-check-input" checked={localGuestCategory.update_confirmation_email_enabled} name="update_confirmation_email_enabled" onChange={this.toggleOption}/>
                      { I18n.t("guest_categories.form_email.update_confirmation") }
                    </label>
                  </div>
                }
                { window.ReactGlobalProps.super_admin && !EMAIL_TYPES_NOT_ALLOWING_LIQUID.includes(emailType) &&
                  <div className="form-check">
                    <label className="form-check-label">
                      <input type="checkbox" className="form-check-input" checked={custom} name="custom" onChange={this.toggleOption}/>
                      { this.i18n("liquid_email") } <span className="text-warning">(admin only)</span>
                    </label>
                  </div>
                }
              </div>
              <div className="mb-3">
                <label className="form-label">{ this.i18n("reply_to_email") }</label>
                <input className="form-control" type="email" name="reply_to_email" value={localCustomEmail.reply_to_email} onChange={this.onChangeTextField} placeholder={this.replyToEmailPlaceholder()} />
              </div>
              <SenderEmailAddressesField
                customEmailSenderEmail={senderEmailAddress}
                allSenderEmailAddresses={allSenderEmailAddresses || []}
                label={this.i18n("sender_email")}
                name="sender_email"
                onSelectSenderEmail={this.onSelectSenderEmail} />
            </div>
          </div>
        </fieldset>
      </div>

      <div className="text-end">
        <button type="submit" className="btn btn-primary mt-10" disabled={updatePending}>
          { this.submitLabel() }
        </button>
      </div>
    </form>;
  }
}

function mapStateToProps(state) {
  return {
    guestCategory: state.guestCategory.data,
    customEmail: state.guestCategory.customEmail,
    updatePending: state.guestCategory.pending,
    errors: state.guestCategory.errors,
    selectedTemplateId: state.emailTemplate.selectedTemplateId,
    event: state.event
  };
}

const mapDispatchToProps = {
  updateGuestCategory,
  fetchCustomEmail,
  clearCustomEmail,
  addOfflineErrors,
  updateEmailSelectedId
};

GuestCategoryEmailForm.propTypes = {
  guestCategory: PropTypes.object,
  emailType: PropTypes.string.isRequired,
  featureEnabledField: PropTypes.string
};

GuestCategoryEmailForm.defaultProps = {
  customEmailDefaultState: {
    subject: "",
    sender: "",
    email_template_id: null,
    attached_icalendar: false,
    attached_badge: false,
    attached_invoice: false,
    reply_to_email: "",
    sender_email: ""
  },
  guestCategoryDefaultState: {
    update_confirmation_email_enabled: false
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestCategoryEmailForm);
