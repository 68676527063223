import { Alert } from "react-bootstrap";
import { featureSetTranslation } from "../../utils/featureSetUtils";

interface Props {
  show: boolean;
  reset: () => void;
  save: () => void;
}

const ConfirmSave: React.FC<Props> = ({ show, reset, save }) => {
  if (!show) return null;

  return <div className="submit-modal container-fluid justify-content-center row">
    <div className="col-lg-9">
      <Alert className="d-flex justify-content-between">
        <div className="d-flex flex-column justify-content-center">
          <h4 className="modal-title">{ featureSetTranslation("save") }</h4>
          <p>{ featureSetTranslation("save_description") }</p>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex">
            <button type="button" className="btn btn-secondary mr-10" onClick={ reset } >{ I18n.t("cancel") }</button>
            <button type="button" className="btn btn-primary" onClick={ save } >{ I18n.t("validate") }</button>
          </div>
        </div>
      </Alert>
    </div>
  </div>;
};

export default ConfirmSave;
