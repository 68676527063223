import { Fragment, ChangeEvent, useState, useEffect } from "react";
import DateTimePicker from "../shared/DateTimePicker.react";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./tooltip_styles.scss";

const MOMENT_DATE_FORMAT = "YYYY-MM-DD HH:mm";
const ONLINE_SCHEDULE_TYPES = ["fixed_date", "dynamic_fields"];

interface Props {
  startDate: string,
  endDate: string,
  startDateEvaluated?: string,
  endDateEvaluated?: string,
  scheduleType: string,
  blockKey?: string,
  updateSettingsData(key: string, value: any, blockKey?: string): void;
}

const ScheduleDisplay: React.FC<Props> = ({
  startDate,
  endDate,
  blockKey,
  startDateEvaluated,
  endDateEvaluated,
  scheduleType = "fixed_date",
  updateSettingsData
}) => {
  const [isShowSchedulePane, setIsShowSchedulePane] = useState<boolean>(!!(startDate || endDate));

  useEffect(() => {
    if (startDate || endDate) setIsShowSchedulePane(true);
  }, [startDate, endDate]);

  const toggleSchedulePane = (): void => {
    if (isShowSchedulePane) {
      if (startDate !== null) {
        updateSettingsData("online_start_date", null, blockKey);
      }
      if (endDate !== null) {
        updateSettingsData("online_end_date", null, blockKey);
      }
    }

    setIsShowSchedulePane(!isShowSchedulePane);
  };

  const onChangeDatePicker = (field: string): (value: string) => void => {
    return (value): void => {
      const selectedDate = value ? moment(value).format(MOMENT_DATE_FORMAT) : null;
      updateSettingsData(field, selectedDate, blockKey);
    };
  };

  const onChangeHoursHandler = (value: string): any => {
    return (e: ChangeEvent<any>) => {
      const date = value === "online_start_date" ? startDate : endDate;
      const newTime = e.target?.value ? moment(date).hours(e.target.value) : null;
      updateSettingsData(value, newTime);
    };
  };

  const onChangeMinutesHandler = (value: string): any => {
    return (e: ChangeEvent<any>) => {
      const date = value === "online_start_date" ? startDate : endDate;
      const newTime = e.target?.value ? moment(date).minutes(e.target.value) : null;
      updateSettingsData(value, newTime);
    };
  };


  const parseDate = (dateString: string): Date => {
    if (dateString === "" || dateString === null) return null;
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
  };

  const tooltipDynamicFieldsHtml = (): JSX.Element => {
    const tooltipText = I18n.t("react.website.dynamic_fields_tooltip_html").split("\n");
    const metadataText = `- {{ event.metadata.metadata_key }}${I18n.t("react.website.dynamic_fields_meta")}`;
    const startDateText = `- {{ event.start_date | add_days: 3 }}${I18n.t("react.website.dynamic_fields_start_text")}`;
    const endDateText = `- {{ event.end_date | subtract_days: 3 }}${I18n.t("react.website.dynamic_fields_end_text")}`;
    const combinedArray = [...tooltipText, metadataText, startDateText, endDateText];
    return (
      <div>
        {combinedArray.map((line, index) => (
          <p key={index} style={{ margin: 0, whiteSpace: "nowrap" }}>
            {line}
          </p>
        ))}
      </div>
    );
  };

  const scheduleDisplayCheckbox = (): JSX.Element => {
    return <div className="form-check">
      <label className="form-check-label">
        <input type="checkbox" className="form-check-input" checked={isShowSchedulePane}
          onChange={toggleSchedulePane}/> {I18n.t("react.builders.schedule_display")}
      </label>
    </div>;
  };

  const scheduleTypePicker = (): JSX.Element => {
    if (!isShowSchedulePane) return null;

    const options = ONLINE_SCHEDULE_TYPES.map((option: string) => {
      return (
        <Fragment key={option}>
          <input type="radio" className="form-check-input" name="scheduleType" value={option}
            id={option} checked={scheduleType === option}
            onChange={(e: ChangeEvent<HTMLInputElement>): void => updateSettingsData("online_schedule_type", e.target?.value)
            }
          />
          <label className={`btn btn-secondary ${scheduleType === option ? "active" : ""}`} htmlFor={option}>
            {I18n.t(`react.website.${option}`)}
          </label>
        </Fragment>
      );
    });

    return (
      <>
        <div className="mb-3 row" key={"date-picker"}>
          <div className="col-12">
            <div className="btn-group btn-group-switch w-100" role="group">
              {options}
            </div>
          </div>
        </div>
        <p className="form-text">
          {I18n.t(`react.website.section_${scheduleType}_info`)}
          {scheduleType === "dynamic_fields" ?
            <OverlayTrigger overlay={<Tooltip id="tooltip">{tooltipDynamicFieldsHtml()}</Tooltip>}>
              <i className="fa-regular fa-circle-info ml-5"></i>
            </OverlayTrigger>
            :
            ""
          }
        </p>
      </>
    );
  };

  const renderDateInputs = (): JSX.Element => {
    if (!isShowSchedulePane) return null;

    if (scheduleType === "fixed_date") {
      return fixedDatePicker();
    } else {
      return dynamicFieldsInput();
    }
  };

  const dateFields = [
    {
      labelKey: "react.builders.start_date",
      dateKey: "online_start_date",
      selectedDate: startDate,
      evaluatedDate: startDateEvaluated,
    },
    {
      labelKey: "react.builders.end_date",
      dateKey: "online_end_date",
      selectedDate: endDate,
      evaluatedDate: endDateEvaluated,
    }
  ];

  const fixedDatePicker = (): JSX.Element => {
    return (
      <div className="mt-3">
        {dateFields.map(({ labelKey, dateKey, selectedDate }) => (
          <div className="d-flex flex-column mb-3">
            <label className="form-label">{I18n.t(labelKey)}</label>
            <DateTimePicker
              title={I18n.t(labelKey)}
              selectedDate={parseDate(selectedDate)}
              onChangeDate={onChangeDatePicker(dateKey)}
              onChangeHour={onChangeHoursHandler(dateKey)}
              onChangeMinute={onChangeMinutesHandler(dateKey)}
              calendarPlacement="top"
              extraClass="col-12"
            />
          </div>
        ))}
      </div>
    );
  };

  const dynamicFieldsInput = (): JSX.Element => {
    return (
      <>
        {dateFields.map(({ labelKey, dateKey, selectedDate, evaluatedDate }) => (
          <div className="d-flex flex-column mb-3">
            <label className="form-label">{I18n.t(labelKey)}</label>
            <input type="text" className="form-control" value={selectedDate || ""}
              onChange={(e): void => updateSettingsData(dateKey, e.target?.value)}/>

            {renderDateTimeSelected(evaluatedDate)}
          </div>

        ))}
      </>
    );
  };

  const renderDateTimeSelected = (date: string): JSX.Element => {
    if (!date) return null;
    return (
      <div className="col-12">
        <span className="badge rounded-pill bg-secondary">
          {moment(date).format(MOMENT_DATE_FORMAT)}
        </span>
      </div>
    );
  };


  return <>
    {scheduleDisplayCheckbox()}
    {scheduleTypePicker()}
    {renderDateInputs()}
  </>;
};


export default ScheduleDisplay;
