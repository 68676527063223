import { Component } from "react";
import PropTypes from "prop-types";
import { pathToPublicRegistrationForm } from "../../utils/pathUtils";

class RegistrationFormCell extends Component {

  render() {
    const { guest, guestCategories } = this.props;
    let content = I18n.t("shared.table_guest.no_registration_form");
    const gc = guestCategories.find(guestCategory => guestCategory.id === guest.guest_category_id);
    if (gc && gc.custom_registration_form_enabled) {
      content = <a href={pathToPublicRegistrationForm(guest)} target="_blank">{I18n.t("shared.table_guest.registration_form")}</a>;
    }

    return (
      <td className="cell-default d-none d-sm-table-cell">
        { content }
      </td>
    );
  }
}

RegistrationFormCell.propTypes = {
  guest: PropTypes.object.isRequired,
  guestCategories: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default RegistrationFormCell;
