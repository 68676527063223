import PropTypes from "prop-types";
import ContactModal from "../../containers/contacts/ContactModal.react";

const ContactModalCell = ({ guest, event }) => {
  return (
    <td className="cell-default d-none d-sm-table-cell">
      { guest.contact_id ? (
        <ContactModal guests={[guest]} event={event} clearStateOnClose={true}>
          <a href="#"><i className="fa-regular fa-user"></i> { I18n.t("shared.table_guest.contact") }</a>
        </ContactModal>
      ) : null}
    </td>
  );
};

ContactModalCell.propTypes = {
  guest: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired
};

export default ContactModalCell;
