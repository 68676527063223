import { Switch, Route } from "react-router";

import GuestsImportFileUploader from "./containers/GuestsImportFileUploader.react";
import App from "./containers/App.react";
import Thematics from "./containers/Thematics.react";
import SessionTypes from "./containers/SessionTypes.react";
import GuestCategoryActionsMenu from "./containers/guest_category/GuestCategoryActionsMenu.react";
import GuestCategoryAccesspoints from "./containers/GuestCategoryAccesspoints.react";
import GuestAccessPrivileges from "./containers/GuestAccessPrivileges.react";
import BadgeLoader from "./containers/BadgeLoader.react";
import ZohoInvoicesLoader from "./containers/ZohoInvoicesLoader.react";
import ConfigureExportedColumns from "./containers/ConfigureExportedColumns.react";

import AccesspointFormContainer from "./containers/accesspoint/AccesspointFormContainer.react";
import GuestCategoryFormContainer from "./containers/guest_category/GuestCategoryFormContainer.react";
import GuestCategoryEmailLiveCheckForm from "./containers/guest_category/GuestCategoryEmailLiveCheckForm.react";
import GuestCategory from "./containers/guest_category/GuestCategory.react";
import DigestEmailPicker from "./containers/visit_routes/DigestEmailPicker.react";
import ExportEmailPicker from "./containers/visit_routes/ExportEmailPicker.react";
import StandQrCodesExportButton from "./containers/visit_routes/StandQrCodesExportButton.react";

import RegistrationForms from "./containers/RegistrationForms.react";
import RegistrationForm from "./containers/RegistrationForm.react";
import EditableFieldsContainer from "./containers/shared/EditableFieldsContainer.react";
import RegistrationFormAttachToGuestCategory from "./containers/RegistrationFormAttachToGuestCategory.react";
import WebsiteBuilder from "./containers/WebsiteBuilder.react";
import TicketingContainer from "./containers/TicketingContainer.react";
import WebsiteMenuForm from "./containers/WebsiteMenuForm.react";
import EmailBuilder from "./containers/EmailBuilder.react";
import EmailTemplatesList from "./containers/EmailTemplatesList.react";
import WebsiteDropdownButton from "./containers/WebsiteDropdownButton.react";
import CheckinRules from "./containers/CheckinRules.react";
import SmsOnCheckin from "./containers/SmsOnCheckin.react";
import SeatsioForm from "./containers/integrations/SeatsioForm.react";
import EventThemeForm from "./containers/EventThemeForm.react";
import GuestProductTranslationsSlidingPane from "./containers/GuestProductTranslationsSlidingPane.react";
import Translations from "./containers/translations/Translations.react";
import Workflows from "./containers/automation/Workflows.react";
import WorkflowRunDetails from "./containers/automation/WorkflowRunDetails.react";
import WorkflowRuns from "./containers/automation/WorkflowRuns.react";
import WorkflowVersionBuilder from "./containers/automation/WorkflowVersionBuilder.react";
import GuestLabels from "./containers/GuestLabels.react";
import DirectEmail from "./components/direct_emails/DirectEmail.react";
import ContactModal from "./containers/contacts/ContactModal.react";
import ErbFormColorInput from "./components/ErbFormColorInput.react";
import ErbTranslatableInput from "./components/ErbTranslatableInput.react";
import WebsiteFormContainer from "./containers/WebsiteFormContainer.react";
import FeatureSetFormContainer from "./containers/features/FeatureSetFormContainer.react";
import IntegrationsIndex from "./components/integrations/IntegrationsIndex.react";
import IntegrationHeader from "./components/integrations/IntegrationHeader.react";
import EventCreationSteps from "./containers/events/EventCreationSteps.react";
import GuestProductCollectionsList from "./containers/guest_product_collections/GuestProductCollectionsList.react";
import ThematicsDropdownErb from "./components/shared/ThematicsDropdownErb.react";
import RichTextEditorErb from "./components/shared/RichTextEditorErb.react";
import KeyValueInputErb from "./components/shared/KeyValueInputErb.react";
import ArrayInputErb from "./components/shared/ArrayInputErb.react";
import Networking from "./containers/engagement/Networking.react";
import GuestViewRules from "./containers/guest_views/GuestViewRules.react";
import Meetings from "./containers/engagement/Meetings.react";
import LeadsExportEmailFeature from "./containers/LeadsExportEmailFeature.react";
import ProgramsContainer from "./containers/ProgramsContainer.react";
import GuestShowMeetingAvailabilities from "./components/engagement/meetings/GuestShowMeetingAvailabilities.react";
import DocumentBuilder from "./containers/document_templates/DocumentBuilder.react";
import DocumentTemplatesList from "./containers/document_templates/DocumentTemplatesList.react";
import DefaultLeadQualificationFields from "./containers/lead_qualification_fields/DefaultLeadQualificationFields.react";
import BadgeBuilder from "./components/badge_builder/BadgeBuilder.react";

const Routes = ({ component, defaultHtmlContent, componentProps }) => (
  <div>
    <Switch>
      <Route exact path="/:locale/events/:event_id/badge_templates/builder" component={BadgeBuilder}/>
      <Route exact path="/:locale/events/:event_id/badge_templates/:badge_template_id/builder" component={BadgeBuilder}/>
    </Switch>

    <Route path="/:locale/accounts/:account_id/templates/:template_id/editable_fields" render={ (props) => <EditableFieldsContainer {...props} options={ { tabs: [] } } /> } />
    <Route path="/:locale/accounts/:account_id/events/creation_wizard" component={EventCreationSteps} />

    {/*React guest management*/}
    <Switch>
      <Route path="/:locale/r/events/:event_id/integrations" render={(props) => {
        return <App {...props} integration_configurations={true} />;
      }} />
      <Route path="/:locale/r/events/:event_id/" component={App}/>
    </Switch>

    {/* Feature Deck */}
    <Route path="/:locale/r/events/:event_id/features" component={FeatureSetFormContainer} />

    {/*Registration form routes*/}
    <Route path="/:locale/events/:event_id/registration_forms/:registration_form_id" component={RegistrationForm}/>
    <Route path="/:locale/r/events/:event_id/registration_forms" component={RegistrationForms}/>

    <Route path="/:locale/r/events/:event_id/registration_forms/modal/attach_to_guest_category" component={RegistrationFormAttachToGuestCategory} />

    {/*Integrations*/}
    <Route exact path="/:locale/r/events/:event_id/integrations" component={IntegrationsIndex} />
    <Switch>
      <Route exact path="/:locale/r/events/:event_id/integrations/seatsio" component={SeatsioForm} />
      <Route exact path="/:locale/r/events/:event_id/integrations/:type" render={(props) => {
        return <IntegrationHeader {...props} integrationKey={props.match.params.type} />;
      }} />
    </Switch>

    {/*Other routes*/}
    <Route path="/:locale/events/:event_id/guest_import_operations/new" render={(props) => {
      return <GuestsImportFileUploader {...props} {...componentProps} />;
    }}/>

    <Route path="/:locale/events/:event_id/accesspoints/checkin_rules" component={CheckinRules}/>
    <Route path="/:locale/events/:event_id/accesspoints/sms_on_checkin" component={SmsOnCheckin}/>
    <Route path="/:locale/events/:event_id/networking" component={Networking}/>
    <Route path="/:locale/events/:event_id/meetings" component={Meetings}/>
    <Switch>
      <Route exact path="/:locale/r/events/:event_id/translations" component={Translations}/>
      <Route exact path="/:locale/r/events/:event_id/translations/:type" component={Translations}/>
      <Route exact path="/:locale/r/events/:event_id/translations/:type/:id" component={Translations}/>
    </Switch>
    <Route exact path="/:locale/r/events/:event_id/workflows" component={Workflows}/>
    <Route exact path="/:locale/events/:event_id/workflows/:workflow_id/builder" render={(props) => {
      return <WorkflowVersionBuilder {...props} workflowId={props.match.params.workflow_id} />;
    }}/>
    <Route exact path="/:locale/r/events/:event_id/workflow_runs" component={WorkflowRuns}/>
    <Route exact path="/:locale/r/events/:event_id/workflow_runs/:id" render={(props) => {
      return <WorkflowRunDetails {...props} workflowRunId={props.match.params.id} />;
    }}/>
    <Route path="/:locale/events/:event_id/guests/:guest_id/guest_products/" render={(props) => {
      switch (component) {
      case "ThematicsDropdownErb":
        return <ThematicsDropdownErb {...props} {...componentProps} />;
      case "ErbTranslatableInput":
        return <ErbTranslatableInput {...props} {...componentProps} />;
      case "RichTextEditorErb":
        return <RichTextEditorErb {...props} {...componentProps} />;
      case "ContactModalLink":
        return <ContactModal><div dangerouslySetInnerHTML={{ __html: defaultHtmlContent }}></div></ContactModal>;
      default:
        return null;
      }
    }}/>

    <Route path="/:locale/events/:event_id/guests/:guest_id/guest_products/:guest_product_id/edit" render={(props) => {
      switch (component) {
      case "GuestProductTranslationsSlidingPane":
        return <GuestProductTranslationsSlidingPane {...props} {...componentProps} />;
      default:
        return null;
      }
    }}/>

    <Route path="/:locale/events/:event_id/visit_route" render={(props) => {
      switch (component) {
      case "DigestEmailPicker":
        return <DigestEmailPicker {...props} {...componentProps} />;
      case "ExportEmailPicker":
        return <ExportEmailPicker {...props} {...componentProps} />;
      case "ConfigureExportedColumns":
        return <ConfigureExportedColumns {...props} {...componentProps} />;
      case "StandQrCodesExportButton":
        return <StandQrCodesExportButton {...props} {...componentProps} />;
      case "GuestViewRules":
        return <GuestViewRules {...props} {...componentProps} />;
      default:
        return null;
      }
    }}/>

    <Route path="/:locale/events/:event_id/guests/:guest_id/guest_bookmarks/products" render={() => {
      switch (component) {
      case "ContactModalLink":
        return <ContactModal><div dangerouslySetInnerHTML={{ __html: defaultHtmlContent }}></div></ContactModal>;
      default:
        return null;
      }
    }}/>

    <Route exact path="/:locale/events/:event_id/guests/:guest_id/meeting_availabilities" render={(props) => {
      switch (component) {
      case "GuestShowMeetingAvailabilities":
        return <GuestShowMeetingAvailabilities {...props} {...componentProps} />;
      case "ContactModalLink":
        return <ContactModal><div dangerouslySetInnerHTML={{ __html: defaultHtmlContent }}></div></ContactModal>;
      default:
        return null;
      }
    }}/>

    <Switch>
      <Route exact path="/:locale/events/:event_id/website/builder" component={WebsiteBuilder}/>
      <Route exact path="/:locale/events/:event_id/website" component={WebsiteDropdownButton}/>
      <Route path="/:locale/events/:event_id/website/builder/page/:page_id" component={WebsiteBuilder} />
      <Route path="/:locale/events/:event_id/website/menus/new" component={WebsiteMenuForm}/>
      <Route path="/:locale/events/:event_id/website/menus/:website_menu_id/edit" component={WebsiteMenuForm}/>
      <Route exact path="/:locale/r/events/:event_id/emails" component={EmailTemplatesList}/>
      <Route exact path="/:locale/events/:event_id/emails/:email_template_id/builder" component={EmailBuilder}/>
      <Route path="/:locale/r/events/:event_id/document_templates" component={DocumentTemplatesList} />
      <Route exact path="/:locale/events/:event_id/document_templates/:document_template_id/builder" render={(props) => {
        return <DocumentBuilder {...props} documentTemplateId={props.match.params.document_template_id} />;
      }}/>
      <Route exact path="/:locale/events/:event_id/guest_categories" component={GuestCategoryActionsMenu}/>
      <Route exact path="/:locale/events/:event_id/guest_categories/new" component={GuestCategoryFormContainer}/>
      <Route exact path="/:locale/r/events/:event_id/guest_categories/:guest_category_id" component={GuestCategory} />
      <Route exact path="/:locale/events/:event_id/guest_categories/:guest_category_id/edit" component={GuestCategoryFormContainer}/>
      <Route exact path="/:locale/r/events/:event_id/guest_categories/:guest_category_id/email_live_check" component={GuestCategoryEmailLiveCheckForm}/>

      <Route path="/:locale/events/:event_id/guest_categories/:guest_category_id/liquid_field" render={ (props) => <EditableFieldsContainer {...props} options={ { tabs: ["sponsorship_email", "registrations_closed" ], type: "custom_form" } } /> } />
      <Route path="/:locale/events/:event_id/guest_categories/:guest_category_id/liquid_field_update" render={ (props) => <EditableFieldsContainer {...props} options={ { tabs: ["sponsorship_email", "registrations_closed"], type: "custom_form" } } /> } />

      <Route path="/:locale/events/:event_id/guest_categories/:guest_category_id/email" render={ (props) => <EditableFieldsContainer {...props} options={ { tabs: ["confirmation", "moderation", "edition_link"], type: "custom_email" } } /> } />
      <Route path="/:locale/events/:event_id/guest_categories/:guest_category_id/email_update" render={ (props) => <EditableFieldsContainer {...props} options={ { tabs: ["confirmation", "moderation", "edition_link"], type: "custom_email" } } /> } />

      <Route path="/:locale/events/:event_id/guest_categories/:guest_category_id/document_templates/:document_template_id/edit" render={ (props) => <EditableFieldsContainer {...props} options={ { tabs: [] } } /> } />
      <Route path="/:locale/events/:event_id/guest_categories/:guest_category_id/document_templates/:document_template_id" render={ (props) => <EditableFieldsContainer {...props} options={ { tabs: [] } } /> } />

      <Route path="/:locale/events/:event_id/guest_categories/:guest_category_id/manage_guest_category_accesspoints" render={ (props) => <GuestCategoryAccesspoints {...props} options={ { mode: "accesspoints" } } /> } />
    </Switch>

    <Route path="/:locale/events/:event_id/accesspoints/:accesspoint_id/manage_guest_category_accesspoints" render={ (props) => <GuestCategoryAccesspoints {...props} options={ { mode: "guest_categories" } } /> } />
    <Route path="/:locale/events/:event_id/thematics" component={Thematics}/>

    <Route path="/:locale/events/:event_id/accesspoints/new" component={AccesspointFormContainer}/>
    <Route path="/:locale/events/:event_id/accesspoints/:accesspoint_id/edit" component={AccesspointFormContainer}/>

    <Switch>
      <Route path="/:locale/events/:event_id/programs/:program_id" component={ProgramsContainer}/>
      <Route exact path="/:locale/events/:event_id/programs" component={ProgramsContainer}/>
    </Switch>

    <Route exact path="/:locale/events/:event_id/session_types" component={SessionTypes}/>
    <Route path="/:locale/events/:event_id/ticketing/new" component={TicketingContainer}/>
    <Route path="/:locale/events/:event_id/ticketing/:ticketing_id/edit" component={TicketingContainer}/>

    <Route exact path="/:locale/events/:event_id/guests/:guest_id" render={(props) => {
      switch (component) {
      case "GuestLabels":
        return <GuestLabels {...props} />;
      case "DirectEmailFromModeration":
        return <DirectEmail {...props} rejectionEmail={true}>{ I18n.t("react.direct_email.send_email_and_reject") }</DirectEmail>;
      case "DirectEmailFromMenuItem":
        return <DirectEmail {...props}><i className="fa-regular fa-paper-plane fa-fw"></i> { I18n.t("react.direct_email.send_email") }</DirectEmail>;
      case "ContactModalLink":
        return <ContactModal><div dangerouslySetInnerHTML={{ __html: defaultHtmlContent }}></div></ContactModal>;
      }
    }}/>

    {[
      "/:locale/events/:event_id/guests/:guest_id/edit",
      "/:locale/events/:event_id/guests/new"
    ].map(path => {
      return <Route key={path} exact path={path} render={(props) => {
        switch (component) {
        case "ThematicsDropdownErb":
          return <ThematicsDropdownErb {...props} {...componentProps} />;
        case "ErbTranslatableInput":
          return <ErbTranslatableInput {...props} {...componentProps} />;
        }
      }}/>;
    })}

    <Route exact path="/:locale/events/:event_id/guests/:guest_id/manage_access_privileges" render={(props) => {
      switch (component) {
      case "ContactModalLink":
        return <ContactModal><div dangerouslySetInnerHTML={{ __html: defaultHtmlContent }}></div></ContactModal>;
      case "GuestAccessPrivileges":
        return <GuestAccessPrivileges {...props}/>;
      }
    }}/>

    <Route exact path="/:locale/events/:event_id/guests/:guest_id/scoring" render={(props) => {
      switch (component) {
      case "DirectEmailFromMenuItem":
        return <DirectEmail {...props}><i className="fa-regular fa-paper-plane"></i> { I18n.t("react.direct_email.send_email") }</DirectEmail>;
      case "ContactModalLink":
        return <ContactModal><div dangerouslySetInnerHTML={{ __html: defaultHtmlContent }}></div></ContactModal>;
      }
    }}/>

    <Route path="/:locale/public/events/:event_id/guests/:guest_id/badge" component={BadgeLoader} />
    <Route path="/:locale/public/events/:event_id/guests/:guest_id/zoho_invoice" component={ZohoInvoicesLoader} />
    <Route path="/:locale/accounts/:account_id/edit_smtp_settings" render={(props) => {
      return <ArrayInputErb {...props} {...componentProps} />;
    }}/>
    <Route path="/:locale/accounts/:account_id/update_smtp_settings" render={(props) => {
      return <ArrayInputErb {...props} {...componentProps} />;
    }}/>

    <Route path="/:locale/accounts/:account_id/events/new" render={(props) => {
      return <EventThemeForm {...props}><div dangerouslySetInnerHTML={{ __html: defaultHtmlContent }}></div></EventThemeForm>;
    }}/>
    <Route path="/:locale/events/:event_id/edit" render={(props) => {
      return <EventThemeForm {...props}><div dangerouslySetInnerHTML={{ __html: defaultHtmlContent }}></div></EventThemeForm>;
    }}/>

    <Route path="/:locale/events/:event_id/edit_advanced" render={(props) => {
      switch (component) {
      case "ArrayInputErb":
        return <ArrayInputErb {...props} {...componentProps} />;
      case "GuestProductCollectionsList":
        return <GuestProductCollectionsList {...props} />;
      }
    }}/>

    <Route exact path="/:locale/events/:event_id/exhibitors/leads_settings" render={(props) => {
      switch (component) {
      case "ConfigureExportedColumns":
        return <ConfigureExportedColumns {...props} />;
      case "LeadsExportEmailFeature":
        return <LeadsExportEmailFeature {...Object.assign({}, props, componentProps)} />;
      case "DefaultLeadQualificationFields":
        return <DefaultLeadQualificationFields {...Object.assign({}, props, componentProps)} />;
      }
    }} />

    <Route exact path="/:locale/events/:event_id/exhibitors/" render={(props) => {
      switch (component) {
      case "StandQrCodesExportButton":
        return <StandQrCodesExportButton {...props} {...componentProps} />;
      }
    }} />

    <Route exact path="/:locale/accounts/:account_id/edit_sso_settings" render={(props) => {
      switch (component) {
      case "ErbFormColorInput":
        return <ErbFormColorInput {...Object.assign({}, props, componentProps)} />;
      }
    }}/>
    <Route exact path="/:locale/events/:event_id/guest_categories/:guest_category_id/wallet_pass" render={(props) => {
      switch (component) {
      case "ErbFormColorInput":
        return <ErbFormColorInput {...Object.assign({}, props, componentProps)} />;
      }
    }}/>
    <Route exact path="/:locale/events/:event_id/guest_categories/:guest_category_id" render={(props) => {
      switch (component) {
      case "ErbFormColorInput":
        return <ErbFormColorInput {...Object.assign({}, props, componentProps)} />;
      }
    }}/>
    <Route exact path={"/:locale/events/:event_id/website/edit_pwa"} render={ (props) => {
      return <WebsiteFormContainer {...props} mode="edit_pwa" />;
    }} />
    <Route exact path="/:locale/events/:event_id/website/web_push_notifications" render={ (props) => {
      return <WebsiteFormContainer {...props} mode="web_push_notifications" />;
    }} />
    <Route exact path="/:locale/events/:event_id/website/gdpr" render={ (props) => {
      return <WebsiteFormContainer {...props} mode="gdpr" />;
    }} />
    <Route exact path="/:locale/events/:event_id/website/consent_notice" render={ (props) => {
      return <WebsiteFormContainer {...props} mode="consent_notice" />;
    }} />

    <Route exact path="/:locale/events/:event_id/website/help_registration_after_signin" render={ (props) => {
      return <WebsiteFormContainer {...props} mode="help_registration_after_signin" />;
    }} />

    <Route path="/:locale/events/:event_id/app_configuration" render={(props) => {
      switch (component) {
      case "GuestViewRules":
        return <GuestViewRules {...props} {...componentProps} />;
      }
    }}/>

    {[
      "/:locale/accounts/:account_id/sso_providers/:id/edit",
      "/:locale/accounts/:account_id/sso_providers/new"
    ].map(path => {
      return <Route exact path={path} key={path} render={ (props) => {
        return <KeyValueInputErb {...props} {...componentProps} />;
      }} />;
    })}

    {[
      "/:locale/admin/user_enterprises/:id/edit",
      "/:locale/admin/user_enterprises/new",
      "/:locale/admin/user_enterprises"
    ].map(path => {
      return <Route exact path={path} key={path} render={ (props) => {
        return <ArrayInputErb {...props} {...componentProps} />;
      }} />;
    })}
  </div>
);

export default Routes;
