import { Modal } from "react-bootstrap";
import { pathToOnsitePaymentAppFlashConfig } from "../../utils/pathUtils";

interface Props {
  show: boolean;
  categoryId: string;

  onClose: () => void;
}

const i18n = (key: string): string => {
  return I18n.t(`react.guest_category.onsite_payment_app.${key}`);
};

const OnsitePaymentAppFlashConfigModal: React.FC<Props> = ({ show, categoryId, onClose }) => {
  if (!show) return null;

  return <Modal show={true} onHide={onClose}>
    <Modal.Header>
      <Modal.Title>{i18n("title")}</Modal.Title>
      <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
    </Modal.Header>
    <Modal.Body>
      <p dangerouslySetInnerHTML={{ __html: i18n("details_html") }}></p>
      <img src={pathToOnsitePaymentAppFlashConfig(categoryId)} className="img-fluid center-block" />
    </Modal.Body>
  </Modal>;
};

export default OnsitePaymentAppFlashConfigModal;
