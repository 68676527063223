import { Fragment, Component } from "react";
import { connect } from "react-redux";

import { fetchEventsFromUrl } from "../actions/EventActionCreators";
import { requestEvent } from "../actions/ImportActionCreators";
import * as actions from "../actions/RegistrationFormsActionCreators";

import TargetEventModal from "../components/duplication_modals/TargetEventModal.react";
import DuplicationRunningModal from "../components/duplication_modals/DuplicationRunningModal.react";
import RegistrationFormPickerModal from "../components/duplication_modals/RegistrationFormPickerModal.react";
import { pathToRegistrationForm } from "../utils/pathUtils";
import { redirectIfUnauthorized } from "../utils/aclUtils";

class RegistrationFormCreation extends Component {
  constructor(props) {
    redirectIfUnauthorized("configuration", "manage");
    super(props);

    [
      "toggleEventSourceModal",
      "onEventSourceSelected",
      "createForm",
      "duplicate",
      "fetchMoreEvents"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      showEventSourcePickerModal: false,
      showRegistrationFormPickerModal: false,
      showDuplicationRunningModal: false
    };
  }

  componentDidMount() {
    const { requestEvent, event } = this.props;
    if (!event.fetched) requestEvent();
  }

  createForm(e) {
    e.preventDefault();
    const { createRegistrationForm, event } = this.props;
    createRegistrationForm(event._id, pathToRegistrationForm(":id"));
  }

  toggleEventSourceModal(e) {
    e && e.preventDefault();
    this.setState({
      showEventSourcePickerModal: !this.state.showEventSourcePickerModal,
      showRegistrationFormPickerModal: false
    });
  }

  onEventSourceSelected(eventId) {
    const { fetchRegistrationFormsFromEvent } = this.props;
    fetchRegistrationFormsFromEvent(eventId);
    this.setState({
      showEventSourcePickerModal: false,
      showRegistrationFormPickerModal: true,
      sourceEventId: eventId
    });
  }

  duplicate(sourceRegistrationFormId, newTitle) {
    this.setState({
      showRegistrationFormPickerModal: false,
      showDuplicationRunningModal: true
    });

    const { duplicateRegistrationForm, event } = this.props;

    const registrationFormParam = {
      new_title: newTitle,
      target_event_id: event._id
    };
    duplicateRegistrationForm(sourceRegistrationFormId, registrationFormParam);
  }

  fetchMoreEvents() {
    const { fetchEventsFromUrl, eventsNextURL } = this.props;
    fetchEventsFromUrl(eventsNextURL);
  }

  renderDuplicateFromModals() {
    const { event, events, duplicationErrors, duplicatedForm, eventsNextURL } = this.props;
    const { showEventSourcePickerModal, showRegistrationFormPickerModal, showDuplicationRunningModal, sourceEventId } = this.state;

    const sourceEvent = event._id == sourceEventId ? event : events && events.find(ev => ev._id == sourceEventId);

    return <div>
      {/* TargetEventModal should probably be renamed EventPickerModal */}
      <TargetEventModal
        isVisible={showEventSourcePickerModal}
        onClose={() => this.setState({ showEventSourcePickerModal: false }) }
        currentEvent={event}
        fetchMoreEvents={eventsNextURL ? this.fetchMoreEvents : null}
        targetReadableName={I18n.t("react.email_templates.target_name")}
        onEventSelected={this.onEventSourceSelected}
        title={ I18n.t("react.registration_form.creation_buttons.create_registration_form_from_modal_event_title") }
      />

      <RegistrationFormPickerModal
        isVisible={showRegistrationFormPickerModal}
        onClose={() => this.setState({ showRegistrationFormPickerModal: false }) }
        onSubmit={this.duplicate}
        event={sourceEvent}
        registrationForms={sourceEvent && sourceEvent.registrationForms}
        modalTitle={ I18n.t("react.registration_form.creation_buttons.create_registration_form_from_modal_title") }
        back={this.toggleEventSourceModal}
      />

      <DuplicationRunningModal
        isVisible={showDuplicationRunningModal}
        onClose={() => this.setState({ showDuplicationRunningModal: false }) }
        model="registration_form"
        currentEventId={event._id}
        targetEventId={event._id}
        duplicatedData={duplicatedForm}
        duplicationErrors={duplicationErrors}
      />
    </div>;
  }

  render() {
    return (
      <Fragment>
        {this.props.render(this)}
        { this.renderDuplicateFromModals() }
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { registrationForms } = state;
  return {
    event: state.event,
    events: state.events.data,
    eventsNextURL: state.events.nextURL,
    duplicatedForm: registrationForms.duplicated,
    duplicationErrors: registrationForms.duplicationErrors,
    duplicationWarnings: registrationForms.duplicationWarnings
  };
}

const mapDispatchToProps = Object.assign({}, {
  fetchEventsFromUrl,
  requestEvent
}, actions);

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationFormCreation);
