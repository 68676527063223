import { ActionTypes } from "../constants/Constants";

const initialState = {
  items: null,
  errors: null,
  lastUpdatedLabelId: null
};

function labels(state = initialState, action) {
  switch (action.type) {
  case ActionTypes.RECEIVE_LABELS_SUCCESS:
    return { items: action.data || [] };
  case ActionTypes.ADD_LABEL_REQUEST:
    return { ...state, fetching: true };
  case ActionTypes.ADD_LABEL_SUCCESS: {
    const newItemsAfterCreate = state.items.slice();
    newItemsAfterCreate.unshift(action.data);
    return { items: [...newItemsAfterCreate], fetching: false };
  }
  case ActionTypes.LABEL_FAILURE: {
    const items = state.items.slice();
    const errors = Object.assign({}, action.data);
    return { items: items, errors: errors, lastUpdatedLabelId: action.labelId, fetching: false };
  }
  case ActionTypes.REMOVE_LABEL_SUCCESS: {
    const newItemsAfterRemove = state.items.filter(label => label._id != action.labelId);
    return { items: newItemsAfterRemove };
  }
  case ActionTypes.UPDATE_LABEL_REQUEST: {
    return { ...state, lastUpdatedLabelId: null };
  }
  case ActionTypes.UPDATE_LABEL_SUCCESS: {
    const { name, color, labelId } = action;
    const newItemsAfterUpdate = state.items.map(label => {
      if (label._id == labelId) {
        return Object.assign({}, label, { name, color });
      }
      return label;
    });
    return { items: newItemsAfterUpdate, lastUpdatedLabelId: action.data._id };
  }
  default:
    return state;
  }
}

export default labels;
