import { Component } from "react";
import { formatLongDate } from "../../utils/DateUtils";
import { fetchContactActivities, fetchContactActivitiesPage } from "../../actions/ContactsActionCreators";
import { contactRowStyle } from "../../constants/Style";
import contactDetailsList from "./ContactDetailsList.react";

const WEBHOOKS_STATUS_LABEL_CLASS_MAPPING = {
  none: "info",
  sent: "success",
  not_sent: "danger"
};

const i18n = (key, opts = {}) => {
  return I18n.t(`react.contacts.contact_activities.${key}`, opts);
};

class ContactActivities extends Component {
  activityLabel(activity) {
    switch (activity.type) {
    case "ContactActivity::ArrivedAtEvent":
      return i18n("arrived_at");
    case "ContactActivity::RegisteredAtEvent":
      return i18n("registered_at");
    }
  }

  renderWebhooksSentLabel(activity) {
    return <span className={`badge rounded-pill bg-${ WEBHOOKS_STATUS_LABEL_CLASS_MAPPING[activity.webhooks_status] } float-end`}>
      { i18n(`webhooks_status_${activity.webhooks_status}`) }
    </span>;
  }

  renderActivity(activity) {
    const { eventIdNameMapping } = this.props;
    const event = eventIdNameMapping[activity.event_id];
    const message = event ? i18n("message", { event, activity: this.activityLabel(activity) }) : null;

    return <div key={activity._id} style={contactRowStyle}>
      <p>
        <strong style={{ marginBottom: "5px" }}>{ formatLongDate(activity.created_at) }</strong>
        { this.renderWebhooksSentLabel(activity) }
      </p>
      <p>{ message }</p>
    </div>;
  }

  render() {
    const { items } = this.props;
    return <div>{items.map(item => this.renderActivity(item))}</div>;
  }
}

function mapStateToProps(state) {
  return {
    items: state.contact.activities,
    itemsNextURL: state.contact.ActivitiesNextURL,
    fetching: state.contact.fetchingActivities
  };
}

const mapDispatchToProps = {
  fetchItems: fetchContactActivities,
  fetchItemsPage: fetchContactActivitiesPage
};

export default contactDetailsList(ContactActivities, mapStateToProps, mapDispatchToProps, i18n);
