import { insertOrUpdateObject } from "../utils/StateOperationUtils";

module.exports = {
  createReducer(receiveAllActionName, receiveOneActionName = null) {
    return (state = [], action) => {
      switch (action.type) {
      case receiveAllActionName:
        state = action.objects.slice();
        return state;
      case receiveOneActionName:
        return insertOrUpdateObject(state, action.object, "id");
      default:
        return state;
      }
    };
  }
};
