"use strict";
import { Component, createRef } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import Icons from "../constants/Icons";
import { showNotice } from "../actions/NoticeActionCreators";
import { updateAppearance } from "../actions/TemplatesBuilderActionCreators";
import { requestEvent } from "../actions/ImportActionCreators";
import { fetchLiquidTemplates } from "../actions/AccountActionCreators";
import { clearErrors } from "../actions/ErrorsActionCreators";
import { updateLiquidTemplate, createLiquidTemplate } from "../actions/AccountActionCreators";
import requiresProps from "../utils/requiresProps";
import * as WebsitePagesActionCreators from "../actions/WebsitePagesActionCreators";
import SlidingPane from "react-sliding-pane";
import TranslationTable from "./translations/TranslationTable.react";
import NoticeBlock from "../components/templates_builder/NoticeBlock.react";
import { urlEventId } from "../utils/pathUtils";

const BUILDER_TYPE = {
  EMAIL: "email",
  WEBSITE: "website",
  BLOG_ARTICLE: "blog_article",
  DOCUMENT: "document",
  APP_HOME_SCREEN: "app_home_screen_config",
};

export default function withTemplatesBuilder(WrappedComponent, builderType) {
  class templatesBuilder extends Component {
    constructor(props) {
      super(props);
      [
        "showSectionsList",
        "hideSectionsList",
        "toggleSectionView",
        "toggleAppearanceView",
        "toggleAppearanceSubsectionView",
        "receiveAppearanceChanges",
        "highlightBlockInIframe",
        "displayTranslationsPane",
        "hideTranslationsPane",
        "resetAndChangeView",
        "sidebarMenuClasses"
      ].forEach(item => {
        this[item] = this[item].bind(this);
      });

      this.state = {
        sectionViewForKey: null,
        appearanceViewForKey: null,
        appearanceShown: false,
        sectionsListShown: false,
        translationsPaneShown: false,
        currentTranslationTableId: null,
        activeSidebarMenu: "pages"
      };

      this.childRef = createRef();
    }

    showSectionsList() {
      this.setState({ sectionsListShown: true });
    }

    hideSectionsList() {
      this.setState({ sectionsListShown: false });
    }

    toggleSectionView(key) {
      this.setState({ sectionViewForKey: key });
      if (key && this.childRef.current && this.childRef.current.refs["template-preview-iframe"]) {
        const domNode = this.childRef.current.refs["template-preview-iframe"];
        domNode.contentWindow.postMessage({ type: "scroll", sectionId: key }, "*");
      }
    }

    highlightBlockInIframe(key) {
      const domNode = this.childRef.current && this.childRef.current.refs["template-preview-iframe"];
      if (domNode) {
        domNode.contentWindow.postMessage({ type: "blockToggle", blockId: key }, "*");
      }
    }

    toggleAppearanceView() {
      const { appearanceShown } = this.state;
      const menuKey = appearanceShown ? "pages" : "appearance";
      this.resetAndChangeView({ appearanceShown: !appearanceShown }, menuKey);
    }

    toggleAppearanceSubsectionView(key) {
      this.setState({ appearanceViewForKey: key });
    }

    receiveAppearanceChanges(key, newConfig, changes) {
      const { updateAppearance } = this.props;
      updateAppearance(newConfig, changes);
    }

    displayTranslationsPane(e, id, table, menuKey) {
      e && e.preventDefault();
      if (!id) return;

      this.resetAndChangeView({
        translationsPaneShown: true,
        currentTranslationTableId: id,
        currentTranslationTableName: table || this.translationTableName()
      }, menuKey || "translations");
    }

    hideTranslationsPane() {
      this.resetAndChangeView({
        translationsPaneShown: false,
        currentTranslationTableId: null
      }, this.state.activeSidebarMenu == "menus" ? "menus" : "pages");
    }

    translationTableName() {
      if (builderType === BUILDER_TYPE.EMAIL)
        return "email_templates";
      else if (builderType == BUILDER_TYPE.WEBSITE)
        return "website_pages";
      else if (builderType == BUILDER_TYPE.BLOG_ARTICLE)
        return "blog_articles";
      else if (builderType == BUILDER_TYPE.DOCUMENT)
        return "document_templates";
      else if (builderType == BUILDER_TYPE.APP_HOME_SCREEN)
        return "app_home_screen_config";
    }

    sidebarMenuClasses(menuKey) {
      return classNames({
        "list-group-item": true,
        "justify-content-center": true,
        "active": this.state.activeSidebarMenu === menuKey
      });
    }

    resetAndChangeView(newState, menuKey) {
      this.setState({
        sectionsListShown: false,
        sectionViewForKey: null,
        appearanceShown: false,
        appearanceViewForKey: null,
        activeSidebarMenu: menuKey,
        ...newState
      });
    }

    renderTranslations() {
      const { match, location, history, notice, noticeType } = this.props;
      const { translationsPaneShown, currentTranslationTableId, currentTranslationTableName } = this.state;

      return <SlidingPane
        isOpen={translationsPaneShown}
        title={I18n.t("react.registration_form.translations_title")}
        from="right"
        width='90%'
        onRequestClose={this.hideTranslationsPane}
        className="width-100-xs width-90-sm"
        closeIcon={Icons.close()}
        overlayClassName="sliding-pane-force-top"
      >
        <div style={{ padding: "0 15px 15px" }}>
          <NoticeBlock notice={notice} noticeType={noticeType} />
          <TranslationTable
            match={match}
            location={location}
            history={history}
            type={currentTranslationTableName}
            eventId={urlEventId()}
            id={currentTranslationTableId}
            inSlidingPane={true}
            onlySimpleValidateButton={true}
          />
        </div>
      </SlidingPane>;
    }

    render() {
      const newProps = {
        showSectionsList: this.showSectionsList,
        hideSectionsList: this.hideSectionsList,
        toggleSectionView: this.toggleSectionView,
        toggleAppearanceView: this.toggleAppearanceView,
        toggleAppearanceSubsectionView: this.toggleAppearanceSubsectionView,
        receiveAppearanceChanges: this.receiveAppearanceChanges,
        highlightBlockInIframe: this.highlightBlockInIframe,
        sectionViewForKey: this.state.sectionViewForKey,
        appearanceViewForKey: this.state.appearanceViewForKey,
        sectionsListShown: this.state.sectionsListShown,
        appearanceShown: this.state.appearanceShown,
        translationsPaneShown: this.state.translationsPaneShown,
        displayTranslationsPane: this.displayTranslationsPane,
        resetAndChangeParentView: this.resetAndChangeView,
        sidebarMenuClasses: this.sidebarMenuClasses
      };

      const { slidingPane } = this.props;

      return (
        <div className={`container-fluid website-builder ${slidingPane ? "sliding-pane" : ""}`}>
          <WrappedComponent {...this.props} {...newProps} ref={this.childRef} />
          { this.renderTranslations() }
        </div>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      notice: state.notifications.currentNotice,
      noticeType: state.notifications.noticeType,
      liquidTemplatePendingRequest: state.liquidTemplate.pendingRequest,
      liquidTemplateErrors: state.liquidTemplate.errors,
      event: state.event
    };
  }

  const mapDispatchToProps = Object.assign({}, WebsitePagesActionCreators, {
    updateAppearance,
    showNotice,
    requestEvent,
    fetchLiquidTemplates,
    clearErrors,
    updateLiquidTemplate,
    createLiquidTemplate
  });

  return connect(mapStateToProps, mapDispatchToProps)(requiresProps(templatesBuilder, {
    requirements: {
      event: {
        fn: ({ requestEvent }) => {
          requestEvent();
        },
        desiredState: "has_underscore_id"
      },
      liquidTemplates: {
        waitFor: ["event"],
        fn: ({ fetchLiquidTemplates, event }) => {
          fetchLiquidTemplates(event.account_id, `${builderType}_codeit_section`);
        },
        statePath: "liquidTemplate.all"
      }
    }
  }));
}
