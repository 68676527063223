import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

class DeleteConfirmationModal extends Component {
  constructor(props) {
    super(props);
    [
      "onChangeInput",
      "confirmDeletion"
    ].forEach(fn => {
      this[fn] = this[fn].bind(this);
    });

    this.state = { inputValue: "" };
  }

  i18n(key, options = {}) {
    const { i18nPrefix } = this.props;

    return I18n.t(`${i18nPrefix}.${key}`, options);
  }

  onChangeInput(e) {
    this.setState({ inputValue: e.target.value });
  }

  confirmDeletion() {
    const { onConfirm } = this.props;

    if (this.submitDisabled())
      return null;

    onConfirm();
    this.setState({ inputValue: "" });
  }

  submitDisabled() {
    const { inputValue } = this.state;

    return inputValue != this.i18n("trigger_word");
  }

  render() {
    const { isVisible, onClose, onEnter, type, count } = this.props;
    const { inputValue } = this.state;

    if (!isVisible) return null;

    const inputClassValidation = inputValue && (this.submitDisabled() ? "is-invalid" : "is-valid");

    return <Modal show={true} onEnter={onEnter} onHide={onClose} autoFocus>
      <Modal.Header>
        <Modal.Title>{this.i18n(`confirm_destroy_all_${type}`)}</Modal.Title>
        <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginBottom: "8px" }} dangerouslySetInnerHTML={{ __html: this.i18n("confirm_delete_html") }}></div>
        <input type="text" value={inputValue} onChange={this.onChangeInput} className={`form-control ${inputClassValidation}`} autoFocus autoComplete="false" />
      </Modal.Body>
      <Modal.Footer>
        <input type="submit" value={ count !== undefined && this.i18n("delete", { count: count }) || "" } disabled={this.submitDisabled()} onClick={this.confirmDeletion} className="btn btn-danger btn-wrap" />
      </Modal.Footer>
    </Modal>;
  }
}

DeleteConfirmationModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  count: PropTypes.number,
  i18nPrefix: PropTypes.string,
  onEnter: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

DeleteConfirmationModal.defaultProps = {
  isVisible: false
};

export default DeleteConfirmationModal;
