import { useEffect } from "react";
import { useSelector } from "react-redux";
import Select, { components, Option, SingleValueProps } from "react-select";
import { UI_COLORS, WORKFLOW_STEP_FILTER } from "../../constants/Constants";
import { WorkflowStep } from "../../types/Workflow";
import { checkPathExists, extractPath } from "../../utils/workflowUtils";

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.workflows.object_selector.${key}`, opts);
}

const customStyles = {
  placeholder: (base): any => ({
    ...base,
    color: `${UI_COLORS.grey500} !important`,
  })
};

const SingleValue = (props: SingleValueProps): JSX.Element => {
  const { data } = props;
  const { label, invalid } = data;

  return <components.SingleValue {...props} className={invalid ? "text-danger" : ""}>
    {label}
  </components.SingleValue>;
};

interface Props {
  objectType: string;
  workflowStep: WorkflowStep;
  settingValue: string;
  isDisabled?: boolean;
  onChange(inputValue: any): void;
}

const ObjectSelector: React.FC<Props> = ({ objectType, workflowStep, settingValue, isDisabled, onChange }) => {
  const orderedSteps = useSelector((state: any) => state.workflowVersionBuilder.orderedSteps);
  const context = useSelector((state: any) => state.workflowVersionBuilder.context);

  useEffect(() => {
    // auto-select the only option available
    if (!settingValue && options.length === 1) {
      onSelectField(options[0]);
    }
  }, []);

  const filteredSteps = (): WorkflowStep[] => {
    let before = true;
    return orderedSteps.filter((step: WorkflowStep) => {
      if (step.type === WORKFLOW_STEP_FILTER && step._id !== workflowStep._id) return false;

      if (step._id === workflowStep._id) {
        before = false;
      }
      return before;
    });
  };

  const onSelectField = (selectedOption: Option): void => {
    if (!selectedOption) {
      onChange(null);
    } else {
      onChange(`{{${selectedOption.value}}}`);
    }
  };

  const isOptionSelected = (option: Option): boolean => {
    if (!settingValue) {
      return false;
    }

    const path = extractPath(settingValue);

    return path && path === option.value;
  };

  const formatSettingValue = (): any => {
    if (!settingValue) {
      return null;
    }

    const path = extractPath(settingValue);

    if (path) {
      const [workflowStepId, ...rest] = path.split(".");
      const workflowStep = orderedSteps.find((workflowStep: WorkflowStep) => workflowStep._id == workflowStepId);
      const workflowStepRank = workflowStep?.rank;
      const invalid = !checkPathExists(path, context);
      let label;
      if (rest[0] === "_id") {
        label = i18n(context[workflowStep._id]["model"], { rank: workflowStepRank });
      } else {
        label = i18n(rest[0], { rank: workflowStepRank });
      }

      return { value: path, label, invalid };
    }

    return null;
  };

  const selectOptions = (): any => {
    return filteredSteps().reduce((options, step: WorkflowStep) => {
      if (context[step._id]["model"] === objectType) {
        options.push({
          value: `${step._id}._id`,
          label: i18n(objectType, { rank: step.rank })
        });
      }

      if (context[step._id]["nested_objects"]) {
        context[step._id]["nested_objects"].forEach((nestedObjectName) => {
          const nestedObject = context[step._id][nestedObjectName];

          if (nestedObject && nestedObject["model"] === objectType) {
            options.push({
              value: `${step._id}.${nestedObjectName}._id`,
              label: i18n(nestedObjectName, { rank: step.rank })
            });
          }
        });
      }

      return options;
    }, []);
  };

  const options = selectOptions();

  const reactSelectProps = {
    className: "react-select",
    classNamePrefix: "react-select",
    closeMenuOnSelect: true,
    components: { SingleValue },
    isClearable: true,
    isDisabled,
    isOptionSelected,
    isSearchable: false,
    onChange: onSelectField,
    options,
    placeholder: i18n("select_placeholder"),
    styles: customStyles,
    value: formatSettingValue()
  };

  return <Select {...reactSelectProps} />;
};

export default ObjectSelector;
