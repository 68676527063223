import { featureSetTranslation } from "../../utils/featureSetUtils";
import IMAGE_URLS from "../../constants/ImageUrls";

const Banner: React.FC = () => {
  return <div className="features-banner col-md-12">
    <div className="features-banner-wrapper">
      <div className="container-fluid d-flex justify-content-center">
        <div className="col-xl-10 col-lg-11 col-md-12">
          <div className="row">
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <h1 dangerouslySetInnerHTML={{ __html: featureSetTranslation("banner_title_html") }}/>
              <p className="col-md-10 col-12">{ featureSetTranslation("banner_description") }</p>
              <div className="mt-20">
                <a href={ featureSetTranslation("banner_link") } className="btn btn-secondary" target="_blank">{ featureSetTranslation("read_more") }</a>
              </div>
            </div>
            <div className="col-md-6">
              <img src={IMAGE_URLS["featureDeckBanner"]} className="img-fluid"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default Banner;
