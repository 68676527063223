import { deleteGuests, sendBulkAction } from "../utils/APIUtils";
import { cleanUpQueryString } from "../utils/QueryStringUtils";
import { ActionTypes, CALL_API } from "../constants/Constants";
import map from "lodash/map";

module.exports = {

  launchBatchUpdate(eventId, completeSearchQuery, guestFieldKey, guestFieldValue, opts = {}) {
    const { DEV_NULL } = ActionTypes;
    const body = { q: cleanUpQueryString(completeSearchQuery), field: guestFieldKey, value: guestFieldValue, ...opts };
    return {
      [CALL_API]: {
        types: [DEV_NULL, DEV_NULL, DEV_NULL],
        method: "PUT",
        endpoint: `/events/${eventId}/guests/batch_update`,
        body,
        notificationsOptions: { noticeType: "notice_info_from_data" }
      }
    };
  },

  forceSendConfirmationEmail(eventId, completeSearchQuery) {
    const body = { q: cleanUpQueryString(completeSearchQuery), force_resend: true };
    const { DEV_NULL } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, DEV_NULL, DEV_NULL],
        method: "PUT",
        endpoint: `/events/${eventId}/guests/batch_send_confirmation_email`,
        body,
        notificationsOptions: { noticeType: "notice_info_from_data" }
      }
    };
  },

  changeAccessControls(eventId, completeSearchQuery, accesspointId, action) {
    const body = { q: cleanUpQueryString(completeSearchQuery), accesspoint_id: accesspointId };
    const { DEV_NULL } = ActionTypes;
    let endpoint = "";
    let method = "";
    if (action === "create") {
      endpoint = `/events/${eventId}/access_controls/batch_create`;
      method = "POST";
    } else if (action === "delete") {
      endpoint = `/events/${eventId}/access_controls/batch_destroy`;
      method = "DELETE";
    }
    if (endpoint === "") return {};

    return {
      [CALL_API]: {
        types: [DEV_NULL, DEV_NULL, DEV_NULL],
        method,
        endpoint,
        body,
        notificationsOptions: { noticeType: "notice_info_from_data" }
      }
    };
  },

  deleteGuests(completeSearchQuery) {
    return dispatch => {
      deleteGuests(dispatch, completeSearchQuery);
    };
  },

  launchBulkAction(action, completeSearchQuery, page, options) {
    return (dispatch, getState) => {
      const selectedGuests = map(getState().guests.selectedGuests, "id");
      sendBulkAction(dispatch, selectedGuests, action, completeSearchQuery, page, options);
    };
  },

  updateGuest(eventId, guestId, params) {
    const { UPDATE_GUEST_REQUEST, UPDATE_GUEST, UPDATE_GUEST_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [UPDATE_GUEST_REQUEST, UPDATE_GUEST, UPDATE_GUEST_FAILURE],
        method: "PUT",
        body: { guest: params },
        endpoint: `/api/v1/events/${eventId}/guests/${guestId}.json`
      }
    };
  },

  updateGuestsLabels(eventId, query_string, labels_to_push, labels_to_pull) {
    const { DEV_NULL, UPDATE_GUESTS_LABELS } = ActionTypes;
    const body = { q: cleanUpQueryString(query_string), field: "label_ids", labels_to_push, labels_to_pull };
    return {
      [CALL_API]: {
        types: [DEV_NULL, UPDATE_GUESTS_LABELS, DEV_NULL],
        method: "PUT",
        body,
        endpoint: `/events/${eventId}/guests/batch_update`
      }
    };
  },

  launchBatchUpdateForCalculatedField(eventId, completeSearchQuery, fieldKey, notificationsOptions = {}) {
    const { DEV_NULL } = ActionTypes;
    const body = { q: cleanUpQueryString(completeSearchQuery), field: fieldKey, calculate_field: true };
    return {
      [CALL_API]: {
        types: [DEV_NULL, DEV_NULL, DEV_NULL],
        method: "PUT",
        body,
        endpoint: `/events/${eventId}/guests/batch_update`,
        notificationsOptions
      }
    };
  }
};
