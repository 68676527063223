import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import truncate from "lodash/truncate";
import { Dropdown, DropdownButton } from "react-bootstrap";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Icons from "../../constants/Icons";
import { fetchGuestCategory, updateGuestCategory } from "../../actions/GuestCategoryActionCreators";
import GuestCategoryDocuments from "./GuestCategoryDocuments.react";
import GuestCategoryFeature from "../../components/guest_category/GuestCategoryFeature.react";
import { GuestCategory, Feature } from "../../types/GuestCategory";

interface Props {
  guestCategory?: GuestCategory;
  feature: Feature;
  openOnLoad?: boolean;
  defaultHtmlContent: any;
  match: any;
  location: any;
  history: any;
  onCloseConfig(): void;
}

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.guest_category.documents.${key}`, opts);
}

const GuestCategoryDocumentsFeature: React.FC<Props> = (({
  guestCategory,
  feature,
  openOnLoad,
  defaultHtmlContent,
  match,
  location,
  history,
  onCloseConfig
}) => {
  const [isGuestCategoryDocumentsListOpen, setIsGuestCategoryDocumentsListOpen] = useState<boolean>(openOnLoad);

  const dispatch = useDispatch();

  useEffect(() => {
    if (match && !guestCategoryFetched()) {
      dispatch(fetchGuestCategory(match.params.event_id, match.params.guest_category_id));
    }
  }, []);

  const guestCategoryFetched = (): boolean => {
    return guestCategory && Object.keys(guestCategory).length > 0;
  };

  const openGuestCategoryDocumentsList = (): void => {
    setIsGuestCategoryDocumentsListOpen(true);
  };

  const closeGuestCategoryDocumentsList = (): void => {
    setIsGuestCategoryDocumentsListOpen(false);
    enableBodyScroll();
    onCloseConfig();
  };

  const disableBodyScroll = (): void => {
    document.body.style.overflow = "hidden";
  };

  const enableBodyScroll = (): void => {
    document.body.removeAttribute("style");
  };

  const isFeatureEnabled = (): boolean => {
    return guestCategory[feature.field];
  };

  const disableDocuments = (): void => {
    if (confirm(i18n("disable_documents_confirm"))) {
      dispatch(updateGuestCategory(match.params.event_id, guestCategory._id, { [feature.field]: false }));
    }
  };

  const renderBarsIcon = (): JSX.Element => {
    return <i className="fa-regular fa-bars" />;
  };

  const renderDocumentsOptions = (): JSX.Element => {
    return <div className="feature-dropdown-btn" key="feature-dropdown-btn">
      <DropdownButton variant="link" id="DocumentsOptions" title={renderBarsIcon()} align="end">
        <Dropdown.Item eventKey="1" onClick={disableDocuments}><i className="fa-regular fa-circle-xmark" /> {i18n("disable_documents")}</Dropdown.Item>
      </DropdownButton>
    </div>;
  };

  const renderSlidingPaneTitle = (message: string): JSX.Element => {
    return (
      <div className="d-flex">
        <span className="d-flex align-items-center mr-5">
          { message } { " " }
        </span>
        <span className="badge rounded-pill big guest-category" style={{ backgroundColor: guestCategory.label_color }}>
          {truncate(guestCategory.name, { "length": 45 })}
        </span>
      </div>
    );
  };

  const renderSlidingPane = (): JSX.Element => {
    return (
      <SlidingPane
        isOpen={isGuestCategoryDocumentsListOpen}
        width='60%'
        title={renderSlidingPaneTitle(i18n("sliding_pane_documents_title"))}
        onAfterOpen={disableBodyScroll}
        onRequestClose={closeGuestCategoryDocumentsList}
        className="width-100-xs width-80-sm"
        closeIcon={Icons.close()}>
        <GuestCategoryDocuments
          guestCategory={guestCategory}
          featureEnabledField={feature.field}
          afterGuestCategoryUpdate={closeGuestCategoryDocumentsList}
          match={match}
          location={location}
          history={history}
        />
      </SlidingPane>
    );
  };

  const renderFeatureContent = (): JSX.Element => {
    return (
      <GuestCategoryFeature
        feature={feature}
        guestCategory={guestCategory}
        onClick={openGuestCategoryDocumentsList}>
        { isFeatureEnabled() && renderDocumentsOptions() }
      </GuestCategoryFeature>
    );
  };

  if (!guestCategoryFetched()) {
    return <div dangerouslySetInnerHTML={{ __html: defaultHtmlContent }}></div>;
  }

  return <>
    { renderFeatureContent() }
    { renderSlidingPane() }
  </>;
});

export default GuestCategoryDocumentsFeature;
