import { ChangeEvent, useState } from "react";
import { Button } from "react-bootstrap";
import { DocumentTemplate } from "../../types/DocumentTemplate";

interface Props {
  documentTemplate: DocumentTemplate;
  shown: boolean;
  isSaving: boolean;
  errors: any;
  onHide(): void;
  onSubmit(data: any): void;
}

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.document_templates.document_template_form.${key}`, opts);
}

const DocumentTemplateForm: React.FC<Props> = ({ documentTemplate, shown, isSaving, errors, onHide, onSubmit }) => {
  const [name, setName] = useState<string>(documentTemplate.name);

  const changeName = (e: ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value);
  };

  const saveDocumentTemplate = (e): void => {
    e.preventDefault();
    onSubmit({ name });
  };

  const renderFieldError = (field: string): JSX.Element => {
    if (!errors[field])
      return null;

    return <span className="text-danger" style={{ "marginTop": "5px", "display": "inline-block" }}>{ errors[field] }</span>;
  };

  if (!shown) {
    return <div className="website-page-form"></div>;
  }

  return <>
    <div className="website-page-form in">
      <div className="sidebar-header">
        <p className="lead">
          <a onClick={onHide} className="btn-back mr-5 text-center"><i className="fa-regular fa-angle-left"></i></a>
          { i18n("title") }
        </p>
      </div>

      <div className="sidebar-body">
        <div className="card">
          <div className="card-body">
            <div className="mb-3">
              <label className="form-label">{ i18n("name") }</label>
              <input type="text" className="form-control" value={name || ""} onChange={changeName} />
              { renderFieldError("name") }
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="sidebar-footer">
      <Button variant="primary" onClick={saveDocumentTemplate} disabled={isSaving}>
        { i18n("save") }
      </Button>
    </div>
  </>;
};

export default DocumentTemplateForm;
