import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGuestProductsByNumberOfInterests } from "../../actions/GuestProductActionCreators";
import { fetchGuestProductCollections } from "../../actions/GuestProductCollectionsActionCreators";
import Loader from "../../components/shared/Loader.react";
import ReportHeader from "../../components/event_reports/ReportHeader.react";
import { isAuthorized } from "../../utils/aclUtils";
import { numberWithLimitedDecimals } from "../../utils/NumberUtils";
import { identity } from "../../utils/personUtils";
import Select from "react-select";
import { Dropdown } from "react-bootstrap";
import uniqBy from "lodash/uniqBy";


const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`react.event_reports.guest_products_by_number_of_interests.${key}`, options);
};

const NO_COLLECTION_OPTION = { value: "no_collection", label: i18n("no_collection_option_label") };

const GuestProductsByNumberOfInterests: React.FC = () => {
  const eventId = useSelector((state: any) => state.event.id);
  const dataFetched = useSelector((state: any) => state.guestProductsStatistics?.byNumberOfInterests);
  const isFetching = useSelector((state: any) => state.guestProductsStatistics.isFetching);
  const guestProductCollections = useSelector((state: any) => state.guestProductCollections.data || []);

  const dispatch = useDispatch();

  const [selectedGuestProductId, setSelectedGuestProductId] = useState(null);
  const [selectedGuestId, setSelectedSetGuestId] = useState(null);
  const [selectedGuestProductCollectionId, setSelectedGuestProductCollectionId] = useState(NO_COLLECTION_OPTION.value);
  const [result, setResult] = useState([]);
  const [sortField, setSortField] = useState("nb_interests");

  useEffect(() => {
    if (!eventId) return;

    dispatch(fetchGuestProductsByNumberOfInterests(eventId, sortField));
    dispatch(fetchGuestProductCollections(eventId));
  }, [eventId, sortField]);

  const matchCollectionFilter = (item): boolean => {
    if (selectedGuestProductCollectionId === NO_COLLECTION_OPTION.value) {
      return !item.guest_product_collection_id;
    } else {
      return item.guest_product_collection_id === selectedGuestProductCollectionId;
    }
  };

  const matchProductFilter = (item): boolean => {
    return !selectedGuestProductId || selectedGuestProductId && item._id === selectedGuestProductId;
  };

  const matchGuestFilter = (item): boolean => {
    return !selectedGuestId || item.guest._id === selectedGuestId;
  };

  const resultFiltered = (): any[] => {
    return dataFetched?.filter(item => matchCollectionFilter(item) && matchProductFilter(item) && matchGuestFilter(item));
  };

  useEffect(() => {
    setResult(resultFiltered());
  }, [dataFetched]);

  useEffect(() => {
    setResult(resultFiltered());
  }, [selectedGuestProductId, selectedGuestId, selectedGuestProductCollectionId]);

  if (!isAuthorized("reports", "read")) return null;

  const displayAverageRating = (value: number): string => {
    return value ? numberWithLimitedDecimals(value, 1).toString() : "-";
  };

  const exporterProps = (): any => {
    return {
      data: {
        data: result?.map(
          ({ guest, name, nb_interests, average_rating, nb_ratings }) => {
            return {
              guest_first_name: guest.first_name,
              guest_last_name: guest.last_name,
              guest_company_name: guest.company_name,
              name,
              nb_interests,
              average_rating: displayAverageRating(average_rating),
              nb_ratings
            };
          }
        )
      },
      options: { excludeTotal: true },
      columns: ["guest_first_name", "guest_last_name", "guest_company_name", "name", "nb_interests", "average_rating", "nb_ratings"]
    };
  };

  const renderHeader = (): JSX.Element => {
    return <ReportHeader
      title={I18n.t("react.event_reports.event_reports.guest_products_by_number_of_interests")}
      exporterToExcelProps={result?.length > 0 ? exporterProps() : null}
      exportType="guest_products_by_number_of_interests"
    />;
  };

  const renderSortDropdown = (): JSX.Element => {
    const rows = ["nb_interests", "average_rating", "nb_ratings"].map(sort => {
      return <Dropdown.Item key={sort} onClick={(): void => setSortField(sort)}>{i18n(`sort_by_${sort}`)}</Dropdown.Item>;
    });

    return <div className="float-end">
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-sort">
          {i18n(`sort_by_${sortField}`)}
        </Dropdown.Toggle>
        <Dropdown.Menu
          align="end"
          popperConfig={{
            strategy: "fixed",
            onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll"))
          }}
        >
          {rows}
        </Dropdown.Menu>
      </Dropdown>
    </div>;
  };

  const renderTableHeader = (): JSX.Element => {
    const collections = guestProductCollections.reduce((acc, { _id, name }) => {
      if (dataFetched?.some(({ guest_product_collection_id }) => guest_product_collection_id === _id)) {
        acc.push({ value: _id, label: name });
      }
      return acc;
    }, [NO_COLLECTION_OPTION]);

    const products = result?.map(({ _id, name }) => ({ value: _id, label: name }));
    const guests = uniqBy(
      result?.map(({ guest }) => ({ value: guest._id, label: identity(guest) }))
      , "value");
    return (
      <>
        <tr>
          <th style={{ fontWeight: "normal" }}>
            <Select
              options={collections}
              value={collections.filter(collection => selectedGuestProductCollectionId?.includes(collection.value))}
              onChange={(option): any => setSelectedGuestProductCollectionId(option?.value)}
              isClearable={false}
              menuPosition="fixed"
              styles={{
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                menuPortal: ({ left, ...provided }, state) => ({
                  ...provided,
                }),
              }}
            />
          </th>
          <th style={{ fontWeight: "normal" }}>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              options={guests}
              value={guests?.filter(guest => selectedGuestId?.includes(guest.value))}
              placeholder={i18n("select_a_guest")}
              noOptionsMessage={(): any => i18n("no_guest")}
              onChange={(option): any => setSelectedSetGuestId(option?.value)}
              isClearable={true}
              menuPosition="fixed"
              styles={{
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                menuPortal: ({ left, ...provided }, state) => ({
                  ...provided,
                }),
              }}
            />
          </th>
          <th style={{ fontWeight: "normal" }}>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              options={products}
              value={products?.filter(product => selectedGuestProductId?.includes(product.value))}
              placeholder={i18n("select_a_product")}
              noOptionsMessage={(): any => i18n("no_product")}
              onChange={(option): any => setSelectedGuestProductId(option?.value)}
              isClearable={true}
              menuPosition="fixed"
              styles={{
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                menuPortal: ({ left, ...provided }, state) => ({
                  ...provided,
                }),
              }}
            />
          </th>
          <th colSpan={2}>{renderSortDropdown()}</th>
        </tr>
        <tr>
          <th>{I18n.t("react.event_reports.report_table.guest_product_owner")}</th>
          <th>{I18n.t("react.event_reports.report_table.guest_product_name")}</th>
          <th>{I18n.t("react.event_reports.report_table.nb_interests")}</th>
          <th>{I18n.t("react.event_reports.report_table.average_rating")}</th>
          <th>{I18n.t("react.event_reports.report_table.nb_ratings")}</th>
        </tr>
      </>
    );
  };

  const renderTable = (): JSX.Element => {
    if (!dataFetched) return null;

    return <div className="row">
      <div className="col-12">
        <div className="table-responsive table-container">
          <table className="table table-light table-hover table-sm">
            <thead>
              {renderTableHeader()}
            </thead>
            <tbody>
              {renderTableBody()}
            </tbody>
          </table>
        </div>
        {result?.length === 0 ? renderEmptyMsg() : ""}
      </div>
    </div>;
  };

  const renderTableBody = (): JSX.Element[] => {
    return result?.map(
      ({ _id, name, nb_interests, average_rating, nb_ratings, guest }) => {
        return (
          <tr key={_id}>
            <td>
              <b>{guest.first_name} {guest.last_name}</b>
              <div><small>{guest.company_name}</small></div>
            </td>
            <td>{name}</td>
            <td>{nb_interests}</td>
            <td>{displayAverageRating(average_rating)}</td>
            <td>{nb_ratings}</td>
          </tr>
        );
      }
    );
  };

  const renderEmptyMsg = (): JSX.Element => {
    return <div className="card nothing-yet">
      <h4>{i18n("no_data")}</h4>
    </div>;
  };

  return <>
    { renderHeader() }
    { isFetching ? <Loader/> : renderTable() }
  </>;
};

export default GuestProductsByNumberOfInterests;
