import { ActionTypes } from "../constants/Constants";

const initialState = {
  data: null,
  errors: null
};

function customEmails(state = initialState, action) {
  switch (action.type) {
  case ActionTypes.FETCH_CUSTOM_EMAILS_SUCCESS:
    return { ...state, data: action.data };
  default:
    return state;
  }
}

export default customEmails;
