import { Component } from "react";

class Paginate extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { previousEnabled, nextEnabled, pull, classNames } = this.props;

    let prev = <li className="page-item" onClick={this.props.handlePrevious}><a className="page-link">←</a></li>;
    if (!previousEnabled) {
      prev = <li className="page-item disabled"><span className="page-link">←</span></li>;
    }

    let next = <li className="page-item" onClick={this.props.handleNext}><a className="page-link">→</a></li>;
    if (!nextEnabled) {
      next = <li className="page-item disabled"><span className="page-link">→</span></li>;
    }

    let classes = "pagination";
    if (classNames) {
      classes = `${classes} ${classNames}`;
    }
    if (pull) {
      classes = `${classes} float-${pull}`;
    }

    return (
      <ul className={ classes }>
        { prev }
        { next }
      </ul>
    );
  }
}

export default Paginate;
