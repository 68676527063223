import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchSavedSearches } from "../../actions/SavedSearchActionCreators";
import { fetchCurrentSavedSearch } from "../../actions/SavedSearchActionCreators";
import FilterDropdown from "../../components/FilterDropdown.react";

class SegmentPicker extends Component {

  constructor(props) {
    super(props);
    this.onSelectSegment = this.onSelectSegment.bind(this);
    this.emptySelection = this.emptySelection.bind(this);
    this.state = {
      selectedSegment: null
    };
  }

  componentDidMount() {
    const { fetchSavedSearches, segments, eventId } = this.props;
    if (segments.length === 0 && fetchSavedSearches) {
      fetchSavedSearches(eventId);
    }
    this.setDefaultSegment();
  }

  componentDidUpdate(prevProps) {
    if (prevProps != this.props) {
      this.setDefaultSegment();
    }
  }

  setDefaultSegment(props = this.props) {
    const { defaultSegmentId, segments, defaultSegmentQuery, currentSegment } = props;
    const { selectedSegment } = this.state;
    if (segments.length == 0 || (selectedSegment && selectedSegment._id == defaultSegmentId)) {
      return;
    }

    if (selectedSegment && !defaultSegmentId) {
      this.setState({
        selectedSegment: null
      });
    }

    if (defaultSegmentId) {
      const segment = segments.find(segment => segment._id === defaultSegmentId);
      this.setState({
        selectedSegment: segment
      });
      return;
    }

    if (currentSegment && currentSegment.search_query === defaultSegmentQuery) {
      this.setState({
        selectedSegment: currentSegment
      });
      return;
    }

    if (defaultSegmentQuery) {
      const { fetchCurrentSavedSearch, eventId } = props;
      fetchCurrentSavedSearch(eventId, defaultSegmentQuery);
    }
  }

  onSelectSegment(segmentIds) {
    const { segments } = this.props;
    const selectedSegment = segments.find(segment => segment._id === segmentIds[0]);
    this.setState({
      selectedSegment
    });
    this.props.onSelectSegment(selectedSegment);
  }

  emptySelection() {
    this.setState({
      selectedSegment: null
    });
    this.props.onSelectSegment({ search_query: "" });
  }

  render() {
    const { segments } = this.props;
    const { selectedSegment } = this.state;
    const selectedIds = selectedSegment ? [selectedSegment._id] : [];
    const title = selectedSegment ? selectedSegment.name : null;

    return (
      <div>
        <FilterDropdown
          id="segments"
          translationKey="segments"
          items={segments}
          selectedItemIds={selectedIds}
          showCells={false}
          hasSelectAll={false}
          multipleSelect={false}
          sortItems={false}
          selectedFirst={false}
          itemIdKey="_id"
          onChange={this.onSelectSegment}
          allowUnselect={true}
          title={title}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    segments: state.savedSearches.data,
    currentSegment: state.currentSavedSearch
  };
}

const mapDispatchToProps = {
  fetchSavedSearches,
  fetchCurrentSavedSearch
};

SegmentPicker.defaultProps = {
  defaultSegmentId: null,
  defaultSegmentQuery: null, // will guess
  eventId: null, //required if you want to use defaultSegmentQuery
  segments: []
};

SegmentPicker.propTypes = {
  onSelectSegment: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(SegmentPicker);
export { SegmentPicker };
