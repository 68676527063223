import { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { DropdownButton } from "react-bootstrap";

import { pathToReports } from "../../utils/pathUtils";

import SegmentPicker from "../../containers/shared/SegmentPicker.react";
import ExportToExcelLink from "../../components/ExportToExcelLink.react";
import FilterDropdown from "../../components/FilterDropdown.react";
import EventPicker from "../../containers/shared/EventPicker.react";

class ReportHeader extends Component {

  renderTitle() {
    const { title } = this.props;
    return (
      <div className="col-auto">
        <h2 className="mb-0">
          <Link to={pathToReports()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></Link>
          { title }
        </h2>
      </div>
    );
  }

  renderFieldSelector() {
    const { fieldSelectorProps } = this.props;
    return fieldSelectorProps ? (
      <div className="col-auto">
        <FilterDropdown
          id="guest_fields"
          items={fieldSelectorProps.items}
          multipleSelect={false}
          hasSelectAll={false}
          onChange={fieldSelectorProps.onChangeField}
          itemIdKey="key"
          translationKey="guest_fields"
          showCells={false}
          sort={true}
          title={fieldSelectorProps.title}
        />
      </div>
    ) : null;
  }

  renderExportLink() {
    const { exporterToExcelProps, exportType } = this.props;
    return exporterToExcelProps ? (
      <div className="col-auto">
        <ExportToExcelLink data={exporterToExcelProps.data} columnKeys={exporterToExcelProps.columns} type={exportType || "attendance_by_field"} options={exporterToExcelProps.options} />
      </div>
    ) : null;
  }

  renderDateDropdown() {
    const { dateDropDownProps } = this.props;
    return dateDropDownProps ? (
      <div className="col-auto">
        <DropdownButton variant="secondary" title={dateDropDownProps.title} id="dropdown-date" align="end">
          { dateDropDownProps.menuItemRows }
        </DropdownButton>
      </div>
    ) : null;
  }

  renderSegmentPicker() {
    const { segmentPickerProps } = this.props;
    return segmentPickerProps ? (
      <div className="col-auto">
        <SegmentPicker onSelectSegment={segmentPickerProps.onChangeQuery} defaultSegmentQuery={segmentPickerProps.query} eventId={segmentPickerProps.event_id} />
      </div>
    ) : null;
  }

  renderEventToComparePicker() {
    const { pickerForEventComparison, onEventToCompareChange, eventId, eventToCompareId } = this.props;
    return pickerForEventComparison && (
      <div className="col-auto">
        <EventPicker
          onSelectEvent={onEventToCompareChange}
          emptyTitle={I18n.t("react.event_reports.report_header.pick_event_to_compare")}
          ignoreEventsWithIds={[eventId]}
          initialEventId={eventToCompareId}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="subtitle-page">
            <div className="subtitle-page-content">
              <div className="row align-items-center g-2">
                { this.renderTitle() }
                { this.renderExportLink() }
                { this.renderFieldSelector() }
              </div>
              <div className="row align-items-center g-2">
                { this.renderDateDropdown() }
                { this.renderSegmentPicker() }
                { this.renderEventToComparePicker() }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportHeader;

ReportHeader.propTypes = {
  title: PropTypes.string,

  fieldSelectorProps: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string
      })
    ),
    onChangeField: PropTypes.func,
    title: PropTypes.string
  }),

  exporterToExcelProps: PropTypes.shape({
    data: PropTypes.object,
    columns: PropTypes.array,
    options: PropTypes.shape({
      mapping: PropTypes.object,
      fieldPrettyName: PropTypes.function
    })
  }),

  segmentPickerProps: PropTypes.shape({
    onChangeQuery: PropTypes.func,
    query: PropTypes.string,
    event_id: PropTypes.string,
    style: PropTypes.object
  }),

  dateDropDownProps: PropTypes.shape({
    title: PropTypes.string,
    menuItemRows: PropTypes.array
  }),

  pickerForEventComparison: PropTypes.bool,
  onEventToCompareChange: PropTypes.func,
  eventId: PropTypes.string
};

ReportHeader.defaultProps = {
  title: "Report title"
};
