import { ActionTypes } from "../constants/Constants";

module.exports = {
  setSelectedGuestSortOptions(objectIds) {
    return {
      type: ActionTypes.SET_SELECTED_GUEST_SORT_OPTIONS,
      objectIds
    };
  }

};
