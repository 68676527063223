import { Component } from "react";
import Modal from "react-modal";
import { defaultModalSmallStyles } from "../constants/Style";
import PropTypes from "prop-types";

class MessageModal extends Component {

  renderModalContent() {
    const { message, iconClassName, iconStyle, onRequestClose } = this.props;
    return (
      <div>
        <div className="modal-header" style={{ borderBottom: "0px" }}>
          <button type="button" onClick={ onRequestClose } className="btn-close" aria-label={I18n.t("close")}></button>
        </div>
        <div className="modal-body">
          <div style={{ textAlign: "center" }}>
            <div className="mb-10">
              <i style={iconStyle} className={iconClassName} />
            </div>
            {message}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { onRequestClose, isOpen } = this.props;
    return (
      <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={defaultModalSmallStyles} contentLabel="Modal">
        { this.renderModalContent() }
      </Modal>
    );
  }
}

MessageModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  iconClassName: PropTypes.string.isRequired,
  iconStyle: PropTypes.object
};

export default MessageModal;
