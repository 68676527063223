import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import isEqual from "lodash/isEqual";
import Loader from "../components/shared/Loader.react";
import { fetchEventAtlasJwtToken } from "../actions/EventAtlasJwtTokenActionCreator";
import AtlasChartToExcelLink from "./dashboard/AtlasChartToExcelLink.react";

const BASE_ATLAS_DASHBOARD_URL = "https://charts.mongodb.com/charts-eventmaker-ylkfn";
const MAX_DATA_AGE = 600; // in seconds, is used for the cache duration AND the auto refresh

interface Props {
  chartId: string,
  chartWrapperHeight?: string,
  customFilter?: any,
  eventId: string,
  exportOptions?: any,
  height?: string,
  onChartDataChanged?: (data: any) => any
  subtitle?: string,
  title: string,
  width?: string,
}

const i18n = (key: string): string => {
  return I18n.t(`react.atlas_chart.${key}`);
};

const AtlasChart: React.FC<Props> = ({
  chartId,
  chartWrapperHeight,
  customFilter,
  eventId,
  exportOptions,
  height,
  onChartDataChanged,
  subtitle,
  title,
  width,
}) => {
  const chartWrapperStyle = chartWrapperHeight ? { height: chartWrapperHeight } : {};
  const loaderHeight = chartWrapperHeight || height;
  const isFetching = useSelector((state: any) => state.eventAtlasJwtTokens.isFetching[eventId]);
  const jwtToken = useSelector((state: any) => state.eventAtlasJwtTokens.data[eventId]);
  const dispatch = useDispatch();

  const chartDiv = useRef(null);
  const previousCustomFilter = useRef({});
  const previousChartId = useRef({});

  const [chartData, setChartData] = useState(null);
  const onDataChanged = (data: any | null): void => {
    onChartDataChanged && onChartDataChanged(data);
    setChartData(data);
  };

  const hasDataToDisplay = (chartData?.documents?.length || 0) > 0;

  useEffect(() => {
    if (!eventId || isFetching || jwtToken) return;

    dispatch(fetchEventAtlasJwtToken(eventId));
  }, [eventId]);

  const [sdk, setSdk] = useState(null);
  useEffect(() => {
    if (!jwtToken) return;

    const newSdk = new ChartsEmbedSDK({
      baseUrl: BASE_ATLAS_DASHBOARD_URL,
      getUserToken: (): string => jwtToken
    });
    setSdk(newSdk);
  }, [jwtToken]);

  const [chart, setChart] = useState(null);
  const [rendered, setRendered] = useState(null);
  useEffect(() => {
    if (!sdk) return;
    if (isEqual(previousCustomFilter.current, customFilter) && previousChartId.current === chartId) return;

    setRendered(false);
    onDataChanged(null);
    previousCustomFilter.current = customFilter;
    previousChartId.current = chartId;

    setChart(
      sdk.createChart(
        {
          chartId,
          height,
          width,
          theme: "light",
          autoRefresh: true,
          maxDataAge: MAX_DATA_AGE,
          showAttribution: false,
          filter: customFilter || {}
        }
      )
    );
  }, [sdk, chartId, customFilter]);

  useEffect(() => {
    if (!chart) return;

    chart
      .render(chartDiv.current)
      .then(() => setRendered(true))
      .catch(err => console.log("Error during Charts rendering.", err));
  }, [chart]);

  useEffect(() => {
    if (!rendered) return;

    chart
      .getData()
      .then((data) => onDataChanged(data))
      .catch(err => console.log("Error while fetching chart data.", err));
  }, [rendered]);

  const renderLoader = (): JSX.Element => {
    if (rendered) return null;

    return <Loader size="large" inline={false} containerHeight={loaderHeight} />;
  };

  const renderNoData = (): JSX.Element => {
    if (!rendered) return null;
    if (hasDataToDisplay) return null;

    return <div className="nothing-yet" style={{ height: loaderHeight }} >
      <h4>{ i18n("no_data_to_render") }</h4>
    </div>;
  };

  return <div className="text-center">
    { title ? <h2 className="mt-20">{title}</h2> : null }
    { subtitle ? <h3>{subtitle}</h3> : null }
    { hasDataToDisplay && <AtlasChartToExcelLink chartData={chartData} exportOptions={exportOptions} /> }
    { renderLoader() }
    { renderNoData() }
    <div className={ `chart d-flex flex-row justify-content-center ${rendered && hasDataToDisplay ? "" : "d-none"}`} ref={chartDiv} style={chartWrapperStyle}/>
  </div>;
};

AtlasChart.defaultProps = {
  height: "600px",
  width: "100%",
};

export default AtlasChart;
