import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import querystring from "querystring";

import { CAMPAIGN_STEPS, NavTabGuestCampaign, GuestCampaignStatusLocked } from "../../constants/Constants";
import { urlWithQuery } from "../../utils/pathUtils";

import { guestCountFromQS } from "../../actions/SearchQueryActionCreators";
import { fetchGuestCampaign, updateGuestCampaign } from "../../actions/GuestCampaignActionCreators";
import { createLiquidTemplate } from "../../actions/GuestCampaignActionCreators";
import { campaignStepBreadcrumbLinks } from "../../utils/campaignStepUtils";

import ErrorMessage from "../../components/shared/ErrorMessage.react";
import Title from "../../components/guest_campaign/Title.react";
import Breadcrumb from "../../components/shared/Breadcrumb.react";
import FormCodeEdit from "../../components/guest_campaign/FormCodeEdit.react";
import NavTab from "../../components/guest_campaign/NavTab.react";
import requiresProps from "../../utils/requiresProps";
import { withRouter } from "react-router-dom";
import { redirectIfUnauthorized, isAuthorized } from "../../utils/aclUtils";

class GuestCampaignCodeIt extends Component {

  constructor(props) {
    redirectIfUnauthorized("campaigns", "manage");
    super(props);
    const privateMethods = [
      "_queryString",
      "_urlGuestList",
      "_updateGuestCampaign",
      "_urlGuestCampaignEmail",
      "_changeTab",
      "_renderLinkToPreviewEmail",
      "_onTemplateSave",
      "_onTemplateNameChange",
      "_urlFinalStep"
    ];
    privateMethods.forEach((item) => { this[item] = this[item].bind(this); });
    this.state = {
      selectedTab: NavTabGuestCampaign[0],
      templateName: "",
      launchedCreateTemplate: false
    };
  }

  componentDidUpdate(prevProps) {
    const { accountTemplates } = this.props;
    const { templateName, launchedCreateTemplate } = this.state;

    if (launchedCreateTemplate && prevProps.accountTemplates.length !== accountTemplates.length) {
      const nextState = Object.assign({}, this.state);
      if (this.props.createTemplateError) {
        nextState.launchedCreateTemplate = false;
      } else if (accountTemplates.find(t => t.name === templateName)) {
        const { history, match } = this.props;
        history.push(this._urlGuestCampaignEmail(match.params.guest_campaign_id));
        nextState.launchedCreateTemplate = false;
      }
      this.setState(nextState);
    }
  }


  _queryString() {
    return querystring.parse(this.props.location.search.substring(1)).q;
  }

  _urlGuestList() {
    return urlWithQuery(this._queryString(), "guests");
  }

  _urlGuestCampaignEmail(campaignId = ":id") {
    return urlWithQuery(this._queryString(), `campaign/${campaignId}/email/?tab=preview`);
  }

  _updateGuestCampaign(parameters, sendFile = false) {
    const { updateGuestCampaign, match } = this.props;
    updateGuestCampaign(match.params.event_id, match.params.guest_campaign_id, parameters, sendFile, this._urlGuestCampaignEmail());
  }

  _onTemplateNameChange(e) {
    this.setState({
      templateName: e.target.value
    });
  }

  _onTemplateSave() {
    const { guestCampaign, event, createLiquidTemplate, match } = this.props;
    const { templateName } = this.state;
    const createParams = {
      liquid_template: {
        type: "campaign_email",
        name: templateName,
        body: guestCampaign.body
      },
      liquid_field_id: guestCampaign._id
    };
    createLiquidTemplate(match.params.locale, event.account_id, createParams);
    this.setState({
      launchedCreateTemplate: true
    });
  }

  _changeTab() {
    const { history, match } = this.props;
    return (newSelectedTab) => {
      history.replace(`${urlWithQuery("", `campaign/${match.params.guest_campaign_id}/email`)}?tab=${newSelectedTab}`);
      this.setState({ selectedTab: newSelectedTab });
    };
  }

  _urlFinalStep() {
    const { match } = this.props;
    return urlWithQuery(this._queryString(), `campaign/${match.params.guest_campaign_id}/summarize`);
  }

  _renderSaveAsTemplate() {
    const { templateName, launchedCreateTemplate } = this.state;
    const addonContent = launchedCreateTemplate ? I18n.t("react.guest_campaign.creating_template") : I18n.t("react.guest_campaign.save_as_template");
    const addonOnClickFn = launchedCreateTemplate ? null : this._onTemplateSave;
    return (
      <div className="input-group">
        <input className="form-control" type="text" onChange={this._onTemplateNameChange} value={templateName} placeholder={I18n.t("react.guest_campaign.template_name")}/>
        <div className="btn btn-secondary" type="button" onClick={addonOnClickFn}>
          <i className="fa-regular fa-save"></i>
          <span className="ml-10">{ addonContent }</span>
        </div>
      </div>
    );
  }

  _renderLinkToPreviewEmail() {
    const { match } = this.props;
    return (
      <div className="mt-10">
        <Link to={urlWithQuery(this._queryString(), `campaign/${match.params.guest_campaign_id}/email?tab=template`)}>
          { I18n.t("react.guest_campaign.preview_email") }
        </Link>
      </div>
    );
  }

  render() {
    if (!isAuthorized("campaigns", "manage")) return;
    const { guestCount, guestCampaign, errors, createTemplateError } = this.props;
    const { selectedTab } = this.state;

    const content = guestCampaign ? guestCampaign.body : null;
    const nextUrl = (guestCampaign && GuestCampaignStatusLocked.includes(guestCampaign.status)) ? null : (selectedTab === "fields" ? this._updateGuestCampaignAndGoToPreview : this._urlFinalStep());
    const nextName = selectedTab === "fields" ? I18n.t("react.guest_campaign.save_and_preview") : I18n.t("react.guest_campaign.go_to_final_step");

    return (
      <div style={{ marginBottom: "50px" }}>
        <Title nbGuest={guestCount} />
        <div className="row">
          <div className="col-md-8 offset-md-2 mt-30">
            <div className="mb-10">
              <NavTab selected={selectedTab} items={NavTabGuestCampaign} onChange={this._changeTab()} />
            </div>
            {errors.length > 0 ? <ErrorMessage errors={errors} /> : null}
            {createTemplateError ? <ErrorMessage errors={createTemplateError} /> : null}
            <div style={{ marginBottom: "5px" }}>
              { this._renderSaveAsTemplate() }
            </div>
            <FormCodeEdit content={content} handleSave={this._updateGuestCampaign} />
            { this._renderLinkToPreviewEmail() }
          </div>
        </div>
        <Breadcrumb
          steps={campaignStepBreadcrumbLinks(guestCampaign, {
            guestCampaignIsPersisted: true,
            searchQuery: this._queryString()
          })}
          activeStep={CAMPAIGN_STEPS[1]}
          backUrl={this._urlGuestList()}
          nextUrl={nextUrl}
          nextName={nextName}
          backName={I18n.t("react.guest_campaign.people_list")}
          modalIsOpen={false}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    event: state.event,
    guestCount: state.appearance.selectedGuestCountAll,
    guestCampaign: state.guestCampaign.guestCampaign,
    accountTemplates: state.guestCampaign.accountTemplates,
    createTemplateError: state.guestCampaign.createTemplateError,
    errors: state.guestCampaign.errors
  };
}

const mapDispatchToProps = {
  fetchGuestCampaign,
  updateGuestCampaign,
  guestCountFromQS,
  createLiquidTemplate
};

export default connect(mapStateToProps, mapDispatchToProps)(
  requiresProps(withRouter(GuestCampaignCodeIt), {
    requirements: {
      guestCampaign: {
        fn: (props) => {
          const { match, fetchGuestCampaign } = props;
          fetchGuestCampaign(match.params.event_id, match.params.guest_campaign_id);
        },
        statePath: "guestCampaign.guestCampaign"
      },
      guestCount: {
        waitFor: ["savedSearch"],
        fn: (props) => {
          const { savedSearch, guestCountFromQS } = props;
          guestCountFromQS(savedSearch.search_query, true);
        },
        statePath: "appearance.selectedGuestCountAll"
      }
    }
  })
);
