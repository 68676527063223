import { useSelector } from "react-redux";
import Select from "react-select";
import MultiSelect from "../MultiSelect.react";
import withProgramFilter from "../withProgramFilter.react";
import { FilterProps, FilterOption } from "../../../types/Program";

const TraitFilter: React.FC<FilterProps> = ({ programFilter, onChangeFilterPresets }) => {
  const metadata = useSelector((state: any) => state.programs.metadata);

  const onChangeTraitKey = (option: FilterOption) : void => {
    onChangeFilterPresets(option.value, []);
  };

  const onChangeTraitValues = (options: FilterOption[]): void => {
    onChangeFilterPresets(programFilter.trait_key, options.map(({ value }) => value));
  };

  const buildKeyOptions = (): FilterOption[] => {
    if (!metadata[programFilter._type]) return [];

    return Object.keys(metadata[programFilter._type]).map((traitKey) => {
      return { label: traitKey, value: traitKey };
    });
  };

  const buildValueOptions = (): FilterOption[] => {
    if (!programFilter.trait_key || !metadata[programFilter._type] || !metadata[programFilter._type][programFilter.trait_key]) return [];

    return metadata[programFilter._type][programFilter.trait_key].map((traitValue) => {
      return { label: traitValue, value: traitValue };
    });
  };

  const selectedTraitKey = (): FilterOption => {
    if (!programFilter.trait_key) return null;

    return { label: programFilter.trait_key, value: programFilter.trait_key };
  };

  const selectedTraitValues = (): FilterOption[] => {
    if (!programFilter.trait_key || !metadata[programFilter._type]) return [];

    return (programFilter["preset_args"][programFilter.trait_key] || []).map((traitValue) => {
      return { label: traitValue, value: traitValue };
    });
  };

  return <div className="row g-2 align-items-center">
    <div className="col-auto">
      <Select
        className="react-select"
        classNamePrefix="react-select"
        options={buildKeyOptions()}
        value={selectedTraitKey()}
        placeholder={I18n.t("react.programs.trait_filter.key_dropdown_placeholder")}
        onChange={onChangeTraitKey}
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            width: "200px"
          })
        }} />
    </div>
    <label className="col-form-label col-auto">
      {I18n.t("react.programs.trait_filter.equals_to")}
    </label>
    <div className="col-auto">
      <MultiSelect
        options={buildValueOptions()}
        values={selectedTraitValues()}
        placeholder={I18n.t("react.programs.trait_filter.value_dropdown_placeholder")}
        onChange={onChangeTraitValues}
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            width: "500px"
          })
        }} />
    </div>
  </div>;
};

export default withProgramFilter(TraitFilter, "selection");
