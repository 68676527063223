import { Component, ChangeEvent } from "react";
import last from "lodash/last";
import isEqual from "lodash/isEqual";
import classNames from "classnames";
import { DragTypes } from "../../constants/Constants";
import { WebsiteMenuItem } from "../../types/WebsiteMenuItem";
import Sortable from "../../components/Sortable.react";
import { translatedTextFromJson } from "../../utils/templatesBuilderUtils";
import { findNestedMenuItems, findNestedMenuItem } from "../../utils/websiteMenuUtils";
import MenuSettings from "../../../lib/builders/button_settings.json";
import { connect } from "react-redux";
import { dragIcon } from "../../constants/Icons";

const MenuItemTypesWithPaneAuto = ["registration", "document", "accepted", "declined", "sso_sign_in_or_register"];
const submenusBackgroundColors = ["#F2DCD3", "#B5DCCD", "#A4C9D7" ];

interface Props {
  menuItems: WebsiteMenuItem[];
  pathToMenuItemBeingEdited: number[];
  allMenuItems: WebsiteMenuItem[];
  addItems(nbToAdd: number, pathTo: number[]): any;
  updateMenuItemsOffline(menuItems: WebsiteMenuItem[]): void;
  errors: any;
  displayItemOptionsPane(pathTo: number[]): any;
  changeMenuItemOption(pathTo: number[]): any;
  pages: any[];
  pathTo: number[];
  displayAssetsManager(pathTo: number[]): any;
  assets: any[];
}

interface State {
  subMenuItemOpen: WebsiteMenuItem;
}

class MenuItemsList extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    [
      "changeMenuItemParam",
      "changeRegistrationLabelOption",
      "onFinishUpload",
      "handleDrop",
      "destroyMenuItem",
      "displaySubMenuRow",
      "closeSubMenuRow"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      subMenuItemOpen: null
    };
  }

  changeMenuItemParam(pathTo: number[], attributeName: string): (e: ChangeEvent<any>) => void {
    const { allMenuItems, updateMenuItemsOffline } = this.props;

    return (e): void => {
      const editedItem = findNestedMenuItem(pathTo, allMenuItems);
      editedItem[attributeName] = e.target.value;
      if (attributeName == "type") {
        editedItem["link"] = "";
        editedItem["page_id"] = "";

        if (e.target.value == "registration") {
          editedItem["name"] = "";
        }

        if (MenuItemTypesWithPaneAuto.includes(e.target.value)) {
          this.props.displayItemOptionsPane(pathTo)(e);
        }

        if (e.target.value == "menu") {
          this.displaySubMenuRow(editedItem)();
        }
      }

      updateMenuItemsOffline(allMenuItems);
    };
  }

  changeRegistrationLabelOption(pathTo: number[], optionKey: string): (e: ChangeEvent<HTMLInputElement>) => void {
    return (e): void => {
      this.props.changeMenuItemOption(pathTo)(optionKey, e.target.value);
    };
  }

  destroyMenuItem(pathTo: number[]): () => void {
    const { allMenuItems, updateMenuItemsOffline } = this.props;

    return (): void => {
      const itemIndex = pathTo.pop();
      const menuItemsOfSameLevel = findNestedMenuItems(pathTo, allMenuItems);
      menuItemsOfSameLevel[itemIndex]._destroy = true;

      if (this.state.subMenuItemOpen && this.state.subMenuItemOpen.rank == menuItemsOfSameLevel[itemIndex].rank) {
        this.closeSubMenuRow();
      }

      updateMenuItemsOffline(allMenuItems);
    };
  }

  onFinishUpload(item: WebsiteMenuItem): (e: ChangeEvent<HTMLInputElement>, imageUrl: string) => void {
    const { menuItems, updateMenuItemsOffline } = this.props;
    const index = menuItems.indexOf(item);

    return (_, imageUrl): void => {
      menuItems[index].icon_url = imageUrl;
      updateMenuItemsOffline(menuItems);
    };
  }

  handleDrop(pathTo: number[]): (prevId: string, id: number, nextId: string, estimatedIndex: number) => void {
    const { allMenuItems, updateMenuItemsOffline } = this.props;

    return (previousItemId, itemId, nextItemId, estimatedIndex): void => {
      let sameLevelMenuItems = findNestedMenuItems(pathTo, allMenuItems);
      const itemIndex = sameLevelMenuItems.findIndex(av => av.rank === itemId);
      sameLevelMenuItems[itemIndex] = Object.assign({}, sameLevelMenuItems[itemIndex], { rank: estimatedIndex });

      const sortFunction = (a: any, b: any): number => {
        if (a.rank > b.rank) {
          return 1;
        } else if (a.rank < b.rank) {
          return -1;
        } else {
          return 0;
        }
      };
      sameLevelMenuItems = sameLevelMenuItems.sort(sortFunction);

      updateMenuItemsOffline(allMenuItems);
    };
  }

  displaySubMenuRow(menuItem: WebsiteMenuItem): () => void {
    return (): void => {
      this.setState({ subMenuItemOpen: menuItem });
    };
  }

  closeSubMenuRow(): void {
    this.setState({ subMenuItemOpen: null });
  }

  rowBackgroundColor(pathTo: number[]): string {
    const { pathToMenuItemBeingEdited } = this.props;

    if (pathToMenuItemBeingEdited && isEqual(pathToMenuItemBeingEdited, pathTo))
      return "#ddd";

    return pathTo.length > 1 ? submenusBackgroundColors[(pathTo.length - 2) % 3] : null;
  }

  renderMenuItemError(item: WebsiteMenuItem, param: any): JSX.Element {
    const { errors } = this.props;
    return errors[item._id] && errors[item._id][param] && <div className="form-text text-danger">{ errors[item._id][param] }</div>;
  }

  renderTypeSelect(item: WebsiteMenuItem, pathTo: number[]): JSX.Element {
    const options = MenuSettings.find((setting) => setting.id = "button_type").options.map(type => {
      return <option value={type.value} key={type.value}>{ translatedTextFromJson(type, "label") }</option>;
    });

    return (
      <select
        name="menu-item-type"
        className="form-select"
        value={ item.type }
        onChange={this.changeMenuItemParam(pathTo, "type")}
        autoFocus
      >
        {options}
        <option value="menu" key="menu">Menu</option>
      </select>
    );
  }

  renderLinkOrPageSelector(item: WebsiteMenuItem, pathTo: number[]): JSX.Element {
    if (item.type == "external_link") {
      return this.renderLinkInput(item, pathTo);
    } else if (item.type == "website_page") {
      return this.renderPagesDropdown(item, pathTo);
    } else if (item.type == "file_link") {
      return this.renderFilePicker(item, pathTo);
    } else {
      return null;
    }
  }

  renderLinkInput(item: WebsiteMenuItem, pathTo: number[]): JSX.Element {
    return <div>
      <input type="text"
        name="menu-item-link"
        className="form-control"
        value={ item.link }
        onChange={this.changeMenuItemParam(pathTo, "link")} />
      { this.renderMenuItemError(item, "link") }
    </div>;
  }

  renderPagesDropdown(item: WebsiteMenuItem, pathTo: number[]): JSX.Element {
    const { pages } = this.props;

    return <div>
      <select key={`select-type-${item._id}`}
        name="menu-item-type"
        className="form-select"
        value={ item.page_id || "" }
        onChange={this.changeMenuItemParam(pathTo, "page_id")}
        autoFocus>
        <option key={`type-option-${item._id}-#`} value="#"> { I18n.t("react.menu_items.form.select_a_page") } </option>
        { pages.map(page => {
          return <option key={`type-option-${item._id}-${page._id}`} value={page._id}> { page.name } </option>;
        }) }
      </select>
      { this.renderMenuItemError(item, "page_id") }
    </div>;
  }

  renderFilePicker(item: WebsiteMenuItem, pathTo: number[]): JSX.Element {
    const { displayAssetsManager, assets } = this.props;
    const asset = assets && assets.find(asset => asset._id === item.options["asset_id"]);
    const fileName = asset ? asset.name : I18n.t("react.menu_items.form.select_a_file");

    return (
      <a className="btn btn-secondary asset-selector" onClick={displayAssetsManager(pathTo)}>
        <i className="fa-regular fa-file" aria-hidden="true" />
        <span className="ml-5">{fileName}</span>
      </a>
    );
  }

  renderLabelInput(item: WebsiteMenuItem, pathTo: number[]): JSX.Element {
    if (item.type == "registration")
      return this.renderRegistrationLabelInput(item, pathTo);

    return <div>
      <input type="text"
        name="menu-item-name"
        className="form-control"
        value={ item.name }
        onChange={this.changeMenuItemParam(pathTo, "name")}
        autoFocus />
      { this.renderMenuItemError(item, "name") }
    </div>;
  }

  renderRegistrationLabelInput(item: WebsiteMenuItem, pathTo: number[]): JSX.Element {
    let labelOptionKey = "button_label_known_visitors";

    if (item.options.force_new_registration) {
      labelOptionKey = "button_label_force_new_registration";
    } else if (item.options.confirmation_page_known_visitors) {
      labelOptionKey = "button_label_confirmation_page_known_visitors";
    }

    return <input type="text"
      name="menu-item-name"
      className="form-control"
      value={ item.options[labelOptionKey] }
      onChange={this.changeRegistrationLabelOption(pathTo, labelOptionKey)} />;
  }

  renderItemRows(item: WebsiteMenuItem, pathTo: number[]): JSX.Element[] {
    const { errors } = this.props;
    const { subMenuItemOpen } = this.state;

    if (item._destroy)
      return [<div></div>];

    const classes = classNames({
      "card": true,
      "card-danger": errors[item._id]
    });

    const itemIndex = last(pathTo);

    const style = {
      marginBottom: "10px",
      backgroundColor: this.rowBackgroundColor(pathTo),
      borderColor: pathTo.length > 1 ? "transparent" : null
    };

    let itemRows = [<div key={itemIndex} className={classes} style={style}>
      <div className="card-body" style={{ padding: "8px" }}>
        <div className="row align-items-center g-2 flex-nowrap">
          <div className="col-auto" style={{ minWidth: "85px" }}>
            {!!subMenuItemOpen || <a style={{ cursor: "grab" }} className="btn btn-link mb-5">
              { dragIcon() }
            </a>}
            { this.renderToggleSubmenuButton(item) }
          </div>
          <div className="col-2"> { this.renderTypeSelect(item, pathTo) } </div>
          <div className="col-3"> { this.renderLinkOrPageSelector(item, pathTo) } </div>
          <div className="col-3"> { this.renderLabelInput(item, pathTo) } </div>
          <div className="text-center col-3">
            <a className="btn btn-secondary mr-5" onClick={this.props.displayItemOptionsPane(pathTo)}>
              <i className="fa-regular fa-gear" aria-hidden="true" />
            </a>
            <a className="btn btn-danger" onClick={this.destroyMenuItem(pathTo)}>
              <i className="fa-regular fa-trash-can" aria-hidden="true" />
            </a>
          </div>
        </div>
      </div>
    </div>];

    const subMenu = this.renderItemMenuItems(item, pathTo);

    if (subMenu)
      itemRows = itemRows.concat(subMenu);

    return itemRows;
  }

  renderToggleSubmenuButton(item: WebsiteMenuItem): JSX.Element {
    if (item.type != "menu")
      return null;

    if (this.state.subMenuItemOpen && this.state.subMenuItemOpen.rank == item.rank) {
      return <a className="btn btn-link" onClick={this.closeSubMenuRow} style={{ color: "#333" }}>
        <i className="fa-regular fa-chevron-up" aria-hidden="true" />
      </a>;
    }
    return <a className="btn btn-link" onClick={this.displaySubMenuRow(item)} style={{ color: "#333" }}>
      <i className="fa-regular fa-chevron-down" aria-hidden="true" />
    </a>;
  }

  renderItemMenuItems(item: WebsiteMenuItem, pathTo: number[]): JSX.Element[] {
    const { subMenuItemOpen } = this.state;

    if (!subMenuItemOpen || subMenuItemOpen.rank != item.rank)
      return null;

    return [
      <MenuItemsList {...this.props} menuItems={item.menu_items || [] } pathTo={pathTo} />,
      <div style={{ marginLeft: "30px", marginBottom: "10px" }}>
        <a href="#" onClick={this.props.addItems(1, pathTo)} className="btn btn-secondary">
          <i className="fa-regular fa-plus"></i> { I18n.t("react.menu_items.form.add_item") }
        </a>
      </div>
    ];
  }

  render(): JSX.Element {
    const { subMenuItemOpen } = this.state;
    const { menuItems } = this.props;
    const { pathTo } = this.props;
    if (!menuItems) return null;

    const rows = menuItems.map((item, index) => this.renderItemRows(item, pathTo.concat(index)));
    if (rows.length == 0) return null;

    return <div style={{ marginLeft: pathTo.length ? "30px" : "0px", overflow: "hidden" }}>
      { subMenuItemOpen ? rows :
        <Sortable itemIdKey="rank"
          itemIndexKey="rank"
          dragType={DragTypes.VALUE_LIST}
          items={menuItems}
          handleDrop={this.handleDrop(pathTo)}
          handlePosition="left"
          fullyDraggable={true}>
          { rows }
        </Sortable>
      }
    </div>;
  }
}

function mapStateToProps(state: any): any {
  const { assets } = state.assetsManager;
  return {
    assets
  };
}

export default connect(mapStateToProps)(MenuItemsList);
