import { Fragment, Component } from "react";
import groupBy from "lodash/groupBy";
import PropTypes from "prop-types";
import { translatedTextFromJson, replaceResourceIdWithPlaceholderId, replacePlaceholderIdWithResourceId } from "../../utils/templatesBuilderUtils";
import { isVisible } from "../../utils/VisibilitySettingsUtils";
import LiquidCodeModal from "../shared/LiquidCodeModal.react";
import { FORM_PAGE_REGEX, CONFIRMATION_PAGE_REGEX, PATH_NAME_RESOURCE_ID_REGEX, EventDropFields } from "../../constants/Constants";
import ColorPicker from "../ColorPicker.react";
import TemplateImageUploader from "./TemplateImageUploader.react";
import RichTextEditor from "../shared/RichTextEditor.react";
import ReactBootstrapSlider from "react-bootstrap-slider";
import SegmentsPicker from "./SegmentsPicker.react";
import ProgramPicker from "../shared/ProgramPicker.react";
import Checkbox from "../shared/Checkbox.react";
import SelectRegistrationFormModal from "../../containers/SelectRegistrationFormModal.react";
import FilterDropdown from "../FilterDropdown.react";
import AssetsManagerPane, { AssetsManagersSelectionMode } from "../../containers/AssetsManagerPane.react";
import Networking from "../../containers/engagement/Networking.react";
import AccesspointSearchDropdown from "../../containers/AccesspointSearchDropdown.react";
import GuestProductSearchDropdown from "../../containers/GuestProductSearchDropdown.react";
import GuestSearchDropdown from "../../containers/GuestSearchDropdown.react";
import NetworkingPane from "../../components/engagement/networking_rules/NetworkingPane.react";
import { allEnabled, anyEnabled } from "../../utils/featureSetUtils";
import FontPicker from "./FontPicker.react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import GuestProductCollectionPicker from "./GuestProductCollectionPicker.react";
import GuestProductCollectionFieldPicker from "./GuestProductCollectionFieldPicker.react";
import ConfigureExportedColumns from "../../containers/ConfigureExportedColumns.react";

const ALLOWED_PAGE_RESOURCES = ["session", "guest", "guest_product"];

class TemplateSettings extends Component {
  constructor(props) {
    super(props);
    [
      "changeStandardField",
      "changeCheckbox",
      "colorPickedHandler",
      "onFinishUpload",
      "renderMenuPicker",
      "onRemoveImage",
      "updateRTE",
      "onSelectItemsFromFilter",
      "selectResourceBasedPage",
      "openRegistrationFormsModal",
      "closeRegistrationFormsModal",
      "reloadPreview",
      "openAssetsManagerPane",
      "closeAssetsManagerPane",
      "updateSettingsData",
      "toggleNetworkingPane"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      isRegistrationFormsModalOpen: false,
      isShowAssetsManagerPane: false,
      isShowNetworkingPane: false
    };
  }

  onSelectResource(path_name, field) {
    return data => {
      const { notifyChange, blockKey } = this.props;
      const pathNameWithResourceId = replacePlaceholderIdWithResourceId(path_name, data[0]._id);
      notifyChange(field, pathNameWithResourceId, blockKey);
    };
  }

  changeStandardField(e, delay = 800) {
    const { notifyChange, blockKey } = this.props;
    e.persist(); // in order to access 'e' within setTimeout callback
    // wait for user to stop typing to send changes (800ms seems enough)
    clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(() => {
      notifyChange(e.target.name, e.target.value, blockKey);
    }, delay);
  }

  changeCheckbox(e) {
    const { notifyChange, blockKey } = this.props;
    notifyChange(e.target.name, e.target.checked, blockKey);
  }

  colorPickedHandler(key) {
    return (color) => {
      const { notifyChange, blockKey } = this.props;
      notifyChange(key, color, blockKey);
    };
  }

  onSelectItemsFromFilter(key, multiple = false) {
    const { notifyChange, blockKey } = this.props;
    return (selectedItemIds) => {
      const value = multiple ? selectedItemIds && selectedItemIds || [] : selectedItemIds && selectedItemIds[0] || "";
      notifyChange(key, value, blockKey);
    };
  }

  onLiquidTemplateCreation(componentConfigId) {
    const { notifyChange, blockKey } = this.props;
    return value => {
      notifyChange(componentConfigId, value, blockKey);
    };
  }

  onFinishUpload(key, url) {
    const { notifyChange, blockKey } = this.props;
    notifyChange(key, url, blockKey);
  }

  onRemoveImage(key) {
    const { notifyChange, blockKey } = this.props;
    notifyChange(key, "", blockKey);
  }

  slideStop(key) {
    return (e) => {
      const { notifyChange, blockKey } = this.props;
      notifyChange(key, e.target.value, blockKey);
    };
  }

  updateRTE(key) {
    return (html, draftjsContent) => {
      const { notifyChange, blockKey } = this.props;
      notifyChange(key, { html: html, draftjs_content: draftjsContent }, blockKey);
    };
  }

  selectResourceBasedPage(resourceName, isFormPage, isLivePage, isHighlightedMessagesPage) {
    return (e) => {
      const { notifyChange, blockKey, page, settingsData, submitPage } = this.props;

      if (e.target.value == "create-website-page") {
        const pathName = this.detailedViewPathName(resourceName, isFormPage, isLivePage, isHighlightedMessagesPage);

        submitPage({
          name: this.detailedViewName(isFormPage, isLivePage, isHighlightedMessagesPage),
          path_name: pathName,
          layout_id: page.layout_id,
          page_template_id: page.page_template_id,
          resource_based: true,
          resource_name: resourceName,
          guest_product_collection_id: resourceName === "guest_product" ? page.guest_product_collection_id : null,
          guests_segments: resourceName == "guest" && settingsData["segment_id"] || [],
          skip_add_to_menu: true
        }, "new", this.newSectionsAfterPageCreate(resourceName, isFormPage, isLivePage, isHighlightedMessagesPage));
        notifyChange(e.target.name, pathName, blockKey);
      } else {
        notifyChange(e.target.name, e.target.value, blockKey);
      }
    };
  }

  detailedViewName(isFormPage, isLivePage, isHighlightedMessagesPage) {
    const { websitePages, page, settingsData } = this.props;
    const baseName = settingsData["title"] !== undefined && settingsData["title"] != "" ? settingsData["title"] : page.name;
    let nameSuffix;
    if (isFormPage)
      nameSuffix = I18n.t("react.website.resource_based_form_view");
    else if (isLivePage)
      nameSuffix = I18n.t("react.website.resource_based_live_session");
    else if (isHighlightedMessagesPage)
      nameSuffix = I18n.t("react.website.resource_based_messages_session");
    else
      nameSuffix = I18n.t("react.website.resource_based_detailed_view");
    let name = `${baseName} - ${nameSuffix}`;

    while (websitePages.find(page => page.name == name)) {
      name = name.replace(/(?:(\d+))?$/, (correspondance, p1) => {
        return " " + ((Number(p1) || 0) + 1);
      });
    }
    return name;
  }

  detailedViewPathName(resourceName, isFormPage, isLivePage, isHighlightedMessagesPage) {
    const { websitePages, page } = this.props;
    let pathParts = [];
    // If not "index", else remove "__id__" at the end
    pathParts.push(page.path_name == "index" ? `${resourceName}s` : page.path_name.replace(/\/__id__/, ""));

    if (isFormPage)
      pathParts.push("edit");
    else if (isLivePage)
      pathParts.push("live");
    else if (isHighlightedMessagesPage)
      pathParts.push("highlighted-messages");

    pathParts.push("__id__");
    let pathName = pathParts.join("/");

    while (websitePages.find(page => page.path_name == pathName)) {
      pathName = pathName.replace(/(?:-(\d+))?\/__id__/, (correspondance, p1) => {
        return "-" + ((Number(p1) || 0) + 1) + "/__id__";
      });
    }

    return pathName;
  }

  newSectionsAfterPageCreate(resourceName, isFormPage, isLivePage, isHighlightedMessagesPage) {
    if (resourceName == "guest") {
      return ["guest-info"];
    } else if (resourceName == "session") {
      if (isFormPage)
        return ["session-form"];
      else if (isLivePage)
        return ["session-live"];
      else if (isHighlightedMessagesPage)
        return ["highlighted-messages"];
      else
        return ["session-info"];
    } else if (resourceName == "meeting" && isLivePage) {
      return ["session-live"];
    } else {
      return [];
    }
  }

  openRegistrationFormsModal(e) {
    e.preventDefault();
    this.setState({ isRegistrationFormsModalOpen: true });
  }

  closeRegistrationFormsModal() {
    this.setState({ isRegistrationFormsModalOpen: false });
  }

  reloadPreview() {
    const { reloadPreview } = this.props;
    this.setState({ isRegistrationFormsModalOpen: false }, () => reloadPreview());
  }

  helpMessage(componentConfig) {
    if (componentConfig.info) {
      return <div dangerouslySetInnerHTML={{ __html: translatedTextFromJson(componentConfig, "info") }} className="form-text" />;
    }
  }

  renderTextField(componentConfig, value) {
    return (
      <div className="mb-3" key={ componentConfig.id }>
        <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
        <input type="text" className="form-control" name={ componentConfig.id } defaultValue={ value } onChange={ this.changeStandardField } />
        { this.helpMessage(componentConfig) }
      </div>
    );
  }

  renderNumberField(componentConfig, value) {
    const { min, max } = componentConfig;

    let errorMsg;
    if (min && Number(value) < Number(min)) {
      errorMsg = translatedTextFromJson(componentConfig, "min_alert");
    } else if (max && Number(value) > Number(max)) {
      errorMsg = translatedTextFromJson(componentConfig, "max_alert");
    }

    return (
      <div className="mb-3" key={componentConfig.id}>
        <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
        <input type="number" className="form-control" name={componentConfig.id} defaultValue={value} onChange={this.changeStandardField} min={min} max={max} step="1" />
        {errorMsg && <span className="text-danger mt-5 d-inline-block">{errorMsg}</span>}
        {this.helpMessage(componentConfig)}
      </div>
    );
  }

  renderParagraph(componentConfig, value) {
    return (
      <div className="mb-3" key={ componentConfig.id }>
        <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
        <textarea rows="4" className="form-control" name={ componentConfig.id } defaultValue={ value } onChange={ this.changeStandardField }></textarea>
        { this.helpMessage(componentConfig) }
      </div>
    );
  }

  safeInt(value) {
    return isNaN(parseInt(value)) ? undefined : parseInt(value);
  }

  renderImageUpload(componentConfig, value) {
    const { builderType, templateId } = this.props;

    return (
      <div className="mb-3" key={ componentConfig.id }>
        <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
        <TemplateImageUploader name={componentConfig.id}
          url={value}
          onFinishUpload={this.onFinishUpload}
          onRemoveImage={this.onRemoveImage}
          maxWidth={this.safeInt(componentConfig["max_width"])}
          maxHeight={this.safeInt(componentConfig["max_height"])}
          builderType={builderType}
          templateId={templateId} />
        { this.renderAltField(componentConfig) }
        { this.helpMessage(componentConfig) }
      </div>
    );
  }

  renderAltField(componentConfig) {
    const { settingsData } = this.props;

    if (!(componentConfig.image_picker_options && componentConfig.image_picker_options.alt)) return null;

    return (
      <div className="mt-15">
        <span className="input-group">
          <div className="btn btn-secondary">
            Alt <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-bar-step">{I18n.t("react.website.alt_tag_helper")}</Tooltip>}><i className="fa-regular fa-info-circle" aria-hidden="true" />
            </OverlayTrigger>
          </div>
          <input type="text" className="form-control" name={ `${componentConfig.id}_alt` } defaultValue={ settingsData[`${componentConfig.id}_alt`] } onChange={ this.changeStandardField } placeholder={I18n.t("react.website.alt_tag_placeholder")} />
        </span>
      </div>
    );
  }

  renderSelect(componentConfig, value) {
    let optionsHTML;

    if (componentConfig.options[0]["group"] == undefined) {
      optionsHTML = componentConfig.options.map(option => {
        return <option value={option.value} key={option.value}>{translatedTextFromJson(option, "label")}</option>;
      });
    } else {
      const groupedOptions = groupBy(componentConfig.options, "group");
      optionsHTML = Object.keys(groupedOptions).map(key => {
        return (
          <optgroup label={key} key={key}>
            { groupedOptions[key].map(option => {
              return <option value={option.value} key={option.value}>{translatedTextFromJson(option, "label")}</option>;
            })}
          </optgroup>
        );
      });
    }

    return (
      <div className="mb-3" key={ componentConfig.id }>
        <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
        <select className="form-select" defaultValue={value || ""} name={ componentConfig.id } onChange={ this.changeStandardField }>
          <option value="">{ I18n.t("react.website.please_choose") }</option>
          { optionsHTML }
        </select>
        { this.helpMessage(componentConfig) }
      </div>
    );
  }

  renderButtonGroup(componentConfig, value) {
    const options = componentConfig.options.map(option => {
      return (
        <Fragment key={option.value}>
          <input type="radio" className="form-check-input" name={ componentConfig.id } value={option.value} id={option.value} defaultChecked={ value == option.value } onChange={ (e) => this.changeStandardField(e, null) }/>
          <label className={`btn btn-secondary btn-sm ${value == option.value ? "active" : ""}`} htmlFor={option.value}>
            { option.icon ? <i className={option.icon} aria-hidden="true"></i> : translatedTextFromJson(option, "label") }
            <div data-bs-toggle="tooltip" title={ option.icon ? translatedTextFromJson(option, "label") : "" }></div>
          </label>
        </Fragment>
      );
    });

    return (
      <div className="mb-3 row" key={componentConfig.id}>
        <label className="form-label col-12">{ translatedTextFromJson(componentConfig, "label") }</label>
        <div className="col-12">
          <div className="btn-group btn-group-switch" role="group">{ options }</div>
          { this.helpMessage(componentConfig) }
        </div>
      </div>
    );
  }

  renderFontPicker(componentConfig, value) {
    const { notifyChange, blockKey } = this.props;
    let defaultValue, fontFamily, fontWeight, fontGroups = [];

    componentConfig.options.forEach(option => {
      if (!fontGroups.some(fontGroup => fontGroup.label === option.group)) {
        fontGroups.push({ label: option.group, options: [] });
      }

      value == option.value ? defaultValue = { label: option.label, value: option.value } : "";
    });

    fontGroups.forEach(fontGroup => {
      componentConfig.options.forEach(option => {
        const fontData = option.value.split("_");
        const fontDataNotGoogle = option.value.split(",");

        if (fontData[0] == "Google") {
          fontFamily = fontData[1].replace("+", " ");
          fontWeight = fontData[2];
        } else {
          fontFamily = option.value == "custom" ? "sans-serif" : fontDataNotGoogle[0].replaceAll("'", "");
          fontWeight = 400;
        }

        if (fontGroup.label == option.group) {
          fontGroup.options = [
            ...fontGroup.options,
            { label: option.label, value: option.value, family: fontFamily, weight: fontWeight }
          ];
        }
      });
    });

    return (
      <div className="mb-3" key={componentConfig.id}>
        <label className="form-label">{translatedTextFromJson(componentConfig, "label")}</label>
        <FontPicker
          pickerName={componentConfig.id}
          fontGroups={fontGroups}
          defaultValue={defaultValue}
          blockKey={blockKey}
          notifyChange={notifyChange}
        />
      </div>
    );
  }

  renderRadioGroup(componentConfig, value) {
    const options = componentConfig.options.map(option =>
      <div className="form-check" key={`radio-${option.value}`}>
        <label className="form-check-label">
          <input type="radio" className="form-check-input" name={ componentConfig.id } value={option.value} key={option.value} defaultChecked={ value == option.value } onChange={ this.changeStandardField } />
          { translatedTextFromJson(option, "label") }
        </label>
      </div>
    );

    return (
      <div className="mb-3" key={ componentConfig.id }>
        <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
        { options }
        { this.helpMessage(componentConfig) }
      </div>
    );
  }

  renderCheckbox(componentConfig, value) {
    // Do not display old section visibility fields
    if (componentConfig.id == "private" && !componentConfig.backoffice_option) return null;

    const defaultValue = value !== undefined ? value : false;

    return <div className="mb-2" key={ componentConfig.id }>
      <Checkbox
        text={translatedTextFromJson(componentConfig, "label")}
        help={this.helpMessage(componentConfig)}
        name={componentConfig.id}
        checked={defaultValue}
        disabled={componentConfig.disabled}
        onChange={this.changeCheckbox}
        key={componentConfig.id}
      />
    </div>;
  }

  renderColorPicker(componentConfig, value) {
    const { event } = this.props;

    return (
      <div className="mb-3" key={ componentConfig.id }>
        <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
        <div>
          <ColorPicker setColor={this.colorPickedHandler(componentConfig.id)} selectedColor={value} placement="right" event={event} displayValue={true} />
        </div>
        { this.helpMessage(componentConfig) }
      </div>
    );
  }

  renderGuestCategoryPicker(componentConfig, value) {
    const { guestCategories, sectionLocked, guestCategoriesByPopulation, fullGuestCategories } = this.props;
    const locked = sectionLocked && componentConfig.lockable;
    const { isRegistrationFormsModalOpen } = this.state;
    let guestCategoriesOptions = componentConfig.population ? guestCategoriesByPopulation[componentConfig.population] : guestCategories;
    const { filters } = componentConfig;

    if (filters) {
      guestCategoriesOptions = fullGuestCategories.filter(category => {
        return Object.entries(filters).every(([key, value]) => category[key] === value);
      }).reduce((acc, category) => {
        acc[category._id] = category.name;
        return acc;
      }, {});
    }

    const selectAnotherFormModal = componentConfig.enable_select_another_form && <div>
      <p className="mt-5"><a href="#" onClick={this.openRegistrationFormsModal}>{I18n.t("react.website.select_another_form")}</a></p>
      <SelectRegistrationFormModal isOpen={isRegistrationFormsModalOpen}
        guestCategoryId={value}
        closeModal={this.closeRegistrationFormsModal}
        afterAttachingToForm={this.reloadPreview} />
    </div>;

    return (
      <div className="mb-3" key={ componentConfig.id }>
        <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
        <select className="form-select" defaultValue={value || ""} name={ componentConfig.id } disabled={locked} onChange={ this.changeStandardField }>
          <option value="">{ I18n.t("react.website.please_choose") }</option>
          { Object.keys(guestCategoriesOptions || {}).map(key => {
            return <option value={key} key={key}>{guestCategoriesOptions[key]}</option>;
          })}
        </select>
        { this.helpMessage(componentConfig) }
        { selectAnotherFormModal }
      </div>
    );
  }

  renderSsoProviderPicker(componentConfig, value) {
    const ssoProviders = window.ReactGlobalProps.sso_providers || [];

    let realValue = value && value.slice();

    if (value && !ssoProviders.find(p => p.id === value)) {
      // if value is a "legacy type" and contains provider strategy name instead of id
      // we change the value to match the first provider using the strategy
      const provider = ssoProviders.find(p => p.strategy === value);
      realValue = provider && provider.id;
    }

    return (
      <div className="mb-3" key={ componentConfig.id }>
        <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
        <select className="form-select" defaultValue={realValue || ""} name={ componentConfig.id } onChange={ this.changeStandardField }>
          <option value="">{ I18n.t("react.website.please_choose") }</option>
          { ssoProviders.map(option => {
            return <option value={option.id} key={option.id}>{translatedTextFromJson(option, "name")}</option>;
          })}
        </select>
        { this.helpMessage(componentConfig) }
      </div>
    );
  }

  renderHeader(componentConfig) {
    return (
      <h4 key={ componentConfig.content } className="setting-header mb-3">{ translatedTextFromJson(componentConfig, "content") }</h4>
    );
  }

  renderInfo(componentConfig) {
    const type = componentConfig.info_type || "info";
    return (
      <div key={componentConfig.id} className={`alert alert-${type}`}>
        <p key={ componentConfig.content } dangerouslySetInnerHTML={{ __html: translatedTextFromJson(componentConfig, "content") }} style={{ marginBottom: 0 }} />
      </div>
    );
  }

  renderSlider(componentConfig, value) {
    return (
      <div className="mb-3" key={ componentConfig.id }>
        <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label><br />
        <ReactBootstrapSlider value={value != null ? value : 0} slideStop={this.slideStop(componentConfig.id)}
          step={componentConfig.step} max={componentConfig.max} min={componentConfig.min}
          orientation="horizontal" tooltip="always" />
        { this.helpMessage(componentConfig) }
      </div>
    );
  }

  renderMenuPicker(componentConfig, value) {
    const { menus } = this.props;
    const optionsHTML = menus && menus.map(menu => {
      return <option value={menu.key} key={menu.key}>{menu.name}</option>;
    });

    return (
      <div className="mb-3" key={ componentConfig.id }>
        <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
        <select className="form-select" defaultValue={value} name={ componentConfig.id } onChange={ this.changeStandardField }>
          <option value="">{ I18n.t("react.website.please_choose") }</option>
          { optionsHTML }
        </select>
        { this.helpMessage(componentConfig) }
      </div>
    );
  }

  renderRichTextEditor(componentConfig, value) {
    const dropFields = this.dropFields();
    EventDropFields.forEach(field => dropFields.push({ id: `event.${field}`, name: `event.${field}` }));

    return (
      <div className="mb-3" key={ componentConfig.id }>
        <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
        <RichTextEditor content={ value ? value.html : "" }
          draftjsContent={ value ? value.draftjs_content : {} }
          onBlur={ this.updateRTE(componentConfig.id) }
          dropFields={ dropFields }
          toolbarSize="extra-small"
          textGeneratorPrePromptKey="organizer_content_context"
        />
        { this.helpMessage(componentConfig) }
      </div>
    );
  }

  dropFields() {
    const { guestFields, page } = this.props;
    if (!guestFields) return [];

    if (page && page.resource_based && page.resource_name === "guest") {
      return guestFields.map(field => {
        return { id: `current_resource.${field.key}`, name: `current_resource.${field.key}` };
      });
    }

    return guestFields.map(field => {
      return { id: `guest.${field.key}`, name: `guest.${field.key}` };
    });
  }

  pageCanAppearInPagePicker(page) {
    const authorizedPage = !page.path_name.includes("__id__") || ALLOWED_PAGE_RESOURCES.includes(page.resource_name);
    return authorizedPage && !FORM_PAGE_REGEX.test(page.path_name) && !CONFIRMATION_PAGE_REGEX.test(page.path_name);
  }

  renderWebsitePagePicker(componentConfig, value) {
    const { websitePages } = this.props;
    const optionsHTML = websitePages.filter(page => this.pageCanAppearInPagePicker(page)).map(page => {
      return <option value={page.path_name} key={page.path_name}>{page.name}</option>;
    });

    let pathNameWithoutResourceId = value;
    if (PATH_NAME_RESOURCE_ID_REGEX.test(value)) {
      pathNameWithoutResourceId = replaceResourceIdWithPlaceholderId(value);
    }
    return (
      <div className="mb-3" key={ componentConfig.id }>
        <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
        <select className="form-select" defaultValue={pathNameWithoutResourceId} name={ componentConfig.id } onChange={ this.changeStandardField }>
          <option value="">{ I18n.t("react.website.please_choose") }</option>
          { optionsHTML }
        </select>
        { this.helpMessage(componentConfig) }
        { this.renderResourcePicker(componentConfig, value, pathNameWithoutResourceId)}
      </div>
    );
  }

  renderResourcePicker(componentConfig, value, valueWithoutIdKey) {
    if (!value) return;
    const { websitePages } = this.props;
    const page = websitePages.find(page => page.path_name === valueWithoutIdKey);
    const match = value.match(PATH_NAME_RESOURCE_ID_REGEX);
    const resourceId = match ? match[0] : null;
    if (!page) return;

    switch (page.resource_name) {
    case "session":
      return <div className="mb-3 mt-15">
        <label className="form-label">{I18n.t("react.website.resource_name_session")}</label>
        <AccesspointSearchDropdown onSelectAccesspoint={this.onSelectResource(page.path_name, componentConfig.id)} selectedAccesspointsIds={resourceId} />
      </div>;
    case "guest":
      return <div className="mb-3 mt-15">
        <label className="form-label">{I18n.t("react.website.resource_name_guest")}</label>
        <GuestSearchDropdown onSelectGuest={this.onSelectResource(page.path_name, componentConfig.id)} selectedGuestsIds={resourceId} />
      </div>;
    case "guest_product":
      return <div className="mb-3 mt-15">
        <label className="form-label">{I18n.t("react.website.resource_name_guest_product")}</label>
        <GuestProductSearchDropdown onSelectGuestProduct={this.onSelectResource(page.path_name, componentConfig.id)} selectedGuestProductsIds={resourceId} />
      </div>;
    default:
      return null;
    }
  }

  renderDocumentPicker(componentConfig, value) {
    const { documentNames } = this.props;
    const optionsHTML = documentNames && documentNames.map(name => {
      return <option value={name} key={name}>{name}</option>;
    });

    return (
      <div className="mb-3" key={ componentConfig.id }>
        <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
        <select className="form-select" defaultValue={value} name={ componentConfig.id } onChange={ this.changeStandardField }>
          <option value="">{ I18n.t("react.website.please_choose") }</option>
          { optionsHTML }
        </select>
        { this.helpMessage(componentConfig) }
      </div>
    );
  }

  renderAccesspointTraitPicker(componentConfig, value) {
    const { accesspointsTraits } = this.props;
    const optionsHTML = accesspointsTraits && accesspointsTraits.map(trait => {
      return <option value={trait} key={trait}>{trait}</option>;
    });

    return (
      <div className="mb-3" key={ componentConfig.id }>
        <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
        <select className="form-select" defaultValue={value} name={ componentConfig.id } onChange={ this.changeStandardField }>
          <option value="">{ I18n.t("react.website.please_choose") }</option>
          { optionsHTML }
        </select>
        { this.helpMessage(componentConfig) }
      </div>
    );
  }

  renderAccesspointSessionTypePicker(componentConfig, value) {
    const { accesspointsSessionTypes } = this.props;
    const sessionsTypes = accesspointsSessionTypes && accesspointsSessionTypes.map(sessionType => {
      return { id: sessionType._id, name: I18n.t(`session_type_${sessionType.name}`, { defaultValue: sessionType.name }) };
    });
    const selectedItemIds = Array.isArray(value) ? value : (value != "" ? [value] : []); // handle former value type

    return (
      <div className="mb-3" key={ componentConfig.id }>
        <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
        <div>
          <FilterDropdown
            id="session_types"
            translationKey="session_types"
            items={sessionsTypes}
            selectedItemIds={selectedItemIds}
            showCells={false}
            hasSelectAll={true}
            multipleSelect={true}
            sortItems={false}
            itemIdKey="id"
            onChange={this.onSelectItemsFromFilter(componentConfig.id, true)}
          />
        </div>
        <div className="mt-5">
          { sessionsTypes.filter(sessionType => selectedItemIds.includes(sessionType.id)).map(sessionType => {
            return <span className="badge rounded-pill bg-secondary mr-5" key={sessionType.id}>{ sessionType.name }</span>;
          })}
        </div>
        { this.helpMessage(componentConfig) }
      </div>
    );
  }

  renderSegmentsPicker(componentConfig, value) {
    const { segments, sectionLocked, notifyChange } = this.props;

    return <SegmentsPicker
      componentConfig={componentConfig}
      value={value}
      segments={segments}
      sectionLocked={sectionLocked}
      notifyChange={notifyChange}
    />;
  }

  renderGuestFieldPicker(componentConfig, value) {
    const { guestFields } = this.props;
    const dropFields = (guestFields || []).map(field => {
      if (componentConfig.guest_field_type && componentConfig.guest_field_type !== field.type && field.key !== "country") {
        return;
      }
      return { id: field.key, name: field.label };
    }).filter(field => (field != undefined));

    const multipleSelect = !!componentConfig.multiple;
    const selectedItemIds = multipleSelect ? value : [value];
    const title = multipleSelect ? null : value;

    return <div className="mb-3" key={ componentConfig.id }>
      <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
      <div>
        <FilterDropdown
          id="guest_fields"
          translationKey="guest_fields"
          items={dropFields}
          selectedItemIds={selectedItemIds}
          showCells={false}
          hasSelectAll={false}
          multipleSelect={multipleSelect}
          sortItems={false}
          itemIdKey="id"
          onChange={this.onSelectItemsFromFilter(componentConfig.id, multipleSelect)}
          title={title}
        />
      </div>
      { this.helpMessage(componentConfig) }
    </div>;
  }

  renderGuestProductCollectionPicker(componentConfig, value) {
    return <GuestProductCollectionPicker
      key={componentConfig.id}
      value={value}
      label={translatedTextFromJson(componentConfig, "label")}
      helpMessage={this.helpMessage(componentConfig)}
      onChange={this.onSelectItemsFromFilter(componentConfig.id)}
    />;
  }

  renderGuestProductCollectionFieldPicker(componentConfig, value) {
    const { page, globalSettingsData } = this.props;

    // search guest_product_collection_id in the page resources or the first value found in the settings of the section
    return <GuestProductCollectionFieldPicker
      key={componentConfig.id}
      guestProductCollectionId={page.guest_product_collection_id || globalSettingsData["collection_id"]}
      value={value}
      label={translatedTextFromJson(componentConfig, "label")}
      helpMessage={this.helpMessage(componentConfig)}
      onChange={this.onSelectItemsFromFilter(componentConfig.id)}
    />;
  }

  renderGuestProductInformationRequestColumnsSetModal(componentConfig) {
    return <div key={componentConfig.id} className="mb-3">
      <a className="btn btn-secondary" href="#">
        <ConfigureExportedColumns
          exportFor="product_information_request"
          i18nPath="react.visit_routes.export_columns"
          customLabel={translatedTextFromJson(componentConfig, "label")}
        />
      </a>
      { this.helpMessage(componentConfig) }
    </div>;
  }

  renderProgramPicker(componentConfig, value) {
    return <ProgramPicker
      key={componentConfig.id}
      label={translatedTextFromJson(componentConfig, "label")}
      selectedValue={value}
      onSelect={this.onSelectItemsFromFilter(componentConfig.id)}
      reloadPreview={this.reloadPreview}
    />;
  }

  renderSelectResourceBasedPagePicker(componentConfig, value) {
    const { websitePages, switchPageAndHideView, builderType, page } = this.props;
    const resourceName = componentConfig.resource_name || "guest";
    const optionsHTML = websitePages.filter(page => page.path_name.includes("__id__") && page.resource_name == resourceName)
      .map(page => {
        return <option value={page.path_name} key={page.path_name}>{page.name}</option>;
      });
    const selectedPage = value != "" ? websitePages.find(page => page.path_name == value) : null;

    const isFormPage = componentConfig.resource_form;
    const isLivePage = componentConfig.resource_live;
    const isHighlightedMessagesPage = componentConfig.resource_highlighted_messages;
    const allowCreateNewPage = componentConfig.allow_page_creation != false;

    return <div className="mb-3" key={ componentConfig.id }>
      <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
      <select className="form-select" value={value} name={ componentConfig.id } onChange={ this.selectResourceBasedPage(resourceName, isFormPage, isLivePage, isHighlightedMessagesPage) }>
        <option value="">{ I18n.t("react.website.resource_based_no_detailed_view") }</option>
        { allowCreateNewPage && <option value="create-website-page">{ I18n.t("react.website.resource_based_new_page") }</option> }
        <optgroup label={ I18n.t("react.website.available_pages") }>
          { optionsHTML }
        </optgroup>
      </select>
      { builderType == "website" && value != "" && value != "create-website-page"
        ? <a href="#" onClick={switchPageAndHideView(selectedPage, page)} className="btn btn-link" style={{ "marginLeft": "-12px" }}>{ I18n.t("react.website.resource_based_setup_detailed_view") }</a>
        : null }
      { this.helpMessage(componentConfig) }
    </div>;
  }

  renderLiquidFieldTemplateFilter(componentConfig, liquidTemplate) {
    const { liquidTemplates } = this.props;
    const selectedIds = liquidTemplate && liquidTemplate._id ? [liquidTemplate._id] : [];

    if (liquidTemplates.length == 0) return null;
    return <FilterDropdown
      id="liquid_templates"
      translationKey="liquid_templates"
      items={liquidTemplates}
      selectedItemIds={selectedIds}
      showCells={false}
      hasSelectAll={false}
      multipleSelect={false}
      sortItems={true}
      selectedFirst={true}
      itemIdKey="_id"
      onChange={this.onSelectItemsFromFilter(componentConfig.id)}
      allowUnselect={true}
      hasSearch={true}
      title={liquidTemplate ? liquidTemplate.name : I18n.t("react.website.please_choose") }
    />;
  }

  renderLiquidField(componentConfig, value) {
    const { liquidTemplates, accountId, updateLiquidTemplate, createLiquidTemplate, liquidTemplatePendingRequest, liquidTemplateErrors, clearErrors, builderType } = this.props;
    if (!liquidTemplates) return null;

    const liquidTemplate = liquidTemplates && liquidTemplates.find(template => template._id === value);
    return <div key={ componentConfig.id }>
      <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
      <div className="d-flex">{ this.renderLiquidFieldTemplateFilter(componentConfig, liquidTemplate) }</div>
      <div style={{ marginTop: "10px" }}>
        <LiquidCodeModal
          liquidTemplates={liquidTemplates}
          liquidTemplate={liquidTemplate}
          liquidTemplatePendingRequest={liquidTemplatePendingRequest}
          accountId={accountId}
          updateLiquidTemplate={updateLiquidTemplate}
          createLiquidTemplate={createLiquidTemplate}
          liquidTemplateErrors={liquidTemplateErrors}
          onSave={ this.onLiquidTemplateCreation(componentConfig.id) }
          clearErrors={clearErrors}
          builderType={builderType}
        />
      </div>
    </div>;
  }

  openAssetsManagerPane() {
    this.setState({ isShowAssetsManagerPane: true });
  }

  closeAssetsManagerPane(saveOnClose) {
    const { saveBuilder } = this.props;
    this.setState({ isShowAssetsManagerPane: false });
    if (saveOnClose) saveBuilder();
  }

  updateSettingsData(key, value) {
    const { notifyChange, blockKey } = this.props;
    notifyChange(key, value, blockKey);
  }

  renderAssetsFolderPicker(componentConfig, value) {
    const { isShowAssetsManagerPane } = this.state;
    return (
      <div key={ componentConfig.id }>
        <input className="btn btn-secondary mb-10" type="button" onClick={this.openAssetsManagerPane} value={ translatedTextFromJson(componentConfig, "label") }/>
        <AssetsManagerPane
          selectionMode={AssetsManagersSelectionMode.AssetsFolder}
          assetsFolderId={value}
          isOpen={isShowAssetsManagerPane}
          settingKey={componentConfig.id}
          updateSettingsData={this.updateSettingsData}
          onClose={this.closeAssetsManagerPane}
        />
      </div>
    );
  }

  renderAssetPicker(componentConfig, value) {
    const { isShowAssetsManagerPane } = this.state;
    const { assets } = this.props;
    const asset = assets && value && assets.find(asset => asset._id === value);
    const fileName = asset ? asset.name : I18n.t("react.menu_items.form.select_a_file");

    return (
      <div key={ componentConfig.id }>
        <a className="btn btn-secondary mb-10 asset-selector" onClick={this.openAssetsManagerPane}>
          <i className="fa-regular fa-file" aria-hidden="true" />
          <span className="ml-5">{fileName}</span>
        </a>
        <AssetsManagerPane
          selectionMode={AssetsManagersSelectionMode.Asset}
          assetId={value}
          isOpen={isShowAssetsManagerPane}
          settingKey={componentConfig.id}
          updateSettingsData={this.updateSettingsData}
          onClose={this.closeAssetsManagerPane}
        />
      </div>
    );
  }

  toggleNetworkingPane() {
    const { isShowNetworkingPane } = this.state;
    this.setState({ isShowNetworkingPane: !isShowNetworkingPane });
  }

  renderNetworkingPane(componentConfig, propsToAdd) {
    const { isShowNetworkingPane } = this.state;

    return <div key={ componentConfig.label }>
      <a className="btn btn-secondary mb-10" onClick={this.toggleNetworkingPane}>
        <i className="fa-regular fa-gear" aria-hidden="true" />
        <span className="ml-5">{ translatedTextFromJson(componentConfig, "label") }</span>
      </a>
      <NetworkingPane isOpen={isShowNetworkingPane} hide={this.toggleNetworkingPane} {...propsToAdd}>
        <Networking />
      </NetworkingPane>
    </div>;
  }

  renderMessagingNetworkingPane(componentConfig) {
    return this.renderNetworkingPane(componentConfig, { hideMeetingSettings: true });
  }

  renderMeetingNetworkingPane(componentConfig) {
    return this.renderNetworkingPane(componentConfig, { hideMessagingSettings: true });
  }

  match_required_features(settingComponent) {
    if (!settingComponent.required_features || settingComponent.required_features.length === 0) return true;

    if (settingComponent.require_all_features === false) {
      return anyEnabled(settingComponent.required_features);
    } else {
      return allEnabled(settingComponent.required_features);
    }
  }

  render() {
    const { settingComponentsSchema, settingsData } = this.props;
    const settingsComponents = settingComponentsSchema.map(settingComponent => {
      if (!isVisible(settingsData, settingComponentsSchema, settingComponent) || !this.match_required_features(settingComponent)) return null;
      switch (settingComponent.type) {
      case "text":
        return this.renderTextField(settingComponent, settingsData[settingComponent.id]);
      case "number":
        return this.renderNumberField(settingComponent, settingsData[settingComponent.id]);
      case "paragraph":
        return this.renderParagraph(settingComponent, settingsData[settingComponent.id]);
      case "image":
      case "image_picker":
        return this.renderImageUpload(settingComponent, settingsData[settingComponent.id]);
      case "select":
        return this.renderSelect(settingComponent, settingsData[settingComponent.id]);
      case "button_group":
        return this.renderButtonGroup(settingComponent, settingsData[settingComponent.id]);
      case "font_picker":
        return this.renderFontPicker(settingComponent, settingsData[settingComponent.id]);
      case "radio":
        return this.renderRadioGroup(settingComponent, settingsData[settingComponent.id]);
      case "checkbox":
        return this.renderCheckbox(settingComponent, settingsData[settingComponent.id]);
      case "color":
        return this.renderColorPicker(settingComponent, settingsData[settingComponent.id]);
      case "header":
        return this.renderHeader(settingComponent);
      case "info":
        return this.renderInfo(settingComponent);
      case "range":
        return this.renderSlider(settingComponent, settingsData[settingComponent.id]);
      case "guest_category_picker":
        return this.renderGuestCategoryPicker(settingComponent, settingsData[settingComponent.id]);
      case "sso_provider_picker":
        return this.renderSsoProviderPicker(settingComponent, settingsData[settingComponent.id]);
      case "menu":
        return this.renderMenuPicker(settingComponent, settingsData[settingComponent.id]);
      case "rte":
        return this.renderRichTextEditor(settingComponent, settingsData[settingComponent.id]);
      case "website_page_picker":
        return this.renderWebsitePagePicker(settingComponent, settingsData[settingComponent.id]);
      case "document_picker":
        return this.renderDocumentPicker(settingComponent, settingsData[settingComponent.id]);
      case "accesspoint_trait_picker":
        return this.renderAccesspointTraitPicker(settingComponent, settingsData[settingComponent.id]);
      case "accesspoint_session_type_picker":
        return this.renderAccesspointSessionTypePicker(settingComponent, settingsData[settingComponent.id]);
      case "segments_picker":
        return this.renderSegmentsPicker(settingComponent, settingsData[settingComponent.id]);
      case "guest_field_picker":
        return this.renderGuestFieldPicker(settingComponent, settingsData[settingComponent.id]);
      case "guest_product_collection_picker":
        return this.renderGuestProductCollectionPicker(settingComponent, settingsData[settingComponent.id]);
      case "guest_product_collection_field_picker":
        return this.renderGuestProductCollectionFieldPicker(settingComponent, settingsData[settingComponent.id]);
      case "select_info_page_picker":
        return this.renderSelectResourceBasedPagePicker(settingComponent, settingsData[settingComponent.id]);
      case "program_picker":
        return this.renderProgramPicker(settingComponent, settingsData[settingComponent.id]);
      case "liquid_field":
        return this.renderLiquidField(settingComponent, settingsData[settingComponent.id]);
      case "folder_picker":
        return this.renderAssetsFolderPicker(settingComponent, settingsData[settingComponent.id]);
      case "asset_picker":
        return this.renderAssetPicker(settingComponent, settingsData[settingComponent.id]);
      case "chat_population_mapping_form":
      case "messaging_networking_rules_form":
        return this.renderMessagingNetworkingPane(settingComponent);
      case "meeting_networking_rules_form":
        return this.renderMeetingNetworkingPane(settingComponent);
      case "guest_product_information_request_columns_set_form":
        return this.renderGuestProductInformationRequestColumnsSetModal(settingComponent);
      // TODO handle Video
      }
    });

    return (
      <div className="card-body">
        { settingsComponents }
      </div>
    );
  }
}

TemplateSettings.propTypes = {
  settingsData: PropTypes.object.isRequired,
  settingComponentsSchema: PropTypes.arrayOf(PropTypes.object).isRequired,
  blockKey: PropTypes.string
};

export default TemplateSettings;
