import { Component } from "react";
import PropTypes from "prop-types";
import SlidingPane from "react-sliding-pane";
import AssetsGrid from "../components/assets_manager/AssetsGrid.react";
import { connect } from "react-redux";
import {
  createAsset,
  createAssetsFolder,
  deleteAsset,
  fetchAssets,
  fetchAssetsFolder,
  fetchAssetsFolders,
  updateAsset
} from "../actions/AssetsManagerActionCreator";
import { urlEventId } from "../utils/pathUtils";
import moment from "moment";
import Icons from "../constants/Icons";
import url from "url";

const AssetsManagersSelectionMode = { AssetsFolder: "AssetsFolder", Asset: "Asset" };
const GENERAL_ASSETS_FOLDER_NAME = "general";

class AssetsManagerPane extends Component {

  constructor(props) {
    super(props);

    [
      "createAsset",
      "onSelectAsset",
      "saveSelectedAsset"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.isAssetsFolderCreated = false;
    this.assetIdToSave = "";
  }

  componentDidMount() {
    const { assetsFolderId, createAssetsFolder, selectionMode, fetchAssetsFolders } = this.props;
    if (selectionMode === AssetsManagersSelectionMode.AssetsFolder) {
      if (assetsFolderId)
        this.getAssetsFilesAndFolderData(assetsFolderId);
      else {
        createAssetsFolder(urlEventId(), { name: `photo-gallery-${moment().format()}` });
        this.isAssetsFolderCreated = true;
      }
    } else if (selectionMode === AssetsManagersSelectionMode.Asset) {
      fetchAssetsFolders(urlEventId()); // need to search general folder
    }
  }

  onSelectAsset(assetId) {
    this.assetIdToSave = assetId;
  }

  getFileName(imageUrl) {
    const fileName = imageUrl.split("/").pop();
    const extension = fileName.split(".").pop();
    let tmp = fileName.split("_");
    tmp.pop();
    tmp = tmp.join("_");
    return `${tmp}.${extension}`;
  }

  createAsset(signedUrl) {
    const { createAsset, assetsFolderSelected } = this.props;
    const assetsFolderId = assetsFolderSelected ? assetsFolderSelected._id : null;
    const uri = url.parse(signedUrl);
    const imageUrl = uri.protocol + "//" + uri.host + uri.pathname;
    const fileName = this.getFileName(imageUrl);

    createAsset(
      urlEventId(),
      assetsFolderId,
      {
        name: fileName,
        file_url: imageUrl,
        file_type: fileName.split(".").pop()
      });
  }

  getAssetFileUrl(assetId) {
    const { assets } = this.props;
    const selectedAsset = assets.find(asset => asset._id === assetId);
    return selectedAsset && selectedAsset.file_url || "";
  }

  saveSelectedAsset() {
    const { settingKey, updateSettingsData, onClose, selectionMode } = this.props;
    if (this.assetIdToSave.length > 0) {
      updateSettingsData(settingKey, this.assetIdToSave);
      updateSettingsData("button_link", this.getAssetFileUrl(this.assetIdToSave));
      const isSaveOnClose = selectionMode === AssetsManagersSelectionMode.AssetsFolder;
      onClose(isSaveOnClose);
    }
  }

  getAssetsFilesAndFolderData(assetsFolderId) {
    const { fetchAssetsFolder, fetchAssets } = this.props;
    fetchAssetsFolder(urlEventId(), assetsFolderId);
    fetchAssets(urlEventId(), assetsFolderId);
  }

  componentDidUpdate(previousProps) {
    const { assetsFolderSelected, updateSettingsData, settingKey, assetsFolders, selectionMode, isFetching, errors, createAssetsFolder } = this.props;

    if (selectionMode === AssetsManagersSelectionMode.AssetsFolder) {
      if (this.isAssetsFolderCreated && assetsFolderSelected) {
        // for autosave website
        updateSettingsData(settingKey, assetsFolderSelected._id);
        this.isAssetsFolderCreated = false;
      }
    } else if (selectionMode === AssetsManagersSelectionMode.Asset) {
      if (previousProps.isFetching && !isFetching && errors === null) {
        const assetFolder = assetsFolders && assetsFolders.find(assetFolder => assetFolder.name === GENERAL_ASSETS_FOLDER_NAME);
        if (assetFolder) {
          this.getAssetsFilesAndFolderData(assetFolder._id);
        } else {
          createAssetsFolder(urlEventId(), { name: GENERAL_ASSETS_FOLDER_NAME });
        }
      }
    }
  }

  renderSelectButton() {
    return (
      <button className="btn btn-primary asset-selection-button" onClick={this.saveSelectedAsset}>
        {I18n.t("react.assets_manager.sliding_pane_form.select_asset")}
      </button>
    );
  }

  render() {
    const { isOpen, onClose, assets, assetsFolderSelected, deleteAsset, updateAsset, selectionMode, assetId } = this.props;
    const id = assetsFolderSelected ? assetsFolderSelected._id : null;
    const numberOfFiles = assets ? assets.length : 0;
    const isAssetsFolderSelectionMode = selectionMode === AssetsManagersSelectionMode.AssetsFolder;
    const acceptedFiles = isAssetsFolderSelectionMode
      ? ["image/*"]
      : [
        "image/*",
        "application/pdf",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/zip",
        "video/mp4"
      ];

    return (
      <SlidingPane
        isOpen={isOpen}
        title={I18n.t("react.assets_manager.sliding_pane_form.title")}
        subtitle={I18n.t(
          "react.assets_manager.sliding_pane_form.files_counter",
          { count: numberOfFiles }
        )}
        from="right"
        width="60%"
        onRequestClose={onClose}
        className="width-100-xs width-80-sm"
        closeIcon={Icons.close()}
      >
        { !isAssetsFolderSelectionMode && this.renderSelectButton() }
        <AssetsGrid
          assetsFolderId={id}
          assetId={assetId}
          assets={assets}
          deleteAsset={deleteAsset}
          updateAsset={updateAsset}
          createAsset={this.createAsset}
          acceptedFiles={acceptedFiles}
          selectionMode={selectionMode}
          onSelectAsset={this.onSelectAsset}
        />
      </SlidingPane>
    );
  }
}

AssetsManagerPane.propTypes = {
  assetId: PropTypes.string,
  assetsFolderId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  selectionMode: PropTypes.string.isRequired,
  updateSettingsData: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  settingKey: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  const { assetsFolderSelected, assets, errors, assetsFolders, isFetching } = state.assetsManager;
  return {
    assets,
    assetsFolders,
    assetsFolderSelected,
    errors,
    isFetching
  };
};

const mapDispatchToProps = {
  createAsset,
  createAssetsFolder,
  deleteAsset,
  fetchAssets,
  fetchAssetsFolder,
  fetchAssetsFolders,
  updateAsset
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetsManagerPane);

export { AssetsManagersSelectionMode };