import { useEffect, useState } from "react";
import ExportToExcelLink from "../ExportToExcelLink.react";

interface columnsMappingType {
  [index: string]: any | null;
}
interface exportOptionsType {
  [index: string]: columnsMappingType | string | null;
}
interface Props {
  chartData?: any,
  exportOptions?: exportOptionsType,
}

const AtlasChartToExcelLink: React.FC<Props> = ({ chartData, exportOptions }) => {
  if (!exportOptions) return null;
  const { columnsMapping, type } = exportOptions;

  const [exportFormattedChartData, setExportFormattedChartData] = useState(null);
  useEffect(() => {
    if (!chartData || !columnsMapping) {
      setExportFormattedChartData(null);
      return;
    }

    const data = chartData.documents.reduce((excelData, document) => {
      const lineData = Object.entries(columnsMapping).reduce((acc, [excelKey, keyInfo]) => {
        let value = document[keyInfo.documentKey];
        if ([null, undefined].includes(document[keyInfo.documentKey])) {
          value = "null";
        } else if (keyInfo.i18nPrefix !== undefined) {
          value = I18n.t(`${keyInfo.i18nPrefix}${value}`);
        }

        acc[excelKey] = value;

        if (keyInfo.total) {
          if (!excelData.total[excelKey]) excelData.total[excelKey] = 0;
          excelData.total[excelKey] += document[keyInfo.documentKey];
        }
        return acc;
      }, {});

      excelData.data.push(lineData);

      return excelData;
    }, { data: [], total: {} });

    setExportFormattedChartData(data);
  }, [chartData, columnsMapping]);

  if (!type || !exportFormattedChartData) return null;
  return <div style={{ display: "inline-block", marginLeft: "7px" }}>
    <ExportToExcelLink data={exportFormattedChartData} columnKeys={Object.keys(columnsMapping)} type={type} />
  </div>;
};

export default AtlasChartToExcelLink;
