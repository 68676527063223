import { ActionTypes, CALL_API } from "../constants/Constants";

export function createDirectEmail(eventId, parameters) {
  const { CREATE_DIRECT_EMAIL_REQUEST, CREATE_DIRECT_EMAIL_SUCCESS, CREATE_DIRECT_EMAIL_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_DIRECT_EMAIL_REQUEST, CREATE_DIRECT_EMAIL_SUCCESS, CREATE_DIRECT_EMAIL_FAILURE],
      method: "POST",
      body: { direct_email: parameters },
      endpoint: `/events/${eventId}/direct_emails.json`
    }
  };
}

export function deliverDirectEmail(eventId, directEmailId, guestId) {
  const { DELIVER_DIRECT_EMAIL_REQUEST, DELIVER_DIRECT_EMAIL_SUCCESS, DELIVER_DIRECT_EMAIL_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DELIVER_DIRECT_EMAIL_REQUEST, DELIVER_DIRECT_EMAIL_SUCCESS, DELIVER_DIRECT_EMAIL_FAILURE],
      method: "PUT",
      body: { guest_id: guestId },
      endpoint: `/events/${eventId}/direct_emails/${directEmailId}/deliver.json`
    }
  };
}

export function deliverDirectEmailAndReject(eventId, directEmailId, guestId) {
  const action = deliverDirectEmail(eventId, directEmailId, guestId);
  action[CALL_API].redirectTo = `/events/${eventId}/guests/${guestId}/reject`;
  return action;
}

export function deliverBatchDirectEmail(eventId, directEmailId, query, notificationsOptions = {}) {
  const { DEV_NULL, DELIVER_BATCH_DIRECT_EMAIL_SUCCESS, DELIVER_BATCH_DIRECT_EMAIL_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, DELIVER_BATCH_DIRECT_EMAIL_SUCCESS, DELIVER_BATCH_DIRECT_EMAIL_FAILURE],
      method: "PUT",
      body: { q: query },
      endpoint: `/events/${eventId}/direct_emails/${directEmailId}/batch_deliver.json`,
      notificationsOptions
    }
  };
}

export function clearState() {
  const { CLEAR_DIRECT_EMAIL_STATE } = ActionTypes;
  return {
    type: CLEAR_DIRECT_EMAIL_STATE
  };
}

