import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Loader from "../shared/Loader.react";
import isEmpty from "lodash/isEmpty";

class RegistrationFormPickerModal extends Component {
  constructor(props) {
    super(props);
    [
      "submit",
      "onChangeName",
      "onSelectedRegistrationForm"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = {
      selectedRegistrationFormId: [],
      name: ""
    };
  }

  isSubmittable() {
    const { selectedRegistrationFormId, name } = this.state;
    return !isEmpty(selectedRegistrationFormId) && !isEmpty(name) && name.match(/\S/);
  }

  submit(e) {
    e.preventDefault();
    const { selectedRegistrationFormId, name } = this.state;
    if (!this.isSubmittable()) return;
    this.props.onSubmit(selectedRegistrationFormId, name);
  }

  onChangeName(e) {
    this.setState({ name: e.target.value });
  }

  onSelectedRegistrationForm(e) {
    this.setState({ selectedRegistrationFormId: e.target.value });
  }

  renderRegistrationForms() {
    const { registrationForms } = this.props;
    const { selectedRegistrationFormId } = this.state;

    const options = registrationForms.map(form => {
      return <option value={form._id} key={form._id}>{form.title}</option>;
    });
    return <div className="mb-3">
      <select className="form-select" value={ selectedRegistrationFormId } onChange={this.onSelectedRegistrationForm}>
        <option value="">{ I18n.t("react.duplication.registration_form_picker_modal.please_choose") }</option>
        {options}
      </select>
    </div>;
  }

  renderBody() {
    const { event, registrationForms, back } = this.props;
    const { name } = this.state;
    if (!event || !registrationForms) return <Loader />;

    if (registrationForms.length == 0) {
      return <div className="text-center mt-30">
        <h4>{ I18n.t("react.duplication.registration_form_picker_modal.no_form") }</h4>
        <a href="#" className="btn btn-secondary mt-10 mb-30" onClick={back}>{ I18n.t("react.duplication.registration_form_picker_modal.back_to_event_selection") }</a>
      </div>;
    }

    return <Modal.Body>
      <div className="mb-3">
        <label htmlFor="newCategoryName" className="form-label">{ I18n.t("react.duplication.registration_form_picker_modal.name_label") }</label>
        <input type="text" className="form-control" id="newCategoryName" value={name} onChange={this.onChangeName} />
      </div>
      { this.renderRegistrationForms() }
    </Modal.Body>;
  }

  renderFooter() {
    const { registrationForms, back } = this.props;
    if (!registrationForms || registrationForms.length == 0) return;

    return <Modal.Footer>
      <a href="#" className="btn btn-secondary" onClick={back}>{ I18n.t("react.duplication.registration_form_picker_modal.back_to_event_selection") }</a>
      <button type="submit" className="btn btn-primary" onClick={this.submit} disabled={!this.isSubmittable()}>
        { I18n.t("create") }
      </button>
    </Modal.Footer>;
  }

  render() {
    const { isVisible, onClose, modalTitle } = this.props;

    return <Modal show={isVisible} onHide={onClose} size="lg">
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
        <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
      </Modal.Header>
      <form>
        { this.renderBody() }
        { this.renderFooter() }
      </form>
    </Modal>;
  }
}

export default RegistrationFormPickerModal;

RegistrationFormPickerModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  event: PropTypes.object,
  registrationForms: PropTypes.array
};

RegistrationFormPickerModal.defaultProps = {
  isVisible: false
};
