import { CALL_API, ActionTypes } from "../constants/Constants";

export function fetchCustomPreconfiguredEvents(accountId: string, searchParams: any): any {
  const { FETCH_CUSTOM_PRECONFIGURED_EVENTS_REQUEST, FETCH_CUSTOM_PRECONFIGURED_EVENTS_SUCCESS, FETCH_CUSTOM_PRECONFIGURED_EVENTS_ERROR } = ActionTypes;

  return {
    [CALL_API]: {
      types: [FETCH_CUSTOM_PRECONFIGURED_EVENTS_REQUEST, FETCH_CUSTOM_PRECONFIGURED_EVENTS_SUCCESS, FETCH_CUSTOM_PRECONFIGURED_EVENTS_ERROR],
      method: "GET",
      endpoint: `/api/v1/accounts/${accountId}/preconfigured_events.json`,
      body: { search_params: searchParams, custom_only: true }
    }
  };
}
