import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import Icons from "../../constants/Icons";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Notice from "../../components/features/Notice.react";
import EmailTemplateSelector from "../../components/email_templates/EmailTemplateSelector.react";
import { requestEvent } from "../../actions/ImportActionCreators";
import SenderEmailAddressesField from "../SenderEmailAddressesField.react";
import { clearCustomEmail, createCustomEmail, updateCustomEmail } from "../../actions/CustomEmailActionCreators";
import usePrevious from "../../utils/hooks/usePrevious";
import HelpSection from "../shared/HelpSection.react";

interface Props {
  eventId: string;
  i18nPath: string;
  customEmailType: string;
  emailStoreKey: string;
  fetchEmail(): void;
}

const defaultCustomEmail: any = {
  _id: null,
  updated_at: `${new Date()}`,
  subject: "",
  sender: "",
  email_template_id: null,
  reply_to_email: "",
};

const EventEmailPicker: React.FC<Props> = ({ eventId, i18nPath, customEmailType, emailStoreKey, fetchEmail }) => {
  const [slidingPaneOpen, setSlidingPaneOpen] = useState(false);
  const [localCustomEmail, setLocalCustomEmail] = useState(defaultCustomEmail);

  const event = useSelector((state: any) => state.event);
  const errors = useSelector((state: any) => state.customEmail.errors);

  const pendingRequest = useSelector((state: any) => state.customEmail.pending);
  const previousPendingRequest = usePrevious(pendingRequest);

  const customEmail = useSelector((state: any) => state.event[emailStoreKey]);

  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  useEffect(() => {
    dispatch(requestEvent());
    dispatch(fetchEmail());
  }, []);

  useEffect(() => {
    setLocalCustomEmail(customEmail || localCustomEmail);
  }, [customEmail]);

  useEffect(() => {
    if (previousPendingRequest === true && pendingRequest === false && errors === null)
      setSlidingPaneOpen(false);
  }, [pendingRequest]);

  if (!event?._id) return null;

  const i18n = (key: string, opts: any = {}): string => {
    return I18n.t(`${i18nPath}.${key}`, opts);
  };

  const togglePane = (e: MouseEvent<HTMLAnchorElement> = null): void => {
    if (e) e.preventDefault();

    if (!slidingPaneOpen) dispatch(clearCustomEmail());
    setSlidingPaneOpen(!slidingPaneOpen);
  };

  const renderPickerButton = (): JSX.Element => {
    return <a href="#" className="btn btn-secondary" onClick={togglePane}>
      <i className="fa-regular fa-envelope"></i> { i18n("configure_email") }
    </a>;
  };

  const onSubmit = (e: any): void => {
    if (e) e.preventDefault();

    const customEmailParams = {
      custom_email: {
        subject: localCustomEmail.subject || i18n("default_subject", { event_title: event.title }),
        sender: localCustomEmail.sender || event.organizer,
        type: customEmailType,
        email_template_id: localCustomEmail.email_template_id,
        reply_to_email: localCustomEmail.reply_to_email || event.reply_to_email,
        sender_email: localCustomEmail.sender_email
      }
    };

    const request = customEmail?._id ? updateCustomEmail(eventId, customEmail._id, customEmailParams) : createCustomEmail(eventId, customEmailParams);

    dispatch(request);
  };

  const onFieldChange = (fieldKey: string): ((value: string) => void) => {
    return (value): void => {
      setLocalCustomEmail({ ...localCustomEmail, [fieldKey]: value });
    };
  };

  const onTextFieldChange = (fieldKey: string): ((e: ChangeEvent<HTMLInputElement>) => void) => {
    return (e: ChangeEvent<HTMLInputElement>): void => {
      return onFieldChange(fieldKey)(e.target.value);
    };
  };

  const senderEmailAddress = localCustomEmail.sender_email ? localCustomEmail.sender_email : event.sender_email_address;
  const allSenderEmailAddresses = event.all_sender_email_addresses || [];

  const renderEmailForm = (): JSX.Element => {
    return <form onSubmit={onSubmit} className="sliding-pane-form">
      <div className="form-container card mb-0">
        <fieldset>
          <Notice errors={errors} noticeType={errors ? "alert" : "success"} noLabelKeys={["email_template_id"]} model="custom_email" className="mt-10"/>
          <div className="mb-3">
            <label className="form-label">{ i18n("subject") }</label>
            <input className="form-control" type="text" name="subject" value={localCustomEmail?.subject} onChange={onTextFieldChange("subject")} placeholder={ i18n("default_subject", { event_title: event.title }) } />
          </div>
          <div className="mb-3">
            <label className="form-label">{ i18n("sender") }</label>
            <input className="form-control" type="text" name="sender" value={localCustomEmail?.sender} onChange={onTextFieldChange("sender")} placeholder={ event.organizer } />
          </div>
          <div className="mb-3">
            <label className="form-label">{ i18n("reply_to_email") }</label>
            <input className="form-control" type="email" name="reply_to_email" value={localCustomEmail?.reply_to_email} onChange={onTextFieldChange("reply_to_email")} placeholder={event.reply_to_email} />
          </div>
          <EmailTemplateSelector slidingPaneTitle={ i18n("email_template_selector_title") }
            selectedEmailTemplateId={localCustomEmail.email_template_id}
            persistedEmailTemplateId={customEmail?.email_template_id}
            onSelectTemplate={onFieldChange("email_template_id")}
            afterEmailTemplatesClose={onSubmit}
            event={event}
            enableDelete={false}
            match={match}
            location={location}
            history={history} />

          <HelpSection help={i18n("email_template_hint")} />
          <SenderEmailAddressesField
            customEmailSenderEmail={senderEmailAddress}
            allSenderEmailAddresses={allSenderEmailAddresses || []}
            label={I18n.t("shared.custom_email_fields.sender_email")}
            name="sender_email"
            onSelectSenderEmail={onFieldChange("sender_email")} />
        </fieldset>
      </div>
      <div className="text-end">
        <button type="submit" className="btn btn-primary mt-10" disabled={false} onClick={onSubmit}>
          { i18n("submit") }
        </button>
      </div>
    </form>;
  };

  const renderSlidingPane = (): JSX.Element => {
    return <SlidingPane isOpen={ slidingPaneOpen }
      width='60%'
      title={i18n("email_configuration")}
      onRequestClose={ togglePane }
      className="width-100-xs width-80-sm"
      closeIcon={Icons.close()}>

      { renderEmailForm() }

    </SlidingPane>;
  };

  return <div>
    { renderPickerButton() }
    { renderSlidingPane() }
  </div>;
};

export default EventEmailPicker;
