import { Component } from "react";
import PropTypes from "prop-types";
import without from "lodash/without";
import Modal from "react-modal";
import { Form } from "react-bootstrap";
import { defaultModalBigStyles } from "../../constants/Style";

class SelectOptionsGroupsModal extends Component {
  constructor(props) {
    super(props);
    [
      "toggleModal",
      "toggleCheckAll",
      "onChangeGroup",
      "onSubmit"
    ].forEach((fn) => {
      this[fn] = this[fn].bind(this);
    });
    this.state = {
      isOpen: false,
      checkAll: !props.formItem.options.options_groups,
      selectedGroups: props.formItem.options.options_groups || Object.keys(props.groupedOptions),
      errorMessage: null
    };
  }

  toggleModal(e) {
    if (e) e.preventDefault();
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  toggleCheckAll() {
    const { checkAll } = this.state;
    const { groupedOptions } = this.props;
    this.setState({ checkAll: !checkAll, selectedGroups: checkAll ? [] : Object.keys(groupedOptions) });
  }

  onChangeGroup(e) {
    const { selectedGroups } = this.state;
    const { groupedOptions } = this.props;
    const value = e.target.value;

    if (e.target.checked) {
      this.setState({ selectedGroups: selectedGroups.concat(value), checkAll: selectedGroups.length + 1 == Object.keys(groupedOptions).length });
    } else {
      this.setState({ selectedGroups: without(selectedGroups, value), checkAll: false });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const { checkAll, selectedGroups } = this.state;
    const { updateGroups } = this.props;

    if (selectedGroups.length == 0) {
      this.setState({ errorMessage: I18n.t("react.form_items.select_options_groups.error_message") });
    } else {
      this.setState({ errorMessage: null }, this.toggleModal());
      updateGroups(checkAll ? null : selectedGroups);
    }
  }

  renderErrorMessage() {
    const { errorMessage } = this.state;
    if (!errorMessage)
      return null;

    return <div className="mt-10 text-danger">
      { errorMessage }
    </div>;
  }

  renderModalContent() {
    const { isOpen, checkAll, selectedGroups } = this.state;
    const { groupedOptions } = this.props;

    if (!isOpen)
      return;

    return <div>
      <div className="modal-header">
        <h4 className="modal-title">{ I18n.t("react.form_items.select_options_groups.modal_title") }</h4>
        <button type="button" onClick={ this.toggleModal } className="btn-close" aria-label={I18n.t("close")}></button>
      </div>
      <div className="modal-body">
        <h4 className="modal-title">{ I18n.t("react.form_items.select_options_groups.modal_subtitle") }</h4>
        <div className="mt-30 ml-20">
          <Form.Group controlId="check_all">
            <Form.Check checked={checkAll.toString() === "true"} onChange={this.toggleCheckAll} />
            <Form.Label><strong>{ I18n.t("react.form_items.select_options_groups.check_all") }</strong></Form.Label>
          </Form.Group>
          { Object.entries(groupedOptions).map(([groupName, groupOptions], index) => {
            return <Form.Group key={index} controlId={`check_all_${groupName}`}>
              <Form.Check checked={ checkAll.toString() === "true" || selectedGroups.includes(groupName) } onChange={this.onChangeGroup} value={groupName} />
              <Form.Label>{ groupName } <em>({groupOptions.length} options)</em></Form.Label>
            </Form.Group>;
          })}
        </div>
        { this.renderErrorMessage() }
      </div>
      <div className="modal-footer text-center">
        <a href="#" onClick={ this.onSubmit } className="btn btn-primary btn-lg d-grid" > { I18n.t("react.form_items.select_options_groups.validate") } </a>
      </div>
    </div>;
  }

  render() {
    const { isOpen } = this.state;
    return <div className="mt-10">
      <a href="#" onClick={this.toggleModal}>
        {I18n.t("react.form_items.select_options_groups.manage")}
      </a>
      <Modal isOpen={isOpen} onRequestClose={this.toggleModal} style={defaultModalBigStyles} contentLabel="Modal">
        { this.renderModalContent() }
      </Modal>
    </div>;
  }
}

SelectOptionsGroupsModal.propTypes = {
  groupedOptions: PropTypes.object.isRequired,
  updateGroups: PropTypes.func.isRequired
};

export default SelectOptionsGroupsModal;
