import { useContext } from "react";
import { featureName, featureSetTranslation } from "../../utils/featureSetUtils";
import { FeatureDeckContext } from "../../containers/features/FeatureSetFormContainer.react";
import { Modal } from "react-bootstrap";

interface Props {
  featureKey?: string;
  hide: () => void;
}

const DisablingModal: React.FC<Props> = ({ featureKey, hide }) => {
  const { toggleFeature, enabledDependents } = useContext(FeatureDeckContext);

  if (!featureKey) return null;

  const renderListDependents = (featureKey: string): JSX.Element => {
    return <ul className="dependencies-list mt-10 ps-0">
      { enabledDependents(featureKey).map((dependency, index) => {
        return <li key={ index }>{ featureName(dependency) }</li>;
      })}
    </ul>;
  };

  return <Modal show={ true } onHide={ hide } className="feature-set">
    <Modal.Header>
      <h4 className="modal-title">
        { featureSetTranslation("disabling_modal.title") }
        { " " }
        <small className="text-warning"> { I18n.t("admin_only") } </small>
      </h4>
      <button type="button" onClick={hide} className="btn-close" aria-label={I18n.t("close")}></button>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        <div className="col-sm-12">
          <p>{ I18n.t("features_form.disabling_modal.warning") }</p>
          <p>{ I18n.t(enabledDependents(featureKey).length > 0 ? "features_form.disabling_modal.text_with_dependents" : "features_form.disabling_modal.text", { name: featureName(featureKey) }) }</p>
          { renderListDependents(featureKey) }
          <div className="btn-group-modal d-flex flex-row justify-content-end">
            <button type="button" className="btn btn-secondary mr-10" onClick={ hide }>{ I18n.t("cancel") }</button>
            <button type="button" className="btn btn-primary" onClick={ (): void => { toggleFeature(featureKey); hide(); }}>
              { featureSetTranslation("disabling_modal.submit") }
            </button>
          </div>
        </div>
      </div>
    </Modal.Body>
  </Modal>;
};

export default DisablingModal;
