import { GuestProductCollectionField } from "../../../types/GuestProductCollectionField";
import { UI_COLORS } from "../../../constants/Constants";

interface Props {
  guestProductCollectionField: GuestProductCollectionField;
  isDefaultField?: boolean;
}

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`events.edit_advanced.guest_product_collections.${key}`, options);
};

const GuestProductCollectionFieldShow: React.FC<Props> = ({
  guestProductCollectionField,
  isDefaultField
}) => {

  const renderTextInput = (): JSX.Element => {
    return <input type="text" className="form-control" disabled readOnly />;
  };

  const renderTextArea = (): JSX.Element => {
    return <textarea rows={2} className="form-control" disabled readOnly></textarea>;
  };

  const renderFile = (): JSX.Element => {
    return <div className="custom-file row">
      <div className="input-group">
        <div className="form-control disabled"></div>
        <div className="input-group-text disabled" style={{ borderColor: UI_COLORS.grey200 }}>{I18n.t("choose_file")}</div>
      </div>
    </div>;
  };

  const renderImage = (): JSX.Element => {
    return <div className="card" style={{ backgroundColor: UI_COLORS.grey50 }}>
      <div className="card-body text-center">
        <span><i className="fa-regular fa-file-image fa-2x disabled"></i></span>
      </div>
    </div>;
  };

  const renderValuesList = (): JSX.Element => {
    if (!guestProductCollectionField.possible_values) return;

    return <>
      { guestProductCollectionField.possible_values.map((possibleValue, index) => {
        return <div className="row mb-5" key={index}>
          <div className="col-md-8 col-10">
            <input type="text" className="form-control" value={possibleValue.value} disabled />
          </div>
        </div>;
      }) }
    </>;
  };

  const renderFieldType = (): JSX.Element => {
    switch (guestProductCollectionField.type) {
    case "text":
      return renderTextInput();
    case "text_area":
      return renderTextArea();
    case "file":
      return renderFile();
    case "image":
      return renderImage();
    case "values_list":
      return renderValuesList();
    default:
      return null;
    }
  };

  return <div className={`card ${guestProductCollectionField.hidden ? "d-none" : ""}`} style={isDefaultField ? { cursor: "no-drop" } : {}}>
    <div className="card-header">
      <h4 className="card-title">{ guestProductCollectionField.name } {isDefaultField && <i className="form-text">({ i18n("default") })</i>}</h4>
      <div className="form-text">{ i18n(`type_${guestProductCollectionField.type}`) }</div>
    </div>
    <div className="row card-body">
      <div className="col-sm-7 disabled">
        { renderFieldType() }
      </div>
    </div>
  </div>;
};

export default GuestProductCollectionFieldShow;
