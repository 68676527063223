import { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { defaultModalStyles } from "../../constants/Style";
import Loader from "../shared/Loader.react";
import FilterDropdown from "../FilterDropdown.react";
import isEmpty from "lodash/isEmpty";

class GuestCategoryPickerModal extends Component {
  constructor(props) {
    super(props);
    [
      "submit",
      "onChangeName",
      "setSelectedGuestCategoryIds"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = {
      selectedGuestCategoryIds: [],
      name: ""
    };
  }

  isSubmittable() {
    const { selectedGuestCategoryIds, name } = this.state;
    return !isEmpty(selectedGuestCategoryIds[0]) && !isEmpty(name) && name.match(/\S/);
  }

  submit(e) {
    e.preventDefault();
    const { selectedGuestCategoryIds, name } = this.state;
    if (!this.isSubmittable()) return;
    this.props.onSubmit(selectedGuestCategoryIds[0], name);
  }

  setSelectedGuestCategoryIds(ids) {
    const { guestCategories } = this.props;
    const categoryId = ids[0];
    const category = guestCategories.find(category => category._id == categoryId);
    const title = category && category.name;
    this.setState({
      selectedGuestCategoryIds: ids,
      categoryDropdownTitle: title
    });
  }

  onChangeName(e) {
    this.setState({ name: e.target.value });
  }

  renderGuestCategories() {
    const { guestCategories } = this.props;
    const { selectedGuestCategoryIds, categoryDropdownTitle } = this.state;

    return <FilterDropdown
      id="uniq_guest_category_picker"
      items={guestCategories}
      translationKey="uniq_guest_category_picker"
      selectedItemIds={selectedGuestCategoryIds}
      onChange={this.setSelectedGuestCategoryIds}
      showCells={false}
      itemColorKey="label_color"
      multipleSelect={false}
      itemIdKey="_id"
      title={categoryDropdownTitle}
      hasSelectAll={false}
      hasSearch={true}
    />;
  }

  renderBody() {
    const { event, guestCategories } = this.props;
    const { name } = this.state;
    if (!event || !guestCategories) return <Loader />;

    return [
      <div className="mb-3" key="category-name-input">
        <label htmlFor="newCategoryName" className="form-label">{ I18n.t("react.duplication.guest_category_picker_modal.name_label") }</label>
        <input type="text" className="form-control" id="newCategoryName" value={name} onChange={this.onChangeName} />
        <p className="form-text">{ I18n.t("react.duplication.guest_category_picker_modal.name_help") }</p>
      </div>,
      <div className="mb-3" key="guest-category-select">
        { this.renderGuestCategories() }
      </div>
    ];
  }

  render() {
    const { isVisible, onClose, modalTitle } = this.props;

    return <Modal isOpen={isVisible} onRequestClose={onClose} style={defaultModalStyles} contentLabel="Modal">
      <div className="modal-header">
        <h4 className="modal-title">{modalTitle}</h4>
        <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
      </div>
      <div className="modal-body">
        { this.renderBody() }
      </div>
      <div className="modal-footer">
        <a href="#" className="btn btn-primary" onClick={this.submit} disabled={!this.isSubmittable()}>{ I18n.t("create") }</a>
      </div>
    </Modal>;
  }
}

export default GuestCategoryPickerModal;

GuestCategoryPickerModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  event: PropTypes.object,
  guestCategories: PropTypes.array
};

GuestCategoryPickerModal.defaultProps = {
  isVisible: false
};
