import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSessionsByBookmarks } from "../../actions/SessionActionCreators";
import Loader from "../../components/shared/Loader.react";
import ReportHeader from "../../components/event_reports/ReportHeader.react";
import { isAuthorized } from "../../utils/aclUtils";
import { identity } from "../../utils/personUtils";
import Select from "react-select";
import { Dropdown } from "react-bootstrap";
import uniqBy from "lodash/uniqBy";

const Sessions: React.FC = () => {
  const eventId = useSelector((state: any) => state.event.id);
  const dataFetched = useSelector((state: any) => state.sessionsStatistics.byNumberOfBookmarks);
  const isFetching = useSelector((state: any) => state.sessionsStatistics.isFetching);
  const dispatch = useDispatch();
  const [sessionIdSelected, setSessionIdSelected] = useState(null);
  const [guestIdSelected, setGuestIdSelected] = useState(null);
  const [result, setResult] = useState([]);
  const [sortField, setSortField] = useState("nb_bookmarks");

  useEffect(() => {
    if (!eventId) return;
    dispatch(fetchSessionsByBookmarks(eventId, sortField));
  }, [eventId, sortField]);

  useEffect(() => {
    setResult(dataFetched);
  }, [dataFetched]);

  useEffect(() => {
    if (!sessionIdSelected && !guestIdSelected) {
      setResult(dataFetched);
      return;
    }

    const resultFiltered = dataFetched.filter(item => {
      if (sessionIdSelected && item._id !== sessionIdSelected) return false;
      return !guestIdSelected || item.guests.some(e => e._id === guestIdSelected);
    });
    setResult(resultFiltered);
  }, [sessionIdSelected, guestIdSelected]);

  useEffect(() => {
    setSessionIdSelected(null);
    setGuestIdSelected(null);
  }, []);

  if (!isAuthorized("reports", "read")) return null;

  const i18n = (key: string, options: any = {}): string => {
    return I18n.t(`react.event_reports.sessions_by_number_of_interests.${key}`, options);
  };

  const exportGuestsIdentities = (guests): string => {
    return guests.map((guest) => identity(guest)).join("\n");
  };

  const exporterProps = (): any => {
    return {
      data: {
        data: result?.map(
          ({ guests, name, nb_bookmarks }) => {
            return {
              name,
              exhibitors: exportGuestsIdentities(guests),
              nb_bookmarks
            };
          }
        )
      },
      options: { excludeTotal: true },
      columns: ["name", "exhibitors", "nb_bookmarks"]
    };
  };

  const renderHeader = (): JSX.Element => {
    return <ReportHeader
      title={I18n.t("react.event_reports.event_reports.sessions_by_number_of_interests")}
      exporterToExcelProps={result?.length > 0 ? exporterProps() : null}
      exportType="sessions_by_number_of_interests"
    />;
  };

  const renderSortDropdown = (): JSX.Element => {
    const rows = ["nb_bookmarks", "name"].map(sort => {
      return <Dropdown.Item key={sort} onClick={(): void => setSortField(sort)}>{i18n(`sort_by_${sort}`)}</Dropdown.Item>;
    });

    return <div className="float-end">
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-sort">
          {i18n(`sort_by_${sortField}`)}
        </Dropdown.Toggle>
        <Dropdown.Menu
          align="end"
          popperConfig={{
            strategy: "fixed",
            onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll"))
          }}
        >
          {rows}
        </Dropdown.Menu>
      </Dropdown>
    </div>;
  };

  const renderTableHeader = (): JSX.Element => {
    const sessions = result?.map(({ _id, name }) => ({ value: _id, label: name }));
    let guests = result?.map(({ guests }) => (guests));
    if (guests) guests = [].concat(...guests);
    guests = uniqBy(
      guests?.map((guest) => ({ value: guest._id, label: identity(guest) }))
      , "value");

    return (
      <>
        <tr>
          <th style={{ fontWeight: "normal" }}>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              options={sessions}
              value={sessions?.filter(session => sessionIdSelected?.includes(session.value))}
              placeholder={i18n("select_a_session")}
              noOptionsMessage={(): any => i18n("no_session")}
              onChange={(option): any => setSessionIdSelected(option?.value)}
              isClearable={true}
              menuPosition="fixed"
              styles={{
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                menuPortal: ({ left, ...provided }, state) => ({
                  ...provided
                })
              }}
            />
          </th>
          <th style={{ fontWeight: "normal" }}>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              options={guests}
              value={guests?.filter(guest => guestIdSelected?.includes(guest.value))}
              placeholder={i18n("select_an_exhibitor")}
              noOptionsMessage={(): any => i18n("no_exhibitor")}
              onChange={(option): any => setGuestIdSelected(option?.value)}
              isClearable={true}
              menuPosition="fixed"
              styles={{
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                menuPortal: ({ left, ...provided }, state) => ({
                  ...provided
                })
              }}
            />
          </th>
          <th>{renderSortDropdown()}</th>
        </tr>
        <tr>
          <th>{I18n.t("react.event_reports.report_table.session_name")}</th>
          <th>{I18n.t("react.event_reports.report_table.exhibitors")}</th>
          <th>{I18n.t("react.event_reports.report_table.nb_bookmarks")}</th>
        </tr>
      </>
    );
  };

  const renderTable = (): JSX.Element => {
    if (!dataFetched) return null;
    if (result?.length === 0) return renderEmptyMsg();

    return <div className="row">
      <div className="col-12">
        <div className="table-responsive table-container">
          <table className="table table-light table-hover table-sm">
            <thead>
              {renderTableHeader()}
            </thead>
            <tbody>
              {renderTableBody()}
            </tbody>
          </table>
        </div>
      </div>
    </div>;
  };

  const renderExhibitorsIdentities = (guests): JSX.Element => {
    return <ul style={{ paddingLeft: "15px", marginBottom: "0px" }}>
      {guests.map(guest => (
        <li key={guest._id}>
          <b>{identity(guest)}</b>
          <div>
            <small>{guest.company_name}</small>
          </div>
        </li>
      ))}
    </ul>;
  };

  const renderTableBody = (): JSX.Element[] => {
    return result?.map(
      ({ _id, name, nb_bookmarks, guests }) => {
        return (
          <tr key={_id}>
            <td>{name}</td>
            <td>{renderExhibitorsIdentities(guests)}</td>
            <td>{nb_bookmarks}</td>
          </tr>
        );
      }
    );
  };

  const renderEmptyMsg = (): JSX.Element => {
    return <div className="card nothing-yet">
      <h4>{i18n("no_data")}</h4>
    </div>;
  };

  return <>
    { renderHeader() }
    { isFetching ? <Loader/> : renderTable() }
  </>;
};

export default Sessions;
