import { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { urlEventId } from "../utils/pathUtils";
import ErrorMessage from "./shared/ErrorMessage.react";
import Loader from "./shared/Loader.react";
import { defaultModalStyles } from "../constants/Style";
import {
  DEFAULT_COLUMNS_SETTING,
  IDENTITY_CELL,
  COLUMN_STANDARD_FIELD_TYPE,
  COLUMN_SECTION_STANDARD_TYPE,
  COLUMN_SECTION_GUEST_FIELD_TYPE,
  COLUMN_SECTION_LINK_TYPE,
  COLUMN_SECTION_QUARTILES_THEMATIC_TYPE,
  COLUMN_SECTION_STANDARD_SCORE_THEMATIC_TYPE,
} from "../constants/Constants";
import ColumnsFilter from "./ColumnsFilter.react";
import { enabledStandardFields, availableStandardFieldColumns, availableGuestFieldColumns, availableLinkColumns,
  thematicQuartileColumns, thematicStdScoreColumns } from "../utils/columnsSetUtils";

const SECTIONS = [COLUMN_SECTION_STANDARD_TYPE, COLUMN_SECTION_GUEST_FIELD_TYPE, COLUMN_SECTION_LINK_TYPE, COLUMN_SECTION_QUARTILES_THEMATIC_TYPE, COLUMN_SECTION_STANDARD_SCORE_THEMATIC_TYPE];

class GuestsTableColumnsPicker extends Component {
  constructor(props) {
    super(props);
    this.handleColumnsChange = this.handleColumnsChange.bind(this);
    this.reset = this.reset.bind(this);
    this.updateGuestsColumns = this.updateGuestsColumns.bind(this);
  }

  handleColumnsChange(newColumns) {
    let columns = newColumns.slice();
    if (columns.length === 0) {
      columns.push({
        type: COLUMN_STANDARD_FIELD_TYPE,
        identifier: IDENTITY_CELL
      });
    }

    const sortFn = (a, b) => {
      return a.rank - b.rank;
    };

    this.updateGuestsColumns(columns.sort(sortFn));
  }

  reset() {
    this.updateGuestsColumns(DEFAULT_COLUMNS_SETTING);
  }

  updateGuestsColumns(guests_columns) {
    const { updateUserSettings } = this.props;
    updateUserSettings({ guests_columns });
  }

  isColumnsFilterDisplayable() {
    const { guestFieldsFetched, documentTemplates, thematics } = this.props;
    return guestFieldsFetched && documentTemplates && thematics;
  }

  render() {
    const { isOpen, close, userSettings, guestFields, documentTemplates, event, columnsSets, saveColumnsSet, thematics, deleteColumnsSet } = this.props;
    const { errors, pendingWriteRequest } = columnsSets;
    const standardFields = enabledStandardFields();
    const standardFieldCells = availableStandardFieldColumns(standardFields, event);
    const guestFieldCells = availableGuestFieldColumns(guestFields, standardFields);
    const linkCells = availableLinkColumns(documentTemplates);
    const thematicQuartileCells = thematicQuartileColumns(event, thematics);
    const thematicStdScoreCells = thematicStdScoreColumns(event, thematics);

    return (
      <Modal className="modal-content"
        isOpen={isOpen}
        onRequestClose={close}
        style={defaultModalStyles}
        contentLabel="Modal"
      >
        <div className="modal-header">
          <h4 className="modal-title">{I18n.t("react.reports.guests_table_columns_picker.title")}</h4>
          <button onClick={close} type="button" className="btn btn-close"></button>
        </div>
        <div className="modal-body">
          <ErrorMessage errors={errors}/>
          { this.isColumnsFilterDisplayable() ?
            <ColumnsFilter
              sections={SECTIONS}
              standardFields={standardFieldCells}
              guestFields={guestFieldCells}
              links={linkCells}
              onSubmit={this.handleColumnsChange}
              otherButtonTitle={I18n.t("react.reports.guests_table_columns_picker.reset")}
              otherButtonFn={this.reset}
              helpText={I18n.t("react.reports.guests_table_columns_picker.help")}
              event={event}
              eventId={urlEventId()}
              saveColumnsSet={saveColumnsSet}
              deleteColumnsSet={deleteColumnsSet}
              savingColumnsSet={pendingWriteRequest}
              initialColumns={userSettings.guests_columns}
              thematics={thematics}
              quartile_thematics={thematicQuartileCells}
              std_thematics={thematicStdScoreCells}
            />
            : <Loader size="xlarge" inline={false} containerHeight="350px" />
          }
        </div>
      </Modal>
    );
  }
}

GuestsTableColumnsPicker.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  updateUserSettings: PropTypes.func.isRequired,
  saveColumnsSet: PropTypes.func.isRequired,
  userSettings: PropTypes.object.isRequired,
  guestFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  guestFieldsFetched: PropTypes.bool,
  documentTemplates: PropTypes.arrayOf(PropTypes.object),
  event: PropTypes.object.isRequired,
  columnsSets: PropTypes.object.isRequired,
  thematics: PropTypes.arrayOf(PropTypes.object)
};

export default GuestsTableColumnsPicker;
