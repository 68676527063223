import { Component } from "react";

import ImageUploader from "../../components/shared/ImageUploader.react";
import { urlEventId } from "../../utils/pathUtils";


class TemplateImageUploader extends Component {
  s3UploadURL() {
    const { builderType, templateId } = this.props;

    if (builderType == "email") {
      return `/api/v1/events/${urlEventId()}/email_templates/${templateId}/s3_upload_url`;
    } else if (builderType == "website") {
      return `/api/v1/events/${urlEventId()}/website/s3_upload_url`;
    } else if (builderType == "blog_article") {
      return `/api/v1/events/${urlEventId()}/website/blog_articles/${templateId}/s3_upload_url`;
    } else if (builderType == "document") {
      return `/api/v1/events/${urlEventId()}/document_templates/${templateId}/s3_upload_url`;
    }
  }

  render() {
    return <div className="img-bg-btn">
      <ImageUploader s3UploadUrl={this.s3UploadURL()} mode="templates_builder" {...this.props} field="templates_builder" />
    </div>;
  }
}

export default TemplateImageUploader;
