import { useState } from "react";
import Flag from "./Flag.react";

export interface Props {
  label: string;
  labelClass?: string;
  name?: string;
  value: string;
  error?: string;
  help?: string;
  divWrapperClass?: string;
  preventTranslations?: boolean;
  availableLocales?: string[];
  translationsLabel: string;
  translationsName?: string;
  translationsValues: any;
  translationErrors?: any;
  disabled?: boolean;

  onChange: (value: string) => void;
  onTranslationChanged: (locale: string, value: string) => void;
}

const TranslatableInput: React.FC<Props> = ({
  label,
  labelClass,
  name,
  value,
  error,
  help,
  divWrapperClass,
  preventTranslations,
  availableLocales,
  translationsLabel,
  translationsName,
  translationsValues,
  translationErrors,
  disabled,
  onChange,
  onTranslationChanged
}) => {

  const [showTranslations, setShowTranslations] = useState(false);
  const canTranslate = !preventTranslations && availableLocales && availableLocales.length > 0;

  const renderTranslationsToggle = (): JSX.Element => {
    const labelKey = showTranslations ? "hide_path_name_translations" : "show_path_name_translations";

    return <div className="mt-10">
      <a
        href="#"
        onClick={(e): void => {
          e.preventDefault();
          setShowTranslations(!showTranslations);
        }}
      >
        <i className="fa-regular fa-language"></i> {I18n.t(`react.website.${labelKey}`)}
      </a>
    </div>;
  };

  const errorForLocale = (locale: string): string|null => {
    return translationErrors?.find(e => e.includes(locale));
  };

  const renderTranslationsInputs = (): JSX.Element => {
    return <div className="mb-3 row">
      <label className={`col-form-label ${labelClass || "col-12"}`}>{translationsLabel}</label>
      <div className={divWrapperClass || "col-12"}>
        {availableLocales.map((locale, i) => {
          return <div style={{ marginBottom: "15px" }} key={`translations-${label}-${locale}`}>
            <div className="input-group">
              <div className="input-group-text">
                <Flag localeCode={locale} />
              </div>
              <input
                type="text"
                className="form-control"
                value={translationsValues[locale] || ""}
                disabled={disabled}
                onChange={(e): void => onTranslationChanged(locale, e.target.value)}
                placeholder={value}
                name={translationsName ? `${translationsName}[${locale}]` : null}
              />
            </div>
            {renderError(errorForLocale(locale))}
            {showTranslations && i === availableLocales.length - 1 && (
              renderTranslationsToggle()
            )}
          </div>;
        })}
      </div>
    </div>;
  };

  const renderError = (error: string): JSX.Element => {
    if (!error) return null;

    return <span className="text-danger" style={{ "marginTop": "5px", "display": "inline-block" }}>{error}</span>;
  };

  return <>
    <div className="mb-3 row">
      <label className={`col-form-label ${labelClass || "col-12"}`}>{label}</label>
      <div className={divWrapperClass || "col-12"}>
        <input
          type="text"
          className="form-control"
          value={value}
          onChange={(e): void => onChange(e.target.value)}
          disabled={disabled}
          name={name}
        />
        {help && <div className="form-text">{help}</div>}
        {renderError(error)}
        {canTranslate && !showTranslations && renderTranslationsToggle()}
      </div>
    </div>

    {showTranslations && renderTranslationsInputs()}
  </>;
};

export default TranslatableInput;
