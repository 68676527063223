import { ActionTypes, CALL_API } from "../constants/Constants";

export function fetchAccesspointsStatistics(eventId: string, type = "green"): any {
  const { DEV_NULL, ACCESSPOINTS_GREEN_STATISTICS_SUCCESS, ACCESSPOINTS_RED_STATISTICS_SUCCESS } = ActionTypes;
  const successType = type == "green" ? ACCESSPOINTS_GREEN_STATISTICS_SUCCESS : ACCESSPOINTS_RED_STATISTICS_SUCCESS;
  return {
    [CALL_API]: {
      types: [DEV_NULL, successType, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guests/statistics.json?field=${type}_access_control_ids&limit=-1`
    }
  };
}

export function fetchAccesspointsOccupancy(eventId: string): any {
  const { FETCH_ACCESSPOINTS_OCCUPANCY_REQUEST, FETCH_ACCESSPOINTS_OCCUPANCY_SUCCESS, FETCH_ACCESSPOINTS_OCCUPANCY_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_ACCESSPOINTS_OCCUPANCY_REQUEST, FETCH_ACCESSPOINTS_OCCUPANCY_SUCCESS, FETCH_ACCESSPOINTS_OCCUPANCY_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/accesspoints/occupancies.json`
    }
  };
}

export function fetchAccesspointsLiveConsumption(eventId: string, searchQuery: string): any {
  const { FETCH_LIVE_CONSUMPTION_REQUEST, FETCH_LIVE_CONSUMPTION_SUCCESS, FETCH_LIVE_CONSUMPTION_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_LIVE_CONSUMPTION_REQUEST, FETCH_LIVE_CONSUMPTION_SUCCESS, FETCH_LIVE_CONSUMPTION_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/accesspoints/live_consumption.json?${searchQuery}`
    }
  };
}
