import { useSelector } from "react-redux";
import withProgramFilter from "../withProgramFilter.react";
import ThematicsDropdownComponent from "../../../components/ThematicsDropdownComponent.react";
import { FilterProps, FilterOption } from "../../../types/Program";

const ThematicsFilter: React.FC<FilterProps> = ({ programFilter, onChangeFilterPresets }) => {
  const metadata = useSelector((state: any) => state.programs.metadata);

  const thematics = (): any => {
    if (!metadata[programFilter._type]) return [];

    return metadata[programFilter._type].possible_values;
  };

  const onChangeMultiSelect = (options: FilterOption[]): void => {
    onChangeFilterPresets("thematics", options.map(({ value }) => value));
  };

  return <ThematicsDropdownComponent
    value={programFilter["preset_args"]["thematics"] || []}
    onChange={onChangeMultiSelect}
    allowCreate={false}
    coloredThematics={true}
    followHierarchyOnSelect={false}
    thematics={thematics()}
  />;
};

export default withProgramFilter(ThematicsFilter, "selection");
