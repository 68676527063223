import { Component } from "react";
import { StreamingArchive } from "../types/StreamingArchive";
import GenericS3Uploader from "../components/shared/GenericS3Uploader.react";
import { Accesspoint } from "../types/Accesspoint";
import { fetchStreamingArchives, createStreamingArchive, updateStreamingArchive } from "../actions/StreamingArchivesManagerActionCreator";
import { connect } from "react-redux";
import { urlEventId } from "../utils/pathUtils";
import Loader from "../components/shared/Loader.react";
import VideoPlayer from "../components/shared/VideoPlayer.react";
import { archiveFileName } from "../utils/fileUtils";
import url from "url";

const STREAMING_ARCHIVE_UPLOADED_STATUS = "uploaded";

interface Props {
  accesspointId: string;
  accesspoint: Accesspoint;
  streaming_archive_used_for_live_from_file_id: string;
  onChangeSelectedArchive(e: any): any;
  fetchStreamingArchives(eventId: string, accesspointId: string): any;
  createStreamingArchive(eventId: string, accesspointId: string, params: any): any;
  isFetching: boolean;
  onClose(): void;
  streamingArchives: StreamingArchive[];
  updateStreamingArchive(eventId: string, accesspointId: string, streamingArchiveId: string, params: StreamingArchive): any;
}

interface State {
  streamingArchives: StreamingArchive[];
}

class PreRecordedLiveFilePicker extends Component<Props, State> {
  i18n(key: string): string {
    return I18n.t(`react.accesspoint.pre_recorded_live_file_picker.${key}`);
  }

  constructor(props: Props) {
    super(props);

    [
      "addStreamingArchive"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    const { streamingArchives } = this.props;
    this.state = {
      streamingArchives
    };
  }

  componentDidMount(): void {
    const { fetchStreamingArchives, streamingArchives, isFetching, accesspointId } = this.props;

    if (accesspointId && !isFetching && !streamingArchives) fetchStreamingArchives(urlEventId(), accesspointId);
  }

  componentDidUpdate(prevProps): void {
    const { streamingArchives, onChangeSelectedArchive } = this.props;

    if (prevProps.streamingArchives && streamingArchives && prevProps.streamingArchives.length < streamingArchives.length) {
      const oldArchiveIds = prevProps.streamingArchives.map(archive => archive._id);
      const newStreamingArchive = streamingArchives.find(archive => !oldArchiveIds.includes(archive._id) && archive._id);

      onChangeSelectedArchive({ target: { value: newStreamingArchive._id } });
    }
  }

  addStreamingArchive(signedUrl: string): void {
    const { accesspointId, createStreamingArchive } = this.props;
    const uri = url.parse(signedUrl);
    const streamingArchiveAttributes = {
      status: STREAMING_ARCHIVE_UPLOADED_STATUS,
      path: uri.pathname.replace(/\//, "")
    };

    createStreamingArchive(urlEventId(), accesspointId, streamingArchiveAttributes);
  }

  s3UploadURL(): string {
    const { accesspointId } = this.props;
    return `/api/v1/events/${urlEventId()}/accesspoints/${accesspointId}/streaming_archives/s3_upload_url`;
  }

  renderDropZoneDirectUploader(): JSX.Element {
    const { isFetching } = this.props;

    return (
      <GenericS3Uploader
        acceptedFiles={ [".mp4"] }
        classNameUploader="streaming-archives-uploader-zone card"
        disabled={ isFetching }
        onFinishUpload={ this.addStreamingArchive }
        publicRead={ false }
        s3UploadURL={ this.s3UploadURL() }
      >
        <div className="card-body">
          <div>
            { this.i18n("dnd_file")}
            {" "}
            <u>{ this.i18n("select_file")}</u>
          </div>
          <div className="form-text text-center">{ this.i18n("max_file_size_help") }</div>
          { this.renderCurrentLiveVideoName() }
        </div>
      </GenericS3Uploader>
    );
  }

  renderCurrentLiveVideoName(): JSX.Element {
    const { streamingArchives, streaming_archive_used_for_live_from_file_id } = this.props;
    if (!streamingArchives || streamingArchives.length == 0) return;

    const archive = streamingArchives.find(arch => arch._id == streaming_archive_used_for_live_from_file_id);
    if (!archive) return;

    return <div className="form-text text-center mt-30" style={{ fontSize: "x-large" }}>
      { archiveFileName(archive) }
    </div>;
  }

  renderArchiveDropdown(): JSX.Element {
    const { streamingArchives, onChangeSelectedArchive, streaming_archive_used_for_live_from_file_id } = this.props;
    if (!streamingArchives || streamingArchives.length == 0) return;

    const options = streamingArchives.map(archive => {
      if (archive.extension === "m3u8") return null;

      return <option value={archive._id} key={archive._id}>{archiveFileName(archive)}</option>;
    });

    return <div>
      <h2>{this.i18n("or_choose_existing_file")}</h2>
      <div className="mb-3">
        <div className="input-group">
          <select className="form-select" onChange={onChangeSelectedArchive} value={streaming_archive_used_for_live_from_file_id}>
            { !streaming_archive_used_for_live_from_file_id && <option value="" key="on_archive">{ this.i18n("no_file_selected") }</option> }
            {options}
          </select>
        </div>
      </div>
    </div>;
  }

  renderArchivePlayer(): JSX.Element {
    const { streamingArchives, streaming_archive_used_for_live_from_file_id } = this.props;

    if (!streamingArchives || !streaming_archive_used_for_live_from_file_id) return;

    const archive = streamingArchives.find(archive => archive._id === streaming_archive_used_for_live_from_file_id);

    if (!archive) return;

    const videoJsOptions = {
      autoplay: false,
      controls: true,
      sources: [{
        src: archive.public_url,
        type: "video/mp4"
      }],
      width: 320,
      height: 240
    };

    return (
      <div key={archive._id}>
        <VideoPlayer {...videoJsOptions} />
      </div>
    );
  }

  render(): JSX.Element {
    const { accesspointId, streamingArchives } = this.props;

    if (!accesspointId || !streamingArchives) return <Loader size="large" inline={false} containerHeight="350px" />;

    return <div>
      { this.renderDropZoneDirectUploader() }
      <div className="row">
        <div className="col-md-6">
          { this.renderArchiveDropdown() }
        </div>
        <div className="col-md-6">
          { this.renderArchivePlayer() }
        </div>
      </div>
    </div>;
  }
}

const mapStateToProps = (state: any): any => {
  const { streamingArchives, errors, isFetching } = state.streamingArchives;
  const accesspoint = state.accesspoints && state.accesspoints.currentAccesspoint;

  return {
    errors,
    isFetching,
    streamingArchives,
    accesspoint,
    accesspointId: accesspoint && accesspoint._id
  };
};

const mapDispatchToProps = {
  createStreamingArchive,
  fetchStreamingArchives,
  updateStreamingArchive
};

export default connect(mapStateToProps, mapDispatchToProps)(PreRecordedLiveFilePicker);
