import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFilterMetadata } from "../../actions/ProgramsActionCreators";
import DeletablePanel from "./DeletablePanel.react";
import { urlEventId } from "../../utils/pathUtils";
import { ProgramFilter, FilterContext } from "../../types/Program";

export interface WithProgramFilterProps {
  programFilter: ProgramFilter;
  filterKey: string;

  updateFilter(programFilter: ProgramFilter, filterKey: string): void;
  removeFilter(programFilter: ProgramFilter, filterKey: string, context: FilterContext): void;
}

function withProgramFilter<P extends WithProgramFilterProps>(WrappedComponent: React.FC<P>, context: FilterContext): React.FC<P> {
  const ProgramFilter: React.FC<P> = (props) => {
    const metadata = useSelector((state: any) => state.programs.metadata);
    const isFetchingMetadata = useSelector((state: any) => state.programs.isFetchingMetadata);

    const dispatch = useDispatch();

    const { programFilter, filterKey, updateFilter, removeFilter } = props;

    useEffect(() => {
      if (!metadata[props.programFilter._type] && !isFetchingMetadata) { // fetch metadata once
        dispatch(fetchFilterMetadata(urlEventId(), [props.programFilter._type]));
      }
    }, []);

    const changePresetArgs = (field: string, value: string | string[]): void => {
      if (programFilter._type === "trait") {
        programFilter.trait_key = field;
        programFilter["preset_args"] = { [field]: value };
      } else {
        programFilter["preset_args"][field] = value;
      }
      updateFilter(programFilter, filterKey);
    };

    const changeLabelOptions = (label: string, isVisible: boolean): void => {
      updateFilter({ ...programFilter, label, label_displayed: isVisible }, filterKey);
    };

    return <DeletablePanel
      title={I18n.t(`react.programs.${programFilter._type}_filter.title`)}
      rank={programFilter.rank}
      onDelete={(): void => removeFilter(programFilter, filterKey, context)}
      draggable={context === "ui"}>
      <WrappedComponent {...props}
        onChangeFilterPresets={changePresetArgs}
        onChangeFilter={(programFilter: ProgramFilter): void => updateFilter(programFilter, filterKey)}
        onChangeLabelOptions={changeLabelOptions}
      />
    </DeletablePanel>;
  };

  return ProgramFilter;
}

export default withProgramFilter;
