import { Component } from "react";
import PropTypes from "prop-types";
import Loader from "./Loader.react";

class SubmitInputButton extends Component {

  constructor(props) {
    super(props);

    [
      "componentDidUpdate",
      "onInputChange",
      "onKeyPress",
      "onSubmit",
      "toggleInput"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      askingInput: props.error ? true : false,
      inputContent: ""
    };
  }

  componentDidUpdate(prevProps) {
    const { loading, error, toggleInputAfterSubmit } = this.props;
    const { loading: prevLoading } = prevProps;
    if (!toggleInputAfterSubmit) return;

    if (prevLoading && !loading && !error) {
      this.setState({
        askingInput: false,
        inputContent: ""
      });
    }
  }

  toggleInput(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      askingInput: !this.state.askingInput
    });
  }

  onInputChange(e) {
    this.setState({
      inputContent: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { inputContent } = this.state;
    this.props.onSubmit(inputContent);
  }

  onKeyPress(e) {
    if (e.keyCode === 13) {
      e.stopPropagation();
      const { inputContent } = this.state;
      this.props.onSubmit(inputContent);
    }
  }

  renderButton() {
    const { children, className, disabled } = this.props;
    return <button type="button" className={className} onClick={this.toggleInput} disabled={disabled}>{ children }</button>;
  }

  renderInputAndButton() {
    const { submitInputButtonTitle, inputPlaceholder, loading, disabled, error, submitButtonClassName, submitInputButtonIcon } = this.props;
    const { inputContent } = this.state;
    const submitButtonContent = submitInputButtonIcon ? <i className={submitInputButtonIcon}></i> : submitInputButtonTitle;
    return (
      <div className="mb-3" style={{ marginBottom: "0px" }}>
        <div className="input-group disabled">
          <input type="text" className="form-control" placeholder={inputPlaceholder} value={inputContent} onChange={this.onInputChange} disabled={disabled} onKeyDown={this.onKeyPress}/>
          <button type="button" className={submitButtonClassName} onClick={this.onSubmit} disabled={disabled}>
            {loading ? <Loader size="small" color="black" /> : submitButtonContent}
          </button>
        </div>
        {error ? (
          <div className="form-text text-danger" style={{ marginLeft: "5px", display: "inline" }}>{ error }</div>
        ) : null}
      </div>
    );
  }

  render() {
    const { askingInput } = this.state;
    return askingInput ? this.renderInputAndButton() : this.renderButton();
  }
}

SubmitInputButton.defaultProps = {
  disabled: false,
  error: null,
  loading: false,
  submitButtonClassName: "btn btn-secondary",
  submitInputButtonTitle: "Ok",
  toggleInputAfterSubmit: false
};

SubmitInputButton.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitButtonClassName: PropTypes.string,
  submitInputButtonIcon: PropTypes.string,
  submitInputButtonTitle: PropTypes.string,
  toggleInputAfterSubmit: PropTypes.bool
};

export default SubmitInputButton;
