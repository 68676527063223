import { ChangeEvent } from "react";

interface Props {
  label: string;
  url: string;
  name: string;
  onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void
}

const ImageInputWithPreview: React.FC<Props> = ({ label, url, name, onChangeHandler }) => {
  const img = url ? <p style={{ marginBottom: "20px" }}><img src={url} className="img-thumbnail"></img></p> : null;
  return (
    <>
      <div className="mb-3">
        <label htmlFor={name} className="form-label">{label}</label>
        <div className="custom-file row">
          <div className="input-group">
            <div className="form-control">{I18n.t("no_file_selected")}</div>
            <div className="input-group-text">{I18n.t("choose_file")}</div>
          </div>
          <input type="file" accept="image/*" name={name} id={name} onChange={onChangeHandler}/>
        </div>
      </div>
      {img}
    </>
  );
};

export default ImageInputWithPreview;
