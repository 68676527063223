import { CALL_API, ActionTypes } from "../constants/Constants";

module.exports = {
  updateSheets(key, value) {
    return {
      type: ActionTypes.UPDATE_SHEETS,
      key,
      value
    };
  },

  receiveGuests(guests, previousURL, nextURL, uniqueKeyInSiloedData = null) {
    return {
      type: ActionTypes.RECEIVE_GUESTS,
      guests,
      previousURL,
      nextURL,
      uniqueKeyInSiloedData
    };
  },

  receiveGuestsError() {
    return {
      type: ActionTypes.RECEIVE_GUESTS_ERROR
    };
  },

  toggleGuest(guests, forceCheck) {
    return {
      type: ActionTypes.TOGGLE_GUEST,
      guests,
      forceCheck
    };
  },

  clearSelectedGuests() {
    return {
      type: ActionTypes.CLEAR_SELECTED_GUESTS
    };
  },

  startFetchingGuests() {
    return {
      type: ActionTypes.START_FETCHING_GUESTS
    };
  },

  fetchGuest(eventId, guestId) {
    const { FETCH_GUEST_REQUEST, RECEIVE_GUEST, RECEIVE_GUEST_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [FETCH_GUEST_REQUEST, RECEIVE_GUEST, RECEIVE_GUEST_FAILURE],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/guests/${guestId}.json`
      }
    };
  },

  updateAccessPrivileges(eventId, guestId, accessPrivilegesAttributes) {
    const { UPDATE_GUEST_REQUEST, UPDATE_GUEST, UPDATE_GUEST_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [UPDATE_GUEST_REQUEST, UPDATE_GUEST, UPDATE_GUEST_FAILURE],
        method: "PUT",
        body: { guest: { access_privileges_attributes: accessPrivilegesAttributes } },
        endpoint: `/api/v1/events/${eventId}/guests/${guestId}.json`
      }
    };
  },

  sampleGuestCreation(eventId) {
    const { DEV_NULL, SAMPLE_GUESTS_CREATION_SUCCESS } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, SAMPLE_GUESTS_CREATION_SUCCESS, DEV_NULL],
        method: "POST",
        body: {},
        endpoint: `/events/${eventId}/guests/batch_create_sample.json`,
        redirectTo: `/events/${eventId}/guests?q=`
      }
    };
  },

  resendWebhooks(eventId, completeSearchQuery) {
    const { DEV_NULL, RESEND_WEBHOOKS_SUCCESS, RESEND_WEBHOOKS_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, RESEND_WEBHOOKS_SUCCESS, RESEND_WEBHOOKS_FAILURE],
        method: "PUT",
        body: { q: completeSearchQuery },
        endpoint: `/events/${eventId}/guests/resend_webhooks.json`
      }
    };
  },

  synchronizeGuestsIntoAccount(eventId, completeSearchQuery) {
    const { DEV_NULL } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, DEV_NULL, DEV_NULL],
        method: "PUT",
        body: { q: completeSearchQuery },
        endpoint: `/events/${eventId}/guests/synchronize_into_account.json`
      }
    };
  },

  reloadGuestsPage() {
    return {
      type: ActionTypes.RELOAD_GUESTS_PAGE
    };
  }
};
