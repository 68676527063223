import { CALL_API, ActionTypes } from "../constants/Constants";

export function fetchMeetingLocations(eventId: string): any {
  const { FETCH_MEETING_LOCATIONS_REQUEST, FETCH_MEETING_LOCATIONS_SUCCESS, DEV_NULL } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_MEETING_LOCATIONS_REQUEST, FETCH_MEETING_LOCATIONS_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/meeting_locations.json`,
    }
  };
}
