import { Component, ChangeEvent, FormEvent } from "react";
import { Website } from "../types/Website";
import { pathToWebsiteConfig } from "../utils/pathUtils";
import ImageInputWithPreview from "./ImageInputWithPreview.react";
import WebsiteFormSaveButton from "./WebsiteFormSaveButton.react";

type WebsitePwa = Pick<Website, "pwa_enabled" | "pwa_name" | "pwa_description" | "pwa_short_name" | "pwa_display" | "pwa_icon">;

interface Props extends WebsitePwa {
  updated_at: Date;
  onSubmit: (data: State) => void;
  isPendingRequest: boolean;
}

interface State extends WebsitePwa {
  currentIconUrl?: string;
}

function i18n(key, opts = {}): string {
  return I18n.t(`react.pwa.${key}`, opts);
}

const displaySettingHelpURL = "https://developer.mozilla.org/en-US/docs/Web/Manifest/display";
const PWA_DISPLAY_VALUES = ["fullscreen", "standalone", "minimal-ui", "browse"];

class WebsitePwaConfigForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    [
      "onTextChange",
      "onSubmit",
      "onSelectChange",
      "onTextAreaChange",
      "onCheckBoxChange",
      "onFileChange"
    ].forEach(fn => (this[fn] = this[fn].bind(this)));

    const { pwa_name, pwa_short_name, pwa_description, pwa_display, pwa_enabled, pwa_icon } = props;
    this.state = {
      pwa_name,
      pwa_short_name,
      pwa_description: pwa_description || "",
      pwa_display: pwa_display || PWA_DISPLAY_VALUES[0],
      pwa_enabled,
      currentIconUrl: pwa_icon ? pwa_icon.url : "",
      pwa_icon: null
    };
  }

  componentDidUpdate(prevProps: Props): void {
    const { pwa_icon } = this.props;
    const { pwa_icon: prevPwaIcon } = prevProps;

    if (pwa_icon !== prevPwaIcon) {
      this.setState({ currentIconUrl: pwa_icon ? pwa_icon.url : "" });
    }
  }

  onTextChange(e: ChangeEvent<HTMLInputElement>): void {
    const { target } = e;
    const { name: key, value } = target;
    this.setState({ [key]: value } as Pick<State, "pwa_name" | "pwa_short_name" | "pwa_description">);
  }

  onFileChange(e: ChangeEvent<HTMLInputElement>): void {
    const { target } = e;
    const { name: key, files } = target;
    this.setState({ [key]: files[0] } as Pick<State, "pwa_icon">);
  }

  onCheckBoxChange(e: ChangeEvent<HTMLInputElement>): void {
    const { target } = e;
    const { name: key, checked: value } = target;
    this.setState({ [key]: value } as Pick<State, "pwa_enabled">);
  }

  onSelectChange(e: ChangeEvent<HTMLSelectElement>): void {
    const { target } = e;
    const { name: key, value } = target;
    this.setState({ [key]: value } as Pick<State, "pwa_display">);
  }

  onTextAreaChange(e: ChangeEvent<HTMLTextAreaElement>): void {
    const { target } = e;
    const { name: key, value } = target;
    this.setState({ [key]: value } as Pick<State, "pwa_description">);
  }

  onSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { pwa_name, pwa_short_name, pwa_description, pwa_display, pwa_enabled, pwa_icon } = this.state;
    onSubmit({ pwa_name, pwa_short_name, pwa_description, pwa_display, pwa_enabled, pwa_icon });
  }

  renderInput(type: string, label: string, value: any, name: string, onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void): JSX.Element {
    return (
      <>
        <label htmlFor={name} className="form-label">{label}</label>
        <input
          type={type}
          className="form-control"
          placeholder={label}
          value={value || ""}
          name={name}
          id={name}
          onChange={onChangeHandler}
        />
      </>
    );
  }

  renderSelect(label: string, value: any, name: string, values: any, onChangeHandler: (e: ChangeEvent<HTMLSelectElement>) => void, help?: JSX.Element): JSX.Element {
    const options: JSX.Element = values.map(value => {
      return <option value={value} key={value}>{value}</option>;
    });

    return (
      <div>
        <label htmlFor={name} className="form-label">{label}</label>
        <select className="form-select" value={value || ""} name={name} onChange={onChangeHandler}>
          {options}
        </select>
        <div className="form-text">{help}</div>
      </div>
    );
  }

  renderCheckbox(label: string, value: boolean, name: string, onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void): JSX.Element {
    return (
      <div className="form-check mb-0">
        <label className="form-check-label">
          <input type="checkbox" className="form-check-input" checked={value} name={name} onChange={onChangeHandler}/>
          {label}
        </label>
      </div>
    );
  }

  renderTextArea(label: string, value = "", name: string, onChangeHandler: (e: ChangeEvent<HTMLTextAreaElement>) => void, help?: JSX.Element): JSX.Element {
    return (
      <div className="mb-3">
        <label htmlFor={name} className="form-label">{label}</label>
        <textarea name={name} value={value} onChange={onChangeHandler} className="form-control" rows={4} style={{ resize: "none" }} />
        <div className="form-text">{help}</div>
      </div>
    );
  }

  renderConfigurationBlock(): JSX.Element {
    const { pwa_name, pwa_short_name, pwa_description, pwa_display, pwa_enabled, currentIconUrl } = this.state;
    const { updated_at } = this.props;
    // use updated_at to force the browsers to refresh the image
    const pwaIconUrl: string = currentIconUrl ? `${currentIconUrl}?${updated_at}` : "";
    const displayHelp = <span>{i18n("display_help")} <a href={displaySettingHelpURL} target="_blank">{i18n("here")}</a></span>;

    return (
      <div>
        <div className="header-page">
          <div className="header-page-title">
            <h1>
              <a href={pathToWebsiteConfig()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></a>
              {i18n("settings")}
            </h1>
          </div>
        </div>

        <div className="card mb-10">
          <div className="card-body">
            { this.renderCheckbox(i18n("enabled"), pwa_enabled, "pwa_enabled", this.onCheckBoxChange) }
            { pwa_enabled && (
              <div className="mt-3">
                <div className="mb-3 row">
                  <div className="col-md-6">
                    { this.renderInput("text", i18n("name"), pwa_name, "pwa_name", this.onTextChange) }
                  </div>
                  <div className="col-md-6">
                    { this.renderInput("text", i18n("short_name"), pwa_short_name, "pwa_short_name", this.onTextChange) }
                  </div>
                </div>
                { this.renderTextArea(i18n("description"), pwa_description, "pwa_description", this.onTextAreaChange) }
                <ImageInputWithPreview label={i18n("icon")} url={pwaIconUrl} name={"pwa_icon"} onChangeHandler={this.onFileChange} />
                <div className="row">
                  <div className="col-md-6">
                    { this.renderSelect(i18n("display"), pwa_display, "pwa_display", PWA_DISPLAY_VALUES, this.onSelectChange, displayHelp) }
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  render(): JSX.Element {
    return (
      <form onSubmit={this.onSubmit}>
        <fieldset>
          {this.renderConfigurationBlock()}
          <WebsiteFormSaveButton
            pendingRequest={this.props.isPendingRequest}
          />
        </fieldset>
      </form>
    );
  }
}

export default WebsitePwaConfigForm;
