interface Props {
  help: string | JSX.Element;
  badgeText?: string;
  type?: string;
  classNames?: string;
  customIcon?: string;
  noIcon?: boolean;
}

const HelpSection: React.FC<Props> = ({
  help,
  badgeText,
  type,
  classNames,
  customIcon,
  noIcon
}) => {
  const icon = customIcon ? customIcon : "fa-check fa-inverse";

  return (
    <p className={`section-help ${classNames || ""}`}>
      <span className={`badge rounded-pill bg-${type ? type : "info"}`}>
        {!noIcon && <i className={`fa-regular ${icon}`}></i>} {badgeText ? badgeText : I18n.t("help")}
      </span>
      <span className="text-muted ml-5">{help}</span>
    </p>
  );
};

export default HelpSection;
