import { ActionTypes, CALL_API } from "../constants/Constants";

module.exports = {
  fetchAssetsFolders(eventId) {
    const { FETCH_ASSETS_FOLDERS_REQUEST, FETCH_ASSETS_FOLDERS_SUCCESS, FETCH_ASSETS_FOLDERS_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [FETCH_ASSETS_FOLDERS_REQUEST, FETCH_ASSETS_FOLDERS_SUCCESS, FETCH_ASSETS_FOLDERS_FAILURE],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/website/assets_folders.json`
      }
    };
  },

  fetchAssetsFolder(eventId, assetsFolderId) {
    const { FETCH_ASSETS_FOLDER_REQUEST, FETCH_ASSETS_FOLDER_SUCCESS, FETCH_ASSETS_FOLDER_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [FETCH_ASSETS_FOLDER_REQUEST, FETCH_ASSETS_FOLDER_SUCCESS, FETCH_ASSETS_FOLDER_FAILURE],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/website/assets_folders/${assetsFolderId}.json`
      }
    };
  },

  createAssetsFolder(eventId, params) {
    const { CREATE_ASSETS_FOLDER_REQUEST, CREATE_ASSETS_FOLDER_SUCCESS, CREATE_ASSETS_FOLDER_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [CREATE_ASSETS_FOLDER_REQUEST, CREATE_ASSETS_FOLDER_SUCCESS, CREATE_ASSETS_FOLDER_FAILURE],
        method: "POST",
        body: {
          assets_folder: params
        },
        endpoint: `/api/v1/events/${eventId}/website/assets_folders.json`
      }
    };
  },

  fetchAssets(eventId, assetsFolderId) {
    const { FETCH_ASSETS_REQUEST, FETCH_ASSETS_SUCCESS, FETCH_ASSETS_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [FETCH_ASSETS_REQUEST, FETCH_ASSETS_SUCCESS, FETCH_ASSETS_FAILURE],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/website/assets_folders/${assetsFolderId}/assets.json`
      }
    };
  },

  createAsset(eventId, assetsFolderId, params) {
    const { CREATE_ASSET_REQUEST, CREATE_ASSET_SUCCESS, CREATE_ASSET_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [CREATE_ASSET_REQUEST, CREATE_ASSET_SUCCESS, CREATE_ASSET_FAILURE],
        method: "POST",
        body: {
          asset: params
        },
        endpoint: `/api/v1/events/${eventId}/website/assets_folders/${assetsFolderId}/assets.json`
      }
    };
  },

  deleteAsset(eventId, assetsFolderId, assetId) {
    const { DELETE_ASSET_REQUEST, DELETE_ASSET_SUCCESS, DELETE_ASSET_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DELETE_ASSET_REQUEST, DELETE_ASSET_SUCCESS, DELETE_ASSET_FAILURE],
        method: "DELETE",
        endpoint: `/api/v1/events/${eventId}/website/assets_folders/${assetsFolderId}/assets/${assetId}`
      },
      assetId
    };
  },

  updateAsset(eventId, assetsFolderId, assetId, params) {
    const { UPDATE_ASSET_REQUEST, UPDATE_ASSET_SUCCESS, UPDATE_ASSET_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [UPDATE_ASSET_REQUEST, UPDATE_ASSET_SUCCESS, UPDATE_ASSET_FAILURE],
        method: "PUT",
        body: {
          asset: params
        },
        endpoint: `/api/v1/events/${eventId}/website/assets_folders/${assetsFolderId}/assets/${assetId}`
      }
    };
  }

};