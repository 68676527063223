import { ActionTypes, CALL_API } from "../constants/Constants";

export function fetchContact(accountId, contactId) {
  const { FETCH_CONTACT_REQUEST, FETCH_CONTACT_SUCCESS, FETCH_CONTACT_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_CONTACT_REQUEST, FETCH_CONTACT_SUCCESS, FETCH_CONTACT_FAILURE],
      method: "GET",
      endpoint: `/api/v1/accounts/${accountId}/contacts/${contactId}.json`
    }
  };
}

function fetchContactHistoryWithEndpoint(endpoint, initialCall = true) {
  const { FETCH_CONTACT_HISTORY_REQUEST, FETCH_CONTACT_HISTORY_SUCCESS, FETCH_CONTACT_HISTORY_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_CONTACT_HISTORY_REQUEST, FETCH_CONTACT_HISTORY_SUCCESS, FETCH_CONTACT_HISTORY_FAILURE],
      method: "GET",
      endpoint
    },
    resetContactHistory: initialCall
  };
}

export function fetchContactHistory(contact) {
  const endpoint = `/api/v1/accounts/${contact.account_id}/contacts/${contact._id}/history_items.json`;
  return fetchContactHistoryWithEndpoint(endpoint);
}

export function fetchContactHistoryPage(pageURL) {
  return fetchContactHistoryWithEndpoint(pageURL, false);
}

function fetchContactActivitiesWithEndpoint(endpoint, initialCall = true) {
  const { FETCH_CONTACT_ACTIVITIES_REQUEST, FETCH_CONTACT_ACTIVITIES_SUCCESS, FETCH_CONTACT_ACTIVITIES_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_CONTACT_ACTIVITIES_REQUEST, FETCH_CONTACT_ACTIVITIES_SUCCESS, FETCH_CONTACT_ACTIVITIES_FAILURE],
      method: "GET",
      endpoint
    },
    resetContactActivities: initialCall
  };
}

export function fetchContactActivities(contact) {
  const endpoint = `/api/v1/accounts/${contact.account_id}/contacts/${contact._id}/activities.json`;
  return fetchContactActivitiesWithEndpoint(endpoint);
}

export function fetchContactActivitiesPage(pageURL) {
  return fetchContactActivitiesWithEndpoint(pageURL, false);
}

export function clearContactState() {
  return {
    type: ActionTypes.CLEAR_CONTACT_STATE
  };
}

export function generateEmailConfirmationLink(contact) {
  const { DEV_NULL, GENERATE_EMAIL_CONFIRMATION_LINK_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, GENERATE_EMAIL_CONFIRMATION_LINK_SUCCESS, DEV_NULL],
      method: "PUT",
      endpoint: `/api/v1/accounts/${contact.account_id}/contacts/${contact._id}/generate_email_confirmation_link`,
      body: {}
    }
  };
}
