import { fetchVisitRouteExportEmail } from "../../actions/EventActionCreators";
import EventEmailPicker from "../../components/shared/EventEmailPicker.react";

interface Props {
  eventId: string;
}

const ExportEmailPicker: React.FC<Props> = ({ eventId, }) => {
  return <EventEmailPicker
    eventId={eventId}
    i18nPath="react.visit_routes.export_email_picker"
    customEmailType="visit_route_export"
    emailStoreKey="visitRouteExportEmail"
    fetchEmail={(): any => fetchVisitRouteExportEmail(eventId)}
  />;
};

export default ExportEmailPicker;
