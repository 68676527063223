import { Component } from "react";
import PropTypes from "prop-types";
import { FormItemsWithValueList } from "../../constants/Constants";
import isEqual from "lodash/isEqual";

class FormItemOption extends Component {

  constructor(props) {
    super(props);

    ["inputChanged", "inputOnBlur", "destroy", "selectChanged"].forEach(item => {
      this[item] = this[item].bind(this);
    });

    const { itemOption } = props;
    this.state = {
      label: itemOption.label,
      key: itemOption.key
    };
  }

  componentDidUpdate(prevProps) {
    const { itemOption } = this.props;
    if (!isEqual(prevProps.itemOption, itemOption)) {
      this.setState({
        label: itemOption.label,
        key: itemOption.key
      });
    }
  }

  label(option) {
    const { labelField } = this.props;
    return option[labelField || "label"];
  }

  key(option) {
    const { keyField } = this.props;
    return option[keyField || "key"];
  }

  inputChanged(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  inputOnBlur(e) {
    const target = e.target.name;
    const { itemOption, updateHandler } = this.props;
    if (itemOption[target] === this.state[target]) {
      return;
    }
    updateHandler(Object.assign({}, itemOption, { [target]: this.state[target] }));
  }

  selectChanged(e) {
    const { itemOption, updateHandler, keyInputData } = this.props;
    const field = keyInputData.find(field => this.key(field) == e.target.value);
    if (!field) return;

    const key = this.key(field);
    const label = this.label(field);

    updateHandler(Object.assign({}, itemOption, { key, label }));

    this.setState({
      key, label
    });
  }

  destroy() {
    const { itemOption, destroyHandler } = this.props;
    destroyHandler(itemOption._id);
  }

  needsKeyInput() {
    const { formItem } = this.props;
    return !FormItemsWithValueList.includes(formItem.type);
  }

  renderKeyInput() {
    const { keyInputType, keyInputData } = this.props;

    if (!this.needsKeyInput())
      return null;

    if (keyInputType === "text")
      return this.renderInput("key");

    //dropdown
    if (!keyInputData)
      throw new Error("No keyInputData props to a FormItemOption with keyInputType dropdown");

    const options = keyInputData.map(opt => {
      return <option key={ this.key(opt) } value={ this.key(opt) }>{ this.label(opt) }</option>;
    });
    return (
      <select className="form-select" value={this.state.key} name="key" onChange={this.selectChanged}>
        {options}
      </select>
    );
  }

  renderInput(name) {
    const { disableLabel } = this.props;
    const disable = name == "label" && disableLabel;
    const value = this.state[name];

    return (
      <input type="text" className="form-control" value={value} name={name} onChange={this.inputChanged} onBlur={this.inputOnBlur} disabled={disable} />
    );
  }

  render() {
    const { children, allowDestroy, mode, itemOption, readStyle, defaultValue } = this.props;

    if (mode === "read") {
      const isDefaultValue = defaultValue && defaultValue == itemOption.value;
      const isDefaultLabel = isDefaultValue ? `(${I18n.t("react.form_checkbox_group.checked_by_default")})` : null;

      return (
        <div style={readStyle}>
          <span>{ children } { itemOption.label } <strong>{ isDefaultLabel }</strong></span>
          <hr className="light-hr" />
        </div>
      );
    }

    const destroyButton = allowDestroy ? (
      <a className="btn btn-secondary" onClick={this.destroy} style={ { cursor: "pointer" } }>
        <i className="fa-regular fa-close"></i>
      </a>
    ) : "";

    return (
      <div className="row mb-10">
        <div className="col-8">
          <div className="input-group">
            <div className="input-group-text">{ children }</div>
            { this.renderInput("label") }
          </div>
        </div>
        <div className="col-3">
          { this.renderKeyInput() }
        </div>
        <div className="col-1">
          { destroyButton }
        </div>
      </div>
    );
  }
}

FormItemOption.propTypes = {
  itemOption: PropTypes.object.isRequired,
  keyInputType: PropTypes.string.isRequired, // text | dropdown
  keyInputData: PropTypes.array, // required if type = dropdown, requires [{ key: "some keys", label: "some labels" }]
  readStyle: PropTypes.object,
  labelField: PropTypes.string // field from itemOption used to display the label
};

/*
props injected by FormItemWithOptions:
- mode (read | edit)
- allowDestroy (true | false)
- updateHandler (func)
- destroyHandler (func)
*/

FormItemOption.defaultValue = {
  readStyle: {}
};

export default FormItemOption;
