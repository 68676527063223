import { Fragment, Component } from "react";
import Sortable from "./Sortable.react";
import { DragTypes, COLUMN_STANDARD_SCORE_THEMATIC_TYPE, COLUMN_QUARTILE_THEMATIC_TYPE } from "../constants/Constants";
import PropTypes from "prop-types";
import flatten from "lodash/flatten";
import ColumnsSetPicker from "../containers/ColumnsSetPicker.react";
import SubmitInputButton from "../components/shared/SubmitInputButton.react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Loader from "./shared/Loader.react";

import {
  COLUMN_SECTION_STANDARD_TYPE,
  COLUMN_SECTION_GUEST_FIELD_TYPE,
  COLUMN_SECTION_ACCESSPOINT_TYPE,
  COLUMN_SECTION_QUARTILES_THEMATIC_TYPE,
  COLUMN_SECTION_STANDARD_SCORE_THEMATIC_TYPE
} from "../constants/Constants";

const SECTIONS = [COLUMN_SECTION_STANDARD_TYPE, COLUMN_SECTION_GUEST_FIELD_TYPE, COLUMN_SECTION_ACCESSPOINT_TYPE];

const defaultActionSelect = [
  <option key="default" value="default">{I18n.t("react.export_columns_filter.hint_pick_field")}</option>
];

class ColumnsFilter extends Component {
  constructor(props) {
    super(props);
    [
      "clearAllSection",
      "createColumnsSet",
      "deleteColumnsSet",
      "handleDrop",
      "onChangeSelect",
      "onColumnsSetSelected",
      "onSubmit",
      "removeColumnSelected",
      "selectAllSection",
      "updateColumnsSet"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    if (props.initialColumns) {
      this.state = this.stateForColumns(props.initialColumns);
    } else if (props.selectedColumnsSet) {
      this.state = {
        ...this.stateForColumns(props.selectedColumnsSet.columns),
        selectedColumnsSetId: props.selectedColumnsSet._id,
        isSelectedColumnsChanged: false
      };
    } else {
      this.state = this.emptyState();
    }
  }

  emptyState() {
    return this.props.sections.reduce((acc, { selectedItemsKeyInState, lastSelectedItemKeyInState }) => {
      acc[selectedItemsKeyInState] = [];
      acc[lastSelectedItemKeyInState] = null;
      return acc;
    }, {
      isSelectedColumnsChanged: false
    });
  }

  componentDidUpdate(prevProps) {
    const { initialColumns, selectedColumnsSet } = this.props;
    const { initialColumns: prevInitialColumns, selectedColumnsSet: prevSelectedColumnsSet } = prevProps;
    if (!prevInitialColumns && initialColumns) {
      this.setState(this.stateForColumns(initialColumns));
    } else if ((!prevSelectedColumnsSet && selectedColumnsSet) || (selectedColumnsSet && selectedColumnsSet._id !== prevSelectedColumnsSet._id)) {
      this.onColumnsSetSelected(selectedColumnsSet);
    }
  }

  onChangeSelect(section) {
    return (e) => {
      if (e.target.value === "default") return;

      const itemIdKey = section.itemIdKey;
      const selectedItems = this.selectedItems();

      if (!section || !section.itemsKeyInProps) {
        return;
      }
      let selectedItem = (this.props[section.itemsKeyInProps] || []).find(item => item[itemIdKey] === e.target.value);

      selectedItem = { ...selectedItem, rank: (selectedItems.length + 1) * 1000 };
      this.setState({
        isSelectedColumnsChanged: true,
        [section.selectedItemsKeyInState]: [...this.state[section.selectedItemsKeyInState], selectedItem],
        [section.lastSelectedItemKeyInState]: selectedItem[itemIdKey]
      });
    };
  }

  clearAllSection(selectedItemsKeyInState) {
    return () => {
      this.setState({
        isSelectedColumnsChanged: true,
        [selectedItemsKeyInState]: []
      });
    };
  }

  selectAllSection(selectedItemsKeyInState, itemsKeyInPropsSection) {
    return () => {
      const rankMax = this.selectedItems().length;
      let selectedItemsBySection = this.props[itemsKeyInPropsSection];
      selectedItemsBySection = selectedItemsBySection.map((item, index) => {
        return Object.assign({}, item, { rank: (rankMax + index + 1) * 1000 });
      });
      this.setState({
        isSelectedColumnsChanged: true,
        [selectedItemsKeyInState]: selectedItemsBySection
      });
    };
  }

  onColumnsSetSelected(columnsSet) {
    if (!columnsSet) {
      this.setState({ selectedColumnsSetId: null });

      const { initialColumns } = this.props;
      if (initialColumns) {
        this.setState(this.stateForColumns(initialColumns));
      } else {
        this.setState(this.emptyState());
      }
      return;
    }
    this.setState({
      ...this.stateForColumns(columnsSet.columns),
      selectedColumnsSetId: columnsSet._id,
      isSelectedColumnsChanged: false
    });
  }

  // columns may come from a column set or from the user_settings
  stateForColumns(columns) {
    const { sections } = this.props;
    return columns.reduce((acc, { type, identifier }, idx) => {
      const section = sections.find(sec => sec.name === type);
      if (!section) return acc; // columns have a type that is not handled here

      const { itemsKeyInProps, itemIdKey, selectedItemsKeyInState, lastSelectedItemKeyInState } = section;
      const item = this.props[itemsKeyInProps].find(item => item[itemIdKey] === identifier);
      if (!item) return acc; // the item exists in the columns but not in the props. May not exists anymore

      const itemWithRank = { ...item, rank: (idx + 1) * 1000 };
      acc[selectedItemsKeyInState].push(itemWithRank);
      acc[lastSelectedItemKeyInState] = null;
      return acc;
    }, this.emptyState());
  }

  createColumnsSet(name) {
    const { saveColumnsSet } = this.props;
    const columns = this.columnsSetColumns();
    saveColumnsSet(name, columns);
    this.setState({ isSelectedColumnsChanged: false });
  }

  updateColumnsSet() {
    const { selectedColumnsSetId } = this.state;
    const { saveColumnsSet } = this.props;
    const columns = this.columnsSetColumns();
    saveColumnsSet(name, columns, selectedColumnsSetId);
  }

  deleteColumnsSet() {
    const { deleteColumnsSet } = this.props;
    const { selectedColumnsSetId } = this.state;
    if (confirm(I18n.t("confirm"))) {
      deleteColumnsSet(selectedColumnsSetId);
      this.setState({ selectedColumnsSetId: null });

      const { initialColumns } = this.props;
      if (initialColumns) {
        this.setState(this.stateForColumns(initialColumns));
      } else {
        this.setState(this.emptyState());
      }
    }
  }

  removeColumnSelected(section, itemIdKeyDeleted) {
    return () => {
      const newSelectedItemArraySection = this.state[section.selectedItemsKeyInState].filter(item => item[section.itemIdKey] !== itemIdKeyDeleted);
      this.setState({
        isSelectedColumnsChanged: true,
        [section.selectedItemsKeyInState]: newSelectedItemArraySection
      });
    };
  }

  onSubmit() {
    const { onSubmit } = this.props;
    const columns = this.columnsSetColumns();
    onSubmit(columns);
  }

  renderSubmitButton() {
    const { savingColumnsSet, onSubmit } = this.props;

    if (!onSubmit) return; // only when managing guest views

    return (
      <div className="col-auto">
        <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={savingColumnsSet}>
          { savingColumnsSet ? <Loader size="small" color="black" /> : I18n.t("react.export_columns_filter.submit") }
        </button>
      </div>
    );
  }

  renderOtherButton() {
    const { otherButtonTitle, otherButtonFn } = this.props;
    if (!otherButtonFn) {
      return;
    }
    return (
      <button type="button" className="btn btn-secondary" onClick={otherButtonFn}>
        { otherButtonTitle }
      </button>
    );
  }

  renderColumnsSetCreateButton() {
    const { savingColumnsSet } = this.props;
    return (
      <SubmitInputButton
        key="columnsSetCreateButton"
        className="btn btn-secondary"
        submitInputButtonTitle={I18n.t("react.export_columns_filter.save")}
        submitInputButtonIcon="fa-regular fa-check"
        inputPlaceholder={I18n.t("react.export_columns_filter.new_view_name")}
        onSubmit={this.createColumnsSet}
        loading={savingColumnsSet}
        toggleInputAfterSubmit= {true}>
        <i className="fa-regular fa-plus"></i> {I18n.t("react.export_columns_filter.save_as_new_view")}
      </SubmitInputButton>
    );
  }

  informationTooltip(message) {
    return (
      <Tooltip id="tooltip">{message}</Tooltip>
    );
  }

  renderColumnsSetUpdateButton() {
    const { savingColumnsSet } = this.props;
    const overlay = this.informationTooltip(I18n.t("react.export_columns_filter.update_view"));
    return (
      <OverlayTrigger key="columnsSetUpdateButton" placement="top" overlay={overlay}>
        <button type="button" className="btn btn-secondary" onClick={this.updateColumnsSet} disabled={savingColumnsSet}>
          { savingColumnsSet ? <Loader size="small" color="black" /> : <i className="fa-regular fa-floppy-disk"></i> }
        </button>
      </OverlayTrigger>
    );
  }

  renderColumnsSetDeleteButton() {
    const { savingColumnsSet } = this.props;
    const overlay = this.informationTooltip(I18n.t("react.export_columns_filter.delete_view"));
    return (
      <OverlayTrigger key="columnsSetDeleteButton" placement="top" overlay={overlay}>
        <button type="button" className="btn btn-danger" onClick={this.deleteColumnsSet} disabled={savingColumnsSet}>
          { savingColumnsSet ? <Loader size="small" color="black" /> : <i className="fa-regular fa-trash-can"></i> }
        </button>
      </OverlayTrigger>
    );
  }

  handleDrop(previousItemId, itemId, nextItemId, estimatedIndex) {
    const { sections } = this.props;
    let item = -1;
    let indexSection = null;
    sections.some((section, index) => {
      item = this.props[section.itemsKeyInProps].find(selectedItem => selectedItem[section.itemIdKey] === itemId);
      indexSection = index;
      if (item) {
        return true;
      }
    });
    if (indexSection == -1 || !item) {
      return;
    }

    const selectedItemsKeyInState = sections[indexSection].selectedItemsKeyInState;
    const itemIdKey = sections[indexSection].itemIdKey;

    const nextItem = this.selectedItemsSorted().find(selectedItem => {
      const item = sections.find(section => {
        return selectedItem[section.itemIdKey];
      });
      return item;
    });

    const newItems = this.state[selectedItemsKeyInState].map((item) => {
      if (item[itemIdKey] === itemId) {
        if (!previousItemId) { // first item
          const newRank = nextItem.rank - 1000;
          return Object.assign({}, item, { rank: newRank });
        } else {
          return Object.assign({}, item, { rank: estimatedIndex });
        }
      }
      return item;
    });
    this.setState({
      isSelectedColumnsChanged: true,
      [selectedItemsKeyInState]: newItems
    });
  }

  sortByRank(collection) {
    return collection.sort((a, b) => {
      return a.rank - b.rank;
    });
  }

  selectedItemsSorted() {
    return this.sortByRank(this.selectedItems());
  }

  selectedItems(injectColumnsSetInfo = false) {
    const { sections } = this.props;
    return flatten(sections.map(section => {
      if (!injectColumnsSetInfo) {
        return this.state[section.selectedItemsKeyInState];
      }
      return this.state[section.selectedItemsKeyInState].map(item => {
        return { ...item, type: section.name, identifier: item[section.itemIdKey] };
      });
    }));
  }

  columnsSetColumns() {
    const items = this.selectedItems(true);
    return this.sortByRank(items).map(item => {
      return { type: item.type, identifier: item.identifier };
    });
  }

  findSectionByItem(item) {
    const { sections } = this.props;
    return sections.find(section => {
      return this.props[section.itemsKeyInProps].find(field => field[section.itemIdKey] === item[section.itemIdKey]);
    });
  }

  renderSelectedColumns() {
    const style = {
      padding: "0.8rem",
      marginBottom: ".5rem",
      backgroundColor: "#f5f5f5",
      color: "#666666",
      border: "1px solid #e3e3e3",
      borderRadius: "4px 4px",
      fontSize: "0.9em"
    };

    const { columnsEmptyAllowed } = this.props;
    const selectedItems = this.selectedItemsSorted();
    const selectedItemsHtml = selectedItems.map(item => {
      const section = this.findSectionByItem(item);
      return (
        <div key={`${item[section.itemIdKey]}-${item.identifier}`} style={style}>
          {this.itemLabel(item, section)}
          { columnsEmptyAllowed || selectedItems.length > 1 ? <span onClick={this.removeColumnSelected(section, item[section.itemIdKey])} className="float-end" style={{ cursor: "pointer", fontSize: "14px", lineHeight: "initial" }}> × </span> : null}
        </div>
      );
    });

    return (
      <div className="card">
        <div className="card-header">
          <i className="fa-regular fa-up-down-left-right"></i> {I18n.t("react.export_columns_filter.hint_dnd")}
        </div>
        <div className="card-body">
          <Sortable itemIdKey="sortableItemId"
            itemIndexKey="rank"
            dragType={DragTypes.GUEST_TABLE_COLUMNS}
            items={selectedItems}
            handleDrop={this.handleDrop}
            fullyDraggable={true}>
            {selectedItemsHtml}
          </Sortable>
        </div>
      </div>
    );
  }

  renderSelectClearAllLink(section) {
    if (this.state[section.selectedItemsKeyInState].length === this.props[section.itemsKeyInProps].length) {
      return (
        <a className="btn-sm" onClick={this.clearAllSection(section.selectedItemsKeyInState)}>
          ({I18n.t("react.export_columns_filter.clear_all")})
        </a>
      );
    }
    return (
      <a className="btn-sm" onClick={this.selectAllSection(section.selectedItemsKeyInState, section.itemsKeyInProps)}>
        ({I18n.t("react.export_columns_filter.select_all")})
      </a>
    );
  }

  itemLabel(item, section) {
    let defaultValue = item[section.itemNameKey];

    if ([COLUMN_STANDARD_SCORE_THEMATIC_TYPE, COLUMN_QUARTILE_THEMATIC_TYPE].includes(section.name)) defaultValue += ` (${ I18n.t(`react.reports.guests_table_columns_picker.${ section.name.split("_")[0] }`) })`;
    return I18n.t(`react.export_columns_filter.${item.key}`, { defaultValue });
  }

  renderSection(section) {
    const items = this.props[section.itemsKeyInProps];
    const selectedItemIds = this.state[section.selectedItemsKeyInState];

    const sortedItems = section.itemsAlreadySorted ? items : items.sort((a, b) => {
      return a[section.itemNameKey].toLowerCase() < b[section.itemNameKey].toLowerCase() ? -1 : 1;
    });

    const itemsHtml = sortedItems.map(item => {
      const isDisabled = this.state[section.selectedItemsKeyInState].find(selectedItem => selectedItem[section.itemIdKey] === item[section.itemIdKey]) ;
      return <option key={item[section.itemIdKey]} value={item[section.itemIdKey]} disabled={!!isDisabled}>{this.itemLabel(item, section)}</option>;
    });
    const options = [...defaultActionSelect, ...itemsHtml];
    const lastSelectedItemKey = this.state[section.lastSelectedItemKeyInState];

    return (
      <div className="card">
        <div className="card-header">
          { `${section.title} ${selectedItemIds.length}/${items.length}`} {this.renderSelectClearAllLink(section)}
        </div>
        <div className="card-body">
          <select className="form-select" value={lastSelectedItemKey || ""} onChange={this.onChangeSelect(section)}>
            {options}
          </select>
        </div>
      </div>
    );
  }

  shouldDisplaySection(section) {
    const { thematics, event } = this.props;

    if (![COLUMN_SECTION_STANDARD_SCORE_THEMATIC_TYPE.name, COLUMN_SECTION_QUARTILES_THEMATIC_TYPE.name].includes(section.name)) return true;
    return event && event.scoring_and_engagement_enabled && thematics && thematics.length > 0;
  }

  renderSections() {
    const { sections } = this.props;

    return sections.map(section => {
      if (this.shouldDisplaySection(section)) {
        return (
          <Fragment key={section.name}>
            {this.renderSection(section)}
          </Fragment>
        );
      }
    });
  }

  renderColumnsSetActionButtons() {
    const { allowColumnsSetCreate } = this.props;

    if (!allowColumnsSetCreate) return;

    const { selectedColumnsSetId, isSelectedColumnsChanged } = this.state;
    let buttons = [];

    if (selectedColumnsSetId) {
      buttons.push(this.renderColumnsSetDeleteButton());
      if (isSelectedColumnsChanged) {
        buttons.push(this.renderColumnsSetUpdateButton());
      }
    }
    if (isSelectedColumnsChanged) {
      buttons.push(this.renderColumnsSetCreateButton());
    }
    return <div className="d-flex flex-row-reverse no-margin" style={{ gap: "0 5px" }}>{buttons}</div>;
  }

  render() {
    const { eventId, columnsSetPicker, height } = this.props;
    const { selectedColumnsSetId } = this.state;
    const style = { height: height || 350, overflowY: "scroll" };

    return (
      <>
        <div className="row">
          <div className={`${columnsSetPicker ? "col-md-6" : "col-md-12"}`}>
            <div className="form-text">{ this.props.helpText }</div>
          </div>
          { columnsSetPicker &&
            <div className="col-md-6">
              <ColumnsSetPicker
                onSelectColumnsSet={this.onColumnsSetSelected}
                eventId={eventId}
                style={{ margin: "5px 0" }}
                title={<span><i className="fa-regular fa-table"></i> {I18n.t("react.export_columns_filter.apply_an_existing_view")}</span>}
                selectedColumnsSetId={selectedColumnsSetId}
              />
            </div>
          }
        </div>
        <div className="row">
          <div className="col-md-6" style={style}>
            { this.renderSections() }
          </div>
          <div className="col-md-6" style={style}>
            { this.renderSelectedColumns() }
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-4">
            { this.renderOtherButton() }
          </div>
          <div className="col-md-8 d-flex justify-content-end" style={{ gap: "0 5px" }}>
            { this.renderColumnsSetActionButtons() }
            { this.renderSubmitButton() }
          </div>
        </div>
      </>
    );
  }
}

export default ColumnsFilter;

ColumnsFilter.requiresProps = {
  allowColumnsSetCreate: PropTypes.string,
  deleteColumnsSet: PropTypes.func,
  event: PropTypes.object,
  eventId: PropTypes.string.isRequired,
  initialColumns: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  quartile_thematics: PropTypes.arrayOf(PropTypes.object),
  saveColumnsSet: PropTypes.func,
  savingColumnsSet: PropTypes.bool,
  std_thematics: PropTypes.arrayOf(PropTypes.object),
  thematics: PropTypes.arrayOf(PropTypes.object),
  selectedColumnsSet: PropTypes.object,
  columnsSetPicker: PropTypes.bool,
  height: PropTypes.string
};

ColumnsFilter.defaultProps = {
  allowColumnsSetCreate: true,
  deleteColumnsSet: null,
  helpText: I18n.t("react.export_columns_filter.help"),
  otherButtonFn: null,
  otherButtonTitle: null,
  saveColumnsSet: null,
  savingColumnsSet: false,
  sections: SECTIONS,
  columnsSetPicker: true,
  height: null,
  columnsEmptyAllowed: false,
};
