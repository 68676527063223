import { ActionTypes } from "../constants/Constants";

function guestCampaigns(state = { data: [], errors: null, loading: true }, action) {
  switch (action.type) {
  case ActionTypes.GUEST_CAMPAIGNS_SUCCESS:
    state = { data: action.data.slice(), loading: false }; // data is injected in the action by the api middleware
    state["nextURL"] = action.nextURL || null;
    state["previousURL"] = action.previousURL || null;
    return state;
  case ActionTypes.GUEST_CAMPAIGN_DELETE_SUCCESS:
    return Object.assign({}, state, { data: state.data.filter(campaign => { return campaign._id != action.guestCampaignId; }) });
  case ActionTypes.GUEST_CAMPAIGN_DUPLICATE_SUCCESS:
    return Object.assign({}, state, { data: [ ...state.data, action.data] });
  case ActionTypes.GUEST_CAMPAIGN_DUPLICATE_FAILURE:
    return Object.assign({}, state, { errors: action.data });
  default:
    return state;
  }
}

export default guestCampaigns;
