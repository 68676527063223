import { Component } from "react";
import BottomOptions from "./form_item_question/BottomOptions.react";
import conditionalDisplay from "../utils/conditionalDisplayUtils.js";

class FormTitle extends Component {

  constructor(props) {
    super(props);
    ["_handleContentChanges", "_handleContentOnBlur", "updateDisplay"].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = {
      currentContent: props.formItem.options.content,
      display: {
        conditional_display: this.props.formItem.display_conditions.length > 0
      }
    };
  }

  _handleContentChanges(e) {
    this.setState({ currentContent: e.target.value });
  }

  _handleContentOnBlur(e) {
    const newContent = e.target.value;
    if (newContent == "") {
      return;
    }
    const { updateHandler } = this.props;
    updateHandler({
      options: {
        content: newContent
      }
    });
  }

  updateDisplay(newDisplay) {
    this.setState({ display: newDisplay });
  }

  render() {
    const { mode, updateHandler, destroyHandler, formItem, renderConditionalDisplay } = this.props;
    const { display } = this.state;

    if (mode == "edit") {
      return (
        <div>
          <div className="mb-3 row">
            <div className="col-sm-12">
              <input type="text" className="form-control" rel="field-label" value={this.state.currentContent} onBlur={this._handleContentOnBlur} onChange={this._handleContentChanges}/>
            </div>
          </div>
          { display.conditional_display && renderConditionalDisplay() }
          <BottomOptions
            formItem={formItem}
            display={display}
            destroyHandler={destroyHandler}
            updateHandler={updateHandler}
            updateParentDisplay={this.updateDisplay} />
        </div>
      );
    } else if (mode == "read") {
      return (
        <div>
          <p style={{ fontSize: "20px" }}>{this.state.currentContent}</p>
        </div>
      );
    }
  }

}
export default conditionalDisplay(FormTitle);
