import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownButton, DropdownItem } from "react-bootstrap";
import querystring from "querystring";

import SalesTable from "../../components/event_reports/SalesTable.react";
import Loader from "../../components/shared/Loader.react";
import SegmentPicker from "../shared/SegmentPicker.react";
import HelpSection from "../../components/shared/HelpSection.react";
import DateRangePicker from "../../components/DateRangePicker.react";

import ExportToExcelLink from "../../components/ExportToExcelLink.react";

import { fetchGuestsPaymentStatistics, clearGuestsPaymentStatistics } from "../../actions/GuestsStatisticsActionCreators";
import { importGuestCategories } from "../../actions/ImportGuestCategoryActionCreators";
import { fetchAccesspoints } from "../../actions/AccesspointActionCreators";

import { salesStatisticsDerivedData } from "../../selectors/salesStatisticsSelector";

import { pathToReports } from "../../utils/pathUtils";
import { redirectIfUnauthorized, isAuthorized } from "../../utils/aclUtils";
import { isEnabled } from "../../utils/featureSetUtils";
import { PAYMENT } from "../../constants/FeaturesSet";
import moment from "moment";

const COUNT_PAID_STAT_TYPE = "count_paid";
const COUNT_RESERVED_STAT_TYPE = "count_reserved";
const STAT_TYPES = [COUNT_RESERVED_STAT_TYPE, COUNT_PAID_STAT_TYPE];

const URL_SAVABLE_STATES = ["currentStat", "query"];
const DEFAULT_DATE_FORMAT = "YYYY-MM-DD HH:mm";

class Sales extends Component {

  constructor(props) {
    redirectIfUnauthorized("reports", "read");
    super(props);
    const query = querystring.parse(this.props.location.search.substring(1));
    this.state = {
      currentStat: query.currentStat || COUNT_PAID_STAT_TYPE,
      startDate: null,
      endDate: null,
      query: query.query
    };
    [
      "changeStatType",
      "changeQuery",
      "fetchStatistics",
      "exportLink",
      "fetchStatisticsFromDateRange",
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.currentStatPrettyName = {
      [COUNT_PAID_STAT_TYPE]: I18n.t("react.event_reports.event_reports.paid_items"),
      [COUNT_RESERVED_STAT_TYPE]: I18n.t("react.event_reports.event_reports.reserved_items")
    };
  }

  componentDidMount() {
    const { importGuestCategories, fetchAccesspoints, match } = this.props;
    const { query, currentStat, startDate, endDate } = this.state;
    this.fetchStatistics(currentStat, startDate, endDate, query);
    importGuestCategories();
    fetchAccesspoints(match.params.event_id);
  }

  componentDidUpdate() {
    const { location, history } = this.props;
    const query = querystring.parse(this.props.location.search.substring(1));
    let nextQuery = Object.assign({}, query);
    let nextLocation = Object.assign({}, location);
    URL_SAVABLE_STATES.forEach(key => {
      if (this.state[key]) {
        nextQuery = Object.assign({}, nextQuery, { [key]: this.state[key].toString() });
      }
    });
    nextLocation.search = `?${querystring.stringify(nextQuery)}`;
    if (location.search != nextLocation.search) {
      history.replace(nextLocation);
    }
  }

  changeStatType(newType) {
    return () => {
      this.setState({
        currentStat: newType
      });
      this.fetchStatistics(newType, this.state.startDate, this.state.endDate, this.state.query);
    };
  }

  changeQuery(segment) {
    const nextQuery = segment ? segment.search_query : null;
    const { query, currentStat, startDate, endDate } = this.state;
    if (nextQuery === query) {
      return;
    }
    this.props.clearGuestsPaymentStatistics();
    this.setState({
      query: nextQuery
    });
    this.fetchStatistics(currentStat, startDate, endDate, nextQuery, true);
  }

  fetchStatisticsFromDateRange() {
    const { query, currentStat, startDate, endDate } = this.state;
    this.fetchStatistics(currentStat, startDate, endDate, query, true);
  }

  fetchStatistics(type, startDate, endDate, q, force = false) {
    const { salesStatistics, fetchGuestsPaymentStatistics, match } = this.props;
    if (!salesStatistics[type] || force) {
      fetchGuestsPaymentStatistics(match.params.event_id, { stats_type: type, start_date: startDate, end_date: endDate, q: `include_takeout ${q || ""}` });
    }
  }

  renderStatSelector() {
    const { currentStat } = this.state;
    const title = this.currentStatPrettyName[currentStat];
    const selectable = STAT_TYPES.slice().filter(type => type !== currentStat);
    const menuItems = selectable.map(type => {
      return <DropdownItem key={type} onClick={this.changeStatType(type)}>{this.currentStatPrettyName[type]}</DropdownItem>;
    });
    return (
      <div className="col-auto">
        <DropdownButton variant="link p-0" title={title} id="dropdown">
          {menuItems}
        </DropdownButton>
      </div>
    );
  }

  renderDateRangePicker() {
    const { startDate, endDate } = this.state;

    return (
      <DateRangePicker
        startDate={startDate ? moment(startDate) : startDate}
        endDate={endDate ? moment(endDate) : endDate}
        onDateRangeChanged={(start, end) => {
          this.setState(
            {
              startDate: start && start.format(DEFAULT_DATE_FORMAT),
              endDate: end && end.format(DEFAULT_DATE_FORMAT)
            },
            this.fetchStatisticsFromDateRange
          );
        }}
        showClearButton={false} />
    );
  }

  exportHeaders() {
    const { currentStat } = this.state;
    const refundHeaders = currentStat === COUNT_PAID_STAT_TYPE ? ["refund_count", "refund_amount"] : [];
    return ["name",
      "count",
      "promo_code_ratio",
      "discount_amount",
      ...refundHeaders,
      "price",
      "taxes",
      "total_price_excl_taxes",
      "total_price_incl_taxes"];
  }

  exportLink(stats) {
    const columnKeys = this.exportHeaders();
    return <div className="col-auto">
      <ExportToExcelLink data={stats} columnKeys={columnKeys} type="sales" />
    </div>;
  }

  renderHeader(stats) {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="subtitle-page">
            <div className="subtitle-page-content mb-3">
              <div className="row align-items-center g-2">
                <div className="col-auto">
                  <h2 className="mb-0">
                    <Link to={pathToReports()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></Link>
                    {I18n.t("react.event_reports.event_reports.sales")}
                  </h2>
                </div>
                {this.renderStatSelector()}
                {this.exportLink(stats)}
              </div>
              <div className="row align-items-center g-2">
                <div className="col-auto">
                  <SegmentPicker onSelectSegment={this.changeQuery} defaultSegmentQuery={this.state.query} eventId={this.props.match.params.event_id} />
                </div>
              </div>
            </div>
            <div>
              {this.renderDateRangePicker()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderHelp() {
    const { currentStat } = this.state;
    let text = I18n.t("react.event_reports.event_reports.sales_count_paid_help");
    if (currentStat === COUNT_RESERVED_STAT_TYPE) {
      text = I18n.t("react.event_reports.event_reports.sales_count_reserved_help");
    }
    return <HelpSection help={text} />;
  }

  render() {
    if (!isAuthorized("reports", "read")) return;
    if (!isEnabled(PAYMENT)) return;

    const { salesStatistics } = this.props;
    const { currentStat } = this.state;
    const stats = salesStatistics[currentStat];
    const content = !stats ? <Loader /> : <SalesTable stats={stats.data} total={stats.total} currentStat={currentStat} />;
    return (
      <div>
        {this.renderHeader(stats)}
        {this.renderHelp()}
        {content}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    salesStatistics: salesStatisticsDerivedData(state, props),
    guestCategories: state.guestCategories.data,
    accesspoints: state.accesspoints.data
  };
}

const mapDispatchToProps = {
  fetchGuestsPaymentStatistics,
  importGuestCategories,
  fetchAccesspoints,
  clearGuestsPaymentStatistics
};

export default connect(mapStateToProps, mapDispatchToProps)(Sales);
