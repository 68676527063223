import { useState } from "react";
import { Modal } from "react-bootstrap";
import HelpSection from "../shared/HelpSection.react";
import { WorkflowVersion } from "../../types/Workflow";

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.workflows.builder.workflow_version_form.${key}`, opts);
}

interface Props {
  workflowVersion: WorkflowVersion;
  isVisible: boolean;
  onClose(): void;
  onPublish(name: string, description: string): void;
}

const WorkflowVersionForm: React.FC<Props> = ({
  workflowVersion,
  isVisible,
  onClose,
  onPublish
}) => {
  const [name, setName] = useState<string>(workflowVersion.name);
  const [description, setDescription] = useState<string>(workflowVersion.description);

  const onSubmit = (): void => {
    onPublish(name, description);
    onClose();
  };

  return <Modal show={isVisible} onHide={onClose} restoreFocus={false}>
    <Modal.Header>
      <Modal.Title>{i18n("title")}</Modal.Title>
      <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
    </Modal.Header>
    <Modal.Body>
      <HelpSection help={i18n("help")} />
      <div className="mb-3">
        <label htmlFor="name" className="form-label">{i18n("name")} <span className="text-danger">*</span></label>
        <input type="text"
          className="form-control"
          value={name || ""}
          onChange={(e): void => setName(e.target.value)}
          autoFocus={true}
          required={true} />
      </div>
      <div className="mb-3">
        <label htmlFor="description" className="form-label">{i18n("description")}</label>
        <textarea
          className="form-control"
          value={description || ""}
          onChange={(e): void => setDescription(e.target.value)}
          rows={4}></textarea>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-secondary" onClick={onClose}>{ I18n.t("cancel") }</button>
      <button className="btn btn-primary" onClick={onSubmit} disabled={name === ""}>{ i18n("publish") }</button>
    </Modal.Footer>
  </Modal>;
};

export default WorkflowVersionForm;
