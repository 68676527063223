import { Person } from "../types/Person";

export function identity(person: Person): string {
  if (person.last_name && person.last_name !== "") {
    return `${person.first_name || ""} ${person.last_name}`.trim();
  } else if (person.email && person.email !== "") {
    return person.email;
  } else if (person.company_name && person.company_name !== "") {
    return person.company_name;
  }

  return person.uid;
}
