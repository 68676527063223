import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchExhibitorByNumberOfLeads } from "../../actions/ExhibitorActionCreators";
import Loader from "../../components/shared/Loader.react";
import ReportHeader from "../../components/event_reports/ReportHeader.react";
import { isAuthorized } from "../../utils/aclUtils";
import Select from "react-select";
import { Dropdown } from "react-bootstrap";
import uniqBy from "lodash/uniqBy";
import HeaderStats from "../../components/dashboard/HeaderStats.react";
import { DASHBOARD_HEADER_NO_VALUE_STR } from "../../constants/Constants";
import { eventDates } from "../../utils/DateUtils";
import moment from "moment";

const DATE_FORMAT = "LL";
const API_DATE_FORMAT = "YYYY-MM-DD";
const ALL_DAYS_STATS_IF_NUMBER_OF_DAYS_LTE = 7;

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`react.event_reports.visit_trail.${key}`, options);
};

const VisitRoute: React.FC = () => {
  const event = useSelector((state: any) => state.event);
  const dataFetched = useSelector((state: any) => state.exhibitorsStatistics.byNumberOfLeads);
  const isFetching = useSelector((state: any) => state.exhibitorsStatistics.isFetching);
  const dispatch = useDispatch();
  const [exhibitorSelected, setExhibitorSelected] = useState(null);
  const [result, setResult] = useState([]);
  const [sortField, setSortField] = useState("nb_leads");
  const [kpis, setKpis] = useState({});
  const [overallStats, setOverallStats] = useState(true);
  const [statsDate, setStatsDate] = useState(null);

  if (!isAuthorized("reports", "read")) return null;

  useEffect(() => {
    if (!event._id) return;

    setKpis({});
    setResult([]);
    dispatch(fetchExhibitorByNumberOfLeads(event._id, sortField, statsDate || ""));
    fetchKpis();
  }, [event._id, sortField, statsDate]);

  useEffect(() => {
    if (!dataFetched) return;

    setResultFilteredByVisitRouteSource(dataFetched);
  }, [dataFetched]);

  useEffect(() => {
    if (!exhibitorSelected) {
      dataFetched && setResultFilteredByVisitRouteSource(dataFetched);
      return;
    }

    const resultFiltered = dataFetched.filter(item => {
      return exhibitorSelected ? item.company_name === exhibitorSelected : true;
    });
    setResultFilteredByVisitRouteSource(resultFiltered);
  }, [exhibitorSelected]);

  const fetchKpis = (): void => {
    fetch(`/api/v1/events/${event._id}/exhibitors/statistics.json?type=visit_route_kpis&stats_date=${statsDate || ""}`)
      .then(response => {
        return response.json();
      }).then(data => {
        setKpis(data);
      });
  };

  const setResultFilteredByVisitRouteSource = (result: any): void => {
    setResult(result.filter((item: any) => item.raw_lead_source === "visit_route"));
  };

  const exporterProps = (): any => {
    return {
      data: {
        data: result.map(
          line => {
            return {
              exhibitor: line.company_name,
              nb_leads: line.nb_leads,
            };
          }
        )
      },
      options: { excludeTotal: true },
      columns: ["exhibitor", "nb_leads"]
    };
  };

  const renderHeader = (): JSX.Element => {
    const dateDropDownProps = { title: dateDropdownTitle(), menuItemRows: dateDropdownRows() };
    return <ReportHeader
      title={I18n.t("react.event_reports.event_reports.visit_route")}
      exporterToExcelProps={result?.length > 0 ? exporterProps() : null}
      dateDropDownProps={dateDropDownProps}
      exportType="visit_trail"
    />;
  };

  const renderSortDropdown = (): JSX.Element => {
    const rows = ["nb_leads", "company_name"].map(sort => {
      return <Dropdown.Item key={sort} onClick={(): void => setSortField(sort)}>{i18n(`sort_by_${sort}`)}</Dropdown.Item>;
    });

    return <div className="float-end">
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-sort">
          {i18n(`sort_by_${sortField}`)}
        </Dropdown.Toggle>
        <Dropdown.Menu
          align="end"
          popperConfig={{
            strategy: "fixed",
            onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll"))
          }}
        >
          {rows}
        </Dropdown.Menu>
      </Dropdown>
    </div>;
  };

  const renderExhibitorsSelect = (): JSX.Element => {
    const exhibitors = uniqBy(
      result.map((line) => ({
        value: line.company_name,
        label: line.company_name,
      })),
      function (object) {
        return object.value;
      }
    );

    return <Select
      className="react-select"
      classNamePrefix="react-select"
      options={exhibitors}
      value={exhibitors?.filter((exhibitor): any => exhibitorSelected === exhibitor.value)}
      placeholder={i18n("select_an_exhibitor")}
      noOptionsMessage={(): any => i18n("no_exhibitor")}
      onChange={(option): any => setExhibitorSelected(option?.value)}
      isClearable={true}
      menuPosition="fixed"
      styles={{
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        menuPortal: ({ left, ...provided }, state) => ({
          ...provided
        })
      }}
    />;
  };

  const onDateChangeHandler = (date: string): (() => void) => {
    return () => {
      setStatsDate(date);
      setOverallStats(false);
    };
  };

  const displayOverallStats = (): void => {
    setOverallStats(true);
    setStatsDate(null);
  };

  const dateDropdownTitle = (): string => {
    return overallStats ? I18n.t("react.event_reports.event_reports.all_days_stats") : `${moment(statsDate).format(DATE_FORMAT)}` || I18n.t("react.event_reports.event_reports.pick_day");
  };

  const dateDropdownRows = (): JSX.Element[] => {
    if (!event._id) {
      return [];
    }

    const dates = eventDates(event);
    const rows = dates.map(date => {
      const m = moment(date);
      return <Dropdown.Item key={date} onClick={onDateChangeHandler(m.format(API_DATE_FORMAT))}>{ m.format(DATE_FORMAT) }</Dropdown.Item>;
    });
    if (dates.length < ALL_DAYS_STATS_IF_NUMBER_OF_DAYS_LTE) {
      rows.push(<Dropdown.Divider />);
      rows.push(<Dropdown.Item key="cumul" onClick={displayOverallStats}>{ I18n.t("react.event_reports.event_reports.all_days_stats") }</Dropdown.Item>);
    }
    return rows;
  };

  const renderTableHeader = (): JSX.Element => {
    return (
      <>
        <tr>
          <th style={{ fontWeight: "normal" }}>
            {renderExhibitorsSelect()}
          </th>
          <th>{renderSortDropdown()}</th>
        </tr>
        <tr>
          <th>{I18n.t("react.event_reports.report_table.exhibitor")}</th>
          <th>{I18n.t("react.event_reports.report_table.nb_leads")}</th>
        </tr>
      </>
    );
  };

  const renderTable = (): JSX.Element => {
    if (!dataFetched) return null;
    if (result?.length === 0) return renderEmptyMsg();

    return <div className="row">
      <div className="col-12">
        <div className="table-responsive table-container">
          <table className="table table-light table-hover table-sm">
            <thead>
              {renderTableHeader()}
            </thead>
            <tbody>
              {renderTableBody()}
            </tbody>
          </table>
        </div>
      </div>
    </div>;
  };

  const renderTableBody = (): JSX.Element[] => {
    return result.map((line, index) => {
      return (
        <tr key={index}>
          <td>
            {line.company_name}
          </td>
          <td>{line.nb_leads}</td>
        </tr>
      );
    });
  };

  const renderEmptyMsg = (): JSX.Element => {
    return <div className="card nothing-yet">
      <h4>{i18n("no_data")}</h4>
    </div>;
  };

  const renderKpis = (): JSX.Element => {
    const avg_scan_by_visitor = kpis["total_scan"] && kpis["nb_uniq_visitor_that_scanned"] ? (kpis["total_scan"] / kpis["nb_uniq_visitor_that_scanned"]).toFixed(2) : "-";
    return <HeaderStats
      dataFetched={true}
      headers={
        [
          { i18nKey: "total_scan", value: kpis["total_scan"] || DASHBOARD_HEADER_NO_VALUE_STR, nbCols: 3 },
          { i18nKey: "nb_uniq_visitor_that_scanned", value: kpis["nb_uniq_visitor_that_scanned"] || DASHBOARD_HEADER_NO_VALUE_STR, nbCols: 3, hintI18nKey: "nb_uniq_visitor_that_scanned_hint" },
          { i18nKey: "nb_exhibitors_scanned", value: kpis["nb_exhibitors_scanned"] || DASHBOARD_HEADER_NO_VALUE_STR, nbCols: 3, hintI18nKey: "nb_exhibitors_scanned_hint" },
          { i18nKey: "avg_scan_by_visitor", value: avg_scan_by_visitor || DASHBOARD_HEADER_NO_VALUE_STR, nbCols: 3, hintI18nKey: "avg_scan_by_visitor_hint" }

        ]
      }
      tabKey="visit_route"
    />;
  };

  return <>
    { renderHeader() }
    { renderKpis() }
    { isFetching ? <Loader/> : renderTable() }
  </>;
};

export default VisitRoute;
