import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MeetingAvailabilitiesSummary from "../meetings/MeetingAvailabilitiesSummary.react";
import HasMeetingAvailabilitiesForm from "../../../containers/engagement/HasMeetingAvailabilitiesForm.react";
import { Event } from "../../../types/Event";
import { Guest } from "../../../types/Guest";
import { storeGuestInGuests } from "../../../actions/GuestActionCreators";
import Loader from "../../shared/Loader.react";

interface Props {
  event: Event;
  guest: Guest;
}

const i18n = (key: string, opt = {}): string => {
  return I18n.t(`react.meeting_availabilities.${key}`, opt);
};

const GuestShowMeetingAvailabilities: React.FC<Props> = ({ event, guest }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(storeGuestInGuests(guest));
  }, []);

  const renderSlidingPaneTitleForGuest = (guest: Guest): JSX.Element => {
    return (
      <div className="d-flex">
        <span className="d-flex align-items-center mr-5">
          {i18n("default_availabilities_for_guest")}
        </span>
        <span>
          {guest.first_name} {guest.last_name}
        </span>
      </div>
    );
  };

  const storedGuest = useSelector((state: any) => { return state.guests.guests.filter(g => g["_id"] === guest._id)[0]; });

  const availabilitiesInheritedFrom = (): JSX.Element => {
    if (storedGuest.meeting_availabilities) {
      return <p>{ i18n("summary_inherited_from", { parent: i18n(storedGuest.availabilities_inherited_from) }) }</p>;
    }
  };

  if (!storedGuest) {
    return <Loader />;
  }

  if (storedGuest) {
    return <>
      {availabilitiesInheritedFrom()}
      <HasMeetingAvailabilitiesForm
        event={event}
        slidingPaneTitle={renderSlidingPaneTitleForGuest(guest)}
        hasMeetingAvailabilities={storedGuest}
        defaultMeetingAvailabilities={storedGuest.meeting_availabilities || []}
        type={"guest"}
        startDate={event.start_date}
        endDate={event.end_date}
        meetingTypes={"physical_or_virtual"}
      />
      <MeetingAvailabilitiesSummary
        meetingAvailabilities={storedGuest.meeting_availabilities}
        type={"guest"}
      />
    </>;
  }
};

export default GuestShowMeetingAvailabilities;
