import { Component } from "react";
import PropTypes from "prop-types";
import CreateGuestCategoryModal from "../shared/CreateGuestCategoryModal.react";
import GuestCategoryForm from "../shared/GuestCategoryForm.react";
import HelpSection from "../shared/HelpSection.react";

class CreateGuestOnCheckin extends Component {

  constructor(props) {
    super(props);
    [
      "regexChangeHandler",
      "submit",
      "showCreateCategoryModalHandler",
      "hideCreateCategoryModal",
      "onCategoryCreatedHandler",
      "guestCategorySelectHandler",
      "updateRule",
      "addRule",
      "deleteRuleHandler"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      rules: [],
      showCreateCategoryModal: false,
      createCategoryModalForRuleAtIndex: -1
    };
    this.eventLoaded = false;
  }

  componentDidUpdate() {
    const { event } = this.props;
    if (!this.eventLoaded && event.id && this.state.rules != event.create_guest_on_checkin_rules) {
      this.eventLoaded = true;
      this.setState({
        rules: event.create_guest_on_checkin_rules || []
      });
    }
  }

  submit(e) {
    e.preventDefault();
    const { rules } = this.state;
    const updateParams = rules.reduce((params, rule) => {
      if (rule.guest_category_id === "" || rule.regex === "") {
        return;
      }
      params.push(rule);
      return params;
    }, []);
    this.props.onSubmit(updateParams);
  }

  showCreateCategoryModalHandler(index) {
    return (e) => {
      e.preventDefault();
      this.setState({
        showCreateCategoryModal: true,
        createCategoryModalForRuleAtIndex: index
      });
    };
  }

  hideCreateCategoryModal() {
    this.setState({
      showCreateCategoryModal: false,
      createCategoryModalForRuleAtIndex: -1
    });
  }

  onCategoryCreatedHandler(index) {
    return (category) => {
      this.hideCreateCategoryModal();
      this.updateRule(index, { guest_category_id: category._id });
    };
  }

  updateRule(index, params) {
    const { rules } = this.state;
    const newRules = rules.slice();
    const rule = rules[index];
    newRules.splice(index, 1, Object.assign({}, rule, params));
    this.setState({
      rules: newRules
    });
  }

  regexChangeHandler(index) {
    return (e) => {
      this.updateRule(index, { regex: e.target.value });
    };
  }

  guestCategorySelectHandler(index) {
    return (e) => {
      this.updateRule(index, { guest_category_id: e.target.value });
    };
  }

  addRule(e) {
    e.preventDefault();
    const { rules } = this.state;
    const newRules = rules.slice();
    newRules.push({ guest_category_id: "", regex: "" });
    this.setState({
      rules: newRules
    });
  }

  deleteRuleHandler(index) {
    return (e) => {
      e.preventDefault();
      const { rules } = this.state;
      const newRules = rules.slice();
      newRules.splice(index, 1);
      this.setState({
        rules: newRules
      });
    };
  }

  renderGuestCategorySelect(index) {
    const { guestCategories } = this.props;
    const { rules } = this.state;
    const options = guestCategories.map(gc => {
      return <option value={gc.id} key={gc.id}>{gc.name}</option>;
    });

    const value = rules[index].guest_category_id;
    const def = value === "" ? <option value="" key="default">{ I18n.t("react.create_guest_on_checkin.pick_a_value") }</option> : null;
    return (
      <div className="col-md-4" style={{ paddingRight: 0 }}>
        <select className="form-select" value={value} onChange={this.guestCategorySelectHandler(index)} style={{ width: "100%" }}>
          {def}
          {options}
        </select>
      </div>
    );
  }

  renderRule(rule, index) {
    const { regex } = rule;
    return (
      <li key={index} className="row" style={{ gap: "5px 0" }}>
        <div className="value d-flex align-items-center justify-content-end col-md-4" style={{ paddingRight: 0 }}>
          <input type="text" className="form-control" value={regex} placeholder={I18n.t("react.create_guest_on_checkin.no_regex")} onChange={this.regexChangeHandler(index)}/>
          <i className="fa-regular fa-arrow-right ml-15"></i>
        </div>

        { this.renderGuestCategorySelect(index) }

        <div className="d-flex align-items-center col-md-4">
          <strong>{I18n.t("or")}</strong>
          <a className="ml-10" href="#" onClick={this.showCreateCategoryModalHandler(index)}>{I18n.t("guest_import_operations.modal_guest_category.create_category")}</a>
          <a className="btn btn-danger ml-10" href="#" onClick={this.deleteRuleHandler(index)}><i className="fa-regular fa-trash-can"></i></a>
        </div>
      </li>
    );
  }

  renderRules() {
    const { rules } = this.state;
    const lines = rules.map((rule, index) => {
      return this.renderRule(rule, index);
    });
    return (
      <ul className="ps-0 d-flex flex-column mb-0" style={{ gap: "10px 0", listStyleType: "none" }}>
        { lines }
        <li>
          { this.renderAddRuleButton() }
        </li>
      </ul>
    );
  }

  renderAddRuleButton() {
    const button = <a href="#" className="btn btn-secondary" onClick={this.addRule}><i className="fa-regular fa-plus"></i> { I18n.t("react.create_guest_on_checkin.add_a_rule") }</a>;
    const { rules } = this.state;
    if (rules.length === 0) {
      return button;
    }
    const lastRule = rules[rules.length - 1];
    if (!lastRule.guest_category_id) {
      return null;
    }
    return button;
  }

  renderCreateCategoryModal() {
    const { event } = this.props;
    if (!event.id) {
      return null;
    }
    const { showCreateCategoryModal, createCategoryModalForRuleAtIndex } = this.state;
    return <CreateGuestCategoryModal
      isVisible={showCreateCategoryModal}
      event={event}
      onCloseFunction={this.hideCreateCategoryModal}
      updateParent={this.onCategoryCreatedHandler(createCategoryModalForRuleAtIndex)}
      render={ (state) => <GuestCategoryForm {...state} /> }
    />;
  }

  render() {
    return <>
      <div className="card mb-10">
        <div className="card-header">
          <h4 className="card-title">{ I18n.t("react.create_guest_on_checkin.title") }</h4>
        </div>
        <div className="card-body">
          <HelpSection
            help={I18n.t("react.create_guest_on_checkin.help")}
            badgeText={I18n.t("react.create_guest_on_checkin.advanced_usage")}
            type="warning"
            noIcon={true}
          />
          { this.renderRules() }
          { this.renderCreateCategoryModal() }
        </div>
      </div>
      <a href="#" className="btn btn-primary" onClick={this.submit}>{ I18n.t("react.create_guest_on_checkin.validate") }</a>
    </>;
  }
}

export default CreateGuestOnCheckin;

CreateGuestOnCheckin.propTypes = {
  event: PropTypes.object.isRequired,
  guestCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func.isRequired
};
