import { ChangeEvent } from "react";
import withProgramFilter from "../withProgramFilter.react";
import InputLabelWithVisibilityButton from "../InputLabelWithVisibilityButton.react";
import Checkbox from "../../../components/shared/Checkbox.react";
import { FilterProps } from "../../../types/Program";

function i18n(key: string, opts = {}): string {
  return I18n.t(`react.programs.date_range_filter.${key}`, opts);
}

const DateRangeFilter: React.FC<FilterProps> = ({ programFilter, onChangeFilter, onChangeLabelOptions }) => {
  const onCheckboxChanged = (field: string): (e: ChangeEvent<HTMLInputElement>) => void => {
    return (e: ChangeEvent<HTMLInputElement>): void => {
      programFilter[field] = e.target.checked;
      onChangeFilter(programFilter);
    };
  };

  const onChangeTimeSearchLabelOptions = (label: string, isDisplayed: boolean): void => {
    programFilter.time_search_label = label;
    programFilter.time_search_label_displayed = isDisplayed;
    onChangeFilter(programFilter);
  };

  return <>
    <div>
      <Checkbox
        checked={programFilter.date_search_enabled}
        text={i18n("date_search_enabled")}
        onChange={onCheckboxChanged("date_search_enabled")} />
    </div>
    {programFilter.date_search_enabled && <div className="mt-10">
      <InputLabelWithVisibilityButton
        label={I18n.t("react.programs.label")}
        value={programFilter.label}
        placeholder={i18n("title")}
        name="date_range_label"
        isDisplayed={programFilter.label_displayed}
        onChangeHandler={onChangeLabelOptions}
      />
    </div>}
    <div className="mt-3">
      <Checkbox
        checked={programFilter.time_search_enabled}
        text={i18n("time_search_enabled")}
        onChange={onCheckboxChanged("time_search_enabled")} />
    </div>
    {programFilter.time_search_enabled && <div className="mt-10">
      <InputLabelWithVisibilityButton
        label={i18n("time_search_label")}
        value={programFilter.time_search_label}
        placeholder={i18n("time_search_label_placeholder")}
        name="time_search_label"
        isDisplayed={programFilter.time_search_label_displayed}
        onChangeHandler={onChangeTimeSearchLabelOptions}
      />
    </div>}
  </>;
};

export default withProgramFilter(DateRangeFilter, "ui");
