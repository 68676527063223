import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import WorkflowStepSettings from "../../components/automation/WorkflowStepSettings.react";
import WorkflowStepConditions from "../../components/automation/WorkflowStepConditions.react";
import { addTriggerStep, changeStepStrategy, createWorkflowStep, updateWorkflowStep,
  receiveStepChanges } from "../../actions/WorkflowItemsActionCreators";
import { WorkflowVersion, WorkflowStep, WorkflowStepType, WorkflowStepStrategy } from "../../types/Workflow";
import { urlEventId } from "../../utils/pathUtils";
import { WORKFLOW_STEP_TRIGGER, WORKFLOW_TRIGGER_SELECTION, WORKFLOW_TRIGGER_EDITION,
  WORKFLOW_ACTION_SELECTION, WORKFLOW_ACTION_EDITION, WORKFLOW_FILTER_EDITION, UI_COLORS,
  WORKFLOW_TRIGGER_GROUPS, WORKFLOW_ACTION_STRATEGIES } from "../../constants/Constants";

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.workflows.builder.slider.${key}`, opts);
}

interface Props {
  workflowVersion: WorkflowVersion;
  workflowStep: WorkflowStep;
  sliderMode: string;
  isVersionEditable: boolean;
  onChangeStrategyRequest(type: WorkflowStepType): (() => void);
  onSelectStrategy(type: WorkflowStepType, strategy: WorkflowStepStrategy): void;
  onSwitchVersionRequest(): void;
  onClose(): void;
}

const Slider: React.FC<Props> = ({
  workflowVersion,
  workflowStep,
  sliderMode,
  isVersionEditable,
  onChangeStrategyRequest,
  onSelectStrategy,
  onSwitchVersionRequest,
  onClose
}) => {
  const isPendingRequest = useSelector((state: any) => state.workflowVersionBuilder.isPendingRequest);
  const strategiesGlobalConfigParams = useSelector((state: any) => state.workflowVersionBuilder.strategiesGlobalConfigParams);
  const isPendingStrategyRequest = useSelector((state: any) => state.workflowVersionBuilder.isPendingStrategyRequest);
  const context = useSelector((state: any) => state.workflowVersionBuilder.context);

  const dispatch = useDispatch();

  const saveStep = (newSettings: any, newConditions: any[]): void => {
    const newAttributes = stepAttributes(newSettings, newConditions);

    if (workflowStep._id) {
      dispatch(updateWorkflowStep(urlEventId(), workflowVersion._id, workflowStep._id, newAttributes, { noticeType: "success", notice: I18n.t("react.workflows.builder.notice.step_updated") }));
    } else if (workflowStep.temporaryId) {
      dispatch(createWorkflowStep(urlEventId(), workflowVersion._id, newAttributes, workflowStep.temporaryId, { noticeType: "success", notice: I18n.t("react.workflows.builder.notice.step_created") }));
    }
  };

  const stepAttributes = (newSettings: any, newConditions: any[]): any => {
    return {
      ..._.pick(workflowStep, "type", "strategy", "next_step_id", "previous_step_id"),
      settings: newSettings || workflowStep.settings,
      conditions: newConditions || workflowStep.conditions
    };
  };

  const selectStepStrategyHandler = (strategy: WorkflowStepStrategy): (() => void) => {
    return (): void => {
      if (workflowStep) {
        if (workflowStep.strategy !== strategy) {
          dispatch(changeStepStrategy(strategy));
        }
      } else {
        dispatch(addTriggerStep(strategy));
      }
      onSelectStrategy(workflowStep ? workflowStep.type : WORKFLOW_STEP_TRIGGER, strategy);
    };
  };

  const receiveStepChangesNotification = (workflowStep: WorkflowStep): void => {
    dispatch(receiveStepChanges(workflowStep._id || workflowStep.temporaryId));
  };

  const renderTitle = (): string => {
    switch (sliderMode) {
    case WORKFLOW_TRIGGER_SELECTION:
    case WORKFLOW_TRIGGER_EDITION:
      return i18n("trigger_title");
    case WORKFLOW_ACTION_SELECTION:
      return i18n("action_selection_title");
    case WORKFLOW_ACTION_EDITION:
      return i18n("action_edition_title");
    case WORKFLOW_FILTER_EDITION:
      return i18n("filter_edition_title");
    }
  };

  const renderBody = (): JSX.Element => {
    switch (sliderMode) {
    case WORKFLOW_TRIGGER_SELECTION:
      return renderTriggerSelection();
    case WORKFLOW_TRIGGER_EDITION:
      return renderTriggerEdition();
    case WORKFLOW_ACTION_SELECTION:
      return renderActionSelection();
    case WORKFLOW_ACTION_EDITION:
      return renderActionEdition();
    case WORKFLOW_FILTER_EDITION:
      return renderFilterEdition();
    }
  };

  const renderTriggerSelection = (): JSX.Element => {
    return <ul className="list-unstyled mt-10 mb-0 py-3 pt-md-1">
      {Object.entries<any>(WORKFLOW_TRIGGER_GROUPS).map(([groupName, config]) => {
        return <li key={groupName} className="mb-1">
          <div className="px-3 py-2 fw-bold">
            <i className={`${config.icon} fa-fw mr-5`}></i> {I18n.t(`automation.trigger_groups.${groupName}`)}
          </div>
          <ul className="list-unstyled fw-normal pb-1">
            {config.triggers.map(strategy => {
              return <Link to="#" onClick={selectStepStrategyHandler(strategy)} className="list-group-item list-group-item-action border-0" style={{ paddingLeft: "43px" }} key={strategy}>
                {I18n.t(`automation.triggers.abbrev.${strategy}`)}
              </Link>;
            })}
          </ul>
        </li>;
      })}
    </ul>;
  };

  const renderTriggerEdition = (): JSX.Element => {
    return <div className="p-10">
      <div className="input-group mb-3">
        <input type="text" className="form-control" value={I18n.t(`automation.triggers.full.${workflowStep.strategy}`)} readOnly />
        { isVersionEditable &&
          <button className="btn btn-link border border-secondary-subtle border-start-0 shadow-none" type="button" onClick={onChangeStrategyRequest(workflowStep.type)} style={{ background: UI_COLORS.grey50 }}>
            <i className="fa fa-pencil"></i>
          </button>
        }
      </div>
      {renderSettings()}
    </div>;
  };

  const renderActionSelection = (): JSX.Element => {
    return <ul className="list-group mt-10">
      {WORKFLOW_ACTION_STRATEGIES.map((strategy: WorkflowStepStrategy) => {
        return <Link to="#" onClick={selectStepStrategyHandler(strategy)} className="list-group-item list-group-item-action" key={strategy}>
          {I18n.t(`automation.actions.${strategy}`)}
        </Link>;
      })}
    </ul>;
  };

  const renderActionEdition = (): JSX.Element => {
    return <div className="p-10">
      <div className="input-group mb-3">
        <input type="text" className="form-control" value={I18n.t(`automation.actions.${workflowStep.strategy}`)} readOnly />
        { isVersionEditable &&
          <button className="btn btn-link border border-secondary-subtle border-start-0 shadow-none" type="button" onClick={onChangeStrategyRequest(workflowStep.type)} style={{ background: UI_COLORS.grey50 }}>
            <i className="fa fa-pencil"></i>
          </button>
        }
      </div>
      {renderSettings()}
    </div>;
  };

  const renderSettings = (): JSX.Element => {
    return <WorkflowStepSettings
      workflowStep={workflowStep}
      globalConfigParams={strategiesGlobalConfigParams[workflowStep.strategy]}
      isFetchingStrategy={isPendingStrategyRequest}
      isSavingStep={isPendingRequest}
      isDisabled={!isVersionEditable}
      context={context}
      onReceiveChanges={receiveStepChangesNotification}
      onSave={saveStep}
      onSwitchVersionRequest={onSwitchVersionRequest}
    />;
  };

  const renderFilterEdition = (): JSX.Element => {
    return <div className="p-10">
      <WorkflowStepConditions
        workflowStep={workflowStep}
        isSavingStep={isPendingRequest}
        isDisabled={!isVersionEditable}
        context={context}
        onReceiveChanges={receiveStepChangesNotification}
        onSave={saveStep}
        onSwitchVersionRequest={onSwitchVersionRequest}
      />
    </div>;
  };

  if (!sliderMode) {
    return null;
  }

  return <div className="col-4 workflow-builder-sidebar">
    <div className="sidebar-header">
      <div className="sidebar-close" onClick={onClose}>
        <i className="fa-regular fa-xmark-large"></i>
      </div>
      <div className="sidebar-title-wrapper">
        <h2 className="sidebar-title">{renderTitle()}</h2>
      </div>
    </div>
    {renderBody()}
  </div>;
};

export default Slider;
