import { ActionTypes } from "../constants/Constants";
import findIndex from "lodash/findIndex";
import each from "lodash/each";
import map from "lodash/map";
import pullAt from "lodash/pullAt";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";

const defaultState = {
  guests: [],
  selectedGuests: [],
  siloedData: {},
  isPending: false,
  errors: [],
  reloadGuestsPage: false,
};

function guests(state = defaultState, action) {
  switch (action.type) {
  case ActionTypes.START_FETCHING_GUESTS: {
    return Object.assign({}, state, { guests: [], selectedGuests: [] });
  }
  case ActionTypes.RECEIVE_GUESTS: {
    const newGuestList = map(action.guests, (guest) => {
      guest.id = guest._id;
      return guest;
    });
    state = Object.assign({}, state, { guests: newGuestList });
    state["nextURL"] = action.nextURL;
    state["previousURL"] = action.previousURL;
    const uniqueKeyInSiloedData = action["uniqueKeyInSiloedData"];
    state.siloedData = uniqueKeyInSiloedData ? { ...state.siloedData, [uniqueKeyInSiloedData]: newGuestList } : state.siloedData;
    return state;
  }
  case ActionTypes.TOGGLE_GUEST: {
    let selectedGuestsArray = state.selectedGuests.slice();
    each(action.guests, guest => {
      const index = findIndex(selectedGuestsArray, g => {return g.id == guest.id;});
      if (index > -1 && action.forceCheck != true) {
        pullAt(selectedGuestsArray, index);
      } else {
        if (index == -1 && action.forceCheck != false) {
          selectedGuestsArray.push(guest);
        }
      }
    });
    return Object.assign({}, state, { selectedGuests: selectedGuestsArray });
  }
  case ActionTypes.CLEAR_FILTERS:
  case ActionTypes.CLEAR_SELECTED_GUESTS:
    return Object.assign({}, state, { selectedGuests: [] });
  case ActionTypes.UPDATE_GUEST_REQUEST:
    return { ...state, isPending: true, errors: [] };
  case ActionTypes.RECEIVE_GUEST:
  case ActionTypes.UPDATE_GUEST:
    return { ...state, guests: insertOrUpdateObject(state.guests, action.data, "_id"), isPending: false, errors: [] };
  case ActionTypes.UPDATE_GUEST_FAILURE:
    return { ...state, isPending: false, errors: action.data.errors };
  case ActionTypes.STORE_GUEST_IN_GUESTS:
    return { ...state, guests: insertOrUpdateObject(state.guests, action.data, "_id"), isPending: false, errors: [] };
  case ActionTypes.DELETE_GUEST_KEY_IN_SILOED_DATA: {
    const uniqueKeyInSiloedData = action["uniqueKeyInSiloedData"];
    if (uniqueKeyInSiloedData) {
      const data = { ...state.siloedData };
      delete data[uniqueKeyInSiloedData];
      return { ...state, siloedData: data };
    } else {
      return state;
    }
  }
  case ActionTypes.RELOAD_GUESTS_PAGE:
    return { ...state, reloadGuestsPage: true };
  default:
    return state;
  }
}

export default guests;
