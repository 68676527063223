import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Loader from "../shared/Loader.react";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import flatten from "lodash/flatten";

class ValueListMappingModal extends Component {
  constructor(props) {
    super(props);
    [
      "changeSheetValueMapping",
      "onSubmit"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = {
      currentMapping: {}
    };
  }

  componentDidUpdate(prevProps) {
    const { fetchDistinctValuesAtColumn, mappingColumn, isOpen, guestImportOperationId, eventId, distinctValuesPendingRequest } = this.props;
    if (isOpen && !prevProps.isOpen) {
      fetchDistinctValuesAtColumn(eventId, guestImportOperationId, mappingColumn + 1);
      this.setState({ currentMapping: {} });
    }
    if (prevProps.distinctValuesPendingRequest && !distinctValuesPendingRequest && isOpen) {
      this.setState({ currentMapping: this.autoMapping(this.props) });
    }
  }

  autoMapping(props) {
    const { guestField } = props;
    let mapping = {};

    guestField.available_values.forEach(availableValue => {
      const matchedValue = this.clearedDistinctValues(props).find(value => value == availableValue.value);
      if (matchedValue) mapping[this.cleanMongoHashKeyValue(matchedValue)] = availableValue.value;
    });
    return mapping;
  }

  changeSheetValueMapping(sheetValue) {
    return (e) => {
      const { currentMapping } = this.state;
      const value = e.target.value;
      const mapping = Object.assign({}, currentMapping, { [this.cleanMongoHashKeyValue(sheetValue)]: value });
      this.setState({ currentMapping: mapping });
    };
  }

  cleanMongoHashKeyValue(value) {
    return value.replace(/\./g, "___DOT___").replace(/\$/g, "___DOL___");
  }

  clearedDistinctValues(props) {
    const { guestField, distinctValues } = (props || this.props);

    if (!distinctValues) return [];

    return (guestField.allow_multiple_values ? uniq(flatten(distinctValues.map(value => value.split(";")))) : distinctValues).sort();
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.canBeSubmitted()) return;
    const { onSubmit } = this.props;
    const { currentMapping } = this.state;
    onSubmit(currentMapping);
  }

  canBeSubmitted() {
    const { currentMapping } = this.state;
    if (!this.clearedDistinctValues()) return false;

    const unMappedField = this.clearedDistinctValues().find(value => !currentMapping[this.cleanMongoHashKeyValue(value)] || currentMapping[this.cleanMongoHashKeyValue(value)] == "");
    return !!unMappedField;
  }

  renderGuestFieldValueSelect(sheetValue) {
    const { guestField } = this.props;
    const options = sortBy(guestField.available_values, (av) => av.label).map(availableValue => {
      return <option value={availableValue.value} key={availableValue.value}>{ availableValue.label }</option>;
    });
    return <select className="form-select" onChange={this.changeSheetValueMapping(sheetValue)} value={this.state.currentMapping[this.cleanMongoHashKeyValue(sheetValue)]}>
      <option value="" key="default" >{ I18n.t("please_choose") }</option>;
      { options }
    </select>;
  }

  renderMappingForm() {
    const { distinctValues } = this.props;
    if (!distinctValues) return <Loader size="large" inline={false} message={I18n.t("react.loader.loading")} />;
    return this.clearedDistinctValues().map(value => {
      return <div className="row mt-5" key={value}>
        <div className="col-5" key="value">{ value }</div>
        <div className="col-2" key="arrow"><i className="fa-regular fa-arrow-right"></i></div>
        <div className="col-5" key="select">{ this.renderGuestFieldValueSelect(value) }</div>
      </div>;
    });
  }

  render() {
    const { isOpen, guestField, onClose } = this.props;
    if (!isOpen) return null;
    return <Modal show={isOpen} onHide={this.onClose} size="lg">
      <Modal.Header>
        <Modal.Title>{ guestField.label }</Modal.Title>
        <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
      </Modal.Header>
      <Modal.Body>
        { this.renderMappingForm() }
      </Modal.Body>
      <Modal.Footer>
        <a href="#" className="btn btn-secondary" onClick={onClose}>{ I18n.t("cancel") }</a>
        <a href="#" className="btn btn-primary" onClick={this.onSubmit} disabled={this.canBeSubmitted()}>{ I18n.t("validate") }</a>
      </Modal.Footer>
    </Modal>;
  }
}

export default ValueListMappingModal;

ValueListMappingModal.propTypes = {
  fetchDistinctValuesAtColumn: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  guestImportOperationId: PropTypes.string,
  mappingColumn: PropTypes.number,
  distinctValues: PropTypes.array,
  distinctValuesPendingRequest: PropTypes.bool,
  guestField: PropTypes.object,
  eventId: PropTypes.string
};
