import { ActionTypes, CALL_API } from "../constants/Constants";
import { WorkflowStepStrategy } from "../types/Workflow";

export function addTriggerStep(strategy: WorkflowStepStrategy): any {
  return {
    type: ActionTypes.ADD_TRIGGER_STEP_OFFLINE,
    data: {
      strategy,
      type: "trigger",
      temporaryId: `TRIGGER-${Math.floor(Math.random() * 100)}`,
      rank: 1,
      hasChanges: true
    }
  };
}

export function changeStepStrategy(strategy: WorkflowStepStrategy): any {
  return {
    type: ActionTypes.CHANGE_STEP_STRATEGY,
    strategy
  };
}

export function addActionStep(previousStepId: string, nextStepId: string): any {
  return {
    type: ActionTypes.ADD_ACTION_STEP_OFFLINE,
    data: {
      previous_step_id: previousStepId,
      next_step_id: nextStepId,
      type: "action",
      temporaryId: `ACTION-${Math.floor(Math.random() * 100)}`,
      settings: {},
      hasChanges: true
    }
  };
}

export function addFilterStep(previousStepId: string, nextStepId: string): any {
  return {
    type: ActionTypes.ADD_FILTER_STEP_OFFLINE,
    data: {
      previous_step_id: previousStepId,
      next_step_id: nextStepId,
      type: "filter",
      temporaryId: `FILTER-${Math.floor(Math.random() * 100)}`,
      strategy: "base",
      conditions: [],
      hasChanges: true
    }
  };
}

export function changeActiveStepId(workflowStepId: string): any {
  return {
    type: ActionTypes.CHANGE_ACTIVE_STEP_ID,
    workflowStepId
  };
}

export function receiveStepChanges(workflowStepId: string): any {
  return {
    type: ActionTypes.RECEIVE_WORKFLOW_STEP_CHANGES,
    workflowStepId
  };
}

export function createWorkflowStep(eventId: string, workflowVersionId: string, workflowAttributes: any, temporaryId: string, notificationsOptions?: any): any {
  const { CREATE_WORKFLOW_STEP_PENDING, CREATE_WORKFLOW_STEP_SUCCESS, CREATE_WORKFLOW_STEP_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_WORKFLOW_STEP_PENDING, CREATE_WORKFLOW_STEP_SUCCESS, CREATE_WORKFLOW_STEP_FAILURE],
      method: "POST",
      body: { workflow_step: workflowAttributes },
      endpoint: `/api/v1/events/${eventId}/automation/workflow_versions/${workflowVersionId}/workflow_steps.json`,
      notificationsOptions
    },
    temporaryId
  };
}

export function updateWorkflowStep(eventId: string, workflowVersionId: string, workflowStepId: string, workflowAttributes: any, notificationsOptions?: any): any {
  const { UPDATE_WORKFLOW_STEP_PENDING, UPDATE_WORKFLOW_STEP_SUCCESS, UPDATE_WORKFLOW_STEP_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_WORKFLOW_STEP_PENDING, UPDATE_WORKFLOW_STEP_SUCCESS, UPDATE_WORKFLOW_STEP_FAILURE],
      method: "PUT",
      body: { workflow_step: workflowAttributes },
      endpoint: `/api/v1/events/${eventId}/automation/workflow_versions/${workflowVersionId}/workflow_steps/${workflowStepId}.json`,
      notificationsOptions
    },
    workflowStepId
  };
}

export function deleteWorkflowStep(eventId: string, workflowVersionId: string, workflowStepId: string, nextStepId: string, notificationsOptions?: any): any {
  const { DELETE_WORKFLOW_STEP_PENDING, DELETE_WORKFLOW_STEP_SUCCESS, DELETE_WORKFLOW_STEP_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DELETE_WORKFLOW_STEP_PENDING, DELETE_WORKFLOW_STEP_SUCCESS, DELETE_WORKFLOW_STEP_FAILURE],
      method: "DELETE",
      endpoint: `/api/v1/events/${eventId}/automation/workflow_versions/${workflowVersionId}/workflow_steps/${workflowStepId}.json`,
      notificationsOptions
    },
    workflowStepId,
    nextStepId
  };
}

export function deleteUnpersistedWorkflowStep(workflowStepId: string, nextStepId: string): any {
  return {
    type: ActionTypes.DELETE_WORKFLOW_STEP_SUCCESS,
    workflowStepId,
    nextStepId
  };
}
