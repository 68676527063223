import { ActionTypes } from "../constants/Constants";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";
import { normalizeId } from "../utils/identifierUtils";
import { normalizeIdInArray } from "../utils/identifierUtils";

const defaultState = {
  data: [],
  errors: {},
  fetched: false,
  currentAccesspoint: null,
  customSessionTypes: [],
  customLocations: [],
  customRoomTypes: [],
  siloedData: {},
  nextURL: null,
  previousURL: null,
};

function accesspoints(state = defaultState, action) {
  switch (action.type) {
  case ActionTypes.RECEIVE_ACCESSPOINTS_REQUEST:
    return { ...state, data: [], fetched: false, errors: {} };
  case ActionTypes.RECEIVE_ACCESSPOINTS_SUCCESS: {
    const uniqueKeyInSiloedData = action["uniqueKeyInSiloedData"];
    const data = normalizeIdInArray(action.data);
    return { ...state, data: [ ...data ], fetched: true, nextURL: action.nextURL, previousURL: action.previousURL,
      siloedData: uniqueKeyInSiloedData ? { ...state.siloedData, [uniqueKeyInSiloedData]: [ ...data ] } : state.siloedData };
  }
  case ActionTypes.RECEIVE_ACCESSPOINT: {
    const data = insertOrUpdateObject(state.data, normalizeId(action.data), "id");
    return Object.assign({}, state, { data, errors: {}, currentAccesspoint: action.data });
  }
  case ActionTypes.CREATE_ACCESSPOINT_SUCCESS:
  case ActionTypes.UPDATE_ACCESSPOINT_SUCCESS: {
    const accesspoint = Object.assign({}, action.data, { id: action.data._id }); //ugly hack to make it works with id to _id transformation
    const data = insertOrUpdateObject(state.data, accesspoint, "_id");
    return Object.assign({}, state, { data, errors: {}, currentAccesspoint: accesspoint });
  }
  case ActionTypes.CREATE_ACCESSPOINT_FAILURE:
  case ActionTypes.UPDATE_ACCESSPOINT_FAILURE:
    return Object.assign({}, state, { errors: action.data });
  case ActionTypes.FETCH_ACCESSPOINT_CUSTOM_VALUES_FOR_SESSION_SUCCESS:
    return Object.assign({}, state, {
      customSessionTypes: action.data.session_types,
      customLocations: action.data.locations,
      customRoomTypes: action.data.room_types
    });
  case ActionTypes.DELETE_ACCESSPOINT_KEY_IN_SILOED_DATA: {
    const uniqueKeyInSiloedData = action["uniqueKeyInSiloedData"];
    if (uniqueKeyInSiloedData) {
      const data = { ...state.siloedData };
      delete data[uniqueKeyInSiloedData];
      return { ...state, siloedData: data };
    } else {
      return state;
    }
  }
  default:
    return state;
  }
}

export default accesspoints;
