import { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import EngagementHistoryModal from "../../containers/EngagementHistoryModal.react";
import { Guest } from "../../types/Guest";
import { ThematicScoring } from "../../types/ThematicScoring";

interface Props {
  value?: number;
  absoluteValue?: number;
  guest: Guest;
  thematicScoring: ThematicScoring;
}

interface State {
  showGuestEngagementHistoryModal: boolean;
}

class StandardizedScoreCell extends Component<Props, State> {
  constructor(props) {
    super(props);
    [
      "toggleGuestEngagementHistoryModal"
    ].forEach((fn) => { this[fn] = this[fn].bind(this); });
    this.state = { showGuestEngagementHistoryModal: false };
  }

  standardizedValuePercent(): number {
    const { value } = this.props;

    if (value <= -2) return 0;
    if (value >= 2) return 100;

    return (value + 2) * 25;
  }

  toggleGuestEngagementHistoryModal(): void {
    const { showGuestEngagementHistoryModal } = this.state;
    this.setState({ showGuestEngagementHistoryModal: !showGuestEngagementHistoryModal });
  }

  render(): JSX.Element {
    const { value, absoluteValue, guest, thematicScoring } = this.props;
    const { showGuestEngagementHistoryModal } = this.state;
    if (!value) return null;

    const label = `${absoluteValue || 0} (${parseFloat(value.toFixed(2)).toLocaleString(I18n.locale)})`;
    const tooltip = <Tooltip id="tooltip">{label}</Tooltip>;
    return (
      <td className="cell-default d-none d-sm-table-cell" onClick={this.toggleGuestEngagementHistoryModal} style={{ cursor: "pointer" }}>
        <OverlayTrigger placement="top" overlay={tooltip}>
          <div style={{ textAlign: "left", margin: "0 auto", width: "64px" }}>
            <div className="rep">
              <span className="bar"></span>
              <span className="ext_ind_neg"></span>
              <span className="ext_ind_pos"></span>
              <span className="median"></span>
              <span className="ind" style={{ left: `${this.standardizedValuePercent().toFixed(0)}%` }}></span>
            </div>
          </div>
        </OverlayTrigger>
        <EngagementHistoryModal
          isOpen={showGuestEngagementHistoryModal}
          toggleModal={this.toggleGuestEngagementHistoryModal}
          guest={guest}
          thematicScoring={thematicScoring}
        />
      </td>
    );
  }
}

export default StandardizedScoreCell;
