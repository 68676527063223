import { useState, MouseEvent } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import WorkflowNameForm from "./WorkflowNameForm.react";
import WorkflowVersionForm from "./WorkflowVersionForm.react";
import { pathToWorkflows, pathToWorkflowRuns } from "../../utils/pathUtils";
import { WorkflowVersion, Workflow } from "../../types/Workflow";
import Switch from "react-bootstrap-switch";

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.workflows.builder.navbar.${key}`, opts);
}

interface Props {
  workflowVersion: WorkflowVersion;
  workflow: Workflow;
  isVersionOnline: boolean;
  isVersionPublished: boolean;
  isVersionPublishable: boolean
  isWorkflowEditable: boolean;
  versionBelongsToCurrentUser: boolean;
  publishVersion(name: string, description: string): void;
  switchToOnlineVersion(e: MouseEvent): void;
  onWorkflowSave(attributes: any): void;
  onWorkflowVersionsRequest(): void;
}

const WorkflowNavbar: React.FC<Props> = ({
  workflowVersion,
  workflow,
  isVersionOnline,
  isVersionPublished,
  isVersionPublishable,
  isWorkflowEditable,
  versionBelongsToCurrentUser,
  publishVersion,
  switchToOnlineVersion,
  onWorkflowSave,
  onWorkflowVersionsRequest
}) => {
  const [versionFormModalDisplayed, setVersionFormModalDisplayed] = useState<boolean>(false);

  const renderVersionNumber = (): JSX.Element => {
    if (!workflowVersion) return;

    if (isVersionOnline) {
      return <span className="badge badge-pill bg-primary ms-2">
        {i18n("version_number", { number: workflowVersion.version_number })}
      </span>;
    }

    if (isVersionPublished) {
      return <span className="badge badge-pill bg-secondary ms-2">
        {i18n("react.workflow_version.show.past_published_version")}
      </span>;
    }

    return <span className="badge badge-pill bg-secondary ms-2">
      {i18n("draft")}
    </span>;
  };

  const renderBackToOnlineVersionLink = (): JSX.Element => {
    if (!workflow.online_version_id || isVersionOnline) return;

    return <a href="#" className="link-primary ms-2" onClick={switchToOnlineVersion}>
      {i18n("back_to_online_version")}
    </a>;
  };

  const renderEditorName = (): JSX.Element => {
    return <span className="ms-2">
      {i18n("edited_by")} {versionBelongsToCurrentUser ? i18n("you") : workflowVersion.user_identity}
    </span>;
  };

  const renderWorkflowRunsHistoryButton = (): JSX.Element => {
    return <a href={pathToWorkflowRuns(workflow._id)} className="btn btn-secondary btn-sm">
      <i className="fa-regular fa-clock-rotate-left"></i> {i18n("history")}
    </a>;
  };

  const renderVersionsButton = (): JSX.Element => {
    return <button type="button" onClick={onWorkflowVersionsRequest} className="btn btn-secondary btn-sm me-3">
      <i className="fa-regular fa-list"></i> {i18n("see_versions_history")}
    </button>;
  };

  const renderPublicationButton = (): JSX.Element => {
    if (isVersionPublished) {
      return;
    }

    if (!isVersionPublishable || !isWorkflowEditable) {
      const tooltipMsg = <Tooltip id="tooltip-disabled-switch">{i18n("cant_publish_workflow_version")}</Tooltip>;
      return <OverlayTrigger placement="bottom" overlay={tooltipMsg}>
        <span>
          <button type="button" className="btn btn-secondary btn-sm me-3" disabled={true}>
            {I18n.t("react.workflow_versions.show.publish")}
          </button>
        </span>
      </OverlayTrigger>;
    }

    return <button type="button" onClick={(): void => setVersionFormModalDisplayed(true)} className="btn btn-primary btn-sm me-3">
      <span>{I18n.t("react.workflow_versions.show.publish")}</span>
    </button>;
  };

  const renderEnableWorkflowSwitch = (): JSX.Element => {
    const switchProps = {
      onColor: "success",
      onText: I18n.t("react.workflows.index.active"),
      offText: I18n.t("react.workflows.index.inactive"),
      labelWidth: 25,
      value: workflow.enabled,
      bsSize: "small",
      onChange: (): void => onWorkflowSave({ enabled: !workflow.enabled })
    };

    if (!workflow.online_version_id) {
      const tooltipMsg = <Tooltip id="tooltip-disabled-switch">{i18n("cant_enable_workflow_without_published_version")}</Tooltip>;

      return <OverlayTrigger placement="bottom" overlay={tooltipMsg}>
        <span><Switch {...switchProps} disabled={true} /></span>
      </OverlayTrigger>;
    }

    return <Switch {...switchProps} />;
  };

  const renderWorkflowVersionFormModal = (): JSX.Element => {
    return <WorkflowVersionForm
      workflowVersion={workflowVersion}
      isVisible={versionFormModalDisplayed}
      onClose={(): void => setVersionFormModalDisplayed(false)}
      onPublish={publishVersion}
    />;
  };

  const tooltip = <Tooltip id="tooltip">{I18n.t("react.builders.close_builder")}</Tooltip>;

  return <nav className="navbar navbar-expand navbar-light bg-light navbar-static-top">
    <div className="container-fluid">
      <div className="navbar-text d-flex align-items-center">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <a href={pathToWorkflows()} className="close-builder">
            <i className="fa-regular fa-xmark-large"></i>
          </a>
        </OverlayTrigger>
        <span className="ml-15">{renderEnableWorkflowSwitch()}</span>
        { workflow && <WorkflowNameForm
          workflow={workflow}
          onSave={onWorkflowSave}
          disabled={!isWorkflowEditable} />
        }
        {renderVersionNumber()}
        {renderEditorName()}
        {renderBackToOnlineVersionLink()}
      </div>
      <div className="navbar-text">
        {renderPublicationButton()}
        {renderVersionsButton()}
        {renderWorkflowRunsHistoryButton()}
      </div>
    </div>
    {renderWorkflowVersionFormModal()}
  </nav>;
};

export default WorkflowNavbar;
