import { Component } from "react";
import { DropdownItem, Dropdown } from "react-bootstrap";
import { AccesspointBundle } from "../types/Accesspoint";

interface BundlesPrivileges {
  accesspoint_bundle: AccesspointBundle[],
  id: string,
  name: string,
}

interface Props {
  resource: any;
  forceNoAccessEnabled?: boolean;
  bundlesPrivileges?: BundlesPrivileges[];

  handleRemove: () => void;
  handleValue: (value: any) => void;
}

interface State {
  maxAllowedAccesses: string;
  tooManyAccessesMessage: string;
}

const i18n = (key: string): string => {
  return I18n.t(`react.access_privilege_dropdown.${key}`);
};

class AccessPrivilegeDropdown extends Component<Props, State> {
  constructor(props) {
    super(props);
    [
      "handleInputChange",
      "handleSubmit",
      "selectHandler",
    ].forEach(method => this[method] = this[method].bind(this));

    this.state = {
      maxAllowedAccesses: "",
      tooManyAccessesMessage: "",
    };
  }

  componentDidMount(): void {
    const { resource } = this.props;
    if (resource) {
      const { max_allowed_accesses, too_many_accesses_message } = resource;
      this.setState({
        maxAllowedAccesses: max_allowed_accesses || "",
        tooManyAccessesMessage: too_many_accesses_message || ""
      });
    }
  }

  handleSubmit(): void {
    const { handleValue } = this.props;
    const { maxAllowedAccesses, tooManyAccessesMessage } = this.state;
    handleValue({
      max_allowed_accesses: maxAllowedAccesses,
      too_many_accesses_message: tooManyAccessesMessage
    });
  }

  selectHandler(maxAllowedAccesses): () => void {
    return (): void => {
      if (maxAllowedAccesses === 0 || maxAllowedAccesses === "") {
        return;
      }
      this.setState({ maxAllowedAccesses });
      const { handleValue } = this.props;
      handleValue({ max_allowed_accesses: maxAllowedAccesses });
    };
  }

  handleInputChange({ target }): void {
    const { name, value } = target;
    this.setState({ [name]: value } as Pick<State, keyof State>);
  }

  renderOverlay(initialMaxAllowedAccesses: number): JSX.Element {
    const { resource, forceNoAccessEnabled, bundlesPrivileges, handleRemove } = this.props;
    const { tooManyAccessesMessage, maxAllowedAccesses } = this.state;
    const disabledOptions = bundlesPrivileges?.length > 0;

    return <Dropdown.Menu style={{ minWidth: "220px" }}>
      <DropdownItem className="small" active={!resource && !forceNoAccessEnabled} onClick={():void => handleRemove()}>
        <span className="text-danger"><span className="fa-regular fa-ban" /> {i18n("access_none")}</span>
      </DropdownItem>
      <DropdownItem className="small" active={disabledOptions || initialMaxAllowedAccesses === 1} onClick={this.selectHandler(1)}>
        {i18n("access_once")}
      </DropdownItem>
      <DropdownItem className="small" active={disabledOptions || initialMaxAllowedAccesses === -1} onClick={this.selectHandler(-1)}>
        &infin;<span style={{ marginLeft: "3px" }}>{i18n("access_unlimited")}</span>
      </DropdownItem>

      <div className="p-3 pt-5">
        <div className={`mb-2 ${disabledOptions ? "disabled" : ""}`}>
          <label className="form-label small">{ i18n("access_number") }</label>
          <input type="number" onWheel={(e): void => (e.target as HTMLElement).blur()} disabled={disabledOptions} name="maxAllowedAccesses" value={maxAllowedAccesses} onChange={this.handleInputChange} className="form-control form-control-sm" />
        </div>
        <div className={disabledOptions ? "disabled" : ""}>
          <label className="form-label small">{ i18n("too_many_accesses_message") }</label>
          <input type="text" name="tooManyAccessesMessage" value={tooManyAccessesMessage} disabled={disabledOptions} onChange={this.handleInputChange} className="form-control form-control-sm" />
        </div>
        <div className={`text-end mt-10 ${disabledOptions ? "disabled" : ""}`}>
          <button className="btn btn-sm btn-primary" onClick={this.handleSubmit} disabled={disabledOptions}>
            { i18n("submit") }
          </button>
        </div>
      </div>
    </Dropdown.Menu>;
  }

  renderButtonLabel(initialMaxAllowedAccesses: number): JSX.Element|string {
    const { resource } = this.props;
    if (resource) {
      if (initialMaxAllowedAccesses === 1) {
        return i18n("access_once");
      } else if (initialMaxAllowedAccesses === -1) {
        return `∞ ${i18n("access_unlimited")}`;
      } else if (initialMaxAllowedAccesses > 1) {
        return `${initialMaxAllowedAccesses} ${i18n("accesses")}`;
      }
    } else {
      return <span className="text-danger"><span className="fa-regular fa-ban" /> {i18n("access_none")} </span>;
    }
  }

  render(): JSX.Element {
    const { resource } = this.props;

    if (resource && resource.errors) {
      let errors = "";
      Object.keys(resource.errors).forEach(attribute => {
        resource.errors[attribute].forEach(error => {
          errors += `${error} `;
        });
      });
      return <span>
        <small className="text-danger">{errors}</small>
      </span>;
    }

    const initialMaxAllowedAccesses = resource?.max_allowed_accesses || 0;
    return <Dropdown>
      <Dropdown.Toggle variant="secondary btn-sm">
        {this.renderButtonLabel(initialMaxAllowedAccesses)}
      </Dropdown.Toggle>
      {this.renderOverlay(initialMaxAllowedAccesses)}
    </Dropdown>;
  }
}

export default AccessPrivilegeDropdown;
