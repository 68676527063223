import { Component, ChangeEvent } from "react";
import { connect } from "react-redux";
import SlidingPane from "react-sliding-pane";
import EventFormPane from "./events/EventFormPane.react";
import SeatsioForm from "./integrations/SeatsioForm.react";

import Loader from "../components/shared/Loader.react";
import HelpSection from "../components/shared/HelpSection.react";
import { requestEvent } from "../actions/ImportActionCreators";
import { Event } from "../types/Event";
import Icons from "../constants/Icons";
import { SeatsioClient, Region } from "seatsio";

interface Props {
  seatsioEventKey: string;
  ticketsTypes: any[];
  event: Event;
  requestEvent(): void;
  isOpen: boolean;
  onChange(seatsioEventKey: string): void;
  onValidateChartCategories(chartCategories: any[]): void;
  onClose(): void;
  pendingRequest: boolean;
}

interface State {
  seatsioEvents: any[];
  eventFormPaneOpen: boolean;
  selectedSeatsioEvent: any;
  chartCategories: any;
}

function i18n(key, opts = {}): string {
  return I18n.t(`react.event_seatsio_form.${key}`, opts);
}

class TicketingSeatsioFormPane extends Component<Props, State> {
  constructor(props) {
    super(props);
    [
      "changeEventKey",
      "toggleEventFormPane",
      "validateChartCategories"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      seatsioEvents: null,
      eventFormPaneOpen: false,
      selectedSeatsioEvent: null,
      chartCategories: null
    };
  }

  componentDidMount(): void {
    const { event } = this.props;

    if (event.fetched) {
      this.listAllSeatsioEvents(event);
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { event, isOpen, requestEvent, pendingRequest, seatsioEventKey } = this.props;

    if (!prevProps.isOpen && isOpen && !prevProps.event.fetched) {
      requestEvent();
    }

    if (!prevProps.event.fetched && event.fetched || (prevProps.pendingRequest && !pendingRequest)) {
      this.listAllSeatsioEvents(event);
    }

    if (prevProps.seatsioEventKey != seatsioEventKey) {
      this.findSelectedSeatsioEvent(seatsioEventKey);
    }
  }

  // seats.io returns an async iterator, so we have to handle it with async..for await
  async listAllSeatsioEvents(event: Event): Promise<any> {
    if (this.configurationIsEmpty(event)) return;
    const seatsioEvents = [];
    const client = new SeatsioClient(Region.EU(), event.seatsio_secret_workspace_key);

    try {
      for await (const event of client.events.listAll()) {
        seatsioEvents.push(event);
      }
      this.setState({ seatsioEvents }, () => this.findSelectedSeatsioEvent());
    } catch (_error) {
      this.setState({ seatsioEvents });
    }
  }

  findSelectedSeatsioEvent(seatsioEventKey = this.props.seatsioEventKey): void {
    const { seatsioEvents } = this.state;
    const selectedSeatsioEvent = (seatsioEvents || []).find((event) => event.key === seatsioEventKey);

    this.setState({ selectedSeatsioEvent }, () => this.retrieveChartCategories());
  }

  retrieveChartCategories(): void {
    const { selectedSeatsioEvent } = this.state;
    const { event } = this.props;

    if (!selectedSeatsioEvent) return this.setState({ chartCategories: null });

    const client = new SeatsioClient(Region.EU(), event.seatsio_secret_workspace_key);
    client.charts.retrievePublishedVersion(selectedSeatsioEvent.chartKey).then((chart) => {
      this.setState({ chartCategories: chart.categories.list });
    });
  }

  configurationIsEmpty(event: Event): boolean {
    return !event.seatsio_secret_workspace_key;
  }

  changeEventKey(e: ChangeEvent<any>): void {
    this.props.onChange(e.target.value);
  }

  toggleEventFormPane(): void {
    const { eventFormPaneOpen } = this.state;
    this.setState({ eventFormPaneOpen: !eventFormPaneOpen });
  }

  validateChartCategories(): void {
    const { chartCategories } = this.state;
    const { onValidateChartCategories } = this.props;

    onValidateChartCategories(chartCategories);
  }

  isChartCategoryInTicketsTypes(chartCategory: any): boolean {
    const { ticketsTypes } = this.props;
    const matchedTicketType = ticketsTypes.find(ticketsType => ticketsType.seatsio_category_key == chartCategory.key);

    return matchedTicketType !== undefined;
  }

  renderEventKeyError(): JSX.Element {
    const { event, seatsioEventKey } = this.props;
    const { selectedSeatsioEvent, seatsioEvents } = this.state;

    if (this.configurationIsEmpty(event)) return;

    if (seatsioEventKey && seatsioEvents && !selectedSeatsioEvent) {
      return <p className="text-danger"><i className="fa-regular fa-circle-xmark" /> { i18n("wrong_event_key") }</p>;
    }
  }

  renderMappingInfo(chartCategory): JSX.Element {
    if (this.isChartCategoryInTicketsTypes(chartCategory)) {
      return <small className="text-success"><i className="fa-regular fa-check"></i> { i18n("mapped") }</small>;
    }

    return <small className="text-danger"><i className="fa-regular fa-xmark"></i> { i18n("unmapped") }</small>;
  }

  renderValidateChartCategoriesButton(): JSX.Element {
    const { ticketsTypes } = this.props;
    const { chartCategories } = this.state;
    const buttonLabel = ticketsTypes.length ? i18n("update_ticket_types") : i18n("add_as_ticket_types");

    if (ticketsTypes.length === 0 && chartCategories.length === 0) return;

    return <button className="btn btn-secondary mt-10" onClick={this.validateChartCategories}>{ buttonLabel }</button>;
  }

  renderChartCategories(): JSX.Element {
    const { chartCategories } = this.state;
    const { event } = this.props;

    if (this.configurationIsEmpty(event)) return;

    if (!chartCategories) return;

    const categories = chartCategories.map(chartCategory => {
      return <li className="list-group-item" key={ chartCategory.key }>
        <span className="label-empty mr-10" style={{ backgroundColor: chartCategory.color }}></span>
        { chartCategory.label }
        <div className="float-end">{ this.renderMappingInfo(chartCategory) }</div>
      </li>;
    });

    return (
      <div className="mb-3">
        <label className="form-label">{ i18n("chart_categories") }</label>
        { categories.length ? categories : <p className="text-danger"><i className="fa-regular fa-circle-xmark" /> { i18n("no_chart_categories") }</p> }
        { this.renderValidateChartCategoriesButton() }
      </div>
    );
  }

  renderPaneContent(): JSX.Element {
    const { event, seatsioEventKey } = this.props;
    const { seatsioEvents } = this.state;

    if (!event.fetched) {
      return <Loader />;
    }

    const emptyConfig = this.configurationIsEmpty(event);
    const buttonLabel = emptyConfig ? i18n("configure_main_setup") : i18n("change_main_setup");
    const errorMessage = emptyConfig && i18n("missing_event_config");

    return <div>
      <HelpSection help={<span className="text" dangerouslySetInnerHTML={{ __html: i18n("help_message") }}></span>} />
      <h4>{ i18n("seatsio_main_setup") }</h4>
      <div className="mb-3">
        <label className="form-label">{ i18n("api_key") }</label>
        <input className="form-control" type="text" value={event.seatsio_api_key || i18n("undefined") } disabled />
      </div>

      <div className="mb-3">
        <label className="form-label">{ i18n("public_workspace_key") }</label>
        <input className="form-control" type="text" value={event.seatsio_public_workspace_key || i18n("undefined") } disabled />
      </div>

      { errorMessage && <p><span className="text-danger"><i className="fa-regular fa-circle-xmark" /> { errorMessage }</span></p> }
      <p><button className="btn btn-secondary" onClick={this.toggleEventFormPane}>{ buttonLabel }</button></p>

      <h4 className="mt-30">{ i18n("ticketing_config") }</h4>
      <HelpSection help={<span className="text" dangerouslySetInnerHTML={{ __html: i18n("help_message_2") }}></span>} />
      <div className="mb-3">
        <label className="form-label">{ i18n("event_key") }</label>
        <select className="form-select" onChange={this.changeEventKey} value={!emptyConfig && seatsioEventKey ? seatsioEventKey : ""} disabled={emptyConfig}>
          <option value="">{ i18n("undefined") }</option>
          { (seatsioEvents || []).map(seatsioEvent => {
            return <option value={seatsioEvent.key} key={seatsioEvent.key}>{seatsioEvent.key}</option>;
          }) }
        </select>
      </div>

      { this.renderEventKeyError() }
      { this.renderChartCategories() }
    </div>;
  }

  render(): JSX.Element {
    const { isOpen, onClose } = this.props;
    const { eventFormPaneOpen } = this.state;

    return (
      <SlidingPane isOpen={isOpen}
        title={i18n("title")}
        from="right"
        width="25%"
        onRequestClose={onClose}
        className="width-100-xs width-50-md"
        closeIcon={Icons.close()}>
        <div className="card-body">
          { this.renderPaneContent() }

          <EventFormPane isOpen={eventFormPaneOpen}
            onClose={this.toggleEventFormPane}
            title={i18n("title")}
            width="25%"
            className="width-100-xs width-50-md">
            <SeatsioForm noPanel={true} />
          </EventFormPane>
        </div>
      </SlidingPane>
    );
  }
}

function mapStateToProps(state): any {
  return {
    event: state.event,
    pendingRequest: state.event.pendingRequest
  };
}

const mapDispatchToProps = {
  requestEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketingSeatsioFormPane);
