import { Component } from "react";
import { connect } from "react-redux";
import querystring from "querystring";
import { withRouter } from "react-router-dom";

import { importGuestCategories } from "../actions/ImportGuestCategoryActionCreators";
import { fetchAccesspoints } from "../actions/AccesspointActionCreators";
import { fetchThematics } from "../actions/ThematicsActionCreators";
import { fetchLabels } from "../actions/LabelsActionCreators";
import { fillStateFromSearchQuery } from "../actions/SearchQueryActionCreators";
import { buildSearchQueryFromState, cleanUpQueryString } from "./QueryStringUtils";
import { fetchEventGuestFields } from "../actions/GuestFieldsActionCreators";
import Loader from "../components/shared/Loader.react";

const START_SEARCH_DELAY = 100;

function connectToQueryString(OriginalComponent) {
  class ConnectToQueryStringEnhancedComponent extends Component {
    constructor(props) {
      super(props);
      this.timeout = null;
      this._loadRequiredData = this._loadRequiredData.bind(this);
      this._hasInitialFiltersDataInProps = this._hasInitialFiltersDataInProps.bind(this);
      this.state = { filtersDataLoaded: false, currentPathName: "" };
    }

    componentDidMount() {
      this._loadRequiredData();
    }

    componentDidUpdate(prevProps) {
      if (this.state.filtersDataLoaded) { // if all needed data are loaded from server
        if (!this.props.guestPage.isHydrating) { // if is filling state from url q param
          if (this.state.currentPathName === this.props.location.pathname) { // Action didn't change
            clearTimeout(this.timeout);
            this.timeout = setTimeout(
              () => {
                const nextSearch = `?q=${buildSearchQueryFromState(this.props)}&page=${this.props.guestPage.currentPage}utf8=✓`;
                if (prevProps.location.search != nextSearch) {
                  this.props.history.replace(nextSearch);
                }
              },
              START_SEARCH_DELAY
            );
          } else { // New action and new view
            this.setState({ currentPathName: this.props.location.pathname });
            this._fillStateFromSearchQuery(this.props);
          }
        }
      } else { // Needed data from server still not loaded
        if (this._hasInitialFiltersDataInProps(this.props)) {
          this.setState({ filtersDataLoaded: true });
          this._fillStateFromSearchQuery(this.props);
        }
      }
    }

    componentWillUnmount() {
      this.setState({ filtersDataLoaded: false });
    }

    _fillStateFromSearchQuery(nextProps) {
      const query = querystring.parse(nextProps.location.search.substring(1));
      const currentPage = parseInt(query.page) || 1;
      this.props.fillStateFromSearchQuery(cleanUpQueryString(query.q), currentPage);
    }

    _loadRequiredData() {
      const { match, fetchEventGuestFields, fetchLabels, importGuestCategories, fetchAccesspoints, fetchThematics } = this.props;
      fetchEventGuestFields(match.params.event_id, { search: true });
      fetchLabels(match.params.event_id);
      importGuestCategories();
      fetchAccesspoints(match.params.event_id);
      fetchThematics(match.params.event_id);
    }

    _hasInitialFiltersDataInProps(props) {
      const { guestCategories, accesspoints, guestFieldsFetched, thematics } = props;
      return guestCategories.fetched && accesspoints.fetched && guestFieldsFetched && thematics.items;
    }

    render() {
      if (!this.state.filtersDataLoaded) return <Loader />;

      return (
        <OriginalComponent { ...this.props }/>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      acceptedAtAccesspointIds: state.acceptedAtAccesspointIds,
      accesspoints: state.accesspoints,
      checkedInAtAccesspointIds: state.checkedInAtAccesspointIds,
      emailActivities: state.emailActivities,
      expectedAtAccesspointIds: state.expectedAtAccesspointIds,
      guestCategories: state.guestCategories,
      guestFields: state.guestFields.guestFields,
      guestFieldsFetched: state.guestFields.fetched,
      guestPage: state.guestPage,
      labels: state.labels,
      registrationDate: state.registrationDate,
      rejectedAtAccesspointIds: state.rejectedAtAccesspointIds,
      selectedEngagementLevels: state.selectedEngagementLevels,
      selectedGuestCategoryIds: state.selectedGuestCategoryIds,
      selectedGuestLabelIds: state.selectedGuestLabelIds,
      selectedGuests: state.guests.selectedGuests,
      selectedGuestSortOptions: state.selectedGuestSortOptions,
      selectedGuestStatuses: state.selectedGuestStatuses,
      selectedSSOStatuses: state.selectedSSOStatuses,
      selectedQuartilesIdsInGuestsThematicsQuartiles: state.selectedQuartilesIdsInGuestsThematicsQuartiles,
      selectedThematicsIdsInGuestsThematicsQuartiles: state.selectedThematicsIdsInGuestsThematicsQuartiles,
      selectedPaymentStatuses: state.selectedPaymentStatuses,
      selectedShowedUpStatuses: state.selectedShowedUpStatuses,
      selectedThematicIds: state.selectedThematicIds,
      selectedOptins: state.selectedOptins,
      simpleSearchQuery: state.simpleSearchQuery,
      thematics: state.thematics
    };
  }

  const mapDispatchToProps = {
    fillStateFromSearchQuery,
    fetchEventGuestFields,
    fetchLabels,
    importGuestCategories,
    fetchAccesspoints,
    fetchThematics
  };

  return connect(mapStateToProps, mapDispatchToProps)(withRouter(ConnectToQueryStringEnhancedComponent));
}

export default connectToQueryString;
