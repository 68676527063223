import { CALL_API, ActionTypes, ENGAGEMENT_ACTIONS } from "../constants/Constants";
import { Guest } from "../types/Guest";
import { ThematicScoring } from "../types/ThematicScoring";

export function fetchHistoryItemsWithEndpoint(endpoint: string, initialCall = true, options: any = {}): any {
  const { FETCH_HISTORY_ITEMS_REQUEST, FETCH_HISTORY_ITEMS_SUCCESS, FETCH_HISTORY_ITEMS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_HISTORY_ITEMS_REQUEST, FETCH_HISTORY_ITEMS_SUCCESS, FETCH_HISTORY_ITEMS_FAILURE],
      method: "GET",
      endpoint,
      body: options
    },
    resetHistoryItems: initialCall
  };
}

export function fetchGuestHistoryItems(guest: Guest, initialCall = true, options: any = {}): any {
  const endpoint = `/api/v1/events/${guest.event_id}/guests/${guest._id}/history_items.json`;
  return fetchHistoryItemsWithEndpoint(endpoint, initialCall, options);
}

export function fetchGuestEngagementHistoryItems(guest: Guest, initialCall = true): any {
  return fetchGuestHistoryItems(guest, initialCall, { filtered_actions: ENGAGEMENT_ACTIONS });
}

export function fetchThematicScoringHistoryItems(thematicScoring: ThematicScoring, initialCall = true): any {
  const endpoint = `/api/v1/events/${thematicScoring.event_id}/thematic_scorings/${thematicScoring._id}/history_items.json`;
  return fetchHistoryItemsWithEndpoint(endpoint, initialCall);
}

export function clearHistoryItems(): any {
  return { type: ActionTypes.CLEAR_HISTORY_ITEMS };
}