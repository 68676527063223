import { Component, ChangeEvent, FormEvent } from "react";
import { SegmentPicker } from "../containers/shared/SegmentPicker.react";
import { Website } from "../types/Website";
import { SavedSearch } from "../types/SavedSearch";
import { Event } from "../types/Event";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { pathToWebsiteConsentNotice, pathToWebsiteConfig } from "../utils/pathUtils";
import WebsiteFormSaveButton from "./WebsiteFormSaveButton.react";
import HelpSection from "./shared/HelpSection.react";

type WebsiteGdpr = Pick<Website, "legitimate_interest_segment_id" | "third_party_analytic_tool_used" | "third_party_analytic_cookies_title" |
                                "third_party_analytic_tool_rational" | "required_cookies_title" | "required_cookies_rational" |
                                "consent_banner_text" | "consent_banner_link" | "consent_banner_enabled">;

interface Props extends WebsiteGdpr {
  onSubmit: (data: State) => void;
  savedSearches: SavedSearch[];
  event: Event;
  isPendingRequest: boolean;
  website: Website;
  showTranslationPane?: () => void;
}

type State = WebsiteGdpr

function i18n(key, opts = {}): string {
  return I18n.t(`react.gdpr.${key}`, opts);
}

class WebsiteGdprConfigForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    [
      "onSubmit",
      "onTextChange",
      "onTextAreaChange",
      "onCheckBoxChange",
      "onSavedSearchesSelect"
    ].forEach(fn => (this[fn] = this[fn].bind(this)));

    const { legitimate_interest_segment_id, third_party_analytic_tool_used, third_party_analytic_tool_rational, required_cookies_rational,
      third_party_analytic_cookies_title, required_cookies_title, consent_banner_enabled, consent_banner_text, consent_banner_link } = props;

    this.state = {
      legitimate_interest_segment_id,
      third_party_analytic_tool_used,
      third_party_analytic_cookies_title,
      third_party_analytic_tool_rational,
      consent_banner_enabled,
      consent_banner_text,
      consent_banner_link,
      required_cookies_title,
      required_cookies_rational
    };
  }

  onTextChange(e: ChangeEvent<HTMLInputElement>): void {
    const { target } = e;
    const { name: key, value } = target;
    this.setState({ [key]: value } as Pick<State, "third_party_analytic_cookies_title" | "consent_banner_link" | "required_cookies_title">);
  }

  onCheckBoxChange(e: ChangeEvent<HTMLInputElement>): void {
    const { target } = e;
    const { name: key, checked: value } = target;
    this.setState({ [key]: value } as Pick<State, "third_party_analytic_tool_used" | "consent_banner_enabled">);
  }

  onTextAreaChange(e: ChangeEvent<HTMLTextAreaElement>): void {
    const { target } = e;
    const { name: key, value } = target;
    this.setState({ [key]: value } as Pick<State, "third_party_analytic_tool_rational" | "consent_banner_text" | "required_cookies_rational">);
  }

  onSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { legitimate_interest_segment_id, third_party_analytic_tool_used, third_party_analytic_cookies_title, third_party_analytic_tool_rational,
      required_cookies_title, required_cookies_rational, consent_banner_enabled, consent_banner_text, consent_banner_link } = this.state;

    onSubmit({
      legitimate_interest_segment_id,
      third_party_analytic_tool_used,
      third_party_analytic_cookies_title,
      third_party_analytic_tool_rational,
      required_cookies_title,
      required_cookies_rational,
      consent_banner_enabled,
      consent_banner_text,
      consent_banner_link
    });
  }

  onSavedSearchesSelect(savedSearch: SavedSearch): void {
    this.setState({ legitimate_interest_segment_id: savedSearch ? savedSearch._id : null });
  }

  renderInput(type: string, label: string, value: any, name: string, onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void): JSX.Element {
    return (
      <div className="mb-3" key={name}>
        <label htmlFor={name} className="form-label">{label}</label>
        <input
          type={type}
          className="form-control"
          placeholder={label}
          value={value || ""}
          name={name}
          id={name}
          onChange={onChangeHandler}
        />
      </div>
    );
  }

  renderCheckbox(label: string, value: boolean, name: string, onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void): JSX.Element {
    return (
      <div key={name}>
        <div className="form-check">
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input" checked={value} name={name} onChange={onChangeHandler}/>
            {label}
          </label>
        </div>
      </div>
    );
  }

  renderTextArea(label: string, value = "", name: string, onChangeHandler: (e: ChangeEvent<HTMLTextAreaElement>) => void, help?: JSX.Element): JSX.Element {
    return (
      <div className="mb-3" key={name}>
        <label htmlFor={name} className="form-label">{label}</label>
        <textarea name={name} value={value || ""} onChange={onChangeHandler} className="form-control" rows={4} style={{ resize: "none" }} />
        <div className="form-text">{help}</div>
      </div>
    );
  }

  renderSavedSearchSelect(): JSX.Element {
    const { legitimate_interest_segment_id } = this.state;
    const { savedSearches } = this.props;

    return (
      <div>
        <SegmentPicker onSelectSegment={this.onSavedSearchesSelect} defaultSegmentId={legitimate_interest_segment_id} segments={savedSearches} />
      </div>
    );
  }

  renderMarketingCookiesSection(): JSX.Element[] {
    const { third_party_analytic_tool_used, third_party_analytic_cookies_title, third_party_analytic_tool_rational } = this.state;

    if (!third_party_analytic_tool_used) return;

    return [
      <h2 key="marketing-cookies" className="mt-3">{ i18n("marketing_cookies") }</h2>,
      this.renderHelp(i18n("marketing_cookies_help")),
      this.renderInput("text", i18n("cookies_banner_title"), third_party_analytic_cookies_title, "third_party_analytic_cookies_title", this.onTextChange),
      this.renderTextArea(i18n("cookies_banner_desc"), third_party_analytic_tool_rational, "third_party_analytic_tool_rational", this.onTextAreaChange)
    ];
  }

  renderHelp(message: string, extraClass?: string): JSX.Element {
    return <HelpSection key={`help-${message}`} help={message} classNames={extraClass} />;
  }

  renderConfigurationBlock(): JSX.Element {
    const { third_party_analytic_tool_used, required_cookies_title, required_cookies_rational,
      consent_banner_enabled, consent_banner_text, consent_banner_link } = this.state;
    const { event, showTranslationPane } = this.props;

    return (
      <div>
        <div className="header-page">
          <div className="header-page-title">
            <h1>
              <a href={pathToWebsiteConfig()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></a>
              {i18n("settings")}
            </h1>
          </div>
        </div>

        { this.renderMigrateToDidomiNotice() }

        <div className="card mb-10">
          <div className="card-body">
            <label className="form-label">{ i18n("why_consent_needed") }</label>
            <p>{ i18n("reason_consent_needed") }</p>
            <div className="no-margin">
              { this.renderCheckbox(i18n("third_party_analytic_tool_used"), third_party_analytic_tool_used, "third_party_analytic_tool_used", this.onCheckBoxChange) }
            </div>
          </div>
        </div>

        <div className="card mb-10">
          <div className="card-header">
            <h4 className="card-title">
              { i18n("consent_banner") + " " }
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip-bar-step">{ i18n("consent_banner_help") }</Tooltip>}>
                <i className="fa-regular fa-info-circle" aria-hidden="true"/>
              </OverlayTrigger>
            </h4>
          </div>
          <div className="card-body">
            { this.renderTextArea(i18n("consent_banner_text"), consent_banner_text, "consent_banner_text", this.onTextAreaChange) }
            <div className="no-margin">
              { this.renderInput("text", i18n("consent_banner_link"), consent_banner_link, "consent_banner_link", this.onTextChange) }
            </div>
          </div>
        </div>

        <div className="card mb-10">
          <div className="card-header">
            <h4 className="card-title">{ i18n("tracking_options_window") }</h4>
          </div>
          <div className="card-body">
            { this.renderHelp(i18n("tracking_options_window_help"), "mb-0") }
          </div>
        </div>

        <div className="card mb-10">
          <div className="card-header">
            <h4 className="card-title">
              { i18n("functional_cookies") + " " }
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip-bar-step">{ i18n("functional_cookies_help") }</Tooltip>}>
                <i className="fa-regular fa-info-circle" aria-hidden="true"/>
              </OverlayTrigger>
            </h4>
          </div>
          <div className="card-body">
            { this.renderInput("text", i18n("cookies_banner_title"), required_cookies_title, "required_cookies_title", this.onTextChange) }
            { this.renderTextArea(i18n("cookies_banner_desc"), required_cookies_rational, "required_cookies_rational", this.onTextAreaChange) }
            <div className="form-text">{ event.scoring_and_engagement_enabled ? i18n("scoring_enabled") : i18n("scoring_disabled") }</div>
            { this.renderMarketingCookiesSection() }
          </div>
        </div>

        <div className="card mb-10">
          <div className="card-header">
            <h4 className="card-title">
              { i18n("legitimate_interest_segment") + " " }
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip-bar-step">{ i18n("legitimate_interest_segment_help") }</Tooltip>}>
                <i className="fa-regular fa-info-circle" aria-hidden="true"/>
              </OverlayTrigger>
            </h4>
          </div>
          <div className="card-body">
            { this.renderSavedSearchSelect() }
          </div>
        </div>

        <div className="card mb-10">
          <div className="card-header">
            <h4 className="card-title"><span className="text-danger">Danger</span> Zone</h4>
          </div>
          <div className="card-body">
            { this.renderHelp(i18n("consent_banner_enabled_help")) }
            { this.renderCheckbox(i18n("consent_banner_enabled"), consent_banner_enabled, "consent_banner_enabled", this.onCheckBoxChange) }
            { showTranslationPane && (
              <div className="mt-3"><i className="fa-regular fa-language"></i> <a href="#" onClick={showTranslationPane}>{i18n("translate")}</a></div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderMigrateToDidomiNotice(): JSX.Element {
    if (!this.props.website.can_setup_new_consent_notice) return null;

    return (
      <div className="alert alert-info">
        {i18n("migrate_to_new_consent_notice")} <a href={pathToWebsiteConsentNotice({ migratingFromLegacyNotice: true })}>{i18n("i_migrate")}</a>
      </div>
    );
  }

  render(): JSX.Element {
    return (
      <form onSubmit={this.onSubmit}>
        <fieldset>
          {this.renderConfigurationBlock()}
          <WebsiteFormSaveButton
            pendingRequest={this.props.isPendingRequest}
          />
        </fieldset>
      </form>
    );
  }
}

export default WebsiteGdprConfigForm;
