import { useContext } from "react";
import { enabledByDefault, featureSetTranslation, featureMetadata } from "../../utils/featureSetUtils";
import { isSuperAdmin } from "../../utils/aclUtils";
import Icons from "../../constants/Icons";
import { FeatureDeckContext } from "../../containers/features/FeatureSetFormContainer.react";

interface Props {
  featureKey: string;
}

function click(featureKey: string, context: any): void {
  const { featureBeingEnabled, featureAlreadyEnabled, featureBeingDisabled, hasDisabledDependencies, toggleFeature, displayDependenciesModal, displayDisablingModal } = context;

  if (isSuperAdmin() && featureAlreadyEnabled(featureKey) && !featureBeingDisabled(featureKey)) {
    displayDisablingModal(featureKey);
  } else if (!featureBeingEnabled(featureKey) && hasDisabledDependencies(featureKey)) {
    displayDependenciesModal(featureKey);
  } else {
    toggleFeature(featureKey);
  }
}

const featureActionButton: React.FC<Props> = ({ featureKey }) => {
  const context = useContext(FeatureDeckContext);
  const { featureBeingToggled, featureBeingEnabled, featureAlreadyEnabled } = context;
  const metadata = featureMetadata(featureKey);
  const isAdmin = isSuperAdmin();
  const canCancel = featureBeingToggled(metadata.key);
  const isFeatureDisabled = (featureAlreadyEnabled(metadata.key) && isAdmin) || featureBeingEnabled(metadata.key);

  let label = "enable";
  if (canCancel) {
    label = "cancel";
  } else if (isFeatureDisabled) {
    label = "disable";
  }

  let icon = <i className="ml-5 fa-regular fa-plus"></i>;
  if (canCancel) {
    icon = <i className="ml-5 fa-regular fa-arrows-rotate"></i>;
  } else if (isFeatureDisabled) {
    icon = <i className="ml-5 fa-regular fa-xmark"></i>;
  }

  if (enabledByDefault(metadata.key)) return <span className="label-status d-flex flex-column justify-content-center pe-0">{featureSetTranslation("enabled_by_default")}</span>;

  if (featureAlreadyEnabled(metadata.key) && !isAdmin) {
    return <span className="label-status d-flex flex-column justify-content-center">{featureSetTranslation("enabled")}</span>;
  }

  if (metadata && metadata.features) {
    return <a href="#" className="see-all">{ featureSetTranslation("see_all") } <span className="ml-5">{ Icons.arrowIcon() }</span></a>;
  }

  return <div key={ metadata.key }>
    <button
      type="button"
      className={ `btn btn-sm ${isFeatureDisabled ? "btn-outline-primary" : "btn-primary"}` }
      onClick={ (): void => {
        click(metadata.key, context);
      } }>
      { I18n.t(label) }
      { icon }
    </button>
  </div>;
};

export default featureActionButton;
