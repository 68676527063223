import { Component } from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import moment from "moment";
import filter from "lodash/filter";
import get from "lodash/get";
import truncate from "lodash/truncate";
import isEmpty from "lodash/isEmpty";
import "moment/locale/fr";
import querystring from "querystring";
import { withRouter } from "react-router-dom";

import ReportHeader from "../../components/event_reports/ReportHeader.react";
import ReportTable from "../../components/event_reports/ReportTable.react";
import Loader from "../../components/shared/Loader.react";
import SubmitInputButton from "../../components/shared/SubmitInputButton.react";
import { eventDates } from "../../utils/DateUtils";

import {
  fetchGuestsStatistics,
  selectValuesGuestStatistics,
  clearGuestStatistics
} from "../../actions/GuestsStatisticsActionCreators";
import { fetchThematics } from "../../actions/ThematicsActionCreators";

import { createReportConfiguration } from "../../actions/ReportConfigurationsActionCreators";

import { importGuestCategories } from "../../actions/ImportGuestCategoryActionCreators";
import { fetchEventGuestFields } from "../../actions/GuestFieldsActionCreators";

import { attendanceDerivedData, computeTotal, emptyStat } from "../../selectors/guestsStatisticsSelector";
import { redirectIfUnauthorized, isAuthorized } from "../../utils/aclUtils";
import { LazyCalculatedType, ValueList } from "../../constants/Constants.js";
import { isEnabled } from "../../utils/featureSetUtils";
import { CHECKIN } from "../../constants/FeaturesSet";

const DATE_FORMAT = "LL";
const API_DATE_FORMAT = "YYYY-MM-DD";
const ALL_DAYS_STATS_IF_NUMBER_OF_DAYS_LTE = 7;

const URL_SAVABLE_STATES = ["statsField", "statsDate", "overallStats", "query", "columns", "eventToCompareId"]; //these states values will be saved in the URL
const EXTRA_ACCESSIBLE_FIELDS = ["payment_promo_code", "utm_source", "utm_campaign", "utm_medium", "country_name", "population"];
const COLUMNNS_REQUIRING_CHECKIN = ["new_visits", "returning", "visits", "no_shows", "transformation_rate"];
const DEFAULT_COLUMNS = ["_id", "count", "count_percent"].concat(COLUMNNS_REQUIRING_CHECKIN);

class AttendanceByField extends Component {

  constructor(props) {
    redirectIfUnauthorized("reports", "read");
    super(props);
    [
      "onDateChangeHandler",
      "onFieldChange",
      "onQueryChange",
      "fieldPrettyName",
      "displayOverallStats",
      "onFieldValuesChange",
      "removeColumn",
      "addColumn",
      "createReportConfiguration",
      "saveAnotherReportConfiguration",
      "onEventToCompareChange"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    moment.locale(I18n.currentLocale());

    const query = querystring.parse(props.location.search.substring(1));

    this.state = {
      // stats options
      statsField: query.field || query.statsField,
      statsDate: query.statsDate ? new Date(query.statsDate) : this.defaultDate(props),
      overallStats: query.overallStats ? query.overallStats === "true" : false,
      fieldValuesIds: null,
      query: query.query,
      columns: query.columns ? query.columns.split(",") : this.filteredColumns(),

      // other
      reportConfigurationSaved: false,
      eventToCompare: null,
      eventToCompareId: query.eventToCompareId || ""
    };

    const idNameMapping = this.idNameMapping();

    // this need to be done after initial state is set as most of the method require this.state
    this.state = { ...this.state,
      formattedStats: this.formatStats(),
      idNameMapping,
      items: this.items(props, { idNameMapping })
    };
  }

  componentDidMount() {
    const { guestFields, fetchEventGuestFields } = this.props;
    if (guestFields.length === 0) {
      const { match } = this.props;
      fetchEventGuestFields(match.params.event_id);
    }
    this.fetchStatistics();
  }

  comparedEventThematics(props = this.props) {
    const { eventToCompareId } = this.state;
    const { otherEventsThematics } = props;

    if (!eventToCompareId) return null;
    if (!otherEventsThematics[eventToCompareId]) return null;
    return otherEventsThematics[eventToCompareId].items;
  }

  filteredColumns(columns = DEFAULT_COLUMNS) {
    return columns.filter(column => !COLUMNNS_REQUIRING_CHECKIN.includes(column) || isEnabled(CHECKIN));
  }

  receivingOtherEventThematics(props, nextProps) {
    const { eventToCompareId } = this.state;
    if (!nextProps.otherEventsThematics[eventToCompareId]) return false;

    return !this.comparedEventThematics(props) && this.comparedEventThematics(nextProps);
  }

  componentDidUpdate(prevProps, prevState) {
    const refresh = ["statsField", "statsDate", "overallStats", "query", "eventToCompare"].find(key => prevState[key] !== this.state[key]);

    if (refresh || prevProps.event != this.props.event) {
      this.fetchStatistics();
    }
    this.saveStateInUrl(prevProps);

    const { event, reportConfigurations, stats, guestCategories, thematics } = this.props;

    if (!prevProps.event._id && event._id) {
      if (!this.state.statsDate) {
        this.setState(this.defaultDate(this.props));
      }
    }

    // new stats incoming
    if (prevProps.stats != stats || this.receivingOtherEventThematics(prevProps, this.props)) {
      let nextState = {};
      nextState.formattedStats = this.formatStats(this.props);
      nextState.idNameMapping = this.idNameMapping(this.props);
      nextState.items = this.items(this.props, nextState);

      const { fieldValuesIds } = this.state;
      if (!fieldValuesIds) {
        nextState.fieldValuesIds = this.itemsInStatsIds(this.props);
      }
      this.setState(nextState);
    }

    // received guestCategories or thematics for idNameMapping
    if (prevProps.guestCategories.length < guestCategories.length || !prevProps.thematics && thematics) {
      let nextState = {};
      nextState.formattedStats = this.formatStats(this.props);
      nextState.idNameMapping = this.idNameMapping(this.props);
      nextState.items = this.items(this.props, nextState);
      this.setState(nextState);
    }

    if (prevProps.reportConfigurations.data.length < reportConfigurations.data.length) {
      this.setState({ reportConfigurationSaved: true });
    }
  }

  itemsInStatsIds(props = this.props) {
    const { statsField } = this.state;
    const { event, guestStatistics } = props;
    const fieldStats = get(guestStatistics, [event._id, statsField].join("."), undefined);
    if (!fieldStats) {
      return [];
    }
    const { data } = fieldStats[Object.keys(fieldStats)[0]]; // getting item of the first dates
    return data.map(i => i._id);
  }

  items(props = this.props, state = this.state) {
    return this.itemsInStatsIds(props).map(id => {
      const identifier = state.idNameMapping[id] || id || "";
      const properties = identifier.props;
      if (properties) {
        return { id, name: properties.children, color: properties.style.backgroundColor };
      }
      return { id, name: identifier };
    });
  }

  // event handlers
  onFieldValuesChange(fieldValuesIds) {
    const { selectValuesGuestStatistics } = this.props;
    selectValuesGuestStatistics(fieldValuesIds);
    this.setState({ fieldValuesIds });
  }

  onFieldChange(field) {
    this.props.clearGuestStatistics();
    this.setState({
      statsField: field[0],
      fieldValuesIds: null
    }); // FilterDropdown will return an array
  }

  onDateChangeHandler(date) {
    return () => {
      this.setState({
        statsDate: date,
        overallStats: false
      });
    };
  }

  onQueryChange(segment) {
    const nextQuery = segment ? segment.search_query : null;
    if (nextQuery === this.state.query) {
      return;
    }
    this.props.clearGuestStatistics();
    this.setState({
      query: nextQuery,
      fieldValuesIds: null // clear field filter as it may not be valid in the stats of the new segment
    });
  }

  removeColumn(type) {
    this.setState({
      columns: this.state.columns.filter(col => col !== type)
    });
  }

  addColumn(type) {
    this.setState({
      columns: [...this.state.columns, type].sort((a, b) => this.filteredColumns().indexOf(a) - this.filteredColumns().indexOf(b))
    });
  }

  // helpers
  saveStateInUrl(prevProps) {
    const { location, history } = this.props;

    const query = querystring.parse(location.search.substring(1));
    const nextQuery = { ...query };
    URL_SAVABLE_STATES.forEach(key => {
      if (![null, undefined].includes(this.state[key])) {
        nextQuery[key] = this.state[key].toString();
      }
    });
    const nextLocation = { ...location };
    nextLocation.search = `?${querystring.stringify(nextQuery)}`;
    if (prevProps.location.search !== nextLocation.search) {
      history.replace(nextLocation);
    }
  }

  defaultDate(props = this.props) {
    const { event } = props;
    return event.start_date ? new Date(event.start_date) : null;
  }

  fetchStatistics() {
    const { event } = this.props;
    const { statsDate, eventToCompare } = this.state;
    this.fetchEventStatistics(event, statsDate);

    if (eventToCompare) {
      const relativeDate = this.eventToCompareRelativeDate();
      if (!this.fetchEventStatistics(eventToCompare, relativeDate)) {
        // no data were fetched but we still want to set formattedState has relativeDate may not be valid
        this.setState({
          formattedStats: this.formatStats()
        });
      }
    } else {
      this.setState({
        formattedStats: this.formatStats()
      });
    }
  }

  fetchEventStatistics(event, date) {
    const { statsField, overallStats, query } = this.state;
    if (!statsField || (!date && !overallStats) || !event._id) {
      return false;
    }

    const dates = overallStats ? eventDates(event) : [date];
    const missingDates = dates.filter(date => {
      return !this.hasStats(event, statsField, moment(date).format(API_DATE_FORMAT));
    });

    if (missingDates.length === 0) {
      return false;
    }

    const { fetchGuestsStatistics } = this.props;

    dates.forEach(d => {
      const fDate = moment(d).format(API_DATE_FORMAT);
      fetchGuestsStatistics(event._id, {
        field: statsField,
        stat_types: "count,new_visits,returning",
        date: fDate,
        q: `include_takeout ${query || ""}`
      });
    });
    return true;
  }

  hasStats(event, field, date) {
    const { stats } = this.props;
    return get(stats, [event._id, field, date].join("."), undefined) !== undefined;
  }

  displayOverallStats() {
    this.setState({
      overallStats: true
    });
  }

  eventToCompareRelativeDate() {
    const { statsDate, eventToCompare } = this.state;
    const currentEventDates = eventDates(this.props.event).map(d => moment(d).format(API_DATE_FORMAT));
    const date = moment(statsDate).format(API_DATE_FORMAT);
    const dayNumber = currentEventDates.findIndex(d => d === date);
    const datesEventToCompare = eventDates(eventToCompare);
    return datesEventToCompare[dayNumber];
  }

  onEventToCompareChange(eventToCompare) {
    const { statsField } = this.state;

    if (statsField === "thematic_ids" && eventToCompare) {
      const { fetchThematics, otherEventsThematics } = this.props;
      if (!otherEventsThematics[eventToCompare._id]) {
        fetchThematics(eventToCompare._id, {}, eventToCompare._id);
      }
    }

    this.setState({
      eventToCompare,
      eventToCompareId: eventToCompare ? eventToCompare._id : ""
    });
  }

  idNameMapping(props = this.props) {
    const { statsField, idNameMapping } = this.state;

    switch (statsField) {
    case "guest_category_id": {
      const { guestCategories, importGuestCategories } = props;
      if (guestCategories.length === 0) {
        importGuestCategories();
        return idNameMapping || {};
      }
      return guestCategories.reduce((acc, category) => {
        return { ...acc, [category.id]: (
          <span className="badge rounded-pill" style={{ backgroundColor: category.label_color }}>
            { truncate(category.name, { "length": 40 }) }
          </span>
        ) };
      }, idNameMapping || {});
    }
    case "thematic_ids": {
      const { fetchThematics, thematics, thematicsPendingRequest, match } = props;

      if (!thematics) {
        if (!thematicsPendingRequest) fetchThematics(match.params.event_id);
        return idNameMapping || {};
      }

      return thematics.reduce((acc, thematic) => {
        return { ...acc, [thematic._id]: (
          <span className="badge rounded-pill" style={{ backgroundColor: thematic.color }}>
            { truncate(thematic.name, { "length": 40 }) }
          </span>
        ) };
      }, idNameMapping || {});
    }
    default:
      return idNameMapping || {};
    }
  }

  guestField() {
    const { guestFields, statsField } = this.props;

    return guestFields.find(field => { return field.key === statsField; });
  }

  fieldPrettyName() {
    const { statsField } = this.state;
    switch (statsField) {
    case null:
      return "";
    case "guest_category_id":
      return I18n.t("react.event_reports.event_reports.guest_category");
    case "thematic_ids":
      return I18n.t("react.event_reports.event_reports.thematics");
    case "green_access_control_ids":
      return I18n.t("react.event_reports.event_reports.accesspoint");
    default: {
      const guestField = this.guestField();
      return guestField ? guestField.label : statsField;
    }
    }
  }

  fieldSelectorItems() {
    const { guestFields } = this.props;
    const items = guestFields.map(field => {
      return { key: field.key, name: field.key };
    });
    EXTRA_ACCESSIBLE_FIELDS.forEach(function(key) {
      items.push({ key: key, name: key });
    });
    return items;
  }

  dateDropdownRows() {
    const { event } = this.props;
    if (!event) {
      return null;
    }

    const dates = eventDates(this.props.event);
    const rows = dates.map(date => {
      const m = moment(date);
      return <Dropdown.Item key={date} onClick={this.onDateChangeHandler(date)}>{ m.format(DATE_FORMAT) }</Dropdown.Item>;
    });
    if (dates.length < ALL_DAYS_STATS_IF_NUMBER_OF_DAYS_LTE) {
      rows.push(<Dropdown.Divider key="divider" />);
      rows.push(<Dropdown.Item key="cumul" onClick={this.displayOverallStats}>{ I18n.t("react.event_reports.event_reports.all_days_stats") }</Dropdown.Item>);
    }
    return rows;
  }

  dateDropdownTitle() {
    const { statsDate, overallStats } = this.state;
    return overallStats ? I18n.t("react.event_reports.event_reports.all_days_stats") : `${moment(statsDate).format(DATE_FORMAT)}` || I18n.t("react.event_reports.event_reports.pick_day");
  }

  createReportConfiguration(name) {
    const { query, statsField, columns, overallStats, eventToCompareId } = this.state;
    const { createReportConfiguration, event } = this.props;
    const params = {
      name,
      search_query: query,
      field: statsField,
      columns,
      overall: overallStats,
      event_to_compare_id: eventToCompareId
    };

    createReportConfiguration(event.account_id, params);
  }

  renderHeader() {
    const query = querystring.parse(this.props.location.search.substring(1));
    const { statsField, columns, formattedStats, idNameMapping, eventToCompareId } = this.state;

    const { event_id } = this.props.match.params;

    const title = query.field ? `${I18n.t("react.event_reports.event_reports.attendance_by")} ${this.fieldPrettyName()}` : I18n.t("react.event_reports.event_reports.attendance_by");
    const fieldSelectorProps = query.field ? null : { items: this.fieldSelectorItems(), onChangeField: this.onFieldChange, title: this.fieldPrettyName() };
    const exporterToExcelProps = statsField ? { data: formattedStats, columns: this.filteredColumns(columns), options: { mapping: idNameMapping, fieldPrettyName: this.fieldPrettyName } } : null;
    const segmentPickerProps = { onChangeQuery: this.onQueryChange, query: query.query, event_id };
    const dateDropDownProps = { title: this.dateDropdownTitle(), menuItemRows: this.dateDropdownRows() };

    return (
      <ReportHeader
        title={title}
        fieldSelectorProps={fieldSelectorProps}
        exporterToExcelProps={exporterToExcelProps}
        dateDropDownProps={dateDropDownProps}
        segmentPickerProps={segmentPickerProps}
        pickerForEventComparison={statsField && statsField !== "guest_category_id"}
        onEventToCompareChange={this.onEventToCompareChange}
        eventToCompareId={eventToCompareId}
        eventId={event_id}
      />
    );
  }

  renderLoader() {
    return <Loader />;
  }

  formatStats(props = this.props) {
    const { stats, guestStatistics, event } = props;
    if (!event._id || isEmpty(stats)) {
      return null;
    }

    const { overallStats, statsField, statsDate, eventToCompare } = this.state;
    let data = get(stats, [event._id, statsField].join("."), undefined);
    if (!data) {
      return null;
    }

    if (overallStats) {
      data = data.all_days;
      if (!data) {
        return null;
      }
      if (data.dates.length != eventDates(event).length) {
        return null;
      }
    } else {
      const date = moment(statsDate).format(API_DATE_FORMAT);
      data = data[date];
      if (!data) {
        return null;
      }
    }

    if (eventToCompare) {
      let dataToCompare = null;

      // stats are filtered on current event thematic_ids and filter remove all compared event lines because thematic_ids are not selected.
      // Anyway, no need to filter on compared event stats if no total displayed
      const baseStats = statsField === "thematic_ids" ? guestStatistics : stats;

      if (overallStats) {
        const dataRoot = get(baseStats, [eventToCompare._id, statsField].join("."), undefined);
        if (dataRoot && dataRoot.all_days && dataRoot.all_days.dates.length === eventDates(eventToCompare).length) {
          dataToCompare = dataRoot.all_days;
        }
      } else {
        const relativeDate = this.eventToCompareRelativeDate();
        if (relativeDate) {
          const relativeDateStr = moment(relativeDate).format(API_DATE_FORMAT);
          dataToCompare = get(baseStats, [eventToCompare._id, statsField, relativeDateStr].join("."), null);
        }
      }

      if (dataToCompare && dataToCompare.data) {
        const comparedData = data.data.map(line => {
          return this.findDataToCompare(line, dataToCompare.data, props) || { ...emptyStat, _id: line._id, count_percent: 0 };
        });
        data.comparedData = comparedData;
        data.comparedTotal = computeTotal(comparedData); // total must be recomputed as some data may not be comparable
      } else {
        data.comparedData = null;
        data.comparedTotal = null;
      }
    } else {
      data.comparedData = null;
      data.comparedTotal = null;
    }

    return data;
  }

  findDataToCompare(line, dataToCompare, props = this.props) {
    const { statsField } = this.state;

    if (statsField !== "thematic_ids") {
      return dataToCompare.find(l => l._id === line._id);
    }

    const { thematics } = this.props;
    const thematic = thematics.find(th => th._id === line._id);
    if (!thematic) return;

    const comparedThematics = this.comparedEventThematics(props);
    if (!comparedThematics) return;

    const comparedThematic = comparedThematics.find(th => th.name === thematic.name);
    if (!comparedThematic) return;

    return dataToCompare.find(l => l._id === comparedThematic._id);
  }

  renderReportTable() {
    const { statsField, statsDate, overallStats, fieldValuesIds, query, columns, formattedStats, idNameMapping, items } = this.state;
    const { thematics } = this.props;

    if (!statsField || (!statsDate && !overallStats)) {
      return null;
    }

    if (!formattedStats) {
      return this.renderLoader();
    }

    if (statsField === "thematic_ids" && !thematics) {
      return this.renderLoader();
    }

    const { guestFields } = this.props;
    if (guestFields.length === 0) {
      return this.renderLoader();
    }
    const guestField = this.guestField();
    const isMultiValuesField = guestField && guestField.type === ValueList && guestField.allow_multiple_values;

    return (
      <ReportTable
        overallStats={overallStats}
        field={this.fieldPrettyName(statsField)}
        fieldKey={statsField}
        fieldIsMultiValuesField={isMultiValuesField}
        stats={formattedStats.data}
        total={formattedStats.total}
        formattedStatsDate = {overallStats ? null : moment(statsDate).format("DD-MM-YYYY")}
        idNameMapping={idNameMapping}
        onChange={this.onFieldValuesChange}
        items={items}
        fieldValuesIds={fieldValuesIds || []}
        query={query}
        columns={this.filteredColumns(columns)}
        hiddenColumns={this.filteredColumns().filter(col => !columns.includes(col))}
        removeColumn={this.removeColumn}
        addColumn={this.addColumn}
        comparedStats={formattedStats.comparedData || []}
        comparedTotal={formattedStats.comparedTotal}
      />
    );
  }

  saveAnotherReportConfiguration(e) {
    e.preventDefault();
    this.setState({
      reportConfigurationSaved: false
    });
  }

  renderSaveReportConfiguration() {
    const { reportConfigurations } = this.props;
    const { createPending, createErrors } = reportConfigurations;
    return (
      <div className="row">
        <div className="col-md-3 offset-md-9">
          {this.state.reportConfigurationSaved ? (
            <p className="text-end">
              <i className="fa-regular fa-check text-success"></i> {I18n.t("react.event_reports.attendance_by_field.report_saved")}<br />
              <a href="#" onClick={this.saveAnotherReportConfiguration}>({I18n.t("react.event_reports.attendance_by_field.save_another_report")})</a>
            </p>
          ) : (
            <SubmitInputButton
              className="btn btn-secondary float-end"
              submitInputButtonTitle={I18n.t("react.event_reports.attendance_by_field.create")}
              submitButtonClassName="btn btn-primary"
              inputPlaceholder={I18n.t("react.event_reports.attendance_by_field.report_name")}
              onSubmit={this.createReportConfiguration}
              loading={createPending}
              disabled={createPending}
              error={createErrors && createErrors.name}>
              <i className="fa-regular fa-plus"></i> {I18n.t("react.event_reports.attendance_by_field.save_report")}
            </SubmitInputButton>
          )}
        </div>
      </div>
    );
  }

  render() {
    if (!isAuthorized("reports", "read")) return null;

    return (
      <div>
        { this.renderHeader() }
        { this.renderReportTable() }
        { this.renderSaveReportConfiguration() }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    stats: attendanceDerivedData(state),
    guestStatistics: state.guestsStatistics,
    guestCategories: state.guestCategories.data,
    guestFields: filter(state.guestFields.guestFields, field => field.type != LazyCalculatedType),
    event: state.event,
    thematics: state.thematics.items,
    otherEventsThematics: state.thematics.otherEvents,
    thematicsPendingRequest: state.thematics.fetching,
    reportConfigurations: state.reportConfigurations
  };
}

const mapDispatchToProps = {
  clearGuestStatistics,
  fetchGuestsStatistics,
  importGuestCategories,
  fetchEventGuestFields,
  selectValuesGuestStatistics,
  createReportConfiguration,
  fetchThematics
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AttendanceByField));
