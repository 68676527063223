import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../components/shared/Loader.react";
import { pathToReport } from "../../utils/pathUtils";
import { eventDates } from "../../utils/DateUtils";
import { redirectIfUnauthorized, isAuthorized } from "../../utils/aclUtils";
import { fetchReportConfigurations, deleteReportConfiguration } from "../../actions/ReportConfigurationsActionCreators";
import { isEnabled, anyEnabled } from "../../utils/featureSetUtils";
import { EXHIBITOR_INVITATIONS, CHECKIN, PAYMENT, ADVANCED_REPORTING, SCHEDULE, EXHIBITORS, EXHIBITOR_LEADS,
  EXHIBITOR_MEETINGS, SCHEDULED_1TO1_MEETING, VISIT_ROUTE,
  ONE_TO_ONE_CHAT_AND_CONNECTIONS, WEBINAR, VIDEO_CONFERENCE, STREAMING } from "../../constants/FeaturesSet";

const ALL_DAYS_STATS_IF_NUMBER_OF_DAYS_LTE = 5;

class EventReports extends Component {

  constructor(props) {
    redirectIfUnauthorized("reports", "read");
    super(props);
    [
      "deleteReportConfigurationHandler"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.event.id && this.props.event.id) {
      this.fetchReportConfigurations(this.props);
    }
  }

  componentDidMount() {
    this.fetchReportConfigurations();
  }

  fetchReportConfigurations(props = this.props) {
    const { event, fetchReportConfigurations } = props;
    if (event.account_id) {
      fetchReportConfigurations(event.account_id);
    }
  }

  i18n(key, opts = {}) {
    return I18n.t(`react.event_reports.event_reports.${key}`, opts);
  }

  deleteReportConfigurationHandler(id) {
    return (e) => {
      e.preventDefault();
      if (confirm(I18n.t("confirm"))) {
        const { event, deleteReportConfiguration } = this.props;
        deleteReportConfiguration(event.account_id, id);
      }
    };
  }

  renderStatsList() {
    const links = [
      { name: this.i18n("attendance_by_population"), path: "attendance_by_field?field=population" },
      { name: this.i18n("attendance_by_guest_category"), path: "attendance_by_field?field=guest_category_id" },
      { name: this.i18n("attendance_by_other_fields"), path: "attendance_by_field" },
      { name: this.i18n("attendance_by_accesspoint"), path: "attendance_by_accesspoint" },
    ];
    links.push({ name: this.i18n("attendance_by_thematic"), path: "attendance_by_field?field=thematic_ids" });

    const { event } = this.props;
    if (eventDates(event).length < ALL_DAYS_STATS_IF_NUMBER_OF_DAYS_LTE) {
      links.unshift({ name: this.i18n("attendance_summary"), path: "attendance_summary" });
    }

    return this.renderSection(this.i18n("attendance"), "users", links);
  }

  renderSavedReportsList() {
    const { reportConfigurations } = this.props;
    const links = reportConfigurations.data.map(conf => {
      return {
        name: conf.name,
        path: conf.report_by_accesspoint ?
          `attendance_by_accesspoint?${conf.search_query || ""}`
          : `attendance_by_field?field=${conf.field}&columns=${conf.columns.join(",")}&query=${conf.search_query || ""}&overallStats=${conf.overall}&eventToCompareId=${conf.event_to_compare_id || ""}`,
        destroyable: true,
        id: conf._id
      };
    });

    return this.renderSection(this.i18n("saved_reports"), "user", links);
  }

  renderSection(title, faIcon, reports) {
    const children = reports.map(report => {
      return (
        <li key={report.name}>
          <Link to={pathToReport(report.path)} href="#">{ report.name }</Link>
          {report.destroyable && (
            <a href="#" onClick={this.deleteReportConfigurationHandler(report.id)} className="text-small"><i className="fa-regular fa-trash-can" style={{ marginLeft: "5px" }}></i></a>
          )}
        </li>
      );
    });
    return (
      <div>
        <div className="subtitle-page">
          <div className="subtitle-page-title">
            <h2 className="d-flex flex-row"><i className={`fa-regular fa-${faIcon} d-flex flex-column justify-content-center mr-5`}></i> { title }</h2>
          </div>
        </div>
        <ul className="pl-15 mb-0">
          { children }
        </ul>
      </div>
    );
  }

  sessionsAndMeetingsSections() {
    const sections = [];

    sections.push({ name: this.i18n("sessions_by_number_of_interests"), path: "sessions_by_number_of_interests" });

    if (anyEnabled([EXHIBITOR_MEETINGS, SCHEDULED_1TO1_MEETING])) {
      sections.push({ name: this.i18n("meetings"), path: "meetings_by_number_of_appointments" });
    }
    return sections;
  }

  exhibitorsSections() {
    const exhibitorsSections = [];

    if (isEnabled(EXHIBITOR_INVITATIONS)) {
      exhibitorsSections.push({ name: this.i18n("guest_invitation_sent_stats"), path: "guest_invitation_sent_stats" });
      exhibitorsSections.push({ name: this.i18n("guest_promo_code_invitation_stats"), path: "guest_promo_code_invitation_stats" });
    }

    if (isEnabled(EXHIBITORS)) {
      exhibitorsSections.push({ name: this.i18n("exhibitors_by_number_of_interests"), path: "exhibitors_by_number_of_interests" });
    }

    if (isEnabled(EXHIBITOR_LEADS)) {
      exhibitorsSections.push({ name: this.i18n("exhibitors_by_number_of_leads"), path: "exhibitors_by_number_of_leads" });
    }

    if (isEnabled(VISIT_ROUTE)) {
      exhibitorsSections.push({ name: this.i18n("visit_route"), path: "visit_route" });
    }

    exhibitorsSections.push({ name: this.i18n("guests_by_number_of_information_requests"), path: "guests_by_number_of_information_requests" });

    return exhibitorsSections;
  }

  render() {
    if (!isEnabled(ADVANCED_REPORTING)) return null;
    if (!isAuthorized("reports", "read")) return null;

    const { event, reportConfigurations } = this.props;
    if (Object.keys(event).length === 0) {
      return <Loader />;
    }

    const showExhibitorsSections = this.exhibitorsSections().length > 0;

    return (
      <div className="event-reports">
        <div className="header-page">
          <div className="header-page-title">
            <h1>{ this.i18n("standard_reports") }</h1>
          </div>
        </div>

        <div className="card col-12">
          <div className="row g-0">
            { reportConfigurations.data.length > 0 && (
              <div className="card-body col-lg-4 col-md-6">
                { this.renderSavedReportsList() }
              </div>
            )}
            <div className="card-body col-lg-4 col-md-6">
              { this.renderStatsList() }
            </div>
            { isEnabled(CHECKIN) && (
              <div className="card-body col-lg-4 col-md-6">
                { this.renderSection(this.i18n("consumption"), "tachometer", [
                  { name: this.i18n("live_sessions"), path: "live_consumption?type=session" },
                  { name: this.i18n("live_meetings"), path: "live_consumption?type=meeting" }
                ]
                ) }
              </div>
            )}
            { isEnabled(PAYMENT) && (
              <div className="card-body col-lg-4 col-md-6">
                { this.renderSection(this.i18n("sales"), "money-bill-1", [
                  { name: this.i18n("by_item"), path: "sales" },
                  { name: this.i18n("by_cart_items"), path: "cart_items_sales" }
                ]) }
              </div>
            )}
            { isEnabled(SCHEDULE) && (
              <div className="card-body col-lg-4 col-md-6">
                { this.renderSection(this.i18n("sessions_and_meetings"), "calendar", this.sessionsAndMeetingsSections()) }
              </div>
            )}
            { showExhibitorsSections && (
              <div className="card-body col-lg-4 col-md-6">
                { this.renderSection(this.i18n("exhibitors"), "briefcase", this.exhibitorsSections()) }
              </div>
            )}
            <div className="card-body col-lg-4 col-md-6">
              { this.renderSection(this.i18n("products"), "cart-plus", [
                { name: this.i18n("guest_products_by_number_of_interests"), path: "guest_products_by_number_of_interests" },
                { name: this.i18n("guest_products_by_number_of_information_requests"), path: "guest_products_by_number_of_information_requests" }
              ])}
            </div>
            { anyEnabled([ONE_TO_ONE_CHAT_AND_CONNECTIONS, WEBINAR, VIDEO_CONFERENCE, STREAMING]) &&
              (<div className="card-body col-lg-4 col-md-6">
                { this.renderSection(this.i18n("messages"), "comments", [
                  { name: this.i18n("messages_sent_on_this_event"), path: "messages" }
                ])}
              </div>)
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    event: state.event,
    reportConfigurations: state.reportConfigurations
  };
}

const mapDispatchToProps = {
  fetchReportConfigurations,
  deleteReportConfiguration
};

export default connect(mapStateToProps, mapDispatchToProps)(EventReports);
