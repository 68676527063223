import { Component } from "react";

class Table extends Component {
  render() {
    const { headers, children } = this.props;
    const thead = headers.map(header =>
      <th key={header} >{ header }</th>
    );
    return (
      <div className="table-responsive table-container">
        <table className="table table-light table-bordered table-hover">
          <thead>
            <tr>
              { thead }
            </tr>
          </thead>
          <tbody>
            { children }
          </tbody>
        </table>
      </div>
    );
  }
}

export default Table;
