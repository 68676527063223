import { useState, MouseEvent, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateBadgeTemplate } from "../../actions/BadgeTemplateActionCreator";
import { urlEventId } from "../../utils/pathUtils";
import usePrevious from "../../utils/hooks/usePrevious";
import { registerFont } from "./utils";

const NEW_CUSTOM_FONT = "newCustomFont";

interface Props {
  isOpen: boolean;
  badgeTemplateId: string;

  toggleModal(): void;
}

function i18n(key, opts = {}): string {
  return I18n.t(`react.badge_builder.badge_font_upload_modal.${key}`, opts);
}

const BadgeFontUploadModal: React.FC<Props> = ({
  isOpen,
  badgeTemplateId,
  toggleModal,
}) => {
  const [fontFile, setFontFile] = useState(null);
  const [fontName, setFontName] = useState("");
  const [dragging, setDragging] = useState(false);

  const isSaving = useSelector((state: any) => state.badgeTemplate.isSaving);
  const previousIsSaving = usePrevious(isSaving);

  const dispatch = useDispatch();

  useEffect(() => {
    if (previousIsSaving && !isSaving) {
      toggleModal();
    }
  }, [isSaving]);

  const getGuestCategoryId = (): string => {
    const params = new URL(document.location.toString()).searchParams;
    return params.get("guest_category_id");
  };

  const submit = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    if (fontFile) {
      const data = new FormData();
      data.append("badge_template[badge_fonts_attributes][0][font]", fontFile);
      dispatch(updateBadgeTemplate(urlEventId(), getGuestCategoryId(), badgeTemplateId, (data as any), true));
    }
  };

  const loadFontFile = (file): void => {
    if (!file) return;

    setFontFile(file);

    const reader = new FileReader();
    reader.onload = (e): void => {
      registerFont(NEW_CUSTOM_FONT, e.target.result, () => {
        setFontName(file.name);
      });
    };
    reader.readAsArrayBuffer(file);
  };

  const handleDragOver = (e): void => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (): void => {
    setDragging(false);
  };

  const handleDrop = (e): void => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    if (file && file.type.match("font/*")) {
      loadFontFile(file);
    }
  };

  return <Modal show={ isOpen } onHide={ toggleModal } size="lg" className="badge-builder">
    <Modal.Header>
      <Modal.Title>{ i18n("modal_title") }</Modal.Title>
      <button type="button" onClick={ toggleModal } className="btn-close" aria-label={ I18n.t("close") }></button>
    </Modal.Header>

    <form className="bagde-font-upload-modal">
      <Modal.Body
        className={ `${dragging ? "dragging" : ""}` }
        onDragOver={ handleDragOver }
        onDragLeave={ handleDragLeave }
        onDrop={ handleDrop }
      >
        <div style={{ textAlign: "center", width: "80%" }}>
          <input
            type="file"
            accept=".otf,.ttf,.woff"
            onChange={ ({ target }): void => loadFontFile(target.files[0]) }
            style={{ display: "none" }}
            id="fontFileInput"
          />
          <label htmlFor="fontFileInput" className="file-upload-label" dangerouslySetInnerHTML={{ __html: i18n("choose_custom_font_for_upload") }}></label>
          <small className="text-muted">{ i18n("authorized_formats") }</small>

          {fontName && <>
            <div className="font-preview" style={{ fontFamily: NEW_CUSTOM_FONT }}>
              {fontName} - Sample text preview
            </div>
          </>}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={toggleModal}>
          { I18n.t("cancel") }
        </Button>
        <Button variant={"primary"} type="submit" onClick={submit} disabled={!fontFile || isSaving}>
          { i18n("upload") }
        </Button>
      </Modal.Footer>
    </form>
  </Modal>;
};

export default BadgeFontUploadModal;
