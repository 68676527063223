import { CALL_API, ActionTypes, TEMP_ID_PREFIX, FormItemGrouped, DecoratingFormItem, FormItemsDefault, FormItemsWithText, FormItemsWithFile, FormItemsWithValueList } from "../constants/Constants";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";
import cloneDeep from "lodash/cloneDeep";

// utils
function isPersistedId(itemId) {
  return itemId && !itemId.startsWith(TEMP_ID_PREFIX);
}

function makeNestedParamsReadbleByAPI(params) {
  if (!params.form_item_options) {
    return params;
  }

  const tmpParams = cloneDeep(params);
  const formItemOptions = tmpParams.form_item_options.slice();
  delete tmpParams.form_item_options;
  const newParams = Object.assign({}, tmpParams, { form_item_options_attributes: formItemOptions });

  //might have created temporary id, destroy them
  newParams.form_item_options_attributes = newParams.form_item_options_attributes.map(option => {
    if (option._id && !isPersistedId(option._id)) {
      delete option["_id"];
    }
    ["rank_before_id", "rank_after_id"].forEach((key) => {
      delete option[key];
    });
    return option;
  });
  return newParams;
}

function mergeUpdateParams(formItem, params) {
  const newFormItem = Object.assign({}, formItem);

  // remove image and image_url properties when not uploading otherwise server will try to upload something wrong
  delete newFormItem.image;
  delete newFormItem.image_url;

  //handle form_item_options
  if (params.form_item_options) {
    // add id to form item options
    const newFormItemOptionsWithId = params.form_item_options.map((option, index) => {
      if (!option._id) {
        return Object.assign({}, option, { _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}${index}` });
      }
      return option;
    });

    // merge new form item options with existing ones
    let newFormItemOptions = (formItem.form_item_options || []).slice();
    newFormItemOptionsWithId.forEach((option) => {
      newFormItemOptions = insertOrUpdateObject(newFormItemOptions, option, "_id");
    });
    newFormItem.form_item_options = newFormItemOptions;
  }

  const paramsWithoutFormItemOptions = Object.assign({}, params);
  delete paramsWithoutFormItemOptions.form_item_options;
  return Object.assign(newFormItem, paramsWithoutFormItemOptions);
}

function destroyFormItemOptionsWithDestroyFlag(formItem) {
  if (!formItem.form_item_options) {
    return formItem;
  }
  let formItemOptions = [];
  formItem.form_item_options.forEach((option) => {
    if (!option._destroy) {
      formItemOptions.push(option);
    }
  });
  return Object.assign({}, formItem, { form_item_options: formItemOptions });
}

// check wether a formItem is persistable or not (valid?). Returns [true/fasle, errorMessage]
function isPersistable(formItem) {
  if (!formItem.type) {
    return [false, { type: I18n.t("react.registration_form.error_missing_type") }]; //can not happen, but safety first
  }

  if (DecoratingFormItem.indexOf(formItem.type) >= 0) {
    return [true, null];
  }

  let errors = { key: [], options: [] };

  const exists = (str) => {
    return str != null && str != undefined && str != "";
  };
  if (FormItemGrouped.indexOf(formItem.type) == -1) {
    //simple item, can be persisted if it has a key and a label
    if (!exists(formItem.key)) {
      errors["key"].push(I18n.t("react.registration_form.error_missing_guest_field"));
    }
  } else {
    // grouped item, need to have a key in its options
    if (!formItem.form_item_options || formItem.form_item_options.length == 0) {
      errors["options"].push(I18n.t("react.registration_form.error_missing_option"));
    } else {
      const optionsWithoutRequiredData = formItem.form_item_options.find(option => {
        return !exists(option.key) || !exists(option.label);
      });

      if (optionsWithoutRequiredData) {
        errors["options"].push(I18n.t("react.registration_form.error_missing_label_or_field_in_option"));
      }
    }
  }

  var filteredErrors = {};
  Object.keys(errors).map((key) => {
    if (errors[key].length > 0) {
      filteredErrors[key] = errors[key];
    }
  });

  if (Object.keys(filteredErrors).length > 0) {
    return [false, filteredErrors];
  } else {
    return [true, null];
  }
}

// actual actions

export function newRegistrationFormItem(type, formSectionId, registrationFormId, formItem = {}) {
  const defaults = FormItemsDefault[type];
  const newFormItem = Object.assign({}, defaults, { type }, { form_section_id: formSectionId }, formItem);
  const [persistable, error] = isPersistable(newFormItem);
  if (persistable) {
    return createRegistrationFormItem(registrationFormId, newFormItem);
  }
  return {
    type: ActionTypes.REGISTRATION_FORM_ITEM_NEW,
    formItem: Object.assign({}, newFormItem, { error })
  };
}

export function createRegistrationFormItem(registrationFormId, formItem, commitForTemporaryId = null) {
  const { REGISTRATION_FORM_ITEM_CREATE_REQUEST, REGISTRATION_FORM_ITEM_CREATE_SUCCESS, REGISTRATION_FORM_ITEM_CREATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [REGISTRATION_FORM_ITEM_CREATE_REQUEST, REGISTRATION_FORM_ITEM_CREATE_SUCCESS, REGISTRATION_FORM_ITEM_CREATE_FAILURE],
      method: "POST",
      body: { form_item: makeNestedParamsReadbleByAPI(formItem) },
      endpoint: `/api/v1/registration_forms/${registrationFormId}/form_items.json`
    },
    commitForTemporaryId,
    rollback: formItem
  };
}

export function changeRegistrationFormItemType(registrationFormId, formItem, newType, locale = "en", keepGuestField = false) {
  const isFormItemWithValueList = FormItemsWithValueList.includes(formItem.type) && FormItemsWithValueList.includes(newType);
  const hasACountryKey = formItem.key === "country";

  let paramsToKeep = {
    label: formItem.label,
    rank: formItem.rank,
    rank_before_id: formItem.rank_before_id,
    rank_after_id: formItem.rank_after_id
  };
  // not necessary to change key
  if (
    formItem.key != "" && (
      FormItemsWithText.includes(formItem.type) && FormItemsWithText.includes(newType) ||
      FormItemsWithFile.includes(formItem.type) && FormItemsWithFile.includes(newType) ||
      isFormItemWithValueList || hasACountryKey || keepGuestField)
  ) {
    paramsToKeep["key"] = formItem.key;
  }
  const newAction = newRegistrationFormItem(newType, formItem.form_section_id, registrationFormId, paramsToKeep);

  if (isPersistedId(formItem._id)) {
    return destroyRegistrationFormItem(registrationFormId, formItem, locale, newAction);
  }
  return [destroyRegistrationFormItem(registrationFormId, formItem, locale), newAction];
}

export function updateRegistrationFormItem(registrationFormId, formItem, newParams, sendFile = false, optimistic = false) {
  const newFormItem = mergeUpdateParams(formItem, newParams);
  const { REGISTRATION_FORM_ITEM_UPDATE_REQUEST, REGISTRATION_FORM_ITEM_UPDATE_SUCCESS, REGISTRATION_FORM_ITEM_UPDATE_FAILURE } = ActionTypes;
  //basic update with API call
  if (isPersistedId(newFormItem._id)) {
    const action = {
      [CALL_API]: {
        types: [REGISTRATION_FORM_ITEM_UPDATE_REQUEST, REGISTRATION_FORM_ITEM_UPDATE_SUCCESS, REGISTRATION_FORM_ITEM_UPDATE_FAILURE],
        method: "PUT",
        body: sendFile ? newParams : { form_item: makeNestedParamsReadbleByAPI(newFormItem) },
        sendFile: sendFile,
        endpoint: `/api/v1/registration_forms/${registrationFormId}/form_items/${newFormItem._id}.json`
      },
      formItemId: newFormItem._id,
      optimistic, //if set we suppose the update will succeed in the API and allow the reducer to merge the params when the request starts
      rollback: newFormItem
    };
    if (optimistic) {
      return Object.assign({}, action, { params: newFormItem });
    }
    return action;
  }

  // not persisted and not persistable
  const formItemReadyForCreateOrLocalUpadte = destroyFormItemOptionsWithDestroyFlag(newFormItem);

  const [persistable, error] = isPersistable(newFormItem);

  if (!persistable) {
    return {
      type: REGISTRATION_FORM_ITEM_UPDATE_SUCCESS,
      data: Object.assign({}, formItemReadyForCreateOrLocalUpadte, { _id: newFormItem._id, error }),
      keepErrors: true
    };
  }

  // not persisted and persistable, create
  return createRegistrationFormItem(registrationFormId, formItemReadyForCreateOrLocalUpadte, newFormItem._id);
}

export function destroyRegistrationFormItem(registrationFormId, formItem, locale = "en", nextAction = null) {
  const { REGISTRATION_FORM_ITEM_DESTROY_REQUEST, REGISTRATION_FORM_ITEM_DESTROY_SUCCESS, REGISTRATION_FORM_ITEM_DESTROY_FAILURE } = ActionTypes;
  if (!isPersistedId(formItem._id)) {
    return {
      type: REGISTRATION_FORM_ITEM_DESTROY_SUCCESS,
      formItem
    };
  }
  return {
    [CALL_API]: {
      types: [REGISTRATION_FORM_ITEM_DESTROY_REQUEST, REGISTRATION_FORM_ITEM_DESTROY_SUCCESS, REGISTRATION_FORM_ITEM_DESTROY_FAILURE],
      method: "DELETE",
      endpoint: `/api/v1/registration_forms/${registrationFormId}/form_items/${formItem._id}.json?locale=${locale}`,
      nextAction
    },
    formItem
  };
}

export function createFormItems(registrationFormId, formItems, locale = "en") {
  const { DEV_NULL, REGISTRATION_FORM_ITEM_BATCH_CREATE_SUCCESS, REGISTRATION_FORM_ITEM_BATCH_CREATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, REGISTRATION_FORM_ITEM_BATCH_CREATE_SUCCESS, REGISTRATION_FORM_ITEM_BATCH_CREATE_FAILURE],
      method: "POST",
      endpoint: `/api/v1/registration_forms/${registrationFormId}/form_items/batch_create.json?locale=${locale}`,
      body: { form_items: formItems }
    }
  };
}

export function destroyRegistrationFormItemOption(registrationFormId, formItemId, formItemOptionId, locale = "en") {
  const { REGISTRATION_FORM_ITEM_OPTION_DESTROY_SUCCESS, REGISTRATION_FORM_ITEM_OPTION_DESTROY_REQUEST, REGISTRATION_FORM_ITEM_OPTION_DESTROY_FAILURE } = ActionTypes;
  if (!isPersistedId(formItemId)) {
    return {
      type: REGISTRATION_FORM_ITEM_OPTION_DESTROY_SUCCESS,
      formItemId,
      formItemOptionId
    };
  }
  return {
    [CALL_API]: {
      types: [REGISTRATION_FORM_ITEM_OPTION_DESTROY_REQUEST, REGISTRATION_FORM_ITEM_OPTION_DESTROY_SUCCESS, REGISTRATION_FORM_ITEM_OPTION_DESTROY_FAILURE],
      method: "DELETE",
      endpoint: `/api/v1/registration_forms/${registrationFormId}/form_item_options/${formItemOptionId}.json?locale=${locale}`
    },
    formItemId,
    formItemOptionId
  };
}

export function toggleRegistrationFormItemMode(formItem) {
  return {
    type: ActionTypes.REGISTRATION_FORM_ITEM_TOGGLE_MODE,
    formItem
  };
}

export function displayError(formItem) {
  return {
    type: ActionTypes.REGISTRATION_FORM_ITEM_DISPLAY_ERROR,
    formItem
  };
}
