import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FabricEditorFunctions, StackingOrder } from "../fabricjs/types";
import { BADGE_IMAGE_POSITIONS } from "./../Constants";
import { fabric } from "fabric";

interface Props {
  editor?: FabricEditorFunctions;
  selectedObjects?: fabric.Object[];
  onDeleteImage(position: string): void;
}

const i18n = (key, opts = {}): string => {
  return I18n.t(`react.badge_builder.toolbar.${key}`, opts);
};

const Toolbar: React.FC<Props> = ({
  editor,
  selectedObjects,
  onDeleteImage
}) => {
  const deleteActiveObject = () => (): void => {
    if (selectedObjects[0]?.type === "image") {
      onDeleteImage(selectedObjects[0].imageKey);
    } else {
      editor?.removeSelectedObject();
    }
  };

  const updateActiveObjectStacking = (action: StackingOrder) => (): void => {
    editor?.updateActiveObjectStacking(action);
  };

  const stackingOrderDisabled = selectedObjects.length !== 1;

  const deletionDisabled = (): boolean => {
    if (selectedObjects.length === 0) return true;
    if (selectedObjects[0].type === "image" && BADGE_IMAGE_POSITIONS.includes(selectedObjects[0].imageKey)) return true;

    return false;
  };

  return <div className="mt-5">
    <OverlayTrigger overlay={<Tooltip id={"remove-selected-object"}>{i18n("delete")}</Tooltip>}>
      <Button variant="secondary" onClick={deleteActiveObject()} disabled={deletionDisabled()}>
        <i className="fa-regular fa-trash-can" aria-hidden="true"/>
      </Button>
    </OverlayTrigger>
    <OverlayTrigger overlay={<Tooltip id={"bring-to-front"}>{i18n("bring_to_front")}</Tooltip>}>
      <Button variant="secondary" className="ml-5" onClick={updateActiveObjectStacking(StackingOrder.BringToFront)} style={{ paddingLeft: "9px", paddingRight: "9px" }} disabled={stackingOrderDisabled}>
        <i className="fa-regular fa-bring-front" aria-hidden="true"/>
      </Button>
    </OverlayTrigger>
    <OverlayTrigger overlay={<Tooltip id={"bring-forward"}>{i18n("bring_forward")}</Tooltip>}>
      <Button variant="secondary" className="ml-5" onClick={updateActiveObjectStacking(StackingOrder.BringForward)} disabled={stackingOrderDisabled}>
        <i className="fa-regular fa-bring-forward" aria-hidden="true"/>
      </Button>
    </OverlayTrigger>
    <OverlayTrigger overlay={<Tooltip id={"send-backward"}>{i18n("send_backward")}</Tooltip>}>
      <Button variant="secondary" className="ml-5" onClick={updateActiveObjectStacking(StackingOrder.SendBackward)} disabled={stackingOrderDisabled}>
        <i className="fa-regular fa-send-backward" aria-hidden="true"/>
      </Button>
    </OverlayTrigger>
    <OverlayTrigger overlay={<Tooltip id={"send-to-back"}>{i18n("send_to_back")}</Tooltip>}>
      <Button variant="secondary" className="ml-5" onClick={updateActiveObjectStacking(StackingOrder.SendToBack)} style={{ paddingLeft: "9px", paddingRight: "9px" }} disabled={stackingOrderDisabled}>
        <i className="fa-regular fa-send-back" aria-hidden="true"/>
      </Button>
    </OverlayTrigger>
  </div>;
};

export default Toolbar;
