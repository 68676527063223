import { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import ContactShow, { ContactShowFromGuestsList } from "./ContactShow.react";
import { clearContactState } from "../../actions/ContactsActionCreators";
import ContactHistory from "./ContactHistory.react";
import ContactActivities from "./ContactActivities.react";
import ContactSso from "./ContactSso.react";

const INFO_TAB = "information";
const HISTORY_TAB = "history";
const ACTIVITIES_TAB = "activities";
const SSO_TAB = "sso";

class ContactModal extends Component {
  constructor(props) {
    super(props);
    [
      "toggleModal",
      "changeModalTab"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      isOpen: false,
      currentTab: INFO_TAB
    };
  }

  toggleModal(e) {
    if (e) e.preventDefault();
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
      currentTab: INFO_TAB
    });

    const { clearStateOnClose, clearContactState } = this.props;
    if (clearStateOnClose && !isOpen) {
      clearContactState();
    }
  }

  changeModalTab(tab) {
    return (e) => {
      e.preventDefault();
      this.setState({
        currentTab: tab
      });
    };
  }

  i18n(key, opts = {}) {
    return I18n.t(`react.contacts.contact_modal.${key}`, opts);
  }

  renderContent() {
    const { currentTab } = this.state;
    switch (currentTab) {
    case HISTORY_TAB:
      return <ContactHistory />;
    case ACTIVITIES_TAB:
      return <ContactActivities />;
    case SSO_TAB:
      return <ContactSso />;
    default:
      return this.renderContactShow();
    }
  }

  renderContactShow() {
    const { event, guests } = this.props;
    if (event && guests && guests.length > 0) {
      return <ContactShowFromGuestsList guests={guests} event={event} />;
    }
    return <ContactShow />;
  }

  renderTab(tab, title) {
    const { currentTab } = this.state;
    const activeClass = currentTab === tab ? "active" : null;
    return (
      <li className={`nav-item ${activeClass}`}>
        <a href="#" className="nav-link" onClick={this.changeModalTab(tab)}>
          { title }
        </a>
      </li>
    );
  }

  render() {
    const { children } = this.props;
    const { isOpen } = this.state;

    return <>
      <span onClick={this.toggleModal}>
        { children }
      </span>
      <Modal show={isOpen} onHide={this.toggleModal}>
        <Modal.Header>
          <Modal.Title>{ this.i18n("title") }</Modal.Title>
          <button type="button" onClick={this.toggleModal} className="btn-close" aria-label={I18n.t("close")}></button>
        </Modal.Header>
        <Modal.Body>
          <ul className="nav nav-tabs mb-10">
            { this.renderTab(INFO_TAB, this.i18n("information")) }
            { this.renderTab(ACTIVITIES_TAB, this.i18n("activity")) }
            { this.renderTab(HISTORY_TAB, this.i18n("history")) }
            { this.renderTab(SSO_TAB, this.i18n("sso"))}
          </ul>
          <div className="row" style={{ overflow: "auto", maxHeight: "70vh" }}>
            <div className="col-md-12">
              { this.renderContent() }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>;
  }
}

ContactModal.propTypes = {
  guests: PropTypes.arrayOf(PropTypes.object),
  event: PropTypes.object,
  clearStateOnClose: PropTypes.bool
};

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {
  clearContactState
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactModal);
