import { ActionTypes } from "../constants/Constants";

function receiverActivities(state = { data: [], nextURL: null, previousURL: null }, action) {
  switch (action.type) {
  case ActionTypes.RECEIVER_ACTIVITIES_SUCCESS:
    return action;
  default:
    return state;
  }
}

export default receiverActivities;
