import { CAMPAIGN_STEPS } from "../constants/Constants";
import { urlWithQuery } from "./pathUtils";

const mandatoryFields = ["name", "from_name", "subject", "reply_to_email"];

export function campaignStepBreadcrumbLinks(guestCampaign, options = {}) {
  if (guestCampaign == null || (options.guestCampaignIsPersisted === false)) {
    return campaignStepWithNoLink();
  }
  return campaignStepWithLinks(guestCampaign, options.searchQuery || "");
}

function campaignStepWithNoLink() {
  return CAMPAIGN_STEPS.map(item => {
    return { label: item.label, url: null };
  });
}

function campaignStepWithLinks(guestCampaign, searchQuery) {
  return CAMPAIGN_STEPS.map(item => {
    //the first link always be available
    let url = urlWithQuery(searchQuery, `campaign/${guestCampaign._id}/${item.url}`);
    if (!guestCampaignValid) {
      url = null;
    }
    return { label: item.label, url: url };
  });
}

function guestCampaignValid(guestCampaign) {
  const valueUndefined = mandatoryFields.find(value => {
    return guestCampaign[value] == null;
  });
  return valueUndefined == null;
}
