"use strict";
import { Component } from "react";
import { connect } from "react-redux";
import { fetchEvents } from "../../actions/EventActionCreators";
import Loader from "../../components/shared/Loader.react";

export default function contactDetailsList(WrappedComponent, mapStateToProps, mapDispatchToProps, i18n) {
  class ContactDetailsList extends Component {
    constructor(props) {
      super(props);
      [
        "loadMore"
      ].forEach(fn => this[fn] = this[fn].bind(this));
    }

    componentDidMount() {
      const { contact, fetchItems, fetchEvents, events } = this.props;

      // this component expect the contact to be in the store
      if (!contact) return;
      if (!events) fetchEvents();
      fetchItems(contact);
    }

    loadMore() {
      const { itemsNextURL, fetchItemsPage } = this.props;
      fetchItemsPage(itemsNextURL);
    }

    eventIdNameMapping() {
      const { events } = this.props;
      if (!events || event.length === 0) return {};
      return events.reduce((acc, event) => Object.assign(acc, { [event._id]: event.title }), {});
    }

    renderNoItem() {
      return <p className="text-center"><em>{ i18n("no_item") }</em></p>;
    }

    renderShowMore() {
      const { itemsNextURL } = this.props;
      if (!itemsNextURL) return null;
      return <p className="text-center" style={{ cursor: "pointer" }} onClick={this.loadMore}>{ i18n("show_more") }</p>;
    }

    render() {
      const { items, fetching } = this.props;

      if (!items || fetching) return <Loader />;
      if (items.length === 0) return this.renderNoItem();

      return <div>
        <WrappedComponent { ...this.props } eventIdNameMapping={this.eventIdNameMapping()} />
        { this.renderShowMore() }
      </div>;
    }
  }

  function finalMapStateToProps(state) {
    let finalState = mapStateToProps(state);
    finalState.contact = state.contact.data; // this component expects the contact to be in the store
    finalState.events = state.events.data;
    return finalState;
  }

  const finalMapDispatchToProps = Object.assign(mapDispatchToProps, {
    fetchEvents
  });

  return connect(finalMapStateToProps, finalMapDispatchToProps)(ContactDetailsList);
}