import { ActionTypes } from "../constants/Constants";

module.exports = {
  setSelectedPaymentStatuses(objectIds) {
    return {
      type: ActionTypes.SET_SELECTED_PAYMENT_STATUSES,
      objectIds
    };
  }

};
