import { Component } from "react";
import FilterDropdown from "../FilterDropdown.react";

class TranslationsSelect extends Component {

  constructor(props) {
    super(props);
    [
      "setAvailableFrontendLocales"
    ].forEach(item => this[item] = this[item].bind(this));

    const { event } = props;
    this.state = {
      availableFrontendLocales: event.available_frontend_locales || []
    };
  }

  componentDidUpdate(prevProps) {
    const { event } = this.props;
    if (event.available_frontend_locales.length !== prevProps.event.available_frontend_locales.length) {
      this.setState({
        availableFrontendLocales: event.available_frontend_locales || []
      });
    }
  }

  setAvailableFrontendLocales(selectedAvailableLocales) {
    const { event, updateEvent } = this.props;
    if (selectedAvailableLocales) this.setState({ availableFrontendLocales: selectedAvailableLocales });
    updateEvent(event._id, { available_frontend_locales: selectedAvailableLocales });
  }

  renderLocalesDropdown() {
    const { availableFrontendLocales } = this.state;
    const { event, frontendLocales } = this.props;

    const translationsLangages = frontendLocales.filter(locale => event && event.locale != locale.code);
    const options = translationsLangages
      .sort((a, b) => {
        return (a[I18n.locale] > b[I18n.locale]) ? 1 : ((b[I18n.locale] > a[I18n.locale]) ? -1 : 0);
      })
      .map(locale => {
        const label = `${locale.flag} ${locale[I18n.locale]} (${locale.code})`;
        return { id: locale.code, label: label };
      });

    return <div className="row">
      <div className="col-md-6">
        <div className="mb-3 d-flex">
          <FilterDropdown
            id="available_frontend_locales"
            items={options}
            selectedItemIds={availableFrontendLocales}
            itemNameKey="label"
            translationKey="available_frontend_locales"
            onChange={this.setAvailableFrontendLocales}
            liveChange={true}
            sortItems={false}
            maxHeight="280px"
          />
        </div>
      </div>
    </div>;
  }

  render() {
    return this.renderLocalesDropdown();
  }
}

export default TranslationsSelect;
