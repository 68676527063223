import { Fragment } from "react";

interface Props {
  text: string;
}

const TextWithLineBreaks: React.FC<Props> = ({ text }) => {
  if (!text) return null;

  const textWithBreaks = text.split("\n").map((text, index) => (
    <Fragment key={index}>
      {text}
      <br />
    </Fragment>
  ));

  return <>{textWithBreaks}</>;
};

export default TextWithLineBreaks;
