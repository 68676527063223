import { Component } from "react";
import { connect } from "react-redux";
import withModal from "../withModal.react";
import { updateUI } from "../../actions/UIAppearanceActionCreators";

import SendDirectEmail from "./SendDirectEmail.react";

class BatchDirectEmailModal extends Component {
  componentDidMount() {
    const { updateUI } = this.props;
    updateUI({
      modalTitle: I18n.t("react.reports.send_direct_email")
    });
  }

  render() {
    return (
      <SendDirectEmail {...this.props} />
    );
  }
}

const mapDispatchToProps = {
  updateUI
};

export default connect(null, mapDispatchToProps)(withModal(BatchDirectEmailModal));
