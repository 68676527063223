import { ActionTypes } from "../constants/Constants";
import { SessionsStatisticsState } from "../types/SessionStatistics";

const defaultState: SessionsStatisticsState = {
  errors: null,
  isFetching: false,
  byNumberOfBookmarks: null
};

export function sessionsStatistics(state = defaultState, action: any): SessionsStatisticsState {
  switch (action.type) {
  case ActionTypes.FETCH_SESSIONS_STATISTICS_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.FETCH_SESSIONS_STATISTICS_SUCCESS:
    return { ...state, byNumberOfBookmarks: action.data, isFetching: false, errors: null };
  case ActionTypes.FETCH_SESSIONS_STATISTICS_FAILURE:
    return { ...state, byNumberOfBookmarks: [], isFetching: false, errors: action.data };
  default:
    return state;
  }
}

export default sessionsStatistics;
