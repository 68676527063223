import { OverlayTrigger, Button } from "react-bootstrap";
import { addTooltip } from "../../../utils/templatesBuilderUtils";
import { Alignment } from "../fabricjs/types";

interface Props {
  topValue: string;
  leftValue: string;
  heightValue: string;
  widthValue: string;
  sizeIsReadOnly: boolean;
  positionIsReadOnly: boolean;
  alignmentIsReadOnly: boolean;
  handleChange: (property: string) => (e: any) => void;
}

const i18n = (key, opts = {}): string => {
  return I18n.t(`react.badge_builder.properties_panel.${key}`, opts);
};

const targetValueEvent = (value: any): any => {
  return {
    target: { value }
  };
};

const PositionProperties: React.FC<Props> = ({
  topValue,
  leftValue,
  heightValue,
  widthValue,
  sizeIsReadOnly,
  positionIsReadOnly,
  alignmentIsReadOnly,
  handleChange
}) => {

  const renderButtonWithTooltip = (tooltip: string, icon: string, onClick: () => void, disabled = false): JSX.Element => {
    return <OverlayTrigger placement="bottom" overlay={addTooltip(i18n(`align_${tooltip}`))}>
      <Button variant="secondary" onClick={onClick} disabled={disabled}>
        <i className={`fa-regular fa-${icon}`} aria-hidden="true" />
      </Button>
    </OverlayTrigger>;
  };

  const renderInputText = (label: string, key: string, value: string, property: string, disabled = false): JSX.Element => {
    return <div className={`input-group ${disabled && "disabled"}`}>
      <div className="input-group-text">{label} :</div>
      <input
        type="text"
        id={key}
        className="form-control"
        value={value}
        onChange={handleChange(property)}
        disabled={disabled}
      />
    </div>;
  };

  const updateAlignment = (value: string): void => {
    handleChange("alignment")(targetValueEvent(value));
  };

  const renderAlignment = (): JSX.Element => {
    return <div className="row align-items-center">
      <label className="col-3">{i18n("alignment")}</label>
      <div className="col-9">
        <div className="btn-group">
          { renderButtonWithTooltip("left", "objects-align-left", (): void => updateAlignment(Alignment.HLeft), alignmentIsReadOnly) }
          { renderButtonWithTooltip("center_horizontal", "objects-align-center-horizontal", (): void => updateAlignment(Alignment.HCenter), alignmentIsReadOnly) }
          { renderButtonWithTooltip("right", "objects-align-right", (): void => updateAlignment(Alignment.HRight), alignmentIsReadOnly) }
          { renderButtonWithTooltip("top", "objects-align-top", (): void => updateAlignment(Alignment.VTop), alignmentIsReadOnly) }
          { renderButtonWithTooltip("center_vertical", "objects-align-center-vertical", (): void => updateAlignment(Alignment.VCenter), alignmentIsReadOnly) }
          { renderButtonWithTooltip("bottom", "objects-align-bottom", (): void => updateAlignment(Alignment.VBottom), alignmentIsReadOnly) }
        </div>
      </div>
    </div>;
  };

  const renderPosition = (): JSX.Element => {
    return <div className="row align-items-center">
      <label className="col-3">{i18n("position")}</label>
      <div className="col-9 d-flex gap-2">
        { renderInputText("X", "leftValue", leftValue, "left", positionIsReadOnly) }
        { renderInputText("Y", "topValue", topValue, "top", positionIsReadOnly) }
      </div>
    </div>;
  };

  const renderSize = (): JSX.Element => {
    return <div className="row align-items-center">
      <label className="col-3">{i18n("size")}</label>
      <div className="col-9 d-flex gap-2">
        { renderInputText("W", "widthValue", widthValue, "width", sizeIsReadOnly) }
        { renderInputText("H", "heightValue", heightValue, "height", sizeIsReadOnly) }
      </div>
    </div>;
  };

  return <div className="d-flex flex-column gap-3">
    <label className="form-label">{i18n("position")}</label>
    {renderAlignment()}
    {renderPosition()}
    {renderSize()}
  </div>;
};

export default PositionProperties;
