import { Component } from "react";
import classNames from "classnames";
import { addSection, translatedTextFromJson } from "../../utils/templatesBuilderUtils";
import { isEnabled } from "../../utils/featureSetUtils";
import FeatureSetFormContainerPane from "../../components/features/FeatureSetFormContainerPane.react";
import { Collapse } from "react-bootstrap";
import HelpSection from "../shared/HelpSection.react";

class SectionsTypesList extends Component {
  constructor(props) {
    super(props);
    [
      "hideSectionsList",
      "toggleFeatureSetFormContainerPane",
      "toggleDeprecatedGroup"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      isFeatureSetFormContainerPaneOpen: false,
      isDeprecatedGroupOpen: false
    };
  }

  toggleFeatureSetFormContainerPane() {
    const { isFeatureSetFormContainerPaneOpen } = this.state;
    this.setState({ isFeatureSetFormContainerPaneOpen: !isFeatureSetFormContainerPaneOpen });
  }

  hideSectionsList() {
    this.props.hideComponent();
  }

  addNewSection(sectionTypeKey) {
    return () => {
      const { sections, sectionTypes, createNewSection, showEditSection, newSectionAddedTo, event } = this.props;
      const newSectionId = addSection(sectionTypeKey, sections, sectionTypes, createNewSection, event.locale, newSectionAddedTo);
      showEditSection(newSectionId);
      this.hideSectionsList();
    };
  }

  sortedGroupKeys() {
    const { sectionsGroups } = this.props;

    return Object.keys(sectionsGroups).sort((a, b) => {
      return sectionsGroups[a].rank - sectionsGroups[b].rank;
    });
  }

  // if groupKey is null, it will return all section types
  sortedSectionsTypesForGroup(groupKey = null) {
    const { sectionTypes } = this.props;

    return sectionTypes.filter((sectionType) => this.shouldDisplaySection(sectionType, groupKey))
      .sort((a, b) => a.schema.group_rank - b.schema.group_rank);
  }

  shouldDisplaySection(sectionType, groupKey) {
    const { layoutStaticSections } = this.props;

    if (groupKey && sectionType.schema.group_key != groupKey) return false;
    if (sectionType.schema.hidden_from_user) return false;
    if (!this.match_required_features(sectionType)) return false;

    return !layoutStaticSections.includes(sectionType.filename);
  }

  match_required_features(sectionType) {
    if (!sectionType.schema.required_features || sectionType.schema.required_features.length === 0) return true;

    if (sectionType.schema.require_all_features === false) {
      return sectionType.schema.required_features.some(featureKey => isEnabled(featureKey));
    } else {
      return sectionType.schema.required_features.every(featureKey => isEnabled(featureKey));
    }
  }

  toggleDeprecatedGroup() {
    const { isDeprecatedGroupOpen } = this.state;
    this.setState({ isDeprecatedGroupOpen: !isDeprecatedGroupOpen });
  }

  renderDeprecatedGroupInfo() {
    return <HelpSection help={I18n.t("react.builders.deprecated_helper")} />;
  }

  renderSectionsGroups() {
    const { sectionsGroups } = this.props;
    const { isDeprecatedGroupOpen } = this.state;
    const sortedGroupKeys = this.sortedGroupKeys();

    // if website has no section groups, we still have to display all section types
    if (sortedGroupKeys.length == 0)
      return this.renderSectionsGroup(this.sortedSectionsTypesForGroup());

    return sortedGroupKeys.map((key) => {
      const sectionTypesForGroup = this.sortedSectionsTypesForGroup(key);
      if (sectionTypesForGroup.length == 0) return;

      if (key === "deprecated") {
        return (
          <div id={`sections-group-${key}`} key={`sections-group-${key}`} className="mb-20">
            <h5 style={{ cursor: "pointer" }} onClick={this.toggleDeprecatedGroup}>
              {translatedTextFromJson(sectionsGroups[key], "name")} <i className={`ml-5 fa-regular fa-angle-${isDeprecatedGroupOpen ? "up" : "down"}`}></i>
            </h5>
            <Collapse in={isDeprecatedGroupOpen}>
              <div>
                {this.renderDeprecatedGroupInfo()}
                {this.renderSectionsGroup(sectionTypesForGroup)}
              </div>
            </Collapse>
          </div>
        );
      }

      return (
        <div id={`sections-group-${key}`} key={`sections-group-${key}`}>
          <h5>{ translatedTextFromJson(sectionsGroups[key], "name") }</h5>
          { this.renderSectionsGroup(sectionTypesForGroup) }
        </div>
      );
    });
  }

  renderSectionsGroup(sectionTypesForGroup) {
    return (
      <div className="card">
        <ul className="list-group">
          { sectionTypesForGroup.map((sectionType, index) => {
            return this.renderSectionType(sectionType, index);
          })}
        </ul>
      </div>
    );
  }

  renderSectionType(sectionType, index) {
    return (
      <li key={index} className="list-group-item" onClick={ this.addNewSection(sectionType.filename) }>
        <i className={ classNames(sectionType.schema.icon, "mr-10") }></i>
        { translatedTextFromJson(sectionType.schema, "name") }
      </li>
    );
  }

  render() {
    const { isShown, withSidebar } = this.props;
    const { isFeatureSetFormContainerPaneOpen } = this.state;

    const componentClasses = classNames({
      "website-section-new": true,
      "in": isShown,
      "no-sidebar": !withSidebar
    });

    return (
      <div className={ componentClasses } ref="sectionsList">
        <div className="sidebar-header">
          <p className="lead">
            <a onClick={ this.hideSectionsList } className="btn-back mr-5 text-center"><i className="fa-regular fa-angle-left"></i></a>
            { I18n.t("react.website.new_section") }
          </p>
        </div>

        <div className="sidebar-body">
          {this.renderSectionsGroups()}
          <div className="text-center mb-15">
            <button className="btn btn-secondary" onClick={this.toggleFeatureSetFormContainerPane}>
              <i className="fa-regular fa-grid-2-plus fa-flip-vertical"></i> {I18n.t("shared.global_menu.features")}
            </button>
          </div>
        </div>
        <FeatureSetFormContainerPane
          isOpen={isFeatureSetFormContainerPaneOpen}
          hide={this.toggleFeatureSetFormContainerPane} />
      </div>
    );
  }
}

export default SectionsTypesList;
