import { Component } from "react";
import PropTypes from "prop-types";

class HiddenFieldType extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.state = {
      value: props.defaultValue || ""
    };
  }

  onChange(e) {
    this.setState({
      value: e.target.value
    });
  }

  onBlur() {
    const { updateHandler } = this.props;
    const { value } = this.state;
    updateHandler({
      options: {
        default_value: value
      }
    });
  }

  render() {
    const { value } = this.state;
    const { mode } = this.props;

    if (mode === "edit") {
      return (
        <div className="mb-3 row">
          <div className="col-sm-12">
            <input type="text" className="form-control form-control-sm" placeholder={ I18n.t("react.form_hidden_field.default_value") } value={value} onChange={this.onChange} onBlur={this.onBlur}/>
          </div>
        </div>
      );
    }

    return value.length ? <p>{ value }</p> : <p><em>{ I18n.t("react.form_hidden_field.empty_value") }</em></p>;
  }
}

export default HiddenFieldType;

HiddenFieldType.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  updateHandler: PropTypes.func.isRequired
};
