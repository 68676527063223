import { Component, ChangeEvent } from "react";
import { UNLIMITED, LIMITED } from "../../constants/Constants";

interface Props {
  value: number;
  onChange: (value: number) => void;
  controlLabel: string;
  i18nRootPath: string;
}

interface State {
  limitationType: string;
  value: number;
}

export default class LimitSelector extends Component<Props, State> {
  constructor(props) {
    super(props);

    [
      "handleLimitChange",
      "onChange"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      value: props.value || 0,
      limitationType: props.value === -1 ? UNLIMITED : LIMITED,
    };
  }

  componentDidUpdate(prevProps): void {
    const { value } = this.props;
    if (!prevProps.value && value) {
      this.setState({
        value,
        limitationType: value === -1 ? UNLIMITED : LIMITED,
      });
    }
  }

  handleLimitChange(e: ChangeEvent<HTMLInputElement>): void {
    const { target } = e;
    const { value: eventLimitationType } = target;
    const { value: stateValue } = this.state;
    this.setState({ limitationType: eventLimitationType, value: eventLimitationType === UNLIMITED ? -1 : stateValue });

    if (eventLimitationType === UNLIMITED) {
      const { onChange } = this.props;
      onChange(-1);
    }
  }

  onChange(e: ChangeEvent<HTMLInputElement>): void {
    const { target } = e;
    const { value } = target;
    this.setState({ value: value ? parseInt(value) : undefined });

    const { onChange } = this.props;
    onChange(value ? parseInt(value) : undefined);
  }

  render(): JSX.Element {
    const { limitationType, value } = this.state;
    const { controlLabel, i18nRootPath } = this.props;

    return (
      <div className="mb-3">
        <label className="form-label">{controlLabel}</label>
        <div className="g-2 row align-items-center">
          {[UNLIMITED, LIMITED].map((option, index) => {
            return <div key={index} className="col-auto">
              <div className="form-check mt-1">
                <label className="form-check-label" key={option}>
                  <input type="radio" className="form-check-input" value={option} checked={limitationType === option} onChange={this.handleLimitChange} />
                  {" "}
                  {I18n.t(`${i18nRootPath}.${option}`)}
                </label>
              </div>
            </div>;
          })}
          <div className="col-auto">
            <input type="number" onWheel={(e): void => (e.target as HTMLInputElement).blur()} min="-1" value={value || ""} className="form-control" disabled={limitationType === UNLIMITED} onChange={this.onChange} />
          </div>
        </div>
      </div>
    );
  }
}
