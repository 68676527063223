import { Component } from "react";
import { pathToEventDocuments } from "../../utils/pathUtils";
import { Button } from "react-bootstrap";

import GuestCategoryCreationButtons from "./GuestCategoryCreationButtons.react";
import RegistrationFormActionsButton from "./RegistrationFormActionsButton.react";

class GuestCategoryActionsMenu extends Component {
  render() {
    return (
      <div className="btn-group-react">
        <Button variant="secondary" href={pathToEventDocuments()}>
          { I18n.t("react.registration.manage_documents") }
        </Button>
        <RegistrationFormActionsButton/>
        <GuestCategoryCreationButtons/>
      </div>
    );
  }
}


export default GuestCategoryActionsMenu;
