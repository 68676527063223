import { Component } from "react";
import { connect } from "react-redux";
import requiresProps from "../../utils/requiresProps";
import querystring from "querystring";

import { CAMPAIGN_STEPS, GuestCampaignStatusLocked } from "../../constants/Constants";
import { urlWithQuery } from "../../utils/pathUtils";
import { campaignStepBreadcrumbLinks } from "../../utils/campaignStepUtils";

import { fetchGuestCampaign, deliverGuestCampaign, sendTestEmail } from "../../actions/GuestCampaignActionCreators";
import { guestCountFromQS, countGuestsInQS } from "../../actions/SearchQueryActionCreators";
import { fetchSavedSearch } from "../../actions/SavedSearchActionCreators";
import { showNotice } from "../../actions/NoticeActionCreators";
import Breadcrumb from "../../components/shared/Breadcrumb.react";
import InformationBlock from "../../components/guest_campaign/InformationBlock.react";
import Modal from "react-modal";
import { defaultModalStyles } from "../../constants/Style";
import { redirectIfUnauthorized, isAuthorized } from "../../utils/aclUtils";
import moment from "moment";
import { urlEventLocale } from "../../utils/pathUtils";
import { Form, Button } from "react-bootstrap";

class GuestCampaignSummarize extends Component {

  constructor(props) {
    redirectIfUnauthorized("campaigns", "manage");
    super(props);
    this.state = { showConfirmationModal: false };
    moment.locale(urlEventLocale());
    const methods = [
      "_closeConfirmationModal",
      "_deliverCampaign",
      "_openConfirmationModal",
      "_renderConfirmationModal",
      "_renderDeliveryError",
      "_renderEmailTestForm",
      "sendTestEmail",
      "_renderInformationBlocks",
      "_urlGuestCampaignInformation",
      "_urlGuestListFromCampaignSavedSearch",
      "testEmailCanBeSent"
    ];
    methods.forEach((item) => { this[item] = this[item].bind(this); });
  }

  _closeConfirmationModal() {
    this.setState({ showConfirmationModal: false });
  }

  _openConfirmationModal() {
    this.setState({ showConfirmationModal: true });
  }

  _queryString() {
    return querystring.parse(this.props.location.search.substring(1)).q;
  }

  _urlGuestListFromCampaignSavedSearch(options = {}) {
    const { savedSearch } = this.props;
    if (!savedSearch) {
      return "#";
    }
    let q = savedSearch.search_query;
    if (options.with_no_email === true) {
      q = `${q} no:email`;
    }
    if (options.without_email_null === true) {
      q = `${q} -email:null`;
    }
    if (options.without_phone_number_null === true) {
      q = `${q} -phone_number:null`;
    }
    if (options.with_optin === true) {
      q = `${q} campaign_optin:true`;
    } else if (options.with_optin === false) {
      q = `${q} campaign_optin:false`;
    }

    return urlWithQuery(q, "guests");
  }

  _urlGuestCampaignInformation() {
    const { match } = this.props;
    return urlWithQuery(this._queryString(), `campaign/${match.params.guest_campaign_id}/information`);
  }

  _deliverCampaign() {
    const { deliverGuestCampaign, match, guestCampaign } = this.props;
    const { event_id, guest_campaign_id } = match.params;
    this._closeConfirmationModal();
    const noticeMessage = guestCampaign.scheduled_at ? I18n.t("react.guest_campaign.summary.campaign_scheduled_notice") : I18n.t("react.guest_campaign.summary.campaign_sent_notice");
    deliverGuestCampaign(event_id, guest_campaign_id, urlWithQuery(this._queryString(), "guests"), { notice: noticeMessage, noticeType: "success" }, match.params.locale);
  }

  _renderInformationBlocks() {
    const { guestCampaign } = this.props;
    if (!guestCampaign) return null;

    const { use_sms, nb_sms_to_send } = guestCampaign;
    return (
      <div className="card">
        <div className="card-header text-center">
          <h4 className="card-title">
            { I18n.t("react.guest_campaign.summary.panel_heading")}
          </h4>
        </div>
        <div className="card-body">
          { this.renderEmailRecap() }
          { this.renderMediumRecap(use_sms, I18n.t("react.guest_campaign.summary.sms_title"), I18n.t("react.guest_campaign.summary.sms_info", { nb_sms_to_send: nb_sms_to_send }), this._urlGuestListFromCampaignSavedSearch({ without_phone_number_null: true, with_optin: true })) }
          { this.renderFromNameBlock() }
          { this.renderScheduledBlockAtBlock() }
          { this.renderEmailsInformationBlocks() }
          { this._renderEmailTestForm() }
        </div>
      </div>
    );
  }

  renderEmailRecap() {
    const { guestCampaign, guestWithoutEmailAddressCount } = this.props;
    const { use_email, nb_emails_to_send } = guestCampaign;

    if (!use_email) {
      return;
    }

    const listNoEmail = guestWithoutEmailAddressCount > 0 &&
      <InformationBlock
        title={I18n.t("react.guest_campaign.summary.list_no_email")}
        info={I18n.t("react.guest_campaign.people", { count: guestWithoutEmailAddressCount })}
        linkName={I18n.t("react.guest_campaign.show")}
        linkUrl={this._urlGuestListFromCampaignSavedSearch({ with_no_email: true })}
        newTab={true}
        leftIconType={ guestCampaign.nb_emails_to_send == 0 ? "times-circle" : "exclamation-circle" }
        leftIconColor={ guestCampaign.nb_emails_to_send == 0 ? "danger" : "warning" }
      />;

    return (
      <div>
        { this.renderMediumRecap(use_email,
          I18n.t("react.guest_campaign.summary.email_title"),
          I18n.t("react.guest_campaign.summary.email_info", { count: nb_emails_to_send }),
          this._urlGuestListFromCampaignSavedSearch({ without_email_null: true, with_optin: true })) }
        { listNoEmail }
      </div>
    );
  }

  renderMediumRecap(use_medium, title, info, linkUrl) {
    if (!use_medium) {
      return;
    }
    return (
      <InformationBlock
        title={ title }
        info={ info }
        linkName={I18n.t("react.guest_campaign.show")}
        linkUrl={ linkUrl }
        newTab={true}
      />
    );
  }

  renderFromNameBlock() {
    const { use_email, use_sms, from_name } = this.props.guestCampaign;
    if (!use_email && !use_sms) {
      return;
    }
    return (
      <InformationBlock
        title={I18n.t("react.guest_campaign.summary.from_name")}
        info={from_name}
        linkName={I18n.t("react.guest_campaign.change")}
        linkUrl={this._urlGuestCampaignInformation()}
      />
    );
  }

  renderScheduledBlockAtBlock() {
    const { scheduled_at } = this.props.guestCampaign;
    return (
      <InformationBlock
        title={I18n.t("react.guest_campaign.summary.scheduled")}
        info={scheduled_at ? I18n.t("react.guest_campaign.summary.scheduled_at", { date: moment(scheduled_at).format("LLLL") }) : I18n.t("react.guest_campaign.summary.not_scheduled")}
        linkName={I18n.t("react.guest_campaign.change")}
        linkUrl={this._urlGuestCampaignInformation()}
      />
    );
  }

  renderEmailsInformationBlocks() {
    const { use_email, subject, reply_to_email } = this.props.guestCampaign;
    if (!use_email) {
      return;
    }
    return (
      <div>
        <InformationBlock
          title={I18n.t("react.guest_campaign.summary.subject")}
          info={subject}
          linkName={I18n.t("react.guest_campaign.change")}
          linkUrl={this._urlGuestCampaignInformation()}
        />
        <InformationBlock
          title={I18n.t("react.guest_campaign.summary.replies")}
          info={`${I18n.t("react.guest_campaign.summary.all_replies_got_to")} ${reply_to_email}`}
          linkName={I18n.t("react.guest_campaign.change")}
          linkUrl={this._urlGuestCampaignInformation()}
        />
      </div>
    );
  }

  _renderDeliveryError() {
    const { deliveryError } = this.props;

    if (deliveryError && deliveryError.length > 0) {
      return <p className="alert alert-danger"> { deliveryError } </p>;
    }
    return null;
  }

  _renderConfirmationModal() {
    const { guestCampaign } = this.props;
    let confirmMessage = I18n.t("react.guest_campaign.summary.confirm_message");
    if (guestCampaign.scheduled_at) {
      confirmMessage = I18n.t("react.guest_campaign.summary.confirm_message_scheduled", { date: moment(guestCampaign.scheduled_at).format("LLLL") });
    }
    return (
      <div>
        <Modal
          className="modal-content"
          isOpen={this.state.showConfirmationModal}
          onRequestClose={this._closeConfirmationModal}
          style={defaultModalStyles}
          contentLabel="Modal"
        >
          <div className="modal-header">
            <h4 className="modal-title">
              { I18n.t("react.guest_campaign.summary.confirm_title") }
            </h4>
            <button type="button" onClick={ this._closeConfirmationModal } className="btn-close" aria-label={I18n.t("close")}></button>
          </div>
          <div className="modal-body" style={{ maxHeight: "500px", overflow: "auto" }}>
            { confirmMessage }
          </div>
          <div className="modal-footer">
            <a href="#" className="btn btn-primary" onClick={this._deliverCampaign}>{ I18n.t("react.guest_campaign.summary.confirm") }</a>
            <a href="#" className="btn btn-secondary" onClick={this._closeConfirmationModal}>{ I18n.t("react.guest_campaign.summary.cancel") }</a>
          </div>
        </Modal>
      </div>
    );
  }

  testEmailCanBeSent() {
    return this.testEmail && this.testEmail.value.match(/\w+/);
  }

  sendTestEmail(e) {
    e.preventDefault();
    const { sendTestEmail, match, showNotice } = this.props;
    if (this.testEmailCanBeSent()) {
      showNotice("", "", null);
      const { event_id, guest_campaign_id } = match.params;
      const testEmail = encodeURIComponent(this.testEmail.value);
      sendTestEmail(event_id, guest_campaign_id, testEmail);
    } else {
      showNotice(I18n.t("react.guest_campaign.summary.email_required"), "danger", null);
    }
    $("html, body").stop().animate({ scrollTop: 0 }, 1000, "swing");
    $("#formInlineEmail").val("");
  }

  _renderEmailTestForm() {
    const { use_email } = this.props.guestCampaign;
    if (!use_email) {
      return;
    }
    const form = <Form className="row g-2 align-items-center" onSubmit={this.sendTestEmail}>
      <Form.Group controlId="formInlineEmail" className="col-auto">
        <Form.Control type="email" placeholder="jane.doe@example.com" inputRef={(ref) => { this.testEmail = ref; }} />
      </Form.Group>{" "}
      <div className="col-auto">
        <Button type="submit"> { I18n.t("react.guest_campaign.test.send") } </Button>
      </div>
    </Form>;

    return <InformationBlock
      title={I18n.t("react.guest_campaign.test.send_test_email")}
      info={ form }
      noIcon={true}
    />;
  }

  render() {
    if (!isAuthorized("campaigns", "manage")) return;
    const { guestCampaign } = this.props;
    const nextUrl = GuestCampaignStatusLocked.includes(guestCampaign.status) ? null : this._openConfirmationModal;
    const nextName = guestCampaign.scheduled_at ? I18n.t("react.guest_campaign.schedule_campaign") : I18n.t("react.guest_campaign.send_campaign");
    return (
      <div style={{ marginBottom: "50px" }}>
        <div className="row">
          <div className="col-md-8 offset-md-2 mt-30">
            { this._renderDeliveryError() }
            { this._renderInformationBlocks() }
          </div>
        </div>
        {this._renderConfirmationModal()}
        <Breadcrumb
          steps={campaignStepBreadcrumbLinks(guestCampaign, {
            guestCampaignIsPersisted: true,
            searchQuery: this._queryString()
          })}
          activeStep={CAMPAIGN_STEPS[2]}
          backUrl={this._urlGuestListFromCampaignSavedSearch()}
          backName={I18n.t("react.guest_campaign.people_list")}
          nextUrl={nextUrl}
          nextName={nextName}
          modalIsOpen={false}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    deliveryError: state.guestCampaign.deliveryError
  };
}

const mapDispatchToProps = {
  fetchGuestCampaign,
  deliverGuestCampaign,
  guestCountFromQS,
  fetchSavedSearch,
  countGuestsInQS,
  sendTestEmail,
  showNotice
};

export default connect(mapStateToProps, mapDispatchToProps)(
  requiresProps(GuestCampaignSummarize, {
    requirements: {
      guestCampaign: {
        fn: (props) => {
          const { match, fetchGuestCampaign } = props;
          fetchGuestCampaign(match.params.event_id, match.params.guest_campaign_id);
        },
        statePath: "guestCampaign.guestCampaign"
      },
      savedSearch: {
        waitFor: ["guestCampaign"],
        fn: (props) => {
          const { guestCampaign, fetchSavedSearch, match } = props;
          fetchSavedSearch(match.params.event_id, guestCampaign.saved_search_id);
        },
        options: {
          force: true
        }
      },
      guestWithoutEmailAddressCount: {
        waitFor: ["savedSearch", "guestCampaign"],
        fn: (props) => {
          const { guestCampaign, savedSearch, countGuestsInQS } = props;
          if (guestCampaign.use_email) {
            countGuestsInQS(`${savedSearch.search_query} no:email`, true, "guestWithoutEmailAddressCount");
          } else {
            return { cancelled: true };
          }
        },
        options: {
          force: true
        },
        statePath: "appearance.guestWithoutEmailAddressCount"
      }
    }
  })
);
