import { ActionTypes } from "../constants/Constants";
import { MessagesStatisticsState } from "../types/MessageStatistics";

const defaultState: MessagesStatisticsState = {
  errors: null,
  isFetching: false,
  messages: null,
};

export function messagesStatistics(state = defaultState, action: any): MessagesStatisticsState {
  switch (action.type) {
  case ActionTypes.FETCH_MESSAGES_STATISTICS_REQUEST:
    return { ...state, isFetching: true, errors: null, messages: null };
  case ActionTypes.FETCH_MESSAGES_STATISTICS_SUCCESS:
    return { ...state, messages: action.data, isFetching: false, errors: null };
  case ActionTypes.FETCH_MESSAGES_STATISTICS_FAILURE:
    return { ...state, messages: null, isFetching: false, errors: action.data };
  default:
    return state;
  }
}

export default messagesStatistics;
