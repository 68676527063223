import { Component, MouseEvent } from "react";
import { WebsiteMenu } from "../../types/WebsiteMenu";

interface Props {
  isShown: boolean;
  menus: WebsiteMenu[];
  toggleView(): void;
  onSelectWebsiteMenu(e: MouseEvent<any>, menuId: string): void;
}

class WebsiteMenusList extends Component<Props> {

  constructor(props: Props) {
    super(props);
    [
      "close",
      "onSelectWebsiteMenu",
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  close(): void {
    this.props.toggleView();
  }

  onSelectWebsiteMenu(menuId: string): ((e: MouseEvent<any>) => void) {
    return (e: MouseEvent<any>): void => {
      return this.props.onSelectWebsiteMenu(e, menuId);
    };
  }

  render(): JSX.Element {
    const { isShown, menus } = this.props;
    if (!isShown || !menus) {
      return <div className="website-menus-list"></div>;
    }

    return (
      <div className="website-menus-list in">
        <div className="sidebar-header">
          <p className="lead">
            <a onClick={ this.close } className="btn-back mr-5 text-center"><i className="fa-regular fa-angle-left"></i></a>
            { I18n.t("react.website.manage_menus") }
          </p>
        </div>

        <div className="sidebar-body">
          <div className="card">
            {menus.length > 0 &&
              <ul className="list-group">
                { menus.map(menu => {
                  return (<li className="list-group-item" onClick={this.onSelectWebsiteMenu(menu._id)} key={menu.key}>
                    { menu.name }
                  </li>);
                })}
              </ul>
            }
            <div className="card-footer">
              <a onClick={this.onSelectWebsiteMenu(null)}>
                <i className="fa-regular fa-plus-circle"></i> { I18n.t("react.website.new_menu") }
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WebsiteMenusList;
