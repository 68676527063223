import { Component } from "react";
import { Dropdown, DropdownDivider } from "react-bootstrap";
import { Link } from "react-router-dom";

import { urlWithQuery, pathToNewEventGuestImportOperation } from "../utils/pathUtils";

class GuestMoreActionsDopdown extends Component {
  _renderSavedSearcheslink(key) {
    const { searchQuery } = this.props;

    return (
      <Link to={urlWithQuery(searchQuery, "guests/modal/saved_searches")} key={key} className="dropdown-item">
        <i className="fa-regular fa-list-alt"></i> {I18n.t("guests.action_dropdown.saved_searches")}
      </Link>
    );
  }

  render() {
    const { event, searchQuery } = this.props;
    return <Dropdown>
      <button type="button" className="btn btn-secondary dropdown-toggle" id="moreActionsDopdown" data-bs-toggle="dropdown" aria-expanded="false">
        {I18n.t("guests.action_dropdown.more")}
      </button>
      <div className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="moreActionsDopdown">
        <Dropdown.Header key={0}>{I18n.t("guests.action_dropdown.export_import")}</Dropdown.Header>
        <Dropdown.Item href={pathToNewEventGuestImportOperation()}>
          <i className="fa-regular fa-download"></i> {I18n.t("react.import_from_excel")}
        </Dropdown.Item>
        {
          window.ReactGlobalProps.super_admin && event && event.account_contacts_enabled ? (
            <Link to={urlWithQuery(searchQuery, "guests/modal/synchronize_contacts_into_event")} key={9} className="dropdown-item">
              <i className="fa-regular fa-user-plus"></i> {I18n.t("react.synchronize_contacts_into_event")}
            </Link>
          ) : null
        }
        {
          event.has_people ? (
            [
              <Link to={urlWithQuery(searchQuery, "guests/modal/exports")} key={1} className="dropdown-item">
                <i className="fa-regular fa-upload"></i> {I18n.t("export_to_excel")}
              </Link>,
              <DropdownDivider key={2} />,
              <Dropdown.Header key={3}>{I18n.t("guests.action_dropdown.actions")}</Dropdown.Header>,
              <Link to={urlWithQuery(searchQuery, "guests/modal/trigger_actions")} key={4} className="dropdown-item">
                <i className="fa-regular fa-spinner"></i> {I18n.t("react.trigger_action")}
              </Link>,
              <DropdownDivider key={7}/>,
              <Dropdown.Header key={8}>{I18n.t("guests.action_dropdown.other")}</Dropdown.Header>,
              this._renderSavedSearcheslink(9),
              <Link to={urlWithQuery(searchQuery, "guests/modal/labels_list")} key={10} className="dropdown-item">
                <i className="fa-regular fa-tags fa-fw"></i> {I18n.t("guests.action_dropdown.label_list")}
              </Link>,
              <Link to={urlWithQuery(searchQuery, "guests/modal/exports_list")} key={11} className="dropdown-item">
                <i className="fa-regular fa-list"></i> {I18n.t("guests.action_dropdown.export_list")}
              </Link>
            ]
          ) : null
        }
      </div>
    </Dropdown>;
  }

}

export default GuestMoreActionsDopdown;
