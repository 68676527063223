import moment from "moment";
import range from "lodash/range";

const LOCAL_DATE_FORMAT = I18n.t("datetime_picker_js_format");
const RAW_DATE_FORMAT = "YYYY-MM-DD HH:mm ZZ";

export function eventDates(event) {
  const [start, end] = [eventStartDate(event), eventEndDate(event)];
  const dates = [];

  iterateDaysBetween(start, end, (day) => {
    dates.push(day.toDate());
  });
  return dates;
}

// startDate and endDate expected to be moment date
export function iterateDaysBetween(startDate, endDate, fn) {
  let current = moment(startDate).toDate();
  while (current <= endDate) {
    const m = moment(current);
    fn(m);
    current = m.add(1, "days").toDate();
  }
}

export function formatLongDate(dateStr) {
  moment.locale(I18n.currentLocale());
  return moment(dateStr).format("LLLL");
}

export function hoursOptions(step = 1) {
  return range(0, 24, step).map(hour => {
    return (<option key={`hour-${hour}`} value={hour}>{`${hour}h`}</option>);
  });
}

export function minutesOptions(step = 5) {
  return range(0, 60, step).map(minute => {
    return (<option key={`minute-${minute}`} value={minute}>{`${minute.toString().padStart(2, "0")}`}</option>);
  });
}

export function convertMinutesSinceMidnightToTime(minutes) {
  return moment().startOf("day").add(minutes, "m").format("LT");
}

export function convertTimeToMinutesSinceMidnight(time) {
  const momentDate = moment(time);
  const midnight = momentDate.clone().startOf("day");

  return momentDate.diff(midnight, "minutes");
}

export function dateRangeRelativeToEvent(startDate, endDate, originalEvent, targetEvent) {
  const datesDelta = moment(targetEvent.end_date).diff(originalEvent.end_date);
  const relativeStartDate = startDate ? moment(startDate).add(datesDelta) : startDate;
  const relativeEndDate = endDate ? moment(endDate).add(datesDelta) : endDate;
  return [relativeStartDate, relativeEndDate];
}

export function convertDatesToRelativeDaysToEvent(dates, datesFormat, event) {
  const baseFormat = "YYYY-MM-DD";
  const refDate = momentWithFormat(event.start_date, baseFormat);

  return dates.map(date => {
    const currentDate = momentWithFormat(date, baseFormat, { initialFormat: datesFormat });
    return currentDate.diff(refDate, "day");
  });
}

function momentWithFormat(date, format, opts = {}) {
  return moment(moment(date, opts["initialFormat"]).format(format), format);
}

function eventStartDate(event) {
  if (event.local_start_date) {
    return moment(event.local_start_date, LOCAL_DATE_FORMAT);
  }
  return moment(event.start_date, RAW_DATE_FORMAT);
}

function eventEndDate(event) {
  if (event.local_end_date) {
    return moment(event.local_end_date, LOCAL_DATE_FORMAT);
  }
  return moment(event.end_date, RAW_DATE_FORMAT);
}
