import { ActionTypes } from "../constants/Constants";
import { ReportConfigurationsState } from "../types/ReportConfiguration";

const defaultState: ReportConfigurationsState = {
  data: [],
  createPending: false,
  fetchPending: false
};

function reportConfiguration(state: ReportConfigurationsState = defaultState, action: any): ReportConfigurationsState {
  switch (action.type) {
  case ActionTypes.CREATE_REPORT_CONFIGURATION_REQUEST:
    return { ...state, createPending: true, createErrors: null };
  case ActionTypes.CREATE_REPORT_CONFIGURATION_SUCCESS:
    return { ...state, data: [...state.data, action.data], createPending: false };
  case ActionTypes.CREATE_REPORT_CONFIGURATION_FAILURE:
    return { ...state, createPending: false, createErrors: action.data };

  case ActionTypes.FETCH_REPORT_CONFIGURATIONS_REQUEST:
    return { ...state, fetchPending: true };
  case ActionTypes.FETCH_REPORT_CONFIGURATIONS_SUCCESS:
    return { ...state, data: action.data };

  case ActionTypes.DELETE_REPORT_CONFIGURATIONS_SUCCESS:
    return { ...state, data: state.data.filter(conf => conf._id !== action.reportConfigurationId) };

  default:
    return state;
  }
}

export default reportConfiguration;
