import { ActionTypes, CALL_API } from "../constants/Constants";

module.exports = {
  fetchColumnsSets(eventId) {
    const { FETCH_COLUMNS_SETS_REQUEST, FETCH_COLUMNS_SETS_SUCCESS, FETCH_COLUMNS_SETS_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [FETCH_COLUMNS_SETS_REQUEST, FETCH_COLUMNS_SETS_SUCCESS, FETCH_COLUMNS_SETS_FAILURE],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/columns_sets.json`
      }
    };
  },

  createColumnsSet(eventId, params) {
    const { CREATE_COLUMNS_SET_REQUEST, CREATE_COLUMNS_SET_SUCCESS, CREATE_COLUMNS_SET_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [CREATE_COLUMNS_SET_REQUEST, CREATE_COLUMNS_SET_SUCCESS, CREATE_COLUMNS_SET_FAILURE],
        method: "POST",
        body: {
          columns_set: params
        },
        endpoint: `/api/v1/events/${eventId}/columns_sets.json`
      }
    };
  },

  updateColumnsSet(eventId, columnSetId, params) {
    const { UPDATE_COLUMNS_SET_REQUEST, UPDATE_COLUMNS_SET_SUCCESS, UPDATE_COLUMNS_SET_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [UPDATE_COLUMNS_SET_REQUEST, UPDATE_COLUMNS_SET_SUCCESS, UPDATE_COLUMNS_SET_FAILURE],
        method: "PUT",
        body: {
          columns_set: params
        },
        endpoint: `/api/v1/events/${eventId}/columns_sets/${columnSetId}.json`
      }
    };
  },

  deleteColumnsSet(eventId, columnSetId) {
    const { DELETE_COLUMNS_SET_REQUEST, DELETE_COLUMNS_SET_SUCCESS, DELETE_COLUMNS_SET_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DELETE_COLUMNS_SET_REQUEST, DELETE_COLUMNS_SET_SUCCESS, DELETE_COLUMNS_SET_FAILURE],
        method: "DELETE",
        endpoint: `/api/v1/events/${eventId}/columns_sets/${columnSetId}.json`
      },
      columnSetId
    };
  },

  changeSelectedColumnsSetId(selectedColumnsSetId) {
    return {
      type: ActionTypes.CHANGE_SELECTED_COLUMNS_SET_ID,
      data: selectedColumnsSetId
    };
  }
};
