import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SlidingPane from "react-sliding-pane";
import Icons from "../../constants/Icons";

import { updateEvent } from "../../actions/EventActionCreators";
import { updateGuestCategory } from "../../actions/GuestCategoryActionCreators";
import { updateGuest } from "../../actions/GuestUpdatesActionCreators";

import Calendar from "../../guest_meeting_availabilities/Calendar.react";
import { formatAvailabilitiesToParams, GuestMeetingAvailability } from "../../guest_meeting_availabilities/types/GuestMeetingAvailability";
import { Event } from "../../types/Event";
import { HasMeetingAvailabilities, HasMeetingAvailabilitiesType } from "../../types/HasMeetingAvailabilities";
import usePrevious from "../../utils/hooks/usePrevious";
import Loader from "../../components/shared/Loader.react";
import ConfirmationModal from "../../components/shared/ConfirmationModal.react";
import isEmpty from "lodash/isEmpty";

interface Props {
  event: Event;
  slidingPaneTitle: JSX.Element|string;
  hasMeetingAvailabilities: HasMeetingAvailabilities;
  defaultMeetingAvailabilities: GuestMeetingAvailability[];
  type: HasMeetingAvailabilitiesType;
  startDate: string;
  endDate: string;
  meetingTypes: string;

  onDestroyedMeetingAvailabilities?: () => void;
}

const i18n = (key: string): string => {
  return I18n.t(`react.networking.has_meeting_availabilities_form.${key}`);
};

const HasMeetingAvailabilitiesForm: React.FC<Props> = ({
  event,
  slidingPaneTitle,
  hasMeetingAvailabilities,
  defaultMeetingAvailabilities,
  startDate,
  endDate,
  meetingTypes,
  type,
  onDestroyedMeetingAvailabilities
}) => {
  const [isSlidingPaneOpened, setIsSlidingPaneOpened] = useState(false);
  const [meetingAvailabilities, setMeetingAvailabilities] = useState(null);
  const [meetingAvailabilitiesChanged, setMeetingAvailabilitiesChanged] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [destroying, setDestroying] = useState(false);
  const [showDestroyConfirmationModal, setShowDestroyConfirmationModal] = useState(false);

  // state is bound to the props
  useEffect(() => {
    setMeetingAvailabilities(hasMeetingAvailabilities.meeting_availabilities || defaultMeetingAvailabilities);
  }, [hasMeetingAvailabilities, defaultMeetingAvailabilities]);

  // look into the redux store to see the status of the request
  const requestPending = useSelector((state: any) => {
    switch (type) {
    case "guest":
      return state.guests.isPending;
    case "guest_category":
      return state.guestCategory.pending;
    case "event":
      return state.event.pendingRequest;
    }
  });

  const previousRequestPending = usePrevious(requestPending);

  useEffect(() => {
    if (previousRequestPending && !requestPending) {
      if (!hasMeetingAvailabilities.errors || isEmpty(hasMeetingAvailabilities.errors)) {
        if (destroying && onDestroyedMeetingAvailabilities) onDestroyedMeetingAvailabilities();
        setIsSlidingPaneOpened(false);
      }
      setUpdating(false);
      setDestroying(false);
    }
  }, [requestPending]);

  const dispatch = useDispatch();

  const save = (): void => {
    if (!meetingAvailabilitiesChanged) {
      // nothing to save
      setIsSlidingPaneOpened(false);
      return;
    }

    setUpdating(true);

    update({
      meeting_availabilities: formatAvailabilitiesToParams(meetingAvailabilitiesChanged)
    });
  };

  const destroy = (): void => {
    setDestroying(true);
    update({
      meeting_availabilities: null
    });
  };

  const update = (payload: any): void => {
    switch (type) {
    case "guest":
      dispatch(updateGuest(event._id, hasMeetingAvailabilities._id, payload));
      break;
    case "guest_category":
      dispatch(updateGuestCategory(event.id, hasMeetingAvailabilities._id, payload));
      break;
    case "event":
      dispatch(updateEvent(event._id, payload));
      break;
    }
  };

  return <>
    <button
      className="btn btn-sm btn-secondary"
      onClick={(e): void => {
        e.preventDefault();
        setIsSlidingPaneOpened(true);
      }}
    >
      <i className="fa-regular fa-pencil"></i> {i18n("edit")}
    </button>
    <SlidingPane
      isOpen={isSlidingPaneOpened}
      width="80%"
      title={slidingPaneTitle}
      onRequestClose={(): void => setIsSlidingPaneOpened(false)}
      closeIcon={Icons.close()}
    >
      <div style={{ margin: "10px" }}>
        <div className="in-back-office">
          <Calendar
            fromDate={startDate}
            toDate={endDate}
            guestMeetingAvailabilities={meetingAvailabilities}
            hasMeetingAvailabilityErrors={hasMeetingAvailabilities.errors}
            slotCapacityEditionDisabled={true}
            onMeetingAvailabilitiesChanged={setMeetingAvailabilitiesChanged}
            displayTypePicker={true}
            defaultType={meetingTypes}
            simultaneousSlotsEnabled={event.meeting_simultaneous_slots_enabled}
            defaultSlotDurationMinutes={event.meeting_default_slot_duration?.toString()}
          />
        </div>
        <button className="btn btn-secondary" onClick={(): void => setShowDestroyConfirmationModal(true)} disabled={requestPending}>
          {i18n("destroy")} {destroying && <Loader size="small" color="#334155" />}
        </button>
        <button className="btn btn-primary float-end" onClick={save} disabled={requestPending}>
          {i18n("save")} {updating && <Loader size="small" color="white" />}
        </button>

        <ConfirmationModal
          isVisible={showDestroyConfirmationModal}
          onClose={(): void => setShowDestroyConfirmationModal(false)}
          onConfirm={(): void => {
            destroy();
            setShowDestroyConfirmationModal(false);
          }}
          title={i18n("are_you_sure")}
          confirmationQuestion={i18n(`destroy_confirmation_${type}`)}
        />
      </div>
    </SlidingPane>
  </>;
};

export default HasMeetingAvailabilitiesForm;
