import { Component } from "react";
import { Accesspoint } from "../../types/Accesspoint";
import { FormItem } from "../../types/FormItem";

interface Props {
  accesspoint: Accesspoint;
  overrideLabel: string;
  mode: string;
  shouldBeDisplayed: boolean;
  formItem: FormItem;
}

class ProductAdvancedOption extends Component<Props> {
  renderAccesspoint(): JSX.Element {
    const { accesspoint, shouldBeDisplayed } = this.props;
    const disabledClass = shouldBeDisplayed ? "" : "disabled";
    return (
      <input type="text" className={`form-control ${disabledClass}`} value={accesspoint.name} readOnly />
    );
  }

  renderQuantity(): JSX.Element {
    const { accesspoint, formItem } = this.props;
    const { override_quantity } = formItem.options;

    const minTrait = override_quantity && override_quantity.min;
    const min = minTrait && accesspoint.traits[minTrait] || 0;
    const maxTrait = override_quantity && override_quantity.max;
    const max = maxTrait && accesspoint.traits[maxTrait] || 10;
    return <div>
      <label className="col-form-label">{ min } <i className="fa-regular fa-arrow-right"></i> { max }</label>
    </div>;
  }

  render(): JSX.Element {
    const { mode, shouldBeDisplayed, accesspoint, overrideLabel } = this.props;
    const disabledClass = shouldBeDisplayed ? "" : "disabled";
    const accesspointNode = (mode == "edit") ? this.renderAccesspoint() : null;
    const key = Object.keys(overrideLabel)[0];
    const label = accesspoint.traits[key] ? accesspoint.traits[key] : accesspoint.display_name;

    return (
      <div className="row mb-10">
        <div className="col-6">
          <input type="text" className={`form-control ${disabledClass}`} value={label} readOnly/>
        </div>
        <div className="col-4">
          { accesspointNode }
        </div>
        <div className="col-2">
          { this.renderQuantity() }
        </div>
      </div>
    );
  }
}

export default ProductAdvancedOption;
