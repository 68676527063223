import { Component } from "react";
import { connect } from "react-redux";
import { guestCountTotal } from "../../actions/GuestCountActionCreators";

interface Props {
  eventId: string;
  queryString?: string;
  keyStore: string;
  render(count: number): JSX.Element;
  guestCount?: any;
  guestCountTotal?(eventId: string, options?: any, keyStore?: string): void;
}

class GuestsCounter extends Component<Props> {
  count(): number {
    const { guestCount, eventId, keyStore } = this.props;
    return guestCount && guestCount[eventId] && guestCount[eventId][keyStore] ? guestCount[eventId][keyStore] : 0;
  }

  componentDidMount(): void {
    const { eventId, queryString, guestCountTotal, keyStore } = this.props;
    guestCountTotal(eventId, { q: queryString || "" }, keyStore);
  }

  render(): JSX.Element {
    const { render } = this.props;
    return render(this.count());
  }
}

function mapStateToProps(state: any): any {
  return {
    guestCount: state.guestCount
  };
}

const mapDispatchToProps = {
  guestCountTotal
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestsCounter);
