import { ActionTypes } from "../constants/Constants";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";
import { StaticGuestFieldToExport, StandardGuestFieldsInColumns } from "../constants/Constants";
import { allEnabled } from "../utils/featureSetUtils";

function guestFieldsToExport(state = initialStateWithStaticFields(), action) {
  switch (action.type) {
  case ActionTypes.GUEST_FIELDS_TO_EXPORT_SUCCESS: {
    let newData = state.data.slice();
    action.data.forEach(guestField => {
      newData = insertOrUpdateObject(newData, guestField, "key");
    });
    return { data: newData, fetched: true };
  }
  default:
    return state;
  }
}

function initialStateWithStaticFields() {
  const staticFields = StaticGuestFieldToExport.reduce((enabledStaticGuestFields, guestField) => {
    if (StandardGuestFieldsInColumns[guestField] && !allEnabled(StandardGuestFieldsInColumns[guestField].requiredFeatures)) return enabledStaticGuestFields;

    enabledStaticGuestFields.push({ label: guestField, key: guestField, type: "text" });
    return enabledStaticGuestFields;
  }, []);

  return { data: staticFields, fetched: false };
}

export default guestFieldsToExport;
