import { useSelector } from "react-redux";
import DocumentTemplates from "./DocumentTemplates.react";
import { pathToGuestCategoriesList } from "../../utils/pathUtils";

const listBodyHeight = "100%";
const toolbarHeight = "40px";
const searchBarHeight = "50px";
const borderStyle = "1px solid #E3E3E3";

interface Props {
  match: any;
  history: any;
  location: any;
}

const DocumentTemplatesList: React.FC<Props> = ({ match, history, location }) => {
  const event = useSelector((state: any) => state.event);
  const selectedTemplateId = useSelector((state: any) => state.documentTemplates.selectedTemplateId);

  const eventId = (): string => {
    return match.params.event_id;
  };

  return (
    <div className="row document-templates-index">
      <div className="col-12">
        <div className="header-page">
          <div className="header-page-title">
            <h1>
              <a href={pathToGuestCategoriesList()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></a>
              { I18n.t("react.document_templates.list.title") }
            </h1>
          </div>
        </div>

        <DocumentTemplates
          match={match}
          location={location}
          history={history}
          event={event}
          eventId={eventId()}
          borderStyle={borderStyle}
          listBodyHeight={listBodyHeight}
          toolbarHeight={toolbarHeight}
          searchBarHeight={searchBarHeight}
          enableEdit={true}
          enableDelete={true}
          iframeAutoResize={true}
          loaderContainerHeight={listBodyHeight}
          selectedTemplateId={selectedTemplateId}
          iframeCustomClass="card"
          fromTemplatesList={true} />
      </div>
    </div>
  );
};

export default DocumentTemplatesList;
