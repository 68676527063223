import { ActionTypes, CALL_API } from "../constants/Constants";
import { stringify } from "querystring";

export function guestCountTotal(eventId, options = {}, keyStore = "") {
  const { DEV_NULL, GUESTS_COUNT_TOTAL_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, GUESTS_COUNT_TOTAL_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guests/count.json?${stringify(options)}`
    },
    eventId,
    keyStore
  };
}

export function guestCountSince(eventId, options = {}) {
  const { DEV_NULL, GUESTS_COUNT_SINCE_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, GUESTS_COUNT_SINCE_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guests/count.json?${stringify(options)}`
    },
    eventId
  };
}

export function guestCountBetween(eventId, options = {}) {
  const { DEV_NULL, GUESTS_COUNT_BETWEEN_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, GUESTS_COUNT_BETWEEN_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guests/count.json?${stringify(options)}`
    },
    eventId
  };
}

export function guestCountPerDate(eventId, options = {}) {
  const { DEV_NULL, GUEST_COUNT_PER_REGISTER_DATE_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, GUEST_COUNT_PER_REGISTER_DATE_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guests/count_per_day.json?${stringify(options)}`
    },
    eventId
  };
}

export function clearAllGuestCount() {
  return {
    type: ActionTypes.GUESTS_COUNT_CLEAR
  };
}
