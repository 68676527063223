import includes from "lodash/includes";
import compact from "lodash/compact";
import each from "lodash/each";
import { SearchQueryKeywords, emailActivityFilters, optinsKeywords, GuestStatuses } from "../constants/Constants";
import querystring from "querystring";

// return an array of terms in the search
function _tokenize(query) {
  if (query == undefined || query == null || query == "undefined") {
    query = "";
  }
  const regex = /(?:[^\s:]+):((?:(?:[^\s"]+)|(?:"(?:\\"|[^"])*")|(?:'(?:\\'|[^'])*'))+)|(?:[^\s:]+)/g;
  const terms = query.match(regex);
  return terms;
}

// return all single terms in the tokenized query string
function _singleTerms(terms) {
  let singleTerms = [];
  each(terms, term => {
    if (term.split(":").length == 1) {
      singleTerms.push(term);
    }
  });
  return singleTerms;
}

// return all composed terms in the search query in an array of { search_field: <some field>, search_value: <some value> }
function _composedTerms(terms) {
  let composedTerms = [];
  each(terms, term => {
    const separatorIndex = term.indexOf(":");
    if (separatorIndex == -1) return true;
    const components = [term.slice(0, separatorIndex), term.slice(separatorIndex + 1)];
    if (components.length > 1) {
      const value = components.slice(1, components.length + 1).join(":");
      const composedTerm = {
        searchField: components[0],
        searchValue: value
      };
      composedTerms.push(composedTerm);
    }
  });
  return composedTerms;
}

function _extractTermsTypes(terms) {
  return [_singleTerms(terms), _composedTerms(terms)];
}

function _extractSimpleQueryFromUrl(queryString) {
  return _singleTerms(_tokenize(queryString)).join(" ");
}

function _extractComposedQueryFromUrl(queryString) {
  return _composedTerms(_tokenize(queryString));
}

function _getSelectedItems(allItems = [], selectedIds = []) {
  return allItems.filter(currentItem => {
    return includes(selectedIds, (currentItem.id || currentItem._id));
  });
}

function _buildSearchQueryFromState(state) {
  // 1: get back the simple search query
  let components = [];

  let guestCategoriesState = [];
  if (state.guestCategories) {
    guestCategoriesState = state.guestCategories.data;
  }
  let accesspointsState = [];
  if (state.accesspoints) {
    accesspointsState = state.accesspoints.data;
  }
  let thematicsState = [];
  if (state.thematics) {
    thematicsState = state.thematics.items || [];
  }

  //components identified by their id
  const guestCategories = _getSelectedItems(guestCategoriesState, state.selectedGuestCategoryIds);
  components.push(_enrichQuery(guestCategories, SearchQueryKeywords.GUEST_CATEGORY, "OR", true));

  const expectedAtAccesspoints = _getSelectedItems(accesspointsState, state.expectedAtAccesspointIds);
  components.push(_enrichQuery(expectedAtAccesspoints, SearchQueryKeywords.EXPECTED_AT, "AND", true));

  const checkedInAtAccesspoints = _getSelectedItems(accesspointsState, state.checkedInAtAccesspointIds);
  components.push(_enrichQuery(checkedInAtAccesspoints, SearchQueryKeywords.CHECKED_IN_AT, "AND", true));

  const acceptedAtAccesspoints = _getSelectedItems(accesspointsState, state.acceptedAtAccesspointIds);
  components.push(_enrichQuery(acceptedAtAccesspoints, SearchQueryKeywords.ACCEPTED_AT, "AND", true));

  const rejectedAtAccesspoints = _getSelectedItems(accesspointsState, state.rejectedAtAccesspointIds);
  components.push(_enrichQuery(rejectedAtAccesspoints, SearchQueryKeywords.REJECTED_AT, "AND", true));

  const thematics = _getSelectedItems(thematicsState, state.selectedThematicIds);
  components.push(_enrichQuery(thematics, SearchQueryKeywords.THEMATICS, "AND", true));

  const guestsThematicsForQuartiles = _getSelectedItems(thematicsState, state.selectedThematicsIdsInGuestsThematicsQuartiles);
  const guestsThematicsQuartilesFilter = _add_quartiles_filter(state, _enrichQuery(guestsThematicsForQuartiles, SearchQueryKeywords.INTEREST_IN, "OR", true));
  components.push(guestsThematicsQuartilesFilter);

  const { labels } = state;
  const items = labels ? labels.items : []; // play nice with the tests
  const guestLabels = _getSelectedItems(items || [], state.selectedGuestLabelIds);
  components.push(_enrichQuery(guestLabels, SearchQueryKeywords.LABEL, "OR", true));

  //components identified by their names
  components.push(_enrichQuery(state.selectedGuestStatuses, SearchQueryKeywords.GUEST_STATUS, "OR", false));
  components.push(_enrichQuery(state.selectedSSOStatuses, SearchQueryKeywords.SSO_STATUS, "OR", false));
  components.push(_enrichQuery(state.selectedEngagementLevels, SearchQueryKeywords.ENGAGEMENT_LEVELS, "OR", false));
  components.push(_enrichQuery(state.selectedPaymentStatuses, SearchQueryKeywords.PAYMENT_STATUS, "OR", false));
  components.push(_enrichQuery(state.selectedShowedUpStatuses, SearchQueryKeywords.SHOWED_UP_STATUS, "UNIQUE", false));
  components.push(_enrichQuery(state.selectedGuestSortOptions, SearchQueryKeywords.SORT, "UNIQUE", false));

  // email activities
  if (state.emailActivities) {
    each(emailActivityFilters, filter => {
      if (state.emailActivities[filter]) {
        components.push(filter + ":" + state.emailActivities[filter]);
      }
    });
  }

  if (state.selectedOptins) {
    each(optinsKeywords, filter => {
      if (state.selectedOptins[filter]) {
        components.push(filter + ":" + state.selectedOptins[filter]);
      }
    });
  }

  // 3: include registration dates
  if (state.registrationDate) {
    if (state.registrationDate.registeredBefore) {
      components.push(SearchQueryKeywords.REGISTERED_BEFORE + ":" + state.registrationDate.registeredBefore);
    }

    if (state.registrationDate.registeredAfter) {
      components.push(SearchQueryKeywords.REGISTERED_AFTER + ":" + state.registrationDate.registeredAfter);
    }
  }

  components.push(state.simpleSearchQuery);
  return encodeURIComponent(compact(components).join(" "));
}

function _buildSelectedGuestFromState(state) {
  return encodeURIComponent(_getSelectedItems([], state.guests.selectedGuests));
}

// Used to add composed term filters from state.
function _enrichQuery(selectedItems, filterKeyword, type, nameAsId) {
  let formattedSelectedItems = [];
  let components = [];
  each(selectedItems, item => {
    // When using name : "My "great" category" => "My \"great\" category" in search query
    let value = item;
    if (item.id || item._id) {
      value = nameAsId ? item.name.replace(/"/g, "\\\"") : (item.id || item._id);
    }
    const charsWhoNeedQuotesArround = [",", ";", " ", "'", "\\\""];
    each(charsWhoNeedQuotesArround, char => {
      if (value.indexOf(char) >= 0) {
        value = `"${value}"`;
        return false;
      }
    });
    formattedSelectedItems.push(value);
  });
  if (formattedSelectedItems.length > 0) {
    switch (type) {
    case "UNIQUE":
      components.push(`${filterKeyword}:${formattedSelectedItems[0]}`);
      break;
    case "AND":
      each(formattedSelectedItems, selectedItem => {
        components.push(`${filterKeyword}:${selectedItem}`);
      });
      break;
    default:
      components.push(`${filterKeyword}:${formattedSelectedItems.join(",")}`);
    }
  }
  return components.join(" ");
}

function _add_quartiles_filter(state, filter) {
  const Quartiles = state.selectedQuartilesIdsInGuestsThematicsQuartiles;
  return Quartiles && filter ? `${Quartiles.join("_")}_${filter}` : "";
}

function _cleanUpQueryString(query) {
  try {
    return decodeURIComponent(query || "");
  } catch (e) {
    if (e instanceof URIError)
      return query; // query might have already been decoded, so we return it
    else
      throw e;
  }
}

module.exports = {

  enrichQuery(selectedItems, filterKeyword, type, nameAsId) {
    return _enrichQuery(selectedItems, filterKeyword, type, nameAsId);
  },

  statusQueryWithoutRegistered() {
    const query = "status:";
    let statuses = GuestStatuses.slice();
    statuses.splice(statuses.indexOf("registered"), 1); // remove registered in statuses
    const stringStatuses = statuses.join();
    return (query + stringStatuses);
  },

  extractSimpleQueryFromUrl(queryString) {
    return _extractSimpleQueryFromUrl(queryString);
  },

  extractComposedTerms(queryString) {
    return _extractComposedQueryFromUrl(queryString);
  },

  extractTermsTypes(queryString) {
    return _extractTermsTypes(_tokenize(_cleanUpQueryString(queryString)));
  },

  buildSearchQueryFromState(state) {
    return _buildSearchQueryFromState(state);
  },

  buildSearchQueryAndSelectedGuestsFromState(state) {
    const completeSearchQuery = _buildSearchQueryFromState(state);
    const selectedGuests = _buildSelectedGuestFromState(state);
    if (selectedGuests.length > 0) {
      return completeSearchQuery + " " + selectedGuests;
    }
    return completeSearchQuery;
  },

  queryStringAndSelectedGuests(location, selectedGuests = []) {
    const selectedGuestQuery = selectedGuests.map(guest => guest.id);
    const params = querystring.parse(location.search.substring(1));
    if (selectedGuestQuery.length > 0) {
      return (params.q || "") + " " + encodeURI(selectedGuestQuery);
    }
    return params.q;
  },

  cleanUpQueryString(query) {
    return _cleanUpQueryString(query);
  },

  extractPaginationPageNumbers(previousURL, nextURL) {
    let url = null;
    return [previousURL, nextURL].map(pageUrl => {
      if (!pageUrl) return null;

      url = new URL(pageUrl.substring(1));
      const searchParams = new URLSearchParams(url.search);
      return parseInt(searchParams.get("page"));
    });
  }
};
