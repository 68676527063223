import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DropdownButton, DropdownItem } from "react-bootstrap";
import truncate from "lodash/truncate";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Icons from "../constants/Icons";
import { fetchWebsite } from "../actions/WebsiteActionCreators";
import { fetchGuestCategory, updateGuestCategory } from "../actions/GuestCategoryActionCreators";
import GuestCategoryEmailForm from "./GuestCategoryEmailForm.react";
import GuestCategoryFeature from "../components/guest_category/GuestCategoryFeature.react";
import UnpublishedEventAlertMessage from "../components/UnpublishedEventAlertMessage.react";

class GuestCategoryEmailFeature extends Component {
  constructor(props) {
    super(props);
    [
      "openEmailConfigForm",
      "closeEmailConfigForm",
      "disableBodyScroll",
      "enableBodyScroll",
      "disableEmail"
    ].forEach(item => { this[item] = this[item].bind(this); });

    this.state = {
      isEmailConfigFormOpen: props.openOnLoad
    };
  }

  componentDidMount() {
    const { guestCategory, fetchGuestCategory, match } = this.props;
    if (match && (!guestCategory || Object.keys(guestCategory).length === 0))
      fetchGuestCategory(match.params.event_id, match.params.guest_category_id, { custom_emails: true });
  }

  openEmailConfigForm() {
    this.setState({ isEmailConfigFormOpen: true });
  }

  closeEmailConfigForm() {
    const { onCloseConfig } = this.props;

    this.setState({ isEmailConfigFormOpen: false });
    this.enableBodyScroll();
    onCloseConfig();
  }

  disableBodyScroll() {
    document.body.style.overflow = "hidden";
  }

  enableBodyScroll() {
    document.body.removeAttribute("style");
  }

  isSlidingPaneOpenable() {
    const { guestCategory, feature } = this.props;
    return !guestCategory[feature.field] || !guestCategory[`${feature.emailType}_email_is_custom`];
  }

  i18n(key) {
    const { feature } = this.props;

    return I18n.t(`react.guest_category.${feature.emailType}_email.${key}`);
  }

  isFeatureEnabled() {
    const { guestCategory, feature } = this.props;
    return guestCategory[feature.field];
  }

  disableEmail() {
    const { updateGuestCategory, guestCategory, feature } = this.props;

    if (confirm(this.i18n("disable_email_confirm")))
      updateGuestCategory(guestCategory.event_id, guestCategory._id, { [feature.field]: false });
  }

  renderBarsIcon() {
    return <i className="fa-regular fa-bars" />;
  }

  renderEmailOptions() {
    return <div className="feature-dropdown-btn" key="feature-dropdown-btn">
      <DropdownButton variant="link" align="end" id="EmailOptions" title={this.renderBarsIcon()}>
        <DropdownItem eventKey="1" onClick={this.disableEmail}><i className="fa-regular fa-circle-xmark" /> {this.i18n("disable_email")}</DropdownItem>
      </DropdownButton>
    </div>;
  }

  renderSlidingPaneTitle(message) {
    const { guestCategory } = this.props;

    return (
      <div className="d-flex">
        <span className="d-flex align-items-center mr-5">
          { message } { " " }
        </span>
        <span className="badge rounded-pill big guest-category" style={{ backgroundColor: guestCategory.label_color }}>
          {truncate(guestCategory.name, { "length": 45 })}
        </span>
      </div>
    );
  }

  renderSlidingPane() {
    const { event, feature, match, location, history } = this.props;
    const { isEmailConfigFormOpen } = this.state;

    return (
      <SlidingPane isOpen={ isEmailConfigFormOpen }
        width='60%'
        title={ this.renderSlidingPaneTitle(this.i18n("sliding_pane_email_title")) }
        onAfterOpen={ this.disableBodyScroll }
        onRequestClose={ this.closeEmailConfigForm }
        className="width-100-xs width-80-sm"
        closeIcon={Icons.close()}>

        <UnpublishedEventAlertMessage event={event} wrapperClass="bg-gray pr-15 pl-15 pt-15"/>
        <GuestCategoryEmailForm emailType={feature.emailType}
          featureEnabledField={feature.field}
          match={match}
          location={location}
          history={history} />
      </SlidingPane>
    );
  }

  renderFeatureContent() {
    const { guestCategory, feature } = this.props;

    return (
      <GuestCategoryFeature feature={feature}
        guestCategory={guestCategory}
        onClick={this.isSlidingPaneOpenable() && this.openEmailConfigForm || null}>
        { this.isFeatureEnabled() && this.renderEmailOptions() }
      </GuestCategoryFeature>
    );
  }

  render() {
    const { guestCategory, defaultHtmlContent } = this.props;

    if (!guestCategory || Object.keys(guestCategory).length == 0)
      return <div dangerouslySetInnerHTML={{ __html: defaultHtmlContent }}></div>;

    return <div>
      { this.renderFeatureContent() }
      { this.renderSlidingPane() }
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    event: state.event,
    guestCategory: state.guestCategory.data,
    website: state.website.data,
    sectionTypes: state.sectionTypes.items
  };
}

const mapDispatchToProps = {
  fetchGuestCategory,
  fetchWebsite,
  updateGuestCategory
};

GuestCategoryEmailFeature.propTypes = {
  guestCategory: PropTypes.object,
  feature: PropTypes.object.isRequired,
  openOnLoad: PropTypes.bool
};

GuestCategoryEmailFeature.defaultProps = {
  openOnLoad: false
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestCategoryEmailFeature);
