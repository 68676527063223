interface Props {
  label?: string | JSX.Element;
  checked?: boolean;
  disabled?: boolean;
  text: string|JSX.Element;
  name?: string;
  help?: string|JSX.Element;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: React.FC<Props> = ({ label, text, name, help, checked, disabled, onChange }) => {
  return <>
    { label && <label className="form-label">{label}</label>}
    <div className={`form-check ${disabled ? "disabled" : ""}`}>
      <label className="form-check-label">
        <input type="checkbox" className="form-check-input" name={name || ""} checked={checked || false} onChange={onChange} disabled={disabled} />
        {text}
      </label>
    </div>
    { help && <div className="form-text">{help}</div> }
  </>;
};

export default Checkbox;
