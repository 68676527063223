import { Component, MouseEvent } from "react";
import moment from "moment";
import { shouldBeDisplayed } from "../../utils/CheckinpointUtils.js";
import AccommodationOption from "./AccommodationOption.react";
import CreateAccesspointModal from "../mapping_step/CreateAccesspointModal.react";
import ShowAllLink from "./ShowAllLink.react";
import { Accesspoint } from "../../types/Accesspoint";
import { FormItem } from "../../types/FormItem";
import { FormItemOption } from "../../types/FormItemOption";

const TRUNCATE_LIMIT = 5;

interface Props {
  accesspointList(): Accesspoint[];
  accesspoints: Accesspoint[];
  availableAccesspoints(optionItem?: FormItemOption): Accesspoint[];
  checkboxValueOnBlur(): void;
  checkboxValueOnChanged(): void;
  checkedAccesspoints: string[];
  closeCreateAccesspointModal(): void;
  destroyOption(): void;
  eventId: string;
  formItem: FormItem;
  getNewAccesspoint(accesspoint?: Accesspoint): void;
  handleDrop(): void;
  renderAddAccesspointOptions(): JSX.Element;
  injectCreateAccesspointKey(accesspoints: Accesspoint[]): Accesspoint[];
  locale: string;
  mode: string;
  renderOptionsFilter(): JSX.Element;
  showCreateAccesspointModal: boolean;
  showErrorAccesspoint(): JSX.Element;
  showingAll: boolean;
  triggerShowAll(e: MouseEvent<HTMLAnchorElement>): void;
  updateOption(): void;
}

class AccommodationType extends Component<Props> {

  renderAdvancedEditModeItems(): JSX.Element {
    const { accesspointList, showingAll } = this.props;
    const accommodationList = accesspointList().map(accommodation => this.renderAdvancedModeItem(accommodation));
    const truncate = showingAll ? accommodationList.length : TRUNCATE_LIMIT;

    return <div>
      { accommodationList.slice(0, truncate) }
      { this.renderShowAllLink(accommodationList) }
    </div>;
  }

  renderAdvancedModeItem(accommodation): JSX.Element {
    return <div className="row mb-10" key={accommodation.id}>
      <div className="col-12">
        <input type="text" className="form-control disabled" value={accommodation.display_name} readOnly />
      </div>
    </div>;
  }

  renderDefaultEditModeItems(): JSX.Element {
    const { showingAll, formItem } = this.props;
    const formItemOptions = formItem.form_item_options || [];
    const checkinpointOptions = formItemOptions.map((option) => this.renderDefaultModeItem(option));
    const truncate = showingAll ? formItemOptions.length : TRUNCATE_LIMIT;

    return <div>
      { checkinpointOptions.slice(0, truncate) }
      { this.renderShowAllLink(formItemOptions) }
    </div>;
  }

  renderDefaultModeItem(option): JSX.Element {
    const { accesspoints, formItem, updateOption, destroyOption, injectCreateAccesspointKey, availableAccesspoints } = this.props;

    return <AccommodationOption
      key={option.key}
      shouldBeDisplayed={shouldBeDisplayed(accesspoints, formItem, option.key)}
      itemOption={option}
      updateHandler={updateOption}
      destroyHandler={destroyOption}
      allowDestroy={formItem.form_item_options.length > 1}
      accesspoints={injectCreateAccesspointKey(availableAccesspoints(option))}
    />;
  }

  renderShowAllLink(options): JSX.Element {
    const { triggerShowAll } = this.props;

    return <ShowAllLink
      nbItems={options.length}
      onClickHandler={triggerShowAll}
      limit={TRUNCATE_LIMIT}
    />;
  }

  renderEditMode(): JSX.Element {
    const { formItem, showErrorAccesspoint, renderAddAccesspointOptions, renderOptionsFilter } = this.props;
    const items = formItem.options.advanced_mode ? this.renderAdvancedEditModeItems() : this.renderDefaultEditModeItems();
    const options = formItem.options.advanced_mode && renderOptionsFilter();

    return <div className="row">
      <div className="col-sm-12">
        <div className="mt-10">
          { showErrorAccesspoint() }
          { items }
          { renderAddAccesspointOptions() }
        </div>
        { options }
        { this.renderCreateAccesspointModal() }
      </div>
    </div>;
  }

  renderCreateAccesspointModal(): JSX.Element {
    const { eventId, locale, showCreateAccesspointModal, closeCreateAccesspointModal, getNewAccesspoint } = this.props;

    return <CreateAccesspointModal
      isVisible={showCreateAccesspointModal}
      eventId={eventId}
      locale={locale}
      onCloseFunction={closeCreateAccesspointModal}
      updateParent={getNewAccesspoint}
      defaultType="accommodation"
    />;
  }

  renderReadMode(): JSX.Element {
    const { accesspoints, formItem, accesspointList } = this.props;
    let accesspoint = null;

    if (formItem.options.advanced_mode) {
      const collection = accesspointList();
      accesspoint = collection && collection[0];
    } else {
      const option = (formItem.form_item_options || []).find(option => {
        return shouldBeDisplayed(accesspoints, formItem, option.key);
      });
      accesspoint = accesspoints.find(accesspoint => accesspoint._id == option.key);
    }

    if (!accesspoint) return null;

    moment.locale(I18n.currentLocale());
    const checkinDate = moment(accesspoint.start_date_to_timezone, "L").format("L");
    const checkoutDate = moment(accesspoint.start_date_to_timezone, "L").add(1, "days").format("L");

    return <div className="row">
      <div className="col-4">
        <div className="form-text">{I18n.t("react.form_accesspoint_group.from")}</div>
        <input type="text" className="form-control form-control-sm" value={checkinDate} readOnly />
      </div>
      <div className="col-4">
        <div className="form-text">{I18n.t("react.form_accesspoint_group.to")}</div>
        <input type="text" className="form-control form-control-sm" value={checkoutDate} readOnly />
      </div>
      <div className="col-4">
        <div className="form-text">{I18n.t("react.form_accesspoint_group.room_type")}</div>
        <select className="form-select form-select-sm" disabled>
          <option value="">{ accesspoint.room_type }</option>
        </select>
      </div>
      <div className="col-12 mt-10">
        <div className="form-text">{I18n.t("react.form_accesspoint_group.establishment")}</div>
        <select className="form-select form-select-sm" disabled>
          <option value="">{ accesspoint.location }</option>
        </select>
      </div>
    </div>;
  }

  render(): JSX.Element {
    const { mode } = this.props;

    return mode === "read" ? this.renderReadMode() : this.renderEditMode();
  }
}

export default AccommodationType;
