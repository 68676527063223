import { Component } from "react";
import { connect } from "react-redux";
import { components } from "react-select";
import { requestGuests, removeGuestInSiloedData } from "../actions/ImportActionCreators";
import { importGuestCategories } from "../actions/ImportGuestCategoryActionCreators";
import GuestCategoryLabel from "../components/guest_category/GuestCategoryLabel.react";
import ResourceSearchDropdown from "../components/ResourceSearchDropdown.react";
import { Guest } from "../types/Guest";
import { GuestCategory } from "../types/GuestCategory";
import { multiValueRemoveStyle } from "../constants/Style";

const I18N_ROOT_KEY = "react.guests.guest_search_dropdown";

const GuestSearchMultiValueContainer = (props: any): JSX.Element => {
  return (
    <div className="multi-guest-dropdown-value">
      <div className="dropdown-value-content">
        <img src={props.data.avatar} width="23" className="value-image" />
        <components.MultiValueContainer {...props} />
      </div>
    </div>
  );
};

const GuestSearchValueContainer = ({ children, ...props }): JSX.Element => {
  return (
    <div className="guest-dropdown-value">
      <div className="dropdown-value-content">
        <img src={props.data.avatar} width="23" className="value-image" />
        <components.SingleValue {...props}>{children}</components.SingleValue>
      </div>
    </div>
  );
};

const customStyles = {
  singleValue: (base: any): any => ({
    ...base,
    display: "inline-flex",
    fontSize: "0.9em",
    fontWeight: "bold",
    color: "inherit"
  }),
  multiValue: (base: any): any => ({
    ...base,
    display: "inline-flex",
    backgroundColor: "inherit",
  }),
  multiValueLabel: (base: any): any => ({
    ...base,
    fontSize: "0.9em",
    fontWeight: "bold",
    color: "inherit"
  }),
  multiValueRemove: (base: any): any => ({
    ...base,
    ...multiValueRemoveStyle
  })
};

const GuestOption = (props: any): JSX.Element => {
  return (
    <components.Option {...props}>
      <div className="guest-dropdown-option">
        <div className="option-image">
          <img src={props.data.avatar} width="30" />
        </div>
        <div className="option-identity">
          <span>{props.data.label}</span><br />
          <span className="option-company-name">{props.data.company_name}</span>
        </div>
        <div className="option-info">
          <GuestCategoryLabel guestCategory={props.data.guestCategory} isLink={false} truncate={30} hasTooltip={false} /><br />
          <span className="badge rounded-pill bg-info">{I18n.t(props.data.status)}</span>
        </div>
      </div>
    </components.Option>
  );
};

interface Props {
  allowMultipleSelect?: boolean;
  guestCategories: GuestCategory[];
  guests: Guest[];
  requestGuestCategories(opts?: any): void;
  onSelectGuest(guests: Guest[]): void;
  removeGuestInSiloedData(uniqueKeyInSiloedData: string): void;
  requestGuests(searchQuery: string, page: number, options?: any);
  selectedGuests: Guest[];
  selectedGuestsIds?: string[];
  siloedData: any;
}

class GuestSearchDropdown extends Component<Props> {

  static defaultProps = {
    allowMultipleSelect: false
  };

  constructor(props: Props) {
    super(props);
    [
      "fetchGuests",
      "fetchGuestsFromIds",
      "resourceMapping"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  componentDidMount(): void {
    const { requestGuestCategories } = this.props;
    requestGuestCategories();
  }

  resourceMapping(guest: Guest): any {
    const { guestCategories } = this.props;
    const guestCategory = guestCategories && guestCategories.find(category => category._id == guest.guest_category_id);
    return {
      _id: guest._id,
      avatar: guest.avatar_thumb,
      company_name: guest.company_name,
      guestCategory: guestCategory,
      label: guest.identity,
      status: guest.status,
      value: guest._id
    };
  }

  fetchGuests(_event: string, searchQuery: string, page: number, options: any = {}): void {
    const { requestGuests } = this.props;
    requestGuests(searchQuery, page, { ...options, skipReset: true });
  }

  fetchGuestsFromIds(_eventId: string, searchQuery: string, page: number, options?: any): void {
    const { requestGuests } = this.props;
    const qs = options.searchFilters.id || searchQuery;
    delete options.searchFilters;
    requestGuests(qs, page, options);
  }

  render(): JSX.Element {
    const { guests, onSelectGuest, selectedGuests, selectedGuestsIds, allowMultipleSelect, siloedData, removeGuestInSiloedData } = this.props;

    return (
      <ResourceSearchDropdown
        className="react-select"
        classNamePrefix="react-select"
        allowMultipleSelect={allowMultipleSelect}
        cleanUniqueKeyInSiloedData={removeGuestInSiloedData}
        i18nRootKey={I18N_ROOT_KEY}
        onSelectResource={onSelectGuest}
        requestResources={this.fetchGuests}
        requestResourcesFromIds={this.fetchGuestsFromIds}
        resourceMapping={this.resourceMapping}
        resources={guests}
        selectedResources={selectedGuests}
        selectedResourcesIds={selectedGuestsIds}
        siloedData={siloedData}
        components={{ MultiValueContainer: GuestSearchMultiValueContainer, SingleValue: GuestSearchValueContainer, Option: GuestOption }}
        styles={customStyles} />
    );
  }
}

const mapStateToProps = (state: any): any => {
  return {
    guestCategories: state.guestCategories.data,
    guests: state.guests.guests,
    siloedData: state.guests.siloedData
  };
};

const mapDispatchToProps = {
  requestGuests,
  removeGuestInSiloedData,
  requestGuestCategories: importGuestCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestSearchDropdown);
