import { ActionTypes } from "../constants/Constants";

module.exports = {
  setGuestPage(page) {
    return {
      type: ActionTypes.SET_GUEST_PAGE,
      value: page
    };
  },

  stopHydratingState() {
    return {
      type: ActionTypes.SET_HYDRATATION_STATE,
      value: false
    };
  },

  startHydratingState() {
    return {
      type: ActionTypes.SET_HYDRATATION_STATE,
      value: true
    };
  },

  startFillingQueryStringFilters() {
    return {
      type: ActionTypes.SET_FILLING_FILTER_STATE,
      value: true
    };
  },

  stopFillingQueryStringFilters() {
    return {
      type: ActionTypes.SET_FILLING_FILTER_STATE,
      value: false
    };
  }

};
