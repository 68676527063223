import { Component } from "react";
import { connect } from "react-redux";
import concat from "lodash/concat";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import { StaticGuestFieldsForTicketing, DragTypes } from "../constants/Constants";
import {
  pathToTicketing, pathToTicketingEdit, pathToTicketingNew, pathToWebsiteView, urlEventId, pathToRegistrationForm, pathToBadgeConfiguration,
  pathToConfirmationEmailConfiguration, pathToWebsiteBuilderPage, pathToGuestCategoryPayment, pathToGuestCategoryZohoConfiguration,
  pathToGuestCategoryShow
} from "../utils/pathUtils";
import { convertColorToHexa } from "../utils/StringUtils";
import { isEnabled } from "../utils/featureSetUtils";
import { CONFIRMATION_EMAIL, SEATSIO, INVOICING } from "../constants/FeaturesSet";

import { Form, InputGroup, OverlayTrigger, Tooltip, DropdownButton, DropdownItem } from "react-bootstrap";
import Switch from "react-bootstrap-switch";

import ErrorMessage from "../components/shared/ErrorMessage.react";
import { fetchTicketing, createTicketing, updateTicketing } from "../actions/TicketingActionCreators";
import { fetchRegistrationForms } from "../actions/RegistrationFormsActionCreators";
import { fetchWebsite } from "../actions/WebsiteActionCreators";
import Loader from "../components/shared/Loader.react";
import HelpSection from "../components/shared/HelpSection.react";
import Sortable from "../components/Sortable.react";
import TicketingSeatsioFormPane from "./TicketingSeatsioFormPane.react";

class TicketingContainer extends Component {

  constructor(props) {
    super(props);
    [
      "changeStandardField",
      "addTicket",
      "removeTicket",
      "changeTicketField",
      "onSwitch",
      "onSwitchField",
      "createOrUpdateTicketingCategory",
      "handleSelectChange",
      "handleTicketDrop",
      "changeSeatsioEventKey",
      "setTicketsAttributesFromSeatsioCategories",
      "toggleSeatsioPane"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    const { match } = this.props;
    this.state = Object.assign({}, props.defaultState, {
      isEdit: match.url.endsWith("/edit"),
      seatsioFormPaneOpen: false
    });
  }

  componentDidMount() {
    const { fetchTicketing, match, fetchRegistrationForms, fetchWebsite } = this.props;
    const { params } = match;
    fetchRegistrationForms(urlEventId(), 1, 100);
    if (params.ticketing_id) {
      fetchTicketing(urlEventId(), params.ticketing_id);
      fetchWebsite(urlEventId());
    }
  }

  componentDidUpdate(prevProps) {
    const { ticketingCategory } = this.props;

    // after create ticketing
    if (!isEmpty(ticketingCategory) && isEmpty(prevProps.ticketingCategory)) {
      this.setState(Object.assign({}, this.filterTicketingParamsFrom(ticketingCategory)));
    }

    // after update ticketing
    if (!isEmpty(prevProps.ticketingCategory) && ticketingCategory.updated_at != prevProps.ticketingCategory.updated_at) {
      this.setState(Object.assign({}, this.filterTicketingParamsFrom(ticketingCategory)));
    }
  }

  activateModerationOnCreation(ticketingCategory) {
    ticketingCategory.moderated_registrations_enabled = true;
    ticketingCategory.tickets_attributes.forEach(e => {
      e.moderated_registrations_enabled = true;
    });
  }

  createOrUpdateTicketingCategory(e) {
    e.preventDefault();
    const { createTicketing, updateTicketing, match } = this.props;
    const { params } = match;
    const ticketingParams = this.filterTicketingParamsFrom(this.state);

    // ensure `seatsio_category_key` is not set when seats.io is disabled
    if (!this.state.seatsio_enabled) {
      ticketingParams.tickets_attributes.forEach(t => t.seatsio_category_key = null);
    }

    if (!params.ticketing_id) {
      if (this.state.payment_enabled || this.state.seatsio_enabled) {
        this.activateModerationOnCreation(ticketingParams);
      }
      ticketingParams.population_type = "order";
      ticketingParams.tickets_attributes.forEach(e => e.population_type = "visitor");
      createTicketing(urlEventId(), ticketingParams, pathToTicketingEdit(":id"), { notice: I18n.t("react.ticketing.form.created"), noticeType: "success" });
    } else {
      updateTicketing(urlEventId(), params.ticketing_id, ticketingParams, null, { notice: I18n.t("react.ticketing.form.updated"), noticeType: "success" });
    }
    window.scrollTo(0, 0);
  }

  filterTicketingParamsFrom(state) {
    const fields = [
      "_id", "name", "created_at", "updated_at", "payment_enabled", "zoho_enabled",
      "badge_enabled", "confirmation_email_enabled", "confirmation_email_for_ticket", "display_prices_excl_taxes",
      "fields_for_ticketing_category", "fields_for_ticket", "registration_form_id", "seatsio_enabled", "seatsio_event_key"];

    const ticketFields = [
      "_id", "name", "created_at", "updated_at", "type", "event_id", "max_tickets_per_registration", "min_tickets_per_registration", "label_color",
      "price", "tax", "badge_enabled", "registration_form_id", "ticket_rank", "_destroy", "moderated_registrations_enabled", "seatsio_category_key"];

    let payload = fields.reduce((acc, field) => {
      acc[field] = state[field];
      return acc;
    }, {});

    const ticketsAttributes = state.tickets_attributes.map(item => {
      delete item.key;

      const ticketPayload = ticketFields.reduce((acc, field) => {
        acc[field] = item[field];
        return acc;
      }, {});

      return Object.assign({}, ticketPayload, {
        confirmation_email_enabled: state.confirmation_email_for_ticket,
        payment_enabled: state.payment_enabled,
        custom_registration_form_enabled: true,
        seatsio_enabled: state.seatsio_enabled,
        seatsio_event_key: state.seatsio_event_key,
        automatic_removal_of_pending_guests_enabled: state.seatsio_enabled
      });
    });
    payload.tickets_attributes = ticketsAttributes;
    payload.custom_registration_form_enabled = true;
    return payload;
  }

  changeStandardField(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSwitch(element, active) {
    this.setState({
      [element.props.id]: active,
      seatsioFormPaneOpen: element.props.id === "seatsio_enabled" && active
    });
  }

  onSwitchField(key, field) {
    return (element, active) => {
      const fieldsConfig = Object.assign({}, this.state[key]);
      fieldsConfig[field][element.props.id] = active;
      this.setState({ [key]: fieldsConfig });
    };
  }

  tooltip(name) {
    return (
      <Tooltip id="tooltip">{name}</Tooltip>
    );
  }

  addTicket(e) {
    e.preventDefault();
    const { tickets_attributes, payment_enabled } = this.state;
    const currentMaxRank = tickets_attributes.length > 0 ? Math.max.apply(Math, tickets_attributes.map(t => t.ticket_rank)) : 0;
    const ticketRank = currentMaxRank + 10000;
    this.setState({
      tickets_attributes: concat(tickets_attributes,
        Object.assign({}, this.props.ticketDefaultState, { key: Math.floor(Math.random() * 1000), ticket_rank: ticketRank, moderated_registrations_enabled: payment_enabled })
      )
    });
  }

  removeTicket(ticketKey) {
    return () => {
      const { tickets_attributes } = this.state;
      let newTickets = tickets_attributes.slice();
      newTickets.find((ticket) => ticket.key == ticketKey)["_destroy"] = "1";
      this.setState({ tickets_attributes: newTickets });
    };
  }

  changeTicketField(ticketKey) {
    return (e) => {
      const [field, value] = [e.target.name, e.target.value];
      this.updateTicket(ticketKey, field, value);
    };
  }

  changeSeatsioEventKey(seatsioEventKey) {
    this.setState({ seatsio_event_key: seatsioEventKey });
  }

  setTicketsAttributesFromSeatsioCategories(chartCategories) {
    if (!chartCategories) return;

    const { tickets_attributes, payment_enabled } = this.state;
    let ticketsAttributes = tickets_attributes.slice();
    // first we mark all tickets types as detroyable
    ticketsAttributes.forEach(ticketType => ticketType._destroy = "1");

    // then we loop through all seats.io categories to decide what tickets types we need to create or update
    chartCategories.forEach((chartCategory, index) => {
      // we want to avoid deleting existing tickets types as much as possible, so we update existing tickets types when :
      // - there is a match through `seatsio_category_key` (already mapped before)
      // - or a match through `name` (unmapped yet)
      let ticketType = ticketsAttributes.find(t => t.seatsio_category_key == chartCategory.key || t.name === chartCategory.label);

      if (ticketType) {
        // on update, we override some of the most important properties
        ticketType.name = chartCategory.label;
        ticketType.seatsio_category_key = chartCategory.key;
        ticketType.label_color = convertColorToHexa(chartCategory.color);
        ticketType.ticket_rank = index * 1000;
        delete ticketType._destroy; // no longer destroyable
      } else {
        // on create, we need to set all properties
        ticketType = { ...this.props.ticketDefaultState,
          key: chartCategory.key,
          name: chartCategory.label,
          seatsio_category_key: chartCategory.key,
          label_color: convertColorToHexa(chartCategory.color),
          ticket_rank: index * 1000,
          moderated_registrations_enabled: payment_enabled
        };
        ticketsAttributes.push(ticketType);
      }
    });

    this.setState({ tickets_attributes: ticketsAttributes });
  }

  toggleSeatsioPane() {
    const { seatsioFormPaneOpen } = this.state;
    this.setState({ seatsioFormPaneOpen: !seatsioFormPaneOpen });
  }

  renderAddTicketButton() {
    const { seatsio_enabled } = this.state;

    if (seatsio_enabled) return;

    return <div className="btn-table-add-items">
      <a href="#" className="btn btn-secondary" onClick={this.addTicket}>
        <i className='fa-regular fa-plus'></i> {I18n.t("react.ticketing.form.add_ticket")}
      </a>
    </div>;
  }

  renderTicketsTable() {
    const ticketLines = this.renderTickets();
    const { seatsio_enabled } = this.state;

    if (ticketLines.props.children.length == 0) return this.renderAddTicketButton();

    return <div className="table-responsive table-container">
      <table className="table table-light table-striped table-bordered" id="tickets">
        <thead>
          <tr>
            { seatsio_enabled || <th></th> }
            <th>{I18n.t("react.ticketing.form.ticket_category_header")}</th>
            { seatsio_enabled || <th>{I18n.t("react.ticketing.form.min_tickets_header")}</th> }
            <th>{I18n.t("react.ticketing.form.max_tickets_header")}</th>
            <th>{I18n.t("react.ticketing.form.ticket_price_header")}</th>
            <th>{I18n.t("react.ticketing.form.ticket_tax_header")}</th>
            { seatsio_enabled || <th>{I18n.t("react.ticketing.form.ticket_actions_header")}</th> }
          </tr>
        </thead>
        {ticketLines}
      </table>
      { this.renderAddTicketButton() }
    </div>;
  }

  updateTicket(ticketKey, field, value) {
    const { tickets_attributes } = this.state;
    let newTickets = tickets_attributes.slice();
    let ticket = newTickets.find((ticket) => ticket.key == ticketKey);
    ticket[field] = value;
    this.setState({ tickets_attributes: newTickets });
  }

  renderTicketLine(ticket) {
    const { payment_enabled, seatsio_enabled } = this.state;
    return (
      <tr key={ticket.key}>
        { seatsio_enabled || <td className="text-center"></td> }
        <td className="col-4">
          <Form.Control type="text" name="name" value={ticket.name} placeholder={I18n.t("react.ticketing.form.ticket_name_placeholder")} onChange={this.changeTicketField(ticket.key)} />
        </td>
        { seatsio_enabled ||
          <td>
            <InputGroup>
              <InputGroup.Text>{I18n.t("react.ticketing.form.min")}</InputGroup.Text>
              <Form.Control type="number" name="min_tickets_per_registration" value={ticket.min_tickets_per_registration} onChange={this.changeTicketField(ticket.key)} />
            </InputGroup>
          </td>
        }
        <td>
          <InputGroup>
            <InputGroup.Text>{I18n.t("react.ticketing.form.max")}</InputGroup.Text>
            <Form.Control type="number" name="max_tickets_per_registration" value={ticket.max_tickets_per_registration} onChange={this.changeTicketField(ticket.key)} />
          </InputGroup>
        </td>
        <td>
          <Form.Control type="text" name="price" value={payment_enabled ? ticket.price : 0} placeholder={I18n.t("react.ticketing.form.price")} disabled={!payment_enabled} onChange={this.changeTicketField(ticket.key)} />
        </td>
        <td>
          <InputGroup>
            <Form.Control type="text" name="tax" value={payment_enabled ? ticket.tax : 0} placeholder={I18n.t("react.ticketing.form.vat")} disabled={!payment_enabled} onChange={this.changeTicketField(ticket.key)} />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </td>
        { seatsio_enabled ||
          <td className="text-center">
            <span className="btn btn-secondary" onClick={this.removeTicket(ticket.key)}><i className='fa-regular fa-trash-can'></i></span>
          </td>
        }
      </tr>
    );
  }

  renderTickets() {
    const { tickets_attributes } = this.state;

    const ticketsToDisplay = tickets_attributes.filter(ticket => {
      return !ticket._destroy; // no need to display tickets marked for destruction
    });

    const rows = ticketsToDisplay.reduce((acc, ticket) => {
      if (ticket.key == undefined)
        ticket.key = ticket._id;
      acc.push(this.renderTicketLine(ticket));
      return acc;
    }, []);

    return <Sortable
      itemIdKey="key"
      itemIndexKey="ticket_rank"
      dragType={DragTypes.TICKETS}
      items={ticketsToDisplay}
      handleDrop={this.handleTicketDrop}
      mode="table"
      handleColumnIndex={0}
      fullyDraggable={true}>
      {rows}
    </Sortable>;
  }

  handleTicketDrop(previousItemId, itemId, nextItemId, estimatedIndex) {
    const { tickets_attributes } = this.state;
    let newTicketsRanking = tickets_attributes.slice();

    const itemIndex = tickets_attributes.findIndex(av => av._id === itemId);
    let newItem = Object.assign({}, newTicketsRanking[itemIndex]);
    newItem = Object.assign({}, newItem, { ticket_rank: estimatedIndex });
    newTicketsRanking[itemIndex] = newItem;

    const sortFunction = (a, b) => {
      if (a.ticket_rank > b.ticket_rank) {
        return 1;
      } else if (a.ticket_rank < b.ticket_rank) {
        return -1;
      } else {
        return 0;
      }
    };
    newTicketsRanking = newTicketsRanking.sort(sortFunction);
    this.setState({ tickets_attributes: newTicketsRanking });
  }

  renderInformationFields() {
    return StaticGuestFieldsForTicketing.map(field => {
      return (
        <tr key={field}>
          <td>
            <OverlayTrigger placement="top" overlay={this.tooltip(field)}>
              <span className='badge rounded-pill bg-secondary big long-badge'>{I18n.t(`mongoid.attributes.guest.${field}`)}</span>
            </OverlayTrigger>
          </td>
          <td className="text-center col-1">
            {this.renderSwitchForField("fields_for_ticketing_category", field, "visible")}
          </td>
          <td className="text-center col-1">
            {this.renderSwitchForField("fields_for_ticketing_category", field, "required")}
          </td>
          <td className="text-center col-1">
            {this.renderSwitchForField("fields_for_ticket", field, "visible")}
          </td>
          <td className="text-center col-1">
            {this.renderSwitchForField("fields_for_ticket", field, "required")}
          </td>
        </tr>
      );
    });
  }

  renderSwitchForField(key, field, type) {
    if (key == "fields_for_ticket" && ["payment_promo_code", "vat_number"].indexOf(field) != -1)
      return; // tickets can not have promo code or vat_number

    const fieldState = this.state[key] && this.state[key][field] && this.state[key][field][type];

    return <Switch id={type} onColor="success" size="small" onText={I18n.t("yyes")}
      offText={I18n.t("nno")} handleWidth={65} labelWidth={20}
      value={fieldState} onChange={this.onSwitchField(key, field)} />;
  }

  renderNewTicketingButton() {
    const { website, ticketingCategory } = this.props;
    const { isEdit } = this.state;
    if (isEdit) {
      const customDomain = website && website.custom_domain;
      return <div className="header-page-btn col-md-6">
        <a href={pathToTicketingNew()} className="btn btn-primary">{I18n.t("react.ticketing.form.create_new")}</a>
        <DropdownButton variant="secondary" title={I18n.t("react.ticketing.form.more_actions")} rootCloseEvent="click" id="more-actions" align="end">
          {customDomain ? <DropdownItem href={pathToWebsiteView(customDomain, `registration/${ticketingCategory._id}`)} target="_blank">{I18n.t("react.ticketing.form.preview_ticketing")}</DropdownItem> : null}
          <DropdownItem href={pathToWebsiteBuilderPage(ticketingCategory.registration_form_page_id)} target="_blank">{I18n.t("react.ticketing.form.configure_website")}</DropdownItem>
        </DropdownButton>
      </div>;
    }
  }

  renderNoticeBlock() {
    const { notice, noticeType } = this.props;
    if (notice.length == 0)
      return null;

    const classes = classNames({
      "alert": true,
      "alert-success": noticeType == "success",
      "alert-danger": noticeType == "error"
    });

    return <div className={classes}>{notice}</div>;
  }

  handleSelectChange(ticketKey = null) {
    return (e) => {
      if (e.target.value == "newCategory") {
        this.setState({ gcCreatedForTicketKey: ticketKey });
        return;
      }
      if (ticketKey) this.changeTicketField(ticketKey)(e);
      else this.changeStandardField(e);
    };
  }

  renderZohoConfigurationHint() {
    const { zoho_enabled, isEdit } = this.state;
    const { ticketingCategory } = this.props;

    if (!zoho_enabled || !isEdit || !ticketingCategory) return null;

    const helpMessage = <>{I18n.t("react.ticketing.form.zoho_config_link_hint")} <a href={`${pathToGuestCategoryZohoConfiguration(ticketingCategory._id)}`} target="_blank">{I18n.t("react.ticketing.form.here")}</a></>;

    return <HelpSection help={helpMessage} classNames="mt-10" />;
  }

  renderZohoConfig() {
    if (!isEnabled(INVOICING)) return;
    const { payment_enabled, zoho_enabled } = this.state;

    return <div className="mb-3">
      <div className="subtitle-page">
        <div className="subtitle-page-title">
          <h2>{I18n.t("react.ticketing.form.invoicing")}</h2>
        </div>
      </div>

      <Form.Group>
        <Form.Label>{I18n.t("react.ticketing.form.invoice")}</Form.Label><br />
        <Switch id="zoho_enabled" onColor="success" size="small" onText={I18n.t("yyes")} offText={I18n.t("nno")}
          handleWidth={80} labelWidth={20} value={zoho_enabled} disabled={!payment_enabled} onChange={this.onSwitch} />
      </Form.Group>
      { zoho_enabled && <div>{this.renderZohoConfigurationHint()}</div> }
    </div>;
  }

  renderTicketingFormInformationNew() {
    return <div className="mb-3">
      <div className="subtitle-page">
        <div className="subtitle-page-title">
          <h2>{I18n.t("react.ticketing.form.registration")}</h2>
        </div>
      </div>

      <div className="table-responsive table-container">
        <table className="table table-light table-striped table-bordered">
          <thead>
            <tr>
              <th></th>
              <th colSpan="2" className="text-center">{I18n.t("react.ticketing.form.purchaser")}</th>
              <th colSpan="2" className="text-center">{I18n.t("react.ticketing.form.tickets")}</th>
            </tr>
            <tr>
              <th></th>
              <th className="text-center">{I18n.t("react.ticketing.form.visible")}</th>
              <th className="text-center">{I18n.t("react.ticketing.form.required")}</th>
              <th className="text-center">{I18n.t("react.ticketing.form.visible")}</th>
              <th className="text-center">{I18n.t("react.ticketing.form.required")}</th>
            </tr>
          </thead>
          <tbody>
            {this.renderInformationFields()}
          </tbody>
        </table>
      </div>
    </div>;
  }

  registrationFormSelectOptions() {
    const { registrationForms } = this.props;
    return (registrationForms || []).filter(rf => !rf.is_ticketing_form).map(rf =>
      <option key={rf._id} value={rf._id}>{rf.title}</option>
    );
  }

  // If ticket is null, we are setting the main guest registration_form
  renderFormBuilderSelect(ticket = null) {
    if (!ticket) return null;

    const registrationFormId = ticket.registration_form_id;

    return <select className="form-select" name="registration_form_id" onChange={this.handleSelectChange(ticket.key)} value={registrationFormId || ""}>
      <option value="">{I18n.t("react.ticketing.form.select")}</option>
      {this.registrationFormSelectOptions()}
    </select>;
  }

  renderConfigurationLine(ticket = null) {
    if (ticket && (!ticket.created_at || ticket._destroy == "1")) return null; // do not display unpersisted ticket

    const { registration_form_id, confirmation_email_enabled } = this.state;
    const { ticketingCategory } = this.props;
    const style = { verticalAlign: "middle" };
    const guestCategoryId = ticket ? ticket._id : ticketingCategory._id;
    const registrationFormId = ticket ? ticket.registration_form_id : registration_form_id;
    let hasConfirmationEmail = confirmation_email_enabled;
    if (ticket) hasConfirmationEmail = ticket.confirmation_email_enabled;

    return <tr key={`edit-configuration-${ticket ? ticket.key : "purchaser"}`}>
      <td key="gc-name" style={style}>
        <span className="badge rounded-pill" style={{ backgroundColor: (ticket ? ticket.label_color : ticketingCategory.label_color), fontSize: "90%" }}>
          {ticket ? ticket.name : ticketingCategory.name}
        </span>
        {!ticket && <small> {I18n.t("react.ticketing.form.purchaser")}</small>}
      </td>
      <td key="fb-select" style={style}>{this.renderFormBuilderSelect(ticket)}</td>
      <td key="fb-config-link" style={style}>
        <div style={{ textAlign: "center" }}>
          <DropdownButton variant="secondary" title={<i className="fa-regular fa-gear" />} rootCloseEvent="click" id={`action-dropdown-${ticket ? ticket.key : "purchaser"}`} align="end">
            <DropdownItem href={pathToRegistrationForm(registrationFormId)} target="_blank">{I18n.t("react.ticketing.form.registration_setup")}</DropdownItem>
            <DropdownItem href={pathToGuestCategoryShow(guestCategoryId)} target="_blank">{I18n.t("react.ticketing.form.guest_category_setup")}</DropdownItem>
            <DropdownItem href={pathToBadgeConfiguration(guestCategoryId)} target="_blank">{I18n.t("react.ticketing.form.badge_setup")}</DropdownItem>
            {hasConfirmationEmail && isEnabled(CONFIRMATION_EMAIL) ? <DropdownItem href={pathToConfirmationEmailConfiguration(guestCategoryId)} target="_blank">{I18n.t("react.ticketing.form.email_setup")}</DropdownItem> : null}
          </DropdownButton>
        </div>
      </td>
    </tr>;
  }

  renderConfigurationByTicket() {
    const { tickets_attributes } = this.state;
    let lines = [];

    // First the purchaser line
    lines.push(this.renderConfigurationLine());

    // Then we add tickets lines
    tickets_attributes.forEach(ticket => {
      lines.push(this.renderConfigurationLine(ticket));
    });

    return lines;
  }

  renderTicketingFormInformationEdit() {
    return <div className="mb-3">
      <div className="subtitle-page">
        <div className="subtitle-page-title">
          <h2>{I18n.t("react.ticketing.form.guest_categories_configuration")}</h2>
        </div>
      </div>

      <div className="table-container">
        <table className="table table-light table-striped table-bordered">
          <thead>
            <tr>
              <th className="text-center col-md-5">{I18n.t("react.ticketing.form.ticket_guest_category_name")}</th>
              <th className="text-center col-md-5">{I18n.t("react.ticketing.form.ticket_form_builder_select")}</th>
              <th className="text-center col-md-2">{I18n.t("react.ticketing.form.ticket_form_builder_configure_link")}</th>
            </tr>
          </thead>
          <tbody>
            {this.renderConfigurationByTicket()}
          </tbody>
        </table>
      </div>
    </div>;
  }

  renderTicketingFormInformation() {
    const { isEdit } = this.state;
    return isEdit ? this.renderTicketingFormInformationEdit() : this.renderTicketingFormInformationNew();
  }

  renderPaymentConfigurationHint() {
    const { payment_enabled, isEdit } = this.state;
    const { ticketingCategory } = this.props;

    if (!payment_enabled || !isEdit || !ticketingCategory) return null;

    const helpMessage = <>
      <span>{I18n.t("react.ticketing.form.payment_link_hint")} <a href={`${pathToGuestCategoryPayment(ticketingCategory._id)}#merchant-type`} target="_blank">{I18n.t("react.ticketing.form.here")}</a></span>
      <br />
      <span>{I18n.t("react.ticketing.form.devise_hint")}</span>
    </>;

    return <HelpSection help={helpMessage} classNames="mt-10" />;
  }

  stillWaitingAjaxRequest() {
    const { isBeingCreated, isBeingUpdated } = this.props;
    return isBeingCreated || isBeingUpdated;
  }

  render() {
    const { name, payment_enabled, display_prices_excl_taxes, confirmation_email_enabled, confirmation_email_for_ticket,
      seatsio_enabled, seatsio_event_key, isEdit, seatsioFormPaneOpen, tickets_attributes } = this.state;
    const { errors, isBeingCreated } = this.props;

    if (this.stillWaitingAjaxRequest()) {
      const translationKey = isBeingCreated ? "ticketing_pending_create" : "ticketing_pending_update";
      return <Loader size="large" inline={false} containerHeight="500px" message={I18n.t(`react.loader.${translationKey}`)} />;
    }

    return <div>
      <ErrorMessage errors={errors} model="guest_category" />
      <form onSubmit={this.createOrUpdateTicketingCategory}>
        {this.renderNoticeBlock()}

        <div className="header-page">
          <div className="header-page-content row">
            <div className="header-page-title col-md-6">
              <h1>
                <a href={pathToTicketing()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></a>
                {isEdit ? I18n.t("react.ticketing.form.title_edit") : I18n.t("react.ticketing.form.title")}
              </h1>
            </div>
            {this.renderNewTicketingButton()}
          </div>
        </div>

        <div className="row mt-30">
          <div className="col-sm-6">
            <Form.Group controlId="name">
              <Form.Label>{I18n.t("react.ticketing.form.name")}</Form.Label>
              <Form.Control type="text" name="name" value={name} placeholder={I18n.t("react.ticketing.form.name_placeholder")} onChange={this.changeStandardField} />
            </Form.Group>
          </div>

          <div className="col-sm-6">
            <div className="row">
              <div className="col-sm-4">
                <Form.Group>
                  <Form.Label>{I18n.t("react.ticketing.form.payment")}</Form.Label><br />
                  <Switch id="payment_enabled" onColor="success" onText={I18n.t("react.ticketing.form.payment_yes")} offText={I18n.t("react.ticketing.form.payment_no")}
                    handleWidth={80} labelWidth={20} value={payment_enabled} onChange={this.onSwitch} /><br />
                </Form.Group>
              </div>
              <div className="col-sm-4">
                {payment_enabled &&
                  <Form.Group>
                    <Form.Label>{I18n.t("react.ticketing.form.display_prices_excl_taxes")}</Form.Label><br />
                    <Switch id="display_prices_excl_taxes" onColor="success" onText={I18n.t("yyes")} offText={I18n.t("nno")}
                      handleWidth={80} labelWidth={20} value={display_prices_excl_taxes} onChange={this.onSwitch} /><br />
                  </Form.Group>
                }
              </div>
              { isEnabled(SEATSIO) &&
                <div className="col-sm-4">
                  <Form.Group>
                    <Form.Label>{I18n.t("react.ticketing.form.seatsio")}</Form.Label><br />
                    <Switch id="seatsio_enabled" onColor="success" onText={I18n.t("yyes")} offText={I18n.t("nno")}
                      handleWidth={80} labelWidth={20} value={seatsio_enabled} onChange={this.onSwitch} /><br />
                    { seatsio_enabled && <a className="form-text" onClick={this.toggleSeatsioPane} style={{ cursor: "pointer" }}>{ I18n.t("react.ticketing.form.setup_seatsio") }</a> }
                  </Form.Group>
                  <TicketingSeatsioFormPane
                    seatsioEventKey={seatsio_event_key}
                    ticketsTypes={tickets_attributes}
                    onChange={this.changeSeatsioEventKey}
                    onValidateChartCategories={this.setTicketsAttributesFromSeatsioCategories}
                    onClose={this.toggleSeatsioPane}
                    isOpen={seatsioFormPaneOpen} />
                </div>
              }
            </div>
            <div className="row">
              <div className="col-sm-12">
                {this.renderPaymentConfigurationHint()}
              </div>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <div className="subtitle-page">
            <div className="subtitle-page-title">
              <h2>{I18n.t("react.ticketing.form.tickets_types")}</h2>
            </div>
          </div>

          {this.renderTicketsTable()}
          {this.renderTicketingFormInformation()}
        </div>

        <div className="mb-3">
          <div className="subtitle-page">
            <div className="subtitle-page-title">
              <h2>{I18n.t("react.ticketing.form.confirmation_emails")}</h2>
            </div>
          </div>

          <div className="mt-10 row">
            <div className="col-md-6">
              <Form.Group>
                <Form.Label>{I18n.t("react.ticketing.form.confirmation_for_purchaser")}</Form.Label><br />
                <Switch id="confirmation_email_enabled" onColor="success" size="small" onText={I18n.t("yyes")} offText={I18n.t("nno")}
                  handleWidth={80} labelWidth={20} value={confirmation_email_enabled} onChange={this.onSwitch} />
              </Form.Group>
            </div>

            <div className="col-md-6">
              <Form.Group>
                <Form.Label>{I18n.t("react.ticketing.form.confirmation_for_tickets")}</Form.Label><br />
                <Switch id="confirmation_email_for_ticket" onColor="success" size="small" onText={I18n.t("yyes")} offText={I18n.t("nno")}
                  handleWidth={80} labelWidth={20} value={confirmation_email_for_ticket} onChange={this.onSwitch} />
              </Form.Group>
            </div>
          </div>
        </div>

        {this.renderZohoConfig()}

        <input type="submit" className="btn btn-primary" value={isEdit ? I18n.t("react.ticketing.form.submit_edit") : I18n.t("react.ticketing.form.submit_new")} />
      </form>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    ticketingCategory: state.ticketingCategory.data,
    registrationForms: state.registrationForms.data,
    website: state.website.data,
    isBeingCreated: state.ticketingCategory.isBeingCreated,
    isBeingUpdated: state.ticketingCategory.isBeingUpdated,
    errors: state.ticketingCategory.errors,
    notice: state.notifications.currentNotice,
    noticeType: state.notifications.noticeType
  };
}

const mapDispatchToProps = {
  fetchTicketing,
  fetchRegistrationForms,
  createTicketing,
  updateTicketing,
  fetchWebsite
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketingContainer);

TicketingContainer.defaultProps = {
  isEdit: false,
  defaultState: {
    name: "",
    type: "ticketing",
    payment_enabled: true,
    display_prices_excl_taxes: false,
    badge_enabled: true,
    tickets_attributes: [],
    zoho_enabled: false,
    confirmation_email_enabled: true,
    confirmation_email_for_ticket: true,
    registration_form_id: null,
    seatsio_enabled: false,
    seatsio_event_key: null,
    fields_for_ticketing_category: {
      first_name: { visible: true, required: false },
      last_name: { visible: true, required: false },
      email: { visible: true, required: true },
      company_name: { visible: true, required: false },
      position: { visible: true, required: false },
      phone_number: { visible: true, required: false },
      address: { visible: true, required: false },
      country: { visible: true, required: false },
      payment_promo_code: { visible: false, required: false },
      vat_number: { visible: false, required: false }
    },
    fields_for_ticket: {
      first_name: { visible: false, required: false },
      last_name: { visible: false, required: false },
      email: { visible: true, required: true },
      company_name: { visible: true, required: false },
      position: { visible: true, required: false },
      phone_number: { visible: true, required: false },
      address: { visible: true, required: false },
      country: { visible: true, required: false }
    }
  },
  ticketDefaultState: {
    name: "",
    type: "ticket",
    event_id: urlEventId(),
    max_tickets_per_registration: 10,
    min_tickets_per_registration: 0,
    price: "",
    tax: "",
    badge_enabled: true,
    seatsio_category_key: null
  }
};
