import { Component } from "react";
import PropTypes from "prop-types";

import truncate from "lodash/truncate";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class CategoryCell extends Component {

  constructor(props) {
    super(props);
    this.selectGuestCategory = this.selectGuestCategory.bind(this);
  }

  selectGuestCategory(guestCategoryId) {
    const { setFilteredGuestCategories } = this.props;
    return (e) => {
      e.preventDefault();
      setFilteredGuestCategories([guestCategoryId]);
    };
  }

  render() {
    const { guest } = this.props;
    const categoryTooltip = <Tooltip id="tooltip">{guest.guest_category_name}</Tooltip>;
    return (
      <td className="cell-default d-none d-sm-table-cell">
        <a href="#" className="label-link" onClick={this.selectGuestCategory(guest.guest_category_id)}>
          <OverlayTrigger placement="top" overlay={categoryTooltip}>
            <span className="badge rounded-pill" style={{ backgroundColor: guest.guest_category_color }}>
              {truncate(guest.guest_category_name, { "length": 30 })}
            </span>
          </OverlayTrigger>
        </a>
      </td>
    );
  }
}


CategoryCell.defaultProps = {
  setFilteredGuestCategories: () => {}
};

CategoryCell.propTypes = {
  guest: PropTypes.object.isRequired,
  setFilteredGuestCategories: PropTypes.func.isRequired
};

export default CategoryCell;
