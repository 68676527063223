import { Component } from "react";
import ThematicsDropdown from "../containers/ThematicsDropdown.react";
import Select, { components } from "react-select";
import { multiValueRemoveStyle } from "../constants/Style";

export const QUARTILES = [
  { value: "no", label: "none" },
  { value: "low", label: "low" },
  { value: "moderate", label: "moderate" },
  { value: "high", label: "high" },
  { value: "extreme", label: "extreme" }
];

interface Props {
  selectedThematicsIds: string[];
  onThematicsChange(selectedThematicsIds: string[]): void;
  selectedQuartilesIds: string[];
  onQuartilesChange(selectedQuartilesIds: string[]): void;
  displayInputsAlert?: boolean;
}

interface Option {
  value: string;
  label: string;
}

export const QuartileOption = (option: Option): JSX.Element => {
  const quartileLabel = I18n.t(`react.reports.guest_thematics_quartiles.guest_quartiles.${option.label}_value`);
  return <span className="badge bg-secondary rounded-pill">{quartileLabel}</span>;
};

export const QuartileMultiValueContainer = (props: any): JSX.Element => {
  const innerProps = { ...props.innerProps, className: props.innerProps.className.concat(" badge bg-secondary rounded-pill") };
  return <components.MultiValueContainer {...props} innerProps={innerProps} />;
};

export const QuartileMultiValueLabel = (props: any): JSX.Element => {
  return <span>{ I18n.t(`react.reports.guest_thematics_quartiles.guest_quartiles.${props.data.label}_value`) }</span>;
};

export const quartileCustomStyles = {
  multiValue: (base: any): any => ({
    ...base,
    fontSize: "100%"
  }),
  multiValueRemove: (base: any): any => ({
    ...base,
    ...multiValueRemoveStyle
  })
};

export class GuestsThematicsQuartilesFilter extends Component<Props> {

  constructor(props) {
    super(props);
    [
      "renderInputAlert"
    ].forEach((item) => { this[item] = this[item].bind(this); });
  }

  createSelectedValues(items: Option[]): string[] {
    return items ? items.map(item => item.value) : [];
  }

  renderInputAlert(): JSX.Element {
    return (
      <div className="mt-5" style={{ color: "red", fontWeight: "bold" }}>
        { I18n.t("react.reports.guest_thematics_quartiles.input_alert") }
      </div>
    );
  }

  render(): JSX.Element {
    const { selectedThematicsIds, onThematicsChange, selectedQuartilesIds, onQuartilesChange, displayInputsAlert } = this.props;
    return (
      <div className="mb-3 row">
        <div className="col-md-5 col-12">
          <Select
            className="react-select"
            classNamePrefix="react-select"
            value={QUARTILES.filter(({ value }) => selectedQuartilesIds.includes(value))}
            isMulti={true}
            options={QUARTILES}
            onChange={ (items: Option[]): any => onQuartilesChange(this.createSelectedValues(items)) }
            placeholder={ I18n.t("react.reports.guest_thematics_quartiles.filter_quartiles_placeholder") }
            noOptionsMessage={(): string => I18n.t("react.reports.no_levels")}
            closeMenuOnSelect={false}
            formatOptionLabel={QuartileOption}
            components={{ MultiValueContainer: QuartileMultiValueContainer, MultiValueLabel: QuartileMultiValueLabel }}
            styles={quartileCustomStyles}
            menuPlacement="top"
          />
        </div>
        <div className="col-md-2 col-12 d-flex flex-column justify-content-center">
          <span className="text-center" style={{ whiteSpace: "nowrap" }}>
            { I18n.t("react.reports.guest_thematics_quartiles.filter_interested_by") }
          </span>
        </div>
        <div className="col-md-5 col-12">
          <ThematicsDropdown
            followHierarchyOnSelect={false}
            onChange={ (items: Option[]): any => onThematicsChange(this.createSelectedValues(items)) }
            value={selectedThematicsIds}
            coloredThematics={true}
            menuPlacement="top"
          />
        </div>
        { displayInputsAlert && this.renderInputAlert() }
      </div>
    );
  }
}

export default GuestsThematicsQuartilesFilter;
