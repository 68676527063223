import { useState, useEffect } from "react";
import { FabricEditorFunctions } from "../fabricjs/types";
import { fabric } from "fabric";
import { BadgeFont, BadgeImage } from "../../../types/BadgeTemplate";
import PositionProperties from "./PositionProperties.react";
import ContentProperties from "./ContentProperties.react";
import TextProperties from "./TextProperties.react";
import Toolbar from "./Toolbar.react";
import { DEFAULT_EDITOR_PROPERTIES, BADGE_IMAGE_POSITIONS } from "../Constants";

interface Props {
  badgeTemplateId: string;
  newBackgroundKey: string;
  selectedObjects?: fabric.Object[];
  editor?: FabricEditorFunctions;
  badgeImages: BadgeImage[];
  badgeFonts: BadgeFont[];
  imageFormatSettings: any;

  onChangeImage(badgeImage: BadgeImage): void;
  onDeleteImage(position: string): void;
}

function i18n(key, opts = {}): string {
  return I18n.t(`react.badge_builder.properties_panel.${key}`, opts);
}

const PropertiesPanel: React.FC<Props> = ({
  badgeTemplateId,
  newBackgroundKey,
  selectedObjects,
  editor,
  badgeImages,
  badgeFonts,
  imageFormatSettings,
  onChangeImage,
  onDeleteImage,
}) => {
  if (!editor) return <>{i18n("loading")}</>;

  const positionIsReadOnly = BADGE_IMAGE_POSITIONS.includes(selectedObjects[0]?.imageKey);
  const sizeIsReadOnly = selectedObjects?.length > 0 && selectedObjects[0].group || selectedObjects[0]?.type === "image" || positionIsReadOnly;

  const [textValue, setTextValue] = useState<string>("");
  const [leftValue, setLeftValue] = useState<string>("");
  const [topValue, setTopValue] = useState<string>("");
  const [heightValue, setHeightValue] = useState<string>("");
  const [widthValue, setWidthValue] = useState<string>("");
  const [fillValue, setFillValue] = useState<string>(DEFAULT_EDITOR_PROPERTIES["fontColor"]);
  const [fontFamilyValue, setFontFamilyValue] = useState<string>("");
  const [fontSizeValue, setFontSizeValue] = useState<number>(DEFAULT_EDITOR_PROPERTIES["fontSize"]);
  const [textAlignValue, setTextAlignValue] = useState<string>(DEFAULT_EDITOR_PROPERTIES["textAlign"]);

  const STATE_VALUE_MAP: any = {
    text: textValue,
    left: leftValue,
    top: topValue,
    height: heightValue,
    width: widthValue,
    fill: fillValue,
    fontSize: fontSizeValue,
    fontFamily: fontFamilyValue,
    textAlign: textAlignValue,
  };

  const SET_STATE_MAP: any = {
    text: setTextValue,
    left: setLeftValue,
    top: setTopValue,
    height: setHeightValue,
    width: setWidthValue,
    fill: setFillValue,
    fontFamily: setFontFamilyValue,
    fontSize: setFontSizeValue,
    textAlign: setTextAlignValue,
  };

  const clearAllValues = (): void => {
    setTextValue("");
    setLeftValue("0");
    setTopValue("0");
    setHeightValue("0");
    setWidthValue("0");
    setFillValue(DEFAULT_EDITOR_PROPERTIES["fontColor"]);
    setFontFamilyValue("");
    setFontSizeValue(DEFAULT_EDITOR_PROPERTIES["fontSize"]);
    setTextAlignValue(DEFAULT_EDITOR_PROPERTIES["textAlign"]);
  };

  const setAllValues = (selectedObject: fabric.Object): void => {
    const { left, top, text, height, width, scaleX, scaleY, fill, fontFamily, fontSize, textAlign } = selectedObject;
    setLeftValue(left.toString());
    setTopValue(top.toString());
    setHeightValue(height.toString());
    setWidthValue(width.toString());

    if (selectedObject.type === "textbox") {
      setTextValue(text.toString());
      setFillValue(fill.toString());
      setFontFamilyValue(fontFamily.toString());
      setFontSizeValue(fontSize);
      setTextAlignValue(textAlign.toString());
    } else if (selectedObject.type === "image") {
      setHeightValue((height * scaleY).toString());
      setWidthValue((width * scaleX).toString());
    }
  };

  useEffect(() => {
    if (selectedObjects?.length === 1) {
      // represents a selection that has just been modified with the mouse
      if (selectedObjects[0].type === "activeSelection") {
        setLeftValue(selectedObjects[0].left.toString());
        setTopValue(selectedObjects[0].top.toString());
      } else {
        setAllValues(selectedObjects[0]);
      }
    } else if (selectedObjects?.length > 1) {
      if (selectedObjects[0].type === "textbox") {
        const firstText = selectedObjects[0].text;
        const allTextSame = selectedObjects.every((obj: fabric.Object) => obj.text === firstText);
        setTextValue(allTextSame ? firstText : i18n("mixed"));
      }
      setLeftValue(selectedObjects[0].group.left);
      setTopValue(selectedObjects[0].group.top);
      setHeightValue("0");
      setWidthValue("0");
    } else {
      clearAllValues();
    }
  }, [selectedObjects]);

  const canEraseMixedValue = (value: string, e): boolean => {
    if (value === i18n("mixed") && e.nativeEvent.inputType === "deleteContentBackward") return true;

    return value.includes(i18n("mixed"));
  };

  const updateElementsPropertyFromGroup = (property: string, e): void => {
    const { value } = e.target;
    const stateValue = STATE_VALUE_MAP[property];
    const newValue = canEraseMixedValue(stateValue, e) ? "" : value;
    if (newValue !== i18n("mixed")) {
      selectedObjects.forEach((selectedObject: fabric.Object) => {
        editor?.updateProperty(selectedObject, property, newValue, ["text", "textAlign", "fontSize", "fill"].includes("text") ? false : true);
      });
      const setState = SET_STATE_MAP[property];
      setState(newValue);
    }
  };

  const updatePadding = (property: string, e): void => {
    const { value } = e.target;
    editor?.updateProperty(selectedObjects[0].group ? selectedObjects[0].group : selectedObjects[0], property, value, true);
    const setState = SET_STATE_MAP[property];
    setState(value);
  };

  const updateSingleElementProperty = (property: string, e) : void => {
    const { value } = e.target;
    if (!selectedObjects[0].group) {
      editor?.updateProperty(selectedObjects[0], property, value, true);
      const setState = SET_STATE_MAP[property];
      setState(value);
    }
  };

  const handleChange = (property: string) => (e): void => {
    e.preventDefault && e.preventDefault();
    const { value } = e.target;
    const setState = SET_STATE_MAP[property];

    if (selectedObjects?.length > 0) {
      switch (property) {
      case "text":
      case "fill":
      case "textAlign":
      case "fontSize":
      case "fontFamily":
        if (selectedObjects[0].group) {
          updateElementsPropertyFromGroup(property, e);
        } else {
          editor?.updateProperty(selectedObjects[0], property, value, ["fontSize"].includes(property));
          setState(value);
        }
        break;
      case "alignment":
        if (selectedObjects[0].group) {
          editor?.updateGroupAlignment(selectedObjects, value);
        }
        break;
      case "left":
      case "top":
        updatePadding(property, e);
        break;
      case "height":
      case "width":
        updateSingleElementProperty(property, e);
        break;
      }
    } else {
      clearAllValues();
    }
  };

  const selectedObjectsIsAGroup = selectedObjects?.length > 0 && selectedObjects[0].group;
  return <div id="properties-panel" className="mt-20">
    <Toolbar
      editor={editor}
      selectedObjects={selectedObjects}
      onDeleteImage={onDeleteImage}
    />
    <PositionProperties
      topValue={topValue}
      leftValue={leftValue}
      heightValue={heightValue}
      widthValue={widthValue}
      sizeIsReadOnly={sizeIsReadOnly}
      positionIsReadOnly={positionIsReadOnly}
      alignmentIsReadOnly={!selectedObjectsIsAGroup}
      handleChange={handleChange}
    />
    {(newBackgroundKey || ["textbox", "image"].includes(selectedObjects[0]?.type)) &&
      <ContentProperties
        newBackgroundKey={newBackgroundKey}
        textValue={textValue}
        selectedObjects={selectedObjects}
        badgeImages={badgeImages}
        imageFormatSettings={imageFormatSettings}
        onChangeText={(e): void => handleChange("text")(e)}
        onChangeImage={onChangeImage}
        onDeleteImage={onDeleteImage}
      />
    }
    {selectedObjects[0]?.type === "textbox" &&
      <TextProperties
        badgeTemplateId={badgeTemplateId}
        badgeFonts={badgeFonts}
        fillValue={fillValue}
        fontFamilyValue={fontFamilyValue}
        fontSizeValue={fontSizeValue}
        textAlignValue={textAlignValue}
        handleChange={handleChange}
      />}
  </div>;
};

export default PropertiesPanel;
