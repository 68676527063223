import { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import difference from "lodash/difference";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import GuestFieldForm from "./GuestFieldForm.react";
import { createGuestField, fetchGuestFieldTypes } from "../../actions/GuestFieldsActionCreators";
import { defaultModalBigStyles } from "../../constants/Style";

class GuestFieldsModal extends Component {

  componentDidMount() {
    const { event, fetchGuestFieldTypes, guestFieldsTypes } = this.props;
    if (isEmpty(guestFieldsTypes)) {
      fetchGuestFieldTypes(event._id);
    }
  }

  componentDidUpdate(prevProps) {
    const { guestFields, onCloseFunction, updateParent } = this.props;
    if (prevProps.isVisible && prevProps.guestFields.length != guestFields.length) {
      onCloseFunction();
      const newGuestField = difference(guestFields, prevProps.guestFields)[0];
      if (newGuestField) {
        updateParent(newGuestField);
      }
    }
  }

  onSubmit() {
    const { createGuestField, event } = this.props;
    return (guestField) => {
      createGuestField(event._id, guestField);
    };
  }

  guestFieldsTypesExcluded() {
    const { guestFieldTypeAllowed, guestFieldsTypes } = this.props;
    if (!guestFieldTypeAllowed) {
      return [];
    }
    return guestFieldsTypes.filter(gf => !guestFieldTypeAllowed.includes(gf));
  }

  render() {
    const { isVisible, guestFieldErrors, guestFieldsTypes, onCloseFunction, event } = this.props;
    return (
      <Modal isOpen={isVisible} onRequestClose={onCloseFunction} style={defaultModalBigStyles} contentLabel="Modal">
        <div className="modal-header">
          <h4 className="modal-title">{ I18n.t("react.guest_field.form.create_title") }</h4>
          <button type="button" onClick={onCloseFunction} className="btn-close" aria-label={I18n.t("close")}></button>
        </div>
        <div className="modal-body">
          <GuestFieldForm
            event={event}
            isModal={true}
            errors={guestFieldErrors}
            guestFieldsTypes={guestFieldsTypes}
            onSubmit={this.onSubmit()}
            guestFieldsTypesExcluded={this.guestFieldsTypesExcluded()}
          />
        </div>
      </Modal>
    );
  }
}

GuestFieldsModal.propTypes = {
  isVisible: PropTypes.bool,
  guestFieldsTypes: PropTypes.array.isRequired,
  errors: PropTypes.object,
  onCloseFunction: PropTypes.func,
  updateParent: PropTypes.func,
  guestFieldTypeAllowed: PropTypes.array
};


GuestFieldsModal.defaultProps = {
  isVisible: false
};


function mapStateToProps(state) {
  return {
    guestFields: state.guestFields.guestFields,
    guestFieldsTypes: state.guestFields.types,
    guestFieldErrors: state.guestFields.errors
  };
}

const mapDispatchToProps = {
  createGuestField,
  fetchGuestFieldTypes
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestFieldsModal);
