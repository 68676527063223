import { Component } from "react";
import { connect } from "react-redux";
import { pathToRegistrationForm, pathToGuestCategoriesList } from "../utils/pathUtils";
import { requestEvent } from "../actions/ImportActionCreators";
import * as actions from "../actions/RegistrationFormsActionCreators";
import { fetchGuestCategories } from "../actions/GuestCategoryActionCreators";
import { fetchEventsFromUrl } from "../actions/EventActionCreators";
import Paginate from "../components/Paginate.react";
import Loader from "../components/shared/Loader.react";
import TargetEventModal from "../components/duplication_modals/TargetEventModal.react";
import AskModelNameModal from "../components/duplication_modals/AskModelNameModal.react";
import DuplicationRunningModal from "../components/duplication_modals/DuplicationRunningModal.react";
import GuestCategoriesAndTicketsLinked from "../components/shared/GuestCategoriesAndTicketsLinked.react";
import RegistrationFormCreationButtons from "./registration_form/RegistrationFormCreationButtons.react.js";
import { redirectIfDisabled } from "../utils/featureSetUtils";
import { REGISTRATION_FORM } from "../constants/FeaturesSet";
const PER_PAGE = 20;

class RegistrationForms extends Component {

  constructor(props) {
    redirectIfDisabled(REGISTRATION_FORM);
    super(props);
    [
      "goToPage",
      "openSelectEventModal",
      "duplicateIntoEvent",
      "onEventTargetSelected",
      "fetchMoreEvents"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      currentRegistrationFormId: null,
      showDuplicationRunningModal: false,
      duplicateTargetEventId: null,
      showEventPickerModal: false,
      showNewRegistrationNameModal: false
    };
  }

  componentDidMount() {
    const { fetchRegistrationForms, fetchGuestCategories } = this.props;

    fetchGuestCategories(this.eventId());
    fetchRegistrationForms(this.eventId(), 1, PER_PAGE);
    requestEvent();
  }

  componentDidUpdate(prevProps) {
    const { duplicationErrors } = this.props;
    const { showDuplicationRunningModal } = this.state;

    if (showDuplicationRunningModal && Object.keys(prevProps.duplicationErrors).length == 0 && Object.keys(duplicationErrors).length > 0) {
      this.setState({
        showNewRegistrationNameModal: true,
        showDuplicationRunningModal: false
      });
    }
  }

  openSelectEventModal(formId) {
    return (e) => {
      e.preventDefault();
      this.setState({ showEventPickerModal: true, currentRegistrationFormId: formId });
    };
  }

  goToPage(url) {
    return (e) => {
      e.preventDefault();
      this.props.fetchRegistrationFormsPage(url);
    };
  }

  eventId() {
    return this.props.match.params.event_id;
  }

  fetchMoreEvents() {
    const { fetchEventsFromUrl, eventsNextURL } = this.props;
    fetchEventsFromUrl(eventsNextURL);
  }

  renderTableRow(form) {
    const { guestCategories } = this.props;

    return (
      <tr key={form._id}>
        <td>
          <a href={pathToRegistrationForm(form._id)}>
            <span className="badge rounded-pill bg-secondary">{form.title}</span>
          </a>
        </td>
        <td>
          <GuestCategoriesAndTicketsLinked form={form} guestCategories={guestCategories} />
        </td>
        <td>
          {I18n.l("time.formats.long", form.created_at)}
        </td>
        <td>
          <a href="#" className="btn btn-secondary btn-sm" onClick={this.openSelectEventModal(form._id)}>
            <i className="fa-regular fa-clone"></i> {I18n.t("react.registration_forms.duplicate")}
          </a>
        </td>
      </tr>
    );
  }

  renderTable() {
    const { registrationForms } = this.props;

    return (
      <div className="table-responsive table-container">
        <table className="table table-light table-bordered table-hover">
          <thead>
            <tr>
              <th>{I18n.t("react.registration_forms.form_name")}</th>
              <th>{I18n.t("react.registration_forms.category")}</th>
              <th>{I18n.t("react.registration_forms.form_created_at")}</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {registrationForms.map(form => this.renderTableRow(form))}
          </tbody>
        </table>
      </div>
    );
  }

  onEventTargetSelected(targetEventId) {
    this.setState({
      showEventPickerModal: false,
      showNewRegistrationNameModal: true,
      duplicateTargetEventId: targetEventId
    });
  }

  duplicateIntoEvent(newName) {
    this.setState({
      showNewRegistrationNameModal: false,
      showDuplicationRunningModal: true
    });
    const { duplicateRegistrationForm } = this.props;
    const { duplicateTargetEventId, currentRegistrationFormId } = this.state;
    const formParam = {
      new_title: newName,
      target_event_id: duplicateTargetEventId
    };
    duplicateRegistrationForm(currentRegistrationFormId, formParam);
  }

  renderCopyModals() {
    const { event, duplicationErrors, duplicatedForm, duplicationWarnings, eventsNextURL } = this.props;
    const { showEventPickerModal, showNewRegistrationNameModal, showDuplicationRunningModal, duplicateTargetEventId } = this.state;

    const formattedDuplicationsWarning = duplicationWarnings.reduce((acc, warning) => {
      acc[warning] = [I18n.t("react.registration_forms.duplicate_warning", { key: warning })];
      return acc;
    }, {});

    return (
      <div>
        <TargetEventModal
          isVisible={showEventPickerModal}
          onClose={() => { this.setState({ showEventPickerModal: false }); }}
          currentEvent={event}
          fetchMoreEvents={eventsNextURL ? this.fetchMoreEvents : null}
          targetReadableName={I18n.t("react.registration_forms.target_name")}
          onEventSelected={this.onEventTargetSelected} />

        <AskModelNameModal
          isVisible={showNewRegistrationNameModal}
          onClose={() => { this.setState({ showNewRegistrationNameModal: false }); }}
          onSubmit={this.duplicateIntoEvent}
          i18nRootKey="registration_form"
          errors={duplicationErrors} />

        <DuplicationRunningModal
          isVisible={showDuplicationRunningModal}
          onClose={() => { this.setState({ showDuplicationRunningModal: false }); }}
          model="registration_form"
          currentEventId={event._id}
          targetEventId={duplicateTargetEventId}
          duplicatedData={duplicatedForm}
          duplicationWarnings={formattedDuplicationsWarning} />
      </div>
    );
  }

  renderRegistrationFormsList() {
    const { nextURL, previousURL } = this.props;

    return [
      <div className="row" key="header">
        <div className="col-12">
          <Paginate handlePrevious={this.goToPage(previousURL)}
            handleNext={this.goToPage(nextURL)}
            previousEnabled={!!previousURL}
            nextEnabled={!!nextURL}
            pull="end"
            classNames="m-0"
          />
        </div>
      </div>,
      <div className="row" key="table">
        <div className="col-12">
          {this.renderTable()}
        </div>
      </div>
    ];
  }

  renderNoRegistrationForms() {
    return <div className="card nothing-yet">
      <div>
        <RegistrationFormCreationButtons />
      </div>
    </div>;
  }

  render() {
    const { loading, registrationForms } = this.props;

    if (loading) {
      return <Loader />;
    }

    return (
      <div>
        <div className="header-page">
          <div className="header-page-content row">
            <div className="header-page-title col-md-6">
              <h1>
                <a href={pathToGuestCategoriesList()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></a>
                {I18n.t("react.registration.manage_forms")}
              </h1>
            </div>
            <div className="header-page-btn col-md-6">
              <RegistrationFormCreationButtons />
            </div>
          </div>
        </div>
        {registrationForms.length > 0 ? this.renderRegistrationFormsList() : this.renderNoRegistrationForms()}
        {this.renderCopyModals()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { guestCategories, registrationForms, event, events } = state;

  return {
    guestCategories: guestCategories.data,
    loading: registrationForms.loading,
    nextURL: registrationForms ? registrationForms.nextURL : null,
    previousURL: registrationForms ? registrationForms.previousURL : null,
    registrationForms: registrationForms.data,
    duplicatedForm: registrationForms.duplicated,
    duplicationErrors: registrationForms.duplicationErrors,
    duplicationWarnings: registrationForms.duplicationWarnings,
    event: event,
    eventsNextURL: events.nextURL
  };
}

const mapDispatchToProps = Object.assign({}, {
  requestEvent,
  fetchEventsFromUrl,
  fetchGuestCategories
}, actions);

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForms);
