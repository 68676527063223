import { Component } from "react";

class ConditionalDisplay extends Component {
  conditionalDisplayCheckbox() {
    const { conditionalDisplay, isPrivate, isPublicOnly, onChangeVisibilitySettings } = this.props;

    return <div className="form-check" key="conditional_display">
      <label className="form-check-label">
        <input type="checkbox" className="form-check-input" name="conditional_display" checked={ isPrivate || isPublicOnly || conditionalDisplay } onChange={ onChangeVisibilitySettings } />
        { I18n.t("react.builders.conditional_display") }
      </label>
    </div>;
  }

  privateOrOnlyPublicRadioButtons() {
    const { conditionalDisplay, isPrivate, isPublicOnly, onChangeVisibilitySettings } = this.props;
    if (!conditionalDisplay) return null;
    return <div className="mb-3" key="visibilitySettings">
      <label className="form-label">{ I18n.t("react.builders.visibility_options") }</label>
      <div className="form-check" key="private">
        <label className="form-check-label">
          <input type="radio" className="form-check-input" name="private" value="private" key="private" checked={ isPrivate } onChange={ onChangeVisibilitySettings } />
          { I18n.t("react.builders.only_connected_people") }
        </label>
      </div>
      <div className="form-check" key="public_only">
        <label className="form-check-label">
          <input type="radio" className="form-check-input" name="public_only" value="public_only" key="public_only" checked={ isPublicOnly } onChange={ onChangeVisibilitySettings } />
          { I18n.t("react.builders.only_not_connected_people") }
        </label>
      </div>
    </div>;
  }

  render() {
    return <div>
      { this.conditionalDisplayCheckbox() }
      { this.privateOrOnlyPublicRadioButtons() }
    </div>;
  }
}

export default ConditionalDisplay;
