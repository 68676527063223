import merge from "lodash/merge";

import NoticeActionCreators from "../actions/NoticeActionCreators";
import { updateUI, errorUpdateUI } from "../actions/UIAppearanceActionCreators";
import { receiveExports } from "../actions/RequestExportsActionCreators";
import { receiveGuests, startFetchingGuests, clearSelectedGuests, receiveGuestsError, reloadGuestsPage } from "../actions/GuestListActionCreators";
import { receiveEvent } from "../actions/EventActionCreators";
import { urlEventId } from "./pathUtils";
import { receiveGuestCategories, receiveGuestCategoriesFailure, receiveGuestCategoriesRequest } from "../actions/GuestCategoryActionCreators";
import { normalizeIdInArray } from "./identifierUtils";
import { cleanUpQueryString } from "./QueryStringUtils";
import { stringify } from "querystring";

function _start(method, path, body, sendFile, cbSuccess, cbFailure) {
  let options = {
    url: injectLocaleInPath(path),
    type: method,
    data: body
  };

  if ((method == "POST" || method == "PUT") && !sendFile) {
    //passing a real JSON object in the body
    options.data = JSON.stringify(body);
    options.dataType = "json";
    options.contentType = "application/json";
  }

  if (sendFile) {
    options.processData = false;
    options.contentType = false;
  }

  const req = $.ajax(options);

  req.done(function(data, status, response) {
    cbSuccess(data, status, response);
  });
  req.fail(function(data, status, response) {
    cbFailure(data, status, response);
  });
}

function injectLocaleInPath(path) {
  const locale = I18n.locale;
  if (!locale) {
    return path;
  }
  if (path.includes("?")) {
    return `${path}&locale=${locale}`;
  }
  return `${path}?locale=${locale}`;
}

function _injectAPIV1Path(path) {
  return `/api/v1/${path}`;
}

function _injectEventPath(path) {
  return `/events/${urlEventId()}/${path}`;
}

function _injectEventAndAPIV1Path(path) {
  return _injectAPIV1Path(_injectEventPath(path));
}

function _cleanupSearchQuery(query) {
  return cleanUpQueryString(query);
}

function _fetchGuests(dispatch, completeSearchQuery, page, options, cbSuccess) {
  const uniqueKeyInSiloedData = options["uniqueKeyInSiloedData"];
  delete options["uniqueKeyInSiloedData"];
  const body = {
    q: _cleanupSearchQuery(completeSearchQuery),
    badges: true,
    identity: true,
    secret: true,
    files: true,
    documents: true,
    page: page,
    per_page: 50,
    guest_metadata: true,
    ...options
  };
  if (!options.skipReset) dispatch(startFetchingGuests());
  _start("GET", _injectEventAndAPIV1Path("guests.json"), body, false,
    function(data, _status, response) {
      dispatch(receiveGuests(data, _extractLink("previous", response), _extractLink("next", response), uniqueKeyInSiloedData));
      if (cbSuccess) cbSuccess();
    },
    function() { // failure
      dispatch(receiveGuestsError());
    }
  );
}

function _countGuests(dispatch, completeSearchQuery, appearanceFieldTarget, count_all) {
  const searchQuery = _cleanupSearchQuery(completeSearchQuery);
  _start("GET", _injectEventPath("guests/count.json"), { q: searchQuery, count_all: count_all }, false,
    function(data) {
      dispatch(updateUI({ [appearanceFieldTarget]: data }));
    },
    function() { // failure
      dispatch(errorUpdateUI());
    }
  );
}

function _extractLink(rel, response) {
  const link = response.getResponseHeader("Link");
  if (!link) {
    return null;
  }
  const nextLink = link.split(",").find(s => s.indexOf(`rel="${rel}"`) > -1);
  if (!nextLink) {
    return null;
  }

  return nextLink.split(";")[0].slice(1, -1);
}

module.exports = {

  //for middleware use
  start(method, path, body, sendFile, cbSuccess, cbFailure) {
    _start(method, path, body, sendFile, cbSuccess, cbFailure);
  },

  extractLink(rel, response) {
    return _extractLink(rel, response);
  },

  extractTotalCount(response) {
    return response.getResponseHeader("TotalCount");
  },

  deleteGuests(dispatch, completeSearchQuery) {
    const body = { q: _cleanupSearchQuery(completeSearchQuery) };
    _start("DELETE", _injectEventPath("delete_guests"), body, false, function(data) {
      dispatch(NoticeActionCreators.showNotice(data.message));
    });
  },

  exportBadges(dispatch, completeSearchQuery, fileNaming = "last_name", oneBadgePerPage = false) {
    _start("GET", _injectEventPath("guests/create_badges_archive.json"), { search: _cleanupSearchQuery(completeSearchQuery), file_naming: fileNaming, one_badge_per_page: oneBadgePerPage }, false, function(data) {
      //success
      dispatch(NoticeActionCreators.showNotice(data.message));
    }, function() {
      //failure
      dispatch(NoticeActionCreators.showNotice(I18n.t("react.reports.no_guest_categories_selected"), "alert"));
    });
  },

  exportQRCodes(dispatch, completeSearchQuery, fileNaming = "last_name") {
    _start("GET", _injectEventPath("guests/create_qr_code_archive.json"), { search: _cleanupSearchQuery(completeSearchQuery), file_naming: fileNaming }, false,
      function(data) {
        dispatch(NoticeActionCreators.showNotice(data.message));
      }, function(data) {
        dispatch(NoticeActionCreators.showNotice(data.responseText, "danger"));
      });
  },

  exportDocumentsArchive(dispatch, completeSearchQuery) {
    _start("GET", _injectEventPath("guests/create_documents_archive.json"), { search: _cleanupSearchQuery(completeSearchQuery) }, false, function(data) {
      dispatch(NoticeActionCreators.showNotice(data.message));
    });
  },

  exportGuestList(dispatch, sheets, exportFormat, completeSearchQuery, options = {}) {
    const body = Object.assign({ sheets: sheets, export_format: exportFormat, search: _cleanupSearchQuery(completeSearchQuery) }, options);
    _start("POST", _injectEventPath("guest_export_operations.json"), body, false,
      function(data) {
        //success
        dispatch(NoticeActionCreators.showNotice(data.message));
      }, function(data) {
        //failure
        dispatch(NoticeActionCreators.showNotice(data.responseJSON.message));
      }
    );
  },

  fetchGuestCategories(dispatch, page = 0) {
    const body = {};
    if (page !== 0) {
      body["page"] = page;
      body["per_page"] = 50;
    }
    dispatch(receiveGuestCategoriesRequest());
    _start("GET", _injectEventPath("guest_categories.json"), body, false,
      function(data, _status, response) {
        dispatch(receiveGuestCategories(normalizeIdInArray(data), _extractLink("previous", response), _extractLink("next", response)));
      }, function() {
        dispatch(receiveGuestCategoriesFailure());
      }
    );
  },

  fetchExports(dispatch) {
    _start("GET", _injectEventPath("exports.json"), null, false, function(data) {
      dispatch(receiveExports(data));
    });
  },

  fetchGuests(dispatch, completeSearchQuery, page = 1, options = {}) {
    _fetchGuests(dispatch, completeSearchQuery, page, options, null);
  },

  fetchEvent(dispatch, options = {}) {
    const req = $.ajax({
      url: `/${I18n.currentLocale()}/events/${urlEventId()}.json?${stringify(options)}`,
      type: "GET"
    });
    req.done(function(data) {
      dispatch(receiveEvent(data));
    });
  },

  //TODO: take count_all into account
  countGuests(dispatch, completeSearchQuery, appearanceFieldTarget = "selectedGuestCount", countAll = false) {
    const searchQuery = _cleanupSearchQuery(completeSearchQuery);
    _countGuests(dispatch, searchQuery, appearanceFieldTarget, countAll);
  },

  sendBulkAction(dispatch, guests, action, completeSearchQuery, page, options) {
    let body = { current_action: action, guests: guests };
    merge(body, options);
    _start("POST", _injectEventPath("guests/bulk_action.json"), body, false, function(data) {
      dispatch(NoticeActionCreators.showNotice(data.message));
      if (action === "delete") {
        dispatch(reloadGuestsPage());
        return;
      }
      _fetchGuests(dispatch, completeSearchQuery, page, {}, function() {
        dispatch(clearSelectedGuests());
      });
      _countGuests(dispatch, completeSearchQuery, "guestsCount", false);
    });
  },

  cleanUniqueKeyInSiloedData(dispatch, actionType, uniqueKeyInSiloedData) {
    dispatch({
      type: actionType,
      uniqueKeyInSiloedData: uniqueKeyInSiloedData
    });
  }
};
