import { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import querystring from "querystring";

import { fetchGuestsStatistics } from "../../actions/GuestsStatisticsActionCreators";
import { attendanceDerivedData } from "../../selectors/guestsStatisticsSelector";
import { eventDates } from "../../utils/DateUtils";

import ReportHeader from "../../components/event_reports/ReportHeader.react";
import SummaryReportTable from "../../components/event_reports/SummaryReportTable.react";
import Loader from "../../components/shared/Loader.react";
import { redirectIfUnauthorized, isAuthorized } from "../../utils/aclUtils";

const FIELD = "guest_category_id";

class AttendanceSummary extends Component {

  constructor(props) {
    redirectIfUnauthorized("reports", "read");
    super(props);
    [
      "changeQuery"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = { query: querystring.parse(this.props.location.search.substring(1)).query };
  }

  componentDidMount() {
    const { event } = this.props;
    this.fetchStatistics(event);
  }

  componentDidUpdate(prevProps, prevState) {
    const { query } = this.state;
    if (prevState.query != query) {
      this.fetchStatistics(this.props.event, query);
    }

    const { event } = this.props;
    if ((!prevProps.event || !prevProps.event._id) && (event && event._id)) {
      this.fetchStatistics(event);
    }
  }

  fetchStatistics(event, query = this.state.query) {
    const { fetchGuestsStatistics } = this.props;
    const field = FIELD;
    const stat_types = "count,new_visits,returning";
    const dates = eventDates(event);
    if (!dates) return;

    dates.forEach(date => {
      const fDate = moment(date).format("YYYY-MM-DD");
      fetchGuestsStatistics(event.id, { field, stat_types, date: fDate, q: `include_takeout ${query || ""}` });
    });
  }

  getStats() {
    const { stats, event } = this.props;
    const dates = eventDates(event);
    let totals = [];

    let total = { count: 0, new_visits: 0, no_shows: 0, returning: 0, transformation_rate: 0, visits: 0 };
    let keys = Object.keys(total);

    dates.forEach(date => {
      const fDate = moment(date).format("YYYY-MM-DD");
      if (stats[event._id] && stats[event._id][FIELD] && stats[event._id][FIELD][fDate]) {
        totals[fDate] = stats[event._id][FIELD][fDate]["total"];
        keys.forEach(key => {
          if (key == "count") {
            total[key] = totals[fDate][key];
          } else if (key == "no_shows") {
            total[key] = total["count"] - total["new_visits"];
          } else {
            total[key] = total[key] + totals[fDate][key];
          }
        });
      }
    });

    totals["total"] = total;
    return totals;
  }

  changeQuery(segment) {
    const nextQuery = segment ? segment.search_query : null;
    if (nextQuery === this.state.query) return;
    this.setState({ query: nextQuery });
  }

  renderLoader() {
    return <Loader />;
  }

  renderHeader() {
    const { query } = this.state;
    const segmentPickerProps = { onChangeQuery: this.changeQuery, query: query, event_id: this.props.match.params.event_id };
    return <ReportHeader title={I18n.t("react.event_reports.event_reports.attendance_summary")} segmentPickerProps={segmentPickerProps} />;
  }

  renderTable() {
    const { event } = this.props;
    let dates = eventDates(event);
    const stats = this.getStats();
    if (Object.keys(stats).length == dates.length + 1) {
      return <SummaryReportTable
        stats={stats}
        dates={dates}
        query={`include_takeout ${this.state.query || ""}`}
      />;
    }

    return this.renderLoader();
  }

  render() {
    if (!isAuthorized("reports", "read")) return;
    return (
      <div>
        { this.renderHeader() }
        { this.renderTable() }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    stats: attendanceDerivedData(state),
    event: state.event
  };
}

const mapDispatchToProps = {
  fetchGuestsStatistics
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceSummary);
