import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSessionTypes, createSessionType } from "../actions/SessionTypesActionCreators";
import { urlEventId } from "../utils/pathUtils";
import SessionTypesDropdownComponent from "../components/SessionTypesDropdownComponent.react";

interface Option {
  value: string;
}

interface Props {
  value: string;
  allowCreate?: boolean;

  onSelect: (options: Option[]) => void;
}

const SessionTypesDropdown: React.FC<Props> = (props) => {
  const sessionTypes = useSelector((state: any) => state.sessionTypes.items);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sessionTypes || sessionTypes.length === 0) {
      dispatch(fetchSessionTypes(urlEventId()));
    }
  }, []);

  const onCreate = props.allowCreate ? (
    (name: string): void => {
      dispatch(createSessionType(urlEventId(), { name }));
    }
  ) : null;

  return <SessionTypesDropdownComponent {...props} sessionTypes={sessionTypes || []} onCreate={onCreate} />;
};

export default SessionTypesDropdown;
