import { Component } from "react";
import PropTypes from "prop-types";

class CheckinpointList extends Component {

  constructor(props) {
    super(props);
    [ "renderAccesspoint"].forEach(item => {
      this[item] = this[item].bind(this);
    });
  }

  renderAccesspoint() {
    const { accesspoint, shouldBeDisplayed } = this.props;
    const disabledClass = shouldBeDisplayed ? "" : "disabled";
    return (
      <input type="text" className={`form-control ${disabledClass}`} rel="field-label" value={accesspoint.name} readOnly />
    );
  }

  render() {
    const { mode, shouldBeDisplayed, accesspoint, overrideLabel } = this.props;
    const disabledClass = shouldBeDisplayed ? "" : "disabled";
    const accesspointNode = (mode == "edit") ? this.renderAccesspoint() : null;
    const key = Object.keys(overrideLabel)[0];
    const label = accesspoint.traits[key] ? accesspoint.traits[key] : accesspoint.display_name;
    return (
      <div className="row mb-10">
        <div className="col-8">
          <input type="text" className={`form-control ${disabledClass}`} value={label} readOnly/>
        </div>
        <div className="col-4">
          { accesspointNode }
        </div>
      </div>
    );
  }
}

CheckinpointList.propTypes = {
  accesspoint: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  shouldBeDisplayed: PropTypes.bool.isRequired
};

export default CheckinpointList;
