
export type Notification = {
  currentNotice: string;
  noticeType: string;
};

interface Props {
  notification: Notification
}

const NotificationAlert: React.FC<Props> = ({
  notification
}) => {
  const { currentNotice, noticeType } = notification;

  if (!currentNotice || currentNotice.length === 0) return null;

  return (
    <div className={`alert alert-${noticeType}`}>
      {currentNotice}
    </div>
  );
};

export default NotificationAlert;
