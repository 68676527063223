import { ActionTypes } from "../constants/Constants";

export function updateSectionsOrder(orderedSections: any): any {
  return {
    type: ActionTypes.UPDATE_BUILDER_SECTIONS_ORDER,
    objects: orderedSections
  };
}

export function updateSection(sectionKey: string, sectionSettings: any, sectionChanges: any): any {
  return {
    type: ActionTypes.UPDATE_BUILDER_SECTION,
    sectionKey: sectionKey,
    settings: sectionSettings,
    changes: sectionChanges
  };
}

export function sendChangesNotification(): any {
  return {
    type: ActionTypes.RECEIVE_BUILDER_CHANGES
  };
}

export function createNewSection(sectionKey: string, sectionData: any): any {
  return {
    type: ActionTypes.CREATE_NEW_BUILDER_SECTION,
    sectionKey,
    sectionData
  };
}

export function removeSection(sectionKey: string): any {
  return {
    type: ActionTypes.REMOVE_BUILDER_SECTION,
    sectionKey
  };
}

export function createNewBlock(blockKey: string, blockData: any, sectionKey: string): any {
  return {
    type: ActionTypes.CREATE_NEW_BUILDER_BLOCK,
    blockKey,
    blockData,
    sectionKey
  };
}

export function removeBlock(blockKey: string, sectionKey: string): any {
  return {
    type: ActionTypes.REMOVE_BUILDER_BLOCK,
    blockKey,
    sectionKey
  };
}

export function clearBuilder(): any {
  return {
    type: ActionTypes.CLEAR_BUILDER
  };
}
