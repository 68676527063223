import { useEffect, useState, MouseEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import Loader from "../../components/shared/Loader.react";
import WorkflowStepCard from "../../components/automation/WorkflowStepCard.react";
import WorkflowNavbar from "../../components/automation/WorkflowNavbar.react";
import WorkflowVersionsModal from "../../components/automation/WorkflowVersionsModal.react";
import Slider from "./Slider.react";
import SwitchVersionModal from "./SwitchVersionModal.react";
import NoticeBlock from "../../components/templates_builder/NoticeBlock.react";
import { publishWorkflowVersion, updateWorkflow, fetchWorkflow, fetchWorkflowVersion, fetchWorkflowStrategy,
  fetchWorkflowVersions } from "../../actions/WorkflowsActionCreators";
import { addActionStep, addFilterStep, changeActiveStepId, deleteWorkflowStep, deleteUnpersistedWorkflowStep } from "../../actions/WorkflowItemsActionCreators";
import { redirectIfUnauthorized, isSuperAdmin } from "../../utils/aclUtils";
import { urlEventId } from "../../utils/pathUtils";
import { WorkflowStep, WorkflowStepType, WorkflowStepStrategy, SliderModes } from "../../types/Workflow";
import { hasAvailableFeature } from "../../utils/availableFeature";
import { WORKFLOW_STEP_TRIGGER, WORKFLOW_STEP_ACTION, WORKFLOW_STEP_FILTER, WORKFLOW_TRIGGER_SELECTION, WORKFLOW_TRIGGER_EDITION,
  WORKFLOW_ACTION_SELECTION, WORKFLOW_ACTION_EDITION, WORKFLOW_FILTER_EDITION } from "../../constants/Constants";

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.workflows.builder.${key}`, opts);
}

interface Props {
  workflowId: string;
}

const WorkflowVersionBuilder: React.FC<Props> = ({ workflowId }) => {
  redirectIfUnauthorized("configuration", "manage");

  // TODO : remove this condition once released
  if (!isSuperAdmin() && !hasAvailableFeature("automation")) {
    return null;
  }

  const [sliderMode, setSliderMode] = useState<SliderModes>(null);
  const [switchVersionModalDisplayed, setSwitchVersionModalDisplayed] = useState<boolean>(false);
  const [workflowVersionsModalDisplayed, setWorkflowVersionsModalDisplayed] = useState<boolean>(false);

  const workflow = useSelector((state: any) => state.workflowVersionBuilder.workflow);
  const workflowVersion = useSelector((state: any) => state.workflowVersionBuilder.workflowVersion);
  const workflowVersions = useSelector((state: any) => state.workflowVersionBuilder.workflowVersions);
  const orderedSteps = useSelector((state: any) => state.workflowVersionBuilder.orderedSteps);
  const activeStepId = useSelector((state: any) => state.workflowVersionBuilder.activeStepId);
  const strategiesGlobalConfigParams = useSelector((state: any) => state.workflowVersionBuilder.strategiesGlobalConfigParams);
  const isPendingRequest = useSelector((state: any) => state.workflowVersionBuilder.isPendingRequest);
  const isSwitchingVersion = useSelector((state: any) => state.workflowVersionBuilder.isSwitchingVersion);
  const notice = useSelector((state: any) => state.notifications.currentNotice);
  const noticeType = useSelector((state: any) => state.notifications.noticeType);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWorkflow(urlEventId(), workflowId));
  }, []);

  const addTriggerRequest = (e): void => {
    e.preventDefault();
    setSliderMode(WORKFLOW_TRIGGER_SELECTION);
  };

  const changeStrategyRequestHandler = (type: WorkflowStepType): (() => void) => {
    return (): void => {
      if (type === WORKFLOW_STEP_TRIGGER) {
        setSliderMode(WORKFLOW_TRIGGER_SELECTION);
      } else if (type === WORKFLOW_STEP_ACTION) {
        setSliderMode(WORKFLOW_ACTION_SELECTION);
      }
    };
  };

  const addActionRequest = (previousStep: WorkflowStep): void => {
    dispatch(addActionStep(previousStep._id, previousStep.next_step_id));
    setSliderMode(WORKFLOW_ACTION_SELECTION);
  };

  const addFilterRequest = (previousStep: WorkflowStep): void => {
    dispatch(addFilterStep(previousStep._id, previousStep.next_step_id));
    setSliderMode(WORKFLOW_FILTER_EDITION);
  };

  const afterStrategySelected = (type: WorkflowStepType, strategy: WorkflowStepStrategy): void => {
    if (type === WORKFLOW_STEP_TRIGGER) {
      setSliderMode(WORKFLOW_TRIGGER_EDITION);
    } else if (type === WORKFLOW_STEP_ACTION) {
      setSliderMode(WORKFLOW_ACTION_EDITION);
    }
    if (!strategiesGlobalConfigParams[strategy]) {
      dispatch(fetchWorkflowStrategy(urlEventId(), type, strategy));
    }
  };

  const onSwitchVersionRequest = (): void => {
    setSwitchVersionModalDisplayed(true);
    closeSidebar();
  };

  const removeStep = (workflowStep: WorkflowStep): void => {
    if ([workflowStep._id, workflowStep.temporaryId].includes(activeStepId)) {
      closeSidebar();
    }
    if (workflowStep.temporaryId) {
      dispatch(deleteUnpersistedWorkflowStep(workflowStep.temporaryId, workflowStep.next_step_id));
    } else {
      dispatch(deleteWorkflowStep(urlEventId(), workflowVersion._id, workflowStep._id, workflowStep.next_step_id, { noticeType: "success", notice: I18n.t("react.workflows.builder.notice.step_deleted") }));
    }
  };

  const closeSidebar = (): void => {
    setSliderMode(null);
    dispatch(changeActiveStepId(null));
  };

  const activeStep = (): WorkflowStep => {
    if (!activeStepId) return null;

    return orderedSteps.find((step: WorkflowStep) => {
      return (step._id && activeStepId === step._id) || (step.temporaryId && activeStepId === step.temporaryId);
    });
  };

  const changeActiveStep = (step: WorkflowStep): void => {
    if (step.type === WORKFLOW_STEP_TRIGGER) {
      setSliderMode(WORKFLOW_TRIGGER_EDITION);
    } else if (step.type === WORKFLOW_STEP_ACTION) {
      if (!step.strategy) {
        setSliderMode(WORKFLOW_ACTION_SELECTION);
      } else {
        setSliderMode(WORKFLOW_ACTION_EDITION);
      }
    } else if (step.type === WORKFLOW_STEP_FILTER) {
      setSliderMode(WORKFLOW_FILTER_EDITION);
    }
    if (step.type !== WORKFLOW_STEP_FILTER && step.strategy && !strategiesGlobalConfigParams[step.strategy]) {
      dispatch(fetchWorkflowStrategy(urlEventId(), step.type, step.strategy));
    }
    dispatch(changeActiveStepId(step._id || step.temporaryId));
  };

  const publishVersion = (name: string, description: string): void => {
    dispatch(publishWorkflowVersion(urlEventId(), workflowVersion._id, { name, description }));
  };

  const saveWorkflow = (attributes: any): void => {
    dispatch(updateWorkflow(urlEventId(), workflow._id, attributes));
  };

  const switchToOnlineVersion = (e: MouseEvent): void => {
    e.preventDefault();
    dispatch(fetchWorkflowVersion(urlEventId(), workflow.online_version_id));
    closeSidebar();
  };

  const displayWorkflowVersions = (): void => {
    dispatch(fetchWorkflowVersions(urlEventId(), workflow._id));
    setWorkflowVersionsModalDisplayed(true);
  };

  const versionBelongsToCurrentUser = (): boolean => {
    return workflowVersion.user_id === workflow.current_user_id;
  };

  const isVersionOnline = (): boolean => {
    return workflow.online_version_id === workflowVersion._id;
  };

  const isVersionPublished = (): boolean => {
    return !!workflowVersion.published_at;
  };

  const isVersionEditable = (): boolean => {
    return !isVersionPublished() && versionBelongsToCurrentUser();
  };

  const isWorkflowEditable = (): boolean => {
    return isVersionOnline() || versionBelongsToCurrentUser();
  };

  const isVersionPublishable = (): boolean => {
    const persistedStepTypes = orderedSteps.reduce((acc: string[], step: WorkflowStep) => {
      if (step._id) {
        acc.push(step.type);
      }
      return acc;
    }, []);
    return _.intersection(persistedStepTypes, [WORKFLOW_STEP_TRIGGER, WORKFLOW_STEP_ACTION]).length == 2;
  };

  const renderWorkflowSteps = (): JSX.Element => {
    return orderedSteps.map((step: WorkflowStep) => {
      return <WorkflowStepCard
        key={step._id || step.temporaryId}
        workflowStep={step}
        activeStepId={activeStepId}
        isPendingRequest={isPendingRequest}
        isVersionEditable={isVersionEditable()}
        allWorkflowSteps={orderedSteps}
        onClickStepCard={changeActiveStep}
        onAddActionStep={addActionRequest}
        onAddFilterStep={addFilterRequest}
        onRemoveStep={removeStep}
        onSwitchVersionRequest={onSwitchVersionRequest}
      />;
    });
  };

  const renderEmptyWorkflow = (): JSX.Element => {
    return <div className="text-center">
      <h2>{i18n("empty_workflow.title")}</h2>
      <p className="mb-30">{i18n("empty_workflow.subtitle")}</p>
      <a href="#" onClick={addTriggerRequest} className="btn btn-primary" role="button">
        <i className="fa-regular fa-plus"></i> {i18n("empty_workflow.add_first_trigger")}
      </a>
    </div>;
  };

  if (!workflowVersion || !workflow || isSwitchingVersion) {
    return <Loader size="large" inline={false} message={I18n.t("react.loader.loading")} />;
  }

  return <>
    <WorkflowNavbar
      workflow={workflow}
      workflowVersion={workflowVersion}
      isVersionOnline={isVersionOnline()}
      isVersionPublished={isVersionPublished()}
      isVersionPublishable={isVersionPublishable()}
      isWorkflowEditable={isWorkflowEditable()}
      versionBelongsToCurrentUser={versionBelongsToCurrentUser()}
      onWorkflowSave={saveWorkflow}
      publishVersion={publishVersion}
      switchToOnlineVersion={switchToOnlineVersion}
      onWorkflowVersionsRequest={displayWorkflowVersions}
    />
    <div className="workflow-builder container">
      <div className="row workflow-builder-row">
        <div className={ sliderMode ? "col-8 workflow-builder-main" : "col-12 workflow-builder-main" }>
          {orderedSteps.length ? renderWorkflowSteps() : renderEmptyWorkflow() }
        </div>
        <Slider
          workflowVersion={workflowVersion}
          workflowStep={activeStep()}
          sliderMode={sliderMode}
          isVersionEditable={isVersionEditable()}
          onChangeStrategyRequest={changeStrategyRequestHandler}
          onSelectStrategy={afterStrategySelected}
          onSwitchVersionRequest={onSwitchVersionRequest}
          onClose={closeSidebar}
        />
      </div>
      <NoticeBlock notice={notice} noticeType={noticeType} />
      <SwitchVersionModal
        workflow={workflow}
        isVisible={switchVersionModalDisplayed}
        onClose={(): void => setSwitchVersionModalDisplayed(false)}
      />
    </div>
    <WorkflowVersionsModal
      workflowVersions={workflowVersions}
      workflow={workflow}
      isVisible={workflowVersionsModalDisplayed}
      isPendingRequest={isPendingRequest}
      onClose={(): void => setWorkflowVersionsModalDisplayed(false)}
    />
  </>;
};

export default WorkflowVersionBuilder;
