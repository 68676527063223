import { Component } from "react";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { urlEventId } from "../utils/pathUtils";
import Icons from "../constants/Icons";
import { fetchMenu, updateMenu, updateMenuItemsOffline, clearSaveQueryStatus, clearMenuStore,
  updateMenuOffline, createMenuOffline, createMenu, deleteMenu } from "../actions/WebsiteMenuActionCreators";
import Loader from "../components/shared/Loader.react";
import classNames from "classnames";
import NoticeBlock from "../components/templates_builder/NoticeBlock.react";
import { showNotice } from "../actions/NoticeActionCreators";
import MenuSettings from "../../lib/builders/button_settings.json";
import SlidingPane from "react-sliding-pane";
import MenuItemsList from "../components/website/MenuItemsList.react.tsx";
import MenuItemOptionsForm from "../components/website/MenuItemOptionsForm.react.tsx";
import { translatedTextFromJson } from "../utils/templatesBuilderUtils";
import { findNestedMenuItems, findNestedMenuItem } from "../utils/websiteMenuUtils";
import AssetsManagerPane, { AssetsManagersSelectionMode } from "../containers/AssetsManagerPane.react";
import { PATH_NAME_RESOURCE_ID_REGEX } from "../constants/Constants";
import { replaceResourceIdWithPlaceholderId } from "../utils/templatesBuilderUtils";

class WebsiteMenuForm extends Component {
  constructor(props) {
    super(props);
    [
      "addItems",
      "submitMenu",
      "changeMenuParam",
      "displayItemOptionsPane",
      "closeItemOptionsPane",
      "changeMenuItemOption",
      "deleteMenu",
      "displayAssetsManager",
      "hideAssetsManager"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      pathToMenuItemBeingEdited: null,
      assetsManagerIsDisplayed: false
    };
  }

  componentDidMount() {
    const { fetchMenu, createMenuOffline, menuId } = this.props;

    if (menuId)
      fetchMenu(urlEventId(), menuId);
    else {
      createMenuOffline();
      this.addItems(2)();
    }
  }

  componentWillUnmount() {
    this.props.clearMenuStore();
  }

  componentDidUpdate(prevProps) {
    const { saveQueryStatus, clearSaveQueryStatus, showNotice, afterSave, reloadPreview } = this.props;

    if (prevProps.saveQueryStatus != this.props.saveQueryStatus && this.props.saveQueryStatus != null) {
      if (saveQueryStatus) {
        showNotice(I18n.t("react.menu_items.form.successfully_saved"), "success");
        afterSave();
        reloadPreview();
      } else {
        showNotice(I18n.t("react.menu_items.form.can_not_be_save"), "danger");
      }
      setTimeout(() => { clearSaveQueryStatus(); }, 5000);
    }
  }

  submitMenu(e) {
    e.preventDefault();
    const { updateMenu, showNotice, menuItems, menuParams, createMenu, persisted, menuId } = this.props;

    if (persisted) {
      updateMenu(urlEventId(), menuId, menuParams, menuItems);
    } else {
      createMenu(urlEventId(), menuParams, menuItems);
    }
    showNotice(I18n.t("react.menu_items.form.saving"));
  }

  displayItemOptionsPane(pathTo) {
    return () => {
      this.setState({ pathToMenuItemBeingEdited: pathTo });
    };
  }

  displayAssetsManager(pathTo) {
    return () => {
      this.setState({ pathToMenuItemBeingEdited: pathTo, assetsManagerIsDisplayed: true });
    };
  }

  hideAssetsManager() {
    this.setState({ pathToMenuItemBeingEdited: null, assetsManagerIsDisplayed: false });
  }

  closeItemOptionsPane() {
    this.setState({ pathToMenuItemBeingEdited: null });
  }

  deleteMenu() {
    const { deleteMenu, menuId, afterDestroy } = this.props;

    if (confirm(I18n.t("confirm"))) {
      const notificationOptions = { notice: I18n.t("react.menu_items.form.deleted_successfully"), noticeType: "success" };
      deleteMenu(urlEventId(), menuId, notificationOptions);
      afterDestroy();
    }
  }

  changeMenuItemOption(pathTo) {
    const { menuItems, updateMenuItemsOffline, pages } = this.props;
    return (optionKey, optionValue) => {
      let editedItem = findNestedMenuItem(pathTo, menuItems);
      editedItem.options[optionKey] = optionValue;

      if (optionKey == "button_type") {
        editedItem.type = optionValue;
        if (optionValue == "registration") {
          editedItem.name = "";
        }
      } else if (optionKey == "button_link") {
        editedItem.link = optionValue;
      } else if (optionKey == "button_label") {
        editedItem.name = optionValue;
      } else if (optionKey == "website_page_url") {
        let pathNameWithoutResourceId = optionValue;
        if (PATH_NAME_RESOURCE_ID_REGEX.test(optionValue)) {
          pathNameWithoutResourceId = replaceResourceIdWithPlaceholderId(optionValue);
        }
        editedItem.page_id = pages.find(page => page.path_name == pathNameWithoutResourceId)._id;
      }

      updateMenuItemsOffline(menuItems);
    };
  }

  changeMenuParam(attributeName) {
    const { updateMenuOffline, menuParams } = this.props;

    return (e) => {
      menuParams[attributeName] = e.target.value;
      updateMenuOffline(menuParams);
    };
  }

  addItems(nbToAdd = 1, pathTo = []) {
    const { menuItems, updateMenuItemsOffline, pages } = this.props;

    return (e) => {
      e && e.preventDefault();

      let firstLevelMenuItems = cloneDeep(menuItems || []);
      let deepestLevelMenuItems = findNestedMenuItems(pathTo, firstLevelMenuItems);

      const startRank = deepestLevelMenuItems.length > 0 ? Math.max.apply(Math, deepestLevelMenuItems.map(i => i.rank)) : 0;
      const itemPageIds = deepestLevelMenuItems.map(item => item.page_id);
      let pagesNotIncluded = pages.filter(page => {
        return itemPageIds.indexOf(page._id) == -1;
      });

      for (let i = 1; i <= nbToAdd; i++) {
        const unlinkedPage = pagesNotIncluded.shift();
        const menuItem = {
          link: "",
          name: unlinkedPage ? unlinkedPage.name : I18n.t("react.menu_items.form.new_element"),
          page_id: unlinkedPage && unlinkedPage._id,
          rank: startRank + i * 10000,
          type: unlinkedPage ? "website_page" : "external_link",
          options: this.defaultMenuItemOptions(),
          menu_items: []
        };
        deepestLevelMenuItems.push(menuItem);
      }

      updateMenuItemsOffline(firstLevelMenuItems);
    };
  }

  defaultMenuItemOptions() {
    return MenuSettings.reduce((opts, setting) => {
      if (setting.id === undefined) return opts;
      opts[setting.id] = translatedTextFromJson(setting, "default", event.locale);
      if (opts[setting.id] === undefined) opts[setting.id] = "";
      return opts;
    }, {});
  }

  menuItemsDisplayable() {
    return this.props.menuItems.filter(item => !item._destroy);
  }

  renderItems() {
    const menuItemsToDisplay = this.menuItemsDisplayable();

    if (menuItemsToDisplay.length == 0) {
      return <div className="card nothing-yet mt-10">
        <div>
          <a className="btn btn-secondary" onClick={this.addItems()}>
            <i className="fa-regular fa-plus"></i> { I18n.t("react.menu_items.form.add_item") }
          </a>
        </div>
      </div>;
    }

    return [
      <table className="table table-website-menu mt-10 mb-10" key="header">
        <thead>
          <tr className="row align-items-center flex-nowrap no-margin" style={{ width: "100%" }}>
            <th className="col-auto" style={{ minWidth: "85px" }}>{ I18n.t("react.menu_items.form.drag_to_reorder") }</th>
            <th className="col-2">{ I18n.t("react.menu_items.form.type") }</th>
            <th className="col-3">{ I18n.t("react.menu_items.form.link_or_page") }</th>
            <th className="col-3">{ I18n.t("react.menu_items.form.name") }</th>
            <th className="col-3 text-center">{ I18n.t("react.menu_items.form.actions") }</th>
          </tr>
        </thead>
      </table>,
      <MenuItemsList menuItems={this.props.menuItems}
        addItems={this.addItems}
        pathToMenuItemBeingEdited={this.state.pathToMenuItemBeingEdited}
        allMenuItems={this.props.menuItems}
        updateMenuItemsOffline={this.props.updateMenuItemsOffline}
        errors={this.props.errors}
        displayItemOptionsPane={this.displayItemOptionsPane}
        changeMenuItemOption={this.changeMenuItemOption}
        pages={this.props.pages}
        pathTo={[]}
        key="list"
        displayAssetsManager={this.displayAssetsManager}
      />
    ];
  }

  render() {
    const { notice, noticeType, menuItems, menuParams, menuErrors, persisted, guestCategories, documentNames, pages, displayTranslationsPane } = this.props;
    const { pathToMenuItemBeingEdited, assetsManagerIsDisplayed } = this.state;

    if (!menuItems) return <Loader />;

    return (
      <div>
        <NoticeBlock notice={notice} noticeType={noticeType} />
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <div className={classNames({ "mb-3": true, "has-error": menuErrors["name"] })}>
              <label htmlFor="menu-name" className="form-label">{I18n.t("react.menu_items.form.menu_name")}</label>
              <input
                type="text"
                id="menu-name"
                name="menu-name"
                className="form-control"
                value={ menuParams.name }
                onChange={this.changeMenuParam("name")}
                autoFocus
              />
              { menuErrors["name"] && <div className="form-text text-danger">{ menuErrors["name"] }</div> }
            </div>
          </div>
          { (persisted || menuErrors["key"]) &&
            <div className="col-md-3 col-sm-6">
              <div className={classNames({ "mb-3": true, "has-error": menuErrors["key"] })}>
                <label className="form-label">{I18n.t("react.menu_items.form.menu_key")}</label>
                <input className="form-control" value={ menuParams.key || "" } disabled />
                { menuErrors["key"] && <div className="form-text text-danger">{ menuErrors["key"] }</div> }
              </div>
            </div>
          }
          { persisted &&
            <div className="col-md-6">
              <div className="row align-item-center justify-content-end g-2 mt-20">
                { displayTranslationsPane && <div className="col-auto">
                  <a className="btn btn-secondary" onClick={ (e) => displayTranslationsPane(e, menuParams._id, "website_menus", "menus") }>
                    <i className="fa-regular fa-language" /> { I18n.t("react.registration_form.manage_translations") }
                  </a>
                </div> }
                <div className="col-auto">
                  <a className="btn btn-secondary" onClick={this.deleteMenu}>
                    <i className="fa-regular fa-trash-can" /> { I18n.t("react.menus.form.delete") }
                  </a>
                </div>
              </div>
            </div>
          }
        </div>
        { this.renderItems() }
        { this.menuItemsDisplayable().length > 0 &&
          <a href="#" onClick={this.addItems()} className="btn btn-secondary">
            <i className="fa-regular fa-plus"></i> { I18n.t("react.menu_items.form.add_item") }
          </a>
        }
        <SlidingPane isOpen={!!pathToMenuItemBeingEdited}
          title="Options"
          from="right"
          width='25%'
          onRequestClose={this.closeItemOptionsPane}
          className="width-100-xs width-50-md"
          closeIcon={Icons.close()}>
          <MenuItemOptionsForm pathToMenuItem={pathToMenuItemBeingEdited}
            menuItems={menuItems}
            menuSettings={MenuSettings}
            onUpdateOption={this.changeMenuItemOption(pathToMenuItemBeingEdited)}
            guestCategories={guestCategories}
            documentNames={documentNames}
            websitePages={pages} />
        </SlidingPane>
        <a href="#" onClick={this.submitMenu} className="btn btn-primary float-end"> { I18n.t("react.menu_items.form.validate") } </a>
        <AssetsManagerPane
          selectionMode={AssetsManagersSelectionMode.Asset}
          assetId={ menuItems[pathToMenuItemBeingEdited] && menuItems[pathToMenuItemBeingEdited].options["asset_id"] }
          isOpen={assetsManagerIsDisplayed}
          settingKey="asset_id"
          updateSettingsData={this.changeMenuItemOption(pathToMenuItemBeingEdited)}
          onClose={this.hideAssetsManager}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  fetchMenu,
  createMenu,
  createMenuOffline,
  updateMenu,
  updateMenuOffline,
  updateMenuItemsOffline,
  deleteMenu,
  clearSaveQueryStatus,
  clearMenuStore,
  showNotice
};

function mapStateToProps(state) {
  return {
    menuItems: state.websiteMenuItems.items,
    menuParams: state.websiteMenu.data,
    menuErrors: state.websiteMenu.errors,
    persisted: state.websiteMenu.persisted,
    errors: state.websiteMenuItems.errors,
    saveQueryStatus: state.websiteMenuItems.saveQueryStatus,
    notice: state.notifications.currentNotice,
    noticeType: state.notifications.noticeType
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteMenuForm);
