import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import difference from "lodash/difference";
import isEmpty from "lodash/isEmpty";
import AccesspointForm from "../shared/AccesspointForm.react";
import { fetchAccesspoints, createAccesspoint, fetchAccesspointsCustomValuesForSessions } from "../../actions/AccesspointActionCreators";
import { fetchGuestCategories } from "../../actions/GuestCategoryActionCreators";

class CreateAccesspointModal extends Component {

  constructor(props) {
    super(props);
    [
      "onSubmit"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
  }

  componentDidMount() {
    const { accesspoints, guestCategories, fetchAccesspoints, fetchGuestCategories, fetchAccesspointsCustomValuesForSessions, eventId } = this.props;
    if (isEmpty(accesspoints)) {
      fetchAccesspoints(eventId);
    }
    fetchAccesspointsCustomValuesForSessions(eventId);
    if (isEmpty(guestCategories)) {
      fetchGuestCategories(eventId);
    }
  }

  componentDidUpdate(prevProps) {
    const { isVisible, accesspoints, onCloseFunction, updateParent, fetchAccesspointsCustomValuesForSessions, eventId } = this.props;

    if (prevProps.isVisible && prevProps.accesspoints.length != accesspoints.length) {
      onCloseFunction();
      const newAccesspoint = difference(accesspoints, prevProps.accesspoints)[0];
      if (newAccesspoint) {
        updateParent(newAccesspoint);
      }
    }
    // in case a new accesspoint has already been created with new custom values, we need to fetch them
    if (!prevProps.isVisible && isVisible) {
      fetchAccesspointsCustomValuesForSessions(eventId);
    }
  }

  onSubmit(accesspoints) {
    const { createAccesspoint, eventId, locale } = this.props;
    createAccesspoint(eventId, accesspoints, { locale: locale });
    $("#create-accesspoint-modal").stop().animate({ scrollTop: 0 }, 1000, "swing");
  }

  render() {
    const { isVisible, onCloseFunction, accesspointsErrors, guestCategories, customSessionTypes,
      customLocations, customRoomTypes, defaultType } = this.props;

    return (
      <Modal show={isVisible} size="lg" onHide={onCloseFunction} id="create-accesspoint-modal">
        <Modal.Header>
          <Modal.Title>{ I18n.t(`react.accesspoint.form.new_${defaultType || "checkin_point"}`) }</Modal.Title>
          <button type="button" onClick={onCloseFunction} className="btn-close" aria-label={I18n.t("close")}></button>
        </Modal.Header>
        <Modal.Body>
          <AccesspointForm accesspoint={{}}
            isModal={true}
            errors={accesspointsErrors}
            onSubmit={this.onSubmit}
            guestCategories={guestCategories}
            customSessionTypes={customSessionTypes}
            customLocations={customLocations}
            customRoomTypes={customRoomTypes}
            defaultType={defaultType}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

CreateAccesspointModal.propTypes = {
  isVisible: PropTypes.bool,
  onCloseFunction: PropTypes.func,
  updateParent: PropTypes.func,
  eventId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  defaultType: PropTypes.string
};


CreateAccesspointModal.defaultProps = {
  isVisible: false
};


function mapStateToProps(state) {
  return {
    accesspoints: state.accesspoints.data,
    accesspointsErrors: state.accesspoints.errors,
    customSessionTypes: state.accesspoints.customSessionTypes,
    customLocations: state.accesspoints.customLocations,
    customRoomTypes: state.accesspoints.customRoomTypes,
    guestCategories: state.guestCategories.data
  };
}

const mapDispatchToProps = {
  fetchAccesspoints,
  createAccesspoint,
  fetchGuestCategories,
  fetchAccesspointsCustomValuesForSessions
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccesspointModal);
