import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { Dataset, toChartJsDataset } from "../../../types/ChartDataset";

interface Props {
  dataset: Dataset;
  type?: "pie"|"doughnut";
}

const DATASET_OVERRIDE = {
  borderColor: "white"
};

const PieChart: React.FC<Props> = ({
  dataset,
  type
}) => {
  const chartCanvas = useRef<HTMLCanvasElement>(null);
  const chart = useRef(null);

  useEffect(() => {
    renderChart();
  }, [dataset, type]);

  const renderChart = (): void => {
    if (chart.current) chart.current.destroy();

    const { yAxesValueFormatter, labels } = dataset;

    const ctx = chartCanvas.current;

    chart.current = new Chart(ctx, {
      type: type || "pie",
      data: {
        labels,
        datasets: [{ ...toChartJsDataset(dataset), ...DATASET_OVERRIDE }]
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: ({ raw }): string => {
                return yAxesValueFormatter ? yAxesValueFormatter(raw as number) : raw.toString();
              }
            }
          },
          legend: {
            labels: {
              usePointStyle: true,
              pointStyle: "rectRounded",
            }
          }
        },
        maintainAspectRatio: false,
        responsive: true
      }
    });
  };

  return <canvas ref={chartCanvas}></canvas>;
};

export default PieChart;
