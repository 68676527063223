import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SlidingPane from "react-sliding-pane";
import { createDocumentTemplate, duplicateDocumentTemplate, deleteDocumentTemplate } from "../../actions/DocumentTemplateActionCreators";
import { fetchDocumentTemplates, updateDocumentSelectedId, fetchDocumentsPresetsList } from "../../actions/DocumentTemplatesActionCreators";
import TemplateCreationButtons from "../shared/TemplateCreationButtons.react";
import TargetEventModal from "../../components/duplication_modals/TargetEventModal.react";
import AskModelNameModal from "../../components/duplication_modals/AskModelNameModal.react";
import DuplicationRunningModal from "../../components/duplication_modals/DuplicationRunningModal.react";
import TemplateActionButtons from "../../components/shared/TemplateActionButtons.react";
import EditTemplateWarningModal from "../../components/shared/EditTemplateWarningModal.react";
import TemplatePreviewIframe from "../../components/shared/TemplatePreviewIframe.react";
import DocumentBuilder from "../../containers/document_templates/DocumentBuilder.react";
import Loader from "../../components/shared/Loader.react";
import Filter from "../../components/Filter.react";
import { urlEventId } from "../../utils/pathUtils";
import { generateRandomIdentifier } from "../../utils/identifierUtils";
import Icons from "../../constants/Icons";
import { Event } from "../../types/Event";
import { DocumentTemplate } from "../../types/DocumentTemplate";

interface Props {
  match: any;
  location: any;
  history: any;
  event: Event;
  eventId: string;
  guestId?: string;
  listBodyHeight: string;
  toolbarHeight: string;
  searchBarHeight: string;
  borderStyle: string;
  loaderContainerHeight: string
  enableEdit: boolean;
  enableDelete: boolean;
  iframeAutoResize: boolean;
  fromTemplatesList?: boolean;
  selectedTemplateId: string;
  iframeCustomClass?: string;
  inModal?: boolean;
  defaultCreateButton?: boolean;
  builderInSlidingPane?: boolean;
  slidingPaneTitle?: JSX.Element;
  refreshTemplatesList?(): void;
  onSelectTemplate?(id: string): void;
}

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.document_templates.list.${key}`, opts);
}

const DocumentTemplates: React.FC<Props> = (props) => {
  const documentTemplates = useSelector((state: any) => state.documentTemplates.data);
  const isPendingRequest = useSelector((state: any) => state.documentTemplates.isPendingRequest);
  const creationErrors = useSelector((state: any) => state.documentTemplates.errors);
  const duplicatedDocument = useSelector((state: any) => state.documentTemplates.duplicatedTemplate);
  const duplicationErrors = useSelector((state: any) => state.documentTemplates.duplicationErrors);
  const presetsList = useSelector((state: any) => state.documentTemplates.presetsList);

  const [searchValue, setSearchValue] = useState<string>(null);
  const [showDuplicationRunningModal, setShowDuplicationRunningModal] = useState<boolean>(false);
  const [showNewDocumentTemplateNameModal, setShowNewDocumentTemplateNameModal] = useState<boolean>(false);
  const [showDuplicateDocumentTemplateNameModal, setShowDuplicateDocumentTemplateNameModal] = useState<boolean>(false);
  const [duplicateTargetEventId, setDuplicateTargetEventId] = useState<string>(null);
  const [showEventPickerModal, setShowEventPickerModal] = useState<boolean>(false);
  const [showEditWarningModal, setShowEditWarningModal] = useState<boolean>(false);
  const [isDocumentBuilderOpen, setIsDocumentBuilderOpen] = useState<boolean>(false);
  const [previewIframeKey, setPreviewIframeKey] = useState<string>(generateRandomIdentifier);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!documentTemplates) {
      dispatch(fetchDocumentTemplates(urlEventId()));
    }
    if (!presetsList) {
      dispatch(fetchDocumentsPresetsList(urlEventId()));
    }
  }, []);

  useEffect(() => {
    if (!showDuplicationRunningModal || Object.keys(duplicationErrors).length === 0) return;

    setShowDuplicateDocumentTemplateNameModal(true);
    setShowDuplicationRunningModal(false);
  }, [Object.keys(duplicationErrors).length]);

  useEffect(() => {
    setShowNewDocumentTemplateNameModal(false);
  }, [documentTemplates?.length]);

  const deleteDocumentTemplateHandler = (documentTemplateId: string): (() => void) => {
    const { eventId } = props;

    return (): void => {
      if (confirm(I18n.t("confirm"))) {
        dispatch(deleteDocumentTemplate(eventId, documentTemplateId));
      }
    };
  };

  const openDocumentBuilder = (): void => {
    setIsDocumentBuilderOpen(true);
  };

  const closeDocumentBuilder = (): void => {
    setIsDocumentBuilderOpen(false);
    enableBodyScroll();
    reloadDocumentPreview();
  };

  const disableBodyScroll = (): void => {
    document.body.style.overflow = "hidden";
  };

  const enableBodyScroll = (): void => {
    document.body.removeAttribute("style");
  };

  const reloadDocumentPreview = (): void => {
    setPreviewIframeKey(generateRandomIdentifier());
  };

  const renderCopyModals = (): JSX.Element => {
    const { event, eventId } = props;

    return <>
      <TargetEventModal
        isVisible={showEventPickerModal}
        onClose={(): void => setShowEventPickerModal(false)}
        currentEvent={event}
        targetReadableName={i18n("target_name")}
        onEventSelected={onEventTargetSelected}
      />
      <AskModelNameModal
        isVisible={showDuplicateDocumentTemplateNameModal}
        onClose={(): void => setShowDuplicateDocumentTemplateNameModal(false)}
        onSubmit={duplicateIntoEvent}
        i18nRootKey="document_templates"
        errors={duplicationErrors}
        modalSize={null}
      />
      <DuplicationRunningModal
        isVisible={showDuplicationRunningModal}
        onClose={(): void => setShowDuplicationRunningModal(false)}
        model="document_templates"
        currentEventId={eventId}
        targetEventId={duplicateTargetEventId}
        duplicatedData={duplicatedDocument}
        duplicationErrors={{}}
        duplicationWarnings={{}}
      />
    </>;
  };

  const onEventTargetSelected = (targetEventId: string): void => {
    setShowEventPickerModal(false);
    setShowDuplicateDocumentTemplateNameModal(true);
    setDuplicateTargetEventId(targetEventId);
  };

  const duplicateIntoEvent = (newName: string): void => {
    const { eventId, selectedTemplateId } = props;
    const documentTemplateParams = {
      new_name: newName,
      target_event_id: duplicateTargetEventId
    };

    dispatch(duplicateDocumentTemplate(eventId, selectedTemplateId, documentTemplateParams, duplicateTargetEventId === eventId));
    setShowDuplicateDocumentTemplateNameModal(false);
    setShowDuplicationRunningModal(true);
  };

  const createDocumentTemplateHandler = (documentName: string, presetsName: string): void => {
    const { eventId } = props;
    dispatch(createDocumentTemplate(eventId, { name: documentName, presets_name: presetsName }));
  };

  const openNewDocumentTemplateModal = (): void => {
    setShowNewDocumentTemplateNameModal(true);
  };

  const onFilterChange = (result: { searchValue?: string; selectedItemIds?: string[] }): any => {
    const { onSelectTemplate } = props;
    const { searchValue, selectedItemIds } = result;

    if (searchValue !== undefined) // we need to enter this condition when "" (which is falsy)
      setSearchValue(searchValue.length > 0 ? searchValue : null);

    if (selectedItemIds) {
      dispatch(updateDocumentSelectedId(selectedItemIds[0]));
      if (onSelectTemplate) onSelectTemplate(selectedItemIds[0]);
    }
  };

  const openSelectEventModal = (): void => {
    const { eventId } = props;

    setDuplicateTargetEventId(eventId);
    setShowEventPickerModal(true);
  };

  const toggleEditWarningModal = (): void => {
    setShowEditWarningModal(!showEditWarningModal);
  };

  const renderTemplateUses = (documentTemplate: DocumentTemplate): JSX.Element => {
    if (documentTemplate.uses_count === 0) return null;

    return <span className="badge rounded-pill bg-secondary badge-uses-count">{documentTemplate.uses_count}</span>;
  };

  const renderActionButtons = (documentTemplate: DocumentTemplate): JSX.Element => {
    const { enableEdit, builderInSlidingPane, enableDelete } = props;

    return <TemplateActionButtons
      templateId={documentTemplate._id}
      templateType="document_template"
      onEditRequest={documentTemplate.uses_count >= 1 ? toggleEditWarningModal : null}
      enableEdit={enableEdit}
      enableDuplication={true}
      openSelectEventModal={openSelectEventModal}
      deleteTemplate={enableDelete && deleteDocumentTemplateHandler}
      builderInSlidingPane={builderInSlidingPane}
      openBuilder={openDocumentBuilder}
      enableDelete={enableDelete && (documentTemplate.uses_count === 0 || !documentTemplate.uses_count)}
    />;
  };

  const renderCreationButtons = (): JSX.Element => {
    const { defaultCreateButton } = props;

    return <div>
      <TemplateCreationButtons
        templateType="document_template"
        onNewTemplate={openNewDocumentTemplateModal}
        defaultCreateButton={defaultCreateButton}
        duplicatedTemplate={duplicatedDocument}
        duplicationErrors={duplicationErrors}
      />
    </div>;
  };

  const renderNewDocumentTemplateModal = (): JSX.Element => {
    return <AskModelNameModal
      isVisible={showNewDocumentTemplateNameModal}
      onClose={(): void => setShowNewDocumentTemplateNameModal(false) }
      i18nRootKey="document_templates.new_document"
      onSubmit={createDocumentTemplateHandler}
      errors={creationErrors}
      showLoader={isPendingRequest}
      modalSize={null}
      presetsList={presetsList}
    />;
  };

  const renderDocumentBuilder = (): JSX.Element => {
    const { slidingPaneTitle, selectedTemplateId, match, location, history } = props;

    return (
      <SlidingPane
        isOpen={isDocumentBuilderOpen}
        width="90%"
        title={slidingPaneTitle}
        onAfterOpen={disableBodyScroll}
        onRequestClose={closeDocumentBuilder}
        className="width-100-xs width-100-sm"
        closeIcon={Icons.close()}>
        <DocumentBuilder
          documentTemplateId={selectedTemplateId}
          match={match}
          location={location}
          history={history}
        />
      </SlidingPane>
    );
  };

  const renderDocumentTemplates = (): JSX.Element => {
    return <>
      {renderList()}
      {renderPreview()}
    </>;
  };

  const renderNoDocumentTemplates = (): JSX.Element => {
    const { inModal } = props;

    return <div className={`col-12 ${inModal ? "pt-15" : ""}`}>
      <div className="card nothing-yet">
        <div>
          <i className="fa-regular fa-file fa-xl"></i>
        </div>
        <h4>{ i18n("no_templates") }</h4>
        {renderCreationButtons()}
      </div>
    </div>;
  };

  const renderRefreshTemplatesList = (): JSX.Element => {
    const { refreshTemplatesList } = props;

    if (!refreshTemplatesList) return;

    return <a href="#" onClick={refreshTemplatesList} style={{ marginLeft: "15px" }}>
      <i className='fa-regular fa-arrows-rotate'></i> {i18n("refresh")}
    </a>;
  };

  const renderList = (): JSX.Element => {
    const { borderStyle, listBodyHeight, toolbarHeight, searchBarHeight, selectedTemplateId } = props;

    return (
      <div className="col-md-3 templates-list">
        <div className="card mb-0">
          <div style={{ borderBottom: borderStyle }}>
            <Filter
              translationKey="document_templates"
              items={documentTemplates}
              selectedItemIds={[selectedTemplateId]}
              showCells={false}
              hasSelectAll={false}
              multipleSelect={false}
              sortItems={false}
              selectedFirst={false}
              itemIdKey="_id"
              onChange={onFilterChange}
              searchValue={searchValue}
              allowUnselect={false}
              hasSearch={true}
              maxHeight={`calc(${listBodyHeight} - (${toolbarHeight} + ${searchBarHeight})`}
              actionButtons={renderActionButtons}
              additionalInfo={renderTemplateUses}
            />
          </div>
          <div className="mt-10 mb-10 ml-10 mr-10 text-center">
            {renderCreationButtons()}
            {renderRefreshTemplatesList()}
          </div>
        </div>
      </div>
    );
  };

  const renderPreview = (): JSX.Element => {
    const { borderStyle, listBodyHeight, iframeAutoResize, selectedTemplateId, guestId, iframeCustomClass, fromTemplatesList, inModal } = props;
    const styles = {
      padding: fromTemplatesList ? "auto" : "1px",
      borderLeft: fromTemplatesList ? "none" : borderStyle,
      height: listBodyHeight
    };

    if (!selectedTemplateId) {
      return (
        <div className="col-md-9 template-preview" style={styles}>
          <div className="nothing-yet" style={{ height: "100%" }}>
            <h4>{i18n("preview")}</h4>
          </div>
        </div>
      );
    }

    return (
      <div className="col-md-9 template-preview" style={styles}>
        <TemplatePreviewIframe
          templateId={selectedTemplateId}
          templateType="document_template"
          guestId={guestId}
          autoResize={iframeAutoResize}
          scrolling={inModal}
          key={previewIframeKey}
          customClass={iframeCustomClass} />
      </div>
    );
  };

  const renderEditWarningModal = (): JSX.Element => {
    const { selectedTemplateId, builderInSlidingPane } = props;
    const documentTemplate = documentTemplates.find(template => template._id === selectedTemplateId);

    if (!documentTemplate) return null;

    return <EditTemplateWarningModal
      isOpen={showEditWarningModal}
      closeModal={toggleEditWarningModal}
      template={documentTemplate}
      templateType="document_template"
      builderInSlidingPane={builderInSlidingPane}
      openBuilder={openDocumentBuilder}
      duplicate={openSelectEventModal}
    />;
  };

  const { loaderContainerHeight, inModal } = props;

  if (!documentTemplates)
    return <Loader size="large" inline={false} containerHeight={loaderContainerHeight} />;

  return (
    <div className={`row ${inModal ? "g-0" : ""}`}>
      { documentTemplates && documentTemplates.length > 0 ? renderDocumentTemplates() : renderNoDocumentTemplates() }
      {renderDocumentBuilder()}
      {renderEditWarningModal()}
      {renderCopyModals()}
      {renderNewDocumentTemplateModal()}
    </div>
  );
};

export default DocumentTemplates;
