import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import querystring from "querystring";

import moment from "moment";
import DashboardNavTab from "../../components/dashboard/DashboardNavTab.react";
import HeaderStats from "../../components/dashboard/HeaderStats.react";
import { setSelectedGuestCategoryIds } from "../../actions/GuestCategoryActionCreators";
import FilterDropdown from "../../components/FilterDropdown.react";
import { importGuestCategories } from "../../actions/ImportGuestCategoryActionCreators";
import { fetchEventAtlasJwtToken } from "../../actions/EventAtlasJwtTokenActionCreator";
import PopulationTypePicker from "../../components/PopulationTypePicker.react";
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { urlWithQuery } from "../../utils/pathUtils";
import DateRangePicker from "../../components/DateRangePicker.react";
import { showNotice } from "../../actions/NoticeActionCreators";
import { redirectIfUnauthorized, isAuthorized, authorizedCategories } from "../../utils/aclUtils";
import AtlasChart from "../../components/AtlasChart.react";
import withDatabaseDefaultRegionRestriction from "../../components/shared/WithDatabaseDefaultRegionRestriction.react";
import { DefaultGuestsListQuery, DASHBOARD_HEADER_NO_VALUE_STR } from "../../constants/Constants";
import EventPicker from "../../containers/shared/EventPicker.react";
import { buildFilter } from "../../utils/AtlasChartUtils";
import { dateRangeRelativeToEvent } from "../../utils/DateUtils";

const REGISTRATIONS_BY_DAY_CUMULATIVE_CHART_ID = "62fb5291-9416-40b2-8704-42679e415778";
const REGISTRATIONS_BY_DAY_CHART_ID = "88a41a97-9b3f-4d5a-a431-808fc2c24edd";
const REGISTRATIONS_BY_POPULATION_CHART_ID = "8cfd3b3e-49a5-4e67-9904-d77ff713fb45";
const REGISTRATIONS_BY_CATEGORY_CHART_ID = "633ad54e-075a-4689-8e01-14c2af2b1a81";

const CHART_BASE_HEIGHT = 125; // in px
const CHART_HEIGHT_PER_CATEGORY = 25; // in px

function i18n(key, opts = {}) {
  return I18n.t(`react.dashboard.registered.${key}`, opts);
}

class RegisteredDashboard extends Component {
  constructor(props) {
    redirectIfUnauthorized("reports", "read");
    super(props);
    [
      "onDateRangeChanged",
      "onCheckboxChangeHandler",
      "onEventToCompareChange",
      "onChartDataChanged"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    moment.locale(I18n.currentLocale());

    this.state = {
      cumulative: false,
      guestsCount: null,
      topDayDate: null,
      topDayCount: null,
      startDate: null,
      endDate: null,
      withImport: false,
      eventToCompare: null
    };
  }

  componentDidMount() {
    const { importGuestCategories, match, fetchEventAtlasJwtToken } = this.props;
    const q = querystring.parse(this.props.location.search.substring(1));
    const { notice, alert } = q;
    if (notice || alert) {
      showNotice(notice || alert, alert ? "danger" : "success");
    }
    importGuestCategories();
    fetchEventAtlasJwtToken(match.params.event_id);
  }

  endDate() {
    const { endDate } = this.state;
    if (endDate) return moment(endDate);

    const { event } = this.props;
    if (!event._id) return null;

    return moment().isBefore(event.end_date) ? moment() : moment(event.end_date);
  }

  startDate() {
    const { startDate } = this.state;
    if (startDate) return moment(startDate);
    if (!this.endDate()) return null;

    return this.endDate().subtract(1, "months");
  }

  jwtToken() {
    const { event, jwtTokens } = this.props;

    return event._id ? jwtTokens[event._id] : null;
  }

  onDateRangeChanged(startDate, endDate) {
    this.setState({ startDate, endDate });
  }

  onCheckboxChangeHandler(key) {
    return (e) => {
      this.setState({
        [key]: e.target.checked
      });
    };
  }

  renderHeaderStats() {
    const { guestsCount, topDayCount, topDayDate } = this.state;

    return (
      <HeaderStats
        dataFetched={guestsCount !== null}
        headers={
          [
            { i18nKey: "total", value: guestsCount, nbCols: 6 },
            { i18nKey: "top_day", value: topDayCount, labelInfoValue: topDayDate, nbCols: 6 }
          ]
        }
        tabKey={"registered"}
      />
    );
  }

  renderPeriodDatePicker() {
    return <div className="col-auto">
      <DateRangePicker
        startDate={this.startDate()}
        endDate={this.endDate()}
        onDateRangeChanged={this.onDateRangeChanged}
      />
    </div>;
  }

  renderGuestCategoriesPicker() {
    const { guestCategories, selectedGuestCategoryIds, setSelectedGuestCategoryIds } = this.props;
    const { eventToCompare } = this.state;

    return (
      <div className="col-auto">
        <FilterDropdown
          id="guest_category"
          disabled={eventToCompare && eventToCompare._id}
          items={guestCategories}
          selectedItemIds={selectedGuestCategoryIds}
          translationKey="guest_category"
          onChange={setSelectedGuestCategoryIds}
          showCells={false}
          itemColorKey="label_color"
          showBadgeNbItems={true}
        />
      </div>
    );
  }

  renderPopulationPicker() {
    const { guestCategories, selectedGuestCategoryIds, setSelectedGuestCategoryIds } = this.props;
    const { eventToCompare } = this.state;

    return (
      <div className="col-auto">
        <PopulationTypePicker
          disabled={eventToCompare && eventToCompare._id}
          guestCategories={guestCategories}
          selectedGuestCategoryIds={selectedGuestCategoryIds}
          setSelectedGuestCategoryIds={setSelectedGuestCategoryIds}
          showCells={false}
          showBadgeNbItems={true}/>
      </div>
    );
  }

  renderCheckbox(stateKey, i18nKey) {
    return (
      <div className="col-auto">
        <Form.Group key={stateKey} controlId={i18nKey}>
          <Form.Check
            type="checkbox"
            label={i18n(i18nKey)}
            className="mb-0"
            onChange={this.onCheckboxChangeHandler(stateKey)}
            checked={this.state[stateKey]} />
        </Form.Group>
      </div>
    );
  }

  onChartDataChanged(chartData) {
    if (!chartData) {
      this.setState({ chartData, guestsCount: null, topDayDate: null, topDayCount: null });
    } else {
      let guestsCount = DASHBOARD_HEADER_NO_VALUE_STR;
      let topDayDate = DASHBOARD_HEADER_NO_VALUE_STR;
      let topDayCount = 0;
      chartData.documents.forEach(document => {
        if (document.y < topDayCount) return;

        topDayDate = document.x;
        topDayCount = document.y;
      });
      if (topDayDate == DASHBOARD_HEADER_NO_VALUE_STR) {
        topDayCount = DASHBOARD_HEADER_NO_VALUE_STR;
      } else {
        topDayDate = moment(topDayDate).format("ll");
        guestsCount = chartData.documents.reduce((partialSum, document) => partialSum + document.y, 0);
      }
      this.setState({ chartData, guestsCount, topDayDate, topDayCount });
    }
  }

  onEventToCompareChange(eventToCompare) {
    const { setSelectedGuestCategoryIds } = this.props;
    setSelectedGuestCategoryIds([]);
    this.setState({ eventToCompare });
  }

  renderEventToComparePicker() {
    const { event } = this.props;
    const { eventToCompare } = this.state;
    if (!event._id) return null;

    const tooltip = <Tooltip id="info">{i18n("compared_event_tooltip")}</Tooltip>;
    const tooltipOverlay = <OverlayTrigger placement="bottom" overlay={tooltip}><i className="fa-regular fa-info-circle ml-5" aria-hidden="true"></i></OverlayTrigger>;

    return <div className="mb-3 row g-2 align-items-center">
      <div className="col-auto">
        <EventPicker
          onSelectEvent={this.onEventToCompareChange}
          accountId={event.account_id}
          emptyTitle={I18n.t("react.event_reports.report_header.pick_event_to_compare")}
          ignoreEventsWithIds={[event._id]}
          initialEventId={eventToCompare && eventToCompare._id}
        />
      </div>
      <div className="col-auto">
        {tooltipOverlay}
      </div>
    </div>;
  }

  renderFilters() {
    return <div class="d-flex flex-wrap justify-content-between">
      <div className="mb-3 row g-2 align-items-center">
        { this.renderPeriodDatePicker()}
        { this.renderGuestCategoriesPicker()}
        { this.renderPopulationPicker() }
        { this.renderCheckbox("withImport", "include_import")}
      </div>
      { this.renderEventToComparePicker() }
    </div>;
  }

  customFilter(startDate = null, endDate = null) {
    const { withImport } = this.state;
    const { selectedGuestCategoryIds } = this.props;
    const filters = [
      { type: "startDate", field: "registered_at", value: startDate || this.startDate() },
      { type: "endDate", field: "registered_at", value: endDate || this.endDate() },
      { type: "idsIn", field: "guest_category_id", value: selectedGuestCategoryIds },
    ];
    if (!withImport) filters.push({ type: "valueNe", field: "utm_medium", value: "Import Registration" });
    return buildFilter(filters);
  }

  renderRegistrationsByDayChart() {
    const { event } = this.props;
    const { cumulative, eventToCompare } = this.state;

    return <div className={ eventToCompare ? "col-md-6" : "col-md-12" }>
      <div className="card" >
        <div style={{ position: "absolute", top: "10px", right: "50px" }}>{ this.renderCheckbox("cumulative", "cumulative") }</div>
        <AtlasChart
          eventId={event._id}
          title={i18n("registrations_by_day_chart_title")}
          subtitle={eventToCompare ? event.title.replace(/(.{60})..+/, "$1…") : null}
          onChartDataChanged={ this.onChartDataChanged }
          chartId={cumulative ? REGISTRATIONS_BY_DAY_CUMULATIVE_CHART_ID : REGISTRATIONS_BY_DAY_CHART_ID}
          customFilter={this.customFilter()}
          exportOptions={{
            columnsMapping: { day: { documentKey: "x" }, count: { documentKey: "y", total: true } },
            type: "registrations_by_day"
          }}
        />
      </div>
    </div>;
  }

  renderComparedEventRegistrationsByDayChart() {
    const { event } = this.props;
    const { eventToCompare, cumulative } = this.state;
    if (!eventToCompare) return null;

    const [startDate, endDate] = dateRangeRelativeToEvent(this.startDate(), this.endDate(), event, eventToCompare);

    return <div className="col-md-6">
      <div className="card" >
        <div style={{ position: "absolute", top: "10px", right: "50px" }}>{ this.renderCheckbox("cumulative", "cumulative") }</div>
        <AtlasChart
          key={eventToCompare._id}
          eventId={eventToCompare._id}
          subtitle={eventToCompare ? eventToCompare.title.replace(/(.{60})..+/, "$1…") : null}
          title={i18n("registrations_by_day_chart_title")}
          chartId={cumulative ? REGISTRATIONS_BY_DAY_CUMULATIVE_CHART_ID : REGISTRATIONS_BY_DAY_CHART_ID}
          customFilter={this.customFilter(startDate, endDate)}
          exportOptions={{
            columnsMapping: { day: { documentKey: "x" }, count: { documentKey: "y", total: true } },
            type: "registrations_by_day"
          }}
        />
      </div>
    </div>;
  }

  renderRegistrationsByPopulationChart() {
    const { event } = this.props;

    return <>
      <div className="card" >
        <AtlasChart
          eventId={event._id}
          title={i18n("by_population_chart_title")}
          chartId={REGISTRATIONS_BY_POPULATION_CHART_ID}
          customFilter={this.customFilter()}
          exportOptions={{
            columnsMapping: { population: { documentKey: "label" }, count: { documentKey: "value", total: true } },
            type: "registrations_by_population"
          }}
        />
      </div>
    </>;
  }

  renderRegistrationsByCategoryChart() {
    const { event, guestCategories, guestCategoriesFetched, selectedGuestCategoryIds } = this.props;

    if (!guestCategoriesFetched || guestCategories.length == 0) return null;

    const nbCategories = selectedGuestCategoryIds && selectedGuestCategoryIds.length > 0 ? selectedGuestCategoryIds.length : guestCategories.length;
    const chartHeight = CHART_BASE_HEIGHT + nbCategories * CHART_HEIGHT_PER_CATEGORY;

    return <>
      <div className="card" >
        <AtlasChart
          eventId={event._id}
          title={i18n("by_category_chart_title")}
          chartId={REGISTRATIONS_BY_CATEGORY_CHART_ID}
          width="100%"
          height={`${chartHeight}px`}
          chartWrapperHeight="600px"
          customFilter={this.customFilter()}
          exportOptions={{
            columnsMapping: { category: { documentKey: "y" }, count: { documentKey: "x", total: true } },
            type: "registrations_by_category"
          }}
        />
      </div>
    </>;
  }

  renderWarningImportedGuestsNotIncluded() {
    const { withImport } = this.state;
    if (withImport) return null;

    return <p className="sub-title">
      <i className="fa-regular fa-exclamation-triangle" aria-hidden="true"></i> { i18n("warning_imported_guests") }
    </p>;
  }

  renderNoRegisteredGuests() {
    return (
      <div>
        <div className="card nothing-yet">
          <div>
            <i className="fa-regular fa-user fa-xl"></i>
          </div>
          <div>
            <h4>
              {i18n("you_have_no_guests")}
              <Link to={urlWithQuery(DefaultGuestsListQuery, "guests")}>
                {i18n("add_guests")}
              </Link>
            </h4>
            { this.renderWarningImportedGuestsNotIncluded() }
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (!isAuthorized("reports", "read")) return null;
    if (!this.jwtToken()) return null;
    if (!this.endDate()) return null;

    const { guestsCount } = this.state;
    return (
      <div>
        <DashboardNavTab active="registered" event_has_exits={this.props.event.has_exits} />
        { this.renderHeaderStats() }
        { this.renderFilters() }
        { guestsCount === DASHBOARD_HEADER_NO_VALUE_STR && this.renderNoRegisteredGuests() }
        <div className={guestsCount === DASHBOARD_HEADER_NO_VALUE_STR ? "d-none" : null} >
          <div className="row">
            { this.renderRegistrationsByDayChart() }
            { this.renderComparedEventRegistrationsByDayChart() }
          </div>
          <div className="row mt-30">
            <div className="col-md-6">{ this.renderRegistrationsByCategoryChart() }</div>
            <div className="col-md-6">{ this.renderRegistrationsByPopulationChart() }</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {

  return {
    event: state.event,
    guestCategories: authorizedCategories(state.guestCategories.data),
    selectedGuestCategoryIds: state.selectedGuestCategoryIds,
    guestCategoriesFetched: state.guestCategories.fetched,
    events: state.events.data,
    eventsJwtTokensIsFetching: state.eventAtlasJwtTokens.isFetching,
    jwtTokens: state.eventAtlasJwtTokens.data
  };
}

const mapDispatchToProps = {
  importGuestCategories,
  setSelectedGuestCategoryIds,
  showNotice,
  fetchEventAtlasJwtToken
};

export default withDatabaseDefaultRegionRestriction(connect(mapStateToProps, mapDispatchToProps)(RegisteredDashboard));
