import { Component } from "react";
import { connect } from "react-redux";
import ColorPicker from "../components/ColorPicker.react";
import ImageUploader from "../components/shared/ImageUploader.react";
import { requestEvent } from "../actions/ImportActionCreators";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class EventThemeForm extends Component {
  constructor(props) {
    super(props);
    const privateMethods = ["setPrimaryColor", "setSecondaryColor", "onImageUploaded"];
    privateMethods.forEach(item => this[item] = this[item].bind(this));

    const { account_id } = props.match.params;
    this.state = {
      primaryColor: $("#last_used_primary_color").html() || "#162950",
      secondaryColor: $("#last_used_secondary_color").html() || "#757575",
      coverUrl: "",
      backgroundUrl: "",
      accountId: account_id
    };
  }

  componentDidMount() {
    const { event_id } = this.props.match.params;
    if (event_id) {
      this.props.requestEvent();
    }
  }

  componentDidUpdate(prevProps) {
    const { event } = this.props;
    if (!prevProps.event.fetched && event.fetched) {
      this.setState({
        primaryColor: event.primary_color,
        secondaryColor: event.secondary_color,
        coverUrl: event.cover_url,
        backgroundUrl: event.background_url,
        accountId: event.account_id
      });
    }
  }

  setPrimaryColor(primaryColor) {
    this.setState({ primaryColor });
  }

  setSecondaryColor(secondaryColor) {
    this.setState({ secondaryColor });
  }

  onImageUploaded(key) {
    return (_, imgUrl) => {
      this.setState({ [key]: imgUrl });
    };
  }

  s3UploadURL() {
    const { accountId } = this.state;
    return `/api/v1/accounts/${accountId}/events/s3_upload_url.json`;
  }

  renderImageInput(stateKey, field, title, description) {
    const { event_id } = this.props.match.params;
    const url = this.state[stateKey];
    return (
      <div className="col-md-6">
        <label className="form-label" htmlFor={ field }>
          {title}
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{description}</Tooltip>}>
            <i className="fa-regular fa-info-circle ml-5"></i>
          </OverlayTrigger>
        </label>
        <ImageUploader url={ url } qParams={{ event_id }} s3UploadUrl={this.s3UploadURL()} onFinishUpload={this.onImageUploaded(stateKey)} field={ field } />
        <input type="hidden" value={ url || "" } name={ field }/>
      </div>
    );
  }

  renderColorInput(stateKey, field, title, description, method) {
    const color = this.state[stateKey];
    return (
      <div className="col-md-6">
        <label className="form-label" htmlFor={ field }>
          {title}
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{description}</Tooltip>}>
            <i className="fa-regular fa-info-circle ml-5"></i>
          </OverlayTrigger>
        </label>
        <div>
          <ColorPicker setColor={method} selectedColor={color} displayValue={true} />
          <input type="hidden" value={ color } name={ field }/>
        </div>
      </div>
    );
  }

  render() {
    const { event } = this.props;
    if (!event) return null;
    return (
      <div>
        <div className="mb-3 row">
          {this.renderImageInput("coverUrl", "event[remote_cover_url]", I18n.t("react.event_theme_form.cover_url"), I18n.t("react.event_theme_form.cover_url_desc"))}
          {this.renderImageInput("backgroundUrl", "event[background_url]", I18n.t("react.event_theme_form.background_url"), I18n.t("react.event_theme_form.background_url_desc"))}
        </div>
        <div className="row">
          {this.renderColorInput("primaryColor", "event[primary_color]", I18n.t("react.event_theme_form.primary_color"), I18n.t("react.event_theme_form.primary_color_desc"), this.setPrimaryColor)}
          {this.renderColorInput("secondaryColor", "event[secondary_color]", I18n.t("react.event_theme_form.secondary_color"), I18n.t("react.event_theme_form.secondary_color_desc"), this.setSecondaryColor)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    event: state.event
  };
}

function mapDispatchToProps() {
  return {
    requestEvent
  };
}

export default connect(mapStateToProps, mapDispatchToProps())(EventThemeForm);
