import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { urlEventId } from "../utils/pathUtils";
import { fetchSessionTypes, createSessionType, updateSessionType, deleteSessionType, clearSessionTypeErrors } from "../actions/SessionTypesActionCreators";
import ColoredOjectsTable from "../components/ColoredOjectsTable.react";
import ColoredObjectForm from "../components/ColoredObjectForm.react";
import Loader from "../components/shared/Loader.react";
import { SessionType } from "../types/SessionType";

const SessionTypes: React.FC = () => {
  const sessionTypes = useSelector((state: any) => state.sessionTypes.items);
  const sessionTypesErrors = useSelector((state: any) => state.sessionTypes.errors);
  const fetchingSessionTypes = useSelector((state: any) => state.sessionTypes.fetching);
  const lastUpdatedSessionTypeId = useSelector((state: any) => state.sessionTypes.lastUpdatedSessionTypeId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sessionTypes) dispatch(fetchSessionTypes(urlEventId()));
  }, []);

  const handleCreate = (name: string, color: string): void => {
    dispatch(createSessionType(urlEventId(), { name, color }));
  };

  const handleUpdate = (sessionType: SessionType, name: string, color: string): void => {
    dispatch(updateSessionType(urlEventId(), sessionType._id, { name, color }));
  };

  const handleDelete = (sessionType: any): void => {
    dispatch(deleteSessionType(urlEventId(), sessionType._id));
  };

  const handleCancel = (): void => {
    if (!sessionTypesErrors) return;

    dispatch(clearSessionTypeErrors());
  };

  const renderForm = (): JSX.Element => {
    const errorMessage = sessionTypesErrors && !lastUpdatedSessionTypeId && sessionTypesErrors.name;

    return <ColoredObjectForm handleSave={handleCreate}
      errorMessage={errorMessage}
      i18nKey="session_types"
      fetching={fetchingSessionTypes} />;
  };

  const renderTable = (): JSX.Element => {
    return <ColoredOjectsTable
      items={sessionTypes}
      i18nKey="session_types"
      modelName="session_type"
      lastUpdatedItemId={lastUpdatedSessionTypeId}
      errors={sessionTypesErrors}
      handleCreate={handleCreate}
      handleUpdate={handleUpdate}
      handleDelete={handleDelete}
      handleCancel={handleCancel}
    />;
  };

  const noItems = (): JSX.Element => {
    return <div className="card nothing-yet mt-10">
      <div><i className="fa-regular fa-tags fa-xl"></i></div>
      <h4>{I18n.t("react.session_types.no_items")}</h4>
    </div>;
  };

  const content = (): JSX.Element => {
    if (!sessionTypes) return <Loader />;

    return sessionTypes.length > 0 ? renderTable() : noItems();
  };

  return <>
    <div className="header-page mt-15">
      <div className="header-page-title">
        <h1>{I18n.t("react.session_types.title")}</h1>
      </div>
    </div>

    <div className="row mb-10">
      <div className="col-sm-6 d-flex flex-column justify-content-center">
        {renderForm()}
      </div>
    </div>
    {content()}
  </>;
};

export default SessionTypes;
