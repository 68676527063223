import { useContext } from "react";
import { featureName } from "../../utils/featureSetUtils";
import SlidingPane from "react-sliding-pane";
import Icons from "../../constants/Icons";
import { FeatureDeckContext } from "../../containers/features/FeatureSetFormContainer.react";
import FeaturesList from "./FeaturesList.react";
import FeatureStatus from "./FeatureStatus.react";

interface Props {
  groupKey?: string;
  hide: () => void;
}

const FeaturesPane: React.FC<Props> = (props: Props) => {
  const { groupKey, hide } = props;

  const featurePaneChildren = (groupKey: string): JSX.Element => {
    if (!groupKey) return <></>;

    const { enabledGroupFeatures } = useContext(FeatureDeckContext);

    const activeClass = enabledGroupFeatures(groupKey).length > 0 ? "active" : "";

    return <>
      <div className={`slide-pane-header d-flex flex-row justify-content-between ${activeClass}`}>
        <h2>{ featureName(groupKey) }</h2>
        <FeatureStatus featureKey={groupKey} />
      </div>
      <div className="list-group features-list">
        <FeaturesList groupKey={groupKey} />
      </div>
    </>;
  };

  return <SlidingPane
    isOpen={ !!groupKey }
    width="60%"
    onRequestClose={ hide }
    className="feature-set feature-slide-plane width-80-xs width-50-sm width-40-md"
    closeIcon={ Icons.close() }>
    { featurePaneChildren(groupKey) }
  </SlidingPane>;
};

export default FeaturesPane;
