import { Component, MouseEvent } from "react";
import { connect } from "react-redux";
import { Contact } from "../../types/Contact";
import { generateEmailConfirmationLink } from "../../actions/ContactsActionCreators";
import CopyToClipboardTextArea from "../../components/shared/CopyToClipboardTextArea.react";
import moment from "moment";

// this component assumes contact is in the store
interface Props {
  contact: Contact;
  emailConfirmationLink?: string;
  generateEmailConfirmationLink: (contact: Contact) => void;
}

class ContactSso extends Component<Props> {
  constructor(props: Props) {
    super(props);
    moment.locale(I18n.currentLocale());

    [
      "onGenerateEmailConfirmationLinkClick"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  i18n(key: string, opts: any = {}): string {
    return I18n.t(`react.contacts.contact_sso.${key}`, opts);
  }

  onGenerateEmailConfirmationLinkClick(e: MouseEvent<any>): void {
    e.preventDefault();
    const { contact, generateEmailConfirmationLink } = this.props;
    generateEmailConfirmationLink(contact);
  }

  renderSSOAccountEnabled(): JSX.Element {
    const { contact } = this.props;
    return (
      <p>
        <i className="fa-regular fa-check-circle fa-lg text-success"></i> {this.i18n("account_enabled")} <strong>{moment(contact.confirmed_at).format("LLLL")}</strong>
      </p>
    );
  }

  renderEmailNotConfirmed(): JSX.Element {
    const { emailConfirmationLink } = this.props;
    return (
      <div>
        <p><i className="fa-regular fa-exclamation-circle fa-lg text-warning"></i> {this.i18n("never_confirmed_email")}</p>
        <p className="text-muted">
          <i className="fa-regular fa-info-circle fa-lg"></i> {this.i18n("generate_confirmation_link_help")} <a href="#" onClick={this.onGenerateEmailConfirmationLinkClick}>{this.i18n("generate_confirmation_link")}</a>
        </p>
        {emailConfirmationLink && (
          <CopyToClipboardTextArea value={emailConfirmationLink} rows={2} />
        )}
      </div>
    );
  }

  renderSSOAccountNotEnabled(): JSX.Element {
    return (
      <p>
        <i className="fa-regular fa-circle-xmark fa-lg text-danger"></i> {this.i18n("no_sso_account")}
      </p>
    );
  }

  renderContent(): JSX.Element {
    const { contact } = this.props;
    switch (contact.sso_status) {
    case "has_account":
      return this.renderSSOAccountEnabled();
    case "unconfirmed_account":
      return this.renderEmailNotConfirmed();
    case "no_account":
      return this.renderSSOAccountNotEnabled();
    }
  }

  render(): JSX.Element {
    const { contact } = this.props;
    return (
      <div>
        <h2>{contact.email}</h2>
        {this.renderContent()}
      </div>
    );
  }
}

function mapStateToProps(state: any): any {
  return {
    contact: state.contact.data,
    emailConfirmationLink: state.contact.emailConfirmationLink
  };
}

const mapDispatchToProps = {
  generateEmailConfirmationLink
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactSso);
