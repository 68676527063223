import { ActionTypes } from "../constants/Constants";

function currentSavedSearch(state = null, action) {
  switch (action.type) {
  case ActionTypes.CURRENT_SAVED_SEARCH_SUCCESS:
    return action.data[0] || null;
  case ActionTypes.CHANGE_CURRENT_SAVED_SEARCH:
    return { ...action.data };
  case ActionTypes.CLEAR_CURRENT_SAVED_SEARCH:
    return null;
  default:
    return state;
  }
}

export default currentSavedSearch;
