import { Component } from "react";
import groupBy from "lodash/groupBy";
import { normalizeString } from "../../utils/StringUtils";
import ReactDOM from "react-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ShowAllLink from "./ShowAllLink.react";
import FormItemOption from "./FormItemOption.react";
import SelectOptionsGroupsModal from "./SelectOptionsGroupsModal.react";

const TRUNCATE_LIMIT = 5;

class DropdownType extends Component {

  constructor(props) {
    super(props);
    [
      "defaultValueOnChanged",
      "triggerShowAll",
      "changeSelectedOptionsGroups",
      "checkboxTooltip"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = {
      showImportModal: false,
      showingAll: false
    };
  }

  isOptionKeyAlreadyTaken(key) {
    const { formItem } = this.props;
    return (formItem.form_item_options || []).find(option => {
      return option.key === key;
    }) !== undefined;
  }

  triggerShowAll(showingAll) {
    if (!showingAll) {
      //ensure element stay visible
      const component = this.refs["dropdown-div"];
      const domNode = ReactDOM.findDOMNode(component);
      domNode.scrollIntoView();
    }
    this.setState({ showingAll });
  }

  uniqueKeyForLabel(label) {
    let unique = false;
    let suffix = "";
    let cpt = 1;
    while (!unique) {
      const key = `${normalizeString(label)}${suffix}`;
      if (!this.isOptionKeyAlreadyTaken(key)) {
        return key;
      } else {
        suffix = `_${cpt}`;
        cpt ++;
      }
    }
  }

  defaultValueOnChanged(e) {
    e.preventDefault();
    const { updateFormItem } = this.props;
    const defaultValue = e.target.checked ? e.target.value : null;

    updateFormItem({
      options: { default_value: defaultValue }
    });
  }

  changeSelectedOptionsGroups(optionsGroups) {
    const { updateFormItem } = this.props;
    updateFormItem({
      options: { options_groups: optionsGroups }
    });
  }

  checkboxTooltip(checked) {
    const checkedMessage = checked ? I18n.t("react.form_checkbox_group.checked_by_default") : I18n.t("react.form_checkbox_group.unchecked_by_default");
    return (
      <Tooltip id="tooltip">{checkedMessage}</Tooltip>
    );
  }

  filteredAvailableValues(guestField) {
    const { formItem } = this.props;

    if (formItem.options.options_groups) {
      return guestField.available_values.filter(availableValue => {
        return formItem.options.options_groups.includes(availableValue.group);
      });
    }

    return guestField.available_values || [];
  }

  renderOptions(guestField) {
    const { mode, formItem } = this.props;
    const defaultValue = formItem.options.default_value;

    return this.filteredAvailableValues(guestField).map((availableValue, index) => {
      return (
        <FormItemOption
          formItem={formItem}
          key={index}
          itemOption={availableValue}
          keyInputType="text"
          mode={mode}
          readStyle={{ marginLeft: 30 }}
          disableLabel={true}
          defaultValue={defaultValue}
        >
          { mode == "edit" ?
            <OverlayTrigger placement="top" overlay={this.checkboxTooltip(defaultValue == availableValue.value)}>
              <input type="checkbox" className="form-check-input" checked={defaultValue == availableValue.value} value={availableValue.value} onChange={this.defaultValueOnChanged} />
            </OverlayTrigger>
            : <span>{ index + 1 }.</span>
          }
        </FormItemOption>
      );
    });
  }

  render() {
    const { mode, formItem, guestFields } = this.props;
    const guestField = guestFields.find(gf => gf.key === formItem.key);
    if (!guestField)
      return null;

    const options = this.renderOptions(guestField);
    const truncate = this.state.showingAll ? options.length : TRUNCATE_LIMIT;
    const groupedOptions = groupBy(guestField.available_values || [], "group");
    const grouped = Object.keys(groupedOptions).length > 1; // default group is ""

    return (
      <div>
        <div ref="dropdown-div">
          { options.slice(0, truncate) }
        </div>
        <ShowAllLink
          nbItems={options.length}
          onClickHandler={this.triggerShowAll}
          limit={TRUNCATE_LIMIT}
        />
        { mode == "edit" && formItem.type == "dropdown" && grouped ?
          <SelectOptionsGroupsModal
            formItem={formItem}
            groupedOptions={groupedOptions}
            updateGroups={this.changeSelectedOptionsGroups} />
          : null }
      </div>
    );
  }
}

export default DropdownType;
