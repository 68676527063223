import { fetchExports, fetchGuests, fetchEvent, countGuests, cleanUniqueKeyInSiloedData } from "../utils/APIUtils";
import { ActionTypes } from "../constants/Constants";

module.exports = {
  requestExports() {
    return dispatch => {
      return fetchExports(dispatch);
    };
  },

  requestGuests(completeSearchQuery, page, options = {}) {
    return dispatch => {
      fetchGuests(dispatch, completeSearchQuery, page, options);
    };
  },

  requestEvent(options = {}) {
    return dispatch => {
      fetchEvent(dispatch, options);
    };
  },

  requestCountGuests(completeSearchQuery, appearanceFieldTarget = "selectedGuestCount", countAll = false) {
    return dispatch => {
      countGuests(dispatch, completeSearchQuery, appearanceFieldTarget, countAll);
    };
  },

  removeGuestInSiloedData(uniqueKeyInSiloedData) {
    const { DELETE_GUEST_KEY_IN_SILOED_DATA } = ActionTypes;
    return dispatch => {
      cleanUniqueKeyInSiloedData(dispatch, DELETE_GUEST_KEY_IN_SILOED_DATA, uniqueKeyInSiloedData);
    };
  }
};
