import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import usePrevious from "../../utils/hooks/usePrevious";
import useFabricEditor from "./fabricjs/hook/useFabricEditor";
import { urlEventId, pathToGuestCategoryBadgeBuilder, pathToGuestCategoryShow } from "../../utils/pathUtils";
import { fetchBadgeTemplate, createBadgeTemplate, updateBadgeTemplate } from "../../actions/BadgeTemplateActionCreator";
import { showNotice } from "../../actions/NoticeActionCreators";
import { BadgeImage, BadgeTemplateApiAttributes } from "../../types/BadgeTemplate";
import BadgeBuilderItemsList from "./BadgeBuilderItemsList.react";
import BadgeBuilderNavBar from "./BadgeBuilderNavBar.react";
import BadgeTemplateSettingsModal from "./BadgeTemplateSettingsModal.react";
import PropertiesPanel from "./properties_panel/PropertiesPanel.react";
import NoticeBlock from "../../components/templates_builder/NoticeBlock.react";
import Loader from "../shared/Loader.react";
import BadgeBuilderCanvas from "./BadgeBuilderCanvas.react";
import { registerFont } from "./utils";
import { DEFAULT_EDITOR_PROPERTIES, BADGE_IMAGE_POSITIONS, DEFAULT_CUSTOMISATION_FABRIC_STRUCTURE } from "./Constants";
import yaml from "js-yaml";

const BadgeBuilder: React.FC = () => {
  const { badge_template_id: badgeTemplateId } = useParams();

  const isEditMode = !!badgeTemplateId;

  const [isBadgeSettingsModalOpen, setBadgeSettingsModalOpen] = useState(!isEditMode);
  const [newBackgroundKey, setNewBackgroundKey] = useState<string>("");
  const [badgeImages, setBadgeImages] = useState<BadgeImage[]>([]);
  const [formatSettings, setFormatSettings] = useState({});
  const [defaultElementsAdded, setDefaultElementsAdded] = useState(false);

  const badgeTemplate = useSelector((state: any) => state.badgeTemplate.data);
  const isFetching = useSelector((state: any) => state.badgeTemplate.isFetching);
  const isSaving = useSelector((state: any) => state.badgeTemplate.isSaving);
  const notice = useSelector((state: any) => state.notifications.currentNotice);
  const noticeType = useSelector((state: any) => state.notifications.noticeType);

  const { selectedObjects, editor, onReady: onFabricCanvasReady } = useFabricEditor(DEFAULT_EDITOR_PROPERTIES);
  const previousIsSaving = usePrevious(isSaving);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEditMode) return;

    dispatch(fetchBadgeTemplate(urlEventId(), getGuestCategoryId(), badgeTemplateId));
  }, [badgeTemplateId]);

  useEffect(() => {
    if (previousIsSaving === true && isSaving === false) {
      dispatch(showNotice(I18n.t("react.badge_builder.submit_form.update"), "success"));
    }
  }, [isSaving]);

  const fetchFormatSettings = (): void => {
    fetch("https://templates.applidget.com/mobicheckin/config/badges.templates.yml")
      .then(response => response.text())
      .then((data) => {
        const yamlData = yaml.load(data);
        setFormatSettings(yamlData.common[badgeTemplate.badge_format] || yamlData.common);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (!badgeTemplate || defaultElementsAdded) return;

    if (isEditMode) {
      setBadgeImages(badgeTemplate.badge_images);
      fetchFormatSettings();
      return;
    }

    window.location = pathToGuestCategoryBadgeBuilder(urlEventId(), badgeTemplate["_id"], getGuestCategoryId());
  }, [badgeTemplate]);

  useEffect(() => {
    registerBadgeFonts();
  }, [badgeTemplate?.badge_fonts]);

  const registerBadgeFonts = (cb?: () => void): void => {
    if (badgeTemplate?.badge_fonts?.length > 0) {
      Promise.all(badgeTemplate.badge_fonts.map(badgeFont => {
        const fontName = badgeFont._id;
        const fontUrl = badgeFont.font.url;
        const fontAlreadyRegistered = Array.from(document.fonts as unknown as Iterable<FontFace>).find(fontFace => fontFace.family === fontName);
        if (fontAlreadyRegistered) return;

        return registerFont(fontName, `url(${fontUrl})`, (loadedFont) => {
          (document.fonts as any).add(loadedFont);
        });
      })).then(() => {
        cb && cb();
      });
    } else {
      cb && cb();
    }
  };

  const addDottedLinesIfNeeded = (): void => {
    const noDottedLines = formatSettings["no_dotted_lines"];
    if (noDottedLines === undefined || noDottedLines === true) return;

    editor?.addDottedLines();
  };

  const initEditorState = (): void => {
    if (badgeTemplate.customized && Object.keys(badgeTemplate.fabriq_json).length === 0) {
      const defaultElements = DEFAULT_CUSTOMISATION_FABRIC_STRUCTURE[badgeTemplate.badge_format];
      if (defaultElements)
        editor?.createDefaultElementsOnCanvas(defaultElements, badgeImages, badgeTemplate.badge_format);
      else
        alert("Badge format not recognized!");
    } else {
      registerBadgeFonts(() => {
        editor?.loadFromJSON(badgeTemplate.fabriq_json);
      });
    }
  };

  useEffect(() => {
    if (defaultElementsAdded || !editor || !badgeTemplate || Object.keys(formatSettings).length === 0) return;

    setDefaultElementsAdded(true);
    initEditorState();
    addDottedLinesIfNeeded();
    editor.unselectAll();
  }, [badgeTemplate, editor, formatSettings]);

  const getBadgeFormat = (): string => {
    const params = new URL(document.location.toString()).searchParams;
    return isEditMode ? badgeTemplate?.badge_format : params.get("badge_format");
  };

  const getGuestCategoryId = (): string => {
    const params = new URL(document.location.toString()).searchParams;
    return params.get("guest_category_id");
  };

  const toggleBadgeSettingsModal = (): void => {
    if (badgeTemplate) {
      setBadgeSettingsModalOpen(!isBadgeSettingsModalOpen);
    } else {
      window.location = pathToGuestCategoryShow(getGuestCategoryId());
    }
  };

  const updateBadgeImagesList = (badgeImage: BadgeImage, destroy?: boolean): void => {
    const { position, url, file, width, height } = badgeImage;
    let newValues = [...badgeImages];
    const imageSelected = newValues.find(badgeImage => badgeImage.position === position);

    if (destroy) {
      if (imageSelected?._id) {
        imageSelected.destroy = true;
      } else {
        newValues = newValues.filter(badgeImage => badgeImage !== imageSelected);
      }
    } else {
      if (imageSelected?._id) {
        imageSelected.url = url;
        imageSelected.file = file;
        imageSelected.width = width;
        imageSelected.height = height;
      } else {
        newValues.push({ file, position, url, width, height }) ;
      }
    }

    setBadgeImages(newValues);
  };

  const onChangeImage = (badgeImage: BadgeImage): void => {
    const { url, position } = badgeImage;

    updateBadgeImagesList(badgeImage);
    if (BADGE_IMAGE_POSITIONS.includes(position)) {
      editor?.addBackground(url, position, badgeTemplate?.badge_format);
    } else {
      editor?.addImage(url, position);
    }
  };

  const onDeleteImage = (position: string): void => {
    updateBadgeImagesList(badgeImages.find(img => img.position === position), true);
    editor?.removeSelectedObject();
  };

  const newImageSize = (badgeImage: BadgeImage, fabricJson: any): [string, string] => {
    const imageObject = fabricJson?.objects?.find(object => object.type === "image" && object.imageKey === badgeImage.position);
    if (!imageObject) return [badgeImage.width, badgeImage.height];

    return [Math.trunc(imageObject.width * imageObject.scaleX).toString(), Math.trunc(imageObject.height * imageObject.scaleY).toString()];
  };

  const getFormData = (params: BadgeTemplateApiAttributes): any => {
    const data = new FormData();
    const fabricJson = editor?.exportToJSON();
    if (params) {
      // Update modal settings
      data.append("badge_template[name]", params.name);
      data.append("badge_template[badge_display_name]", params.badge_display_name);
      data.append("badge_template[badge_qr_code_enabled]", params.badge_qr_code_enabled.toString());
      data.append("badge_template[badge_ecard_in_qr_code_enabled]", params.badge_ecard_in_qr_code_enabled.toString());
      data.append("badge_template[badge_format]", params.badge_format);
    }
    // Update images
    badgeImages?.map((badgeImage, index) => {
      if (badgeImage.destroy) {
        data.append(`badge_template[badge_images_attributes][${index}][_destroy]`, "true");
      } else if (badgeImage.file) {
        const [newWidth, newHeight] = newImageSize(badgeImage, fabricJson);
        data.append(`badge_template[badge_images_attributes][${index}][height]`, newHeight);
        data.append(`badge_template[badge_images_attributes][${index}][width]`, newWidth);
        data.append(`badge_template[badge_images_attributes][${index}][image]`, badgeImage.file);
      }
      data.append(`badge_template[badge_images_attributes][${index}][position]`, badgeImage.position);
    });
    // Update JSON
    data.append("badge_template[fabriq_json]", JSON.stringify(fabricJson));

    return data;
  };

  const onSubmit = (params: BadgeTemplateApiAttributes, fromModal: boolean): void => {
    if (isEditMode) {
      const withUpdatedImages = !!badgeImages?.find(badgeImage => badgeImage.file || badgeImage.destroy);
      const newParams = withUpdatedImages ? getFormData(params) : { ...params, fabriq_json: JSON.stringify(editor?.exportToJSON()) };
      if (fromModal) toggleBadgeSettingsModal();
      dispatch(updateBadgeTemplate(urlEventId(), getGuestCategoryId(), badgeTemplateId, newParams, withUpdatedImages));
    } else {
      dispatch(createBadgeTemplate(urlEventId(), getGuestCategoryId(), params));
    }
  };

  const renderNavBar = (): JSX.Element => {
    return <BadgeBuilderNavBar
      eventId={urlEventId()}
      badgeTemplateId={badgeTemplateId}
      guestCategoryId={getGuestCategoryId()}
      title={badgeTemplate?.name}
      isEditMode={isEditMode}
      editor={editor}
      badgeImages={badgeImages}
      onChangeImage={onChangeImage}
    />;
  };

  const renderSettingsModal = (): JSX.Element => {
    return <BadgeTemplateSettingsModal
      isOpen={isBadgeSettingsModalOpen}
      badgeTemplate={badgeTemplate}
      isEditMode={isEditMode}
      badgeFormat={getBadgeFormat()}
      toggleModal={toggleBadgeSettingsModal}
      onSubmitModal={(params: BadgeTemplateApiAttributes): void => onSubmit(params, true)}
      isSaving={isSaving}
    />;
  };

  const renderPropertiesPanel = (): JSX.Element => {
    if (isFetching) return <Loader />;

    return <div className="col-3 sidebar sidebar-right">
      <PropertiesPanel
        badgeTemplateId={badgeTemplate?._id}
        newBackgroundKey={newBackgroundKey}
        selectedObjects={selectedObjects}
        editor={editor}
        badgeFonts={badgeTemplate?.badge_fonts}
        badgeImages={badgeImages}
        imageFormatSettings={formatSettings["images"]}
        onChangeImage={onChangeImage}
        onDeleteImage={onDeleteImage}
      />
    </div>;
  };

  const renderItemsList = (): JSX.Element => {
    if (isFetching) return <Loader />;

    return <BadgeBuilderItemsList
      badgeImages={badgeImages}
      editor={editor}
      selectedObjects={selectedObjects}
      imageFormatSettings={formatSettings["images"]}
      setNewBackgroundKey={setNewBackgroundKey}
      toggleBadgeSettingsModal={toggleBadgeSettingsModal}
    />;
  };

  const renderItemsListPanel = (): JSX.Element => {
    return <div className="col-3 sidebar sidebar-left">
      {renderItemsList()}
      <div className="sidebar-footer row">
        <NoticeBlock notice={notice} noticeType={noticeType} />
        <div className="text-end p-10">
          <Button className="btn btn-primary" onClick={(): void => onSubmit(badgeTemplate, false)} disabled={isSaving}>
            {isSaving ? I18n.t("react.badge_builder.badge_builder_nav_bar.please_wait") : I18n.t("save")}
          </Button>
        </div>
      </div>
    </div>;
  };

  const renderFabricCanvas = (): JSX.Element => {
    if (!formatSettings["page_size"] && !badgeTemplate?.page_size_product) return null;

    return <BadgeBuilderCanvas
      onReady={onFabricCanvasReady}
      canvasSize={formatSettings["page_size"] || badgeTemplate.page_size_product}
    />;
  };

  return <>
    <div className={`badge-builder ${isSaving ? "disabled" : ""}`}>
      {renderNavBar()}
      <div className="container-fluid">
        <div className="row full-height-form">
          {renderItemsListPanel()}
          <div className="col-6" style={{ position: "relative" }}>
            {renderFabricCanvas()}
          </div>
          {renderPropertiesPanel()}
        </div>
      </div>
    </div>
    {renderSettingsModal()}
  </>;
};

export default BadgeBuilder;
