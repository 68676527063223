import { Component } from "react";
import { connect } from "react-redux";
import find from "lodash/find";
import { updateUI } from "../actions/UIAppearanceActionCreators";
import { requestCountGuests } from "../actions/ImportActionCreators";
import { fetchGuestFieldsMostUsedValues } from "../actions/GuestFieldsActionCreators";
import { launchBatchUpdate } from "../actions/GuestUpdatesActionCreators";
import { clearStatistics } from "../actions/GuestFieldsActionCreators";
import UpdateGuestFormComponent from "../components/UpdateGuestFormComponent.react";
import { CalculatedType, LazyCalculatedType, BulkEditableStandardsFields, BooleanGuestFields } from "../constants/Constants";
import { queryStringAndSelectedGuests } from "../utils/QueryStringUtils";
import withModal from "./withModal.react";
import { urlEventId } from "../utils/pathUtils";

class ChangeGuestField extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { match, location, selectedGuests, fetchGuestFieldsMostUsedValues, requestCountGuests, updateUI } = this.props;
    const updates = {
      "modalTitle": I18n.t("react.reports.modify_guest_field")
    };
    updateUI(updates);
    fetchGuestFieldsMostUsedValues(urlEventId(), "", match.params.key);
    const query = queryStringAndSelectedGuests(location, selectedGuests);
    fetchGuestFieldsMostUsedValues(urlEventId(), query, match.params.key);
    requestCountGuests(query, "nbGuestsWillBeUpdated", true);
  }

  componentWillUnmount() {
    const { clearStatistics } = this.props;
    clearStatistics();
  }

  submit(value) {
    const { match, location, closeModal, selectedGuests, launchBatchUpdate } = this.props;
    // update fields
    launchBatchUpdate(urlEventId(), queryStringAndSelectedGuests(location, selectedGuests), match.params.key, value);
    closeModal();
  }

  matchingField() {
    const { guestFields, match } = this.props;
    const fieldKey = match.params.key;
    const guestField = find(guestFields, field => field.key === fieldKey);

    if (BulkEditableStandardsFields.includes(fieldKey)) {
      if (BooleanGuestFields.includes(fieldKey)) {
        return { key: fieldKey, label: I18n.t(`mongoid.attributes.guest.${fieldKey}`), type: "boolean" };
      }
      return guestField;
    } else if (guestField && Object.keys(guestField).includes("_id") && ![CalculatedType, LazyCalculatedType].includes(guestField.type)) {
      return guestField;
    }
  }

  render() {
    const { eventFieldMostUsedValues, searchFieldMostUsedValues, selectedGuestCount } = this.props;
    const matchingField = this.matchingField();

    if (matchingField) {
      return (
        <UpdateGuestFormComponent
          eventFieldMostUsedValues={eventFieldMostUsedValues}
          searchFieldMostUsedValues={searchFieldMostUsedValues}
          selectedGuestCount={selectedGuestCount}
          fieldLabel={matchingField.label}
          fieldKey={matchingField.key}
          fieldType={matchingField.type}
          submitAction={this.submit}
          enableSubmitButton={true}
          availableValues={matchingField.available_values}
        />
      );
    }

    return (
      <div className="alert alert-warning">
        <strong>{I18n.t("react.reports.warning_guest_field")} !</strong>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    guestFields: state.guestFields.guestFields,
    eventFieldMostUsedValues: state.guestFieldStatistics.eventFieldMostUsedValues,
    searchFieldMostUsedValues: state.guestFieldStatistics.searchFieldMostUsedValues,
    selectedGuestCount: state.appearance.nbGuestsWillBeUpdated,
    selectedGuests: state.guests.selectedGuests
  };
}

const mapDispatchToProps = {
  fetchGuestFieldsMostUsedValues,
  launchBatchUpdate,
  requestCountGuests,
  clearStatistics,
  updateUI
};

export default connect(mapStateToProps, mapDispatchToProps)(withModal(ChangeGuestField));
