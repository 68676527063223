import { ActionTypes } from "../constants/Constants";
import sortBy from "lodash/sortBy";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";
import { FormItemsWithValueList } from "../constants/Constants";

function registrationFormItems(state = [], action) {
  switch (action.type) {

  case ActionTypes.REGISTRATION_FORM_SUCCESS: {
    const { formItems } = action.data.entities;
    state = Object.keys(formItems || []).map((key) => {
      removeGuestFieldValueListFromItemOptions(formItems[key]);
      return formItems[key];
    });
    return sortBy(state, ["rank"]);
  }

  case ActionTypes.REGISTRATION_FORM_ITEM_CREATE_SUCCESS: {
    removeGuestFieldValueListFromItemOptions(action.data);
    const newState = insertOrUpdateWithSort(state, Object.assign({}, action.data, { error: null, highlightError: false }));
    if (action.commitForTemporaryId) {
      return stateWithoutItemWithId(newState, action.commitForTemporaryId);
    }
    return newState;
  }

  case ActionTypes.REGISTRATION_FORM_ITEM_UPDATE_SUCCESS: {
    removeGuestFieldValueListFromItemOptions(action.data);
    const formItem = (formItemWithSortedOption(action.data));
    const errors = action.keepErrors ? formItem.error : null;
    const currentFormItemInState = state.find(item => item._id == formItem._id);
    const nbUpdated = (currentFormItemInState && currentFormItemInState.nbUpdated || 0) + 1;
    return insertOrUpdateWithSort(state, Object.assign({}, formItem, { highlightError: false, error: errors, nbUpdated }));
  }

  case ActionTypes.REGISTRATION_FORM_ITEM_UPDATE_FAILURE:
  case ActionTypes.REGISTRATION_FORM_ITEM_CREATE_FAILURE:
    return apiFaillureRollback(state, action);

  case ActionTypes.REGISTRATION_FORM_ITEM_UPDATE_REQUEST:
    if (action.optimistic) {
      const formItem = formItemWithSortedOption(Object.assign({}, action.params, { _id: action.formItemId, error: undefined, highlightError: false }));
      return insertOrUpdateWithSort(state, formItem);
    }
    return state;

  case ActionTypes.REGISTRATION_FORM_ITEM_DESTROY_SUCCESS:
    return stateWithoutItemWithId(state, action.formItem._id);

  case ActionTypes.REGISTRATION_FORM_ITEM_DESTROY_FAILURE:
  case ActionTypes.REGISTRATION_FORM_ITEM_OPTION_DESTROY_FAILURE:
    return insertOrUpdateObject(state, Object.assign({}, action.formItem, { highlightError: true, error: action.data.errors }), "_id");

  case ActionTypes.REGISTRATION_FORM_ITEM_OPTION_DESTROY_SUCCESS:
    return findAndRemoveFormItemOption(state, action.formItemId, action.formItemOptionId);

  case ActionTypes.REGISTRATION_FORM_ITEM_NEW:
    return insertOrUpdateWithSort(state, action.formItem);

  case ActionTypes.REGISTRATION_FORM_ITEM_DISPLAY_ERROR:
    return insertOrUpdateObject(state, Object.assign({}, action.formItem, { highlightError: true }), "_id");

  case ActionTypes.REGISTRATION_FORM_ITEM_BATCH_CREATE_SUCCESS:
    action.data.form_items.forEach(formItem => state.push(formItem));
    return state.slice();

  case ActionTypes.REGISTRATION_FORM_ITEM_BATCH_CREATE_FAILURE: {
    const formItem = state.find(item => item.key == "company_name");
    formItem.companyFieldsErrors = action.data.errors;
    return state.slice();
  }

  default:
    return state;
  }
}

function insertOrUpdateWithSort(state, formItem) {
  return sortBy(insertOrUpdateObject(state, formItem, "_id"), ["rank"]);
}

function apiFaillureRollback(state, action) {
  const formItem = Object.assign({}, action.rollback);
  const newState = insertOrUpdateWithSort(state, formItem);
  return injectErrors(newState, formItem._id, action.data);
}

function formItemWithSortedOption(formItem) {
  if (formItem.form_item_options) {
    formItem.form_item_options = sortBy(formItem.form_item_options, ["rank"]);
  }
  return formItem;
}

function stateWithoutItemWithId(state, itemId) {
  return state.filter(item => {
    return item._id != itemId;
  });
}

function injectErrors(state, formItemId, data) {
  //in order to use ErrorMessages
  let prettyError = data.errors && Object.keys(data.errors).length > 0 ? data.errors : { key: [I18n.t("react.registration_form.unknown_error")] };
  const errorsHash = { highlightError: true, error: prettyError };
  return state.map(item => {
    if (item._id == formItemId) {
      item.mode = "edit"; // focus on element with errors
      return Object.assign({}, item, errorsHash);
    }
    return item;
  });
}

function findAndRemoveFormItemOption(state, formItemId, formItemOptionId) {
  return state.map(item => {
    if (item._id === formItemId) {
      const formItemsModified = item.form_item_options.filter(fio => {
        if (fio._id !== formItemOptionId) {
          return fio;
        }
      });
      return Object.assign({}, item, { form_item_options: formItemsModified });
    }
    return item;
  });
}

function removeGuestFieldValueListFromItemOptions(data) {
  if (FormItemsWithValueList.includes(data.type)) delete data.form_item_options;
  return data;
}

export default registrationFormItems;
