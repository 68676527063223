import { ActionTypes, CALL_API } from "../constants/Constants";

export function fetchDocumentTemplate(eventId: string, documentTemplateId: string, guestCategoryId: string = null): any {
  const { FETCH_DOCUMENT_TEMPLATE_PENDING, FETCH_DOCUMENT_TEMPLATE_SUCCESS, DEV_NULL } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_DOCUMENT_TEMPLATE_PENDING, FETCH_DOCUMENT_TEMPLATE_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: guestCategoryId ? `/api/v1/events/${eventId}/guest_categories/${guestCategoryId}/document_templates/${documentTemplateId}.json` : `/api/v1/events/${eventId}/document_templates/${documentTemplateId}.json?builder=true`
    }
  };
}

export function createDocumentTemplate(eventId: string, documentTemplateAttributes: any, notificationsOptions?: any): any {
  const { CREATE_DOCUMENT_TEMPLATE_PENDING, CREATE_DOCUMENT_TEMPLATE_SUCCESS, CREATE_DOCUMENT_TEMPLATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_DOCUMENT_TEMPLATE_PENDING, CREATE_DOCUMENT_TEMPLATE_SUCCESS, CREATE_DOCUMENT_TEMPLATE_FAILURE],
      method: "POST",
      body: { document_template: documentTemplateAttributes },
      endpoint: `/api/v1/events/${eventId}/document_templates.json`,
      notificationsOptions
    }
  };
}

export function updateDocumentTemplate(eventId: string, documentTemplateId: string, documentTemplateAttributes: any, notificationsOptions?: any): any {
  const { UPDATE_DOCUMENT_TEMPLATE_PENDING, UPDATE_DOCUMENT_TEMPLATE_SUCCESS, UPDATE_DOCUMENT_TEMPLATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_DOCUMENT_TEMPLATE_PENDING, UPDATE_DOCUMENT_TEMPLATE_SUCCESS, UPDATE_DOCUMENT_TEMPLATE_FAILURE],
      method: "PUT",
      body: { document_template: documentTemplateAttributes },
      endpoint: `/api/v1/events/${eventId}/document_templates/${documentTemplateId}.json?builder=true`,
      notificationsOptions
    }
  };
}

export function deleteDocumentTemplate(eventId: string, documentTemplateId: string, notificationsOptions?: any): any {
  const { DELETE_DOCUMENT_TEMPLATE_PENDING, DELETE_DOCUMENT_TEMPLATE_SUCCESS, DELETE_DOCUMENT_TEMPLATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DELETE_DOCUMENT_TEMPLATE_PENDING, DELETE_DOCUMENT_TEMPLATE_SUCCESS, DELETE_DOCUMENT_TEMPLATE_FAILURE],
      method: "DELETE",
      endpoint: `/api/v1/events/${eventId}/document_templates/${documentTemplateId}.json`,
      notificationsOptions
    },
    documentTemplateId
  };
}

export function duplicateDocumentTemplate(eventId: string, documentTemplateId: string, documentTemplateAttributes: any, duplicateInCurrentEvent: boolean): any {
  const { DUPLICATE_DOCUMENT_TEMPLATE_PENDING, DUPLICATE_DOCUMENT_TEMPLATE_SUCCESS, DUPLICATE_DOCUMENT_TEMPLATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DUPLICATE_DOCUMENT_TEMPLATE_PENDING, DUPLICATE_DOCUMENT_TEMPLATE_SUCCESS, DUPLICATE_DOCUMENT_TEMPLATE_FAILURE],
      method: "POST",
      body: { document_template: documentTemplateAttributes },
      endpoint: `/api/v1/events/${eventId}/document_templates/${documentTemplateId}/duplicate.json`
    },
    duplicateInCurrentEvent: duplicateInCurrentEvent || eventId === documentTemplateAttributes.targetEventId
  };
}

export function clearDocumentTemplateDuplicationErrors(): any {
  return {
    type: ActionTypes.CLEAR_DOCUMENT_TEMPLATE_DUPLICATION_ERRORS
  };
}

export function fetchDocumentTemplateSectionTypes(eventId: string, documentTemplateId: string): any {
  const { DEV_NULL, RECEIVE_SECTION_TYPES_SUCCESS, RECEIVE_SECTION_TYPES_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_SECTION_TYPES_SUCCESS, RECEIVE_SECTION_TYPES_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/document_templates/${documentTemplateId}/section_types.json`
    },
    builderType: "document"
  };
}

export function reloadTheme(eventId: string, documentTemplateId: string, templateProjectRoot: string, notificationsOptions?: any): any {
  const { RELOAD_DOCUMENT_TEMPLATE_THEME_PENDING, RELOAD_DOCUMENT_TEMPLATE_THEME_SUCCESS, RELOAD_DOCUMENT_TEMPLATE_THEME_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [RELOAD_DOCUMENT_TEMPLATE_THEME_PENDING, RELOAD_DOCUMENT_TEMPLATE_THEME_SUCCESS, RELOAD_DOCUMENT_TEMPLATE_THEME_FAILURE],
      method: "PUT",
      body: { template_project_root: templateProjectRoot },
      endpoint: `/api/v1/events/${eventId}/document_templates/${documentTemplateId}/reload_theme.json`,
      nextAction: fetchDocumentTemplateSectionTypes(eventId, documentTemplateId),
      notificationsOptions
    }
  };
}
