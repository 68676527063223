import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGuestProductCollections } from "../../actions/GuestProductCollectionsActionCreators";
import { urlEventId } from "../../utils/pathUtils";
import FilterDropdown from "../FilterDropdown.react";

export interface Props {
  guestProductCollectionId: string;
  label: string;
  value: string;
  helpMessage?: string;

  onChange: (value: string) => void;
}

const GuestProductCollectionFieldPicker: React.FC<Props> = ({ value, label, helpMessage, guestProductCollectionId, onChange }) => {
  const guestProductCollections = useSelector((state: any) => state.guestProductCollections.data);
  const isFetchingGuestProductCollections = useSelector((state: any) => state.guestProductCollections.isFetching);

  const dispatch = useDispatch();

  useEffect(() => {
    if (guestProductCollections === null && !isFetchingGuestProductCollections) dispatch(fetchGuestProductCollections(urlEventId()));
  }, []);

  if (!guestProductCollections || guestProductCollections.length === 0) return null;

  const guestProductCollection = guestProductCollections.find(collection => collection._id === guestProductCollectionId);
  if (!guestProductCollection) return null;

  const items = guestProductCollection.collection_fields || [];
  const selectedItem = items.find(item => item._id === value);

  return <div className="mb-3">
    <label className="form-label">{ label }</label>
    <div>
      <FilterDropdown
        id="guest_product_collection_field_picker"
        translationKey="guest_product_collection_field_picker" // must be same as id, used by FilterDropdown.handleFilterPlacement
        items={guestProductCollection.collection_fields}
        selectedItemIds={ selectedItem ? [selectedItem._id] : [] }
        showCells={false}
        hasSelectAll={false}
        multipleSelect={false}
        sortItems={false}
        itemIdKey="_id"
        onChange={onChange}
        title={selectedItem?.name}
      />
    </div>
    { helpMessage }
  </div>;
};

export default GuestProductCollectionFieldPicker;
