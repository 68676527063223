import sortBy from "lodash/sortBy";
import PropTypes from "prop-types";
import Countries from "../../constants/Countries.yml";
import { transliterate as tr } from "transliteration";

const CountrySelect = ({ value, locale, className, onChange, onBlur, defaultLabel, formatDisplay }) => {
  const sortedCountries = sortBy(Countries, (country) => {
    return tr(country[locale]);
  });

  const countries = sortedCountries.map(country => {
    let label = country[locale];
    if (formatDisplay) {
      label = formatDisplay(country, locale);
    }
    return <option key={country.code} value={country.code}>{label}</option>;
  });

  return (
    <select className={className} value={value} onChange={onChange} onBlur={onBlur}>
      <option key="default" value="">{ defaultLabel }</option>
      {countries}
    </select>
  );
};

CountrySelect.propTypes = {
  value: PropTypes.string,
  locale: PropTypes.string.isRequired,
  defaultLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  formatDisplay: PropTypes.func,
  onBlur: PropTypes.func
};

CountrySelect.defaultProps = {
  className: "form-control",
  defaultLabel: I18n.t("react.country_select.default_option")
};

export default CountrySelect;
