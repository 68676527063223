import PropTypes from "prop-types";

const ImageCell = ({ guest, field }) => {
  return guest[field] ? <img src={ guest[field] } className="rounded-circle mr-10" style={{ maxWidth: "30px", border: "1px solid #E0E0E0" }} /> : null;
};

ImageCell.propTypes = {
  guest: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default ImageCell;
