import { Component } from "react";
import Filter from "../Filter.react";
import SlidingPane from "react-sliding-pane";
import withEmailTemplatesContainer from "../../containers/EmailTemplatesContainer.react";
import TemplateCreationButtons from "../../containers/shared/TemplateCreationButtons.react";

import TargetEventModal from "../duplication_modals/TargetEventModal.react";
import AskModelNameModal from "../duplication_modals/AskModelNameModal.react";
import DuplicationRunningModal from "../duplication_modals/DuplicationRunningModal.react";
import TemplateActionButtons from "../shared/TemplateActionButtons.react";
import EditTemplateWarningModal from "../shared/EditTemplateWarningModal.react";
import TemplatePreviewIframe from "../shared/TemplatePreviewIframe.react";
import EmailBuilder from "../../containers/EmailBuilder.react";
import Loader from "../../components/shared/Loader.react";
import { generateRandomIdentifier } from "../../utils/identifierUtils";
import Icons from "../../constants/Icons";

class EmailTemplates extends Component {
  constructor(props) {
    super(props);
    [
      "onFilterChange",
      "openSelectEventModal",
      "deleteEmailTemplate",
      "onEventTargetSelected",
      "duplicateIntoEvent",
      "renderActionButtons",
      "toggleEditWarningModal",
      "openEmailBuilder",
      "closeEmailBuilder",
      "openNewEmailTemplateModal",
      "createEmailTemplate"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      searchValue: null,
      showDuplicationRunningModal: false,
      showNewEmailTemplateNameModal: false,
      showDuplicateEmailTemplateNameModal: false,
      duplicateTargetEventId: null,
      showEventPickerModal: false,
      showEditWarningModal: false,
      isEmailBuilderOpen: false,
      creatingNewEmail: false,
      previewIframeKey: generateRandomIdentifier()
    };
  }

  componentDidUpdate(prevProps) {
    const { duplicationErrors, templates, creationErrors } = this.props;
    const { showDuplicationRunningModal, creatingNewEmail } = this.state;

    if (showDuplicationRunningModal && Object.keys(prevProps.duplicationErrors).length == 0 && Object.keys(duplicationErrors).length > 0) {
      this.setState({
        showDuplicateEmailTemplateNameModal: true,
        showDuplicationRunningModal: false
      });
    }

    if (creatingNewEmail && Object.keys(creationErrors).length > 0) {
      this.setState({ creatingNewEmail: false });
    }

    if (prevProps.templates && templates.length > prevProps.templates.length) {
      this.setState({ showNewEmailTemplateNameModal: false, creatingNewEmail: false });
    }
  }

  deleteEmailTemplate(emailTemplateId) {
    return () => {
      const { deleteEmailTemplate, eventId } = this.props;
      if (confirm(I18n.t("confirm"))) {
        deleteEmailTemplate(eventId, emailTemplateId);
      }
    };
  }

  openEmailBuilder() {
    this.setState({ isEmailBuilderOpen: true });
  }

  closeEmailBuilder() {
    this.setState({ isEmailBuilderOpen: false });
    this.enableBodyScroll();
    this.reloadEmailPreview();
  }

  disableBodyScroll() {
    document.body.style.overflow = "hidden";
  }

  enableBodyScroll() {
    document.body.removeAttribute("style");
  }

  reloadEmailPreview() {
    this.setState({ previewIframeKey: generateRandomIdentifier() });
  }

  renderCopyModals() {
    const { event, duplicationErrors, duplicatedEmail } = this.props;
    const { showEventPickerModal, showDuplicateEmailTemplateNameModal, showDuplicationRunningModal, duplicateTargetEventId } = this.state;
    return (
      <div>
        <TargetEventModal
          isVisible={showEventPickerModal}
          onClose={() => { this.setState({ showEventPickerModal: false }); }}
          currentEvent={event}
          targetReadableName={I18n.t("react.email_templates.target_name")}
          onEventSelected={this.onEventTargetSelected}/>

        <AskModelNameModal
          isVisible={showDuplicateEmailTemplateNameModal}
          onClose={() => { this.setState({ showDuplicateEmailTemplateNameModal: false }); }}
          onSubmit={this.duplicateIntoEvent}
          i18nRootKey="email_templates"
          errors={duplicationErrors}
          modalSize={null} />

        <DuplicationRunningModal
          isVisible={showDuplicationRunningModal}
          onClose={() => { this.setState({ showDuplicationRunningModal: false }); }}
          model="email_templates"
          currentEventId={event._id}
          targetEventId={duplicateTargetEventId}
          duplicatedData={duplicatedEmail}/>
      </div>
    );
  }

  onEventTargetSelected(targetEventId) {
    this.setState({
      showEventPickerModal: false,
      showDuplicateEmailTemplateNameModal: true,
      duplicateTargetEventId: targetEventId
    });
  }

  duplicateIntoEvent(newName) {
    const { duplicateEmailTemplate, eventId, selectedTemplateId } = this.props;
    const { duplicateTargetEventId } = this.state;
    const emailTemplateParams = {
      new_name: newName,
      target_event_id: duplicateTargetEventId
    };
    duplicateEmailTemplate(eventId, selectedTemplateId, emailTemplateParams);
    this.setState({
      showDuplicateEmailTemplateNameModal: false,
      showDuplicationRunningModal: true
    });
  }

  createEmailTemplate(emailName) {
    const { createEmailTemplate, eventId } = this.props;

    this.setState({ creatingNewEmail: true });
    createEmailTemplate(eventId, emailName);
  }

  openNewEmailTemplateModal() {
    this.setState({ showNewEmailTemplateNameModal: true });
  }

  onFilterChange(result) {
    const { onSelectTemplate, updateEmailSelectedId } = this.props;
    const { searchValue, selectedItemIds } = result;

    if (searchValue !== undefined) // we need to enter this condition when "" (which is falsy)
      this.setState({ searchValue: searchValue.length > 0 ? searchValue : null });

    if (selectedItemIds) {
      updateEmailSelectedId(selectedItemIds[0]);
      if (onSelectTemplate) onSelectTemplate(selectedItemIds[0]);
    }
  }

  openSelectEventModal() {
    const { eventId } = this.props;
    this.setState({ duplicateTargetEventId: eventId, showEventPickerModal: true });
  }

  toggleEditWarningModal() {
    const { showEditWarningModal } = this.state;
    this.setState({ showEditWarningModal: !showEditWarningModal });
  }

  renderTemplateUses(template) {
    if (template.uses_count == 0) return null;
    return <span className="badge rounded-pill bg-secondary ml-5">{template.uses_count}</span>;
  }

  renderActionButtons(emailTemplate) {
    const { duplicateEmailTemplate, deleteEmailTemplate, enableEdit, builderInSlidingPane, enableDelete, persistedEmailTemplateId, inModal } = this.props;
    // we must display warning for templates used by at least one other record than the one we may be editing
    const minUseForWarning = (persistedEmailTemplateId && persistedEmailTemplateId == emailTemplate._id) ? 2 : 1;

    return <TemplateActionButtons
      templateId={emailTemplate._id}
      templateType="email_template"
      onEditRequest={emailTemplate.uses_count >= minUseForWarning ? this.toggleEditWarningModal : null}
      enableEdit={enableEdit}
      enableDuplication={typeof duplicateEmailTemplate == "function"}
      openSelectEventModal={this.openSelectEventModal}
      deleteTemplate={deleteEmailTemplate && this.deleteEmailTemplate}
      builderInSlidingPane={builderInSlidingPane}
      openBuilder={this.openEmailBuilder}
      enableDelete={enableDelete && emailTemplate.uses_count == 0}
      inModal={inModal}
    />;
  }

  renderCreationButtons() {
    const { defaultCreateButton, duplicatedEmail, duplicationErrors, duplicationWarnings } = this.props;

    return <div>
      <TemplateCreationButtons
        templateType="email_template"
        onNewTemplate={this.openNewEmailTemplateModal}
        defaultCreateButton={defaultCreateButton}
        duplicatedTemplate={duplicatedEmail}
        duplicationErrors={duplicationErrors}
        duplicationWarnings={duplicationWarnings}
      />
    </div>;
  }

  renderNewEmailTemplateModal() {
    const { showNewEmailTemplateNameModal, creatingNewEmail } = this.state;
    const { creationErrors } = this.props;

    return <AskModelNameModal
      isVisible={showNewEmailTemplateNameModal}
      onClose={() => { this.setState({ showNewEmailTemplateNameModal: false }); }}
      i18nRootKey="create_email_templates"
      onSubmit={this.createEmailTemplate}
      errors={creationErrors}
      showLoader={creatingNewEmail}
      modalSize={null}
    />;
  }

  renderEmailBuilder() {
    const { isEmailBuilderOpen } = this.state;
    const { slidingPaneTitle, selectedTemplateId, match, location, history } = this.props;

    return (
      <SlidingPane isOpen={ isEmailBuilderOpen }
        width='90%'
        title={ slidingPaneTitle }
        onAfterOpen={ this.disableBodyScroll }
        onRequestClose={ this.closeEmailBuilder }
        className="width-100-xs width-100-sm"
        closeIcon={Icons.close()}>
        <EmailBuilder
          emailTemplateId={selectedTemplateId}
          match={match}
          location={location}
          history={history}
        />
      </SlidingPane>
    );
  }

  renderEmailTemplates() {
    return <>
      {this.renderList()}
      {this.renderPreview()}
    </>;
  }

  renderNoEmailTemplates() {
    return <div className="col-12 mt-10">
      <div className="card nothing-yet">
        <div><i className="fa-regular fa-envelope fa-xl"></i></div>
        <h4>{ I18n.t("react.email_templates.no_email_template") }</h4>
        {this.renderCreationButtons()}
      </div>
    </div>;
  }

  renderList() {
    const { templates, refreshTemplatesList, borderStyle,
      listBodyHeight, toolbarHeight, searchbarHeight, selectedTemplateId } = this.props;
    const { searchValue } = this.state;
    const hasRefreshTemplatesList = refreshTemplatesList && (
      <a href="#" onClick={refreshTemplatesList} style={{ marginLeft: "15px" }}>
        <i className='fa-regular fa-arrows-rotate'></i> {I18n.t("react.email_template_picker_modal.refresh")}
      </a>
    );

    return (
      <div className="col-md-3 templates-list">
        <div className="card mb-0">
          <div style={{ borderBottom: borderStyle }}>
            <Filter translationKey="email_templates"
              items={templates}
              selectedItemIds={[selectedTemplateId]}
              showCells={false}
              hasSelectAll={false}
              multipleSelect={false}
              sortItems={false}
              selectedFirst={false}
              itemIdKey="_id"
              onChange={this.onFilterChange}
              searchValue={searchValue}
              allowUnselect={false}
              hasSearch={true}
              maxHeight={`calc(${listBodyHeight} - (${toolbarHeight} + ${searchbarHeight})`}
              actionButtons={this.renderActionButtons}
              additionalInfo={this.renderTemplateUses} />
          </div>
          <div className="mt-10 mb-10 ml-10 mr-10 text-center">
            {this.renderCreationButtons()}
            {hasRefreshTemplatesList}
          </div>
        </div>
      </div>
    );
  }

  renderPreview() {
    const { previewIframeKey } = this.state;
    const { borderStyle, listBodyHeight, iframeAutoResize, selectedTemplateId, guestId, iframeCustomClass, fromEmailTemplateList, inModal } = this.props;
    const styles = {
      padding: fromEmailTemplateList ? "auto" : "1px",
      borderLeft: fromEmailTemplateList ? "none" : borderStyle,
      height: listBodyHeight
    };

    if (!selectedTemplateId) {
      return (
        <div className="col-md-9 template-preview" style={styles}>
          <div className="card nothing-yet" style={{ height: "100%" }}>
            <h4>{I18n.t("react.email_template_picker_modal.preview")}</h4>
          </div>
        </div>
      );
    }

    return (
      <div className="col-md-9 template-preview" style={styles}>
        <TemplatePreviewIframe
          templateId={selectedTemplateId}
          templateType="email_template"
          guestId={guestId}
          autoResize={iframeAutoResize}
          scrolling={inModal}
          key={previewIframeKey}
          customClass={iframeCustomClass} />
      </div>
    );
  }

  renderEditWarningModal() {
    const { showEditWarningModal } = this.state;
    const { templates, selectedTemplateId, builderInSlidingPane, inModal } = this.props;
    const emailTemplate = templates.find(template => template._id == selectedTemplateId);

    if (!emailTemplate) return null;
    const modal = builderInSlidingPane ? false : inModal;

    return <EditTemplateWarningModal
      isOpen={showEditWarningModal}
      closeModal={this.toggleEditWarningModal}
      template={emailTemplate}
      templateType="email_template"
      builderInSlidingPane={builderInSlidingPane}
      openBuilder={this.openEmailBuilder}
      duplicate={this.openSelectEventModal}
      inModal={modal}
    />;
  }

  render() {
    const { templates, duplicateEmailTemplate, inModal, loaderContainerHeight } = this.props;

    if (!templates)
      return <Loader size="large" inline={false} containerHeight={loaderContainerHeight} />;

    return (
      <div className={`row ${inModal ? "g-0" : ""}`}>
        { templates && templates.length > 0 ? this.renderEmailTemplates() : this.renderNoEmailTemplates() }
        {this.renderEmailBuilder()}
        {this.renderEditWarningModal()}
        {duplicateEmailTemplate && this.renderCopyModals()}
        {this.renderNewEmailTemplateModal()}
      </div>
    );
  }
}

export default withEmailTemplatesContainer(EmailTemplates);
