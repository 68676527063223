import { Component } from "react";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";

import { pathToRegistrationForms } from "../../utils/pathUtils";
import { isEnabled } from "../../utils/featureSetUtils";
import { REGISTRATION_FORM } from "../../constants/FeaturesSet";

import RegistrationFormCreationReact from "../RegistrationFormCreation.react";

class RegistrationFormActionsButton extends Component {

  redirect(url) {
    window.location.replace(url);
  }

  render() {
    if (!isEnabled(REGISTRATION_FORM)) return null;

    return (<RegistrationFormCreationReact render={ (wrapper) =>
      <Dropdown as={ButtonGroup}>
        <Button variant="secondary" onClick={() => this.redirect(pathToRegistrationForms())}>
          { I18n.t("react.registration.manage_forms") }
        </Button>
        <Dropdown.Toggle variant="secondary" aria-haspopup="true"></Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item eventKey="1" onClick={wrapper.createForm}>
            { I18n.t("react.registration.create_registration_form_from") }
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    }
    />);
  }
}

export default RegistrationFormActionsButton;
