import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGuestInvitationSentStats } from "../../actions/GuestInvitationsActionCreators";
import { fetchGuestCategories } from "../../actions/GuestCategoryActionCreators";
import { fetchSavedSearches } from "../../actions/SavedSearchActionCreators";
import Loader from "../../components/shared/Loader.react";
import HelpSection from "../../components/shared/HelpSection.react";
import ReportHeader from "../../components/event_reports/ReportHeader.react";
import { isAuthorized } from "../../utils/aclUtils";
import { GuestCategory } from "../../types/GuestCategory";
import { GuestInvitationStat } from "../../types/GuestInvitationStats";
import { SegmentPicker } from "../shared/SegmentPicker.react";
import { Dropdown } from "react-bootstrap";
import { identity } from "../../utils/personUtils";
import { pathToGuestShow } from "../../utils/pathUtils";
import Select from "react-select";
import uniqBy from "lodash/uniqBy";

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`react.event_reports.guest_invitation_sent.${key}`, options);
};

const GuestInvitationSent: React.FC = () => {
  if (!isAuthorized("reports", "read")) return null;

  const eventId = useSelector((state: any) => state.event.id);
  const guests = useSelector((state: any) => state.guestInvitationStats?.data);
  const invitationCategoryIds = useSelector((state: any) => state.guestInvitationStats?.invitationCategoryIds);
  const isFetching = useSelector((state: any) => state.guestInvitationStats.isFetching);
  const categories = useSelector((state: any) => state.guestCategories?.data);
  const categoriesFetched = useSelector((state: any) => state.guestCategories?.fetched);
  const isFetchingCategories = useSelector((state: any) => state.guestCategories?.fetching);
  const savedSearches = useSelector((state: any) => state.savedSearches.data);

  const [sortField, setSortField] = useState("total_invitations");
  const [selectedSavedSearch, setSelectedSavedSearch] = useState(null);
  const [selectedGuestId, setSelectedGuestId] = useState(null);
  const [filteredGuests, setFilteredGuests] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!eventId) return;

    const sort = sortField === "company_name" ? { company_name_lower: 1 } : { total_invitations: -1 };
    dispatch(fetchGuestInvitationSentStats(eventId, { sort: sort, q: `include_takeout ${selectedSavedSearch?.search_query || ""}` }));
  }, [eventId, sortField, selectedSavedSearch]);

  useEffect(() => {
    if (!eventId) return;

    dispatch(fetchSavedSearches(eventId));

    if (isFetchingCategories || categoriesFetched) return;

    dispatch(fetchGuestCategories(eventId));
  }, [eventId]);

  useEffect(() => {
    const list = selectedGuestId ? guests?.filter((g: GuestInvitationStat) => g.guest_id === selectedGuestId) : guests;
    setFilteredGuests(list?.slice());
  }, [guests, selectedGuestId]);

  const exporterProps = (): any => {
    if (!filteredGuests || filteredGuests.length === 0) return;

    const categoriesNames = categories.filter(cat => invitationCategoryIds.includes(cat._id)).reduce((acc, cat) => {
      acc.push(cat.name);
      acc.push(cat.name + " quota");

      return acc;
    }, []);
    const columns = ["company_name", "first_name", "last_name", "email"].concat(categoriesNames).concat(["total_invitations", "invitations_quota"]);

    const data = filteredGuests.reduce((acc, guest: GuestInvitationStat) => {
      const datum = { ...guest };
      if (!guest.invitations_sent_by_guest_category) return acc;

      Object.entries(guest.invitations_sent_by_guest_category).forEach(([categoryId, val]) => {
        const categoryName = categories.find((cat: GuestCategory) => cat._id == categoryId)?.name;
        datum[categoryName] = val || 0;
        datum[categoryName + " quota"] = categoryQuota(guest.invitations_quota_mapping, categoryId) || 0;
        ["company_name", "first_name", "last_name", "email"].forEach(col => {
          datum[col] = datum[col] || "";
        });
      });
      acc.push(datum);

      return acc;
    }, []);

    return {
      data: { data: data },
      options: { excludeTotal: true },
      columns: columns
    };
  };

  const renderSortDropdown = (): JSX.Element => {
    const rows = ["company_name", "total_invitations"].map(sort => {
      return <Dropdown.Item key={sort} onClick={(): void => setSortField(sort)}>{i18n(`sort_by_${sort}`)}</Dropdown.Item>;
    });

    return <div className="float-end">
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-sort">
          {i18n(`sort_by_${sortField}`)}
        </Dropdown.Toggle>
        <Dropdown.Menu
          align="end"
          popperConfig={{
            strategy: "fixed",
            onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll"))
          }}
        >
          {rows}
        </Dropdown.Menu>
      </Dropdown>
    </div>;
  };

  const renderHeader = (): JSX.Element => {
    return <>
      <ReportHeader
        title={i18n("title")}
        exporterToExcelProps={filteredGuests?.length > 0 ? exporterProps() : null}
        exportType="guest_invitation_sent"
      />
      <HelpSection help={i18n("instant_help")} />
    </>;
  };

  const renderTableHeader = (): JSX.Element => {
    return <tr>
      <th style={{ width: "300px" }}>{i18n("company")}</th>
      { renderByCategoryHeaders()}
      <th className="text-center">{i18n("total_invitations")}</th>
    </tr>;
  };

  const renderByCategoryHeaders = (): JSX.Element[] => {
    if (!filteredGuests || filteredGuests.length === 0) return;

    return invitationCategoryIds.map((categoryId: string) => {
      return <th key={ categoryId } className="text-center">
        { categoryName(categoryId) }
      </th>;
    });
  };

  const renderByCategoryColumns = (invitationsSentByGuestCategory: any, invitationsQuotaMapping: any): JSX.Element[] => {
    if (!invitationsSentByGuestCategory) return null;

    return Object.entries(invitationsSentByGuestCategory).map(([categoryId, value]) => {
      return <td key={ categoryId } className="text-center">
        { value }{ renderQuota(categoryQuota(invitationsQuotaMapping, categoryId)) }
      </td>;
    });
  };

  const categoryName = (id: string): string => {
    const category = categories.find((cat: GuestCategory) => cat._id === id);
    return category?.name || "";
  };

  const categoryQuota = (guestInvitationsQuotaMapping: any, categoryId: string): number => {
    const guestQuota = guestInvitationsQuotaMapping && guestInvitationsQuotaMapping[categoryId];
    if (guestQuota) return guestQuota;

    return categories.find((cat: GuestCategory) => cat._id == categoryId)?.default_invitations_quota;
  };

  const renderQuota = (quota: number): JSX.Element => {
    if (!quota || quota == -1) return null;

    return <small>
      { ` / ${quota || 0}` }
    </small>;
  };

  const guestPicker = (): JSX.Element => {
    if (!guests) return;

    const options = uniqBy(
      guests?.map(guest => {
        let label = guest?.company_name || "";
        const guestIdentity = identity(guest);
        if (label != guestIdentity) label += label == "" ? guestIdentity : ` - ${guestIdentity}`;

        return { value: guest.guest_id, label };
      }), "value");

    return <Select
      className="react-select"
      classNamePrefix="react-select"
      options={options}
      value={options?.filter((guest: any) => selectedGuestId?.includes(guest.value))}
      placeholder={i18n("select_a_company")}
      noOptionsMessage={(): any => i18n("no_company")}
      onChange={(option): any => setSelectedGuestId(option?.value)}
      isClearable={true}
      menuPosition="fixed"
      styles={{
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        menuPortal: ({ left, ...provided }, state) => ({
          ...provided
        })
      }}
    />;
  };

  const renderFiltersAndSort = (): JSX.Element => {
    return <tr>
      <th style={{ fontWeight: "normal", width: "300px" }}> { guestPicker() }</th>
      { invitationCategoryIds.map((id: string) => <th key={id}></th>) }
      <th>{ renderSortDropdown() }</th>
    </tr>;
  };

  const renderTable = (): JSX.Element => {
    if (filteredGuests?.length === 0) return renderEmptyMsg();

    return <div className="row">
      <div className="col-12">
        <div className="table-responsive table-container">
          <table className="table table-light table-hover table-sm">
            <thead>
              {renderFiltersAndSort()}
              {renderTableHeader()}
            </thead>
            <tbody>
              {renderTableBody()}
            </tbody>
          </table>
        </div>
      </div>
    </div>;
  };

  const goToGuest = (guestId): () => void => {
    return (): void => location.href = pathToGuestShow(guestId);
  };

  const renderTableBody = (): JSX.Element[] => {
    return filteredGuests?.map(
      ({ guest_id, first_name, last_name, company_name, email, total_invitations, invitations_quota, invitations_quota_mapping, invitations_sent_by_guest_category }) => {
        const identity = `${first_name || ""} ${last_name || ""}`;
        const separator = email && (first_name || last_name) ? " - " : "";

        return (
          <tr key={guest_id} onClick={goToGuest(guest_id)} style={{ cursor: "pointer" }}>
            <td>
              <b>{company_name}</b>
              <div><small>{email} {separator} {identity}</small></div>
            </td>
            { renderByCategoryColumns(invitations_sent_by_guest_category, invitations_quota_mapping) }
            <td className="text-center">{total_invitations} { renderQuota(invitations_quota) }</td>
          </tr>
        );
      }
    );
  };

  const renderEmptyMsg = (): JSX.Element => {
    return <div className="card nothing-yet">
      <h4>{i18n("no_data")}</h4>
    </div>;
  };

  const renderSegmentPicker = (): JSX.Element => {
    return <div className="row">
      <div className="col-12">
        <div className="float-end mb-10">
          <SegmentPicker
            onSelectSegment={setSelectedSavedSearch}
            segments={savedSearches}
            defaultSegmentId={selectedSavedSearch?._id}
          />
        </div>
      </div>
    </div>;
  };

  const render = (): JSX.Element => {
    if (!filteredGuests) return null;

    return <>
      { renderSegmentPicker() }
      { renderTable() }
    </>;
  };

  return <>
    { renderHeader() }
    { isFetching || !categoriesFetched ? <Loader/> : render() }
  </>;
};

export default GuestInvitationSent;
