import { Component } from "react";
import { connect } from "react-redux";
import {
  fetchEmailTemplates,
  duplicateEmailTemplate,
  deleteEmailTemplate,
  createEmailTemplate,
  updateEmailSelectedId
} from "../actions/EmailBuilderActionCreators";
import { urlEventId } from "../utils/pathUtils";

const withEmailTemplatesContainer = WrappedComponent => {
  class EmailTemplatesContainer extends Component {

    componentDidMount() {
      const { fetchEmailTemplates } = this.props;
      fetchEmailTemplates(urlEventId());
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = state => {
    return {
      templates: state.emailTemplate.templates,
      duplicatedEmail: state.emailTemplate.duplicated,
      duplicationErrors: state.emailTemplate.duplicationErrors,
      duplicationWarnings: state.emailTemplate.duplicationWarnings,
      creationErrors: state.emailTemplate.errors,
      event: state.event
    };
  };

  const mapDispatchToProps = {
    fetchEmailTemplates,
    createEmailTemplate,
    duplicateEmailTemplate,
    deleteEmailTemplate,
    updateEmailSelectedId
  };

  return connect(mapStateToProps, mapDispatchToProps)(EmailTemplatesContainer);
};

export default withEmailTemplatesContainer;
