import { Component } from "react";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";

import { pathToNewGuest, pathToNewRegistration } from "../utils/pathUtils";
import truncate from "truncate";
import { isEnabled } from "../utils/featureSetUtils";
import { REGISTRATION_FORM } from "../constants/FeaturesSet";

class GuestAddDropdown extends Component {

  render() {
    const { guestCategories, acl } = this.props;
    if (!guestCategories || !acl.guest.create) return null;

    const guestCategoriesWithForm = guestCategories.filter(category => {
      return category.custom_registration_form_enabled;
    });

    const displayPublicForms = isEnabled(REGISTRATION_FORM) && guestCategoriesWithForm.length > 0;

    const menuItems = guestCategoriesWithForm.map(category => {
      return (
        <Dropdown.Item key={ category.id } href={pathToNewRegistration(category)} target="_blank">
          { truncate(category.name, 80) }
        </Dropdown.Item>
      );
    });

    return (
      <Dropdown as={ButtonGroup}>
        <Button variant="primary" href={pathToNewGuest()}>
          <i className="fa-regular fa-plus"></i> { I18n.t("guests.index.add_guest") }
        </Button>
        { displayPublicForms &&
        <>
          <Dropdown.Toggle variant="primary" aria-haspopup="true"></Dropdown.Toggle>
          <Dropdown.Menu align="end">
            { menuItems }
          </Dropdown.Menu>
        </>
        }
      </Dropdown>
    );
  }
}

export default GuestAddDropdown;
