import { useState } from "react";
import { useDispatch } from "react-redux";
import SlidingPane from "react-sliding-pane";
import Icons from "../../constants/Icons";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import NoticeBlock from "../../components/templates_builder/NoticeBlock.react";
import { urlEventId } from "../../utils/pathUtils";
import { GuestProductCollection } from "../../types/GuestProductCollection";
import { GuestProductCollectionField } from "../../types/GuestProductCollectionField";
import { GuestProductCollectionFieldPossibleValues } from "../../types/GuestProductCollectionFieldPossibleValues";
import { updateGuestProductCollection } from "../../actions/GuestProductCollectionActionCreators";
import GuestProductCollectionFormSidebar from "./GuestProductCollectionFormSidebar.react";
import GuestProductCollectionFieldsForm from "./fields_form/GuestProductCollectionFieldsForm.react";

interface Props {
  isSlidingPaneOpen: boolean;
  guestProductCollection: GuestProductCollection;
  guestProductCollectionName: string;
  translationsEnabled: boolean;
  errors?: string[];
  notice?: string;

  toggleSlidingPane(): void;
  toggleSlidingPaneTranslations(): void;
  onFieldsChange(newGuestProductCollectionFields: GuestProductCollectionField[]): void;
  onCollectionNameChange(value: string): void;
}

const GuestProductCollectionSlidePane: React.FC<Props> = ({
  isSlidingPaneOpen,
  guestProductCollection,
  guestProductCollectionName,
  translationsEnabled,
  errors,
  notice,
  toggleSlidingPane,
  toggleSlidingPaneTranslations,
  onFieldsChange,
  onCollectionNameChange,
}) => {
  const [isInputCollectionNameDisplay, setIsInputCollectionNameDisplay] = useState<boolean>(false);
  const [focusedFieldIndex, setFocusedFieldIndex] = useState<number>(0);
  const focusableFieldsIndices = guestProductCollection?.collection_fields?.reduce((acc, field, i) => (field.hidden ? acc : [...acc, i]), []) || [];

  if (focusableFieldsIndices.length && !focusableFieldsIndices.includes(focusedFieldIndex)) {
    setFocusedFieldIndex(focusableFieldsIndices[0]);
  }

  const dispatch = useDispatch();

  const onSave = (): void => {
    dispatch(
      updateGuestProductCollection(
        urlEventId(),
        guestProductCollection,
        { guest_product_collection: {
          name: guestProductCollectionName,
          collection_fields_attributes: collectionFieldsParams(guestProductCollection.collection_fields) }
        }
      )
    );
  };

  const collectionFieldsParams = (fields: GuestProductCollectionField[]): GuestProductCollectionField[] => {
    return fields.map(field => {
      if (field.possible_values) {
        const possible_values = cleanPossibleValues(field.possible_values);
        return {
          ...field,
          possible_values_attributes: possible_values
        };
      } else {
        return field;
      }
    });
  };

  const cleanPossibleValues = (possibleValues: GuestProductCollectionFieldPossibleValues[]): GuestProductCollectionFieldPossibleValues[] => {
    return possibleValues.map(value => {
      if (valueIsNotPersisted(value)) { delete value._id; }
      return value;
    });
  };

  const valueIsNotPersisted = (value: GuestProductCollectionFieldPossibleValues): boolean => {
    return value.isTemporary;
  };

  const onFieldFocus = (index: number): void => {
    setFocusedFieldIndex(index);

    setTimeout(() => {
      document.getElementById(`guest_product_collection_field_${index}`)?.scrollIntoView({ block: "center", behavior: "smooth" });
    }, 100);
  };

  const toggleInputCollectionName = (): void => {
    if (guestProductCollection.isDefault) return;

    setIsInputCollectionNameDisplay(!isInputCollectionNameDisplay);
  };

  const renderInputCollectionName = (): JSX.Element => {
    if (!isInputCollectionNameDisplay) return null;

    return <div className="row">
      <div className="col-auto">
        <input
          type="text"
          className="form-control"
          onChange={(e): void => onCollectionNameChange(e.target.value)}
          value={guestProductCollectionName}
          onBlur={toggleInputCollectionName}
          autoFocus={true}
          style={{ height: "28px", padding: "5px" }}
        />
      </div>
    </div>;
  };

  const renderCollectionName = (): JSX.Element => {
    if (!guestProductCollection) return null;
    if (guestProductCollection.isDefault) {
      return <div>
        {guestProductCollectionName} <span className="form-text">({ I18n.t("events.edit_advanced.guest_product_collections.default_collection") })</span>
      </div>;
    }

    return <>
      { !isInputCollectionNameDisplay &&
        <div onClick={toggleInputCollectionName} style={{ cursor: "pointer" }}>
          {guestProductCollectionName}<small> <i className="fa-regular fa-pencil fa-fw text-primary"></i></small>
        </div>}
      { renderInputCollectionName() }
    </>;
  };

  const renderAlerts = (): JSX.Element => {
    return <>
      <ErrorMessage errors={errors} noLabelKeys={["collection_fields"]}/>
      <NoticeBlock notice={notice} noticeType="success" />
    </>;
  };

  const renderSlidingPaneContent = (): JSX.Element => {
    return <div className="fields-form-builder">
      <div className="container-fluid" style={{ height: "100%" }}>
        <div className="row" style={{ height: "100%" }}>
          <GuestProductCollectionFormSidebar
            defaultCollection={guestProductCollection?.isDefault}
            guestProductCollectionFields={guestProductCollection?.collection_fields}
            onFieldsChange={onFieldsChange}
            onFieldFocus={onFieldFocus}
            onSave={onSave}
            toggleSlidingPaneTranslations={toggleSlidingPaneTranslations}
            translationsEnabled={translationsEnabled}
          />
          <div className="col-9 full-height-form">
            { renderAlerts() }
            <GuestProductCollectionFieldsForm
              defaultCollection={guestProductCollection?.isDefault}
              guestProductCollectionFields={guestProductCollection?.collection_fields}
              focusedFieldIndex={focusedFieldIndex}
              onFieldsChange={onFieldsChange}
              onFieldFocus={setFocusedFieldIndex}
            />
          </div>
        </div>
      </div>
    </div>;
  };

  return <SlidingPane
    isOpen={isSlidingPaneOpen}
    width="90%"
    title={renderCollectionName()}
    onRequestClose={toggleSlidingPane}
    className="width-100-xs width-100-sm width-transition"
    closeIcon={Icons.close()}
  >
    {renderSlidingPaneContent()}
  </SlidingPane>;
};

export default GuestProductCollectionSlidePane;
