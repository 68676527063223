import withProgramFilter from "../withProgramFilter.react";
import InputLabelWithVisibilityButton from "../InputLabelWithVisibilityButton.react";
import { FilterProps } from "../../../types/Program";

const SortByRecommendationFilter: React.FC<FilterProps> = ({ programFilter, onChangeLabelOptions }) => {
  return <InputLabelWithVisibilityButton
    label={I18n.t("react.programs.label")}
    value={programFilter.label}
    placeholder={I18n.t("react.programs.sort_by_recommendation_filter.title")}
    name="sort_by_recommendation_filter_label"
    isDisplayed={programFilter.label_displayed}
    onChangeHandler={onChangeLabelOptions}
  />;
};

export default withProgramFilter(SortByRecommendationFilter, "ui");
