import SlidingPane from "react-sliding-pane";
import Icons from "../../constants/Icons";
import FeatureSetFormContainer from "../../containers/features/FeatureSetFormContainer.react";

interface Props {
  isOpen: boolean;
  hide: () => void;
}

const FeatureSetFormContainerPane: React.FC<Props> = ({ isOpen, hide }) => {
  return (
    <SlidingPane
      isOpen={isOpen}
      width="90%"
      onRequestClose={hide}
      closeIcon={Icons.close()}>
      <FeatureSetFormContainer />
    </SlidingPane>
  );
};

export default FeatureSetFormContainerPane;
