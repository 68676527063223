import { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import { Modal, Button } from "react-bootstrap";
import Checkbox from "../shared/Checkbox.react";
import { BadgeTemplate, BadgeTemplateApiAttributes } from "../../types/BadgeTemplate";

interface Props {
  isOpen: boolean;
  badgeTemplate: BadgeTemplate;
  isEditMode: boolean;
  isSaving: boolean;
  badgeFormat: string;

  toggleModal(): void;
  onSubmitModal(params: BadgeTemplateApiAttributes): void
}

function i18n(key, opts = {}): string {
  return I18n.t(`react.badge_builder.badge_template_settings_modal.${key}`, opts);
}

const BadgeTemplateSettingsModal: React.FC<Props> = ({
  isOpen,
  badgeTemplate,
  isEditMode,
  isSaving,
  badgeFormat,
  toggleModal,
  onSubmitModal
}) => {
  if (!badgeTemplate && isEditMode) return null;

  const [name, setName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [qrCodeEnabled, setQrCodeEnabled] = useState(false);
  const [ecardInQrCodeEnabled, setEcardInQrCodeEnabled] = useState(false);

  useEffect(() => {
    if (!badgeTemplate) return;

    setName(badgeTemplate["name"]);
    setDisplayName(badgeTemplate["badge_display_name"]);
    setQrCodeEnabled(badgeTemplate["badge_qr_code_enabled"]);
    setEcardInQrCodeEnabled(badgeTemplate["badge_ecard_in_qr_code_enabled"]);
  }, [badgeTemplate]);

  const onSubmit = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    if (!name || !displayName) return null;

    onSubmitModal({
      name,
      badge_display_name: displayName,
      badge_qr_code_enabled: qrCodeEnabled,
      badge_ecard_in_qr_code_enabled: ecardInQrCodeEnabled,
      badge_format: badgeFormat
    });
  };

  const renderInput = (key: string, required: boolean, onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void, placeholder?: string, defaultValue?: any, type = "text"): JSX.Element => {
    return <div className="mb-3 row">
      <label htmlFor={key} className={`col-sm-4 col-form-label text-end ${required && "required"}`}>{ i18n(key) }</label>
      <div className="col-sm-8 d-flex align-items-center">
        <input
          type={type}
          name={key}
          className="form-control"
          placeholder={placeholder || i18n(key)}
          defaultValue={badgeTemplate ? badgeTemplate[key] : defaultValue}
          required={required}
          onChange={onChangeHandler} />
      </div>
    </div>;
  };

  const renderCheckbox = (key: string, inputText: string, value: boolean, onChangeHandler: () => void): JSX.Element => {
    return <div className="mb-3 row">
      <label htmlFor={key} className="col-sm-4 col-form-label text-end">{ i18n(key) }</label>
      <div className="col-sm-8 d-flex align-items-center">
        <Checkbox
          name={key}
          text={inputText}
          checked={value}
          onChange={onChangeHandler}
        />
      </div>
    </div>;
  };

  return <Modal show={isOpen} onHide={toggleModal} size="lg">
    <Modal.Header>
      <Modal.Title>{ i18n("modal_title") }</Modal.Title>
      <button type="button" onClick={toggleModal} className="btn-close" aria-label={I18n.t("close")}></button>
    </Modal.Header>

    <form>
      <Modal.Body>
        {renderInput("name", true, (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value))}
        {renderInput("badge_display_name", true, (e: ChangeEvent<HTMLInputElement>) => setDisplayName(e.target.value), i18n("badge_category_placeholder"))}
        {renderCheckbox("badge_qr_code_enabled", i18n("badges_with_qr_codes"), qrCodeEnabled, () => setQrCodeEnabled(!qrCodeEnabled))}
        {renderCheckbox("badge_ecard_in_qr_code_enabled", i18n("badges_with_business_card"), ecardInQrCodeEnabled, () => setEcardInQrCodeEnabled(!ecardInQrCodeEnabled))}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={toggleModal}>
          { I18n.t("cancel") }
        </Button>
        <Button variant={`primary ${(!name || !displayName || isSaving) && "disabled"}`} type="submit" onClick={onSubmit}>
          { i18n("submit") }
        </Button>
      </Modal.Footer>
    </form>
  </Modal>;
};

export default BadgeTemplateSettingsModal;
