import { Component } from "react";
import { connect } from "react-redux";
import { fetchRegistrationForms } from "../../actions/RegistrationFormsActionCreators";
import { fetchAccesspoints } from "../../actions/AccesspointActionCreators";
import { fetchWebsitePages } from "../../actions/WebsitePagesActionCreators";
import { fetchWebsiteMenus, fetchWebsite, fetchWebsiteLayouts } from "../../actions/WebsiteActionCreators";
import { fetchCustomEmails } from "../../actions/CustomEmailsActionCreators";
import { fetchGuestCampaigns } from "../../actions/GuestCampaignsActionCreators";
import { fetchEmailTemplates } from "../../actions/EmailBuilderActionCreators";
import { fetchGuestCategories } from "../../actions/GuestCategoryActionCreators";
import { fetchThematics } from "../../actions/ThematicsActionCreators";
import { fetchBlogArticles } from "../../actions/BlogArticlesActionCreators";
import { fetchSessionRooms } from "../../actions/SessionRoomsActionCreator";
import { fetchSessionTypes } from "../../actions/SessionTypesActionCreators";
import { fetchEventGuestFields } from "../../actions/GuestFieldsActionCreators";
import { fetchPrograms } from "../../actions/ProgramsActionCreators";
import { fetchMeetingLocations } from "../../actions/MeetingLocationsActionCreators";
import { fetchMobinetworkConfiguration } from "../../actions/MobinetworkConfigurationActionCreators";
import { fetchDocumentTemplates } from "../../actions/DocumentTemplatesActionCreators";
import { fetchGuestProductCollections } from "../../actions/GuestProductCollectionsActionCreators";
import { requestEvent } from "../../actions/ImportActionCreators";
import { updateEvent } from "../../actions/EventActionCreators";
import Loader from "../../components/shared/Loader.react";
import TranslationsNavigation from "../../components/translations/TranslationsNavigation.react";
import TranslationsHeader from "../../components/translations/TranslationsHeader.react";
import TranslationTable from "./TranslationTable.react";
import TranslationsIndex from "../../components/translations/TranslationsIndex.react";
import WebsiteTranslationsIndex from "../../components/translations/WebsiteTranslationsIndex.react";
import GuestCategoriesAndTicketsLinked from "../../components/shared/GuestCategoriesAndTicketsLinked.react";
import { TranslationsParentTypes, StaticGuestFieldToExport } from "../../constants/Constants.js";
import { isEnabled, anyEnabled } from "../../utils/featureSetUtils";
import { MULTILINGUAL } from "../../constants/FeaturesSet";
import "./styles";

class Translations extends Component {
  constructor(props) {
    super(props);
    [
      "fetchEmailTemplates",
      "fetchRegistrationFormsObjects",
      "guestCategoriesAndTicketsLinkedToForm",
      "guestCategoriesAndTicketsLinkedToDocument"
    ].forEach(item => this[item] = this[item].bind(this));
  }

  renderTranslationTable() {
    const { match } = this.props;
    const { event_id, id } = match.params;
    return <TranslationTable
      match={match}
      location={location}
      history={history}
      type={this.currentType()}
      eventId={event_id}
      id={id}
      inSlidingPane={false}
    />;
  }

  renderTranslations() {
    const { event, requestEvent, pages, registrationForms, guestCategories, match, accesspoints, customEmails, fetchGuestCampaigns,
      fetchAccesspoints, guestFields, fetchEventGuestFields, emailTemplates, fetchCustomEmails, leadsQualification, fetchMobinetworkConfiguration,
      fetchGuestCategories, fetchWebsitePages, fetchWebsite, fetchWebsiteMenus, menus, guestCampaigns,
      fetchThematics, thematics, fetchWebsiteLayouts, layouts, website, blogArticles, fetchBlogArticles,
      fetchSessionRooms, sessionRooms, fetchPrograms, programs, fetchMeetingLocations, meetingLocations, fetchSessionTypes, sessionTypes,
      documentTemplates, fetchGuestProductCollections, guestProductCollections } = this.props;

    const { event_id, id } = match.params;
    if (id) return this.renderTranslationTable();

    switch (this.currentType()) {
    case "registration_forms":
      return <TranslationsIndex
        fetchObjects={this.fetchRegistrationFormsObjects}
        objects={registrationForms}
        columns={["title", ["categories", this.guestCategoriesAndTicketsLinkedToForm], "created_at"]}
        type={this.currentType()}
      />;
    case "accesspoints":
      return <TranslationsIndex
        fetchObjects={() => fetchAccesspoints(event_id)}
        objects={accesspoints}
        columns={["name", "created_at"]}
        type={this.currentType()}
      />;
    case "guest_fields": {
      const filteredGuestFields = guestFields.filter(guestField => !StaticGuestFieldToExport.includes(guestField.key));
      return <TranslationsIndex
        fetchObjects={() => fetchEventGuestFields(event_id)}
        objects={filteredGuestFields}
        columns={["label", "key"]}
        type={this.currentType()}
      />;
    }
    case "guest_categories":
      return <TranslationsIndex
        fetchObjects={() => fetchGuestCategories(event_id)}
        objects={guestCategories}
        columns={["name", "created_at"]}
        type={this.currentType()}
      />;
    case "document_templates":
      return <TranslationsIndex
        fetchObjects={() => this.fetchDocumentTemplatesObjects() }
        objects={documentTemplates}
        columns={["name", ["categories", this.guestCategoriesAndTicketsLinkedToDocument], "created_at"]}
        type={this.currentType()}
      />;
    case "email_templates":
      return <TranslationsIndex
        fetchObjects={this.fetchEmailTemplates}
        objects={emailTemplates}
        columns={["name", "created_at"]}
        type={this.currentType()}
      />;
    case "website_pages":
      return <TranslationsIndex
        fetchObjects={() => fetchWebsitePages(event_id)}
        objects={pages}
        columns={["name", "created_at"]}
        type={this.currentType()}
      />;
    case "website_menus":
      return <TranslationsIndex
        fetchObjects={() => fetchWebsiteMenus(event_id)}
        objects={menus}
        columns={["name", "created_at"]}
        type={this.currentType()}
      />;
    case "website_layouts":
      return <TranslationsIndex
        fetchObjects={() => fetchWebsiteLayouts(event_id)}
        objects={layouts}
        columns={["name"]}
        type={this.currentType()}
      />;
    case "custom_emails":
      return <TranslationsIndex
        fetchObjects={() => fetchCustomEmails(event_id, { types: "confirmation,moderation" })}
        objects={customEmails}
        columns={["name", "created_at"]}
        type={this.currentType()}
      />;
    case "guest_campaigns":
      return <TranslationsIndex
        fetchObjects={() => fetchGuestCampaigns(event_id, 1, 1000)} // 1000 per page because this generic component do not handle pagination
        objects={guestCampaigns}
        columns={["name", "created_at"]}
        type={this.currentType()}
      />;
    case "thematics":
      return <TranslationsIndex
        fetchObjects={() => fetchThematics(event_id)}
        objects={thematics}
        columns={["name", "created_at"]}
        type={this.currentType()}
      />;
    case "website":
      return <WebsiteTranslationsIndex
        fetchWebsite={() => fetchWebsite(event_id)}
        website={website}
        type={this.currentType()}
      />;
    case "event":
      return <TranslationsIndex
        fetchObjects={requestEvent}
        objects={[event]}
        columns={["title"]}
        type={this.currentType()}
      />;
    case "blog_articles":
      return <TranslationsIndex
        fetchObjects={() => fetchBlogArticles(event_id, null, 1, 1000)}
        objects={blogArticles}
        columns={["title"]}
        type={this.currentType()}
      />;
    case "session_rooms":
      return <TranslationsIndex
        fetchObjects={() => fetchSessionRooms(event_id)}
        objects={sessionRooms}
        columns={["name"]}
        type={this.currentType()}
      />;
    case "programs":
      return <TranslationsIndex
        fetchObjects={() => fetchPrograms(event_id)}
        objects={programs}
        columns={["name"]}
        type={this.currentType()}
      />;
    case "meeting_locations":
      return <TranslationsIndex
        fetchObjects={() => fetchMeetingLocations(event_id)}
        objects={meetingLocations}
        columns={["name"]}
        type={this.currentType()}
      />;
    case "session_types":
      return <TranslationsIndex
        fetchObjects={() => fetchSessionTypes(event_id)}
        objects={sessionTypes}
        columns={["name"]}
        type={this.currentType()}
      />;
    case "leads_qualification":
      return <TranslationsIndex
        fetchObjects={() => fetchMobinetworkConfiguration(event_id)}
        objects={leadsQualification}
        columns={["label", "created_at"]}
        type={this.currentType()}
      />;
    case "guest_product_collections":
      return <TranslationsIndex
        fetchObjects={() => fetchGuestProductCollections(event_id)}
        objects={guestProductCollections}
        columns={["name"]}
        type={this.currentType()}
      />;
    default:
      return null;
    }
  }

  guestCategoriesAndTicketsLinkedToForm(form) {
    const { guestCategories } = this.props;
    return <GuestCategoriesAndTicketsLinked
      form={form}
      guestCategories={guestCategories}
    />;
  }

  guestCategoriesAndTicketsLinkedToDocument(document) {
    const { guestCategories } = this.props;
    return <GuestCategoriesAndTicketsLinked
      document={document}
      guestCategories={guestCategories}
    />;
  }

  fetchRegistrationFormsObjects() {
    const { fetchRegistrationForms, fetchGuestCategories, match } = this.props;
    const { event_id } = match.params;
    fetchRegistrationForms(event_id, 1, 100);
    fetchGuestCategories(event_id);
  }

  fetchDocumentTemplatesObjects() {
    const { fetchDocumentTemplates, fetchGuestCategories, match } = this.props;
    const { event_id } = match.params;
    fetchDocumentTemplates(event_id);
    fetchGuestCategories(event_id);
  }

  fetchEmailTemplates() {
    const { fetchEmailTemplates, match } = this.props;
    return fetchEmailTemplates(match.params.event_id);
  }

  availableTypes() {
    return Object.keys(TranslationsParentTypes).filter(key => anyEnabled(TranslationsParentTypes[key].requiredFeatures));
  }

  currentType() {
    const { type } = this.props.match.params;

    if (type && this.availableTypes().includes(type)) return type;

    return null;
  }

  renderTranslationsPanel() {
    const { event, history } = this.props;

    if (event.available_frontend_locales.length <= 0) {
      return <div className="row">
        <div className="col-md-12">
          <div className="card nothing-yet mt-10">
            <div>
              <i className="fa-regular fa-language fa-xl"></i>
            </div>
            <div>
              <h4>{I18n.t("react.translations.no_translations")}</h4>
              <p className="sub-title">{I18n.t("react.translations.add_translations")}</p>
            </div>
          </div>
        </div>
      </div>;
    }

    return <div className="row">
      <div className="col-md-3 col-12"><TranslationsNavigation currentType={this.currentType()} history={history} /></div>
      <div className="col-md-9 col-12">{ this.renderTranslations() }</div>
    </div>;
  }

  render() {
    if (!isEnabled(MULTILINGUAL)) return null;

    const { event, updateEvent } = this.props;

    if (!event._id) {
      return <Loader />;
    }

    return <div>
      <TranslationsHeader match={this.props.match} event={event} updateEvent={updateEvent} />
      { this.renderTranslationsPanel() }
    </div>;
  }
}

function mapStateToProps(state) {
  const { registrationForms, accesspoints, guestFields, emailTemplate, guestCategories, mobinetworkConfiguration,
    websiteLayouts, websitePages, websiteMenus, event, customEmails, guestCampaigns } = state;
  return {
    registrationForms: registrationForms.data,
    accesspoints: accesspoints.data,
    guestFields: guestFields.guestFields,
    emailTemplates: emailTemplate.templates,
    errors: emailTemplate.errors,
    guestCategories: guestCategories.data,
    layouts: websiteLayouts.data,
    pages: websitePages.items,
    menus: websiteMenus.data,
    event: event,
    customEmails: customEmails.data,
    guestCampaigns: guestCampaigns.data,
    leadsQualification: mobinetworkConfiguration.data && mobinetworkConfiguration.data.lead_qualification_fields,
    thematics: state.thematics.items,
    website: state.website.data,
    blogArticles: state.blogArticles.data,
    sessionRooms: state.sessionRooms.data,
    sessionTypes: state.sessionTypes.items,
    programs: state.programs.data,
    meetingLocations: state.meetingLocations.data,
    documentTemplates: state.documentTemplates.data,
    guestProductCollections: state.guestProductCollections.data,
  };
}

const mapDispatchToProps = {
  fetchAccesspoints,
  fetchBlogArticles,
  fetchCustomEmails,
  fetchEmailTemplates,
  fetchGuestCampaigns,
  fetchGuestCategories,
  fetchPrograms,
  fetchRegistrationForms,
  fetchSessionRooms,
  fetchSessionTypes,
  fetchThematics,
  fetchWebsite,
  fetchWebsiteLayouts,
  fetchWebsiteMenus,
  fetchWebsitePages,
  fetchMobinetworkConfiguration,
  fetchMeetingLocations,
  fetchDocumentTemplates,
  fetchGuestProductCollections,
  requestEvent,
  fetchEventGuestFields,
  updateEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(Translations);
