import { CALL_API, ActionTypes } from "../constants/Constants";

export function fetchBlogArticles(eventId: string, search = null, page = 1, perPage = 20): any {
  const { FETCH_BLOG_ARTICLES_PENDING, FETCH_BLOG_ARTICLES_SUCCESS, DEV_NULL } = ActionTypes;

  return {
    [CALL_API]: {
      types: [FETCH_BLOG_ARTICLES_PENDING, FETCH_BLOG_ARTICLES_SUCCESS, DEV_NULL],
      method: "GET",
      body: { page: page, per_page: perPage, search },
      endpoint: `/api/v1/events/${eventId}/website/blog_articles.json`
    }
  };
}

export function fetchBlogArticlesTotalCount(eventId: string, search = null): any {
  const { FETCH_BLOG_ARTICLES_COUNT_SUCCESS, DEV_NULL } = ActionTypes;

  return {
    [CALL_API]: {
      types: [DEV_NULL, FETCH_BLOG_ARTICLES_COUNT_SUCCESS, DEV_NULL],
      method: "GET",
      body: { search },
      endpoint: `/api/v1/events/${eventId}/website/blog_articles/count.json`
    }
  };
}
