export function itemsByStep(formItems, sections, step, removeStep = false) {
  let allItems = [];
  const section = sections.find(section => { return section._id === step.form_sections[0]; }); //assume one section per step
  const items = itemsForSection(formItems, section);
  const extraOptions = {
    nonSortable: true,
    type: "step",
    itemsCount: items.length
  };
  if (!removeStep) {
    allItems.push(Object.assign({}, step, extraOptions));
  }
  allItems = allItems.concat(items);
  return allItems;
}

export function itemsForSection(formItems, section) {
  return formItems.filter(item => { return item.form_section_id === section._id; });
}

export function stepBeingEdited(steps) {
  return steps.find(step => { return step.focused; });
}

export function itemBeingEdited(items) {
  return items.find(item => { return item.mode === "edit"; });
}
