import { Component } from "react";
import { connect } from "react-redux";
import { GuestCategoryName, LiquidTemplateName, DocumentTemplateName } from "../../utils/LiquidFieldUtils";
import EditableFieldsLayout from "../../components/shared/EditableFieldsLayout.react";
import Loader from "../../components/shared/Loader.react";
import { fetchGuestCategory } from "../../actions/GuestCategoryActionCreators";
import { fetchDocumentTemplate } from "../../actions/DocumentTemplateActionCreators";
import { fetchEventGuestFields } from "../../actions/GuestFieldsActionCreators";
import { offlineUpdate } from "../../actions/RichTextEditorActionCreator";
import { fetchLiquidTemplateInAccount } from "../../actions/AccountActionCreators";


class EditableFieldsContainer extends Component {

  constructor(props) {
    super(props);
    const functions = [
      "updateEditableField",
      "renderEditableFieldsLayout",
      "selectCurrentModel",
      "selectCurrentModelName",
      "selectTagName",
      "deleteFileEditableField"
    ];
    functions.forEach((item) => { this[item] = this[item].bind(this); });
  }

  componentDidMount() {
    const { fetchGuestCategory, fetchEventGuestFields, fetchDocumentTemplate, fetchLiquidTemplateInAccount, match, options } = this.props;
    if (match.params.document_template_id) {
      fetchDocumentTemplate(match.params.event_id, match.params.document_template_id, match.params.guest_category_id);
      fetchEventGuestFields(match.params.event_id);
    } else if (match.params.account_id) {
      fetchLiquidTemplateInAccount(match.params.account_id, match.params.template_id);
    } else {
      let liquid_field_type = this.props.location.search.substring(6);
      if (!options.tabs.includes(liquid_field_type)) {
        liquid_field_type = `${options.type}_auto`; // server will determine what must be displayed depending on what is enabled
      }
      fetchEventGuestFields(match.params.event_id);
      fetchGuestCategory(match.params.event_id, match.params.guest_category_id, { liquid_field_type: liquid_field_type });
    }
  }

  selectCurrentModel() {
    const { match, documentTemplate, liquidTemplate, guestCategory } = this.props;
    if (match.params.document_template_id) {
      return documentTemplate;
    } else if (match.params.account_id) {
      return liquidTemplate;
    } else {
      return guestCategory;
    }
  }

  selectCurrentModelName() {
    const { match } = this.props;
    if (match.params.document_template_id) {
      return DocumentTemplateName;
    } else if (match.params.account_id) {
      return LiquidTemplateName;
    } else {
      return GuestCategoryName;
    }
  }

  selectTagName() {
    const { match } = this.props;
    if (match.params.document_template_id) { // document form
      return "document_template[editable_elements_attributes]";
    } else if (match.params.account_id) { // account templates form
      return "liquid_template[editable_elements_attributes]";
    } else {
      if (match.url.includes("email")) { // email form
        return "guest_category[custom_email][editable_elements_attributes]";
      } else { // custom from
        return "custom_form[liquid_field][editable_elements_attributes]";
      }
    }
  }

  renderEditableFieldsLayout() {
    const { guestFields } = this.props;
    const model = this.selectCurrentModel();

    return <EditableFieldsLayout
      guestFields={guestFields}
      errors={model.errors}
      model={model.data}
      updateEditableField={this.updateEditableField}
      deleteFileEditableField={this.deleteFileEditableField}
      changeImageEditableField={() => {}}
      name={this.selectTagName()}
    />;
  }


  updateEditableField(params) {
    const { offlineUpdate } = this.props;
    const model = this.selectCurrentModel();
    const editable_elements = model.data.editable_elements.map(el => {
      return el._id === params._id ? Object.assign({}, el, params) : el;
    });
    offlineUpdate(model.data, this.selectCurrentModelName(), { editable_elements });
  }

  deleteFileEditableField(params, e) {
    e.preventDefault();
    const { offlineUpdate } = this.props;
    const model = this.selectCurrentModel();
    const editable_elements = model.data.editable_elements.map(el => {
      return el._id === params.editable_elements_attributes._id ? Object.assign({}, el, { file: { url: "" } }) : el;
    });
    offlineUpdate(model.data, this.selectCurrentModelName(), { editable_elements });
  }

  render() {
    const model = this.selectCurrentModel();
    if (!model.data || Object.keys(model.data).length === 0) {
      return <Loader />;
    }

    return (
      <div>
        <div className="col-12">
          {this.renderEditableFieldsLayout()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    guestFields: state.guestFields.guestFields,
    guestCategory: state.guestCategory,
    documentTemplate: state.documentTemplate,
    liquidTemplate: state.liquidTemplate,
    account: state.account
  };
}

const mapDispatchToProps = {
  fetchGuestCategory,
  fetchEventGuestFields,
  fetchDocumentTemplate,
  fetchLiquidTemplateInAccount,
  offlineUpdate
};

export default connect(mapStateToProps, mapDispatchToProps)(EditableFieldsContainer);
export { EditableFieldsContainer };
