import { verticalDragIcon } from "../../constants/Icons";

export interface Props {
  title: string;
  rank?: number;
  draggable?: boolean;
  children: JSX.Element | JSX.Element[];

  onDelete(): void;
}

const DeletablePanel: React.FC<Props> = ({ title, draggable, children, onDelete }) => {
  return <div className="card">
    <div className="card-header">
      <div className="card-title">
        { draggable &&
          <span style={{ "verticalAlign": "text-bottom", "lineHeight": 1, "marginRight": "5px" }}>
            { verticalDragIcon() }
          </span>
        }
        {title}
        <span className="float-end" onClick={onDelete} style={{ cursor: "pointer" }}>
          <i className="fa-regular fa-trash-can"></i>
        </span>
      </div>
    </div>
    <div className="card-body">
      {children}
    </div>
  </div>;
};

export default DeletablePanel;
