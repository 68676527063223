import { Component } from "react";
import PropTypes from "prop-types";

class ShowAllLink extends Component {

  constructor(props) {
    super(props);
    [ "render", "toggle"].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = { showingAll: false };
  }

  toggle(e) {
    const { onClickHandler } = this.props;
    e.stopPropagation();
    e.preventDefault();
    const showingAll = !this.state.showingAll;
    this.setState({
      showingAll
    });

    onClickHandler(showingAll, e);
  }

  render() {
    const { nbItems, limit } = this.props;
    if (nbItems > limit) {
      const content = this.state.showingAll ? (
        <span>{ I18n.t("react.registration_form.form_item_with_options.show_less") } <i className="fa-regular fa-angle-up"></i></span>
      ) : (
        <span>{ I18n.t("react.registration_form.form_item_with_options.show_all") } <i className="fa-regular fa-angle-down"></i></span>
      );
      return (<a style={{ marginLeft: "15px", color: "#999", fontSize: "small" }} href="#" onClick={this.toggle}>{ content }</a>);
    }
    return null;
  }
}

ShowAllLink.propTypes = {
  nbItems: PropTypes.number.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired
};

export default ShowAllLink;
