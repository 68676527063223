import { ActionTypes } from "../constants/Constants";

function accesspointsStats(state = { data: { greenAccessControls: null, redAccessControls: null } }, action) {
  let data;
  switch (action.type) {
  case ActionTypes.ACCESSPOINTS_GREEN_STATISTICS_SUCCESS:
    data = Object.assign({}, state.data, { greenAccessControls: action.data });
    return Object.assign({}, state, { data });
  case ActionTypes.ACCESSPOINTS_RED_STATISTICS_SUCCESS:
    data = Object.assign({}, state.data, { redAccessControls: action.data });
    return Object.assign({}, state, { data });
  default:
    return state;
  }
}

export default accesspointsStats;
