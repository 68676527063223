import { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { withRouter } from "react-router-dom";
import { urlEventId } from "../utils/pathUtils";
import { splitStandardFromCustomGuestFieldsForExport } from "../utils/guestFieldsUtils";
import { defaultModalStyles } from "../constants/Style";

import ColumnsFilter from "../components/ColumnsFilter.react";
import ErrorMessage from "../components/shared/ErrorMessage.react";
import Loader from "../components/shared/Loader.react";

import { fetchExportableGuestFields } from "../actions/ExportsActionCreators";
import { fetchColumnsSets, createColumnsSet, updateColumnsSet } from "../actions/ColumnsSetsActionCreators";
import { fetchAccesspoints } from "../actions/AccesspointActionCreators";
import { fetchUserSettings } from "../actions/UserSettingsActionCreators";

const mobinetworkColumnsSetName = "Leads";
const visitRouteColumnsSetName = "VisitRoute";
const informationRequestColumnsSetName = "InformationRequest";

class ConfigureExportedColumns extends Component {
  constructor(props) {
    super(props);
    [
      "saveColumnsSet",
      "toggleModal"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      modalOpened: false
    };
  }

  i18n(key) {
    const { i18nPath } = this.props;

    return I18n.t(`${i18nPath}.${key}`);
  }

  columnsSetName() {
    const { exportFor } = this.props;

    if (exportFor === "visit_route") return visitRouteColumnsSetName;
    if (exportFor === "product_information_request") return informationRequestColumnsSetName;

    return mobinetworkColumnsSetName;
  }

  columnsSetExportTypeField() {
    const { exportFor } = this.props;

    if (exportFor === "visit_route") return "for_visit_route";
    if (exportFor === "product_information_request") return "for_product_information_request";

    return "for_mobinetwork_configuration";
  }

  componentDidMount() {
    const { fetchExportableGuestFields, fetchAccesspoints, fetchColumnsSets, fetchUserSettings } = this.props;

    fetchColumnsSets(urlEventId());
    fetchExportableGuestFields(urlEventId());
    fetchAccesspoints(urlEventId());
    fetchUserSettings(urlEventId());
  }

  componentDidUpdate(prevProps) {
    const { columnsSets: prevColumnsSets } = prevProps;
    const { columnsSets } = this.props;

    if (prevColumnsSets.pendingWriteRequest && !columnsSets.pendingWriteRequest && this.state.modalOpened === true) {
      this.toggleModal();
    }
  }

  saveColumnsSet(columns) {
    const { createColumnsSet, updateColumnsSet, exportFor } = this.props;
    const columnsSet = this.columnsSet();

    if (columnsSet) {
      updateColumnsSet(urlEventId(), columnsSet._id, { columns: columns });
    } else {
      createColumnsSet(urlEventId(), { name: this.columnsSetName(), columns, attach_to: exportFor });
    }
  }

  toggleModal(e) {
    if (e) e.preventDefault();

    this.setState({
      modalOpened: !this.state.modalOpened
    });
  }

  columnsSet() {
    const { columnsSets } = this.props;

    return (columnsSets.data || []).find(columnsSet => columnsSet[this.columnsSetExportTypeField()]);
  }

  isColumnsFilterDisplayable() {
    const { accesspoints, guestFieldsToExport } = this.props;
    return accesspoints.fetched && guestFieldsToExport.fetched;
  }

  renderModal() {
    const { modalOpened } = this.state;
    const { guestFieldsToExport, accesspoints, columnsSets, userSettings, exportFor, customLabel } = this.props;
    const { errors } = columnsSets;

    const [standardFields, guestFields] = splitStandardFromCustomGuestFieldsForExport(guestFieldsToExport.data);

    const columnsSet = this.columnsSet();
    let initialColumns;
    if (columnsSet) {
      initialColumns = columnsSet.columns;
    } else if (exportFor === "product_information_request") {
      initialColumns = [];
    } else {
      initialColumns = userSettings.guest_export_columns;
    }

    return <Modal className="modal-content"
      isOpen={modalOpened}
      onRequestClose={this.toggleModal}
      style={defaultModalStyles}
      contentLabel="Modal"
    >
      <div className="modal-header">
        <h4 className="modal-title">{customLabel || this.i18n("export_config_columns")}</h4>
        <button onClick={this.toggleModal} type="button" className="btn btn-close"></button>
      </div>
      <div className="modal-body">
        <ErrorMessage errors={errors}/>
        <div className="row">
          <div className="col-md-12">
            { this.isColumnsFilterDisplayable() ?
              <ColumnsFilter
                guestFields={guestFields.map(field => Object.assign({}, field, { sortableItemId: field.key }))}
                standardFields={standardFields.map(field => Object.assign({}, field, { sortableItemId: field.key }))}
                accesspoints={accesspoints.data.map(ap => Object.assign({}, ap, { sortableItemId: ap._id }))}
                onSubmit={this.saveColumnsSet}
                eventId={urlEventId()}
                allowColumnsSetCreate={false}
                initialColumns={initialColumns}
                columnsEmptyAllowed={exportFor === "product_information_request"}
              />
              : <Loader size="xlarge" inline={false} containerHeight="350px" />
            }
          </div>
        </div>
      </div>
    </Modal>;
  }

  render() {
    const { customLabel } = this.props;
    return (
      <>
        <div onClick={this.toggleModal} style={{ cursor: "pointer" }}>
          <i className="fa-regular fa-gear fa-fw"></i> { customLabel || this.i18n("configure_export") }
        </div>
        {this.renderModal()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    columnsSets: state.columnsSets,
    accesspoints: state.accesspoints,
    guestFieldsToExport: state.guestFieldsToExport,
    userSettings: state.userSettings,
  };
}

const mapDispatchToProps = {
  fetchAccesspoints,
  fetchExportableGuestFields,
  fetchColumnsSets,
  createColumnsSet,
  updateColumnsSet,
  fetchUserSettings
};

ConfigureExportedColumns.defaultProps = {
  i18nPath: "exhibitors.leads_settings",
  exportFor: "mobinetwork_configuration"
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfigureExportedColumns));
