import { Component } from "react";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";

import RegistrationFormCreationReact from "../RegistrationFormCreation.react";

class RegistrationFormCreationButtons extends Component {
  render() {
    return (<RegistrationFormCreationReact render={ (wrapper) =>
      <Dropdown as={ButtonGroup}>
        <Button variant="primary" onClick={wrapper.createForm}>
          <i className="fa-regular fa-plus"></i> { I18n.t("react.registration_forms.create_new_form") }
        </Button>
        <Dropdown.Toggle variant="primary" aria-haspopup="true"></Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item eventKey="1" onClick={wrapper.toggleEventSourceModal}>
            { I18n.t("react.registration_forms.create_registration_form_from") }
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    }
    />);
  }
}

export default RegistrationFormCreationButtons;
