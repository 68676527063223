import { Component } from "react";
import { ButtonToolbar, Dropdown } from "react-bootstrap";
import { TextFieldValidations, TextFieldNormalizations } from "../../constants/Constants";
import FieldsModal from "../registration_form/FieldsModal.react";
import PhoneNumberPrefixModal from "../registration_form/PhoneNumberPrefixModal.react";
import CountrySelect from "../shared/CountrySelect.react";

class TextFieldValidation extends Component {

  /************************************************
  *  state.validations will be an array of hashes *
  *  => [                                         *
  *    { key: "email", type: "format" },          *
  *    { key: "min_length", value: 5}             *
  *  ]                                            *
  ************************************************/
  constructor(props) {
    super(props);
    [
      "addNormalizationHandler",
      "addNormalizationLink",
      "addValidation",
      "availableCategoryKeys",
      "availableValidationKeys",
      "getValidationCategory",
      "handleNormalizationSelect",
      "handleRemoveValidation",
      "removeValidation",
      "hasValidationValue",
      "isValidationAvailable",
      "renderNormalizations",
      "renderValidationInputValue",
      "renderValidations",
      "selectValidationHandler",
      "setValidationValue",
      "updateValidations",
      "validationCategoryOnChangeHandler"
    ].forEach((fn) => {
      this[fn] = this[fn].bind(this);
    });
    this.state = {
      validations: props.formItem.options.validations || [],
      normalizations: props.formItem.options.normalizations || []
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.formItem === this.props.formItem) return;

    const options = this.props.formItem && this.props.formItem.options;
    if (options) {
      const { validations, normalizations } = options;
      this.setState({ validations: validations || [], normalizations: normalizations || [] });
    }
  }

  /********************
   * Validations Part *
   ********************/

  getValidationCategory(validationKey) {
    return Object.keys(TextFieldValidations).filter(key => {
      return TextFieldValidations[key].find (h => h.key === validationKey);
    })[0];
  }

  hasValidationValue(validationKey) {
    const matchedValidation = TextFieldValidations[this.getValidationCategory(validationKey)].find(validation => validation.key === validationKey);
    return matchedValidation && matchedValidation.valueUpdatable;
  }

  //  Validation must be uniq
  //  Only one validation of type "format" can be defined
  isValidationAvailable(validation, validationIndex = null) {
    const { validations } = this.state;
    const validationNotUsed = validations.findIndex(v => v.key == validation.key) === -1;
    const existingFormatValidationIndex = validations.findIndex(v => v.type == "format");
    const formatDefinedTwice = validation.type == "format" && existingFormatValidationIndex > -1 && existingFormatValidationIndex != validationIndex;
    return validationNotUsed && !formatDefinedTwice;
  }

  availableValidationKeys(category, validationIndex = null) {
    const filteredValidations = TextFieldValidations[category].filter(validation => {
      return this.isValidationAvailable(validation, validationIndex);
    });
    return filteredValidations.map(h => h.key);
  }

  availableCategoryKeys() {
    const { formItem } = this.props;
    return Object.keys(TextFieldValidations).filter(category => {
      if (formItem.type == "text_area" && category != "length") return;
      return this.availableValidationKeys(category).length > 0;
    });
  }

  validationCategoryOnChangeHandler(category, validationIndex) {
    const { updateFormItem } = this.props;
    return () => {
      let validations = this.state.validations.slice();
      const defaultKey = this.availableValidationKeys(category, validationIndex)[0];
      const validation = TextFieldValidations[category].find(v => v.key === defaultKey);
      validations[validationIndex] = { key: validation.key, type: validation.type, value: validation.value };
      updateFormItem({ options: { validations } });
    };
  }

  selectValidationHandler(validationKey, validationIndex) {
    const { updateFormItem } = this.props;
    return () => {
      const defaultValidation = TextFieldValidations[this.getValidationCategory(validationKey)].find(h => h.key === validationKey);
      let validations = this.state.validations.slice();
      validations[validationIndex] = { key: validationKey, value: defaultValidation.value, type: defaultValidation.type };
      updateFormItem({ options: { validations } });
    };
  }

  updateValidations() {
    const { updateFormItem } = this.props;
    const { validations } = this.state;
    updateFormItem({ options: { validations } });
  }

  handleRemoveValidation(validationIndex) {
    const { updateFormItem } = this.props;
    return () => {
      let validations = this.state.validations.slice();
      validations.splice(validationIndex, 1);
      updateFormItem({ options: { validations } });
    };
  }

  removeValidation(validationKey) {
    const { updateFormItem } = this.props;
    let validations = this.state.validations.slice();
    const validation = validations.find((obj) => obj.key == validationKey);
    if (validation) {
      validations.splice(validations.indexOf(validation), 1);
      updateFormItem({ options: { validations } });
    }
  }

  setValidationValue(validationIndex, updatedKey = "value") {
    return (event) => {
      const newValue = event.target.value;
      let validations = this.state.validations.slice();
      validations[validationIndex][updatedKey] = newValue;
      this.setState({ validations });
    };
  }

  addValidation(e) {
    e.preventDefault();
    const { updateFormItem } = this.props;
    let validations = this.state.validations.slice();
    const defaultCategory = this.availableCategoryKeys()[0];
    const defaultValidationKey = this.availableValidationKeys(defaultCategory)[0];
    const validation = TextFieldValidations[defaultCategory].find(v => v.key === defaultValidationKey);
    validations.push({ key: validation.key, type: validation.type, value: validation.value });
    updateFormItem({ options: { validations } });
  }

  renderValidationSelect(validationIndex) {
    const { validations } = this.state;
    const { formItem } = this.props;
    const { key } = formItem;

    if (!validations) return null;
    const category = this.getValidationCategory(validations[validationIndex].key);
    const formatOptions = TextFieldValidations[category].map((validation, index) => {
      const disabled = !this.isValidationAvailable(validation, validationIndex);
      return (
        <Dropdown.Item
          key={`${validationIndex}-${index}`}
          disabled={disabled}
          onClick={this.selectValidationHandler(validation.key, validationIndex)}
        >
          { I18n.t(`react.input_sub_format_${validation.key}`) }
        </Dropdown.Item>
      );
    });

    return <div className="col-auto">
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id={`${key}-format-select`} key={validationIndex}>
          { I18n.t(`react.input_sub_format_${validations[validationIndex].key}`) }
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          {formatOptions}
        </Dropdown.Menu>
      </Dropdown>
    </div>;
  }

  renderValidationInputValue(validationIndex) {
    const validation = this.state.validations[validationIndex];

    if (validation.key === "phone_number") {
      return (
        <div className="col-auto">
          <CountrySelect
            value={validation.first}
            locale={this.props.locale}
            onChange={this.setValidationValue(validationIndex, "first")}
            onBlur={this.updateValidations}
            defaultLabel={I18n.t("react.phone_number_prefix_select_default")}
            formatDisplay={(country, locale) => `${country[locale]} (${country["phone_number_prefix"]}) `}
            className="form-control float-start"
          />
        </div>
      );
    }

    if (!this.hasValidationValue(validation.key)) {
      return null;
    }

    return (
      <div className="col-auto">
        <input type="text" className="form-control float-start" onBlur={this.updateValidations} onChange={this.setValidationValue(validationIndex)} value={validation.value}/>
      </div>
    );
  }

  renderCategorySelect(validationIndex) {
    const { formItem } = this.props;
    const { validations } = this.state;

    const options = Object.keys(TextFieldValidations).map(category => {
      if (formItem.type == "text_area" && category != "length") return;
      const disabled = this.availableValidationKeys(category, validationIndex).length == 0;
      return (
        <Dropdown.Item
          key={category}
          disabled={disabled}
          onClick={this.validationCategoryOnChangeHandler(category, validationIndex)}
        >
          <i className={"fa-regular fa-" + I18n.t(`react.input_format_${category}_icon`)}></i> { I18n.t(`react.input_format_${category}`) }
        </Dropdown.Item>
      );
    });

    const category = this.getValidationCategory(validations[validationIndex].key);
    return (
      <div className="col-auto">
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id={`${formItem.key}-format-select`}>
            <i className={`fa-regular fa-plus ${I18n.t(`react.input_format_${category}_icon`)}`}></i> {I18n.t(`react.input_format_${category}`)}
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            {options}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  renderRemoveButton(removeMethod, key) {
    return (
      <div className="col-auto">
        <button key={key} type="button" className="btn btn-secondary" onClick={removeMethod}>
          <i className="fa-regular fa-trash-can"></i>
        </button>
      </div>
    );
  }

  renderValidations() {
    const { validations } = this.state;

    if (!validations) return null;

    const validationHtml = validations.map((validation, index) => {
      return <ButtonToolbar className="mb-3 row g-2 align-items-center" key={`${validation.key}-${index}`}>
        { this.renderCategorySelect(index) }
        { this.renderValidationSelect(index) }
        { this.renderValidationInputValue(index) }
        { this.renderRemoveButton(this.handleRemoveValidation(index), `remove-${validation.key}-${index}`) }
      </ButtonToolbar>;
    });

    return <div>
      <hr/>
      <label className="form-label">{ I18n.t("react.data_validations") }</label>
      { validationHtml }
      { this.renderAddValidationButton() }
    </div>;
  }

  renderAddValidationButton() {
    if (this.availableCategoryKeys().length == 0) return;
    return <div className=" mt-10">
      <a href="#" onClick={this.addValidation}>
        { I18n.t("react.add_validation") }
      </a>
    </div>;
  }

  /***********************
   * Normalizations Part *
   **********************/

  handleNormalizationSelect(normalization, normalizationIndex) {
    const { updateFormItem } = this.props;
    return () => {
      let normalizations = this.state.normalizations.slice();
      normalizations[normalizationIndex] = normalization;
      updateFormItem({ options: { normalizations } });
    };
  }


  renderNormalizationSelect(normalizationIndex) {
    const { normalizations } = this.state;
    const options = TextFieldNormalizations.map((normalization, index) => {
      const disabled = normalizations.includes(normalization);
      return (
        <Dropdown.Item
          key={`${normalization}-${index}`}
          disabled={disabled}
          onClick={this.handleNormalizationSelect(normalization, normalizationIndex)}
        >
          <i className={"fa-regular fa-" + I18n.t(`react.input_normalization_${normalization}_icon`)}></i> { I18n.t(`react.input_normalization_${normalization}`) }
        </Dropdown.Item>
      );
    });

    const normalization = normalizations[normalizationIndex];
    return (
      <div className="col-auto">
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id={`${normalizationIndex}-normalization-select`}>
            <i className={`fa-regular fa-plus ${I18n.t(`react.input_normalization_${normalization}_icon`)}`}></i> {I18n.t(`react.input_normalization_${normalization}`)}
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            {options}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  handleRemoveNormalization(normalizationIndex) {
    const { updateFormItem } = this.props;
    return () => {
      let normalizations = this.state.normalizations.slice();
      normalizations.splice(normalizationIndex, 1);
      updateFormItem({ options: { normalizations } });
    };
  }

  addNormalizationHandler(e) {
    e.preventDefault();
    const { updateFormItem } = this.props;
    let normalizations = this.state.normalizations.slice();
    const defaultNormalization = TextFieldNormalizations.find(val => !normalizations.includes(val));
    normalizations.push(defaultNormalization);
    updateFormItem({ options: { normalizations } });
  }


  addNormalizationLink() {
    const { normalizations } = this.state;
    if (!normalizations || normalizations.length >= TextFieldNormalizations.length) return;
    return <a href="#" onClick={this.addNormalizationHandler}>{ I18n.t("react.add_normalization") }</a>;
  }

  renderNormalizations() {
    const { normalizations } = this.state;
    let normalizationsHtml = null;
    if (normalizations) {
      normalizationsHtml = normalizations.map((normalization, index) => {
        return <ButtonToolbar className="mb-3 row g-2 align-items-center" key={`${normalization}-${index}`}>
          { this.renderNormalizationSelect(index) }
          { this.renderRemoveButton(this.handleRemoveNormalization(index), `remove-${normalization}-${index}`) }
        </ButtonToolbar>;
      });
    }
    const headers = normalizations && normalizations.length > 0 ? (
      <div>
        <hr/>
        <label className="form-label">{ I18n.t("react.data_normalizations") }</label>
      </div>
    ) : null;

    return <div>
      { headers }
      { normalizationsHtml }
      { this.addNormalizationLink() }
    </div>;
  }

  render() {
    const { shouldBeDisplayed, allItems, formItem, allGuestFields, createFormItems, createGuestField, updateFormItem, accountId } = this.props;
    if (!shouldBeDisplayed || !accountId) return null;
    return (
      <div>
        { this.renderValidations() }
        { this.renderNormalizations() }
        <FieldsModal
          createFormItems={createFormItems}
          allItems={allItems}
          formItem={formItem}
          updateFormItem={updateFormItem}
          accountId={accountId}
        />
        <PhoneNumberPrefixModal
          formItem={formItem}
          removeValidation={this.removeValidation}
          createGuestField={createGuestField}
          allGuestFields={allGuestFields} />
      </div>
    );
  }
}

export default TextFieldValidation;
