import { ActionTypes } from "../constants/Constants";

module.exports = {

  showNotice: function(notice, noticeType = "info", timeout = 5000, customDisplay = false) {
    return {
      type: ActionTypes.SHOW_NOTICE,
      message: notice,
      noticeType,
      timeout,
      customDisplay
    };
  }
};
