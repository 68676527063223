import PropTypes from "prop-types";
import Timezones from "../../constants/Timezones.yml";

const TimezoneSelect = ({ value, className, onChange, onBlur }) => {
  const timezones = Timezones.map(timezone => {
    return <option key={timezone.value} value={timezone.value}>{timezone.label}</option>;
  });

  return (
    <select className={className} value={value} onChange={onChange} onBlur={onBlur}>
      {timezones}
    </select>
  );
};

TimezoneSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func
};

TimezoneSelect.defaultProps = {
  className: "form-select",
};

export default TimezoneSelect;
