import { Component, MouseEvent } from "react";
import ReactDOM from "react-dom";
import last from "lodash/last";
import Icons from "../../constants/Icons";
import { DragTypes } from "../../constants/Constants";
import ShowAllLink from "./ShowAllLink.react";
import FormItemOption from "./FormItemOption.react";
import Sortable from "../Sortable.react";
import { FormItem } from "../../types/FormItem";
import { FormItemOption as FormItemOptionType } from "../../types/FormItemOption";
import { Thematic } from "../../types/Thematic";

const TRUNCATE_LIMIT = 5;
const RANK_STEP = 1000;

type PartialFormItem = Pick<FormItem, "form_item_options">;

interface Props {
  formItem: FormItem;
  mode: string;
  updateFormItem(formItem: PartialFormItem, sendFile?: boolean, optimistic?: boolean): void;
  thematics: Thematic[];
  fetchThematics(): void;
}

interface State {
  showingAll: boolean;
}

class ThematicsType extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    [
      "triggerShowAll",
      "updateFormItemOption",
      "addThematic",
      "addAllThematics",
      "handleDrop",
      "destroyFormItemOption"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      showingAll: false
    };
  }

  componentDidMount(): void {
    const { thematics, fetchThematics, formItem, mode } = this.props;
    if (!thematics) {
      fetchThematics();
    } else if (this.needsToCreateFirstOption(formItem, mode)) {
      this.createThematicOption();
    }
  }

  componentDidUpdate(): void {
    const { thematics, formItem, mode } = this.props;
    if (thematics && this.needsToCreateFirstOption(formItem, mode)) {
      this.createThematicOption(this.props);
    }
  }

  needsToCreateFirstOption(formItem: FormItem, mode: string): boolean {
    return this.noFormItemOptions(formItem) && mode == "edit";
  }

  noFormItemOptions(formItem: FormItem): boolean {
    return !formItem.form_item_options || formItem.form_item_options.length == 0;
  }

  createThematicOption(props: Props = this.props): void {
    const thematic = this.availableThematics(props)[0];
    if (!thematic) return;

    props.updateFormItem({
      form_item_options: [{ label: thematic.name, key: thematic._id, rank: this.nextRank(props) }]
    });
  }

  nextRank(props: Props = this.props): number {
    const lastFormItemOption = last(props.formItem.form_item_options);
    return lastFormItemOption ? lastFormItemOption.rank + RANK_STEP : 1000;
  }

  availableThematics(props: Props = this.props): Thematic[] {
    const { thematics, formItem } = props;

    if (!thematics) return [];

    if (this.noFormItemOptions(formItem))
      return thematics;

    const usedThematics = formItem.form_item_options.map(option => option.key);

    return thematics.filter(thematic => {
      return usedThematics.indexOf(thematic._id) == -1;
    });
  }

  triggerShowAll(showingAll: boolean): void {
    if (!showingAll) {
      //ensure element stay visible
      const component = this.refs["dropdown-div"];
      const domNode = ReactDOM.findDOMNode(component) as HTMLInputElement;
      domNode.scrollIntoView();
    }
    this.setState({ showingAll });
  }

  updateFormItemOption(formItemOption: FormItemOptionType): void {
    this.props.updateFormItem({
      form_item_options: [formItemOption]
    });
  }

  addThematic(e: MouseEvent<any>): void {
    e.preventDefault();
    this.createThematicOption();
  }

  addAllThematics(e: MouseEvent<any>): void {
    e.preventDefault();
    let nextRank = this.nextRank();

    const newFormItemOptions = this.availableThematics().reduce((acc, thematic) => {
      acc.push({ label: thematic.name, key: thematic._id, rank: nextRank });
      nextRank += RANK_STEP;
      return acc;
    }, []);

    this.props.updateFormItem({
      form_item_options: newFormItemOptions
    });
  }

  handleDrop(previousItemId: string, itemId: string, nextItemId: string, estimatedIndex: number): void {
    this.props.updateFormItem({
      form_item_options: [{
        _id: itemId,
        rank: estimatedIndex,
        rank_after_id: previousItemId,
        rank_before_id: nextItemId
      }]
    }, false, true);
  }

  destroyFormItemOption(optionId: string): void {
    this.props.updateFormItem({
      form_item_options: [{ _id: optionId, _destroy: "1" }]
    });
  }

  numberOfOptionsDisplayed(): number {
    return this.state.showingAll ? this.props.formItem.form_item_options.length : TRUNCATE_LIMIT;
  }

  renderOptions(): JSX.Element {
    const { mode, thematics } = this.props;

    if (mode != "edit" || !thematics) return;

    if (this.availableThematics().length <= 0)
      return this.renderNoMoreAvailableThematicsMessage();

    return <div className="mt-10" style={{ marginLeft: "3%" }}>
      <a href="#" onClick={this.addThematic}>{ I18n.t("react.form_thematics.add_thematic") }</a>
      <span style={{ paddingLeft: "10px", verticalAlign: "middle" }}>{ Icons["vertical_divider"]() }</span>
      <a href="#" onClick={this.addAllThematics}>{ I18n.t("react.form_thematics.add_all_thematics") }</a>
    </div>;
  }

  renderNoMoreAvailableThematicsMessage(): JSX.Element {
    return <div className="alert alert-warning mt-10" role="alert">
      <span className="alert-link">
        {I18n.t("react.form_thematics.no_more_thematics")}
      </span>
    </div>;
  }

  renderFormItemOptions(): JSX.Element[] {
    const { mode, formItem, thematics } = this.props;

    if (this.noFormItemOptions(formItem))
      return [];

    const formItemOptions = formItem.form_item_options.map((option, index) => {
      return (
        <FormItemOption
          formItem={formItem}
          key={option._id}
          itemOption={option}
          keyInputType="dropdown"
          keyInputData={thematics}
          keyField="_id"
          labelField="name"
          mode={mode}
          readStyle={{ marginLeft: 30 }}
          disableLabel={true}
          defaultValue={false}
          updateHandler={this.updateFormItemOption}
          allowDestroy={true}
          destroyHandler={this.destroyFormItemOption}
        >
          <span>{ index + 1 }.</span>
        </FormItemOption>
      );
    });

    return formItemOptions.slice(0, this.numberOfOptionsDisplayed());
  }

  render(): JSX.Element {
    const { formItem, mode, thematics } = this.props;

    if (!thematics) {
      return null;
    }

    const renderedFormItemOptions = this.renderFormItemOptions();

    return <div>
      <div ref="dropdown-div">
        { mode == "read" ? renderedFormItemOptions : <Sortable
          itemIdKey="_id"
          itemIndexKey="rank"
          dragType={DragTypes.FORM_ITEM_OPTION}
          items={formItem.form_item_options.slice(0, this.numberOfOptionsDisplayed())}
          handleDrop={this.handleDrop}
          handlePosition="left">
          { renderedFormItemOptions }
        </Sortable>
        }
      </div>
      <ShowAllLink
        nbItems={formItem.form_item_options.length}
        onClickHandler={this.triggerShowAll}
        limit={TRUNCATE_LIMIT}
      />
      { this.renderOptions() }
    </div>;
  }
}

export default ThematicsType;
