import { useState } from "react";
import Select from "react-select";
import ColorPicker from "../../../components/ColorPicker.react";
import BadgeFontUploadModal from "../BadgeFontUploadModal.react";
import { Button } from "react-bootstrap";
import { BadgeFont } from "../../../types/BadgeTemplate";
import { DEFAULT_EDITOR_PROPERTIES } from "../Constants";

const FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96];

const i18n = (key, opts = {}): string => {
  return I18n.t(`react.badge_builder.properties_panel.${key}`, opts);
};

const targetValueEvent = (value: any): any => {
  return {
    target: { value }
  };
};

interface Props {
  badgeTemplateId: string;
  fillValue: string;
  fontSizeValue: number;
  fontFamilyValue: string;
  textAlignValue: string;
  badgeFonts: BadgeFont[];

  handleChange: (property: string) => (e: any) => void;
}

const TextProperties: React.FC<Props> = ({
  badgeTemplateId,
  badgeFonts = [],
  fillValue,
  fontSizeValue,
  fontFamilyValue,
  textAlignValue,
  handleChange,
}) => {

  const [isFontModalOpen, setIsFontModalOpen] = useState<boolean>(false);

  const updateFontFamily = (value: string): void => {
    handleChange("fontFamily")(targetValueEvent(value));
  };

  const updateFontSize = (value: string): void => {
    handleChange("fontSize")(targetValueEvent(value));
  };

  const renderFontFamily = (): JSX.Element => {
    const options = [
      { label: I18n.t("default"), value: DEFAULT_EDITOR_PROPERTIES["fontFamily"] },
      ...badgeFonts.map(({ _id, basename }) => ({ label: basename, value: _id }))
    ];

    return <div className="row align-items-center">
      <label className="col-3">{i18n("font")}</label>
      <div className="col-9">
        <Select
          className="react-select"
          classNamePrefix="react-select"
          name="font-select"
          value={options.filter(({ value }) => value === fontFamilyValue)}
          options={options}
          onChange={(e): void => updateFontFamily(e.value)}
        />
      </div>
    </div>;
  };

  const renderFontSize = (): JSX.Element => {
    const options = FONT_SIZES.map(size => ({ label: `${size}px`, value: size }));

    return <div className="row align-items-center">
      <label className="col-3">{i18n("size")}</label>
      <div className="col-9">
        <Select
          className="react-select"
          classNamePrefix="react-select"
          name="font-size-select"
          value={options.filter(({ value }) => value === fontSizeValue)}
          options={options}
          onChange={(e): void => updateFontSize(e.value)}
        />
      </div>
    </div>;
  };

  const updateTextAlign = (value: string): void => {
    handleChange("textAlign")(targetValueEvent(value));
  };

  const renderAlignment = (): JSX.Element => {
    return <div className="row align-items-center">
      <label className="col-3">{i18n("alignment")}</label>
      <div className="col-9">
        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
          <label className="btn btn-secondary" htmlFor="btnradio1">
            <i className="fa-regular fa-align-left" aria-hidden="true" />
            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" onChange={(): void => updateTextAlign("left")} checked={(textAlignValue === "left") || false}/>
          </label>

          <label className="btn btn-secondary" htmlFor="btnradio2">
            <i className="fa-regular fa-align-center" aria-hidden="true" />
            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" onChange={(): void => updateTextAlign("center")} checked={(textAlignValue === "center") || false}/>
          </label>

          <label className="btn btn-secondary" htmlFor="btnradio3">
            <i className="fa-regular fa-align-right" aria-hidden="true" />
            <input type="radio" className="btn-check" name="btnradio" id="btnradio3" onChange={(): void => updateTextAlign("right")} checked={(textAlignValue === "right") || false}/>
          </label>
        </div>
      </div>
    </div>;
  };

  const updateTextColor = (value: string): void => {
    handleChange("fill")(targetValueEvent(value));
  };

  const renderColorPicker = (): JSX.Element => {
    return <div className="row align-items-center">
      <label className="col-3">{i18n("color")}</label>
      <div className="col-9">
        <ColorPicker
          setColor={updateTextColor}
          selectedColor={fillValue}
          displayValue={true}
          placement="top"
        />
      </div>
    </div>;
  };

  const renderBadgeFontUploadButton = () : JSX.Element => {
    return <div>
      <Button className="btn btn-secondary" onClick={(): void => setIsFontModalOpen(true)}>
        {i18n("upload_custom_font")}
      </Button>
      {isFontModalOpen && <BadgeFontUploadModal isOpen={isFontModalOpen} badgeTemplateId={badgeTemplateId} toggleModal={(): void => setIsFontModalOpen(false)} />}
    </div>;
  };

  return <div className="d-flex flex-column gap-3">
    <label className="form-label">{i18n("text")}</label>
    { renderFontFamily() }
    { renderFontSize() }
    { renderAlignment() }
    { renderColorPicker() }
    { renderBadgeFontUploadButton() }
  </div>;
};

export default TextProperties;
