import { Component, MouseEvent } from "react";
import CheckinpointList from "./CheckinpointList.react";
import CheckinpointOption from "./CheckinpointOption.react";
import { DragTypes } from "../../constants/Constants";
import Sortable from "../Sortable.react";
import { shouldBeDisplayed } from "../../utils/CheckinpointUtils.js";
import CreateAccesspointModal from "../mapping_step/CreateAccesspointModal.react";
import ShowAllLink from "./ShowAllLink.react";
import { Accesspoint } from "../../types/Accesspoint";
import { FormItem } from "../../types/FormItem";
import { FormItemOption } from "../../types/FormItemOption";

const TRUNCATE_LIMIT = 5;

interface Props {
  accesspointList(): Accesspoint[];
  accesspoints: Accesspoint[];
  availableAccesspoints(optionItem?: FormItemOption): Accesspoint[];
  checkboxValueOnBlur(): void;
  checkboxValueOnChanged(): void;
  checkedAccesspoints: string[];
  closeCreateAccesspointModal(): void;
  destroyOption(): void;
  eventId: string;
  formItem: FormItem;
  getNewAccesspoint(accesspoint?: Accesspoint): void;
  handleDrop(): void;
  renderAddAccesspointOptions(): JSX.Element;
  injectCreateAccesspointKey(accesspoints: Accesspoint[]): void;
  locale: string;
  mode: string;
  renderOptionsFilter(advancedMode: boolean): JSX.Element;
  showCreateAccesspointModal: boolean;
  showErrorAccesspoint(): JSX.Element;
  showingAll: boolean;
  triggerShowAll(e: MouseEvent<HTMLAnchorElement>): void;
  updateOption(): void;
}

class CheckinpointGroupType extends Component<Props> {

  renderAdvancedEditModeOptions(): JSX.Element {
    const { accesspoints, formItem, mode, accesspointList, showingAll, triggerShowAll } = this.props;
    const overrideLabel = formItem.options.override_label || [];
    const checkinPointList = accesspointList().map(accesspoint => {
      return (
        <CheckinpointList
          key={accesspoint.id}
          mode={mode}
          accesspoint={accesspoint}
          overrideLabel={overrideLabel}
          shouldBeDisplayed={shouldBeDisplayed(accesspoints, formItem, accesspoint.id)}
        />
      );
    });
    const truncate = showingAll ? checkinPointList.length : TRUNCATE_LIMIT;
    return (
      <div>
        {checkinPointList.slice(0, truncate)}
        <ShowAllLink
          nbItems={checkinPointList.length}
          onClickHandler={triggerShowAll}
          limit={TRUNCATE_LIMIT}
        />
      </div>
    );
  }

  renderEditModeOptions(): JSX.Element {
    const {
      accesspoints, formItem, mode, showingAll, checkedAccesspoints,
      updateOption, destroyOption, checkboxValueOnChanged, checkboxValueOnBlur,
      injectCreateAccesspointKey, availableAccesspoints, handleDrop, triggerShowAll
    } = this.props;

    const formItemOptions = formItem.form_item_options || [];

    const checkinpointOptions = formItemOptions.map((option) => {
      const checked = checkedAccesspoints[option.key] || false;
      return (
        <div key={option.key}>
          <CheckinpointOption
            mode={mode}
            shouldBeDisplayed={shouldBeDisplayed(accesspoints, formItem, option.key)}
            itemOption={option}
            updateHandler={updateOption}
            destroyHandler={destroyOption}
            allowDestroy={formItem.form_item_options.length > 1}
            accesspoints={injectCreateAccesspointKey(availableAccesspoints(option))}
            checked={checked}
            checkboxValueOnChanged={checkboxValueOnChanged}
            checkboxValueOnBlur={checkboxValueOnBlur}
            radioStyle={formItem.options.uniq_option}
          />
        </div>
      );
    });

    const truncate = showingAll ? formItemOptions.length : TRUNCATE_LIMIT;
    return (
      <div>
        <Sortable itemIdKey="_id"
          itemIndexKey="rank"
          dragType={DragTypes.FORM_ITEM_OPTION}
          items={formItemOptions.slice(0, truncate)}
          handleDrop={handleDrop}
          handlePosition="left">
          { checkinpointOptions.slice(0, truncate) }
        </Sortable>
        <ShowAllLink
          nbItems={formItemOptions.length}
          onClickHandler={triggerShowAll}
          limit={TRUNCATE_LIMIT}
        />
      </div>
    );
  }

  renderEditMode(): JSX.Element {
    const { formItem, eventId, locale, showCreateAccesspointModal, showErrorAccesspoint, renderAddAccesspointOptions, renderOptionsFilter, closeCreateAccesspointModal, getNewAccesspoint } = this.props;
    const items = formItem.options.advanced_mode ? this.renderAdvancedEditModeOptions() : this.renderEditModeOptions();

    return (
      <div>
        <CreateAccesspointModal isVisible={showCreateAccesspointModal} eventId={eventId} locale={locale} onCloseFunction={closeCreateAccesspointModal} updateParent={getNewAccesspoint} />
        <div className="row">
          <div className="col-sm-12">
            <div className="mt-10">
              {showErrorAccesspoint()}
              {items}
              {renderAddAccesspointOptions()}
            </div>
            { renderOptionsFilter(formItem.options.advanced_mode) }
          </div>
        </div>
      </div>
    );
  }

  renderReadModeOptions(collection, datakey, fromTraits = false): JSX.Element {
    const { formItem, showingAll } = this.props;
    const { options } = formItem;
    const truncate = showingAll ? collection.length : TRUNCATE_LIMIT;
    return collection.map((option, index) => {
      const checkable = formItem.options.uniq_option ? (
        <input type="radio" className="form-check-input" disabled checked={option.value} readOnly />
      ) : (
        <input type="checkbox" className="form-check-input" disabled checked={option.value} readOnly/>
      );
      let value = option[datakey];
      if (fromTraits && options.override_label) {
        const key = Object.keys(options.override_label)[0];
        value = option.traits[key] || value;
      }
      return (
        <div key={`${option._id}_${index}`} className="mb-3">
          <div>
            <div className="form-check">
              <label className="form-check-label">
                {checkable}
                { value }
              </label>
            </div>
            <hr className="light-hr" />
          </div>
        </div>
      );
    }).slice(0, truncate);
  }

  renderReadMode(): JSX.Element {
    const { accesspoints, formItem, accesspointList } = this.props;
    //formItemOptions or Accesspoints
    let collection = [];
    let datakey = "";
    if (formItem.options.advanced_mode) {
      collection = accesspointList();
      datakey = "display_name";
    } else {
      let formItemOptions = formItem.form_item_options.slice();
      formItemOptions = formItemOptions || [];
      formItemOptions = formItemOptions.filter(option => {
        return shouldBeDisplayed(accesspoints, formItem, option.key);
      });
      collection = formItemOptions;
      datakey = "label";
    }
    return <div>{this.renderReadModeOptions(collection, datakey, formItem.options.advanced_mode)}</div>;
  }

  render(): JSX.Element {
    const { mode } = this.props;

    return mode === "read" ? this.renderReadMode() : this.renderEditMode();
  }
}

export default CheckinpointGroupType;
