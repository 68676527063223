import { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputLabelWithVisibilityButton from "../InputLabelWithVisibilityButton.react";
import { fetchFilterMetadata } from "../../../actions/ProgramsActionCreators";
import { urlEventId } from "../../../utils/pathUtils";
import { Program } from "../../../types/Program";
import DeletablePanel from "../DeletablePanel.react";

interface Props {
  program: Program;
  traitIndex: number;

  onChangeConfiguration(program: Program): void;
  onDeleteOption(optionType: string, optionIndex: number): void;
}

function i18n(key, opts = {}): string {
  return I18n.t(`react.programs.traits_display_options.${key}`, opts);
}

const TraitDisplayOptions: React.FC<Props> = ({ program, traitIndex, onChangeConfiguration, onDeleteOption }) => {
  const metadata = useSelector((state: any) => state.programs.metadata);
  const isFetchingMetadata = useSelector((state: any) => state.programs.isFetchingMetadata);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!metadata.trait && !isFetchingMetadata) {
      dispatch(fetchFilterMetadata(urlEventId(), ["trait"]));
    }
  }, []);

  const traitChanged = (e: ChangeEvent<HTMLSelectElement>): void => {
    const traitsDisplayed = [...program.traits_displayed];
    traitsDisplayed[traitIndex].key = e.target.value;
    const newProgram = { ...program, traits_displayed: traitsDisplayed };
    onChangeConfiguration(newProgram);
  };

  const buildTraitOptions = (): any => {
    if (!metadata.trait) return [];

    const options = Object.keys(metadata.trait).map((traitKey) => {
      return <option value={traitKey} key={traitKey}>{traitKey}</option>;
    });
    if (program.traits_displayed[traitIndex]?.key === "") {
      options.push(<option value="" key="empty">{i18n("select_placeholder")}</option>);
    }
    return options;
  };

  const renderTraitPicker = (): JSX.Element => {
    return <div className="mt-3">
      <label htmlFor={"traits"} className="form-label">{i18n("data")}</label>
      <select className="form-select" value={program.traits_displayed[traitIndex]?.key || ""} name="traits" onChange={traitChanged}>
        {buildTraitOptions()}
      </select>
    </div>;
  };

  const labelWithVisibilityButtonChanged = (label: string, isVisible: boolean): void => {
    const traitsDisplayed = [...program.traits_displayed];
    traitsDisplayed[traitIndex].label = label;
    traitsDisplayed[traitIndex].label_displayed = isVisible;
    const newProgram = { ...program, traits_displayed: traitsDisplayed };
    onChangeConfiguration(newProgram);
  };

  const metadataOptions = program.traits_displayed[traitIndex];
  return <DeletablePanel
    title={I18n.t("react.programs.program_options.traits_option_label")}
    onDelete={(): void => onDeleteOption("traits", traitIndex)}
    draggable={true}>
    <InputLabelWithVisibilityButton
      label={i18n("label")}
      value={metadataOptions?.label}
      name="label"
      isDisplayed={metadataOptions?.label_displayed}
      onChangeHandler={labelWithVisibilityButtonChanged}
    />
    { renderTraitPicker() }
  </DeletablePanel>;
};

export default TraitDisplayOptions;
