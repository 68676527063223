import { Component } from "react";
import moment, { Moment } from "moment";
import { Button } from "react-bootstrap";
import DatePicker from "../../components/shared/DatePicker.react";
import ThematicsDropdown from "../../containers/ThematicsDropdown.react";
import { BlogArticle, BlogArticleApiAttributes } from "../../types/BlogArticle";
import { jsonToFormData } from "../../utils/formDataUtils.js";
import { hoursOptions, minutesOptions } from "../../utils/DateUtils.js";
import { Event } from "../../types/Event";
import TranslatableInput from "../../components/shared/TranslatableInput.react";
import { generatePathSlug } from "../../utils/StringUtils";

const AUTHORIZED_BLOG_ARTICLE_PARAMS = ["title", "website_path_slug", "website_path_slug_translations", "published", "published_at", "excerpt", "thematic_ids", "illustration", "remove_illustration"];

interface Props {
  event: Event;
  blogArticle: BlogArticle;
  errors: any;
  isFetching: boolean;
  isSaving: boolean;
  withSidebar: boolean
  shown: boolean;
  onSubmit?: (attributes: any) => void;
  hide: () => void;
}

interface State extends BlogArticleApiAttributes {
  momentPublishedAt: Moment;
  publishedDate: string;
  publishedHour: number;
  publishedMinute: number;
}

function i18n(key, opts = {}): string {
  return I18n.t(`react.blog_articles.blog_article_form.${key}`, opts);
}

class BlogArticleForm extends Component<Props, State> {
  private static defaultState = {
    title: "",
    website_path_slug: "",
    website_path_slug_translations: {},
    published: false,
    momentPublishedAt: moment(),
    publishedDate: moment().format(I18n.t("datetime_picker_js_format")),
    publishedHour: moment().hours(),
    publishedMinute: moment().minutes(),
    excerpt: "",
    thematic_ids: [],
    illustration: null,
    remove_illustration: false,
  };

  private illustrationInput;

  constructor(props: Props) {
    super(props);
    [
      "prefillPathSlug",
      "changeTimeSelector",
      "changeDateSelector",
      "toggleCheckbox",
      "updateImage",
      "changeThematicsSelector",
      "saveBlogArticle",
      "hideView"
    ].forEach(fn => {
      this[fn] = this[fn].bind(this);
    });

    this.state = BlogArticleForm.defaultState;
  }

  componentDidUpdate(prevProps: Props): void {
    const { blogArticle, isSaving } = this.props;

    if (!prevProps.blogArticle && blogArticle) {
      this.setState({ ...this.filterBlogArticleAttributes(blogArticle) }, () => {
        this.computePublishedAtState();
      });
    }

    if (prevProps.isSaving && !isSaving && this.illustrationInput) {
      // after create/update, clear file input and checkbox state
      this.setState({ illustration: null, remove_illustration: false });
      this.illustrationInput.value = "";
    }
  }

  filterBlogArticleAttributes(blogArticle: any): any {
    return AUTHORIZED_BLOG_ARTICLE_PARAMS.reduce((acc, field) => {
      if (blogArticle[field] !== undefined && blogArticle[field] !== null) {
        acc[field] = blogArticle[field];
      }
      return acc;
    }, {});
  }

  computePublishedAtState(): any {
    const { blogArticle } = this.props;
    const momentPublishedAt = blogArticle.published_at ? moment(blogArticle.published_at) : moment();
    const publishedDate = momentPublishedAt.format(I18n.t("datetime_picker_js_format"));
    const publishedHour = momentPublishedAt.hours();
    const publishedMinute = momentPublishedAt.minutes();

    this.setState({
      momentPublishedAt,
      publishedDate,
      publishedHour,
      publishedMinute
    });
  }

  changeStandardField(key: string): (e) => void {
    return (e): void => {
      let value = e.target.value;
      if (key === "published") {
        value = value === "true";
      }
      this.setState({ [key]: value } as Pick<State, "title" | "published" | "excerpt">);
    };
  }

  prefillPathSlug(e): void {
    const { website_path_slug } = this.state;

    if (website_path_slug == "") {
      this.setState({ website_path_slug: generatePathSlug(e.target.value) });
    }
  }

  toggleCheckbox(key: string): () => void {
    return (): void => {
      const oldValue = this.state[key];
      this.setState({ [key]: !oldValue } as Pick<State, "remove_illustration">);
    };
  }

  updateImage(key: string): (e) => void {
    return (e): void => {
      const file = e.target.files[0];
      this.setState({ [key]: file } as Pick<State, "illustration">);
    };
  }

  changeTimeSelector(key: string): (e) => void {
    return (e): void => {
      const newState = { ...this.state, [key]: e.target.value };
      const { publishedDate, publishedHour, publishedMinute } = newState;
      newState.momentPublishedAt = moment(publishedDate, I18n.t("datetime_picker_js_format")).hours(publishedHour).minutes(publishedMinute);
      newState.published_at = newState.momentPublishedAt.format();
      this.setState(newState);
    };
  }

  changeDateSelector(value: string): void {
    const { publishedHour, publishedMinute } = this.state;
    const momentValue = moment(value);
    const publishedDate = momentValue.format(I18n.t("datetime_picker_js_format"));
    const momentPublishedAt = momentValue.clone().hours(publishedHour).minutes(publishedMinute);
    const published_at = momentPublishedAt.format();

    this.setState({
      publishedDate,
      momentPublishedAt,
      published_at
    });
  }

  changeThematicsSelector(options): void {
    this.setState({ "thematic_ids": options ? options.map(opt => opt.value) : [] });
  }

  saveBlogArticle(e): void {
    e.preventDefault();
    const { onSubmit } = this.props;
    // we need to use FormData to upload illustration through AJAX request
    onSubmit(jsonToFormData(this.filterBlogArticleAttributes(this.state), "blog_article"));
  }

  hideView(): void {
    const { hide } = this.props;
    hide();
  }

  fieldError(field): string {
    const { errors } = this.props;

    if (!errors[field]) return null;

    return errors[field];
  }

  renderFieldError(field): JSX.Element {
    const error = this.fieldError(field);
    if (!error) return null;

    return <span className="text-danger" style={{ "marginTop": "5px", "display": "inline-block" }}>{ error }</span>;
  }

  viewTitle(): string {
    const { blogArticle } = this.props;

    if (blogArticle) {
      return i18n("edit_article");
    } else {
      return i18n("add_article");
    }
  }

  renderPublishedAt(): JSX.Element {
    const { published, momentPublishedAt, publishedHour, publishedMinute } = this.state;

    if (!published) return;

    const value = momentPublishedAt.format();

    return (
      <div className="mb-3" key="picker">
        <label className="form-label">{ I18n.t("mongoid.attributes.blog_article.published_at") }</label><br />
        <div className="row g-2 align-items-center">
          <div className="col-auto">
            <DatePicker
              selectedDate={new Date(value)}
              onChange={this.changeDateSelector}
            />
          </div>
          <div className="col-auto">
            <div className="row g-2 align-items-center time-part">
              <div className="col-auto">
                <select id="start_hour" className="form-select" onChange={this.changeTimeSelector("publishedHour")} value={publishedHour}>
                  {hoursOptions()}
                </select>
              </div>
              <div className="col-auto">
                <select id="start_minute" className="form-select" onChange={this.changeTimeSelector("publishedMinute")} value={publishedMinute}>
                  {minutesOptions(1)}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCheckbox(stateKey: string, label: string, text: string): JSX.Element {
    const value = this.state[stateKey];
    return <>
      { label ? <label className="form-label" key={`label-${stateKey}`}>{label}</label> : null }
      <div className="form-check" key={`checkbox-${stateKey}`}>
        <label className="form-check-label">
          <input type="checkbox" className="form-check-input" checked={value || false} onChange={this.toggleCheckbox(stateKey)} />
          {text}
        </label>
      </div>
    </>;
  }

  render(): JSX.Element {
    const { blogArticle, isFetching, isSaving, withSidebar, shown, event } = this.props;

    if (!shown) {
      return <div className="blog-article-form"></div>;
    }

    const { title, website_path_slug, published, excerpt, thematic_ids, remove_illustration, website_path_slug_translations } = this.state;
    const imageUrl = blogArticle && blogArticle.illustration_medium;

    return <>
      <div className={`blog-article-form in ${withSidebar ? "" : "no-sidebar"}`}>
        <div className="sidebar-header">
          <p className="lead">
            { blogArticle &&
              <a onClick={ this.hideView } className="btn-back mr-5 text-center"><i className="fa-regular fa-angle-left"></i></a>
            }
            { this.viewTitle() }
          </p>
        </div>

        <div className="sidebar-body">
          <div className="card">
            <div className="card-body">
              <div className="mb-3">
                <label className="form-label">{ I18n.t("mongoid.attributes.blog_article.title") }</label>
                <input type="text" className="form-control" value={title} onChange={this.changeStandardField("title")} onBlur={this.prefillPathSlug} />
                { this.renderFieldError("title") }
              </div>

              <TranslatableInput
                label={I18n.t("react.website.website_path_slug")}
                value={website_path_slug || ""}
                error={this.fieldError("website_path_slug")}
                availableLocales={event.available_frontend_locales}
                translationsLabel={I18n.t("react.website.website_path_slug_translations")}
                translationsValues={website_path_slug_translations}
                translationErrors={this.fieldError("website_path_slug_translations")}
                onChange={(website_path_slug): void => this.setState({ website_path_slug })}
                help={I18n.t("slug_hint")}
                onTranslationChanged={(locale, value): void => {
                  this.setState({
                    website_path_slug_translations: { ...website_path_slug_translations, [locale]: value }
                  } as Pick<State, "website_path_slug_translations">);
                }}
              />

              <div className="mb-3">
                <label className="form-label">{ i18n("status") }</label>
                <select className="form-select" value={published.toString()} onChange={this.changeStandardField("published")}>
                  <option value="true">{ i18n("published") }</option>
                  <option value="false">{ i18n("not_published") }</option>
                </select>
              </div>

              { this.renderPublishedAt() }

              <div className="mb-3">
                <label className="form-label">{ I18n.t("mongoid.attributes.blog_article.thematics") }</label>
                <ThematicsDropdown
                  value={thematic_ids || []}
                  onChange={this.changeThematicsSelector}
                  allowCreate={true}
                  followHierarchyOnSelect={true}
                  coloredThematics={true}
                />
              </div>

              <div className="mb-3 d-flex flex-column">
                <label className="form-label">{ I18n.t("mongoid.attributes.blog_article.illustration") }</label>
                <div className="custom-file row">
                  <div className="input-group">
                    <div className="form-control" style={{ whiteSpace: "nowrap", overflow: "hidden" }}>{I18n.t("no_file_selected")}</div>
                    <div className="input-group-text">{I18n.t("choose_file")}</div>
                  </div>
                  <input type="file" accept="image/*" name="blog_article_illustration" id="blog_article_illustration" onChange={this.updateImage("illustration")} ref={(ref): any => this.illustrationInput = ref}/>
                </div>
                <div className="form-text">{i18n("illustration_help")}</div>
                { imageUrl && this.renderCheckbox("remove_illustration", null, i18n("remove_illustration")) }
                {
                  imageUrl && !remove_illustration &&
                  <p><img src={imageUrl} className="img-fluid" /></p>
                }
              </div>

              <div className="mb-3">
                <label className="form-label">{ I18n.t("mongoid.attributes.blog_article.excerpt") }</label>
                <textarea rows={4} className="form-control" value={excerpt || ""} onChange={this.changeStandardField("excerpt")}></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sidebar-footer">
        <Button variant="primary" onClick={this.saveBlogArticle} disabled={isFetching || isSaving}>
          { blogArticle ? i18n("save_article") : i18n("create_article") }
        </Button>
      </div>
    </>;
  }
}

export default BlogArticleForm;
