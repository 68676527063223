import { ActionTypes } from "../constants/Constants";

module.exports = {
  updateSelectedEmailActivity(filter, value) {
    return {
      type: ActionTypes.SET_SELECTED_EMAIL_ACTIVITY,
      filter,
      value
    };
  }
};
