import { ActionTypes } from "../constants/Constants";
import { ProgramsState, ProgramFilter, ProgramFiltersObject } from "../types/Program";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";

const defaultState: ProgramsState = {
  data: null,
  errors: null,
  isFetching: false,
  isFetchingMetadata: false,
  isSaving: false,
  lastAction: null,
  metadata: {}
};

function programs(state = defaultState, action: any): ProgramsState {
  switch (action.type) {

  case ActionTypes.FETCH_PROGRAMS_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.FETCH_PROGRAMS_SUCCESS: {
    const programs = action.data.map((program) => {
      program.program_filters = transformFiltersIntoObject(program.program_filters);
      return program;
    });
    return { ...state, data: programs, isFetching: false, errors: null };
  }
  case ActionTypes.CREATE_PROGRAM_REQUEST:
  case ActionTypes.UPDATE_PROGRAM_REQUEST:
  case ActionTypes.DELETE_PROGRAM_REQUEST:
    return { ...state, errors: null, isSaving: true, lastAction: null };
  case ActionTypes.CREATE_PROGRAM_SUCCESS: {
    const programs = insertOrUpdateObject(state.data, action.data, "_id");
    return { ...state, data: programs, errors: null, isSaving: false, lastAction: "create" };
  }
  case ActionTypes.UPDATE_PROGRAM_SUCCESS: {
    const program = { ...action.data };
    program.program_filters = transformFiltersIntoObject(program.program_filters);
    const programs = insertOrUpdateObject(state.data, program, "_id");
    return { ...state, data: programs, errors: null, isSaving: false, lastAction: "update" };
  }
  case ActionTypes.DELETE_PROGRAM_SUCCESS: {
    const programs = state.data.filter(item => item._id !== action.programId);
    return { ...state, data: programs, errors: null, isSaving: false, lastAction: "delete" };
  }
  case ActionTypes.CREATE_PROGRAM_FAILURE:
  case ActionTypes.UPDATE_PROGRAM_FAILURE:
  case ActionTypes.DELETE_PROGRAM_FAILURE:
    return { ...state, errors: action.data.errors, isSaving: false, lastAction: null };
  case ActionTypes.FETCH_FILTER_METADATA_REQUEST: {
    return { ...state, isFetchingMetadata: true, errors: null };
  }
  case ActionTypes.FETCH_FILTER_METADATA_SUCCESS:
    return { ...state, metadata: { ...state.metadata, ...action.data }, isFetchingMetadata: false };
  default:
    return state;
  }
}

// transform filters array into an object to make add/update/remove filters way simpler to manage
function transformFiltersIntoObject(programFilters: ProgramFilter[]): ProgramFiltersObject {
  return programFilters.reduce((acc, programFilter) => {
    const uniqKey = programFilter._type === "trait" ? `trait_${Math.floor(Math.random() * 1000)}` : programFilter._type;
    acc[uniqKey] = programFilter;

    return acc;
  }, {});
}

export default programs;
