import { Component } from "react";
import { pathToRegistrationForm, pathToGuestCategoryLiquidField } from "../utils/pathUtils.js";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CopyFieldsModal from "../containers/CopyFieldsModal.react";

const UNLIMITED = "unlimited";
const LIMITED = "limited";
const LIMITED_BY_FIELD_VALUE = "limited_by_field_value";
class SubFormByCategory extends Component {

  constructor(props) {
    super(props);
    ["updateOptions",
      "handleLimitChange",
      "handleGuestFieldLimitSelect"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      limitType: props.options.guestFieldLimitKey && props.options.guestFieldLimitKey != "" && LIMITED_BY_FIELD_VALUE || props.options.limit == -1 && UNLIMITED || LIMITED
    };
  }

  updateOptions(e) {
    const { updateHandler, defaultOptions, nonEmptyValueField, options } = this.props;
    if (nonEmptyValueField.includes(e.target.name) && e.target.value == "") {
      e.target.value = defaultOptions[e.target.name];
    }
    const value = e.target.type == "checkbox" ? e.target.checked : e.target.value;
    let changes = { [e.target.name]: value };
    if (e.target.name == "guestCategoryId") {
      changes["copyFieldsMapping"] = {}; // empty copy fields mapping because sub form may be different
      if (!this.isImportAllowed(value)) {
        changes["allowImport"] = false;
      }
    }
    const newOptions = Object.assign({}, options, changes);
    updateHandler(newOptions);
  }

  handleLimitChange(e) {
    const { updateHandler, options } = this.props;
    let newOptions = Object.assign({}, options);

    if (e.target.value == UNLIMITED) {
      newOptions.limit = -1;
      newOptions.guestFieldLimitKey = null;
      this.refs["limit"].value = -1;
    }
    if (e.target.value == LIMITED) {
      newOptions.guestFieldLimitKey = null;
    }
    this.setState({ limitType: e.target.value }, () => updateHandler(newOptions));
  }

  selectedGuestCategory(guestCategoryId) {
    const { guestCategories } = this.props;

    if (!guestCategoryId) {
      return;
    }

    return guestCategories.find(category => category._id == guestCategoryId);
  }

  isImportAllowed(guestCategoryId) {
    const selectedCategory = this.selectedGuestCategory(guestCategoryId);
    return selectedCategory && !selectedCategory.payment_enabled;
  }

  linkToRegistrationForm() {
    const { options } = this.props;
    const selectedCategory = this.selectedGuestCategory(options.guestCategoryId);

    if (!selectedCategory) return null;

    if (selectedCategory.registration_form_id) {
      return (<span>
        <a target="_blank" href={pathToRegistrationForm(selectedCategory.registration_form_id)}>{ I18n.t("react.form_items.subform.click_here") }</a>
        { " " } { I18n.t("react.form_items.subform.configure_category_form") } <strong>{ selectedCategory.name }</strong>.
      </span>);
    }

    return (<span>{ I18n.t("react.form_items.subform.no_form_for_category") } <strong>{ selectedCategory.name }</strong>. { " " }
      <a target="_blank" href={pathToGuestCategoryLiquidField(selectedCategory.id)}>{ I18n.t("react.form_items.subform.click_here") }</a>
      { " " } { I18n.t("react.form_items.subform.link_category_to_form") }.
    </span>);
  }

  renderTooltip(message) {
    return <Tooltip id="info">{message}</Tooltip>;
  }

  handleGuestFieldLimitSelect(e) {
    const { updateHandler, options } = this.props;
    updateHandler(Object.assign({}, options, { guestFieldLimitKey: e.target.value }));
  }

  renderGuestFieldLimitSelect() {
    const { guestFields, options } = this.props;
    const { limitType } = this.state;

    if (limitType !== LIMITED_BY_FIELD_VALUE) return null;

    const guestFieldOptions = guestFields.map(field => {
      return <option key={field.key} value={field.key}> { field.label } ({ field.key })</option>;
    });

    return <div className="col-12">
      <select className="form-select" value={options.guestFieldLimitKey || ""} onChange={this.handleGuestFieldLimitSelect}>
        <option value="" key="default" >{ I18n.t("please_choose") }</option>
        { guestFieldOptions }
      </select>
    </div>;
  }

  calcLimitType(props) {
    const { guestFieldLimitKey, limit } = props.options;
    return guestFieldLimitKey && guestFieldLimitKey != "" && LIMITED_BY_FIELD_VALUE || limit == -1 && UNLIMITED || LIMITED;
  }

  renderLimit() {
    const { options, guestCategoryId } = this.props;
    const { limitType } = this.state;

    const limitOptions = [UNLIMITED, LIMITED, LIMITED_BY_FIELD_VALUE].map(limitOption => {
      const tooltip = <Tooltip id="destroy-tooltip">{I18n.t(`react.form_items.subform.${limitOption}_tooltip`)}</Tooltip>;
      return <div className="col-auto" key={limitOption}>
        <OverlayTrigger placement="top" overlay={tooltip}>
          <div className="form-check">
            <label className="form-check-label">
              <input type="radio" className="form-check-input" name={`limit-type-${guestCategoryId}`} value={limitOption} defaultChecked={limitType == limitOption} onClick={this.handleLimitChange} />
              { " " }
              { I18n.t(`react.form_items.subform.${limitOption}`) }
            </label>
          </div>
        </OverlayTrigger>
      </div>;
    });

    return <div className="mb-3">
      <label className="form-label">{I18n.t("react.form_items.subform.limit_label")}</label>
      <div className="mb-3 row g-3 align-items-center">
        { limitOptions }
        <div className="col-auto">
          { this.renderGuestFieldLimitSelect() }
          <div style={{ display: limitType !== LIMITED_BY_FIELD_VALUE ? "block" : "none" }}>
            <input type="number" defaultValue={options.limit} className="form-control" name="limit" ref="limit" disabled={limitType == UNLIMITED} onBlur={this.updateOptions} />
          </div>
        </div>
      </div>
    </div>;
  }

  render() {
    const { guestCategories, options, updateHandler, parentFormItems } = this.props;
    const ticketSubform = options.ticket === true;

    return (<div key={guestCategories && guestCategories.map(gc => gc._id).join(",") || "no_category"}>
      <div className="mb-3 row">
        <div className="col-md-4">
          <select className="form-select" disabled={ticketSubform} name="guestCategoryId" defaultValue={options.guestCategoryId} onChange={this.updateOptions}>
            <option value="">{ I18n.t("react.form_items.subform.select_category") }</option>
            { guestCategories.filter(category => category.registration_form_id || !category.custom_registration_form_enabled).map(category => {
              return (<option value={category.id} key={category.id}>{category.name}</option>);
            })}
          </select>
        </div>
        <div className="col-sm-12">
          <div className="form-text">
            { I18n.t("react.form_items.subform.select_category_help") }<br />
            { I18n.t("react.form_items.subform.select_category_help2") }<br />
            { this.linkToRegistrationForm() }
          </div>
        </div>
      </div>

      { ticketSubform ? null : (
        <div className="mb-3">
          <div className="mb-3 row">
            <div className="col-sm-6">
              <div className="input-group">
                <div className="btn btn-secondary">
                  <OverlayTrigger placement="top" overlay={this.renderTooltip(I18n.t("react.form_items.subform.enable_add_button"))}>
                    <input type="checkbox" className="form-check-input" name="add" checked={options.add.toString() === "true"} onChange={this.updateOptions} />
                  </OverlayTrigger>
                </div>
                <input type="text" className="form-control" name="addLabel" defaultValue={options.addLabel} placeholder={I18n.t("react.form_items.subform.add_button_placeholder")} onBlur={this.updateOptions} />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="input-group">
                <div className="btn btn-secondary">
                  <OverlayTrigger placement="top" overlay={this.renderTooltip(I18n.t("react.form_items.subform.enable_remove_button"))}>
                    <input type="checkbox" className="form-check-input" name="remove" checked={options.remove.toString() === "true"} onChange={this.updateOptions} />
                  </OverlayTrigger>
                </div>
                <input type="text" className="form-control" name="removeLabel" defaultValue={options.removeLabel} placeholder={I18n.t("react.form_items.subform.remove_button_placeholder")} onBlur={this.updateOptions} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="mb-3">
                <label className="form-label">{I18n.t("react.form_items.subform.nb_default_label")}</label>
                <div className="row">
                  <div className="col-sm-4">
                    <input type="number" defaultValue={options.defaultNb} className="form-control" name="defaultNb" onBlur={this.updateOptions} />
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div className="input-group">
                  <div className="btn btn-secondary">
                    <OverlayTrigger placement="top" overlay={this.renderTooltip(I18n.t("react.form_items.subform.allow_import"))}>
                      <input type="checkbox" className="form-check-input" name="allowImport" checked={options.allowImport.toString() === "true"} onChange={this.updateOptions} disabled={!this.isImportAllowed(options.guestCategoryId)} />
                    </OverlayTrigger>
                  </div>
                  <input type="text" className="form-control" name="importLabel" defaultValue={options.importLabel} placeholder={I18n.t("react.form_items.subform.import_button_placeholder")} onBlur={this.updateOptions} disabled={!this.isImportAllowed(options.guestCategoryId)} />
                </div>
                <div className="form-text ">
                  { I18n.t("react.form_items.subform.allow_import_help") }
                </div>
              </div>

              { options.allowImport &&
                <div className="mb-3">
                  <input type="text" defaultValue={options.importedListLabel} className="form-control" name="importedListLabel" placeholder={I18n.t("react.form_items.subform.imported_list_placeholder")} onBlur={this.updateOptions} disabled={!this.isImportAllowed(options.guestCategoryId)} />
                </div>
              }
            </div>

            <div className="col-sm-6">
              { this.renderLimit() }

              <div className="mb-3">
                <div className="form-check">
                  <label className="form-check-label">
                    <input type="checkbox" className="form-check-input" name="inline" checked={options.inline.toString() === "true"} onChange={this.updateOptions} />
                    { I18n.t("react.form_items.subform.inline") }
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <CopyFieldsModal subFormOptions={options} updateOptions={updateHandler} parentFormItems={parentFormItems} />
    </div>);
  }
}

SubFormByCategory.defaultProps = {
  nonEmptyValueField: ["defaultNb", "limit"]
};

export default SubFormByCategory;
