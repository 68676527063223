import { useState, useEffect } from "react";
import OFFLINE_COLOR from "../../constants/Constants";

interface Props {
  title: string;
  displayed: boolean;
  titleCentered?: true;
  faIcon?: string;
  warningText?: string
}

const CollapsablePanel: React.FC<Props> = ({
  title,
  children,
  displayed,
  faIcon,
  titleCentered,
  warningText,
}) => {
  const [collapsed, setCollapsed] = useState(displayed);

  useEffect(() => {
    setCollapsed(displayed);
  }, [displayed]);

  const style = {
    cursor: "pointer",
    borderBottom: collapsed ? null : "1px solid transparent",
    borderBottomRightRadius: collapsed ? "0px" : "3px",
    borderBottomLeftRadius: collapsed ? "0px" : "3px"
  };
  const caretDirection = collapsed ? "up" : "down";
  const panelTitle = faIcon ? <><i className={`mr-5 fa-regular fa-${faIcon}`}></i>{title}</> : title;

  const renderWarningText = (): JSX.Element => {
    if (!warningText) return null;

    return <div className="float-end" style={{ color: OFFLINE_COLOR }}>
      {warningText}
      <i className="fa-regular fa-lg fa-exclamation-circle text-warning ml-5"></i>
    </div>;
  };

  return <div className="card" style={{ marginBottom: "10px" }}>
    <div className={`card-header ${titleCentered ? "text-center" : ""}`} style={style} onClick={(): void => setCollapsed(!collapsed)}>
      <h4 className="card-title">
        {panelTitle}<i className={`ml-5 fa-regular fa-angle-${caretDirection}`} style={{ verticalAlign: "middle" }}></i>
        {renderWarningText()}
      </h4>
    </div>
    {collapsed && <div className="card-body">
      {children}
    </div>}
  </div>;
};

export default CollapsablePanel;
