import { isEnabled } from "../../utils/featureSetUtils";
import { MULTILINGUAL } from "../../constants/FeaturesSet";
import FrontendLocales from "../../../config/frontend_locales.yml";
import TranslationsSelect from "./TranslationsSelect.react";
import { Event } from "../../types/Event";

function i18n(key, opts = {}): string {
  return I18n.t(`react.translations.${key}`, opts);
}

interface Props {
  match: any;
  event: Event;

  updateEvent: (id: string, params: any, redirectTo: string|null, notificationsOptions: any) => void;
}

const TranslationsHeader: React.FC<Props> = ({ match, event, updateEvent }) => {
  if (!isEnabled(MULTILINGUAL)) return null;
  if (!event) return null;

  const locale = FrontendLocales.find(locale => event.locale === locale.code);
  return <div className="row">
    <div className="col-md-12">
      <div className="header-page">
        <div className="header-page-title">
          <h1>{ i18n("main_title") }</h1>
          <div>
            <span>{ i18n("translations_info", { language: locale[match.params.locale], flag: locale.flag }) }</span>
          </div>
        </div>
      </div>
    </div>

    <div className="col-md-12">
      <TranslationsSelect event={event} updateEvent={updateEvent} frontendLocales={FrontendLocales} />
    </div>
  </div>;
};

export default TranslationsHeader;
