import {
  ActionTypes,
  DEFAULT_COLUMNS_SETTING
} from "../constants/Constants";

const defaultState = {
  guests_columns: DEFAULT_COLUMNS_SETTING,
  guest_export_columns: []
};

function userSettings(state = defaultState, action) {
  switch (action.type) {
  case ActionTypes.USER_SETTING_CREATE_SUCCESS:
  case ActionTypes.USER_SETTING_SUCCESS:
    return stateWithDefaultGuestsColumnsIfEmpty(action.data);
  case ActionTypes.USER_SETTING_CREATE_FAILURE:
    return Object.assign({}, state, action.data);
  case ActionTypes.USER_SETTING_FAILURE:
    return defaultState;
  default:
    return state;
  }
}

function stateWithDefaultGuestsColumnsIfEmpty(state) {
  if (state.guests_columns.length === 0) {
    return Object.assign({}, state, { guests_columns: defaultState.guests_columns });
  }
  return state;
}

export default userSettings;
