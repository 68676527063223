import { Component } from "react";
import { connect } from "react-redux";
import DuplicationRunning from "../../components/DuplicationRunning.react";
import { DuplicationOperation } from "../../types/DuplicationOperation";
import { fetchDuplicationOperation } from "../../actions/DuplicationOperationActionCreator";

const WATCH_INTERVAL = 5000;

interface Props {
  duplicatedEventId?: string;
  duplicationOperationId: string;
  match: any;
  duplicationOperation: DuplicationOperation;
  pendingRequest: boolean;
  fetched: boolean;
  warnings?: string[];
  errors?: string[];
  fetchDuplicationOperation(accountId: string, duplicationOperationId: string): any;
}

export class EventDuplicationRunningModal extends Component<Props> {
  timeout?: number;

  constructor(props) {
    super(props);
    this.timeout = null;
  }

  componentDidMount(): void {
    this.watchDuplicationProgress();
  }

  watchDuplicationProgress(): void {
    clearTimeout(this.timeout);
    const { fetchDuplicationOperation, duplicationOperationId, pendingRequest, match } = this.props;

    setTimeout(() => {
      if (this.isDuplicationOver()) return;

      if (duplicationOperationId && !pendingRequest) {
        fetchDuplicationOperation(match.params.account_id, duplicationOperationId);
      }

      this.watchDuplicationProgress();
    }, WATCH_INTERVAL);
  }

  isDuplicationOver(): boolean {
    const { duplicationOperation, fetched, errors } = this.props;

    return fetched && ["success", "error"].includes(duplicationOperation.status) || !!errors;
  }

  render(): JSX.Element {
    const { duplicationOperation, warnings, errors } = this.props;

    return <DuplicationRunning
      model="events"
      currentEventId={null}
      targetEventId={duplicationOperation && duplicationOperation.target_object_id}
      duplicatedData={duplicationOperation}
      pendingRequest={!this.isDuplicationOver()}
      keepDuplicateOnError={true}
      duplicationWarnings={warnings}
      duplicationErrors= {errors}
    />;
  }
}

function mapStateToProps(state: any): Pick<Props, "duplicationOperation" | "warnings" | "pendingRequest" | "fetched" | "errors"> {
  return {
    duplicationOperation: state.duplicationOperation.data,
    warnings: state.duplicationOperation.warnings,
    errors: state.duplicationOperation.errors,
    pendingRequest: state.duplicationOperation.pendingRequest,
    fetched: state.duplicationOperation.fetched
  };
}

const mapDispatchToProps = {
  fetchDuplicationOperation
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDuplicationRunningModal);
