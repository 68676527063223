import { useState } from "react";
import { Button } from "react-bootstrap";
import { BadgeImage } from "../../../types/BadgeTemplate";

interface Props {
  imageSelected: BadgeImage;
  newBackgroundKey: string;
  imageFormatSettings: any;
  onChangeImage(badgeImage: BadgeImage): void;
  onDeleteImage(position: string): void;
}

function i18n(key, opts = {}): string {
  return I18n.t(`react.badge_builder.properties_panel.${key}`, opts);
}

const ImageProperties: React.FC<Props> = ({
  imageSelected,
  newBackgroundKey,
  imageFormatSettings,
  onChangeImage,
  onDeleteImage
}) => {
  const [contentValue, setContentValue] = useState("");
  const [activeImageButtonId, setActiveImageButtonId] = useState<"image-uploader" | "guest-field-text">("image-uploader");

  const handleActiveButton = (e): void => {
    const id = e.target.getAttribute("id");
    setActiveImageButtonId(id);
  };

  const handleInputChange = () => (e): any => {
    const img = new Image();
    img.src = window.URL.createObjectURL(e.target.files[0]);
    img.onload = (): void => {
      onChangeImage({
        position: e.target.name,
        url: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
        width: img.naturalWidth.toString(),
        height: img.naturalHeight.toString()
      });
    };
  };

  const renderButton = (id: string, label: string, icon?: string): JSX.Element => {
    return <Button
      id={id}
      variant={`secondary ${activeImageButtonId === id && "active"}`}
      onClick={(e): void => handleActiveButton(e)}>
      <i className={`fa-regular fa-${icon || label}`} aria-hidden="true" /> {i18n((label))}
    </Button>;
  };

  const renderRecommendedSize = (): JSX.Element => {
    const recommandedValues = Object.values(imageFormatSettings)[0]["versions"]["medium"]["resize_and_pad"];
    const width = recommandedValues[0];
    const height = recommandedValues[1];

    return <small className="form-text text-muted">{width}x{height} ({i18n("recommended")})</small>;
  };

  const renderInputContent = (): JSX.Element => {
    if (activeImageButtonId === "image-uploader") return null;

    return <div>
      <input
        type="text"
        id="contentValue"
        className="form-control"
        value={contentValue}
        onChange={(e): void => setContentValue(e.target.value)}
      />
    </div>;
  };

  const renderInputFile = (): JSX.Element => {
    return <div className="custom-file row">
      <div className="input-group image-uploader">
        <div className="form-control">{I18n.t("no_file_selected")}</div>
        <div className="input-group-text">{I18n.t("choose_file")}</div>
      </div>
      <input
        type="file"
        accept="image/*"
        name={newBackgroundKey}
        onChange={handleInputChange()}
      />
      {renderRecommendedSize()}
    </div>;
  };

  const renderImagePreview = (): JSX.Element => {
    return <div className="text-center">
      <img src={imageSelected?.url || imageSelected?.image.small.url} style={{ maxWidth: "100%" }}/>
      <div className="mt-10">
        <Button variant="secondary btn-sm" onClick={(): void => onDeleteImage(imageSelected.position)}>
          {i18n("delete_image")}
        </Button>
      </div>
    </div>;
  };

  const renderImageUploader = (): JSX.Element => {
    if (activeImageButtonId !== "image-uploader") return null;

    if (imageSelected) {
      return renderImagePreview();
    } else {
      return renderInputFile();
    }
  };

  const renderNavTab = (): JSX.Element => {
    if (imageSelected) return null;

    return <div className="btn-switch d-flex gap-2">
      {renderButton("image-uploader", "image")}
      {renderButton("guest-field-image", "field", "i")}
    </div>;
  };

  return <>
    {renderNavTab()}
    {renderImageUploader()}
    {renderInputContent()}
  </>;
};

export default ImageProperties;
