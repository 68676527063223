import { useState } from "react";
import classNames from "classnames";
import { components, Option, GroupedOption, GroupProps, OptionProps, SingleValueProps } from "react-select";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { useSelector } from "react-redux";
import { EVENT_DEFAULT_SECONDARY_COLOR, UI_COLORS, WORKFLOW_STEP_TRIGGER, WORKFLOW_STEP_ACTION, WORKFLOW_STEP_FILTER } from "../../constants/Constants";
import { WorkflowStep } from "../../types/Workflow";
import { checkPathExists, extractPath } from "../../utils/workflowUtils";

const Group = (props: GroupProps): JSX.Element => {
  const groupClassNames = classNames({
    "border": true,
    "border-2": true,
    "border-primary": props.data.selectedStepId === props.data.workflowStepId,
    "border-white": props.data.selectedStepId !== props.data.workflowStepId,
    "rounded": true,
    "m-1": true
  });
  return <div className={groupClassNames}>
    <components.Group {...props} />
  </div>;
};

const renderNestedOptions = (props: OptionProps, data: any, nestedOptions: Option[]): JSX.Element => {
  const { getStyles, innerProps, selectOption } = props;
  const { label, selectedStepId, workflowStepId, level } = data;
  const paddingLeft = `${(level - 1) * 12}px`;

  return (
    <div className="nested-optgroup" style={{ display: selectedStepId === workflowStepId ? "block" : "none", paddingLeft }} key={data.path}>
      <div className="nested-optgroup-label pt-2" style={getStyles("groupHeading", props)}>{i18n(label, { default: label })}</div>
      {nestedOptions.map((nestedOption: Option, index: number) => {
        if (nestedOption.options) {
          return renderNestedOptions(props, nestedOption, nestedOption.options);
        }

        const newProps = { ...props, innerProps: { ...innerProps,
          id: `${innerProps.id}-${index}`,
          onClick: (): void => selectOption(nestedOption)
        } };

        return <div className="nested-optgroup-option" key={nestedOption.path}>
          <components.Option {...newProps} className="px-4">
            <strong>{nestedOption.value}</strong>{ " " }<small className="text-nowrap">{String(nestedOption.label)}</small>
          </components.Option>
        </div>;
      })}
    </div>
  );
};

const FieldOption = (props: OptionProps): JSX.Element => {
  const { data } = props;

  if (data.__isNew__) {
    return <components.Option {...props} />;
  }

  const nestedOptions = data.options;

  if (nestedOptions) {
    return renderNestedOptions(props, data, nestedOptions);
  }

  return <div style={{ display: data.selectedStepId === data.workflowStepId ? "block" : "none" }}>
    <components.Option {...props}>
      <strong>{data.value}</strong>{ " " }<small className="text-nowrap">{String(data.label)}</small>
    </components.Option>
  </div>;
};

const SingleValue = (props: SingleValueProps): JSX.Element => {
  const { data } = props;
  const { value, path, label, invalid } = data;

  if (path) {
    return <components.SingleValue {...props}>
      <span className={`badge rounded-pill ${invalid ? "bg-danger" : "bg-secondary"}`}>
        {label}
      </span>
    </components.SingleValue>;
  }

  return <components.SingleValue {...props}>
    {value}
  </components.SingleValue>;
};

const customStyles = {
  groupHeading: (base): any => ({
    ...base,
    color: EVENT_DEFAULT_SECONDARY_COLOR,
    fontSize: "0.9em"
  }),
  option: (base): any => ({
    ...base,
    paddingTop: "3px",
    paddingBottom: "3px"
  }),
  placeholder: (base): any => ({
    ...base,
    color: `${UI_COLORS.grey500} !important`,
  }),
};

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.workflows.context_selector.${key}`, opts);
}

interface Props {
  workflowStep: WorkflowStep;
  settingValue: string;
  creatable?: boolean;
  isDisabled?: boolean;
  onChange(inputValue: any): void;
}

const ContextSelector: React.FC<Props> = ({ workflowStep, settingValue, creatable, isDisabled, onChange }) => {
  const [selectedStepId, setSelectedStepId] = useState(null);
  const orderedSteps = useSelector((state: any) => state.workflowVersionBuilder.orderedSteps);
  const context = useSelector((state: any) => state.workflowVersionBuilder.context);

  const filteredSteps = (): WorkflowStep[] => {
    let before = true;
    return orderedSteps.filter((step: WorkflowStep) => {
      if (step.type === WORKFLOW_STEP_FILTER && step._id !== workflowStep._id) return false;

      if (step._id === workflowStep._id) {
        before = false;
      }
      return before;
    });
  };

  const toggleStepContext = (workflowStep: WorkflowStep): (() => void) => {
    return (): void => {
      if (selectedStepId === workflowStep._id) {
        setSelectedStepId(null);
      } else {
        setSelectedStepId(workflowStep._id);
      }
    };
  };

  const onSelectField = (selectedOption: Option): void => {
    if (!selectedOption) {
      onChange(null);
    } else if (selectedOption.__isNew__) {
      onChange(selectedOption.value);
    } else {
      onChange(`{{${selectedOption.path}}}`);
    }
  };

  const isOptionSelected = (option: Option): boolean => {
    if (!settingValue) {
      return false;
    }

    const path = extractPath(settingValue);

    return path && path === option.path;
  };

  const formatGroupLabel = (data: GroupedOption): JSX.Element => {
    return <div onClick={data.toggleGroup} style={{ cursor: "pointer" }}>
      {data.label}
    </div>;
  };

  const formatSettingValue = (): any => {
    if (!settingValue) {
      return null;
    }

    const path = extractPath(settingValue);

    if (path) {
      const [workflowStepId, ...rest] = path.split(".");
      const workflowStep = orderedSteps.find((workflowStep: WorkflowStep) => workflowStep._id == workflowStepId);
      const workflowStepRank = workflowStep?.rank;
      const invalid = !checkPathExists(path, context);
      const label = `${workflowStepRank}.${rest.join(".")}`;

      return { path, label, invalid };
    }

    return { value: settingValue };
  };

  const stepStrategyLabel = (workflowStep: WorkflowStep): string => {
    if (workflowStep.type === WORKFLOW_STEP_TRIGGER) {
      return I18n.t(`automation.triggers.full.${workflowStep.strategy}`);
    } else if (workflowStep.type === WORKFLOW_STEP_ACTION) {
      return I18n.t(`automation.actions.${workflowStep.strategy}`);
    }
  };

  const groupLabel = (step): JSX.Element => {
    const groupHeadingClassNames = classNames({
      "fa-regular": true,
      "fa-chevron-up": selectedStepId === step._id,
      "fa-chevron-down": selectedStepId !== step._id,
    });

    return <span className="d-flex align-items-center justify-content-between">
      {step.rank + ". " + stepStrategyLabel(step)}
      <i className={groupHeadingClassNames} />
    </span>;
  };

  const optionsForGroup = (options: any, workflowStepId: string, currentPath: string, level: number): any => {
    if (!options) return [];

    return Object.entries(options).reduce((acc, [key, value]) => {
      if (value instanceof Array) return acc;

      const path = `${currentPath}.${key}`;

      if (value && typeof value === "object") {
        acc.push({
          label: key,
          options: optionsForGroup(value, workflowStepId, path, level + 1),
          workflowStepId,
          path,
          selectedStepId,
          level
        });
      } else {
        acc.push({ value: key, label: value, workflowStepId, path, selectedStepId });
      }

      return acc;
    }, []);
  };

  const groupedOptions = (): any => {
    return filteredSteps().map((step: WorkflowStep) => {
      return {
        label: groupLabel(step),
        options: optionsForGroup(context[step._id], step._id, step._id, 1),
        toggleGroup: toggleStepContext(step),
        workflowStepId: step._id,
        selectedStepId
      };
    });
  };

  const reactSelectProps = {
    blurInputOnSelect: true,
    className: "react-select",
    classNamePrefix: "react-select",
    closeMenuOnSelect: true,
    components: {
      Group,
      Option: FieldOption,
      SingleValue,
      DropdownIndicator: (): JSX.Element => null,
      ClearIndicator: (): JSX.Element => null
    },
    createOptionPosition: "first",
    formatCreateLabel: (label: string): string => i18n("create_option", { label: label }),
    formatGroupLabel,
    isClearable: true,
    isDisabled,
    isOptionSelected,
    noOptionsMessage: (): string => i18n("no_options"),
    onChange: onSelectField,
    options: groupedOptions(),
    placeholder: creatable ? i18n("creatable_placeholder") : i18n("select_placeholder"),
    styles: customStyles,
    value: formatSettingValue()
  };

  if (creatable) {
    return <CreatableSelect {...reactSelectProps} />;
  }

  return <Select {...reactSelectProps} />;
};

export default ContextSelector;
