import { ColumnsSet } from "../../types/ColumnsSet";

const SCOPE_COLUMNS_SET = "columns_set";
const SCOPE_NOTHING = "nothing";
const SCOPES = [
  SCOPE_COLUMNS_SET,
  SCOPE_NOTHING
];

type RuleScope = "columns_set"|"nothing";
export type FeatureScope = "mobile_app_profile";

export type Rule = {
  scope: RuleScope,
  columns_set_id?: string;
}

interface Props {
  featureRule?: Rule;
  featureScope: FeatureScope;
  columnsSets: ColumnsSet[];
  population: string;
  objectName: string

  onChange: (rule: Rule) => void;
  onClickColumnsSet?(columnsSetId: string): void;
}

const EMPTY_VALUE = "please-choose";

const i18n = (key: string, opts: any = {}): string => {
  return I18n.t(`react.guest_view_rules.rule.${key}`, opts);
};

const Rule: React.FC<Props> = ({
  featureRule,
  featureScope,
  columnsSets,
  population,
  objectName,
  onChange,
  onClickColumnsSet
}) => {
  const rule = featureRule || { scope: SCOPE_NOTHING };

  const onChangeHandler = (key: keyof Rule): ((e: React.ChangeEvent<HTMLSelectElement>) => void) => {
    return (e: React.ChangeEvent<HTMLSelectElement>): void => {
      const { value } = e.target;

      if (key === "scope" && value === SCOPE_NOTHING) { // reset columns_set_id
        return onChange({ ...rule, [key]: value, columns_set_id: null });
      }

      onChange({
        ...rule,
        [key]: value === EMPTY_VALUE ? null : value
      });
    };
  };

  const onClickColumnsSetHandler = (): void => {
    if (!onClickColumnsSet || !rule.columns_set_id) return;

    onClickColumnsSet(rule.columns_set_id);
  };

  const renderFeatureLabel = (): JSX.Element => {
    switch (featureScope) {
    case "mobile_app_profile": return <div className="col-auto">{ i18n("mobile_app_profile", { count: rule.scope === SCOPE_NOTHING ? 0 : 2 }) }</div>;
    }
  };

  const RuleScopeSelect = (): JSX.Element => {
    return <div className="col-auto">
      <select className="form-select mr-5" value={rule.scope} onChange={onChangeHandler("scope")}>
        {SCOPES.map(scope => {
          return <option key={scope} value={scope}>{i18n(scope)}</option>;
        })}
      </select>
    </div>;
  };

  const emptyOption = <option key={EMPTY_VALUE} value={EMPTY_VALUE}>{i18n("choose")}</option>;

  const RuleColumnsSetSelect = (): JSX.Element => {
    return <div className="col-auto">
      <div className={`${onClickColumnsSet ? "input-group" : "mb-3"} ${!rule.columns_set_id ? "has-error" : ""}`}>
        <select className="form-select" value={rule.columns_set_id || EMPTY_VALUE} onChange={onChangeHandler("columns_set_id")}>
          {[emptyOption].concat(
            columnsSets.map(columnSet => {
              return <option key={columnSet._id} value={columnSet._id}>{columnSet.name}</option>;
            })
          )}
        </select>
        { onClickColumnsSet &&
          <div className="btn btn-secondary" onClick={onClickColumnsSetHandler} style={{ cursor: "pointer" }}>
            <i className="fa-regular fa-eye" />
          </div>
        }
      </div>
    </div>;
  };

  const renderHiddenInputs = (): JSX.Element => {
    return <>
      <input type="hidden" name={`${objectName}[guest_view_rules][${population}][${featureScope}][scope]`} value={rule.scope} />
      <input type="hidden" name={`${objectName}[guest_view_rules][${population}][${featureScope}][columns_set_id]`} value={rule.columns_set_id || ""} />
    </>;
  };

  return <div className="row g-2 align-items-center ml-20">
    <RuleScopeSelect />
    {rule.scope === SCOPE_COLUMNS_SET && <RuleColumnsSetSelect />}
    {renderFeatureLabel()}
    {renderHiddenInputs()}
  </div>;
};

export default Rule;
