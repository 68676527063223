import { Component } from "react";
import { connect } from "react-redux";
import { requestEvent } from "../actions/ImportActionCreators";
import { Dropdown } from "react-bootstrap";
import { pathToWebsiteChangeDomainName, pathToWebsiteConfig, pathToWebsiteConfigurePwa, pathToWebsiteSeo,
  pathToWebsiteGdpr, pathToWebsiteConsentNotice, pathToWebsiteWebPushNotifications, urlEventId, pathToHelpRegistrationAfterSignin } from "../utils/pathUtils";
import { fetchWebsite, duplicateWebsite, disableWebsite } from "../actions/WebsiteActionCreators";
import TargetEventModal from "../components/duplication_modals/TargetEventModal.react";
import ConfirmationModal from "../components/shared/ConfirmationModal.react";
import DuplicationRunningModal from "../components/duplication_modals/DuplicationRunningModal.react";
import { isEnabled } from "../utils/featureSetUtils";
import { SEO, WEB_PUSH, PROGRESSIVE_WEB_APP } from "../constants/FeaturesSet";

class WebsiteDropdownButton extends Component {
  constructor(props) {
    super(props);
    [
      "displayDuplicationModal",
      "onDuplicationConfirmed",
      "onEventSelected",
      "disableWebsite"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = {
      showEventPickerModal: false,
      showDuplicationRunningModal: false,
      duplicateTargetEventId: null
    };
  }

  componentDidMount() {
    const { requestEvent, fetchWebsite } = this.props;
    requestEvent();
    fetchWebsite(urlEventId());
  }

  closeWebsiteErrorModal() {
    this.setState({ showDiplayErrorWebsiteModal: false });
  }

  onEventSelected(targetEventId) {
    this.setState({
      showEventPickerModal: false,
      showDuplicationRunningModal: false,
      showConfirmationModal: true,
      duplicateTargetEventId: targetEventId
    });
  }

  onDuplicationConfirmed() {
    const { duplicateWebsite } = this.props;
    const { duplicateFrom, duplicateTargetEventId } = this.state;

    this.setState({
      showEventPickerModal: false,
      showDuplicationRunningModal: true,
      showConfirmationModal: false
    });

    if (duplicateFrom) duplicateWebsite(duplicateTargetEventId, urlEventId());
    else duplicateWebsite(urlEventId(), duplicateTargetEventId);
  }

  displayDuplicationModal(duplicateFrom = false) {
    return () => {
      this.setState({ showEventPickerModal: true, duplicateFrom });
    };
  }

  disableWebsite() {
    const { disableWebsite } = this.props;

    if (confirm(I18n.t("websites.configuration.r_u_sure")))
      disableWebsite(urlEventId(), pathToWebsiteConfig());
  }

  getEvent(type = "source") {
    const { event, events } = this.props;
    const { duplicateTargetEventId, duplicateFrom } = this.state;

    if (duplicateFrom ? type === "target" : type === "source") return event || {};

    return events && events.find(ev => ev._id == duplicateTargetEventId) || {};
  }

  renderDuplicationModals() {
    const { event, duplicatedWebsite, duplicationErrors } = this.props;
    const { showConfirmationModal, showEventPickerModal, showDuplicationRunningModal, duplicateTargetEventId, duplicateFrom } = this.state;
    const action = duplicateFrom ? "import" : "export";

    return (
      <div>
        <TargetEventModal
          isVisible={showEventPickerModal}
          onClose={() => { this.setState({ showEventPickerModal: false }); }}
          currentEvent={event}
          model={true}
          title={ I18n.t(`websites.configuration.select_event_modal_title_${ action}`) }
          onEventSelected={this.onEventSelected}/>

        <ConfirmationModal
          isVisible={showConfirmationModal}
          onClose={() => { this.setState({ showConfirmationModal: false }); }}
          onConfirm={this.onDuplicationConfirmed}
          title={ I18n.t(`websites.configuration.confirmation_${ action }_modal_title`, { event_title: this.getEvent().title }) }
          confirmationQuestion={ I18n.t("websites.configuration.are_you_sure_to_continue_erasing_website", { event_title: this.getEvent("target").title })}
        />

        <DuplicationRunningModal
          isVisible={showDuplicationRunningModal}
          onClose={() => { this.setState({ showDuplicationRunningModal: false }); }}
          model="website"
          currentEventId={event._id}
          targetEventId={duplicateFrom ? null : duplicateTargetEventId}
          duplicatedData={duplicatedWebsite}
          duplicationErrors={duplicationErrors}
        />
      </div>
    );
  }

  render() {
    const { website } = this.props;

    return (
      <Dropdown id="WebSiteDropdownActions">
        <Dropdown.Toggle variant="secondary"><i className="fa-regular fa-gear"></i> {I18n.t("settings")}{" "}</Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item eventKey="1" href={pathToWebsiteChangeDomainName()}>
            <i className="fa-regular fa-window fa-fw"></i> {I18n.t("websites.configuration.configure_website")}
          </Dropdown.Item>
          { isEnabled(WEB_PUSH) &&
            <Dropdown.Item eventKey="2" href={pathToWebsiteWebPushNotifications()}>
              <i className="fa-regular fa-bell fa-fw"></i> {I18n.t("websites.configuration.web_push_notifications")}
            </Dropdown.Item>
          }
          { isEnabled(PROGRESSIVE_WEB_APP) &&
            <Dropdown.Item eventKey="3" href={pathToWebsiteConfigurePwa()}>
              <i className="fa-regular fa-mobile fa-fw"></i> {I18n.t("react.pwa.item_menu")}
            </Dropdown.Item>
          }
          { isEnabled(SEO) &&
            <Dropdown.Item eventKey="4" href={pathToWebsiteSeo()} >
              <i className="fa-regular fa-magnifying-glass fa-fw"></i> {I18n.t("websites.configuration.seo")}
            </Dropdown.Item>
          }
          { website && website.can_use_old_cookie_banner ? (
            <Dropdown.Item eventKey="5" href={pathToWebsiteGdpr()} >
              <i className="fa-regular fa-shield-check fa-fw"></i> {I18n.t("websites.configuration.gdpr")}
            </Dropdown.Item>
          ) : (
            <Dropdown.Item eventKey="5.1" href={pathToWebsiteConsentNotice()} >
              <i className="fa-regular fa-shield-check fa-fw"></i> {I18n.t("websites.configuration.consent_notice")}
            </Dropdown.Item>
          )}

          <Dropdown.Item eventKey="6" href={pathToHelpRegistrationAfterSignin()} >
            <i className="fa-regular fa-file-lines fa-fw"></i> {I18n.t("websites.configuration.help_registration_after_signin")}
          </Dropdown.Item>
          <Dropdown.Item eventKey="7" onClick={this.displayDuplicationModal(true)} >
            <i className="fa-regular fa-download fa-fw"></i> {I18n.t("websites.configuration.import_from")}
          </Dropdown.Item>
          <Dropdown.Item eventKey="8" onClick={this.displayDuplicationModal()} >
            <i className="fa-regular fa-upload fa-fw"></i> {I18n.t("websites.configuration.duplicate")}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="9" onClick={this.disableWebsite} >
            <i className="fa-regular fa-circle-xmark fa-fw"></i> {I18n.t("websites.configuration.disable_website")}
          </Dropdown.Item>
          {this.renderDuplicationModals()}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

function mapStateToProps(state) {
  return {
    errors: state.website.errors,
    website: state.website.data,
    events: state.events.data,
    event: state.event,
    duplicatedWebsite: state.website.duplicated,
    duplicationErrors: state.website.duplicationErrors
  };
}

const mapDispatchToProps = {
  requestEvent,
  duplicateWebsite,
  fetchWebsite,
  disableWebsite
};

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteDropdownButton);
