import { Component, ChangeEvent, FormEvent } from "react";
import { Website } from "../types/Website";
import { pathToWebsiteConfig } from "../utils/pathUtils";
import ImageInputWithPreview from "./ImageInputWithPreview.react";
import WebsiteFormSaveButton from "./WebsiteFormSaveButton.react";

type WebsiteWebPush = Pick<Website, "web_push_enabled" | "web_push_rational" | "pwa_icon">;

interface Props extends WebsiteWebPush {
  updatedAt: Date;
  isPendingRequest: boolean;

  onSubmit: (data: State) => void;
  showTranslationPane?: () => void;
}

interface State extends WebsiteWebPush {
  currentIconUrl?: string;
}

function i18n(key, opts = {}): string {
  return I18n.t(`react.web_push_notifications_form.${key}`, opts);
}

class WebsiteWebPushNotificationsForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    [
      "onCheckBoxChange",
      "onFileChange",
      "onSubmit",
      "onTextAreaChange",
    ].forEach(fn => (this[fn] = this[fn].bind(this)));

    const { web_push_enabled, web_push_rational, pwa_icon } = props;
    this.state = {
      web_push_enabled,
      web_push_rational: web_push_rational || "",
      currentIconUrl: pwa_icon ? pwa_icon.url : "",
      pwa_icon: null
    };
  }

  onCheckBoxChange(e: ChangeEvent<HTMLInputElement>): void {
    const { target } = e;
    const { name: key, checked: value } = target;
    this.setState({ [key]: value } as Pick<State, "web_push_enabled">);
  }

  onTextAreaChange(e: ChangeEvent<HTMLTextAreaElement>): void {
    const { target } = e;
    const { name: key, value } = target;
    this.setState({ [key]: value } as Pick<State, "web_push_rational">);
  }

  onSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { web_push_enabled, web_push_rational, pwa_icon } = this.state;
    onSubmit({ web_push_enabled, web_push_rational, pwa_icon });
  }

  onFileChange(e: ChangeEvent<HTMLInputElement>): void {
    const { target } = e;
    const { name: key, files } = target;
    this.setState({ [key]: files[0] } as Pick<State, "pwa_icon">);
  }

  renderCheckbox(label: string, value: boolean, name: string, onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void): JSX.Element {
    return (
      <div className="mb-3">
        <div className="form-check">
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input" checked={value} name={name} onChange={onChangeHandler}/>
            {label}
          </label>
        </div>
      </div>
    );
  }

  renderTextArea(label: string, value = "", name: string, onChangeHandler: (e: ChangeEvent<HTMLTextAreaElement>) => void, help?: JSX.Element): JSX.Element {
    return (
      <div className="mb-3">
        <label htmlFor={name} className="form-label">{label}</label>
        <textarea name={name} value={value} onChange={onChangeHandler} className="form-control" rows={4} style={{ resize: "none" }} />
        <div className="form-text">{help}</div>
      </div>
    );
  }

  renderConfigurationBlock(): JSX.Element {
    const { web_push_enabled, web_push_rational, currentIconUrl } = this.state;
    const { updatedAt } = this.props;
    const { showTranslationPane } = this.props;
    // use updated_at to force the browsers to refresh the image
    const pwaIconUrl = currentIconUrl ? `${currentIconUrl}?${updatedAt}` : "";
    const pushRationalHelp = <span><i className="fa-regular fa-question-circle"></i> { i18n("web_push_rational_help") }</span>;

    return (
      <div>
        <div className="header-page">
          <div className="header-page-title">
            <h1>
              <a href={pathToWebsiteConfig()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></a>
              {i18n("title")}
            </h1>
          </div>
        </div>

        <div className="card mb-10">
          <div className="card-body">
            { this.renderCheckbox(i18n("web_push_enabled"), web_push_enabled, "web_push_enabled", this.onCheckBoxChange) }
            { web_push_enabled && (
              <div className="mb-3">
                { this.renderTextArea(i18n("web_push_rational"), web_push_rational, "web_push_rational", this.onTextAreaChange, pushRationalHelp) }
                <ImageInputWithPreview label={i18n("icon")} url={pwaIconUrl} name={"pwa_icon"} onChangeHandler={this.onFileChange} />
              </div>
            ) }
            { showTranslationPane && (
              <div>
                <i className="fa-regular fa-language"></i> <a href="#" onClick={showTranslationPane}>{i18n("translate")}</a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  render(): JSX.Element {
    return (
      <form onSubmit={this.onSubmit}>
        <fieldset>
          {this.renderConfigurationBlock()}
          <WebsiteFormSaveButton
            pendingRequest={this.props.isPendingRequest}
          />
        </fieldset>
      </form>
    );
  }
}

export default WebsiteWebPushNotificationsForm;
