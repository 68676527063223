import { ChangeEvent, useEffect, useState, MouseEvent, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSessionRoom, fetchSessionRoom, updateSessionRoom } from "../../../js/actions/SessionRoomActionCreator";
import Loader from "../../components/shared/Loader.react";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import { urlEventId, pathToSessionRoomsList, pathToSessionRoomEdit } from "../../utils/pathUtils";
import { isEnabled } from "../../utils/featureSetUtils";
import { STREAMING } from "../../constants/FeaturesSet";
import HasRtmpEndpointForm from "../shared/HasRtmpEndpointForm.react";
import isEqual from "lodash/isEqual";
import ConfirmationModal from ".././shared/ConfirmationModal.react";

interface Props {
  match?: any;
  sessionRoomId?: string;
  inModal?: boolean;

  afterSessionRoomUpdated?: () => void;
}

const i18n = (key: string): string => {
  return I18n.t(`react.session_room.form.${key}`);
};

const SessionRoomForm: React.FC<Props> = ({ match, sessionRoomId, inModal, afterSessionRoomUpdated }) => {
  const roomId = sessionRoomId || match.params.session_room_id;
  const isEdit = !!roomId;

  const isFetching = useSelector((state: any) => state.sessionRoom.isFetching);
  const room = useSelector((state: any) => state.sessionRoom.data);
  const errors = useSelector((state: any) => state.sessionRoom.errors);

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [hlsBroadcastUrl, setHlsBroadcastUrl] = useState("");
  const [liveBroadcastEnabled, setLiveBroadcastEnabled] = useState(null);
  const [setupHls, setSetupHls] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [backToListAfterConfirmation, setBackToListAfterConfirmation] = useState(false);

  const previousRoom = useRef({ room });

  useEffect(() => {
    if (!roomId) return;

    dispatch(fetchSessionRoom(urlEventId(), roomId));
  }, [roomId]);

  useEffect(() => {
    if (!room) return;

    const { name, rtmp_endpoint, rtmp_stream_key, hls_broadcast_url } = room;
    setName(name);
    setHlsBroadcastUrl(hls_broadcast_url);
    setLiveBroadcastEnabled([rtmp_endpoint, rtmp_stream_key, hls_broadcast_url].some(field => field?.length > 0));
  }, [room]);

  useEffect(() => {
    if (isFetching || !room) return;
    if (!previousRoom.current.room) {
      previousRoom.current = { room };
      return;
    }
    if (!isEqual(previousRoom.current.room, room)) {
      previousRoom.current = { room };
      afterSessionRoomUpdated && afterSessionRoomUpdated();
    }
  }, [isFetching]);

  const saveSessionRoom = (backToList = false): void => {
    if (setupHls) {
      toggleConfirmationModal();
    }

    const payload = {
      name,
      hls_broadcast_url: hlsBroadcastUrl,
      setup_hls: setupHls,
    };
    let redirectTo = isEdit ? null : pathToSessionRoomEdit(":id");
    redirectTo = backToList || backToListAfterConfirmation ? pathToSessionRoomsList() : redirectTo;
    const notificationOptions = inModal ? "" : { notice: i18n("saved_successfully"), noticeType: "success" };

    dispatch(isEdit ?
      (updateSessionRoom(urlEventId(), roomId, payload, redirectTo, notificationOptions)) :
      (createSessionRoom(urlEventId(), payload, redirectTo, notificationOptions))
    );
  };

  const saveSessionRoomHandler = (backToList = false): (e: MouseEvent<HTMLElement>) => void => {
    return (e: MouseEvent<HTMLElement>): void => {
      e.preventDefault();

      if (setupHls) {
        setBackToListAfterConfirmation(backToList);
        toggleConfirmationModal();
      } else {
        saveSessionRoom(backToList);
      }
    };
  };

  const renderInput = (type: string, label: string, value: any, name: string, onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void): JSX.Element => {
    return (
      <div className="mb-3" key={name}>
        <label htmlFor={name} className="form-label">{label}</label>
        <input
          type={type}
          className="form-control"
          value={value || ""}
          name={name}
          id={name}
          onChange={onChangeHandler}
        />
      </div>
    );
  };

  const renderInformationBlock = (): JSX.Element => {
    return <div className="card" style={{ marginBottom: "10px" }}>
      <div className="card-header text-center">
        <h4 className="card-title">
          <i className={"fa-regular fa-info-circle"}></i> {i18n("information_block")}
        </h4>
      </div>
      <div className="card-body">
        { renderInput("text", i18n("name"), name, "name", (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)) }
      </div>
    </div>;
  };

  const renderStreamingConfig = (): JSX.Element => {
    if (!setupHls && !liveBroadcastEnabled) return null;

    return <HasRtmpEndpointForm
      model="session_room"
      rtmpEndpointValue={room?.rtmp_endpoint}
      rtmpStreamKeyValue={room?.rtmp_stream_key}
      hlsBroadcastUrl={hlsBroadcastUrl}
      needSaveForRtmpData={!liveBroadcastEnabled}
      onChange={(e: ChangeEvent<HTMLInputElement>): void => setHlsBroadcastUrl(e.target.value)}
    />;
  };

  const renderSetupHlsCheckbox = (): JSX.Element => {
    if (liveBroadcastEnabled) {
      return <div className="form-text">
        <i className="fa-regular fa-exclamation-triangle text-warning mr-5"></i>
        {i18n("live_broadcast_enabled_warning")}
      </div>;
    }

    return <div className="mb-3" key="setup-hls">
      <div className="form-check">
        <label className="form-check-label">
          <input type="checkbox" className="form-check-input" checked={setupHls} name="setup_hls" onChange={(): void => setSetupHls(!setupHls)} />
          {i18n("setup_hls")}
        </label>
      </div>
    </div>;
  };

  const renderLiveBlock = (): JSX.Element => {
    if (!isEnabled(STREAMING)) return;

    return <div className="card" style={{ marginBottom: "10px" }}>
      <div className="card-header text-center">
        <h4 className="card-title">
          <i className={"fa-regular fa-circle-play mr-5"}></i>{i18n("live_block")}
        </h4>
      </div>
      <div className="card-body">
        {renderSetupHlsCheckbox()}
        {renderStreamingConfig()}
      </div>
    </div>;
  };

  const renderSaveButton = (label: string, extraClasses = "", backToList = false): JSX.Element => {
    return <button
      disabled={isFetching}
      className={`btn btn-lg ${extraClasses}`}
      onClick={saveSessionRoomHandler(backToList)}>
      {label}
      {isFetching && <Loader size="small" color="white" />}
    </button>;
  };

  const renderSaveButtons = (): JSX.Element => {
    return <p className="float-end">
      {renderSaveButton(i18n("save"), "mr-5 btn-secondary")}
      {inModal ? null : renderSaveButton(i18n("save_and_goto_list"), "btn-primary", true)}
    </p>;
  };

  const renderCancelButton = (): JSX.Element => {
    if (inModal) return null;

    return <a href={pathToSessionRoomsList()} className="btn btn-secondary btn-lg">
      {i18n("cancel")}
    </a>;
  };

  const renderErrorsMessage = (): JSX.Element => {
    return <ErrorMessage errors={errors} model="session_room" />;
  };

  const renderTitle = (): JSX.Element => {
    if (inModal) return null;

    return <h1>
      <a href={pathToSessionRoomsList()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></a>
      {i18n(isEdit ? "edit_session_room" : "new_session_room")}
    </h1>;
  };

  const toggleConfirmationModal = (): void => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const renderConfirmationModal = (): JSX.Element => {
    return <ConfirmationModal
      isVisible={showConfirmationModal}
      onClose={toggleConfirmationModal}
      onConfirm={(): void => saveSessionRoom()}
      title={`<div class='text-center'><i class='fa-regular fa-triangle-exclamation mr-5'></i>${i18n("confirmation_setup_hls_modal_title")}</div>`}
      confirmationQuestion={`<div class='text-center'>${i18n("confirmation_setup_hls_modal_question_html")}</div>`}
    />;
  };

  const renderForm = (): JSX.Element => {
    return <div className="row">
      <div className={inModal ? "col-md-12" : "col-md-8 offset-md-2"}>
        <div className="header-page">
          <div className="header-page-title">
            {renderTitle()}
          </div>
        </div>
        <form>
          <fieldset disabled={isFetching}>
            {renderErrorsMessage()}
            {renderInformationBlock()}
            {renderLiveBlock()}
            {renderSaveButtons()}
            {renderCancelButton()}
            {renderConfirmationModal()}
          </fieldset>
        </form>
      </div>
    </div>;
  };

  return isEdit && !room ? <Loader/> : renderForm();
};

export default SessionRoomForm;
