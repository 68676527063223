import { Component } from "react";
import { connect } from "react-redux";
import filter from "lodash/filter";
import pick from "lodash/pick";
import map from "lodash/map";
import { requestCountGuests } from "../actions/ImportActionCreators";
import { fetchGuestFieldsMostUsedValues } from "../actions/GuestFieldsActionCreators";
import { updateUI } from "../actions/UIAppearanceActionCreators";
import { clearStatistics } from "../actions/GuestFieldsActionCreators";
import { changeAccessControls } from "../actions/GuestUpdatesActionCreators";
import UpdateGuestFormComponent from "../components/UpdateGuestFormComponent.react";
import { queryStringAndSelectedGuests } from "../utils/QueryStringUtils";
import withModal from "./withModal.react";
import { urlEventId } from "../utils/pathUtils";

const CHECKINS_KEY = "access_controls";

class ChangeAccessControls extends Component {
  constructor(props) {
    super(props);
    this.checkin = this.checkin.bind(this);
    this.unCheckin = this.unCheckin.bind(this);
    this.setSelectedAccesspoint = this.setSelectedAccesspoint.bind(this);
    this.state = { selectedAccesspoint: null };
  }

  componentDidMount() {
    const { location, selectedGuests, fetchGuestFieldsMostUsedValues, requestCountGuests, updateUI } = this.props;
    const updates = {
      "modalTitle": `${I18n.t("react.reports.checkin")}/${I18n.t("react.reports.uncheckin")}`
    };
    updateUI(updates);
    fetchGuestFieldsMostUsedValues(urlEventId(), "", CHECKINS_KEY);
    const query = queryStringAndSelectedGuests(location, selectedGuests);
    fetchGuestFieldsMostUsedValues(urlEventId(), query, CHECKINS_KEY);
    requestCountGuests(query, "selectedGuestCountCheckin", true);
  }

  componentWillUnmount() {
    const { clearStatistics } = this.props;
    clearStatistics();
  }

  setSelectedAccesspoint(accesspointId) {
    this.setState({ selectedAccesspoint: accesspointId });
  }

  triggerChangeAccessControls(action) {
    const { location, closeModal, selectedGuests, changeAccessControls } = this.props;
    const { selectedAccesspoint } = this.state;
    changeAccessControls(urlEventId(), queryStringAndSelectedGuests(location, selectedGuests), selectedAccesspoint, action);
    closeModal();
  }

  checkin() {
    this.triggerChangeAccessControls("create");
  }

  unCheckin() {
    this.triggerChangeAccessControls("delete");
  }

  expectedAtAccesspointsIdsToNames(ids) {
    return map(ids, id => {
      return pick(filter(this.props.accesspoints, accesspoint => {
        return accesspoint.id == id;
      })[0], "id", "name");
    });
  }

  render() {
    const { eventFieldMostUsedValues, searchFieldMostUsedValues, selectedGuestCount, accesspoints } = this.props;
    const { selectedAccesspoint } = this.state;
    const options = map(accesspoints, accesspoint => {
      return { value: accesspoint.id, label: accesspoint.name };
    });

    return (
      <UpdateGuestFormComponent
        options={options}
        selectedGuestCount={selectedGuestCount}
        eventFieldMostUsedValues={this.expectedAtAccesspointsIdsToNames(eventFieldMostUsedValues)}
        searchFieldMostUsedValues={this.expectedAtAccesspointsIdsToNames(searchFieldMostUsedValues)}
        fieldLabel={I18n.t("react.reports.accesspoint.filter_label")}
        selectPlaceholder={I18n.t("react.reports.accesspoint.filter_placeholder")}
        onChange={this.setSelectedAccesspoint}>

        <button type="button" className="btn btn-danger float-start" disabled={!selectedAccesspoint} onClick={this.unCheckin}>
          {I18n.t("react.reports.uncheckin")}
        </button>
        <button type="button" className="btn btn-success float-end" disabled={!selectedAccesspoint} onClick={this.checkin}>
          {I18n.t("react.reports.checkin")}
        </button>
      </UpdateGuestFormComponent>
    );
  }
}

function mapStateToProps(state) {
  return {
    accesspoints: state.accesspoints.data,
    expectedAtAccesspoints: state.expectedAtAccesspoints,
    eventFieldMostUsedValues: state.guestFieldStatistics.eventFieldMostUsedValues,
    searchFieldMostUsedValues: state.guestFieldStatistics.searchFieldMostUsedValues,
    selectedGuestCount: state.appearance.selectedGuestCountCheckin,
    selectedGuests: state.guests.selectedGuests
  };
}

const mapDispatchToProps = {
  fetchGuestFieldsMostUsedValues,
  changeAccessControls,
  requestCountGuests,
  clearStatistics,
  updateUI
};

export default connect(mapStateToProps, mapDispatchToProps)(withModal(ChangeAccessControls));
