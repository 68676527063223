import { Component } from "react";
import { connect } from "react-redux";
import indexOf from "lodash/indexOf";
import isEmpty from "lodash/isEmpty";
import { urlEventId, pathToEmailTemplatePreview } from "../utils/pathUtils";
import { addTooltip } from "../utils/templatesBuilderUtils";

import withTemplatesBuilder from "./withTemplatesBuilder.react";
import Loader from "../components/shared/Loader.react";
import EmailTemplateForm from "../components/email_templates/EmailTemplateForm.react.tsx";
import SectionView from "../components/templates_builder/SectionView.react";
import SectionsTypesList from "../components/templates_builder/SectionsTypesList.react";
import SectionsList from "../components/templates_builder/SectionsList.react";
import NoticeBlock from "../components/templates_builder/NoticeBlock.react";
import AppearanceCategories from "../components/templates_builder/AppearanceCategories.react";
import AppearanceSubsection from "../components/templates_builder/AppearanceSubsection.react";
import ThemeReloader from "../components/templates_builder/ThemeReloader.react";
import * as EmailBuilderActionCreators from "../actions/EmailBuilderActionCreators";
import { fetchEventGuestFields } from "../actions/GuestFieldsActionCreators";
import { fetchSavedSearches } from "../actions/SavedSearchActionCreators";
import { OverlayTrigger, Button } from "react-bootstrap";
import { redirectIfUnauthorized } from "../utils/aclUtils";
import { hasAvailableFeature } from "../utils/availableFeature";

class EmailBuilder extends Component {
  constructor(props) {
    redirectIfUnauthorized("configuration", "manage");
    super(props);
    [
      "createNewSection",
      "updateSectionsOrder",
      "removeBlock",
      "saveEmailTemplate",
      "receiveNewChanges",
      "receiveSectionChanges",
      "removeSectionHandler",
      "reloadTheme",
      "removeLoader",
      "toggleEmailForm",
      "toggleAppearanceView",
      "onSubmitEmailTemplateForm"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      showEmailFormView: false,
      iframeLoading: true
    };
  }

  componentDidMount() {
    const { fetchEmailTemplate, fetchEmailTemplateSectionTypes, fetchEventGuestFields, fetchSavedSearches, fetchWebsitePages } = this.props;
    fetchEmailTemplate(urlEventId(), this.emailTemplateId());
    fetchEmailTemplateSectionTypes(urlEventId(), this.emailTemplateId());
    fetchEventGuestFields(urlEventId(), { website: true }); // website option adds guest's dynamic properties
    fetchSavedSearches(urlEventId());
    fetchWebsitePages(urlEventId());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.emailTemplate && (this.props.emailTemplate.updated_at != prevProps.emailTemplate.updated_at || this.props.emailTemplate.theme_updated_at != prevProps.emailTemplate.theme_updated_at)) {
      if (isEmpty(this.props.errors)) {
        this.props.showNotice(I18n.t("react.website.update_success"), "success", 2500);
        this.forceReloadPreview();
      } else {
        this.props.showNotice(I18n.t("react.website.an_error_occurred"), "error", 2500);
      }
    }
  }

  componentWillUnmount() {
    const { clearEmailTemplate } = this.props;
    clearEmailTemplate();
  }

  forceReloadPreview() {
    const rand = Math.floor((Math.random() * 1000000));
    // we cannot use JS reload method because of same-origin constraint
    // so modifying current url does the job
    this.refs["template-preview-iframe"].src += "?uid=" + rand;
    this.setState({ iframeLoading: true });
  }

  removeLoader() {
    this.setState({ iframeLoading: false });
  }

  emailTemplateId() {
    const { emailTemplateId, match } = this.props;
    return emailTemplateId || match.params.email_template_id;
  }

  saveEmailTemplate() {
    const { emailTemplate, globalChanges, sectionsToRemove, blocksToRemove, updateEmailTemplate } = this.props;

    const params = {
      remove_sections: sectionsToRemove,
      remove_blocks: blocksToRemove,
      settings_data: globalChanges
    };
    updateEmailTemplate(urlEventId(), emailTemplate._id, params);
  }

  toggleEmailForm() {
    const { showEmailFormView } = this.state;
    const menuKey = showEmailFormView ? "pages" : "edit";
    this.props.resetAndChangeParentView({}, menuKey);
    this.setState({ showEmailFormView: !showEmailFormView });
  }

  toggleAppearanceView() {
    this.setState({ showEmailFormView: false });
    this.props.toggleAppearanceView();
  }

  createNewSection(sectionId, sectionData) {
    const { createNewSection } = this.props;
    createNewSection(sectionId, sectionData);
  }

  updateSectionsOrder(contentForPage) {
    const { updateEmailSections } = this.props;
    updateEmailSections(contentForPage);
  }

  removeBlock(blockKey, sectionKey) {
    const { removeBlock } = this.props;
    removeBlock(blockKey, sectionKey);
  }

  reloadTheme(templateProjectRoot) {
    const { reloadTheme, emailTemplate } = this.props;
    reloadTheme(urlEventId(), emailTemplate._id, templateProjectRoot);
  }

  onSubmitEmailTemplateForm(params) {
    const { emailTemplate, updateEmailTemplate } = this.props;
    updateEmailTemplate(urlEventId(), emailTemplate._id, params);
  }

  renderSectionsConfig() {
    const { sectionViewForKey, reloadingTheme } = this.props;
    if (!sectionViewForKey || reloadingTheme) {
      return <SectionView />;
    }
    const { emailTemplate, sections, sectionTypes, createNewBlock, emailSections, event,
      toggleSectionView, highlightBlockInIframe, guestFields, segments, liquidTemplatePendingRequest,
      updateLiquidTemplate, createLiquidTemplate, liquidTemplates, liquidTemplateErrors, clearErrors,
      pages, assets, hasChanges } = this.props;
    const sectionLocalConfiguration = sections[sectionViewForKey];
    const sectionTypeKey = sectionLocalConfiguration["type"];
    const sectionType = sectionTypes.find(s => s.filename == sectionTypeKey);

    return (
      <SectionView sectionKey={sectionViewForKey}
        schema={sectionType.schema}
        configuration={{ ...sectionLocalConfiguration }}
        hide={toggleSectionView}
        detectChanges={this.receiveNewChanges}
        sendChanges={this.receiveSectionChanges}
        addBlock={createNewBlock}
        removeBlock={this.removeBlock}
        highlightBlockInIframe={highlightBlockInIframe}
        deletable={indexOf(emailSections, sectionViewForKey) != -1}
        removeSection={this.removeSectionHandler}
        guestCategories={emailTemplate.event_guest_categories}
        guestCategoriesByPopulation={emailTemplate.event_guest_categories_by_population}
        documentNames={emailTemplate.document_templates_names}
        accesspointsTraits={emailTemplate.accesspoints_traits}
        websitePages={pages}
        guestFields={guestFields}
        segments={segments}
        updateLiquidTemplate={updateLiquidTemplate}
        createLiquidTemplate={createLiquidTemplate}
        liquidTemplates={liquidTemplates}
        liquidTemplateErrors={liquidTemplateErrors}
        liquidTemplatePendingRequest={liquidTemplatePendingRequest}
        accountId={event.account_id}
        builderType="email"
        clearErrors={clearErrors}
        templateId={this.emailTemplateId()}
        saveBuilder={this.saveEmailTemplate}
        event={event}
        assets={assets}
        hasChanges={hasChanges}
        withSidebar={true} />
    );
  }

  removeSectionHandler() {
    const { removeSection, toggleSectionView, sectionViewForKey } = this.props;
    toggleSectionView();
    removeSection(sectionViewForKey);
    this.refs["template-preview-iframe"].contentWindow.postMessage({ type: "removeSection", sectionId: sectionViewForKey }, "*");
  }

  receiveNewChanges() {
    const { sendChangesNotification, hasChanges } = this.props;
    if (!hasChanges) {
      sendChangesNotification();
    }
  }

  receiveSectionChanges(key, newConfig, changes) {
    const { updateSection } = this.props;
    updateSection(key, newConfig, changes);
  }

  iframeUrl() {
    const { emailTemplate } = this.props;
    return pathToEmailTemplatePreview(emailTemplate._id);
  }

  render() {
    const { emailTemplate, sections, sectionTypes, emailSections, generalSettings,
      hasChanges, receiveAppearanceChanges, notice, noticeType, sectionsListShown,
      appearanceShown, toggleSectionView, toggleAppearanceView, toggleAppearanceSubsectionView,
      appearanceViewForKey, showSectionsList, reloadingTheme, reloadThemeError, event,
      translationsPaneShown, displayTranslationsPane, errors, isPendingRequest,
      sidebarMenuClasses } = this.props;
    const { showEmailFormView, iframeLoading } = this.state;

    if (!emailTemplate || sectionTypes.length == 0) {
      return <Loader size="large" inline={false} message={I18n.t("react.loader.loading")} />;
    }

    const translationsEnabled = event && event.available_frontend_locales && event.available_frontend_locales.length > 0;

    return (
      <div className="row website-builder-row">
        <div className="col-lg-3 col-md-4 col-sm-5 col-6 website-builder-sidebar">
          <div className="sidebar-menu">
            <ul className="list-group">
              <OverlayTrigger placement="right" overlay={addTooltip(I18n.t("react.email_template.edit_email"))}>
                <li className={sidebarMenuClasses("edit")} onClick={ this.toggleEmailForm }><i className="fa-regular fa-pencil"></i></li>
              </OverlayTrigger>
              <OverlayTrigger placement="right" overlay={addTooltip(I18n.t("react.website.edit_general_appearance"))}>
                <li className={sidebarMenuClasses("appearance")} onClick={ this.toggleAppearanceView }><i className="fa-regular fa-paintbrush-pencil"></i></li>
              </OverlayTrigger>
              { translationsEnabled &&
                <OverlayTrigger placement="right" overlay={addTooltip(I18n.t("react.registration_form.manage_translations"))}>
                  <li className={sidebarMenuClasses("translations")} onClick={ (e) => displayTranslationsPane(e, this.emailTemplateId()) }><i className="fa-regular fa-language"></i></li>
                </OverlayTrigger>
              }
            </ul>
          </div>

          <div className="sidebar-page-sections">
            <div className="sidebar-header">
              <p className="lead">{ emailTemplate.name }</p>
            </div>

            <div className="sidebar-body">
              <SectionsList layoutStaticSections={emailTemplate.layout.sections}
                templateStaticSections={[]}
                dynamicSectionsNames={emailSections || []}
                sectionTypes={sectionTypes}
                sectionsConfiguration={sections}
                showEditSection={toggleSectionView}
                showSectionsList={showSectionsList}
                updateSectionsOrder={this.updateSectionsOrder} />
            </div>
          </div>

          <div className="sidebar-footer">
            <Button variant="primary" disabled={!hasChanges} onClick={ this.saveEmailTemplate }>{ I18n.t("react.website.save_all") }</Button>
            {window.ReactGlobalProps.super_admin || hasAvailableFeature("reload_website_theme") ? (
              <ThemeReloader
                className="float-end"
                reloadTheme={this.reloadTheme}
                reloading={reloadingTheme}
                reloadError={reloadThemeError}
                hasThemeLoader={emailTemplate}
                resourceType="email_template"
              />
            ) : null}
          </div>

          { translationsPaneShown || <NoticeBlock notice={notice} noticeType={noticeType} /> }

          <SectionsTypesList layoutStaticSections={emailTemplate.layout.sections}
            sectionsGroups={emailTemplate.sections_groups}
            sectionTypes={sectionTypes}
            sections={sections}
            isShown={sectionsListShown}
            hideComponent={this.props.hideSectionsList}
            createNewSection={this.createNewSection}
            showEditSection={toggleSectionView}
            event={event}
            withSidebar={true}
          />

          { this.renderSectionsConfig() }

          <EmailTemplateForm emailTemplate={emailTemplate}
            isOpen={showEmailFormView}
            hide={ this.toggleEmailForm }
            isSubmitting={isPendingRequest}
            onSubmit={ this.onSubmitEmailTemplateForm }
            errors={errors} />
          <AppearanceCategories isShown={appearanceShown}
            mainSettingsSchema={emailTemplate.main_settings_schema}
            toggleView={toggleAppearanceView}
            onSelectAppearanceCategory={toggleAppearanceSubsectionView} />
          <AppearanceSubsection subsectionKey={appearanceViewForKey}
            mainSettingsSchema={emailTemplate.main_settings_schema}
            mainSettingsData={generalSettings}
            toggleView={toggleAppearanceSubsectionView}
            detectChanges={this.receiveNewChanges}
            sendChanges={receiveAppearanceChanges}
            event={event}
            hasChanges={hasChanges} />
        </div>

        <div className="col-lg-9 col-md-8 col-sm-7 col-6 website-builder-preview">
          { iframeLoading ?
            <Loader size="large" inline={false} withOverlay={true} message={I18n.t("react.loader.preview")} />
            : null }
          <iframe src={ this.iframeUrl() } frameBorder="0" id="template-preview-iframe" ref="template-preview-iframe" onLoad={this.removeLoader} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    emailTemplate: state.emailTemplate.data,
    sections: state.emailTemplate.sections,
    generalSettings: state.emailTemplate.generalSettings,
    emailSections: state.emailTemplate.emailSections,
    sectionTypes: state.sectionTypes.items,
    guestFields: state.guestFields.guestFields,
    segments: state.savedSearches.data,
    pages: state.websitePages.items,
    errors: state.emailTemplate.errors,
    hasChanges: state.emailTemplate.hasChanges,
    globalChanges: state.emailTemplate.globalChanges,
    sectionsToRemove: state.emailTemplate.sectionsToRemove,
    blocksToRemove: state.emailTemplate.blocksToRemove,
    reloadingTheme: state.emailTemplate.reloadingTheme,
    reloadThemeError: state.emailTemplate.reloadThemeError,
    isPendingRequest: state.emailTemplate.isPendingRequest,
    assets: state.assetsManager.assets
  };
}

const mapDispatchToProps = Object.assign({}, EmailBuilderActionCreators, {
  fetchEventGuestFields,
  fetchSavedSearches
});

export default connect(mapStateToProps, mapDispatchToProps)(withTemplatesBuilder(EmailBuilder, "email"));
