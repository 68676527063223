import { ActionTypes } from "../constants/Constants";

module.exports = {

  updateRegisteredAtDates(registrationDateBefore, registrationDateAfter) {
    return {
      type: ActionTypes.SET_REGISTERED_AT_DATES,
      registeredAtDates: { registeredBefore: registrationDateBefore, registeredAfter: registrationDateAfter }
    };
  }

};
