import { ActionTypes, CALL_API } from "../constants/Constants";

export function fetchLiquidTemplateInAccount(accountId, templateId) {
  const { DEV_NULL, RECEIVE_LIQUID_TEMPLATE_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_LIQUID_TEMPLATE_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/accounts/${accountId}/templates/${templateId}.json`
    }
  };
}

export function fetchLiquidTemplates(accountId, type = null) {
  const { DEV_NULL, RECEIVE_LIQUID_TEMPLATES_SUCCESS } = ActionTypes;
  let endpoint = `/accounts/${accountId}/templates.json`;
  if (type) {
    endpoint = `${endpoint}?type=${type}`;
  }
  return {
    [CALL_API]: {
      types: [RECEIVE_LIQUID_TEMPLATES_SUCCESS, RECEIVE_LIQUID_TEMPLATES_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint
    }
  };
}

export function updateLiquidTemplate(accountId, templateId, params = {}) {
  const { UPDATE_LIQUID_TEMPLATE_FAILURE, UPDATE_LIQUID_TEMPLATE_REQUEST, UPDATE_LIQUID_TEMPLATE_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_LIQUID_TEMPLATE_REQUEST, UPDATE_LIQUID_TEMPLATE_SUCCESS, UPDATE_LIQUID_TEMPLATE_FAILURE],
      method: "PUT",
      endpoint: `/api/v1/accounts/${accountId}/liquid_templates/${templateId}.json`,
      body: { liquid_template: params }
    }
  };
}

export function createLiquidTemplate(accountId, params) {
  const { CREATE_LIQUID_TEMPLATE_FAILURE, CREATE_LIQUID_TEMPLATE_REQUEST, CREATE_LIQUID_TEMPLATE_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_LIQUID_TEMPLATE_REQUEST, CREATE_LIQUID_TEMPLATE_SUCCESS, CREATE_LIQUID_TEMPLATE_FAILURE],
      method: "POST",
      endpoint: `/api/v1/accounts/${accountId}/liquid_templates.json`,
      body: { liquid_template: params }
    }
  };
}

export function fetchAccount(accountId) {
  const { DEV_NULL, FETCH_ACCOUNT_REQUEST, RECEIVE_ACCOUNT_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_ACCOUNT_REQUEST, RECEIVE_ACCOUNT_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/accounts/${accountId}.json`
    }
  };
}

export function updateAccount(accountId, newParams) {
  const { DEV_NULL, UPDATE_ACCOUNT_SUCCESS, UPDATE_ACCOUNT_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, UPDATE_ACCOUNT_SUCCESS, UPDATE_ACCOUNT_FAILURE],
      method: "PUT",
      endpoint: `/api/v1/accounts/${accountId}.json`,
      body: { account: newParams }
    }
  };
}
