import { ActionTypes } from "../constants/Constants";

const initialState = {
  data: null,
  fetching: false,
  errors: null,
  nextUrl: null
};

function historyItems(state = initialState, action) {
  switch (action.type) {
  case ActionTypes.START_FETCHING_HISTORY_ITEMS: {
    return { ...state, fetching: true } ;
  }
  case ActionTypes.FETCH_HISTORY_ITEMS_SUCCESS: {
    const items = action.resetHistoryItems ? action.data : (state.data || []).concat(action.data);
    return { ...state, fetching: false, data: items, nextURL: action.nextURL };
  }
  case ActionTypes.FETCH_HISTORY_ITEMS_FAILURE: {
    return { ...state, fetching: false, errors: action.data };
  }
  case ActionTypes.CLEAR_HISTORY_ITEMS: {
    return initialState;
  }
  default:
    return state;
  }
}

export default historyItems;
