"use strict";

import { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router";

import { requestEvent } from "../actions/ImportActionCreators";

import { urlWithQuery } from "../utils/pathUtils";

import GuestCampaignInformation from "./guest_campaign/GuestCampaignInformation.react";
import GuestCampaign from "./guest_campaign/GuestCampaign.react";
import GuestCampaignEmail from "./guest_campaign/GuestCampaignEmail.react";
import GuestCampaignNotification from "./guest_campaign/GuestCampaignNotification.react";
import GuestCampaignSummarize from "./guest_campaign/GuestCampaignSummarize.react";
import GuestCampaignList from "./guest_campaign/GuestCampaignList.react";
import GuestCampaignCodeIt from "./guest_campaign/GuestCampaignCodeIt.react";
import GuestCampaignStatistics from "./guest_campaign/GuestCampaignStatistics.react";
import querystring from "querystring";

import EventReports from "./event_reports/EventReports.react";
import AttendanceByField from "./event_reports/AttendanceByField.react";
import AttendanceByAccesspoint from "./event_reports/AttendanceByAccesspoint.react";
import AttendanceSummary from "./event_reports/AttendanceSummary.react";
import LiveConsumptionByAccesspoint from "./event_reports/LiveConsumptionByAccesspoint.react";
import GuestProductsByNumberOfInterests from "./event_reports/GuestProductsByNumberOfInterests.react";
import GuestProductsByNumberOfInformationRequests from "./event_reports/GuestProductsByNumberOfInformationRequests.react";
import GuestsByNumberOfInformationRequests from "./event_reports/GuestsByNumberOfInformationRequests.react";
import GuestInvitationSent from "./event_reports/GuestInvitationSent.react";
import GuestPromoCodeInvitation from "./event_reports/GuestPromoCodeInvitation.react";
import Sales from "./event_reports/Sales.react";
import CartItemsStat from "./event_reports/CartItemsStat.react";
import Sessions from "./event_reports/Sessions.react";
import ExhibitorsByNumberOfInterests from "./event_reports/ExhibitorsByNumberOfInterests.react";
import ExhibitorsByNumberOfLeads from "./event_reports/ExhibitorsByNumberOfLeads.react";
import VisitRoute from "./event_reports/VisitRoute.react";
import Messages from "./event_reports/Messages.react";
import MeetingsbyNumberOfAppointments from "./event_reports/MeetingsbyNumberOfAppointments.react";
import Guests from "./Guests.react";
import GuestFieldFormContainer from "./guest_field/GuestFieldFormContainer.react";
import RegisteredDashboard from "./dashboard/RegisteredDashboard.react";
import CheckinDashboard from "./dashboard/CheckinDashboard.react";
import OccupancyDashboard from "./dashboard/OccupancyDashboard.react";
import NetworkingDashboard from "./dashboard/NetworkingDashboard.react";
import LeadsDashboard from "./dashboard/LeadsDashboard.react";
import ScoringDashboard from "./dashboard/ScoringDashboard.react";
import SessionsDashboard from "./dashboard/SessionsDashboard.react";
import SalesDashboard from "./dashboard/SalesDashboard.react";
import SessionRoomForm from "../components/session_room/SessionRoomForm.react";
import BlogArticlesList from "./blog_articles/BlogArticlesList.react";

import MappingStep from "./MappingStep.react";

class App extends Component {

  constructor(props) {
    super(props);
    this._guestListButtonClick = this._guestListButtonClick.bind(this);
  }

  componentDidMount() {
    const { dispatch, integration_configurations } = this.props;
    dispatch(requestEvent({ integration_configurations: integration_configurations }));
  }

  _guestListButtonClick() {
    const query = querystring.parse(this.props.location.search.substring(1)).q || "";
    const searchURL = urlWithQuery(query, "guests");
    window.location = searchURL;
  }

  render() {
    const { notice, noticeType, match, noticeCustomDisplay } = this.props;
    let noticeBlock = "";
    if (notice.length > 0 && !noticeCustomDisplay) {
      const classes = `alert alert-${noticeType}`;
      noticeBlock = (
        <div className={ classes }>
          {notice}
        </div>
      );
    }
    let title = "";
    return (
      <div>
        {noticeBlock}
        <div className="row">
          <div className="col-md-12">
            {title}
            <Route path={`${match.path}guests`} component={Guests} />
            <Route exact path={`${match.path}campaigns`} component={GuestCampaignList}/>
            <Route path={`${match.path}campaign/:guest_campaign_id/email/templates`} component={GuestCampaignEmail}/>
            <Route path={`${match.path}campaign/:guest_campaign_id/email/codeit`} component={GuestCampaignCodeIt}/>
            <Route exact path={`${match.path}campaign/:guest_campaign_id/email`} component={GuestCampaignEmail}/>
            <Route exact path={`${match.path}campaign/:guest_campaign_id/configuration`} component={GuestCampaignNotification}/>
            <Route path={`${match.path}campaign/:guest_campaign_id/summarize`} component={GuestCampaignSummarize}/>
            <Route path={`${match.path}campaign/information`} component={GuestCampaignInformation}/>
            <Switch>
              <Route path={`${match.path}campaigns/new`} component={GuestCampaign}/>
              <Route path={`${match.path}campaign/:guest_campaign_id/edit`} component={GuestCampaign}/>
            </Switch>
            <Route path={`${match.path}campaign/:guest_campaign_id/information`} component={GuestCampaignInformation}/>
            <Route path={`${match.path}guest_import_operations/:guest_import_operation/mapping_step`} component={MappingStep}/>
            <Route path={`${match.path}campaign/:guest_campaign_id/statistics`} component={GuestCampaignStatistics}/>
            <Switch>
              <Route path={`${match.path}guest_fields/new`} component={GuestFieldFormContainer}/>
              <Route path={`${match.path}guest_fields/:guest_field_id`} component={GuestFieldFormContainer}/>
            </Switch>
            <Route exact path={`${match.path}reports`} component={EventReports} />
            <Route path={`${match.path}reports/attendance_by_field`} component={AttendanceByField} />
            <Route path={`${match.path}reports/attendance_by_accesspoint`} component={AttendanceByAccesspoint} />
            <Route path={`${match.path}reports/attendance_summary`} component={AttendanceSummary} />
            <Route path={`${match.path}reports/live_consumption`} component={LiveConsumptionByAccesspoint} />
            <Route path={`${match.path}reports/guest_products_by_number_of_interests`} component={GuestProductsByNumberOfInterests} />
            <Route path={`${match.path}reports/guest_products_by_number_of_information_requests`} component={GuestProductsByNumberOfInformationRequests} />
            <Route path={`${match.path}reports/guests_by_number_of_information_requests`} component={GuestsByNumberOfInformationRequests} />
            <Route path={`${match.path}reports/guest_invitation_sent_stats`} component={GuestInvitationSent} />
            <Route path={`${match.path}reports/guest_promo_code_invitation_stats`} component={GuestPromoCodeInvitation} />
            <Route path={`${match.path}reports/sales`} component={Sales}/>
            <Route path={`${match.path}reports/cart_items_sales`} component={CartItemsStat}/>
            <Route path={`${match.path}reports/messages`} component={Messages}/>
            <Route path={`${match.path}reports/sessions_by_number_of_interests`} component={Sessions}/>
            <Route path={`${match.path}reports/exhibitors_by_number_of_interests`} component={ExhibitorsByNumberOfInterests}/>
            <Route path={`${match.path}reports/exhibitors_by_number_of_leads`} component={ExhibitorsByNumberOfLeads}/>
            <Route path={`${match.path}reports/visit_route`} component={VisitRoute}/>
            <Route path={`${match.path}reports/meetings_by_number_of_appointments`} component={MeetingsbyNumberOfAppointments}/>
            <Route path={`${match.path}dashboard/registered`} component={RegisteredDashboard} />
            <Route path={`${match.path}dashboard/checkin`} component={CheckinDashboard} />
            <Route path={`${match.path}dashboard/occupancy`} component={OccupancyDashboard} />
            <Route path={`${match.path}dashboard/networking`} component={NetworkingDashboard} />
            <Route path={`${match.path}dashboard/leads`} component={LeadsDashboard} />
            <Route path={`${match.path}dashboard/scoring`} component={ScoringDashboard} />
            <Route path={`${match.path}dashboard/sessions`} component={SessionsDashboard} />
            <Route path={`${match.path}dashboard/sales`} component={SalesDashboard} />
            <Switch>
              <Route path={`${match.path}session_rooms/new`} component={SessionRoomForm}/>
              <Route path={`${match.path}session_rooms/:session_room_id/edit`} component={SessionRoomForm}/>
            </Switch>
            <Route path={`${match.path}blog_articles`} component={BlogArticlesList} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    event: state.event,
    notice: state.notifications.currentNotice,
    noticeType: state.notifications.noticeType,
    noticeCustomDisplay: state.notifications.customDisplay
  };
}

export default connect(mapStateToProps)(App);
