import { useState, ChangeEvent, MouseEvent, useEffect } from "react";
import { Modal } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";

interface Props {
  show: boolean;
  errors?: any[];

  onClose(): void;
  onSubmit(name: string): void;
}

const NewProgramModal: React.FC<Props> = ({ show, errors, onClose, onSubmit }) => {
  const [name, setName] = useState("");

  useEffect(() => {
    if (!show) setName("");
  }, [show]);

  const isSubmittable = (): boolean => {
    return !isEmpty(name) && !!name.match(/\S/);
  };

  const submit = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    if (!isSubmittable()) return;

    onSubmit(name);
  };

  const changeName = (e: ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value);
  };

  const renderErrors = (): JSX.Element => {
    if (!errors || Object.entries(errors).length === 0) return null;

    return <div className="alert alert-danger">
      {Object.keys(errors).map(key => {
        return <li key={key}><strong>{I18n.t(`mongoid.attributes.program.${key}`)}</strong> {errors[key]}</li>;
      })}
    </div>;
  };

  return <Modal show={show} onHide={onClose}>
    <Modal.Header>
      <Modal.Title>{ I18n.t("react.programs.new_program_modal.title") }</Modal.Title>
      <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
    </Modal.Header>
    <form>
      <Modal.Body>
        { renderErrors() }
        <div className="mb-3">
          <input type="text" className="form-control" value={name} onChange={changeName} />
          <div className="form-text">{ I18n.t("react.programs.new_program_modal.name_help") }</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="submit" className="btn btn-primary" onClick={submit} disabled={!isSubmittable()}>
          { I18n.t("create") }
        </button>
      </Modal.Footer>
    </form>
  </Modal>;
};

export default NewProgramModal;
