function validFileFormat(file: File, acceptedExtensionsOrMimeTypes: string[]): boolean {
  const acceptedExtensions: string[] = [];
  const acceptedMimeTypes: string[] = [];

  acceptedExtensionsOrMimeTypes.forEach((item: string) => {
    item[0] === "." ? acceptedExtensions.push(item) : acceptedMimeTypes.push(item);
  });
  return fileExtensionAccepted(file, acceptedExtensions) || mimeTypeAccepted(file, acceptedMimeTypes);
}

function fileExtensionAccepted(file: File, acceptedExtensions: string[]): boolean {
  const fileExtension = `.${file.name.split(".").pop()}`;
  return acceptedExtensions.includes(fileExtension.toLowerCase());
}

function mimeTypeAccepted(file: File, acceptedMimeTypes: string[]): boolean {
  const acceptedMimeType = acceptedMimeTypes.find((mimeType: string) => {
    if (mimeType.includes("/*")) {
      return mimeType.split("/")[0] === file.type.split("/")[0];
    }
    return file.type === mimeType;
  });
  return acceptedMimeType !== undefined;
}

export default validFileFormat;
