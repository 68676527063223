import { ActionTypes } from "../constants/Constants";

function guestFieldStatistics(state = {}, action) {
  switch (action.type) {
  case ActionTypes.FETCH_EVENT_GUEST_FIELD_MOST_USED_VALUES_SUCCESS:
    state = Object.assign({}, state, { eventFieldMostUsedValues: action.data });
    return state;
  case ActionTypes.SEARCH_GUEST_FIELD_MOST_USED_VALUES_SUCCESS:
    state = Object.assign({}, state, { searchFieldMostUsedValues: action.data });
    return state;
  case ActionTypes.CLEAR_STATISTICS:
    return {};
  default:
    return state;
  }
}

export default guestFieldStatistics;