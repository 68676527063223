const withDatabaseDefaultRegionRestriction = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => ({ ...props }) => {
  if (window.ReactGlobalProps.is_execution_context_using_default_region) {
    return <Component {...props as P} />;
  } else {
    return <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div className="card text-center mt-10" style={{ width: "50%" }}>
        <div className="card-header">
          <div className="card-title">{ I18n.t("react.only_available_on_default_region_message.title") }</div>
        </div>
        <div className="card-body">{ I18n.t("react.only_available_on_default_region_message.message") }</div>
      </div>
    </div>;
  }
};

export default withDatabaseDefaultRegionRestriction;
