import { CALL_API, ActionTypes } from "../constants/Constants";
import { LeadQualificationField } from "../types/LeadQualificationField";

export function fetchMobinetworkConfiguration(eventId: string): any {
  const { FETCH_MOBINETWORK_CONFIGURATION_REQUEST, FETCH_MOBINETWORK_CONFIGURATION_SUCCESS, DEV_NULL } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_MOBINETWORK_CONFIGURATION_REQUEST, FETCH_MOBINETWORK_CONFIGURATION_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/events/${eventId}/mobinetwork.json`,
    }
  };
}

export function updateMobinetworkConfiguration(eventId: string, attributes: any, notificationsOptions: any = {}): any {
  const { UPDATE_MOBINETWORK_CONFIGURATION_REQUEST, UPDATE_MOBINETWORK_CONFIGURATION_SUCCESS, UPDATE_MOBINETWORK_CONFIGURATION_ERROR } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_MOBINETWORK_CONFIGURATION_REQUEST, UPDATE_MOBINETWORK_CONFIGURATION_SUCCESS, UPDATE_MOBINETWORK_CONFIGURATION_ERROR],
      method: "PUT",
      endpoint: `/events/${eventId}/mobinetwork.json`,
      body: { mobinetwork_configuration: attributes },
      notificationsOptions,
    }
  };
}

export function leadQualificationFieldsSoftUpdate(leadQualificationFields: LeadQualificationField[]): any {
  const { LEAD_QUALIFICATION_FIELDS_SOFT_UPDATE } = ActionTypes;
  return { data: { lead_qualification_fields: leadQualificationFields }, type: LEAD_QUALIFICATION_FIELDS_SOFT_UPDATE };
}
