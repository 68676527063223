import { cloneElement, Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import classNames from "classnames";

import { FormItemTypes } from "../constants/Constants";
import RegistrationFormStep from "./RegistrationFormStep.react";
import QuestionFormItem from "../components/form_item_question/QuestionFormItem.react";
import FormParagraph from "../components/FormParagraph.react";
import FormTitle from "../components/FormTitle.react";
import FormImage from "../components/FormImage.react";
import SubForm from "../components/SubForm.react";
import TicketsSelector from "../components/TicketsSelector.react.tsx";
import ErrorMessage from "../components/shared/ErrorMessage.react";

import { availableGuestFieldsByType } from "../utils/guestFieldsUtils.js";
import { itemsByStep, stepBeingEdited, itemBeingEdited } from "../utils/itemsBySectionUtils.js";
import { createGuestField, fetchGuestFieldTypes, fetchEventGuestFields } from "../actions/GuestFieldsActionCreators";

import { fetchAccesspoints } from "../actions/AccesspointActionCreators";
import { requestEvent } from "../actions/ImportActionCreators";
import {
  newRegistrationFormItem,
  destroyRegistrationFormItem,
  updateRegistrationFormItem,
  changeRegistrationFormItemType,
  toggleRegistrationFormItemMode,
  createFormItems,
  displayError
} from "../actions/RegistrationFormItemsActionCreators";

import TabRegistrationForm from "../components/registration_form/TabRegistrationForm.react";

import { toggleRegistrationFormStepFocus, updateRegistrationFormStep } from "../actions/RegistrationFormStepActionCreators";
import { fetchThematics } from "../actions/ThematicsActionCreators";

import { DragTypes } from "../constants/Constants";
import Sortable from "../components/Sortable.react";

class RegistrationFormItems extends Component {

  constructor(props) {
    super(props);
    [
      "canUnfocusItem",
      "changeItemType",
      "changeTypeAndUpdate",
      "createGuestField",
      "destroyFormItemOption",
      "destroyItem",
      "handleDrop",
      "renderTabs",
      "toggleItemMode",
      "updateFormItem",
      "updateItem"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.itemUpdates = [];
    this.isUpdatingFormItem = false;
  }

  componentDidMount() {
    const { fetchAccesspoints, fetchEventGuestFields, fetchGuestFieldTypes, eventId, accesspointsFetched, guestFieldsTypes, requestEvent } = this.props;
    if (!accesspointsFetched) fetchAccesspoints(eventId);
    fetchEventGuestFields(eventId);
    if (guestFieldsTypes.length == 0) fetchGuestFieldTypes(eventId);
    requestEvent();
  }

  componentDidUpdate(prevProps) {
    const { items: prevItems } = prevProps;
    const itemWasJustUpdated = this.props.items.find(item => {
      const oldItem = prevItems.find(it => it._id == item._id);
      // if no oldItem present it's because we just created the item and the id changed from "temp_xxxx" to an objectId
      return !oldItem || oldItem.nbUpdated != item.nbUpdated;
    });
    if (itemWasJustUpdated) {
      this.isUpdatingFormItem = false;
      this.updateFormItem(this.props);
    }

    const { items, steps } = this.props;
    const currentStep = stepBeingEdited(steps);
    const prevCurrentStep = stepBeingEdited(prevProps.steps);

    // No need to scroll to focused form item if editing form step info
    if (currentStep && prevCurrentStep && (currentStep.updated_at != prevCurrentStep.updated_at || currentStep.errors)) {
      return;
    }

    window.requestAnimationFrame(() => {
      const itemRef = itemBeingEdited(items);
      // When there is no item being edited in the step
      if (!itemRef) {
        return;
      }
      const ref = itemRef._id;
      const node = this.refs[ref];
      if (node) {
        ReactDOM.findDOMNode(node).scrollIntoView({ block: "center", behavior: "smooth" });
      }
    });
  }

  handleDrop(previousItemId, stepId, nextItemId, estimatedIndex) {
    if (!nextItemId) return;

    const { updateRegistrationFormStep, formId } = this.props;
    updateRegistrationFormStep(formId, stepId, { "rank": estimatedIndex });
  }

  updateFormItem(props = this.props) {
    const { updateRegistrationFormItem, formId, items } = props;
    if (this.itemUpdates.length > 0 && !this.isUpdatingFormItem) {
      this.isUpdatingFormItem = true;
      const { updateParams, sendFile, optimistic, itemId } = this.itemUpdates.shift();
      const item = items.find(item => { return item._id == itemId; });
      updateRegistrationFormItem(formId, item, updateParams, sendFile, optimistic);
    }
  }

  updateItem(item, sendFile = false, optimistic = false) {
    return (updateParams, forceOptimistic = false) => {
      if (forceOptimistic) {
        optimistic = true;
      }
      this.itemUpdates.push({ itemId: item._id, updateParams, sendFile, optimistic });
      this.updateFormItem();
    };
  }

  destroyItem(item) {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { destroyRegistrationFormItem, formId, locale } = this.props;
      destroyRegistrationFormItem(formId, item, locale);
    };
  }

  destroyFormItemOption(itemId) {
    return (optionId) => {
      const { destroyRegistrationFormItemOption, formId, locale } = this.props;
      destroyRegistrationFormItemOption(formId, itemId, optionId, locale);
    };
  }

  changeItemType(formItem) {
    return (newType) => {
      const { changeRegistrationFormItemType, formId, locale } = this.props;
      changeRegistrationFormItemType(formId, formItem, newType, locale);
    };
  }

  changeTypeAndUpdate() {
    return (newType, formItemUpdated, keepGuestField = false) => {
      const { changeRegistrationFormItemType, formId, locale } = this.props;
      changeRegistrationFormItemType(formId, formItemUpdated, newType, locale, keepGuestField);
    };
  }

  toggleItemMode(item) {
    return () => {
      if (item.mode === "edit") {
        return;
      }
      const { toggleRegistrationFormItemMode } = this.props;
      if (this.canUnfocusItem()) {
        toggleRegistrationFormItemMode(item);
      }
    };
  }

  canUnfocusItem() {
    const { displayError, items } = this.props;
    const itemBeingEditedVar = itemBeingEdited(items);
    if (itemBeingEditedVar && itemBeingEditedVar.error) {
      displayError(itemBeingEditedVar);
      return false;
    }
    return true;
  }

  injectCommonItemProps(item, itemComponent) {
    const component = cloneElement(itemComponent, {
      formItem: item,
      destroyHandler: this.destroyItem(item),
      updateHandler: this.updateItem(item),
      mode: item.mode || "read",
      allGuestFields: this.props.guestFields
    });

    const errorMessage = (item.error && item.highlightError || item.mode == "edit" && item.companyFieldsErrors) ? (
      <ErrorMessage errors={item.error || item.companyFieldsErrors} />
    ) : null;

    const classes = classNames({
      focused: item.mode == "edit",
      "col-12 p-10": true
    });

    return (
      <div className="row" key={item._id}>
        <div className={classes}
          onClick={this.toggleItemMode(item)}
          key={item._id || item.rank}
          ref={item._id}
          style={{ marginBottom: "10px" }}>
          {errorMessage}
          {component}
        </div>
      </div>
    );
  }

  renderItem(item) {
    const { guestFields, guestFieldsTypes, guestFieldErrors, items, sections, steps, locale, event,
      hasInvalidAccessPrivileges, createFormItems, formId, guestCategories, ticketingCategory,
      thematics, fetchThematics } = this.props;
    let component = <div> { item.type } has no UI yet </div>;

    switch (item.type) {
    case "paragraph":
      component = <FormParagraph />;
      break;
    case "title":
      component = <FormTitle />;
      break;
    case "image":
      component = <FormImage updateFileHandler={this.updateItem(item, true)} />;
      break;
    case "subform":
      component = <SubForm guestCategories={guestCategories} registrationFormId={formId} parentFormItems={items} parentSections={sections} parentSteps={steps} eventLocale={event.locale} />;
      break;
    case "tickets_selector":
      component = <TicketsSelector ticketingCategory={ticketingCategory} />;
      break;
    }

    const createFormItemsWithParams = (formItemsToCreate) => {
      const formItemsToCreateWithSectionId = formItemsToCreate.map(formItem => {
        formItem.form_section_id = item.form_section_id;
        return formItem;
      });
      return createFormItems(formId, formItemsToCreateWithSectionId, locale);
    };
    if (FormItemTypes.includes(item.type)) {
      const { accesspoints, items, eventId, event } = this.props;

      component = <QuestionFormItem
        changeTypeHandler={this.changeItemType(item)}
        changeTypeAndUpdateHandler={this.changeTypeAndUpdate()}
        hasInvalidAccessPrivileges={hasInvalidAccessPrivileges}
        availableGuestFields={availableGuestFieldsByType(guestFields, items, sections, steps, item)}
        accesspoints={accesspoints}
        destroyFormItemOptionHandler={this.destroyFormItemOption(item._id)}
        guestFieldsTypes={guestFieldsTypes}
        guestFieldErrors={guestFieldErrors}
        event={event}
        eventId={eventId}
        allItems={items}
        locale={locale}
        createFormItems={ createFormItemsWithParams }
        createGuestField={ this.createGuestField }
        thematics={thematics}
        fetchThematics={fetchThematics}
      />;
    }
    return this.injectCommonItemProps(item, component);
  }

  createGuestField(guestFieldData) {
    const { createGuestField, eventId } = this.props;
    createGuestField(eventId, guestFieldData);
  }

  renderStep(step) {
    const { steps } = this.props;
    return (
      <div key={step._id} ref={step.focused ? step._id : ""}>
        <RegistrationFormStep
          step={step}
          stepsCount={steps.length}
          itemsCount={step.itemsCount}
          steps={steps}
        />
        <div className="clearfix" />
      </div>
    );
  }

  renderItems() {
    const { sections, steps } = this.props;
    const items = itemsByStep(this.props.items, sections, stepBeingEdited(steps));
    const itemsHtml = items.map((item, i) => {
      if (item.type === "step") {
        return this.renderStep(item, i === 0);
      }
      return this.renderItem(item);
    });

    return itemsHtml;
  }

  renderTabs() {
    const { steps, toggleRegistrationFormStepFocus } = this.props;
    const formSteps = steps.sort((a, b) => (a.rank > b.rank) ? 1 : -1).map((step, index) => {
      return (
        <div key={index}>
          <TabRegistrationForm
            step={step}
            currentStep={index + 1}
            canUnfocusItem={this.canUnfocusItem}
            toggleStepFocus={toggleRegistrationFormStepFocus}
          />
        </div>
      );
    });
    return (
      <Sortable
        itemIdKey="_id"
        itemIndexKey="rank"
        dragType={DragTypes.FORM_STEP}
        items={steps}
        handleDrop={this.handleDrop}
        fullyDraggable={true}
        additionalClasses="col-md-3 col-lg-2 col-sm-4 col-12"
        horizontalDragging={true}
        wrapperClass="row"
      >
        {formSteps}
      </Sortable>
    );
  }

  render() {
    const { accesspointsFetched } = this.props;
    if (!accesspointsFetched) return <div></div>;

    return (
      <div className="col-9 full-height-form" ref="registration-form-container">
        {this.renderTabs()}
        { this.renderItems() }
      </div>
    );
  }
}

RegistrationFormItems.propTypes = {
  formId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  navBarHeight: PropTypes.number.isRequired,
  createStep: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    accesspoints: state.accesspoints.data,
    accesspointsFetched: state.accesspoints.fetched,
    guestFields: state.guestFields.guestFields,
    guestFieldsTypes: state.guestFields.types,
    guestFieldErrors: state.guestFields.errors,
    thematics: state.thematics.items,
    event: state.event
  };
}

const mapDispatchToProps = {
  updateRegistrationFormItem,
  destroyRegistrationFormItem,
  changeRegistrationFormItemType,
  toggleRegistrationFormItemMode,
  displayError,
  fetchAccesspoints,
  fetchEventGuestFields,
  newRegistrationFormItem,
  fetchGuestFieldTypes,
  createGuestField,
  toggleRegistrationFormStepFocus,
  createFormItems,
  requestEvent,
  updateRegistrationFormStep,
  fetchThematics
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationFormItems);
