import { Component } from "react";
import CodeEditor from "../shared/CodeEditor.react";

class FormCodeEdit extends Component {

  constructor(props) {
    super(props);
    const privateMethods = [
      "_onBodyChange",
      "_handleSubmitWithBtn",
      "_saveBody",
      "_handleSubmitWithKeyboard"
    ];
    privateMethods.forEach((item) => { this[item] = this[item].bind(this); });
    this.state = { body: props.content };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content) {
      this.setState({ body: this.props.content });
    }
  }

  _onBodyChange(newBody) {
    this.setState({ body: newBody });
  }

  _handleSubmitWithBtn(e) {
    e.preventDefault();
    this._saveBody();
  }

  _handleSubmitWithKeyboard() {
    this._saveBody();
  }

  _saveBody() {
    const { handleSave } = this.props;
    const { body } = this.state;
    handleSave({ body });
  }

  render() {
    const { body } = this.state;
    return (
      <form className="code-editor code-editor-custom" onSubmit={this._handleSubmitWithBtn}>
        <CodeEditor content={body || ""} onContentChange={this._onBodyChange} onSaveWithKeyboard={this._handleSubmitWithKeyboard} />
        <input className="btn btn-primary float-end mt-10" type="submit" value={I18n.t("react.guest_campaign.save_and_preview")} />
      </form>
    );
  }

}

export default FormCodeEdit;
