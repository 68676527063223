import { Component } from "react";
import FormFileDrop from "../components/FormFileDrop.react";
import BottomOptions from "./form_item_question/BottomOptions.react";
import conditionalDisplay from "../utils/conditionalDisplayUtils.js";

class FormImage extends Component {

  constructor(props) {
    super(props);
    [
      "handleDimensionPropChange",
      "handleDimensionPropBlur",
      "receiveDroppedFile",
      "updateDisplay"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = {
      currentWidth: props.formItem.options.width,
      currentHeight: props.formItem.options.height,
      currentImage: props.formItem.image_url,
      display: {
        conditional_display: this.props.formItem.display_conditions.length > 0
      }
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.formItem.image_url !== prevState.currentImage) {
      return { currentImage: nextProps.formItem.image_url };
    }
    return null;
  }

  handleDimensionPropChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleDimensionPropBlur(e) {
    const value = e.target.value;
    const dimensionProperty = e.target.name;
    // when value is missing : auto is default
    // when unit is missing : px is default
    const dimensionValue = value == "" ? "auto" : (isNaN(value) ? value : `${value}px`);

    this.setState({ [dimensionProperty]: dimensionValue }, () => {
      this.updateImageOptions();
    });
  }

  updateImageOptions() {
    const { updateHandler } = this.props;
    updateHandler({
      options: {
        height: this.state.currentHeight,
        width: this.state.currentWidth
      }
    });
  }

  receiveDroppedFile(file) {
    const { updateFileHandler } = this.props;
    let data = new FormData();
    data.append("form_item[image]", file);
    updateFileHandler(data);
  }

  updateDisplay(newDisplay) {
    this.setState({ display: newDisplay });
  }

  render() {
    const { mode, updateHandler, destroyHandler, formItem, renderConditionalDisplay } = this.props;
    const { currentImage, currentWidth, currentHeight, display } = this.state;
    const imgStyle = { maxWidth: "100%", width: currentWidth, maxHeight: currentHeight };

    if (mode == "edit") {
      return (
        <form>
          <div>
            <div className="mb-3 row align-items-center">
              <div className="col-auto">
                <div className="row g-2 row align-items-center">
                  <label className="col-form-label col-auto">{I18n.t("react.form_items.form_image.width")}</label>
                  <div className="col-auto">
                    <input type="text"className="form-control col-auto" name="currentWidth" value={currentWidth} onChange={this.handleDimensionPropChange} onBlur={this.handleDimensionPropBlur} />
                  </div>
                </div>
              </div>

              <div className="col-auto">
                <div className="row g-2 row align-items-center">
                  <label className="col-form-label col-auto">{I18n.t("react.form_items.form_image.height")}</label>
                  <div className="col-auto">
                    <input type="text" className="form-control" name="currentHeight" value={currentHeight} onChange={this.handleDimensionPropChange} onBlur={this.handleDimensionPropBlur} />
                  </div>
                </div>
              </div>
            </div>

            <FormFileDrop
              file={currentImage}
              dropHandler={this.receiveDroppedFile}
              acceptedFiles="image/*"
            />
          </div>
          { display.conditional_display && renderConditionalDisplay() }
          <BottomOptions
            formItem={formItem}
            display={display}
            destroyHandler={destroyHandler}
            updateHandler={updateHandler}
            updateParentDisplay={this.updateDisplay} />
        </form>
      );
    } else if (mode == "read") {
      const content = currentImage ? <img src={currentImage} style={imgStyle} /> : <em>{I18n.t("react.form_items.empty_image")}</em>;
      return (
        <div className="text-center">
          <p>{content}</p>
        </div>
      );
    }
  }
}

export default conditionalDisplay(FormImage);
