import { useState } from "react";
import { Program } from "../../types/Program";
import Filter from "../Filter.react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ConfirmationModal from "../shared/ConfirmationModal.react";

interface Props {
  currentProgramId: string;
  programs: Program[];

  deleteProgram: (programId: string) => void;
  setCurrentProgramId: (programId: string) => void;
  toggleNewProgramModal: () => void;
}

function i18n(key: string, opts = {}): string {
  return I18n.t(`react.programs.programs_list.${key}`, opts);
}

const ProgramsList: React.FC<Props> = ({
  currentProgramId,
  programs,
  setCurrentProgramId,
  deleteProgram,
  toggleNewProgramModal
}) => {
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);

  const onProgramChange = (result): void => {
    const { selectedItemIds } = result;
    if (selectedItemIds) {
      setCurrentProgramId(selectedItemIds[0]);
    }
  };

  const toggleConfirmationModalVisible = (): void => {
    setConfirmationModalVisible(!confirmationModalVisible);
  };

  const handleDelete = (): void => {
    deleteProgram(currentProgramId);
    toggleConfirmationModalVisible();
  };

  const renderAreYouSureModal = (): JSX.Element => {
    return <ConfirmationModal
      isVisible={confirmationModalVisible}
      onClose={toggleConfirmationModalVisible}
      onConfirm={handleDelete}
      title={`<div class='text-center'><i class='fa-regular fa-triangle-exclamation mr-5'></i>${I18n.t("warning")}</div>`}
      confirmationQuestion={`<div class='text-center'>${i18n("modal_confirmation_delete")}</div>`}
      restoreFocus={false}
    />;
  };

  const renderProgramUses = (program: Program): JSX.Element => {
    const { uses_count } = program;

    if (uses_count === 0) return null;

    return <span className="badge rounded-pill bg-secondary ml-5">{uses_count}</span>;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderActionButtons = (program: Program): JSX.Element => {
    return <div className="action-buttons">
      {program.uses_count === 0 ? renderDeleteButton() : renderDisabledDeleteButton()}
    </div>;
  };

  const renderDeleteButton = (): JSX.Element => {
    const tooltipDelete = <Tooltip id="tooltip-delete-program">{i18n("tooltip.delete")}</Tooltip>;

    return <OverlayTrigger placement="top" overlay={tooltipDelete}>
      <a href="#" className="btn btn-link" onClick={toggleConfirmationModalVisible}>
        <i className="fa-regular fa-trash-can"></i>
      </a>
    </OverlayTrigger>;
  };

  const renderDisabledDeleteButton = (): JSX.Element => {
    const tooltipMsg = <Tooltip id="tooltip-delete-program">{I18n.t("mongoid.errors.models.program.not_deletable")}</Tooltip>;

    return <OverlayTrigger placement="top" overlay={tooltipMsg}>
      <span>
        <a href="#" className="btn btn-link disabled">
          <i className="fa-regular fa-trash-can"></i>
        </a>
      </span>
    </OverlayTrigger>;
  };

  return <div className="programs-list">
    <div className="card mb-0" >
      <div style={{ borderBottom: "1px solid rgb(227, 227, 227)" }}>
        <Filter
          items={programs}
          selectedItemIds={[currentProgramId]}
          showCells={false}
          hasSelectAll={false}
          multipleSelect={false}
          sortItems={false}
          selectedFirst={false}
          itemIdKey="_id"
          onChange={onProgramChange}
          allowUnselect={false}
          actionButtons={renderActionButtons}
          roundUpTheTopOfFirstOption={true}
          additionalInfo={renderProgramUses}
        />
      </div>
      <div className="mt-10 mb-10 ml-10 mr-10 text-center">
        <a href="#" className="btn btn-primary" onClick={toggleNewProgramModal}>
          <i className="fa-regular fa-plus"></i> {i18n("create_a_new_program")}
        </a>
      </div>
    </div>
    {renderAreYouSureModal()}
  </div>;
};

export default ProgramsList;
