import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import GuestCategoryLabel from "./GuestCategoryLabel.react";
import Loader from "../shared/Loader.react";

class BeforeDestroyCategoryModal extends Component {
  constructor(props) {
    super(props);
    [
      "confirmDeletion"
    ].forEach(fn => {
      this[fn] = this[fn].bind(this);
    });

    this.state = {};
  }

  i18n(key, options = {}) {
    const { i18nPrefix } = this.props;

    return I18n.t(`${i18nPrefix}.${key}`, options);
  }

  confirmDeletion(e) {
    const { onConfirm } = this.props;

    onConfirm(e, this.selectedCategoryId.value);
  }

  targetableGuestCategories() {
    const { guestCategories, guestCategory } = this.props;

    if (guestCategories.length == 0)
      return [];

    let newGuestCategories = guestCategories.slice();

    if (guestCategory.moderated_registrations_enabled)
      newGuestCategories = newGuestCategories.filter(category => category.moderated_registrations_enabled);

    return newGuestCategories.filter(category => category._id != guestCategory._id);
  }

  renderPeopleNumberWarning() {
    const { count } = this.props;

    return <span dangerouslySetInnerHTML={{ __html: this.i18n("guests_number_warning", { count: count }) }}></span>;
  }

  renderGuestCategoriesSelect() {
    const { guestCategories, count } = this.props;

    if (guestCategories.length == 0)
      return <Loader size="small" />;

    const targetableGuestCategories = this.targetableGuestCategories();

    if (targetableGuestCategories.length == 0)
      return <div className="alert alert-danger">{ I18n.t("remaining_guests", { count: count }) }</div>;

    return <select className="form-select" ref={(input) => this.selectedCategoryId = input}>
      { targetableGuestCategories.map(category => {
        return <option value={category._id} key={category._id}>{category.name}</option>;
      }) }
    </select>;
  }

  render() {
    const { isVisible, guestCategory, onClose, onEnter, count } = this.props;

    if (!isVisible)
      return null;

    return <Modal show={true} onEntered={onEnter} onHide={onClose} autoFocus>
      <Modal.Header>
        <Modal.Title>{this.i18n("delete_category")}</Modal.Title>
        <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
      </Modal.Header>
      <Modal.Body>
        <p>{this.i18n("delete_info")}<GuestCategoryLabel guestCategory={guestCategory} isLink={false} cssClasses="big guest-category ml-5 mr-5" /></p>
        <p>{ count && this.renderPeopleNumberWarning() || <Loader size="small" /> }</p>
        <p>{ this.i18n("before_delete") }</p>
        { this.renderGuestCategoriesSelect() }
      </Modal.Body>
      <Modal.Footer>
        <input type="submit" onClick={this.confirmDeletion} disabled={this.targetableGuestCategories().length == 0} value={this.i18n("confirm")} className="btn btn-danger btn-wrap" />
      </Modal.Footer>
    </Modal>;
  }
}

BeforeDestroyCategoryModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  guestCategory: PropTypes.object.isRequired,
  count: PropTypes.number,
  i18nPrefix: PropTypes.string,
  onEnter: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

BeforeDestroyCategoryModal.defaultProps = {
  isVisible: false
};

export default BeforeDestroyCategoryModal;
