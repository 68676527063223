import { CALL_API, ActionTypes } from "../constants/Constants";
import { ProgramApiAttributes } from "../types/Program";

export function fetchPrograms(eventId: string): any {
  const { FETCH_PROGRAMS_REQUEST, FETCH_PROGRAMS_SUCCESS, DEV_NULL } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_PROGRAMS_REQUEST, FETCH_PROGRAMS_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/programs.json`,
    }
  };
}

export function createProgram(eventId: string, attributes: ProgramApiAttributes): any {
  const { CREATE_PROGRAM_REQUEST, CREATE_PROGRAM_SUCCESS, CREATE_PROGRAM_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_PROGRAM_REQUEST, CREATE_PROGRAM_SUCCESS, CREATE_PROGRAM_FAILURE],
      method: "POST",
      body: {
        program: attributes
      },
      endpoint: `/api/v1/events/${eventId}/programs.json`
    }
  };
}

export function updateProgram(eventId: string, programId: string, attributes: ProgramApiAttributes, notificationsOptions = {}): any {
  const { UPDATE_PROGRAM_REQUEST, UPDATE_PROGRAM_SUCCESS, UPDATE_PROGRAM_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_PROGRAM_REQUEST, UPDATE_PROGRAM_SUCCESS, UPDATE_PROGRAM_FAILURE],
      method: "PUT",
      body: {
        program: attributes
      },
      endpoint: `/api/v1/events/${eventId}/programs/${programId}.json`,
      notificationsOptions
    }
  };
}

export function deleteProgram(eventId: string, programId: string): any {
  const { DELETE_PROGRAM_REQUEST, DELETE_PROGRAM_SUCCESS, DELETE_PROGRAM_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DELETE_PROGRAM_REQUEST, DELETE_PROGRAM_SUCCESS, DELETE_PROGRAM_FAILURE],
      method: "DELETE",
      endpoint: `/api/v1/events/${eventId}/programs/${programId}.json`
    },
    programId,
  };
}

export function fetchFilterMetadata(eventId: string, filterTypes: string[]): any {
  const { DEV_NULL, FETCH_FILTER_METADATA_REQUEST, FETCH_FILTER_METADATA_SUCCESS } = ActionTypes;
  const filterTypesParams = filterTypes.map(filterType => `types[]=${filterType}`).join("&");

  return {
    [CALL_API]: {
      types: [FETCH_FILTER_METADATA_REQUEST, FETCH_FILTER_METADATA_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/programs/filter_metadata.json?${filterTypesParams}`
    }
  };
}
