import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

class MapGuestCategoryModal extends Component {
  constructor(props) {
    super(props);
    [
      "submit",
      "renderGuestCategorySelect",
      "renderSubmitButton",
      "toggleOption",
      "onChange"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = { selectedGuestCategory: "", overrideGuestCategoryOfExistingGuests: false };
  }

  onChange(e) {
    this.setState({ selectedGuestCategory: e.target.value });
  }

  toggleOption() {
    const { overrideGuestCategoryOfExistingGuests } = this.state;
    this.setState({ overrideGuestCategoryOfExistingGuests: !overrideGuestCategoryOfExistingGuests });
  }

  submit() {
    const { updateParent } = this.props;
    const { selectedGuestCategory, overrideGuestCategoryOfExistingGuests } = this.state;
    updateParent(overrideGuestCategoryOfExistingGuests, selectedGuestCategory);
  }

  renderGuestCategorySelect() {
    const { guestCategories } = this.props;
    const { selectedGuestCategory } = this.state;
    const options = guestCategories.map(guestCategory => {
      return (<option value={guestCategory._id} key={guestCategory._id}>{guestCategory.name}</option>);
    });
    return (
      <div className="mb-3">
        <label htmlFor="sel1" className="form-label">{I18n.t("guest_import_operations.modal_map_guest_category.prompt")}</label>
        <select className="form-select" id="sel1" value={selectedGuestCategory} onChange={this.onChange}>
          <option value="" key="default">{I18n.t("guest_import_operations.modal_guest_category.prompt")}</option>
          {options}
        </select>
      </div>
    );
  }

  renderSubmitButton() {
    const { selectedGuestCategory } = this.state;
    if (!selectedGuestCategory) {
      return (
        <button type="button" className="btn btn-primary" id="submit-guest-categories" onClick={this.submit} disabled>
          {I18n.t("guest_import_operations.modal_map_guest_category.submit_mapping")}
        </button>
      );
    } else {
      return (
        <button type="button" className="btn btn-primary" id="submit-guest-categories" onClick={this.submit}>
          {I18n.t("guest_import_operations.modal_map_guest_category.submit_mapping")}
        </button>
      );
    }
  }

  render() {
    const { onCloseFunction, isVisible } = this.props;
    const { overrideGuestCategoryOfExistingGuests } = this.state;
    return (
      <Modal show={isVisible} onHide={onCloseFunction}>
        <Modal.Header>
          <Modal.Title>{I18n.t("guest_import_operations.modal_map_guest_category.no_guest_category_found_html")}</Modal.Title>
          <button type="button" onClick={onCloseFunction} className="btn-close" aria-label={I18n.t("close")}></button>
        </Modal.Header>
        <Modal.Body >
          {this.renderGuestCategorySelect()}
          <div className="form-check">
            <label className="form-check-label">
              <input type="checkbox" className="form-check-input" value={overrideGuestCategoryOfExistingGuests} onClick={this.toggleOption}></input>
              {I18n.t("guest_import_operations.modal_map_guest_category.override_guest_category_of_existing_guests")}
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.renderSubmitButton()}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MapGuestCategoryModal;

MapGuestCategoryModal.propTypes = {
  updateParent: PropTypes.func.isRequired,
  onCloseFunction: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  guestCategories: PropTypes.array.isRequired
};

MapGuestCategoryModal.defaultProps = {
};
