import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { SessionType } from "../types/SessionType";

const SessionTypeValueLabel = (sessionTypeProps): JSX.Element => {
  return <div className="badge rounded-pill" style={{ backgroundColor: sessionTypeProps.data.color }}>
    <span>{sessionTypeProps.data.label}</span>
  </div>;
};

interface Option {
  value: string;
  label: string;
  color: string;
  __isNew__: boolean;
}

interface Props {
  value: string;
  sessionTypes: SessionType[];

  onSelect: (options: Option[]) => void;
  onCreate?: (value: string) => void;
}

const SessionTypesDropdownComponent: React.FC<Props> = (props) => {
  const i18n = (key: string, options = {}): string => {
    return I18n.t(`react.accesspoint.form.${key}`, options);
  };

  const onChange = (options: Option[]): void => {
    const { onSelect } = props;
    onSelect(options);
  };

  const renderSessionTypeOption = (option: Option): JSX.Element => {
    if (option.__isNew__) {
      return <span>{option.label}</span>;
    }

    return <span className="badge rounded-pill" style={{ backgroundColor: option.color, float: "none" }}>
      {option.label}
    </span>;
  };

  const selectedSessionTypeObject = (): any => {
    const { value, sessionTypes } = props;
    const selectedSessiontype = sessionTypes.filter((st) => st._id === value)[0];
    if (!selectedSessiontype) return null;

    return {
      value: selectedSessiontype._id,
      label: selectedSessiontype.name,
      color: selectedSessiontype.color
    };
  };

  const sessionTypesOptions = (): object => {
    const { sessionTypes } = props;
    if (!sessionTypes) return null;

    return sessionTypes.map((st) => {
      return {
        value: st._id,
        label: st.name,
        color: st.color,
      };
    });
  };

  const reactSelectProps = {
    className: "react-select",
    classNamePrefix: "react-select",
    isMulti: false,
    closeMenuOnSelect: false,
    options: sessionTypesOptions(),
    onChange: onChange,
    value: selectedSessionTypeObject(),
    formatOptionLabel: renderSessionTypeOption,
    components: {
      SingleValue: SessionTypeValueLabel
    },
    hideSelectedOptions: false,
    isClearable: false,
  };

  const { onCreate } = props;

  return (onCreate) ? (
    <CreatableSelect
      {...reactSelectProps}
      onCreateOption={onCreate}
      placeholder={i18n("create_select_placeholder")}
      noOptionsMessage={(): string => i18n("enter_first_session_type")}
      formatCreateLabel={(label: string): string => i18n("create_session_type", { label: label })}
    />
  ) : (
    <Select
      {...reactSelectProps}
      placeholder={i18n("select_or_search_placeholder")}
      noOptionsMessage={():string => i18n("no_session_types")}
    />
  );
};

export default SessionTypesDropdownComponent;
