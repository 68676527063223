import { CALL_API, ActionTypes } from "../constants/Constants";

export function fetchTranslations(eventId, parentId) {
  const { FETCH_TRANSLATIONS, FETCH_TRANSLATIONS_SUCCESS, FETCH_TRANSLATIONS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_TRANSLATIONS, FETCH_TRANSLATIONS_SUCCESS, FETCH_TRANSLATIONS_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/translations.json?translatable_parent_id=${parentId}`
    }
  };
}

export function fetchTranslatableFields(endpoint) {
  const { DEV_NULL, FETCH_TRANSLATABLE_FIELDS_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, FETCH_TRANSLATABLE_FIELDS_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint
    }
  };
}

export function updateTranslation(eventId, id, params, notificationsOptions = {}, redirectTo) {
  const { UPDATE_TRANSLATIONS, UPDATE_TRANSLATIONS_SUCCESS, UPDATE_TRANSLATIONS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_TRANSLATIONS, UPDATE_TRANSLATIONS_SUCCESS, UPDATE_TRANSLATIONS_FAILURE],
      method: "PUT",
      body: { translation: params },
      endpoint: `/api/v1/events/${eventId}/translations/${id}.json`,
      notificationsOptions,
      redirectTo
    }
  };
}

export function createTranslation(eventId, params, notificationsOptions = {}, redirectTo) {
  const { CREATE_TRANSLATIONS, CREATE_TRANSLATIONS_SUCCESS, CREATE_TRANSLATIONS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_TRANSLATIONS, CREATE_TRANSLATIONS_SUCCESS, CREATE_TRANSLATIONS_FAILURE],
      method: "POST",
      body: { translation: params },
      endpoint: `/api/v1/events/${eventId}/translations.json`,
      notificationsOptions,
      redirectTo
    }
  };
}

export function autoTranslation(eventId, params, notificationsOptions = {}) {
  const { AUTO_TRANSLATIONS, AUTO_TRANSLATIONS_SUCCESS, AUTO_TRANSLATIONS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [AUTO_TRANSLATIONS, AUTO_TRANSLATIONS_SUCCESS, AUTO_TRANSLATIONS_FAILURE],
      method: "PUT",
      body: { translation: params },
      endpoint: `/api/v1/events/${eventId}/translations/auto_translate.json`,
      notificationsOptions
    }
  };
}
export function deleteTranslation(eventId, params, notificationsOptions = {}) {
  const { DELETE_TRANSLATIONS, DELETE_TRANSLATIONS_SUCCESS, DELETE_TRANSLATIONS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DELETE_TRANSLATIONS, DELETE_TRANSLATIONS_SUCCESS, DELETE_TRANSLATIONS_FAILURE],
      method: "DELETE",
      body: { translation: params },
      endpoint: `/api/v1/events/${eventId}/translations/${params["id"]}`,
      notificationsOptions
    }
  };
}

export function selectTranslation(locale) {
  return {
    type: ActionTypes.SELECT_TRANSLATION,
    locale
  };
}

export function clearState() {
  return {
    type: ActionTypes.CLEAR_TRANSLATION_STATE
  };
}

export function offlineSetTranslationTable(table) {
  return {
    type: ActionTypes.OFFLINE_SET_TRANSLATION_TABLE,
    table
  };
}
