import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import filter from "lodash/filter";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import querystring from "querystring";

import { updateUI } from "../actions/UIAppearanceActionCreators";
import { CalculatedType, LazyCalculatedType, BulkEditableStandardsFields } from "../constants/Constants";

import { urlWithQuery } from "../utils/pathUtils";
import withModal from "./withModal.react";

class GuestFields extends Component {
  constructor(props) {
    super(props);
    this._handleSearchChanges = this._handleSearchChanges.bind(this);
    this.state = {
      search: ""
    };
  }

  componentDidMount() {
    const { updateUI } = this.props;
    updateUI({ "modalTitle": I18n.t("react.reports.choose_guest_field") });
  }

  _handleSearchChanges(e) {
    this.setState({ search: e.target.value });
  }

  fieldMatchesSearch(field) {
    const { search } = this.state;
    const lowerCaseSearch = search.toLowerCase();

    if (search === "") return true;

    return field.label.toLowerCase().includes(lowerCaseSearch) || field.key.toLowerCase().includes(lowerCaseSearch);
  }

  renderStandardFieldsList() {
    const fields = map(BulkEditableStandardsFields, (key) => {
      return { key, label: I18n.t(`mongoid.attributes.guest.${key}`) };
    }).filter(field => this.fieldMatchesSearch(field)).sort((a, b) => {
      return a.label < b.label ? -1 : 1;
    });

    return map(fields, (field, index) => {
      return this.renderFieldLine(field, index);
    });
  }

  renderGuestFieldsList() {
    const { guestFields } = this.props;
    const fields = guestFields.filter(field => {
      // if '_id' property is present, this means the field is a metadata and not a native field
      return Object.keys(field).includes("_id") && this.fieldMatchesSearch(field);
    });

    return map(fields, (field, index) => {
      return this.renderFieldLine(field, index);
    });
  }

  renderFieldLine(field, index) {
    const { location } = this.props;
    const { q } = querystring.parse(location.search.substring(1));

    return (
      <li key={`${field.key}-${index}`}>
        <Link to={urlWithQuery(q, `guests/modal/guest_fields/${field.key}`)}>
          {field.label}
        </Link> { " " }
        <code>{field.key}</code>
      </li>
    );
  }

  render() {
    const { search } = this.state;

    return (
      <div>
        <div className="mb-3">
          <input type="text" className="form-control" placeholder={ I18n.t("react.reports.search") } onChange={this._handleSearchChanges} value={search}/>
        </div>
        <ul className="col-md-12 guest-fields-list">
          { this.renderStandardFieldsList() }
          { this.renderGuestFieldsList() }
        </ul>
      </div>
    );
  }
}

GuestFields.propTypes = {
  guestFields: function(props, propName) {
    if (isEmpty(props[propName])) {
      return null;
    }
  }
};

function mapStateToProps(state) {
  return {
    guestFields: filter(state.guestFields.guestFields, field => {
      return ![CalculatedType, LazyCalculatedType].includes(field.type);
    })
  };
}

const mapDispatchToProps = {
  updateUI
};

export default connect(mapStateToProps, mapDispatchToProps)(withModal(GuestFields));
