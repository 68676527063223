import moment from "moment";
import isEmpty from "lodash/isEmpty";

const DATE_FORMAT = "YYYY-MM-DD HH:mm";

function filterOnStartDate(filter: any): any {
  const { field, value } = filter;
  if (!value) return;

  const beginningOfDayOfStartDate = moment(value).startOf("day").format(DATE_FORMAT);

  return { [field]: { "$gte": new Date(beginningOfDayOfStartDate) } };
}

function filterOnEndDate(filter: any): any {
  const { field, value } = filter;
  if (!value) return;

  const endOfDayOfEndDate = moment(value).endOf("day").format(DATE_FORMAT);

  return { [field]: { "$lte": new Date(endOfDayOfEndDate) } };
}

function mapToOids(ids: string[]): any {
  return ids.map(id => { return { "$oid": id }; });
}

function filterOnIdsIn(filter: any): any {
  const { field, value } = filter;
  if (isEmpty(value)) return;
  return { [field]: { "$in": mapToOids(value) } };
}

function filterOnValuesIn(filter: any): any {
  const { field, value } = filter;
  if (isEmpty(value)) return;
  return { [field]: { "$in": value } };
}

function filterOnValueNe(filter: any): any {
  const { field, value } = filter;
  if (isEmpty(value)) return;
  return { [field]: { "$ne": value } };
}

function filterOn(filter: any): any {
  switch (filter.type) {
  case "startDate":
    return filterOnStartDate(filter);
  case "endDate":
    return filterOnEndDate(filter);
  case "idsIn":
    return filterOnIdsIn(filter);
  case "valuesIn":
    return filterOnValuesIn(filter);
  case "valueNe":
    return filterOnValueNe(filter);
  }
}

export function buildFilter(filters: any = []):any {
  const customFilter = filters.reduce((acc, filter) => {
    const newFilter = filterOn(filter);
    if (newFilter) acc.push(newFilter);
    return acc;
  }, []);

  return customFilter.length === 0 ? {} : { "$and": customFilter };
}


