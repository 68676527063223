import { ActionTypes } from "../constants/Constants";

const defaultState = {
  errors: null,
  data: null,
  pendingRequest: false,
  fetched: false
};

function customPreconfiguredEvents(state = defaultState, action): any {
  switch (action.type) {
  case ActionTypes.FETCH_CUSTOM_PRECONFIGURED_EVENTS_REQUEST:
    return { ...defaultState, fetched: false, pendingRequest: true };
  case ActionTypes.FETCH_CUSTOM_PRECONFIGURED_EVENTS_SUCCESS:
    return { ...defaultState, data: action.data, fetched: true, pendingRequest: false };
  default:
    return state;
  }
}

export default customPreconfiguredEvents;
