export function search(key, list, search) {
  return list.filter(element => {
    if (search && search.length < 0 || element[key].toLowerCase().includes(search.toLowerCase())) {
      return element;
    }
  });
}

export function searchHasResult(key, element, search) {
  const result = search(key, [element], search);
  return result.length > 0;
}

export function searchFromSearchableKeywords(list, search) {
  const searchTextTerms = searchTextRegexTerms(search);

  if (searchTextTerms.length == 0)
    return list;

  return list.filter(elt => {
    const searchableKeywords = elt.searchable_keywords.join(" ");
    return searchTextTerms.filter(regex => searchableKeywords.search(regex) === -1).length == 0;
  });
}

function searchTextRegexTerms(search) {
  if (!search) return [];

  const searchTerms = normalizeTextSearch(search).split(" ");

  return searchTerms.reduce(function(acc, term) {
    if (term.length > 0)
      acc.push(new RegExp("\\b" + term.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")));
    return acc;
  }, []);
}

function normalizeTextSearch(search) {
  // Thanks to https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463#37511463
  return search.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
}