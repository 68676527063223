import { ActionTypes } from "../constants/Constants";

function selectedOptins(state = initialState, action) {
  switch (action.type) {
  case ActionTypes.SET_SELECTED_OPTINS: {
    return Object.assign({}, state, { [action.filter]: action.value });
  }
  default:
    return state;
  }
}

const initialState = {
  "event_campaign_optin": null,
  "account_campaign_optin": null,
  "campaign_optin": null
};

export default selectedOptins;
