import useStateFromEndpoint from "../../utils/hooks/useStateFromEndpoint";
import BarChart from "./chart/BarChart.react";
import Loader from "../shared/Loader.react";
import PieChart from "./chart/PieChart.react";
import AtlasChartToExcelLink from "./AtlasChartToExcelLink.react";
import { Dataset, applyColoringStrategy } from "../../types/ChartDataset";

type Type = "bar"|"pie"|"doughnut";

interface Props {
  type: Type;
  title: string;
  subtitle?: string;
  dataEndpoint?: string;
  comparedDataEndpoint?: string;
  chartHeight?: number;
  exportOptions?: any;
  xScaleHelp?: string;

  convertToDataset?: (data: any, isComparisonData?: boolean) => Dataset;
}

const i18n = (key: string): string => {
  return I18n.t(`react.atlas_chart.${key}`);
};

const DEFAULT_CHART_HEIGHT = 500;

const Chart: React.FC<Props> = ({
  type,
  title,
  subtitle,
  dataEndpoint,
  comparedDataEndpoint,
  chartHeight,
  exportOptions,
  xScaleHelp,
  convertToDataset
}) => {
  const postProcessDataHandler = (isComparisonData = false) => {
    return (data: any): Dataset => {
      const dataset = convertToDataset ? convertToDataset(data, isComparisonData) : data;
      return applyColoringStrategy(dataset, isComparisonData);
    };
  };

  const [dataset, dataLoaded] = useStateFromEndpoint<Dataset>(dataEndpoint, {
    canLoad: !!dataEndpoint,
    postProcessData: postProcessDataHandler()
  });

  const [comparedDataset,, comparedDataLoading] = useStateFromEndpoint<Dataset>(comparedDataEndpoint, {
    canLoad: !!comparedDataEndpoint,
    postProcessData: postProcessDataHandler(true)
  });

  const height = `${chartHeight || DEFAULT_CHART_HEIGHT}px`;

  const renderLoader = (): JSX.Element => {
    return <Loader size="large" inline={false} containerHeight={height} />;
  };

  const renderNoData = (): JSX.Element => {
    return <div className="nothing-yet">
      <h4>{i18n("no_data_to_render")}</h4>
    </div>;
  };

  const renderChart = (): JSX.Element => {
    switch (type) {
    case "bar":
      return <BarChart dataset={dataset} comparedDataset={comparedDataset} />;
    case "pie":
      return <PieChart dataset={dataset} />;
    case "doughnut":
      return <PieChart type="doughnut" dataset={dataset} />;
    default:
      return <p>{`Chart type: ${type} not handled 😢`}</p>;
    }
  };

  const renderContent = (): JSX.Element => {
    if (!dataLoaded || comparedDataLoading) return renderLoader();
    if (dataset.data.length === 0) return renderNoData();

    return <>
      {dataset.documents && exportOptions && <AtlasChartToExcelLink chartData={dataset} exportOptions={exportOptions} />}
      <div style={{ height, padding: "0 20px 0px 20px" }}>
        {renderChart()}
      </div>
      <div className="mb-20">
        {xScaleHelp && xScaleHelp}
      </div>
    </>;
  };

  return (
    <div className="text-center">
      <h2 className="mt-20">{title}</h2>
      {subtitle && <h3>{subtitle}</h3>}
      {renderContent()}
    </div>
  );
};

export default Chart;
