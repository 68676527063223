import Select, { components, OptionProps, SingleValueProps, MultiValueContainerProps,
  MultiValueLabelProps } from "react-select";
import { GuestStatuses } from "../../constants/Constants";
import { multiValueRemoveStyle } from "../../constants/Style";

const customStyles = {
  multiValueRemove: (base: any): any => ({
    ...base,
    ...multiValueRemoveStyle
  })
};

const Option = (props: OptionProps): JSX.Element => {
  const { data } = props;

  return <components.Option {...props}>
    <span className="badge rounded-pill bg-secondary">{data.label}</span>
  </components.Option>;
};

const SingleValue = (props: SingleValueProps): JSX.Element => {
  const { data } = props;

  return <components.SingleValue {...props}>
    <span className="badge rounded-pill bg-secondary">{data.label}</span>
  </components.SingleValue>;
};

const MultiValueContainer = (props: MultiValueContainerProps): JSX.Element => {
  const innerProps = { ...props.innerProps, className: props.innerProps.className.concat(" badge rounded-pill bg-secondary") };
  const newProps = { ...props, innerProps };
  return <components.MultiValueContainer {...newProps} />;
};

const MultiValueLabel = (props: MultiValueLabelProps): JSX.Element => {
  return <span>{props.data.label}</span>;
};

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.guest_statuses_dropdown.${key}`, opts);
}

interface Props {
  selectedValue: string[];
  isMulti?: boolean
  isDisabled?: boolean;

  onChange(values: string[]): void;
}

const GuestStatusDropdown: React.FC<Props> = ({ selectedValue, isMulti, isDisabled, onChange }) => {
  const guestStatusesOptions = (): any[] => {
    return GuestStatuses.map((guestStatus) => {
      return {
        value: guestStatus,
        label: I18n.t(guestStatus)
      };
    });
  };

  const options = guestStatusesOptions();
  const selectedGuestStatuses = selectedValue ? options.filter((guestStatus) => selectedValue.includes(guestStatus.value)) : [];

  const props = {
    className: "react-select",
    classNamePrefix: "react-select",
    closeMenuOnSelect: !isMulti,
    components: {
      Option,
      SingleValue,
      MultiValueContainer,
      MultiValueLabel
    },
    hideSelectedOptions: false,
    isClearable: true,
    isMulti,
    isDisabled,
    noOptionsMessage: (): string => i18n("no_options"),
    onChange: (selectedOption): void => onChange(selectedOption),
    options,
    placeholder: i18n("select_placeholder"),
    styles: customStyles,
    value: selectedGuestStatuses
  };

  return <Select {...props} />;
};

export default GuestStatusDropdown;
