import { DragTypes } from "../../constants/Constants";
import Sortable from "../../components/Sortable.react";
import { GuestProductCollectionField } from "../../types/GuestProductCollectionField";

interface Props {
  guestProductCollectionFields: GuestProductCollectionField[];
  onFieldsChange(newGuestProductCollectionFields: GuestProductCollectionField[]): void;
  onFieldFocus(index: number): void;
}

const GuestProductCollectionSortableFields: React.FC<Props> = ({
  guestProductCollectionFields,
  onFieldsChange,
  onFieldFocus
}) => {

  const handleFieldDrop = (_a: any, _b: any, _c: any, _d: any, sortedItems: any): void => {
    // Update rank
    const newGuestProductCollectionFields = sortedItems.map((item, index) => {
      const field = item.item as GuestProductCollectionField;
      field.rank = (index + 1) * 1000;
      return field;
    });

    onFieldsChange(newGuestProductCollectionFields);
  };

  const onDestroyField = (fieldIndex: number): (() => void) => {
    return (): void => {
      const newGuestProductCollectionFields = [...guestProductCollectionFields];
      newGuestProductCollectionFields[fieldIndex].hidden = true;
      onFieldsChange(newGuestProductCollectionFields);
    };
  };

  const sortableFields = guestProductCollectionFields?.map((field, index) => {
    return { ...field, index, rank: field.rank || (index + 1) * 1000 };
  });

  const fields = sortableFields.map((field, index) => {
    return <div key={index} className={`list-group-item ${field.hidden ? "d-none" : ""}`} onClick={(): void => onFieldFocus(index)}>
      <span><i className="fa-solid fa-grip-dots-vertical mr-5"></i></span>
      <span className="title-item">{field.name}</span>
      <button className="float-end btn btn-link" onClick={onDestroyField(index)}><i className="fa-regular fa-trash-can"></i></button>
    </div>;
  });

  return <Sortable itemIdKey="index"
    itemIndexKey="rank"
    dragType={DragTypes.GUEST_PRODUCT_COLLECTION_FIELDS}
    items={sortableFields}
    handleDrop={handleFieldDrop}
    fullyDraggable={true}
  >
    { fields }
  </Sortable>;
};

export default GuestProductCollectionSortableFields;
