import { Component } from "react";
import SlidingPane from "react-sliding-pane";
import EmailTemplates from "./EmailTemplates.react.js";
import TemplatePreviewIframe from "../shared/TemplatePreviewIframe.react";
import { urlEventId } from "../../utils/pathUtils";
import { generateRandomIdentifier } from "../../utils/identifierUtils";
import Icons from "../../constants/Icons";

class EmailTemplateSelector extends Component {

  constructor(props) {
    super(props);
    [
      "openEmailTemplatesList",
      "closeEmailTemplatesList"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      isEmailTemplatesListOpen: false,
      previewIframeKey: generateRandomIdentifier()
    };
  }

  openEmailTemplatesList() {
    this.setState({ isEmailTemplatesListOpen: true });
  }

  closeEmailTemplatesList() {
    const { afterEmailTemplatesClose } = this.props;

    this.setState({ isEmailTemplatesListOpen: false });
    this.enableBodyScroll();
    this.reloadEmailPreview();
    if (afterEmailTemplatesClose) afterEmailTemplatesClose();
  }

  disableBodyScroll() {
    document.body.style.overflow = "hidden";
  }

  enableBodyScroll() {
    document.body.removeAttribute("style");
  }

  reloadEmailPreview() {
    this.setState({ previewIframeKey: generateRandomIdentifier() });
  }

  i18n(key) {
    return I18n.t(`shared.custom_email_fields.${key}`);
  }

  renderChangeTemplateButton() {
    const { selectedEmailTemplateId } = this.props;

    if (!selectedEmailTemplateId)
      return null;

    return <a className="btn btn-secondary btn-sm ml-10" onClick={this.openEmailTemplatesList}>{ this.i18n("change_email_template") }</a>;
  }

  renderEmailTemplatesSlidingPane() {
    const { isEmailTemplatesListOpen } = this.state;
    const { slidingPaneTitle, onSelectTemplate, selectedEmailTemplateId, persistedEmailTemplateId,
      enableDelete, match, location, history } = this.props;

    return (
      <SlidingPane isOpen={isEmailTemplatesListOpen}
        width='90%'
        title={slidingPaneTitle}
        onAfterOpen={this.disableBodyScroll}
        onRequestClose={this.closeEmailTemplatesList}
        className="width-100-xs width-100-sm"
        closeIcon={Icons.close()}>
        <EmailTemplates onSelectTemplate={onSelectTemplate}
          selectedTemplateId={selectedEmailTemplateId}
          persistedEmailTemplateId={persistedEmailTemplateId}
          eventId={urlEventId()}
          events={[]}
          enableEdit={true}
          borderStyle="1px solid #E3E3E3"
          builderInSlidingPane={true}
          listBodyHeight="calc(100vh - 54px)"
          toolbarHeight="54px"
          searchbarHeight="50px"
          iframeAutoResize={false}
          inModal={true}
          slidingPaneTitle={slidingPaneTitle}
          enableDelete={enableDelete}
          match={match}
          location={location}
          history={history} />
      </SlidingPane>
    );
  }

  renderSelector() {
    const { selectorClassNames } = this.props;

    return <div className={ selectorClassNames || "col-md-4 offset-md-4" }>
      <div className="card feature mt-30 mb-30" onClick={this.openEmailTemplatesList}>
        <div className="card-body">
          <div className="feature-content">
            <i className="fa-regular fa-envelope" />
          </div>
          <div className="feature-label">
            <a>{ this.i18n("choose_an_email") }</a>
          </div>
        </div>
      </div>
    </div>;
  }

  renderEmailPreview() {
    const { selectedEmailTemplateId } = this.props;
    const { previewIframeKey } = this.state;

    return <TemplatePreviewIframe
      templateId={selectedEmailTemplateId}
      templateType="email_template"
      autoResize={false}
      scrolling={false}
      defaultHeight="300px"
      displayResizeButton={true}
      key={previewIframeKey} />;
  }

  render() {
    const { selectedEmailTemplateId, label, isDisabled } = this.props;

    return <div className="mb-3">
      <label className="form-label">{ label || "Email" } { !isDisabled && this.renderChangeTemplateButton() }</label>
      <div className="row email-selector mt-5">
        { selectedEmailTemplateId ? this.renderEmailPreview() : this.renderSelector() }
        { this.renderEmailTemplatesSlidingPane() }
      </div>
    </div>;
  }
}

export default EmailTemplateSelector;
