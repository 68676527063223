import { fetchGuestCategories } from "../utils/APIUtils";
import { CALL_API, ActionTypes } from "../constants/Constants";

module.exports = {
  importGuestCategories(page = 0) {
    return dispatch => {
      fetchGuestCategories(dispatch, page);
    };
  },

  importGuestCategoriesFromEvent(eventId) {
    const { DEV_NULL, FETCH_GUEST_CATEGORIES_FROM_EVENT_SUCCESS } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, FETCH_GUEST_CATEGORIES_FROM_EVENT_SUCCESS, DEV_NULL],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/guest_categories.json`
      }
    };
  }
};
