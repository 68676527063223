import { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import Icons from "../constants/Icons";
import { fetchWebsite, updateWebsite } from "../actions/WebsiteActionCreators";
import { requestEvent } from "../actions/ImportActionCreators";
import { fetchSavedSearches } from "../actions/SavedSearchActionCreators";
import { urlEventId } from "../utils/pathUtils";
import ErrorMessage from "../components/shared/ErrorMessage.react";
import Loader from "../components/shared/Loader.react";
import WebsitePwaConfigForm from "../components/WebsitePwaConfigForm.react";
import WebsiteWebPushNotificationsForm from "../components/WebsiteWebPushNotificationsForm.react";
import WebsiteGdprConfigForm from "../components/WebsiteGdprConfigForm.react";
import WebsiteConsentNoticeForm from "../components/WebsiteConsentNoticeForm.react";
import { Website } from "../types/Website";
import { Event } from "../types/Event";
import { SavedSearch } from "../types/SavedSearch";
import { pathToWebsiteConfig } from "../utils/pathUtils";
import SlidingPane from "react-sliding-pane";
import TranslationTable from "./translations/TranslationTable.react";
import NoticeBlock from "../components/templates_builder/NoticeBlock.react";
import WebsiteHelpRegistrationAfterSigninConfigForm from "../components/WebsiteHelpRegistrationAfterSigninConfigForm.react";
import { isEnabled } from "../utils/featureSetUtils";
import { WEB_PUSH, PROGRESSIVE_WEB_APP } from "../constants/FeaturesSet";
import querystring from "querystring";

interface Notification {
  notice: string;
  noticeType: string;
}

interface Props {
  mode: string;
  website: Website;
  savedSearches: SavedSearch[];
  errors: any;
  isPendingRequest: boolean;
  notice?: string;
  noticeType?: string;
  event: Event;
  match: any;
  location: any;
  history: any;
  fetchWebsite(eventId: string): void;
  updateWebsite(eventId: string, params: any, sendFile?: boolean, redirectTo?: string, notificationsOptions?: Notification): void;
  toggleTranslationsPane(): void;
  requestEvent(): void;
  fetchSavedSearches(eventId: string): void;
}

interface State {
  displayTranslationsPane: boolean;
}

class WebsiteFormContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    [
      "toggleTranslationsPane"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      displayTranslationsPane: false
    };
  }

  componentDidMount(): void {
    const { fetchWebsite, requestEvent, mode, fetchSavedSearches } = this.props;
    fetchWebsite(urlEventId());
    requestEvent();
    if (mode == "gdpr") fetchSavedSearches(urlEventId());
  }

  componentDidUpdate(prevProps: Props): void {
    // after website update
    if (prevProps.isPendingRequest && !this.props.isPendingRequest) {
      $("html, body").stop().animate({ scrollTop: 0 }, 1000, "swing");
    }
  }

  eventHasTranslations(): boolean {
    const { event } = this.props;
    return event && event.available_frontend_locales && event.available_frontend_locales.length > 0;
  }

  toggleTranslationsPane(): void {
    this.setState({
      displayTranslationsPane: !this.state.displayTranslationsPane
    });
  }

  onSubmitHandler(): (data: Website) => void {
    return (website: Website): void => {
      const { mode, updateWebsite } = this.props;
      const notificationsOptions = { notice: I18n.t("react.website_form.saved_successfully"), noticeType: "success" };

      if (["gdpr", "help_registration_after_signin", "consent_notice"].includes(mode)) {
        updateWebsite(urlEventId(), website, false, pathToWebsiteConfig(), notificationsOptions);
      } else if (["edit_pwa", "web_push_notifications"].includes(mode)) {
        const formData = new FormData();
        if (mode === "edit_pwa") {
          formData.append("website[pwa_enabled]", website.pwa_enabled.toString());
          formData.append("website[pwa_name]", website.pwa_name);
          formData.append("website[pwa_description]", website.pwa_description);
          formData.append("website[pwa_short_name]", website.pwa_short_name);
          formData.append("website[pwa_display]", website.pwa_display);
        } else if (mode === "web_push_notifications") {
          formData.append("website[web_push_enabled]", website.web_push_enabled.toString());
          formData.append("website[web_push_rational]", website.web_push_rational);
        }
        if (website.pwa_icon) {
          formData.append("website[pwa_icon]", website.pwa_icon);
        }
        updateWebsite(urlEventId(), formData, true, pathToWebsiteConfig(), notificationsOptions);
      }
    };
  }

  renderNotice(): JSX.Element {
    const { notice, noticeType } = this.props;

    if (!notice || notice.length == 0) return null;

    return (
      <div className={`alert alert-${noticeType}`}>
        {notice}
      </div>
    );
  }

  renderTranslations(): JSX.Element {
    const { match, location, history, website, notice, noticeType } = this.props;
    const { displayTranslationsPane } = this.state;

    return displayTranslationsPane && <SlidingPane
      isOpen={true}
      title={I18n.t("react.translations.parent_navigation.website")}
      from="right"
      width='90%'
      onRequestClose={this.toggleTranslationsPane}
      className="width-100-xs width-90-sm"
      closeIcon={Icons.close()}
    >
      <div style={{ padding: "0 20px 20px" }}>
        <NoticeBlock notice={notice} noticeType={noticeType} />
        <TranslationTable
          match={match}
          location={location}
          history={history}
          type="website"
          eventId={website.event_id}
          id={website._id}
          inSlidingPane={true}
          onlySimpleValidateButton={true}
        />
      </div>
    </SlidingPane>;
  }

  renderWebPushNotificationsForm(): JSX.Element {
    const { website, isPendingRequest } = this.props;

    return <WebsiteWebPushNotificationsForm
      web_push_enabled={website.web_push_enabled}
      web_push_rational={website.web_push_rational}
      pwa_icon={website.pwa_icon}
      isPendingRequest={isPendingRequest}
      onSubmit={this.onSubmitHandler()}
      showTranslationPane={this.eventHasTranslations() && this.toggleTranslationsPane}
      updatedAt={website.updated_at}
    />;
  }

  renderPwaConfigForm(): JSX.Element {
    const { website, isPendingRequest } = this.props;

    return <WebsitePwaConfigForm
      pwa_name={website.pwa_name}
      pwa_short_name={website.pwa_short_name}
      pwa_description={website.pwa_description}
      pwa_display={website.pwa_display}
      pwa_enabled={website.pwa_enabled}
      pwa_icon={website.pwa_icon}
      updated_at={website.updated_at}
      isPendingRequest={isPendingRequest}
      onSubmit={this.onSubmitHandler()}
    />;
  }

  renderGdprConfigForm(): JSX.Element {
    const { website, savedSearches, event, isPendingRequest } = this.props;

    return <WebsiteGdprConfigForm
      legitimate_interest_segment_id={website.legitimate_interest_segment_id}
      third_party_analytic_tool_used={website.third_party_analytic_tool_used}
      third_party_analytic_cookies_title={website.third_party_analytic_cookies_title}
      third_party_analytic_tool_rational={website.third_party_analytic_tool_rational}
      consent_banner_enabled={website.consent_banner_enabled}
      consent_banner_text={website.consent_banner_text}
      consent_banner_link={website.consent_banner_link}
      required_cookies_title={website.required_cookies_title}
      required_cookies_rational={website.required_cookies_rational}
      savedSearches={savedSearches}
      event={event}
      website={website}
      isPendingRequest={isPendingRequest}
      onSubmit={this.onSubmitHandler()}
      showTranslationPane={this.eventHasTranslations() && this.toggleTranslationsPane}
    />;
  }

  renderConsentNoticeForm(): JSX.Element {
    const { website, isPendingRequest, location } = this.props;
    const { migrating_from_legacy_notice } = querystring.parse(location.search.substring(1));

    return <WebsiteConsentNoticeForm
      website={website}
      pendingRequest={isPendingRequest}
      onSubmit={this.onSubmitHandler()}
      migratingFromLegacyNotice={migrating_from_legacy_notice === "true"}
      showTranslationPane={this.eventHasTranslations() && this.toggleTranslationsPane}
    />;
  }

  renderHelpRegistrationAfterSigninConfigForm(): JSX.Element {
    const { website, isPendingRequest } = this.props;

    return <WebsiteHelpRegistrationAfterSigninConfigForm
      help_registration_after_signin_enabled={website.help_registration_after_signin_enabled}
      help_registration_after_signin_title={website.help_registration_after_signin_title}
      help_registration_after_signin_message={website.help_registration_after_signin_message}
      isPendingRequest={isPendingRequest}
      onSubmit={this.onSubmitHandler()}
      showTranslationPane={this.eventHasTranslations() && this.toggleTranslationsPane}
    />;
  }

  renderForm(): JSX.Element {
    const { mode } = this.props;

    switch (mode) {
    case "web_push_notifications":
      return isEnabled(WEB_PUSH) ? this.renderWebPushNotificationsForm() : null;
    case "edit_pwa":
      return isEnabled(PROGRESSIVE_WEB_APP) ? this.renderPwaConfigForm() : null;
    case "consent_notice":
      return this.renderConsentNoticeForm();
    case "gdpr":
      return this.renderGdprConfigForm();
    case "help_registration_after_signin":
      return this.renderHelpRegistrationAfterSigninConfigForm();
    }
  }

  render(): JSX.Element {
    const { website, errors } = this.props;
    const errorsParams = !errors || errors.length == 0 ? {} : errors;

    if (!website) return <Loader />;

    return (
      <div>
        { this.renderNotice() }
        <ErrorMessage errors={errorsParams} model="website" />
        { this.renderForm() }
        { this.renderTranslations() }
      </div>
    );
  }
}

function mapStateToProps(state: any): any {
  const { website, event, savedSearches } = state;
  return {
    event,
    website: website.data,
    savedSearches: savedSearches.data,
    errors: website.errors,
    isPendingRequest: website.isPendingRequest,
    notice: state.notifications.currentNotice,
    noticeType: state.notifications.noticeType
  };
}

const mapDispatchToProps = {
  fetchWebsite,
  updateWebsite,
  requestEvent,
  fetchSavedSearches
};

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteFormContainer);
