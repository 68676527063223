import { ChangeEvent } from "react";

interface Props {
  model: string;
  rtmpEndpointValue: string;
  rtmpStreamKeyValue: string;
  hlsBroadcastUrl: string;
  needSaveForRtmpData: boolean;
  readOnly?: boolean

  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
 }

const i18n = (key: string): string => {
  return I18n.t(`react.has_rtmp_endpoint_form.${key}`);
};

const HasRtmpEndpointForm: React.FC<Props> = ({
  model,
  rtmpEndpointValue,
  rtmpStreamKeyValue,
  hlsBroadcastUrl,
  needSaveForRtmpData,
  readOnly,
  onChange
}): JSX.Element => {

  const attributeI18n = (key: string): string => {
    return I18n.t(`mongoid.attributes.${model}.${key}`);
  };

  const emptyRtmpEndpointExplanation = (): string => {
    return needSaveForRtmpData ? i18n("no_rtmp_endpoint") : i18n("no_rtmp_field_because_live_over");
  };

  const emptyRtmpStreamKeyExplanation = (): string => {
    return needSaveForRtmpData ? i18n("no_rtmp_stream_key") : i18n("no_rtmp_field_because_live_over");
  };

  const isAdmin = window["ReactGlobalProps"]["super_admin"];

  return <>
    <div className="mb-3" key="rtmp-endpoint">
      <label className="form-label">{attributeI18n("rtmp_endpoint")}</label>
      <br />
      <code>{rtmpEndpointValue || emptyRtmpEndpointExplanation()}</code>
      <div className="form-text">{i18n("rtmp_endpoint_help")}</div>
    </div>
    <div className="mb-3" key="rtmp-stream-key">
      <label className="form-label">{attributeI18n("rtmp_stream_key")}</label>
      <br />
      <code>{rtmpStreamKeyValue || emptyRtmpStreamKeyExplanation()}</code>
      <div className="form-text">{i18n("rtmp_stream_key_help") }</div>
    </div>
    <div className="mb-3" key="hls-broadcast-url">
      <label className="form-label">{attributeI18n("hls_broadcast_url") }</label>
      <input
        className="form-control"
        value={hlsBroadcastUrl || ""}
        type="text"
        onChange={onChange}
        readOnly={!isAdmin || !!readOnly}
      />
      {isAdmin && (
        <div className="form-text">
          <i className="fa-regular fa-exclamation-triangle text-warning"></i> {i18n("warning_admin_hls_broadcast_url_edition")}
        </div>
      )}
    </div>
  </>;
};

export default HasRtmpEndpointForm;
