import { CALL_API, ActionTypes } from "../constants/Constants";
import { stringify } from "querystring";

module.exports = {

  receiveGuestCategoriesRequest() {
    return {
      type: ActionTypes.FETCH_GUEST_CATEGORIES,
    };
  },

  receiveGuestCategories(guestCategories, previousURL = "", nextURL = "") {
    return {
      type: ActionTypes.RECEIVE_GUEST_CATEGORIES,
      data: guestCategories,
      previousURL,
      nextURL,
    };
  },

  receiveGuestCategoriesFailure() {
    return {
      type: ActionTypes.RECEIVE_GUEST_CATEGORIES_FAILURE
    };
  },

  fetchGuestCategories(eventId) {
    const { FETCH_GUEST_CATEGORIES, RECEIVE_GUEST_CATEGORIES, RECEIVE_GUEST_CATEGORIES_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [FETCH_GUEST_CATEGORIES, RECEIVE_GUEST_CATEGORIES, RECEIVE_GUEST_CATEGORIES_FAILURE],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/guest_categories.json`
      }
    };
  },

  fetchGuestCategory(eventId, guestCategoryId, options = {}) {
    const { GUEST_CATEGORY_REQUEST, GUEST_CATEGORY_SUCCESS, GUEST_CATEGORY_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [GUEST_CATEGORY_REQUEST, GUEST_CATEGORY_SUCCESS, GUEST_CATEGORY_FAILURE],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/guest_categories/${guestCategoryId}.json?${stringify(options)}`
      }
    };
  },

  fetchIfGuestCategoryIsValid(eventId, guestCategoryId) {
    const { DEV_NULL, GUEST_CATEGORY_VALID_SUCCESS, GUEST_CATEGORY_VALID_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, GUEST_CATEGORY_VALID_SUCCESS, GUEST_CATEGORY_VALID_FAILURE],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/guest_categories/${guestCategoryId}/is_valid.json`
      }
    };
  },

  setSelectedGuestCategoryIds(ids) {
    return {
      type: ActionTypes.SET_SELECTED_GUEST_CATEGORY_IDS,
      objectIds: ids
    };
  },

  updateGuestCategory(eventId, guestCategoryId, params, redirectTo = null, notificationsOptions = {}) {
    const { GUEST_CATEGORY_UPDATE_REQUEST, GUEST_CATEGORY_UPDATE_SUCCESS, GUEST_CATEGORY_UPDATE_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [GUEST_CATEGORY_UPDATE_REQUEST, GUEST_CATEGORY_UPDATE_SUCCESS, GUEST_CATEGORY_UPDATE_FAILURE],
        method: "PUT",
        body: { guest_category: params },
        endpoint: `/api/v1/events/${eventId}/guest_categories/${guestCategoryId}.json`,
        redirectTo,
        notificationsOptions
      },
      id: guestCategoryId
    };
  },

  registrationFormToggle(eventId, guestCategoryId) {
    const { GUEST_CATEGORY_UPDATE_REQUEST, GUEST_CATEGORY_UPDATE_SUCCESS, GUEST_CATEGORY_UPDATE_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [GUEST_CATEGORY_UPDATE_REQUEST, GUEST_CATEGORY_UPDATE_SUCCESS, GUEST_CATEGORY_UPDATE_FAILURE],
        method: "PUT",
        body: {},
        endpoint: `/api/v1/events/${eventId}/guest_categories/${guestCategoryId}/registration_form_toggle.json`
      }
    };
  },

  badgeToggle(eventId, guestCategoryId) {
    const { GUEST_CATEGORY_UPDATE_REQUEST, GUEST_CATEGORY_UPDATE_SUCCESS, GUEST_CATEGORY_UPDATE_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [GUEST_CATEGORY_UPDATE_REQUEST, GUEST_CATEGORY_UPDATE_SUCCESS, GUEST_CATEGORY_UPDATE_FAILURE],
        method: "PUT",
        body: {},
        endpoint: `/api/v1/events/${eventId}/guest_categories/${guestCategoryId}/badge_toggle.json`
      }
    };
  },

  createGuestCategory(eventId, params, redirectTo = null) {
    const { DEV_NULL, GUEST_CATEGORY_CREATE_SUCCESS, GUEST_CATEGORY_CREATE_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, GUEST_CATEGORY_CREATE_SUCCESS, GUEST_CATEGORY_CREATE_FAILURE],
        method: "POST",
        body: { guest_category: params },
        endpoint: `/api/v1/events/${eventId}/guest_categories.json`,
        redirectTo
      }
    };
  },

  copyGuestCategory(eventId, guestCategoryId, params, redirectTo = null) {
    const { DEV_NULL, GUEST_CATEGORY_COPY_SUCCESS, GUEST_CATEGORY_COPY_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, GUEST_CATEGORY_COPY_SUCCESS, GUEST_CATEGORY_COPY_FAILURE],
        method: "POST",
        body: params,
        endpoint: `/api/v1/events/${eventId}/guest_categories/${guestCategoryId}/copy.json`,
        redirectTo
      }
    };
  },

  countPeople(eventId, guestCategoryId) {
    const { DEV_NULL, GUEST_CATEGORY_COUNT_PEOPLE_SUCCESS, GUEST_CATEGORY_COUNT_PEOPLE_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, GUEST_CATEGORY_COUNT_PEOPLE_SUCCESS, GUEST_CATEGORY_COUNT_PEOPLE_FAILURE],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/guest_categories/${guestCategoryId}/count_people.json`
      }
    };
  },

  fetchCustomEmail(eventId, guestCategoryId, type) {
    const { DEV_NULL, GUEST_CATEGORY_CUSTOM_EMAIL_SUCCESS, GUEST_CATEGORY_CUSTOM_EMAIL_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, GUEST_CATEGORY_CUSTOM_EMAIL_SUCCESS, GUEST_CATEGORY_CUSTOM_EMAIL_FAILURE],
        method: "GET",
        body: { type: type },
        endpoint: `/api/v1/events/${eventId}/guest_categories/${guestCategoryId}/custom_email.json`
      }
    };
  },

  clearCustomEmail() {
    return {
      type: ActionTypes.GUEST_CATEGORY_CLEAR_CUSTOM_EMAIL
    };
  },

  addOfflineErrors(errors) {
    return {
      type: ActionTypes.GUEST_CATEGORY_ADD_OFFLINE_ERRORS,
      errors: errors
    };
  },

  deleteAllPeople(eventId, guestCategoryId, notificationsOptions = {}) {
    const { DEV_NULL, GUEST_CATEGORY_DELETE_ALL_PEOPLE_SUCCESS, GUEST_CATEGORY_DELETE_ALL_PEOPLE_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, GUEST_CATEGORY_DELETE_ALL_PEOPLE_SUCCESS, GUEST_CATEGORY_DELETE_ALL_PEOPLE_FAILURE],
        method: "DELETE",
        endpoint: `/api/v1/events/${eventId}/guest_categories/${guestCategoryId}/delete_all_guests.json`,
        notificationsOptions
      }
    };
  },

  destroyGuestCategory(eventId, guestCategoryId, params = {}, redirectTo = null) {
    const { DEV_NULL, GUEST_CATEGORY_DESTROY_SUCCESS, GUEST_CATEGORY_DESTROY_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, GUEST_CATEGORY_DESTROY_SUCCESS, GUEST_CATEGORY_DESTROY_FAILURE],
        method: "DELETE",
        body: params,
        endpoint: `/api/v1/events/${eventId}/guest_categories/${guestCategoryId}.json`,
        redirectTo
      }
    };
  },

  fetchPopulationTypes(eventId) {
    const { DEV_NULL, FETCH_POPULATION_TYPES_SUCCESS } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, FETCH_POPULATION_TYPES_SUCCESS, DEV_NULL],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/guest_categories/population_types.json`
      }
    };
  }
};
