import { Component } from "react";
import uniq from "lodash/uniq";
import ReactDOM from "react-dom";
import { shouldBeDisplayed } from "../../utils/CheckinpointUtils.js";
import Icons from "../../constants/Icons";
import ProductGroupType from "./ProductGroupType.react";
import CheckinPointGroupType from "./CheckinPointGroupType.react";
import AccommodationType from "./AccommodationType.react";
import classNames from "classnames";
import { CreateNewAccesspointId } from "../../constants/Constants";

const MAX_ACCESSPOINTS = 20;

class AccesspointGroupTypeCommon extends Component {

  constructor(props) {
    super(props);
    [
      "addAllOptions",
      "addFilterBy",
      "addOption",
      "availableAccesspoints",
      "accesspointList",
      "checkboxValueOnBlur",
      "checkboxValueOnChanged",
      "createAccesspointOption",
      "destroyOption",
      "handleChange",
      "handleDrop",
      "handleFilterBy",
      "renderAddAccesspointOptions",
      "filteredAccesspoints",
      "toggleOptionMode",
      "traitOptions",
      "updateOption",
      "updateOverrideLabel",
      "updateOverrideQuantityHandler",
      "updateOrder",
      "updateQuantityRange",
      "renderOptionsFilter",
      "injectCreateAccesspointKey",
      "closeCreateAccesspointModal",
      "getNewAccesspoint",
      "showErrorAccesspoint",
      "triggerShowAll"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    const filterMetadata = props.formItem.options.filter ? props.formItem.options.filter.key : null;
    this.state = { selectedOptionId: null,
      checkedAccesspoints: {},
      showDisplayAllAccesspointsButton: true,
      showCreateAccesspointModal: false,
      currentFormItemOption: null,
      showingAll: false,
      filterMetadata
    };
  }

  componentDidMount() {
    const { formItem } = this.props;
    if (!formItem.form_item_options || formItem.form_item_options.length == 0) {
      this.createAccesspointOption();
    }
    let currentCheckedAccesspoints = Object.assign({}, this.state.checkedAccesspoints);
    formItem.form_item_options.forEach(option => {
      currentCheckedAccesspoints[option.key] = (option.value == "true");
    });
    const showDisplayAllAccesspointsButton = this.filteredAccesspoints().length > MAX_ACCESSPOINTS;
    this.setState({ checkedAccesspoints: currentCheckedAccesspoints, showDisplayAllAccesspointsButton });
  }

  componentDidUpdate(prevProps) {
    const { formItem } = this.props;
    if (prevProps.formItem.form_item_options && formItem.form_item_options) {
      if (formItem.form_item_options.length > prevProps.formItem.form_item_options.length) {
        const length = formItem.form_item_options.length;
        const lastFormItemOption = formItem.form_item_options[length - 1];
        this.setState({ selectedOptionId: lastFormItemOption._id, showingAll: true });
      }
    }
  }

  triggerShowAll(showingAll) {
    if (!showingAll) {
      //ensure element stay visible
      const component = this.refs["checkinpointDiv"];
      const domNode = ReactDOM.findDOMNode(component);
      domNode.scrollIntoView();
    }
    this.setState({ showingAll });
  }

  handleDrop(previousItemId, itemId, nextItemId, estimatedIndex) {
    let params = {};
    if (previousItemId) {
      params["rank_after_id"] = previousItemId;
    }
    if (nextItemId) {
      params["rank_before_id"] = nextItemId;
    }

    params["rank"] = estimatedIndex;
    params["_id"] = itemId;
    const { updateFormItem } = this.props;
    updateFormItem({
      form_item_options: [params]
    }, true);
  }

  createAccesspointOption() {
    const { updateFormItem, formItem } = this.props;
    let rank = 1000;
    const accesspoint = this.availableAccesspoints()[0];
    if (formItem.form_item_options && formItem.form_item_options.length > 0) {
      rank = formItem.form_item_options[formItem.form_item_options.length - 1].rank + 1000;
    }
    if (!accesspoint) return;
    updateFormItem({
      form_item_options: [{ label: accesspoint.display_name, key: accesspoint.id, rank }]
    });
  }

  addOption(e) {
    e.preventDefault();
    this.createAccesspointOption();
  }

  addAllOptions(e) {
    e.preventDefault();
    const { updateFormItem, formItem } = this.props;
    let formItemOptions = [];
    let rank = 1000;
    if (formItem.form_item_options && formItem.form_item_options.length > 0) {
      rank = formItem.form_item_options[formItem.form_item_options.length - 1].rank + 1000;
    }
    this.availableAccesspoints().forEach(accesspoint => {
      formItemOptions.push({ label: accesspoint.display_name, key: accesspoint.id, rank });
      rank += 1000;
    });
    updateFormItem({
      form_item_options: formItemOptions
    });
  }

  updateOption(params) {
    const { updateFormItem } = this.props;
    if (params.key === CreateNewAccesspointId) {
      this.setState({ showCreateAccesspointModal: true, currentFormItemOption: params });
      return;
    }
    updateFormItem({
      form_item_options: [params]
    });
  }

  closeCreateAccesspointModal() {
    this.setState({ showCreateAccesspointModal: false, currentFormItemOption: null });
  }

  getNewAccesspoint(accesspoint) {
    const { updateFormItem } = this.props;
    const { currentFormItemOption } = this.state;
    const formItemOptionUpdated = Object.assign({}, currentFormItemOption, { key: accesspoint._id, label: accesspoint.display_name });
    this.setState({ showCreateAccesspointModal: false });
    updateFormItem({
      form_item_options: [formItemOptionUpdated]
    });
  }

  checkboxValueOnChanged(accesspointId, value) {
    const { formItem } = this.props;
    const { options } = formItem;
    let { checkedAccesspoints } = this.state;
    if (options.uniq_option) {
      Object.keys(checkedAccesspoints).forEach(key => {
        checkedAccesspoints[key] = false;
      });
    }
    checkedAccesspoints[accesspointId] = value;
    this.setState({ checkedAccesspoints: checkedAccesspoints });
  }

  checkboxValueOnBlur() {
    const { checkedAccesspoints } = this.state;
    const { formItem, updateFormItem } = this.props;
    const formItemOptions = [];
    formItem.form_item_options.forEach(fio => {
      const value = checkedAccesspoints[fio.key] || "";
      if (fio.value != value) {
        fio.value = value;
        formItemOptions.push(fio);
      }
    });
    updateFormItem({
      form_item_options: formItemOptions
    });
  }

  destroyOption(optionId) {
    const { updateFormItem } = this.props;
    updateFormItem({
      form_item_options: [{ _id: optionId, _destroy: "1" }]
    });
  }

  toggleOptionMode(optionId) {
    return () => {
      this.setState({ selectedOptionId: optionId });
    };
  }

  availableAccesspoints(optionItem = null) {
    const { accesspoints, formItem } = this.props;
    if (!formItem.form_item_options) {
      return accesspoints;
    }

    const usedAccesspoints = formItem.form_item_options.map(option => {
      return option.key;
    });

    return accesspoints.filter(accesspoint => {
      if (optionItem != null && optionItem.key == accesspoint.id) {
        return true;
      }
      return usedAccesspoints.indexOf(accesspoint.id) == -1;
    });
  }

  injectCreateAccesspointKey(accesspoints) {
    const { formItem } = this.props;
    return [{ id: CreateNewAccesspointId, name: I18n.t(`import_operation_shared.mapping.create_new_${formItem.type}`) }, ...accesspoints];
  }

  filteredAccesspoints() {
    const { accesspoints, formItem } = this.props;
    //filter accesspoint if a filter option is enabled
    return accesspoints.filter(accesspoint => {
      return shouldBeDisplayed(accesspoints, formItem, accesspoint.id);
    });
  }

  accesspointList() {
    const { showDisplayAllAccesspointsButton } = this.state;
    return !showDisplayAllAccesspointsButton ? this.filteredAccesspoints() : this.filteredAccesspoints().slice(0, MAX_ACCESSPOINTS);
  }

  traits() {
    const { accesspoints } = this.props;
    let traits = {};
    accesspoints.forEach(accesspoint => {
      if (accesspoint.traits && accesspoint.traits != {}) {
        Object.keys(accesspoint.traits).forEach(key => {
          if (!traits[key]) traits[key] = [];
          traits[key].push(accesspoint.traits[key]);
        });
      }
    });

    return traits;
  }

  showErrorAccesspoint() {
    const { hasInvalidAccessPrivileges, formItem } = this.props;
    const formItemOptions = formItem.form_item_options || [];
    const formItemOptionsKeys = formItemOptions.map(option => option.key);
    const accesspointsFiltered = this.filteredAccesspoints().filter(acc => formItemOptionsKeys.includes(acc.id));
    const error = hasInvalidAccessPrivileges(accesspointsFiltered, formItem.type);
    return error &&
      <div className="alert alert-warning">
        <i className="fa-regular fa-triangle-exclamation"></i> { error }
      </div>;
  }

  handleChange(index, isChangingValue = false, isDestroying = false) {
    return (e) => {
      e.preventDefault();
      const { updateFormItem, formItem } = this.props;
      const { options } = formItem;
      const overrideLabel = options && options.override_label ? options.override_label : [];
      let i = 0;
      let newOverrideLabel = [];
      overrideLabel.forEach(cond => {
        if (!(i == index && isDestroying)) {
          let key = Object.keys(cond)[0];
          let value = cond[key];
          if (i == index) {
            if (isChangingValue) {
              value = e.target.value;
            } else {
              key = e.target.value;
              value = this.traits()[key][0];
            }
          }
          newOverrideLabel.push({ [key]: value });
        }
        i++;
      });
      updateFormItem({ options: Object.assign({}, options, { override_label: newOverrideLabel }) });
    };
  }

  handleFilterBy(e) {
    this.setState({ filterMetadata: e.target.value });
  }

  updateOverrideLabel(e) {
    e.preventDefault();
    const { updateFormItem, formItem } = this.props;
    const { options } = formItem;
    const key = e.target.value;
    const newOverrideLabel = { [key]: this.traits()[key][0] };
    updateFormItem({ options: Object.assign({}, options, { override_label: newOverrideLabel }) });
  }

  updateOverrideQuantityHandler(key) {
    return (e) => {
      e.preventDefault();
      const { updateFormItem, formItem } = this.props;
      const { options } = formItem;
      const { value } = e.target;
      const newOverrideQuantity = { ...(options.override_quantity || {}), [key]: value };
      updateFormItem({ options: Object.assign({}, options, { override_quantity: newOverrideQuantity }) });
    };
  }

  updateOrder(e) {
    e.preventDefault();
    const { updateFormItem, formItem } = this.props;
    const { options } = formItem;
    const key = e.target.value;
    const newOrder = { [key]: this.traits()[key][0] };
    updateFormItem({ options: Object.assign({}, options, { order: newOrder }) });
  }

  updateQuantityRange(key) {
    const { updateFormItem, formItem } = this.props;
    const { options } = formItem;

    return (e) => {
      e.preventDefault();
      const { value } = e.target;
      const newQuantityRange = { ...(options.quantity_range || {}), [key]: value };
      updateFormItem({ options: { ...options, quantity_range: newQuantityRange } });
    };
  }

  addFilterBy(e) {
    e.preventDefault();
    const { updateFormItem, formItem } = this.props;
    const { filterMetadata } = this.state;
    const { options } = formItem;
    const value = e.target.value;
    const newFilter = { key: filterMetadata, value };
    updateFormItem({ options: Object.assign({}, options, { filter: newFilter }) });
  }

  renderTraitValues(key) {
    const uniqueTraits = uniq(this.traits()[key]);
    return uniqueTraits.map((val, i) => {
      return <option key={ `${key}_${val}_${i}` } value={ val }>{ val }</option>;
    });
  }

  traitOptions() {
    return Object.keys(this.traits()).map((key, index) => {
      return (<option key={ `${key}_${index}` } value={ key }>{ key }</option>);
    });
  }

  renderAvailableTraits(onChangeFn, value = "") {
    return (
      <div className="col-sm-4">
        <select className="form-select" value={value || ""} onChange={onChangeFn}>
          <option key="default" value="">{ I18n.t("react.form_question_item.pick_an_accesspoint_metadata") }</option>
          {this.traitOptions()}
        </select>
      </div>
    );
  }

  renderFilterBy() {
    const { filters, formItem } = this.props;
    const { filterMetadata } = this.state;
    const classes = classNames({
      "disabled": !filterMetadata,
      "form-select": true
    });
    const options = filterMetadata ? this.renderTraitValues(filterMetadata) : null;
    const { filter } = formItem.options;
    const currentValue = filter && filter.value || "";
    return filters["filter"] ? (
      <div>
        <hr />
        <label className="form-label">{I18n.t("react.form_question_item.filter")}</label>
        <div className="row">
          {this.renderAvailableTraits(this.handleFilterBy, filterMetadata)}
          <div className="col-sm-4">
            <select className={classes} value={currentValue} onChange={this.addFilterBy}>
              <option key="default" value="">{ I18n.t("react.form_question_item.pick_a_metadata_value") }</option>
              { options }
            </select>
          </div>
        </div>
      </div>
    ) : "";
  }

  renderOverrideLabel() {
    const { filters } = this.props;
    const { formItem } = this.props;
    const { options } = formItem;
    const defaultValue = options.override_label ? Object.keys(options.override_label)[0] : "";
    return filters["override_label"] ? (
      <div>
        <hr />
        <label className="form-label">{I18n.t("react.form_question_item.override_label")}</label>
        <div>
          <div className="row">
            { this.renderAvailableTraits(this.updateOverrideLabel, defaultValue) }
          </div>
        </div>
      </div>
    ) : "";
  }

  renderOverrideQuantity() {
    const { filters, formItem } = this.props;
    const { options } = formItem;

    return filters["override_quantity"] ? (
      <div>
        <hr />
        <div className="row mt-10">
          <div className="col-md-6">
            {I18n.t("react.form_question_item.override_min_quantity")}
          </div>
          <div className="col-md-6">
            { this.renderAvailableTraits(this.updateOverrideQuantityHandler("min"), options.override_quantity && options.override_quantity.min) }
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-md-6">
            {I18n.t("react.form_question_item.override_max_quantity")}
          </div>
          <div className="col-md-6">
            { this.renderAvailableTraits(this.updateOverrideQuantityHandler("max"), options.override_quantity && options.override_quantity.max) }
          </div>
        </div>
      </div>
    ) : "";
  }

  renderOrder() {
    const { filters, formItem } = this.props;
    const { options } = formItem;
    const value = options.order ? Object.keys(options.order)[0] : "";
    return filters["order"] ? (
      <div>
        <hr />
        <label className="form-label">{I18n.t("react.form_question_item.order")}</label>
        <div>
          <div className="row">
            { this.renderAvailableTraits(this.updateOrder, value) }
          </div>
        </div>
      </div>
    ) : "";
  }

  renderQuantityRangeSelect(key) {
    const { formItem } = this.props;
    const quantityRange = formItem.options.quantity_range || {};
    const value = quantityRange[key] && parseInt(quantityRange[key]) || -1;
    let options = [
      <option value={-1} key={`${formItem.id}-quantity-${key}-no-limit`}>{I18n.t("react.form_question_item.quantity_range_no_limit")}</option>
    ];

    for (let i = 1; i <= 20; i++) {
      const selected = i === value ? "selected" : null;
      const key = `${formItem.id}-quantity-${key}-${i}`;
      const option = selected ? <option value={i} selected key={key}>{i}</option> : <option value={i} key={key}>{i}</option>;
      options.push(option);
    }

    return <div className="col-sm-6">
      <label className="form-label">{I18n.t(`react.form_question_item.quantity_range_${key}_label`)}</label>
      <select onChange={this.updateQuantityRange(key)} className="form-select">
        { options }
      </select>
    </div>;
  }

  renderQuantityRange() {
    const { filters } = this.props;

    return filters.quantity_range ? (
      <div>
        <hr />
        <h2>{I18n.t("react.form_question_item.quantity_range")}</h2>
        <div>
          <div className="row mt-10">
            { this.renderQuantityRangeSelect("min") }
            { this.renderQuantityRangeSelect("max") }
          </div>
        </div>
      </div>
    ) : "";
  }

  renderAccesspointListFooterDefaultMode() {
    const { formItem } = this.props;

    return (
      <div style={{ "marginLeft": "3%" }} className="mt-10">
        <a className="btn btn-link" href="#" onClick={this.addOption}>{ I18n.t(`react.form_accesspoint_group.add_accesspoint_${formItem.type}`) }</a>
        <span className="btn-align ps-0 pe-0">{ Icons["vertical_divider"]() }</span>
        <a className="btn btn-link" href="#" onClick={this.addAllOptions}>{ I18n.t(`react.form_accesspoint_group.add_all_accesspoints_${formItem.type}`) }</a>
      </div>
    );
  }

  renderAddAccesspointOptions() {
    const { formItem } = this.props;

    if (formItem.options.advanced_mode) {
      return (
        <div className="mt-10">
          <a className="btn btn-link" href="#" onClick={(e) => { e.preventDefault(); this.setState({ showCreateAccesspointModal: true }); } }>
            { I18n.t(`react.form_accesspoint_group.click_to_create_${formItem.type}`) }
          </a>
        </div>
      );
    }

    if (this.availableAccesspoints().length <= 0) {
      return <div className="alert alert-warning mt-10" role="alert">
        <p className="alert-link">
          {I18n.t(`react.form_accesspoint_group.no_more_accesspoint_${formItem.type}`)}
        </p>
        <a href="#" onClick={(e) => { e.preventDefault(); this.setState({ showCreateAccesspointModal: true }); } }>
          {I18n.t(`react.form_accesspoint_group.click_to_create_${formItem.type}`)}
        </a>
      </div>;
    }

    return this.renderAccesspointListFooterDefaultMode();
  }

  renderOptionsFilter(advancedMode) {
    return (
      <div>
        {
          advancedMode ? [
            this.renderFilterBy(),
            this.renderOverrideLabel(),
            this.renderOverrideQuantity(),
            this.renderOrder()
          ] : null
        }
        { this.renderQuantityRange() }
      </div>
    );
  }

  render() {
    const { formItem } = this.props;

    const fns = {
      accesspointList: this.accesspointList,
      availableAccesspoints: this.availableAccesspoints,
      checkboxValueOnBlur: this.checkboxValueOnBlur,
      checkboxValueOnChanged: this.checkboxValueOnChanged,
      destroyOption: this.destroyOption,
      getNewAccesspoint: this.getNewAccesspoint,
      handleDrop: this.handleDrop,
      renderAddAccesspointOptions: this.renderAddAccesspointOptions,
      injectCreateAccesspointKey: this.injectCreateAccesspointKey,
      renderOptionsFilter: this.renderOptionsFilter,
      showErrorAccesspoint: this.showErrorAccesspoint,
      triggerShowAll: this.triggerShowAll,
      updateOption: this.updateOption,
      closeCreateAccesspointModal: this.closeCreateAccesspointModal,
      showCreateAccesspointModal: this.state.showCreateAccesspointModal
    };

    switch (formItem.type) {
    case "product_group":
      return <div ref="checkinpointDiv"><ProductGroupType { ...this.props } { ...this.state } { ...fns } /></div>;
    case "accommodation":
      return <div ref="checkinpointDiv"><AccommodationType { ...this.props } { ...this.state } { ...fns } /></div>;
    default:
      return <div ref="checkinpointDiv"><CheckinPointGroupType { ...this.props } { ...this.state } { ...fns } /></div>;
    }
  }

}

export default AccesspointGroupTypeCommon;
