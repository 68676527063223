import { ActionTypes } from "../constants/Constants";

function simpleSearchQuery(state = "", action) {
  const { value, type } = action;
  switch (type) {
  case ActionTypes.SET_SIMPLE_SEARCH_QUERY:
    return value || "";
  case ActionTypes.CLEAR_FILTERS:
    return "";
  default:
    return state;
  }
}
export default simpleSearchQuery;