import { Component, MouseEvent } from "react";
import { PreconfiguredEvent } from "../types/PreconfiguredEvent";

interface Props {
  preconfiguredEvents: PreconfiguredEvent[];
  onSelected(eventId: string): void;
}

class PreconfiguredEventSelector extends Component<Props> {
  constructor(props) {
    super(props);
    [
      "onClick",
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
  }

  i18n(key, params = {}): string {
    return I18n.t(`react.events.preconfigured_event_selector.${key}`, params);
  }

  onClick(eventId: string): (e: MouseEvent<HTMLElement>) => void {
    return (e: MouseEvent<HTMLElement>): void => {
      e.preventDefault();
      const { onSelected } = this.props;
      onSelected(eventId);
    };
  }

  renderPreconfiguredEvent(pe: PreconfiguredEvent): JSX.Element {
    const style = pe.image ? { backgroundImage: `url(${pe.image.thumb.url})` } : {};

    return <div className="card item" onClick={this.onClick(pe.event_id)} key={pe._id}>
      <div className="image d-none d-sm-inline" style={style}>
      </div>
      <div className="presentation">
        <div className="h2 mt-0">{pe[`name_${I18n.currentLocale()}`]}</div>
        <p className="description">{pe[`description_${I18n.currentLocale()}`]}</p>
      </div>
    </div>;
  }

  render(): JSX.Element {
    const { preconfiguredEvents } = this.props;
    return <div className="precongigured-event-list">
      {preconfiguredEvents.map(pe => {
        return this.renderPreconfiguredEvent(pe);
      })}
    </div>;
  }
}

export default PreconfiguredEventSelector;
