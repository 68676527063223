import { Component } from "react";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import requiresProps from "../../utils/requiresProps";
import querystring from "querystring";

import { CAMPAIGN_STEPS, GuestCampaignStatusLocked } from "../../constants/Constants";
import { urlWithQuery, pathToGuestCampaigns } from "../../utils/pathUtils";
import { campaignStepBreadcrumbLinks } from "../../utils/campaignStepUtils";

import { guestCountFromQS } from "../../actions/SearchQueryActionCreators";
import { createGuestCampaign, fetchGuestCampaign, updateGuestCampaign, offlineUpdateGuestCampaign, clearGuestCampaignStore } from "../../actions/GuestCampaignActionCreators";
import { fetchSavedSearches } from "../../actions/SavedSearchActionCreators";
import { fetchCurrentSavedSearch } from "../../actions/SavedSearchActionCreators";

import Breadcrumb from "../../components/shared/Breadcrumb.react";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import Form from "../../components/guest_campaign/Form.react";
import { redirectIfUnauthorized, isAuthorized } from "../../utils/aclUtils";

class GuestCampaignInformation extends Component {

  constructor(props) {
    redirectIfUnauthorized("campaigns", "manage");
    super(props);
    const privateMethods = [
      "_queryString",
      "_urlGuestList",
      "_urlGuestCampaignConfigurationTemplates",
      "_updateGuestCampaign",
      "_urlGuestCampaignSummary",
      "_saveCampaignAndGoToNextStep"
    ];
    privateMethods.forEach((item) => { this[item] = this[item].bind(this); });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.allRequirementsMet && this.props.allRequirementsMet) {
      const { guestCampaign, currentSavedSearch, theEvent } = this.props;
      const fieldNotEmpty = ["name", "from_name", "reply_to_email", "subject"].filter(field => { return guestCampaign && guestCampaign[field] !== undefined; });
      if (fieldNotEmpty.length === 0) {
        this._updateGuestCampaign({
          saved_search_id: currentSavedSearch._id,
          name: "",
          from_name: theEvent.organizer,
          reply_to_email: theEvent.reply_to_email,
          subject: ""
        });
      }
    }
  }

  _queryString() {
    return querystring.parse(this.props.location.search.substring(1)).q;
  }

  _urlGuestList() {
    return urlWithQuery(this._queryString(), "guests");
  }

  _updateGuestCampaign(parameters) {
    const { offlineUpdateGuestCampaign, guestCampaign, savedSearches, guestCountFromQS } = this.props;
    const newSavedSearchId = parameters.saved_search_id;
    if (newSavedSearchId && guestCampaign.saved_search_id != newSavedSearchId && savedSearches.length > 0) {
      // segment changed, need to update guest count
      const selectedSavedSearch = savedSearches.find(search => { return search._id == newSavedSearchId; });
      if (selectedSavedSearch) {
        guestCountFromQS(selectedSavedSearch.search_query, true);
      }
    }
    offlineUpdateGuestCampaign(guestCampaign, parameters);
  }

  _saveCampaignAndGoToNextStep() {
    const { createGuestCampaign, updateGuestCampaign, match, guestCampaign } = this.props;
    const skipValidations = { skip_content_validation: true, skip_email_validation: guestCampaign.use_email && guestCampaign.use_sms };
    const params = Object.assign({}, guestCampaign, skipValidations);

    if (guestCampaign._id) {
      updateGuestCampaign(match.params.event_id, match.params.guest_campaign_id, params, false, this._urlGuestCampaignSummary());
    } else {
      createGuestCampaign(match.params.event_id, params, this._urlGuestCampaignConfigurationTemplates());
    }
  }

  _urlGuestCampaignConfigurationTemplates() {
    return urlWithQuery(this._queryString(), "campaign/:id/configuration");
  }

  _urlGuestCampaignSummary() {
    return urlWithQuery(this._queryString(), "campaign/:id/summarize");
  }

  render() {
    if (!isAuthorized("campaigns", "manage")) return;
    const { errors, guestCampaign, savedSearches, currentSavedSearch, guestCampaignIsPersisted } = this.props;
    const nextName = guestCampaign._id ? I18n.t("react.guest_campaign.form.update_information") : I18n.t("react.guest_campaign.form.save_and_continue");
    const nextUrl = (isEmpty(guestCampaign) || GuestCampaignStatusLocked.includes(guestCampaign.status)) ? null : this._saveCampaignAndGoToNextStep;
    const errorsParams = (!errors || errors.length == 0 ? {} : errors);

    return (
      <div style={{ marginBottom: "50px" }}>
        <div className="row">
          <div className="col-md-8 offset-md-2 mt-30">
            <div className="header-page">
              <div className="header-page-title">
                <h1>
                  <a href={pathToGuestCampaigns()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></a>
                  { guestCampaign.name }
                </h1>
              </div>
            </div>
            <ErrorMessage errors={errorsParams} model="guest_campaign" />
            <div className="card mb-10">
              <div className="card-body">
                <Form guestCampaign={ guestCampaign } updateCampaign={this._updateGuestCampaign} currentSavedSearch={currentSavedSearch} savedSearches={savedSearches} />
              </div>
            </div>
          </div>
        </div>
        <Breadcrumb
          steps={campaignStepBreadcrumbLinks(guestCampaign, {
            guestCampaignIsPersisted,
            searchQuery: this._queryString()
          })}
          activeStep={CAMPAIGN_STEPS[0]}
          backUrl={this._urlGuestList()}
          backName={I18n.t("react.guest_campaign.people_list")}
          nextUrl={nextUrl}
          nextName={nextName}
          modalIsOpen={false}
        />
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    guestCampaignIsPersisted: state.guestCampaign.persisted,
    errors: state.guestCampaign.errors,
    theEvent: state.event
  };
}

const mapDispatchToProps = {
  createGuestCampaign,
  fetchGuestCampaign,
  updateGuestCampaign,
  offlineUpdateGuestCampaign,
  guestCountFromQS,
  fetchSavedSearches,
  fetchCurrentSavedSearch,
  clearGuestCampaignStore
};

export default connect(mapStateToProps, mapDispatchToProps)(
  requiresProps(GuestCampaignInformation, {
    requirements: {
      theEvent: {
        fn: () => {},
        statePath: "event",
        desiredState: "has_underscore_id"
      },
      guestCampaign: {
        fn: (props) => {
          const { clearGuestCampaignStore } = props;
          clearGuestCampaignStore();
        },
        statePath: "guestCampaign.guestCampaign",
        desiredState: "empty"
      },
      guestCampaign2: {
        propName: "guestCampaign",
        waitFor: ["guestCampaign"],
        fn: (props) => {
          const { match, fetchGuestCampaign } = props;
          if (match.params.guest_campaign_id) {
            fetchGuestCampaign(match.params.event_id, match.params.guest_campaign_id);
          } else {
            return { cancelled: true };
          }
        },
        options: {
          force: true
        },
        statePath: "guestCampaign.guestCampaign"
      },
      savedSearches: {
        fn: (props) => {
          const { fetchSavedSearches, match } = props;
          fetchSavedSearches(match.params.event_id);
        },
        options: {
          force: true
        },
        statePath: "savedSearches.data"
      },
      currentSavedSearch: {
        waitFor: ["guestCampaign2"],
        fn: (props) => {
          const { guestCampaign, fetchCurrentSavedSearch, match, location } = props;
          if (guestCampaign && !guestCampaign.saved_search_id) {
            fetchCurrentSavedSearch(match.params.event_id, querystring.parse(location.search.substring(1)).q);
          } else {
            return { cancelled: true };
          }
        }
      }
    }
  })
);
