import { ActionTypes } from "../constants/Constants";

const defaultState = {
  errors: null,
  data: null,
  pendingRequest: false,
  fetched: false
};

function preconfiguredEvents(state = defaultState, action): any {
  switch (action.type) {
  case ActionTypes.FETCH_PRECONFIGURED_EVENTS_REQUEST:
    return { ...defaultState, pendingRequest: true };
  case ActionTypes.FETCH_PRECONFIGURED_EVENTS_SUCCESS:
    return { ...defaultState, data: action.data, fetched: true };
  default:
    return state;
  }
}

export default preconfiguredEvents;
