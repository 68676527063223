"use strict";
import { Fragment, Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import withModal from "./withModal.react";
import { queryStringAndSelectedGuests } from "../utils/QueryStringUtils";
import { updateUI } from "../actions/UIAppearanceActionCreators";
import { deleteSavedSearch, fetchSavedSearch } from "../actions/SavedSearchActionCreators";
import { urlWithQuery } from "../utils/pathUtils";
import ErrorMessage from "../components/shared/ErrorMessage.react";
import Loader from "../components/shared/Loader.react";

function i18n(key, params = {}): string {
  return I18n.t(`react.delete_saved_search_modal.${key}`, params);
}

interface Props {
  history: any,
  savedSearches: any[];
  extendedSavedSearch: any;
  errors: any;
  updateUI(params: any): void;
  deleteSavedSearch(eventId: string, id: string, name: string, forceDelete: boolean): void;
  fetchSavedSearch(eventId: string, id: string, options: any): void;
  match: any;
}

interface State {
  name: string;
  searchQuery: string;
  currentSearchQuery: string;
  searchLoaded: boolean;
  forceDelete: boolean;
}

class DeleteSavedSearchModal extends Component<Props, State> {
  constructor(props) {
    super(props);

    [
      "delete",
      "forceDeleteChange"
    ].forEach(fn => (this[fn] = this[fn].bind(this)));

    this.state = {
      searchLoaded: false,
      name: null,
      searchQuery: null,
      currentSearchQuery: queryStringAndSelectedGuests(props.location),
      forceDelete: false
    };
  }

  componentDidMount(): void {
    const savedSearch = this.savedSearch();
    if (savedSearch) {
      this.props.fetchSavedSearch(savedSearch.event_id, savedSearch._id, ["usages"]);
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { updateUI, extendedSavedSearch } = this.props;
    const { searchLoaded } = this.state;

    if (!extendedSavedSearch) {
      const savedSearch = this.savedSearch();
      if (savedSearch) {
        this.props.fetchSavedSearch(savedSearch.event_id, savedSearch._id, ["usages"]);
      }
    }
    if (searchLoaded) return;
    if (prevProps.extendedSavedSearch !== extendedSavedSearch) {
      const modalTitle = i18n("title", { name: extendedSavedSearch.name });
      updateUI({ modalTitle });
      this.setState({
        searchLoaded: true,
        name: extendedSavedSearch.name,
        searchQuery: extendedSavedSearch.search_query
      });
    }
  }

  savedSearch(): any {
    const { match, savedSearches } = this.props;

    return savedSearches && savedSearches.find(savedSearch => {
      return savedSearch._id === match.params.saved_search_id;
    });
  }

  delete(): void {
    const { extendedSavedSearch, history, deleteSavedSearch } = this.props;
    const { currentSearchQuery, forceDelete } = this.state;
    const { event_id, _id, name } = extendedSavedSearch;

    if (extendedSavedSearch) {
      deleteSavedSearch(event_id, _id, name, forceDelete);
      history.push(urlWithQuery(currentSearchQuery, "guests"));
    }
  }

  renderBackToSegmentsListButton(): JSX.Element {
    const { currentSearchQuery } = this.state;

    return <Link to={urlWithQuery(currentSearchQuery, "guests/modal/saved_searches")} className="btn btn-secondary float-end mr-10">
      <i className="fa-regular fa-chevron-left fa-fw fa-xs"></i> { i18n("back_to_saved_searches_list") }
    </Link>;
  }

  isUsed(): boolean {
    const { extendedSavedSearch } = this.props;
    return Object.values(extendedSavedSearch.usages as any).some((arr: any) => arr.length > 0);
  }

  deleteDisabled(): boolean {
    const { forceDelete } = this.state;
    const { extendedSavedSearch } = this.props;
    if (forceDelete) return false;

    return Object.values(extendedSavedSearch.usages as any).some((arr: any) => arr.length > 0);
  }

  renderDeleteSearchButton(): JSX.Element {
    return <button onClick={this.delete} className="btn btn-danger float-end" disabled={this.deleteDisabled()}>
      {i18n("delete")}
    </button>;
  }

  forceDeleteChange(): void {
    const { forceDelete } = this.state;
    this.setState({ forceDelete: !forceDelete });
  }

  renderForceDeleteCheckbox(): JSX.Element {
    const { forceDelete } = this.state;
    return <div className="form-check">
      <label className="form-check-label">
        <input type="checkbox" className="form-check-input" checked={forceDelete} name="force_delete"
          onChange={this.forceDeleteChange}/> { i18n("force_delete") }
      </label>
    </div>;

  }

  renderUsagesCell(): JSX.Element {
    const { extendedSavedSearch } = this.props;
    if (!extendedSavedSearch.usages) return null;

    return (
      <div className="row">
        {Object.keys(extendedSavedSearch.usages).map((key) => (
          <Fragment key={key}>
            {extendedSavedSearch.usages[key].map((item, index) => (
              <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                {item.name}
              </a>
            ))}
          </Fragment>
        ))}
      </div>
    );
  }

  render(): JSX.Element {
    if (!this.savedSearch()) return null;

    const { errors } = this.props;
    const { searchLoaded } = this.state;

    if (!searchLoaded) {
      return <Loader/>;
    }

    return <div>
      <ErrorMessage errors={errors} model="saved_search"/>
      <h3>
        { i18n("description") }
      </h3>
      { this.isUsed() ? this.renderUsagesCell() : i18n("nowhere") }
      <div className="text-muted my-5">
        { this.isUsed()
          ? <>
            <span className="badge rounded-pill bg-info">
              <i className="fa-regular fa-check fa-inverse"></i>
              { I18n.t("help") }
            </span>
            { i18n("explanation") }
            { this.renderForceDeleteCheckbox() }</>
          : "" }
      </div>
      <p className="text-end">
        { this.renderDeleteSearchButton() }
        { this.renderBackToSegmentsListButton() }
      </p>
    </div>;
  }
}

function mapStateToProps(state): any {
  return {
    savedSearches: state.savedSearches.data,
    extendedSavedSearch: state.savedSearch,
    errors: state.savedSearches && state.savedSearches.errors
  };
}

const mapDispatchToProps = {
  updateUI,
  deleteSavedSearch,
  fetchSavedSearch
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withModal(DeleteSavedSearchModal)));
