import { useState } from "react";
import classNames from "classnames";
import _ from "lodash";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ConfirmationModal from "../shared/ConfirmationModal.react";
import { WorkflowStep, WorkflowStepType } from "../../types/Workflow";
import { WORKFLOW_STEP_TRIGGER, WORKFLOW_STEP_ACTION, WORKFLOW_STEP_FILTER, AVAILABLE_OPERATORS_FOR_AUTOMATION } from "../../constants/Constants";
import { extractPath } from "../../utils/workflowUtils";

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.workflows.builder.step_card.${key}`, opts);
}

interface Props {
  workflowStep: WorkflowStep;
  activeStepId: string;
  isPendingRequest: boolean;
  isVersionEditable: boolean;
  allWorkflowSteps: WorkflowStep[];
  onClickStepCard(step: WorkflowStep): void;
  onAddActionStep(previousStep: WorkflowStep): void;
  onAddFilterStep(previousStep: WorkflowStep): void;
  onRemoveStep(step: WorkflowStep): void;
  onSwitchVersionRequest(): void;
}

const WorkflowStepCard: React.FC<Props> = ({
  workflowStep,
  activeStepId,
  isPendingRequest,
  isVersionEditable,
  allWorkflowSteps,
  onClickStepCard,
  onAddActionStep,
  onAddFilterStep,
  onRemoveStep,
  onSwitchVersionRequest
}) => {
  const [stepTypeSelectorDisplayed, setStepTypeSelectorDisplayed] = useState<boolean>(false);
  const [confirmationModelDisplayed, setConfirmationModelDisplayed] = useState<boolean>(false);

  const clickStepCard = (): void => {
    onClickStepCard(workflowStep);
  };

  const clickAddNewStep = (): void => {
    setStepTypeSelectorDisplayed(true);
  };

  const removeStep = (): void => {
    return onRemoveStep(workflowStep);
  };

  const selectStepTypeHandler = (type: WorkflowStepType): (() => void) => {
    return (): void => {
      setStepTypeSelectorDisplayed(false);
      if (type === WORKFLOW_STEP_ACTION) {
        onAddActionStep(workflowStep);
      } else if (type === WORKFLOW_STEP_FILTER) {
        onAddFilterStep(workflowStep);
      }
    };
  };

  const stepCardClasses = (): any => {
    return classNames({
      "card": true,
      "border-success border-2": (workflowStep._id && activeStepId === workflowStep._id) || (workflowStep.temporaryId && activeStepId === workflowStep.temporaryId),
      "mb-0": true
    });
  };

  const canAddStep = (): boolean => {
    return allWorkflowSteps.every((step) => !step.temporaryId);
  };

  const renderStepLeadText = (): JSX.Element => {
    let leadText;

    if (workflowStep.type === WORKFLOW_STEP_TRIGGER) {
      leadText = i18n("when");
    } else if (workflowStep.type === WORKFLOW_STEP_ACTION) {
      leadText = i18n("then");
    } else if (workflowStep.type === WORKFLOW_STEP_FILTER) {
      leadText = i18n("if");
    }

    return <p className="text-muted">{leadText}</p>;
  };

  const renderStepIcon = (): JSX.Element => {
    if (workflowStep.errors && Object.keys(workflowStep.errors).length) {
      return <i className="fa-regular fa-warning text-danger mr-5"></i>;
    } else if (workflowStep.hasChanges) {
      return <i className="fa-regular fa-warning text-warning mr-5"></i>;
    } else if (workflowStep.type === WORKFLOW_STEP_TRIGGER) {
      return <i className="fa-regular fa-play mr-5"></i>;
    } else if (workflowStep.type === WORKFLOW_STEP_ACTION) {
      return <i className="fa-regular fa-bolt mr-5"></i>;
    } else if (workflowStep.type === WORKFLOW_STEP_FILTER) {
      return <i className="fa-regular fa-arrow-right-arrow-left mr-5"></i>;
    }
  };

  const renderStepHeader = (): JSX.Element => {
    return <p>
      <small className="text-muted">{renderStepIcon()}{i18n(workflowStep.type)}</small>
    </p>;
  };

  const strategyLabel = (): string | JSX.Element => {
    if (workflowStep.type === WORKFLOW_STEP_TRIGGER) {
      return I18n.t(`automation.triggers.full.${workflowStep.strategy}`);
    } else if (workflowStep.type === WORKFLOW_STEP_ACTION && workflowStep.strategy) {
      if (workflowStep.strategy === "delay") return delayLabel();

      return I18n.t(`automation.actions.${workflowStep.strategy}`);
    } else if (workflowStep.type === WORKFLOW_STEP_FILTER && workflowStep.conditions.length) {
      const condition = workflowStep.conditions[0][0];
      const nbConditions = _.flatten(workflowStep.conditions).length;

      return <span>
        {renderConditionValue(condition.field)}{ " " }
        {I18n.t(`react.conditional_operators.${AVAILABLE_OPERATORS_FOR_AUTOMATION[condition.operation].translationKey}`)}{ " " }
        {renderConditionValue(condition.value)}{ " " }
        {nbConditions > 1 && i18n("and_n_conditions", { count: nbConditions - 1 })}
      </span>;
    }

    return "";
  };

  const renderConditionValue = (value: string): JSX.Element => {
    if (!value) {
      return <span></span>;
    }

    const path = extractPath(value);

    if (!path) {
      return <strong>{value}</strong>;
    }

    const [workflowStepId, ...rest] = path.split(".");
    const workflowStepRank = allWorkflowSteps.find((workflowStep: WorkflowStep) => workflowStep._id == workflowStepId)?.rank;
    return <span className="badge bg-secondary rounded-pill">{`${workflowStepRank}.${rest.join(".")}`}</span>;
  };

  const delayLabel = (): string => {
    const delay = workflowStep.settings["duration"];

    if (delay) {
      return i18n("wait_delay", { delay: i18n(`wait_${delay["unit"]}`, { count: parseInt(delay["duration"]) }) });
    }

    return i18n("wait");
  };

  const renderStepStrategy = (): JSX.Element => {
    const label = strategyLabel();

    if (label === "") {
      if (workflowStep.type === WORKFLOW_STEP_ACTION) {
        return <span className="text-muted">{i18n("select_action")}</span>;
      } else if (workflowStep.type === WORKFLOW_STEP_FILTER) {
        return <span className="text-muted">{i18n("set_up_condition")}</span>;
      }
    }

    return <span>{workflowStep.rank}. {label}</span>;
  };

  const renderCardInterstice = (): JSX.Element => {
    return <div className="text-center">
      <p className="step-interstice mb-0">|</p>
      { stepTypeSelectorDisplayed ? renderStepTypesSelector() : renderAddStepButton() }
      { workflowStep.next_step_id && <p className="step-interstice mb-0">|</p> }
    </div>;
  };

  const renderAddStepButton = (): JSX.Element => {
    if (!canAddStep()) {
      const tooltipMsg = <Tooltip id="tooltip-disabled-switch">{i18n("save_step_before_adding")}</Tooltip>;
      return <OverlayTrigger placement="right" overlay={tooltipMsg}>
        <span>
          <button className="btn btn-secondary btn-sm rounded-circle disabled">
            <i className="fa-solid fa-plus"></i>
          </button>
        </span>
      </OverlayTrigger>;
    }

    return <Link to="#" className="btn btn-secondary btn-sm rounded-circle" onClick={isVersionEditable ? clickAddNewStep : onSwitchVersionRequest}>
      <i className="fa-solid fa-plus"></i>
    </Link>;
  };

  const renderRemoveStepButton = (): JSX.Element => {
    if (workflowStep.type == WORKFLOW_STEP_TRIGGER) return;

    return <Link to="#" className="remove-step btn btn-sm btn-secondary rounded-circle" onClick={isVersionEditable ? (): void => setConfirmationModelDisplayed(true) : onSwitchVersionRequest}>
      <i className="fa-regular fa-remove text-secondary" />
    </Link>;
  };

  const renderStepTypesSelector = (): JSX.Element => {
    return <div className="row">
      <div className="col-6">
        <div className="card" style={{ "cursor": "pointer" }}>
          <div className="card-body p-10" onClick={isVersionEditable ? selectStepTypeHandler(WORKFLOW_STEP_ACTION) : onSwitchVersionRequest}>
            <h3 className="card-title"><i className="fa-regular fa-bolt"></i></h3>
            <p className="card-text">{i18n("action")}</p>
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="card" style={{ "cursor": "pointer" }}>
          <div className="card-body p-10" onClick={isVersionEditable ? selectStepTypeHandler(WORKFLOW_STEP_FILTER) : onSwitchVersionRequest}>
            <h3 className="card-title"><i className="fa-regular fa-arrow-right-arrow-left"></i></h3>
            <p className="card-text">{i18n("condition")}</p>
          </div>
        </div>
      </div>
    </div>;
  };

  const renderDeleteConfirmationModal = (): JSX.Element => {
    return <ConfirmationModal
      isVisible={confirmationModelDisplayed}
      onClose={(): void => setConfirmationModelDisplayed(false)}
      onConfirm={removeStep}
      title={i18n("deletion_modal_title")}
      confirmationQuestion={i18n("deletion_modal_body", { strategy: workflowStep.type === WORKFLOW_STEP_FILTER ? i18n(workflowStep.type) : strategyLabel() })}
      confirmButtonClasses={`btn-danger ${isPendingRequest ? "disabled" : ""}`}
      restoreFocus={false}
      autoFocusConfirm={true}
    />;
  };

  return <div className="row justify-content-center" key={workflowStep.strategy} style={{ marginTop: "-25px" }}>
    <div className="col-5">
      {renderStepLeadText()}
      <div className={stepCardClasses()} style={{ "cursor": "pointer" }}>
        {renderRemoveStepButton()}
        <div className="card-body" onClick={clickStepCard}>
          {renderStepHeader()}
          {renderStepStrategy()}
        </div>
      </div>
      {renderCardInterstice()}
      {renderDeleteConfirmationModal()}
    </div>
  </div>;
};

export default WorkflowStepCard;
