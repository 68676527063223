import { ActionTypes, CALL_API } from "../constants/Constants";

export function updateUserSettings(eventId, params, redirectTo = null) {
  const { DEV_NULL, USER_SETTING_CREATE_SUCCESS, USER_SETTING_CREATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, USER_SETTING_CREATE_SUCCESS, USER_SETTING_CREATE_FAILURE],
      method: "POST",
      body: { user_setting: params },
      endpoint: `/events/${eventId}/user_settings.json`,
      redirectTo
    }
  };
}

export function fetchUserSettings(eventId) {
  const { USER_SETTING_REQUEST, USER_SETTING_SUCCESS, USER_SETTING_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [USER_SETTING_REQUEST, USER_SETTING_SUCCESS, USER_SETTING_FAILURE],
      method: "GET",
      endpoint: `/events/${eventId}/user_settings/show_from_current_user.json`
    }
  };
}
