import { Component } from "react";
import { connect } from "react-redux";
import { urlEventId } from "../utils/pathUtils";
import { updateUI } from "../actions/UIAppearanceActionCreators";
import * as actions from "../actions/LabelsActionCreators";
import ColoredOjectsTable from "../components/ColoredOjectsTable.react";
import ColoredObjectForm from "../components/ColoredObjectForm.react";
import Loader from "../components/shared/Loader.react";
import withModal from "./withModal.react";

class LabelsList extends Component {
  constructor(props) {
    super(props);
    [
      "handleCreate",
      "handleDelete",
      "handleUpdate",
      "setCurrentFilter",
    ].forEach((item) => { this[item] = this[item].bind(this); });
    this.state = { currentFilter: null };
  }

  componentDidMount() {
    const { updateUI, fetchLabels } = this.props;
    const updates = { "hideNavBar": true, "modalTitle": I18n.t("react.labels.title") };

    updateUI(updates);
    fetchLabels(urlEventId());
  }

  handleCreate(value, color) {
    this.props.createLabel(urlEventId(), value, color);
    this.setCurrentFilter(null);
  }

  handleUpdate(label, newValue, newColor) {
    this.props.updateLabel(urlEventId(), label._id, newValue, newColor);
  }

  handleDelete(label) {
    this.props.deleteLabel(urlEventId(), label._id);
  }

  setCurrentFilter(currentFilter) {
    this.setState({ currentFilter });
  }

  renderForm() {
    const { errors, lastUpdatedItemId, fetching } = this.props;
    const errorMessage = errors && !lastUpdatedItemId && errors.name;

    return <div className="mb-10">
      <ColoredObjectForm handleSave={this.handleCreate}
        errorMessage={errorMessage}
        i18nKey="labels"
        onInputChange={this.setCurrentFilter}
        fetching={fetching} />
    </div>;
  }

  renderTable() {
    const { lastUpdatedItemId, labels, errors } = this.props;

    return <ColoredOjectsTable items={labels}
      lastUpdatedItemId={lastUpdatedItemId}
      errors={errors}
      handleUpdate={this.handleUpdate}
      handleDelete={this.handleDelete}
      i18nKey="labels"
      modelName="label" />;
  }

  noItems() {
    return <div className="card nothing-yet mt-10">
      <div><i className="fa-regular fa-tags fa-xl"></i></div>
      <div>
        <h4>{I18n.t("react.labels.no_items")}</h4>
        <p className="sub-title">{I18n.t("react.labels.attach_to_guests")}</p>
      </div>
    </div>;
  }

  render() {
    const { labels } = this.props;
    const content = !labels ? <Loader /> : (labels.length > 0 ? this.renderTable() : this.noItems());

    return <div className="container-modal">
      {this.renderForm()}
      {content}
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    appearance: state.appearance,
    labels: state.labels.items,
    errors: state.labels.errors,
    lastUpdatedItemId: state.labels.lastUpdatedLabelId,
    fetching: state.labels.fetching
  };
}

const mapDispatchToProps = Object.assign({}, actions, { updateUI });
export default connect(mapStateToProps, mapDispatchToProps)(withModal(LabelsList));
