import { SavedSearch } from "../../../types/SavedSearch";
import RuleForm from "./RuleForm.react";
import { populationI18n } from "../../../utils/miscUtils";

interface Props {
  populations: string[];
  segments: SavedSearch[];
  featureScopes: string[];
  networkingRules: any;

  onChange: (rules: any) => void;
}

const i18n = (key: string): string => {
  return I18n.t(`react.networking.networking_rules.form.${key}`);
};

const featureLabel = (feature: string): string => {
  switch (feature) {
  case "global": return i18n("global_rule_form_sentence");
  case "meetings": return i18n("meetings_rule_form_sentence");
  }
};

const NetworkingRulesForm: React.FC<Props> = ({
  populations,
  segments,
  featureScopes,
  networkingRules,
  onChange
}) => {
  return <>
    {populations.map(population => {
      return <div key={population}>
        <label className="form-label">{populationI18n(population)}</label>
        {featureScopes.map(featureScope => {
          return <RuleForm
            key={`${population}_${featureScope}`}
            populations={populations}
            segments={segments}
            label={featureLabel(featureScope)}
            featureRule={networkingRules?.[population]?.[featureScope]}
            onChange={(rule): void => {
              const populationRules = networkingRules?.[population] || {};
              const nextPopulationRules = { ...populationRules, [featureScope]: rule };
              onChange({ ...networkingRules, [population]: nextPopulationRules });
            }}
          />;
        })}
      </div>;
    })}
  </>;
};

export default NetworkingRulesForm;
