"use strict";
import { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./style.scss";

class SearchBar extends Component {
  constructor(props) {
    super(props);
    [
      "onChange",
      "onSubmit",
      "onClear",
    ].forEach(item => this[item] = this[item].bind(this));
    this.state = { value: props.value };
  }

  onChange({ target }) {
    this.setState({ value: target.value });
    const { handleChange } = this.props;
    handleChange && handleChange(target.value);
  }

  onSubmit(e) {
    e.preventDefault();
    const { handleSubmit } = this.props;
    const { value } = this.state;
    handleSubmit && handleSubmit(value);
  }

  onClear() {
    this.setState({ value: null });
    const { handleClear } = this.props;
    handleClear && handleClear();
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value && value !== this.state.value) {
      this.setState({ value });
    }
  }

  renderClearButton() {
    const { handleClear, forceClearButtonDisplayed, clearTooltip } = this.props;
    const { value } = this.state;
    if (forceClearButtonDisplayed === false || !handleClear || !value) return null;

    const tooltip = <Tooltip id="clear-btn-tooltip">{clearTooltip || I18n.t("react.search_bar.clear_search")}</Tooltip>;
    return <OverlayTrigger overlay={tooltip} placement="top" delay={500}>
      <a style={{ cursor: "pointer", marginLeft: "5px" }} className="clear-btn text-muted" onClick={this.onClear}></a>
    </OverlayTrigger>;
  }

  render() {
    const { value } = this.state;
    const { placeholder } = this.props;
    return <form className="search-bar-form" onSubmit={this.onSubmit} style={{ position: "relative" }}>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          value={value === null ? "" : value}
          placeholder={placeholder}
          onChange={this.onChange}
          style={{ paddingRight: "40px" }}
        />
        <button className="btn btn-primary" type="submit" onClick={this.onSubmit}>
          <i className="fa-regular fa-magnifying-glass"></i>
        </button>
      </div>
      {this.renderClearButton()}
    </form>;
  }
}

module.exports = SearchBar;
