import { ActionTypes } from "../constants/Constants";
import { removeQuotes } from "../utils/StringUtils";

function registrationDate(state = {}, action) {
  switch (action.type) {
  case ActionTypes.SET_REGISTERED_AT_DATES: {
    let { registeredBefore, registeredAfter } = action.registeredAtDates;
    if (registeredBefore) { registeredBefore = `"${removeQuotes(registeredBefore)}"`; }
    if (registeredAfter) { registeredAfter = `"${removeQuotes(registeredAfter)}"`; }
    return { registeredBefore, registeredAfter };
  }
  default:
    return state;
  }
}

export default registrationDate;
