import { Component } from "react";
import reduce from "lodash/reduce";
import snakeCase from "lodash/snakeCase";
import camelCase from "lodash/camelCase";
import flatten from "lodash/flatten";
import SubFormByCategory from "./SubFormByCategory.react";
import BottomOptions from "./form_item_question/BottomOptions.react";
import conditionalDisplay from "../utils/conditionalDisplayUtils.js";
import { itemsByStep, stepBeingEdited } from "../utils/itemsBySectionUtils.js";
import { isTicket } from "../utils/formBuilderUtils.ts";

class SubForm extends Component {

  constructor(props) {
    super(props);
    ["updateDisplay",
      "updateOptions",
      "formatInitialOptions",
      "toggleSameConfigurationForAll"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = {
      options: this.formatInitialOptions(),
      sameConfigurationForAll: "all" in props.formItem.options.subforms,
      display: {
        conditional_display: this.props.formItem.display_conditions.length > 0
      }
    };
  }

  formatInitialOptions() {
    const { formItem } = this.props;
    let initialOptions = {};
    Object.keys(formItem.options.subforms).forEach(key => {
      const formattedOptions = reduce(formItem.options.subforms[key], function(result, value, optionKey) {
        result[camelCase(optionKey)] = value;
        return result;
      }, {});
      initialOptions[key] = Object.assign({}, this.defaultSubformProps(), formattedOptions);
    });
    return initialOptions;
  }

  defaultSubformProps() {
    return {
      guestCategoryId: null,
      defaultNb: 0,
      limit: -1,
      add: true,
      remove: true,
      addLabel: I18n.t("react.form_items.subform.add_label"),
      removeLabel: I18n.t("react.form_items.subform.remove_label"),
      copyFieldsMapping: {},
      inline: false,
      allowImport: false,
      importLabel: I18n.t("react.form_items.subform.import_label"),
      importedListLabel: I18n.t("react.form_items.subform.imported_list_label")
    };
  }

  formatOptionsForApi(options) {
    return reduce(options, function(result, value, key) {
      result[snakeCase(key)] = value;
      return result;
    }, {});
  }

  updateDisplay(newDisplay) {
    this.setState({ display: newDisplay });
  }

  updateOptions(key) {
    return (optionsForKey) => {
      const { updateHandler } = this.props;
      const { options } = this.state;
      const newOptions = Object.assign({}, options, { [key]: optionsForKey });
      const newOptionsForApi = reduce(newOptions, (result, options, categoryId) => {
        result[categoryId] = this.formatOptionsForApi(options);
        return result;
      }, {});

      this.setState({ options: newOptions });
      updateHandler({ options: { subforms: newOptionsForApi } });
    };
  }

  toggleSameConfigurationForAll(e) {
    const { options } = this.state;
    const { updateHandler } = this.props;

    if (e.target.checked) {
      const modelCategory = this.linkedCategories()[0];
      const newOptions = options[modelCategory.id];
      updateHandler({ options: { subforms: { all: this.formatOptionsForApi(newOptions) } } });
      this.setState({ options: { all: newOptions }, sameConfigurationForAll: true });
    } else {
      const newOptions = reduce(this.linkedCategories(), function(result, category) {
        result[category.id] = options.all;
        return result;
      }, {});
      const newOptionsForApi = reduce(newOptions, (result, options, categoryId) => {
        result[categoryId] = this.formatOptionsForApi(options);
        return result;
      }, {});
      updateHandler({ options: { subforms: newOptionsForApi } });
      this.setState({ options: newOptions, sameConfigurationForAll: false });
    }
  }

  linkedCategories() {
    const { guestCategories, registrationFormId } = this.props;
    return guestCategories.filter(category => category.registration_form_id == registrationFormId);
  }

  filteredParentFormItems() {
    const { parentFormItems, parentSections, parentSteps } = this.props;
    const currentStep = stepBeingEdited(parentSteps);

    const allSteps = flatten(parentSteps.map((step) => {
      if (step.rank > currentStep.rank)
        return []; // do not display items that are in a further step

      return itemsByStep(parentFormItems, parentSections, step, true);
    }));

    return allSteps;
  }

  renderConfiguration(key) {
    const { guestCategories, allGuestFields } = this.props;
    const { options } = this.state;
    const defaultOptions = this.defaultSubformProps();

    return <SubFormByCategory guestCategories={guestCategories}
      options={options[key] || defaultOptions}
      guestCategoryId={key}
      guestFields={allGuestFields}
      updateHandler={ this.updateOptions(key) }
      defaultOptions={defaultOptions}
      parentFormItems={this.filteredParentFormItems()} />;
  }

  renderSameConfigurationCheckbox() {
    const { options, sameConfigurationForAll } = this.state;

    if (this.linkedCategories().length <= 1 && "all" in options) {
      return;
    }

    return (
      <div className="col-12 mb-3">
        <div className="form-check">
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input" name="sameConfigurationForAll" checked={sameConfigurationForAll} onChange={this.toggleSameConfigurationForAll} /> { I18n.t("react.form_items.subform.same_configuration_for_all_label") }
          </label>
        </div>
      </div>
    );
  }

  render() {
    const { mode, updateHandler, destroyHandler, formItem, renderConditionalDisplay } = this.props;
    const { display, sameConfigurationForAll, options } = this.state;
    let renderedConfiguration;

    if (sameConfigurationForAll) {
      renderedConfiguration = this.renderConfiguration("all");
    } else {
      renderedConfiguration = this.linkedCategories().map(category => {
        return (<div key={category._id}>
          <h5>{ I18n.t("react.form_items.subform.subcategory_title") } <span className="badge rounded-pill big guest-category" style={{ backgroundColor: category.label_color }}>{category.name}</span></h5>
          { this.renderConfiguration(category._id) }
          <hr />
        </div>);
      });
    }

    if (mode == "edit") {
      return (
        <div>
          { renderedConfiguration }
          { this.renderSameConfigurationCheckbox() }

          { !isTicket(options) && display.conditional_display && renderConditionalDisplay() }

          <BottomOptions
            formItem={formItem}
            display={display}
            destroyable={!isTicket(options)}
            destroyHandler={destroyHandler}
            updateHandler={updateHandler}
            updateParentDisplay={this.updateDisplay} />
        </div>
      );
    } else if (mode == "read") {
      return (
        <div id="dropzone" className="card">
          <div className="card-body dropzone">
            <p className="dropzone-title">
              <i className="fa-regular fa-clone fa-3x" aria-hidden="true"></i>
            </p>
            <p className="dropzone-description mb-0">{ I18n.t("react.form_items.subform.content") }</p>
          </div>
        </div>
      );
    }
  }
}

SubForm.defaultProps = {
  nonEmptyValueField: ["defaultNb", "limit"]
};

export default conditionalDisplay(SubForm);
