import { CALL_API, ActionTypes } from "../constants/Constants";

export function fetchMessages(eventId: string, startAt: Date, endAt: Date): any {
  const { FETCH_MESSAGES_STATISTICS_REQUEST, FETCH_MESSAGES_STATISTICS_SUCCESS, FETCH_MESSAGES_STATISTICS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_MESSAGES_STATISTICS_REQUEST, FETCH_MESSAGES_STATISTICS_SUCCESS, FETCH_MESSAGES_STATISTICS_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/messages/statistics.json?start_at=${startAt?.toJSON()}&end_at=${endAt?.toJSON()}`
    }
  };
}
