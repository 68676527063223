import { Component } from "react";
import { connect } from "react-redux";
import {
  fetchTranslations,
  autoTranslation,
  createTranslation,
  updateTranslation,
  selectTranslation,
  offlineSetTranslationTable,
  clearState,
  fetchTranslatableFields,
  deleteTranslation
} from "../../actions/TranslationsActionCreators";
import { requestEvent } from "../../actions/ImportActionCreators";
import RichTextEditor from "../../components/shared/RichTextEditor.react";
import pick from "lodash/pick";
import AvailableFrontendLocales from "../../../config/frontend_locales.yml";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import ImageUploader from "../../components/shared/ImageUploader.react";
import Loader from "../../components/shared/Loader.react";
import querystring from "querystring";
import { PATH_NAME_RESOURCE_ID_REGEX } from "../../constants/Constants";
import { translatedTextFromJson } from "../../utils/templatesBuilderUtils";
import { fetchWebsiteSectionTypes } from "../../actions/WebsiteActionCreators";
import { fetchEmailTemplateSectionTypes } from "../../actions/EmailBuilderActionCreators";
import SearchBar from "../../components/search_bar/SearchBar.react";
import Modal from "react-modal";
import { defaultModalSmallStyles } from "../../constants/Style";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";

const TRANSLATABLE_PARENT_TYPE_FROM_MENU_NAME = {
  "blog_articles": "BlogArticle",
  "registration_forms": "RegistrationForm",
  "accesspoints": "Accesspoint",
  "guest_fields": "GuestField",
  "guest_categories": "GuestCategory",
  "email_templates": "EmailTemplate",
  "website_layouts": "WebsiteLayout",
  "website_pages": "WebsitePage",
  "website_menus": "WebsiteMenu",
  "custom_emails": "CustomEmail",
  "guest_campaigns": "GuestCampaign",
  "thematics": "Thematic",
  "website": "Website",
  "app_home_screen_config": "AppHomeScreenConfig",
  "guest_product": "GuestProduct",
  "event": "Event",
  "session_rooms": "SessionRoom",
  "session_types": "SessionType",
  "programs": "Program",
  "leads_qualification": "LeadQualificationField",
  "meeting_locations": "MeetingLocation",
  "document_templates": "DocumentTemplate",
  "guest_product_collections": "GuestProductCollection"
};

function i18n(key, opts = {}) {
  return I18n.t(`react.translations.${key}`, opts);
}

function humanFieldName(entityType, fieldName) {
  return entityType && ["trait", "option"].includes(entityType) ? fieldName.replace(/SPECIAL_HASH_KEY_CHARACTER/g, "_") : i18n(fieldName, { defaultValue: fieldName });
}

class TranslationTable extends Component {
  constructor(props) {
    super(props);
    [
      "changeSelectedLocale",
      "searchTranslations",
      "updateTranslation",
      "changeSearchTranslations",
      "toggleDeletionModal",
      "toggleTranslationModal"
    ].forEach(item => this[item] = this[item].bind(this));
    this.state = {
      unmountComponents: false,
      search: "",
      filteredTranslationKeys: null,
      isOpen: false,
      deletionIsOpen: false
    };
  }

  componentDidMount() {
    const { requestEvent, fetchTranslations, clearState, fetchWebsiteSectionTypes, fetchEmailTemplateSectionTypes } = this.props;
    clearState();
    requestEvent();
    fetchTranslations(this.eventId(), this.id());
    this.fetchObject();
    this.setTranslatableParentType();

    if (this.sectionTypesNeedFetched()) {
      switch (this.type()) {
      case "website_pages": {
        fetchWebsiteSectionTypes(this.eventId(), "website");
        break;
      }
      case "blog_articles": {
        fetchWebsiteSectionTypes(this.eventId(), "blog_article");
        break;
      }
      case "email_templates": {
        fetchEmailTemplateSectionTypes(this.eventId(), this.id());
        break;
      }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { loading } = this.props;
    this.setDefaultLocaleIfNeeded(this.props);
    if (!loading && prevProps.loading) {
      this.unMountComponents({ isOpen: false, deletionIsOpen: false });
    }
  }

  sectionTypesNeedFetched() {
    const { sectionBuilderType, sectionTypes } = this.props;
    if (sectionTypes.length === 0 || sectionBuilderType === null) return true;

    switch (this.type()) {
    case "website_pages":
      return sectionBuilderType !== "website";
    case "blog_articles":
      return sectionBuilderType !== "blog_article";
    case "email_templates":
      return sectionBuilderType !== "email_template";
    }
  }

  eventId() {
    return this.props.eventId || this.props.match.params.event_id;
  }

  type() {
    return this.props.type || this.props.match.params.type;
  }

  id() {
    return this.props.id || this.props.match.params.id;
  }

  fetchObject() {
    const { fetchTranslatableFields, guestId } = this.props;
    const type = this.type();
    const id = this.id();
    const eventId = this.eventId();
    let customPathPart = type;
    if (type === "website_pages") customPathPart = "website/pages";
    else if (type === "website_menus") customPathPart = "website/menus";
    else if (type === "website_layouts") customPathPart = "website/layouts";
    else if (type === "guest_product") customPathPart = `guests/${guestId}/guest_products`;
    else if (type === "app_home_screen_config") customPathPart = "website/app_home_screen_config";
    else if (type === "blog_articles") customPathPart = "website/blog_articles";
    else if (type === "leads_qualification") customPathPart = "lead_qualification_fields";
    const idPathPart = ["website", "app_home_screen_config"].includes(type) ? "/" : `/${id}/`;
    if (type === "event") {
      fetchTranslatableFields(`/api/v1/events/${eventId}/translatable_fields.json`);
    } else {
      fetchTranslatableFields(`/api/v1/events/${eventId}/${customPathPart}${idPathPart}translatable_fields.json`);
    }
  }

  setTranslatableParentType() {
    this.setState({ translatableParentType: TRANSLATABLE_PARENT_TYPE_FROM_MENU_NAME[this.type()] });
  }

  setDefaultLocaleIfNeeded(props) {
    const { translations, availableLocales, selectedLocale, location } = props;
    if (!translations) return null;
    if (!availableLocales || availableLocales.length == 0) return null;
    if (selectedLocale) return null;
    const query = querystring.parse(location.search.substring(1));
    const nextLocale = (query.selected_locale) ? query.selected_locale : availableLocales[0];
    this.handleLocaleSelection(nextLocale);
  }

  updateTranslation(key, fieldInfo) {
    return (eventOrValue, customObjectValue = null) => {
      const { translationTable, offlineSetTranslationTable } = this.props;
      let newTranslationTable = Object.assign({}, translationTable);

      if (fieldInfo.type == "rich_text") {
        newTranslationTable[key] = { html: eventOrValue, draftjs_content: customObjectValue };
      } else if (fieldInfo.type == "image_url") {
        newTranslationTable[key] = customObjectValue || "";
      } else {
        newTranslationTable[key] = eventOrValue.target.value;
      }
      offlineSetTranslationTable(newTranslationTable);
    };
  }

  s3UploadURL() {
    const { event } = this.props;
    return `/api/v1/accounts/${event.account_id}/events/s3_upload_url.json`;
  }

  translationInput(key, fieldInfo, isAutoTranslated) {
    const { translationTable, autoTable, event } = this.props;
    const translation = translationTable[key] || (translationTable[key] === undefined && autoTable[key]) || "";

    if (fieldInfo.type == "image_url" || fieldInfo.type == "rich_text") {
      const { unmountComponents } = this.state;
      // RTE should be unmount and reloaded to handle language switch, if not old language value persists
      if (unmountComponents) return null;
    }

    if (fieldInfo.type == "rich_text") {
      return <RichTextEditor content={translation.html}
        draftjsContent={translation.draftjs_content}
        onBlur={this.updateTranslation(key, fieldInfo)}
        toolbarSize="extra-small"
        textGeneratorPrePromptKey="organizer_content_context"
        isAutoTranslated={isAutoTranslated}
      />;
    }

    if (fieldInfo.type == "image_url")
      return <ImageUploader
        field={key}
        url={ translation }
        qParams={{ event_id: event._id }}
        s3UploadUrl={this.s3UploadURL()}
        onFinishUpload={this.updateTranslation(key, fieldInfo)}
        onRemoveImage={this.updateTranslation(key, fieldInfo)}
      />;

    if (fieldInfo.type == "text_area")
      return <textarea className="form-control" rows="5" onChange={this.updateTranslation(key, fieldInfo)} value={translation} />;

    return <input
      type="text"
      className="form-control"
      onChange={this.updateTranslation(key, fieldInfo)}
      value={translation}
    />;
  }

  keyDefaultValue(key, fieldInfo) {
    const { type, value } = fieldInfo;
    if (type == "rich_text") {
      const html = value && value.replace(/<</g, "<span style='background-color: #DDD'>&lt;&lt;").replace(/>>/g, "&gt;&gt;</span>");
      return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
    }
    if (type == "image_url") return <div className="img-bg-btn"><img src={value} className="img-fluid" style={{ maxHeight: "200px" }}/></div>;
    return value;
  }

  fieldName(fieldInfo) {
    const { entity_type, field_name, field_index } = fieldInfo;
    let result = humanFieldName(entity_type, field_name);
    if (field_index !== undefined) result = `${result} ${field_index + 1}`;
    if (entity_type) result = `${result} (${i18n(entity_type, { defaultValue: entity_type })})`;
    return result;
  }

  translationLine(key, fieldInfo) {
    const { translationTable, autoTable } = this.props;
    const translation = translationTable[key];
    const autoTranslation = autoTable[key];
    const isAutoTranslated = (translation === undefined && autoTranslation !== undefined) ||
      (translation && translation.html !== undefined && translation.html.length !== 0 && autoTranslation &&
        translation.html === autoTranslation.html);

    return (
      <tr key={key}>
        <td>{ this.fieldName(fieldInfo) }</td>
        <td>{ this.keyDefaultValue(key, fieldInfo) }</td>
        <td>
          { fieldInfo.type === "rich_text" ? (
            this.translationInput(key, fieldInfo, isAutoTranslated)
          ) : (
            <div className="inner-addon right-addon">
              { isAutoTranslated ? <i className="fa-regular fa-language" title={i18n("automatic.auto_translated")}></i> : null }
              { this.translationInput(key, fieldInfo, isAutoTranslated) }
            </div>
          )}
        </td>
      </tr>
    );
  }

  translationSeparator(separatorKey, sectionTypeKey) {
    const { sectionTypes } = this.props;
    const sectionType = sectionTypes.find(s => s.filename === sectionTypeKey);
    if (!sectionType) return null;

    return <h3 key={separatorKey} style={{ margin: "15px 0" }}>
      {translatedTextFromJson(sectionType.schema, "name")}
    </h3>;
  }

  renderGroupedFieldsBySeparator(separatorKey, groupedFields, index) {
    const { translatableFields } = this.props;

    return <div key={index}>
      {separatorKey ? this.translationSeparator(separatorKey, translatableFields[separatorKey].section_type_key) : ""}
      <div className="table-responsive table-container mb-10" style={{ overflow: "auto" }}>
        <table className="table table-light table-bordered table-hover">
          <thead>
            <tr>
              <th className="col-md-2">{i18n("field")}</th>
              <th className="col-md-5">{i18n("default_translation")}</th>
              <th className="col-md-5">{i18n("current_language_translation")}</th>
            </tr>
          </thead>
          <tbody>
            {groupedFields}
          </tbody>
        </table>
      </div>
    </div>;
  }

  translationsLines() {
    const { translatableFields } = this.props;
    const { filteredTranslationKeys } = this.state;

    const translatableFieldsLength = Object.entries(translatableFields).length;
    let separatorKey;
    let groupedFields = [];
    return Object.entries(translatableFields).reduce((acc, [key, value], index) => {
      if (key.startsWith("section-separator-")) {
        if (index !== 0 && groupedFields.length > 0) {
          acc.push(this.renderGroupedFieldsBySeparator(separatorKey, groupedFields, index));
          groupedFields = [];
        }
      } else {
        if (!filteredTranslationKeys || filteredTranslationKeys.includes(key)) {
          groupedFields.push(this.translationLine(key, value));
        }
        if (index === translatableFieldsLength - 1 && groupedFields.length > 0) {
          acc.push(this.renderGroupedFieldsBySeparator(separatorKey, groupedFields, index));
        }
      }

      if (key.startsWith("section-separator-")) {
        separatorKey = key;
      }
      return acc;
    }, []);
  }

  searchWithoutResults() {
    const { filteredTranslationKeys, search } = this.state;
    return search && filteredTranslationKeys && filteredTranslationKeys.length === 0;
  }

  renderTranslations() {
    const { translatableFields, inSlidingPane } = this.props;
    if (!translatableFields) return <Loader />;

    if (Object.keys(translatableFields).length === 0) {
      return <div className="card nothing-yet mt-10"><h4>{ i18n("no_translatable_field") }</h4></div>;
    } else if (this.searchWithoutResults()) {
      return <div className="card nothing-yet mt-10"><h4>{ i18n("search_no_results") }</h4></div>;
    }

    return <div style={ inSlidingPane ? { margin: "0 0 15px 0", overflow: "auto", flexGrow: 1 } : {} }>
      {this.translationsLines()}
    </div>;
  }

  pathToIndex() {
    const { match } = this.props;
    return match.url.replace(PATH_NAME_RESOURCE_ID_REGEX, "");
  }

  submitForm(redirectTo = null) {
    return () => {
      const { translation, createTranslation, updateTranslation, selectedLocale, translationTable } = this.props;
      const { translatableParentType } = this.state;
      if (translation && translation._id) updateTranslation(this.eventId(), translation._id, { table: translationTable }, { notice: i18n("well_saved"), noticeType: "success" }, redirectTo);
      else createTranslation(this.eventId(), { table: translationTable, translatable_parent_id: this.id(), translatable_parent_type: translatableParentType, locale: selectedLocale }, { notice: i18n("well_saved"), noticeType: "success" }, redirectTo);
      if (!redirectTo) $("html, body").animate({ scrollTop: $("body").offset().top }, 1000);
    };
  }

  autoTranslate() {
    return () => {
      const { translation, autoTranslation, selectedLocale } = this.props;
      const { translatableParentType } = this.state;

      autoTranslation(this.eventId(),
        { translatable_parent_id: this.id(),
          translatable_parent_type: translatableParentType,
          locale: selectedLocale,
          id: translation && translation._id },
        { notice: i18n("automatic.translated_successfully"), noticeType: "success" }
      );
    };
  }

  delete() {
    return () => {
      const { translation, deleteTranslation, selectedLocale } = this.props;
      const { translatableParentType } = this.state;

      deleteTranslation(this.eventId(),
        { translatable_parent_id: this.id(),
          translatable_parent_type: translatableParentType,
          locale: selectedLocale,
          id: translation && translation._id },
        { notice: i18n("automatic.deleted_successfully"), noticeType: "success" }
      );
      this.unMountComponents({ deletionIsOpen: false });
    };
  }

  renderSubmitButtons() {
    const { translatableFields, onlySimpleValidateButton } = this.props;
    if (this.searchWithoutResults() || !translatableFields || Object.keys(translatableFields).length === 0) {
      return null;
    }

    return <div className="btn-wrapper" style={{ textAlign: "right" }}>
      {
        !onlySimpleValidateButton && <span onClick={this.submitForm(this.pathToIndex())} className="btn btn-primary">
          { i18n("validate") } → { i18n("back_to_list") }
        </span>
      }
      { " " }
      <button type="submit" className={`btn ${ onlySimpleValidateButton ? "btn-primary" : "btn-secondary" }`} onClick={this.submitForm()}>
        { i18n("validate") }
      </button>
    </div>;
  }

  unMountComponents(stateUpdates = {}) {
    this.setState({ ...stateUpdates, unmountComponents: true }, () => {
      setTimeout(() => {
        this.setState({ unmountComponents: false });
      }, 100);
    });
  }

  handleLocaleSelection(locale) {
    const { selectTranslation } = this.props;
    selectTranslation(locale);
    this.unMountComponents();
  }

  changeSelectedLocale(e) {
    this.handleLocaleSelection(e.target.value);
  }

  renderLocaleSwitch() {
    const { availableLocales, selectedLocale } = this.props;
    if (!availableLocales || !selectedLocale) return null;
    const options = AvailableFrontendLocales
      .sort((a, b) => {
        return (a[I18n.locale] > b[I18n.locale]) ? 1 : ((b[I18n.locale] > a[I18n.locale]) ? -1 : 0);
      })
      .map(locale => {
        if (availableLocales.indexOf(locale.code) < 0) return;
        const label = `${locale.flag} ${locale[I18n.locale]} (${locale.code})`;
        return <option value={locale.code} key={locale.code}>{label}</option>;
      });
    return <select className="form-select" onChange={this.changeSelectedLocale} value={selectedLocale} >
      { options }
    </select>;
  }

  renderTranslationModalContent() {
    const { loading } = this.props;
    const locale = AvailableFrontendLocales.find(s => s.code === this.props.selectedLocale);
    return <div>
      <div className="modal-header">
        <h4 className="modal-title">{ i18n("automatic.modal_title", { name: this.renderTitle(true) || "" }) }</h4>
        <button type="button" onClick={ this.toggleTranslationModal } className="btn-close" aria-label={I18n.t("close")}></button>
      </div>
      <div className="modal-body">
        <p>{ i18n("automatic.modal_body", { used_locale: locale[I18n.locale] }) }</p>
        <div className="mt-30">
        </div>
      </div>
      <div className="modal-footer center">
        <a href="#" onClick={ this.toggleTranslationModal } className="btn btn-secondary" >
          { i18n("automatic.cancel") }
        </a>
        { loading ? (
          <Loader size="small" color="black" />
        ) : (
          <a href="#" onClick={ this.autoTranslate() } className="btn btn-primary" >
            { i18n("automatic.translate") }
          </a>
        )}
      </div>
    </div>;
  }

  renderDeletionModalContent() {
    const locale = AvailableFrontendLocales.find(s => s.code === this.props.selectedLocale);

    return <div>
      <div className="modal-header">
        <h4 className="modal-title">{ i18n("automatic.delete_modal_title", { name: this.renderTitle(true) || "", used_locale: locale[I18n.locale] }) }</h4>
        <button type="button" onClick={ this.toggleDeletionModal } className="btn-close" aria-label={I18n.t("close")}></button>
      </div>
      <div className="modal-body">
        <p>{ i18n("automatic.delete_modal_body", { used_locale: locale[I18n.locale], name: this.renderTitle(true) || "" }) }</p>
        <div className="mt-30">
        </div>
      </div>
      <div className="modal-footer center">
        <a href="#" onClick={ this.toggleDeletionModal } className="btn btn-secondary" >
          { i18n("automatic.cancel") }
        </a>
        <a href="#" onClick={ this.delete() } className="btn btn-danger" >
          { i18n("automatic.confirm_deletion") }
        </a>
      </div>
    </div>;
  }

  toggleTranslationModal(e) {
    if (e) e.preventDefault();
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }
  toggleDeletionModal(e) {
    if (e) e.preventDefault();
    const { deletionIsOpen } = this.state;
    this.setState({ deletionIsOpen: !deletionIsOpen });
  }

  renderAutoTranslateButton() {
    if (!this.props.selectedLocale) return null;
    return <div>
      <Dropdown as={ButtonGroup}>
        <Button variant="secondary" onClick={this.toggleTranslationModal} style={{ whiteSpace: "nowrap" }}>
          { i18n("automatic.translate") }
        </Button>
        <Dropdown.Toggle variant="secondary" aria-haspopup="true"></Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item eventKey="1" onClick={this.toggleDeletionModal}>
            { i18n("automatic.delete") }
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Modal isOpen={this.state.isOpen} onRequestClose={this.toggleTranslationModal} style={defaultModalSmallStyles} contentLabel="Modal" portalClassName="portal-on-top" >
        { this.renderTranslationModalContent() }
      </Modal>
      <Modal isOpen={this.state.deletionIsOpen} onRequestClose={this.toggleDeletionModal} style={defaultModalSmallStyles} contentLabel="Modal" portalClassName="portal-on-top" >
        { this.renderDeletionModalContent() }
      </Modal>
    </div>;
  }

  renderErrors() {
    const { errors } = this.props;
    return <>
      <ErrorMessage errors={errors} style={{ marginTop: "20px" }} />
    </>;
  }

  renderTitle(onlyString = false) {
    const { parentObject } = this.props;
    let field = "";

    switch (this.type()) {
    case "event":
    case "registration_forms":
      field = "title"; break;
    case "guest_fields":
      field = "label"; break;
    case "website":
      return this.renderContent(onlyString, i18n("index.main_settings"));
    case "app_home_screen_config":
      return this.renderContent(onlyString, i18n("index.app_home_screen_config"));
    default:
      field = "name"; break;
    }
    return this.renderContent(onlyString, parentObject[field]);
  }

  renderContent(onlyString, content) {
    return onlyString ? content : <h2 style={{ marginTop: "0px" }}>{ content }</h2>;
  }

  matchSearch(inputValue, [fieldKey, fieldInfo]) {
    if (fieldKey.startsWith("section-separator-")) return false;

    const { translationTable, autoTable } = this.props;
    const translation = translationTable[fieldKey] || autoTable[fieldKey];
    const { entity_type, field_name, value: defaultValue } = fieldInfo;
    const fieldName = humanFieldName(entity_type, field_name);

    return [fieldName, defaultValue, translation].some(value => {
      if (typeof value === "string") {
        return value.toLowerCase().includes(inputValue.toLowerCase());
      } else if (typeof value === "object" && value["html"]) {
        return value["html"].toLowerCase().includes(inputValue.toLowerCase());
      }
      return false;
    });
  }

  searchTranslations(inputValue) {
    this.setState({ search: inputValue });
    if (inputValue.length === 0) {
      this.setState({ filteredTranslationKeys: null });
      return;
    }

    const { translatableFields } = this.props;
    const filteredTranslationKeys = Object.entries(translatableFields).reduce((acc, pair) => {
      if (this.matchSearch(inputValue, pair)) {
        acc.push(pair[0]);
      }
      return acc;
    }, []);
    this.setState({ filteredTranslationKeys });
  }

  changeSearchTranslations(inputValue) {
    clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(() => {
      this.searchTranslations(inputValue);
    }, 800);
  }

  renderTranslationsSearch() {
    const { translatableFields } = this.props;
    if (!translatableFields) return null;

    const { search } = this.state;
    return <SearchBar
      placeholder={i18n("search")}
      value={search}
      handleSubmit={this.searchTranslations}
      handleClear={() => this.searchTranslations("")}
      handleChange={this.changeSearchTranslations}
    />;
  }

  pageContent() {
    const { parentObject, translatableFields, inSlidingPane } = this.props;
    if (!parentObject || !translatableFields) return <div className="mt-10"><Loader /></div>;

    return <div style={{
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
    }} className={ inSlidingPane ? "in-sliding-pan" : ""}>
      <div className="subtitle-page" style={{ marginTop: "0px" }}>
        { this.renderTitle() }
        <div className="subtitle-page-content">
          <div className="subtitle-page-title">
            { this.renderTranslationsSearch() }
          </div>
          <div className="subtitle-page-btn">
            { this.renderAutoTranslateButton() }
            { this.renderLocaleSwitch() }
          </div>
        </div>
      </div>
      { this.renderTranslations() }
      { this.renderSubmitButtons() }
    </div>;
  }

  render() {
    return <div id="frontend-locales">
      { this.renderErrors() }
      { this.pageContent() }
    </div>;
  }
}

function mapStateToProps(state) {
  let res = pick(state,
    "event"
  );
  res.translations = state.translations.data;
  res.translation = state.translations.selectedTranslation;
  res.availableLocales = state.translations.availableLocales;
  res.selectedLocale = state.translations.selectedLocale;
  res.translatableFields = state.translations.currentTranslatableFieldsHash;
  res.translationTable = res.translation && res.translation.table || {};
  res.autoTable = res.translation && res.translation.auto_table || {};
  res.errors = state.translations.errors;
  res.loading = state.translations.loading;
  res.parentObject = state.translations.parentObject;
  res.sectionTypes = state.sectionTypes.items;
  res.sectionBuilderType = state.sectionTypes.builderType;
  return res;
}

const mapDispatchToProps = {
  fetchTranslatableFields,
  requestEvent,
  fetchTranslations,
  autoTranslation,
  deleteTranslation,
  createTranslation,
  updateTranslation,
  selectTranslation,
  offlineSetTranslationTable,
  clearState,
  fetchWebsiteSectionTypes,
  fetchEmailTemplateSectionTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(TranslationTable);
