import { ActionTypes } from "../constants/Constants";
import { insertOrUpdateObject, mergeObjectWithExistingObject } from "../utils/StateOperationUtils";

function registrationFormSteps(state = [], action) {
  switch (action.type) {
  case ActionTypes.REGISTRATION_FORM_SUCCESS: {
    const { formSteps } = action.data.entities;
    return Object.keys(formSteps || []).map(key => {
      return formSteps[key];
    });
  }
  case ActionTypes.REGISTRATION_FORM_STEP_CREATE_SUCCESS: {
    const { formSteps } = action.data.entities;
    return insertOrUpdateObject(state, formSteps[Object.keys(formSteps)[0]], "_id");
  }
  case ActionTypes.REGISTRATION_FORM_STEP_UPDATE_SUCCESS: {
    const { formStepId, params } = action;
    return insertOrUpdateObject(state, Object.assign({}, { _id: formStepId }, params, { updated_at: new Date() }), "_id");
  }
  case ActionTypes.REGISTRATION_FORM_STEP_UPDATE_FAILURE: {
    const { formStepId, data } = action;
    return mergeObjectWithExistingObject(state, { _id: formStepId, errors: data }, "_id");
  }
  case ActionTypes.REGISTRATION_FORM_STEP_DELETE_SUCCESS: {
    const { formStepId } = action;
    return state.filter(step => { return step._id !== formStepId; });
  }
  case ActionTypes.REGISTRATION_FORM_STEP_DELETE_FAILURE: {
    const { formStepId } = action;
    const newState = state.map(step => {
      if (step._id === formStepId) {
        step["errors"] = action.data.errors;
      }
      return step;
    });
    return newState;
  }
  case ActionTypes.REGISTRATION_FORM_ITEM_NEW:
    return uncollapseStepWithFormItem(state, action.formItem);
  case ActionTypes.REGISTRATION_FORM_ITEM_CREATE_SUCCESS:
    return uncollapseStepWithFormItem(state, action.data);
  case ActionTypes.REGISTRATION_FORM_STEP_CLEAN_ERRORS: {
    return state.map((step) => {
      if (step._id === action.formStepId) {
        return Object.assign({}, step, { errors: null });
      }
      return step;
    });
  }
  default:
    return state;
  }
}

function uncollapseStepWithFormItem(state, formItem) {
  const stepToUncollapse = state.find(step => {
    return step.form_sections.includes(formItem.form_section_id);
  });
  return state.map(step => {
    if (step._id === stepToUncollapse._id) {
      return Object.assign({}, step, { collapsed: false });
    }
    return step;
  });
}

export default registrationFormSteps;
