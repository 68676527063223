import { Component } from "react";
import ConditionalDisplayOption from "../components/form_item_question/ConditionalDisplayOption.react";

export default function conditionalDisplay(OriginalComponent) {
  return class conditionalDisplay extends Component {
    constructor(props) {
      super(props);
      ["renderConditionalDisplay"].forEach(item => {
        this[item] = this[item].bind(this);
      });
    }

    renderConditionalDisplay() {
      const { allGuestFields, formItem, updateHandler } = this.props;
      return <ConditionalDisplayOption
        guestFields={allGuestFields}
        displayConditions={formItem.display_conditions}
        updateHandler={updateHandler}
      />;
    }

    render() {
      return <OriginalComponent {...this.props} renderConditionalDisplay={this.renderConditionalDisplay} />;
    }
  };
}
