import { useContext } from "react";
import { featureName, enabledByDefault, featureDescription, childFeatures } from "../../utils/featureSetUtils";
import { FeatureDeckContext } from "../../containers/features/FeatureSetFormContainer.react";
import FeatureActionButton from "./FeatureActionButton.react";
import Dependencies from "./Dependencies.react";
import ReadMoreLink from "./ReadMoreLink.react";
import FeatureStatus from "./FeatureStatus.react";

interface Props {
  groupKey: string;
}

const FeaturesList: React.FC<Props> = ({ groupKey }) => {
  const { featureEnabled } = useContext(FeatureDeckContext);

  return <>
    {
      childFeatures(groupKey).map((featureKey) => {
        const label = featureEnabled(featureKey) || enabledByDefault(featureKey) ? "active" : "";

        return <div key={featureKey} className={`card ${ label }`}>
          <div className="card-body">
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-column justify-content-center">
                <p><strong>{ featureName(featureKey) }</strong></p>
              </div>
              <div>
                <FeatureActionButton featureKey={featureKey} />
              </div>
            </div>
            <div className="d-flex">
              <FeatureStatus featureKey={featureKey} />
              <Dependencies featureKey={featureKey} />
            </div>
            <p>{ featureDescription(featureKey) }</p>
            <ReadMoreLink featureKey={featureKey} />
          </div>
        </div>;
      })
    }
  </>;
};

export default FeaturesList;
