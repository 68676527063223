import { ActionTypes, CALL_API, WORKFLOW_RUNS_PER_PAGE } from "../constants/Constants";

export function fetchWorkflowRuns(eventId: string, workflowId = null, statuses = null, since = null, before = null, page = 1): any {
  const { FETCH_WORKFLOW_RUNS_REQUEST, FETCH_WORKFLOW_RUNS_SUCCESS, DEV_NULL } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_WORKFLOW_RUNS_REQUEST, FETCH_WORKFLOW_RUNS_SUCCESS, DEV_NULL],
      method: "GET",
      body: { workflow_id: workflowId, statuses, since, before },
      endpoint: `/api/v1/events/${eventId}/automation/workflow_runs.json?page=${page}&per_page=${WORKFLOW_RUNS_PER_PAGE}`
    }
  };
}

export function fetchWorkflowRun(eventId: string, workflowRunId: string): any {
  const { FETCH_WORKFLOW_RUN_REQUEST, FETCH_WORKFLOW_RUN_SUCCESS, DEV_NULL } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_WORKFLOW_RUN_REQUEST, FETCH_WORKFLOW_RUN_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/automation/workflow_runs/${workflowRunId}.json`
    }
  };
}

export function fetchWorkflowRunsTotalCount(eventId: string, workflowId = null, statuses = null, since = null, before = null): any {
  const { FETCH_WORKFLOW_RUNS_COUNT_SUCCESS, DEV_NULL } = ActionTypes;

  return {
    [CALL_API]: {
      types: [DEV_NULL, FETCH_WORKFLOW_RUNS_COUNT_SUCCESS, DEV_NULL],
      method: "GET",
      body: { workflow_id: workflowId, statuses, since, before },
      endpoint: `/api/v1/events/${eventId}/automation/workflow_runs/count.json`
    }
  };
}

export function resetCountedAll(): any {
  return {
    type: ActionTypes.RESET_WORKFLOW_RUNS_COUNTED_ALL
  };
}

export function resetSelectedWorkflowRun(): any {
  return {
    type: ActionTypes.RESET_SELECTED_WORKFLOW_RUN
  };
}

export function deleteWorkflowRun(eventId: string, workflowRunId: string, notificationsOptions?: any): any {
  const { DEV_NULL, DELETE_WORKFLOW_RUN_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, DELETE_WORKFLOW_RUN_SUCCESS, DEV_NULL],
      method: "DELETE",
      endpoint: `/api/v1/events/${eventId}/automation/workflow_runs/${workflowRunId}.json`,
      notificationsOptions
    },
    workflowRunId
  };
}

export function replayWorkflowRun(eventId: string, workflowRunId: string, notificationsOptions?: any): any {
  const { DEV_NULL, REPLAY_WORKFLOW_RUN_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, REPLAY_WORKFLOW_RUN_SUCCESS, DEV_NULL],
      method: "PUT",
      body: {},
      endpoint: `/api/v1/events/${eventId}/automation/workflow_runs/${workflowRunId}/replay.json`,
      notificationsOptions
    }
  };
}

export function batchDeleteWorkflowRuns(eventId: string, workflowId = null, statuses = null, since = null, before = null, ids?: string[], notificationsOptions?: any): any {
  const { BATCH_DELETE_WORKFLOW_RUNS_PENDING, BATCH_DELETE_WORKFLOW_RUNS_SUCCESS, DEV_NULL } = ActionTypes;
  return {
    [CALL_API]: {
      types: [BATCH_DELETE_WORKFLOW_RUNS_PENDING, BATCH_DELETE_WORKFLOW_RUNS_SUCCESS, DEV_NULL],
      method: "DELETE",
      body: { workflow_id: workflowId, statuses, since, before, ids },
      endpoint: `/api/v1/events/${eventId}/automation/workflow_runs/batch_destroy.json`,
      notificationsOptions
    }
  };
}

export function batchReplayWorkflowRuns(eventId: string, workflowId = null, statuses = null, since = null, before = null, ids?: string[], notificationsOptions?: any): any {
  const { BATCH_REPLAY_WORKFLOW_RUNS_PENDING, BATCH_REPLAY_WORKFLOW_RUNS_SUCCESS, DEV_NULL } = ActionTypes;
  return {
    [CALL_API]: {
      types: [BATCH_REPLAY_WORKFLOW_RUNS_PENDING, BATCH_REPLAY_WORKFLOW_RUNS_SUCCESS, DEV_NULL],
      method: "PUT",
      body: { workflow_id: workflowId, statuses, since, before, ids },
      endpoint: `/api/v1/events/${eventId}/automation/workflow_runs/batch_replay.json`,
      notificationsOptions
    }
  };
}
