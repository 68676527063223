import { Component } from "react";
import AsyncSelect from "react-select/async";
import { urlEventId } from "../utils/pathUtils";
import uuid from "tiny-uuid4";

const I18N_ROOT_KEY = "react.resource_search_dropdown";

interface RequestResources {
  (eventId: string, completeSearchQuery: string, page: number, options?: any): void;
}

interface Props {
  allowMultipleSelect?: boolean;
  cleanUniqueKeyInSiloedData(uniqueKeyInSiloedData: string): void;
  i18nRootKey?: string;
  onSelectResource(selectedResources: any[]): void;
  requestResources: RequestResources;
  requestResourcesFromIds?: RequestResources;
  resourceMapping?(resource: any): any;
  resources: any[];
  idsToExclude?: string[];
  selectedResources: any[];
  selectedResourcesIds?: string[];
  siloedData: any;
  styles?: any;
  components?: any;
  className?: any;
  classNamePrefix?: any;
}

interface State {
  inputValue: string;
  selectedResources: any[];
}

class ResourceSearchDropdown extends Component<Props, State> {
  fetchResourcesTimeout: number;
  uniqueKeyInSiloedData: string;
  fetchingResourcesFromIds: boolean;
  loadOptionsCallback?: (any) => any;

  static defaultProps = {
    allowMultipleSelect: false,
    i18nRootKey: I18N_ROOT_KEY
  };

  constructor(props: Props) {
    super(props);
    [
      "loadOptions",
      "onInputChange",
      "onSelectResource"
    ].forEach((item) => {
      this[item] = this[item].bind(this);
    });

    this.state = { inputValue: "", selectedResources: [] };
    this.fetchResourcesTimeout = null;
    this.uniqueKeyInSiloedData = null;
    this.loadOptionsCallback = null;
    this.fetchingResourcesFromIds = false;
  }

  componentDidMount(): void {
    const { selectedResources, selectedResourcesIds } = this.props;
    if (selectedResources && selectedResources.length) {
      const resources = Array.isArray(selectedResources) ? selectedResources : [selectedResources];
      this.setDefaultSelectedResources(resources);
    } else if (selectedResourcesIds && selectedResourcesIds.length) {
      this.requestResourcesFromIds();
    }
  }

  requestResourcesFromIds(): void {
    this.fetchingResourcesFromIds = true;

    const { requestResourcesFromIds, selectedResourcesIds } = this.props;
    this.uniqueKeyInSiloedData = uuid();
    requestResourcesFromIds(urlEventId(), "", 1, { searchFilters: { id: selectedResourcesIds }, uniqueKeyInSiloedData: this.uniqueKeyInSiloedData });
  }

  componentDidUpdate(prevProps): void {
    const { resources } = this.props;
    const { resources: prevResources } = prevProps;
    if (this.loadOptionsCallback && !this.fetchingResourcesFromIds && resources && resources.length > 0 && resources != prevResources) {
      // callback from loadOptions to trigger on requestResources ajax response
      this.loadOptionsCallback(this.resourceOptions());
      this.loadOptionsCallback = null;
    }

    const { siloedData, cleanUniqueKeyInSiloedData } = this.props;
    if (this.fetchingResourcesFromIds && siloedData[this.uniqueKeyInSiloedData]) {
      this.fetchingResourcesFromIds = false;
      this.setDefaultSelectedResources(siloedData[this.uniqueKeyInSiloedData]);
      cleanUniqueKeyInSiloedData(this.uniqueKeyInSiloedData);
      this.uniqueKeyInSiloedData = null;
    }
  }

  setDefaultSelectedResources(resources: any[]): void {
    this.setState({ selectedResources: resources.map(resource => this.resourceMapping(resource)) });
  }

  resourceMapping(resource: any): any {
    const { resourceMapping } = this.props;
    const performMapping = resourceMapping || this.defaultResourceMapping;
    return performMapping(resource);
  }

  defaultResourceMapping(resource: any): any {
    const resourceId = resource.id || resource._id;
    return {
      _id: resourceId,
      label: resource.identity || resource.name || resourceId,
      value: resourceId
    };
  }

  loadOptions(inputValue: string, callback: (RequestResources) => void): void {
    const { requestResources } = this.props;
    if (this.fetchResourcesTimeout) clearTimeout(this.fetchResourcesTimeout);
    this.loadOptionsCallback = callback;
    this.fetchResourcesTimeout = setTimeout(() => {
      requestResources(urlEventId(), inputValue, 1);
    }, 600);
  }

  onInputChange(inputValue: string): void {
    this.setState({ inputValue });
  }

  onSelectResource(selectedResources: any): void {
    const ressources = Array.isArray(selectedResources) ? selectedResources : [selectedResources];
    this.setState({ selectedResources: ressources });

    const { onSelectResource } = this.props;
    onSelectResource(ressources);
  }

  i18n(key: string, options: any = {}): string {
    const { i18nRootKey } = this.props;
    return I18n.t(`${i18nRootKey}.${key}`, options);
  }

  resourceOptions(): any[] {
    const { resources, idsToExclude } = this.props;
    if (!resources) return [];

    const { selectedResources } = this.state;
    const selectedResourcesIds = selectedResources.map(resource => resource.value);
    return resources.filter(resource => {
      if (selectedResourcesIds.includes(resource.id)) return false;
      if (!idsToExclude) return true;

      return !idsToExclude.includes(resource.id);
    }).map(resource => this.resourceMapping(resource));
  }

  render(): JSX.Element {
    const { selectedResources } = this.state;
    const { allowMultipleSelect, components, styles, className, classNamePrefix } = this.props;

    return (
      <AsyncSelect
        className={className}
        classNamePrefix={classNamePrefix}
        isClearable={false}
        loadOptions={this.loadOptions}
        defaultOptions={true}
        isMulti={allowMultipleSelect}
        closeMenuOnSelect={!allowMultipleSelect}
        loadingMessage={(): string => this.i18n("loading_placeholder")}
        noOptionsMessage={(): string => this.i18n("no_results_text")}
        onChange={this.onSelectResource}
        onInputChange={this.onInputChange}
        placeholder={this.i18n("select_placeholder")}
        value={allowMultipleSelect ? selectedResources : selectedResources[0]}
        styles={styles}
        components={components}
      />
    );
  }
}

export default ResourceSearchDropdown;
