import { ActionTypes } from "../constants/Constants";
import orderBy from "lodash/orderBy";
import { createDefaultError } from "./unexpectedApiError";

const defaultState = {
  data: [],
  errors: {},
  ascending: false,
  property: "",
};

function savedSearches(state = defaultState, action) {
  switch (action.type) {
  case ActionTypes.FETCH_SAVED_SEARCH_SUCCESS: {
    const data = orderBy(action.data, ["created_at"], "desc");
    return { ...state, data, errors: {} };
  }
  case ActionTypes.SORT_SAVED_SEARCHES: {
    const order = action.ascending ? "asc" : "desc";
    const data = orderBy(state.data, [action.property], order);
    return { ...state, data, errors: {} };
  }
  case ActionTypes.DELETE_SAVED_SEARCH_SUCCESS: {
    const data = state.data.filter(item => {
      return item._id != action.id;
    });
    return { ...state, data, errors: {} };
  }
  case ActionTypes.UPDATE_SAVED_SEARCH_SUCCESS: {
    const savedSearchIndex = state.data.map(ss => ss._id).indexOf(action.data._id);
    let data = [...state.data];

    if (savedSearchIndex != -1)
      data[savedSearchIndex] = action.data;
    else
      data.push(action.data);

    return { ...state, data, errors: {} };
  }
  case ActionTypes.CREATE_SAVED_SEARCH_SUCCESS: {
    const data = orderBy([...state.data, action.data], ["created_at"], "desc");
    return { ...state, data, errors: {} };
  }
  case ActionTypes.DELETE_SAVED_SEARCH_FAILURE:
    return { ...state, errors: { message: action.data.error } };
  case ActionTypes.FETCH_SAVED_SEARCH_FAILURE:
    return { ...state, errors: createDefaultError() };
  default:
    return state;
  }
}

export default savedSearches;
