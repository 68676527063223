import { DropdownButton, DropdownItem } from "react-bootstrap";
import GuestDisplayOptions from "./options/GuestDisplayOptions.react";
import TraitDisplayOptions from "./options/TraitDisplayOptions.react";
import { Program } from "../../types/Program";
import { DragTypes } from "../../constants/Constants";
import Sortable from "../../components/Sortable.react";
import { mergeObjectWithExistingObject } from "../../utils/StateOperationUtils";
import SessionsInformationOptions from "./options/SessionsInformationOptions.react";
import ProgramDisplayOptions from "./options/ProgramDisplayOptions.react";
import HelpSection from "../shared/HelpSection.react";

interface Props {
  program: Program;

  onChangeConfiguration(program: Program): void;
}

function i18n(key: string, opts = {}): string {
  return I18n.t(`react.programs.program_options.${key}`, opts);
}

const ProgramOptions: React.FC<Props> = ({ program, onChangeConfiguration }) => {
  const renderAvailableOptionsDropdown = (): JSX.Element[] => {
    const options = ["speakers", "exhibitors", "moderators"].reduce((acc, optionType, index) => {
      if (!program[`${optionType}_information_displayed`]) {
        acc.push(renderAvailableOptionDropdown(optionType, index));
      }
      return acc;
    }, []);
    options.push(renderAvailableOptionDropdown("traits", 99));
    return options;
  };

  const renderAvailableOptionDropdown = (optionType: string, index: number): JSX.Element => {
    return <DropdownItem eventKey={index} key={optionType} onClick={(): void => addOption(optionType)}>
      {i18n(`${optionType}_option_label`)}
    </DropdownItem>;
  };

  const addOption = (optionType: string): void => {
    const { speakers_display_options_rank, exhibitors_display_options_rank, moderators_display_options_rank } = program;
    const newRank = Math.max(
      ...Object.values(program.traits_displayed).map((filter) => filter.rank),
      speakers_display_options_rank,
      exhibitors_display_options_rank,
      moderators_display_options_rank,
      0) + 1000;
    let newProgram;
    if (optionType === "traits") {
      newProgram = {
        ...program, traits_displayed: [...program.traits_displayed, {
          key: "",
          label: "Metadata",
          label_displayed: true,
          rank: newRank,
        }]
      };
    } else {
      newProgram = {
        ...program,
        [`${optionType}_information_displayed`]: true,
        [`${optionType}_display_options_rank`]: newRank,
      };
    }
    newProgram[`${optionType}_label`] = I18n.t(`react.programs.${optionType}_display_options.default_label`);

    onChangeConfiguration(newProgram);
  };

  const onDeleteOption = (optionType: string, optionIndex?: number): void => {
    let newProgram;
    if (optionType === "traits") {
      const traitsDisplayed = [...program.traits_displayed];
      traitsDisplayed.splice(optionIndex, 1);
      newProgram = { ...program, traits_displayed: traitsDisplayed };
    } else {
      newProgram = { ...program, [`${optionType}_information_displayed`]: false };
    }
    onChangeConfiguration(newProgram);
  };

  const sessionsInformationSection = (): JSX.Element => {
    return <SessionsInformationOptions
      key="SessionsInformationPanel"
      program={program}
      onChangeConfiguration={onChangeConfiguration}
    />;
  };

  const programDisplayOptions = (): JSX.Element => {
    return <ProgramDisplayOptions
      key="ProgramDisplayOption"
      program={program}
      onChangeConfiguration={onChangeConfiguration}
    />;
  };

  const handleDrop = (_previousItemId: string, itemId: string | number, _nextItemId: string, estimatedIndex: number, itemsByIndex: any): void => {
    const droppedItem = itemsByIndex.find(item => item.id === itemId);
    let newProgram;
    if (droppedItem.id === "speakers") {
      newProgram = { ...program, speakers_display_options_rank: estimatedIndex };
    } else if (droppedItem.id === "exhibitors") {
      newProgram = { ...program, exhibitors_display_options_rank: estimatedIndex };
    } else if (droppedItem.id === "moderators") {
      newProgram = { ...program, moderators_display_options_rank: estimatedIndex };
    } else {
      const { traits_displayed } = program;
      const newTraitsDisplayed = mergeObjectWithExistingObject(traits_displayed, { ...traits_displayed.find(item => item.key === itemId), rank: estimatedIndex }, "key");
      newProgram = { ...program, traits_displayed: [ ...newTraitsDisplayed ] };
    }
    onChangeConfiguration(newProgram);
  };

  const renderOptions = (): JSX.Element[] => {
    const { speakers_information_displayed, exhibitors_information_displayed, traits_displayed, speakers_display_options_rank,
      exhibitors_display_options_rank, moderators_information_displayed, moderators_display_options_rank } = program;

    const commonProps = { program, onChangeConfiguration, onDeleteOption };
    const dynamicOptions = [];
    if (speakers_information_displayed) {
      dynamicOptions.push({
        id: "speakers",
        rank: speakers_display_options_rank, component: <GuestDisplayOptions key="speakers" guestRole="speakers" {...commonProps} />
      });
    }
    if (exhibitors_information_displayed) {
      dynamicOptions.push({
        id: "exhibitors",
        rank: exhibitors_display_options_rank, component: <GuestDisplayOptions key="exhibitors" guestRole="exhibitors" {...commonProps} />
      });
    }
    if (moderators_information_displayed) {
      dynamicOptions.push({
        id: "moderators",
        rank: moderators_display_options_rank, component: <GuestDisplayOptions key="moderators" guestRole="moderators" {...commonProps} />
      });
    }
    traits_displayed.forEach((trait, index) => {
      return dynamicOptions.push({
        id: trait.key,
        rank: trait.rank,
        component: <TraitDisplayOptions key={`traits-${index}`} traitIndex={index} { ...commonProps } />
      });
    });

    const sortableItems = dynamicOptions.sort((a, b) => a.rank - b.rank).reduce((acc, item) => {
      acc.push({ id: item.id, rank: item.rank });
      return acc;
    }, []);

    const renderedDynamicOptions = dynamicOptions.sort((a, b) => a.rank - b.rank).map((item, index) => <div key={index}>{item.component}</div>);

    const sortableOptions = <Sortable
      key="SessionSortableOptions"
      itemIdKey="id"
      itemIndexKey="rank"
      dragType={DragTypes.PROGRAM_OPTIONS}
      items={sortableItems}
      handleDrop={handleDrop}
      fullyDraggable={true}>
      {renderedDynamicOptions}
    </Sortable>;

    return [programDisplayOptions(), sessionsInformationSection(), sortableOptions];
  };

  const dropdownOptionsNotConfigured = renderAvailableOptionsDropdown();

  return <div className="card">
    <div className="card-header">
      <div className="card-title">{i18n("title")}</div>
    </div>
    <div className="card-body">
      <HelpSection help={i18n("help")} />
      { renderOptions() }
      { dropdownOptionsNotConfigured.length > 0 &&
        <DropdownButton variant="secondary" title={<span><i className="fa-regular fa-plus"></i> {i18n("option_dropdown_title")}</span>} id="ProgramOptionsDropdown">
          {dropdownOptionsNotConfigured}
        </DropdownButton>
      }
    </div>
  </div>;
};

export default ProgramOptions;
