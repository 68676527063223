import { fabric } from "fabric";
import { BadgeImage } from "../../../types/BadgeTemplate";

export interface FabricEditorFunctions {
  addText(text: string): void;
  addImage(url: string, imageKey: string): void;
  addDottedLines(): void;
  unselectAll(): void;
  addQRCode(): void;
  addBackground(url: string, imageKey: string, badgeFormat: string): void;
  updateProperty(selectedObject: fabric.Object, propertyName: string, value: string, isNumeric: boolean): void;
  exportToJSON(): any;
  loadFromJSON(canvasJSONString: string): void;
  updateGroupAlignment(selectedObjects: fabric.Object[], value: string): void;
  selectObject(key: string): boolean;
  removeSelectedObject(): void;
  updateActiveObjectStacking(action: StackingOrder): void;
  createDefaultElementsOnCanvas(elements, badgeImages: BadgeImage[], badgeFormat: string) : void;
}

export enum Alignment {
  HLeft = "h-left",
  HCenter = "h-center",
  HRight = "h-right",
  VTop = "v-top",
  VCenter = "v-center",
  VBottom = "v-bottom",
}

export enum StackingOrder {
  BringToFront = "bringToFront",
  SendToBack = "sendToBack",
  BringForward = "bringForward",
  SendBackward = "sendBackward",
}
