import { Component } from "react";
import { connect } from "react-redux";
import { updateUI } from "../actions/UIAppearanceActionCreators";
import { forceSendConfirmationEmail } from "../actions/GuestUpdatesActionCreators";
import { queryStringAndSelectedGuests } from "../utils/QueryStringUtils";
import withModal from "./withModal.react";
import { countGuests } from "../utils/APIUtils";
import { urlEventId } from "../utils/pathUtils";

class SendConfirmationEmail extends Component {
  constructor(props) {
    super(props);
    this._hasConfirmationEmailSearchQuery = this._hasConfirmationEmailSearchQuery.bind(this);
    this._submit = this._submit.bind(this);
  }

  _hasConfirmationEmailSearchQuery() {
    const { selectedGuests } = this.props;
    return encodeURIComponent((queryStringAndSelectedGuests(this.props.location, selectedGuests) || "") + " has_confirmation_email:true");
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const updates = {
      "modalTitle": I18n.t("resend_confirmation_email")
    };
    dispatch(updateUI(updates));
    countGuests(dispatch, this._hasConfirmationEmailSearchQuery(), "guestWithConfirmationEmailCount", true);
  }

  _submit() {
    const { dispatch, closeModal } = this.props;
    dispatch(forceSendConfirmationEmail(urlEventId(), this._hasConfirmationEmailSearchQuery()));
    closeModal();
  }

  render() {
    const { guestWithConfirmationEmailCount, has_confirmation_email } = this.props;

    const emailedPeopleDescription = (
      <div>
        <strong>{guestWithConfirmationEmailCount}</strong> {I18n.t("react.reports.emails_will_be_resent")}
      </div>
    );

    return (
      (has_confirmation_email) ? (
        <div>
          {emailedPeopleDescription}
          <hr/>
          <button type="button" className="btn btn-success float-end" disabled={guestWithConfirmationEmailCount == 0} onClick={this._submit}>
            {I18n.t("react.reports.send")}
          </button>
        </div>
      ) : <div className="col-md-12">{I18n.t("react.resend_confirmation_emails.no_email_to_resend")}</div>
    );

  }
}

function mapStateToProps(state) {
  return {
    guestWithConfirmationEmailCount: state.appearance.guestWithConfirmationEmailCount,
    has_confirmation_email: state.event.has_confirmation_email,
    selectedGuests: state.guests.selectedGuests
  };
}

export default connect(mapStateToProps)(withModal(SendConfirmationEmail));
