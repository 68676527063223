import { COLORS } from "../constants/Constants";

export const DATA_PRIMARY_COLOR = "#366CDE";
const COMPARED_DATA_COLOR = "red";
const OTHERS_DATA_COLOR = "#D3D3D3";

export enum ColoringStrategy {
  Same, // using the primary color on all data
  Random, // using the primary color + random colors from our constants
  Defined // manually set in the dataset
}

export interface Dataset {
  data: number[];
  labels: string[];
  multiDatasetsLabels?: string[];
  coloringStrategy: ColoringStrategy;
  // used when coloring strategy is "defined"
  colors?: string[]|string;
  label?: string;
  // This field store the raw mongodb aggregation documents. It allows us to re-use the AtlasChartToExcelLink component without modifications.
  documents?: any;
  yAxesValueFormatter?: (v: string|number) => string;
}

export const toChartJsDataset = (dataset: Dataset, forMultiDatasets = false): any => {
  return {
    label: dataset.label,
    data: forMultiDatasets ? dataset.data.map((data, i) => {
      return { x: dataset.multiDatasetsLabels[i], y: data };
    }) : dataset.data,
    backgroundColor: dataset.colors,
    borderColor: dataset.colors
  };
};

export const applyColoringStrategy = (dataset: Dataset, isComparisonData?: boolean): Dataset => {
  let { colors } = dataset;
  const { coloringStrategy, data } = dataset;

  if (coloringStrategy === ColoringStrategy.Random) {
    colors = randomColorsForData(data);
  } else if (coloringStrategy === ColoringStrategy.Same) {
    colors = isComparisonData ? COMPARED_DATA_COLOR : DATA_PRIMARY_COLOR;
  } else if (coloringStrategy === ColoringStrategy.Defined) {
    colors = (colors as Array<string>).map((c) => c || DATA_PRIMARY_COLOR);
  }

  return { ...dataset, colors };
};

const randomColorsForData = (data: any[]): string[] => {
  const length = data.length;

  if (length === 0) return undefined;

  const colors = [DATA_PRIMARY_COLOR];
  const colorsLength = COLORS.length;

  for (let i = 0; i < length - 1; i++) {
    colors.push(COLORS[i % colorsLength]);
  }

  return colors;
};

export const compressDataset = (dataset: Dataset, max: number, otherLabel: string): Dataset => {
  const { data, labels, colors } = dataset;

  if (data.length <= max || max < 1) {
    // If the dataset is already within the limit, or max makes no sense, return it as is
    return dataset;
  }

  const handleColors = Array.isArray(colors);

  // Create an array of objects to keep data colors and labels together for sorting
  const combined = data.map((value, index) => ({
    value,
    label: labels[index],
    color: handleColors ? colors[index] : null
  }));

  combined.sort((a, b) => b.value - a.value);

  const compressedData: number[] = [];
  const compressedLabels: string[] = [];
  const compressedColors: string[] = [];

  let othersSum = 0;

  for (let i = 0; i < combined.length; i++) {
    if (i < max - 1) {
      compressedData.push(combined[i].value);
      compressedLabels.push(combined[i].label);
      compressedColors.push(combined[i].color);
    } else {
      othersSum += combined[i].value;
    }
  }

  compressedData.push(othersSum);
  compressedLabels.push(otherLabel);

  if (handleColors) {
    compressedColors.push(OTHERS_DATA_COLOR);
  }

  return {
    ...dataset,
    colors: handleColors ? compressedColors : colors,
    data: compressedData,
    labels: compressedLabels
  };
};
