import { WebsiteMenuItem } from "../types/WebsiteMenuItem";

// returns menu items of a specific parent from its path in the tree (pathTo)
// ex : [2, 1] => returns menu items of the first item of the second item of the menu
export function findNestedMenuItems(pathTo: number[], baseList: WebsiteMenuItem[]): WebsiteMenuItem[] {
  return pathTo.reduce((items: WebsiteMenuItem[], i: number) => {
    return (items && items[i]) ? items[i].menu_items : null;
  }, baseList);
}

// returns data of a specific menu item from its path in the tree (pathTo)
// ex : [2, 1] => returns data of the first item of the second item of the menu
export function findNestedMenuItem(pathTo: number[], baseList: WebsiteMenuItem[]): WebsiteMenuItem {
  return pathTo.reduce((parent: any, i: number) => {
    return (parent && parent.menu_items && parent.menu_items[i]) ? parent.menu_items[i] : null;
  }, { menu_items: baseList });
}
