import { ActionTypes } from "../constants/Constants";
import { BadgeTemplateState } from "../types/BadgeTemplate";

const defaultState: BadgeTemplateState = {
  errors: null,
  isFetching: false,
  isSaving: false,
  data: null,
};

function badgeTemplate(state = defaultState, action: any): BadgeTemplateState {
  switch (action.type) {
  case ActionTypes.FETCH_BADGE_TEMPLATE_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.FETCH_BADGE_TEMPLATE_SUCCESS:
    return { ...state, data: action.data, isFetching: false, errors: null };
  case ActionTypes.FETCH_BADGE_TEMPLATE_FAILURE:
    return { ...state, data: null, isFetching: false, errors: action.data };
  case ActionTypes.CREATE_BADGE_TEMPLATE_REQUEST:
  case ActionTypes.UPDATE_BADGE_TEMPLATE_REQUEST:
    return { ...state, isSaving: true, errors: null };
  case ActionTypes.CREATE_BADGE_TEMPLATE_SUCCESS:
  case ActionTypes.UPDATE_BADGE_TEMPLATE_SUCCESS:
    return { ...state, data: action.data, isSaving: false, errors: null };
  case ActionTypes.CREATE_BADGE_TEMPLATE_FAILURE:
  case ActionTypes.UPDATE_BADGE_TEMPLATE_FAILURE:
    return { ...state, isSaving: false, errors: action.data };
  default:
    return state;
  }
}

export default badgeTemplate;
