import { Component } from "react";
import { extractComposedTerms } from "../utils/QueryStringUtils";
import SearchKeyInput from "./SearchKeyInput.react";
import FilterDropdown from "../components/FilterDropdown.react";

class ByFieldFilters extends Component {
  constructor(props) {
    super(props);
    [
      "_clickAvailableField",
      "_setQuery",
      "_handleChange",
      "_handleCancel",
      "_onPageSubmit",
      "_changeFocusedValue",
      "_setFocusedField",
      "changeFocusedValueOnSelect"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = { availableFields: props.fields, query: this._setInitialQuery(props.searchQueryString), focusedField: null };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.submitLaunched && this.props.submitLaunched) {
      this._onPageSubmit();
    }
  }

  _onPageSubmit() {
    const { focusedField, focusedValue } = this.state;
    if (focusedField) {
      this._setQuery(focusedField, focusedValue);
    }
  }

  _setInitialQuery(searchQueryString) {
    if (!searchQueryString)
      return [];

    return extractComposedTerms(searchQueryString).reduce((acc, term) => {
      const { searchField, searchValue } = term;

      if (searchValue && searchValue.length > 0)
        acc.push({ key: searchField, value: searchValue });

      return acc;
    }, []);
  }

  _clickAvailableField(key) {
    const { query, availableFields } = this.state;

    if (!key) {
      this.setState({ focusedField: null, focusedValue: null });
      return;
    }

    const filteredQuery = query.filter((term) => term.key == key);
    let value = "";
    if (filteredQuery.length > 0) {
      value = filteredQuery[0].value;
      value = value.replace(/^"|([^\\])"$/g, "$1");
      value = value.replace(/\\"/g, "\"");
    }

    const filteredField = availableFields.find((field) => field.key == key);
    if (filteredField && filteredField.type == "value_list") {
      value = filteredField.available_values[0].value;
    }
    this.setState({ focusedField: key, focusedValue: value });

    const { isEditing } = this.props;
    if (isEditing) {
      isEditing(true);
    }
  }

  _handleChange() {
    const terms = this.state.query.map((term) => {
      return `${term.key}:${term.value}`;
    });
    this.props.onChange(terms.join(" "));
  }

  _changeFocusedValue(value) {
    this.setState({ focusedValue: value });
  }

  changeFocusedValueOnSelect(e) {
    this._changeFocusedValue(e.target.value);
  }

  _setQuery(key, value, cancelEditing = true) {
    const { query } = this.state;
    let newQuery = query.filter((term) => {
      return term.key != key;
    });
    if (value.length > 0) {
      value = value.replace(/"/g, "\\\"");
      value = `"${value}"`;
      newQuery.push({ key, value });
    }

    this.setState({ query: newQuery, focusedField: null }, () => {
      this._handleChange();
    });

    const { isEditing } = this.props;
    if (isEditing && cancelEditing) {
      isEditing(false);
    }
  }

  _renderFocusedField() {
    const { focusedField, focusedValue, availableFields } = this.state;
    const filteredField = availableFields.find((field) => field.key == focusedField);
    const validate = () => { this._setQuery(focusedField, focusedValue); };

    if (focusedField && filteredField) {
      if (filteredField.type == "value_list") {
        const options = filteredField.available_values.map((option) => {
          return <option value={option.value} key={`${filteredField.key}-${option.value}`}>{option.label}</option>;
        });

        return (
          <form className="row mt-10" onBlur={validate}>
            <div className="col-md-6">
              <div className="input-group">
                <div className="input-group-text">{focusedField}</div>
                <select className="form-select" onChange={this.changeFocusedValueOnSelect} autoFocus defaultValue={focusedValue}>
                  {options}
                </select>
              </div>
            </div>
          </form>
        );
      } else {
        return (
          <SearchKeyInput field={focusedField} value={focusedValue} validate={validate} onChange={this._changeFocusedValue} />
        );
      }
    }
    return "";
  }

  _handleCancel(field) {
    this._setQuery(field, "", false);
  }

  _setFocusedField(selectedFieldKeys) {
    this._clickAvailableField(selectedFieldKeys[0]);
  }

  _renderQuery() {
    const itemCells = this.state.query.map((item, index) => {
      const handler = () => {this._handleCancel(item.key);};
      return (
        <li key={index}>
          <span className="badge rounded-pill bg-secondary">
            {item.key}: {item.value}
            <div className="filter-cell-close">
              <i className="fa-regular fa-xmark" value={item.key} onClick={handler}></i>
            </div>
          </span>
        </li>
      );
    });

    return (
      <ul className="filter-cells-group d-inline-block list-inline mt-5" style={{ width: "100%" }}>
        {itemCells}
      </ul>
    );
  }

  render() {
    const { availableFields, query } = this.state;
    return (
      <div>
        <h4 className="trigger-action-title">{I18n.t("shared.guest_filter.add_field_filters")}</h4>
        <div className="row">
          <div className="col-md-4">
            <FilterDropdown
              id="guest_fields"
              items={availableFields}
              itemIdKey="key"
              itemNameKey="label"
              translationKey="guest_fields"
              showCells={false}
              multipleSelect={false}
              selectedItemIds={query.map((item) => item.key)}
              onChange={this._setFocusedField} />
          </div>
        </div>
        <div>
          {this._renderFocusedField()}
        </div>
        <div>
          {this._renderQuery()}
        </div>
      </div>
    );
  }
}

export default ByFieldFilters;
