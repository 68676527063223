import { CartItemStatType } from "../../containers/event_reports/types/CartItemStatType";
import { CART_ITEMS_STATS_HEADERS } from "../../constants/Constants";
import { pathToGuestShow } from "../../../js/utils/pathUtils";

interface Props {
  stats: CartItemStatType[];
}

function i18n(key: string): string {
  return I18n.t(`react.event_reports.cart_items_table.${key}`);
}

const CartItemsStatTable: React.FC<Props> = ({ stats }) => {
  const guestShowLink = (id: string, identity: string): JSX.Element => {
    return <td>
      <a href={pathToGuestShow(id)}>{identity}</a>
    </td>;
  };

  const row = (stat: CartItemStatType, index: number): JSX.Element => {
    return <tr key={ index }>
      {CART_ITEMS_STATS_HEADERS.map((fieldName: string) => {
        if (fieldName === "guest") {
          return guestShowLink(stat["_id"], stat["guest"]);
        }
        return <td>{ stat[fieldName] }</td>;
      })}
    </tr>;
  };

  const rows = stats.map((stat, index) => {
    return row(stat, index);
  });

  return <div className="table-responsive table-container fixed-head">
    <table className="table table-light table-hover table-sm">
      <thead>
        <tr>
          {CART_ITEMS_STATS_HEADERS.map((fieldName: string) => {
            return <th>{ i18n(fieldName) }</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  </div>;
};

export default CartItemsStatTable;
