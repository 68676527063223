import { Component } from "react";
import PropTypes from "prop-types";
import { FormControl, Button } from "react-bootstrap";

const TAB = "    ";

class TextAreaDataImport extends Component {

  constructor(props) {
    super(props);

    ["contentChanged", "placeholder", "renderHeader", "submit", "keydown"].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      content: "",
      parsedContent: [],
      error: null
    };
  }

  isEmpty(str) {
    return [undefined, null, ""].includes(str);
  }

  contentChanged(e) {
    const { mandatoriesColums } = this.props;
    const content = e.target.value;
    const nbColumnsExpected = this.props.expectedColumns.length;
    const expectedColumns = Object.assign({}, this.props.expectedColumns);

    const rows = content.split("\n");
    let error = null;
    let parsedContent = [];
    const needAllColumns = !mandatoriesColums || (mandatoriesColums && mandatoriesColums.length === 0);
    const mandatoriesColumsArray = mandatoriesColums || [];

    const setError = (line, message) => {
      if (error == null) {
        error = {
          line,
          message
        };
      }
    };

    rows.forEach((row, index) => {
      if (this.isEmpty(row)) {
        return;
      }
      const cols = row.split("\t", nbColumnsExpected);
      if (needAllColumns && cols.length < nbColumnsExpected) {
        setError(index + 1, I18n.t("react.text_area_data_import.error_missing_value", { expected: nbColumnsExpected, actual: cols.length }));
        return;
      }

      let parsedRow = {};
      cols.forEach((col, indexCol) => {
        if (mandatoriesColumsArray.includes(expectedColumns[indexCol]) && this.isEmpty(col)) {
          setError(index + 1, I18n.t("react.text_area_data_import.error_empty_value"));
          return;
        }
        parsedRow[expectedColumns[indexCol]] = col;
      });
      parsedContent.push(parsedRow);
    });

    this.setState({
      content,
      parsedContent,
      error
    });
  }

  keydown(e) {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 9) { // tab character
      e.preventDefault();
      const textarea = e.target;
      textarea.value = textarea.value.substring(0, textarea.selectionStart) + "\t" + textarea.value.substring(textarea.selectionEnd);
      textarea.selectionEnd = textarea.selectionStart + 1;
    }
  }

  submit() {
    const { onSubmit } = this.props;
    const { parsedContent } = this.state;
    onSubmit(parsedContent);
  }

  placeholder() {
    const { expectedColumns, placeholderRows } = this.props;
    let rows = [];
    for (let index = 0; index < placeholderRows; index++) {
      rows.push(expectedColumns.map(col => { return `${col}${index + 1} `; }).join(TAB));
    }
    return rows.join("\n");
  }

  renderHeader() {
    const { expectedColumns } = this.props;
    const { parsedContent, error } = this.state;
    let errorItem = null;
    if (error) {
      errorItem = (
        <li className="text-danger">
          <small><b>{ I18n.t("react.text_area_data_import.error", error) }</b></small>
        </li>
      );
    }
    return (
      <ul className="list-inline">
        <li> { expectedColumns.join("/") } <small><b>{ parsedContent.length }</b></small> </li>
        { errorItem }
      </ul>
    );
  }

  render() {
    const { minHeight, explanationText, importButtonText } = this.props;
    const { error } = this.state;
    const { content } = this.state;
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <p> {explanationText } </p>
          </div>
        </div>
        <div className="row">
          <div className="col-8 mt-10">
            { this.renderHeader() }
          </div>
          <div className="col-4">
            <Button className="float-end btn-primary btn-sm mb-10" onClick={this.submit} disabled={error !== null}> { importButtonText } </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <FormControl as="textarea" value={content} placeholder={this.placeholder()} onChange={this.contentChanged} style={{ height: minHeight, resize: "vertical" }} onKeyDown={this.keydown} wrap="off"/>
          </div>
        </div>
      </div>
    );
  }
}


TextAreaDataImport.propTypes = {
  minHeight: PropTypes.string,
  explanationText: PropTypes.string,
  importButtonText: PropTypes.string,
  expectedColumns: PropTypes.array.isRequired,
  mandatoriesColums: PropTypes.array
};

TextAreaDataImport.defaultProps = {
  minHeight: "100px",
  placeholderRows: 20,
  explanationText: I18n.t("react.text_area_data_import.explanation"),
  importButtonText: I18n.t("react.text_area_data_import.import")
};

export default TextAreaDataImport;
