import { ChangeEvent } from "react";
import Checkbox from "../../shared/Checkbox.react";
import Select from "react-select";
import { Program, FilterOption } from "../../../types/Program";
import { ProgramFilterTypes } from "../../../constants/Constants";
import times from "lodash/times";

interface Props {
  program: Program;

  onChangeConfiguration(program: Program): void;
}

function i18n(key: string, opts = {}): string {
  return I18n.t(`react.programs.program_options.schedule_display_section.${key}`, opts);
}

const ProgramDisplayOptions: React.FC<Props> = ({ program, onChangeConfiguration }) => {
  const navigationByDateChanged = (e: ChangeEvent<HTMLInputElement>): void => {
    onChangeConfiguration({ ...program, navigation_by_date_enabled: e.target.checked });
  };

  const groupSessionsByStartingDatesChanged = (e: ChangeEvent<HTMLInputElement>): void => {
    onChangeConfiguration({ ...program, group_sessions_by_starting_dates: e.target.checked });
  };

  const nbSessionsPerLineChanged = (option: FilterOption): void => {
    onChangeConfiguration({ ...program, nb_sessions_per_line: Number(option.value) });
  };

  const searchPositionChanged = (option: FilterOption): void => {
    onChangeConfiguration({ ...program, search_bar_position: option.value });
  };

  const buildSearchPositionOptions = (): FilterOption[] => {
    return ["top", "left"].map(position => ({ label: i18n(`search_bar_position.${position}`), value: position }));
  };

  const buildNbSessionPerLineOptions = (): FilterOption[] => {
    return Array.from({ length: 4 }, (_, i) => i + 1).map(value => ({ label: value.toString(), value: value.toString() }));
  };

  const selectedOption = (label: string, value: string): FilterOption => {
    return { label: label, value: value };
  };

  const navigationByDateDisabled = (): boolean => {
    return !Object.values(program.program_filters).find(filter => {
      return filter._type === ProgramFilterTypes.DATE_RANGE && !filter._destroy;
    });
  };

  const renderNavigationByDate = (): JSX.Element => {
    const { navigation_by_date_enabled } = program;

    return <div className="mb-3">
      <Checkbox
        checked={navigation_by_date_enabled}
        disabled={navigationByDateDisabled()}
        text={i18n("add_navigation_by_date.label")}
        onChange={navigationByDateChanged}
      />
      <span style={{ color: "#999999" }}>{i18n("add_navigation_by_date.help")}</span><br />
      <span style={{ color: "#999999" }}>{i18n("add_navigation_by_date.warning")}</span>
    </div>;
  };

  const renderGroupSessionsByStartingDates = (): JSX.Element => {
    const { group_sessions_by_starting_dates } = program;

    return <div className="mb-3">
      <Checkbox
        checked={group_sessions_by_starting_dates}
        text={i18n("group_sessions_by_starting_dates.label")}
        onChange={groupSessionsByStartingDatesChanged}
      />
    </div>;
  };

  const renderSelectNbSessionPerLine = (): JSX.Element => {
    const { nb_sessions_per_line } = program;

    return <Select
      key="nb_sessions_per_line"
      className="react-select"
      classNamePrefix="react-select"
      options={buildNbSessionPerLineOptions()}
      value={selectedOption(nb_sessions_per_line.toString(), nb_sessions_per_line.toString())}
      onChange={nbSessionsPerLineChanged}
    />;
  };

  const renderSelectSearchPosition = (): JSX.Element => {
    const { search_bar_position } = program;

    return <Select
      key="search_bar_position"
      className="react-select"
      classNamePrefix="react-select"
      options={buildSearchPositionOptions()}
      value={selectedOption(i18n(`search_bar_position.${search_bar_position}`), search_bar_position)}
      onChange={searchPositionChanged}
    />;
  };

  const renderSelects = (): JSX.Element[] => {
    return [renderSelectNbSessionPerLine(), renderSelectSearchPosition()].map(select => {
      return <div key={`${select.key}`} className="mb-3 row g-2 align-items-center">
        <label className="col-form-label col-auto">{i18n(`${select.key}.label`)}</label>
        <div className="col-auto" style={{ width: "110px" }}>{select}</div>
      </div>;
    });
  };

  const isSearchBarOnLeft = (): boolean => {
    const { search_bar_position } = program;
    return search_bar_position === "left";
  };

  const renderPreviewSingleLine = (key: string): JSX.Element => {
    const { nb_sessions_per_line } = program;

    return <div key={key} className="d-flex flex-row">
      {times(nb_sessions_per_line, n =>
        <div key={n} style={{ width: `calc(100% / ${nb_sessions_per_line})` }}>
          <div style={{ margin: "2.5px", background: "#d9d9d9", height: `${isSearchBarOnLeft() ? "50px" : "60px" }`, borderRadius: "4px" }}></div>
        </div>
      )}
    </div>;
  };

  const renderPreview = (): JSX.Element => {
    return <>
      <div className="card" style={{ padding: "5px", maxWidth: "350px", margin: "auto" }}>
        <div className={`row g-0 ${isSearchBarOnLeft() ? "flex-row" : "flex-column" }`}>
          <div className={isSearchBarOnLeft() ? "col-3" : "col-12"}>
            <div style={{ margin: "2.5px", background: "#ebebeb", height: "calc(100% - 5px)", minHeight: "20px", borderRadius: "4px" }}>
              <i className="fa-regular fa-magnifying-glass" aria-hidden="true" style={{ "fontSize": "10px", "margin": "0 6px", "opacity": "0.5" }}></i>
            </div>
          </div>
          <div className={isSearchBarOnLeft() ? "col-9" : "col-12"}>
            {times(isSearchBarOnLeft() ? 4 : 3, n => renderPreviewSingleLine(`row-${n}`))}
          </div>
        </div>
      </div>
      <div className="text-center mt-5">
        <span style={{ color: "#20A599" }}>{i18n("preview")}</span>
      </div>
    </>;
  };

  return <>
    <label className="form-label">{i18n("title")}</label>
    <div className="row">
      <div className="col-md-6">
        {renderNavigationByDate()}
        {renderGroupSessionsByStartingDates()}
        {renderSelects()}
      </div>
      <div className="col-md-6">{renderPreview()}</div>
    </div>
    <hr />
  </>;
};

export default ProgramDisplayOptions;
