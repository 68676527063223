import { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import FilterDropdown from "../../components/FilterDropdown.react";
import { urlWithQuery } from "../../utils/pathUtils";

class ReportTable extends Component {
  i18n(key, opts = {}) {
    return I18n.t(`react.event_reports.report_table.${key}`, opts);
  }

  ifColumn(type, child) {
    const { columns } = this.props;
    if (!columns || columns.includes(type)) return child;
  }

  baseQueryString(stat) {
    const { idNameMapping, fieldKey, query } = this.props;
    const id = idNameMapping[stat._id] || stat._id;

    let queryString = query || "";
    if (id && id !== "") {
      queryString = `${queryString} ${fieldKey}:"${id}"`;

      // rowName.props may be empty if the guest category no more exists
      // TO DO: fix issue when object name too long and truncated into id.props.children
      if (fieldKey == "guest_category_id" && id.props) {
        queryString = `${queryString} guest_category:"${id.props.children}"`;
      } else if (fieldKey == "thematic_ids" && id.props) {
        queryString = `${queryString} thematics:"${id.props.children}"`;
      }
    } else {
      queryString += ` no:${fieldKey}`;
    }

    return `status:registered include_takeout ${queryString}`;
  }

  newVisitsQueryString(stat) {
    const { formattedStatsDate } = this.props;
    const queryString = formattedStatsDate === null ? "showed_up:true" : `first_attended_date:${formattedStatsDate}`;
    return `${this.baseQueryString(stat)} ${queryString}`;
  }

  returningQueryString(stat) {
    const { formattedStatsDate } = this.props;
    return `${this.baseQueryString(stat)} attended_date:${formattedStatsDate} -first_attended_date:${formattedStatsDate}`;
  }

  visitsQueryString(stat) {
    const { formattedStatsDate } = this.props;
    const queryString = formattedStatsDate === null ? "showed_up:true" : `attended_date:${formattedStatsDate}`;
    return `${this.baseQueryString(stat)} ${queryString}`;
  }

  noShowsQueryString(stat) {
    const { formattedStatsDate } = this.props;
    const queryString = formattedStatsDate === null ? "showed_up:false" : `-attended_date:${formattedStatsDate}`;
    return `${this.baseQueryString(stat)} ${queryString}`;
  }

  renderDiff(type, stat, comparedStat, higherBest = true, isPercent = false) {
    if (!comparedStat) {
      return null;
    }
    const diff = stat[type] - comparedStat[type];
    const fDiff = isPercent ? `${(diff * 100).toFixed(2)} %` : diff;
    const percentageDiff = ((stat[type] - comparedStat[type]) / comparedStat[type] * 100).toFixed(2) + "%";
    if (diff > 0) {
      return <strong className={higherBest ? "text-success" : "text-danger"}>+{fDiff} <b style={ { color: "black", marginLeft: "7.5px" } }>{percentageDiff}</b></strong>;
    } else if (diff < 0) {
      return <strong className={higherBest ? "text-danger" : "text-success"}>{fDiff} <b style={ { color: "black", marginLeft: "7.5px" } }>{percentageDiff}</b></strong>;
    }
    return null;
  }

  separator() {
    return <span style={{ marginLeft: "20px" }}></span>;
  }

  renderCountCase(stat, comparedStat, showLink, keyPrefix, style) {
    const { fieldIsMultiValuesField } = this.props;
    return (
      <td key={`${keyPrefix}-count-${stat._id}`} style={style}>
        {!showLink || stat.count === 0 ? (
          stat.count
        ) : (
          <span>
            <Link to={urlWithQuery(this.baseQueryString(stat), "guests")}>{ stat.count }</Link> {!fieldIsMultiValuesField && <div className="form-text"> ({ (stat.count_percent * 100).toFixed(2) } %)</div>}
          </span>
        )}
        {this.separator()}
        {this.renderDiff("count", stat, comparedStat)}
      </td>
    );
  }

  renderNewVisitsCase(stat, comparedStat, showLink, keyPrefix, style) {
    return (
      <td key={`${keyPrefix}-new-visits-${stat._id}`} style={style}>
        {!showLink || stat.new_visits === 0 ? (
          stat.new_visits
        ) : (
          <Link to={urlWithQuery(this.newVisitsQueryString(stat), "guests")}>{ stat.new_visits }</Link>
        )}
        {this.separator()}
        {this.renderDiff("new_visits", stat, comparedStat)}
      </td>
    );
  }

  renderReturningCase(stat, comparedStat, showLink, keyPrefix, style) {
    return (
      <td key={`${keyPrefix}-returnings-${stat._id}`} style={style}>
        {!showLink || stat.returning === 0 ? (
          stat.returning
        ) : (
          <Link to={urlWithQuery(this.returningQueryString(stat), "guests")}>{ stat.returning }</Link>
        )}
        {this.separator()}
        {this.renderDiff("returning", stat, comparedStat)}
      </td>
    );
  }

  renderVisitsCase(stat, comparedStat, showLink, keyPrefix, style) {
    return (
      <td key={`${keyPrefix}-visits-${stat._id}`} style={style}>
        {!showLink || stat.visits === 0 ? (
          stat.visits
        ) : (
          <Link to={urlWithQuery(this.visitsQueryString(stat), "guests")}>{ stat.visits }</Link>
        )}
        {this.separator()}
        {this.renderDiff("visits", stat, comparedStat)}
      </td>
    );
  }

  renderNoShowsCase(stat, comparedStat, showLink, keyPrefix, style) {
    return (
      <td key={`${keyPrefix}-no-shows-${stat._id}`} style={style}>
        {!showLink || stat.no_shows === 0 ? (
          stat.no_shows
        ) : (
          <Link to={urlWithQuery(this.noShowsQueryString(stat), "guests")}> { stat.no_shows } </Link>
        )}
        {this.separator()}
        {this.renderDiff("no_shows", stat, comparedStat, false)}
      </td>
    );
  }

  renderTransformationRateCase(stat, comparedStat, keyPrefix, style) {
    return (
      <td key={`${keyPrefix}-transformation-${stat._id}`} style={style}>
        { (stat.transformation_rate * 100).toFixed(2) } %
        {this.separator()}
        {this.renderDiff("transformation_rate", stat, comparedStat, true, true)}
      </td>
    );
  }

  renderCase(type, stat, comparedStat, showLink = true, keyPrefix = "", style = {}) {
    switch (type) {
    case "count":
      return this.renderCountCase(stat, comparedStat, showLink, keyPrefix, style);
    case "new_visits":
      return this.renderNewVisitsCase(stat, comparedStat, showLink, keyPrefix, style);
    case "returning":
      return this.renderReturningCase(stat, comparedStat, showLink, keyPrefix, style);
    case "visits":
      return this.renderVisitsCase(stat, comparedStat, showLink, keyPrefix, style);
    case "no_shows":
      return this.renderNoShowsCase(stat, comparedStat, showLink, keyPrefix, style);
    case "transformation_rate":
      return this.renderTransformationRateCase(stat, comparedStat, keyPrefix, style);
    }
  }

  renderStatRow(stat, comparedStat = null) {
    const { hiddenColumns, columns, idNameMapping } = this.props;
    const cols = columns.map(col => {
      return this.renderCase(col, stat, comparedStat);
    });

    const id = idNameMapping[stat._id] || stat._id;
    const registeredGuestsURL = urlWithQuery(this.baseQueryString(stat), "guests");
    const title = (!id || id === "") ? (
      <Link to={registeredGuestsURL}><em>{ this.i18n("blank") }</em></Link>
    ) : (
      <Link to={registeredGuestsURL}>{id}</Link>
    );

    return (
      <tr key={stat._id}>
        <td> {title} </td>
        {cols}
        {hiddenColumns && hiddenColumns.length > 0 && <td></td>}
      </tr>
    );
  }

  renderTotalRow(total, comparedTotal = null) {
    const { hiddenColumns, columns } = this.props;

    const cols = columns.map(col => {
      return this.renderCase(col, total, comparedTotal, false);
    });

    return (
      <tr key="total" className="bg-info">
        <td> {this.i18n("total")} </td>
        {cols}
        {hiddenColumns && hiddenColumns.length > 0 && <td></td>}
      </tr>
    );
  }

  renderComparedRow(stat, className = "") {
    const style = { borderTop: "0px" };
    const { hiddenColumns, columns } = this.props;
    const cols = columns.map(col => {
      return this.renderCase(col, stat, null, false, "compared", style);
    });

    return (
      <tr key={`compared-${stat._id}`} className={`text-muted ${className}`}>
        <td style={style}><small><em>{this.i18n("comparison")}</em></small></td>
        {cols}
        {hiddenColumns && hiddenColumns.length > 0 && <td style={style}></td>}
      </tr>
    );
  }

  renderRemoveButtonForType(type) {
    const { removeColumn } = this.props;
    if (removeColumn) {
      return <i className="fa-regular fa-trash-can text-muted" style={{ cursor: "pointer" }} onClick={() => removeColumn(type)}></i>;
    }
  }

  renderAddColumnButton() {
    const { addColumn, hiddenColumns } = this.props;

    const hiddenColumnsList = hiddenColumns.map(type => {
      return <Dropdown.Item key={type} onClick={() => addColumn(type)}>{ this.i18n(type) }</Dropdown.Item>;
    });

    return (
      <th className="text-end" style={{ width: "10px" }}>
        <Dropdown>
          <Dropdown.Toggle variant="link" style={{ color: "currentColor" }}>
            <i className="fa-regular fa-plus"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu
            align="end"
            popperConfig={{
              strategy: "fixed",
              onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll"))
            }}>
            {hiddenColumnsList}
          </Dropdown.Menu>
        </Dropdown>
      </th>
    );
  }

  render() {
    const { field, stats, total, onChange, fieldValuesIds, items, hiddenColumns, comparedStats, comparedTotal, fieldIsMultiValuesField, fieldKey } = this.props;
    const rows = [];

    stats.forEach((stat, i) => {
      const comparedStat = comparedStats[i];
      rows.push(this.renderStatRow(stat, comparedStat));
      if (comparedStat) {
        rows.push(this.renderComparedRow(comparedStat));
      }
    });

    if (!fieldIsMultiValuesField && fieldKey !== "thematic_ids") {
      // total row make no sense on thematic_ids or multi values field
      rows.push(this.renderTotalRow(total, comparedTotal));
      if (comparedTotal) {
        rows.push(this.renderComparedRow(comparedTotal, "bg-info"));
      }
    }

    const firstColumnName = (
      <FilterDropdown
        id="statistic_filter"
        items={items}
        multipleSelect={true}
        hasSelectAll={true}
        onChange={onChange}
        showCells={false}
        translationKey="statistic_filter"
        selectedItemIds={fieldValuesIds}
        title={field}
      />
    );

    return (
      <div className="table-responsive table-container mb-10">
        <table className="table table-light table-hover table-sm">
          <thead>
            <tr>
              <th>{ firstColumnName }</th>
              <th>{ this.i18n("registered") }</th>
              {this.ifColumn("new_visits", (
                <th>
                  {this.i18n("new_visits") } { this.renderRemoveButtonForType("new_visits") }
                </th>
              ))}
              {this.ifColumn("returning", (
                <th>{ this.i18n("returning") } { this.renderRemoveButtonForType("returning") }</th>
              ))}
              {this.ifColumn("visits", (
                <th>{ this.i18n("visits") } { this.renderRemoveButtonForType("visits") }</th>
              ))}
              {this.ifColumn("no_shows", (
                <th>{ this.i18n("no_shows") } { this.renderRemoveButtonForType("no_shows") }</th>
              ))}
              {this.ifColumn("transformation_rate", (
                <th>{ this.i18n("transformation_rate") } { this.renderRemoveButtonForType("transformation_rate") }</th>
              ))}
              {hiddenColumns && hiddenColumns.length > 0 && (
                this.renderAddColumnButton()
              )}
            </tr>
          </thead>
          <tbody>
            { rows }
          </tbody>
        </table>
      </div>
    );
  }
}

ReportTable.defaultProps = {
  idNameMapping: {},
  comparedStats: [],
  fieldIsMultiValuesField: false
};

ReportTable.requireProps = {
  stats: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string),
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  removeColumn: PropTypes.func,
  addColumn: PropTypes.func
};

export default ReportTable;
