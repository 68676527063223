import { ChangeEvent } from "react";
import { translatedTextFromJson } from "../../utils/templatesBuilderUtils";
import { isVisible } from "../../utils/VisibilitySettingsUtils";
import Checkbox from "../shared/Checkbox.react";
import ConditionalDisplay from "./ConditionalDisplay.react";
import ScheduleDisplay from "./ScheduleDisplay.react";
import SegmentsPicker from "./SegmentsPicker.react";
import { SavedSearch } from "../../types/SavedSearch";
import { BuilderType } from "../../types/Builder";

interface Props {
  settingsData: any;
  settingComponentsSchema: any;
  builderType: BuilderType;
  segments: SavedSearch[];
  sectionLocked: boolean;
  noVisibilitySettings: boolean;
  blockKey?: string;
  displayBlocksVisibilitySettings?: boolean;

  notifyChange(key: string, value: any, blockItemKey?: string): void;
}

export const VISIBILITY_SEGMENTS_SETTINGS = {
  default: [],
  id: "segments",
  label: I18n.t("react.builders.filter_on_segment"),
  show_if: { operator: "==", source_id: "private", value: true },
  type: "segments_picker",
  info: I18n.t("react.builders.filter_on_segment_info"),
  backoffice_option: true
};

export const VISIBILITY_PRIVATE_SETTINGS = {
  default: false,
  id: "private",
  label: I18n.t("react.builders.private_label"),
  type: "checkbox",
  backoffice_option: true
};

const VisibilitySettings: React.FC<Props> = ({
  settingsData,
  settingComponentsSchema,
  builderType,
  segments,
  sectionLocked,
  noVisibilitySettings,
  blockKey,
  displayBlocksVisibilitySettings,
  notifyChange
}) => {

  if (noVisibilitySettings) return null;
  if (!displayBlocksVisibilitySettings && blockKey) return null;
  if (displayBlocksVisibilitySettings && !blockKey) return null;

  const changeCheckbox = (e: ChangeEvent<HTMLInputElement>): void => {
    notifyChange(e.target.name, e.target.checked, blockKey);
  };

  const onChangeVisibilitySettings = (e: ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    const value = e.target.value;
    const changes = { conditional_display: true, private: false, public_only: false };

    if (name == "conditional_display") changes.conditional_display = !settingsData["conditional_display"];
    if (value == "private") changes.private = true;
    if (value == "public_only") changes.public_only = true;

    if (blockKey) return Object.keys(changes).forEach(field => notifyChange(field, changes[field], blockKey));

    Object.keys(changes).forEach(field => notifyChange(field, changes[field]));
  };

  const helpMessage = (componentConfig: any): JSX.Element => {
    if (!componentConfig.info) return null;

    return <div dangerouslySetInnerHTML={{ __html: translatedTextFromJson(componentConfig, "info") }} className="form-text" />;
  };

  const renderCheckbox = (componentConfig: any, value: boolean): JSX.Element => {
    // Do not display old section visibility fields
    if (componentConfig.id == "private" && !componentConfig.backoffice_option) return null;

    const defaultValue = value !== undefined ? value : false;

    return <Checkbox
      text={translatedTextFromJson(componentConfig, "label")}
      help={helpMessage(componentConfig)}
      name={componentConfig.id}
      checked={defaultValue}
      disabled={componentConfig.disabled}
      onChange={changeCheckbox}
    />;
  };

  const renderSegmentsPicker = (): JSX.Element => {
    if (!isVisible(settingsData, settingComponentsSchema, VISIBILITY_SEGMENTS_SETTINGS)) return null;

    return <SegmentsPicker
      componentConfig={VISIBILITY_SEGMENTS_SETTINGS}
      value={settingsData["segments"]}
      segments={segments}
      sectionLocked={sectionLocked}
      blockKey={blockKey}
      notifyChange={notifyChange}
    />;
  };

  const renderVisibilitySettings = (): JSX.Element => {
    if (["email", "document"].includes(builderType))
      return renderCheckbox(VISIBILITY_PRIVATE_SETTINGS, settingsData["private"]);

    return <ConditionalDisplay key="conditional-display"
      isPrivate={settingsData["private"]}
      isPublicOnly={settingsData["public_only"]}
      conditionalDisplay={settingsData["conditional_display"]}
      onChangeVisibilitySettings={onChangeVisibilitySettings} />;
  };

  const renderDatePicker = (): JSX.Element => {
    const startDate = settingsData["online_start_date"];
    const endDate = settingsData["online_end_date"];
    const scheduleType = settingsData["online_schedule_type"];

    return <ScheduleDisplay
      key="schedule-display"
      startDate={startDate}
      endDate={endDate}
      blockKey={blockKey}
      scheduleType={scheduleType}
      updateSettingsData={notifyChange}
    />;
  };

  const renderHideFromAppSettings = (): JSX.Element => {
    if (["email", "document"].includes(builderType)) return;

    return <Checkbox
      key="conditional_display"
      text={I18n.t("react.builders.hide_on_app")}
      name="hide_on_app"
      checked={settingsData.hide_on_app}
      onChange={changeCheckbox}
    />;
  };

  if (displayBlocksVisibilitySettings && blockKey) {
    return <div className="card-body block-visibility-options">
      <h4 className="setting-header mb-3">{ I18n.t("react.builders.visibility") }</h4>
      <div>
        { renderVisibilitySettings() }
        { renderSegmentsPicker() }
        { renderDatePicker() }
      </div>
    </div>;
  }

  return <div>
    <h5>{ I18n.t("react.builders.visibility") }</h5>
    <div className="card">
      <div className="card-body">
        { renderVisibilitySettings() }
        { renderSegmentsPicker() }
        { renderDatePicker() }
        { renderHideFromAppSettings() }
      </div>
    </div>
  </div>;
};

export default VisibilitySettings;
