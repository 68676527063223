import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import { Form } from "react-bootstrap";
import { FormItemTypesForSubformCopy } from "../constants/Constants";
import { defaultModalStyles } from "../constants/Style";
import { fetchSubFormItems } from "../actions/RegistrationFormsActionCreators";
import Loader from "../components/shared/Loader.react";

class CopyFieldsModal extends Component {
  constructor(props) {
    super(props);
    [
      "onSubmit",
      "toggleModal",
      "toggleCopyFields",
      "changeFieldMapping"
    ].forEach((fn) => {
      this[fn] = this[fn].bind(this);
    });
    this.state = {
      isOpen: false,
      copyFieldsEnabled: Object.keys(props.subFormOptions.copyFieldsMapping).length > 0,
      copyFieldsMapping: props.subFormOptions.copyFieldsMapping
    };
  }

  componentDidMount() {
    const { copyFieldsEnabled } = this.state;
    const { subFormItems } = this.props;

    if (copyFieldsEnabled && !subFormItems)
      this.fetchSubformItems();
  }

  componentDidUpdate(prevProps, prevState) {
    const { copyFieldsEnabled } = this.state;
    if (!prevState.copyFieldsEnabled && copyFieldsEnabled)
      this.fetchSubformItems();

    const { subFormOptions } = this.props;
    if (Object.keys(prevProps.subFormOptions.copyFieldsMapping).length > 0 && Object.keys(subFormOptions.copyFieldsMapping).length == 0) {
      this.setState({ copyFieldsEnabled: false, copyFieldsMapping: {} });
    }
  }

  fetchSubformItems() {
    const { subFormOptions, fetchSubFormItems, guestCategories } = this.props;
    if (!subFormOptions.guestCategoryId)
      return;

    const subFormGuestCategory = guestCategories.find(guestCategory => guestCategory.id == subFormOptions.guestCategoryId);
    if (subFormGuestCategory && subFormGuestCategory.registration_form_id)
      fetchSubFormItems(subFormGuestCategory.registration_form_id);
  }

  toggleModal(e) {
    if (e) e.preventDefault();
    const { isOpen, copyFieldsEnabled, copyFieldsMapping } = this.state;
    // do not let checkbox checked if no mapping has been done or was available
    if (copyFieldsEnabled && Object.keys(copyFieldsMapping).length == 0) {
      return this.setState({ isOpen: !isOpen, copyFieldsEnabled: false });
    }
    this.setState({ isOpen: !isOpen });
  }

  toggleCopyFields() {
    const { copyFieldsEnabled } = this.state;
    this.setState({ copyFieldsEnabled: !copyFieldsEnabled });
  }

  changeFieldMapping(subFormItemKey) {
    return (e) => {
      const { copyFieldsMapping } = this.state;
      const parentFormItemKey = e.target.value;

      if (parentFormItemKey && parentFormItemKey != "") {
        this.setState({ copyFieldsMapping: Object.assign({}, copyFieldsMapping, { [subFormItemKey]: parentFormItemKey }) });
        return;
      }

      delete copyFieldsMapping[subFormItemKey];
      this.setState({ copyFieldsMapping: copyFieldsMapping });
    };
  }

  onSubmit(e) {
    e.preventDefault();
    const { updateOptions, subFormOptions } = this.props;
    const { copyFieldsEnabled, copyFieldsMapping } = this.state;
    const copyFieldsMappingForUpdate = copyFieldsEnabled ? copyFieldsMapping : {};
    const newOptions = Object.assign({}, subFormOptions, { copyFieldsMapping: copyFieldsMappingForUpdate });

    this.setState({ copyFieldsMapping: copyFieldsMappingForUpdate }, this.toggleModal());
    updateOptions(newOptions);
  }

  renderFieldRow(field) {
    const { parentFormItems } = this.props;
    const { copyFieldsMapping } = this.state;

    const filteredParentFormItems = parentFormItems.filter((formItem) => FormItemTypesForSubformCopy.includes(formItem.type));

    const options = filteredParentFormItems.map((formItem) => {
      if (formItem.type == "checkbox_group") {
        return formItem.form_item_options.map(formItemOption => {
          return <option key={formItemOption.key} value={formItemOption.key}>{formItemOption.label}</option>;
        });
      } else {
        return <option key={formItem.key} value={formItem.key}>{formItem.label}</option>;
      }
    });

    return <tr key={field.key}>
      <td>
        <span>{field.label}</span>
        { " " }
        <code>{field.key}</code>
      </td>
      <td>
        <select className="form-select form-select-sm" defaultValue={copyFieldsMapping[field.key] || ""} onChange={this.changeFieldMapping(field.key)}>
          <option value="">{ I18n.t("react.form_items.subform.copy_field_default") }</option>
          { options }
        </select>
      </td>
    </tr>;
  }

  renderFieldsTable() {
    const { copyFieldsEnabled } = this.state;
    const { requestPending, subFormItems, errorMessage } = this.props;

    if (!copyFieldsEnabled) return null;

    if (requestPending) {
      return <div className="text-center">
        <Loader size="large" />
      </div>;
    }

    if (errorMessage) return <div className="alert alert-danger">{ errorMessage }</div>;

    if (!subFormItems || subFormItems.length == 0) return null;

    const filteredFormItems = subFormItems.filter((formItem) => FormItemTypesForSubformCopy.includes(formItem.type));

    const fieldRows = filteredFormItems.map((formItem) => {
      if (formItem.type == "checkbox_group")
        return formItem.form_item_options.map(formItemOption => this.renderFieldRow(formItemOption));
      else
        return this.renderFieldRow(formItem);
    });

    return <div className="table-responsive table-container copy-fields-table">
      <table className="table table-light table-bordered table-hover table-sm">
        <thead>
          <tr>
            <th>{ I18n.t("react.form_items.subform.copy_field_subform_field") }</th>
            <th>{ I18n.t("react.form_items.subform.copy_field_main_form_field") }</th>
          </tr>
        </thead>
        <tbody>
          { fieldRows }
        </tbody>
      </table>
    </div>;
  }

  renderModalContent() {
    const { copyFieldsEnabled } = this.state;

    return <div>
      <div className="modal-header">
        <h4 className="modal-title">{ I18n.t("react.form_items.subform.copy_fields_modal_title") }</h4>
        <button type="button" onClick={ this.toggleModal } className="btn-close" aria-label={I18n.t("close")}></button>
      </div>
      <div className="modal-body">
        <p>{ I18n.t("react.form_items.subform.copy_fields_modal_subtitle") }</p>
        <div className="mt-30">
          <Form.Group controlId="copy_fields_checkbox">
            <Form.Check
              type="checkbox"
              checked={ copyFieldsEnabled.toString() === "true" }
              onChange={this.toggleCopyFields}
              value="copyFieldsEnabled"
              label={I18n.t("react.form_items.subform.copy_fields_checkbox_label")}
            />
          </Form.Group>
        </div>
        { this.renderFieldsTable() }
      </div>
      <div className="modal-footer text-center">
        <a href="#" onClick={ this.onSubmit } className="btn btn-primary" > { I18n.t("react.form_items.subform.copy_fields_modal_validate") } </a>
      </div>
    </div>;
  }

  render() {
    const { isOpen, copyFieldsEnabled } = this.state;
    return <div>
      <a href="#" onClick={this.toggleModal}>
        {I18n.t("react.form_items.subform.manage_copy_fields")} ({ I18n.t(`react.form_items.subform.copy_fields_${copyFieldsEnabled ? "enabled" : "disabled"}`) })
      </a>
      <Modal isOpen={isOpen} onRequestClose={this.toggleModal} style={defaultModalStyles} contentLabel="Modal">
        { this.renderModalContent() }
      </Modal>
    </div>;
  }
}

CopyFieldsModal.propTypes = {
  subFormOptions: PropTypes.object.isRequired,
  updateOptions: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    guestCategories: state.guestCategories.data,
    subFormItems: state.subFormItems.data,
    requestPending: state.subFormItems.requestPending,
    errorMessage: state.subFormItems.errorMessage
  };
}

const mapDispatchToProps = {
  fetchSubFormItems
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyFieldsModal);
