import { ActionTypes } from "../constants/Constants";
import { SessionRoomState } from "../types/SessionRoom";

const defaultState: SessionRoomState = {
  errors: null,
  isFetching: false,
  data: null,
};

function sessionRoom(state = defaultState, action: any): SessionRoomState {
  switch (action.type) {
  case ActionTypes.FETCH_SESSION_ROOM_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.FETCH_SESSION_ROOM_SUCCESS:
    return { ...state, data: action.data, isFetching: false, errors: null };
  case ActionTypes.FETCH_SESSION_ROOM_FAILURE:
    return { ...state, data: null, isFetching: false, errors: action.data };

  case ActionTypes.CREATE_SESSION_ROOM_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.CREATE_SESSION_ROOM_SUCCESS:
    return { ...state, data: action.data, isFetching: false, errors: null };
  case ActionTypes.CREATE_SESSION_ROOM_FAILURE:
    return { ...state, isFetching: false, errors: action.data };

  case ActionTypes.UPDATE_SESSION_ROOM_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.UPDATE_SESSION_ROOM_SUCCESS:
    return { ...state, data: action.data, isFetching: false, errors: null };
  case ActionTypes.UPDATE_SESSION_ROOM_FAILURE:
    return { ...state, isFetching: false, errors: action.data };

  default:
    return state;
  }
}

export default sessionRoom;
