import Checkbox from "../../shared/Checkbox.react";
import HelpSection from "../../shared/HelpSection.react";
import { fieldI18n } from "../../../types/Event";

interface Props {
  smsMeetingNotificationEnabled: boolean;
  emailMeetingNotificationEnabled: boolean;

  onCheckboxChange: (key: string) => void;
}

const i18n = (key: string): string => {
  return I18n.t(`react.networking.meetings.notifications.${key}`);
};

const NotificationSettings: React.FC<Props> = ({
  smsMeetingNotificationEnabled,
  emailMeetingNotificationEnabled,
  onCheckboxChange
}) => {

  const renderCheckbox = (key: string, checked: boolean, i18nKey: string): JSX.Element => {
    return <Checkbox
      checked={checked}
      text={fieldI18n(i18nKey)}
      onChange={(): void => onCheckboxChange(key) }
    />;
  };

  return <>
    <HelpSection help={i18n("help")} />

    {renderCheckbox("smsMeetingNotificationEnabled", smsMeetingNotificationEnabled, "meeting_sms_notifications_enabled")}
    {renderCheckbox("emailMeetingNotificationEnabled", emailMeetingNotificationEnabled, "meeting_email_notifications_enabled")}
  </>;
};

export default NotificationSettings;
