import { Component } from "react";
import { connect } from "react-redux";
import map from "lodash/map";

import { updateUI } from "../actions/UIAppearanceActionCreators";
import { requestCountGuests } from "../actions/ImportActionCreators";
import { launchBatchUpdateForCalculatedField } from "../actions/GuestUpdatesActionCreators";
import { clearStatistics } from "../actions/GuestFieldsActionCreators";
import { CalculatedType } from "../constants/Constants";

import UpdateGuestFormComponent from "../components/UpdateGuestFormComponent.react";

import { queryStringAndSelectedGuests } from "../utils/QueryStringUtils";
import { urlEventId } from "../utils/pathUtils";
import withModal from "./withModal.react";

class CalculateGuestField extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { selectedGuests, location, updateUI, requestCountGuests } = this.props;
    const updates = {
      "modalTitle": I18n.t("react.reports.calculate_guest_field")
    };
    updateUI(updates);
    const query = queryStringAndSelectedGuests(location, selectedGuests);
    requestCountGuests(query, "nbGuestsWillBeUpdated", true);
  }

  componentWillUnmount() {
    const { clearStatistics } = this.props;
    clearStatistics();
  }

  submit(guestFieldKey) {
    const { closeModal, location, selectedGuests, launchBatchUpdateForCalculatedField } = this.props;
    launchBatchUpdateForCalculatedField(
      urlEventId(),
      queryStringAndSelectedGuests(location, selectedGuests),
      guestFieldKey,
      { notice: I18n.t("react.reports.batch_update_calculated_field"), noticeType: "success" }
    );
    closeModal();
  }

  render() {
    const { selectedGuestCount, guestFields } = this.props;
    const calculatedFields = guestFields.filter(guestField => guestField.type == CalculatedType);
    const options = map(calculatedFields, guestField => {
      return { value: guestField.key, label: guestField.label };
    });

    return (
      <UpdateGuestFormComponent
        options={options}
        noMostUsedValues={true}
        selectedGuestCount={selectedGuestCount}
        fieldLabel={I18n.t("mongoid.models.guest_field")}
        selectPlaceholder={I18n.t("react.reports.calculated_guest_field.filter_placeholder")}
        submitAction={this.submit}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    guestFields: state.guestFields.guestFields,
    selectedGuestCount: state.appearance.nbGuestsWillBeUpdated,
    selectedGuests: state.guests.selectedGuests
  };
}

const mapDispatchToProps = {
  launchBatchUpdateForCalculatedField,
  clearStatistics,
  requestCountGuests,
  updateUI
};

export default connect(mapStateToProps, mapDispatchToProps)(withModal(CalculateGuestField));
export { CalculateGuestField };
