import { createContext, Component } from "react";
import { connect } from "react-redux";
import { FEATURES_ENABLED_BY_DEFAULT, FEATURE_KEYS } from "../../constants/FeaturesSet";
import { childFeatures, dependencies, dependents } from "../../utils/featureSetUtils";
import { Event } from "../../types/Event";
import { FeatureSet } from "../../types/FeatureSet";
import { fetchFeatureSet, createFeatureSet, updateFeatureSet } from "../../actions/FeatureSetActionCreators";
import { urlEventId } from "../../utils/pathUtils";
import Loader from "../../components/shared/Loader.react";
import Menu from "../../components/features/Menu.react";
import Banner from "../../components/features/Banner.react";
import Notice from "../../components/features/Notice.react";
import DependenciesModal from "../../components/features/DependenciesModal.react";
import DisablingModal from "../../components/features/DisablingModal.react";
import ConfirmSave from "../../components/features/ConfirmSave.react";
import FeaturesPane from "../../components/features/FeaturesPane.react";
import Groups from "../../components/features/Groups.react";
import { redirectIfUnauthorized, isAuthorized } from "../../utils/aclUtils";

interface State {
  featurePaneKey?: string;
  dependenciesModalKey?: string;
  disablingModalKey?: string;
  newFeatures: string[];
  oldFeatures: string[];
}

interface StoreProps {
  errors?: string[];
  event: Event;
  featureSet: FeatureSet;
  isPendingRequest: boolean;
  isUpdating: boolean;
  notice: string;
  noticeType: string;
}

interface DispatchProps {
  createFeatureSet(eventId: string, params: any, notificationOptions?: any): void;
  fetchFeatureSet(eventId: string): void;
  updateFeatureSet(eventId: string, params: any, notificationOptions?: any): void;
}

export const FeatureDeckContext = createContext(null);

interface Props extends StoreProps, DispatchProps {}
class FeatureSetFormContainer extends Component<Props, State> {
  constructor(props: Props) {
    redirectIfUnauthorized("configuration", "manage");

    super(props);
    [
      "displayFeaturePane",
      "displayDependenciesModal",
      "displayDisablingModal",
      "hideDisablingModal",
      "hideFeaturePane",
      "save",
      "resetEnabledFeatures",
      "featureEnabled",
      "disabledDependencies",
      "hasDisabledDependencies",
      "toggleFeature",
      "featureBeingEnabled",
      "featureAlreadyEnabled",
      "disabledDependencies",
      "enabledGroupFeatures",
      "hideDependenciesModal",
      "featureBeingToggled",
      "featureBeingDisabled",
      "enabledDependents"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      featurePaneKey: null,
      dependenciesModalKey: null,
      disablingModalKey: null,
      newFeatures: [],
      oldFeatures: [],
    };
  }

  componentDidUpdate(prevProps): void {
    if (prevProps.isUpdating && !this.props.isUpdating && !this.props.errors) {
      window.location.replace(window.location.pathname);
    } else if (prevProps.isPendingRequest && !this.props.isPendingRequest && !this.props.errors) {
      this.resetEnabledFeatures();
    }
  }

  componentDidMount(): void {
    const { featureSet, fetchFeatureSet } = this.props;

    if (!featureSet) fetchFeatureSet(urlEventId());
  }

  resetEnabledFeatures(): void {
    this.setState({ newFeatures: [], oldFeatures: [] });
  }

  allFeatures(): string[] {
    return Object.values(FEATURE_KEYS);
  }

  computedFeatures(): string[] {
    const { newFeatures, oldFeatures } = this.state;
    const { featureSet } = this.props;

    const features = this.featureSetExists() ?
      Array.from(new Set(featureSet.features.concat(newFeatures).concat(FEATURES_ENABLED_BY_DEFAULT))) :
      this.allFeatures();

    // features to disable
    oldFeatures.forEach(key => {
      features.splice(features.indexOf(key), 1);
    });

    return features;
  }

  save(): void {
    const { createFeatureSet, updateFeatureSet } = this.props;

    const features = this.computedFeatures();
    const body = { feature_set: { features } };
    const notificationOptions = { notice: I18n.t("successfully_saved"), noticeType: "success", customDisplay: true };

    if (this.featureSetExists()) {
      updateFeatureSet(urlEventId(), body, notificationOptions);
    } else {
      createFeatureSet(urlEventId(), body, notificationOptions);
    }

    window.scrollTo(0, 0);
  }

  displayFeaturePane(groupKey: string): void {
    this.setState({ featurePaneKey: groupKey });
  }

  hideFeaturePane(): void {
    this.setState({ featurePaneKey: null });
  }

  displayDependenciesModal(featureKey: string): void {
    this.setState({ dependenciesModalKey: featureKey });
  }

  hideDependenciesModal(): void {
    this.setState({ dependenciesModalKey: null });
  }

  displayDisablingModal(featureKey: string): void {
    this.setState({ disablingModalKey: featureKey });
  }

  hideDisablingModal(): void {
    this.setState({ disablingModalKey: null });
  }

  featureSetExists(): boolean {
    const { featureSet } = this.props;
    return !!featureSet.created_at;
  }

  featureBeingEnabled(featureKey: string): boolean {
    const { newFeatures } = this.state;

    if (!this.featureSetExists()) return false; // Everything enabled on old events with no featureSet
    return newFeatures.includes(featureKey);
  }

  featureBeingDisabled(featureKey: string): boolean {
    const { oldFeatures } = this.state;

    return oldFeatures.includes(featureKey);
  }

  featureBeingToggled(featureKey: string): boolean {
    return this.featureBeingEnabled(featureKey) || this.featureBeingDisabled(featureKey);
  }

  featureAlreadyEnabled(featureKey: string): boolean {
    const { featureSet } = this.props;

    if (!this.featureSetExists()) return true; // Everything enabled and cannot be disable on old events with no featureSet
    return featureSet.features.includes(featureKey);
  }

  featureEnabled(featureKey: string): boolean {
    return this.featureBeingEnabled(featureKey) || (this.featureAlreadyEnabled(featureKey) && !this.featureBeingDisabled(featureKey));
  }

  enabledGroupFeatures(groupKey: string): string[] {
    return childFeatures(groupKey).filter((featureKey) => {
      return this.featureEnabled(featureKey);
    });
  }

  disabledDependencies(featureKey: string): string[] {
    return dependencies(featureKey).filter(featureKey => !this.featureEnabled(featureKey));
  }

  enabledDependents(featureKey: string): string[] {
    return dependents(featureKey).filter(featureKey => this.featureEnabled(featureKey));
  }

  hasDisabledDependencies(featureKey: string): boolean {
    return this.disabledDependencies(featureKey).length > 0;
  }

  reenableFeature(featureKey: string): void {
    const { oldFeatures } = this.state;
    const newState = oldFeatures.slice();
    const index = oldFeatures.indexOf(featureKey);

    newState.splice(index, 1);
    this.setState({ oldFeatures: newState });
    return;
  }

  enableFeature(featureKey: string): void {
    const { oldFeatures, newFeatures } = this.state;
    const featuresToEnable = newFeatures.slice();
    const featuresToDisable = oldFeatures.slice();

    [...Array.from(new Set([...dependencies(featureKey), featureKey]))].forEach(key => {
      if (this.featureAlreadyEnabled(key)) {
        featuresToDisable.splice(featuresToDisable.indexOf(key), 1);
      } else {
        featuresToEnable.push(key);
      }
    });

    this.setState(
      {
        newFeatures: [...Array.from(new Set([...featuresToEnable]))],
        oldFeatures: [...Array.from(new Set([...featuresToDisable]))]
      }
    );
  }

  disableFeature(featureKey: string): void {
    const { oldFeatures, newFeatures } = this.state;
    const featuresToEnable = newFeatures.slice();
    const featuresToDisable = oldFeatures.slice();

    [...Array.from(new Set([...dependents(featureKey), featureKey]))].forEach(key => {
      if (this.featureBeingEnabled(key)) {
        featuresToEnable.splice(featuresToEnable.indexOf(key), 1);
      } else if (this.featureAlreadyEnabled(key)) {
        featuresToDisable.push(key);
      }
    });

    this.setState(
      {
        newFeatures: [...Array.from(new Set([...featuresToEnable]))],
        oldFeatures: [...Array.from(new Set([...featuresToDisable]))]
      }
    );
  }

  toggleFeature(featureKey: string): void {
    if (this.featureEnabled(featureKey)) {
      this.disableFeature(featureKey);
    } else {
      this.enableFeature(featureKey);
    }

    this.hideDependenciesModal();
  }

  hasChanges(): boolean {
    const { newFeatures, oldFeatures } = this.state;

    return newFeatures.length > 0 || oldFeatures.length > 0;
  }

  render(): JSX.Element {
    const { dependenciesModalKey, disablingModalKey, featurePaneKey } = this.state;
    const { featureSet, errors, notice, noticeType } = this.props;

    if (!isAuthorized("configuration", "manage")) return null;
    if (!featureSet) return <Loader />;

    return <FeatureDeckContext.Provider value={{
      featureEnabled: this.featureEnabled,
      enabledGroupFeatures: this.enabledGroupFeatures,
      featureBeingEnabled: this.featureBeingEnabled,
      featureAlreadyEnabled: this.featureAlreadyEnabled,
      featureBeingDisabled: this.featureBeingDisabled,
      featureBeingToggled: this.featureBeingToggled,
      disabledDependencies: this.disabledDependencies,
      hasDisabledDependencies: this.hasDisabledDependencies,
      enabledDependents: this.enabledDependents,
      toggleFeature: this.toggleFeature,
      displayDependenciesModal: this.displayDependenciesModal,
      displayDisablingModal: this.displayDisablingModal,
      displayFeaturePane: this.displayFeaturePane
    }}>
      <div className="feature-set row justify-content-center" id="feature-set">
        <Banner />
        <div className="col-xl-10 col-lg-11 col-md-12 mt-4" id="top-content-features">
          <div className="row">
            <div className="col-lg-3">
              <Menu />
            </div>
            <div className={`col-lg-9 ${this.hasChanges() ? "mb-50" : ""}`}>
              <Notice errors={errors} notice={notice} noticeType={noticeType} />
              <Groups />
              <ConfirmSave reset={this.resetEnabledFeatures} save={this.save} show={this.hasChanges()} />
              <DisablingModal featureKey={disablingModalKey} hide={this.hideDisablingModal} />
              <DependenciesModal featureKey={dependenciesModalKey} hide={this.hideDependenciesModal} />
              <FeaturesPane groupKey={featurePaneKey} hide={this.hideFeaturePane} />
            </div>
          </div>
        </div>
      </div>
    </FeatureDeckContext.Provider>;
  }
}

const mapStateToProps = (state): StoreProps => {
  const { featureSet, event, notifications } = state;
  return {
    errors: featureSet.errors,
    event,
    featureSet: featureSet.data,
    isPendingRequest: featureSet.pendingRequest,
    isUpdating: featureSet.isUpdating,
    notice: notifications.currentNotice,
    noticeType: notifications.noticeType,
  };
};

const mapDispatchToProps: DispatchProps = {
  fetchFeatureSet,
  createFeatureSet,
  updateFeatureSet
};

export default connect<StoreProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(FeatureSetFormContainer);
