import { useState, useRef } from "react";
import ReactIframeResizer from "react-iframe-resizer-super";
import { pathToEmailTemplatePreview, pathToDocumentTemplatePreview } from "../../utils/pathUtils";

interface Props {
  templateId: string;
  templateType: "email_template" | "document_template";
  guestId: string;
  scrolling?: boolean;
  autoResize: boolean;
  displayResizeButton?: boolean;
  customClass?: string;
  defaultHeight?: string;
}

const TemplatePreviewIframe: React.FC<Props> = (props) => {
  const [autoResize, setAutoResize] = useState<boolean>(props.autoResize || false);
  const iframeRef = useRef(null);

  const toggleIframeHeight = (): void => {
    setAutoResize(!autoResize);
  };

  const i18n = (key: string): string => {
    switch (props.templateType) {
    case "email_template":
      return I18n.t(`react.guest_category.email_preview.${key}`);
    case "document_template":
      return I18n.t(`react.guest_category.document_preview.${key}`);
    }
  };

  const renderResizeButton = (): JSX.Element => {
    if (!props.displayResizeButton)
      return null;

    return <div className="text-center resize-button-container">
      <a onClick={toggleIframeHeight} className="btn btn-primary btn-sm">
        { autoResize ? i18n("reduce_iframe_height") : i18n("display_full_iframe") }
      </a>
    </div>;
  };

  const iframeUrl = (): string => {
    switch (props.templateType) {
    case "email_template":
      return pathToEmailTemplatePreview(props.templateId, props.guestId);
    case "document_template":
      return pathToDocumentTemplatePreview(props.templateId, props.guestId);
    }
  };

  const renderIframe = (): JSX.Element => {
    const { defaultHeight, scrolling, customClass } = props;
    const customStyle = {
      minHeight: defaultHeight || "100%"
    };

    if (autoResize || scrolling) {
      return <ReactIframeResizer
        src={iframeUrl()}
        className={`template-preview-iframe ${customClass ? customClass : ""}`}
        id="template-preview-iframe"
        ref={iframeRef}
        iframeResizerEnable={autoResize}
        style={customStyle} />;
    }

    // since scrolling attribute cannot be passed as prop to ReactIframeResizer, we have to handle this special case
    return <iframe
      src={iframeUrl()}
      className={`template-preview-iframe ${customClass ? customClass : ""}`}
      id="template-preview-iframe"
      ref={iframeRef}
      style={customStyle}
      scrolling="no" />;
  };

  if (!props.displayResizeButton)
    return renderIframe();

  return <div className="iframe-container col-12">
    { renderIframe() }
    { renderResizeButton() }
  </div>;
};

export default TemplatePreviewIframe;
