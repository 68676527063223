import { fabric } from "fabric";
import qrcodeImage from "./../assets/qrcode-image.png";

const MAX_WIDTH_QRCODE = 80;

export const defineQrcodeClass = (canvas: fabric.Canvas): void => {
  fabric.Qrcode = fabric.util.createClass(fabric.Object, {
    type: "qrcode",
    initialize: function(options = {}) {
      this._loadImage(qrcodeImage, options);
    },
    _loadImage: function(url, options) {
      fabric.util.loadImage(url, (img) => {
        this.qrCodeImage = img;
        let scaleValue = MAX_WIDTH_QRCODE / this.qrCodeImage.width;
        if (options) {
          scaleValue = options.width / this.qrCodeImage.width;
        }

        this.set({
          top: options.top || 0,
          left: options.left || 0,
          width: this.qrCodeImage.width,
          height: this.qrCodeImage.height,
          scaleX: options.scaleX || scaleValue,
          scaleY: options.scaleY || scaleValue
        });
        this.setCoords();
        canvas.requestRenderAll();
      });
    },
    _render: function(ctx) {
      if (this.qrCodeImage) {
        ctx.drawImage(this.qrCodeImage, -this.width / 2, -this.height / 2, this.width, this.height);
        this.callSuper("_render", ctx);
      }
    }
  });

  fabric.Qrcode.fromObject = function(object: any, callback: any): any {
    const qrCode = new fabric.Qrcode(object);
    callback && callback(qrCode);
    return qrCode;
  };
};
