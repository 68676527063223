import { featureName, dependencies } from "../../utils/featureSetUtils";
import Icons from "../../constants/Icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
  featureKey: string;
}

const Dependencies: React.FC<Props> = ({ featureKey }) => {
  const dep = dependencies(featureKey);

  if (dep.length === 0) return null;

  const tooltip = (): JSX.Element => {
    return <Tooltip id="dependencies">
      <ul style={{ margin: 0, padding: "5px", textAlign: "left", listStyle: "none" }}>
        {dep.map((dependency, index) => {
          return <li key={index}>{featureName(dependency)}</li>;
        })}
      </ul>
    </Tooltip>;
  };

  const overlayTrigger = (child: JSX.Element, tooltip: JSX.Element): JSX.Element => {
    return <OverlayTrigger placement="top" overlay={tooltip}>
      {child}
    </OverlayTrigger>;
  };

  const dependenciesList = <div className="d-flex">
    <p className="dependencies-list mt-10 ps-0">
      <span className="icon-linked d-flex flex-column justify-content-center">{Icons.paperClipIcon()}</span>
      {dep.slice(0, 2).map((dependency, index) => {
        return <span key={index}>
          {featureName(dependency)}{index !== (dep.length - 1) ? ", " : null}
        </span>;
      })}
    </p>
  </div>;

  return dep.length > 1 ? overlayTrigger(dependenciesList, tooltip()) : dependenciesList;
};

export default Dependencies;
