import { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Loader from "../../components/shared/Loader.react";
import ConfirmationModal from "../../components/shared/ConfirmationModal.react";
import { fetchWorkflowRun, deleteWorkflowRun, resetSelectedWorkflowRun, replayWorkflowRun } from "../../actions/WorkflowRunsActionCreators";
import { urlEventId, pathToWorkflowRuns } from "../../utils/pathUtils";
import { redirectIfUnauthorized, isSuperAdmin } from "../../utils/aclUtils";
import { hasAvailableFeature } from "../../utils/availableFeature";
import { WorkflowRun, StepRun } from "../../types/Workflow";
import usePrevious from "../../utils/hooks/usePrevious";
import { WORKFLOW_RUN_STATUSES, WORKFLOW_STEP_RUN_STATUSES, WORKFLOW_STEP_TRIGGER, WORKFLOW_STEP_ACTION,
  WORKFLOW_STEP_FILTER } from "../../constants/Constants";

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.workflow_runs.show.${key}`, opts);
}

interface Props {
  workflowRunId: string;
}

const WorkflowRunDetails: React.FC<Props> = ({ workflowRunId }) => {
  redirectIfUnauthorized("configuration", "manage");

  // TODO : remove this condition once released
  if (!isSuperAdmin() && !hasAvailableFeature("automation")) {
    return null;
  }

  moment.locale(I18n.currentLocale());

  const [replayModalVisible, setReplayModalVisible] = useState<boolean>(false);

  const workflowRun: WorkflowRun = useSelector((state: any) => state.workflowRuns.selectedWorkflowRun);
  const isPendingRequest: boolean = useSelector((state: any) => state.workflowRuns.isPendingRequest);
  const previousWorkflowRun = usePrevious(workflowRun);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchWorkflowRun(urlEventId(), workflowRunId));
  }, []);

  useEffect(() => {
    // after deleting run
    if (previousWorkflowRun && !workflowRun) {
      history.push({ ...location, pathname: pathToWorkflowRuns() });
    }
  }, [workflowRun]);

  const deleteRun = (): void => {
    if (confirm(I18n.t("confirm"))) {
      const notificationOptions = { notice: i18n("deleted_successfully"), noticeType: "success" };
      dispatch(deleteWorkflowRun(urlEventId(), workflowRunId, notificationOptions));
    }
  };

  const replayRun = (): void => {
    const notificationOptions = { notice: i18n("replayed_successfully"), noticeType: "success" };
    dispatch(replayWorkflowRun(urlEventId(), workflowRunId, notificationOptions));
    setReplayModalVisible(false);
  };

  const renderWorkflowRunStatus = (): JSX.Element => {
    const config = WORKFLOW_RUN_STATUSES[workflowRun.status];
    const label = I18n.t(`workflow_run_status.${workflowRun.status}`);

    return <span className={`badge rounded-pill ${config.backgroundClass}`}>
      <i className={`fa fa-regular fa-${config.iconClass}`}></i> {label}
    </span>;
  };

  const renderStepRunStatus = (status: string): JSX.Element => {
    const config = WORKFLOW_STEP_RUN_STATUSES[status];
    const label = I18n.t(`workflow_step_run_status.${status}`);

    return <span className={`badge rounded-pill ${config.backgroundClass}`}>
      <i className={`fa fa-regular fa-${config.iconClass}`}></i> {label}
    </span>;
  };

  const renderMainInformation = (): JSX.Element => {
    return <>
      <h4>{workflowRun.workflow_name}</h4>
      <div className="table-container mb-3">
        <table className="table table-light table-bordered">
          <thead>
            <tr>
              <th>{i18n("status")}</th>
              <th>{i18n("version")}</th>
              <th>{i18n("triggered_at")}</th>
              <th>{i18n("finished_at")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{renderWorkflowRunStatus()}</td>
              <td><span className="badge rounded-pill bg-secondary">Version {workflowRun.workflow_version_number}</span></td>
              <td>{renderFormattedDate(workflowRun.triggered_at)}</td>
              <td>{renderFormattedDate(workflowRun.finished_at)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>;
  };

  const renderStepTypeIcon = (type: string): JSX.Element => {
    if (type === WORKFLOW_STEP_TRIGGER) {
      return <i className="fa-regular fa-play mr-5"></i>;
    } else if (type === WORKFLOW_STEP_ACTION) {
      return <i className="fa-regular fa-bolt mr-5"></i>;
    } else if (type === WORKFLOW_STEP_FILTER) {
      return <i className="fa-regular fa-arrow-right-arrow-left mr-5"></i>;
    }
  };

  const renderStepRunType = (type: string): JSX.Element => {
    return <small className="text-muted">{renderStepTypeIcon(type)}{I18n.t(`automation.${type}`)}</small>;
  };

  const renderStepRunStrategy = (stepRun: StepRun, rank: number): JSX.Element => {
    if (stepRun.type === WORKFLOW_STEP_TRIGGER) {
      return <span>{rank}. {I18n.t(`automation.triggers.full.${stepRun.strategy}`)}</span>;
    } else if (stepRun.type === WORKFLOW_STEP_ACTION) {
      return <span>{rank}. {I18n.t(`automation.actions.${stepRun.strategy}`)}</span>;
    } else if (stepRun.type === WORKFLOW_STEP_FILTER && stepRun.conditions.length) {
      return <span>{rank}. {I18n.t(`automation.filters.${stepRun.strategy}`)}</span>;
    }
  };

  const renderData = (options: any, level: number): JSX.Element[] | JSX.Element => {
    if (!options || Object.keys(options).length === 0) {
      return <p>-</p>;
    }

    return Object.entries(options).map(([key, value]) => {
      const paddingLeft = `${(level - 1) * 12}px`;

      if (value && typeof value === "object") {
        if (value instanceof Array) {
          return <Fragment key={key}>
            <p style={{ paddingLeft }}><strong>{key}</strong></p>
            {value.map((val) => {
              if (typeof val === "object") {
                return renderData(val, level + 1);
              } else {
                return <p key={`${key}-${val}`} style={{ paddingLeft }}>
                  - <small className="text-nowrap">{String(val)}</small>
                </p>;
              }
            })}
          </Fragment>;
        }

        return <Fragment key={key}>
          <p style={{ paddingLeft }}><strong>{key}</strong></p>
          {renderData(value, level + 1)}
        </Fragment>;
      } else {
        return <p key={key} style={{ paddingLeft }}>
          <strong>{key}</strong>{ " " }<small className={`text-nowrap ${key === "error" ? "text-danger fw-bolder" : null}`}>{String(value)}</small>
        </p>;
      }
    });
  };

  const renderFormattedDate = (date: string): string => {
    if (!date) return "-";

    return `${moment(date).format("LL")} ${moment(date).format("LTS")}`;
  };

  const renderIOs = (title: string, data: any): JSX.Element => {
    return <>
      <h5 className="border-bottom pb-2">{title}</h5>
      <div className="pt-1" style={{ maxHeight: "400px", overflowY: "scroll" }}>
        {renderData(data, 1)}
      </div>
    </>;
  };

  const renderStepRunCard = (stepRun: StepRun, index: number): JSX.Element => {
    return <div className="card mb-3" key={stepRun._id}>
      <div className="card-body">
        <div className="row">
          <div className="col-md-4">
            <p className="card-subtitle mb-1">{renderStepRunType(stepRun.type)}</p>
            <h4 className="card-title mb-2">{renderStepRunStrategy(stepRun, index + 1)}</h4>
            <p className="card-text">{renderStepRunStatus(stepRun.status)}</p>
            <p className="card-text">{renderFormattedDate(stepRun.started_at)}</p>
          </div>
          <div className="col-md-4">
            {renderIOs(i18n("input"), stepRun.type === WORKFLOW_STEP_FILTER ? stepRun.conditions : stepRun.settings)}
          </div>
          <div className="col-md-4">
            {renderIOs(i18n("output"), workflowRun.context[stepRun.step_id])}
          </div>
        </div>
      </div>
    </div>;
  };

  const renderBackButton = (): JSX.Element => {
    return <Link to={`../workflow_runs${location.search}`} onClick={(): void => dispatch(resetSelectedWorkflowRun())}>
      <i className="fa-regular fa-chevron-left fa-fw fa-xs"></i>
    </Link>;
  };

  const renderDeleteButton = (): JSX.Element => {
    if (!isSuperAdmin()) return;

    return <input type="button" className="btn btn-danger" onClick={deleteRun} value={i18n("delete")} />;
  };

  const renderReplayButton = (): JSX.Element => {
    if (workflowRun.status !== "failed") return;

    return <input type="button" className="btn btn-secondary" onClick={(): void => setReplayModalVisible(true)} value={i18n("replay")} />;
  };

  const renderReplayModal = (): JSX.Element => {
    return <ConfirmationModal
      isVisible={replayModalVisible}
      onClose={(): void => setReplayModalVisible(false)}
      onConfirm={replayRun}
      title={i18n("replay_confirmation_title")}
      confirmationQuestion={i18n("replay_confirmation_text")}
      restoreFocus={false}
    />;
  };

  if (isPendingRequest || !workflowRun) {
    return <Loader size="large" inline={false} containerHeight="100%" />;
  }

  return <div className="row workflow-run-show">
    <div className="col-12">
      <div className="header-page">
        <div className="header-page-content row">
          <div className="header-page-title col-sm-9">
            <h1>
              {renderBackButton()}
              {i18n("title")}
            </h1>
          </div>
          <div className="header-page-btn col-sm-3">
            {renderReplayButton()}
            {renderDeleteButton()}
          </div>
        </div>
      </div>
      {renderMainInformation()}
      {workflowRun.step_runs.map((stepRun: StepRun, index: number) => {
        return renderStepRunCard(stepRun, index);
      })}
      {renderReplayModal()}
    </div>
  </div>;

};

export default WorkflowRunDetails;
