import { ActionTypes } from "../constants/Constants";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";

const initialState = {
  items: null,
  errors: null,
  lastUpdatedSessionTypeId: null,
  fetching: false
};

function sessionTypes(state = initialState, action) {
  switch (action.type) {
  case ActionTypes.RECEIVE_SESSION_TYPES_REQUEST:
    return { ...initialState, fetching: true };
  case ActionTypes.RECEIVE_SESSION_TYPES_SUCCESS:
    return { ...state, items: action.data || [], fetching: false };
  case ActionTypes.CREATE_SESSION_TYPE_REQUEST:
    return { ...state, fetching: true };
  case ActionTypes.CREATE_SESSION_TYPE_SUCCESS: {
    const newItemsAfterCreate = [action.data, ...state.items];
    return { items: newItemsAfterCreate, lastUpdatedSessionTypeId: action.data._id, fetching: false };
  }
  case ActionTypes.SESSION_TYPE_FAILURE: {
    const items = [...state.items];
    const errors = { ...action.data };
    return { items: items, errors: errors, lastUpdatedSessionTypeId: action.sessionTypeId, fetching: false };
  }
  case ActionTypes.UPDATE_SESSION_TYPE_REQUEST: {
    return { ...state, lastUpdatedSessionTypeId: null };
  }
  case ActionTypes.UPDATE_SESSION_TYPE_SUCCESS: {
    const items = insertOrUpdateObject(state.items, action.data, "_id");
    return { items: items, errors: null, lastUpdatedSessionTypeId: action.data._id, fetching: false };
  }
  case ActionTypes.REMOVE_SESSION_TYPE_SUCCESS: {
    const newItemsAfterRemove = state.items.filter(sessionType => {
      return sessionType._id != action.sessionTypeId;
    });
    return { ...initialState, items: newItemsAfterRemove, fetching: false };
  }
  case ActionTypes.CLEAR_SESSION_TYPE_ERRORS:
    return { ...state, errors: null };

  default:
    return state;
  }
}

export default sessionTypes;
