import { Component } from "react";
import RichTextEditor from "./shared/RichTextEditor.react";
import BottomOptions from "./form_item_question/BottomOptions.react";
import conditionalDisplay from "../utils/conditionalDisplayUtils.js";

class FormParagrah extends Component {

  constructor(props) {
    super(props);
    ["_handleContentOnBlur", "updateDisplay"].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = {
      currentContent: props.formItem.options.content,
      draftjsContent: props.formItem.options.draftjs_content,
      display: {
        conditional_display: this.props.formItem.display_conditions.length > 0
      }
    };
  }

  _handleContentOnBlur(content, draftjs_content) {
    if (content === "") { return; }
    this.setState({ currentContent: content, draftjsContent: draftjs_content });
    const { updateHandler } = this.props;
    updateHandler({ options: { content, draftjs_content } });
  }

  updateDisplay(newDisplay) {
    this.setState({ display: newDisplay });
  }

  render() {
    const { mode, updateHandler, destroyHandler, formItem, renderConditionalDisplay } = this.props;
    const { currentContent, draftjsContent, display } = this.state;
    if (mode == "edit") {
      return (
        <div>
          <div className="mb-3 row">
            <div className="col-sm-12">
              <RichTextEditor content={currentContent}
                draftjsContent={draftjsContent}
                onBlur={this._handleContentOnBlur}
                toolbarSize="small"
                textGeneratorPrePromptKey="organizer_content_context"
              />
            </div>
          </div>
          { display.conditional_display && renderConditionalDisplay() }
          <BottomOptions
            formItem={formItem}
            display={display}
            destroyHandler={destroyHandler}
            updateHandler={updateHandler}
            updateParentDisplay={this.updateDisplay} />
        </div>
      );
    } else if (mode == "read") {
      const hasText = draftjsContent && draftjsContent.blocks.find(block => {
        return block.text.match(/\S/);
      });
      const content = hasText ? currentContent : `<em>${I18n.t("react.form_items.empty_paragraph")}</em>`;
      return (
        <div>
          <p dangerouslySetInnerHTML={{ __html: content }}></p>
        </div>
      );
    }
  }
}

export default conditionalDisplay(FormParagrah);
