import { DropdownButton, DropdownItem } from "react-bootstrap";
import SessionTypeFilter from "./selection_filters/SessionTypeFilter.react";
import AttendanceTypeFilter from "./selection_filters/AttendanceTypeFilter.react";
import ThematicsFilter from "./selection_filters/ThematicsFilter.react";
import SessionRoomFilter from "./selection_filters/SessionRoomFilter.react";
import DateRangeFilter from "./selection_filters/DateRangeFilter.react";
import TraitFilter from "./selection_filters/TraitFilter.react";
import BookmarksOnlyFilter from "./selection_filters/BookmarksOnlyFilter.react";
import MissedSessionsFilter from "./selection_filters/MissedSessionsFilter.react";
import SortByRecommendationFilter from "./selection_filters/SortByRecommendationFilter.react";
import { Program, ProgramFilter, FilterContext } from "../../types/Program";
import { availableFilterTypes } from "../../utils/programUtils";
import { ProgramFilterTypes } from "../../constants/Constants";
import { Event } from "../../types/Event";
import HelpSection from "../shared/HelpSection.react";

interface Props {
  program: Program;
  event: Event;

  addFilter(type: string, context: FilterContext): void;
  updateFilter(programFilter: ProgramFilter, filterKey: string): void
  removeFilter(programFilter: ProgramFilter, filterKey: string, context: FilterContext): void;
}

function i18n(key: string, opts = {}): string {
  return I18n.t(`react.programs.${key}`, opts);
}

const ProgramSelection: React.FC<Props> = ({ program, event, addFilter, updateFilter, removeFilter }) => {
  const renderFilterDropdownOptions = (): JSX.Element[] => {
    return availableFilterTypes(program, "selection").map((filterType, index) => {
      if (filterType === "sort_by_recommendation" && event?.scoring_and_engagement_enabled === false) return;

      return <DropdownItem eventKey={index} key={filterType} onClick={(): void => addFilter(filterType, "selection")}>
        {i18n(`filter_type.${filterType}`)}
      </DropdownItem>;
    });
  };

  const renderFilters = (): JSX.Element[] => {
    return Object.entries(program.program_filters).filter(filter => {
      return !filter[1]._destroy && Object.keys(filter[1].preset_args).length > 0;
    }).map(([filterKey, filter]) => {
      const filterProps = {
        key: filterKey,
        filterKey,
        programFilter: filter,
        removeFilter,
        updateFilter
      };

      switch (filter._type) {
      case ProgramFilterTypes.SESSION_TYPE:
        return <SessionTypeFilter {...filterProps} />;
      case ProgramFilterTypes.ATTENDANCE_TYPE:
        return <AttendanceTypeFilter {...filterProps} />;
      case ProgramFilterTypes.THEMATIC:
        return <ThematicsFilter {...filterProps} />;
      case ProgramFilterTypes.SESSION_ROOM:
        return <SessionRoomFilter {...filterProps} />;
      case ProgramFilterTypes.DATE_RANGE:
        return <DateRangeFilter {...filterProps} />;
      case ProgramFilterTypes.TRAIT:
        return <TraitFilter {...filterProps} />;
      case ProgramFilterTypes.BOOKMARKS_ONLY:
        return <BookmarksOnlyFilter {...filterProps} />;
      case ProgramFilterTypes.MISSED_SESSIONS:
        return <MissedSessionsFilter {...filterProps} />;
      case ProgramFilterTypes.SORT_BY_RECOMMENDATION:
        return <SortByRecommendationFilter {...filterProps} />;
      }
    });
  };

  return <div className="card">
    <div className="card-header">
      <div className="card-title">{i18n("selection_section.title")}</div>
    </div>
    <div className="card-body">
      <HelpSection help={i18n("selection_section.help")} />
      { renderFilters() }
      <DropdownButton variant="secondary" title={<span><i className="fa-regular fa-plus"></i> {i18n("selection_filter_dropdown.title")}</span>} id="SelectionFilterDropdown">
        {renderFilterDropdownOptions()}
      </DropdownButton>
    </div>
  </div>;
};

export default ProgramSelection;
