import { useEffect, useState, MouseEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../components/shared/Loader.react";
import Paginate from "../../components/Paginate.react";
import WorkflowsDropdown from "./WorkflowsDropdown.react";
import WorkflowStatusDropdown from "../../components/automation/WorkflowStatusDropdown.react";
import DateRangePicker from "../../components/DateRangePicker.react";
import BatchActionConfirmationModal from "../../components/automation/BatchActionConfirmationModal.react";
import { fetchWorkflowRuns, fetchWorkflowRunsTotalCount, resetCountedAll, batchDeleteWorkflowRuns,
  batchReplayWorkflowRuns } from "../../actions/WorkflowRunsActionCreators";
import { urlEventId, pathToWorkflowRun } from "../../utils/pathUtils";
import usePrevious from "../../utils/hooks/usePrevious";
import { WorkflowRun } from "../../types/Workflow";
import { redirectIfUnauthorized, isSuperAdmin } from "../../utils/aclUtils";
import { hasAvailableFeature } from "../../utils/availableFeature";
import { extractPaginationPageNumbers } from "../../utils/QueryStringUtils";
import { WORKFLOW_RUNS_PER_PAGE, WORKFLOW_RUN_STATUSES } from "../../constants/Constants";

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.workflow_runs.index.${key}`, opts);
}

const WorkflowRuns: React.FC = () => {
  redirectIfUnauthorized("configuration", "manage");

  // TODO : remove this condition once released
  if (!isSuperAdmin() && !hasAvailableFeature("automation")) {
    return null;
  }

  moment.locale(I18n.currentLocale());

  const [previousPageNumber, setPreviousPageNumber] = useState<number>(null);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(null);
  const [nextPageNumber, setNextPageNumber] = useState<number>(null);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<string>(null);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(null);
  const [selectedStartDate, setSelectedStartDate] = useState<string>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<string>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [replayModalVisible, setReplayModalVisible] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [batchForceAll, setBatchForceAll] = useState<boolean>(false);

  const workflowRuns = useSelector((state: any) => state.workflowRuns.data);
  const nextURL = useSelector((state: any) => state.workflowRuns.nextURL);
  const previousURL = useSelector((state: any) => state.workflowRuns.previousURL);
  const totalCount = useSelector((state: any) => state.workflowRuns.totalCount);
  const countedAll = useSelector((state: any) => state.workflowRuns.countedAll);
  const isPendingBatchRequest = useSelector((state: any) => state.workflowRuns.isPendingBatchRequest);

  const previousIsPendingBatchRequest = usePrevious(isPendingBatchRequest);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    initSearchQueryFromUrl();
  }, []);

  useEffect(() => {
    if (isPendingBatchRequest || !previousIsPendingBatchRequest) return;

    if (replayModalVisible) setReplayModalVisible(false);
    if (deleteModalVisible) setDeleteModalVisible(false);
    setSelectedIds([]);
    setBatchForceAll(false);
    dispatch(fetchWorkflowRuns(urlEventId(), selectedWorkflowId, selectedStatuses, selectedStartDate, selectedEndDate, currentPageNumber));
  }, [isPendingBatchRequest]);

  useEffect(() => {
    const [ previousPageNumber, nextPageNumber ] = extractPaginationPageNumbers(previousURL, nextURL);
    setPreviousPageNumber(previousPageNumber);
    setNextPageNumber(nextPageNumber);
  }, [nextURL, previousURL]);

  useEffect(() => {
    if (!countedAll) return;

    dispatch(resetCountedAll());
  }, [selectedWorkflowId, selectedStatuses, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    if (!selectedWorkflowId && !selectedStatuses && !selectedStartDate && !selectedEndDate && !currentPageNumber) return;

    pushSearchQueryIntoUrl();
    setSelectedIds([]);
    dispatch(fetchWorkflowRuns(urlEventId(), selectedWorkflowId, selectedStatuses, selectedStartDate, selectedEndDate, currentPageNumber));
  }, [selectedWorkflowId, selectedStatuses, selectedStartDate, selectedEndDate, currentPageNumber]);

  const initSearchQueryFromUrl = (): void => {
    const searchQuery = new URLSearchParams(location.search);

    setSelectedWorkflowId(searchQuery.get("workflow_id"));
    setSelectedStatuses(searchQuery.getAll("statuses"));
    setCurrentPageNumber(parseInt(searchQuery.get("page")) || 1);
    setSelectedStartDate(searchQuery.get("since"));
    setSelectedEndDate(searchQuery.get("before"));
  };

  const pushSearchQueryIntoUrl = (): void => {
    const searchQuery = new URLSearchParams();

    if (selectedWorkflowId) {
      searchQuery.append("workflow_id", selectedWorkflowId);
    }
    if (selectedStartDate) {
      searchQuery.append("since", selectedStartDate);
    }
    if (selectedEndDate) {
      searchQuery.append("before", selectedEndDate);
    }
    if (currentPageNumber) {
      searchQuery.append("page", currentPageNumber.toString());
    }
    (selectedStatuses || []).forEach((status) => {
      searchQuery.append("statuses", status);
    });
    history.push({ path_name: "workflow_runs", search: searchQuery.toString() });
  };

  const onPaginate = (pageNumber: number): any => {
    return (e: MouseEvent): void => {
      e.preventDefault();
      setCurrentPageNumber(pageNumber || 1);
    };
  };

  const changeSelectedWorkflow = (selectedWorkflow: any): void => {
    setSelectedWorkflowId(selectedWorkflow ? selectedWorkflow.value : "");
    setCurrentPageNumber(1);
  };

  const changeSelectedStatuses = (selectedStatuses: any[]): void => {
    setSelectedStatuses(selectedStatuses.map(status => status.value));
    setCurrentPageNumber(1);
  };

  const changeSelectedDateRange = (startDate: any, endDate: any): void => {
    setSelectedStartDate(startDate ? startDate.toISOString() : null);
    setSelectedEndDate(endDate ? endDate.toISOString() : null);
    setCurrentPageNumber(1);
  };

  const fetchTotalCount = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    dispatch(fetchWorkflowRunsTotalCount(urlEventId(), selectedWorkflowId, selectedStatuses, selectedStartDate, selectedEndDate));
  };

  const toggleWorkflowRun = (idOrAll: string): (() => void) => {
    return (): void => {
      if (idOrAll === "all") {
        const forceCheck = selectedIds.length !== workflowRuns.length;
        if (forceCheck) {
          setSelectedIds(workflowRuns.map(workflowRun => workflowRun._id));
        } else {
          setSelectedIds([]);
        }
      } else if (selectedIds.includes(idOrAll)) {
        setSelectedIds(_.without(selectedIds, idOrAll));
      } else {
        setSelectedIds([...selectedIds, idOrAll]);
      }
    };
  };

  const batchDeleteRuns = (): void => {
    const notice = batchForceAll ? i18n("deletion_pending") : i18n("deleted_successfully");
    const notificationOptions = { notice, noticeType: "success" };
    dispatch(batchDeleteWorkflowRuns(urlEventId(), selectedWorkflowId, selectedStatuses, selectedStartDate, selectedEndDate, batchForceAll ? [] : selectedIds, notificationOptions));
  };

  const batchReplayRuns = (): void => {
    const notice = batchForceAll ? i18n("replay_pending") : i18n("replayed_successfully");
    const notificationOptions = { notice, noticeType: "success" };
    dispatch(batchReplayWorkflowRuns(urlEventId(), selectedWorkflowId, selectedStatuses, selectedStartDate, selectedEndDate, batchForceAll ? [] : selectedIds, notificationOptions));
  };

  const renderWorkflowsDropdown = (): JSX.Element => {
    return <WorkflowsDropdown
      selectedValue={selectedWorkflowId}
      onChange={changeSelectedWorkflow}
    />;
  };

  const renderStatusesDropdown = (): JSX.Element => {
    return <WorkflowStatusDropdown
      selectedValues={selectedStatuses}
      onChange={changeSelectedStatuses}
    />;
  };

  const renderDateRangePicker = (): JSX.Element => {
    return <DateRangePicker
      startDate={selectedStartDate ? moment(selectedStartDate) : null}
      endDate={selectedEndDate ? moment(selectedEndDate) : null}
      onDateRangeChanged={changeSelectedDateRange}
      calendarPlacement="bottom"
    />;
  };

  const renderWorkflowRunStatus = (workflowRun: WorkflowRun): JSX.Element => {
    const config = WORKFLOW_RUN_STATUSES[workflowRun.status];
    const label = I18n.t(`workflow_run_status.${workflowRun.status}`);

    return <span className={`badge rounded-pill ${config.backgroundClass}`}>
      <i className={`fa fa-regular fa-${config.iconClass}`}></i> {label}
    </span>;
  };

  const renderWorkflowRunsCounter = (): JSX.Element => {
    const workflowRunsCount = workflowRuns?.length || 0;

    let currentCounter: JSX.Element;
    if (previousPageNumber || nextPageNumber) {
      const currentPageNumber = previousPageNumber && previousPageNumber + 1 || nextPageNumber && nextPageNumber - 1;
      const startCounter = (currentPageNumber - 1) * WORKFLOW_RUNS_PER_PAGE + 1;
      const endCounter = (currentPageNumber - 1) * WORKFLOW_RUNS_PER_PAGE + workflowRunsCount;
      currentCounter = <span>{`${startCounter} - ${endCounter} ${I18n.t("paginate_of")}`}</span>;
    }

    let totalCounter: JSX.Element;
    if (currentCounter) {
      if (!countedAll && parseInt(totalCount) === 1000) {
        totalCounter = <a href="#" onClick={fetchTotalCount}>
          <span className="ml-5" dangerouslySetInnerHTML={{ __html: i18n("workflow_runs_count", { count: I18n.t("react.reports.a_large_number") }) }}></span>
        </a>;
      } else {
        totalCounter = <span className="ml-5" dangerouslySetInnerHTML={{ __html: i18n("workflow_runs_count", { count: totalCount }) }}></span>;
      }
    } else {
      totalCounter = <span className="ml-5" dangerouslySetInnerHTML={{ __html: i18n("workflow_runs_count", { count: workflowRunsCount }) }}></span>;
    }

    return <div className="count">
      {currentCounter}
      {totalCounter}
    </div>;
  };

  const renderPagination = (): JSX.Element => {
    return <Paginate
      handlePrevious={onPaginate(previousPageNumber)}
      handleNext={onPaginate(nextPageNumber)}
      previousEnabled={!!previousPageNumber}
      nextEnabled={!!nextPageNumber}
      pull="end"
    />;
  };

  const renderButtonsOnSelectedIds = (): JSX.Element => {
    if (selectedIds.length === 0) return;

    return <>
      <span className="me-2"><strong>{i18n("selected_workflow_runs_count", { count: selectedIds.length })}</strong></span>
      <button className="btn btn-secondary me-1" onClick={(): void => setReplayModalVisible(true)}>
        <i className="fa-regular fa-refresh" aria-hidden="true"></i> {i18n("replay")}
      </button>
      { isSuperAdmin() &&
        <button className="btn btn-danger" onClick={(): void => setDeleteModalVisible(true)}>
          <i className="fa-regular fa-trash-can" aria-hidden="true"></i> {i18n("delete")}
        </button>
      }
    </>;
  };

  const batchForceAllCount = (): string => {
    if ((previousPageNumber || nextPageNumber) && parseInt(totalCount) === WORKFLOW_RUNS_PER_PAGE) {
      return `${WORKFLOW_RUNS_PER_PAGE}+`;
    }
    return totalCount;
  };

  const renderReplayModal = (): JSX.Element => {
    return <BatchActionConfirmationModal
      isVisible={replayModalVisible}
      title={i18n("replay_confirmation_title", { count: batchForceAll ? batchForceAllCount() : selectedIds.length })}
      applyAllText={i18n("batch_apply_all", { count: batchForceAllCount() })}
      applyAllEnabled={selectedIds.length === WORKFLOW_RUNS_PER_PAGE && !!(nextPageNumber || previousPageNumber)}
      batchForceAll={batchForceAll}
      isPendingBatchRequest={isPendingBatchRequest}
      onClose={(): void => {
        setReplayModalVisible(false);
        setBatchForceAll(false);
      }}
      onConfirm={batchReplayRuns}
      confirmationQuestion={i18n("replay_confirmation_text")}
      confirmationWarning={i18n("replay_warning_text")}
      toggleForceAll={(checked: boolean): void => setBatchForceAll(checked)}
    />;
  };

  const renderDeleteModal = (): JSX.Element => {
    return <BatchActionConfirmationModal
      isVisible={deleteModalVisible}
      title={i18n("delete_confirmation_title", { count: batchForceAll ? batchForceAllCount() : selectedIds.length })}
      applyAllText={i18n("batch_apply_all", { count: batchForceAllCount() })}
      applyAllEnabled={selectedIds.length === WORKFLOW_RUNS_PER_PAGE && !!(nextPageNumber || previousPageNumber)}
      batchForceAll={batchForceAll}
      isPendingBatchRequest={isPendingBatchRequest}
      onClose={(): void => {
        setDeleteModalVisible(false);
        setBatchForceAll(false);
      }}
      onConfirm={batchDeleteRuns}
      confirmationQuestion={i18n("delete_confirmation_text")}
      confirmationDanger={i18n("delete_warning_text")}
      toggleForceAll={(checked: boolean): void => setBatchForceAll(checked)}
    />;
  };

  const renderWorkflowRunsList = (): JSX.Element => {
    return <>
      <div className="row">
        <div className="col-sm-6">{renderButtonsOnSelectedIds()}</div>
        <div className="col-sm-6">
          <div className="collection-pagination">
            {renderWorkflowRunsCounter()}
            {renderPagination()}
          </div>
        </div>
      </div>
      <div className="table-container">
        <table className="table table-light table-bordered">
          <thead>
            <tr>
              <th className="bulk-cell-react d-none d-sm-table-cell">
                <input type="checkbox" className="form-check-input" checked={selectedIds.length === workflowRuns.length} onChange={toggleWorkflowRun("all")}/>
              </th>
              <th>{i18n("workflow_name")}</th>
              <th>{i18n("status")}</th>
              <th>{i18n("triggered_at")}</th>
            </tr>
          </thead>
          <tbody>
            {workflowRuns.map((workflowRun: WorkflowRun) => {
              return <tr key={workflowRun._id}>
                <td className="bulk-cell-react" style={{ maxWidth: "100px" }}>
                  <input type="checkbox" className="form-check-input" checked={selectedIds.includes(workflowRun._id)} onChange={toggleWorkflowRun(workflowRun._id)}/>
                </td>
                <td>
                  <Link to={`${pathToWorkflowRun(workflowRun._id)}${location.search}`}>{workflowRun.workflow_name || "-"}</Link>
                  <span className="badge rounded-pill bg-secondary ms-2">{i18n("version_number", { number: workflowRun.workflow_version_number })}</span>
                </td>
                <td>{renderWorkflowRunStatus(workflowRun)}</td>
                <td>{moment(workflowRun.triggered_at).format("LL")} {moment(workflowRun.triggered_at).format("LTS")}</td>
              </tr>;
            })}
          </tbody>
        </table>
      </div>
    </>;
  };

  const renderNoWorkflowRuns = (): JSX.Element => {
    return <div className="card nothing-yet">
      <h4>{i18n("no_workflow_runs")}</h4>
    </div>;
  };

  const renderBackButton = (): JSX.Element => {
    return <Link to="workflows">
      <i className="fa-regular fa-chevron-left fa-fw fa-xs"></i>
    </Link>;
  };

  if (!workflowRuns) {
    return <Loader size="large" inline={false} containerHeight="100%" />;
  }

  return <div className="row workflow-runs-index">
    <div className="col-12">
      <div className="header-page">
        <div className="header-page-content row">
          <div className="header-page-title col-sm-9">
            <h1>
              {renderBackButton()}
              {i18n("title")}
            </h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 mb-2">{renderWorkflowsDropdown()}</div>
        <div className="col-lg-4 mb-2">{renderStatusesDropdown()}</div>
        <div className="col-lg-4 mb-2">{renderDateRangePicker()}</div>
      </div>
      { workflowRuns.length > 0 ? renderWorkflowRunsList() : renderNoWorkflowRuns() }
      { renderReplayModal() }
      { renderDeleteModal() }
    </div>
  </div>;
};

export default WorkflowRuns;
