import { Component, MouseEvent } from "react";
import { connect } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import pick from "lodash/pick";
import moment from "moment";
import querystring from "querystring";
import DatePicker from "../../components/shared/DatePicker.react";
import {
  EVENT_DEFAULT_PRIMARY_COLOR,
  EVENT_DEFAULT_SECONDARY_COLOR,
  URLS_DOC_VERTICALS,
  URLS_DOC_STARTER_KIT_FEATURES
} from "../../constants/Constants";
import FrontendLocales from "../../../config/frontend_locales.yml";
import { urlEventLocale } from "../../utils/pathUtils";
import { hoursOptions, minutesOptions } from "../../utils/DateUtils.js";
import EventDuplication from "./EventDuplication.react";
import WaitingForEventDuplication from "./WaitingForEventDuplication.react";
import Loader from "../../components/shared/Loader.react";
import CountrySelect from "../../components/shared/CountrySelect.react";
import TimezoneSelect from "../../components/shared/TimezoneSelect.react";
import ColorPicker from "../../components/ColorPicker.react";
import ImageUploader from "../../components/shared/ImageUploader.react";
import { fetchPreconfiguredEvents } from "../../actions/PreconfiguredEventsActionCreators";
import { fetchCustomPreconfiguredEvents } from "../../actions/CustomPreconfiguredEventsActionCreators";
import { reloadTheme } from "../../actions/WebsiteActionCreators";
import IMAGE_URLS from "../../constants/ImageUrls";
import { PreconfiguredEvent } from "../../types/PreconfiguredEvent";
import PreconfiguredEventSelector from "../../components/PreconfiguredEventSelector.react";

interface Props {
  match: any;
  location: any;
  history: any;
  preconfiguredEvents: PreconfiguredEvent[],
  preconfiguredEventsFetched: boolean;
  preconfiguredEventsPendingRequest: boolean;
  customPreconfiguredEvents: PreconfiguredEvent[],
  customPreconfiguredEventsFetched: boolean;
  customPreconfiguredEventsPendingRequest: boolean;
  fetchCustomPreconfiguredEvents(accountId: string, searchParams: string): any;
  fetchPreconfiguredEvents(accountId: string, searchParams: string): any;
  reloadTheme(eventId: string, projectRootPath: string, options: any): any;
}

interface State {
  step: string;
  creationType: string;
  error: string;
  event: any;
  overrides: any;
  hasDuplicationErrors: boolean;
  hasDuplicationWarnings: boolean;
  newEventId: string;
  themeName: string;
  sourceEventId: string;
  customStarterKit: boolean;
}

function i18n(key, params = {}): string {
  return I18n.t(`react.events.creation_steps.${key}`, params);
}

const ORDERED_STEPS = [
  "creation_mode",
  "environment",
  "type",
  "recurrence",
  "starter_kit_detection",
  "starter_kit_list",
  "informations",
  "location",
  "visual_identity",
  "creation",
  "finalization"
];

const GROUPS_OF_STEPS = {
  types: ["environment", "starter_kit_detection", "type", "recurrence"],
  starting_point: ["starter_kit_list"],
  configuration: ["informations", "location", "visual_identity"],
  finalization: ["creation", "finalization", "duplication_source_event"]
};

class EventCreationSteps extends Component<Props, State> {

  constructor(props) {
    super(props);
    [
      "clickEventType",
      "clickEventEnvironment",
      "clickCreationType",
      "setRecurrence",
      "clickPreviousStep",
      "changeStandardField",
      "changeStartDateSelector",
      "changeEndDateSelector",
      "onImageUploaded",
      "changeStartTime",
      "changeEndTime",
      "changeFile",
      "changeColorField",
      "clickNextStep",
      "onDuplicationDone",
      "onPreconfiguredEventSelected"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    const startHour = moment().hours() + 1;
    const endHour = startHour < 18 ? 18 : startHour + 1;

    this.state = {
      step: this.defaultStep(),
      creationType: null,
      error: null,
      event: {
        environments: null,
        types: null,
        has_recurring_events: false,
      },
      customStarterKit: null,
      overrides: {
        title: "",
        type: null,
        environment: null,
        address: "",
        country: "FRA",
        locale: I18n.locale,
        timezone: "Paris",
        multiTimeZone: false,
        startDate: new Date(),
        endDate: new Date(),
        startHour,
        startMinute: 0,
        endHour,
        endMinute: 0,
        momentStartDate: moment().hours(startHour).minutes(0),
        momentEndDate: moment().hours(endHour).minutes(0),
        primaryColor: EVENT_DEFAULT_PRIMARY_COLOR,
        secondaryColor: EVENT_DEFAULT_SECONDARY_COLOR,
        logo: null,
        banner: null
      },
      hasDuplicationErrors: false,
      hasDuplicationWarnings: false,
      newEventId: null,
      themeName: null,
      sourceEventId: null,
    };
  }

  defaultStep(): string {
    const { location } = this.props;
    const query = querystring.parse(location.search.substring(1));

    if (query.duplication_operation_id)
      return "duplication_source_event";

    return "creation_mode";
  }

  componentDidMount(): void {
    const { fetchCustomPreconfiguredEvents, match } = this.props;
    const { event } = this.state;

    fetchCustomPreconfiguredEvents(match.params.account_id, event);
  }

  componentDidUpdate(prevProps): void {
    const { preconfiguredEvents, preconfiguredEventsFetched } = this.props;

    if (preconfiguredEventsFetched && !prevProps.preconfiguredEventsFetched && preconfiguredEvents) {
      if (preconfiguredEvents.length === 1) {
        this.setState({ sourceEventId: preconfiguredEvents[0].event_id }, () => this.clickNextStep());
      } else if (preconfiguredEvents.length > 1) {
        this.clickNextStep();
      }
    }
  }

  clickEventEnvironment(e: MouseEvent<HTMLInputElement>): void {
    const { event, overrides } = this.state;
    const value = e.currentTarget.value;

    this.setState({
      event: { ...event, environments: value },
      overrides: { ...overrides, environment: value },
      step: this.nextStep(),
    });
  }

  clickEventType(e: MouseEvent<HTMLInputElement>): void {
    const { event, overrides } = this.state;
    const value = e.currentTarget.value;

    this.setState({
      event: { ...event, types: value },
      overrides: { ...overrides, type: value },
      step: this.nextStep(),
    });
  }

  clickCreationType(e: MouseEvent<HTMLInputElement>): void {
    const { match } = this.props;
    const creationType = e.currentTarget.value;

    if (creationType === "manual") {
      window.location.href = `/${urlEventLocale()}/accounts/${match.params.account_id}/events/new`;
    } else {

      this.setState({
        creationType,
        customStarterKit: creationType === "custom_starter_kit"
      }, () => this.setState({
        step: this.nextStep()
      }, this.nextStepAction)
      );
    }
  }

  setRecurrence(recurrence: boolean): void {
    const { event } = this.state;

    this.setState({
      event: { ...event, has_recurring_events: recurrence },
      step: this.nextStep(),
    }, this.nextStepAction);
  }

  resetSelection(): void {
    this.setState({
      sourceEventId: null,
    });
  }

  nextStepAction(): void {
    const { step } = this.state;

    switch (step) {
    case "environment": {
      this.resetSelection();

      break;
    }
    case "starter_kit_detection": {
      const { fetchPreconfiguredEvents, match } = this.props;
      const { event } = this.state;

      fetchPreconfiguredEvents(match.params.account_id, event);
      break;
    }
    case "finalization": {
      const { newEventId } = this.state;
      const { reloadTheme } = this.props;

      reloadTheme(newEventId, null, { redirectTo: `/${I18n.locale}/r/events/${newEventId}/features` });
      break;
    }
    }
  }

  changeStandardField(field: string): (e) => void {
    return (e): void => {
      const { overrides } = this.state;
      this.setState({ overrides: { ...overrides, [field]: e.target.value } });
    };
  }

  changeStartDateSelector(value: string): void {
    const { overrides } = this.state;
    const momentValue = moment(value);
    const startDate = momentValue.format(I18n.t("datetime_picker_js_format"));
    const momentStartDate = momentValue.clone().hours(overrides.startHour).minutes(overrides.startMinute);

    if (momentStartDate > overrides.momentEndDate) {
      const endDate = momentValue.format(I18n.t("datetime_picker_js_format"));
      const momentEndDate = momentStartDate.clone().hours(overrides.endHour).minutes(overrides.endMinute);
      this.setState({ overrides: { ...overrides, startDate, momentStartDate, endDate, momentEndDate } });
    } else {
      this.setState({ overrides: { ...overrides, startDate, momentStartDate } });
    }
  }

  changeEndDateSelector(value: string): void {
    const { overrides } = this.state;
    const momentValue = moment(value);
    const endDate = momentValue.format(I18n.t("datetime_picker_js_format"));
    const momentEndDate = momentValue.clone().hours(overrides.endHour).minutes(overrides.endMinute);
    this.setState({ overrides: { ...overrides, endDate, momentEndDate } });
  }

  changeStartTime(e): void {
    const { overrides } = this.state;
    const newEventState = { ...overrides, [e.target.name]: e.target.value };
    const { startDate, startHour, startMinute } = newEventState;
    newEventState.momentStartDate = moment(startDate, I18n.t("datetime_picker_js_format")).hours(startHour).minutes(startMinute);
    this.setState({ overrides: newEventState });
  }

  changeEndTime(e): void {
    const { overrides } = this.state;
    const newEventState = { ...overrides, [e.target.name]: e.target.value };
    const { endDate, endHour, endMinute } = newEventState;
    newEventState.momentEndDate = moment(endDate, I18n.t("datetime_picker_js_format")).hours(endHour).minutes(endMinute);
    this.setState({ overrides: newEventState });
  }

  changeFile(field: string): (e) => void {
    return (e): void => {
      const { overrides } = this.state;
      this.setState({ overrides: { ...overrides, [field]: e.target.files[0] } });
    };
  }

  changeCheckbox(field: string): (e) => void {
    return (e): void => {
      const { overrides } = this.state;
      this.setState({ overrides: { ...overrides, [field]: e.target.checked } });
    };
  }

  onImageUploaded(key: string): (_, imgUrl: string) => void {
    return (_, imgUrl: string): void => {
      const { overrides } = this.state;

      this.setState({ overrides: { ...overrides, [key]: imgUrl } });
    };
  }

  s3UploadURL(): string {
    const { match } = this.props;
    return `/api/v1/accounts/${match.params.account_id}/events/s3_upload_url.json`;
  }

  renderImageInput(stateKey: string): JSX.Element {
    const url = this.state.overrides[stateKey];

    return <div className="mb-3">
      <label className="form-label">{ i18n(`event_${stateKey}`) }</label>

      <ImageUploader
        url={ url }
        s3UploadUrl={this.s3UploadURL()}
        onFinishUpload={this.onImageUploaded(stateKey)}
        field={`event_${stateKey}`}
      />
    </div>;
  }

  changeColorField(field: string): (color: string) => void {
    return (color: string): void => {
      const { overrides } = this.state;
      this.setState({ overrides: { ...overrides, [field]: color } });
    };
  }

  onPreconfiguredEventSelected(eventId: string): void {
    this.setState({
      sourceEventId: eventId,
    });
    this.clickNextStep();
  }

  clickNextStep(): void {
    if (!this.validStep()) return null;

    this.setState({
      step: this.nextStep(),
    }, this.nextStepAction);
  }

  allowedLocales(): string[] {
    return FrontendLocales.map(l => l.code);
  }

  validStep(): boolean {
    const { overrides, step } = this.state;
    let errorMessage = null;

    switch (step) {
    case "informations":
      if (overrides.title === "")
        errorMessage = i18n("error_title");
      if (overrides.momentEndDate <= overrides.momentStartDate || overrides.momentStartDate <= moment())
        errorMessage = i18n("error_dates");
      break;
    case "location":
      if (overrides.country === "")
        errorMessage = i18n("error_country");
      else if (overrides.timezone === "")
        errorMessage = i18n("error_timezone");
      else if (!this.allowedLocales().includes(overrides.locale))
        errorMessage = i18n("error_locale");
      break;
    }

    if (errorMessage) {
      this.setState({ error: errorMessage });
      return false;
    }

    this.setState({ error: null });
    return true;
  }

  nextStep(): string {
    const { step, creationType } = this.state;
    const { preconfiguredEvents } = this.props;

    switch (step) {
    case "creation_mode":
      if (creationType === "duplication") {
        return "duplication_source_event";
      } else if (creationType == "custom_starter_kit") {
        return "starter_kit_list";
      } else {
        return "environment";
      }
    case "starter_kit_detection":
      if (preconfiguredEvents.length === 1) return "informations";
      if (preconfiguredEvents.length > 1) return "starter_kit_list";

      return null;
    default: {
      const position = ORDERED_STEPS.indexOf(step);
      if (position === -1 || position === ORDERED_STEPS.length - 1) return null;

      return ORDERED_STEPS[position + 1];
    }
    }
  }

  clickPreviousStep(): void {
    const { step } = this.state;
    this.setState({ step: this.previousStep(), error: null });

    if (step === "duplication_source_event") {
      const { history, location } = this.props;
      history.push({ ...location, search: "" });
    }
  }

  previousStep(): string {
    const { step, customStarterKit } = this.state;

    if (step === "starter_kit_list") {
      return customStarterKit ? "creation_mode" : "recurrence";
    } else if (step === "duplication_source_event") {
      return "creation_mode";
    } else {
      const position = ORDERED_STEPS.indexOf(step);
      if (position === -1 || position === 0) return null;

      return ORDERED_STEPS[position - 1];
    }
  }

  renderStep(): JSX.Element {
    const { step } = this.state;
    const { match } = this.props;
    const { sourceEventId } = this.state;

    switch (step) {
    case "environment":
      return this.renderEventEnvironmentQuestion();
    case "type":
      return this.renderEventTypeQuestion();
    case "recurrence":
      return this.renderEventRecurrenceQuestion();
    case "starter_kit_list":
      return this.renderPreconfiguredEventSelector();
    case "creation_mode":
      return this.renderCreationModeQuestion();
    case "duplication_source_event":
      return this.renderSourceEventDropdown();
    case "starter_kit_detection":
      return this.renderStarterKitDetection();
    case "informations":
      return this.renderEventInformationsQuestion();
    case "location":
      return this.renderEventLocationQuestion();
    case "visual_identity":
      return this.renderEventVisualIdentityQuestion();
    case "creation":
      return <WaitingForEventDuplication sourceEventId={sourceEventId} overrides={this.duplicationOperationOverrides()} onDuplicationDone={this.onDuplicationDone} match={match}>
        { this.renderEventCreationStep() }
      </WaitingForEventDuplication>;
    case "finalization":
      return this.renderEventFinalizationLoader();
    }
  }

  onDuplicationDone(newEventId: string, hasErrors: boolean, hasWarnings: boolean): void {
    const nextState = { hasDuplicationErrors: hasErrors, hasDuplicationWarnings: hasWarnings, newEventId } as Pick<State, "hasDuplicationErrors" | "hasDuplicationWarnings" | "newEventId" | "step">;

    if (!hasErrors && !hasWarnings) {
      nextState.step = this.nextStep();
    }

    this.setState(nextState, this.nextStepAction);
  }

  duplicationOperationOverrides(): any {
    const { overrides } = this.state;
    const { title, type, environment, address, momentStartDate, momentEndDate, primaryColor, secondaryColor, logo, banner, multiTimeZone } = overrides;

    return {
      type,
      environment,
      title,
      address,
      start_date: momentStartDate,
      end_date: momentEndDate,
      primary_color: primaryColor,
      secondary_color: secondaryColor,
      remote_photo_url: logo,
      remote_cover_url: banner,
      multi_time_zone: multiTimeZone,
    };
  }

  renderEventEnvironmentQuestion(): JSX.Element {
    const radios = ["virtual", "physical", "hybrid"].map(env => this.renderEventEnvironmentRadio(env));
    const { step } = this.state;

    return (
      <div key={ step } className="vertical-center enter-animation">
        <h2 className="event-assistant-title">{ i18n("format_title") }</h2>
        <p>{ i18n("format_description") }</p>
        <div>
          <div className="row">
            { radios }
          </div>
        </div>
      </div>
    );
  }

  renderEventEnvironmentRadio(environment): JSX.Element {
    return <div className="col-lg-4 col-md-6 col-sm-6" key={environment}>
      <div className="card">
        <img src={IMAGE_URLS[environment]} className="img-fluid" width="500" height="375"/>
        <div className="card-body">
          <div className="h2 mt-0 mb-15">{I18n.t(environment)}</div>
          <input type="radio" className="btn-check" name="options" id={environment} value={environment} onClick={this.clickEventEnvironment} />
          <label className="btn btn-primary d-grid" htmlFor={environment}>{i18n("select")}</label>
        </div>
      </div>
    </div>;
  }

  renderEventTypeRadio(eventType): JSX.Element {
    return <div className="col-lg-4 col-md-6 col-sm-6" key={eventType}>
      <div className="d-flex flex-column h-100">
        <div className="card d-flex flex-column h-100">
          <img src={IMAGE_URLS[eventType]} className="img-fluid" width="500" height="375"/>
          <div className="card-body d-flex flex-column flex-grow-1">
            <div className="h2 mt-0">{I18n.t(eventType)}</div>
            <div className="flex-grow-1">
              <p className="mb-10">{i18n(`${eventType}_description`)}</p>
              { URLS_DOC_VERTICALS[eventType] && <a className="mb-10" style={{ "display": "inline-block" }} href={URLS_DOC_VERTICALS[eventType]} target="_blank">{i18n("see_more")}</a> }
            </div>
            <input type="radio" className="btn-check" name="options" id={eventType} value={eventType} onClick={this.clickEventType} />
            <label className="btn btn-primary d-grid" htmlFor={eventType}>{i18n("select")}</label>
          </div>
        </div>
      </div>
    </div>;
  }

  renderEventTypeQuestion(): JSX.Element {
    const radios = ["tradeshow", "congress", "corporate", "agency", "education"].map(eventType => this.renderEventTypeRadio(eventType));
    const { step } = this.state;

    return (
      <div key={ step } className="vertical-center enter-animation">
        <h2 className="event-assistant-title">{ i18n("type_title") }</h2>
        <p>{ i18n("type_description") }</p>
        <div>
          <div className="row">
            { radios }
          </div>
        </div>
      </div>
    );
  }

  renderEventRecurrenceQuestion(): JSX.Element {
    const { step } = this.state;
    return (
      <div key={ step } className="vertical-center enter-animation">
        <h2 className="event-assistant-title">{ i18n("recurrence_title") }</h2>
        <p>{ i18n("recurrence_description") }</p>

        <div className="recurrence-buttons">
          <input type="radio" className="btn-check" value="one_shot_event" name="options" id="one_shot_event" onClick={(): void => this.setRecurrence(false)} />
          <label className="btn btn-secondary btn-lg" htmlFor="one_shot_event">{i18n("one_shot_event")}</label>
          <input type="radio" className="btn-check" value="series_of_events" name="options" id="series_of_events" onClick={(): void => this.setRecurrence(true)} />
          <label className="btn btn-secondary btn-lg ms-3" htmlFor="series_of_events">{i18n("series_of_events")}</label>
        </div>
      </div>
    );
  }

  renderCreationModeQuestion(): JSX.Element {
    const { step } = this.state;
    const { customPreconfiguredEvents } = this.props;

    return (
      <div key={ step } className="vertical-center enter-animation">
        <h2 className="event-assistant-title">{i18n("creation_mode.title")}</h2>
        <p>{i18n("creation_mode.description")}</p>

        <div>
          <div className="row">
            <div className="col-sm-4 mb-4">
              <input type="radio" className="btn-check" value="starter_kit" name="options" id="starter_kit" onClick={this.clickCreationType} />
              <label className="btn btn-secondary d-flex align-items-center justify-content-center create" htmlFor="starter_kit">
                <div className="d-flex flex-column">
                  <i className="fa-regular fa-wand-magic-sparkles fa-5x" style={{ "display": "block" }}></i>
                  <span className="h4 mt-10">{i18n("creation_mode.starter_kit")}</span>
                </div>
              </label>
            </div>
            {customPreconfiguredEvents && customPreconfiguredEvents.length > 0 &&
              <div className="col-sm-4 mb-4">
                <input type="radio" className="btn-check" value="custom_starter_kit" name="options" id="custom_starter_kit" onClick={this.clickCreationType} />
                <label className="btn btn-secondary d-flex align-items-center justify-content-center create" htmlFor="custom_starter_kit">
                  <div className="d-flex flex-column">
                    <i className="fa-regular fa-bolt fa-5x" style={{ "display": "block" }}></i>
                    <span className="h4 mt-10">{i18n("creation_mode.custom_starter_kit")}</span>
                  </div>
                </label>
              </div>
            }
            <div className="col-sm-4 mb-4">
              <input type="radio" className="btn-check" value="duplication" name="options" id="duplication" onClick={this.clickCreationType} />
              <label className="btn btn-secondary d-flex align-items-center justify-content-center create" htmlFor="duplication">
                <div className="d-flex flex-column">
                  <i className="fa-regular fa-clone fa-5x" style={{ "display": "block" }}></i>
                  <span className="h4 mt-10">{i18n("creation_mode.duplication")}</span>
                </div>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-auto">
              <input type="radio" className="btn-check" value="manual" name="options" id="manual" onClick={this.clickCreationType } />
              <label className="btn btn-secondary btn-lg" htmlFor="manual"> { i18n("creation_mode.manual") }</label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSourceEventDropdown(): JSX.Element {
    const { match, location, history } = this.props;
    const { overrides, step } = this.state;
    const pickedOverrides = pick(overrides, ["type"]);

    return (
      <div key={ step } className="row enter-animation">
        <div className="col-sm-12">
          <EventDuplication match={match} location={location} history={history} overrides={pickedOverrides} />
        </div>
      </div>
    );
  }

  renderDocumentationLink(field: string): JSX.Element {
    const link = URLS_DOC_STARTER_KIT_FEATURES[field];

    if (!link) return null;

    return <a href={ URLS_DOC_STARTER_KIT_FEATURES[field] } target="_blank">
      { i18n("see_more") }
    </a>;
  }

  renderManuallyCreateEventButton(): JSX.Element {
    const { match } = this.props;

    return <div>
      <a href={`/${I18n.locale}/accounts/${match.params.account_id}/events/new`} className="btn btn-primary btn-lg mt-30">
        { i18n("create_event_from_scratch") }
      </a>
    </div>;
  }

  renderStarterKitDetection(): JSX.Element {
    const { preconfiguredEventsFetched } = this.props;
    const { sourceEventId, step } = this.state;

    if (!preconfiguredEventsFetched) {
      return <Loader size="large" inline={false} containerHeight="500px" message={ i18n("detecting_starter_kit") }/>;
    } else if (!sourceEventId) {
      return <div key={ step } className="text-center vertical-center enter-animation">
        <div>{ i18n("no_corresponding_starter_kit") }</div>
        { this.renderManuallyCreateEventButton() }
      </div>;
    } else {
      return null;
    }
  }

  renderPreconfiguredEventSelector(): JSX.Element {
    const { step, customStarterKit } = this.state;
    const { customPreconfiguredEvents, preconfiguredEvents } = this.props;
    return (
      <div key={ step } className="vertical-center enter-animation">
        <h2 className="event-assistant-title">{ i18n("preconfigured_event_selector_title") }</h2>
        <p>{ i18n(customStarterKit ? "custom_preconfigured_event_selector_description" : "preconfigured_event_selector_description") }</p>
        <PreconfiguredEventSelector preconfiguredEvents={customStarterKit ? customPreconfiguredEvents : preconfiguredEvents} onSelected={this.onPreconfiguredEventSelected} />
      </div>
    );
  }

  renderEventInformationsQuestion(): JSX.Element {
    const { overrides, step } = this.state;
    const formattedStartDate = overrides.momentStartDate.format();
    const formattedEndDate = overrides.momentEndDate.format();

    return (
      <div key={ step } className="vertical-center enter-animation with-btn">
        <h2 className="event-assistant-title">{ i18n("informations_title") }</h2>
        <p>{ i18n("informations_description") }</p>
        <div className="mb-3">
          <label className="form-label">{ i18n("event_title") }</label>
          <input type="text" className="form-control form-control-lg" value={overrides.title} onChange={this.changeStandardField("title")} />
        </div>
        <div className="mb-3">
          <label className="form-label">{ i18n("event_start_date") }</label>
          <div className="row g-2 align-items-center datepicker-group">
            <div className="col-auto" style={{ minWidth: "230px" }}>
              <DatePicker
                selectedDate={new Date(formattedStartDate)}
                onChange={this.changeStartDateSelector}
                className="form-control-lg"
              />
            </div>
            <div className="col-auto">
              <select name="startHour" className="form-select form-select-lg" onChange={this.changeStartTime} value={overrides.startHour}>
                {hoursOptions()}
              </select>
            </div>
            <div className="col-auto">
              <select name="startMinute" className="form-select form-select-lg" onChange={this.changeStartTime} value={overrides.startMinute}>
                {minutesOptions()}
              </select>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label">{ i18n("event_end_date") }</label>
          <div className="row g-2 align-items-center datepicker-group">
            <div className="col-auto" style={{ minWidth: "230px" }}>
              <DatePicker
                selectedDate={new Date(formattedEndDate)}
                onChange={this.changeEndDateSelector}
                className="form-control-lg"
              />
            </div>
            <div className="col-auto">
              <select name="endHour" className="form-select form-select-lg" onChange={this.changeEndTime} value={overrides.endHour}>
                {hoursOptions()}
              </select>
            </div>
            <div className="col-auto">
              <select name="endMinute" className="form-select form-select-lg" onChange={this.changeEndTime} value={overrides.endMinute}>
                {minutesOptions()}
              </select>
            </div>
          </div>
        </div>
        { this.renderError() }
        <button className="btn btn-primary btn-lg btn-submit mt-10" onClick={this.clickNextStep}>{ I18n.t("validate") }</button>
      </div>
    );
  }

  renderEventLocationQuestion(): JSX.Element {
    const { overrides, step } = this.state;

    return (
      <div key={ step } className="vertical-center enter-animation with-btn">
        <h2 className="event-assistant-title">{ i18n("informations_2_title") }</h2>
        <p>{ i18n("informations_2_description") }</p>
        <div className="mb-3 row">
          <div className="col-sm-12">
            <label className="form-label">{ i18n("event_address") }</label>
            <textarea className="form-control form-control-lg" rows={3} defaultValue={overrides.address} onChange={this.changeStandardField("address")}></textarea>
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-sm-6">
            <label className="form-label">{ i18n("event_country") }</label>
            <CountrySelect value={overrides.country} locale={I18n.locale} className="form-select form-select-lg" onChange={this.changeStandardField("country")} />
          </div>
          <div className="col-sm-6">
            <label className="form-label">{ i18n("event_timezone") }</label>
            <TimezoneSelect value={overrides.timezone} className="form-select form-select-lg" onChange={this.changeStandardField("timezone")} />
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-sm-6">
            <label className="form-label">{ i18n("event_locale") }</label>
            <select value={overrides.locale} className="form-select form-select-lg" onChange={this.changeStandardField("locale")}>
              { FrontendLocales.map(locale => {
                return <option key={locale.code} value={locale.code}>{ locale[I18n.locale] }</option>;
              })}
            </select>
          </div>
          <div className="col-sm-6">
            <div className="form-check">
              <label className="form-check-label">
                <input type="checkbox" className="form-check-input" checked={overrides.multiTimeZone} name="multi_time_zone" onChange={this.changeCheckbox("multiTimeZone")}/>
                { i18n("multi_time_zone") }
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{ i18n("multi_time_zone_info") }</Tooltip>}>
                  <i className="fa-regular fa-info-circle ml-5"></i>
                </OverlayTrigger>
              </label>
            </div>
          </div>
        </div>
        { this.renderError() }
        <button className="btn btn-primary btn-lg btn-submit" onClick={this.clickNextStep}>{ I18n.t("validate") }</button>
      </div>
    );
  }

  renderEventVisualIdentityQuestion(): JSX.Element {
    const { overrides, step } = this.state;

    return (
      <div key={ step } className="vertical-center enter-animation with-btn">
        <h2 className="event-assistant-title">{ i18n("visual_identity_title") }</h2>
        <p>{ i18n("visual_identity_description") }</p>
        { this.renderImageInput("logo")}
        { this.renderImageInput("banner")}
        <div className="row mt-10">
          <div className="col-sm-6">
            <div className="mb-3">
              <label className="form-label">{ i18n("event_primary_color") }</label>
              <div><ColorPicker setColor={this.changeColorField("primaryColor")} selectedColor={overrides.primaryColor} placement="top" displayValue={true}/></div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label className="form-label">{ i18n("event_secondary_color") }</label>
              <div><ColorPicker setColor={this.changeColorField("secondaryColor")} selectedColor={overrides.secondaryColor} placement="top" displayValue={true}/></div>
            </div>
          </div>
        </div>
        <div className="pull btn-submit">
          <button className="btn btn-primary btn-lg" onClick={this.clickNextStep}>{ I18n.t("validate") }</button>
          <button className="btn btn-secondary btn-lg ml-10" onClick={this.clickNextStep}>{ i18n("ignore") }</button>
        </div>
      </div>
    );
  }

  renderEventCreationStep(): JSX.Element {
    const { hasDuplicationErrors, hasDuplicationWarnings } = this.state;

    if (hasDuplicationErrors) {
      return <div className="text-center">{ this.renderManuallyCreateEventButton() }</div>;
    }

    if (hasDuplicationWarnings) {
      return <div className="text-center">
        <button className="btn btn-primary btn-lg mt-30" onClick={this.clickNextStep}>{ i18n("go_to_next_step") }</button>
      </div>;
    }

    return <div className="vertical-center">
      <Loader size="large" inline={false} containerHeight="500px" message={ i18n("event_creation_in_progress") } />
    </div>;
  }

  renderEventFinalizationLoader(): JSX.Element {
    return <div className="vertical-center">
      <Loader size="large" inline={false} containerHeight="500px" message={i18n("finalization")} />
    </div>;
  }

  renderError(): JSX.Element {
    const { error } = this.state;

    if (!error)
      return null;

    return <p className="text-danger">{ error }</p>;
  }

  renderWizardStepGroupItem(number: number, name: string, active: boolean, alreadyDone: boolean): JSX.Element {
    return <div className={`item ${active ? "active" : "inactive"} ${alreadyDone ? "passed" : ""}`} key={`item${number}`}>
      <div className={`circle-progress ${alreadyDone ? "passed" : ""}`}>
        { alreadyDone
          ? <span className={"fa-regular fa-check"}></span>
          : <span>{number}</span> }
      </div>
      <span className="ml-10" >{i18n(`wizard_step_group.${name}`)}</span>
    </div>;
  }

  currentWizardStepGroupNumber(): number {
    const { step, customStarterKit } = this.state;
    let currentStepGroupNumber: number;

    Object.values(GROUPS_OF_STEPS).forEach((steps, index) => {
      if (steps.includes(step)) currentStepGroupNumber = index + 1;
    });
    if (customStarterKit) currentStepGroupNumber--;
    return currentStepGroupNumber;
  }

  renderWizardStepGroup(): JSX.Element {
    const { step, customStarterKit } = this.state;
    const currentStepGroupNumber = this.currentWizardStepGroupNumber();

    const steps = Object.entries(GROUPS_OF_STEPS);
    if (customStarterKit) steps.shift();
    return <div className="wizard-step-group">
      {
        steps.map(([name, steps], index) => {
          return this.renderWizardStepGroupItem(index + 1, name, steps.includes(step), (index + 1) < currentStepGroupNumber);
        })
      }
    </div>;
  }

  renderLeftPanelContent(): JSX.Element {
    const { step } = this.state;

    if (step === "creation_mode")
      return (
        <div className="environment">
          <span className="environment-title">{ i18n("introduction_title") }</span>
          <p>{i18n("introduction_description")}</p>
          <img src={IMAGE_URLS["imageEnvironment"]} className="img-environment img-fluid" width="1000" height="1000" />
        </div>
      );

    return this.renderWizardStepGroup();
  }

  renderBackButton(): JSX.Element {
    const { step } = this.state;
    if (["creation", "creation_mode", "finalization"].includes(step)) return;

    return <button className="btn btn-link btn-lg btn-back ps-0 pe-0" onClick={this.clickPreviousStep}>
      <i className="fa-regular fa-angle-left" /> { I18n.t("back") }
    </button>;
  }

  render(): JSX.Element {
    return (
      <div className="splitted vertical-center">
        <div>
          <div className="left-card">
            { this.renderLeftPanelContent() }
            <img src={IMAGE_URLS["backgroundAssistant"]} className="background-assistant" />
          </div>
          <div className="right-card">
            { this.renderStep() }
          </div>
          { this.renderBackButton() }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state): any {
  return {
    preconfiguredEvents: state.preconfiguredEvents.data && state.preconfiguredEvents.data,
    preconfiguredEventsFetched: state.preconfiguredEvents.fetched,
    preconfiguredEventsPendingRequest: state.preconfiguredEvents.pendingRequest,
    customPreconfiguredEvents: state.customPreconfiguredEvents.data && state.customPreconfiguredEvents.data,
    customPreconfiguredEventsFetched: state.customPreconfiguredEvents.fetched,
    customPreconfiguredEventsPendingRequest: state.customPreconfiguredEvents.pendingRequest,
  };
}

const mapDispatchToProps = {
  fetchPreconfiguredEvents,
  fetchCustomPreconfiguredEvents,
  reloadTheme
};

export default connect(mapStateToProps, mapDispatchToProps)(EventCreationSteps);
