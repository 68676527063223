import { CALL_API, ActionTypes } from "../constants/Constants";
import { BadgeTemplateApiAttributes } from "../types/BadgeTemplate";

export function fetchBadgeTemplate(eventId: string, guestCategoryId: string, badgeTemplateId: string): any {
  const { FETCH_BADGE_TEMPLATE_REQUEST, FETCH_BADGE_TEMPLATE_SUCCESS, FETCH_BADGE_TEMPLATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_BADGE_TEMPLATE_REQUEST, FETCH_BADGE_TEMPLATE_SUCCESS, FETCH_BADGE_TEMPLATE_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guest_categories/${guestCategoryId}/badge_templates/${badgeTemplateId}.json`,
    }
  };
}

export function createBadgeTemplate(eventId: string, guestCategoryId: string, params: BadgeTemplateApiAttributes): any {
  const { CREATE_BADGE_TEMPLATE_REQUEST, CREATE_BADGE_TEMPLATE_SUCCESS, CREATE_BADGE_TEMPLATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_BADGE_TEMPLATE_REQUEST, CREATE_BADGE_TEMPLATE_SUCCESS, CREATE_BADGE_TEMPLATE_FAILURE],
      method: "POST",
      body: {
        badge_template: params
      },
      endpoint: `/api/v1/events/${eventId}/guest_categories/${guestCategoryId}/badge_templates.json`
    }
  };
}

export function updateBadgeTemplate(eventId: string, guestCategoryId: string, badgeTemplateId: string, params: BadgeTemplateApiAttributes, sendFile?: boolean): any {
  const { UPDATE_BADGE_TEMPLATE_REQUEST, UPDATE_BADGE_TEMPLATE_SUCCESS, UPDATE_BADGE_TEMPLATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_BADGE_TEMPLATE_REQUEST, UPDATE_BADGE_TEMPLATE_SUCCESS, UPDATE_BADGE_TEMPLATE_FAILURE],
      method: "PUT",
      body: sendFile ? params : { badge_template: params },
      sendFile: sendFile,
      endpoint: `/api/v1/events/${eventId}/guest_categories/${guestCategoryId}/badge_templates/${badgeTemplateId}.json`
    }
  };
}
