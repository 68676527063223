import { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { Modal } from "react-bootstrap";
import Loader from "../shared/Loader.react";
import { Event } from "../../types/Event";

interface Props {
  templateType: string;
  isVisible: boolean;
  event: Event;
  templates: any[];
  onClose(): void;
  onSubmit(templateId: string, name: string): void;
  onBack(e): void;
}

const TemplatePickerModal: React.FC<Props> = ({ templateType, isVisible, event, templates, onClose, onSubmit, onBack }) => {
  const [name, setName] = useState<string>("");
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>(null);

  const isSubmittable = (): boolean => {
    return !isEmpty(selectedTemplateId) && !isEmpty(name) && !!name.match(/\S/);
  };

  const submit = (e): void => {
    e.preventDefault();
    if (!isSubmittable()) return;

    onSubmit(selectedTemplateId, name);
  };

  const onChangeName = (e): void => {
    setName(e.target.value);
  };

  const onSelectTemplate = (e): void => {
    setSelectedTemplateId(e.target.value);
  };

  const i18n = (key: string): string => {
    switch (templateType) {
    case "email_template":
      return I18n.t(`react.duplication.email_template_picker_modal.${key}`);
    case "document_template":
      return I18n.t(`react.duplication.document_template_picker_modal.${key}`);
    }
  };

  const renderDropdown = (): JSX.Element => {
    const options = templates.map(template => {
      return <option value={template._id} key={template._id}>{template.name}</option>;
    });

    return <div className="mb-3">
      <select className="form-select" value={selectedTemplateId || ""} onChange={onSelectTemplate}>
        <option value="">{ i18n("please_choose") }</option>
        {options}
      </select>
    </div>;
  };

  const renderBody = (): JSX.Element => {
    if (!event || !templates) {
      return <Loader size="large" inline={false} containerHeight="250px" />;
    }

    if (templates.length === 0) {
      return <div className="text-center mt-30">
        <h4>{ i18n("no_email") }</h4>
        <a href="#" className="btn btn-secondary mt-10 mb-30" onClick={onBack}>{ i18n("back_to_event_selection") }</a>
      </div>;
    }

    return <Modal.Body>
      <div className="mb-3">
        <label htmlFor="newTemplateName" className="form-label">{ i18n("name_label") }</label>
        <input type="text" className="form-control" id="newTemplateName" value={name} onChange={onChangeName} />
      </div>
      { renderDropdown() }
    </Modal.Body>;
  };

  const renderFooter = (): JSX.Element => {
    if (!templates || templates.length == 0) return;

    return <Modal.Footer>
      <a href="#" className="btn btn-secondary" onClick={onBack}>{ i18n("back_to_event_selection") }</a>
      <button type="submit" className="btn btn-primary" onClick={submit} disabled={!isSubmittable()}>
        { I18n.t("create") }
      </button>
    </Modal.Footer>;
  };

  return <Modal show={isVisible} onHide={onClose} bsSize="large">
    <Modal.Header>
      <Modal.Title>{ i18n("title") }</Modal.Title>
      <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
    </Modal.Header>
    <form>
      { renderBody() }
      { renderFooter() }
    </form>
  </Modal>;
};

export default TemplatePickerModal;
