import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import difference from "lodash/difference";
import isEmpty from "lodash/isEmpty";
import { createGuestCategory, fetchPopulationTypes } from "../../actions/GuestCategoryActionCreators";
import { fetchAccesspoints } from "../../actions/AccesspointActionCreators";
import { urlEventId } from "../../utils/pathUtils";

class CreateGuestCategoryModal extends Component {

  constructor(props) {
    super(props);
    [
      "onPendingQuery",
      "onCloseFunction",
      "renderGuestCategoryForm",
      "onSubmit"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = { hasPendingQuery: false };
  }

  componentDidMount() {
    const { accesspoints, fetchAccesspoints, fetchPopulationTypes } = this.props;
    //if no fetched accesspoint
    if (isEmpty(accesspoints)) {
      fetchAccesspoints(urlEventId());
    }
    fetchPopulationTypes(urlEventId());
  }

  componentDidUpdate(prevProps) {
    const { guestCategories, onCloseFunction, updateParent } = this.props;
    if (prevProps.isVisible && prevProps.guestCategories.length != guestCategories.length) {
      onCloseFunction();
      const newGuestCategory = difference(guestCategories, prevProps.guestCategories)[0];
      if (newGuestCategory) {
        updateParent(newGuestCategory);
      }
    }
  }

  onSubmit(guestCategory) {
    const { createGuestCategory, event } = this.props;
    createGuestCategory(event.id, guestCategory);
  }

  onPendingQuery(value) {
    this.setState({ hasPendingQuery: value });
  }

  renderGuestCategoryForm() {
    const { accesspoints, guestCategoriesErrors, proposedName, render, populationTypes, event } = this.props;
    return render({ isModal: true, accesspoints, errors: guestCategoriesErrors, onSubmit: this.onSubmit, proposedName, onPendingQuery: this.onPendingQuery, populationTypes, event });
  }

  onCloseFunction() {
    const { onCloseFunction } = this.props;
    const { hasPendingQuery } = this.state;
    return hasPendingQuery ? null : onCloseFunction;
  }

  render() {
    const { isVisible } = this.props;
    return (
      <Modal size="lg" show={isVisible} onHide={this.onCloseFunction()} enforceFocus={false}>
        <Modal.Header>
          <Modal.Title>{ I18n.t("guest_categories.new.new_guest_category") }</Modal.Title>
          <button type="button" onClick={this.onCloseFunction()} className="btn-close" aria-label={I18n.t("close")}></button>
        </Modal.Header>
        <Modal.Body>
          {this.renderGuestCategoryForm()}
        </Modal.Body>
      </Modal>
    );
  }
}

CreateGuestCategoryModal.propTypes = {
  isVisible: PropTypes.bool,
  onCloseFunction: PropTypes.func,
  updateParent: PropTypes.func,
  event: PropTypes.object.isRequired,
  proposedName: PropTypes.string,
  render: PropTypes.func.isRequired
};

CreateGuestCategoryModal.defaultProps = {
  isVisible: false
};

function mapStateToProps(state) {
  return {
    guestCategories: state.guestCategories.data,
    guestCategoriesErrors: state.guestCategories.errors,
    accesspoints: state.accesspoints.data,
    populationTypes: state.guestCategory.populationTypes
  };
}

const mapDispatchToProps = {
  createGuestCategory,
  fetchAccesspoints,
  fetchPopulationTypes
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGuestCategoryModal);
