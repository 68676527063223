import { ActionTypes } from "../constants/Constants";

export default () => next => action => {
  switch (action.type) {
  case ActionTypes.SHOW_NOTICE:
    next(action);
    if (action.timeout) {
      setTimeout(() => {
        next({ type: ActionTypes.SHOW_NOTICE, message: "" });
      }, action.timeout);
    }
    break;
  default:
    next(action);
  }
};

