import { fetchVisitRouteDigestEmail } from "../../actions/EventActionCreators";
import EventEmailPicker from "../../components/shared/EventEmailPicker.react";

interface Props {
  eventId: string;
}

const DigestEmailPicker: React.FC<Props> = ({ eventId, }) => {
  return <EventEmailPicker
    eventId={eventId}
    i18nPath="react.visit_routes.digest_email_picker"
    customEmailType="visit_route_digest"
    emailStoreKey="visitRouteDigestEmail"
    fetchEmail={(): any => fetchVisitRouteDigestEmail(eventId)}
  />;
};

export default DigestEmailPicker;
