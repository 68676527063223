import { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { defaultModalBigStyles } from "../../constants/Style";
import CodeEditor from "./CodeEditor.react";
import ErrorMessage from "./ErrorMessage.react";
import Loader from "./Loader.react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const DEFAULT_SECTION_CODE = "<div class='container {{ settings.spacing }}' data-section-id='{{ section.id }}' data-section-type='codeit'>\n  <!-- Code here -->\n</div>";

class LiquidCodeModal extends Component {
  constructor(props) {
    super(props);
    [
      "onClose",
      "open",
      "onBodyChange",
      "handleSubmitWithKeyboard",
      "handleInputChange",
      "onSaveHandler"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = { isOpen: false, body: "", updateMode: false, templateName: "" };
  }

  componentDidUpdate(prevProps) {
    const { liquidTemplateErrors, liquidTemplatePendingRequest, liquidTemplates, onSave } = this.props;
    const { templateName } = this.state;
    if (prevProps.liquidTemplatePendingRequest && !liquidTemplatePendingRequest && Object.keys(liquidTemplateErrors).length == 0) {
      // just created / updated
      const liquidTemplate = liquidTemplates && liquidTemplates.find(template => template.name === templateName);
      onSave(liquidTemplate && liquidTemplate._id);
      this.onClose();
    }
  }

  onClose() {
    const { clearErrors } = this.props;
    this.setState({ isOpen: false }, clearErrors);
  }

  open(updateMode = false) {
    return (e) => {
      e.preventDefault();
      const { liquidTemplate, builderType } = this.props;
      let body = builderType == "website" ? DEFAULT_SECTION_CODE : "";
      let templateName = "";
      if (updateMode && liquidTemplate) {
        body = liquidTemplate.body;
        templateName = liquidTemplate.name;
      }
      this.setState({ isOpen: true, updateMode, body, templateName });
    };
  }

  onBodyChange(newBody) {
    this.setState({ body: newBody });
  }

  handleSubmitWithKeyboard(e) {
    if (e) e.preventDefault();
    const { handleSave } = this.props;
    const { body } = this.state;
    handleSave({ body });
  }

  onSaveHandler(asNew = false) {
    return (e) => {
      if (e) e.preventDefault();
      const { accountId, updateLiquidTemplate, createLiquidTemplate, liquidTemplate, liquidTemplatePendingRequest, builderType } = this.props;
      if (liquidTemplatePendingRequest) {
        return;
      }

      const { body, templateName, updateMode } = this.state;
      const params = { body, type: `${builderType}_codeit_section`, name: templateName };

      if (updateMode && !asNew) {
        updateLiquidTemplate(accountId, liquidTemplate._id, params);
      } else {
        createLiquidTemplate(accountId, params);
      }
    };
  }

  renderButton(title, help, onClick) {
    const disabled = this.props.liquidTemplatePendingRequest ? "disabled" : "";
    const tooltip = <Tooltip id="help">{help}</Tooltip>;
    return (
      <div className="col-auto">
        <OverlayTrigger placement="top" overlay={tooltip}>
          <a href="#" className={ `btn btn-primary ${disabled}` } onClick={ onClick }>
            { title }
          </a>
        </OverlayTrigger>
      </div>
    );
  }

  renderSaveAsNewButton() {
    return this.renderButton(
      I18n.t("react.liquid_code_modal.save_as_new"),
      I18n.t("react.liquid_code_modal.save_as_tooltip"),
      this.onSaveHandler(true)
    );
  }

  renderSaveButton() {
    return this.renderButton(
      I18n.t("save"),
      I18n.t("react.liquid_code_modal.save_tooltip"),
      this.onSaveHandler()
    );
  }

  renderSubmitButton() {
    const { liquidTemplatePendingRequest } = this.props;
    const loader = liquidTemplatePendingRequest ? <Loader /> : null;
    const { updateMode } = this.state;
    return <div className="row g-2 align-items-center">
      { loader }
      { updateMode ? this.renderSaveAsNewButton() : null }
      { this.renderSaveButton() }
    </div>;
  }

  openModalButtons() {
    const { liquidTemplate } = this.props;
    return (
      <ul className="list-unstyled">
        {liquidTemplate && liquidTemplate._id ? (
          <li>
            <a href="#" onClick={this.open(true)}><i className="fa-regular fa-edit"></i> { I18n.t("react.liquid_code_modal.edit") }</a>
          </li>
        ) : null}
        <li>
          <a href="#" onClick={this.open(false)}><i className="fa-regular fa-plus"></i> {I18n.t("react.liquid_code_modal.new")}</a>
        </li>
      </ul>
    );
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  renderNameInput() {
    const { templateName } = this.state;
    return (
      <form className="mb-3 row g-2 align-items-center">
        <label className="col-form-label col-auto">Snippet name </label>
        <div className="col-auto">
          <input type="text" value={templateName} className="form-control" onChange={this.handleInputChange} name="templateName" />
        </div>
      </form>
    );
  }

  render() {
    const { isOpen, body } = this.state;
    const { liquidTemplateErrors } = this.props;
    return <div>
      { this.openModalButtons() }
      <Modal isOpen={isOpen} style={defaultModalBigStyles} contentLabel="Modal">
        <div className="modal-header" style={{ height: "65px" }}>
          <h4 className="modal-title">{ I18n.t("react.liquid_code_modal.title") }</h4>
          <button type="button" onClick={ this.onClose } className="btn-close" aria-label={I18n.t("close")}></button>
        </div>

        <div className="modal-body" style={{ height: "calc(100% - 130px)", overflow: "auto" }}>
          <ErrorMessage errors={liquidTemplateErrors}/>
          {this.renderNameInput()}
          <div style={{ marginTop: "10px" }} className="code-editor-custom">
            { isOpen ? <CodeEditor height="calc(90vh - 240px)" content={body || ""} onContentChange={this.onBodyChange} onSaveWithKeyboard={this.handleSubmitWithKeyboard} /> : null }
          </div>
        </div>
        <div className="modal-footer">
          { this.renderSubmitButton() }
        </div>
      </Modal>
    </div>;
  }
}

LiquidCodeModal.propTypes = {
  liquidTemplates: PropTypes.array,
  liquidTemplate: PropTypes.object,
  liquidTemplatePendingRequest: PropTypes.bool,
  accountId: PropTypes.string.isRequired,
  updateLiquidTemplate: PropTypes.func.isRequired,
  createLiquidTemplate: PropTypes.func.isRequired,
  liquidTemplateErrors: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

export default LiquidCodeModal;
