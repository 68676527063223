import { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { defaultModalStyles } from "../../constants/Style";
import CopyToClipboardTextArea from "./CopyToClipboardTextArea.react";
import HelpSection from "../shared/HelpSection.react";

class RegistrationFormIntegrationModal extends Component {
  renderIframeCode() {
    const { guestCategory } = this.props;
    const code = `<iframe style='border: 0; width: 100%; max-width: 900px; display: block; margin-left: auto; margin-right: auto;' id='mobicheckin-form' src='${guestCategory.custom_registration_form_url}'></iframe>
<script>
\t(function(d,t,u,a,m){a=d.createElement(t),m=d.getElementsByTagName(t)[0];a.src=u;m.parentNode.insertBefore(a,m)})(document,'script','https://applidget.github.io/vx-assets/shared/js/iframe-resizer/iframeResizerComplete.js');
\twindow.addEventListener('load', function(){iFrameResize({checkOrigin:false});});
</script>`;

    return [
      <HelpSection key="help" help={I18n.t("guest_categories.how_to_embed.iframe_snippet")} />,
      <CopyToClipboardTextArea value={code} rows={8} key="iframe" />
    ];
  }

  renderUrlTrackingCode() {
    const { guestCategory, website } = this.props;
    let custom_settings = "";
    if (guestCategory.registration_form_id && website && website.custom_domain)
      custom_settings = `\twindow.eventmakerTracking = { iframeHost: "https://${website.custom_domain}" };`;

    const code = `<script>
\twindow.eventmakerCustomFields = []; // here you can set custom_fields that are not already tracked by the script
${custom_settings}
\tscript=document.createElement("script"), script.type="text/javascript", script.async=!0, script.onload=function(){ window.eventmakerTracking.injectURLParamsInIframes()}, script.src="https://applidget.github.io/vx-assets/shared/js/url-params-tracking/1.0.1/url-params-tracking.js", document.getElementsByTagName("head")[0].appendChild(script);
</script>`;

    return [
      <HelpSection key="help" help={I18n.t("guest_categories.how_to_embed.tracking_snippet")} />,
      <CopyToClipboardTextArea value={code} rows={7} key="tracker"/>
    ];
  }

  render() {
    const { isOpen, closeModal, onAfterOpen } = this.props;

    return (
      <Modal isOpen={isOpen} onAfterOpen={onAfterOpen} onRequestClose={closeModal} style={defaultModalStyles} contentLabel="Modal">
        <div className="modal-header" key="modal-header">
          <h4 className="modal-title">{ I18n.t("react.guest_category.registration_form_integration_modal.title") }</h4>
          <button type="button" onClick={closeModal} className="btn-close" aria-label={I18n.t("close")}></button>
        </div>
        <div className="modal-body" key="modal-body">
          { this.renderIframeCode() }
          <br />
          { this.renderUrlTrackingCode() }
        </div>
      </Modal>
    );
  }
}

RegistrationFormIntegrationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  guestCategory: PropTypes.object.isRequired,
  website: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  onAfterOpen: PropTypes.func.isRequired
};

export default RegistrationFormIntegrationModal;
