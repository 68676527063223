import { Component } from "react";
import { Modal } from "react-bootstrap";
import DuplicationRunning from "../DuplicationRunning.react";

interface Props {
  isVisible: boolean;
  onClose(): void;
  model: string;
  duplicatedData: any;
  currentEventId: string;
  targetEventId: string;
  duplicationWarnings: any;
  duplicationErrors: any;
}

class DuplicationRunningModal extends Component<Props> {
  static defaultProps = {
    isVisible: false
  };

  render(): JSX.Element {
    const { isVisible, onClose, model } = this.props;

    return <Modal show={isVisible} onHide={onClose} size="lg">
      <Modal.Header>
        <Modal.Title>{ I18n.t(`react.${model}.duplication_running_modal.title`) }</Modal.Title>
        <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
      </Modal.Header>
      <Modal.Body>
        <DuplicationRunning {...this.props} />
      </Modal.Body>
    </Modal>;
  }
}

export default DuplicationRunningModal;
