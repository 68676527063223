import { Component, ReactNode, Children, cloneElement } from "react";
import SlidingPane from "react-sliding-pane";
import { connect } from "react-redux";
import { updateEvent } from "../../actions/EventActionCreators";
import { showNotice } from "../../actions/NoticeActionCreators";
import { urlEventId } from "../../utils/pathUtils";
import Icons from "../../constants/Icons";

interface StoreProps {
  pendingRequest: boolean;
}

interface DispatchProps {
  updateEvent(eventId: string, eventParams: any): void;
  showNotice(notice: string, noticeType?: string, timeout?: number): void;
}

interface OwnProps {
  title?: string;
  isOpen: boolean;
  width?: string;
  className?: string;
  onClose(): void;
  children: ReactNode;
}

interface Props extends StoreProps, DispatchProps, OwnProps {}

interface State {
  event: any;
}

function i18n(key, opts = {}): string {
  return I18n.t(`react.event_form_pane.${key}`, opts);
}

class EventFormPane extends Component<Props, State> {

  constructor(props) {
    super(props);
    [
      "onSectionChange",
      "onSubmit"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      event: {}
    };
  }

  componentDidUpdate(prevProps: Props): void {
    const { pendingRequest, showNotice, onClose } = this.props;

    if (!prevProps.pendingRequest && pendingRequest) {
      showNotice(i18n("saving"));
    }

    if (prevProps.pendingRequest && !pendingRequest) {
      showNotice(i18n("successfully_saved"), "success");
      setTimeout(() => { onClose(); }, 500);
    }
  }

  onSectionChange(eventAttributes: any): void {
    this.setState({ event: { ...this.state.event, ...eventAttributes } });
  }

  onSubmit(): void {
    const { event } = this.state;
    const { updateEvent, onClose } = this.props;

    if (Object.keys(event).length > 0) {
      updateEvent(urlEventId(), this.state.event);
    } else {
      onClose(); // if no changes, no update needed, so pane can be closed
    }
  }

  renderFormSections(): React.ReactElement[] {
    const elements = Children.toArray(this.props.children);

    return Children.map(elements, child => {
      return cloneElement(child as React.ReactElement<any>, {
        onChange: this.onSectionChange,
        noPanel: elements.length == 1
      });
    });
  }

  render(): JSX.Element {
    const { isOpen, onClose, title, width, className } = this.props;

    return (
      <SlidingPane
        isOpen={isOpen}
        title={ title || i18n("title") }
        from="right"
        width={ width || "60%" }
        onRequestClose={onClose}
        className={ className || "width-100-xs width-80-sm" }
        closeIcon={Icons.close()}
      >
        <div style={{ padding: "10px" }}>
          { this.renderFormSections() }
          <input type="submit" className="btn btn-primary" value={I18n.t("validate")} onClick={this.onSubmit} />
        </div>
      </SlidingPane>
    );
  }
}

const mapStateToProps = (state): StoreProps => {
  return {
    pendingRequest: state.event.pendingRequest
  };
};

const mapDispatchToProps: DispatchProps = {
  showNotice,
  updateEvent
};

export default connect<StoreProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(EventFormPane);
