import { Component } from "react";
import moment from "moment";
import XLSX from "xlsx";
import saveAs from "file-saverjs";
import { COLUMNS_WITH_CURRENCY } from "../containers/event_reports/CartItemsStat.react";

class ExportToExcelLink extends Component {

  constructor(props) {
    super(props);
    [
      "s2ab",
      "buildWorkbook",
      "stringWorkbook",
      "downloadSpreadsheet",
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
  }

  s2ab(string) {
    const buf = new ArrayBuffer(string.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i != string.length; ++i) view[i] = string.charCodeAt(i) & 0xFF;
    return buf;
  }

  buildWorkbook() {
    const column = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    const { data, type, options } = this.props;
    const columnKeys = [...this.props.columnKeys]; // Avoid operations like array.shift() to change the initial array
    let workbook = { Sheets: {}, SheetNames: [] };

    const nbRows = data.data.length + 2;
    const nbColumn = columnKeys.length;
    const ws = { "!ref": `A1:${column[nbColumn]}${nbRows}` };

    // Build header
    columnKeys.forEach((key, i) => {
      let value = I18n.t(`react.event_reports.export.${type}.${key}`, { defaultValue: key });
      if (i == 0 && options && options.fieldPrettyName) { value = options.fieldPrettyName(); }
      ws[`${column[i]}1`] = { h: value, r: `<t>${value}</t>`, t: "s", v: value, s: { font: { bold: true } } };
    });

    if (data) {
      // Build data
      data.data.forEach((stat, i) => {
        let rowIndex = i + 2;
        columnKeys.forEach((key, j) => {
          let value = stat[key];
          let isCurrency = false;
          let isNumber = false;
          if (key == "_id" && options && options.mapping && options.mapping[value]) {
            value = options.mapping[value].props.children;
          } else if (key === "transformation_rate" || key === "count_percent") {
            value = `${(value * 100).toFixed(2)}%`;
          } else if (type === "sales" && ["total_price_incl_taxes", "total_price_excl_taxes", "price", "discount_amount", "refund_amount"].includes(key)) {
            isCurrency = true;
          } else if (type === "cart_item_sales" && COLUMNS_WITH_CURRENCY.includes(key)) {
            isCurrency = true;
          } else if (type === "cart_item_sales" && ["quantity", "vat"].includes(key)) {
            isNumber = true;
          } else if (type === "sales" && ["promo_code_ratio", "taxes"].includes(key)) {
            value = `${value} %`;
          }
          ws[`${column[j]}${rowIndex}`] = this.formatCell(value, isNumber || isCurrency, isCurrency ? stat.currency : null);
        });
      });

      // Build total
      if (!options || !options.excludeTotal) {
        ws[`A${nbRows}`] = { h: "Total", r: "<t>Total</t>", t: "s", v: "Total" };
        columnKeys.shift();
        columnKeys.forEach((key, j) => {
          let columnIndex = j + 1;
          let totalData = data.total;
          if (type == "sales") { totalData = data.total[data.data[0]["currency"]]; }
          let value = totalData[key];
          let isCurrency = false;
          if (type == "sales" && ["discount_amount", "total_price_excl_taxes", "total_price_incl_taxes"].includes(key)) {
            isCurrency = true;
          }
          if (key == "transformation_rate") { value = `${(value * 100).toFixed(2)}%`; }
          ws[`${column[columnIndex]}${nbRows}`] = this.formatCell(value, isCurrency, data.data[0]["currency"]);
        });
      }

      workbook.SheetNames.push("Report");
      workbook.Sheets["Report"] = ws;
    }

    return workbook;
  }

  formatCell(value, isNumber, currency) {
    if (!isNumber) return { h: value, r: `<t>${value}</t>`, t: "s", v: value };

    let currencySymbol = "";
    if (currency) {
      const formatter = new Intl.NumberFormat(I18n.currentLocale(), { style: "currency", currency });
      currencySymbol = formatter.formatToParts(value).find(part => part.type === "currency").value;
    }

    // Cannot get a comma as a decimal separator or a dot as a separator for thousands.
    const format = I18n.currentLocale() === "fr" ? "# ##0.00" : "#,##0.00";
    const zParam = currencySymbol === "" ? `${format}` : `[$${currencySymbol}] ${format}`;
    return { h: value, r: `<t>${value}</t>`, v: value, t: "n", z: zParam };
  }

  stringWorkbook() {
    const options = { bookType: "xlsx", bookSST: false, type: "binary" };
    const workbook = this.buildWorkbook();
    return XLSX.write(workbook, options);
  }

  downloadSpreadsheet(e) {
    e.preventDefault();
    const currentDate = moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss");
    const fileName = `report-${currentDate}`;
    const blob = new Blob([this.s2ab(this.stringWorkbook(fileName))], { type: "" });
    saveAs(blob, `${fileName}.xlsx`);
  }

  render() {
    return (
      <a href="" onClick={this.downloadSpreadsheet}>
        <i className="fa-regular fa-download" aria-hidden="true"/> { I18n.t("react.event_reports.export.export_to_excel") }
      </a>
    );
  }

}

export default ExportToExcelLink;
