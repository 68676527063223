import { ActionTypes } from "../constants/Constants";

function labelStats(state = null, action) {
  switch (action.type) {
  case ActionTypes.LABELS_STATS_SUCCESS: {
    state = action.data.reduce((acc, element) => {
      acc[element["_id"]] = element["count"];
      return acc;
    }, {});
    return state;
  }
  case ActionTypes.LABELS_STATS_CLEAR: {
    return null;
  }
  }
  return state;
}

export default labelStats;
