import { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AccessPrivilegeDropdown from "./AccessPrivilegeDropdown.react";
import classNames from "classnames";

const i18n = (key, options) => {
  return I18n.t(`react.access_privilege_row.${key}`, options);
};

class AccessPrivilegeRow extends Component {
  renderGuestCategoryTooltip() {
    const { displayName } = this.props;
    return <Tooltip id="tooltip">{displayName}</Tooltip>;
  }

  displayedCapacity() {
    const { capacity, nbReserved } = this.props;
    if (capacity === undefined || nbReserved === undefined) {
      return "";
    }
    if (capacity > -1 && nbReserved >= capacity) {
      return i18n("full");
    } else if (capacity === -1) {
      return i18n("unlimited_slots");
    }
    return i18n("remaining_slots", { count: capacity - nbReserved });
  }

  renderCapacityLabel() {
    const { capacity, nbReserved } = this.props;
    if (capacity === undefined || nbReserved === undefined) {
      return "";
    }

    const classes = classNames({
      "badge rounded-pill": true,
      "bg-danger": capacity > -1 && nbReserved >= capacity,
      "bg-info": capacity == -1,
      "bg-success": nbReserved < capacity //nbReserved is never < 0
    });
    return (
      <span className={classes}>{this.displayedCapacity()}</span>
    );
  }

  renderBundleLabel() {
    const { bundles } = this.props;

    if (!bundles || bundles.length === 0)
      return null;

    return bundles.map(bundle => {
      return <span key={`${bundle.id}`} className="badge bg-success rounded-pill">{bundle.name}</span>;
    });
  }

  renderDropdown(resource) {
    const { dropdownDisplayed, bundlesPrivileges } = this.props;
    if (!dropdownDisplayed) return null;

    const { handleValue, handleRemove } = this.props;
    return <div className="col-auto">
      <AccessPrivilegeDropdown
        resource={resource}
        handleRemove={handleRemove}
        handleValue={handleValue}
        bundlesPrivileges={bundlesPrivileges}
      />
    </div>;
  }

  renderHelpBundle() {
    const { bundlesPrivileges } = this.props;

    if (!bundlesPrivileges || bundlesPrivileges.length === 0)
      return;

    const overlay = (
      <Tooltip id="tooltip">
        <p>
          {I18n.t("react.access_privilege_row.privilege_on_bundle_info_tooltip")}<br />
          {bundlesPrivileges.map(bundle => {
            return <span key={bundle.id}>{bundle.name}<br /></span>;
          })}
        </p>
      </Tooltip>
    );

    return (
      <span className="col-auto text-primary">
        <OverlayTrigger placement="top" overlay={overlay}>
          <i className="fa-regular fa-info-circle" aria-hidden="true"></i>
        </OverlayTrigger>
      </span>
    );
  }

  render() {
    const { resource, showUrl, displayName } = this.props;

    const badgeStyle = {
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "fit-content"
    };

    return (
      <div className="row">
        <span className="col-sm-8 col-12 d-grid">
          <a href={showUrl} style={{ display: "inherit" }}>
            <OverlayTrigger placement="top" overlay={this.renderGuestCategoryTooltip(displayName)}>
              <span className="badge rounded-pill bg-secondary big" style={badgeStyle}>{displayName}</span>
            </OverlayTrigger>
          </a>
          <div>{this.renderBundleLabel()} {this.renderCapacityLabel()}</div>
        </span>
        <span className="col-sm-4 col-12 row align-items-center gx-2 justify-content-end">
          {this.renderHelpBundle()}
          {this.renderDropdown(resource)}
        </span>
      </div>
    );
  }
}

AccessPrivilegeRow.defaultProps = {
  dropdownDisplayed: true,
};

export default AccessPrivilegeRow;
