import { useEffect, useState } from "react";
import { fabric } from "fabric";
import FabricCanvas from "./fabricjs/FabricCanvas.react";

interface Props {
  canvasSize: string;
  onReady?: (canvas: fabric.Canvas) => void;
}

const MARGIN_PDF = 7.5;

const BadgeBuilderCanvas: React.FC<Props> = ({
  canvasSize,
  onReady
}) => {
  const [canvasWidth, setCanvasWidth] = useState<number>();
  const [canvasHeight, setCanvasHeight] = useState<number>();

  useEffect(() => {
    setCanvasSize();
  }, [canvasWidth || canvasHeight]);

  const convertCmToPx = (cmValue: number): number => {
    return (72 * cmValue) / 2.54;
  };

  const getCanvasWidth = (): number => {
    const widthInCm = parseFloat(canvasSize.split("x")[0]);
    return convertCmToPx(widthInCm) - MARGIN_PDF;
  };

  const getCanvasHeight = (): number => {
    const heightInCm = parseFloat(canvasSize.split("x")[1]);
    return convertCmToPx(heightInCm) - MARGIN_PDF;
  };

  const canvasScale = (): number => {
    const maxheight = window.innerHeight * 0.85;
    const horizontalCard = canvasWidth > 150 && canvasHeight < 150;
    const verticalCard = canvasHeight > 150 && canvasWidth < 150;
    const a6 = canvasWidth < 350 && canvasWidth > 240;

    let scaleValue = maxheight / canvasHeight;
    if (horizontalCard) scaleValue = 240 / canvasWidth;
    if (verticalCard) scaleValue = 240 / canvasHeight;
    if (a6) scaleValue = 350 / canvasWidth;
    return scaleValue;
  };

  const canvasMargin = (): number => {
    return ((window.innerWidth / 2) - canvasWidth) / 2;
  };

  const setCanvasSize = (): void => {
    setCanvasWidth(getCanvasWidth());
    setCanvasHeight(getCanvasHeight());
  };

  const customStyle = {
    position: "absolute",
    left: canvasMargin() || 0,
    transform: `scale(${canvasScale()})`,
    transformOrigin: "top center"
  };

  return <FabricCanvas
    className="mt-20"
    canvasHeight={canvasHeight}
    canvasWidth={canvasWidth}
    customStyle={customStyle}
    onReady={onReady}
  />;
};

export default BadgeBuilderCanvas;
