import { Component } from "react";
import { Link } from "react-router-dom";
import { relativePath } from "../../utils/pathUtils";
import {
  CHECKIN,
  REAL_TIME_CAPACITY_MONITORING,
  EXHIBITOR_MEETINGS,
  EXHIBITOR_LEADS,
  SCHEDULED_1TO1_MEETING,
  ONE_TO_ONE_CHAT_AND_CONNECTIONS,
  ENGAGEMENT_SCORING,
  THEMATIC_SCORING,
  PAYMENT
} from "../../constants/FeaturesSet";
import { isEnabled, anyEnabled, allEnabled } from "../../utils/featureSetUtils";

class DashboardNavTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event_has_exits: props.event_has_exits !== undefined ? props.event_has_exits : true,
    };
  }

  render() {
    const { active } = this.props;
    const { event_has_exits } = this.state;
    return (
      <ul className="nav nav-tabs">
        <li className={`nav-item ${active === "registered" ? "active" : ""}`}>
          <Link to={relativePath("dashboard/registered")} className="nav-link">{ I18n.t("react.dashboard.registration") }</Link>
        </li>
        {
          isEnabled(CHECKIN) && <li className={`nav-item ${active === "checkin" ? "active" : ""}`}>
            <Link to={relativePath("dashboard/checkin")} className="nav-link">{ I18n.t("react.dashboard.checkins") }</Link>
          </li>
        }
        {
          isEnabled(REAL_TIME_CAPACITY_MONITORING) && event_has_exits && <li className={`nav-item ${active === "occupancy" ? "active" : ""}`}>
            <Link to={relativePath("dashboard/occupancy")} className="nav-link">{ I18n.t("react.dashboard.occupancy") }</Link>
          </li>
        }
        {
          anyEnabled([ONE_TO_ONE_CHAT_AND_CONNECTIONS, EXHIBITOR_MEETINGS, SCHEDULED_1TO1_MEETING]) && <li className={`nav-item ${active === "networking" ? "active" : ""}`}>
            <Link to={relativePath("dashboard/networking")} className="nav-link">{ I18n.t("react.dashboard.networking.tab_name") }</Link>
          </li>
        }
        {
          isEnabled(EXHIBITOR_LEADS) && <li className={`nav-item ${active === "leads" ? "active" : ""}`}>
            <Link to={relativePath("dashboard/leads")} className="nav-link">{ I18n.t("react.dashboard.leads.tab_name") }</Link>
          </li>
        }
        {
          allEnabled([ENGAGEMENT_SCORING, THEMATIC_SCORING]) && <li className={`nav-item ${active === "scoring" ? "active" : ""}`}>
            <Link to={relativePath("dashboard/scoring")} className="nav-link">{ I18n.t("react.dashboard.scoring.tab_name") }</Link>
          </li>
        }
        {
          isEnabled(EXHIBITOR_LEADS) && <li className={`nav-item ${active === "sessions" ? "active" : ""}`}>
            <Link to={relativePath("dashboard/sessions")} className="nav-link">{ I18n.t("react.dashboard.sessions.tab_name") }</Link>
          </li>
        }
        {
          isEnabled(PAYMENT) && <li className={`nav-item ${active === "sales" ? "active" : ""}`}>
            <Link to={relativePath("dashboard/sales")} className="nav-link">{ I18n.t("react.dashboard.sales.tab_name") }</Link>
          </li>
        }
      </ul>
    );
  }

}

export default DashboardNavTab;
