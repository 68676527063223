import { Component } from "react";
import PropTypes from "prop-types";
import EmailTemplatePicker from "../containers/guest_campaign/EmailTemplatePicker.react";

class EmailConfiguration extends Component {

  constructor(props) {
    super(props);
    [
      "onInputChange",
      "onSelectedTemplateChange"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
  }

  renderInput(value, label, type, handler) {
    return (
      <div className="mb-3">
        <label className="form-label">{ label }</label>
        <div className="row">
          <div className="col-md-12">
            <input type={type} value={value} onChange={this.onInputChange(handler)} className="form-control" />
          </div>
        </div>
      </div>
    );
  }

  onInputChange(handler) {
    return (e) => {
      handler(e.target.value);
    };
  }

  onSelectedTemplateChange(template) {
    const { onTemplateIdChange } = this.props;
    const templateId = template ? template._id : undefined;
    onTemplateIdChange(templateId);
  }

  renderEmailTemplate() {
    const { locale, eventId, templateId, guestId, selectedTemplateId } = this.props;
    return (
      <div className="mb-3" >
        <label className="form-label">{ I18n.t("react.email_configuration.email_template_title") }</label>
        <div className="row">
          <div className="col-md-4">
            <EmailTemplatePicker
              onSelectTemplate={this.onSelectedTemplateChange}
              defaultTemplateId={templateId}
              modalPicker={true}
              eventId={eventId}
              locale={locale}
              guestId={guestId}
              selectedTemplateId={selectedTemplateId}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { replyToEmailAdress, subject, onSubjectChange, onReplyToEmailAdressChange, displayFromNameInput, fromName, onFromNameChange } = this.props;

    return (
      <div>
        { this.renderInput(subject, I18n.t("react.email_configuration.subject"), "text", onSubjectChange)}
        { displayFromNameInput ? (
          this.renderInput(fromName, I18n.t("react.email_configuration.from_name"), "text", onFromNameChange)
        ) : null}
        { this.renderInput(replyToEmailAdress, I18n.t("react.email_configuration.reply_to"), "email", onReplyToEmailAdressChange)}
        { this.renderEmailTemplate() }
      </div>
    );
  }
}

EmailConfiguration.requiresProps = {
  locale: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  guestId: PropTypes.string,

  replyToEmailAdress: PropTypes.string,
  subject: PropTypes.string,
  templateId: PropTypes.string,
  fromName: PropTypes.string,

  onTemplateIdChange: PropTypes.func.isRequired,
  onSubjectChange: PropTypes.func.isRequired,
  onFromNameChange: PropTypes.func,
  onReplyToEmailAdressChange: PropTypes.func.isRequired,

  displayFromNameInput: PropTypes.bool
};

EmailConfiguration.defaultProps = {
  replyToEmailAdress: "",
  subject: "",
  templateId: "",
  displayFromNameInput: false
};

export default EmailConfiguration;
