import { useState } from "react";

interface Options {
  canLoad?: boolean;
  default?: any;
  postProcessData?: (data: any) => void;
}

const defaultOptions: Options = {
  canLoad: true
};

export default function useStateFromEndpoint<T>(endpoint: string, opts: Options): [T, boolean, boolean] {
  const options = { ...defaultOptions, ...(opts || {}) };

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [state, setState] = useState<T>(options.default);
  const [loadedEndpoint, setLoadedEndpoint] = useState(null);

  const load = (): void => {
    setLoading(true);
    setLoaded(false);
    setLoadedEndpoint(endpoint);
    fetch(endpoint)
      .then(res => res.json())
      .then(result => {
        const data = options.postProcessData ? options.postProcessData(result) : result;
        setState(data);
        setLoaded(true);
        setLoading(false);
      });
  };

  if (!loaded && !loading && options.canLoad) load(); // initial load
  if (loaded && options.canLoad && endpoint !== loadedEndpoint) load(); // endpoint changed, reload
  if (loaded && !endpoint) { // endpoint became null, clear data
    setState(options.default);
    setLoaded(false);
  }

  return [state, loaded, loading];
}
