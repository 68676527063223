import { ChangeEvent } from "react";
import { Modal } from "react-bootstrap";
import Checkbox from "../shared/Checkbox.react";

interface Props {
  isVisible: boolean;
  title: string;
  applyAllText: string;
  applyAllEnabled: boolean;
  batchForceAll: boolean;
  confirmationQuestion: string;
  confirmationWarning?: string;
  confirmationDanger?: string;
  confirmButtonClasses?: string;
  isPendingBatchRequest: boolean;
  onClose(): void;
  onConfirm(): void;
  toggleForceAll(checked: boolean): void;
}

const BatchActionConfirmationModal: React.FC<Props> = ({
  isVisible,
  title,
  applyAllText,
  applyAllEnabled,
  batchForceAll,
  confirmationQuestion,
  confirmationWarning,
  confirmationDanger,
  confirmButtonClasses,
  isPendingBatchRequest,
  onClose,
  onConfirm,
  toggleForceAll
}) => {
  return <Modal show={isVisible} onHide={onClose} restoreFocus={false}>
    <Modal.Header>
      <Modal.Title>
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Modal.Title>
      <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
    </Modal.Header>
    <Modal.Body>
      <p className="mb-3" dangerouslySetInnerHTML={{ __html: confirmationQuestion }} />
      { applyAllEnabled &&
        <Checkbox checked={batchForceAll} text={applyAllText} onChange={(e: ChangeEvent<HTMLInputElement>): void => toggleForceAll(e.target.checked)} />
      }
      {confirmationWarning && (
        <p className="text-warning mt-3"><i className="fa-regular fa-triangle-exclamation"></i> {confirmationWarning}</p>
      )}
      {confirmationDanger && (
        <p className="text-danger mt-3"><i className="fa-regular fa-triangle-exclamation"></i> {confirmationDanger}</p>
      )}
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-secondary" onClick={onClose}>{ I18n.t("cancel") }</button>
      <button className={`btn ${confirmButtonClasses || "btn-primary"}`} onClick={onConfirm} disabled={isPendingBatchRequest}>{ I18n.t("continue") }</button>
    </Modal.Footer>
  </Modal>;
};

export default BatchActionConfirmationModal;
