import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DropdownButton, Dropdown } from "react-bootstrap";
import truncate from "lodash/truncate";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { pathToGuestCategoryLiquidField, pathToNewRegistration, urlEventId } from "../utils/pathUtils";
import Icons from "../constants/Icons";
import { fetchWebsite } from "../actions/WebsiteActionCreators";
import { fetchGuestCategory, updateGuestCategory, registrationFormToggle } from "../actions/GuestCategoryActionCreators";
import WebsiteBuilder from "./WebsiteBuilder.react";
import RegistrationForm from "./RegistrationForm.react";
import SelectRegistrationFormModal from "./SelectRegistrationFormModal.react";
import GuestCategoryFeature from "../components/guest_category/GuestCategoryFeature.react";
import RegistrationFormIntegrationModal from "../components/shared/RegistrationFormIntegrationModal.react";

import { isEnabled } from "../utils/featureSetUtils";
import { SOCIAL_REGISTRATION } from "../constants/FeaturesSet";

class GuestCategoryRegistrationFormFeature extends Component {
  constructor(props) {
    super(props);
    [
      "openRegistrationFormsModal",
      "closeRegistrationFormsModal",
      "disableRegistrationForm",
      "openWebsiteBuilder",
      "openFormIntegrationModal",
      "closeFormIntegrationModal",
      "loadWebsite",
      "disableBodyScroll",
      "enableBodyScroll"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = {
      isWebsiteBuilderOpen: false,
      isFormBuilderOpen: false,
      reloadWebsitePreview: false,
      isRegistrationFormsModalOpen: false,
      isFormIntegrationModalOpen: false
    };
  }

  componentDidMount() {
    const { guestCategory, fetchGuestCategory, match, openOnLoad } = this.props;
    if (match && (!guestCategory || Object.keys(guestCategory).length === 0))
      fetchGuestCategory(match.params.event_id, match.params.guest_category_id, { registration_form_page: true });

    if (openOnLoad && guestCategory)
      this.openFeature();
  }

  openFeature() {
    const { guestCategory } = this.props;

    if (guestCategory.custom_registration_form_enabled && guestCategory.registration_form_id) {
      this.setState({ isWebsiteBuilderOpen: true });
    } else if (!guestCategory.custom_registration_form_enabled) {
      this.setState({ isRegistrationFormsModalOpen: true });
    }
  }

  reloadWebsitePreview() {
    this.setState({ reloadWebsitePreview: true }, () => {
      this.setState({ reloadWebsitePreview: false });
    });
  }

  openRegistrationFormsModal(e) {
    e.preventDefault();
    this.setState({ isRegistrationFormsModalOpen: true });
  }

  closeRegistrationFormsModal() {
    this.setState({ isRegistrationFormsModalOpen: false });
  }

  openFormIntegrationModal(e) {
    e.preventDefault();
    this.setState({ isFormIntegrationModalOpen: true });
  }

  closeFormIntegrationModal() {
    this.setState({ isFormIntegrationModalOpen: false });
  }

  openWebsiteBuilder(e) {
    if (e) e.preventDefault();
    this.setState({ isWebsiteBuilderOpen: true, isRegistrationFormsModalOpen: false });
  }

  disableRegistrationForm() {
    const { updateGuestCategory, guestCategory } = this.props;

    if (confirm(this.i18n("disable_form_confirm")))
      updateGuestCategory(guestCategory.event_id, guestCategory._id, { registration_form_id: null, custom_registration_form_enabled: false });
  }

  loadWebsite() {
    const { fetchWebsite } = this.props;
    fetchWebsite(urlEventId());
  }

  disableBodyScroll() {
    document.body.style.overflow = "hidden";
  }

  enableBodyScroll() {
    document.body.removeAttribute("style");
  }

  i18n(key) {
    return I18n.t(`react.guest_category.registration_form_feature.${key}`);
  }

  renderSlidingPaneTitle(message) {
    const { guestCategory } = this.props;

    return (
      <div className="d-flex">
        <span className="d-flex align-items-center mr-5">
          { message } { " " }
        </span>
        <span className="badge rounded-pill big guest-category" style={{ backgroundColor: guestCategory.label_color }}>
          {truncate(guestCategory.name, { "length": 45 })}
        </span>
      </div>
    );
  }

  renderSlidingPane() {
    const { guestCategory, match, location, history } = this.props;
    const { isWebsiteBuilderOpen, isFormBuilderOpen, reloadWebsitePreview } = this.state;

    return (
      <SlidingPane
        isOpen={ isWebsiteBuilderOpen }
        width='90%'
        title={ this.renderSlidingPaneTitle(this.i18n("sliding_pane_form_page_title")) }
        onAfterOpen={ this.disableBodyScroll }
        onRequestClose={ () => {
          this.setState({ isWebsiteBuilderOpen: false });
          this.enableBodyScroll();
        }}
        className="width-100-xs width-100-sm"
        closeIcon={Icons.close()}>

        <WebsiteBuilder
          initialPageId={guestCategory.registration_form_page_id}
          noPageSwitch={true}
          slidingPane={true}
          reloadPreview={reloadWebsitePreview}
          match={match}
          location={location}
          history={history} />

        { this.renderEditFormButton() }

        <SlidingPane
          isOpen={ isFormBuilderOpen }
          width='90%'
          title={ this.renderSlidingPaneTitle(this.i18n("sliding_pane_form_builder_title")) }
          onRequestClose={ () => {
            this.setState({ isFormBuilderOpen: false });
            this.reloadWebsitePreview();
          }}
          className="width-100-xs width-100-sm"
          closeIcon={Icons.close()}>

          <RegistrationForm registrationFormId={guestCategory.registration_form_id}
            slidingPane={true}
            match={match}
            location={location}
            history={history} />
        </SlidingPane>
      </SlidingPane>
    );
  }

  renderEditFormButton() {
    const { website, sectionTypes } = this.props;

    if (!website || sectionTypes.length == 0)
      return null;

    return <button className="btn btn-primary btn-lg edit-form-button" onClick={() => this.setState({ isFormBuilderOpen: true })}>
      <i className="fa-regular fa-pencil"></i> {this.i18n("setup_your_form")}
    </button>;
  }

  renderBarsIcon() {
    return <i className="fa-regular fa-bars" />;
  }

  renderGuestCategoryFormOptions() {
    const { guestCategory } = this.props;

    return <div className="feature-dropdown-btn" key="feature-dropdown-btn">
      <DropdownButton variant="link" align="end" id="GuestCategoryFormOptions" title={this.renderBarsIcon()}>
        <Dropdown.Item eventKey="1" href={pathToNewRegistration(guestCategory)} target="_blank"><i className="fa-regular fa-eye" /> {this.i18n("preview_form_page")}</Dropdown.Item>
        { isEnabled(SOCIAL_REGISTRATION) &&
          <Dropdown.Item eventKey="2" href={pathToGuestCategoryLiquidField(guestCategory._id, "sponsorship_email")}><i className="fa-regular fa-user-group" /> {this.i18n("sponsorship_email")}</Dropdown.Item>
        }
        <Dropdown.Item eventKey="3" href={pathToGuestCategoryLiquidField(guestCategory._id, "registrations_closed")}><i className="fa-regular fa-stop-circle" /> {this.i18n("registrations_closed")}</Dropdown.Item>
        <Dropdown.Item eventKey="4" onClick={this.openFormIntegrationModal}><i className="fa-regular fa-code" /> {this.i18n("integration_module")}</Dropdown.Item>
        { guestCategory.type != "ticketing" && <Dropdown.Divider /> }
        { guestCategory.type != "ticketing" && <Dropdown.Item eventKey="5" onClick={this.openRegistrationFormsModal}><i className="fa-regular fa-link" /> {this.i18n("select_another_form")}</Dropdown.Item> }
        { guestCategory.type == "standard" && <Dropdown.Item eventKey="6" onClick={this.disableRegistrationForm}><i className="fa-regular fa-circle-xmark" /> {this.i18n("disable_form")}</Dropdown.Item> }
      </DropdownButton>
    </div>;
  }

  renderFeatureContent() {
    const { guestCategory, feature } = this.props;

    if (guestCategory.custom_registration_form_enabled) {
      if (guestCategory.registration_form_id) {
        return <GuestCategoryFeature feature={feature} guestCategory={guestCategory} onClick={this.openWebsiteBuilder}>
          { this.renderSlidingPane() }
          { this.renderGuestCategoryFormOptions() }
        </GuestCategoryFeature>;
      } else {
        return <GuestCategoryFeature feature={feature} guestCategory={guestCategory} />;
      }
    } else {
      return <GuestCategoryFeature feature={feature} guestCategory={guestCategory}
        onClick={this.openRegistrationFormsModal} />;
    }
  }

  render() {
    const { guestCategory, website, defaultHtmlContent } = this.props;

    if (!guestCategory || Object.keys(guestCategory).length == 0)
      return <div dangerouslySetInnerHTML={{ __html: defaultHtmlContent }}></div>;

    const { isRegistrationFormsModalOpen, isFormIntegrationModalOpen } = this.state;

    return <div>
      { this.renderFeatureContent() }
      <SelectRegistrationFormModal isOpen={isRegistrationFormsModalOpen}
        guestCategory={guestCategory}
        closeModal={this.closeRegistrationFormsModal}
        afterAttachingToForm={this.openWebsiteBuilder} />
      <RegistrationFormIntegrationModal isOpen={isFormIntegrationModalOpen}
        guestCategory={guestCategory}
        closeModal={this.closeFormIntegrationModal}
        onAfterOpen={this.loadWebsite}
        website={website} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    guestCategory: state.guestCategory.data,
    website: state.website.data,
    sectionTypes: state.sectionTypes.items
  };
}

const mapDispatchToProps = {
  fetchGuestCategory,
  updateGuestCategory,
  registrationFormToggle,
  fetchWebsite
};

GuestCategoryRegistrationFormFeature.propTypes = {
  guestCategory: PropTypes.object,
  feature: PropTypes.object.isRequired,
  openOnLoad: PropTypes.bool
};

GuestCategoryRegistrationFormFeature.defaultProps = {
  openOnLoad: false
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestCategoryRegistrationFormFeature);
