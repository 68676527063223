import { CALL_API, ActionTypes } from "../constants/Constants";
import { cleanUpQueryString } from "../utils/QueryStringUtils";
import { cleanUniqueKeyInSiloedData } from "../utils/APIUtils.js";

export function fetchGuestProducts(eventId: string, searchQuery: string, page: number, options: any = {}): any {
  const { FETCH_GUEST_PRODUCTS_REQUEST, FETCH_GUEST_PRODUCTS_SUCCESS, FETCH_GUEST_PRODUCTS_FAILURE } = ActionTypes;
  const body = {
    search: cleanUpQueryString(searchQuery),
    page: page,
    per_page: 50,
    ...options["searchFilters"]
  };
  const action = {
    [CALL_API]: {
      types: [FETCH_GUEST_PRODUCTS_REQUEST, FETCH_GUEST_PRODUCTS_SUCCESS, FETCH_GUEST_PRODUCTS_FAILURE],
      method: "GET",
      body: body,
      endpoint: `/api/v1/events/${eventId}/guest_products.json`,
    }
  };
  if (options["uniqueKeyInSiloedData"]) {
    action["uniqueKeyInSiloedData"] = options["uniqueKeyInSiloedData"];
  }
  return action;
}

export function removeGuestProductInSiloedData(uniqueKeyInSiloedData: string): any {
  const { DELETE_GUEST_PRODUCT_KEY_IN_SILOED_DATA } = ActionTypes;
  return (dispatch: any): void => {
    cleanUniqueKeyInSiloedData(dispatch, DELETE_GUEST_PRODUCT_KEY_IN_SILOED_DATA, uniqueKeyInSiloedData);
  };
}

export function fetchGuestProductsByNumberOfInterests(eventId: string, sortBy: string): any {
  const { FETCH_GUEST_PRODUCTS_STATISTICS_REQUEST, FETCH_GUEST_PRODUCTS_STATISTICS_SUCCESS, FETCH_GUEST_PRODUCTS_STATISTICS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_GUEST_PRODUCTS_STATISTICS_REQUEST, FETCH_GUEST_PRODUCTS_STATISTICS_SUCCESS, FETCH_GUEST_PRODUCTS_STATISTICS_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guest_products/statistics.json`,
      body: {
        sort_by: sortBy
      }
    }
  };
}

export function fetchGuestProductsByNumberOfInformationRequests(eventId: string): any {
  const { FETCH_GUEST_PRODUCTS_INFORMATION_REQUESTS_STATISTICS_REQUEST, FETCH_GUEST_PRODUCTS_INFORMATION_REQUESTS_STATISTICS_SUCCESS, FETCH_GUEST_PRODUCTS_INFORMATION_REQUESTS_STATISTICS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_GUEST_PRODUCTS_INFORMATION_REQUESTS_STATISTICS_REQUEST, FETCH_GUEST_PRODUCTS_INFORMATION_REQUESTS_STATISTICS_SUCCESS, FETCH_GUEST_PRODUCTS_INFORMATION_REQUESTS_STATISTICS_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guest_products/information_requests_statistics.json`,
    }
  };
}

export function fetchGuestsByNumberOfInformationRequests(eventId: string): any {
  const { FETCH_GUESTS_INFORMATION_REQUESTS_STATISTICS_REQUEST, FETCH_GUESTS_INFORMATION_REQUESTS_STATISTICS_SUCCESS, FETCH_GUESTS_INFORMATION_REQUESTS_STATISTICS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_GUESTS_INFORMATION_REQUESTS_STATISTICS_REQUEST, FETCH_GUESTS_INFORMATION_REQUESTS_STATISTICS_SUCCESS, FETCH_GUESTS_INFORMATION_REQUESTS_STATISTICS_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guest_products/information_requests_statistics.json`,
      body: { group_by: "guest" }
    }
  };
}
