import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGuestProductCollections } from "../../actions/GuestProductCollectionsActionCreators";
import { urlEventId } from "../../utils/pathUtils";
import FilterDropdown from "../FilterDropdown.react";

export interface Props {
  label: string;
  value?: string;
  helpMessage?: string;

  onChange: (value: string) => void;
}

const GuestProductCollectionPicker: React.FC<Props> = ({ value, label, helpMessage, onChange }) => {
  const guestProductCollections = useSelector((state: any) => state.guestProductCollections.data);
  const isFetchingGuestProductCollections = useSelector((state: any) => state.guestProductCollections.isFetching);

  const dispatch = useDispatch();

  useEffect(() => {
    if (guestProductCollections === null && !isFetchingGuestProductCollections) dispatch(fetchGuestProductCollections(urlEventId()));
  }, []);

  const fullGuestProductCollections = [{ _id: null, name: I18n.t("react.guest_product_collection_picker.products") }, ...guestProductCollections];
  const selectedItem = (value === "") ? fullGuestProductCollections[0] : fullGuestProductCollections.find(item => item._id == value);

  return <div className="mb-3">
    <label className="form-label">{ label }</label>
    <div>
      <FilterDropdown
        id="guest_product_collection_picker"
        translationKey="guest_product_collection_picker" // must be same as id, used by FilterDropdown.handleFilterPlacement
        items={fullGuestProductCollections}
        selectedItemIds={ [selectedItem?._id] }
        showCells={false}
        hasSelectAll={false}
        multipleSelect={false}
        sortItems={false}
        itemIdKey="_id"
        onChange={onChange}
        title={selectedItem?.name || I18n.t("react.guest_product_collection_picker.products")}
      />
    </div>
    <div className="form-text">{ helpMessage }</div>
  </div>;
};

export default GuestProductCollectionPicker;
