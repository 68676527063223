import { ActionTypes, CALL_API } from "../constants/Constants";

export function fetchLabels(eventId) {
  const { DEV_NULL, RECEIVE_LABELS_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_LABELS_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/labels.json`
    }
  };
}

export function fetchLabelsStats(eventId, query_string) {
  const { DEV_NULL, LABELS_STATS_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, LABELS_STATS_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guests/statistics.json?stat_types=count&field=label_ids&q=${query_string}`
    }
  };
}

export function createLabel(eventId, name, color) {
  const { ADD_LABEL_REQUEST, ADD_LABEL_SUCCESS, LABEL_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [ADD_LABEL_REQUEST, ADD_LABEL_SUCCESS, LABEL_FAILURE],
      method: "POST",
      body: { name, color },
      endpoint: `/api/v1/events/${eventId}/labels.json`
    }
  };
}

export function updateLabel(eventId, labelId, name, color) {
  const { UPDATE_LABEL_REQUEST, UPDATE_LABEL_SUCCESS, LABEL_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_LABEL_REQUEST, UPDATE_LABEL_SUCCESS, LABEL_FAILURE],
      method: "PUT",
      body: { name, color },
      endpoint: `/api/v1/events/${eventId}/labels/${labelId}.json`
    },
    name,
    color,
    labelId
  };
}

export function deleteLabel(eventId, labelId) {
  const { DEV_NULL, REMOVE_LABEL_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, REMOVE_LABEL_SUCCESS, DEV_NULL],
      method: "DELETE",
      endpoint: `/api/v1/events/${eventId}/labels/${labelId}.json`
    },
    labelId
  };
}

export function setSelectedGuestLabelIds(ids) {
  return {
    type: ActionTypes.SET_SELECTED_GUEST_LABEL_IDS,
    objectIds: ids
  };
}

export function clearLabelStats() {
  return {
    type: ActionTypes.LABELS_STATS_CLEAR
  };
}
