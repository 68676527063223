import { useState } from "react";
import HasMeetingAvailabilitiesForm from "../../../containers/engagement/HasMeetingAvailabilitiesForm.react";
import { GuestMeetingAvailability } from "../../../guest_meeting_availabilities/types/GuestMeetingAvailability";
import { EventStoreProps } from "../../../types/Event";
import { GuestCategory } from "../../../types/GuestCategory";
import { HasMeetingAvailabilities, HasMeetingAvailabilitiesType } from "../../../types/HasMeetingAvailabilities";
import HelpSection from "../../shared/HelpSection.react";
import MeetingAvailabilitiesSummary from "./MeetingAvailabilitiesSummary.react";
import GuestCategoryLabel from "../../../components/guest_category/GuestCategoryLabel.react";
import truncate from "lodash/truncate";

interface Props {
  event: EventStoreProps;
  guestCategories: GuestCategory[];
  networkingMeetingsType: "physical"|"virtual"|"physical_or_virtual";
}

const i18n = (key: string, opts = {}): string => {
  return I18n.t(`react.networking.meetings.default_availabilities.${key}`, opts);
};

const DefaultAvailabilitiesSettings: React.FC<Props> = ({
  event,
  guestCategories,
  networkingMeetingsType
}) => {
  const [categoryIdsToConfigure, setCategoryIdsToConfigure] = useState([]);

  const categoriesWaitingToBeConfigured = guestCategories.filter(c => {
    return !c.meeting_availabilities && !categoryIdsToConfigure.includes(c._id);
  });


  const categoriesReadyToBeConfigured = guestCategories.filter(c => {
    return c.meeting_availabilities || categoryIdsToConfigure.includes(c._id);
  });

  const categoriesWaitingToBeConfiguredSelect = categoriesWaitingToBeConfigured.length === 0 ? (
    null
  ) : (
    <select
      className="form-control"
      value="empty"
      style={{
        marginLeft: "5px",
        display: "inline-block",
        width: "300px"
      }}
      onChange={(e): void => {
        if (e.target.value === "empty") return;

        setCategoryIdsToConfigure([...categoryIdsToConfigure, e.target.value]);
      }}
    >
      <option value="empty" key="empty">{I18n.t("please_choose")}</option>
      {categoriesWaitingToBeConfigured.map(category => {
        return <option key={`select-${category._id}`} value={category._id}>
          {category.name}
        </option>;
      })}
    </select>
  );

  const renderHasMeetingAvailability = (
    slidingPaneTitle: JSX.Element|string,
    hasMeetingAvailability: HasMeetingAvailabilities,
    type: HasMeetingAvailabilitiesType,
    defaults: GuestMeetingAvailability[]
  ): JSX.Element => {
    return <>
      <HasMeetingAvailabilitiesForm
        event={event}
        slidingPaneTitle={slidingPaneTitle}
        hasMeetingAvailabilities={hasMeetingAvailability}
        defaultMeetingAvailabilities={defaults}
        type={type}
        startDate={event.start_date}
        endDate={event.end_date}
        meetingTypes={networkingMeetingsType}
        onDestroyedMeetingAvailabilities={(): void => {
          if (type === "guest_category") {
            setCategoryIdsToConfigure(categoryIdsToConfigure.filter(id => id !== hasMeetingAvailability._id));
          }
        }}
      />

      <MeetingAvailabilitiesSummary
        meetingAvailabilities={hasMeetingAvailability.meeting_availabilities}
        type={type}
      />
    </>;
  };

  const renderSlidingPaneTitleForCategory = (category: GuestCategory): JSX.Element => {
    return (
      <div className="d-flex">
        <span className="d-flex align-items-center mr-5">
          {i18n("category_default")}
        </span>
        <span className="badge rounded-pill big guest-category" style={{ backgroundColor: category.label_color }}>
          {truncate(category.name, { "length": 60 })}
        </span>
      </div>
    );
  };

  return <>
    <HelpSection help={i18n("help")} />

    <div className="mb-3">
      <label className="mr-5">{i18n("event")}</label>
      {renderHasMeetingAvailability(
        i18n("event_default"),
        event,
        "event",
        []
      )}
    </div>

    {categoriesReadyToBeConfigured.map(category => {
      return (
        <div className="mb-3" key={`meeting-availability-${category._id}`} style={{ marginTop: "30px" }}>
          <label className="mr-5">
            {i18n("category")} <GuestCategoryLabel guestCategory={category} isLink={false} />
          </label>

          {renderHasMeetingAvailability(
            renderSlidingPaneTitleForCategory(category),
            category,
            "guest_category",
            event.meeting_availabilities || []
          )}
        </div>
      );
    })}

    <div className="flex-wrap d-flex align-items-center mt-20">
      {i18n("add_default_for_category")} {categoriesWaitingToBeConfiguredSelect}
    </div>
  </>;
};

export default DefaultAvailabilitiesSettings;
