import React, { useState, useEffect, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pathToIntegrationsList } from "../../utils/pathUtils";
import { updateEvent } from "../../actions/EventActionCreators";
import Loader from "../../components/shared/Loader.react";
import { SeatsioClient, Region } from "seatsio";
import { Event } from "../../types/Event";
import IntegrationHeader from "../../components/integrations/IntegrationHeader.react";

interface RootState {
  event: Event;
}

interface SeatsioFormProps {
  noPanel?: boolean;
  onChange?(eventAttributes: any): void;
}

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.event_seatsio_form.${key}`, opts);
}

const SeatsioForm: React.FC<SeatsioFormProps> = ({ noPanel, onChange }) => {
  const dispatch = useDispatch();

  const event = useSelector((state: RootState) => state.event);

  const [seatsioApiKey, setSeatsioApiKey] = useState("");
  const [seatsioPublicWorkspaceKey, setSeatsioPublicWorkspaceKey] = useState("");
  const [seatsioSecretWorkspaceKey, setSeatsioSecretWorkspaceKey] = useState("");
  const [seatsioConfigurationValid, setSeatsioConfigurationValid] = useState<boolean | null>(null);
  const [seatsioConfigurationError, setSeatsioConfigurationError] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (event.fetched && initialLoad) {
      setSeatsioApiKey(event.seatsio_api_key || "");
      setSeatsioPublicWorkspaceKey(event.seatsio_public_workspace_key || "");
      setSeatsioSecretWorkspaceKey(event.seatsio_secret_workspace_key || "");
      setInitialLoad(false);
      checkConfiguration();
    }
  }, [event]);

  useEffect(() => {
    if (seatsioApiKey && seatsioPublicWorkspaceKey) {
      setTimeout(() => {
        retrieveSecretKey();
      }, 1000);
    }
  }, [seatsioApiKey, seatsioPublicWorkspaceKey]);

  const retrieveWorkspacePromise = (): Promise<any> => {
    const client = new SeatsioClient(Region.EU(), seatsioApiKey);
    return client.workspaces.retrieve(seatsioPublicWorkspaceKey);
  };

  const checkConfiguration = (): void => {
    if (configurationIsEmpty()) return;

    retrieveWorkspacePromise().then((workspace) => {
      if (workspace.secretKey === seatsioSecretWorkspaceKey) {
        setValidConfiguration();
      } else {
        setInvalidConfiguration(["Wrong secret key"]);
      }
    }, (errorResponse) => {
      setInvalidConfiguration(errorResponse.messages);
    });
  };

  const retrieveSecretKey = (): void => {
    if (configurationIsEmpty()) {
      setSeatsioSecretWorkspaceKey("");
      setSeatsioConfigurationValid(null);
      return;
    }

    retrieveWorkspacePromise().then((workspace) => {
      setSeatsioSecretWorkspaceKey(workspace.secretKey);
      sendEventAttributes();
      setValidConfiguration();
    }, (errorResponse) => {
      setSeatsioSecretWorkspaceKey("");
      sendEventAttributes();
      setInvalidConfiguration(errorResponse.messages);
    });
  };

  const sendEventAttributes = (): void => {
    if (onChange) {
      const eventAttributes = {
        seatsio_api_key: seatsioApiKey,
        seatsio_public_workspace_key: seatsioPublicWorkspaceKey,
        seatsio_secret_workspace_key: seatsioSecretWorkspaceKey
      };
      onChange(eventAttributes);
    }
  };

  const configurationIsEmpty = (): boolean => {
    return seatsioApiKey === "" || seatsioPublicWorkspaceKey === "";
  };

  const setValidConfiguration = (): void => {
    setSeatsioConfigurationValid(true);
    setSeatsioConfigurationError("");
  };

  const setInvalidConfiguration = (errors: string[] = []): void => {
    setSeatsioConfigurationValid(false);
    setSeatsioConfigurationError(errors ? errors.join(". ") : "");
  };

  const changeTextField = (fieldKey: string) => (e: ChangeEvent<any>): void => {
    const value = e.target.value;
    if (fieldKey === "seatsioApiKey") {
      setSeatsioApiKey(value);
    } else if (fieldKey === "seatsioPublicWorkspaceKey") {
      setSeatsioPublicWorkspaceKey(value);
    }
    sendEventAttributes();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const eventAttributes = {
      seatsio_api_key: seatsioApiKey,
      seatsio_public_workspace_key: seatsioPublicWorkspaceKey,
      seatsio_secret_workspace_key: seatsioSecretWorkspaceKey
    };
    if (onChange) {
      onChange(eventAttributes);
    } else {
      const notificationsOptions = { notice: i18n("saved_successfully"), noticeType: "success" };
      dispatch(updateEvent(event.id, eventAttributes, pathToIntegrationsList(), notificationsOptions));
    }
  };

  const renderConfigurationValidationState = (): JSX.Element => {
    if (seatsioConfigurationValid === null) return null;

    if (seatsioConfigurationValid) {
      return <p className="text-success"><i className="fa-regular fa-check-circle" /> {i18n("valid_configuration")}</p>;
    } else {
      return <p className="text-danger"><i className="fa-regular fa-circle-xmark" /> {i18n("invalid_configuration")}: <em>{seatsioConfigurationError}</em></p>;
    }
  };

  const renderFormFields = (): JSX.Element => (
    <>
      <div className="mb-3">
        <label className="form-label">{i18n("api_key")}</label>
        <input className="form-control" name="event[seatsio_api_key]" type="text" value={seatsioApiKey} onChange={changeTextField("seatsioApiKey")} />
      </div>
      <div className="mb-3">
        <label className="form-label">{i18n("public_workspace_key")}</label>
        <input className="form-control" name="event[seatsio_public_workspace_key]" type="text" value={seatsioPublicWorkspaceKey} onChange={changeTextField("seatsioPublicWorkspaceKey")} />
      </div>
      {seatsioSecretWorkspaceKey !== "" ?
        <div className="mb-3">
          <label className="form-label">{i18n("secret_workspace_key")}</label>
          <input className="form-control" name="event[seatsio_secret_workspace_key]" type="text" value={seatsioSecretWorkspaceKey} readOnly />
        </div>
        : <input type="hidden" value="" name="event[seatsio_secret_workspace_key]" />
      }
    </>
  );

  const renderFormContent = (): JSX.Element => {
    if (!event.fetched) {
      return <Loader />;
    }

    if (noPanel) {
      return (
        <>
          {renderFormFields()}
          {renderConfigurationValidationState()}
        </>
      );
    }

    return (
      <div className="col-md-8 offset-md-2">
        <form onSubmit={handleSubmit}>
          <div className="card">
            <div className="border-bottom border-light">
              <h5 className="fs-4 border-bottom py-2 px-3" style={{ borderColor: "#F1F5F9" }}>{I18n.t("react.integrations.configuration")}</h5>
            </div>
            <div className="card-body">
              {renderConfigurationValidationState()}
              {renderFormFields()}
            </div>
          </div>
          <button type="submit" className="btn btn-primary float-end btn-lg" >{I18n.t("save")}</button>
        </form>
      </div>
    );
  };

  return (
    <>
      {!noPanel && <IntegrationHeader integrationKey="seatsio" />}
      {renderFormContent()}
    </>
  );
};

export default SeatsioForm;
