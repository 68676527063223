import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { components, OptionProps, SingleValueProps, MultiValueContainerProps, MultiValueLabelProps } from "react-select";
import { fetchLabels } from "../../actions/LabelsActionCreators";
import { urlEventId } from "../../utils/pathUtils";
import { multiValueRemoveStyle } from "../../constants/Style";

const customStyles = {
  multiValue: (base: any, state: any): any => ({
    ...base,
    backgroundColor: state.data.color,
    fontSize: "100%"
  }),
  multiValueRemove: (base: any): any => ({
    ...base,
    ...multiValueRemoveStyle
  })
};

const Option = (props: OptionProps): JSX.Element => {
  const { data } = props;

  return <components.Option {...props}>
    <span className="badge rounded-pill" style={{ backgroundColor: data.color }}>
      {data.label}
    </span>
  </components.Option>;
};

const SingleValue = (props: SingleValueProps): JSX.Element => {
  const { data } = props;

  return <components.SingleValue {...props}>
    <span className="badge rounded-pill" style={{ backgroundColor: data.color }}>
      {data.label}
    </span>
  </components.SingleValue>;
};

const LabelMultiValueContainer = (props: MultiValueContainerProps): JSX.Element => {
  const innerProps = { ...props.innerProps, className: props.innerProps.className.concat(" badge rounded-pill") };
  const newProps = { ...props, innerProps };
  return <components.MultiValueContainer {...newProps} />;
};

const LabelMultiValueLabel = (props: MultiValueLabelProps): JSX.Element => {
  return <span>{props.data.label}</span>;
};

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.labels_dropdown.${key}`, opts);
}

interface Props {
  selectedValue: string[];
  isMulti?: boolean;
  isDisabled?: boolean;

  onChange(values: string[]): void;
}

const LabelDropdown: React.FC<Props> = ({ selectedValue, isMulti, isDisabled, onChange }) => {
  const labels = useSelector((state: any) => state.labels.items);
  const dispatch = useDispatch();

  useEffect(() => { dispatch(fetchLabels(urlEventId())); }, []);

  const labelsOptions = (): any[] => {
    if (!labels) return [];

    return labels.map((label) => {
      return {
        value: label._id,
        label: label.name,
        color: label.color,
      };
    });
  };

  const options = labelsOptions();
  const selectedLabels = selectedValue ? options.filter((label) => selectedValue.includes(label.value)) : [];

  const props = {
    className: "react-select",
    classNamePrefix: "react-select",
    closeMenuOnSelect: !isMulti,
    components: {
      Option,
      SingleValue,
      MultiValueContainer: LabelMultiValueContainer,
      MultiValueLabel: LabelMultiValueLabel
    },
    hideSelectedOptions: false,
    isClearable: true,
    isMulti,
    isDisabled,
    noOptionsMessage: (): string => i18n("no_options"),
    onChange: (selectedOption): void => onChange(selectedOption),
    options,
    placeholder: i18n("select_placeholder"),
    styles: customStyles,
    value: selectedLabels
  };

  return <Select {...props} />;
};

export default LabelDropdown;
