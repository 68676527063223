"use strict";
import { Component } from "react";
import ModalContainer from "./ModalContainer.react";

export default function withModal(WrappedComponent, options = {}) {
  return class WithModal extends Component {
    render() {
      return <ModalContainer {...options}><WrappedComponent {...this.props}/></ModalContainer>;
    }
  };
}