export function shouldBeDisplayed(accesspoints, formItem, accesspointId) {
  const { options } = formItem;
  const filter = (options && options.filter) ? options.filter : null;
  if (!filter) {
    return true;
  }
  const accesspoint = accesspoints.filter(acc => {
    return acc.id == accesspointId;
  })[0];
  if (accesspoint) {
    return accesspoint.traits[filter.key] && accesspoint.traits[filter.key] == filter.value;
  } else {
    return false;
  }
}

export function sortAccesspoints(accesspoints, formItem) {
  const { options } = formItem;
  const order = (options && options.order) ? options.order : {};
  const key = Object.keys(order)[0];
  if (!key) {
    return accesspoints;
  }
  const compare = (a, b) => {
    const alpha = a.traits[key];
    const beta = b.traits[key];
    if (!alpha) {
      return -1;
    }
    if (!beta) {
      return 1;
    }
    if (alpha == beta) return 0;
    return alpha > beta ? 1 : -1;
  };
  const accesspointsFiltered = accesspoints.slice();
  return accesspointsFiltered.sort(compare);
}

export function accesspointsNotIncludedInGuestCategory(accesspoints, guestCategoryId) {
  return accesspoints.filter((ap) => {
    if (ap.guest_category_accesspoints) {
      const guestCategoryAccesspoint = ap.guest_category_accesspoints.find(gca => gca.guest_category_id === guestCategoryId);
      return !guestCategoryAccesspoint;
    }
    return true;
  });
}

export function optionsToDisplay(accesspoints, formItem) {
  const advancedMode = formItem.options.advanced_mode || false;
  return (advancedMode) ? accesspointDisplayed(accesspoints, formItem) : formItemOptionDisplayed(accesspoints, formItem);
}

function accesspointDisplayed(accesspoints, formItem) {
  const accesspointsFiltered = accesspoints.filter(accesspoint => {
    return shouldBeDisplayed(accesspoints, formItem, accesspoint.id);
  });
  return accesspointsFiltered.map(accesspoint => {
    //value does not exist in accesspoint model
    return { key: accesspoint.id, value: false, label: accesspoint.name };
  });
}

function formItemOptionDisplayed(accesspoints, formItem) {
  const { form_item_options } = formItem;
  return form_item_options.filter(item => {
    return shouldBeDisplayed(accesspoints, formItem, item.key);
  });
}
