import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SlidingPane from "react-sliding-pane";
import FilterDropdown from "../FilterDropdown.react";
import ProgramsContainer from "../../containers/ProgramsContainer.react";
import Loader from "./Loader.react";
import { fetchPrograms } from "../../actions/ProgramsActionCreators";
import { Program } from "../../types/Program";
import { urlEventId } from "../../utils/pathUtils";
import Icons from "../../constants/Icons";

interface Props {
  label: string;
  selectedValue: string;

  onSelect(values: string[]): void;
  reloadPreview(): void;
}

const ProgramPicker: React.FC<Props> = ({ label, selectedValue, onSelect, reloadPreview }) => {
  const [isSlidingPaneOpen, setIsSlidingPaneOpen] = useState(false);

  const isFetching = useSelector((state: any) => state.programs.isFetching);
  const isSaving = useSelector((state: any) => state.programs.isSaving);
  const programs = useSelector((state: any) => state.programs.data);
  const errors = useSelector((state: any) => state.programs.errors);
  const lastAction = useSelector((state: any) => state.programs.lastAction);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!programs) dispatch(fetchPrograms(urlEventId()));
  }, []);

  const toggleSlidingPane = (): void => {
    setIsSlidingPaneOpen(!isSlidingPaneOpen);
  };

  useEffect(() => {
    if (isSaving || errors || lastAction !== "update") return;

    reloadPreview();
    setTimeout(() => { setIsSlidingPaneOpen(false); }, 800); // let the smooth scroll top be done
  }, [isSaving]);

  const renderProgramPane = (title: string): JSX.Element => {
    if (!selectedValue) return null;

    return <div className="ml-5">
      <a className="btn btn-secondary" onClick={toggleSlidingPane}>
        <i className="fa-regular fa-pencil" aria-hidden="true" />
      </a>
      <SlidingPane
        isOpen={isSlidingPaneOpen}
        width="60%"
        title={title}
        onRequestClose={toggleSlidingPane}
        closeIcon={Icons.close()}>
        <ProgramsContainer inPane={true} programId={selectedValue} />
      </SlidingPane>
    </div>;
  };

  if (isFetching || !programs) {
    return <Loader size="small" />;
  }

  const dropdownItems = programs.map((program: Program) => {
    return { _id: program._id, name: program.name };
  });

  const title = programs.find(program => program._id === selectedValue)?.name;

  return <div className="mb-3">
    <label className="form-label">{label}</label>
    <div className="d-flex">
      <div className="col-auto">
        <FilterDropdown
          id="programs"
          translationKey="programs"
          items={dropdownItems}
          selectedItemIds={[selectedValue]}
          showCells={false}
          hasSelectAll={false}
          multipleSelect={false}
          sortItems={false}
          itemIdKey="_id"
          onChange={(values: string[]): void => onSelect(values)}
          title={title}
        />
      </div>
      {renderProgramPane(title)}
    </div>
  </div>;
};

export default ProgramPicker;
