import { Component } from "react";
import { connect } from "react-redux";
import { updateEmailSelectedId } from "../actions/EmailBuilderActionCreators";
import EmailTemplates from "../components/email_templates/EmailTemplates.react.js";
import UnpublishedEventAlertMessage from "../components/UnpublishedEventAlertMessage.react";

const listBodyHeight = "100%";
const toolbarHeight = "40px";
const searchbarHeight = "50px";
const borderStyle = "1px solid #E3E3E3";

class EmailTemplatesList extends Component {

  constructor(props) {
    super(props);
    [].forEach(item => { this[item] = this[item].bind(this); });
  }

  eventId() {
    return this.props.match.params.event_id;
  }

  render() {
    const { event, selectedTemplateId } = this.props;

    return (
      <div className="row templates-index">
        <div className="col-12">
          <UnpublishedEventAlertMessage event={event} />

          <div className="header-page">
            <div className="header-page-title">
              <h1>{ I18n.t("react.email_templates.title") }</h1>
            </div>
          </div>

          <EmailTemplates
            event={event}
            eventId={this.eventId()}
            borderStyle={borderStyle}
            listBodyHeight={listBodyHeight}
            toolbarHeight={toolbarHeight}
            searchbarHeight={searchbarHeight}
            enableEdit={true}
            enableDelete={true}
            iframeAutoResize={true}
            loaderContainerHeight={listBodyHeight}
            selectedTemplateId={selectedTemplateId}
            iframeCustomClass="card"
            fromEmailTemplateList={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    event: state.event,
    selectedTemplateId: state.emailTemplate.selectedTemplateId
  };
}

const mapDispatchToProps = Object.assign({}, {
  updateEmailSelectedId
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplatesList);
