import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

class ConfirmationModal extends Component {
  render() {
    const { isVisible, onClose, title, onConfirm, confirmationQuestion, confirmationWarning,
      confirmButtonClasses, modalClassName, restoreFocus, autoFocusConfirm } = this.props;

    return <Modal show={isVisible} onHide={onClose} restoreFocus={restoreFocus} className={modalClassName}>
      <Modal.Header>
        <Modal.Title>
          <div dangerouslySetInnerHTML={{ __html: title }} />
        </Modal.Title>
        <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
      </Modal.Header>
      <Modal.Body>
        <p className="thematic-overflow" dangerouslySetInnerHTML={{ __html: confirmationQuestion }} />
        {confirmationWarning && (
          <p><i className="fa-regular fa-triangle-exclamation text-warning"></i> {confirmationWarning}</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onClose}>{ I18n.t("cancel") }</button>
        <button className={`btn ${confirmButtonClasses}`} onClick={onConfirm} autoFocus={autoFocusConfirm}>{ I18n.t("continue") }</button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmationQuestion: PropTypes.string.isRequired,
  confirmButtonClasses: PropTypes.string,
  confirmationWarning: PropTypes.string,
  restoreFocus: PropTypes.bool,
  autoFocusConfirm: PropTypes.bool
};

ConfirmationModal.defaultProps = {
  isVisible: false,
  confirmButtonClasses: "btn-primary",
  autoFocusConfirm: false
};
