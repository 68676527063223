import { CALL_API, ActionTypes } from "../constants/Constants";

export function fetchSessionRooms(eventId: string): any {
  const { FETCH_SESSION_ROOMS_REQUEST, FETCH_SESSION_ROOMS_SUCCESS, FETCH_SESSION_ROOMS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_SESSION_ROOMS_REQUEST, FETCH_SESSION_ROOMS_SUCCESS, FETCH_SESSION_ROOMS_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/session_rooms.json?`
    }
  };
}
