import { ActionTypes } from "../constants/Constants";

const defaultState = {
  data: null,
  nextURL: null,
  previousURL: null,
  fetching: false
};

function events(state = defaultState, action) {
  switch (action.type) {

  case ActionTypes.FETCH_EVENTS_REQUEST: {
    return { ...state, fetching: true, nextURL: null, previousURL: null };
  }
  case ActionTypes.FETCH_EVENTS_SUCCESS: {
    const data = action.data.map(e => {
      return { ...e, id: e._id };
    });
    const newData = action.concatEvents ? (state.data || []).concat(data) : data;
    return { ...state, data: newData, nextURL: action.nextURL, previousURL: action.previousURL, fetching: false };
  }
  case ActionTypes.FETCH_GUEST_CATEGORIES_FROM_EVENT_SUCCESS: {
    return addDataToEvent(state, action, "guestCategories");
  }
  case ActionTypes.FETCH_REGISTRATION_FORMS_FROM_EVENT_SUCCESS: {
    return addDataToEvent(state, action, "registrationForms");
  }
  case ActionTypes.FETCH_EMAIL_TEMPLATES_FROM_EVENT_SUCCESS: {
    return addDataToEvent(state, action, "emailTemplates");
  }
  case ActionTypes.FETCH_DOCUMENT_TEMPLATES_FROM_EVENT_SUCCESS: {
    return addDataToEvent(state, action, "documentTemplates");
  }
  default:
    return state;
  }
}

function addDataToEvent(state, action, stateKey) {
  const eventId = action.eventId || action.data[0] && action.data[0].event_id;
  let newState = Object.assign({}, state);
  if (newState.data) {
    const eventIndex = newState.data.findIndex((ev) => {
      return ev._id == eventId;
    });
    newState.data = newState.data.slice();
    newState.data[eventIndex] = Object.assign({}, newState.data[eventIndex], { [stateKey]: action.data });
  }

  return newState;
}

export default events;
