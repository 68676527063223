import Select, { components, OptionProps, MultiValueContainerProps, MultiValueLabelProps } from "react-select";
import { WORKFLOW_RUN_STATUSES } from "../../constants/Constants";
import { multiValueRemoveStyle } from "../../constants/Style";

const customStyles = {
  multiValueRemove: (base: any): any => ({
    ...base,
    ...multiValueRemoveStyle
  })
};

const Option = (props: OptionProps): JSX.Element => {
  const { data } = props;

  return <components.Option {...props}>
    <span className={`badge rounded-pill ${data.backgroundClass}`}>{data.label}</span>
  </components.Option>;
};

const StatusMultiValueContainer = (props: MultiValueContainerProps): JSX.Element => {
  const { data } = props;
  const innerProps = { ...props.innerProps, className: props.innerProps.className.concat(` badge rounded-pill ${data.backgroundClass}`) };
  const newProps = { ...props, innerProps };
  return <components.MultiValueContainer {...newProps} />;
};

const StatusMultiValueLabel = (props: MultiValueLabelProps): JSX.Element => {
  return <span>{props.data.label}</span>;
};

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.workflow_runs.statuses_dropdown.${key}`, opts);
}

interface Props {
  selectedValues: string[];

  onChange(values: string[]): void;
}

const WorkflowStatusDropdown: React.FC<Props> = ({ selectedValues, onChange }) => {
  const workflowStatusesOptions = (): any[] => {
    return Object.entries(WORKFLOW_RUN_STATUSES).map(([status, config]) => {
      return {
        value: status,
        label: I18n.t(`workflow_run_status.${status}`),
        backgroundClass: config["backgroundClass"],
        iconClass: config["iconClass"]
      };
    });
  };

  const options = workflowStatusesOptions();
  const selectedOptions = selectedValues ? options.filter((status) => selectedValues.includes(status.value)) : [];

  const props = {
    className: "react-select",
    classNamePrefix: "react-select",
    closeMenuOnSelect: false,
    components: {
      Option,
      MultiValueContainer: StatusMultiValueContainer,
      MultiValueLabel: StatusMultiValueLabel
    },
    hideSelectedOptions: false,
    isClearable: true,
    isMulti: true,
    onChange: (selectedOptions): void => onChange(selectedOptions),
    options,
    placeholder: i18n("all_statuses"),
    styles: customStyles,
    value: selectedOptions
  };

  return <Select {...props} />;
};

export default WorkflowStatusDropdown;
