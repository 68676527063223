import { ChangeEvent, Component } from "react";
import Loader from "../components/shared/Loader.react";
import ErrorMessage from "../components/shared/ErrorMessage.react";
import HelpSection from "../components/shared/HelpSection.react";
import SenderEmailAddressesField from "../components/SenderEmailAddressesField.react";
import EmailTemplateSelector from "../components/email_templates/EmailTemplateSelector.react";
import { EmailTemplate } from "../types/EmailTemplate";
import { Event } from "../types/Event";

interface Props {
  localCustomEmail: EmailTemplate;
  updatePending: boolean;
  event: Event;
  errors: any[];
  match: any;
  isCustom: boolean;
  defaultLiquidBodyUrl: string;
  infoFromUrlParams?: { sender: string; subject: string; reply_to_email: string; };

  submitForm(eventId: string, eventParams: any, custom: boolean): void;
  addOfflineErrors(errors: any): void;
  changeCustom(newState: any): void;
}

interface State {
  isAdvancedOptionsOpen: boolean;
  customEmail: EmailTemplate;
}

const defaultCustomEmail: EmailTemplate = {
  _id: "12345",
  name: "NEW_LEADS_EXPORT_EMAIL",
  updated_at: `${new Date()}`,
  subject: "",
  sender: "",
  body_url: "",
  email_template_id: null,
  reply_to_email: "",
  sender_email: ""
};

export class LeadsExportEmailForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    [
      "toggleAdvancedOptions",
      "toggleOption",
      "onChangeTextField",
      "onSelectTemplate",
      "onSelectSenderEmail",
      "submit"
    ].forEach(item => { this[item] = this[item].bind(this); });

    this.state = {
      isAdvancedOptionsOpen: false,
      customEmail: { ...defaultCustomEmail, ...props.infoFromUrlParams }
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { localCustomEmail } = this.props;
    if (!prevProps.localCustomEmail && localCustomEmail) {
      this.setState({ customEmail: localCustomEmail });
    }
  }

  i18n(key: string): string {
    return I18n.t(`shared.custom_email_fields.${key}`);
  }

  renderSlidingPaneTitle(): JSX.Element {
    return (
      <div className="d-flex">
        <span className="d-flex align-items-center mr-5">
          {I18n.t("react.leads_export_email.sliding_pane_email_title")} {" "}
        </span>
      </div>
    );
  }

  submitLabel(): JSX.Element {
    const { updatePending } = this.props;
    return <span>{updatePending && <Loader size="small" color="white" />} {I18n.t("save")}</span>;
  }

  submit(e: any): boolean {
    const { event, isCustom, localCustomEmail, defaultLiquidBodyUrl, submitForm } = this.props;
    const { customEmail } = this.state;
    if (e) e.preventDefault();

    if (!this.isFormValid())
      return false;

    const customEmailParams = {
      sender: customEmail.sender,
      subject: customEmail.subject,
      type: "mobinetwork_contacts_export",
      email_template_id: isCustom ? null : customEmail.email_template_id,
      body_url: isCustom ? defaultLiquidBodyUrl : customEmail.body_url,
      reply_to_email: customEmail.reply_to_email,
      sender_email: customEmail.sender_email
    };

    const id = localCustomEmail ? localCustomEmail._id : null;
    if (id !== null)
      customEmailParams["_id"] = id;

    const params = { custom_emails_attributes: [customEmailParams] };

    submitForm(event._id, params, isCustom);
  }

  isFormValid(): boolean {
    const { customEmail } = this.state;
    const { isCustom, addOfflineErrors } = this.props;
    let isValid = true;

    if (!isCustom && (!customEmail || !customEmail.email_template_id)) {
      addOfflineErrors({ email_template_id: this.i18n("choose_email_template_error") });
      isValid = false;
    }

    if (!customEmail || !customEmail.subject) {
      addOfflineErrors({ subject: this.i18n("subject_blank_error") });
      isValid = false;
    }

    return isValid;
  }

  onChangeTextField(e: ChangeEvent<HTMLInputElement>): void {
    const { customEmail } = this.state;
    this.setState({ customEmail: { ...customEmail, [e.target.name]: e.target.value } });
  }

  onSelectTemplate(emailTemplateId: string): void {
    const { customEmail } = this.state;
    this.setState({ customEmail: { ...customEmail, email_template_id: emailTemplateId } });
  }

  onSelectSenderEmail(senderEmail: string): void {
    const { customEmail } = this.state;
    this.setState({ customEmail: { ...customEmail, sender_email: senderEmail } });
  }

  toggleAdvancedOptions(): void {
    const { isAdvancedOptionsOpen } = this.state;
    this.setState({ isAdvancedOptionsOpen: !isAdvancedOptionsOpen });
  }

  toggleOption(e: any): void {
    const { changeCustom } = this.props;
    const optionNewState = { [e.target.name]: e.target.checked } as Pick<State, keyof State>;

    if (e.target.name === "custom") {
      changeCustom(optionNewState);
    }
  }

  replyToEmailPlaceholder(): string {
    const { event } = this.props;
    return event.reply_to_email;
  }

  subjectPlaceholder(): string {
    const { event } = this.props;
    return `${I18n.t("react.leads_export_email.subject")} - ${event.title}`;
  }

  senderPlaceholder(): string {
    const { event } = this.props;
    return event.organizer;
  }

  renderInfoExportUrlButton(): JSX.Element {
    return <HelpSection help={I18n.t("react.leads_export_email.help.message")} badgeText={I18n.t("react.leads_export_email.help.label")} />;
  }

  render(): JSX.Element {
    const { isAdvancedOptionsOpen, customEmail } = this.state;
    const { event, errors, match, updatePending, isCustom } = this.props;
    const senderEmailAddress = customEmail.sender_email ? customEmail.sender_email : event.sender_email_address;
    const allSenderEmailAddresses = event.all_sender_email_addresses || [];

    return <form onSubmit={this.submit} className="sliding-pane-form">
      <div className="form-container card mb-0">
        <fieldset>
          <ErrorMessage errors={errors} noLabelKeys={["email_template_id"]} model="custom_email" />
          <div className="mb-3">
            <label className="form-label">{this.i18n("subject")}</label>
            <input className="form-control" type="text" name="subject" value={customEmail.subject || ""} onChange={this.onChangeTextField} placeholder={this.subjectPlaceholder()} />
          </div>
          <div className="mb-3">
            <label className="form-label">{this.i18n("sender")}</label>
            <input className="form-control" type="text" name="sender" value={customEmail.sender || ""} onChange={this.onChangeTextField} placeholder={this.senderPlaceholder()} />
          </div>

          <EmailTemplateSelector slidingPaneTitle={this.renderSlidingPaneTitle()}
            selectedEmailTemplateId={customEmail.email_template_id}
            persistedEmailTemplateId={customEmail.email_template_id}
            onSelectTemplate={this.onSelectTemplate}
            afterEmailTemplatesClose={this.submit}
            event={event}
            enableDelete={false}
            match={match}
            location={location}
            history={history} />

          {this.renderInfoExportUrlButton()}

          <div>
            <div onClick={this.toggleAdvancedOptions} className="advanced-options-toggle d-flex justify-content-between">
              <label className="form-label mb-0">{this.i18n("advanced_options")}</label>
              <div className="d-flex flex-column justify-content-center">
                <i className={`fa-regular fa-chevron-${isAdvancedOptionsOpen ? "up" : "down"} float-end`} />
              </div>
            </div>
            <div style={{ display: isAdvancedOptionsOpen ? "block" : "none" }}>
              { window["ReactGlobalProps"]["super_admin"] &&
                <div className="mb-3 mt-3">
                  <div className="form-check">
                    <label className="form-check-label">
                      <input type="checkbox" className="form-check-input" checked={isCustom} name="custom" onChange={this.toggleOption}/>
                      {this.i18n("liquid_email")} <span className="text-warning">(admin only)</span>
                    </label>
                  </div>
                </div>
              }
              <div className="mb-3">
                <label className="form-label">{this.i18n("reply_to_email")}</label>
                <input className="form-control" type="email" name="reply_to_email" value={customEmail.reply_to_email || ""} onChange={this.onChangeTextField} placeholder={this.replyToEmailPlaceholder()} />
              </div>
              <SenderEmailAddressesField
                customEmailSenderEmail={senderEmailAddress}
                allSenderEmailAddresses={allSenderEmailAddresses || []}
                label={this.i18n("sender_email")}
                name="sender_email"
                onSelectSenderEmail={this.onSelectSenderEmail} />
            </div>
          </div>
        </fieldset>
      </div>

      <div className="text-end">
        <button type="submit" className="btn btn-primary mt-10" disabled={updatePending}>
          {this.submitLabel()}
        </button>
      </div>
    </form>;
  }
}

export default LeadsExportEmailForm;
