import { VISIBILITY_SEGMENTS_SETTINGS, VISIBILITY_PRIVATE_SETTINGS } from "../components/templates_builder/VisibilitySettings.react";

export function isVisible(settingsData: any, settingComponentsSchema: any, componentConfig: any, deepIndex = 0): boolean {
  if (deepIndex > 10) {
    throw "isVisible stack level too deep.";
  }
  const { show_if } = componentConfig;
  if (!show_if) return true;

  /* show_if format in schemas :
    - single condition:
      {
        "source_id": "< field id in schema >",
        "operator": "< == | != | =~ >",
        "value": "< value of field id in schema >"
      }

    - multiple conditions:
     [
       [
         { single condition } # AND
         { single condition } # AND
         ...
       ], # OR
       [
         { single condition } # AND,
         ...
       ], # OR
       [
         ...
       ]
     ]
  */

  if (!Array.isArray(show_if)) {
    // single condition
    return evaluateShowIfCondition(settingsData, settingComponentsSchema, show_if, deepIndex);
  }

  // multi conditions
  return evaluateShowIfOrConditions(settingsData, settingComponentsSchema, show_if as any, deepIndex);
}

function findSettingsComponent(id: any, settingComponentsSchema): any {
  const allSettingComponentsSchema = settingComponentsSchema.slice();

  allSettingComponentsSchema.push(VISIBILITY_PRIVATE_SETTINGS);
  allSettingComponentsSchema.push(VISIBILITY_SEGMENTS_SETTINGS);

  return allSettingComponentsSchema.find(comp => comp.id == id);
}

function evaluateShowIfCondition(settingsData: any, settingComponentsSchema: any, { source_id, operator, value }, deepIndex: number): boolean {
  const source = settingsData[source_id];
  const sourceComponentsSchema = findSettingsComponent(source_id, settingComponentsSchema);

  if (!sourceComponentsSchema || !isVisible(settingsData, settingComponentsSchema, sourceComponentsSchema, deepIndex + 1)) return false;
  if (operator == "==") return source == value; // do not use === otherwise null can't be used in the schema JSON
  if (operator == "!=") return source != value;
  if (operator == "=~") {
    const regex = RegExp(value);
    return regex.test(source);
  }

  return false;
}

function evaluateShowIfAndConditions(settingsData: any, settingComponentsSchema: any, conditions: any, deepIndex: number): boolean {
  // and conditions are true if none are false
  return conditions.find(cond => !evaluateShowIfCondition(settingsData, settingComponentsSchema, cond, deepIndex)) === undefined;
}

function evaluateShowIfOrConditions(settingsData, settingComponentsSchema, [conds, ...rest], deepIndex): boolean {
  if (!conds) {
    return false;
  }

  return evaluateShowIfAndConditions(settingsData, settingComponentsSchema, conds, deepIndex) || evaluateShowIfOrConditions(settingsData, settingComponentsSchema, rest as any, deepIndex);
}
