import { FONT_FAMILY_DEFAULT, FontManager, Options, OPTIONS_DEFAULTS } from "@samuelmeuli/font-manager";
import { Component } from "react";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { UI_COLORS } from "../../constants/Constants";

interface Props {
  fontGroups: string[];
  defaultValue: string;
  pickerName: string;
  notifyChange: any;
  blockKey: any;
}

interface State {
  error?: string;
}

class FontPicker extends Component<Props, State> {
  private fontManager: FontManager;

  constructor(props: Props) {
    super(props);

    this.state = {
      error: null
    };

    const options: Options = {
      pickerId: OPTIONS_DEFAULTS.pickerId,
      families: OPTIONS_DEFAULTS.families,
      categories: OPTIONS_DEFAULTS.categories,
      scripts: OPTIONS_DEFAULTS.scripts,
      variants: OPTIONS_DEFAULTS.variants,
      filter: OPTIONS_DEFAULTS.filter,
      limit: OPTIONS_DEFAULTS.limit,
      sort: OPTIONS_DEFAULTS.sort
    };

    const googleFontsApiKey = process.env.GOOGLE_FONTS_API_KEY;
    this.fontManager = new FontManager(googleFontsApiKey, FONT_FAMILY_DEFAULT, options);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ value }): void {
    const { notifyChange, blockKey, pickerName } = this.props;
    notifyChange(pickerName, value, blockKey);
  }

  componentDidMount(): void {
    this.fontManager
      .init()
      .catch((error) => {
        this.setState({ error: `Error fetching fonts from google: ${error}` });
      });
  }

  style(): any {
    return {
      option: (styles: any, { data }): any => ({ ...styles, fontFamily: data.family, fontWeight: data.weight })
    };
  }

  theme(theme: any): any {
    return {
      ...theme,
      borderRadius: 6,
      colors: {
        ...theme.colors,
        primary: UI_COLORS.brand500,
        primary25: UI_COLORS.brand50,
        primary50: UI_COLORS.brand100
      }
    };
  }

  formatGroupLabel({ label }): JSX.Element {
    return <div className="d-flex align-items-center justify-content-between">
      <span style={{ color: UI_COLORS.grey400 }}>{label}</span>
    </div>;
  }

  dropdownIndicator(props: DropdownIndicatorProps): JSX.Element {
    return <components.DropdownIndicator {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="11.6" viewBox="0 0 451.8 451.8"><path d="M225.9 354.7c-7.9 0-15.7-3-21.7-9L15.6 157.1c-12-12-12-31.5 0-43.5s31.5-12 43.4 0l166.9 166.9 166.9-166.9c12-12 31.5-12 43.4 0 12 12 12 31.5 0 43.5L247.6 345.7c-6 6-13.8 9-21.7 9z" fill="currentColor" stroke="currentColor" strokeWidth="13" strokeMiterlimit="10"/></svg>
    </components.DropdownIndicator>;
  }

  render(): JSX.Element {
    const { fontGroups, defaultValue } = this.props;
    const { error } = this.state;

    return <>
      <Select
        className="react-select"
        classNamePrefix="react-select"
        options={fontGroups}
        defaultValue={defaultValue}
        formatGroupLabel={this.formatGroupLabel}
        onChange={this.handleChange}
        components={{
          DropdownIndicator: this.dropdownIndicator
        }}
        styles={this.style()}
        theme={this.theme}
        menuPlacement="auto"
        maxMenuHeight="220"
      />
      {this.state.error && <span className="text-danger">{ error }</span>}
    </>;
  }
}

export default FontPicker;
