import { CALL_API, ActionTypes } from "../constants/Constants";

export function fetchFeatureSet(eventId: string): any {
  const { FETCH_FEATURE_SET_REQUEST, FETCH_FEATURE_SET_SUCCESS, FETCH_FEATURE_SET_FAILURE } = ActionTypes;

  return {
    [CALL_API]: {
      types: [FETCH_FEATURE_SET_REQUEST, FETCH_FEATURE_SET_SUCCESS, FETCH_FEATURE_SET_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/feature_set.json`,
    }
  };
}

export function createFeatureSet(eventId: string, body: any, notificationsOptions: any = {}): any {
  const { CREATE_FEATURE_SET_REQUEST, CREATE_FEATURE_SET_SUCCESS, CREATE_FEATURE_SET_FAILURE } = ActionTypes;

  return {
    [CALL_API]: {
      types: [CREATE_FEATURE_SET_REQUEST, CREATE_FEATURE_SET_SUCCESS, CREATE_FEATURE_SET_FAILURE],
      method: "POST",
      endpoint: `/api/v1/events/${eventId}/feature_set.json`,
      body,
      notificationsOptions,
    }
  };
}

export function updateFeatureSet(eventId: string, body: any, notificationsOptions: any = {}): any {
  const { UPDATE_FEATURE_SET_REQUEST, UPDATE_FEATURE_SET_SUCCESS, UPDATE_FEATURE_SET_FAILURE } = ActionTypes;

  return {
    [CALL_API]: {
      types: [UPDATE_FEATURE_SET_REQUEST, UPDATE_FEATURE_SET_SUCCESS, UPDATE_FEATURE_SET_FAILURE],
      method: "PUT",
      endpoint: `/api/v1/events/${eventId}/feature_set.json`,
      body,
      notificationsOptions,
    }
  };
}
