import { SavedSearch } from "../../../types/SavedSearch";
import { populationI18n } from "../../../utils/miscUtils";

// equivalent to NetworkingRule::SCOPES
const SCOPE_POPULATION = "population";
const SCOPE_SEGMENT = "segment";
const SCOPE_EVERBODY = "everybody";
const SCOPE_NOBODY = "nobody";
const SCOPES = [
  SCOPE_POPULATION,
  SCOPE_SEGMENT,
  SCOPE_EVERBODY,
  SCOPE_NOBODY
];

type Scope = "segment"|"population"|"everybody"|"nobody";

export type Rule = {
  scope: Scope,
  segment_id?: string;
  population?: string;
}

interface Props {
  populations: string[];
  segments: SavedSearch[];
  label: string;
  featureRule?: Rule;

  onChange: (rule: Rule) => void;
}

const EMPTY_VALUE = "please-choose";

const i18n = (key: string): string => {
  return I18n.t(`react.networking.networking_rules.form.rule_form.${key}`);
};

const RuleForm: React.FC<Props> = ({
  populations,
  segments,
  featureRule,
  label,
  onChange
}) => {
  const rule = featureRule || { scope: SCOPE_NOBODY };

  const onChangeHandler = (key: keyof Rule): ((e: React.ChangeEvent<HTMLSelectElement>) => void) => {
    return (e: React.ChangeEvent<HTMLSelectElement>): void => {
      const { value } = e.target;
      onChange({
        ...rule,
        [key]: value === EMPTY_VALUE ? null : value
      });
    };
  };

  const RuleScopeSelect = (): JSX.Element => {
    return <div className="col-auto">
      <select className="form-select" value={rule.scope} onChange={onChangeHandler("scope")}>
        {SCOPES.map(scope => {
          return <option key={scope} value={scope}>{i18n(scope)}</option>;
        })}
      </select>
    </div>;
  };

  const emptyOption = <option key={EMPTY_VALUE} value={EMPTY_VALUE}>{i18n("choose")}</option>;

  const RulePopulationSelect = (): JSX.Element => {
    return <div className={`col-auto ${!rule.population && "has-error"}`}>
      <select className="form-select" value={rule.population || EMPTY_VALUE} onChange={onChangeHandler("population")}>
        {[emptyOption].concat(
          populations.map(population => {
            return <option key={population} value={population}>{populationI18n(population)}</option>;
          })
        )}
      </select>
    </div>;
  };

  const RuleSegmentSelect = (): JSX.Element => {
    return <div className={`col-auto ${!rule.segment_id && "has-error"}`}>
      <select className="form-select" value={rule.segment_id || EMPTY_VALUE} onChange={onChangeHandler("segment_id")}>
        {[emptyOption].concat(
          segments.map(segment => {
            return <option key={segment._id} value={segment._id}>{segment.name}</option>;
          })
        )}
      </select>
    </div>;
  };

  return <form className="mb-3 row g-2 align-items-center">
    <div className="col-auto">{label}</div>
    <RuleScopeSelect />
    {rule.scope === SCOPE_POPULATION && <RulePopulationSelect />}
    {rule.scope === SCOPE_SEGMENT && <RuleSegmentSelect />}
  </form>;
};

export default RuleForm;
