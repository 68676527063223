import { connect } from "react-redux";
import { Component } from "react";
import { urlEventId } from "../../utils/pathUtils";
import { Modal } from "react-bootstrap";
import { GuestStatusRegistered, GuestStatusImported, CUSTOM_ERROR_SYNCHRONIZE_CONTACT_FIELD_INTO_EVENT, CUSTOM_ERROR_SYNCHRONIZE_CONTACT_INTO_EVENT } from "../../constants/Constants";
import { ContactShowWithAccountAndContactIds } from "./ContactShow.react";
import { fetchEventContactImportErrors } from "../../actions/CustomErrorsActionCreators";
import { synchronizeContactsIntoEvent } from "../../actions/EventActionCreators";
import { importGuestCategories } from "../../actions/ImportGuestCategoryActionCreators";
import querystring from "querystring";
import { urlWithQuery } from "../../utils/pathUtils";

class SynchronizeContactsIntoEventModal extends Component {
  constructor(props) {
    super(props);
    [
      "onSubmit",
      "onClose",
      "toggleContactDisplay"
    ].forEach((item) => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      status: GuestStatusRegistered,
      guestCategoryId: null,
      displayErrors: false,
      usePopulation: true
    };
  }

  componentDidMount() {
    const { fetchEventContactImportErrors } = this.props;
    fetchEventContactImportErrors(urlEventId(), { types: [CUSTOM_ERROR_SYNCHRONIZE_CONTACT_FIELD_INTO_EVENT, CUSTOM_ERROR_SYNCHRONIZE_CONTACT_INTO_EVENT] });
    this.setDefaultGuestCategoryId();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.guestCategories && this.props.guestCategories) {
      // just received categories
      this.setDefaultGuestCategoryId(this.props);
    }
  }

  setDefaultGuestCategoryId(props = this.props) {
    if (!props.guestCategories) return;

    const defaultCategory = props.guestCategories.find(cat => cat.default_category);
    if (!defaultCategory) return;

    this.setState({
      guestCategoryId: defaultCategory._id
    });
  }

  onFieldChange(field) {
    return (e) => {
      const value = e.target.value == "" ? null : e.target.value;
      this.setState({ [field]: value });
    };
  }

  statusSelect() {
    const { status } = this.state;

    return <div className="mb-3">
      <label className="form-label">{I18n.t("guest_import_operations.modal_guests_status.title")}</label>
      <div className="form-check">
        <label className="form-check-label">
          <input type="radio" className="form-check-input" checked={status == GuestStatusRegistered} value={GuestStatusRegistered} onChange={this.onFieldChange("status")}></input>
          {I18n.t("guest_import_operations.modal_guests_status.registering")}
          <div className="form-text">{I18n.t("guest_import_operations.modal_guests_status.registering_help")}</div>
        </label>
      </div>
      <div className="form-check">
        <label className="form-check-label">
          <input type="radio" className="form-check-input" checked={status == GuestStatusImported} value={GuestStatusImported} onChange={this.onFieldChange("status")}></input>
          {I18n.t("guest_import_operations.modal_guests_status.inviting")}
          <div className="form-text">{I18n.t("guest_import_operations.modal_guests_status.inviting_help")}</div>
        </label>
      </div>
    </div>;
  }

  guestCategorySelect() {
    const { guestCategories } = this.props;
    const options = guestCategories.map(category => {
      return <option value={category._id} key={category._id} >
        { category.name } { category.default_category && `(${I18n.t("guests.synchronize_contacts_into_event_modal.default")})` }
      </option>;
    });

    const { usePopulation, guestCategoryId } = this.state;
    return (
      <div>
        <div className="mb-3">
          <label htmlFor="guest-import-from-contact-guest-category-id" className="form-label">{ I18n.t("guests.synchronize_contacts_into_event_modal.category_select_label") }</label>
          <select className="form-select" id="guest-import-from-contact-guest-category-id" onChange={this.onFieldChange("guestCategoryId")} value={guestCategoryId}>
            <option value="">{ I18n.t("please_choose") }</option>
            { options }
          </select>
        </div>
        <div>
          <div className="form-check">
            <label className="form-check-label">
              <input type="checkbox" className="form-check-input" checked={usePopulation} onChange={() => this.setState({ usePopulation: !usePopulation })}/>
              { I18n.t("guests.synchronize_contacts_into_event_modal.use_population") }
              <div className="form-text">{I18n.t("guests.synchronize_contacts_into_event_modal.use_population_help")}</div>
            </label>
          </div>
        </div>
      </div>
    );
  }

  backUrl() {
    const { q } = querystring.parse(this.props.location.search.substring(1));
    return urlWithQuery(q, "guests");
  }

  onSubmit(e) {
    e.preventDefault();
    const { guestCategoryId, status, usePopulation } = this.state;
    const { synchronizeContactsIntoEvent, event } = this.props;
    const payload = {
      guest_category_id: guestCategoryId,
      status,
      use_population: usePopulation
    };
    const notice = {
      notice: I18n.t("guests.action_dropdown.import_guests_from_contacts_notice"),
      type: "success"
    };
    synchronizeContactsIntoEvent(event._id, payload, this.backUrl(), notice);
  }

  onClose() {
    this.props.history.push(this.backUrl());
  }

  renderSubmitButton() {
    const { displayErrors } = this.state;
    if (displayErrors) return null;
    const disabled = !![
      "guestCategoryId",
      "status"
    ].find(field => {
      const value = this.state[field];
      return !value || value == "";
    });
    return <button className="btn btn-primary" onClick={this.onSubmit} disabled={disabled}>
      { I18n.t("validate") }
    </button>;
  }

  toggleContactDisplay(currentContactId) {
    return (e) => {
      e.preventDefault();
      const { contactId } = this.state;
      const contactIdValue = contactId === currentContactId ? null : currentContactId;
      this.setState({ contactId: contactIdValue });
    };
  }

  closeContactModal() {
    this.setState({ contactId: null });
  }

  renderErrorMessages(error) {
    return <div key={`error_messages_for_${error._id}`}>
      { I18n.t("guests.synchronize_contacts_into_event_modal.occured_n_times", { count: error.nb_occured }) }
      <ul>
        {
          error.error_messages.map((errorMessage, index) => {
            return <li key={index}>
              {errorMessage}
              <br />
            </li>;
          })
        }
      </ul>
    </div>;
  }

  renderContactFieldError(error) {
    const { contactId } = this.state;
    if (contactId) return null;
    const guestFieldKeys = error.information.guest_field_keys || [];
    return <div key={error._id}><hr />
      <label className="form-label">{ guestFieldKeys.join(" / ") }</label>
      { this.renderErrorMessages(error) }
    </div>;
  }

  renderContactError(error) {
    const { contactId } = this.state;
    const { event } = this.props;
    const sampleContactId = error.information.contact_ids && error.information.contact_ids[0];
    if (contactId && contactId !== sampleContactId) return null;
    const contactLink = <div>
      <div className="text-center">
        <a href="#" onClick={this.toggleContactDisplay(sampleContactId)}>
          { contactId ? I18n.t("guests.synchronize_contacts_into_event_modal.back_to_errors_list") : I18n.t("guests.synchronize_contacts_into_event_modal.show_contact") }
        </a>
      </div>
      { contactId && <ContactShowWithAccountAndContactIds key={contactId} accountId={event.account_id} contactId={contactId} /> }
    </div>;

    return <div key={error._id}><hr />
      { this.renderErrorMessages(error) }
      { contactLink }
    </div>;
  }

  renderError(error) {
    switch (error.type) {
    case CUSTOM_ERROR_SYNCHRONIZE_CONTACT_FIELD_INTO_EVENT:
      return this.renderContactFieldError(error);
    case CUSTOM_ERROR_SYNCHRONIZE_CONTACT_INTO_EVENT:
      return this.renderContactError(error);
    default:
      return null;
    }
  }

  renderPreviousImportErrors() {
    const { displayErrors } = this.state;
    const { errors } = this.props;

    if (!errors || errors.length === 0) return null;
    const errorsMessages = errors.map(error => {
      return this.renderError(error);
    });
    return [
      <div className="alert alert-danger">
        <p>{ I18n.t("guests.synchronize_contacts_into_event_modal.previous_import_contains_errors") }</p>
        <a href="#" onClick={() => this.setState({ displayErrors: !displayErrors })}>
          { displayErrors ? I18n.t("guests.synchronize_contacts_into_event_modal.click_here_to_hide") : I18n.t("guests.synchronize_contacts_into_event_modal.click_here_to_display") }
        </a>
      </div>,
      displayErrors ? errorsMessages : null
    ];
  }

  renderImportForm() {
    const { displayErrors } = this.state;
    if (displayErrors) return null;
    return [
      this.statusSelect(),
      this.guestCategorySelect()
    ];
  }

  renderFooter() {
    const { displayErrors } = this.state;
    if (displayErrors) return null;

    return <Modal.Footer>
      { this.renderSubmitButton() }
    </Modal.Footer>;
  }

  render() {
    return <div>
      <Modal show={true} onHide={this.onClose} size="lg" >
        <Modal.Header>
          <Modal.Title>
            { I18n.t("react.synchronize_contacts_into_event") }
            { " " }
            <small className="text-warning">{ I18n.t("admin_only") }</small>
          </Modal.Title>
          <button type="button" onClick={this.onClose} className="btn-close" aria-label={I18n.t("close")}></button>
        </Modal.Header>
        <Modal.Body>
          <div className="row" style={{ overflow: "auto", maxHeight: "80vh" }}>
            <div className="col-sm-12">
              { this.renderPreviousImportErrors() }
              { this.renderImportForm() }
            </div>
          </div>
        </Modal.Body>
        { this.renderFooter() }
      </Modal>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    event: state.event,
    errors: state.event.importContactErrors,
    guestCategories: state.guestCategories.data == [] ? null : state.guestCategories.data
  };
}

const mapDispatchToProps = {
  fetchEventContactImportErrors,
  importGuestCategories,
  synchronizeContactsIntoEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(SynchronizeContactsIntoEventModal);
