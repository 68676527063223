import "../require-babel-polyfill.js";
import Modal from "react-modal";
import * as React from "react";
import ReactDOM from "react-dom";

window.React = React; // export for http://fb.me/react-devtools
import Root from "../containers/Root.react";

import configureStore from "../store/configureStore";
import moment from "moment";

moment.locale(I18n.locale);

const store = configureStore();

const anchors = Array.from(document.getElementsByClassName("react-anchor"));
const reactElement = document.getElementById("react");

if (anchors.length > 0) {
  anchors.forEach(anchor => {
    const component = anchor.dataset.reactComponent;
    const componentProps = JSON.parse(anchor.dataset.reactComponentProps || "{}");
    ReactDOM.render(<Root store={store} component={component} defaultHtmlContent={anchor.innerHTML} componentProps={componentProps} />, anchor);
    Modal.setAppElement(anchor);
  });
} else if (reactElement) {
  ReactDOM.render(<Root store={store} />, reactElement);
  Modal.setAppElement("#react");
}

if (module.hot) {
  module.hot.accept();
}
