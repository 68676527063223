import { ActionTypes } from "../constants/Constants";
import { WorkflowVersionStore, Workflow, WorkflowStep, WorkflowVersion, Context } from "../types/Workflow";
import { workflowStepHasMissingPath } from "../utils/workflowUtils";

const defaultState: WorkflowVersionStore = {
  workflow: null,
  workflowVersion: null,
  workflowVersions: null,
  orderedSteps: [],
  activeStepId: null,
  errors: {},
  isPendingRequest: false,
  isPendingStrategyRequest: false,
  isSwitchingVersion: false,
  strategiesGlobalConfigParams: {},
  context: {},
  strategyChanged: false
};

function workflowVersionBuilder(state = defaultState, action: any): WorkflowVersionStore {
  let orderedSteps: WorkflowStep[],
    previousStepIndex: number,
    workflow: Workflow,
    workflowVersion: WorkflowVersion,
    context: Context,
    errors: any;

  switch (action.type) {
  case ActionTypes.FETCH_WORKFLOW_SUCCESS:
    workflowVersion = action.data.current_workflow_version;
    context = action.data.current_workflow_version.ordered_steps.reduce((acc, step: WorkflowStep) => {
      acc[step._id] = step.data_out;
      return acc;
    }, {});
    orderedSteps = action.data.current_workflow_version.ordered_steps.map((step: WorkflowStep, index: number) => {
      if (workflowStepHasMissingPath(step, context)) {
        return { ...step, rank: index + 1, errors: { "base": I18n.t("react.workflows.settings.missing_path_error") } };
      }
      return { ...step, rank: index + 1, errors: {} };
    });
    delete action.data.current_workflow_version;
    return { ...state, workflow: action.data, workflowVersion, orderedSteps, context };
  case ActionTypes.UPDATE_WORKFLOW_SUCCESS:
    return { ...state, workflow: action.data, errors: {}, isPendingRequest: false };
  case ActionTypes.FETCH_WORKFLOW_VERSION_REQUEST:
  case ActionTypes.CREATE_WORKFLOW_VERSION_PENDING:
    return { ...state, isSwitchingVersion: true };
  case ActionTypes.FETCH_WORKFLOW_VERSION_SUCCESS:
    context = action.data.ordered_steps?.reduce((acc, step: WorkflowStep) => {
      acc[step._id] = step.data_out;
      return acc;
    }, {});
    orderedSteps = (action.data.ordered_steps || []).map((step: WorkflowStep, index: number) => {
      return { ...step, rank: index + 1 };
    });
    delete action.data.ordered_steps;
    return { ...state, workflowVersion: action.data, orderedSteps, context, isSwitchingVersion: false };
  case ActionTypes.ADD_TRIGGER_STEP_OFFLINE:
    return { ...state, orderedSteps: [action.data, ...state.orderedSteps], activeStepId: action.data.temporaryId };
  case ActionTypes.ADD_ACTION_STEP_OFFLINE:
  case ActionTypes.ADD_FILTER_STEP_OFFLINE:
    previousStepIndex = state.orderedSteps.findIndex((step: WorkflowStep) => step._id === action.data.previous_step_id);
    orderedSteps = [...state.orderedSteps];
    orderedSteps.splice(previousStepIndex + 1, 0, action.data);
    orderedSteps = orderedSteps.map((step: WorkflowStep, index: number) => {
      if (step._id === action.data.previous_step_id) {
        return { ...step, rank: index + 1, next_step_id: action.data.temporaryId };
      } else {
        return { ...step, rank: index + 1 };
      }
    });
    return { ...state, orderedSteps, activeStepId: action.data.temporaryId };
  case ActionTypes.CREATE_WORKFLOW_STEP_SUCCESS:
    context = { ...state.context };
    orderedSteps = state.orderedSteps.map((step: WorkflowStep) => {
      if (action.temporaryId && action.temporaryId === step.temporaryId) {
        context[action.data._id] = action.data.data_out;
        return { ...action.data, rank: step.rank, errors: {} };
      } else if (step.next_step_id === action.temporaryId) {
        return { ...step, next_step_id: action.data._id };
      } else {
        return { ...step };
      }
    });
    return { ...state, orderedSteps, isPendingRequest: false, activeStepId: action.data._id, context };
  case ActionTypes.CHANGE_STEP_STRATEGY:
    orderedSteps = state.orderedSteps.map((step: WorkflowStep) => {
      if ((step._id && state.activeStepId === step._id) || (step.temporaryId && state.activeStepId === step.temporaryId)) {
        return { ...step, strategy: action.strategy, settings: {}, hasChanges: true };
      } else {
        return { ...step };
      }
    });
    return { ...state, orderedSteps, strategyChanged: true };
  case ActionTypes.UPDATE_WORKFLOW_STEP_SUCCESS:
    context = { ...state.context };
    orderedSteps = state.orderedSteps.map((step: WorkflowStep) => {
      if (action.workflowStepId && action.workflowStepId === step._id) {
        if (state.strategyChanged) {
          context[action.workflowStepId] = action.data.data_out;
        }
        return { ...step, ...action.data, hasChanges: false, errors: {} };
      } else {
        return { ...step };
      }
    });
    if (state.strategyChanged) {
      orderedSteps = orderedSteps.map((step: WorkflowStep) => {
        if (workflowStepHasMissingPath(step, context)) {
          return { ...step, errors: { "base": I18n.t("react.workflows.settings.missing_path_error") } };
        }
        return { ...step, errors: {} };
      });
    }
    return { ...state, orderedSteps, isPendingRequest: false, context, strategyChanged: false };
  case ActionTypes.CREATE_WORKFLOW_STEP_FAILURE:
  case ActionTypes.UPDATE_WORKFLOW_STEP_FAILURE:
    orderedSteps = state.orderedSteps.map((step) => {
      if (action.workflowStepId && action.workflowStepId === step._id || action.temporaryId && action.temporaryId === step.temporaryId) {
        return { ...step, errors: action.data.errors || action.data };
      } else {
        return { ...step };
      }
    });
    return { ...state, orderedSteps, isPendingRequest: false };
  case ActionTypes.DELETE_WORKFLOW_STEP_SUCCESS:
    context = { ...state.context };
    delete context[action.workflowStepId];

    orderedSteps = state.orderedSteps.filter((step: WorkflowStep) => {
      return ![step._id, step.temporaryId].includes(action.workflowStepId);
    }).map((step: WorkflowStep, index: number) => {
      errors = {};
      if (workflowStepHasMissingPath(step, context)) {
        errors["base"] = I18n.t("react.workflows.settings.missing_path_error");
      }
      if (step.next_step_id === action.workflowStepId) {
        return { ...step, rank: index + 1, next_step_id: action.nextStepId, errors };
      } else {
        return { ...step, rank: index + 1, errors };
      }
    });
    return { ...state, orderedSteps, isPendingRequest: false, context };
  case ActionTypes.PUBLISH_WORKFLOW_VERSION_SUCCESS:
    workflowVersion = { ...state.workflowVersion, version_number: action.data.version_number, published_at: action.data.published_at };
    workflow = { ...state.workflow, online_version_id: state.workflowVersion._id, draft_version_id: null, enabled: true };
    return { ...state, workflow, workflowVersion, errors: {}, isPendingRequest: false };
  case ActionTypes.UPDATE_WORKFLOW_VERSION_SUCCESS:
    workflowVersion = { ...state.workflowVersion, ...action.workflowVersionAttributes };
    workflow = { ...state.workflow };
    return { ...state, workflow, workflowVersion, errors: {}, isPendingRequest: false };
  case ActionTypes.CREATE_WORKFLOW_VERSION_SUCCESS:
    context = action.data.ordered_steps?.reduce((acc, step: WorkflowStep) => {
      acc[step._id] = step.data_out;
      return acc;
    }, {});
    orderedSteps = action.data.ordered_steps?.map((step: WorkflowStep, index: number) => {
      return { ...step, rank: index + 1 };
    }) || [];
    delete action.data.ordered_steps;
    workflow = { ...state.workflow, draft_version_id: action.data._id };
    return { ...state, workflow, workflowVersion: action.data, errors: {}, isSwitchingVersion: false, orderedSteps, context };
  case ActionTypes.UPDATE_WORKFLOW_VERSION_PENDING:
  case ActionTypes.PUBLISH_WORKFLOW_VERSION_PENDING:
    return { ...state, isPendingRequest: true, errors: {} };
  case ActionTypes.CREATE_WORKFLOW_VERSION_FAILURE:
    return { ...state, isSwitchingVersion: false, errors: action.data };
  case ActionTypes.UPDATE_WORKFLOW_VERSION_FAILURE:
  case ActionTypes.PUBLISH_WORKFLOW_VERSION_FAILURE:
    return { ...state, isPendingRequest: false, errors: action.data };
  case ActionTypes.CREATE_WORKFLOW_VERSION_STEP_PENDING:
  case ActionTypes.UPDATE_WORKFLOW_VERSION_STEP_PENDING:
  case ActionTypes.DELETE_WORKFLOW_VERSION_STEP_PENDING:
    return { ...state, isPendingRequest: true };
  case ActionTypes.FETCH_WORKFLOW_STRATEGY_REQUEST:
    return { ...state, isPendingStrategyRequest: true };
  case ActionTypes.FETCH_WORKFLOW_STRATEGY_SUCCESS:
    return { ...state, isPendingStrategyRequest: false, strategiesGlobalConfigParams: { ...state.strategiesGlobalConfigParams, [action.strategy]: action.data } };
  case ActionTypes.FETCH_WORKFLOW_STRATEGY_FAILURE:
    return { ...state, isPendingStrategyRequest: false };
  case ActionTypes.FETCH_WORKFLOW_VERSIONS_REQUEST:
    return { ...state, isPendingRequest: true };
  case ActionTypes.FETCH_WORKFLOW_VERSIONS_SUCCESS:
    return { ...state, workflowVersions: action.data, isPendingRequest: false };
  case ActionTypes.CHANGE_ACTIVE_STEP_ID:
    return { ...state, activeStepId: action.workflowStepId };
  case ActionTypes.RECEIVE_WORKFLOW_STEP_CHANGES:
    orderedSteps = state.orderedSteps.map((step) => {
      if (action.workflowStepId && action.workflowStepId === step._id) {
        return { ...step, hasChanges: true };
      } else {
        return { ...step };
      }
    });
    return { ...state, orderedSteps };
  default:
    return state;
  }
}

export default workflowVersionBuilder;
