import { cleanUniqueKeyInSiloedData } from "../utils/APIUtils";
import { ActionTypes, CALL_API } from "../constants/Constants";
import { stringify } from "querystring";
import { cleanUpQueryString } from "../utils/QueryStringUtils";

module.exports = {
  fetchAccesspoints(eventId, searchQuery = "", page = 0, options = {}) {
    const body = {
      search: cleanUpQueryString(searchQuery),
      ...options["searchFilters"],
      include: options["include"]
    };
    if (page !== 0) {
      body["page"] = page;
      body["per_page"] = 50;
    }
    const { RECEIVE_ACCESSPOINTS_REQUEST, RECEIVE_ACCESSPOINTS_SUCCESS, RECEIVE_ACCESSPOINTS_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [RECEIVE_ACCESSPOINTS_REQUEST, RECEIVE_ACCESSPOINTS_SUCCESS, RECEIVE_ACCESSPOINTS_FAILURE],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/accesspoints.json`,
        body: body
      },
      uniqueKeyInSiloedData: options["uniqueKeyInSiloedData"]
    };
  },

  fetchAccesspoint(eventId, accesspointId, includeOptions = []) {
    const body = includeOptions.length > 0 ? { include: includeOptions } : {};
    const { DEV_NULL, RECEIVE_ACCESSPOINT } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, RECEIVE_ACCESSPOINT, DEV_NULL],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/accesspoints/${accesspointId}.json`,
        body: body
      }
    };
  },

  updateExpectedAtAccesspointIds(objectIds) {
    return {
      type: ActionTypes.SET_EXPECTED_AT_ACCESSPOINT_IDS,
      objectIds
    };
  },

  updateAcceptedAtAccesspointIds(objectIds) {
    return {
      type: ActionTypes.SET_CHECKED_IN_AT_ACCESSPOINT_IDS,
      objectIds
    };
  },

  createAccesspoint(eventId, accesspointParams, urlOptions = {}, sendFile = true, redirectTo = null, notificationsOptions = {}) {
    const { DEV_NULL, CREATE_ACCESSPOINT_SUCCESS, CREATE_ACCESSPOINT_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, CREATE_ACCESSPOINT_SUCCESS, CREATE_ACCESSPOINT_FAILURE],
        method: "POST",
        body: sendFile ? accesspointParams : { accesspoint: accesspointParams },
        sendFile: sendFile,
        endpoint: `/api/v1/events/${eventId}/accesspoints.json?${stringify(urlOptions)}`,
        redirectTo,
        notificationsOptions
      }
    };
  },

  updateAccesspoint(eventId, accesspointId, accesspointParams, urlOptions = {}, sendFile = true, redirectTo = null, notificationsOptions = {}) {
    const { DEV_NULL, UPDATE_ACCESSPOINT_SUCCESS, UPDATE_ACCESSPOINT_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, UPDATE_ACCESSPOINT_SUCCESS, UPDATE_ACCESSPOINT_FAILURE],
        method: "PUT",
        body: sendFile ? accesspointParams : { accesspoint: accesspointParams },
        sendFile: sendFile,
        endpoint: `/api/v1/events/${eventId}/accesspoints/${accesspointId}.json?${stringify(urlOptions)}`,
        redirectTo,
        notificationsOptions
      }
    };
  },

  fetchAccesspointsCustomValuesForSessions(eventId) {
    const { DEV_NULL, FETCH_ACCESSPOINT_CUSTOM_VALUES_FOR_SESSION_SUCCESS, FETCH_ACCESSPOINT_CUSTOM_VALUES_FOR_SESSION_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, FETCH_ACCESSPOINT_CUSTOM_VALUES_FOR_SESSION_SUCCESS, FETCH_ACCESSPOINT_CUSTOM_VALUES_FOR_SESSION_FAILURE],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/accesspoints/custom_values_for_event_sessions.json`
      }
    };
  },

  removeAccesspointInSiloedData(uniqueKeyInSiloedData) {
    const { DELETE_ACCESSPOINT_KEY_IN_SILOED_DATA } = ActionTypes;
    return dispatch => {
      cleanUniqueKeyInSiloedData(dispatch, DELETE_ACCESSPOINT_KEY_IN_SILOED_DATA, uniqueKeyInSiloedData);
    };
  },
};
