import { MouseEvent, useEffect, useState } from "react";
import { fetchBlogArticles, fetchBlogArticlesTotalCount } from "../../actions/BlogArticlesActionCreators";
import { deleteBlogArticle, duplicateBlogArticle, clearBlogArticleDuplicationErrors } from "../../actions/BlogArticleActionCreators";
import { fetchThematics } from "../../actions/ThematicsActionCreators";
import { useDispatch, useSelector } from "react-redux";
import { urlEventId, pathToWebsiteConfig, pathToBlogArticlesList, pathToBlogArticle } from "../../utils/pathUtils";
import ConfirmationModal from "../../components/shared/ConfirmationModal.react";
import HelpSection from "../../components/shared/HelpSection.react";
import { BlogArticle } from "../../types/BlogArticle";
import SearchBar from "../../components/search_bar/SearchBar.react";
import Paginate from "../../components/Paginate.react";
import { extractPaginationPageNumbers } from "../../utils/QueryStringUtils";
import Loader from "../../components/shared/Loader.react";
import { BLOG } from "../../constants/FeaturesSet";
import { isEnabled } from "../../utils/featureSetUtils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import TargetEventModal from "../../components/duplication_modals/TargetEventModal.react";
import BlogArticleDuplicationInputModal from "../../components/blog_articles/BlogArticleDuplicationInputModal.react";
import DuplicationRunningModal from "../../components/duplication_modals/DuplicationRunningModal.react";
import BlogArticleBuilderSlidingPane from "../../components/blog_articles/BlogArticleBuilderSlidingPane.react";
import moment from "moment";
import { Route, Switch } from "react-router";
import { Link } from "react-router-dom";

moment.locale(I18n.currentLocale());

const PER_PAGE_PAGINATION = 20;

function i18n(key, opts = {}): string {
  return I18n.t(`react.blog_articles.blog_articles_list.${key}`, opts);
}

interface Props {
  match: any;
  location: any;
  history: any;
}

const BlogArticlesList: React.FC<Props> = ({ match }) => {
  if (!isEnabled(BLOG)) return null;

  const isFetching = useSelector((state: any) => state.blogArticles.isFetching);
  const blogArticles = useSelector((state: any) => state.blogArticles.data);
  const thematics = useSelector((state: any) => state.thematics.items);
  const nextURL = useSelector((state: any) => state.blogArticles.nextURL);
  const previousURL = useSelector((state: any) => state.blogArticles.previousURL);
  const blogArticlesTotalCount = useSelector((state: any) => state.blogArticles.totalCount);
  const event = useSelector((state: any) => state.event);
  const duplicationErrors = useSelector((state: any) => state.blogArticles.duplicationErrors);
  const duplicatedBlogArticle = useSelector((state: any) => state.blogArticles.duplicated);

  const dispatch = useDispatch();

  const [articleToDestroy, setArticleToDestroy] = useState(null);
  const [previousPageNumber, setPreviousPageNumber] = useState(null);
  const [nextPageNumber, setNextPageNumber] = useState(null);
  const [search, setSearch] = useState(null);
  const [firstLoadingDone, setFirstLoadingDone] = useState(false);
  const [showEventPickerModal, setShowEventPickerModal] = useState(false);
  const [showDuplicationInputModal, setShowDuplicationInputModal] = useState(false);
  const [showDuplicationRunningModal, setShowDuplicationRunningModal] = useState(false);
  const [duplicateTargetEventId, setDuplicateTargetEventId] = useState("");
  const [articleToDuplicate, setArticleToDuplicate] = useState(null);

  useEffect(() => {
    dispatch(fetchBlogArticles(urlEventId(), null, 1, PER_PAGE_PAGINATION));
    dispatch(fetchBlogArticlesTotalCount(urlEventId(), null));
    if (!firstLoadingDone) {
      setFirstLoadingDone(true);
    }
  }, []);

  useEffect(() => {
    if (!thematics) {
      dispatch(fetchThematics(urlEventId()));
    }
  }, [blogArticles]);

  useEffect(() => {
    const [ previousPageNumber, nextPageNumber ] = extractPaginationPageNumbers(previousURL, nextURL);
    setPreviousPageNumber(previousPageNumber);
    setNextPageNumber(nextPageNumber);
  }, [nextURL, previousURL]);

  const openConfirmationModal = (item: BlogArticle): any => {
    return (): void => {
      setArticleToDestroy(item);
    };
  };

  const closeConfirmationModal = (): void => {
    setArticleToDestroy(null);
  };

  const handleDelete = (item): any => {
    return (): void => {
      dispatch(deleteBlogArticle(urlEventId(), item._id));
      closeConfirmationModal();
    };
  };

  const renderAreYouSureModal = (): JSX.Element => {
    if (!articleToDestroy) return null;

    return <ConfirmationModal
      isVisible={!!articleToDestroy}
      onClose={closeConfirmationModal}
      onConfirm={handleDelete(articleToDestroy)}
      title={`<div class='text-center'><i class='fa-regular fa-triangle-exclamation mr-5'></i>${I18n.t("warning")}</div>`}
      confirmationQuestion={`<div class='text-center'>${i18n("modal_confirmation_delete")}</div>`}
    />;
  };

  const renderTitleAndNavTabs = (): JSX.Element => {
    return <>
      <div className="row">
        <div className="col-md-12">
          <ul className="nav nav-tabs">
            <li role="presentation" className="nav-item">
              <a href={pathToWebsiteConfig()} className="nav-link">{I18n.t("react.translations.parent_navigation.website")}</a>
            </li>
            <li role="presentation" className="active nav-item">
              <a href={pathToBlogArticlesList()} className="nav-link">{i18n("articles_tab")}</a>
            </li>
          </ul>
        </div>
      </div>
    </>;
  };

  const renderSubtitle = (): JSX.Element => {
    return <div className="subtitle-page">
      <div className="subtitle-page-content row">
        <div className="subtitle-page-title col-md-6">
          <h2>{i18n("title")}</h2>
          <HelpSection help={i18n("instant_help")} />
        </div>
        <div className="subtitle-page-btn col-md-6 d-flex align-items-start">
          {(blogArticles?.length > 0) && renderButtonToNewBlogArticle()}
        </div>
      </div>
    </div>;
  };

  const handleSearch = (value: string): void => {
    setSearch(value);
    dispatch(fetchBlogArticles(urlEventId(), value, 1, PER_PAGE_PAGINATION));
    dispatch(fetchBlogArticlesTotalCount(urlEventId(), value));
  };

  const renderBlogArticleThematics = (blogArticle: BlogArticle): JSX.Element => {
    if (!blogArticle.thematic_ids?.length || !thematics) return null;

    let thematic = null;
    return <>
      {blogArticle.thematic_ids.map(thematicId => {
        thematic = thematics.find(thematic => thematic._id === thematicId);
        if (!thematic) return null;

        return <span key={thematicId} className="badge rounded-pill mr-5 float-start" style={{ backgroundColor: thematic.color }}>
          {thematic.name}
        </span>;
      })}
    </>;
  };

  const renderBlogArticlesSearch = (): JSX.Element => {
    if (!isFetching && !(blogArticles?.length > 0) && !search?.length) return null;

    return <div className="row blog-articles-search">
      <div className="col-md-4 col-6">
        <SearchBar placeholder={i18n("search_placeholder")} value={search} handleSubmit={handleSearch} />
      </div>
    </div>;
  };

  const goToPage = (pageNumber: number): any => {
    return (e: MouseEvent): void => {
      e.preventDefault();
      if (!pageNumber) return;

      dispatch(fetchBlogArticles(urlEventId(), search, pageNumber, PER_PAGE_PAGINATION));
    };
  };

  const blogArticlesCounter = (): JSX.Element => {
    const blogArticlesCount = blogArticles?.length || 0;
    const perPage = 2;

    let value;
    if (!previousPageNumber && !nextPageNumber) {
      value = <strong>{blogArticlesCount}</strong>;
    } else {
      const currentPageNumber = previousPageNumber && previousPageNumber + 1 || nextPageNumber && nextPageNumber - 1;
      value = <>
        <span>{`${(currentPageNumber - 1) * perPage + 1} - ${(currentPageNumber - 1) * perPage + blogArticlesCount} ${I18n.t("paginate_of")}`}</span>
        <strong className="ml-5">{blogArticlesTotalCount}</strong>
      </>;
    }

    return <>
      {value}
      <span className="ml-5">{i18n("blog_articles_count_html", { count: blogArticlesCount })}</span>
    </>;
  };

  const renderPlannedPublicationTooltip = (): JSX.Element => {
    return <Tooltip id="tooltip">{i18n("planned_publication_tooltip")}</Tooltip>;
  };

  const renderPagination = (): JSX.Element => {
    const paginationNotRequired = !previousPageNumber && !nextPageNumber || !(blogArticles?.length > 0);

    return <div className="row blog-articles-pagination">
      <div className="offset-md-4 col-md-8 offset-6 col-6 d-flex align-items-center justify-content-end">
        {blogArticlesCounter()}
        {!paginationNotRequired && <Paginate
          handlePrevious={goToPage(previousPageNumber)}
          handleNext={goToPage(nextPageNumber)}
          previousEnabled={previousPageNumber}
          nextEnabled={nextPageNumber}
          pull="end"
          classNames="ml-5"
        />}
      </div>
    </div>;
  };

  const openEventPickerModal = (blogArticle: BlogArticle): ((e: MouseEvent) => void) => {
    return (e: MouseEvent): void => {
      e.preventDefault();
      setArticleToDuplicate(blogArticle);
      setShowEventPickerModal(true);
    };
  };

  const renderButtonToNewBlogArticle = (): JSX.Element => {
    return <div>
      <Link to={pathToBlogArticlesList() + "/new"} className="btn btn-primary">
        <i className="fa-regular fa-plus"></i> {i18n("create_new_article")}
      </Link>
    </div>;
  };

  const renderBlogArticlesList = (): JSX.Element => {
    if (!firstLoadingDone) return null;

    if (isFetching) {
      return <div className="mt-50">
        <Loader/>
      </div>;
    }

    if (!(blogArticles?.length > 0)) {
      return <div className="card nothing-yet mt-10">
        <div><i className="fa-regular fa-newspaper fa-xl" aria-hidden="true"></i></div>
        <h4>{i18n("no_article")}</h4>
        {!(search?.length > 0) && renderButtonToNewBlogArticle()}
      </div>;
    }

    return <div className="table-responsive table-container">
      <table className="table table-light table-hover" id="resource-table">
        <colgroup>
          <col />
          <col width="140px" />
          <col width="200px" />
          <col width="200px" />
          <col width="200px" />
        </colgroup>
        <thead>
          <tr>
            <th>{i18n("name")}</th>
            <th>{i18n("status")}</th>
            <th>{i18n("published_date")}</th>
            <th>{i18n("updated_date")}</th>
            <th>{I18n.t("actions")}</th>
          </tr>
        </thead>
        <tbody>
          {blogArticles && blogArticles.map(blogArticle => (
            <tr key={blogArticle._id}>
              <td>
                <Link to={pathToBlogArticle(blogArticle._id)} className="d-flex justify-content-between text-default fw-bold">
                  {blogArticle.title}
                </Link>
                <div>
                  {renderBlogArticleThematics(blogArticle)}
                </div>
              </td>
              <td>
                {blogArticle.published && !moment().isAfter(moment(blogArticle.published_at_to_timezone, `${I18n.t("datetime_picker_js_format")} HH:mm`)) && (
                  <OverlayTrigger placement="top" overlay={renderPlannedPublicationTooltip()}>
                    <i className="fa-regular fa-clock pr-5"></i>
                  </OverlayTrigger>
                )}
                {i18n(`${blogArticle.published ? "published" : "unpublished"}`)}
              </td>
              <td>
                {blogArticle.published && blogArticle.published_at_to_timezone}
              </td>
              <td>
                {blogArticle.updated_at_to_timezone}
              </td>
              <td>
                <button type="button" className="btn btn-secondary mr-5" onClick={openEventPickerModal(blogArticle)}>
                  <i className="fa-regular fa-clone"></i>
                </button>
                <button type="button" className="btn btn-secondary mr-5" onClick={openConfirmationModal(blogArticle)}>
                  <i className="fa-regular fa-trash-can"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>;
  };

  const targetEventSelected = (targetEventId: string): void => {
    dispatch(clearBlogArticleDuplicationErrors()); // erases a possible error from the previous duplication of an article
    setShowEventPickerModal(false);
    setShowDuplicationInputModal(true);
    setDuplicateTargetEventId(targetEventId);
  };

  const duplicateIntoEvent = (title: string, websitePathSlug: string): void => {
    const blogArticleAttributes = {
      title: title,
      website_path_slug: websitePathSlug,
    };
    dispatch(duplicateBlogArticle(event.id, articleToDuplicate._id, blogArticleAttributes, duplicateTargetEventId));

    setShowDuplicationInputModal(false);
    setShowDuplicationRunningModal(true);
  };

  const renderDuplicateModals = (): JSX.Element => {
    return <>
      <TargetEventModal
        isVisible={showEventPickerModal}
        onClose={(): void => setShowEventPickerModal(false)}
        currentEvent={event}
        targetReadableName={i18n("duplication.target_name")}
        onEventSelected={targetEventSelected}
      />
      <BlogArticleDuplicationInputModal
        isVisible={showDuplicationInputModal}
        onClose={(): void => setShowDuplicationInputModal(false)}
        onSubmit={duplicateIntoEvent}
        errors={duplicationErrors}
        initialTitle={articleToDuplicate?.title}
        initialWebsitePathSlug={articleToDuplicate?.website_path_slug}
      />
      <DuplicationRunningModal
        isVisible={showDuplicationRunningModal}
        onClose={(): void => setShowDuplicationRunningModal(false)}
        model="blog_articles"
        currentEventId={event.id}
        targetEventId={duplicateTargetEventId}
        duplicatedData={duplicatedBlogArticle}
        duplicationErrors={duplicationErrors}
        duplicationWarnings={null}
      />
    </>;
  };

  return <>
    {renderTitleAndNavTabs()}
    {renderSubtitle()}
    {renderBlogArticlesSearch()}
    <div className="row blog-articles-list">
      <div className="col-md-12">
        {renderPagination()}
        {renderBlogArticlesList()}
      </div>
    </div>
    {renderAreYouSureModal()}
    {renderDuplicateModals()}
    <Switch>
      <Route path={`${match.path}/new`} component={BlogArticleBuilderSlidingPane}/>
      <Route path={`${match.path}/:article_id`} component={BlogArticleBuilderSlidingPane}/>
    </Switch>
  </>;
};

export default BlogArticlesList;
