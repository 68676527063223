import { ActionTypes } from "../constants/Constants";
import { mergeLocalChanges } from "../utils/templatesBuilderUtils";

const defaultState = {
  data: null,
  sections: {},
  generalSettings: {},
  emailSections: {},
  hasChanges: false,
  errors: {},
  globalChanges: {},
  blocksToRemove: {},
  sectionsToRemove: [],
  templates: null,
  duplicationErrors: {},
  duplicated: null,
  reloadingTheme: false,
  reloadThemeError: null,
  isPendingRequest: false,
  selectedTemplateId: null
};

function emailTemplate(state = defaultState, action) {
  let settings_data, newSections, newEmailSections, newSectionsToRemove, newBlocksToRemove, localChanges, newGlobalChanges;
  switch (action.type) {
  case ActionTypes.RECEIVE_EMAIL_TEMPLATE_SUCCESS: {
    settings_data = action.data.settings_data;
    delete(action.data.settings_data);
    const newState = {
      data: action.data,
      sections: settings_data.sections,
      generalSettings: settings_data.general,
      emailSections: settings_data.sections_order,
      errors: {},
      hasChanges: false,
      globalChanges: {},
      blocksToRemove: {},
      sectionsToRemove: [],
      isPendingRequest: false
    };
    return Object.assign({}, state, newState);
  }
  case ActionTypes.UPDATE_EMAIL_TEMPLATE_REQUEST:
    return { ...state, errors: {}, isPendingRequest: true };
  case ActionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESS: {
    settings_data = action.data.settings_data;
    delete(action.data.settings_data);
    const newState = {
      data: action.data,
      sections: settings_data.sections,
      generalSettings: settings_data.general,
      emailSections: settings_data.sections_order,
      errors: {},
      hasChanges: false,
      globalChanges: {},
      blocksToRemove: {},
      sectionsToRemove: [],
      isPendingRequest: false
    };
    return Object.assign({}, state, newState);
  }
  case ActionTypes.CREATE_EMAIL_TEMPLATE_SUCCESS: {
    const newTemplates = state.templates.slice();
    newTemplates.unshift(action.data);
    return Object.assign({}, state, { templates: newTemplates, errors: {}, duplicationErrors: {}, selectedTemplateId: action.data._id });
  }
  case ActionTypes.CREATE_EMAIL_TEMPLATE_FAILURE:
    return Object.assign({}, state, { errors: action.data });
  case ActionTypes.DELETE_EMAIL_TEMPLATE_SUCCESS: {
    const templates = state.templates.filter(t => t._id !== action.emailTemplateId);
    return Object.assign({}, state, { templates, selectedTemplateId: templates[0] && templates[0]._id });
  }
  case ActionTypes.UPDATE_EMAIL_SELECTED_ID:
    return Object.assign({}, state, { selectedTemplateId: action.data });
  case ActionTypes.DUPLICATE_EMAIL_TEMPLATE_PENDING:
    return Object.assign({}, state, { errors: {}, duplicationErrors: {}, duplicated: null });
  case ActionTypes.DUPLICATE_EMAIL_TEMPLATE_SUCCESS: {
    if (action.duplicateInCurrentEvent) {
      const newTemplates = state.templates.slice();
      newTemplates.unshift(action.data);
      return Object.assign({}, state, { templates: newTemplates, errors: {}, duplicationErrors: {}, duplicated: action.data, selectedTemplateId: action.data._id });
    }
    return Object.assign({}, state, { errors: {}, duplicationErrors: {}, duplicated: action.data });
  }
  case ActionTypes.DUPLICATE_EMAIL_TEMPLATE_FAILURE:
    return Object.assign({}, state, { duplicationErrors: action.data });
  case ActionTypes.RECEIVE_EMAIL_TEMPLATES_SUCCESS:
    return Object.assign({}, state, { templates: action.data, selectedTemplateId: state.selectedTemplateId || action.data[0] && action.data[0]._id });
  case ActionTypes.UPDATE_EMAIL_SECTIONS:
    localChanges = { sections_order: action.objects };
    newGlobalChanges = mergeLocalChanges(state.globalChanges, localChanges);
    return { ...state, emailSections: action.objects, globalChanges: newGlobalChanges, hasChanges: true };
  case ActionTypes.UPDATE_SECTION:
    newSections = { ...state.sections, [action.sectionKey]: action.settings };
    localChanges = { sections: { [action.sectionKey]: action.changes } };
    newGlobalChanges = mergeLocalChanges(state.globalChanges, localChanges);
    return { ...state, sections: newSections, globalChanges: newGlobalChanges };
  case ActionTypes.CREATE_NEW_EMAIL_SECTION:
    newSections = { ...state.sections, [action.sectionKey]: action.sectionData };
    newEmailSections = [...state.emailSections, action.sectionKey];
    localChanges = {
      sections: { [action.sectionKey]: action.sectionData },
      sections_order: newEmailSections
    };
    newGlobalChanges = mergeLocalChanges(state.globalChanges, localChanges);
    return { ...state, sections: newSections, emailSections: newEmailSections, globalChanges: newGlobalChanges, hasChanges: true };
  case ActionTypes.REMOVE_EMAIL_SECTION:
    newSections = { ...state.sections };
    delete newSections[action.sectionKey];
    newEmailSections = [...state.emailSections];
    newEmailSections.splice(newEmailSections.indexOf(action.sectionKey), 1);
    newSectionsToRemove = [...state.sectionsToRemove, action.sectionKey];
    localChanges = { sections_order: newEmailSections };
    newGlobalChanges = mergeLocalChanges(state.globalChanges, localChanges);
    return { ...state, sections: newSections, emailSections: newEmailSections, sectionsToRemove: newSectionsToRemove, globalChanges: newGlobalChanges, hasChanges: true };
  case ActionTypes.CREATE_NEW_BLOCK:
    newSections = { ...state.sections };
    newSections[action.sectionKey]["blocks"][action.blockKey] = action.blockData;
    newSections[action.sectionKey]["blocks_order"].push(action.blockKey);
    return { ...state, sections: newSections, hasChanges: true };
  case ActionTypes.REMOVE_BLOCK:
    newBlocksToRemove = { ...state.blocksToRemove };
    if (!newBlocksToRemove[action.sectionKey]) {
      newBlocksToRemove[action.sectionKey] = [];
    }
    newBlocksToRemove[action.sectionKey].push(action.blockKey);
    return { ...state, blocksToRemove: newBlocksToRemove, hasChanges: true };
  case ActionTypes.RECEIVE_EMAIL_TEMPLATE_CHANGES:
    return { ...state, hasChanges: true };
  case ActionTypes.UPDATE_APPEARANCE:
    localChanges = { general: action.changes };
    newGlobalChanges = mergeLocalChanges(state.globalChanges, localChanges);
    return { ...state,
      generalSettings: { ...action.settings },
      globalChanges: newGlobalChanges
    };
  case ActionTypes.UPDATE_EMAIL_TEMPLATE_ERROR:
    return Object.assign({}, state, { errors: action.data, hasChanges: true, isPendingRequest: false });
  case ActionTypes.RECEIVE_EMAIL_TEMPLATE_FAILURE:
    return state;
  case ActionTypes.RELOAD_EMAIL_TEMPLATE_THEME_REQUEST:
    return Object.assign({}, state, { reloadingTheme: true, reloadThemeError: null });
  case ActionTypes.RELOAD_EMAIL_TEMPLATE_THEME_SUCCESS: {
    const { updated_at, theme_name, theme_updated_at, reloaded_at, reload_mean } = action.data;
    settings_data = action.data.settings_data;

    const data = Object.assign({}, state.data, { updated_at, theme_name, theme_updated_at, reloaded_at, reload_mean });
    return Object.assign({}, state, { data, reloadingTheme: false, sections: settings_data.sections, generalSettings: settings_data.general });
  }
  case ActionTypes.RELOAD_EMAIL_TEMPLATE_THEME_FAILURE:
    return Object.assign({}, state, { reloadingTheme: false, reloadThemeError: action.data.error });
  case ActionTypes.CLEAR_EMAIL_TEMPLATE:
    return Object.assign({}, defaultState, { templates: state.templates, selectedTemplateId: state.selectedTemplateId });
  default:
    return state;
  }
}

export default emailTemplate;
