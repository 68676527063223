import { CALL_API, ActionTypes } from "../constants/Constants";
import { StreamingArchiveUpdateAttributes } from "../types/StreamingArchive";

export function fetchStreamingArchives(eventId: string, accesspointId: string): any {
  const { FETCH_STREAMING_ARCHIVES_REQUEST, FETCH_STREAMING_ARCHIVES_SUCCESS, FETCH_STREAMING_ARCHIVES_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_STREAMING_ARCHIVES_REQUEST, FETCH_STREAMING_ARCHIVES_SUCCESS, FETCH_STREAMING_ARCHIVES_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/accesspoints/${accesspointId}/streaming_archives.json`
    }
  };
}

export function createStreamingArchive(eventId: string, accesspointId: string, params: any): any {
  const { CREATE_STREAMING_ARCHIVE_REQUEST, CREATE_STREAMING_ARCHIVE_SUCCESS, CREATE_STREAMING_ARCHIVE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_STREAMING_ARCHIVE_REQUEST, CREATE_STREAMING_ARCHIVE_SUCCESS, CREATE_STREAMING_ARCHIVE_FAILURE],
      method: "POST",
      body: {
        streaming_archive: params
      },
      endpoint: `/api/v1/events/${eventId}/accesspoints/${accesspointId}/streaming_archives.json`
    }
  };
}

export function deleteStreamingArchive(eventId: string, accesspointId: string, streamingArchiveId: string): any {
  const { DELETE_STREAMING_ARCHIVE_REQUEST, DELETE_STREAMING_ARCHIVE_SUCCESS, DELETE_STREAMING_ARCHIVE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DELETE_STREAMING_ARCHIVE_REQUEST, DELETE_STREAMING_ARCHIVE_SUCCESS, DELETE_STREAMING_ARCHIVE_FAILURE],
      method: "DELETE",
      endpoint: `/api/v1/events/${eventId}/accesspoints/${accesspointId}/streaming_archives/${streamingArchiveId}`
    },
    streamingArchiveId
  };
}

export function updateStreamingArchive(eventId: string, accesspointId: string, streamingArchiveId: string, params: StreamingArchiveUpdateAttributes): any {
  const { UPDATE_STREAMING_ARCHIVE_REQUEST, UPDATE_STREAMING_ARCHIVE_SUCCESS, UPDATE_STREAMING_ARCHIVE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_STREAMING_ARCHIVE_REQUEST, UPDATE_STREAMING_ARCHIVE_SUCCESS, UPDATE_STREAMING_ARCHIVE_FAILURE],
      method: "PUT",
      body: {
        streaming_archive: params
      },
      endpoint: `/api/v1/events/${eventId}/accesspoints/${accesspointId}/streaming_archives/${streamingArchiveId}`
    }
  };
}
