import { ActionTypes } from "../constants/Constants";

function websiteLayouts(state = { data: null, errors: {} }, action) {
  switch (action.type) {
  case ActionTypes.RECEIVE_WEBSITE_LAYOUTS_SUCCESS:
    return { data: action.data || [], errors: {} };

  case ActionTypes.RECEIVE_WEBSITE_LAYOUTS_FAILURE:
    return state;

  default:
    return state;
  }
}

export default websiteLayouts;
