import { ActionTypes } from "../constants/Constants";
import { WorkflowsStore } from "../types/Workflow";

const defaultState: WorkflowsStore = {
  data: null,
  errors: {},
  isPendingRequest: false,
  newWorkflowId: null
};

function workflows(state = defaultState, action: any): WorkflowsStore {
  switch (action.type) {
  case ActionTypes.WORKFLOWS_SUCCESS:
    return { ...state, data: action.data };
  case ActionTypes.CREATE_WORKFLOW_SUCCESS:
    return { ...state, data: [action.data, ...state.data], errors: {}, isPendingRequest: false, newWorkflowId: action.data._id };
  case ActionTypes.UPDATE_WORKFLOW_SUCCESS:
    return { ...state, data: action.data, errors: {}, isPendingRequest: false };
  case ActionTypes.DELETE_WORKFLOW_SUCCESS: {
    const data = state.data.filter(w => w._id !== action.workflowId);
    return { ...state, data, isPendingRequest: false };
  }
  case ActionTypes.CREATE_WORKFLOW_PENDING:
  case ActionTypes.UPDATE_WORKFLOW_PENDING:
  case ActionTypes.DELETE_WORKFLOW_PENDING:
    return { ...state, isPendingRequest: true, errors: {} };
  case ActionTypes.CREATE_WORKFLOW_FAILURE:
  case ActionTypes.UPDATE_WORKFLOW_FAILURE:
  case ActionTypes.DELETE_WORKFLOW_FAILURE:
    return { ...state, isPendingRequest: false, errors: action.data };
  default:
    return state;
  }
}

export default workflows;
