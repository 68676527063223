import { Component } from "react";
import GenericS3Uploader from "../components/shared/GenericS3Uploader.react";
import url from "url";

const ACCEPTED_FILES = [
  ".csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel"
];

export default class GuestsImportFileUploader extends Component {
  constructor(props) {
    super(props);
    this.onFinish = this.onFinish.bind(this);
  }

  onFinish(signedUrl) {
    const uri = url.parse(signedUrl);
    const tokens = uri.pathname.split("/");
    const filename = tokens[tokens.length - 1];

    const { create_operation_path, klassname } = this.props;
    const data = {
      [klassname]: {
        direct_filename: filename
      }
    };

    $.ajax(create_operation_path, {
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify(data)
    }).success(function(data) {
      window.location = data[0].redirect_url;
    });
  }

  render() {
    const { s3_upload_url, title } = this.props;

    return (
      <GenericS3Uploader
        acceptedFiles={ACCEPTED_FILES}
        classNameUploader="guest-import-file-uploader-zone card dropContainer"
        onFinishUpload={this.onFinish}
        s3UploadURL={s3_upload_url}
      >
        <div>{title}</div>

      </GenericS3Uploader>
    );
  }
}
