import { Component } from "react";

class Title extends Component {

  render() {
    const { nbGuest } = this.props;
    let title = I18n.t("react.guest_campaign.title_unknown_nb_guests");
    if (nbGuest || nbGuest === 0) {
      title = I18n.t("react.guest_campaign.title", { nb_guest: nbGuest });
    }
    return <div className="text-center">{ title }</div>;
  }

}

export default Title;
