import { CALL_API, ActionTypes } from "../constants/Constants";
import { SessionRoomUpdateAttributes } from "../types/SessionRoom";

export function fetchSessionRoom(eventId: string, session_room_id: string): any {
  const { FETCH_SESSION_ROOM_REQUEST, FETCH_SESSION_ROOM_SUCCESS, FETCH_SESSION_ROOM_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_SESSION_ROOM_REQUEST, FETCH_SESSION_ROOM_SUCCESS, FETCH_SESSION_ROOM_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/session_rooms/${session_room_id}.json`,
    }
  };
}

export function createSessionRoom(eventId: string, params: SessionRoomUpdateAttributes, redirectTo = null, notificationsOptions = {}): any {
  const { CREATE_SESSION_ROOM_REQUEST, CREATE_SESSION_ROOM_SUCCESS, CREATE_SESSION_ROOM_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_SESSION_ROOM_REQUEST, CREATE_SESSION_ROOM_SUCCESS, CREATE_SESSION_ROOM_FAILURE],
      method: "POST",
      body: {
        session_room: params
      },
      endpoint: `/api/v1/events/${eventId}/session_rooms.json`,
      redirectTo,
      notificationsOptions,
    }
  };
}

export function updateSessionRoom(eventId: string, session_room_id: string, params: SessionRoomUpdateAttributes, redirectTo = null, notificationsOptions = {}): any {
  const { UPDATE_SESSION_ROOM_REQUEST, UPDATE_SESSION_ROOM_SUCCESS, UPDATE_SESSION_ROOM_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_SESSION_ROOM_REQUEST, UPDATE_SESSION_ROOM_SUCCESS, UPDATE_SESSION_ROOM_FAILURE],
      method: "PUT",
      body: {
        session_room: params
      },
      endpoint: `/api/v1/events/${eventId}/session_rooms/${session_room_id}.json`,
      redirectTo,
      notificationsOptions,
    }
  };
}
