import { Component } from "react";
import { Link } from "react-router-dom";

class InformationBlock extends Component {

  constructor(props) {
    super(props);
    const privateMethods = ["_renderSubtitle", "_renderInformation", "_renderLink"];
    privateMethods.forEach((item) => { this[item] = this[item].bind(this); });
  }

  _renderSubtitle(title) {
    return title ? (
      <span className="subtitle">{title}</span>
    ) : null;
  }

  _renderLink() {
    const { linkName, linkUrl, newTab } = this.props;
    if (!linkUrl) return null;
    let linkAttributes = { to: linkUrl };
    if (newTab) linkAttributes["target"] = "_blank";
    return <span> - <Link { ...linkAttributes }>{ linkName }</Link></span>;
  }

  _renderInformation() {
    const { info } = this.props;
    if (!info) return;

    return <div className="information">{ info } { this._renderLink() }</div>;
  }

  render() {
    const { title, leftIconType, leftIconColor, noIcon } = this.props;
    return (
      <div key={ title } className="review-information-block">
        <div className="row">
          <div className="col-md-1 d-flex flex-column justify-content-center">
            { noIcon ? null : <i className={`fa-regular fa-3 fa-${leftIconType} text-${leftIconColor}`}></i> }
          </div>
          <div className="col-md-11 d-flex flex-column justify-content-center">
            {this._renderSubtitle(title)}
            {this._renderInformation()}
          </div>
        </div>
      </div>
    );
  }
}

InformationBlock.defaultProps = {
  leftIconType: "check-circle",
  leftIconColor: "success"
};

export default InformationBlock;
