import { CALL_API, ActionTypes } from "../constants/Constants";
import { ReportConfiguration } from "../types/ReportConfiguration";

export function createReportConfiguration(accountId: string, params: ReportConfiguration): any {
  const { CREATE_REPORT_CONFIGURATION_REQUEST, CREATE_REPORT_CONFIGURATION_SUCCESS, CREATE_REPORT_CONFIGURATION_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_REPORT_CONFIGURATION_REQUEST, CREATE_REPORT_CONFIGURATION_SUCCESS, CREATE_REPORT_CONFIGURATION_FAILURE],
      method: "POST",
      body: {
        report_configuration: params
      },
      endpoint: `/api/v1/accounts/${accountId}/report_configurations.json`
    }
  };
}

export function fetchReportConfigurations(accountId: string): any {
  const { FETCH_REPORT_CONFIGURATIONS_REQUEST, FETCH_REPORT_CONFIGURATIONS_SUCCESS, DEV_NULL } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_REPORT_CONFIGURATIONS_REQUEST, FETCH_REPORT_CONFIGURATIONS_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/accounts/${accountId}/report_configurations.json`
    }
  };
}

export function deleteReportConfiguration(accountId: string, id: string): any {
  const { DELETE_REPORT_CONFIGURATIONS_SUCCESS, DEV_NULL } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, DELETE_REPORT_CONFIGURATIONS_SUCCESS, DEV_NULL],
      method: "DELETE",
      endpoint: `/api/v1/accounts/${accountId}/report_configurations/${id}.json`
    },
    reportConfigurationId: id
  };
}
