import { Component } from "react";
import ConditionalDisplayRow from "./ConditionalDisplayRow.react";

export const CURRENT_TIME_KEY = "___CURRENT_TIME___";

function i18n(key) {
  return I18n.t(`react.form_items.conditional_display.${key}`);
}

class ConditionalDisplayOption extends Component {

  constructor(props) {
    super(props);
    [
      "onChangeCondition",
      "onChangeTextField",
      "addAndCondition",
      "removeAndCondition",
      "addOrCondition"].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = { displayConditions: props.displayConditions };
  }

  componentDidUpdate(prevProps) {
    // switch between two filter steps
    if (prevProps.workflowStep && this.props.workflowStep && prevProps.workflowStep.conditions !== prevProps.displayConditions && this.props.workflowStep.conditions === this.props.displayConditions) {
      this.setState({ displayConditions: this.props.displayConditions });
    }
  }

  hasDisplayCondition(orIndex, andIndex = null) {
    const { displayConditions } = this.state;
    if (!andIndex)
      return displayConditions[orIndex] !== undefined;

    return displayConditions[orIndex] !== undefined && displayConditions[orIndex][andIndex] !== undefined;
  }

  onChangeCondition(key, orIndex, andIndex, value) {
    if (!this.hasDisplayCondition(orIndex, andIndex)) return;

    const { updateHandler } = this.props;
    let displayConditions = this.state.displayConditions.slice();
    displayConditions[orIndex][andIndex][key] = value;
    this.setState({ displayConditions });
    updateHandler({ display_conditions: displayConditions });
  }

  onChangeTextField(orIndex, andIndex, value) {
    if (!this.hasDisplayCondition(orIndex, andIndex)) return;

    let displayConditions = this.state.displayConditions.slice();
    displayConditions[orIndex][andIndex]["value"] = value;
    this.setState({ displayConditions });
  }

  addAndCondition(orIndex) {
    return () => {
      if (!this.hasDisplayCondition(orIndex)) return;

      const { updateHandler, context } = this.props;
      const newAndCondition = context === "automation" ?
        { field: "", value: "", operation: "text_equals" } :
        { field: "last_name", value: "", operation: "equal" };
      let displayConditions = this.state.displayConditions.slice();
      displayConditions[orIndex].push(newAndCondition);
      this.setState({ displayConditions });
      updateHandler({ display_conditions: displayConditions });
    };
  }

  removeAndCondition(orIndex, andIndex) {
    if (!this.hasDisplayCondition(orIndex, andIndex)) return;

    const { updateHandler } = this.props;
    let displayConditions = this.state.displayConditions.slice();
    if (displayConditions[orIndex].length === 1) {
      displayConditions.splice(orIndex, 1); // if only one and condition, remove all OR set of conditions
    } else {
      displayConditions[orIndex].splice(andIndex, 1);
    }
    this.setState({ displayConditions });
    updateHandler({ display_conditions: displayConditions });
  }

  addOrCondition() {
    const { context, updateHandler } = this.props;
    const newOrCondition = context === "automation" ?
      [{ field: "", value: "", operation: "text_equals" }] :
      [{ field: "last_name", value: "", operation: "equal" }];
    const displayConditions = [...this.state.displayConditions, newOrCondition];
    this.setState({ displayConditions });
    updateHandler({ display_conditions: displayConditions });
  }

  renderFirstOrConditionButton() {
    return <a className="btn btn-secondary" onClick={this.addOrCondition}>
      <i className="fa-regular fa-plus" /> { i18n("add_filter") }
    </a>;
  }

  renderOrTitle() {
    const { context } = this.props;

    if (context === "automation") {
      return <h3 className="mt-50">{ i18n("or_continue_if") }</h3>;
    }
    return <label className="form-label">{ i18n("or_display_if") }</label>;
  }

  renderAndConditions(orIndex, setOfAndConditions) {
    if (!setOfAndConditions) return null;

    const { guestFields, context, workflowStep } = this.props;
    let options = [<option key={CURRENT_TIME_KEY} value={CURRENT_TIME_KEY}> { i18n("current_time") } </option>];
    guestFields.forEach(field => {
      options.push(<option key={field.key} value={field.key}> { field.label } </option>);
    });

    return setOfAndConditions.map((condition, andIndex) => {
      const { isDisabled } = this.props;

      return <ConditionalDisplayRow key={`display-${andIndex}`}
        condition={condition}
        orIndex={orIndex}
        andIndex={andIndex}
        options={options}
        onChangeCondition={this.onChangeCondition}
        onChangeTextField={this.onChangeTextField}
        onRemoveAndCondition={this.removeAndCondition}
        workflowStep={workflowStep}
        isDisabled={isDisabled}
        context={context} />;
    });
  }

  renderAddAndConditionButton(orIndex) {
    const { isDisabled } = this.props;

    if (isDisabled) return;

    return <a className="btn btn-secondary" onClick={this.addAndCondition(orIndex)}>
      + { i18n("and") }
    </a>;
  }

  renderAddOrConditionButton(orIndex) {
    const { isDisabled } = this.props;

    if (isDisabled) return;

    const { displayConditions } = this.state;
    // Display button only for the last set of conditions
    if (displayConditions.length != orIndex + 1) return null;

    return <a className="btn btn-secondary" onClick={this.addOrCondition}>
      + { i18n("or") }
    </a>;
  }

  renderConditionsFilters() {
    const { displayConditions } = this.state;

    if (displayConditions.length <= 0)
      return <p>{ this.renderFirstOrConditionButton() }</p>;

    return displayConditions.map((orConditions, orIndex) => {
      return <div key={`or-conditions-${orIndex}`}>
        { orIndex > 0 && this.renderOrTitle() }
        { this.renderAndConditions(orIndex, orConditions) }
        <p>
          { this.renderAddAndConditionButton(orIndex) } { " " }
          { this.renderAddOrConditionButton(orIndex) }
        </p>
      </div>;
    });
  }

  render() {
    const { context } = this.props;

    if (context === "automation") {
      return this.renderConditionsFilters();
    }

    return (
      <div>
        <hr/>
        <label className="form-label">{ i18n("display_if") }</label>
        {this.renderConditionsFilters()}
      </div>
    );
  }
}

export default ConditionalDisplayOption;
