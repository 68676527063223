import { Accesspoint } from "../types/Accesspoint";

export function accesspointFromBundles(accesspoints: Accesspoint[], accesspoint: Accesspoint): any {
  return accesspoints.filter(acp => {
    if (acp.type !== "bundle") return false;

    return acp?.accesspoint_bundle?.bundled_accesspoints?.map(bundled_accesspoint => bundled_accesspoint.accesspoint_id).includes(accesspoint._id);
  }).map(bundle => {
    return {
      name: bundle.name,
      id: bundle.id,
      accesspoint_bundle: bundle.accesspoint_bundle
    };
  });
}
