import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import CreateAccesspointModal from "./CreateAccesspointModal.react";

import Filter from "../Filter.react";

class AccesspointsModal extends Component {
  constructor(props) {
    super(props);
    [
      "availableAccesspoint",
      "renderAccesspointSelect",
      "onChange",
      "openCreateAccesspointModal",
      "closeCreateAccesspointModal",
      "getNewAccesspoint"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = { selectedAccesspoint: null, showCreateAccesspointModal: false, searchValue: null };
  }

  onChange(stateValue) {
    const { updateParent } = this.props;
    if (stateValue.selectedItemIds) {
      this.setState({ selectedAccesspoint: stateValue.selectedItemIds[0] });
      updateParent(stateValue.selectedItemIds[0]);
    } else { //searchValue
      this.setState({ searchValue: stateValue.searchValue });
    }
  }

  openCreateAccesspointModal() {
    this.setState({ showCreateAccesspointModal: true });
  }

  closeCreateAccesspointModal() {
    this.setState({ showCreateAccesspointModal: false });
  }

  getNewAccesspoint(accesspoint) {
    const { updateParent } = this.props;
    this.setState({ showCreateAccesspointModal: false, selectedAccesspoint: accesspoint._id });
    window.requestAnimationFrame(() => {
      updateParent(accesspoint._id);
    });
  }

  availableAccesspoint() {
    const { accesspoints, mappingAccesspoints } = this.props;
    return accesspoints.filter(accesspoint => {
      return !Object.keys(mappingAccesspoints).includes(accesspoint.id);
    });
  }

  renderAccesspointSelect() {
    const { selectedAccesspoint, searchValue } = this.state;
    const selectedItemIds = selectedAccesspoint ? [selectedAccesspoint] : [];
    return (
      <div className="container-modal">
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              {I18n.t("guest_import_operations.mapping_step.accesspoint_modal.message")}
              <a onClick={this.openCreateAccesspointModal}>{" " + I18n.t("guest_import_operations.mapping_step.accesspoint_modal.create_accesspoint_button")}</a>
            </div>
          </div>
          <Filter
            items={this.availableAccesspoint()}
            selectedItemIds={selectedItemIds}
            searchValue={searchValue}
            translationKey="accesspoint"
            onChange={this.onChange}
            itemIdKey={"id"}
            hasSearch={true}
            multipleSelect={false}
          />
        </div>
      </div>
    );
  }

  render() {
    const { onCloseFunction, isVisible, eventId, locale } = this.props;
    const { showCreateAccesspointModal } = this.state;
    return (
      <div>
        <CreateAccesspointModal isVisible={showCreateAccesspointModal} eventId={eventId} locale={locale} onCloseFunction={this.closeCreateAccesspointModal} updateParent={this.getNewAccesspoint} />
        <Modal show={isVisible} onHide={onCloseFunction}>
          <Modal.Header>
            <Modal.Title>{I18n.t("guest_import_operations.mapping_step.accesspoint_modal.title")}</Modal.Title>
            <button type="button" onClick={onCloseFunction} className="btn-close" aria-label={I18n.t("close")}></button>
          </Modal.Header>
          <Modal.Body >
            {this.renderAccesspointSelect()}
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AccesspointsModal;

AccesspointsModal.propTypes = {
  updateParent: PropTypes.func.isRequired,
  onCloseFunction: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  accesspoints: PropTypes.array.isRequired,
  eventId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
};
