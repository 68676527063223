"use strict";
import { Component } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Select from "react-select";
import filter from "lodash/filter";
import snakeCase from "lodash/snakeCase";
import pick from "lodash/pick";
import moment from "moment";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withModal from "./withModal.react";

import { extractSimpleQueryFromUrl, extractComposedTerms } from "../utils/QueryStringUtils";
import { urlWithQuery, urlEventLocale } from "../utils/pathUtils";
import { objectsFromIds, ShowedUpStatusesFromIds } from "../utils/identifierUtils";
import { removeQuotes } from "../utils/StringUtils";

import { GuestStatuses, PaymentStatuses, ShowedUpStatuses, SearchQueryKeywords, emailActivityFilters,
  optinsFilters, LazyCalculatedType, GuestSSOStatuses } from "../constants/Constants";

import ByFieldFilters from "../components/ByFieldFilters.react";
import FilterDropdown from "../components/FilterDropdown.react";
import DateTimePicker from "../components/shared/DateTimePicker.react";
import { GuestsThematicsQuartilesFilter, QuartileOption, QuartileMultiValueContainer, QuartileMultiValueLabel, quartileCustomStyles, QUARTILES } from "./GuestsThematicsQuartilesFilter.react";

import { updateUI } from "../actions/UIAppearanceActionCreators";
import { setSimpleSearchQuery } from "../actions/SearchQueryActionCreators";
import { updateExpectedAtAccesspointIds, updateAcceptedAtAccesspointIds } from "../actions/AccesspointActionCreators";
import { setSelectedGuestCategoryIds } from "../actions/GuestCategoryActionCreators";
import { setSelectedGuestLabelIds } from "../actions/LabelsActionCreators";
import { setSelectedGuestStatuses } from "../actions/GuestStatusActionCreators";
import { setSelectedSSOStatuses } from "../actions/SSOStatusActionCreators";
import { setSelectedPaymentStatuses } from "../actions/PaymentStatusActionCreators";
import { setSelectedShowedUpStatuses } from "../actions/ShowedUpStatusesActionCreators";
import { setSelectedThematicIds } from "../actions/ThematicsActionCreators";
import { setSelectedEngagementLevels } from "../actions/EngagementLevelsActionCreators";
import { updateRegisteredAtDates } from "../actions/RegistrationDateActionCreators";
import { updateSelectedEmailActivity } from "../actions/EmailActivitiesActionCreators";
import { updateSelectedOptin } from "../actions/OptinActionCreators";
import { setSelectedThematicsIdsInGuestsThematicsQuartiles, setSelectedQuartilesIdsInGuestsThematicsQuartiles } from "../actions/GuestsThematicsQuartilesActionCreators.ts";

import { startFillingQueryStringFilters, stopFillingQueryStringFilters } from "../actions/GuestPageActionCreators";

import { queryStringAndSelectedGuests } from "../utils/QueryStringUtils";

import { isEnabled } from "../utils/featureSetUtils";
import { CHECKIN, PAYMENT, THEMATIC_SCORING, ENGAGEMENT_SCORING } from "../constants/FeaturesSet";

class AdvancedSearch extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    const methods = [
      "_completedGuestsThematicsQuartilesInput",
      "_handleChangeRegistrationDate",
      "_handleChangeTime",
      "_onSearchBarChange",
      "_onSubmit",
      "_resetRegisteredDate",
      "byFieldFilterIsEditing",
      "handleKeyDown",
      "onEnterFilterDropdown",
      "onExitFilterDropdown"
    ];

    methods.forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      displayGuestsThematicsQuartilesAlert: false,
      nbFiltersBeingEdited: 0,
      searchBarValue: this._getSearchBarValue(),
      searchQuery: queryStringAndSelectedGuests(props.location),
      submitLaunched: false
    };

    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    this.setState({ filtersDataLoaded: false });
    this.props.stopFillingQueryStringFilters();
  }

  componentDidMount() {
    const { startFillingQueryStringFilters, updateUI } = this.props;
    const updates = {
      "modalTitle": I18n.t("react.reports.advanced_search")
    };
    startFillingQueryStringFilters();
    updateUI(updates);
  }

  componentDidUpdate(prevProps) {
    const { location, selectedGuests } = this.props;
    if (prevProps.location !== location || prevProps.selectedGuests !== selectedGuests) {
      const searchQuery = queryStringAndSelectedGuests(location, selectedGuests);
      this.setState({ searchQuery: searchQuery });
    }
  }

  _onSearchBarChange(value) {
    this.setState({ searchBarValue: value });
    this.props.setSimpleSearchQuery(value);
  }

  onEnterFilterDropdown() {
    const { nbFiltersBeingEdited } = this.state;
    this.setState({ nbFiltersBeingEdited: nbFiltersBeingEdited + 1 });
  }

  onExitFilterDropdown() {
    const { nbFiltersBeingEdited } = this.state;
    this.setState({ nbFiltersBeingEdited: nbFiltersBeingEdited - 1 });
  }

  byFieldFilterIsEditing(editing) {
    const { nbFiltersBeingEdited } = this.state;
    const change = editing ? 1 : -1;
    this.setState({ nbFiltersBeingEdited: nbFiltersBeingEdited + change });
  }

  _containFieldFilters() {
    const { guestFields } = this.props;
    const searchQuery = extractComposedTerms(this.state.searchQuery);
    if (searchQuery === undefined) return false;
    const hasGuestFieldFilter = searchQuery.find(term => {
      return guestFields.find(gf => {
        return gf.key == term.searchField;
      });
    });
    return hasGuestFieldFilter != undefined;
  }

  _getSearchBarValue() {
    return this.state ? extractSimpleQueryFromUrl(this.state.searchQuery) : null;
  }

  _guestCategoryFilter() {
    const { guestCategories, setSelectedGuestCategoryIds, selectedGuestCategoryIds } = this.props;
    return <FilterDropdown
      id="guest_category"
      translationKey="guest_category"
      wrapperClasses="mb-10"
      items={guestCategories}
      selectedItemIds={selectedGuestCategoryIds}
      onChange={setSelectedGuestCategoryIds}
      submitLaunched={this.state.submitLaunched}
      itemColorKey="label_color"
      onFocus={this.onEnterFilterDropdown}
      onBlur={this.onExitFilterDropdown} />;
  }

  _guestLabelFilter() {
    const { labels, selectedGuestLabelIds, setSelectedGuestLabelIds } = this.props;
    return <FilterDropdown
      id="labels"
      translationKey="labels"
      wrapperClasses="mb-10"
      items={labels.items}
      selectedItemIds={selectedGuestLabelIds}
      onChange={setSelectedGuestLabelIds}
      submitLaunched={this.state.submitLaunched}
      itemIdKey="_id"
      itemColorKey="color"
      onFocus={this.onEnterFilterDropdown}
      onBlur={this.onExitFilterDropdown} />;
  }

  _guestThematicsFilter() {
    const { thematics, selectedThematicIds, setSelectedThematicIds } = this.props;

    return <FilterDropdown
      id="thematics"
      translationKey="thematics"
      wrapperClasses="mb-10"
      items={thematics.items || []}
      selectedItemIds={selectedThematicIds}
      onChange={setSelectedThematicIds}
      submitLaunched={this.state.submitLaunched}
      itemIdKey="_id"
      itemColorKey="color"
      onFocus={this.onEnterFilterDropdown}
      onBlur={this.onExitFilterDropdown} />;
  }

  _renderGuestsEngagementFilter() {
    const { selectedEngagementLevels, setSelectedEngagementLevels } = this.props;

    return <Select
      className="react-select"
      classNamePrefix="react-select"
      value={QUARTILES.filter(({ value }) => selectedEngagementLevels.includes(value))}
      isMulti={true}
      options={QUARTILES}
      onChange={ (items) => setSelectedEngagementLevels(items && items.map(item => item.value) || []) }
      placeholder={I18n.t("react.reports.engagement_levels.engagement_levels_filter_placeholder")}
      noOptionsMessage={() => I18n.t("react.reports.no_levels")}
      closeMenuOnSelect={false}
      formatOptionLabel={QuartileOption}
      components={{ MultiValueContainer: QuartileMultiValueContainer, MultiValueLabel: QuartileMultiValueLabel }}
      styles={quartileCustomStyles}
    />;
  }

  _renderGuestsThematicsQuartilesFilter() {
    const {
      selectedQuartilesIdsInGuestsThematicsQuartiles,
      selectedThematicsIdsInGuestsThematicsQuartiles,
      setSelectedQuartilesIdsInGuestsThematicsQuartiles,
      setSelectedThematicsIdsInGuestsThematicsQuartiles
    } = this.props;
    const { displayGuestsThematicsQuartilesAlert } = this.state;
    return (
      <GuestsThematicsQuartilesFilter
        displayInputsAlert={displayGuestsThematicsQuartilesAlert}
        onQuartilesChange={setSelectedQuartilesIdsInGuestsThematicsQuartiles}
        onThematicsChange={setSelectedThematicsIdsInGuestsThematicsQuartiles}
        selectedQuartilesIds={selectedQuartilesIdsInGuestsThematicsQuartiles}
        selectedThematicsIds={selectedThematicsIdsInGuestsThematicsQuartiles}
      />
    );
  }

  _guestStatusFilter() {
    const statuses = objectsFromIds(GuestStatuses);
    const { selectedGuestStatuses, setSelectedGuestStatuses } = this.props;
    return <FilterDropdown
      id="status"
      translationKey="status"
      wrapperClasses="mb-10"
      items={statuses}
      selectedItemIds={selectedGuestStatuses}
      onChange={setSelectedGuestStatuses}
      submitLaunched={this.state.submitLaunched}
      onFocus={this.onEnterFilterDropdown}
      onBlur={this.onExitFilterDropdown} />;
  }

  _guestSsoStatusFilter() {
    const ssoStatuses = objectsFromIds(GuestSSOStatuses);
    const { selectedSSOStatuses, setSelectedSSOStatuses } = this.props;
    return <FilterDropdown
      id="sso_status"
      translationKey="sso_status"
      wrapperClasses="mb-10"
      items={ssoStatuses}
      selectedItemIds={selectedSSOStatuses}
      onChange={setSelectedSSOStatuses}
      submitLaunched={this.state.submitLaunched}
      onFocus={this.onEnterFilterDropdown}
      onBlur={this.onExitFilterDropdown} />;
  }

  _paymentStatusFilter() {
    if (!isEnabled(PAYMENT)) return;

    const paymentStatuses = objectsFromIds(PaymentStatuses);
    const { selectedPaymentStatuses, setSelectedPaymentStatuses } = this.props;
    return <FilterDropdown
      id="payment_status"
      translationKey="payment_status"
      wrapperClasses="mb-10"
      items={paymentStatuses}
      selectedItemIds={selectedPaymentStatuses}
      onChange={setSelectedPaymentStatuses}
      submitLaunched={this.state.submitLaunched}
      onFocus={this.onEnterFilterDropdown}
      onBlur={this.onExitFilterDropdown} />;
  }

  _showedUpFilter() {
    if (!isEnabled(CHECKIN)) return;

    const showedUpStatuses = ShowedUpStatusesFromIds(ShowedUpStatuses);
    const { selectedShowedUpStatuses, setSelectedShowedUpStatuses } = this.props;
    return <FilterDropdown
      id="showed_up"
      translationKey="showed_up"
      wrapperClasses="mb-10"
      items={showedUpStatuses}
      selectedItemIds={selectedShowedUpStatuses}
      onChange={setSelectedShowedUpStatuses}
      submitLaunched={this.state.submitLaunched}
      multipleSelect={false}
      onFocus={this.onEnterFilterDropdown}
      onBlur={this.onExitFilterDropdown} />;
  }

  _expectedAtFilter() {
    const { expectedAtAccesspointIds, updateExpectedAtAccesspointIds, accesspoints } = this.props;
    if (accesspoints.length === 0) return null;

    return <FilterDropdown
      id="expected_at"
      translationKey="expected_at"
      wrapperClasses="mb-10"
      items={accesspoints}
      selectedItemIds={expectedAtAccesspointIds}
      onChange={updateExpectedAtAccesspointIds}
      submitLaunched={this.state.submitLaunched}
      onFocus={this.onEnterFilterDropdown}
      onBlur={this.onExitFilterDropdown} />;
  }

  _acceptedAtFilter() {
    if (!isEnabled(CHECKIN)) return;

    const { acceptedAtAccesspointIds, updateAcceptedAtAccesspointIds, accesspoints } = this.props;
    if (accesspoints.length === 0) return null;

    return <FilterDropdown
      id="checked_in_at"
      translationKey="checked_in_at"
      wrapperClasses="mb-10"
      items={accesspoints}
      selectedItemIds={acceptedAtAccesspointIds}
      onChange={updateAcceptedAtAccesspointIds}
      submitLaunched={this.state.submitLaunched}
      onFocus={this.onEnterFilterDropdown}
      onBlur={this.onExitFilterDropdown} />;
  }

  _renderFilterEmailActivity(filter) {
    const { emailActivities } = this.props;
    const selectedItemIds = emailActivities[filter] ? [emailActivities[filter]] : [];
    const items = [{ id: "yes", name: I18n.t("yyes") }, { id: "no", name: I18n.t("nno") }];
    return <FilterDropdown
      id={ filter }
      translationKey={ filter }
      wrapperClasses="mb-10"
      key={filter}
      items={items}
      selectedItemIds={selectedItemIds}
      onChange={this.changeEmailActivity(filter)}
      submitLaunched={this.state.submitLaunched}
      onFocus={this.onEnterFilterDropdown}
      onBlur={this.onExitFilterDropdown}
      sortItems={false}
      multipleSelect={false}
      hasSearch={false}
      hasSelectAll={false}
      allowUnselect={true} />;
  }

  changeEmailActivity(filter) {
    return (values) => {
      const { updateSelectedEmailActivity } = this.props;
      updateSelectedEmailActivity(filter, values[0]);
    };
  }

  _renderOptinFilter(filter) {
    const { selectedOptins } = this.props;
    const selectedItemIds = selectedOptins[filter] ? [selectedOptins[filter]] : [];
    const items = [{ id: "true", name: I18n.t("yyes") }, { id: "false", name: I18n.t("nno") }];

    return <FilterDropdown
      id={ filter }
      translationKey={ filter }
      wrapperClasses="mb-10"
      key={filter}
      items={items}
      selectedItemIds={selectedItemIds}
      onChange={this.changeSelectedOptin(filter)}
      submitLaunched={this.state.submitLaunched}
      onFocus={this.onEnterFilterDropdown}
      onBlur={this.onExitFilterDropdown}
      sortItems={false}
      multipleSelect={false}
      hasSearch={false}
      hasSelectAll={false}
      allowUnselect={true} />;
  }

  changeSelectedOptin(filter) {
    return (values) => {
      const { updateSelectedOptin } = this.props;
      updateSelectedOptin(filter, values[0]);
    };
  }

  _computeDateFromString(string) {
    const dateStr = removeQuotes(string);
    const date = moment(dateStr, "YYYY-MM-DD HH:mm ZZ");
    const year = parseInt(date.format("YYYY"));
    const month = parseInt(date.format("MM")) - 1;
    const day = parseInt(date.format("DD"));
    const hour = parseInt(date.format("HH"));
    const minute = parseInt(date.format("mm"));
    const newDate = new Date(year, month, day, hour, minute);
    return newDate;
  }

  _updateRegisteredDate(which, fDate) {
    const { registrationDate, updateRegisteredAtDates } = this.props;
    if (which == "registeredAfter") {
      updateRegisteredAtDates(removeQuotes(registrationDate.registeredBefore), fDate);
    } else if (which == "registeredBefore") {
      updateRegisteredAtDates(fDate, removeQuotes(registrationDate.registeredAfter));
    }
  }

  _handleChangeTime(which, method) {
    return (e) => {
      const { registrationDate } = this.props;
      let newDate = this._computeDateFromString(registrationDate[which]);
      newDate[method](parseInt(e.target.value));
      const fDate = moment(newDate).format("YYYY-MM-DD HH:mm ZZ");
      this._updateRegisteredDate(which, fDate);
    };
  }

  _handleChangeRegistrationDate(which) {
    return (date) => {
      const fDate = date ? moment(date).format("YYYY-MM-DD HH:mm ZZ") : null;
      this._updateRegisteredDate(which, fDate);
    };
  }

  _resetRegisteredDate(which) {
    return (e) => {
      e.preventDefault();
      this._updateRegisteredDate(which, null);
    };
  }

  _renderFilterRegisteredDate(which) {
    const { registrationDate } = this.props;
    const selectedDate = registrationDate && registrationDate[which] ? moment(removeQuotes(registrationDate[which]), "YYYY-MM-DD HH:mm ZZ") : null;
    return (
      <DateTimePicker
        title={ I18n.t(`react.registration_date.${snakeCase(which)}`) }
        selectedDate={selectedDate}
        onChangeDate={this._handleChangeRegistrationDate(which)}
        onChangeHour={this._handleChangeTime(which, "setHours")}
        onChangeMinute={this._handleChangeTime(which, "setMinutes")}
        locale={urlEventLocale()}
        reset={this._resetRegisteredDate(which)}
        labelDateFormat={"YYYY-MM-DD HH:mm ZZ"}
      />
    );
  }

  _renderGuestsThematicsQuartilesSection() {
    const { event } = this.props;
    const { has_thematics } = event;

    return event.scoring_and_engagement_enabled && has_thematics && isEnabled(THEMATIC_SCORING) && (
      <div className="row">
        <div className="col-md-12">
          <h4 className="trigger-action-title">
            {I18n.t("react.reports.guest_thematics_quartiles.by_guest_thematics_quartiles")}
          </h4>
          {this._renderGuestsThematicsQuartilesFilter()}
        </div>
      </div>
    );
  }

  _renderFilters() {
    const { event, accesspoints } = this.props;

    return (
      <div>
        <div className="row mt-20">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6 d-flex flex-column">
                <h4 className="trigger-action-title">{I18n.t("react.reports.guest_category.by_affinity")}</h4>
                {this._guestCategoryFilter()}
                {this._guestLabelFilter()}
                {this._guestThematicsFilter()}
              </div>

              <div className="col-md-6 d-flex flex-column">
                <h4 className="trigger-action-title">{I18n.t("react.reports.status.by_status")}</h4>
                {this._guestStatusFilter()}
                {this._guestSsoStatusFilter()}
                {this._paymentStatusFilter()}
                {this._showedUpFilter()}
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-md-6 d-flex flex-column">
                <h4 className="trigger-action-title">{ I18n.t("react.registration_date.title") }</h4>
                <div className="mb-10">
                  {this._renderFilterRegisteredDate("registeredAfter")}
                </div>
                {this._renderFilterRegisteredDate("registeredBefore")}
              </div>

              <div className="col-md-6 d-flex flex-column">
                { accesspoints.length > 0 && <>
                  <h4 className="trigger-action-title">{I18n.t("react.reports.expected_at.by_checkin_points")}</h4>
                  {this._expectedAtFilter()}
                  {this._acceptedAtFilter()}
                </>
                }
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex flex-column">
            <h4 className="trigger-action-title">{ I18n.t("react.reports.email_activity.title") }</h4>
            { emailActivityFilters.map(filter => this._renderFilterEmailActivity(filter))}
          </div>
        </div>

        <div className="row mt-20">
          { event.scoring_and_engagement_enabled && isEnabled(ENGAGEMENT_SCORING) &&
            <div className="col-md-8 d-flex flex-column">
              <h4 className="trigger-action-title">
                {I18n.t("react.reports.engagement_levels.by_engagement_levels")}
              </h4>
              { this._renderGuestsEngagementFilter() }
            </div>
          }
          <div className={` d-flex flex-column ${event.scoring_and_engagement_enabled ? "col-md-4" : "col-md-12"}`}>
            <h4 className="trigger-action-title">{ I18n.t("react.reports.optins.title") }</h4>
            { optinsFilters.map(filter => {
              return this._renderOptinFilter(filter);
            })}
          </div>
        </div>
        {this._renderGuestsThematicsQuartilesSection()}
      </div>
    );
  }

  _onSubmit(e) {
    e.preventDefault();
    const { nbFiltersBeingEdited, searchQuery } = this.state;
    const displayGuestsThematicsQuartilesAlert = !this._completedGuestsThematicsQuartilesInput();
    this.setState({ displayGuestsThematicsQuartilesAlert });
    if (nbFiltersBeingEdited > 0 || displayGuestsThematicsQuartilesAlert) {
      return null;
    }
    const { stopFillingQueryStringFilters } = this.props;
    stopFillingQueryStringFilters();
    this.setState({ submitLaunched: true }, () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const { history } = this.props;
        const completeSearchQuery = encodeURIComponent(searchQuery);
        const searchURL = urlWithQuery(completeSearchQuery, "guests");
        history.push(searchURL);
      }, 50);
    });
  }

  _completedGuestsThematicsQuartilesInput() {
    const { selectedQuartilesIdsInGuestsThematicsQuartiles, selectedThematicsIdsInGuestsThematicsQuartiles } = this.props;
    // XNOR between the arrays length
    return (selectedQuartilesIdsInGuestsThematicsQuartiles.length > 0 ^ selectedThematicsIdsInGuestsThematicsQuartiles.length > 0) === 0;
  }

  _renderSaveSearchButton() {
    const { searchQuery, nbFiltersBeingEdited } = this.state;
    const disabled = nbFiltersBeingEdited > 0 ? "disabled" : "";

    return searchQuery && (
      <Link to={urlWithQuery(searchQuery, "guests/modal/save_search")} className={`btn btn-secondary ${disabled}`} style={{ marginRight: 5 }}>
        <i className="fa-regular fa-pie-chart"></i> {I18n.t("react.saved_report_form_component.save_report")}
      </Link>
    );
  }

  handleKeyDown(e) {
    if (e.keyCode === 13 && this.state.nbFiltersBeingEdited <= 0) {
      this._onSubmit(e);
    }
  }

  render() {
    const { guestFields, simpleSearchQuery } = this.props;
    const { nbFiltersBeingEdited } = this.state;

    // inject non standard guest field that can be used as a guest field
    const filterableFields = guestFields.slice();
    filterableFields.push({
      key: SearchQueryKeywords.PROMO_CODE,
      label: I18n.t("react.reports.promo_code")
    });

    const submitButtonClasses = classNames({
      "btn": true,
      "btn-primary": true,
      "disabled": nbFiltersBeingEdited > 0
    });

    return (
      <div>
        <ByFieldFilters
          fields={filterableFields}
          onChange={this._onSearchBarChange}
          searchQueryString={simpleSearchQuery}
          submitLaunched={this.state.submitLaunched}
          isEditing={this.byFieldFilterIsEditing}
        />
        {this._renderFilters()}
        <hr />
        <form onSubmit={this._onSubmit} className="text-end">
          {this._renderSaveSearchButton()}
          <input type="submit" className={submitButtonClasses} value={I18n.t("react.reports.search")}/>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const res = pick(state, [
    "acceptedAtAccesspointIds",
    "emailActivities",
    "event",
    "expectedAtAccesspointIds",
    "labels",
    "registrationDate",
    "selectedGuestCategoryIds",
    "selectedGuestLabelIds",
    "selectedGuestStatuses",
    "selectedSSOStatuses",
    "selectedOptins",
    "selectedThematicsIdsInGuestsThematicsQuartiles",
    "selectedQuartilesIdsInGuestsThematicsQuartiles",
    "selectedPaymentStatuses",
    "selectedEngagementLevels",
    "selectedShowedUpStatuses",
    "selectedThematicIds",
    "simpleSearchQuery",
    "thematics"
  ]);
  res.guestFields = filter(state.guestFields.guestFields, field => field.type != LazyCalculatedType);
  res.guestCategories = state.guestCategories.data;
  res.accesspoints = state.accesspoints.data;
  res.selectedGuests = state.guests.selectedGuests;
  return res;
}

const mapDispatchToProps = {
  setSelectedEngagementLevels,
  setSelectedGuestCategoryIds,
  setSelectedGuestLabelIds,
  setSelectedGuestStatuses,
  setSelectedSSOStatuses,
  setSelectedPaymentStatuses,
  setSelectedQuartilesIdsInGuestsThematicsQuartiles,
  setSelectedShowedUpStatuses,
  setSelectedThematicIds,
  setSelectedThematicsIdsInGuestsThematicsQuartiles,
  setSimpleSearchQuery,
  startFillingQueryStringFilters,
  stopFillingQueryStringFilters,
  updateAcceptedAtAccesspointIds,
  updateExpectedAtAccesspointIds,
  updateRegisteredAtDates,
  updateSelectedEmailActivity,
  updateSelectedOptin,
  updateUI
};

const modalOptions = {
  customStyle: {
    content: {
      width: "1100px"
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withModal(AdvancedSearch, modalOptions)));
export { AdvancedSearch }; // pure component. Used in tests
