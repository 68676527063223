import { Component } from "react";
import PropTypes from "prop-types";
import CodeMirror from "react-codemirror";

require("codemirror/mode/htmlmixed/htmlmixed");
require("codemirror/addon/search/search");
require("codemirror/addon/search/searchcursor");
require("codemirror/addon/search/jump-to-line");
require("codemirror/addon/dialog/dialog");

// importing CSS files makes yarn test fail
if (process.env.NODE_ENV != "test") {
  require("codemirror/lib/codemirror.css");
  require("codemirror/addon/dialog/dialog.css");
}

class CodeEditor extends Component {

  componentDidMount() {
    const cm = this.refs.editor.getCodeMirror();
    const { width, height } = this.props;
    // set width & height
    cm.setSize(width, height);
  }

  render() {
    const { content, onContentChange, onSaveWithKeyboard } = this.props;
    const codeMirrorOptions = {
      lineNumbers: true,
      tabSize: 2,
      indentUnit: 2,
      lineWrapping: true,
      viewportMargin: 10,
      theme: "default",
      extraKeys: {
        "Ctrl-S": onSaveWithKeyboard,
        "Cmd-S": onSaveWithKeyboard
      }
    };
    return (
      <CodeMirror
        mode="liquid"
        onChange={onContentChange}
        value={content || ""}
        options={codeMirrorOptions}
        ref="editor"
      />
    );
  }
}

CodeEditor.defaultProps = {
  onSaveWithKeyboard: () => {}
};

CodeEditor.propTypes = {
  onContentChange: PropTypes.func.isRequired
};

export default CodeEditor;
