import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchThematics, createThematic } from "../actions/ThematicsActionCreators";
import { urlEventId } from "../utils/pathUtils";
import ThematicsDropdownComponent from "../components/ThematicsDropdownComponent.react";

interface Option {
  value: string;
  label: string;
}

interface Props {
  value: string[];
  allowCreate?: boolean;
  followHierarchyOnSelect?: boolean;
  coloredThematics?: boolean;
  menuPlacement?: string;

  onChange: (options: Option[]) => void;
}

const ThematicsDropdown: React.FC<Props> = (props) => {
  const thematics = useSelector((state: any) => state.thematics.items);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!thematics || thematics.length === 0) {
      dispatch(fetchThematics(urlEventId()));
    }
  }, []);

  const onCreate = props.allowCreate ? (
    (value: string): void => {
      dispatch(createThematic(urlEventId(), value));
    }
  ) : null;

  return <ThematicsDropdownComponent {...props} thematics={thematics} onCreate={onCreate} />;
};

export default ThematicsDropdown;
