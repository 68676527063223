import { Component } from "react";
import { pathToTranslation } from "../../utils/pathUtils";
import Loader from "../shared/Loader.react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const BOOTSTRAP_MAX_COL = 12;
const DATE_FIELDS = ["created_at"];

class TranslationsIndex extends Component {
  componentDidMount() {
    const { fetchObjects } = this.props;
    fetchObjects();
  }

  componentDidUpdate(prevProps) {
    const { type, fetchObjects } = this.props;
    if (type !== prevProps.type) fetchObjects();
  }

  renderTableRow(obj) {
    const { type, columns } = this.props;
    const key = obj.id || obj._id;

    const lineCells = columns.map((column, i) => {
      if (i == 0) return <td key={i}><Link to={pathToTranslation(type, obj)} className="text-default fw-bold"><i className="badge rounded-pill bg-secondary"></i>{ obj[column] }</Link></td>;
      if (DATE_FIELDS.indexOf(column) >= 0) return <td key={i}>{ I18n.l("time.formats.long", obj[column]) }</td>;
      if (column instanceof Array) return <td key={i}>{column[1](obj)}</td>;
      return <td key={i}>{ obj[column] }</td>;
    });
    return <tr key={key}>{ lineCells }</tr>;
  }

  renderTableCol() {
    const { columns } = this.props;
    const colLength = BOOTSTRAP_MAX_COL / columns.length;
    const headerLines = columns.map((column, i) => {
      let translationKey = column;
      if (column instanceof Array) translationKey = column[0];
      return <th key={i} className={`col-md-${colLength}`}>{I18n.t(`react.translations.index.${translationKey}`)}</th>;
    });
    return <tr key="O">{ headerLines }</tr>;
  }

  renderTable() {
    const { objects } = this.props;

    const rows = objects.map(obj => {
      return this.renderTableRow(obj);
    });

    return (
      <div className="table-responsive table-container mb-10" style={{ marginTop: "0px" }}>
        <table className="table table-light table-bordered table-hover">
          <thead>
            { this.renderTableCol() }
          </thead>
          <tbody>
            { rows }
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { objects } = this.props;
    if (!objects) return <Loader />;
    return this.renderTable();
  }
}

TranslationsIndex.propTypes = {
  columns: PropTypes.array.isRequired,
  fetchObjects: PropTypes.func.isRequired,
  objects: PropTypes.array
};

TranslationsIndex.defaultProps = {
  columns: ["name", "created_at"]
};

export default TranslationsIndex;
