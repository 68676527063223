import { Component } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class ConfirmableButton extends Component {

  constructor(props) {
    super(props);

    this.click = this.click.bind(this);
    this.confirm = this.confirm.bind(this);
    this.cancel = this.cancel.bind(this);

    this.state = {
      confirming: false
    };
  }

  click(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.disabled) return;
    this.setState({
      confirming: true
    });
  }

  cancel(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      confirming: false
    });
  }

  confirm(e) {
    e.preventDefault();
    const { onConfirm } = this.props;
    onConfirm(e);
    this.setState({
      confirming: false
    });
  }

  renderTooltip() {
    const { tooltipMessage } = this.props;
    return <Tooltip id="info">{tooltipMessage}</Tooltip>;
  }

  render() {
    const { confirming } = this.state;
    const { faIcon, btnType, title, confirmTitle, confirmBtnType, cancelTitle, cancelBtnType, tooltipPlacement, style, disabled } = this.props;
    if (confirming) {
      return (
        <div className="btn-group" role="group">
          <button onClick={this.confirm} type="button" className={`btn btn-${confirmBtnType}`} style={style}>{ confirmTitle }</button>
          <button onClick={this.cancel} type="button" className={`btn btn-${cancelBtnType}`} style={style}>{ cancelTitle }</button>
        </div>
      );
    }

    const disableLink = disabled ? "disabled" : "";
    // The link has to be wrapped in a <span> to display tooltip on disable link.
    // Mouse events don't fire on disabled elements (browser behavior), so hover is going to fail there without <span>
    return (
      <OverlayTrigger placement={tooltipPlacement} overlay={this.renderTooltip()}>
        <span>
          <a href="#" className={`btn btn-${btnType} ${disableLink}`} onClick={this.click} style={style}>
            <i className={`fa-regular fa-${faIcon}`}></i> { title }
          </a>
        </span>
      </OverlayTrigger>
    );
  }

}

ConfirmableButton.defaultProps = {
  btnType: "danger",
  cancelBtnType: "default",
  cancelTitle: I18n.t("react.confirmable_button.default_cancel"),
  confirmBtnType: "danger",
  confirmTitle: I18n.t("react.confirmable_button.default_confirm"),
  disabled: false,
  faIcon: "trash-can",
  style: {},
  title: "",
  tooltipMessage: I18n.t("react.confirmable_button.default_tooltip_message"),
  tooltipPlacement: "top"
};

ConfirmableButton.propTypes = {
  onConfirm: PropTypes.func.isRequired
};

export default ConfirmableButton;
