import { Component } from "react";
import { connect } from "react-redux";
import SlidingPane from "react-sliding-pane";
import { requestEvent } from "../../actions/ImportActionCreators";
import { fetchColumnsSets } from "../../actions/ColumnsSetsActionCreators";
import { changeSelectedColumnsSetId } from "../../actions/ColumnsSetsActionCreators";
import Loader from "../../components/shared/Loader.react";
import HelpSection from "../../components/shared/HelpSection.react";
import ColumnsSetsManager from "../../containers/ColumnsSetsManager.react";
import Rule, { FeatureScope } from "./Rule.react";
import { EventStoreProps } from "../../types/Event";
import { ColumnsSet } from "../../types/ColumnsSet";
import { populationI18n } from "../../utils/miscUtils";
import { redirectIfUnauthorized } from "../../utils/aclUtils";
import Icons from "../../constants/Icons";

interface StoreProps {
  event: EventStoreProps;
  columnsSets: ColumnsSet[];
}

interface DispatchProps {
  requestEvent: () => void;
  fetchColumnsSets: (eventId: string) => void;
  changeSelectedColumnsSetId: (columnsSetId: string) => void;
}

interface OwnProps {
  context?: string;
  objectName?: string;
  filterOnPopulations?: string[];
}

interface Props extends StoreProps, DispatchProps, OwnProps {}

interface State {
  guestViewRules: any;
  columnsSetsPaneOpen: boolean;
}

const i18n = (key: string): string => {
  return I18n.t(`react.guest_view_rules.${key}`);
};

class GuestViewRules extends Component<Props, State> {
  constructor(props: Props) {
    redirectIfUnauthorized("configuration", "manage");
    super(props);

    this.state = this.stateFromProps(props);

    [
      "onChangeRule",
      "toggleColumnsSetsPaneOpen",
      "showColumnsSetSetUp"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  componentDidMount(): void {
    this.props.requestEvent();
  }

  componentDidUpdate(prevProps: Props): void {
    const { event, columnsSets, fetchColumnsSets } = this.props;

    if (!prevProps.event.fetched && event.fetched) {
      this.setState(this.stateFromProps());

      if (!columnsSets || columnsSets.length === 0) {
        fetchColumnsSets(event.id);
      }
    }
  }

  stateFromProps(props = this.props): State {
    const { event } = props;

    return {
      guestViewRules: event.guest_view_rules || {},
      columnsSetsPaneOpen: false
    };
  }

  toggleColumnsSetsPaneOpen(): void {
    this.setState({ columnsSetsPaneOpen: !this.state.columnsSetsPaneOpen });
  }

  showColumnsSetSetUp(columnsSetId): void {
    const { changeSelectedColumnsSetId } = this.props;

    this.toggleColumnsSetsPaneOpen();
    changeSelectedColumnsSetId(columnsSetId);
  }

  onChangeRule(population: string, featureScope: string, rule: any): void {
    const { guestViewRules } = this.state;
    const populationRules = guestViewRules?.[population] || {};
    const nextPopulationRules = { ...populationRules, [featureScope]: rule };

    this.setState({ guestViewRules: { ...guestViewRules, [population]: nextPopulationRules } });
  }

  renderColumnsSetsPane(): JSX.Element {
    return <SlidingPane
      isOpen={this.state.columnsSetsPaneOpen}
      title={i18n("sliding_pane_title")}
      from="right"
      width='90%'
      onRequestClose={this.toggleColumnsSetsPaneOpen}
      className="width-100-xs width-90-sm"
      closeIcon={Icons.close()}
    >
      <ColumnsSetsManager />
    </SlidingPane>;
  }

  renderGuestViewRulesPanel(): JSX.Element {
    const { event, context, columnsSets, objectName, filterOnPopulations } = this.props;
    const featureScopes = context ? [context] : [];
    const { guestViewRules } = this.state;

    return <>
      <button type="button" onClick={this.toggleColumnsSetsPaneOpen} className="btn btn-secondary float-end ml-10">
        <i className="fa-regular fa-gear"></i> {i18n("manage_views")}
      </button>

      <HelpSection help={context ? i18n(`help_${context}`) : i18n("help")} classNames="mb-20" />

      {(filterOnPopulations || event.population_types).map(population => {
        return <div className="mb-3" key={population}>
          <label className="form-label">{populationI18n(population)}</label>
          {featureScopes.map(featureScope => {
            return <Rule
              key={`${population}_${featureScope}`}
              population={population}
              featureRule={guestViewRules?.[population]?.[featureScope]}
              featureScope={featureScope as FeatureScope}
              columnsSets={columnsSets}
              objectName={objectName || "event"}
              onChange={(rule): void => {
                this.onChangeRule(population, featureScope, rule);
              }}
              onClickColumnsSet={this.showColumnsSetSetUp}
            />;
          })}
        </div>;
      })}
      {this.renderColumnsSetsPane()}
    </>;
  }

  render(): JSX.Element {
    const { event, columnsSets } = this.props;

    if (!event?.fetched || !columnsSets) return <Loader />;

    return this.renderGuestViewRulesPanel();
  }
}

function mapStateToProps(state: any): StoreProps {
  return {
    event: state.event,
    columnsSets: state.columnsSets.data
  };
}

const mapDispatchToProps: DispatchProps = {
  requestEvent,
  fetchColumnsSets,
  changeSelectedColumnsSetId
};

export default connect<StoreProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(GuestViewRules);
