import { start, extractLink, extractTotalCount } from "../utils/APIUtils";
import { CALL_API } from "../constants/Constants";
import history from "../utils/getHistory";

import { normalize } from "normalizr";
import { showNotice } from "../actions/NoticeActionCreators";

const ALLOWED_METHOD = ["GET", "PUT", "POST", "DELETE"];

export default () => next => action => {
  const callAPI = action[CALL_API];

  if (typeof callAPI === "undefined") {
    // no need to call the API
    return next(action);
  }

  const { types, endpoint, method, body, redirectTo, redirectToOptions, notificationsOptions, schema, sendFile, nextAction, doNotUseReactRouter } = callAPI;

  // check data passed by action creators
  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every(type => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }
  if (ALLOWED_METHOD.indexOf(method) == -1) {
    throw new Error(`Expected method to be in ${ALLOWED_METHOD}`);
  }
  if ((method == "POST" || method === "PUT") && body == undefined) {
    throw new Error(`Calling ${method} without body`);
  }
  if (nextAction && !(nextAction.type || nextAction[CALL_API])) {
    throw new Error(`Next action should be a proper redux action, got ${nextAction}`);
  }
  if (redirectToOptions && !redirectTo) {
    throw new Error("Can't use redirectToOptions without a redirectTo");
  }

  function actionWith(data) {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  }
  const currentPath = window.location.pathname;

  const [ requestType, successType, failureType ] = types;
  next(actionWith({ type: requestType }));

  function handleNotifications(notificationParams, data) {
    if (notificationParams) {
      const { notice, noticeType, customDisplay } = notificationParams;
      let type = "info";
      let message = notice;
      if (noticeType === "notice_info_from_data") {
        message = data["message"];
      } else if (noticeType) {
        type = noticeType;
      }
      if (message) {
        next(showNotice(message, type, 5000, !!customDisplay));
      }
    }
  }

  start(method, endpoint, body, sendFile, function(data, status, response) {
    if (schema != undefined) {
      data = normalize(data, schema);
    }
    const finalAction = actionWith({ type: successType, data });
    finalAction["nextURL"] = extractLink("next", response);
    finalAction["previousURL"] = extractLink("previous", response);
    finalAction["totalCount"] = extractTotalCount(response);
    next(finalAction);
    if (redirectTo != null) {
      let redirectPath = redirectTo;
      if (redirectPath.indexOf(":id") > -1) {
        redirectPath = redirectPath.replace(":id", finalAction.data._id);
      }
      if (currentPath.includes("/r/") && redirectPath.includes("/r/") && !doNotUseReactRouter) {
        //from a react path to a react path, safe to push in the router
        history.push(redirectPath);
        handleNotifications(redirectToOptions, data);
      } else {
        window.location = redirectPath; //going through the rails controller
      }
    }
    handleNotifications(notificationsOptions, data);
    if (nextAction) {
      next(nextAction);
    }
  }, function(data) {
    const { responseJSON, status, statusText } = data;
    if (responseJSON) {
      return next(actionWith({ type: failureType, data: responseJSON }));
    } else if (status >= 400) {
      return next(actionWith({ type: failureType, data: { error: [statusText] } }));
    }
    return next(actionWith({ type: failureType, data }));
  });

};
