import { ActionTypes } from "../constants/Constants";

const ACTIONS = [
  ActionTypes.RECEIVE_THEMATICS_REQUEST,
  ActionTypes.RECEIVE_THEMATICS_SUCCESS,
  ActionTypes.CREATE_THEMATIC_REQUEST,
  ActionTypes.CREATE_THEMATIC_SUCCESS,
  ActionTypes.THEMATIC_FAILURE,
  ActionTypes.REMOVE_THEMATIC_SUCCESS,
  ActionTypes.UPDATE_THEMATIC_SUCCESS,
  ActionTypes.UPDATE_THEMATIC_REQUEST,
  ActionTypes.CLEAR_THEMATIC_ERRORS,
];

const initialState = {
  items: null,
  errors: null,
  lastUpdatedThematicId: null,
  fetching: false,
  otherEvents: {}
};

function thematics(state = initialState, action) {
  if (!ACTIONS.includes(action.type)) return state;

  if (action.byEventId) {
    let otherEvents = { ...state.otherEvents };
    otherEvents[action.byEventId] = eventThematics(otherEvents[action.byEventId] || initialState, action);
    return { ...state, otherEvents };
  } else {
    return { ...eventThematics(state, action), otherEvents: { ...state.otherEvents } };
  }
}

function eventThematics(state = initialState, action) {
  switch (action.type) {
  case ActionTypes.RECEIVE_THEMATICS_REQUEST:
    return { ...initialState, fetching: true };
  case ActionTypes.RECEIVE_THEMATICS_SUCCESS:
    return { items: action.data || [], fetching: false };
  case ActionTypes.CREATE_THEMATIC_REQUEST:
    return { ...state, fetching: true };
  case ActionTypes.CREATE_THEMATIC_SUCCESS: {
    const newItemsAfterCreate = state.items.slice();
    newItemsAfterCreate.unshift(action.data);
    return { items: newItemsAfterCreate, fetching: false, lastUpdatedThematicId: action.data._id };
  }
  case ActionTypes.THEMATIC_FAILURE: {
    const items = state.items.slice();
    const errors = Object.assign({}, action.data);
    return { items: items, errors: errors, lastUpdatedThematicId: action.thematicId || action.temporaryId, fetching: false };
  }
  case ActionTypes.REMOVE_THEMATIC_SUCCESS: {
    const newItemsAfterRemove = state.items.filter(thematic => {
      return thematic._id != action.thematicId && thematic.parent_thematic_id != action.thematicId;
    });
    return { items: newItemsAfterRemove, fetching: false };
  }
  case ActionTypes.UPDATE_THEMATIC_REQUEST: {
    return { ...state, lastUpdatedThematicId: null };
  }
  case ActionTypes.UPDATE_THEMATIC_SUCCESS: {
    const { name, color, thematicId } = action;
    const newItemsAfterUpdate = state.items.map(thematic => {
      if (thematic._id == thematicId) {
        return Object.assign({}, thematic, { name, color });
      } else if (action.data["updated_sub_thematics_colors"] && action.data.updated_sub_thematics_colors[thematic._id]) {
        return Object.assign({}, thematic, { color: action.data.updated_sub_thematics_colors[thematic._id] });
      }
      return thematic;
    });
    return { items: newItemsAfterUpdate, lastUpdatedThematicId: action.data._id, fetching: false };
  }
  case ActionTypes.CLEAR_THEMATIC_ERRORS:
    return { ...state, errors: undefined };

  default:
    return state;
  }
}


export default thematics;
