const LOCAL_ID_PREFIX = "meetingLocation-";
export const UNLIMITED_CAPACITY = -1;
export const DEFAULT_LIMITED_CAPACITY = 1;

export function randomId(prefix: string|null): string {
  return `${prefix}${(Math.random() + 1).toString(36).substring(7)}`;
}

export function newMeetingLocation(): MeetingLocation {
  return { name: "", _id: randomId(LOCAL_ID_PREFIX), capacity: UNLIMITED_CAPACITY };
}

export function isMeetingLocationPersisted(location: MeetingLocation): boolean {
  return !location._id?.startsWith(LOCAL_ID_PREFIX);
}

export interface MeetingLocation {
  _id: string;
  _destroy?: boolean;
  event_id?: string;
  capacity?: number;
  name: string;
}
