import { useSelector } from "react-redux";
import MultiSelect from "../MultiSelect.react";
import withProgramFilter from "../withProgramFilter.react";
import { FilterProps, FilterOption } from "../../../types/Program";

const SessionRoomFilter: React.FC<FilterProps> = ({ programFilter, onChangeFilterPresets }) => {
  const metadata = useSelector((state: any) => state.programs.metadata);

  const buildOptions = (): any[] => {
    if (!metadata[programFilter._type]) return [];

    return metadata[programFilter._type].possible_values;
  };

  const selectedOptions = (): any[] => {
    if (!metadata[programFilter._type]) return [];

    return metadata[programFilter._type].possible_values.filter((option) => {
      return (programFilter["preset_args"]["session_rooms"] || []).includes(option.value);
    });
  };

  const onChangeMultiSelect = (options: FilterOption[]): void => {
    onChangeFilterPresets("session_rooms", options.map(({ value }) => value));
  };

  return <MultiSelect
    options={buildOptions()}
    values={selectedOptions()}
    placeholder={I18n.t("react.programs.session_room_filter.dropdown_placeholder")}
    onChange={onChangeMultiSelect}
  />;
};

export default withProgramFilter(SessionRoomFilter, "selection");
