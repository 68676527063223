import { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/RegistrationFormsActionCreators";
import { fetchEventGuestFields } from "../actions/GuestFieldsActionCreators";
import { updateGuestCategory } from "../actions/GuestCategoryActionCreators";
import { guestFieldsBatchCreate } from "../actions/GuestFieldsActionCreators";
import { updateUI } from "../actions/UIAppearanceActionCreators";
import { pathToGuestCategoryLiquidField } from "../utils/pathUtils";
import ModalContainer from "./ModalContainer.react";
import Loader from "../components/shared/Loader.react";
import querystring from "querystring";

class RegistrationFormAttachToGuestCategory extends Component {
  constructor() {
    super();
    this._linkFormToGuestCategory = this._linkFormToGuestCategory.bind(this);
    this.state = { pendingRequest: false };
  }

  componentDidMount() {
    const { fetchRegistrationForm, location, fetchEventGuestFields, updateUI, match } = this.props;
    const { registration_form_id } = querystring.parse(location.search.substring(1));
    const modalTitle = I18n.t("react.registration_form_attach_to_guest_category.title");
    updateUI({ modalTitle });
    const { event_id } = match.params;
    fetchEventGuestFields(event_id);
    fetchRegistrationForm(registration_form_id);
  }

  componentDidUpdate(prevProps) {
    if (this.requestedMissingGuestFieldsCreation && this._missingGuestFields(this.props).length == 0) {
      // just created missing guest fields successfuly
      this._linkFormToGuestCategory(prevProps);
    }
  }

  _missingGuestFields(props = this.props) {
    const { registrationFormItems, guestFields } = props;
    let missingGuestFields = [];
    registrationFormItems.forEach(item => {
      if (!item.key) {
        return;
      }
      if (guestFields.find(field => { return field.key == item.key; }) == undefined) {
        missingGuestFields.push({
          key: item.key,
          label: item.label
        });
      }
    });
    return missingGuestFields;
  }

  _startLinkFormToGuestCategoryProcess() {
    return () => {
      const missingGuestFields = this._missingGuestFields();

      if (missingGuestFields.length > 0) {
        const { guestFieldsBatchCreate, match } = this.props;
        const { event_id } = match.params;
        this.requestedMissingGuestFieldsCreation = true;
        guestFieldsBatchCreate(event_id, missingGuestFields);
      } else {
        this._linkFormToGuestCategory();
      }
      this.setState({ pendingRequest: true });
    };
  }

  _linkFormToGuestCategory(props = this.props) {
    const { updateGuestCategory, match, location } = props;
    const { guest_category_id, registration_form_id } = querystring.parse(location.search.substring(1));
    const { event_id } = match.params;
    updateGuestCategory(event_id, guest_category_id, { registration_form_id, custom_registration_form_enabled: true }, pathToGuestCategoryLiquidField(guest_category_id));
  }

  renderErrors() {
    const { guestCategoriesErrors } = this.props;
    if (guestCategoriesErrors && guestCategoriesErrors["registration_form"] !== undefined) {
      return <div className="alert alert-danger">
        <strong>{I18n.t("react.registration_form_attach_to_guest_category.cannot_link_to_form")}</strong><br />
        { guestCategoriesErrors["registration_form"] }
      </div>;
    }
  }

  _renderMissingGuestFields() {
    const missingGuestFields = this._missingGuestFields();
    if (missingGuestFields.length == 0) {
      return null;
    }
    const rows = missingGuestFields.map(field => { return <p key={ field.key }> { field.label } <code> { field.key } </code> </p>; });
    return (
      <div>
        <hr />
        <p> { I18n.t("react.registration_form_attach_to_guest_category.missing_guest_fields_notice") } </p>
        { rows }
      </div>
    );
  }

  renderButton() {
    const { pendingRequest } = this.state;

    if (pendingRequest) {
      return (
        <button className="btn btn-secondary float-end" disabled="disabled">
          <Loader size="small" /> { I18n.t("react.registration_form_attach_to_guest_category.link_pending") }
        </button>
      );
    }

    return (
      <button onClick={ this._startLinkFormToGuestCategoryProcess() } className="btn btn-secondary float-end">
        <i className="fa-regular fa-link"></i> { I18n.t("react.registration_form_attach_to_guest_category.link_attach_category") }
      </button>
    );
  }

  render() {
    return (
      <ModalContainer>
        { this.renderErrors() }
        <p> { I18n.t("react.registration_form_attach_to_guest_category.attach_notice") } </p>
        { this._renderMissingGuestFields() }
        { this.renderButton() }
      </ModalContainer>
    );
  }

}

function mapStateToProps(state) {
  return {
    registrationFormItems: state.registrationFormItems,
    guestFields: state.guestFields.guestFields,
    guestCategoriesErrors: state.guestCategories.errors
  };
}

function mapDispatchToProps() {
  return Object.assign({}, actions, { fetchEventGuestFields }, { updateGuestCategory }, { guestFieldsBatchCreate }, { updateUI });
}

export default connect(mapStateToProps, mapDispatchToProps()) (RegistrationFormAttachToGuestCategory);
