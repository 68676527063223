import { Partner } from "../../../types/Partner";
import { Purpose } from "../../../types/Purpose";
import PurposesPicker from "./PurposesPicker.react";

interface Props {
  partner: Partner;
  customPurposes: Purpose[];
  userDefinedPurposeIds: string[];
  integrationScript: string[];
  onPurposeIdsChange: (purposeIds: string[]) => void;
  onIntegrationScriptChange: (script: string) => void;
  onPartnerChange: (partner: Partner) => void;
  removePartner: () => void;
}

const i18n = (key: string, opts = {}): string => {
  return I18n.t(`react.consent_notice.partner_view.${key}`, opts);
};

const PartnerView: React.FC<Props> = ({
  partner,
  customPurposes,
  userDefinedPurposeIds,
  integrationScript,
  onPurposeIdsChange,
  onIntegrationScriptChange,
  onPartnerChange,
  removePartner
}) => {

  const hasDefaultPurposes = partner.default_purposes_id.length > 0;
  const privacyPolicyUrl = partner.links?.privacypolicy?.length > 0 && partner.links.privacypolicy;

  return (
    <div className="card" key={partner.sdk_id}>
      {!partner.allow_edit && (
        <div className="card-header text-center">
          <h4 className="card-title">
            {partner.name}
          </h4>
        </div>
      )}
      <div className="card-body">
        {partner.allow_edit && (
          <div className="mb-3">
            <label className="form-label">{i18n("name")}</label>
            <input type="text" className="form-control" value={partner.name} onChange={(e): void => {
              onPartnerChange({ ...partner, name: e.target.value });
            }}/>
          </div>
        )}
        <div className="mb-3">
          <label className="form-label">{i18n("purposes")}</label><br />
          {hasDefaultPurposes ? (
            <>
              <p className="text-muted">
                <em>{i18n("known_purposes_help")}</em>
              </p>
              <PurposesPicker
                readonly={true}
                selectedPurposeIds={partner.default_purposes_id}
              />
            </>
          ) : (
            <>
              <p className="text-muted">
                <em>{i18n("purposes_help")}</em>
              </p>

              <PurposesPicker
                selectedPurposeIds={userDefinedPurposeIds}
                customPurposes={customPurposes}
                onChange={onPurposeIdsChange}
              />
            </>
          )}
        </div>
        <div className="mb-3">
          {partner.partner_code_input ? (
            <>
              <label className="form-label">{partner.partner_code_input.label}</label>
              <input type="text" className="form-control" value={partner.partner_code} onChange={(e): void => {
                onPartnerChange({ ...partner, partner_code: e.target.value });
              }} />
              {partner.partner_code_input.help && (
                <div className="form-text">
                  {partner.partner_code_input.help}
                </div>
              )}
            </>
          ) : (
            <>
              <label className="form-label">{i18n("partner_integration_script")}</label>
              <textarea
                className="form-control"
                value={integrationScript}
                rows={5}
                onChange={(e): void => {
                  onIntegrationScriptChange(e.target.value);
                }}
                placeholder={i18n("partner_integration_script_placeholder")}
              >
              </textarea>
            </>
          )}
        </div>

        {partner.allow_edit ? (
          <div className="mb-3">
            <label className="form-label">{i18n("privacy_policy_url")}</label>
            <input type="text" className="form-control" value={partner.links.privacypolicy} onChange={(e): void => {
              const nextPartner = { ...partner };
              nextPartner.links = { privacypolicy: e.target.value };
              onPartnerChange(nextPartner);
            }} />
          </div>
        ) : (
          privacyPolicyUrl && (
            <div className="form-text">
              <em>
                <a href={privacyPolicyUrl} target="_blank">{privacyPolicyUrl}</a>
              </em>
            </div>
          )
        )}

        <a href="#" className="float-end" onClick={(e): void => {
          e.preventDefault();
          removePartner();
        }}>
          <i className="fa-regular fa-trash-can"></i>
        </a>
      </div>
    </div>
  );
};

export default PartnerView;
