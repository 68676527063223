import { useContext } from "react";
import { featureName, featureSetTranslation } from "../../utils/featureSetUtils";
import { FeatureDeckContext } from "../../containers/features/FeatureSetFormContainer.react";
import { Modal } from "react-bootstrap";

interface Props {
  featureKey?: string;
  hide: () => void;
}

const DependenciesModal: React.FC<Props> = ({ featureKey, hide }) => {
  const { toggleFeature, hasDisabledDependencies, disabledDependencies } = useContext(FeatureDeckContext);

  if (!featureKey) return null;

  if (!hasDisabledDependencies(featureKey)) return null;

  return <Modal show={ true } onHide={ hide } className="feature-set">
    <Modal.Header>
      <h4 className="modal-title">{ featureSetTranslation("additional_features_required") }</h4>
      <button className="btn-close" onClick={ hide }></button>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        <div className="col-sm-12">
          <p>{ I18n.t("features_form.enable_feature_dependencies", { name: featureName(featureKey) }) }</p>
          <div key={ featureKey }>
            <ul className="dependencies-list mt-10 ps-0">
              { disabledDependencies(featureKey).map((dependency, index) => {
                return <li key={ index }>{ featureName(dependency) }</li>;
              })}
            </ul>
            <div className="btn-group-modal d-flex flex-row justify-content-end">
              <button type="button" className="btn btn-secondary mr-10" onClick={ hide }>{ I18n.t("cancel") }</button>
              <button type="button" className="btn btn-primary" onClick={ (): void => toggleFeature(featureKey) }>
                { featureSetTranslation("save_label") }
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
  </Modal>;
};

export default DependenciesModal;
