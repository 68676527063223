import { FEATURE_BY_KEY, FEATURES_DEPENDENTS } from "../constants/FeaturesSet";

export function getEnabledFeatures() {
  return window.ReactGlobalProps && window.ReactGlobalProps.event_enabled_features;
}

export function isEnabled(featureKey) {
  const features = getEnabledFeatures();

  // when no feature set for this event, we only make sure
  // the feature exists
  if (!Array.isArray(features)) return Object.keys(FEATURE_BY_KEY).includes(featureKey);

  return features.includes(featureKey);
}

export function allEnabled(featureKeys) {
  if (!featureKeys) return true;
  if (featureKeys.length === 0) return true;

  return featureKeys.every(featureKey => isEnabled(featureKey));
}

export function anyEnabled(featureKeys) {
  if (!featureKeys) return true;
  if (featureKeys.length === 0) return true;

  return featureKeys.some(featureKey => isEnabled(featureKey));
}

export function redirectIfDisabled(featureKey) {
  if (!isEnabled(featureKey)) window.location.href = "/?redirection_reason=feature_disabled";
}

export function featureTranslation(featureKey, attribute) {
  const keyName = featureKey.split(".").pop();

  return I18n.t(["features_form", featureKey, attribute].join("."), { defaultValue: keyName });
}

export function featureName(featureKey) {
  return featureTranslation(featureKey, "name");
}

export function featureDescription(featureKey) {
  return featureTranslation(featureKey, "description");
}

export function featureLink(featureKey) {
  const linkTranslation = I18n.t(["features_form", featureKey, "link"].join("."), { defaultValue: "" });

  if (linkTranslation === "") return null;

  return linkTranslation;
}

export function featureMetadata(featureKey) {
  const data = FEATURE_BY_KEY[featureKey];

  if (data && data["features"] && Object.keys(data["features"]).length === 1 && featureKey.indexOf(".") > 0) {
    return featureMetadata(`${featureKey}.${Object.keys(data["features"])[0]}`);
  }

  return data;
}

export function featureSetTranslation(attribute) {
  return I18n.t(["features_form", attribute].join("."));
}

export function dependencies(featureKey) {
  const metadata = featureMetadata(featureKey);
  if (!metadata) return [];

  return metadata.dependencies || [];
}

export function dependents(featureKey) {
  return FEATURES_DEPENDENTS[featureKey] || [];
}

export function childFeatures(groupKey) {
  if (isSingleFeature(groupKey)) return [];

  const metadata = featureMetadata(groupKey);

  return Object.keys(metadata.features || []).map((key) => {
    return `${groupKey}.${key}`;
  });
}

export function hasDependencies(featureKey) {
  return dependencies(featureKey).length > 0;
}

export function isSingleFeature(featureKey) {
  const metadata = featureMetadata(featureKey);

  if (metadata === null || !metadata.features) return true;

  Object.keys(metadata.features).length === 1;
}

export function enabledByDefault(featureKey) {
  const metadata = featureMetadata(featureKey);
  if (!metadata) return false;

  return metadata.enabled_by_default || false;
}
