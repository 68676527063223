import { ActionTypes } from "../constants/Constants";
import { StreamingArchivesState } from "../types/StreamingArchive";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";

const defaultState: StreamingArchivesState = {
  errors: null,
  isFetching: false,
  streamingArchives: null
};

function streamingArchives(state = defaultState, action: any): StreamingArchivesState {
  switch (action.type) {
  case ActionTypes.FETCH_STREAMING_ARCHIVES_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.FETCH_STREAMING_ARCHIVES_SUCCESS:
    return { ...state, streamingArchives: action.data, isFetching: false, errors: null };
  case ActionTypes.FETCH_STREAMING_ARCHIVES_FAILURE:
    return { ...state, streamingArchives: [], isFetching: false, errors: action.data };

  case ActionTypes.CREATE_STREAMING_ARCHIVE_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.CREATE_STREAMING_ARCHIVE_SUCCESS: {
    const archivesUpdated = state.streamingArchives ? insertOrUpdateObject(state.streamingArchives, action.data, "_id", false) : [action.data];
    return { ...state, streamingArchives: archivesUpdated, isFetching: false, errors: null };
  }
  case ActionTypes.CREATE_STREAMING_ARCHIVE_FAILURE:
    return { ...state, isFetching: false, errors: action.data };

  case ActionTypes.UPDATE_STREAMING_ARCHIVE_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.UPDATE_STREAMING_ARCHIVE_SUCCESS: {
    const archivesUpdated = state.streamingArchives ? insertOrUpdateObject(state.streamingArchives, action.data, "_id", false) : [action.data];
    return { ...state, streamingArchives: archivesUpdated, isFetching: false, errors: null };
  }
  case ActionTypes.UPDATE_STREAMING_ARCHIVE_FAILURE:
    return { ...state, isFetching: false, errors: action.data };

  case ActionTypes.DELETE_STREAMING_ARCHIVE_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.DELETE_STREAMING_ARCHIVE_SUCCESS: {
    const archivesUpdated = state.streamingArchives.filter(archive => archive._id !== action.streamingArchiveId);
    return { ...state, streamingArchives: archivesUpdated, isFetching: false, errors: null };
  }
  case ActionTypes.DELETE_STREAMING_ARCHIVE_FAILURE:
    return { ...state, isFetching: false, errors: action.data };

  case ActionTypes.FETCH_STREAMING_ARCHIVE_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.FETCH_STREAMING_ARCHIVE_SUCCESS: {
    if (state.streamingArchives) {
      const archiveUpdated = { ...state.streamingArchives.find(archive => archive._id === action.streamingArchiveId), ...action.data };
      const archivesUpdated = insertOrUpdateObject(state.streamingArchives, archiveUpdated, "_id", false);
      return { ...state, streamingArchives: archivesUpdated, isFetching: false, errors: null };
    } else {
      return state;
    }
  }
  case ActionTypes.FETCH_STREAMING_ARCHIVE_FAILURE:
    return { ...state, streamingArchives: [], isFetching: false, errors: action.data };

  default:
    return state;
  }
}

export default streamingArchives;
