import FrontendLocales from "../../../config/frontend_locales.yml";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
  localeCode: string;
}

const Flag: React.FC<Props> = ({ localeCode }) => {
  const locale = FrontendLocales.find(l => localeCode === l.code);

  if (!locale) return null;

  const tootltip = <Tooltip id={`locale-${locale.code}`}>{locale[I18n.locale] || locale["en"]}</Tooltip>;

  return <OverlayTrigger placement="top" overlay={tootltip}>
    <span>{locale.flag}</span>
  </OverlayTrigger>;
};

export default Flag;
