import { Component } from "react";
import { connect } from "react-redux";
import startsWith from "lodash/startsWith";
import classNames from "classnames";
import { updateUI } from "../actions/UIAppearanceActionCreators";
import { deleteGuests } from "../actions/GuestUpdatesActionCreators";
import { requestCountGuests } from "../actions/ImportActionCreators";

import { queryStringAndSelectedGuests } from "../utils/QueryStringUtils";
import withModal from "./withModal.react";

class DeleteGuests extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "", submitStyle: "danger", submitDislabled: true };
    this._onChange = this._onChange.bind(this);
    this._submit = this._submit.bind(this);
  }

  componentDidMount() {
    const { updateUI, requestCountGuests } = this.props;
    const updates = {
      "modalTitle": I18n.t("guests.modal_confirmation_delete.confirm_destroy_all_guests")
    };
    updateUI(updates);
    requestCountGuests(this.searchQuery(), "selectedGuestCount", true);
  }

  _onChange(e) {
    const { value } = e.target;
    const intermediateMatchingString = I18n.t("react.reports.delete_guests.strong_delete");
    const finalMatchingString = `${I18n.t("react.reports.delete_guests.strong_delete")} ${this.props.selectedGuestCount} ${I18n.t("react.reports.delete_guests.strong_guests")}`;
    this.setState({ value });
    if (value == finalMatchingString) {
      this.setState({ submitStyle: "success", submitDislabled: false });
      return;
    }
    if (startsWith(value, intermediateMatchingString)) {
      this.setState({ submitStyle: "warning", submitDislabled: true });
      return;
    }
    this.setState({ submitStyle: "danger", submitDislabled: true });
  }

  _submit(e) {
    const { deleteGuests, closeModal } = this.props;
    deleteGuests(this.searchQuery());
    closeModal();
    e.preventDefault();
  }

  searchQuery() {
    const { location, selectedGuests } = this.props;
    return queryStringAndSelectedGuests(location, selectedGuests);
  }

  render() {
    const { selectedGuestCount } = this.props;
    const btnStyle = `btn-${this.state.submitStyle}`;
    const btnValue = `${I18n.t("react.reports.delete_guests.i_want_to_delete")} ${selectedGuestCount} ${I18n.t("react.reports.guests")}`;
    const confirmMessage = `${I18n.t("react.reports.delete_guests.type")} <strong>"${I18n.t("react.reports.delete_guests.strong_delete")} ${selectedGuestCount} ${I18n.t("react.reports.delete_guests.strong_guests")}"</strong> ${I18n.t("react.reports.delete_guests.to_confirm")}`;

    return (
      <form onSubmit={this._submit}>
        <label className="form-label">
          <div dangerouslySetInnerHTML={{ __html: confirmMessage }}></div>
        </label>
        <input type="text" className="form-control enter-delete-field text-danger" value={this.state.value} onChange={this._onChange} />
        <hr />
        <button className={classNames(btnStyle, "btn", "float-end")} disabled={this.state.submitDislabled}>{btnValue}</button>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedGuestCount: state.appearance.selectedGuestCount,
    selectedGuests: state.guests.selectedGuests
  };
}

const mapDispatchToProps = {
  deleteGuests,
  requestCountGuests,
  updateUI
};

export default connect(mapStateToProps, mapDispatchToProps)(withModal(DeleteGuests));
export { DeleteGuests }; // pure component. Used in tests
