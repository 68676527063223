import React, { FC } from "react";
import IntegrationsData from "../../../config/integration_set.yml";

interface MenuProps {
  i18n: (key: string) => string;
}

const Menu: FC<MenuProps> = ({ i18n }) => {
  const scrollToIntegrationsGroup = (integrationCategory: string): void => {
    const element = document.getElementById(integrationCategory);
    if (element) {
      const integrationOffsetTop = element.offsetTop;

      window.scrollTo({
        top: integrationOffsetTop - 120,
        left: 0,
        behavior: "smooth"
      });
    }
  };

  return (
    <div id="integration-side-menu" className="feature-side-menu mt-2">
      <div className="card">
        <ul className="list-group">
          {Object.keys(IntegrationsData.groups).map((groupKey: string) => (
            <button
              key={groupKey}
              onClick={(): void => scrollToIntegrationsGroup(groupKey)}
              className="list-group-item list-group-item-action capitalize"
            >
              {i18n(groupKey)}
            </button>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Menu;
