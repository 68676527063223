import { ActionTypes } from "../constants/Constants";

const initialState = { savedSearch: {}, errors: [], persisted: false };

function savedSearchCreation(state = initialState, action) {
  switch (action.type) {
  case ActionTypes.CREATE_SAVED_SEARCH_SUCCESS:
    return Object.assign({}, state, { savedSearch: action.data, errors: [], persisted: true });
  case ActionTypes.CREATE_SAVED_SEARCH_FAILURE:
    return Object.assign({}, state, { errors: action.data.errors, persisted: false });
  case ActionTypes.CREATE_SAVED_SEARCH_CLEAR:
    return initialState;
  default:
    return state;
  }
}

export default savedSearchCreation;
