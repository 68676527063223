import { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import { urlEventLocale, urlWithQuery, pathToGuestCampaigns } from "../../utils/pathUtils";
import { SegmentPicker } from "../../containers/shared/SegmentPicker.react";
import DateTimePicker from "../shared/DateTimePicker.react";
import InformationBlock from "../../components/guest_campaign/InformationBlock.react";
import PropTypes from "prop-types";
import Loader from "../shared/Loader.react";
import { GuestCampaignStatusLocked, ONLINE_COLOR, PATH_NAME_RESOURCE_ID_REGEX } from "../../constants/Constants";
import EmailTemplateSelector from "../../components/email_templates/EmailTemplateSelector.react";
import GuestProductSearchDropdown from "../../containers/GuestProductSearchDropdown.react";
import AccesspointSearchDropdown from "../../containers/AccesspointSearchDropdown.react";
import GuestSearchDropdown from "../../containers/GuestSearchDropdown.react";
import { replaceResourceIdWithPlaceholderId, replacePlaceholderIdWithResourceId } from "../../utils/templatesBuilderUtils";
import SenderEmailAddressesField from "../SenderEmailAddressesField.react";

const SMS_MAX_NB_CHAR = 160;
const MESSAGE_TITLE_MAX_NB_CHAR = 30;
const MESSAGE_CONTENT_MAX_NB_CHAR = 60;

const LABEL_TYPE_STATUS_MAPPING = {
  draft: "warning",
  sending: "info",
  scheduled: "info",
  sent: "success",
  blocked: "danger"
};

class GuestCampaignForm extends Component {

  constructor(props) {
    super(props);
    [
      "onCheckBoxChange",
      "onInputChange",
      "onResetDatePicker",
      "onScheduleCheckboxChange",
      "onScheduledDateChange",
      "onSelectedSavedSearchChange",
      "onSelectTemplate",
      "onSmsContentChange",
      "onSubmit",
      "onTimeChange",
      "onNotificationPagePathChange",
      "saveAndDeliveryIfNeed",
      "saveBeforeTestingEmail",
      "saveWithoutDelivery",
      "sendTestEmail"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    const initialState = props.editMode ? this.initialStateForEditMode() : this.initialStateForCreateMode(props);
    this.state = Object.assign(this.emptyState(), initialState);
  }

  componentDidUpdate(prevProps) {
    const { event, editMode } = this.props;
    if (!editMode && !prevProps.event.fetched && event.fetched) {
      this.setState(this.initialStateForCreateMode(this.props));
    }
  }

  emptyState() {
    return {
      name: null,
      from_name: null,
      reply_to_email: null,
      subject: null,
      saved_search_id: null,
      use_email: false,
      use_sms: false,
      use_message: false,
      scheduled_at: null,
      sms_content: "",
      status: null,
      email_template_id: null,
      sent_at: null,
      _id: null,
      message_content: "",
      message_notification_requires_interaction: null,
      send_to_optout: false,
      message_notification_page_path: "",
      message_notification_title: "",
      sender_email: null
    };
  }

  initialStateForCreateMode(props) {
    const { event, initialMedium } = props;
    moment.locale(I18n.currentLocale());
    const initialState = {
      name: `${I18n.t("react.guest_campaign.form.campaign")} ${moment().format("LLL")}`,
      from_name: event.organizer,
      [initialMedium]: true
    };

    if (initialMedium === "use_email")
      return {
        ...initialState,
        reply_to_email: event.reply_to_email,
        subject: `${I18n.t("react.guest_campaign.form.campaign_to_the_event")} ${event.title}`
      };
    else if (initialMedium === "use_message")
      return {
        ...initialState,
        message_notification_requires_interaction: true,
        message_notification_page_path: "",
      };
    else
      return initialState;
  }

  initialStateForEditMode() {
    const {
      name,
      from_name,
      reply_to_email,
      subject,
      saved_search_id,
      use_email,
      use_sms,
      use_message,
      scheduled_at,
      sms_content,
      status,
      email_template_id,
      sent_at,
      _id,
      message_content,
      message_notification_requires_interaction,
      send_to_optout,
      message_notification_page_path,
      message_notification_title,
      sender_email
    } = this.props.guestCampaign;

    return {
      name,
      from_name,
      reply_to_email,
      subject,
      saved_search_id,
      use_email,
      use_sms,
      use_message,
      scheduled_at,
      sms_content,
      status,
      email_template_id,
      sent_at,
      _id,
      message_content,
      message_notification_requires_interaction,
      send_to_optout,
      message_notification_page_path,
      message_notification_title,
      sender_email
    };
  }

  urlGuestListFromCampaignSavedSearch(options = {}) {
    const { savedSearches } = this.props;
    const { saved_search_id } = this.state;
    const savedSearch = savedSearches.find(el => el._id === saved_search_id);
    if (!savedSearch) {
      return "#";
    }
    let q = savedSearch.search_query;
    if (options.with_no_email === true) {
      q = `${q} no:email`;
    }
    if (options.without_email_null === true) {
      q = `${q} -email:null`;
    }
    if (options.with_no_phone_number === true) {
      q = `${q} no:phone_number`;
    }
    if (options.without_phone_number_null === true) {
      q = `${q} -phone_number:null`;
    }
    if (options.with_optin === true) {
      q = `${q} campaign_optin:true`;
    } else if (options.with_optin === false) {
      q = `${q} campaign_optin:false`;
    }
    return urlWithQuery(q, "guests");
  }

  urlGuestCampaignsList() {
    return urlWithQuery("", "campaigns");
  }

  urlGuestCampaignEdit() {
    return urlWithQuery("", "campaign/:id/edit");
  }

  onInputChange({ target }) {
    const { name: key, value } = target;
    this.setState({ [key]: value });
  }

  onCheckBoxChange({ target }) {
    const { name: key, checked: value } = target;
    this.setState({ [key]: value });
  }

  onNotificationPagePathChange({ target }) {
    const { name: key, value } = target;
    this.setState({ [key]: value });
  }

  onSelectedSavedSearchChange(search) {
    if (!search) {
      this.setState({
        saved_search_id: null
      });
      return;
    }
    const { onSelectedSavedSearchChange } = this.props;
    this.setState({
      saved_search_id: search._id
    }, () => {onSelectedSavedSearchChange(search.search_query);}
    );
  }

  onScheduleCheckboxChange() {
    const { scheduled_at } = this.state;
    const newScheduledAt = scheduled_at ? null : moment().add(1, "hours").minutes(0).format();
    this.setState({ scheduled_at: newScheduledAt });
  }

  onResetDatePicker(e) {
    e.preventDefault();
    this.onScheduleCheckboxChange();
  }

  onTimeChange(timeUnit) {
    return ({ target }) => {
      const { scheduled_at } = this.state;
      const { value } = target;
      let newDate = moment(scheduled_at);
      newDate[timeUnit](parseInt(value));
      const fDate = moment(newDate).format();

      this.setState({ scheduled_at: fDate });
    };
  }

  onScheduledDateChange(date) {
    const { scheduled_at } = this.state;
    const selectedHour = moment(scheduled_at).hours();
    const selectedMinute = moment(scheduled_at).minutes();
    const fDate = date ? moment(date).hours(selectedHour).minutes(selectedMinute).format() : null;

    this.setState({ scheduled_at: fDate });
  }

  onSmsContentChange({ target }) {
    const { value } = target;
    if (value.length > SMS_MAX_NB_CHAR) return;
    this.setState({ sms_content: value });
  }

  onSelectTemplate(emailTemplateId) {
    this.setState({
      email_template_id: emailTemplateId
    });
  }

  onSubmit(e) {
    e.preventDefault();
    this.saveWithoutDelivery();
  }

  onSelectResource(keyInState, path_name) {
    return data => {
      const pathNameWithResourceId = replacePlaceholderIdWithResourceId("/" + path_name, data[0]._id);
      this.setState({ [keyInState]: pathNameWithResourceId });
    };
  }

  renderInput(type, label, value, name, infoMessage, helpElement = null) {
    return (
      <div className="mb-3">
        <label htmlFor={name} className="form-label">{label}</label>
        { this.renderInfoSpan(infoMessage) }
        <input type={type} className="form-control" placeholder={label} value={value || ""} name={name} id={name} onChange={this.onInputChange}/>
        { helpElement }
      </div>
    );
  }

  renderTextArea(name, value, label, method, helpElement = null) {
    return (
      <div className="mb-3">
        <label htmlFor={ name } className="form-label">{ label }</label>
        <textarea name={ name } value={ value } onChange={ method } className="form-control" rows="4" style={{ resize: "none" }} />
        { helpElement }
      </div>
    );
  }

  renderHelpLimitedInput(value, limit, elementAfterLimit = null) {
    let classes = "float-end form-text";
    classes = value.length < limit ? classes : `${classes} text-danger`;
    return <>
      <div className={classes}>{ value.length } / { limit }</div>
      {elementAfterLimit}
    </>;
  }

  renderLimitedText(label, value, name, infoMessage, limit) {
    const helpElement = this.renderHelpLimitedInput(value, limit);
    return (
      <div className="mb-3">
        {this.renderInput("text", label, value, name, infoMessage, helpElement)}
      </div>
    );
  }

  renderLimitedTextArea(name, value, label, limit, onContentChange) {
    const elementAfterLimit = <a target="_blank" href={process.env.APP_DOCUMENTATION_HOST}>
      {I18n.t("react.guest_campaign.guest_campaign_notification.see_liquid_doc")}
    </a>;
    const helpElement = this.renderHelpLimitedInput(value, limit, elementAfterLimit);
    return (
      <div className="mb-3">
        {this.renderTextArea(name, value, label, onContentChange, helpElement)}
      </div>
    );
  }

  renderCheckbox(label, value, name, onChangeHandler, infoMessage = "") {
    return (
      <div className="mb-3">
        <div className="form-check">
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input" checked={value} name={name} onChange={onChangeHandler}/>
            {label}
          </label>
          { this.renderInfoSpan(infoMessage) }
        </div>
      </div>
    );
  }

  renderSelect(label, value, name, data, onChangeHandler) {
    if (!data) return;
    const options = data.map(item => {
      return <option value={item.value} key={item.value}>{item.label}</option>;
    });
    return (
      <div className="mb-3">
        <label htmlFor={name} className="form-label">{label}</label>
        <select className="form-select" value={value || ""} name={name} onChange={onChangeHandler}>
          {options}
        </select>
      </div>
    );
  }

  informationTooltip(message) {
    return (
      <Tooltip id="tooltip">{message}</Tooltip>
    );
  }

  renderInfoSpan(infoMessage) {
    if (infoMessage === "") return;
    return (
      <span className="form-label d-none d-sm-inline ml-5">
        <OverlayTrigger overlay={this.informationTooltip(infoMessage)}>
          <i className="fa-regular fa-info-circle"></i>
        </OverlayTrigger>
      </span>
    );
  }

  renderDateLabel(date) {
    moment.locale(I18n.currentLocale());
    const words = moment(date).fromNow();
    const actualDate = moment(date).format("LLL");
    return (
      <span title={actualDate}>
        ({words})
      </span>
    );
  }

  renderConfigurationBlocks() {
    const { name, use_email, use_sms, use_message } = this.state;
    return (
      <div className="card">
        {use_email && this.renderPanelHeading("fa-regular fa-envelope", I18n.t("react.guest_campaign.guest_campaign_notification.email_panel.title"))}
        {use_sms && this.renderPanelHeading("fa-regular fa-comments", I18n.t("react.guest_campaign.guest_campaign_notification.sms_panel.title"))}
        {use_message && this.renderPanelHeading("fa-regular fa-bell", I18n.t("react.guest_campaign.guest_campaign_notification.message_panel.title"))}
        <div className="card-body">
          { this.renderInput("text", I18n.t("react.guest_campaign.form.name"), name, "name", "") }
          { this.renderSavedSearchSelect() }
          { this.renderFromName() }
          { this.renderEmailSubJectAndReplyTo() }
          { this.renderEmailTemplatePanel() }
          { this.renderSMSPanel() }
          { this.renderMessageBlock() }
          { this.renderScheduler() }
        </div>
      </div>
    );
  }

  renderSavedSearchSelect() {
    const { saved_search_id } = this.state;
    const { savedSearches } = this.props;
    return (
      <div className="mb-3">
        <label className="form-label"> { I18n.t("react.guest_campaign.form.pick_a_segment") } </label>
        <div className="row">
          <div className="col-md-12">
            <SegmentPicker onSelectSegment={this.onSelectedSavedSearchChange} defaultSegmentId={saved_search_id} segments={savedSearches} />
          </div>
        </div>
      </div>
    );
  }

  renderFromName() {
    const { use_email, use_sms, from_name } = this.state;
    if (!use_email && !use_sms) return;

    if (use_sms) {
      return this.renderLimitedText(I18n.t("react.guest_campaign.form.from_name"), from_name, "from_name", I18n.t("react.guest_campaign.form.info_from_name"), 11);
    } else {
      return this.renderInput("text", I18n.t("react.guest_campaign.form.from_name"), from_name, "from_name", I18n.t("react.guest_campaign.form.info_from_name"));
    }
  }

  renderEmailSubJectAndReplyTo() {
    const { use_email, reply_to_email, subject, sender_email } = this.state;
    if (!use_email) return;

    const { event } = this.props;
    return (
      <div className="mb-3">
        { this.renderInput("email", I18n.t("react.guest_campaign.guest_campaign_notification.email_panel.reply_to"), reply_to_email, "reply_to_email", "")}
        { this.renderInput("text", I18n.t("react.guest_campaign.guest_campaign_notification.email_panel.subject"), subject, "subject", "")}
        <SenderEmailAddressesField
          customEmailSenderEmail={sender_email}
          allSenderEmailAddresses={event.all_sender_email_addresses || []}
          label={I18n.t("react.guest_campaign.guest_campaign_notification.email_panel.sender_email")}
          name="sender_email"
          disabled={this.isEditionDisabled()}
          onSelectSenderEmail={(sender_email) => this.setState({ sender_email })}
        />
      </div>
    );
  }

  renderScheduledDateTimePicker() {
    const { scheduled_at } = this.state;
    if (!scheduled_at) return null;
    return <DateTimePicker
      title={ I18n.t("react.guest_campaign.form.scheduled_at") }
      selectedDate={moment(scheduled_at)}
      onChangeDate={this.onScheduledDateChange}
      onChangeHour={this.onTimeChange("hours")}
      onChangeMinute={this.onTimeChange("minutes")}
      locale={urlEventLocale()}
      reset={this.onResetDatePicker}
      calendarPlacement="top"
      minDate={moment().format()}
    />;
  }

  renderScheduler() {
    const { scheduled_at } = this.state;
    return <div className="mb-3">
      <div className="row mb-10">
        <div className="col-md-11">
          <div className="form-check">
            <label className="form-check-label">
              <input type="checkbox" className="form-check-input" checked={scheduled_at != null} onChange={this.onScheduleCheckboxChange}/> {I18n.t("react.guest_campaign.form.schedule_campaign")}
            </label>
          </div>
        </div>
      </div>
      { this.renderScheduledDateTimePicker() }
    </div>;
  }

  renderEmailTemplatePanel() {
    const { use_email, email_template_id } = this.state;
    const { match, location, history } = this.props;
    if (!use_email) return;
    return (
      <EmailTemplateSelector
        slidingPaneTitle={I18n.t("react.guest_campaign.form.choose_email_template")}
        selectedEmailTemplateId={email_template_id}
        onSelectTemplate={this.onSelectTemplate}
        match={match}
        location={location}
        history={history}
      />);
  }

  renderSMSPanel() {
    const { use_sms, sms_content } = this.state;
    if (!use_sms) return;

    return this.renderLimitedTextArea("smsContent", sms_content, I18n.t("react.guest_campaign.guest_campaign_notification.sms_panel.sms_content"), SMS_MAX_NB_CHAR, this.onSmsContentChange);
  }

  renderCounterBadge(count) {
    const color = count > 0 ? ONLINE_COLOR : "red";
    return <span className="badge rounded-pill" style={{ backgroundColor: color }}>{count}</span>;
  }

  buildDataForWebSitePagesSelect() {
    const { websitePages } = this.props;
    const data = websitePages.items.map(page => {
      return { value: `/${page.path_name}`, label: page.name };
    });
    return [{ value: "", label: I18n.t("react.guest_campaign.form.message_notification_no_path_page") }, ...data];
  }

  renderMessageBlock() {
    const {
      use_message,
      message_content,
      message_notification_requires_interaction,
      message_notification_page_path,
      message_notification_title,
    } = this.state;

    if (!use_message) return null;

    const require_interaction_help = I18n.t("react.guest_campaign.guest_campaign_notification.message_panel.require_interaction_help");
    return (
      <div className="mb-3">
        { this.renderLimitedText(I18n.t("react.guest_campaign.form.message_notification_title"), message_notification_title, "message_notification_title", "", MESSAGE_TITLE_MAX_NB_CHAR) }
        { this.renderLimitedTextArea("message_content", message_content, I18n.t("react.guest_campaign.guest_campaign_notification.message_panel.message_content"), MESSAGE_CONTENT_MAX_NB_CHAR, this.onInputChange) }
        { this.renderCheckbox(I18n.t("react.guest_campaign.form.message_notification_requires_interaction"), message_notification_requires_interaction, "message_notification_requires_interaction", this.onCheckBoxChange, require_interaction_help) }
        { this.renderWebsitePagePicker("message_notification_page_path", message_notification_page_path, I18n.t("react.guest_campaign.form.message_notification_page_path")) }
      </div>
    );
  }

  renderWebsitePagePicker(name, value, label) {
    const { websitePages } = this.props;
    if (!websitePages || !websitePages.items) return null;

    let pathNameWithoutResourceId = value;
    if (PATH_NAME_RESOURCE_ID_REGEX.test(value) && !value.startsWith("/registration/")) {
      pathNameWithoutResourceId = replaceResourceIdWithPlaceholderId(value);
    }
    return (
      <>
        {this.renderSelect(label, pathNameWithoutResourceId, name, this.buildDataForWebSitePagesSelect(), this.onNotificationPagePathChange)}
        {this.renderResourcePicker(name, value, pathNameWithoutResourceId)}
      </>
    );
  }

  renderResourcePicker(name, value, valueWithoutIdKey) {
    if (!value) return;
    const { websitePages } = this.props;
    const page = websitePages.items.find(page => "/" + page.path_name === valueWithoutIdKey);
    const match = value.match(PATH_NAME_RESOURCE_ID_REGEX);
    const resourceId = match ? match[0] : null;
    if (!page) return;

    switch (page.resource_name) {
    case "session":
      return <div className="mb-3 mt-15">
        <label className="form-label">{I18n.t("react.website.resource_name_session")}</label>
        <AccesspointSearchDropdown onSelectAccesspoint={this.onSelectResource(name, page.path_name)} selectedAccesspointsIds={resourceId} />
      </div>;
    case "guest":
      return <div className="mb-3 mt-15">
        <label className="form-label">{I18n.t("react.website.resource_name_guest")}</label>
        <GuestSearchDropdown onSelectGuest={this.onSelectResource(name, page.path_name)} selectedGuestsIds={resourceId} />
      </div>;
    case "guest_product":
      return <div className="mb-3 mt-15">
        <label className="form-label">{I18n.t("react.website.resource_name_guest_product")}</label>
        <GuestProductSearchDropdown onSelectGuestProduct={this.onSelectResource(name, page.path_name)} selectedGuestProductsIds={resourceId} />
      </div>;
    default:
      return null;
    }
  }

  renderPanelHeading(icon, title) {
    const { status, sent_at, scheduled_at } = this.state;
    let statusLabel = I18n.t(`react.guest_campaigns.status_${status}`);
    if (sent_at) {
      statusLabel = <span> {statusLabel} {this.renderDateLabel(sent_at)} </span>;
    } else if (scheduled_at) {
      statusLabel = <span> {statusLabel} {this.renderDateLabel(scheduled_at)} </span>;
    }
    const statusClasses = `badge rounded-pill bg-${LABEL_TYPE_STATUS_MAPPING[status]}`;
    return (
      <div className="card-header text-center">
        <h4 className="card-title">
          <i className={ icon + " mr-5" }></i>
          { title }
          { status && <p className={statusClasses + " ml-5"}>{statusLabel}</p>}
        </h4>
      </div>
    );
  }

  renderMediumRecapSegmentBlock(segmentInfo, segmentLinkUrl) {
    const { saved_search_id } = this.state;
    return this.renderMediumRecap(
      I18n.t("react.guest_campaigns.saved_search"),
      saved_search_id ? segmentInfo : "",
      saved_search_id ? segmentLinkUrl : "",
      !saved_search_id ? "times-circle" : undefined,
      !saved_search_id ? "danger" : undefined
    );
  }

  renderMediumRecap(title, info, linkUrl, leftIconType = "check-circle", leftIconColor = "success") {
    return (
      <InformationBlock
        key={title}
        title={title}
        info={info}
        linkName={I18n.t("react.guest_campaign.show")}
        linkUrl={linkUrl}
        newTab={true}
        leftIconType={leftIconType}
        leftIconColor={leftIconColor}
      />
    );
  }

  renderMediumRecapContentBlock(content, title, info = "") {
    return this.renderMediumRecap(
      title,
      info,
      "",
      !content ? "times-circle" : undefined,
      !content ? "danger" : undefined
    );
  }

  renderSummaryBlocks() {
    return (
      <div className="card">
        <div className="card-header text-center">
          <h4 className="card-title">
            { I18n.t("react.guest_campaign.summary.panel_heading") }
          </h4>
        </div>
        <div className="card-body">
          { this.renderEmailRecap() }
          { this.renderSmsRecap() }
          { this.renderMessageRecap() }
          { this.renderFromNameBlock() }
          { this.renderScheduledAtBlock() }
          { this.renderEmailsInformation() }
        </div>
      </div>
    );
  }

  renderTestEmailBlock() {
    const { use_email, status } = this.state;
    if (!use_email || (status && GuestCampaignStatusLocked.includes(status))) return;

    return (
      <div className="card">
        <div className="card-header text-center">
          <h4 className="card-title">
            { I18n.t("react.guest_campaign.test.panel_heading") }
          </h4>
        </div>
        <div className="card-body">
          { this.renderTestEmailForm() }
        </div>
      </div>
    );
  }

  renderEmailRecap() {
    const { use_email } = this.state;
    if (!use_email) return;

    return ([
      this.renderEmailRecapSegmentBlock(),
      this.renderEmailRecapNoEmailBlock(),
      this.renderEmailRecapNoOptinBlock(),
      this.renderEmailRecapTemplateBlock()
    ]);
  }

  renderEmailRecapSegmentBlock() {
    const { guestCount } = this.props;
    const segmentInfo = I18n.t("react.guest_campaign.summary.email_info", { count: guestCount || 0 });
    const segmentLinkUrl = this.urlGuestListFromCampaignSavedSearch({ without_email_null: true, with_optin: true });
    return this.renderMediumRecapSegmentBlock(segmentInfo, segmentLinkUrl);
  }

  renderEmailRecapNoEmailBlock() {
    const { guestCampaign, guestWithoutEmailAddressCount } = this.props;
    const { saved_search_id } = this.state;

    return saved_search_id && (guestWithoutEmailAddressCount > 0) &&
      <InformationBlock
        key={I18n.t("react.guest_campaign.summary.list_no_email")}
        title={I18n.t("react.guest_campaign.summary.list_no_email")}
        info={I18n.t("react.guest_campaign.people", { count: guestWithoutEmailAddressCount })}
        linkName={I18n.t("react.guest_campaign.show")}
        linkUrl={this.urlGuestListFromCampaignSavedSearch({ with_no_email: true })}
        newTab={true}
        leftIconType={ guestCampaign.nb_emails_to_send == 0 ? "times-circle" : "exclamation-circle" }
        leftIconColor={ guestCampaign.nb_emails_to_send == 0 ? "danger" : "warning" }
      />;
  }

  renderEmailRecapNoOptinBlock() {
    const { guestCampaign, guestWithoutCampaignOptin } = this.props;
    const { saved_search_id, send_to_optout } = this.state;

    return saved_search_id && (guestWithoutCampaignOptin > 0) &&
      ([
        <InformationBlock
          key={I18n.t("react.guest_campaign.summary.list_no_optin")}
          title={I18n.t("react.guest_campaign.summary.list_no_optin")}
          info={I18n.t("react.guest_campaign.people", { count: guestWithoutCampaignOptin })}
          linkName={I18n.t("react.guest_campaign.show")}
          linkUrl={this.urlGuestListFromCampaignSavedSearch({ with_optin: false })}
          newTab={true}
          leftIconType={ guestCampaign.nb_emails_to_send == 0 ? "times-circle" : "exclamation-circle" }
          leftIconColor={ guestCampaign.nb_emails_to_send == 0 ? "danger" : "warning" }
        />,
        <div className="row" key={I18n.t("react.guest_campaign.form.send_emails_to_optout_guests")}>
          <div className="offset-md-1 col-md-11">
            { this.renderCheckbox(I18n.t("react.guest_campaign.form.send_emails_to_optout_guests"), send_to_optout, "send_to_optout", this.onCheckBoxChange) }
          </div>
        </div>
      ]);
  }

  renderEmailRecapTemplateBlock() {
    const { email_template_id } = this.state;
    return this.renderMediumRecapContentBlock(
      email_template_id,
      I18n.t("react.guest_campaign.summary.email_title"),
      email_template_id ? I18n.t("react.guest_campaign.summary.template_selected") : I18n.t("react.guest_campaign.summary.template_required")
    );
  }

  renderSmsRecap() {
    const { use_sms } = this.state;
    if (!use_sms) return;

    return ([
      this.renderSmsRecapSegmentBlock(),
      this.renderSmsRecapNoPhoneNumberBlock(),
      this.renderSmsRecapNoOptinBlock(),
      this.renderSmsRecapContentBlock()
    ]);
  }

  renderSmsRecapSegmentBlock() {
    const { guestCount } = this.props;
    const segmentInfo = I18n.t("react.guest_campaign.summary.sms_info", { nb_sms_to_send: guestCount || 0 });
    const segmentLinkUrl = this.urlGuestListFromCampaignSavedSearch({ without_phone_number_null: true, with_optin: true });
    return this.renderMediumRecapSegmentBlock(segmentInfo, segmentLinkUrl);
  }

  renderSmsRecapNoPhoneNumberBlock() {
    const { guestCampaign, guestWithoutPhoneNumber } = this.props;
    const { saved_search_id } = this.state;

    return saved_search_id && (guestWithoutPhoneNumber > 0) &&
      <InformationBlock
        key={I18n.t("react.guest_campaign.summary.list_no_sms")}
        title={I18n.t("react.guest_campaign.summary.list_no_sms")}
        info={I18n.t("react.guest_campaign.people", { count: guestWithoutPhoneNumber })}
        linkName={I18n.t("react.guest_campaign.show")}
        linkUrl={this.urlGuestListFromCampaignSavedSearch({ with_no_phone_number: true })}
        newTab={true}
        leftIconType={ guestCampaign.nb_sms_to_send == 0 ? "times-circle" : "exclamation-circle" }
        leftIconColor={ guestCampaign.nb_sms_to_send == 0 ? "danger" : "warning" }
      />;
  }

  renderSmsRecapNoOptinBlock() {
    const { guestCampaign, guestWithoutCampaignOptin } = this.props;
    const { saved_search_id, send_to_optout } = this.state;

    return saved_search_id && (guestWithoutCampaignOptin > 0) &&
      ([
        <InformationBlock
          key={I18n.t("react.guest_campaign.summary.list_no_optin")}
          title={I18n.t("react.guest_campaign.summary.list_no_optin")}
          info={I18n.t("react.guest_campaign.people", { count: guestWithoutCampaignOptin })}
          linkName={I18n.t("react.guest_campaign.show")}
          linkUrl={this.urlGuestListFromCampaignSavedSearch({ with_optin: false })}
          newTab={true}
          leftIconType={ guestCampaign.nb_sms_to_send == 0 ? "times-circle" : "exclamation-circle" }
          leftIconColor={ guestCampaign.nb_sms_to_send == 0 ? "danger" : "warning" }
        />,
        <div className="row" key={I18n.t("react.guest_campaign.form.send_messages_to_optout_guests")}>
          <div className="offset-md-1 col-md-11">
            { this.renderCheckbox(I18n.t("react.guest_campaign.form.send_messages_to_optout_guests"), send_to_optout, "send_to_optout", this.onCheckBoxChange) }
          </div>
        </div>
      ]);
  }

  renderSmsRecapContentBlock() {
    const { sms_content } = this.state;
    return this.renderMediumRecapContentBlock(
      sms_content,
      I18n.t("react.guest_campaign.guest_campaign_notification.sms_panel.sms_content")
    );
  }

  renderMessageRecap() {
    const { use_message } = this.state;
    if (!use_message) return;

    return ([
      this.renderMessageRecapSegmentBlock(),
      this.renderMessageRecapContentBlock(),
      this.renderMessageRecapTitleBlock(),
    ]);
  }

  renderMessageRecapSegmentBlock() {
    const { guestCount } = this.props;
    const segmentInfo = I18n.t("react.guest_campaign.summary.message_info", { nb_messages_to_send: guestCount || 0 });
    const segmentLinkUrl = this.urlGuestListFromCampaignSavedSearch({ with_optin: true });
    return this.renderMediumRecapSegmentBlock(segmentInfo, segmentLinkUrl);
  }

  renderMessageRecapContentBlock() {
    const { message_content } = this.state;
    return this.renderMediumRecapContentBlock(
      message_content,
      I18n.t("react.guest_campaign.guest_campaign_notification.message_panel.message_content")
    );
  }

  renderMessageRecapTitleBlock() {
    const { message_notification_title } = this.state;
    return this.renderMediumRecapContentBlock(
      message_notification_title,
      I18n.t("react.guest_campaign.guest_campaign_notification.message_panel.message_notification_title")
    );
  }

  renderInformationBlock(field) {
    const value = this.state[field];
    return (
      <InformationBlock
        key={I18n.t(`react.guest_campaign.summary.${field}`)}
        title={I18n.t(`react.guest_campaign.summary.${field}`)}
        info={value}
        leftIconType={value ? "check-circle" : "times-circle"}
        leftIconColor={value ? "success" : "danger"}
      />
    );
  }

  renderFromNameBlock() {
    const { use_email, use_sms } = this.state;
    if (!use_email && !use_sms) return;
    return this.renderInformationBlock("from_name");
  }

  renderScheduledAtBlock() {
    const { scheduled_at, sent_at } = this.state;
    let timeInfo;

    if (sent_at) {
      timeInfo = I18n.t("react.guest_campaign.summary.sent_at", { date: moment(sent_at).format("LLLL") });
    } else if (scheduled_at) {
      timeInfo = I18n.t("react.guest_campaign.summary.scheduled_at", { date: moment(scheduled_at).format("LLLL") });
    } else {
      timeInfo = I18n.t("react.guest_campaign.summary.not_scheduled");
    }

    return (
      <InformationBlock
        key={I18n.t("react.guest_campaign.summary.scheduled")}
        title={I18n.t("react.guest_campaign.summary.scheduled")}
        info={timeInfo}
        linkName={I18n.t("react.guest_campaign.change")}
      />
    );
  }

  renderEmailsInformation() {
    const { use_email, reply_to_email } = this.state;
    if (!use_email) return;
    return (
      [
        this.renderInformationBlock("subject"),
        <InformationBlock
          key={I18n.t("react.guest_campaign.summary.replies")}
          title={I18n.t("react.guest_campaign.summary.replies")}
          info={`${I18n.t("react.guest_campaign.summary.all_replies_got_to")} ${reply_to_email || "-"}`}
          leftIconType={reply_to_email ? "check-circle" : "times-circle"}
          leftIconColor={reply_to_email ? "success" : "danger"}
        />
      ]
    );
  }

  renderTestEmailInput() {
    return (
      <div className="mb-3">
        <div className="input-group">
          <input type="email" className="form-control" placeholder="jane.doe@example.com" ref={ref => this.testEmail = ref }/>
          <button type="button" className = "btn btn-primary float-end" onClick={this.sendTestEmail}>
            {I18n.t("react.guest_campaign.test.send")}
          </button>
        </div>
      </div>
    );
  }

  renderTestEmailSaveButton() {
    const { isPending } = this.props;
    return (
      <div className="text-start">
        <p>{I18n.t("react.guest_campaign.test.label_save_to_send_test_email")}{"  "}
          <button
            type="button"
            disabled = {isPending}
            onClick={this.saveBeforeTestingEmail}
            className = "btn btn-secondary btn-group btn-sm">
            {I18n.t("react.guest_campaign.test.save_to_send_test_email")}
            {isPending && <Loader size="small" />}
          </button></p>
      </div>
    );
  }

  renderTestEmailForm() {
    const { editMode } = this.props;
    return (
      <div className="mt-30 mb-30">
        <h4><strong>{I18n.t("react.guest_campaign.test.send_test_email")}</strong></h4>
        <div className="mt-10">
          { editMode ? this.renderTestEmailInput() : this.renderTestEmailSaveButton() }
        </div>
      </div>
    );
  }

  renderTitle() {
    const { editMode } = this.props;

    return <div className="header-page">
      <div className="header-page-title">
        <h1>
          <a href={pathToGuestCampaigns()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></a>
          {editMode ? I18n.t("react.guest_campaign.edit_campaign") : I18n.t("react.guest_campaign.new_campaign")}
        </h1>
      </div>
    </div>;
  }

  renderSaveButton() {
    const { isPending } = this.props;
    const { scheduled_at, status } = this.state;
    const labelButton = scheduled_at ? I18n.t("react.guest_campaign.schedule_campaign") : I18n.t("react.guest_campaign.send_campaign");
    if (status && GuestCampaignStatusLocked.includes(status)) return;

    return (
      <button
        type = "submit"
        disabled = {isPending}
        className = "btn btn-secondary float-end btn-lg mr-10"
        onClick = {(e) => {
          e.preventDefault();
          this.saveAndDeliveryIfNeed();
        }}>
        <i className="fa-regular fa-paper-plane fa-fw mr-5"></i>
        {labelButton}
        {isPending && <Loader size="small" />}
      </button>
    );
  }

  renderSaveDraftButton() {
    const { isPending } = this.props;
    const { status } = this.state;
    if (status && GuestCampaignStatusLocked.includes(status)) return;

    return (
      <button
        type = "submit"
        disabled = {isPending}
        className = "btn btn-primary float-end btn-lg">
        <i className="fa-regular fa-save mr-5"></i>
        {I18n.t("react.guest_campaign.form.save_and_exit")}
        {isPending && <Loader size="small" color="white" />}
      </button>
    );
  }

  testEmailCanBeSent() {
    return this.testEmail && this.testEmail.value.match(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,})$/);
  }

  sendTestEmail(e) {
    e.preventDefault();
    const { showNotice, event, sendTestEmail } = this.props;
    if (this.testEmailCanBeSent()) {
      showNotice("", "", null);
      const { guestCampaign } = this.props;
      const testEmail = encodeURIComponent(this.testEmail.value);
      sendTestEmail(event._id, guestCampaign._id, testEmail);
      this.testEmail.value = "";
    } else {
      showNotice(I18n.t("react.guest_campaign.summary.email_required"), "danger", null);
    }
    $("html, body").stop().animate({ scrollTop: 0 }, 1000, "swing");
  }

  saveBeforeTestingEmail() {
    const { onSaveGuestCampaign } = this.props;
    const urlRedirect = this.urlGuestCampaignEdit();
    onSaveGuestCampaign(this.state, false, urlRedirect);
  }

  saveAndDeliveryIfNeed() {
    const { onSaveGuestCampaign } = this.props;
    const { status } = this.state;
    const isStartSendingCampaign = !status || status && !GuestCampaignStatusLocked.includes(status);
    onSaveGuestCampaign(this.state, isStartSendingCampaign);
  }

  saveWithoutDelivery() {
    const { onSaveGuestCampaign } = this.props;
    const urlRedirect = this.urlGuestCampaignsList();
    onSaveGuestCampaign(this.state, false, urlRedirect);
  }

  isEditionDisabled() {
    const { status } = this.state;
    return status && GuestCampaignStatusLocked.includes(status);
  }

  render() {
    return <>
      {this.renderTitle()}
      <form onSubmit={this.onSubmit}>
        <div className="form-container">
          <fieldset disabled={this.isEditionDisabled()}>
            { this.renderConfigurationBlocks() }
            { this.renderSummaryBlocks() }
            { this.renderTestEmailBlock() }
            { this.renderSaveDraftButton() }
            { this.renderSaveButton() }
          </fieldset>
        </div>
      </form>
    </>;
  }
}

GuestCampaignForm.propTypes = {
  guestCampaign: PropTypes.object.isRequired,
  savedSearches: PropTypes.arrayOf(PropTypes.object).isRequired,
  event: PropTypes.object.isRequired,
  guestCount: PropTypes.number,
  guestWithoutEmailAddressCount: PropTypes.number,
  guestWithoutPhoneNumber: PropTypes.number,
  guestWithoutCampaignOptin: PropTypes.number,
  onSaveGuestCampaign: PropTypes.func.isRequired,
  onSelectedSavedSearchChange: PropTypes.func.isRequired,
  showNotice: PropTypes.func.isRequired,
  sendTestEmail: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
  initialMedium: PropTypes.string,
  editMode: PropTypes.bool,
  websitePages: PropTypes.object,
  website: PropTypes.object
};

export default GuestCampaignForm;
