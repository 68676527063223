import { ActionTypes } from "../constants/Constants";

const defaultState = {
  duplicationErrors: {},
  errors: null,
  importContactErrors: null,
  pendingRequest: false,
  fetched: false,
  duplicationOperationId: null
};

function event(state = defaultState, action) {
  switch (action.type) {
  case ActionTypes.RECEIVE_EVENT: {
    const dataModified = Object.assign({}, action.event, { id: action.event._id, fetched: true }); //ugly hack to make it works with id to _id transformation
    return Object.assign({}, state, dataModified);
  }
  case ActionTypes.UPDATE_EVENT_REQUEST:
    return { ...state, pendingRequest: true };
  case ActionTypes.UPDATE_EVENT_FAILURE:
    return Object.assign({}, state, { errors: action.data, pendingRequest: false, fetched: true });
  case ActionTypes.UPDATE_EVENT_SUCCESS:
    return Object.assign({}, state, action.data, { errors: null, pendingRequest: false, fetched: true });
  case ActionTypes.CREATE_DIRECT_EMAIL_SUCCESS: {
    const { reply_to, subject } = action.data;
    return Object.assign({}, state, { direct_email_last_reply_to_email: reply_to, direct_email_last_subject: subject });
  }
  case ActionTypes.FETCH_EVENT_CONTACT_IMPORT_ERRORS_SUCCESS: {
    let importContactErrors = (state.importContactErrors || []).slice();
    importContactErrors = importContactErrors.concat(action.data);
    return Object.assign({}, state, { importContactErrors });
  }
  case ActionTypes.FETCH_LEADS_EXPORT_EMAIL_SUCCESS: {
    return { ...state, leadsExportEmail: action.data };
  }
  case ActionTypes.FETCH_VISIT_ROUTE_DIGEST_EMAIL_SUCCESS: {
    return { ...state, visitRouteDigestEmail: action.data };
  }
  case ActionTypes.FETCH_VISIT_ROUTE_EXPORT_EMAIL_SUCCESS: {
    return { ...state, visitRouteExportEmail: action.data };
  }
  case ActionTypes.CLEAR_LEADS_EXPORT_EMAIL: {
    return { ...state, leadsExportEmail: null };
  }
  case ActionTypes.EVENT_ADD_OFFLINE_ERRORS:
    return { ...state, errors: action.errors };
  case ActionTypes.FETCH_REGISTRATION_FORMS_FROM_EVENT_SUCCESS: {
    const eventId = action.eventId || action.data[0] && action.data[0].event_id;
    if (!state._id || state._id !== eventId) return state;

    return { ...state, registrationForms: action.data };
  }
  default:
    return state;
  }
}

export default event;
