import { ChangeEvent } from "react";
import { Program, FilterOption, ThematicsDisplayLevel } from "../../../types/Program";
import Checkbox from "../../shared/Checkbox.react";
import Select from "react-select";

interface Props {
  program: Program;

  onChangeConfiguration(program: Program): void;
}

function i18n(key, opts = {}): string {
  return I18n.t(`react.programs.sessions_information_options.${key}`, opts);
}

const SessionsInformationOptions: React.FC<Props> = ({ program, onChangeConfiguration }) => {
  const { nb_lines_for_description, sessions_date_displayed, sessions_time_displayed, sessions_location_displayed, sessions_attendance_type_displayed,
    sessions_type_displayed, sessions_thematics_displayed, sessions_description_displayed, sessions_thematics_display_level } = program;

  const checkboxChanged = (key: string): ((e: ChangeEvent<HTMLInputElement>) => void) => {
    return (e: ChangeEvent<HTMLInputElement>): void => {
      const newProgram = { ...program };
      newProgram[key] = e.target.checked;
      onChangeConfiguration(newProgram);
    };
  };

  const thematicLevelChanged = (option: FilterOption): void => {
    onChangeConfiguration({ ...program, sessions_thematics_display_level: option.value as ThematicsDisplayLevel });
  };

  const nbLinesDescriptionChanged = (option: FilterOption): void => {
    const nb_lines_for_description = option.value === i18n("unlimited") ? null : Number(option.value);

    onChangeConfiguration({ ...program, nb_lines_for_description });
  };

  const buildThematicLevelOptions = (): FilterOption[] => {
    return ["all", "highest", "lowest"].map(level => {
      return { label: i18n(`thematics_levels.${level}`), value: level };
    });
  };

  const buildNbLinesDescriptionOptions = (): FilterOption[] => {
    return Array.from({ length: 10 }, (_, i) => i).map(value => {
      const nb_lines_for_description = value > 0 ? value.toString() : i18n("unlimited");

      return { label: nb_lines_for_description, value: nb_lines_for_description };
    });
  };

  const selectedThematicLevelOption = (): FilterOption => {
    return { label: i18n(`thematics_levels.${sessions_thematics_display_level}`), value: sessions_thematics_display_level };
  };

  const selectedNbLinesDescriptionOption = (): FilterOption => {
    const nb_lines = nb_lines_for_description > 0 ? nb_lines_for_description.toString() : i18n("unlimited");

    return { label: nb_lines, value: nb_lines };
  };

  const renderThematicLevelPicker = (): JSX.Element => {
    if (!sessions_thematics_displayed) return null;

    return <div className="mt-3">
      <label className="form-label">{i18n("thematics_level")}</label>
      <Select
        className="react-select"
        classNamePrefix="react-select"
        options={buildThematicLevelOptions()}
        value={selectedThematicLevelOption()}
        onChange={thematicLevelChanged}
      />
    </div>;
  };

  const renderSelectNbLinesForDescription = (): JSX.Element => {
    if (!sessions_description_displayed) return null;

    return <div className="mt-3">
      <label className="form-label">{i18n("nb_lines_for_description")}</label>
      <Select
        className="react-select"
        classNamePrefix="react-select"
        options={buildNbLinesDescriptionOptions()}
        value={selectedNbLinesDescriptionOption()}
        onChange={nbLinesDescriptionChanged}
      />
    </div>;
  };

  return <>
    <label className="form-label">{i18n("title")}</label>
    <div className="row">
      <div className="col-md-6">
        <Checkbox checked={sessions_date_displayed} text={i18n("date")} onChange={checkboxChanged("sessions_date_displayed")} />
        <Checkbox checked={sessions_time_displayed} text={i18n("time")} onChange={checkboxChanged("sessions_time_displayed")} />
        <Checkbox checked={sessions_location_displayed} text={i18n("location")} onChange={checkboxChanged("sessions_location_displayed")} />
        <Checkbox checked={sessions_attendance_type_displayed} text={i18n("attendance_type")} onChange={checkboxChanged("sessions_attendance_type_displayed")} />
      </div>
      <div className="col-md-6">
        <Checkbox checked={sessions_type_displayed} text={i18n("type")} onChange={checkboxChanged("sessions_type_displayed")} />
        <Checkbox checked={sessions_thematics_displayed} text={i18n("thematics")} onChange={checkboxChanged("sessions_thematics_displayed")} />
        <Checkbox checked={sessions_description_displayed} text={i18n("description")} onChange={checkboxChanged("sessions_description_displayed")} />
      </div>
    </div>
    {renderThematicLevelPicker()}
    {renderSelectNbLinesForDescription()}
    <hr />
  </>;
};

export default SessionsInformationOptions;
