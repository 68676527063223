import { Component } from "react";
import PropTypes from "prop-types";
import pick from "lodash/pick";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import DatePicker from "./DatePicker.react";
import CreatableSelect from "react-select/creatable";
import ErrorMessage from "./ErrorMessage.react";
import MetadataForm from "./MetadataForm.react";
import Loader from "../shared/Loader.react";
import FilterDropdown from "../FilterDropdown.react";
import RichTextEditor from "./RichTextEditor.react";
import ThematicsDropdown from "./../../containers/ThematicsDropdown.react";
import SessionTypesDropdown from "./../../containers/SessionTypesDropdown.react";
import GuestSearchDropdown from "../../containers/GuestSearchDropdown.react";
import { pathToAccesspointIndex, pathToSessionsList, pathToProductsList, pathToAccommodationsList, pathToMeetingsList, pathToBundlesList } from "../../utils/pathUtils.js";
import { jsonToFormData } from "../../utils/formDataUtils.js";
import LimitSelector from "../shared/LimitSelector.react";
import StreamingArchivesManagerPane from "../../containers/StreamingArchivesManagerPane.react";
import PreRecordedLiveFilePicker from "../../containers/PreRecordedLiveFilePicker.react";
import Modal from "react-modal";
import { defaultModalStyles } from "../../constants/Style";
import { archiveFileName } from "../../utils/fileUtils";
import VideoPlayer from "./VideoPlayer.react";
import { isEnabled, anyEnabled } from "../../utils/featureSetUtils";
import { hoursOptions, minutesOptions } from "../../utils/DateUtils.js";
import {
  ACCOMPANYING_PERSONS,
  CHECKIN_GATES,
  CHECKIN,
  EXHIBITORS,
  MESSAGES_ON_CHECKIN,
  PAYMENT,
  PRODUCTS,
  REGISTRATION_QUOTAS,
  ROOMING,
  SIGNATURE,
  STREAMING,
  VIDEO_CONFERENCE,
  WEBINAR
} from "../../constants/FeaturesSet";
import HasRtmpEndpointForm from "./HasRtmpEndpointForm.react";
import SessionRoomsDropdown from "../SessionRoomsDropdown.react";
import BundledAccesspointsList from "../BundledAccesspointsList.react";
import "../../assets/styles/accesspoint_form";
import CollapsablePanel from "../shared/CollapsablePanel.react";
import TranslatableInput from "../../components/shared/TranslatableInput.react";
import QRCode from "./QRCode.react";
import { pathToMeetingsConfiguration } from "../../utils/pathUtils";
import HelpSection from "../shared/HelpSection.react";

import GuestCategoryDropdown from "../../containers/shared/GuestCategoryDropdown.react";

let CHECKIN_POINT_TYPES = ["session", "meeting"];
if (isEnabled(ROOMING)) CHECKIN_POINT_TYPES.push("accommodation");
if (isEnabled(PRODUCTS)) CHECKIN_POINT_TYPES.push("product", "bundle");
if (isEnabled(CHECKIN)) CHECKIN_POINT_TYPES.push("checkin_point");

const SECTION_INFO = "info";
const SECTION_BUNDLE = "bundle";
const SECTION_PROGRAM = "prog";
const SECTION_REGISTRATION = "reg";
const SECTION_PRICE = "price";
const SECTION_CHECKIN = "checkin";
const SECTION_ADVANCED = "advanced";
const SECTION_OCCUPANCY = "occupancy";
const SECTION_INVITATION = "invitation";
const SECTION_LIVE = "live";
const SECTION_REPLAYS = "replays";
const LIVE_SESSION_TYPES = ["none", "webinar", "video_conference", "streaming", "from_video_file", "in_person"];
const LIVE_SESSION_TYPES_ALLOWING_LIVE_POLLS = ["webinar", "streaming", "in_person"];

const LIVE_MEETING_TYPES = ["none", "video_conference"];
const LIVE_SESSION_CHAT_MODERATION_TYPES = ["none", "active", "passive"];
const OPENTOK_RESOLUTIONS = ["FHD", "HD", "SD"];

class AccesspointForm extends Component {

  constructor(props) {
    super(props);
    [
      "accesspointParamsToSetState",
      "addAccesspointGate",
      "changeDateSelector",
      "creatableSelectOnChangeHandler",
      "creatableSelectOnInputChangeHandler",
      "createAccesspointAndOpenPreRecordedLiveVideoPickerModal",
      "createNewOptionHandler",
      "createOrUpdateAccesspoint",
      "createOrUpdateAccesspointWithoutRedirect",
      "onBundledAccesspointAdded",
      "onBundledAccesspointChanged",
      "onBundledAccesspointDeleted",
      "onMeetingLocationChange",
      "onPlusOneCategoryChange",
      "onRadioChange",
      "removeAccesspointGate",
      "saveAccesspoint",
      "setExhibitors",
      "setModerators",
      "setSpeakers",
      "toggleCheckbox",
      "togglePreRecordedLiveModal",
      "toggleReplaysManager",
      "triggerOtherTax",
      "updateAccesspointGate",
      "updateDate",
      "updateField",
      "updateImage",
      "updateLimitField",
      "updateLiveSessionType",
      "updateName",
      "updateRTE",
      "updateTraits",
      "updateType",
      "updateOpentokResolution",
      "setSessionType"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    let defaultDisplayedSections = [SECTION_INFO];
    if (props.defaultType == "session" || props.defaultType == "accommodation")
      defaultDisplayedSections.push(SECTION_PROGRAM);
    if (props.defaultType == "accommodation")
      defaultDisplayedSections.push(SECTION_REGISTRATION, SECTION_PRICE);
    if (props.defaultType == "meeting")
      defaultDisplayedSections.push(SECTION_PROGRAM, SECTION_LIVE, SECTION_REGISTRATION);
    if (props.defaultType == "bundle") {
      defaultDisplayedSections.push(SECTION_BUNDLE);
    }

    this.state = {
      ...props.defaultState,
      activateTimeSlot: ["session", "accommodation", "meeting"].includes(props.defaultType),
      capacity: props.defaultType == "meeting" ? 1 : -1,
      checkinable: !["accommodation", "bundle"].includes(props.defaultType),
      displayedSections: defaultDisplayedSections,
      type: props.defaultType || "checkin_point",
      preRecordedLiveModalOpen: !!props.queryParams && props.queryParams["live_video_picker"],
      replaysManagerIsDisplayed: false,
      bundledAccesspoints: [],
      showInPersonLiveAccessQrcode: false
    };
  }

  createOrUpdateAccesspoint(e) {
    return this.saveAccesspoint(e, true);
  }

  createOrUpdateAccesspointWithoutRedirect(e) {
    return this.saveAccesspoint(e);
  }

  createAccesspointAndOpenPreRecordedLiveVideoPickerModal(e) {
    return this.saveAccesspoint(e, false, "?live_video_picker=true");
  }

  saveAccesspoint(e, withRedirect = false, editPathQueryString = "") {
    e.preventDefault();
    const { onSubmit } = this.props;
    const data = this.computeAccesspointData();
    // we need to use FormData to upload illustration through AJAX request
    this.setState(
      { preRecordedLiveModalOpen: false },
      onSubmit(jsonToFormData(data, "accesspoint"), data.type, withRedirect, editPathQueryString)
    );
  }

  computeAccesspointData() {
    const { name, displayName, uid, website_path_slug, website_path_slug_translations, type, price, tax, tax_name, remove_illustration, remove_footer, other_tax, other_tax_name, capacity,
      checkinable, askForSignature, displayMessageUponCheckin, liveSessionType, archiveStreams, hlsBroadcastUrl, footerLink,
      allowGuestsWhoHaventPaidYet, traits, accesspointGates, momentStartDate, momentEndDate, activateTimeSlot, capacityReachedWarningEmail,
      plusOneGuestCategoryId, plusOneEnabled, thematic_ids, location, description, illustration, roomType, footer,
      max_occupancy, square_meters_area, always_allow_exit, opentokResolution, liveSessionChatModerationType, before_start_notification_enabled,
      liveSessionFocusedStreamTakesFullScreen, streaming_archive_used_for_live_from_file_id, chatInReplayEnabled, chatInReplaySyncOffset,
      chatHighlightMessagesEnabled, liveSessionChatEnabled, liveInterventionRequestsEnabled, liveSessionHasOnsiteAttendees, sessionRoomId,
      bundledAccesspoints, livePollsEnabled, inPersonLiveAccessButtonDisplayedOnWebsite, sessionTypeId, auto_register_guest_on_checkin,
      invitationQuotaType, invitationQuotaNumber, invitationQuotaCategoryId
    } = this.state;

    let data = {
      remove_illustration,
      remove_footer,
      name,
      display_name: displayName,
      uid,
      website_path_slug,
      website_path_slug_translations,
      type,
      price,
      tax,
      tax_name,
      other_tax,
      other_tax_name,
      capacity,
      checkinable: isEnabled(CHECKIN) ? checkinable : null,
      location,
      thematic_ids: thematic_ids.length == 0 ? [""] : thematic_ids, // hack to make FormData send the field to server when empty
      room_type: roomType,
      description,
      ask_for_signature: askForSignature,
      display_message_upon_checkin: displayMessageUponCheckin,
      allow_guests_who_havent_paid_yet: allowGuestsWhoHaventPaidYet,
      auto_register_guest_on_checkin,
      live_session_type: liveSessionType,
      footer_link: footerLink,
      opentok_resolution: opentokResolution,
      archive_streams: archiveStreams,
      live_session_chat_moderation_type: liveSessionChatModerationType,
      capacity_reached_warning_email: capacityReachedWarningEmail,
      traits,
      plus_one_guest_category_id: plusOneGuestCategoryId,
      plus_one_enabled: plusOneEnabled,
      guest_accesspoint_roles_attributes: this.computeAccesspointRoles(),
      max_occupancy,
      square_meters_area,
      streaming_archive_used_for_live_from_file_id,
      always_allow_exit,
      live_session_focused_stream_takes_full_screen: liveSessionFocusedStreamTakesFullScreen,
      chat_in_replay_enabled: chatInReplayEnabled,
      chat_in_replay_sync_offset: chatInReplaySyncOffset,
      chat_highlight_messages_enabled: chatHighlightMessagesEnabled,
      live_session_chat_enabled: liveSessionChatEnabled,
      before_start_notification_enabled,
      live_intervention_requests_enabled: liveInterventionRequestsEnabled,
      live_session_has_onsite_attendees: liveSessionHasOnsiteAttendees,
      session_room_id: sessionRoomId,
      live_polls_enabled: livePollsEnabled,
      in_person_live_access_button_displayed_on_website: inPersonLiveAccessButtonDisplayedOnWebsite,
      session_type_id: sessionTypeId
    };
    if (isEnabled(STREAMING)) data["hls_broadcast_url"] = hlsBroadcastUrl;
    // add timeslots
    if (activateTimeSlot) {
      data["local_start_date"] = momentStartDate.utcOffset(0, true).format();
      data["local_end_date"] = momentEndDate.utcOffset(0, true).format();
    }
    if (!isEmpty(accesspointGates)) {
      data["accesspoint_gates_attributes"] = accesspointGates.map((gate) => pick(gate, ["_id", "name", "_destroy"]));
    }
    // we must not send null illustration to server otherwise it sets uploader field with fake url in DB
    if (illustration instanceof File) data["illustration"] = illustration;
    if (footer instanceof File) data["footer"] = footer;

    if (bundledAccesspoints) {
      let bundledAccesspointsToSend = bundledAccesspoints.filter(bundledAp => bundledAp._id || !bundledAp._destroy);
      const { accesspoint } = this.props;
      if (accesspoint) {
        const { accesspoint_bundle } = accesspoint;
        const existingBundledAccesspoints = accesspoint_bundle && accesspoint_bundle.bundled_accesspoints || [];
        bundledAccesspointsToSend = bundledAccesspointsToSend.map(bundledAp => {
          // Here we remap existing bundledAccesspoints _id field from props to the ones in the state
          // After adding one, the _id field is not set in state so the server try to re create one, resulting to an error
          // because an bundled_accesspoint associated to the same accesspoint already exists.
          const existingBundleAp = existingBundledAccesspoints.find(bundledApInBundle => bundledApInBundle.accesspoint_id == bundledAp.accesspoint_id);

          if (existingBundleAp) bundledAp._id = existingBundleAp._id;
          return bundledAp;
        });
      }
      data["accesspoint_bundle_attributes"] = { bundled_accesspoints_attributes: bundledAccesspointsToSend };
    }

    const buildQuotaProduct = {};

    if (invitationQuotaType) buildQuotaProduct.quota_type = invitationQuotaType;
    if (invitationQuotaNumber) buildQuotaProduct.quota_number = invitationQuotaNumber;
    if (invitationQuotaCategoryId) buildQuotaProduct.category_id = invitationQuotaCategoryId;

    if (Object.keys(buildQuotaProduct).length > 0) {
      data.quota_product_attributes = buildQuotaProduct;
    }

    return data;
  }

  computeAccesspointRoles() {
    const { speakers, exhibitors, moderators } = this.state;
    const speakerRoles = this.computeSpecificRole(speakers, "speaker");
    const exhibitorRoles = this.computeSpecificRole(exhibitors, "exhibitor");
    const moderatorRoles = this.computeSpecificRole(moderators, "live_moderator");

    return speakerRoles.concat(exhibitorRoles).concat(moderatorRoles).map((role) => {
      role["_id"] = role["_id"] || null;
      return pick(role, ["_id", "type", "guest_id", "accesspoint_id", "_destroy"]);
    });
  }

  /* For each specific role type (speaker, exhibitor)
     We have to transform selected guests from search dropdown into guest_accesspoint_roles
     We also have to take into account existing roles that might have been removed from selection */
  computeSpecificRole(selectedGuests, roleType) {
    const { accesspoint } = this.props;
    const { guest_accesspoint_roles } = accesspoint;
    const selectedGuestIds = selectedGuests.map(guest => guest._id);
    let existingRolesForType = guest_accesspoint_roles ? guest_accesspoint_roles.filter(role => role.type == roleType) : [];

    // if existing roles from DB are no longer in guest selection from dropdown, we have to mark them as destroyable
    existingRolesForType = existingRolesForType.map(role => {
      if (!selectedGuestIds.includes(role.guest_id)) {
        role["_destroy"] = true;
      }
      return role;
    });

    // if some guests in selection are not already in existing roles, we have to add them in
    const existingRolesGuestIdsForType = existingRolesForType.map(role => role.guest_id);
    const newRolesForType = selectedGuests.reduce((acc, guest) => {
      if (!existingRolesGuestIdsForType.includes(guest._id)) {
        acc.push({ type: roleType, accesspoint_id: accesspoint._id, guest_id: guest._id });
      }
      return acc;
    }, []);

    return existingRolesForType.concat(newRolesForType);
  }

  componentDidMount() {
    const { customSessionTypes, customLocations, customRoomTypes, isModal } = this.props;

    if (isModal) {
      this.appendCustomOptions(customSessionTypes, "sessionTypesOptions");
      this.appendCustomOptions(customLocations, "locationsOptions");
      this.appendCustomOptions(customRoomTypes, "roomTypesOptions");
    }
  }

  updateDisplayedImage(props, nextProps, imageKey, imageUrlKey, imageUrlKeyInProps, removeImageKey, imageRef) {
    const { accesspoint } = props;

    if (nextProps.accesspoint[imageUrlKeyInProps] != accesspoint[imageUrlKeyInProps]) {
      // update displayed image after update and reinit file input
      // and reinit remove checkbox state after removing image
      this.setState({
        [imageUrlKey]: nextProps.accesspoint[imageUrlKeyInProps],
        [imageKey]: null,
        [removeImageKey]: false
      });
      this[imageRef].innerHTML = I18n.t("no_file_selected");
    }
  }

  componentDidUpdate(prevProps) {
    const { accesspoint, customSessionTypes, customLocations, customRoomTypes, event, fetchStreamingArchives } = this.props;

    if (event && event._id && this.isAccesspointPersisted() && !prevProps.isFetchingStreamingArchives && !prevProps.streamingArchives) {
      fetchStreamingArchives(event._id, accesspoint._id);
    }

    if (prevProps.accesspoint && Object.keys(prevProps.accesspoint).length > 0) {
      if (Object.keys(accesspoint).length === 0) {
        // if the url change to accesspoints/:id to accesspoint/new
        this.setState(Object.assign({}, prevProps.defaultState));
      } else {
        if (accesspoint !== prevProps.accesspoint && this.state.hlsBroadcastUrl !== accesspoint.hls_broadcast_url) {
          this.setState({ hlsBroadcastUrl: accesspoint.hls_broadcast_url });
        }
        this.updateDisplayedImage(prevProps, this.props, "illustration", "illustrationMedium", "illustration_medium", "remove_illustration", "illustrationInput");
        this.updateDisplayedImage(prevProps, this.props, "footer", "footerUrl", "footer_url", "remove_footer", "footerInput");
      }
    } else if (!isEmpty(accesspoint) && isEmpty(prevProps.accesspoint)) {
      this.setState(this.accesspointParamsToSetState(accesspoint));
    }

    // once event is loaded, date picker can be initialized
    if (isEmpty(prevProps.accesspoint) && event && !prevProps.event.start_date && event.start_date) {
      const today = new Date().toISOString();
      const defaultDate = today > event.start_date ? today : event.start_date;
      this.initMomentDatesFromEvent(defaultDate);
    }

    if (prevProps.customSessionTypes.length == 0 && customSessionTypes.length > 0) {
      this.appendCustomOptions(customSessionTypes, "sessionTypesOptions");
    }

    if (prevProps.customLocations.length == 0 && customLocations.length > 0) {
      this.appendCustomOptions(customLocations, "locationsOptions");
    }

    if (prevProps.customRoomTypes.length == 0 && customRoomTypes.length > 0) {
      this.appendCustomOptions(customRoomTypes, "roomTypesOptions");
    }
  }

  isMissingArchiveUsedForLiveFromVideoFile() {
    const { streaming_archive_used_for_live_from_file_id, liveSessionType } = this.state;

    return this.isAccesspointPersisted() && !this.isLiveOver() && liveSessionType == "from_video_file" && (!streaming_archive_used_for_live_from_file_id || streaming_archive_used_for_live_from_file_id === "");
  }

  isAccesspointPersisted() {
    const { accesspoint } = this.props;

    return accesspoint && accesspoint._id;
  }

  isLiveOver() {
    const { momentEndDate } = this.state;

    if (!momentEndDate) return true;

    return moment().isAfter(momentEndDate);
  }

  appendCustomOptions(customValues, stateKey) {
    const customOptions = customValues.map(v => ({ value: v, label: v }));
    this.setState({
      [stateKey]: (this.state[stateKey] || []).concat(customOptions)
    });
  }

  initMomentDatesFromEvent(unformattedStartDate) {
    const { startHour, startMinute, durationHour, durationMinute } = this.state;

    const startDate = moment(unformattedStartDate).format(I18n.t("datetime_picker_js_format"));
    const momentStartDate = moment(startDate, I18n.t("datetime_picker_js_format")).hours(startHour).minutes(startMinute);
    const momentEndDate = momentStartDate.clone().add(durationHour, "h").add(durationMinute, "m");

    this.setState({ startDate, momentStartDate, momentEndDate });
  }

  accesspointParamsToSetState(accesspoint) {
    const {
      name,
      display_name,
      uid,
      website_path_slug,
      website_path_slug_translations,
      type,
      ask_for_signature,
      display_message_upon_checkin,
      allow_guests_who_havent_paid_yet,
      auto_register_guest_on_checkin,
      live_session_type,
      archive_streams,
      hls_broadcast_url,
      live_session_chat_moderation_type,
      footer_link,
      opentok_resolution,
      session_type,
      thematic_ids,
      location,
      room_type,
      description,
      illustration,
      illustration_medium,
      footer_url,
      traits,
      price,
      tax,
      tax_name,
      other_tax,
      other_tax_name,
      capacity,
      checkinable,
      accesspoint_gates,
      start_date_to_timezone,
      end_date_to_timezone,
      capacity_reached_warning_email,
      plus_one_guest_category_id,
      plus_one_enabled,
      guest_accesspoint_roles,
      max_occupancy,
      square_meters_area,
      streaming_archive_used_for_live_from_file_id,
      always_allow_exit,
      live_session_focused_stream_takes_full_screen,
      live_video_started_at,
      chat_in_replay_enabled,
      chat_in_replay_sync_offset,
      chat_highlight_messages_enabled,
      live_session_chat_enabled,
      before_start_notification_enabled,
      live_intervention_requests_enabled,
      live_session_has_onsite_attendees,
      session_room_id,
      accesspoint_bundle,
      live_polls_enabled,
      in_person_live_access_button_displayed_on_website,
      session_type_id,
      quota_product,
    } = accesspoint;

    const activateTimeSlot = !!start_date_to_timezone;
    const momentStartDate = start_date_to_timezone ? moment(start_date_to_timezone, `${I18n.t("datetime_picker_js_format")} HH:mm`) : moment();
    const startDate = momentStartDate.format(I18n.t("datetime_picker_js_format"));
    const startHour = momentStartDate.hours();
    const startMinute = momentStartDate.minutes();
    const liveVideoStartedAt = live_video_started_at ? moment(live_video_started_at).format("HH:mm:ss") : null;
    const momentEndDate = end_date_to_timezone ? moment(end_date_to_timezone, `${I18n.t("datetime_picker_js_format")} HH:mm`) : moment();
    const durationHour = momentEndDate.diff(momentStartDate, "hours");
    const durationMinute = momentEndDate.diff(momentStartDate, "minutes") % 60;
    const traitObjectToArray = Object.entries(traits).map(([key, value]) => {
      return { key, value };
    });

    const speakersRoles = guest_accesspoint_roles.filter(role => role.type == "speaker");
    const speakers = speakersRoles.map(role => role.guest);
    const exhibitors = guest_accesspoint_roles.filter(role => role.type == "exhibitor").map(role => role.guest);
    const moderators = guest_accesspoint_roles.filter(role => role.type == "live_moderator").map(role => role.guest);
    const remove_illustration = false;
    const remove_footer = false;

    let bundledAccesspoints = accesspoint_bundle && accesspoint_bundle.bundled_accesspoints || [];
    if (type === "bundle") {
      bundledAccesspoints = bundledAccesspoints.map((item, index) => {
        item["index"] = index;
        return item;
      });
    }

    let invitationQuotaType = quota_product ? quota_product.quota_type : null;
    let invitationQuotaNumber = quota_product ? quota_product.quota_number : null;
    let invitationQuotaCategoryId = quota_product ? quota_product.category_id : null;

    return {
      remove_illustration,
      remove_footer,
      name,
      displayName: display_name,
      uid,
      website_path_slug,
      website_path_slug_translations,
      type,
      askForSignature: ask_for_signature,
      displayMessageUponCheckin: display_message_upon_checkin,
      allowGuestsWhoHaventPaidYet: allow_guests_who_havent_paid_yet,
      auto_register_guest_on_checkin,
      liveSessionType: live_session_type,
      archiveStreams: archive_streams,
      hlsBroadcastUrl: hls_broadcast_url,
      liveSessionChatModerationType: live_session_chat_moderation_type,
      footerLink: footer_link,
      opentokResolution: opentok_resolution,
      sessionType: session_type,
      thematic_ids: thematic_ids || [],
      location,
      roomType: room_type,
      description,
      illustration,
      illustrationMedium: illustration_medium,
      footerUrl: footer_url,
      traits: traitObjectToArray,
      price,
      tax,
      tax_name,
      other_tax,
      other_tax_name,
      capacity,
      checkinable,
      accesspointGates: accesspoint_gates || [],
      activateTimeSlot,
      startDate,
      startHour,
      startMinute,
      durationHour,
      durationMinute,
      momentStartDate,
      momentEndDate,
      capacityReachedWarningEmail: capacity_reached_warning_email,
      isEdit: true,
      plusOneGuestCategoryId: plus_one_guest_category_id,
      plusOneEnabled: plus_one_enabled,
      displayedSections: this.sectionsToDisplayFromAccesspoint(accesspoint),
      speakers,
      exhibitors,
      moderators,
      max_occupancy,
      square_meters_area,
      always_allow_exit,
      streaming_archive_used_for_live_from_file_id,
      liveSessionFocusedStreamTakesFullScreen: live_session_focused_stream_takes_full_screen,
      liveVideoStartedAt,
      chatInReplayEnabled: chat_in_replay_enabled,
      chatInReplaySyncOffset: chat_in_replay_sync_offset,
      chatHighlightMessagesEnabled: chat_highlight_messages_enabled,
      liveSessionChatEnabled: live_session_chat_enabled,
      before_start_notification_enabled,
      liveInterventionRequestsEnabled: live_intervention_requests_enabled,
      liveSessionHasOnsiteAttendees: live_session_has_onsite_attendees,
      sessionRoomId: session_room_id,
      bundledAccesspoints: bundledAccesspoints,
      livePollsEnabled: live_polls_enabled,
      inPersonLiveAccessButtonDisplayedOnWebsite: in_person_live_access_button_displayed_on_website,
      sessionTypeId: session_type_id,
      invitationQuotaType,
      invitationQuotaNumber,
      invitationQuotaCategoryId
    };
  }

  sectionsToDisplayFromAccesspoint(accesspoint) {
    const { type, start_date_to_timezone, capacity, price, checkinable, traits, max_occupancy, live_session_type, quota_product } = accesspoint;
    let sectionsToDisplay = [SECTION_INFO];

    if (type == "session" || !!start_date_to_timezone)
      sectionsToDisplay.push(SECTION_PROGRAM);

    if (capacity > 0)
      sectionsToDisplay.push(SECTION_REGISTRATION);

    if (price > 0)
      sectionsToDisplay.push(SECTION_PRICE);

    if (checkinable > 0)
      sectionsToDisplay.push(SECTION_CHECKIN);

    if (Object.keys(traits).length > 0)
      sectionsToDisplay.push(SECTION_ADVANCED);

    if (max_occupancy > -1)
      sectionsToDisplay.push(SECTION_OCCUPANCY);

    if (live_session_type !== "none") {
      sectionsToDisplay.push(SECTION_LIVE);
      sectionsToDisplay.push(SECTION_REPLAYS);
    }

    if (!isEmpty(quota_product))
      sectionsToDisplay.push(SECTION_INVITATION);

    if (type === "bundle")
      sectionsToDisplay.push(SECTION_BUNDLE);

    return sectionsToDisplay;
  }

  toggleReplaysManager() {
    const { replaysManagerIsDisplayed } = this.state;
    this.setState({ replaysManagerIsDisplayed: !replaysManagerIsDisplayed });
  }

  updateField(key) {
    return e => {
      this.setState({ [key]: e.target.value });
    };
  }

  updateName(e) {
    const { name, displayName } = this.state;
    const newName = e.target.value;

    const newState = { name: newName };
    if (name === displayName) {
      newState.displayName = newName;
    }

    this.setState(newState);
  }

  updateLiveSessionType(e) {
    const liveSessionType = e.target.value;

    this.setState({
      liveSessionType,
      archiveStreams: ["webinar", "streaming"].includes(liveSessionType),
      liveSessionTypeChanged: true
    });
  }

  updateLimitField(key) {
    return value => {
      this.setState({ [key]: value });
    };
  }

  updateType(e) {
    const type = e.target.value;
    const activateTimeSlot = this.state.activateTimeSlot || ["session", "accommodation", "meeting"].includes(type);
    this.setState({ type, activateTimeSlot });
  }

  updateOpentokResolution(e) {
    this.setState({ opentokResolution: e.target.value });
  }

  toggleCheckbox(key) {
    return () => {
      const oldValue = this.state[key];
      let stateModified = {};
      stateModified[key] = !oldValue;
      this.setState(stateModified);
    };
  }

  onRadioChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  triggerOtherTax(e) {
    e.preventDefault();
    this.setState({
      other_tax: 10.0
    });
  }

  updateRTE(htmlContent, draftjsContent) {
    const description = { html: htmlContent, draftjs_content: draftjsContent };
    this.setState({ description: description });
  }

  updateImage(key) {
    return e => {
      const file = e.target.files[0];
      this.setState({ [key]: file });
    };
  }

  creatableSelectOnChangeHandler(key) {
    return option => {
      this.setState({ [key]: this.creatableSelectValueFromOption(key, option) }, () => {
        this.computeAccesspointName();
      });
    };
  }

  // special cases to handle here since some fields expect an array, others a string,
  // some expect a default value, others do not
  // and especially the case when clearing field which always sends [] instead of "" (react-select is at fault)
  creatableSelectValueFromOption(key, option) {
    if (key == "thematic_ids") {
      return option ? option.map(opt => opt.value) : [];
    } else if (option) {
      return option.value || "";
    } else {
      return "";
    }
  }

  creatableSelectOnInputChangeHandler(key) {
    return (inputValue) => {
      this.setState({ [key]: inputValue }, () => {
        this.computeAccesspointName();
      });
    };
  }

  createNewOptionHandler(key, customOptionsKey) {
    return (inputValue) => {
      this.setState({ [key]: inputValue }, () => {
        this.appendCustomOptions([inputValue], customOptionsKey);
        this.computeAccesspointName();
      });
    };
  }

  computeAccesspointName() {
    const { type, location, roomType, startDate } = this.state;
    if (type != "accommodation") return;

    const computedName = `${location} - ${roomType} - ${startDate}`;
    this.setState({ name: computedName, displayName: computedName });
  }

  removeAccesspointGate(index) {
    const { accesspointGates } = this.state;
    return () => {
      let newGates = accesspointGates.slice();
      newGates[index]._destroy = true;
      this.setState({ accesspointGates: newGates });
    };
  }

  addAccesspointGate() {
    const { accesspointGates } = this.state;
    let newGates = accesspointGates.slice();
    newGates.push({ name: "" });
    this.setState({ accesspointGates: newGates });
  }

  updateAccesspointGate(index) {
    const { accesspointGates } = this.state;
    return (e) => {
      let newGates = accesspointGates.slice();
      newGates[index].name = e.target.value;
      this.setState({ accesspointGates: newGates });
    };
  }

  updateTraits(traits) {
    this.setState({ traits });
  }

  updateDate(key) {
    return (e) => {
      const newState = Object.assign({}, this.state, { [key]: e.target.value });
      const { startDate, startHour, startMinute, durationHour, durationMinute } = newState;
      newState.momentStartDate = moment(startDate, I18n.t("datetime_picker_js_format")).hours(startHour).minutes(startMinute);
      newState.momentEndDate = moment(startDate, I18n.t("datetime_picker_js_format")).hours(startHour).minutes(startMinute).add(durationHour, "h").add(durationMinute, "m");
      newState.startDateChanged = true;
      this.setState(newState);
    };
  }

  changeDateSelector(value) {
    const { startHour, startMinute, durationHour, durationMinute } = this.state;
    const momentValue = moment(value);
    const startDate = momentValue.format(I18n.t("datetime_picker_js_format"));
    const momentStartDate = momentValue.clone().hours(startHour).minutes(startMinute);
    const momentEndDate = momentValue.clone().hours(startHour).minutes(startMinute).add(durationHour, "h").add(durationMinute, "m");
    this.setState({
      startDate,
      momentStartDate,
      momentEndDate,
      startDateChanged: true
    }, () => this.computeAccesspointName());
  }

  onPlusOneCategoryChange(categoryIds) {
    const plusOneGuestCategoryId = categoryIds[0] || null;
    this.setState({
      plusOneGuestCategoryId
    });
  }

  setSpeakers(speakers) {
    this.setState({ speakers });
  }

  setExhibitors(exhibitors) {
    this.setState({ exhibitors });
  }

  setModerators(moderators) {
    this.setState({ moderators });
  }

  setSessionType(sessionType) {
    this.setState({ sessionTypeId: sessionType.value });
  }

  backPath() {
    const { defaultType } = this.props;

    if (defaultType == "session")
      return pathToSessionsList();
    else if (defaultType == "product")
      return pathToProductsList();
    else if (defaultType == "accommodation")
      return pathToAccommodationsList();
    else if (defaultType == "meeting")
      return pathToMeetingsList();
    else if (defaultType == "bundle")
      return pathToBundlesList();
    else
      return pathToAccesspointIndex();
  }

  i18n(key, model = false, options = {}) {
    if (model)
      return I18n.t(`mongoid.attributes.accesspoint.${key}`, options);
    return I18n.t(`react.accesspoint.form.${key}`, options);
  }

  isExitAccesspoint() {
    const { accesspoint } = this.props;

    return accesspoint && accesspoint.entrance_accesspoint_id;
  }

  renderNotice() {
    const { notice, noticeType } = this.props;

    if (!notice || notice.length == 0) return "";

    return (
      <div className={`alert alert-${noticeType}`}>
        {notice}
      </div>
    );
  }

  renderTitle() {
    const { isModal, defaultType } = this.props;
    if (isModal) return null;

    const suffix = ["session", "product", "accommodation", "meeting", "bundle"].includes(defaultType) ? defaultType : "checkin_point";
    const { isEdit } = this.state;
    return <h1>
      <a href={this.backPath()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></a>
      {!isEdit ? this.i18n(`new_${suffix}`) : this.i18n(`edit_${suffix}`)}
    </h1>;
  }

  renderSaveAndGoToListButton() {
    const { isModal } = this.props;

    if (isModal || this.isMissingArchiveUsedForLiveFromVideoFile()) return;

    return <input type="submit" className="btn btn-primary btn-lg" value={this.i18n("save_and_goto_list")} />;
  }

  renderSaveButton(classes) {
    return <input type="submit" className={`btn mr-5 ${classes}`} value={this.i18n("save")} onClick={this.createOrUpdateAccesspointWithoutRedirect} />;
  }

  renderSaveButtons() {
    return <p className="float-end">
      {this.renderSaveButton("btn-secondary btn-lg")}
      {this.renderSaveAndGoToListButton()}
    </p>;
  }

  renderCancelButton() {
    const { isModal } = this.props;

    if (isModal) return null;

    return <a href={this.backPath()} className="btn btn-secondary btn-lg">
      {I18n.t("cancel")}
    </a>;
  }

  renderCheckbox(stateKey, label, text, disabled = false) {
    const value = this.state[stateKey];
    return [
      label ? <label key={`label-${stateKey}`} className="col-form-label">{label}</label> : null,
      <div className="form-check" key={`checkbox-${stateKey}`}>
        <label className="form-check-label">
          <input type="checkbox" className="form-check-input" checked={value} onChange={this.toggleCheckbox(stateKey)} disabled={disabled} />
          {text}
        </label>
      </div>
    ];
  }

  renderSessionFields() {
    const { type } = this.state;

    if (!["session", "meeting"].includes(type)) return null;

    return [
      this.renderSessionTypeDropdown(),
      this.renderThematicsDropdown(),
      this.renderLocationDropdown(),
      this.renderSessionRoomDropdown(),
      this.renderSpeakersDropdown(),
      this.renderExhibitorsDropdown()
    ];
  }

  renderAccommodationFields() {
    const { type } = this.state;

    if (type != "accommodation") return null;

    return [
      this.renderLocationDropdown(),
      this.renderRoomTypesDropdown()
    ];
  }

  renderSessionTypeDropdown() {
    const { type, sessionTypeId } = this.state;
    if (type != "session") return null;

    return <div className="mb-3" key="session-type">
      <label className="form-label required">{this.i18n("session_type", true)}</label>
      <SessionTypesDropdown
        value={sessionTypeId}
        onSelect={this.setSessionType}
        allowCreate={true}
      />
    </div>;
  }

  renderThematicsDropdown() {
    const { thematic_ids } = this.state;

    return <div className="mb-3" key="thematic_ids">
      <label className="form-label">{this.i18n("thematic_ids", true)}</label>
      <ThematicsDropdown
        value={thematic_ids}
        onChange={this.creatableSelectOnChangeHandler("thematic_ids")}
        allowCreate={true}
        coloredThematics={true}
        followHierarchyOnSelect={true}
      />
    </div>;
  }

  onMeetingLocationChange(e) {
    const targetValue = e.target.value;
    const newLocationValue = targetValue === "empty" ? "" : targetValue;

    this.setState({ ["location"]: newLocationValue });
  }

  renderMeetingLocationDropdown() {
    const { event } = this.props;

    const helpMessage = <>{this.i18n("path_to_new_location_help")} <a href={pathToMeetingsConfiguration()} target="blank">{this.i18n("here")}</a></>;

    return <div className="mb-3" key="location">
      <label className="form-label">{this.i18n("location", true)}</label>
      <select className="form-select" style={{ display: "inline-block" }}
        value={this.state.location}
        onChange={this.onMeetingLocationChange}
      >
        <option value="empty" key="empty">{I18n.t("please_choose")}</option>
        {(event.meeting_locations || []).map(eventMeetingLocation => {
          return <option key={eventMeetingLocation.name} value={eventMeetingLocation.name}>
            {eventMeetingLocation.name}
          </option>;
        })}
      </select>
      <HelpSection help={helpMessage} classNames="mt-10" />
    </div>;
  }

  renderLocationDropdown() {
    const { type, location, locationsOptions } = this.state;
    if (type === "session") return null;
    if (type === "meeting") return this.renderMeetingLocationDropdown();

    const labelKey = type == "accommodation" ? "establishment" : "location";

    return <div className="mb-3" key="location">
      <label className="form-label">{this.i18n(labelKey, true)}</label>
      <CreatableSelect
        value={locationsOptions.filter(({ value }) => location == value)}
        options={locationsOptions}
        isClearable={true}
        onChange={this.creatableSelectOnChangeHandler("location")}
        onCreateOption={this.createNewOptionHandler("location", "locationsOptions")}
        formatCreateLabel={(label) => this.i18n("create_option", false, { label: label })}
        noOptionsMessage={() => this.i18n("no_locations")}
        placeholder={this.i18n("create_select_placeholder")}
        className="react-select"
        classNamePrefix="react-select" />
    </div>;
  }

  renderSessionRoomDropdown() {
    const { type, sessionRoomId } = this.state;
    if (type !== "session") return null;

    return <div className="mb-3" key="session_room">
      <label className="form-label">{this.i18n("session_room", true)}</label>
      <SessionRoomsDropdown value={sessionRoomId} onChange={this.creatableSelectOnChangeHandler("sessionRoomId")} />
    </div>;
  }

  renderRoomTypesDropdown() {
    const { roomType, roomTypesOptions } = this.state;

    return <div className="mb-3" key="room_type">
      <label className="form-label">{this.i18n("room_type", true)}</label>
      <CreatableSelect
        value={roomTypesOptions.filter(({ value }) => roomType == value)}
        options={roomTypesOptions}
        isClearable={true}
        onChange={this.creatableSelectOnChangeHandler("roomType")}
        onCreateOption={this.createNewOptionHandler("roomType", "roomTypesOptions")}
        formatCreateLabel={(label) => this.i18n("create_option", false, { label: label })}
        noOptionsMessage={() => this.i18n("no_room_types")}
        placeholder={this.i18n("create_select_placeholder")}
        className="react-select"
        classNamePrefix="react-select" />
    </div>;
  }

  renderSpeakersDropdown() {
    const { speakers, type } = this.state;

    return <div className="mb-3" key="speakers">
      <label className="form-label">{type == "session" ? this.i18n("speakers") : this.i18n("attendants")}</label>
      <GuestSearchDropdown
        onSelectGuest={this.setSpeakers}
        selectedGuests={speakers}
        allowMultipleSelect={true} />
    </div>;
  }

  renderExhibitorsDropdown() {
    if (!isEnabled(EXHIBITORS)) return null;

    const { exhibitors } = this.state;
    return <div className="mb-3" key="exhibitors">
      <label className="form-label">{this.i18n("exhibitors")}</label>
      <GuestSearchDropdown
        onSelectGuest={this.setExhibitors}
        selectedGuests={exhibitors}
        allowMultipleSelect={true} />
    </div>;
  }

  renderAccesspointGates() {
    if (!isEnabled(CHECKIN_GATES)) return;

    const { accesspointGates } = this.state;
    const gates = accesspointGates.map((gate, index) => {
      if ("_destroy" in gate) return null;

      return (
        <div key={`gate-${index}`} className="mb-3 row g-2 row align-items-center form-traits">
          <div className="col-auto">
            <input type="text" className="form-control" placeholder={this.i18n("gate_default_key")} value={gate.name} onChange={this.updateAccesspointGate(index)} />
          </div>
          <div className="col-auto">
            <a className="btn btn-danger" onClick={this.removeAccesspointGate(index)}>
              <i className="fa-regular fa-trash-can"></i>
            </a>
          </div>
        </div>
      );
    });

    return (
      <div>
        <label className="form-label">{this.i18n("checkin_point_gates")}</label><br />
        {gates}
        <a className="btn btn-secondary" onClick={this.addAccesspointGate}>
          <i className="fa-regular fa-plus"></i>
        </a>
      </div>
    );
  }

  renderDateChangedWarningMessage() {
    const { momentStartDate, liveSessionType, liveSessionTypeChanged, startDateChanged, before_start_notification_enabled } = this.state;
    if (!before_start_notification_enabled) return;
    if (!liveSessionTypeChanged && !startDateChanged) return;
    if (!liveSessionType || liveSessionType === "none" || !momentStartDate) return;
    if (moment() > momentStartDate) return;

    return <div className="form-text" key="warningMessage">
      <i className="fa-regular fa-exclamation-triangle text-warning"> </i>
      {" "}
      {this.i18n("change_start_date_notification_warning")}
    </div>;
  }

  renderTimeSlots() {
    const { activateTimeSlot, momentStartDate, startHour, startMinute, durationHour, durationMinute, type } = this.state;
    const value = momentStartDate.format();
    const startDateLabel = type == "accommodation" ? this.i18n("night_date") : this.i18n("start_date");

    return activateTimeSlot && [
      <div className="row mt-3" key="picker">
        <div className="col-sm-4">
          <div className="row">
            <label className="form-label">{startDateLabel}</label>
            <div className="col-auto">
              <DatePicker
                selectedDate={new Date(value)}
                onChange={this.changeDateSelector}
              />
            </div>
          </div>
        </div>
        {type != "accommodation" &&
          <div className="col-sm-8">
            <div className="row">
              <div className="col-sm-6">
                <label className="form-label">{this.i18n("start_hour")}</label>
                <div className="row g-2 row align-items-center time-part">
                  <div className="col-auto">
                    <select id="start_hour" className="form-select" onChange={this.updateDate("startHour")} value={startHour}>
                      {hoursOptions()}
                    </select>
                  </div>
                  <div className="col-auto">
                    <select id="start_minute" className="form-select" onChange={this.updateDate("startMinute")} value={startMinute}>
                      {minutesOptions()}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <label className="form-label">{this.i18n("duration")}</label>
                <div className="row g-2 row align-items-center time-part">
                  <div className="col-auto">
                    <select id="duration_hour" className="form-select" onChange={this.updateDate("durationHour")} value={durationHour}>
                      {hoursOptions()}
                    </select>
                  </div>

                  <div className="col-auto">
                    <select id="duration_minute" className="form-select" onChange={this.updateDate("durationMinute")} value={durationMinute}>
                      {minutesOptions()}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>,
      this.renderDateChangedWarningMessage()
    ];
  }

  renderPlusOneFields() {
    if (!isEnabled(ACCOMPANYING_PERSONS)) return;

    const { guestCategories } = this.props;
    const { plusOneEnabled, plusOneGuestCategoryId, submitLaunched } = this.state;

    return <>
      {this.renderCheckbox("plusOneEnabled", null, this.i18n("plus_one_enabled", true))}
      {plusOneEnabled && (
        <div className="mt-10 row">
          <FilterDropdown
            id="guest_category_picker"
            translationKey="guest_category_picker"
            items={guestCategories}
            itemIdKey="_id"
            selectedItemIds={plusOneGuestCategoryId ? [plusOneGuestCategoryId] : []}
            onChange={this.onPlusOneCategoryChange}
            submitLaunched={submitLaunched}
            itemColorKey="label_color"
            title={this.i18n("plus_one_guest_category_id", true)}
            multipleSelect={false}
            hasSelectAll={false}
          />
        </div>
      )}
    </>;
  }

  renderMobileAppSection() {
    if (!anyEnabled([SIGNATURE, MESSAGES_ON_CHECKIN, ACCOMPANYING_PERSONS])) return null;

    return (
      <div className="mb-3">
        <label className="form-label">{this.i18n("mobile_app_features")}</label>
        {isEnabled(SIGNATURE) && this.renderCheckbox("askForSignature", null, this.i18n("ask_for_signature_help"))}
        {isEnabled(MESSAGES_ON_CHECKIN) && this.renderCheckbox("displayMessageUponCheckin", null, this.i18n("display_message_upon_checkin_enabled_help"))}
        {this.renderPlusOneFields()}
      </div>
    );
  }

  renderErrorsMessage() {
    return <ErrorMessage errors={this.props.errors} model="accesspoint" />;
  }

  fieldError(field) {
    const { errors } = this.props;

    if (!errors[field]) return null;

    return errors[field];
  }

  renderImageInput(imageKey, imageUrlKey, labelKeyInLocale, checkboxKeyInLocale, removeImageKey, ref, helpMessage = "") {
    const image_url = this.state[imageUrlKey];
    const removeImage = this.state[removeImageKey];

    return (
      <div className="mt-3">
        <label className="form-label">{this.i18n(labelKeyInLocale, true)}</label>
        <div className="custom-file row">
          <div className="input-group">
            <div className="form-control">{I18n.t("no_file_selected")}</div>
            <div className="input-group-text">{I18n.t("choose_file")}</div>
          </div>
          <input type="file" accept="image/*" name="update_image" id="update_image" onChange={this.updateImage(imageKey)} />
        </div>
        {helpMessage && <div className="form-text">{helpMessage}</div>}
        {image_url && this.renderCheckbox(removeImageKey, null, this.i18n(checkboxKeyInLocale))}
        {
          image_url && !removeImage &&
          <p><img src={image_url} className="img-fluid" /></p>
        }
      </div>
    );
  }

  renderInformationSection() {
    const { type, name, displayName, description } = this.state;
    const { defaultType } = this.props;

    return (
      <div>
        {(!defaultType || defaultType == "checkin_point") &&
          <div className="mb-3">
            <label className="form-label ">{this.i18n("type", true)}</label>
            <select className="form-select" onChange={this.updateType} value={type}>
              {CHECKIN_POINT_TYPES.map(type => {
                return <option key={`type-${type}`} value={type}>{I18n.t(`accesspoint_type_${type}`)}</option>;
              })}
            </select>
          </div>
        }
        <div className="mb-3">
          <label className="form-label required">{this.i18n("name", true)}</label>
          <input className="form-control" value={name} type="text" onChange={this.updateName} readOnly={defaultType === "accommodation"} />
          {defaultType == "accommodation" &&
            <div className="form-text">{this.i18n("accommodation_name_help")}</div>
          }
        </div>
        <div className="mb-3">
          <label className="form-label">{this.i18n("display_name", true)}</label>
          <input className="form-control" value={displayName} type="text" onChange={this.updateField("displayName")} readOnly={defaultType === "accommodation"} />
          <div className="form-text">{this.i18n("display_name_help")}</div>
        </div>
        <div className="mb-3">
          <label className="form-label">{this.i18n("description", true)}</label>
          <RichTextEditor content={description.html}
            draftjsContent={description.draftjs_content}
            onBlur={this.updateRTE}
            toolbarSize="small"
            aiModalSize="medium"
            context="backoffice"
            textGeneratorPrePromptKey="organizer_content_context"
          />
        </div>
        {this.renderImageInput("illustration", "illustrationMedium", "illustration", "remove_illustration", "remove_illustration", ref => this.illustrationInput = ref)}
      </div>
    );
  }

  onBundledAccesspointDeleted(bundledAccesspoint) {
    const { bundledAccesspoints } = this.state;
    bundledAccesspoint["_destroy"] = true;
    this.setState({
      bundledAccesspoints: [...bundledAccesspoints.map(item => bundledAccesspoint.index === item.index ? bundledAccesspoint : item)],
    });
  }

  onBundledAccesspointChanged(bundledAccesspoint) {
    // to keep bundled_accesspoint_id if we add the same accesspoint
    const { bundledAccesspoints } = this.state;
    const accesspointAlreadyBundled = bundledAccesspoints.find(bundledAp => bundledAp.accesspoint_id === bundledAccesspoint.accesspoint_id && bundledAp._id);
    if (accesspointAlreadyBundled && accesspointAlreadyBundled.index !== bundledAccesspoint.index) {
      bundledAccesspoint._id = accesspointAlreadyBundled._id;
      bundledAccesspoints[accesspointAlreadyBundled.index]._destroy = true;
      bundledAccesspoints[accesspointAlreadyBundled.index]._id = null;
    }

    this.setState({
      bundledAccesspoints: bundledAccesspoints.map(item => bundledAccesspoint.index === item.index ? bundledAccesspoint : item),
    });
  }

  onBundledAccesspointAdded() {
    const { bundledAccesspoints } = this.state;
    const newBundledAccesspoint = {
      accesspoint_id: null,
      index: bundledAccesspoints.length,
      quantity: 1,
    };
    this.setState({
      bundledAccesspoints: [...bundledAccesspoints, newBundledAccesspoint]
    });
  }

  renderBundleSection() {
    const { bundledAccesspoints } = this.state;
    return (
      <BundledAccesspointsList
        items={bundledAccesspoints.filter(item => !item._destroy)}
        onDeleted={this.onBundledAccesspointDeleted}
        onAdded={this.onBundledAccesspointAdded}
        onChanged={this.onBundledAccesspointChanged}
      />
    );
  }

  renderProgramSection() {
    const { type } = this.state;
    const checkboxLabel = type == "accommodation" ? this.i18n("activate_night_date_label") : this.i18n("activate_time_slot_label");
    const isCheckboxDisabled = ["session", "accommodation", "meeting"].includes(type);

    return (
      <div>
        {this.renderSessionFields()}
        {this.renderAccommodationFields()}
        {this.renderCheckbox("activateTimeSlot", null, checkboxLabel, isCheckboxDisabled)}
        {this.renderTimeSlots()}
      </div>
    );
  }

  renderStreamingConfig() {
    const { accesspoint } = this.props;
    if (!accesspoint) return;

    const { liveSessionType, hlsBroadcastUrl } = this.state;
    if (liveSessionType !== "streaming") return;

    const { rtmp_endpoint, rtmp_stream_key, live_session_type } = accesspoint;
    const hls_from_session_room = !!(accesspoint.session_room && accesspoint.session_room.hls_broadcast_url);

    return <div className="mt-3">
      <HasRtmpEndpointForm
        model="accesspoint"
        rtmpEndpointValue={rtmp_endpoint}
        rtmpStreamKeyValue={rtmp_stream_key}
        hlsBroadcastUrl={hlsBroadcastUrl}
        needSaveForRtmpData={liveSessionType !== live_session_type}
        readOnly={!window.ReactGlobalProps.super_admin || hls_from_session_room}
        onChange={this.updateField("hlsBroadcastUrl")}
      />
    </div>;
  }

  liveSessionTypes() {
    return LIVE_SESSION_TYPES.filter(type => {
      if (["streaming", "from_video_file"].includes(type))
        return isEnabled(STREAMING);
      else if (type === "webinar")
        return isEnabled(WEBINAR);
      else if (type === "video_conference")
        return isEnabled(VIDEO_CONFERENCE);
      else
        return true;
    });
  }

  liveMeetingTypes() {
    return LIVE_MEETING_TYPES;
  }

  liveTypeIsWebinar(type) {
    return ["webinar", "conference", "hls_broadcast"].includes(type);
  }

  renderLiveSection() {
    const { type, liveSessionType, footerLink, liveSessionChatModerationType, moderators, liveSessionChatEnabled, opentokResolution } = this.state;
    const liveTypes = type == "meeting" ? this.liveMeetingTypes() : this.liveSessionTypes();

    return (
      <div>
        <div className="mb-3">
          <label className="form-label">
            {this.i18n("live_session_type", true)}
          </label>
          <select className="form-select" onChange={this.updateLiveSessionType} value={liveSessionType}>
            {liveTypes.map(type => {
              return <option key={`live-session-type-${type}`} value={type}>{this.i18n(`live_session_type_${type}`)}</option>;
            })}
          </select>
          <div className="form-text">{this.i18n(`live_session_type_help_${liveSessionType}`, false, { defaultValue: "" })}</div>
        </div>

        {liveSessionType !== "none" && (
          <div>
            {this.renderPreRecordedLiveFile()}
            {this.liveTypeIsWebinar(liveSessionType) && (
              <div>
                {this.renderCheckbox("archiveStreams", null, this.i18n("archive_streams"))}
              </div>
            )}
            {liveSessionType === "streaming" && (
              <div>
                {this.renderCheckbox("archiveStreams", null, this.i18n("archive_streams"))}
                <div className="form-text">
                  <i className="fa-regular fa-exclamation-triangle text-warning"></i> {this.i18n("archive_streams_warning")}
                </div>
              </div>
            )}
            {["hls_broadcast", "webinar"].includes(liveSessionType) && (
              <div>
                {this.renderCheckbox("liveSessionFocusedStreamTakesFullScreen", null, this.i18n("live_session_focused_stream_takes_full_screen"))}
              </div>
            )}
            <div>
              {this.renderCheckbox("before_start_notification_enabled", null, this.i18n("before_start_notification_enabled"))}
            </div>
            {liveSessionType === "webinar" && (
              <div>
                {this.renderCheckbox("liveInterventionRequestsEnabled", null, this.i18n("live_intervention_requests_enabled", true))}
              </div>
            )}
            {LIVE_SESSION_TYPES_ALLOWING_LIVE_POLLS.includes(liveSessionType) && (
              <div>
                {this.renderCheckbox("livePollsEnabled", null, this.i18n("live_polls_enabled", true))}
              </div>
            )}
            {["webinar", "video_conference"].includes(liveSessionType) && (
              <div className="mb-3 mt-3">
                <label className="form-label ">{this.i18n("opentok_resolution", true)}</label>
                <select className="form-select" onChange={this.updateOpentokResolution} value={opentokResolution}>
                  {OPENTOK_RESOLUTIONS.map(resolution => {
                    return <option key={`res-${resolution}`} value={resolution}>{this.i18n(`opentok_resolution_${resolution}`)}</option>;
                  })}
                </select>
                <div className="form-text">
                  {this.i18n("opentok_resolution_help")}
                </div>
              </div>
            )}
            {this.renderStreamingConfig()}
            {this.renderImageInput("footer", "footerUrl", "footer", "remove_footer", "remove_footer", ref => this.footerInput = ref, this.i18n("footer_help"))}
            <div className="mb-3 mt-3">
              <label className="form-label">{this.i18n("footer_link")}</label>
              <input className="form-control" value={footerLink || ""} type="text" onChange={this.updateField("footerLink")} />
            </div>
            <div>
              {this.renderCheckbox("liveSessionChatEnabled", null, this.i18n("live_session_chat_enabled"))}
            </div>
            {liveSessionChatEnabled && [
              type != "meeting" && [
                <div className="mb-3 mt-3" key="moderationType">
                  <label className="form-label">{this.i18n("live_session_chat_moderation_type", true)}</label>
                  <select className="form-select" onChange={this.updateField("liveSessionChatModerationType")} value={liveSessionChatModerationType}>
                    {LIVE_SESSION_CHAT_MODERATION_TYPES.map(type => {
                      return <option key={`moderation-type-${type}`} value={type}>{this.i18n(`live_session_chat_moderation.${type}`)}</option>;
                    })}
                  </select>
                  <div className="form-text">{this.i18n(`live_session_chat_moderation_help.${liveSessionChatModerationType}`)}</div>
                </div>,
                (liveSessionChatModerationType != "none" &&
                  <div className="mb-3" key="moderators">
                    <label className="form-label">{this.i18n("moderators")}</label>
                    <GuestSearchDropdown onSelectGuest={this.setModerators} selectedGuests={moderators} allowMultipleSelect={true} />
                    <div className="form-text">{this.i18n("live_moderators_help")}</div>
                  </div>
                )
              ],
              <div key="highlight-messages">
                {this.renderCheckbox("chatHighlightMessagesEnabled", null, this.i18n("chat_highlight_messages_enabled"))}
                <div className="form-text" key="live-session-selected-messages-help">{this.i18n("chat_highlight_messages_enabled_help")}</div>
              </div>
            ]}
            {liveSessionType === "streaming" && (
              <div className="mt-3">
                <label className="form-label">{this.i18n("onsite_attendees")}</label>
                {this.renderCheckbox("liveSessionHasOnsiteAttendees", null, this.i18n("live_session_has_onsite_attendees"))}
                <div className="form-text" key="live-session-has-onsite-attendees-help">{this.i18n("live_session_has_onsite_attendees_help")}</div>
              </div>
            )}
            {this.renderInPersonAccessForm()}
          </div>
        )}
      </div>
    );
  }

  renderInPersonAccessForm() {
    const {
      liveSessionType,
      liveSessionHasOnsiteAttendees,
      livePollsEnabled
    } = this.state;

    const haveInPersonAttendees = (liveSessionType === "in_person" && livePollsEnabled) || (liveSessionType === "streaming" && liveSessionHasOnsiteAttendees);

    if (!haveInPersonAttendees) {
      return null;
    }

    return <>
      <div className="mb-3">
        <label className="form-label">{this.i18n("in_person_live_access")}</label>
        <HelpSection help={this.i18n("in_person_live_access_help")} />
        {this.renderLiveSessionAccessUrl()}
      </div>

      {liveSessionType === "in_person" && (
        <div className="mb-3">
          {this.renderCheckbox("inPersonLiveAccessButtonDisplayedOnWebsite", null, this.i18n("in_person_live_access_button_displayed_on_website"))}
        </div>
      )}
    </>;
  }

  renderLiveSessionAccessUrl() {
    const warningMessage = (message) => {
      return <p><i className="fa-regular fa-triangle-exclamation"></i> <em>{message}</em></p>;
    };

    if (!this.isAccesspointPersisted()) {
      return warningMessage(this.i18n("in_person_url_access_need_save"));
    }

    const url = this.props.accesspoint.website_live_page_url;
    if (!url) {
      return warningMessage(this.i18n("in_person_url_access_need_configuration"));
    }

    const { showInPersonLiveAccessQrcode } = this.state;

    return (
      <div>
        <p><a href={url} target="_blank">{url} <i className="fa-regular fa-external-link"></i></a></p>
        <button
          className="btn btn-sm btn-secondary"
          onClick={(e) => {
            e.preventDefault();
            this.setState({
              showInPersonLiveAccessQrcode: !showInPersonLiveAccessQrcode
            });
          }}
        >
          <i className="fa-regular fa-qrcode"></i> {this.i18n("get_qrcode")}
        </button>

        {showInPersonLiveAccessQrcode && (
          <div className="mt-10">
            <QRCode value={url} />
          </div>
        )}
      </div>
    );
  }

  renderReplaysSection() {
    const { replaysManagerIsDisplayed, liveSessionChatEnabled } = this.state;
    const { accesspoint } = this.props;

    const disabled = !accesspoint || !accesspoint._id;

    return (
      <div>
        <div className="mb-3">
          <div>
            <a className="btn btn-secondary" onClick={disabled ? null : this.toggleReplaysManager} disabled={disabled}>
              {this.i18n("manage_streaming_archives")}
            </a>
          </div>
          <div className="form-text d-none d-sm-inline">{this.i18n("manage_streaming_archives_help")}</div>
        </div>
        <StreamingArchivesManagerPane
          accesspointId={accesspoint._id}
          accesspoint={accesspoint}
          isOpen={replaysManagerIsDisplayed}
          onClose={this.toggleReplaysManager}
        />
        {liveSessionChatEnabled && this.renderChatInReplayConfiguration()}
      </div>
    );
  }

  renderChatInReplayConfiguration() {
    const { liveVideoStartedAt, chatInReplayEnabled, chatInReplaySyncOffset } = this.state;

    if (!liveVideoStartedAt) {
      return null; // this session is not compatible with the chat in replay
    }

    return (
      <div>
        {this.renderCheckbox("chatInReplayEnabled", null, this.i18n("chat_in_replay_enabled", true))}
        {chatInReplayEnabled && (
          <div className="row mt-3">
            <div className="col-sm-12">
              <p>{this.i18n("video_started_at")} <strong>{liveVideoStartedAt}</strong></p>
            </div>
            <div className="col-sm-3">
              <div className="input-group">
                <input type="number" className="form-control" value={chatInReplaySyncOffset} onChange={this.updateField("chatInReplaySyncOffset")} />
                <div className="input-group-text">{this.i18n("seconds")}</div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-text">{this.i18n("chat_in_replay_sync_offset_help")}</div>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderLiveFromVideoFileWarningMessage() {
    if (!this.isMissingArchiveUsedForLiveFromVideoFile()) return;

    return <div className="alert alert-warning">
      {this.i18n("live_from_video_file_archive_missing_warning")}
    </div>;
  }

  togglePreRecordedLiveModal(e) {
    if (e) e.preventDefault();
    this.setState({ preRecordedLiveModalOpen: !this.state.preRecordedLiveModalOpen });
  }

  renderAccessButtonToPreRecordedLiveModal() {
    if (this.isAccesspointPersisted()) {
      return <div>
        <button onClick={this.togglePreRecordedLiveModal} className="btn btn-secondary">
          {this.i18n("select_a_video")}
        </button>
      </div>;
    }

    return <input type="submit" className="btn btn-secondary" value={this.i18n("save_and_select_a_video")} onClick={this.createAccesspointAndOpenPreRecordedLiveVideoPickerModal} />;
  }

  renderVideoPreview() {
    const { streamingArchives } = this.props;
    const { streaming_archive_used_for_live_from_file_id } = this.state;

    if (!streamingArchives || !streaming_archive_used_for_live_from_file_id) return;

    const archive = streamingArchives.find(archive => archive._id === streaming_archive_used_for_live_from_file_id);

    if (!archive) return;

    const videoJsOptions = {
      autoplay: false,
      controls: true,
      sources: [{
        src: archive.public_url,
        type: "video/mp4"
      }],
      width: 320,
      height: 240
    };

    return <div key={archive._id}>
      <p className="mb-10" style={{ fontWeight: "bold", fontSize: "large" }}>{archiveFileName(archive)}</p>
      <VideoPlayer {...videoJsOptions} />
    </div>;
  }

  renderPreRecordedLiveFile() {
    const { liveSessionType, preRecordedLiveModalOpen, streaming_archive_used_for_live_from_file_id } = this.state;
    if (liveSessionType != "from_video_file") return;

    return <div>
      <label className="form-label">{this.i18n("live_video_label")}</label>
      <div className="row">
        <div className="col-md-6 d-flex flex-column">
          {this.renderAccessButtonToPreRecordedLiveModal()}
          <div className="form-text d-none d-sm-inline">{this.i18n("pre_recorded_video_picker_help")}</div>
        </div>
        <div className="col-md-6">{this.renderVideoPreview()}</div>
      </div>

      <Modal isOpen={preRecordedLiveModalOpen} onRequestClose={this.togglePreRecordedLiveModal} style={defaultModalStyles} contentLabel="Modal">
        <div className="modal-header">
          <h4 className="modal-title">
            {this.i18n("select_a_video")}
          </h4>
          <button type="button" onClick={this.togglePreRecordedLiveModal} className="btn-close" aria-label={I18n.t("close")}></button>
        </div>
        <div className="modal-body">
          {this.renderLiveFromVideoFileWarningMessage()}
          <PreRecordedLiveFilePicker
            streaming_archive_used_for_live_from_file_id={streaming_archive_used_for_live_from_file_id}
            onChangeSelectedArchive={this.updateField("streaming_archive_used_for_live_from_file_id")}
          />
        </div>
        <div className="modal-footer">
          <button className="btn btn-secondary" onClick={this.togglePreRecordedLiveModal}>
            {I18n.t("back")}
          </button>
          {this.renderSaveButton("btn-primary")}
        </div>
      </Modal>
    </div>;
  }

  renderRegistrationSection() {
    if (!isEnabled(REGISTRATION_QUOTAS)) return;

    const { capacityReachedWarningEmail, capacity } = this.state;
    return (
      <div>
        <LimitSelector
          value={capacity}
          onChange={this.updateLimitField("capacity")}
          controlLabel={this.i18n("capacity", true)}
          i18nRootPath="react.accesspoint.form"
        />
        <div className="mb-3">
          <label className="form-label">{this.i18n("capacity_reached_warning_email", true)}</label>
          <input type="email" className="form-control" value={capacityReachedWarningEmail} onChange={this.updateField("capacityReachedWarningEmail")} />
        </div>
        <HelpSection help={this.i18n("capacity_reached_warning_email_help")} />
      </div>
    );
  }

  renderTaxInputs(key, nameKey) {
    return (
      <div className="row">
        <div className="col-6">
          <label className="form-label">{this.i18n(key, true)}</label>
          <div className="input-group">
            <input className="form-control" value={this.state[key]} type="text" onChange={this.updateField(key)} />
            <div className="input-group-text">%</div>
          </div>
        </div>
        <div className="col-6">
          <label className="form-label">{this.i18n(nameKey, true)}</label>
          <input className="form-control" value={this.state[nameKey]} type="text" onChange={this.updateField(nameKey)} />
        </div>
      </div>
    );
  }

  renderPriceSection() {
    const { price, other_tax } = this.state;
    return (
      <div className="row">
        <div className="col-6">
          <div>
            <label className="form-label">{this.i18n("price", true)}</label>
            <input className="form-control" value={price} type="text" onChange={this.updateField("price")} />
            <div className="form-text">{I18n.t("price_including_taxes_help")}</div>
          </div>
        </div>
        <div className="col-6">
          {this.renderTaxInputs("tax", "tax_name")}
          {other_tax <= 0 && <div className="mt-10"><a href="#" onClick={this.triggerOtherTax}><i className="fa-regular fa-plus"></i> {this.i18n("add_another_tax")}</a></div>}
        </div>
        {other_tax > 0 && (
          <div className="col-6 offset-6">
            {this.renderTaxInputs("other_tax", "other_tax_name")}
          </div>
        )}
      </div>
    );
  }

  renderOccupancySettingsSection() {
    const { square_meters_area, max_occupancy } = this.state;

    return (
      <div>
        <LimitSelector
          value={max_occupancy}
          onChange={this.updateLimitField("max_occupancy")}
          controlLabel={this.i18n("max_occupancy", true)}
          i18nRootPath="react.accesspoint.form"
        />
        <HelpSection help={this.i18n("occupancy_help")} />

        <div className="mb-3">
          <label className="form-label">{this.i18n("square_meters_area", true)}</label>
          <div className="input-group" style={{ maxWidth: "150px" }}>
            <input type="number" className="form-control" value={square_meters_area} onChange={this.updateField("square_meters_area")} />
            <div className="input-group-text">m<sup>2</sup></div>
          </div>
          <div className="form-text">{this.i18n("square_meters_area_help")}</div>
        </div>

        <div>
          {this.renderCheckbox("always_allow_exit", null, this.i18n("always_allow_exit", true))}
          <div className="form-text">{this.i18n("always_allow_exit_help")}</div>
        </div>
      </div>
    );
  }

  renderInvitationSection() {
    const { invitationQuotaType, invitationQuotaNumber, invitationQuotaCategoryId } = this.state;

    const quotaTypeOptions = [
      { value: "invitations_quota", label: this.i18n("invitations_quota") },
      { value: "category_invitations_quota", label: this.i18n("category_invitations_quota") }
    ];

    return (
      <div>
        <div className="row align-items-end">
          <div className="col-md-4">
            <label htmlFor="invitationQuotaType" className="form-label">{this.i18n("invitations_quota_type", true)}</label>
            <select
              id="invitationQuotaType"
              className="form-select"
              value={invitationQuotaType}
              onChange={this.updateField("invitationQuotaType")}
            >
              <option value="">{this.i18n("select_placeholder")}</option>
              {quotaTypeOptions.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          {invitationQuotaType === "category_invitations_quota" && (
            <div className="col-md-6">
              <label htmlFor="invitationQuotaCategoryId" className="form-label">{this.i18n("invitations_quota_category_id", true)}</label>
              <GuestCategoryDropdown
                selectedValue={invitationQuotaCategoryId}
                onChange={this.creatableSelectOnChangeHandler("invitationQuotaCategoryId")}
              />
            </div>
          )}
          <div className="col-md-2">
            <label htmlFor="invitationQuotaCategoryId" className="form-label">{this.i18n("invitations_quota_number", true)}</label>
            <input type="number" className="form-control" value={invitationQuotaNumber} onChange={this.updateField("invitationQuotaNumber")} />
          </div>
        </div>
      </div>
    );
  }

  renderCheckinSection() {
    if (!isEnabled(CHECKIN)) return;

    const { checkinable } = this.state;
    const { defaultType } = this.props;

    return (
      <div>
        {defaultType != "checkin_point" &&
          <div className="mb-3">
            {this.renderCheckbox("checkinable", null, this.i18n("checkinable"))}
          </div>
        }
        {checkinable && <div>
          <div className="mb-3">
            {this.renderCheckbox("auto_register_guest_on_checkin", this.i18n("auto_register_guest_on_checkin", true), this.i18n("auto_register_guest_on_checkin_help"))}
          </div>
          {!this.isExitAccesspoint() && isEnabled(PAYMENT) && (
            <div className="mb-3">
              {this.renderCheckbox("allowGuestsWhoHaventPaidYet", this.i18n("allow_guests_who_havent_paid_yet", true), this.i18n("allow_guests_who_havent_paid_yet_help"))}
            </div>
          )}
          {this.renderMobileAppSection()}
          {this.renderAccesspointGates()}
        </div>}
      </div>
    );
  }

  renderPathSlugInputs() {
    const { type, website_path_slug, website_path_slug_translations } = this.state;
    const { event } = this.props;

    if (type !== "session") return null;

    return <TranslatableInput
      label={I18n.t("react.website.website_path_slug")}
      value={website_path_slug || ""}
      error={this.fieldError("website_path_slug")}
      availableLocales={event.available_frontend_locales}
      translationsLabel={I18n.t("react.website.website_path_slug_translations")}
      translationsValues={website_path_slug_translations}
      translationErrors={this.fieldError("website_path_slug_translations")}
      onChange={(website_path_slug) => this.setState({ website_path_slug })}
      help={I18n.t("slug_hint")}
      onTranslationChanged={(locale, value) => {
        this.setState({
          website_path_slug_translations: { ...website_path_slug_translations, [locale]: value }
        });
      }}
    />;
  }

  renderAdvancedSection() {
    const { uid, traits } = this.state;
    return (
      <div>
        <div className="mb-3">
          <label className="form-label">{this.i18n("uid", true)}</label>
          <input className="form-control" value={uid} type="text" onChange={this.updateField("uid")} />
        </div>
        {this.renderPathSlugInputs()}
        <div>
          <label className="form-label">{this.i18n("metadata")}</label>
          <MetadataForm traits={traits} updateParent={this.updateTraits} markDestroy={true} displayLegend={false} />
        </div>
      </div>
    );
  }

  renderCollapsableSection(title, icon, render, id, hidden) {
    if (hidden || (id === SECTION_CHECKIN && !isEnabled(CHECKIN)) || (id === SECTION_LIVE && !anyEnabled([STREAMING, WEBINAR, VIDEO_CONFERENCE]))) return null;

    const { displayedSections } = this.state;
    const displayed = displayedSections.includes(id);

    return <CollapsablePanel
      key={id}
      title={title}
      displayed={displayed}
      faIcon={icon}
      titleCentered={true}
    >
      {render.bind(this)()}
    </CollapsablePanel>;
  }

  render() {
    const { isEdit, accesspoint, isModal, event } = this.props;
    const { type, liveSessionType } = this.state;
    if (isEdit && isEmpty(accesspoint))
      return <Loader />;

    const exitAccesspoint = this.isExitAccesspoint();

    const blocks = [
      {
        title: this.i18n("information"),
        icon: "info-circle",
        render: this.renderInformationSection,
        id: SECTION_INFO,
        hidden: type === "accommodation"
      },
      {
        title: this.i18n("bundled_accesspoints"),
        icon: "cubes",
        render: this.renderBundleSection,
        id: SECTION_BUNDLE,
        hidden: type !== "bundle"
      },
      {
        title: type === "accommodation" ? this.i18n("night") : this.i18n("schedule"),
        icon: "calendar",
        render: this.renderProgramSection,
        id: SECTION_PROGRAM,
        hidden: exitAccesspoint || type === "bundle"
      },
      {
        title: this.i18n("live"),
        icon: "circle-play",
        render: this.renderLiveSection,
        id: SECTION_LIVE,
        hidden: exitAccesspoint || !["session", "meeting"].includes(type) || isModal
      },
      {
        title: this.i18n("replays"),
        icon: "clapperboard-play",
        render: this.renderReplaysSection,
        id: SECTION_REPLAYS,
        hidden: !["conference", "hls_broadcast", "streaming", "from_video_file", "webinar"].includes(liveSessionType) || isModal
      },
      {
        title: type === "accommodation" ? this.i18n("availabilities") : this.i18n("registration"),
        icon: "users",
        render: this.renderRegistrationSection,
        id: SECTION_REGISTRATION,
        hidden: exitAccesspoint || !isEnabled(REGISTRATION_QUOTAS)
      },
      {
        title: this.i18n("price"),
        icon: "money-bill-1",
        render: this.renderPriceSection,
        id: SECTION_PRICE,
        hidden: exitAccesspoint || type === "meeting" || !isEnabled(PAYMENT)
      },
      {
        title: this.i18n("other_information"),
        icon: "info-circle",
        render: this.renderInformationSection,
        id: SECTION_INFO,
        hidden: type !== "accommodation" // push down info section for accommodation only
      },
      {
        title: this.i18n("checkin"),
        icon: "scanner-gun",
        render: this.renderCheckinSection,
        id: SECTION_CHECKIN,
        hidden: type === "bundle"
      },
      {
        title: this.i18n("occupancy"),
        icon: "users",
        render: this.renderOccupancySettingsSection,
        id: SECTION_OCCUPANCY,
        hidden: !exitAccesspoint
      },
      {
        title: this.i18n("additional_quota"),
        icon: "id-badge",
        render: this.renderInvitationSection,
        id: SECTION_INVITATION,
        hidden: event && !event.use_new_computation_for_invitations_quota
      },
      {
        title: this.i18n("advanced"),
        icon: "wrench",
        render: this.renderAdvancedSection,
        id: SECTION_ADVANCED
      }
    ];

    return (
      <div className="row react-accesspoint-form">
        <div className={isModal ? "col-md-12" : "col-md-8 offset-md-2"}>
          {this.renderNotice()}
          {this.renderLiveFromVideoFileWarningMessage()}

          <div className="header-page">
            <div className="header-page-title">
              {this.renderTitle()}
            </div>
          </div>

          <form onSubmit={this.createOrUpdateAccesspoint} encType="multipart/form-data">
            {this.renderErrorsMessage()}
            {blocks.map(({ title, icon, render, id, hidden }) => this.renderCollapsableSection(title, icon, render, id, hidden))}
            {this.renderSaveButtons()}
            {this.renderCancelButton()}
          </form>
        </div>
      </div>
    );
  }

}

export default AccesspointForm;

AccesspointForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isModal: PropTypes.bool,
  errors: PropTypes.object,
  guestCategories: PropTypes.arrayOf(PropTypes.object)
};

AccesspointForm.defaultProps = {
  isModal: false,
  isEdit: false,
  customSessionTypes: [],
  customLocations: [],
  customRoomTypes: [],
  defaultState: {
    name: "",
    displayName: "",
    uid: "",
    website_path_slug: "",
    website_path_slug_translations: {},
    type: "checkin_point",
    checkinable: true,
    askForSignature: false,
    displayMessageUponCheckin: false,
    plusOneGuestCategoryId: null,
    plusOneEnabled: false,
    allowGuestsWhoHaventPaidYet: false,
    auto_register_guest_on_checkin: false,
    liveSessionType: "none",
    liveSessionChatModerationType: "none",
    archiveStreams: false,
    hlsBroadcastUrl: "",
    sessionType: "",
    sessionTypesOptions: [],
    thematic_ids: [],
    location: "",
    locationsOptions: [],
    roomType: "",
    roomTypesOptions: [],
    description: {},
    footer: null,
    illustration: null,
    illustrationMedium: null,
    footerUrl: null,
    traits: [],
    price: "0.0",
    tax: "0.0",
    tax_name: I18n.t("default_tax_name"),
    other_tax: "0.0",
    other_tax_name: "",
    capacity: "-1",
    capacityReachedWarningEmail: "",
    accesspointGates: [],
    activateTimeSlot: false,
    startDate: moment().format(I18n.t("datetime_picker_js_format")),
    startHour: 0,
    startMinute: 0,
    durationHour: 1,
    durationMinute: 0,
    momentStartDate: moment(),
    momentEndDate: moment(),
    speakers: [],
    exhibitors: [],
    moderators: [],
    max_occupancy: "-1",
    square_meters_area: null,
    streaming_archive_used_for_live_from_file_id: null,
    always_allow_exit: false,
    live_video_started_at: null,
    chat_in_replay_enabled: true,
    chat_in_replay_sync_offset: 0,
    liveSessionChatEnabled: true,
    liveInterventionRequestsEnabled: false,
    livePollsEnabled: false,
    liveSessionHasOnsiteAttendees: false,
    sessionRoomId: null,
    opentokResolution: "HD",
    inPersonLiveAccessButtonDisplayedOnWebsite: true
  }
};
