import { Component } from "react";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import { translatedTextFromJson, addTooltip } from "../../utils/templatesBuilderUtils";
import { DragTypes } from "../../constants/Constants";
import { OverlayTrigger, Collapse } from "react-bootstrap";

import Sortable from "../Sortable.react";
import TemplateSettings from "./TemplateSettings.react";
import VisibilitySettings from "./VisibilitySettings.react";

const DEFAULT_BLOCK_TYPE = "default_block_type";

class SectionView extends Component {
  constructor(props) {
    super(props);
    [
      "hideView",
      "handleDrop",
      "toggleBlockItem",
      "updateLocalConfiguration",
      "addNewBlockItem",
      "removeSection",
      "switchPageAndHideView"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      activeBlockItemKeyByBlockType: {}
    };

    this.localConfiguration = {};
    this.localChanges = {};
    // now in the blocks_order array, we can store the order of items in several different blocks
    this.blocksOrderByBlockType = {};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.configuration && nextProps.configuration) {
      this.localConfiguration = cloneDeep(nextProps.configuration);
      this.initBlocksOrderByBlockType();
    }
    // most recent changes have just been saved in DB -> we can clear local changes so they are not sent again
    if (this.props.hasChanges && !nextProps.hasChanges) {
      this.localChanges = {};
    }
  }

  initBlocksOrderByBlockType() {
    if (!this.localConfiguration || !this.localConfiguration["blocks_order"]) return;

    const blocks = this.localConfiguration["blocks"];
    if (!blocks) return;

    this.blocksOrderByBlockType = this.localConfiguration["blocks_order"].reduce((acc, blockItemKey) => {
      const item = blocks[blockItemKey];
      if (!item) return acc;

      const blockType = item["type"] || DEFAULT_BLOCK_TYPE;
      if (!acc[blockType]) acc[blockType] = [];
      acc[blockType].push(blockItemKey);
      return acc;
    }, {});
  }

  combineAllBlocksOrders() {
    return Object.values(this.blocksOrderByBlockType).flat(Infinity);
  }

  hideView(e) {
    const { hide, sendChanges, sectionKey, highlightBlockInIframe } = this.props;
    e.preventDefault();
    hide();
    if (highlightBlockInIframe) {
      highlightBlockInIframe(null);
    }

    sendChanges(sectionKey, this.localConfiguration, this.localChanges);
    this.localChanges = {};
    this.setState({ activeBlockItemKeyByBlockType: {} });
  }

  switchPageAndHideView(page, previousListPage = null) {
    const { switchPage } = this.props;

    return (e) => {
      this.hideView(e);
      switchPage(page, previousListPage)(e);
    };
  }

  handleDrop(blockType) {
    return (_previousItemId, _id, _nextItemId, _estimatedIndex, itemsByIndex) => {
      const { detectChanges, sendChanges, sectionKey } = this.props;
      const blocksForSections = itemsByIndex.map(object => {
        return object.item.sortableItemId;
      });
      this.blocksOrderByBlockType[blockType] = blocksForSections;
      this.localChanges["blocks_order"] = this.combineAllBlocksOrders();
      sendChanges(sectionKey, this.localConfiguration, this.localChanges);
      detectChanges();
    };
  }

  toggleBlockItem(key, blockType) {
    return () => {
      const { activeBlockItemKeyByBlockType } = this.state;
      const newBlockItemKey = activeBlockItemKeyByBlockType[blockType] === key ? null : key;
      const { highlightBlockInIframe } = this.props;

      if (highlightBlockInIframe) {
        highlightBlockInIframe(newBlockItemKey);
      }
      this.setState({ activeBlockItemKeyByBlockType: { ...activeBlockItemKeyByBlockType, [blockType]: newBlockItemKey } });
    };
  }

  updateLocalConfiguration(key, value, blockItemKey) {
    const { detectChanges, sendChanges, sectionKey } = this.props;
    if (!blockItemKey) {
      this.localConfiguration["settings"][key] = value;
      set(this.localChanges, `settings.${key}`, value);
    } else {
      this.localConfiguration["blocks"][blockItemKey]["settings"][key] = value;
      set(this.localChanges, `blocks.${blockItemKey}.settings.${key}`, value);
    }

    /* when calling hideView, updateLocalConfiguration is also called if RTE was focused
       hiding sectionView resets props => sendChanges and detectChanges are undefined */
    if (sendChanges && detectChanges) {
      sendChanges(sectionKey, this.localConfiguration, this.localChanges);
      detectChanges();
    }
  }

  addNewBlockItem(block) {
    const { sendChanges, detectChanges, sectionKey } = this.props;
    const { activeBlockItemKeyByBlockType } = this.state;

    if (!this.localConfiguration.blocks)
      this.localConfiguration.blocks = {};

    let randomBlockId;
    do {
      randomBlockId = "bloc-" + Math.floor(Math.random() * 1000);
    } while (this.localConfiguration.blocks[randomBlockId]);

    const defaultBlockSettings = block.settings.reduce((acc, component) => {
      acc[component.id] = translatedTextFromJson(component, "default");
      if (acc[component.id] === undefined) acc[component.id] = "";
      return acc;
    }, {});

    const blockData = { type: block.type, settings: defaultBlockSettings };
    this.localConfiguration["blocks"][randomBlockId] = blockData;

    set(this.localChanges, `blocks.${randomBlockId}`, blockData);

    if (!this.blocksOrderByBlockType[block.type]) {
      this.blocksOrderByBlockType[block.type] = [];
    }
    this.blocksOrderByBlockType[block.type].push(randomBlockId);
    this.localChanges["blocks_order"] = this.combineAllBlocksOrders();
    sendChanges(sectionKey, this.localConfiguration, this.localChanges);
    detectChanges();
    this.setState({ activeBlockItemKeyByBlockType: { ...activeBlockItemKeyByBlockType, [block.type]: randomBlockId } });
  }

  removeBlockItem(blockItemKey, blockType) {
    const { sendChanges, detectChanges, sectionKey, removeBlock } = this.props;
    return () => {
      if (confirm(I18n.t("confirm"))) {
        delete this.localConfiguration["blocks"][blockItemKey];
        if (this.localChanges["blocks"]) {
          delete this.localChanges["blocks"][blockItemKey];
        }
        this.blocksOrderByBlockType[blockType].splice(this.blocksOrderByBlockType[blockType].indexOf(blockItemKey), 1);
        this.localChanges["blocks_order"] = this.combineAllBlocksOrders();

        sendChanges(sectionKey, this.localConfiguration, this.localChanges);
        removeBlock(blockItemKey, sectionKey);
        detectChanges();

        const { activeBlockItemKeyByBlockType } = this.state;
        this.setState({ activeBlockItemKeyByBlockType: { ...activeBlockItemKeyByBlockType, [blockType]: null } });
      }
    };
  }

  removeSection() {
    const { removeSection } = this.props;
    if (confirm(I18n.t("confirm")))
      removeSection();
  }

  renderSectionSettings() {
    const { schema, guestCategories, menus, segments, page, liquidTemplates, accountId, guestFields, documentNames,
      accesspointsTraits, accesspointsSessionTypes, websitePages, sectionLocked, clearErrors, builderType,
      updateLiquidTemplate, createLiquidTemplate, liquidTemplatePendingRequest, liquidTemplateErrors, fullGuestCategories,
      reloadPreview, submitPage, templateId, saveBuilder,
      event, guestCategoriesByPopulation, assets } = this.props;

    return (
      <div>
        <h5>{ I18n.t("react.website.settings") }</h5>
        <div className="card">
          <TemplateSettings settingsData={this.localConfiguration.settings}
            settingComponentsSchema={schema.settings || []}
            notifyChange={this.updateLocalConfiguration}
            guestCategories={guestCategories}
            fullGuestCategories={fullGuestCategories}
            guestCategoriesByPopulation={guestCategoriesByPopulation}
            menus={menus}
            documentNames={documentNames}
            accesspointsTraits={accesspointsTraits}
            accesspointsSessionTypes={accesspointsSessionTypes}
            websitePages={websitePages}
            guestFields={guestFields}
            segments={segments}
            sectionLocked={sectionLocked}
            page={page}
            switchPageAndHideView={this.switchPageAndHideView}
            submitPage={submitPage}
            reloadPreview={reloadPreview}
            liquidTemplates={liquidTemplates}
            updateLiquidTemplate={updateLiquidTemplate}
            createLiquidTemplate={createLiquidTemplate}
            liquidTemplatePendingRequest={liquidTemplatePendingRequest}
            liquidTemplateErrors={liquidTemplateErrors}
            builderType={builderType}
            clearErrors={clearErrors}
            accountId={accountId}
            templateId={templateId}
            saveBuilder={saveBuilder}
            event={event}
            assets={assets} />
        </div>
      </div>
    );
  }

  renderVisibilitySettings() {
    const { builderType, segments, sectionLocked, schema, noVisibilitySettings } = this.props;

    return <VisibilitySettings
      settingsData={this.localConfiguration.settings}
      settingComponentsSchema={schema.settings || []}
      builderType={builderType}
      segments={segments}
      sectionLocked={sectionLocked}
      noVisibilitySettings={noVisibilitySettings}
      notifyChange={this.updateLocalConfiguration}
    />;
  }

  helpMessage(componentConfig) {
    if (componentConfig.info) {
      return <div dangerouslySetInnerHTML={{ __html: translatedTextFromJson(componentConfig, "info") }} className="form-text" />;
    }
  }

  renderBlock(block) {
    const { schema } = this.props;

    const displayProperty = block.display_property || "title";
    const blockName = translatedTextFromJson(block, "name") || I18n.t("react.website.blocks");
    const addBlockLabel = translatedTextFromJson(block, "add_block") || I18n.t("react.website.new_block");
    const removeBlockLabel = translatedTextFromJson(block, "remove_block") || I18n.t("react.website.remove_block");

    const blockItems = (this.blocksOrderByBlockType[block.type] || []).map((blockId, index) =>
      this.defineBlockItem(blockId, displayProperty, index)
    ).filter(b => b);

    const blockItemsHtml = blockItems.map(item => {
      return (
        <div key={item["sortableItemId"]}>
          <li className="list-group-item" key={item["sortableItemId"]} onClick={this.toggleBlockItem(item["sortableItemId"], block.type)}>
            <span><i className="fa-solid fa-grip-dots-vertical mr-5"></i></span> {item.name}
          </li>
          <Collapse in={this.state.activeBlockItemKeyByBlockType[block.type] === item["sortableItemId"]} onDragStart={(e) => {e.stopPropagation(); e.preventDefault();}} draggable >
            <div>
              { this.renderBlockSettings(block, item["sortableItemId"]) }
              <div className="card-body">
                <span className="btn btn-danger d-grid" onClick={this.removeBlockItem(item["sortableItemId"], block.type)}>{ removeBlockLabel }</span>
              </div>
            </div>
          </Collapse>
        </div>
      );
    });

    const maxBlocks = block.max_blocks || schema.max_blocks;
    const maxBlocksHtml = maxBlocks ? <small>- Max : { maxBlocks }</small> : "";

    const blocksFooter = (block.type !== DEFAULT_BLOCK_TYPE && (!maxBlocks || (this.blocksOrderByBlockType[block.type] || []).length < maxBlocks)) ?
      <div className="card-footer">
        <a onClick={ () => this.addNewBlockItem(block) }>
          <i className="fa-regular fa-plus-circle"></i> { addBlockLabel }
        </a>
      </div>
      : null;

    return (
      <div key={blockName}>
        <h5>{ blockName } { maxBlocksHtml }</h5>
        { this.helpMessage(block) }
        <div className="card">
          {blockItems.length > 0 &&
            <div className="list-group">
              <Sortable itemIdKey="sortableItemId"
                itemIndexKey="rank"
                dragType={DragTypes.SECTION_BLOCKS}
                dragTypeSuffix={block.type}
                items={blockItems}
                handleDrop={this.handleDrop(block.type)}
                fullyDraggable={true}>
                {blockItemsHtml}
              </Sortable>
            </div>
          }
          { blocksFooter }
        </div>
      </div>
    );
  }

  renderBlocks() {
    const { schema } = this.props;

    if (!schema.blocks || isEmpty(schema.blocks)) {
      return;
    }

    if (!this.localConfiguration.blocks_order)
      this.localConfiguration.blocks_order = [];

    const elements = schema.blocks.map(block => {
      return this.renderBlock(block);
    });
    // Sometimes we don't have a type for an block item, which means that it will be displayed on the website but not in the builder.
    // If this happens, we add them to a new block so that they can be deleted easily from the builder.
    if (this.blocksOrderByBlockType[DEFAULT_BLOCK_TYPE] && this.blocksOrderByBlockType[DEFAULT_BLOCK_TYPE].length > 0) {
      elements.push(this.renderBlock({ type: DEFAULT_BLOCK_TYPE }));
    }
    return elements;
  }

  findGuestProductCollectionField(collectionFieldId) {
    const { guestProductCollections } = this.props;
    if (!guestProductCollections || !collectionFieldId) return null;

    let foundField = null;
    guestProductCollections.some(collection => {
      const field = collection.collection_fields && collection.collection_fields.find(collectionField => collectionField._id === collectionFieldId);
      if (field) {
        foundField = field;
        return true;
      }
    });

    return foundField;
  }

  defineBlockItem(blockId, displayProperty, defaultRank) {
    if (!this.localConfiguration.blocks) return null;
    if (!this.localConfiguration.blocks[blockId]) return null;

    const { settings } = this.localConfiguration.blocks[blockId];
    let blockItemName = "";

    if (settings.button_type === "registration") {
      if (settings.force_new_registration) {
        blockItemName = settings.button_label_force_new_registration;
      } else if (settings.unknown_visitors_registration) {
        blockItemName = settings.button_label_unknown_visitor_registration;
      } else if (settings.confirmation_page_known_visitors) {
        blockItemName = settings.button_label_confirmation_page_known_visitors;
      } else if (settings.change_known_visitors_category) {
        blockItemName = settings.button_label_known_visitors;
      }
    } else if (displayProperty === "collection_field") {
      const field = this.findGuestProductCollectionField(settings.collection_field);
      if (field) blockItemName = field.name;
    } else if (displayProperty === "collection") {
      const collection = this.props.guestProductCollections.find(collection => collection._id === settings.collection_id);
      if (collection) blockItemName = collection.name;
    }

    return {
      name: blockItemName || settings[displayProperty] || blockId, rank: defaultRank, sortableItemId: blockId
    };
  }

  renderBlockSettings(block, blockItemKey) {
    const { schema, guestFields, websitePages, documentNames, guestCategories, accesspointsTraits,
      builderType, templateId, page, submitPage, saveBuilder, event, assets, menus,
      segments, sectionLocked, displayBlocksVisibilitySettings } = this.props;

    if (!schema.blocks || isEmpty(schema.blocks) || isEmpty(block.settings)) {
      return;
    }

    return <>
      <TemplateSettings settingsData={this.localConfiguration.blocks[blockItemKey].settings}
        settingComponentsSchema={block.settings}
        notifyChange={this.updateLocalConfiguration}
        blockKey={blockItemKey}
        guestFields={guestFields}
        guestCategories={guestCategories}
        websitePages={websitePages}
        documentNames={documentNames}
        accesspointsTraits={accesspointsTraits}
        page={page}
        switchPageAndHideView={this.switchPageAndHideView}
        submitPage={submitPage}
        builderType={builderType}
        templateId={templateId}
        saveBuilder={saveBuilder}
        event={event}
        assets={assets}
        menus={menus}
        globalSettingsData={this.localConfiguration.settings}
      />
      <VisibilitySettings
        settingsData={this.localConfiguration.blocks[blockItemKey].settings}
        settingComponentsSchema={block.settings}
        builderType={builderType}
        segments={segments}
        sectionLocked={sectionLocked}
        noVisibilitySettings={false}
        displayBlocksVisibilitySettings={displayBlocksVisibilitySettings}
        blockKey={blockItemKey}
        notifyChange={this.updateLocalConfiguration}
      />
    </>;
  }

  renderDeleteSectionButton() {
    const { deletable } = this.props;
    if (deletable) {
      return (
        <p><span className="btn btn-danger d-grid" onClick={ this.removeSection }>{ I18n.t("react.website.remove_section") }</span></p>
      );
    }
  }

  renderTranslationsButton() {
    const { sectionKey, displayTranslationsPane, translationTableId } = this.props;
    if (!displayTranslationsPane || !translationTableId) return null;

    let translationTableName = "";
    if (["header", "footer"].includes(sectionKey)) {
      translationTableName = "website_layouts";
    } else if (sectionKey === "app_home_screen") {
      translationTableName = "app_home_screen_config";
    } else {
      return null;
    }

    return <OverlayTrigger placement="bottom" overlay={addTooltip(I18n.t("react.website.translate"))}>
      <a onClick={e => displayTranslationsPane(e, translationTableId, translationTableName)}
        style={{ paddingLeft: "8px", paddingRight: "8px" }}
        className="btn btn-secondary btn-sm float-end"
      >
        <i className="fa-square fa-regular fa-language fa-fw"></i>
      </a>
    </OverlayTrigger>;
  }

  render() {
    const { schema, sectionKey, withSidebar } = this.props;
    const componentClasses = classNames({
      "website-section-edit": true,
      "in": !!sectionKey,
      "no-sidebar": !withSidebar
    });

    // we need to initialize an empty component when key is null for the animation to actually work when key not null
    if (!sectionKey) {
      return (
        <div className={componentClasses}></div>
      );
    }

    return (
      <div className={componentClasses}>
        <div className="sidebar-header">
          { this.renderTranslationsButton() }
          <p className="lead">
            <a onClick={ this.hideView } className="btn-back mr-5 text-center"><i className="fa-regular fa-angle-left"></i></a>
            { translatedTextFromJson(schema, "name") }
          </p>
        </div>

        <div className="sidebar-body">
          { this.renderSectionSettings() }
          { this.renderBlocks() }
          { this.renderVisibilitySettings() }
          { this.renderDeleteSectionButton() }
        </div>
      </div>
    );
  }
}

export default SectionView;
