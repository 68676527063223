import { ActionTypes } from "../constants/Constants";

module.exports = {
  setSelectedSSOStatuses(objectIds) {
    return {
      type: ActionTypes.SET_SELECTED_SSO_STATUSES,
      objectIds
    };
  }

};
