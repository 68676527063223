import { Component } from "react";
import { connect } from "react-redux";
import requiresProps from "../../utils/requiresProps";
import { urlWithQuery, urlEventId } from "../../utils/pathUtils";
import Icons from "../../constants/Icons";
import { guestCountFromQS, countGuestsInQS } from "../../actions/SearchQueryActionCreators";
import {
  createGuestCampaign,
  fetchGuestCampaign,
  clearGuestCampaignStore,
  updateGuestCampaign,
  sendTestEmail,
  deliverGuestCampaign
} from "../../actions/GuestCampaignActionCreators";
import { fetchWebsitePages } from "../../actions/WebsitePagesActionCreators";
import { fetchWebsite } from "../../actions/WebsiteActionCreators";
import { fetchSavedSearches } from "../../actions/SavedSearchActionCreators";
import { showNotice } from "../../actions/NoticeActionCreators";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import GuestCampaignForm from "../../components/guest_campaign/GuestCampaignForm.react";
import { redirectIfUnauthorized, isAuthorized } from "../../utils/aclUtils";
import MessageModal from "../../components/MessageModal.react";
import querystring from "querystring";
import Loader from "../../components/shared/Loader.react";
import isEmpty from "lodash/isEmpty";
import { Modal } from "react-bootstrap";
import TranslationTable from "../translations/TranslationTable.react";
import NoticeBlock from "../../components/templates_builder/NoticeBlock.react";
import SlidingPane from "react-sliding-pane";

class GuestCampaign extends Component {

  constructor(props) {
    redirectIfUnauthorized("campaigns", "manage");
    super(props);
    const privateMethods = [
      "onSelectedSavedSearchChange",
      "onSaveGuestCampaign",
      "renderDeliveryResult",
      "cancelDeliveryCampaign",
      "deliverCampaign",
      "displayTranslationsPane",
      "hideTranslationsPane"
    ];
    privateMethods.forEach((item) => { this[item] = this[item].bind(this); });
    this.isStartSendingCampaign = false;
    this.state = {
      isShowDeliveryResultModal: false,
      isShowConfirmationDialog: false,
      isShowTranslationsPane: false,
      currentTranslatableType: "",
      currentTranslationTableId: null
    };
  }

  componentDidUpdate(prevProps) {
    const { errors, isPending, allRequirementsMet } = this.props;
    const { isPending: prevPending } = prevProps;
    const isEndOfCallApiWithRecoveryOfErrors = !isPending && prevPending;

    if (isEndOfCallApiWithRecoveryOfErrors) {
      if (errors && errors.length != 0)
        $("html, body").stop().animate({ scrollTop: 0 }, 1000, "swing");
      else if (allRequirementsMet && this.isStartSendingCampaign) {
        this.setState({
          isShowConfirmationDialog: true
        });
      }
    }

    const { isDelivering } = this.props;
    const { isDelivering: prevIsDelivering } = prevProps;
    const isEndOfDelivery = !isDelivering && prevIsDelivering;
    const { isShowDeliveryResultModal } = this.state;
    if (isEndOfDelivery && !isShowDeliveryResultModal) {
      this.setState({
        isShowDeliveryResultModal: true
      });
    }
  }

  urlGuestCampaignsList() {
    return urlWithQuery("", "campaigns");
  }

  onSaveGuestCampaign(guestCampaign, isStartSendingCampaign, urlRedirect = null) {
    const { match, updateGuestCampaign, createGuestCampaign } = this.props;
    const { event_id } = match.params;
    const id = match.params.guest_campaign_id;
    this.isStartSendingCampaign = isStartSendingCampaign;
    if (id) {
      updateGuestCampaign(event_id, id, guestCampaign, false, urlRedirect);
    } else {
      createGuestCampaign(event_id, guestCampaign, urlRedirect);
    }
  }

  urlGuestCampaignEdit(id) {
    return urlWithQuery("", `campaign/${id}/edit`);
  }

  cancelDeliveryCampaign() {
    const { guestCampaign, history } = this.props;

    this.isStartSendingCampaign = false;
    this.setState({
      isShowConfirmationDialog: false
    });

    history.push(this.urlGuestCampaignEdit(guestCampaign._id));
  }

  deliverCampaign() {
    const { deliverGuestCampaign, match, guestCampaign } = this.props;
    const { event_id } = match.params;

    this.setState({
      isShowConfirmationDialog: false
    });

    deliverGuestCampaign(
      event_id,
      guestCampaign._id,
      null,
      null,
      match.params.locale);
  }

  onSelectedSavedSearchChange(currentSavedSearchQuery) {
    const { guestCountFromQS, countGuestsInQS } = this.props;
    const medium = this.campaignMedium();

    if (medium == "use_email") {
      guestCountFromQS(`${currentSavedSearchQuery} -email:null campaign_optin:true`, true);
      countGuestsInQS(`${currentSavedSearchQuery} no:email`, true, "guestWithoutEmailAddressCount");
      countGuestsInQS(`${currentSavedSearchQuery} campaign_optin:false`, true, "guestWithoutCampaignOptin");
    } else if (medium == "use_sms") {
      guestCountFromQS(`${currentSavedSearchQuery} -phone_number:null campaign_optin:true`, true);
      countGuestsInQS(`${currentSavedSearchQuery} no:phone_number`, true, "guestWithoutPhoneNumber");
      countGuestsInQS(`${currentSavedSearchQuery} campaign_optin:false`, true, "guestWithoutCampaignOptin");
    } else {
      guestCountFromQS(currentSavedSearchQuery, true);
    }
  }

  displayTranslationsPane(e, currentTranslatableType) {
    e && e.preventDefault();
    const { guestCampaign } = this.props;
    let currentTranslationTableId = "";

    if (currentTranslatableType == "guest_campaigns")
      currentTranslationTableId = guestCampaign._id;
    else if (currentTranslatableType == "email_templates")
      currentTranslationTableId = guestCampaign.email_template_id;

    this.setState({
      isShowTranslationsPane: true,
      currentTranslatableType,
      currentTranslationTableId,
      isShowConfirmationDialog: false
    });
  }

  hideTranslationsPane(e) {
    e && e.preventDefault();
    this.setState({
      isShowTranslationsPane: false,
      currentTranslatableType: "",
      currentTranslationTableId: null,
      isShowConfirmationDialog: true
    });
  }

  renderTranslations() {
    const { match, location, history, notice, noticeType } = this.props;
    const { isShowTranslationsPane, currentTranslatableType, currentTranslationTableId } = this.state;

    return isShowTranslationsPane && <SlidingPane
      isOpen={true}
      title={I18n.t("react.registration_form.translations_title")}
      from="right"
      width='90%'
      onRequestClose={this.hideTranslationsPane}
      className="width-100-xs width-90-sm"
      closeIcon={Icons.close()}
    >
      <div style={{ padding: "0 20px 20px" }}>
        <NoticeBlock notice={notice} noticeType={noticeType} />
        <TranslationTable
          match={match}
          location={location}
          history={history}
          type={currentTranslatableType}
          eventId={urlEventId()}
          id={currentTranslationTableId}
          inSlidingPane={true}
          onlySimpleValidateButton={true}
        />
      </div>
    </SlidingPane>;
  }

  renderDeliveryResult() {
    const { isShowDeliveryResultModal } = this.state;
    const { deliveryError, guestCampaign, history } = this.props;
    let iconClassName = "";
    let iconStyle = {};
    let message = undefined;

    if (deliveryError) {
      iconClassName = "fa-regular fa-circle-xmark fa-4x text-danger";
      iconStyle = { color: "crimson" };
      message = (
        <div>
          <strong>{ I18n.t("react.guest_campaign.response_delivry.error") }</strong>
          <br />
          {deliveryError}
        </div>
      );
    } else if (guestCampaign.scheduled_at) {
      iconClassName = "fa-regular fa-clock fa-4x fa-rotate-90";
      iconStyle = { color: "dodgerblue", WebkitTextStroke: "2px white" };
      message = (
        <strong>
          { I18n.t("react.guest_campaign.summary.campaign_scheduled_notice") }
        </strong>
      );
    } else {
      iconClassName = "fa-regular fa-check fa-4x text-success";
      iconStyle = { WebkitTextStroke: "2px white" };
      message = (
        <strong>{ I18n.t("react.guest_campaign.summary.campaign_sent_notice") }</strong>
      );
    }


    return <MessageModal
      isOpen={isShowDeliveryResultModal}
      iconClassName={iconClassName}
      iconStyle={iconStyle}
      message={message}
      onRequestClose={() => {
        this.setState({
          isShowDeliveryResultModal: false
        });
        history.push(this.urlGuestCampaignsList());
      }}
    />;

  }

  campaignMedium() {
    const { guestCampaign } = this.props;
    const campaignMedium = ["use_email", "use_sms", "use_message"].find(key => guestCampaign[key]);

    if (!campaignMedium)
      return this.initialMedium();

    return campaignMedium;
  }

  initialMedium() {
    const { location } = this.props;
    const q = querystring.parse(location.search.substring(1));

    return q.medium;
  }

  renderConfirmationDialogMessage() {
    const { guestCampaign, guestCount, guestWithoutCampaignOptin } = this.props;
    const { scheduled_at } = guestCampaign;
    const i18nKey = scheduled_at ? "warning_message_scheduled" : "warning_message";
    const totalGuestCount = guestCampaign.send_to_optout ? guestWithoutCampaignOptin + guestCount : guestCount;
    const htmlMessage = <div dangerouslySetInnerHTML={{ __html: I18n.t(`react.guest_campaign.confirmation.${i18nKey}`, { count: totalGuestCount }) }} />;

    return (
      <Modal.Body className="text-center">
        {htmlMessage}
      </Modal.Body>
    );
  }

  submitButtonDisabled() {
    const { guestCampaign, guestCount } = this.props;
    const { scheduled_at } = guestCampaign;

    if (scheduled_at) return false;
    if (guestCampaign.send_to_optout) return false;
    return guestCount <= 0;
  }

  renderConfirmationDialog() {
    const { isShowConfirmationDialog } = this.state;
    const { event, guestCampaign } = this.props;
    const { scheduled_at, use_email } = guestCampaign;
    let translationBlock = null;

    if (event && event.available_frontend_locales && event.available_frontend_locales.length > 0) {
      translationBlock = <Modal.Body>
        <p>{ I18n.t("react.guest_campaign.confirmation.translation_block") }</p>
        <button type="button" className="btn btn-secondary btn-sm" onClick={e => this.displayTranslationsPane(e, "guest_campaigns")} style={{ marginRight: "5px" }}>
          { I18n.t("react.guest_campaign.confirmation.translate_campaign") }
        </button>
        {
          use_email && <button type="button" className="btn btn-secondary btn-sm" onClick={e => this.displayTranslationsPane(e, "email_templates")}>
            { I18n.t("react.guest_campaign.confirmation.translate_email_template") }
          </button>
        }
      </Modal.Body>;
    }

    const labelButtonOk = scheduled_at ? I18n.t("react.guest_campaign.schedule_campaign") : I18n.t("react.guest_campaign.send_campaign");
    const modalTitle = scheduled_at ? I18n.t("react.guest_campaign.confirmation.scheduled_title") : I18n.t("react.guest_campaign.confirmation.title");
    const submitButtonDisabled = this.submitButtonDisabled();

    return (
      <Modal show={isShowConfirmationDialog} onHide={this.cancelDeliveryCampaign} >
        <Modal.Header>
          <h4 className="modal-title"><i className="fa-regular fa-question-circle" /> { modalTitle }</h4>
          <button type="button" onClick={ this.cancelDeliveryCampaign } className="btn-close" aria-label={I18n.t("close")}></button>
        </Modal.Header>
        {this.renderConfirmationDialogMessage()}
        {translationBlock}
        <Modal.Footer>
          <button className="btn btn-secondary float-start" onClick={this.cancelDeliveryCampaign}>
            <i className="fa-regular fa-pen-to-square" /> { I18n.t("cancel") }
          </button>
          <button className="btn btn-primary" onClick={this.deliverCampaign} autoFocus disabled={submitButtonDisabled}>
            <i className={"fa-regular fa-paper-plane"} /> {labelButtonOk}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    if (!isAuthorized("campaigns", "manage")) return;
    const { errors, guestCampaign, savedSearches, guestWithoutEmailAddressCount, event, guestCount,
      showNotice, sendTestEmail, isPending, match, location, history, guestWithoutPhoneNumber,
      guestWithoutCampaignOptin, websitePages, website } = this.props;

    const isEdit = !!match.params.guest_campaign_id;

    if (isEdit && isEmpty(guestCampaign)) {
      return <Loader />;
    }
    const errorsParams = (!errors || errors.length == 0 ? {} : errors);
    return (
      <div className="row mb-30">
        <div className="col-md-8 offset-md-2">
          <ErrorMessage errors={errorsParams} model="guest_campaign" />
          <GuestCampaignForm
            guestCampaign={guestCampaign}
            websitePages={websitePages}
            website={website}
            savedSearches={savedSearches}
            event={event}
            guestCount={guestCount}
            guestWithoutEmailAddressCount={guestWithoutEmailAddressCount}
            guestWithoutPhoneNumber={guestWithoutPhoneNumber}
            guestWithoutCampaignOptin={guestWithoutCampaignOptin}
            onSaveGuestCampaign={this.onSaveGuestCampaign}
            onCancelGuestCampaign={this.onCancelGuestCampaign}
            onSelectedSavedSearchChange={this.onSelectedSavedSearchChange}
            showNotice={showNotice}
            sendTestEmail={sendTestEmail}
            isPending={isPending}
            initialMedium={this.initialMedium()}
            editMode={isEdit}
            match={match}
            location={location}
            history={history}
          />
          { this.renderDeliveryResult() }
          { this.renderConfirmationDialog() }
          { this.renderTranslations() }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errors: state.guestCampaign.errors,
    isPending: state.guestCampaign.isUpdating,
    isDelivering: state.guestCampaign.pendingRequest,
    deliveryError: state.guestCampaign.deliveryError,
    selectedTemplateId: state.emailTemplate.selectedTemplateId,
    notice: state.notifications.currentNotice,
    noticeType: state.notifications.noticeType
  };
}

const mapDispatchToProps = {
  fetchGuestCampaign,
  clearGuestCampaignStore,
  fetchSavedSearches,
  createGuestCampaign,
  updateGuestCampaign,
  guestCountFromQS,
  countGuestsInQS,
  sendTestEmail,
  showNotice,
  deliverGuestCampaign,
  fetchWebsitePages,
  fetchWebsite
};

export default connect(mapStateToProps, mapDispatchToProps)(
  requiresProps(GuestCampaign, {
    requirements: {
      savedSearches: {
        fn: (props) => {
          const { fetchSavedSearches, match } = props;
          fetchSavedSearches(match.params.event_id);
        },
        options: {
          force: true
        },
        statePath: "savedSearches.data"
      },
      event: {
        fn: () => {},
        desiredState: "has_underscore_id"
      },
      website: {
        waitFor: ["event"],
        fn: (props) => {
          const { fetchWebsite, event } = props;
          if (event.website_enabled) {
            fetchWebsite(event._id);
          }
        },
        options: {
          force: true
        }
      },
      websitePages: {
        waitFor: ["event"],
        fn: (props) => {
          const { fetchWebsitePages, event } = props;
          if (event.website_enabled) {
            fetchWebsitePages(event._id);
          }
        },
        options: {
          force: true
        }
      },
      guestCampaign: {
        fn: (props) => {
          const { match, fetchGuestCampaign, clearGuestCampaignStore } = props;
          clearGuestCampaignStore();
          const { guest_campaign_id, event_id } = match.params;
          if (guest_campaign_id) {
            fetchGuestCampaign(event_id, guest_campaign_id);
          } else if (!guest_campaign_id) {
            return { cancelled: true };
          }
        },
        statePath: "guestCampaign.guestCampaign",
        options: {
          force: true
        }
      },
      guestCount: {
        waitFor: ["guestCampaign", "savedSearches"],
        fn: (props) => {
          const { guestCampaign, savedSearches, match, guestCountFromQS } = props;

          if (!match.params.guest_campaign_id) return { cancelled: true };

          const { saved_search_id } = guestCampaign;
          const savedSearch = saved_search_id && savedSearches.find(el => el._id === saved_search_id);

          if (!savedSearch) return { cancelled: true };

          if (guestCampaign.use_email) {
            guestCountFromQS(`${savedSearch.search_query} -email:null campaign_optin:true`, true);
          } else if (guestCampaign.use_sms) {
            guestCountFromQS(`${savedSearch.search_query} -phone_number:null campaign_optin:true`, true);
          } else if (guestCampaign.use_message) {
            guestCountFromQS(savedSearch.search_query, true);
          } else {
            // we can't know for sure to how many people a web push campaign will be sent, so we ignore this case
            return { cancelled: true };
          }
        },
        statePath: "appearance.selectedGuestCountAll",
        options: {
          force: true
        }
      },
      guestWithoutEmailAddressCount: {
        waitFor: ["savedSearches", "guestCampaign"],
        fn: (props) => {
          const { guestCampaign, savedSearches, match, countGuestsInQS } = props;
          if (match.params.guest_campaign_id && guestCampaign.saved_search_id && guestCampaign.use_email) {
            const savedSearch = savedSearches.find(el => el._id === guestCampaign.saved_search_id);
            countGuestsInQS(`${savedSearch.search_query} no:email`, true, "guestWithoutEmailAddressCount");
          } else {
            return { cancelled: true };
          }
        },
        options: {
          force: true
        },
        statePath: "appearance.guestWithoutEmailAddressCount"
      },
      guestWithoutCampaignOptin: {
        waitFor: ["savedSearches", "guestCampaign"],
        fn: (props) => {
          const { guestCampaign, savedSearches, match, countGuestsInQS } = props;
          if (match.params.guest_campaign_id && guestCampaign.saved_search_id && (guestCampaign.use_email || guestCampaign.use_sms)) {
            const savedSearch = savedSearches.find(el => el._id === guestCampaign.saved_search_id);
            countGuestsInQS(`${savedSearch.search_query} campaign_optin:false`, true, "guestWithoutCampaignOptin");
          } else {
            return { cancelled: true };
          }
        },
        options: {
          force: true
        },
        statePath: "appearance.guestWithoutCampaignOptin"
      },
      guestWithoutPhoneNumber: {
        waitFor: ["savedSearches", "guestCampaign"],
        fn: (props) => {
          const { guestCampaign, savedSearches, match, countGuestsInQS } = props;
          if (match.params.guest_campaign_id && guestCampaign.saved_search_id && guestCampaign.use_sms) {
            const savedSearch = savedSearches.find(el => el._id === guestCampaign.saved_search_id);
            countGuestsInQS(`${savedSearch.search_query} no:phone_number`, true, "guestWithoutPhoneNumber");
          } else {
            return { cancelled: true };
          }
        },
        options: {
          force: true
        },
        statePath: "appearance.guestWithoutPhoneNumber"
      }
    }
  })
);
