"use strict";
import { Component } from "react";
import PropTypes from "prop-types";

class Preview extends Component {
  constructor(props) {
    super(props);
    this.renderElement = this.renderElement.bind(this);
  }

  renderElement(fieldName, field, icon, cssClass = "") {
    if (!field) {
      return null;
    }
    const spaceBefore = I18n.locale == "fr" ? " " : null;
    return (
      <div className="element">
        <i className={`fa-regular fa-${icon}`}></i>
        <span> { I18n.t(`mongoid.attributes.guest.${fieldName}`) }{spaceBefore}: </span>
        <strong className={cssClass}>{field}</strong>
      </div>
    );
  }

  render() {
    const { bootstrapWidth, company_name, position, phone_number, message, first_name, last_name, email, guest_category, uid, index } = this.props;
    return (
      <div className={`col-md-${bootstrapWidth}`}>
        <div className="instruction-header">
          {I18n.t("import_operation_shared.mapping.preview_guest")}
          <span id="guest-preview-line"> ({`${I18n.t("import_operation_shared.mapping.preview_guest_line")} ${index}`})</span>
        </div>
        <div className="card guest" id="guest-preview-one">
          <div className="row card-body">
            <div className="col-md-12">
              <div className="identity preview">
                <h2>
                  <span className="name">
                    <span>{first_name}</span>
                    {" "}
                    <span>{last_name}</span>
                  </span>
                  <span className="email">{email}</span>
                  <span className="badge rounded-pill bg-secondary guest-category">{guest_category}</span>
                  <div className="uid">{uid}</div>
                </h2>
                { this.renderElement("company_name", company_name, "home") }
                { this.renderElement("position", position, "briefcase") }
                { this.renderElement("phone_number", phone_number, "user") }
                { this.renderElement("message", message, "comment", "message") }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Preview;

Preview.propTypes = {
  bootstrapWidth: PropTypes.number,
  company_name: PropTypes.string,
  position: PropTypes.string,
  phone_number: PropTypes.string,
  message: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  guest_category: PropTypes.string,
  uid: PropTypes.string,
  index: PropTypes.number
};

Preview.defaultProps = {
  bootstrapWidth: 6,
  company_name: "",
  position: "",
  phone_number: "",
  message: "",
  first_name: "",
  last_name: "",
  email: "",
  guest_category: "",
  uid: "",
  index: 1
};
