import { ActionTypes } from "../constants/Constants";
import { GuestProductsState } from "../types/GuestProduct";

const defaultState: GuestProductsState = {
  errors: null,
  isFetching: false,
  guestProducts: null,
  siloedData: {}
};

function guestProducts(state = defaultState, action: any): GuestProductsState {
  const uniqueKeyInSiloedData = action["uniqueKeyInSiloedData"];
  switch (action.type) {
  case ActionTypes.FETCH_GUEST_PRODUCTS_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.FETCH_GUEST_PRODUCTS_SUCCESS:
    return { ...state, guestProducts: action.data, isFetching: false, errors: null,
      siloedData: uniqueKeyInSiloedData ? { ...state.siloedData, [uniqueKeyInSiloedData]: action.data } : state.siloedData };
  case ActionTypes.FETCH_GUEST_PRODUCTS_FAILURE:
    return { ...state, guestProducts: [], isFetching: false, errors: action.data };
  case ActionTypes.DELETE_GUEST_PRODUCT_KEY_IN_SILOED_DATA:
    if (uniqueKeyInSiloedData) {
      const data = { ...state.siloedData };
      delete data[uniqueKeyInSiloedData];
      return { ...state, siloedData: data };
    } else {
      return state;
    }
  default:
    return state;
  }
}

export default guestProducts;
