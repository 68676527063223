import { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import querystring from "querystring";

import { fetchGuestCategory, updateGuestCategory, badgeToggle } from "../../actions/GuestCategoryActionCreators";
import GuestCategoryMoreAction from "../../containers/GuestCategoryMoreAction.react";
import GuestCategoryLabel from "../../components/guest_category/GuestCategoryLabel.react";
import FeaturesListPane from "../../components/guest_category/FeaturesListPane.react";
import GuestCategoryRegistrationFormFeature from "../../containers/GuestCategoryRegistrationFormFeature.react";
import GuestCategoryEmailFeature from "../../containers/GuestCategoryEmailFeature.react";
import GuestCategoryDocumentsFeature from "../../containers/guest_category/GuestCategoryDocumentsFeature.react";
import GuestCategoryFeature from "../../components/guest_category/GuestCategoryFeature.react";
import Loader from "../../components/shared/Loader.react";
import {
  GuestCategoryFeatures,
  GuestCategoryFeaturesBeforeRegistrationGroup,
  GuestCategoryFeaturesRegistrationGroup,
  GuestCategoryFeaturesPaymentGroup,
  GuestCategoryFeaturesInvoiceGroup,
  GuestCategoryFeaturesPostRegistrationGroup,
  GuestCategoryFeaturesPreRegistrationGroup
} from "../../constants/Constants";

import { pathToGuestCategoryEdit, pathToTicketingEdit, pathToGuestCategoriesList } from "../../utils/pathUtils";
import { populationI18n } from "../../utils/miscUtils";
import { redirectIfUnauthorized } from "../../utils/aclUtils";
import { allEnabled } from "../../utils/featureSetUtils";

class GuestCategory extends Component {

  constructor(props) {
    redirectIfUnauthorized("configuration", "manage");
    super(props);
    [
      "toggleFeaturesListPane",
      "enableFeature",
      "unsetActiveFeature"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      isFeaturesListPaneOpen: false,
      featureBeingAdded: null,
      featureOpened: querystring.parse(props.location.search.substring(1)).feature
    };
  }

  componentDidMount() {
    const { fetchGuestCategory, match } = this.props;
    const options = {
      capacity_stats: true,
      custom_emails: true,
      registration_form_page: true,
      has_people: true
    };
    fetchGuestCategory(match.params.event_id, match.params.guest_category_id, options);
  }

  componentDidUpdate(prevProps) {
    const { guestCategory } = this.props;

    if (prevProps.guestCategory.updated_at != guestCategory.updated_at) {
      this.setState({ featureBeingAdded: null });
    }
  }

  i18n(key, options = {}) {
    return I18n.t(`guest_categories.show.${key}`, options);
  }

  toggleFeaturesListPane() {
    const { isFeaturesListPaneOpen } = this.state;

    this.setState({ isFeaturesListPaneOpen: !isFeaturesListPaneOpen });
  }

  enableFeature(field, key, featureWithCreditCost = null) {
    const { updateGuestCategory, badgeToggle, guestCategory } = this.props;
    const { featureBeingAdded } = this.state;

    if (featureBeingAdded) return;
    this.setState({ featureBeingAdded: field });

    if (featureWithCreditCost && featureWithCreditCost == "badge") {
      badgeToggle(guestCategory.event_id, guestCategory._id);
    } else if (["moderation-email", "edition-link-email", "invitation-email"].includes(key)) {
      this.setState({ featureOpened: key, isFeaturesListPaneOpen: false });
    } else {
      updateGuestCategory(guestCategory.event_id, guestCategory._id, { [field]: true });
    }
  }

  unsetActiveFeature() {
    this.setState({ featureOpened: null, featureBeingAdded: null });
  }

  renderMenuButtons() {
    const { guestCategory, match, location, history } = this.props;

    return <div className="header-page-btn col-md-6 flex-wrap d-flex align-items-start">
      <>
        <a href={pathToGuestCategoryEdit(guestCategory._id)} className="btn btn-secondary" data-turbo="false">
          <i className="fa-regular fa-pencil"></i> { this.i18n("edit") }
        </a>

        { (guestCategory.type == "ticketing" || guestCategory.type == "ticket") &&
          <a href={pathToTicketingEdit(guestCategory.type == "ticketing" ? guestCategory._id : guestCategory.ticketing_category_id)} className="btn btn-secondary ml-5" data-turbo="false">
            <i className="fa-regular fa-ticket"></i> { this.i18n("view_ticketing") }
          </a>
        }

        <div className="btn-group ml-5">
          <GuestCategoryMoreAction
            eventId={guestCategory.event_id}
            guestCategoryId={guestCategory._id}
            match={match}
            location={location}
            history={history}
          />
        </div>
      </>

    </div>;
  }

  displayedFeatures(features) {
    const { guestCategory } = this.props;
    const { featureBeingAdded } = this.state;

    return GuestCategoryFeatures.filter(feature => {
      if (!features.includes(feature.key)) return false;
      if (!allEnabled(feature.requiredFeatures)) return false;
      if (feature.displayedIf && !feature.displayedIf(guestCategory)) return false;

      const alwaysDisplayed = feature.alwaysDisplayed && feature.alwaysDisplayed(guestCategory);
      return guestCategory[feature.field] || alwaysDisplayed || feature.field === featureBeingAdded;
    });
  }

  renderFeaturesGroup(features, withArrow = true) {
    const { guestCategory, match, location, history } = this.props;
    const { featureOpened } = this.state;

    const displayedFeatures = this.displayedFeatures(features);

    if (displayedFeatures.length == 0)
      return null;

    const widthClass = displayedFeatures.length % 4 == 0 ? "col-md-3 col-sm-6" : (displayedFeatures.length % 3 == 0 ? "col-sm-4" : "col-md-4 col-sm-6");
    const offsetClass = displayedFeatures.length == 1 ? "offset-md-4 offset-sm-3" : (displayedFeatures.length == 2 ? "offset-md-2" : "");

    return <div className="row" key={ features.join("_")} >
      { withArrow && this.renderArrowDown() }
      { displayedFeatures.map((feature, index) => {
        return <div className={classNames(widthClass, index == 0 && offsetClass)} key={feature.key}>
          { (feature.key == "registration-form")
            ? <GuestCategoryRegistrationFormFeature feature={feature} guestCategory={guestCategory} openOnLoad={featureOpened == feature.key} match={match} location={location} history={history} />
            : ("emailType" in feature)
              ? <GuestCategoryEmailFeature
                feature={feature}
                guestCategory={guestCategory}
                openOnLoad={featureOpened == feature.key}
                onCloseConfig={this.unsetActiveFeature}
                match={match}
                location={location}
                history={history} />
              : (feature.key === "documents")
                ? <GuestCategoryDocumentsFeature
                  feature={feature}
                  guestCategory={guestCategory}
                  openOnLoad={featureOpened == feature.key}
                  onCloseConfig={this.unsetActiveFeature}
                  match={match}
                  location={location}
                  history={history} />
                : <GuestCategoryFeature feature={feature} guestCategory={guestCategory} />
          }
        </div>;
      }) }
    </div>;
  }

  renderGuestCategoryCapacity() {
    const { guestCategory } = this.props;

    if (!guestCategory.has_real_capacity)
      return null;

    const featureContent = guestCategory.isFull
      ? <span className="value text-warning text-uppercase">{this.i18n("full")}</span>
      : <span className="value">{guestCategory.real_capacity}</span>;

    const feature = {
      name: this.i18n("real_capacity"),
      field: "has_real_capacity",
      pathMethod: "pathToGuestCategoryManageGuestCategoryAccesspoints",
      content: featureContent
    };

    return <div className="row">
      <div className="col-md-4 offset-md-4">
        <GuestCategoryFeature feature={feature} guestCategory={guestCategory} />
      </div>

      { this.renderArrowDown() }
    </div>;
  }

  renderArrowDown() {
    return <div className="col-12" key="arrow">
      <div className="text-center arrow-down">
        <i className="fa-regular fa-arrow-down"></i>
      </div>
    </div>;
  }

  render() {
    const { guestCategory } = this.props;
    const { isFeaturesListPaneOpen, featureBeingAdded } = this.state;

    if (!guestCategory || Object.keys(guestCategory).length == 0)
      return <Loader />;

    return <div>
      <div className="header-page">
        <div className="header-page-content row">
          <div className="header-page-title col-md-6">
            <h1>
              <a href={pathToGuestCategoriesList()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></a>
              { this.i18n("guest_category_title") }
              <GuestCategoryLabel guestCategory={guestCategory} isLink={false} cssClasses="big align-middle guest-category ml-5 mr-5" />
            </h1>
            <div>
              { guestCategory.population_type && (
                <div>Population: { populationI18n(guestCategory.population_type) }</div>
              )}
            </div>
          </div>
          { this.renderMenuButtons() }
        </div>
      </div>

      <div className="container">
        { this.renderGuestCategoryCapacity() }
        {
          this.displayedFeatures(GuestCategoryFeaturesPreRegistrationGroup).length === 0 ? null : [
            this.renderFeaturesGroup(GuestCategoryFeaturesBeforeRegistrationGroup, false),
            this.renderFeaturesGroup(GuestCategoryFeaturesRegistrationGroup, this.displayedFeatures(GuestCategoryFeaturesBeforeRegistrationGroup).length > 0),
            this.renderFeaturesGroup(GuestCategoryFeaturesPaymentGroup, this.displayedFeatures(GuestCategoryFeaturesRegistrationGroup).length > 0),
            this.renderFeaturesGroup(GuestCategoryFeaturesInvoiceGroup, this.displayedFeatures(GuestCategoryFeaturesPaymentGroup).length > 0),
            this.renderArrowDown()
          ]
        }

        <div className="row">
          <div className="col-md-4 offset-md-4 text-center feature-step">
            <p><i className="fa-regular fa-2x fa-users" /></p>
            <div className="text">{ this.i18n("people_on_guest_list") }</div>
          </div>
        </div>

        { this.renderFeaturesGroup(GuestCategoryFeaturesPostRegistrationGroup) }

        <div className="row">
          <div className="col-12 text-center mt-30">
            <span className="btn btn-primary" onClick={this.toggleFeaturesListPane}>
              <i className="fa-regular fa-plus" /> { this.i18n("add_features") }
            </span>
            <FeaturesListPane isOpen={isFeaturesListPaneOpen}
              guestCategory={guestCategory}
              featureBeingAdded={featureBeingAdded}
              onEnableFeature={this.enableFeature}
              onRequestClose={this.toggleFeaturesListPane} />
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    guestCategory: state.guestCategory.data
  };
}

const mapDispatchToProps = {
  fetchGuestCategory,
  updateGuestCategory,
  badgeToggle
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestCategory);
