import { Component } from "react";
import { Dropdown } from "react-bootstrap";

class RegistrationFormMoreDropdown extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="secondary" className="top-bar-link" aria-haspopup="true">
          <i className="d-none d-sm-inline fa-regular fa-ellipsis-vertical"></i>
          <p className="d-inline d-sm-none">{ I18n.t("react.registration_form_more_dropdown.more") }</p>
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          {this.props.children}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default RegistrationFormMoreDropdown;
