
interface Props {
  title: string;
}

const FormSectionPanel: React.FC<Props> = ({
  title,
  children
}) => {
  return (
    <div className="card">
      <div className="card-header text-center">
        <h4 className="card-title">
          {title}
        </h4>
      </div>
      <div className="card-body">
        {children}
      </div>
    </div>
  );
};

export default FormSectionPanel;
