import { Modal } from "react-bootstrap";
import moment from "moment";
import Loader from "../shared/Loader.react";
import TextWithLineBreaks from "../shared/TextWithLineBreaks.react";
import { Workflow, WorkflowVersion } from "../../types/Workflow";

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.workflows.workflow_versions_modal.${key}`, opts);
}

interface Props {
  isVisible: boolean;
  workflowVersions: WorkflowVersion[];
  workflow: Workflow;
  isPendingRequest: boolean;
  onClose(): void;
}

const WorkflowVersionsModal: React.FC<Props> = ({
  isVisible,
  workflowVersions,
  workflow,
  isPendingRequest,
  onClose
}) => {
  moment.locale(I18n.currentLocale());

  const versionBelongsToCurrentUser = (workflowVersion: WorkflowVersion): boolean => {
    return workflowVersion.user_id === workflow.current_user_id;
  };

  const isVersionPublished = (workflowVersion: WorkflowVersion): boolean => {
    return !!workflowVersion.published_at;
  };

  const renderVersionNumber = (workflowVersion: WorkflowVersion): JSX.Element => {
    if (isVersionPublished(workflowVersion)) {
      return <span className="badge badge-pill bg-primary">
        {i18n("version_number", { number: workflowVersion.version_number })}
      </span>;
    }

    return <span className="badge badge-pill bg-secondary">
      {i18n("draft")}
    </span>;
  };

  const renderEditorName = (workflowVersion: WorkflowVersion): JSX.Element => {
    return <span>
      {i18n("edited_by")} {versionBelongsToCurrentUser(workflowVersion) ? i18n("you") : workflowVersion.user_identity}
    </span>;
  };

  const renderPublicationDate = (workflowVersion: WorkflowVersion): JSX.Element => {
    if (!isVersionPublished(workflowVersion)) return;

    return <span>
      {i18n("published_on", { date: `${moment(workflowVersion.published_at).format("LL")} ${moment(workflowVersion.published_at).format("LTS")}` })}
    </span>;
  };

  const renderVersionsList = (): JSX.Element => {
    if (isPendingRequest || !workflowVersions) {
      return <Loader inline={false} containerHeight="160px" />;
    }

    return <div className="table-responsive table-container mt-0">
      <table className="table table-light table-bordered">
        <tbody>
          {workflowVersions.map((workflowVersion: WorkflowVersion) => {
            return <tr key={workflowVersion._id}>
              <td>
                <div className="float-end">{renderVersionNumber(workflowVersion)}</div>
                <h3>{workflowVersion.name}</h3>
                <p className="border-start border-secondary border-4 ps-2">
                  <TextWithLineBreaks text={workflowVersion.description} />
                </p>
                <p className="mb-0">
                  {renderEditorName(workflowVersion)}<br />
                  {renderPublicationDate(workflowVersion)}
                </p>
              </td>
            </tr>;
          })}
        </tbody>
      </table>
    </div>;
  };

  return <Modal show={isVisible} onHide={onClose} restoreFocus={false}>
    <Modal.Header>
      <Modal.Title>{i18n("title")}</Modal.Title>
      <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
    </Modal.Header>
    <Modal.Body>
      {renderVersionsList()}
    </Modal.Body>
  </Modal>;
};

export default WorkflowVersionsModal;
