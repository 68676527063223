import { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import difference from "lodash/difference";
import { Modal, OverlayTrigger, Tooltip, FormControl } from "react-bootstrap";
import Autocomplete from "react-autocomplete";
import TextAreaDataImport from "../../components/shared/TextAreaDataImport.react";
import { pathToGuestField, pathToGuestFields, pathToGuestFieldValuesListExport } from "../../utils/pathUtils";
import { ValueList, ImageType, CalculatedType, LazyCalculatedType, TextType } from "../../constants/Constants";
import Loader from "../shared/Loader.react";
import { dragIcon } from "../../constants/Icons";

import Sortable from "../../components/Sortable.react";
import { DragTypes } from "../../constants/Constants";

import ErrorMessage from "../../components/shared/ErrorMessage.react";
import CodeEditor from "../../components/shared/CodeEditor.react";
import Checkbox from "../shared/Checkbox.react";
import HelpSection from "../shared/HelpSection.react";


const IMPORT_VALUE_COLUMN = I18n.t("react.guest_field.modal.import_value_column");
const IMPORT_LABEL_COLUMN = I18n.t("react.guest_field.modal.import_label_column");
const IMPORT_GROUP_COLUMN = I18n.t("react.guest_field.modal.import_group_column");

class GuestFieldForm extends Component {

  constructor(props) {
    super(props);
    [
      "changeGuestFieldParam",
      "changeEvaluateCodeOnUpdate",
      "changeEvaluateCodeOnCheckin",
      "addEmptyAvailableValue",
      "removeAvailableValue",
      "changeAvailableValueParam",
      "changeAllowMultipleValues",
      "moveCaretAtEnd",
      "submitAndRedirect",
      "submit",
      "guestFieldParamsToSetState",
      "batchCreateOptions",
      "triggerImportModal",
      "handleDrop",
      "onCodeChange",
      "calculateFieldValueForDisplay"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = Object.assign({}, props.defaultState, { showImportModal: false });
  }

  componentDidMount() {
    const { guestFieldsTypesExcluded, guestFieldsTypes } = this.props;
    if (guestFieldsTypesExcluded.length + 1 === guestFieldsTypes.length) {
      const type = difference(guestFieldsTypes, guestFieldsTypesExcluded)[0];
      this.setState({ type });
    }
  }

  componentDidUpdate(prevProps) {
    const { guestField } = this.props;
    if (prevProps.guestField && Object.keys(prevProps.guestField).length > 0 && Object.keys(guestField).length === 0) {
      // if the url change to guest_fields/:id to guest_fields/new
      this.setState(Object.assign({}, this.props.defaultState, { showImportModal: false }));
    } else if (!isEmpty(guestField) && isEmpty(prevProps.guestField)) {
      this.setState(this.guestFieldParamsToSetState(guestField));
    }
  }

  i18n(key, opts = {}) {
    return I18n.t(`react.guest_field.form.${key}`, opts);
  }

  machineValue(value) {
    const labelStr = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    return labelStr.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "_");
  }

  moveCaretAtEnd(e) {
    var tempValue = e.target.value;
    e.target.value = "";
    e.target.value = tempValue;
  }

  guestFieldParamsToSetState(guestField) {
    const availableValues = guestField.available_values.map((availableValue, index) => {
      // We need uniq itemIdKey, if not several options could be hidden by sortable component
      const itemIdKey = `${availableValue.rank}_${availableValue.label}_${availableValue.value}_${index}`;
      return { ...availableValue, persisted: true, editMode: true, itemIdKey };
    });
    return {
      errors: [],
      persisted: true,
      label: guestField.label,
      key: guestField.key,
      type: guestField.type,
      is_translatable: guestField.is_translatable,
      translated_guest_field_id: guestField.translated_guest_field_id,
      keep_on_takeout: guestField.keep_on_takeout,
      available_values: availableValues,
      image_width: guestField.image_width,
      image_height: guestField.image_height,
      allow_multiple_values: guestField.allow_multiple_values,
      code: guestField.code,
      evaluate_code_on_update: guestField.evaluate_code_on_update,
      evaluate_code_on_checkin: guestField.evaluate_code_on_checkin
    };
  }

  changeCheckbox(field) {
    return (e) => {
      this.setState({ [field]: e.target.checked });
    };
  }

  changeGuestFieldParam(field) {
    const { label, key } = this.state;
    return (e) => {
      const tempValue = e.target.value;
      const { persisted } = this.state;
      let stateToSet = { [field]: tempValue, updated: true };
      if (e.target.name == "label" && !persisted) {
        const machineName = this.machineValue(label);
        if (machineName == key) {
          stateToSet = { label: tempValue, key: this.machineValue(tempValue), updated: true };
        }
      }
      this.setState(stateToSet);
    };
  }

  changeAvailableValueParam(index, availableValue, param, locale) {
    const { available_values } = this.state;
    return (e) => {
      let newAvailableValue = Object.assign({}, availableValue);
      switch (param) {
      case "label": {
        const tempValue = e.target.value;
        if (locale) {
          newAvailableValue.translations[locale] = tempValue;
        } else {
          if (newAvailableValue.label === newAvailableValue.value) {
            newAvailableValue.value = tempValue;
          }
          newAvailableValue.label = tempValue;
        }
        break;
      }
      default:
        newAvailableValue[param] = e.target ? e.target.value : e;
      }
      const newAvailableValues = available_values.slice();
      newAvailableValues[index] = newAvailableValue;
      this.setState({ available_values: newAvailableValues, updated: true });
    };
  }

  changeAllowMultipleValues(e) {
    this.setState({ allow_multiple_values: e.target.checked, updated: true });
  }

  addEmptyAvailableValue() {
    let currentAvailableValues = this.state.available_values;
    const nb = currentAvailableValues.length + 1;
    const value = this.i18n("list_values.item", { nb });
    const rank = nb > 1 ? currentAvailableValues[nb - 2].rank + 1000 : 1000;
    const itemIdKey = `${rank}_${value}_${value}_${nb - 1}`;
    currentAvailableValues.push({ label: value, translations: { fr: value, en: value }, value, persisted: false, editMode: false, rank, itemIdKey });
    this.setState({ available_values: currentAvailableValues, updated: true });
  }

  batchCreateOptions(payload) {
    const { available_values } = this.state;

    let nbElement = available_values.length + 1;

    const guestFieldValues = [];
    payload.forEach((option) => {
      const keyPayload = option[IMPORT_VALUE_COLUMN] || option[IMPORT_LABEL_COLUMN];
      let value = keyPayload;
      const label = option[IMPORT_LABEL_COLUMN];
      const group = option[IMPORT_GROUP_COLUMN];
      const rank = nbElement * 1000;

      guestFieldValues.push({ value, label, rank, persisted: false, group });
      nbElement = nbElement + 1;
    });
    this.setState({ available_values: available_values.concat(guestFieldValues), showImportModal: false });
  }

  createOrUpdateGuestField(redirectTo) {
    const { onSubmit } = this.props;
    const { type, available_values } = this.state;

    let changeSet = {};
    if (type !== ValueList) {
      changeSet = Object.assign(changeSet, { available_values: [] });
    }
    if (type != ImageType) {
      changeSet = Object.assign(changeSet, { image_width: null, image_height: null });
    }
    if (type != CalculatedType && type != LazyCalculatedType) {
      changeSet = Object.assign(changeSet, { code: null });
    }

    const newAvailableValues = available_values.filter(value => value.deleted === undefined);
    changeSet = Object.assign(changeSet, { available_values: newAvailableValues });
    this.setState(changeSet, () => {
      onSubmit(this.state, true, redirectTo);
    });
  }

  submitAndRedirect(e) {
    if (e && typeof e.preventDefault === "function") e.preventDefault();
    this.createOrUpdateGuestField(pathToGuestFields());
  }

  submit(e) {
    if (e && typeof e.preventDefault === "function") e.preventDefault();
    this.createOrUpdateGuestField(pathToGuestField(":id"));
  }

  removeAvailableValue(index) {
    const { available_values } = this.state;
    return () => {
      const currentAvailableValues = available_values.map((value, indexAvailableValue) => {
        if (index === indexAvailableValue) {
          return Object.assign({}, value, { deleted: true });
        }
        return value;
      });
      this.setState({ available_values: currentAvailableValues, updated: true });
    };
  }

  undoRemoveAvailableValue(index) {
    const { available_values } = this.state;
    return () => {
      const currentAvailableValues = available_values.map((value, indexAvailableValue) => {
        if (index === indexAvailableValue) {
          let availableValue = Object.assign({}, value);
          delete availableValue.deleted;
          return availableValue;
        }
        return value;
      });
      this.setState({ available_values: currentAvailableValues, updated: true });
    };
  }

  findAvailableValue(field, value) {
    const { available_values } = this.state;
    if (!available_values) {
      return null;
    }
    return available_values.find(availableValue => {
      return availableValue[field] === value;
    });
  }

  renderTitle() {
    const { isModal } = this.props;
    if (isModal) {
      return null;
    }
    const { persisted } = this.state;
    return <div className="header-page">
      <div className="header-page-title">
        <h1>
          <a href={pathToGuestFields()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></a>
          { persisted ? this.i18n("edit_title") : this.i18n("create_title")}
        </h1>
      </div>
    </div>;
  }

  renderMainInputs() {
    const { guestFieldsTypes, guestFieldsTypesExcluded, event } = this.props;
    const { key, label, type, persisted, is_translatable, translated_guest_field_id } = this.state;

    const isTranslation = !!translated_guest_field_id;

    const selectOptions = guestFieldsTypes.map(typeName => {
      if (!guestFieldsTypesExcluded.includes(typeName)) {
        return <option key={typeName} value={typeName}>{ I18n.t(`react.guest_field.type.${typeName}`) }</option>;
      }
    });
    const selectHasOnlyOneElement = guestFieldsTypes.length === guestFieldsTypesExcluded.length + 1;

    return (
      <div className="row">
        <div className="col-sm-4 col-12">
          <div className="mb-2">
            <label htmlFor="label" className="form-label">{this.i18n("label")}</label>
            <input type="text" name="label" className={classNames("form-control", isTranslation ? "disabled" : null)} disabled={isTranslation} onChange={this.changeGuestFieldParam("label")} value={label} />
          </div>
        </div>
        <div className="col-sm-4 col-6">
          <div className="mb-2">
            <label htmlFor="key" className="form-label">{this.i18n("key")}</label>
            <input type="text" name="key" className={classNames("form-control", persisted ? "disabled" : null)} disabled={persisted} onChange={this.changeGuestFieldParam("key")} value={key} />
          </div>
        </div>
        <div className="col-sm-4 col-6">
          <div className="mb-2">
            <label htmlFor="type" className="form-label">{this.i18n("type")}</label>
            <select className={classNames("form-select", persisted || selectHasOnlyOneElement ? "disabled" : null)} disabled={persisted} onChange={this.changeGuestFieldParam("type")} value={type}>
              { selectOptions }
            </select>
          </div>
        </div>
        { !isTranslation && event.available_frontend_locales.length > 0 &&
          <div className="col-sm-4 col-6">
            <div className="mt-5">
              <Checkbox
                text={I18n.t("react.website.is_translatable")}
                onChange={this.changeCheckbox("is_translatable")}
                checked={is_translatable}
              />
            </div>
          </div>
        }
      </div>
    );
  }

  renderAvailableValuesTable() {
    const { available_values } = this.state;

    if (available_values.length == 0) {
      return (
        <div className="card nothing-yet">
          <h4>{ this.i18n("empty_list") }</h4>
          <div className="mt-10 row g-2 align-items-center justify-content-center">
            { this.renderImportOptionsModalBtn() } { this.renderAddAvailableValueBtn() }
          </div>
        </div>
      );
    }

    return <>
      <table className="table">
        <thead>
          <tr className="row align-items-center flex-nowrap no-margin" style={{ width: "100%" }}>
            <th className="col-auto border-0" style={{ minWidth: "50px" }}></th>
            <th className="col-3 border-0">
              { this.i18n("available_value.label_default") + " "}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip-bar-step">{ this.i18n("available_value.label_default_tooltip") }</Tooltip>}>
                <i className="fa-regular fa-info-circle" aria-hidden="true"/>
              </OverlayTrigger>
            </th>
            <th className="col-3 border-0">
              { this.i18n("available_value.value") + " "}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip-bar-step">{ this.i18n("available_value.value_tooltip") }</Tooltip>}>
                <i className="fa-regular fa-info-circle" aria-hidden="true"/>
              </OverlayTrigger>
            </th>
            <th className="col-3 border-0">
              { this.i18n("available_value.group") + " "}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip-bar-step">{ this.i18n("available_value.group_tooltip") }</Tooltip>}>
                <i className="fa-regular fa-info-circle" aria-hidden="true"/>
              </OverlayTrigger>
            </th>
            <th className="col text-end border-0">
              { this.i18n("available_value.actions") }
            </th>
          </tr>
        </thead>
      </table>
      {this.renderAvailableValuesTableRows()}
    </>;
  }

  triggerImportModal(e) {
    e.preventDefault();
    this.setState({
      showImportModal: !this.state.showImportModal
    });
  }

  moveValue(oldIndex, newIndex, availableValue) {
    const { onSubmit } = this.props;
    const { available_values, persisted } = this.state;
    return () => {
      if (newIndex < 0 || newIndex > available_values.length) {
        return;
      }
      let availableValuesWithoutElement = available_values.slice();
      availableValuesWithoutElement.splice(oldIndex, 1);
      let new_available_values = [];
      let index = 0;
      availableValuesWithoutElement.forEach((value) => {
        if (index == newIndex) {
          const val = Object.assign({}, availableValue, { rank: (index + 1) * 1000 });
          new_available_values.push(val);
          index = index + 1;
        }
        const val = Object.assign({}, value, { rank: (index + 1) * 1000 });
        new_available_values.push(val);
        index = index + 1;
      });
      // availableValue must be set at the end
      if (newIndex >= availableValuesWithoutElement.length) {
        const val = Object.assign({}, availableValue, { rank: (availableValuesWithoutElement.length + 1) * 1000 });
        new_available_values.push(val);
      }
      this.setState({ available_values: new_available_values });
      if (persisted) {
        const data = Object.assign({}, this.state, { available_values: new_available_values });
        onSubmit(data, false);
      }
    };
  }

  renderImageTypeSpecificInputs() {
    const labelAndInput = (key) => {
      const value = this.state[key];
      return (
        <div className="col-md-4">
          <label htmlFor={key} className="form-label">{I18n.t(`react.guest_field.form.${key}`)}</label>
          <input type="number" name={key} className={"form-control"} onChange={this.changeGuestFieldParam(key)} value={value} />
        </div>
      );
    };
    return (
      <div className="row">
        { labelAndInput("image_width") }
        { labelAndInput("image_height") }
        <div className="col-md-12 form-text">{ this.i18n("image_help") }</div>
      </div>
    );
  }

  renderHint(hint) {
    return <HelpSection help={hint} classNames="d-none d-sm-block" />;
  }

  calculateFieldValueForDisplay (e) {
    e.preventDefault();
    const { eventId, calculateFieldValue } = this.props;
    const { code, previewUid } = this.state;
    const params = {
      uid: previewUid,
      liquid_code: code
    };
    calculateFieldValue(eventId, params);
  }

  displayPreview () {
    const { previewCalculatedField, calculatedFieldPreviewIsLoading } = this.props;
    return <div>
      <label className="form-label mt-3">{this.i18n("test_your_formula")}</label>
      <div className="row justify-content-start g-2">
        <div className="col-sm-4">
          <FormControl
            placeholder={this.i18n("uid_calculate_field")}
            type="text"
            onChange={this.changeGuestFieldParam("previewUid")}
            className={"form-control"}/>
        </div>
        <div className="col">
          {!calculatedFieldPreviewIsLoading ? <button
            disabled={!this.state.previewUid.trim()}
            onClick={this.calculateFieldValueForDisplay}
            className="btn btn-secondary">{this.i18n("preview_calculate_field")}
          </button> : <Loader />}
        </div>
      </div>
      <div className="mt-10">
        <span className="me-5">{this.i18n("value")}</span>
        <span className="bg-light p-1"><code>{previewCalculatedField}</code></span>
      </div>
    </div>;
  }

  renderCodeEditor(lazy = false) {
    const { code, evaluate_code_on_update, evaluate_code_on_checkin, key } = this.state;
    return (
      <div className="mt-3 mb-3">
        <label className="form-label">{this.i18n("code")}</label>
        { this.renderHint(I18n.t(`react.guest_field.form.instant_help_${lazy ? "lazy_" : ""}calculated`)) }
        {lazy && key && key.length > 0 ? (
          <p><code>{`{{ guest.published_metadata.${key} }}`}</code></p>
        ) : null}
        <div className="row">
          <div className="col-sm-8 code-editor-custom">
            <CodeEditor content={code || ""} onContentChange={this.onCodeChange} onSaveWithKeyboard={this.submit} />
            { this.displayPreview() }
            { lazy ? null :
              <div className="form-check mt-20">
                <label className="form-check-label">
                  <input type="checkbox" className="form-check-input" checked={evaluate_code_on_update} onChange={this.changeEvaluateCodeOnUpdate} />
                  { this.i18n("evaluate_code_on_update") }
                </label>
              </div>
            }
            { lazy ? null :
              <div className="form-check mt-10">
                <label className="form-check-label">
                  <input type="checkbox" className="form-check-input" checked={evaluate_code_on_checkin} onChange={this.changeEvaluateCodeOnCheckin} />
                  { this.i18n("evaluate_code_on_checkin") }
                </label>
              </div>
            }
          </div>
          <div className="col-sm-4">
            <pre className="debug-dump bg-light">
              <strong className="liquid-variable" dangerouslySetInnerHTML={{ __html: "{{ guest }}" }}></strong>
              { "{" }<br />
              { this.renderLiquidProperty("email", "guest@email.com") }
              { this.renderLiquidProperty("first_name", "John") }
              { this.renderLiquidProperty("last_name", "Doe") }
              { this.renderLiquidProperty("identity", "John Doe") }
              { this.renderLiquidProperty("position", "CEO") }
              { this.renderLiquidProperty("company_name", "My Company") }
              { this.renderLiquidProperty("phone_number", "+33132445312") }
              { this.renderLiquidProperty("country", "FRA") }
              { this.renderLiquidProperty("country_name", "France") }
              { this.renderLiquidProperty("guest_metadata", "{}") }
              { this.renderLiquidProperty("payment_promo_code", "PR2018") }
              { this.renderLiquidProperty("payment_status", "UNPAID") }
              { "}" }
            </pre>
            <p className="text-end">
              <a className="btn btn-link" href="https://shopify.github.io/liquid/basics/introduction/" target="_blank">{this.i18n("liquid_doc")}</a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderLiquidProperty(key, value) {
    return <>
      <span>
        <pre>"{ key }"</pre>
        <kbd style={{ color: "slategray" }}> {"=>"} </kbd>
        <pre>
          <kbd style={{ color: "brown" }}>"{ value }"</kbd>
        </pre>,
      </span>
      <br />
    </>;
  }

  onCodeChange(newCode) {
    this.setState({ code: newCode });
  }

  changeEvaluateCodeOnUpdate(e) {
    this.setState({ evaluate_code_on_update: e.target.checked });
  }

  changeEvaluateCodeOnCheckin(e) {
    this.setState({ evaluate_code_on_checkin: e.target.checked });
  }

  renderValueListAllowingCheckbox(checked, onChangeFunction, checkboxLabel, helpText) {
    return (
      <div className="form-check">
        <label className="form-check-label">
          <input type="checkbox" className="form-check-input" checked={checked} onChange={onChangeFunction} />
          { checkboxLabel }
        </label>
        <span> </span>
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id="tooltip-allow-nil">{ helpText }</Tooltip>}
        >
          <i className="fa-regular fa-info-circle" aria-hidden="true"/>
        </OverlayTrigger>
      </div>
    );
  }

  renderImportOptionsModal() {
    return (
      <Modal show={this.state.showImportModal} onHide={this.close}>
        <Modal.Header>
          <Modal.Title>{ I18n.t("react.form_dropdown.import_option") }</Modal.Title>
          <button type="button" onClick={ this.triggerImportModal } className="btn-close" aria-label={I18n.t("close")}></button>
        </Modal.Header>
        <Modal.Body>
          <TextAreaDataImport
            expectedColumns={[IMPORT_LABEL_COLUMN, IMPORT_VALUE_COLUMN, IMPORT_GROUP_COLUMN]}
            mandatoriesColums={[IMPORT_LABEL_COLUMN]}
            onSubmit={this.batchCreateOptions}
          />
        </Modal.Body>
      </Modal>
    );
  }

  handleDrop(previousItemId, itemIdKey, nextItemId, estimatedIndex, newSortedList) {
    let newAvailableValues = newSortedList.map(listItem => listItem.item);
    const itemIndex = newAvailableValues.findIndex(av => av.itemIdKey === itemIdKey);
    newAvailableValues[itemIndex] = { ...newAvailableValues[itemIndex], rank: estimatedIndex };
    this.setState({ available_values: newAvailableValues });
  }

  renderAvailableValuesTableRows() {
    const { available_values } = this.state;
    const rows = available_values.map((available_value, index) => {
      const className = classNames({
        card: true,
        "mb-5": true,
        "border border-2 border-danger": available_value.deleted
      });

      return <div key={index} className={className}>
        <div className="card-body" style={{ padding: "5px 8px" }}>
          <div className="row align-items-center g-2 flex-nowrap">
            <div className="col-auto" style={{ minWidth: "50px" }}>
              <a style={{ cursor: "grab" }} className="btn btn-link mb-5">{dragIcon()}</a>
            </div>
            <div className="col-3"> { this.renderAvailableValueLabel(index, available_value) } </div>
            <div className="col-3"> { this.renderAvailableValueValue(index, available_value) } </div>
            <div className="col-3"> { this.renderAvailableValueGroup(index, available_value) } </div>
            <div className="text-end col">{ this.renderRemoveActions(index, available_value) }</div>
          </div>
        </div>
      </div>;
    });

    if (!rows[0]) return null;

    return (
      <Sortable
        itemIdKey="itemIdKey"
        itemIndexKey="rank"
        dragType={DragTypes.VALUE_LIST}
        items={ available_values.sort((a, b) => { return a.rank - b.rank; }) }
        handleDrop={this.handleDrop}
        fullyDraggable={true}
      >
        { rows }
      </Sortable>
    );
  }

  renderAvailableValueLabel(index, availableValue, locale) {
    return (
      <input
        type="text"
        name="available-value-value"
        className="form-control"
        value={locale ? availableValue.translations[locale] : availableValue.label}
        onChange={this.changeAvailableValueParam(index, availableValue, "label", locale)}
        onFocus={this.moveCaretAtEnd}
        autoFocus
      />
    );
  }

  renderAvailableValueValue(index, availableValue) {
    return (
      <input
        type="text"
        name="available-value-key"
        className="form-control"
        value={availableValue.value}
        onChange={this.changeAvailableValueParam(index, availableValue, "value")}
        onFocus={this.moveCaretAtEnd}
        autoFocus
      />
    );
  }

  renderAvailableValueGroup(index, availableValue) {
    const { available_values } = this.state;
    let groups = available_values.reduce((acc, option) => {
      if (option.group && option.value !== availableValue.value) {
        acc[option.group] = true;
      }
      return acc;
    }, {});

    groups = Object.keys(groups);

    return (
      <Autocomplete
        wrapperStyle={{ display: "inline-block", position: "relative", width: "100%" }}
        getItemValue={(group) => group}
        items={groups}
        value={availableValue.group}
        shouldItemRender={(group, value) => group.toLowerCase().indexOf(value.toLowerCase()) > -1}
        onChange={this.changeAvailableValueParam(index, availableValue, "group")}
        onSelect={this.changeAvailableValueParam(index, availableValue, "group")}
        selectOnBlur={true}
        inputProps={{ className: "form-control italic-placeholder", placeholder: this.i18n("group_placeholder") }}
        renderMenu={(items) => {
          return <ul className="list-group" style={{ position: "absolute", zIndex: 99999, width: "100%" }}>{items}</ul>;
        }}
        renderItem={(group, isHighlighted) => {
          const backgroundColor = isHighlighted ? "#ddd" : "white";
          return <li className="list-group-item" style={{ backgroundColor, cursor: "pointer" }} key={group}>{group}</li>;
        }}
      />
    );
  }

  renderAddAvailableValueBtn() {
    return (
      <div className="col-auto">
        <button type="button" onClick={this.addEmptyAvailableValue} className="btn btn-primary">
          <i className="fa-regular fa-plus" aria-hidden="true"/> { this.i18n("available_value.add") }
        </button>
      </div>
    );
  }

  renderImportOptionsModalBtn() {
    return (
      <div className="col-auto">
        <button type="button" onClick={this.triggerImportModal} className="btn btn-primary">
          <i className="fa-regular fa-download" aria-hidden="true"/> { this.i18n("available_value.show_import_modal") }
        </button>
      </div>
    );
  }

  renderExportBtn() {
    const { persisted } = this.state;
    if (!persisted) return null;

    const { guestField } = this.props;
    return (
      <div className="col-auto">
        <a href={pathToGuestFieldValuesListExport(guestField._id)} className="btn btn-secondary">
          <i className="fa-regular fa-upload" aria-hidden="true"/> { this.i18n("available_value.export") }
        </a>
      </div>
    );
  }

  renderRemoveActions(position, availableValue) {
    let undoRedoFn = null;
    let classNameItag = "";
    let classNameATag = "btn btn-secondary float-end";
    if (availableValue.deleted) {
      undoRedoFn = this.undoRemoveAvailableValue(position);
      classNameItag = "fa-regular fa-undo text-center";
    } else {
      undoRedoFn = this.removeAvailableValue(position);
      classNameItag = "fa-regular fa-trash-can text-center";
      classNameATag = "btn btn-danger";
    }
    return (
      <a className={classNameATag} onClick={undoRedoFn}>
        <i className={classNameItag} aria-hidden="true" />
      </a>
    );
  }

  renderSaveBtn() {
    const { persisted } = this.state;
    const { isModal } = this.props;
    const valueSave = persisted ? this.i18n("save_changes") : this.i18n("create");
    const valueSaveAndRedirect = persisted ? `${this.i18n("save_changes")} → ${this.i18n("guest_fields")}` : `${this.i18n("create")} → ${this.i18n("guest_fields")}`;

    if (isModal) {
      return (
        <div>
          <hr/>
          <div>
            <input type="submit" className="btn btn-primary mb-10 float-end" value={valueSave} />
          </div>
        </div>
      );
    }

    return (
      <div className="float-start mt-3">
        <span onClick={this.submitAndRedirect} className="btn btn-primary">
          {valueSaveAndRedirect}
        </span>
        <span> </span>
        <input type="submit" className="btn btn-secondary" value={valueSave} />
      </div>
    );
  }

  renderErrorsMessage() {
    return <ErrorMessage errors={ this.props.errors } model="guest_field"/>;
  }

  renderAddRowButton() {
    const { available_values } = this.state;

    return available_values.length > 0 ? (
      <div className="mb-1">
        <div className="text-end">
          { this.renderAddAvailableValueBtn() }
        </div>
      </div>
    ) : "";
  }

  renderAdditionalInputs() {
    const { type, available_values, allow_multiple_values } = this.state;

    const availableValuesButtonsHeader = available_values.length > 0 ? (
      <div className="mb-3 row align-items-center">
        <div className="col-sm-6">
          { this.renderValueListAllowingCheckbox(allow_multiple_values, this.changeAllowMultipleValues, this.i18n("allow_multiple_values"), this.i18n("allow_multiple_values_help")) }
        </div>
        <div className="col-sm-6">
          <div className="row g-2 align-items-center justify-content-end">
            { this.renderImportOptionsModalBtn() } { this.renderExportBtn() }
          </div>
        </div>
      </div>
    ) : "";

    switch (type) {
    case "value_list":
      return (
        <div className="mt-3">
          { this.renderImportOptionsModal() }
          { availableValuesButtonsHeader }
          { this.renderAvailableValuesTable() }
          { this.renderAddRowButton() }
        </div>
      );
    case "calculated":
      return this.renderCodeEditor();
    case "lazy_calculated":
      return this.renderCodeEditor(true);
    case "image":
      return this.renderImageTypeSpecificInputs();
    default:
      return null;
    }
  }

  renderKeepOnTakeoutCheckbox() {
    const { keep_on_takeout, type } = this.state;
    if (![TextType, ValueList, CalculatedType].includes(type)) {
      return null;
    }

    return (
      <div className="mt-3 mb-3">
        <label className="form-label">{this.i18n("personal_data")}</label>
        <Checkbox
          text={this.i18n("keep_on_takeout")}
          onChange={this.changeCheckbox("keep_on_takeout")}
          checked={keep_on_takeout}
        />
        <div className="form-text">
          {this.i18n("keep_on_takeout_help")}
        </div>
      </div>
    );
  }

  render() {
    const { event } = this.props;

    if (!event || !event._id) {
      return <Loader size="large" inline={false} containerHeight="500px" message={I18n.t("react.loader.loading")} />;
    }

    return (
      <form onSubmit={this.submit}>
        { this.renderTitle() }
        { this.renderErrorsMessage() }
        { this.renderMainInputs() }
        { this.renderAdditionalInputs() }
        { this.renderKeepOnTakeoutCheckbox() }
        { this.renderSaveBtn() }
      </form>
    );
  }

}

export default GuestFieldForm;

GuestFieldForm.propTypes = {
  guestField: PropTypes.object,
  guestFieldsTypes: PropTypes.array.isRequired,
  errors: PropTypes.object,
  event: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onLeave: PropTypes.func,
  guestFieldsTypesExcluded: PropTypes.array
};


GuestFieldForm.defaultProps = {
  defaultState: {
    errors: {},
    event: {},
    persisted: false,
    is_translatable: false,
    keep_on_takeout: false,
    translated_guest_field_id: "",
    label: "",
    key: "",
    type: "text",
    previewUid: "",
    available_values: [],
    allow_multiple_values: false,
    updated: false,
    image_width: 100,
    image_height: 100,
    code: null,
    evaluate_code_on_update: true,
    evaluate_code_on_checkin: false
  },
  isModal: false,
  guestFieldsTypesExcluded: []
};
