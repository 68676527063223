import { useState, useEffect } from "react";
import Loader from "../../components/shared/Loader.react";
import ContextSelector from "../../containers/automation/ContextSelector.react";
import ObjectSelector from "./ObjectSelector.react";
import GuestCategoryDropdown from "../../containers/shared/GuestCategoryDropdown.react";
import AccesspointDropdown from "../../containers/shared/AccesspointDropdown.react";
import LabelDropdown from "../../containers/shared/LabelDropdown.react";
import EmailTemplateSelector from "../email_templates/EmailTemplateSelector.react";
import GuestStatusDropdown from "../shared/GuestStatusDropdown.react";
import Checkbox from "../shared/Checkbox.react";
import ErrorMessage from "../shared/ErrorMessage.react";
import { WorkflowStep, Context } from "../../types/Workflow";
import { settingsHaveMissingPath } from "../../utils/workflowUtils";

const DEFAULT_DURATION = { duration: "1", unit: "" };

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.workflows.settings.${key}`, opts);
}

interface Props {
  workflowStep: WorkflowStep;
  globalConfigParams: any;
  isFetchingStrategy: boolean;
  isSavingStep: boolean;
  isDisabled: boolean;
  context: Context;
  onReceiveChanges(workflowStep: WorkflowStep): void;
  onSave(newSettings: any, newConditions: any[]): void;
  onSwitchVersionRequest(): void;
}

const WorkflowStepSettings: React.FC<Props> = ({
  workflowStep,
  globalConfigParams,
  isFetchingStrategy,
  isSavingStep,
  isDisabled,
  context,
  onReceiveChanges,
  onSave,
  onSwitchVersionRequest
}) => {
  const [localSettings, setLocalSettings] = useState(workflowStep.settings || {});
  const [localErrors, setLocalErrors] = useState({});

  useEffect(() => {
    setLocalSettings(workflowStep.settings || {});
    setLocalErrors(workflowStep.errors || {});
  }, [workflowStep._id]);

  useEffect(() => {
    setLocalErrors(workflowStep.errors || {});
  }, [workflowStep.errors]);

  const receiveChanges = (): void => {
    if (workflowStep.hasChanges) return;

    onReceiveChanges(workflowStep);
  };

  const updateSettingHandler = (key: string): ((value: string) => void) => {
    return (value: string) => {
      receiveChanges();
      setLocalSettings({ ...localSettings, [key]: value });
    };
  };

  const updateSettingFromReactSelectDropdownHandler = (key: string, isMulti: boolean): ((selectedOption: any) => void) => {
    return (selectedOption: any): void => {
      receiveChanges();
      if (isMulti) {
        setLocalSettings({ ...localSettings, [key]: selectedOption.length ? selectedOption.map(option => option.value) : null });
      } else {
        setLocalSettings({ ...localSettings, [key]: selectedOption ? selectedOption.value : null });
      }
    };
  };

  const updateSettingFromCheckboxHandler = (key: string): ((e: React.ChangeEvent<HTMLInputElement>) => void) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      receiveChanges();
      setLocalSettings({ ...localSettings, [key]: e.target.checked });
    };
  };

  const updateSettingFromRadioHandler = (key: string): ((e: React.ChangeEvent<HTMLInputElement>) => void) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      receiveChanges();
      setLocalSettings({ ...localSettings, [key]: e.target.value });
    };
  };

  const updateSettingFromDurationHandler = (key: string, field: string): ((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void) => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const currentDuration = (localSettings[key] || DEFAULT_DURATION);
      receiveChanges();
      setLocalSettings({ ...localSettings, [key]: { ...currentDuration, [field]: e.target.value } });
    };
  };

  const isStepValid = (): boolean => {
    const errors = {};

    Object.entries(globalConfigParams).map(([key, config]) => {
      if (config["required"] && !localSettings[key]) {
        errors[key] = [I18n.t("required")];
      }
    });

    if (settingsHaveMissingPath(localSettings, context)) {
      errors["base"] = I18n.t("react.workflows.settings.missing_path_error");
    }

    setLocalErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const saveStep = (): void => {
    if (!isStepValid()) return;

    onSave(localSettings, null);
  };

  const renderSettings = (): JSX.Element[] => {
    if (!globalConfigParams) return null;

    return Object.entries(globalConfigParams).map(([key, config]) => {
      return <div className="mb-3" key={key}>
        {renderSetting(key, config)}
      </div>;
    });
  };

  const renderSetting = (key: string, config: any): JSX.Element => {
    switch (config.type) {
    case "text_field":
      return renderTextField(key, config.required, config.help);
    case "guest":
    case "guest_invitation":
    case "access_privilege":
      return renderObjectSelector(key, config.type, config.required, config.help);
    case "email_template":
      return renderEmailTemplateSelector(key, config.required);
    case "guest_category":
      return renderGuestCategoryDropdown(key, config.required, config.help, config.multiple);
    case "accesspoint":
      return renderAccesspointDropdown(key, config.required, config.help, config.multiple);
    case "guest_status":
      return renderGuestStatusDropdown(key, config.required, config.help, config.multiple);
    case "description":
      return <p>{i18n(`${workflowStep.strategy}.description`)}</p>;
    case "checkbox":
      return renderCheckbox(key, config.help);
    case "radio":
      return renderRadioButtons(key, config.required, config.available_values, config.help, config.default);
    case "duration_field":
      return renderDurationField(key, config.required, config.help);
    case "label":
      return renderLabelDropdown(key, config.required, config.help, config.multiple);
    default:
      return <p className="text-danger">Setting of type <em>{config.type}</em> is not handled</p>;
    }
  };

  const renderTextField = (key: string, required: boolean, help: boolean): JSX.Element => {
    return <>
      <label htmlFor={key} className="form-label">{renderLabelText(key, required)}</label>
      <ContextSelector
        key={key}
        settingValue={localSettings[key]}
        workflowStep={workflowStep}
        creatable={true}
        isDisabled={isDisabled}
        onChange={updateSettingHandler(key)}
      />
      { help && <div className="form-text">{renderHelpText(key)}</div> }
    </>;
  };

  const renderObjectSelector = (key: string, objectType: string, required: boolean, help: boolean): JSX.Element => {
    return <>
      <label htmlFor={key} className="form-label">{renderLabelText(key, required)}</label>
      <ObjectSelector
        key={key}
        objectType={objectType}
        settingValue={localSettings[key]}
        workflowStep={workflowStep}
        isDisabled={isDisabled}
        onChange={updateSettingHandler(key)}
      />
      { help && <div className="form-text">{renderHelpText(key)}</div> }
    </>;
  };

  const renderEmailTemplateSelector = (key: string, required: boolean): JSX.Element => {
    return <EmailTemplateSelector
      slidingPaneTitle={i18n(`${workflowStep.strategy}.choose_email_template`)}
      selectedEmailTemplateId={localSettings[key] || ""}
      persistedEmailTemplateId={localSettings[key] || ""}
      onSelectTemplate={updateSettingHandler(key)}
      label={renderLabelText(key, required)}
      selectorClassNames="col-md-6 offset-md-3"
      isDisabled={isDisabled}
    />;
  };

  const renderGuestCategoryDropdown = (key: string, required: boolean, help: boolean, isMulti: boolean): JSX.Element => {
    return <>
      <label className="form-label">{renderLabelText(key, required)}</label>
      <GuestCategoryDropdown
        selectedValue={localSettings[key]}
        isMulti={isMulti}
        isDisabled={isDisabled}
        onChange={updateSettingFromReactSelectDropdownHandler(key, isMulti)}
      />
      { help && <div className="form-text">{renderHelpText(key)}</div> }
    </>;
  };


  const renderAccesspointDropdown = (key: string, required: boolean, help: boolean, isMulti: boolean): JSX.Element => {
    return <>
      <label className="form-label">{renderLabelText(key, required)}</label>
      <AccesspointDropdown
        selectedValue={localSettings[key]}
        isMulti={isMulti}
        isDisabled={isDisabled}
        onChange={updateSettingFromReactSelectDropdownHandler(key, isMulti)}
      />
      { help && <div className="form-text">{renderHelpText(key)}</div> }
    </>;
  };

  const renderLabelDropdown = (key: string, required: boolean, help: boolean, isMulti: boolean): JSX.Element => {
    return <>
      <label className="form-label">{renderLabelText(key, required)}</label>
      <LabelDropdown
        selectedValue={localSettings[key]}
        isMulti={isMulti}
        isDisabled={isDisabled}
        onChange={updateSettingFromReactSelectDropdownHandler(key, isMulti)}
      />
      { help && <div className="form-text">{renderHelpText(key)}</div> }
    </>;
  };

  const renderGuestStatusDropdown = (key: string, required: boolean, help: boolean, isMulti: boolean): JSX.Element => {
    return <>
      <label className="form-label">{renderLabelText(key, required)}</label>
      <GuestStatusDropdown
        selectedValue={localSettings[key]}
        isMulti={isMulti}
        isDisabled={isDisabled}
        onChange={updateSettingFromReactSelectDropdownHandler(key, isMulti)}
      />
      { help && <div className="form-text">{renderHelpText(key)}</div> }
    </>;
  };

  const renderCheckbox = (key: string, help: boolean): JSX.Element => {
    return <Checkbox
      checked={localSettings[key]}
      text={i18n(`${workflowStep.strategy}.${key}`)}
      name={key}
      help={help ? renderHelpText(key) : null}
      disabled={isDisabled}
      onChange={updateSettingFromCheckboxHandler(key)}
    />;
  };

  const renderRadioButtons = (key: string, required: boolean, available_values: string[], help: boolean, defaultValue: string): JSX.Element => {
    const selectedValue = localSettings[key] || defaultValue;
    return <>
      <label className="form-label">{renderLabelText(key, required)}</label>
      {available_values.map((value: string) => {
        return <div className="form-check" key={value}>
          <input className="form-check-input"
            type="radio"
            value={value}
            checked={selectedValue === value}
            disabled={isDisabled}
            onChange={updateSettingFromRadioHandler(key)}
            id={`${key}-${value}`} />
          <label className="form-check-label" htmlFor={`${key}-${value}`}>
            {i18n(`${workflowStep.strategy}.${key}_${value}`)}
          </label>
        </div>;
      })}
      { help && <div className="form-text">{renderHelpText(key)}</div> }
    </>;
  };

  const renderDurationField = (key: string, required: boolean, help: boolean): JSX.Element => {
    const currentDuration = (localSettings[key] || DEFAULT_DURATION);

    return <>
      <label className="form-label">{renderLabelText(key, required)}</label>
      <div className="g-2 row align-items-center">
        <div className="col-auto">
          <input type="number" value={currentDuration.duration} min="1" max="60" onChange={updateSettingFromDurationHandler(key, "duration")} className="form-control" style={{ width: "80px" }} />
        </div>
        <div className="col-auto">
          <select className="form-select" value={currentDuration.unit} onChange={updateSettingFromDurationHandler(key, "unit")}>
            <option value="">{i18n("delay.select_unit")}</option>
            <option value="minutes">{I18n.t("duration_field.minutes")}</option>
            <option value="days">{I18n.t("duration_field.days")}</option>
            <option value="weeks">{I18n.t("duration_field.weeks")}</option>
            <option value="months">{I18n.t("duration_field.months")}</option>
            <option value="years">{I18n.t("duration_field.years")}</option>
          </select>
        </div>
      </div>
      { help && <div className="form-text">{renderHelpText(key)}</div> }
    </>;
  };

  const renderLabelText = (key: string, required: boolean): JSX.Element => {
    const labelText = i18n(`${workflowStep.strategy}.${key}`);
    const requiredSpan = required ? <span className="text-danger">*</span> : null;

    return <>{labelText} {requiredSpan}</>;
  };

  const renderHelpText = (key: string): string => {
    return i18n(`${workflowStep.strategy}.${key}_help`);
  };

  const renderGlobalErrorMessage = (): JSX.Element => {
    if (Object.keys(localErrors).length === 0) return;

    return <span className="text-danger ms-2">
      <i className="fa-regular fa-warning" /> {i18n("global_error_message")}
    </span>;
  };

  const renderSaveButton = (): JSX.Element => {
    if (isDisabled) return;

    return <button type="button" onClick={saveStep} className="btn btn-success" disabled={!workflowStep.hasChanges || isSavingStep}>
      <i className="fa-regular fa-card"></i> {i18n("save")}
    </button>;
  };

  const renderEditDraftButton = (): JSX.Element => {
    if (!isDisabled) return;

    return <button type="button" onClick={onSwitchVersionRequest} className="btn btn-success">
      <i className="fa-regular fa-card"></i> {i18n("edit")}
    </button>;
  };

  if (isFetchingStrategy) {
    return <div className="mb-3">
      <Loader />
    </div>;
  }

  return <>
    <h5 className="mb-3">{i18n("configuration")}</h5>
    <ErrorMessage
      errors={localErrors}
      i18nCustomPath={`react.workflows.settings.${workflowStep.strategy}`}
    />
    {renderSettings()}
    <div className="mb-3">
      {renderSaveButton()}
      {renderEditDraftButton()}
      {renderGlobalErrorMessage()}
    </div>
  </>;
};

export default WorkflowStepSettings;
