import { Component } from "react";
import { connect } from "react-redux";
import Loader from "../../components/shared/Loader.react";
import requiresProps from "../../utils/requiresProps";
import { formatLongDate } from "../../utils/DateUtils";
import { requestEvent } from "../../actions/ImportActionCreators";
import { fetchContact } from "../../actions/ContactsActionCreators";
import { fetchGuest } from "../../actions/GuestListActionCreators";
import { fetchContactFields } from "../../actions/ContactFieldsActionCreators";
import { urlEventId, urlGuestId } from "../../utils/pathUtils";
import { contactRowStyle } from "../../constants/Style";
import { GuestFieldFile, ImageType } from "../../constants/Constants";

const MODELS_FOR_ATTRIBUTES = ["guest", "contact"];
const STATIC_FIELDS_TO_IGNORE = [
  "_id",
  "account_id",
  "guest_metadata",
  "files",
  "avatar_medium",
  "avatar_thumb",
  "encrypted_password",
  "reset_password_token",
  "reset_password_sent_at",
  "confirmation_token",
  "unlock_token",
  "current_sign_in_at",
  "last_sign_in_ip",
  "last_used_provider_identity_at",
  "last_used_provider_identity_id"
];
const DATE_FIELDS = ["created_at", "updated_at"];
const BOOLEAN_FIELDS = ["account_campaign_optin"];
const NOT_FOUND = "not found";

class ContactShow extends Component {

  humanReadbleAttributes(key) {
    const model = MODELS_FOR_ATTRIBUTES.find(model => {
      const attr = I18n.t(`mongoid.attributes.${model}.${key}`, { defaultValue: NOT_FOUND });
      return attr !== NOT_FOUND;
    });
    return model ? I18n.t(`mongoid.attributes.${model}.${key}`) : key;
  }

  valueForField(field) {
    const { contact } = this.props;
    if ([GuestFieldFile, ImageType].includes(field.type)) {
      const file = contact.files && contact.files.find(f => f.filetype === field.key);
      return file && file.url ? <a href={file.url} target="_blank">{ file.filetype }</a> : null;
    }
    const metadata = contact.guest_metadata.find(gm => gm.name == field.key);
    return metadata && metadata.value;
  }

  valueForStandardField(key) {
    const { contact } = this.props;

    if (key == "avatar" && contact.avatar_thumb) {
      return <img src={contact.avatar_thumb} className="rounded-circle" />;
    }

    return contact[key];
  }

  valueForBooleanField(value) {
    return value ? I18n.t("yyes") : I18n.t("nno");
  }

  renderField(key, label, value) {
    const valueElem = value ? value : <i>{ I18n.t("blank") }</i>;
    return (
      <div key={key} style={contactRowStyle}>
        <strong>{ label }</strong> <em className="text-muted">{ key }</em><br />
        { valueElem }
      </div>
    );
  }

  formatContactField(key) {
    const { contact } = this.props;
    let value;

    if (DATE_FIELDS.includes(key)) {
      value = formatLongDate(contact[key]);
    } else if (BOOLEAN_FIELDS.includes(key)) {
      value = this.valueForBooleanField(contact[key]);
    } else {
      value = this.valueForStandardField(key);
    }

    return { key, value, label: this.humanReadbleAttributes(key) };
  }

  renderFields() {
    const { contact, contactFields } = this.props;

    // static fields
    let keyValues = Object.keys(contact).reduce((acc, key) => {
      if (!STATIC_FIELDS_TO_IGNORE.includes(key))
        acc.push(this.formatContactField(key));

      return acc;
    }, []);

    // metadata
    keyValues = (contactFields || []).reduce((acc, field) => {
      const value = this.valueForField(field);
      acc.push({ key: field.key, value, label: field.label });
      return acc;
    }, keyValues);

    return keyValues.map(({ key, label, value }) => this.renderField(key, label, value));
  }

  render() {
    const { contact, contactFields, fetching } = this.props;
    if (!contact || !contactFields || fetching) {
      return <Loader />;
    }
    const fields = this.renderFields();
    return (
      <div>
        { fields }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetching: state.contact.fetching || state.contactFields.fetching
  };
}

const mapDispatchToProps = {
  requestEvent,
  fetchContact,
  fetchGuest,
  fetchContactFields
};

export default connect(mapStateToProps, mapDispatchToProps)(requiresProps(ContactShow, {
  requirements: {
    event: {
      fn: ({ requestEvent }) => requestEvent(),
      desiredState: "has_underscore_id"
    },
    guests: {
      fn: ({ fetchGuest }) => {
        fetchGuest(urlEventId(), urlGuestId());
      },
      statePath: "guests.guests"
    },
    contactFields: {
      waitFor: ["event"],
      fn: ({ fetchContactFields, event }) => fetchContactFields(event.account_id),
      statePath: "contactFields.data"
    },
    contact: {
      waitFor: ["event", "guests"],
      fn: ({ fetchContact, event, guests }) => {
        const guest = guests[0];
        if (guest && guest.contact_id) {
          fetchContact(event.account_id, guest.contact_id);
        }
      },
      statePath: "contact.data"
    }
  }
}));

const ContactShowWithAccountAndContactIds = connect(mapStateToProps, mapDispatchToProps)(requiresProps(ContactShow, {
  requirements: {
    contact: {
      fn: ({ fetchContact, accountId, contactId }) => {
        if (accountId && contactId) {
          fetchContact(accountId, contactId);
        }
      },
      statePath: "contact.data"
    },
    contactFields: {
      fn: ({ fetchContactFields, accountId }) => fetchContactFields(accountId),
      statePath: "contactFields.data"
    }
  }
}));

const ContactShowFromGuestsList = connect(mapStateToProps, mapDispatchToProps)(requiresProps(ContactShow, {
  requirements: {
    contactFields: {
      fn: ({ fetchContactFields, event }) => fetchContactFields(event.account_id),
      statePath: "contactFields.data"
    },
    contact: {
      fn: ({ fetchContact, event, guests }) => {
        const guest = guests[0];
        if (guest && guest.contact_id) {
          fetchContact(event.account_id, guest.contact_id);
        }
      },
      statePath: "contact.data"
    }
  }
}));

export { ContactShowFromGuestsList, ContactShowWithAccountAndContactIds };
