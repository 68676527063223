import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Locales from "date-fns/locale";

const SPECIFIC_MAPPING_I18N_TO_DATEFNS = {
  en: "enUS",
  // there are two official languages in Norway: Bokmål 'nb' and Nynorsk 'nn'
  // and Bokmål is the preferred written standard of Norwegian for 85% to 90% of the population in Norway.
  // https://github.com/date-fns/date-fns/issues/2963#issuecomment-1041913677
  no: "nb",
  zh: "zhCN",
};

interface Props {
  selectedDate: Date;
  minDate?: Date;
  maxDate?: Date;
  isClearable?: boolean;
  className?: string;
  calendarPlacement?: string;
  style?: any;
  onChange(value): void;
}

const LocalizedDatePicker: React.FC<Props> = ({
  selectedDate,
  minDate,
  maxDate,
  isClearable,
  className,
  calendarPlacement,
  style,
  onChange,
}) => {

  const dateFormat = () : string => {
    if (I18n.locale !== "nl") return "P";

    // in date-fns, the date format for the nl locale is: dd-mm-yyyy
    return "MM/dd/yyyy";
  };

  return <DatePicker
    dateFormat={dateFormat()}
    selected={selectedDate}
    onChange={(value): void => onChange(value)}
    minDate={minDate}
    maxDate={maxDate}
    locale={Locales[SPECIFIC_MAPPING_I18N_TO_DATEFNS[I18n.locale] || I18n.locale]}
    isClearable={isClearable}
    className={`form-control ${className || ""}`}
    popperPlacement={calendarPlacement || "bottom"}
    style={style}
    placeholderText={I18n.t("date_format")}
  />;
};

export default LocalizedDatePicker;
