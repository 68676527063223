import { Component } from "react";
import { Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import isObject from "lodash/isObject";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";

import { INVITATION_MODERATION_STATUSES, MOST_USED_VALUES_LIMIT, SearchQueryKeywords, ValueList, BooleanGuestFields } from "../constants/Constants";
import CountrySelect from "./shared/CountrySelect.react";

class UpdateGuestFormComponent extends Component {
  constructor(props) {
    super(props);
    this._onInputChange = this._onInputChange.bind(this);
    this._toggleMostUsedValueSelected = this._toggleMostUsedValueSelected.bind(this);
    this._submit = this._submit.bind(this);
    this._onChange = this._onChange.bind(this);
    this._buildSelectInput = this._buildSelectInput.bind(this);
    this.state = { value: "" };
  }

  _submit(e) {
    this.props.submitAction(this.state.value);
    e.preventDefault();
  }

  _buildCheckboxGroupFromValues(values) {
    let nullValuePresentInTop = false;
    const checkboxGroup = map(values, item => {
      let value = item;
      let labelValue = item;
      if (isObject(item)) {
        value = item._id;
        if (!value) value = item.id;
        labelValue = item.name;
      }
      if (!nullValuePresentInTop && isEmpty(labelValue)) {
        labelValue = <em>{I18n.t("react.reports.blank")}</em>;
        value = "";
        nullValuePresentInTop = true;
      }
      if (!isEmpty(labelValue)) {
        return (
          <Form.Group key={value} controlId={value}>
            <Form.Check type="checkbox" label={labelValue} checked={this.state.value == value } onChange={this._toggleMostUsedValueSelected.bind(this, value)} />
          </Form.Group>
        );
      }
    });
    return checkboxGroup;
  }

  _onInputChange(e) {
    this._onChange(e.target.value);
  }

  _toggleMostUsedValueSelected(value) {
    if (this.state.value == value) {
      this.setState({ value: "" });
    } else {
      this._onChange(value);
    }
  }

  _onChange(selectedItem) {
    const { onChange } = this.props;
    // 'selectedItem' is an object if selected from dropdown but a string when checked from most used values
    const selectedValue = selectedItem && selectedItem.value || selectedItem;
    this.setState({ value: selectedValue });
    if (onChange) {
      onChange(selectedValue);
    }
  }

  _buildSelectInput(options) {
    const selectOptions = map(options, option => {
      return { value: option.value, label: option.label };
    });

    return (
      <Select
        className="react-select"
        classNamePrefix="react-select"
        name="guest-update-select"
        value={selectOptions.filter(({ value }) => value === this.state.value)}
        placeholder={this.props.selectPlaceholder}
        options={selectOptions}
        onChange={this._onChange}
        noOptionsMessage={() => I18n.t("react.reports.no_options_selectable")}
        menuPlacement="auto"
        maxMenuHeight="200px"
      />
    );
  }

  guestsCountLabel(count) {
    if (!count)
      count = 0;

    return (
      <span>
        <strong>{count}</strong> {I18n.t("react.reports.guests_will_be_updated", { count: count })}
      </span>
    );
  }

  renderTopValues() {
    const { eventFieldMostUsedValues, searchFieldMostUsedValues, noMostUsedValues, fieldKey } = this.props;
    if (noMostUsedValues || BooleanGuestFields.includes(fieldKey))
      return null;

    const eventMostUsedValuesCheckboxes = this._buildCheckboxGroupFromValues(eventFieldMostUsedValues);
    const searchMostUsedValuesCheckboxes = this._buildCheckboxGroupFromValues(searchFieldMostUsedValues);
    const mostUsedInEventTooltip = <Tooltip id="most-used-in-event-tooltip">{ I18n.t("react.reports.guest_fields_most_used_in_event_help") }</Tooltip>;
    const mostUsedInSearchTooltip = <Tooltip id="most-used-in-search-tooltip">{ I18n.t("react.reports.guest_fields_most_used_in_searches_help") }</Tooltip>;

    return (
      <div className="row">
        <div className="col-md-6">
          <label className="form-label">
            Top {MOST_USED_VALUES_LIMIT} {I18n.t("react.reports.in_event")}{ " " }
            <OverlayTrigger placement="right" overlay={mostUsedInEventTooltip}>
              <i className="fa-regular fa-question-circle"></i>
            </OverlayTrigger>
          </label>
          {eventMostUsedValuesCheckboxes}
        </div>
        <div className="col-md-6">
          <label className="form-label">
            Top {MOST_USED_VALUES_LIMIT} {I18n.t("react.reports.in_search")}{ " " }
            <OverlayTrigger placement="right" overlay={mostUsedInSearchTooltip}>
              <i className="fa-regular fa-question-circle"></i>
            </OverlayTrigger>
          </label>
          {searchMostUsedValuesCheckboxes}
        </div>
      </div>
    );
  }

  valueInput(fieldKey, fieldType, fieldLabel, availableValues, options) {
    const { value } = this.state;
    const countryLabels = [SearchQueryKeywords.COUNTRY, SearchQueryKeywords.COUNTRY_NAME];
    if (!isEmpty(options)) {
      return this._buildSelectInput(options, fieldLabel);
    }
    if (fieldType == ValueList) {
      return (
        <select className="form-select" value={value} onChange={this._onInputChange}>
          { availableValues.map(e =>
            <option value={e.value} key={e.value}>{e.label}</option>)
          }
        </select>
      );
    }
    if (countryLabels.includes(fieldKey)) {
      return (
        <CountrySelect locale={I18n.locale} value={value} onChange={this._onInputChange}/>
      );
    }
    if (BooleanGuestFields.includes(fieldKey)) {
      return (
        <select className="form-select" value={value} onChange={this._onInputChange}>
          <option value="" key="">{I18n.t("react.reports.select")}</option>
          { [{ value: true, label: I18n.t("yyes") }, { value: false, label: I18n.t("nno") }].map(obj =>
            <option value={obj.value} key={obj.value}>{obj.label}</option>)
          }
        </select>
      );
    }
    if (fieldKey === "invitation_moderation_status") {
      return (
        <select className="form-select" value={value} onChange={this._onInputChange}>
          <option value="" key="">{I18n.t("react.reports.select")}</option>
          {
            INVITATION_MODERATION_STATUSES.map(status => <option value={status} key={status}>{I18n.t(`invitation_moderation_status_${status}`)}</option>)
          }
        </select>
      );
    }

    return (
      <Form.Control
        type="text"
        value={value}
        onChange={this._onInputChange}
      />
    );
  }

  render() {
    const { options, selectedGuestCount, fieldLabel, submitAction, children, noMostUsedValues, fieldKey, fieldType, availableValues, enableSubmitButton } = this.props;
    const { value } = this.state;
    const input = this.valueInput(fieldKey, fieldType, fieldLabel, availableValues, options);
    const disabled = !value && (!enableSubmitButton || BooleanGuestFields.includes(fieldKey));
    const submitButton = submitAction && <Button type="submit" className="btn btn-primary float-end" disabled={disabled}> {I18n.t("submit")} </Button>;

    return (
      <form onSubmit={this._submit}>
        <div className="row">
          <div className="col-lg-12">
            <Form.Group>
              <Form.Label>{fieldLabel}</Form.Label>
              {input}
            </Form.Group>
          </div>
        </div>
        <hr />
        { this.renderTopValues() }
        { noMostUsedValues || BooleanGuestFields.includes(fieldKey) || <hr /> }
        { this.guestsCountLabel(selectedGuestCount) }
        <hr />
        {children}
        {submitButton}
      </form>
    );
  }
}

export default UpdateGuestFormComponent;
export { UpdateGuestFormComponent };
