import { ActionTypes } from "../constants/Constants";
import { BlogArticleStore } from "../types/BlogArticle";

const defaultState: BlogArticleStore = {
  data: null,
  errors: {},
  isFetching: false,
  isSaving: false
};

function blogArticle(state = defaultState, action: any): BlogArticleStore {
  switch (action.type) {
  case ActionTypes.FETCH_BLOG_ARTICLE_PENDING:
    return { ...state, data: null, isFetching: true, errors: {} };
  case ActionTypes.FETCH_BLOG_ARTICLE_SUCCESS:
    return { ...state, data: action.data, isFetching: false };
  case ActionTypes.CREATE_BLOG_ARTICLE_PENDING:
  case ActionTypes.UPDATE_BLOG_ARTICLE_PENDING:
  case ActionTypes.DELETE_BLOG_ARTICLE_PENDING:
    return { ...state, isSaving: true };
  case ActionTypes.CREATE_BLOG_ARTICLE_SUCCESS:
  case ActionTypes.UPDATE_BLOG_ARTICLE_SUCCESS:
  case ActionTypes.DELETE_BLOG_ARTICLE_SUCCESS:
    return { ...defaultState, data: action.data };
  case ActionTypes.CREATE_BLOG_ARTICLE_FAILURE:
  case ActionTypes.UPDATE_BLOG_ARTICLE_FAILURE:
  case ActionTypes.DELETE_BLOG_ARTICLE_FAILURE:
    return { ...state, isSaving: false, errors: action.data.errors };
  case ActionTypes.CLEAR_BLOG_ARTICLE:
    return { ...defaultState };
  default:
    return state;
  }
}

export default blogArticle;
