import { Component } from "react";
import PropTypes from "prop-types";
import { Overlay, Tooltip, Popover } from "react-bootstrap";
import { defaultModalSmallStyles } from "../../constants/Style";
import Modal from "react-modal";
import { urlEventId } from "../../utils/pathUtils";
import GenericS3Uploader from "../shared/GenericS3Uploader.react";
import { AssetsManagersSelectionMode } from "../../containers/AssetsManagerPane.react";

const NAME_MAX_LENGTH = 200;

class AssetsGrid extends Component {

  constructor(props) {
    super(props);

    [
      "closeConfirmationDialog",
      "deleteAsset",
      "onCancelEditing",
      "onChangeInputEditingName",
      "onClickDeleteButton",
      "onClickRenameButton",
      "onEnterEditingName",
      "onMouseEnterActionButton",
      "onMouseEnterFile",
      "onMouseLeaveFile",
      "onSubmitEditing"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      currentFileMouseOver: null,
      targetOverlay: null,
      informationInOverlay: null,
      isEditingName: false,
      deletingAssetId: null,
      editingName: "",
      isShowConfirmationDialog: false,
      selectedAssetId: null
    };
  }

  componentDidMount() {
    const { assetId, selectionMode } = this.props;
    if (selectionMode === AssetsManagersSelectionMode.Asset) {
      this.setState({ selectedAssetId: assetId });
    }
  }

  onClickAsset(assetId) {
    return () => {
      const { selectionMode, onSelectAsset } = this.props;
      if (selectionMode === AssetsManagersSelectionMode.Asset) {
        this.setState({ selectedAssetId: assetId });
        onSelectAsset(assetId);
      }
    };
  }

  checkIfEditingOrDeletingFile() {
    const { isEditingName, deletingAssetId } = this.state;
    return isEditingName || !!deletingAssetId;
  }

  onMouseEnterFile(file, { target }) {
    if (this.checkIfEditingOrDeletingFile()) return;

    this.setState({
      currentFileMouseOver: file,
      targetOverlay: target,
      informationInOverlay: file.name
    });
  }

  onMouseLeaveFile() {
    if (this.checkIfEditingOrDeletingFile()) return;

    this.setState({
      currentFileMouseOver: null,
      informationInOverlay: null
    });
  }

  onMouseEnterActionButton(file, { target }, information) {
    if (this.checkIfEditingOrDeletingFile()) return;

    this.setState({
      currentFileMouseOver: file,
      targetOverlay: target,
      informationInOverlay: information
    });
  }

  onClickRenameButton({ target }) {
    if (this.checkIfEditingOrDeletingFile()) return;

    this.setState({
      targetOverlay: target,
      informationInOverlay: null,
      isEditingName: true
    });
  }

  onClickDeleteButton(assetId) {
    return () => {
      if (this.checkIfEditingOrDeletingFile()) return;

      this.showConfirmationDialog(assetId);
    };
  }

  onChangeInputEditingName(e) {
    this.setState({ editingName: e.target.value });
  }

  onSubmitEditing(e) {
    e.preventDefault();
    const { editingName, currentFileMouseOver } = this.state;
    const { updateAsset, assetsFolderId } = this.props;

    if (document.formEditingName.name.value != "") {
      updateAsset(
        urlEventId(),
        assetsFolderId,
        currentFileMouseOver._id,
        { name: editingName }
      );
      this.setState({ isEditingName: false });
    }
  }

  onCancelEditing() {
    this.setState({ isEditingName: false, currentFileMouseOver: null });
  }

  onEnterEditingName(name) {
    this.setState({ editingName: name });
  }

  deleteAsset() {
    const { deletingAssetId } = this.state;
    const { deleteAsset, assetsFolderId } = this.props;
    deleteAsset(
      urlEventId(),
      assetsFolderId,
      deletingAssetId
    );
    this.closeConfirmationDialog();
  }

  popoverEditingFileName() {
    const { editingName } = this.state;
    const styleNearMaxLength = editingName.length >= NAME_MAX_LENGTH - 10 ? { color: "red" } : {};

    return (
      <Popover
        className="assets-prompt-editing"
        id="popoverId"
        title={ I18n.t("react.assets_manager.sliding_pane_form.popover_rename.title") }
      >
        <form name="formEditingName" onSubmit={this.onSubmitEditing}>
          <fieldset>
            <div className="input-wrapper">
              <input
                type="text"
                id="name"
                value={editingName}
                placeholder={ I18n.t("react.assets_manager.sliding_pane_form.popover_rename.placeholder") }
                maxLength={NAME_MAX_LENGTH}
                autoFocus
                onChange={this.onChangeInputEditingName}
                className="form-control"
              />
              <div className="input-status" style={ styleNearMaxLength }>{`${editingName.length}/${NAME_MAX_LENGTH}`}</div>
            </div>
            <div className="footer">
              <button type="button" className="btn btn-secondary" onClick={this.onCancelEditing} style={{ marginRight: "5px" }}>
                { I18n.t("cancel") }
              </button>
              <button type="submit" className="btn btn-primary">
                { I18n.t("save") }
              </button>
            </div>
          </fieldset>
        </form>
      </Popover>
    );
  }

  renderOverlays() {
    const { currentFileMouseOver, targetOverlay, informationInOverlay, isEditingName, deletingAssetId } = this.state;
    const isFileSelected = currentFileMouseOver ? true : false;

    return [
      !(isEditingName || deletingAssetId) && <Overlay
        key="over1"
        show={isFileSelected}
        delay={{ show: 250, hide: 400 }}
        placement="top"
        target={targetOverlay}
        className="assets-overlay-name"
      >
        { this.informationTooltip(informationInOverlay) }
      </Overlay>,
      isFileSelected && <Overlay
        key="over2"
        show={isEditingName}
        delay={{ show: 250, hide: 400 }}
      >
        <div className="assets-backdrop-style"></div>
      </Overlay>,
      isFileSelected && <Overlay
        key="over3"
        show={isEditingName}
        delay={{ show: 250, hide: 400 }}
        placement="top"
        target={targetOverlay}
        onEnter={() => this.onEnterEditingName(currentFileMouseOver.name)}
      >
        { this.popoverEditingFileName() }
      </Overlay>
    ];
  }

  renderActionButtons(asset) {
    return (
      <div
        className="info-overlay"
        onMouseEnter={e => this.onMouseEnterFile(asset, e)}
        onMouseLeave={this.onMouseLeaveFile}
      >
        <a
          className="info-overlay-action fa-regular fa-eye btn"
          onMouseEnter={e => {
            this.onMouseEnterActionButton(asset, e, I18n.t("react.assets_manager.sliding_pane_form.action_button.preview"));
          }}
          target="_BLANK"
          href={asset.file_url}
        />
        <span
          className="info-overlay-action fa-regular fa-pencil"
          onMouseEnter={e => this.onMouseEnterActionButton(asset, e, I18n.t("edit"))}
          onClick={this.onClickRenameButton}
        />
        <span
          className="info-overlay-action fa-regular fa-trash-can btn"
          onMouseEnter={e => this.onMouseEnterActionButton(asset, e, I18n.t("remove"))}
          onClick={this.onClickDeleteButton(asset._id)}
        />
      </div>
    );
  }

  renderAssetContent(asset) {
    const isDisplayImage = RegExp(/(jpg|jpeg|png|gif)/i).test(asset.file_type);
    if (isDisplayImage) return <img src={asset.file_url} alt={asset.name} />;

    return (
      <div className="assets-file text-center">
        <div className="mb-5">
          <i className="fa-regular fa-file"></i>
          <span>{asset.file_type.toUpperCase()}</span>
        </div>
        {asset.name}
      </div>
    );
  }

  renderAssetsList() {
    const { assets, selectionMode } = this.props;
    const { selectedAssetId } = this.state;
    if (!assets || assets.length === 0) return;

    let selectedAssetStyle = {};
    return (
      assets.map((asset, index) => {
        selectedAssetStyle = (selectionMode === AssetsManagersSelectionMode.Asset) && (asset._id === selectedAssetId)
          ? { border: "3px solid #009688" }
          : {};
        return (
          <figure key={index} className="assets-list-item" style={selectedAssetStyle} onClick={this.onClickAsset(asset._id)}>
            { this.renderAssetContent(asset) }
            { this.renderActionButtons(asset) }
          </figure>
        );
      })
    );
  }

  s3UploadURL() {
    const { assetsFolderId } = this.props;
    return `/api/v1/events/${urlEventId()}/website/assets_folders/${assetsFolderId}/s3_upload_url`;
  }

  renderGrid() {
    const { assets, createAsset, acceptedFiles } = this.props;
    let contentAssetsList = null;

    if (assets && assets.length !== 0)
      contentAssetsList = [
        this.renderOverlays(),
        this.renderAssetsList()
      ];

    return (
      <div className="assets-list">
        <GenericS3Uploader
          acceptedFiles={ acceptedFiles }
          className="assets-list-item"
          classNameUploader="assets-uploader-zone"
          classNameWrapper="assets-list-item uploader-item"
          onFinishUpload={ createAsset }
          s3UploadURL={ this.s3UploadURL() }
        >
          <span className="uploader-label text-center d-flex flex-column">
            <i className="fa-regular fa-folder-open mb-5"></i>
            { I18n.t("react.assets_manager.sliding_pane_form.browse_files") }
          </span>
        </GenericS3Uploader>
        { contentAssetsList }
      </div>
    );
  }

  informationTooltip(message) {
    return (
      <Tooltip id="tooltip">{message}</Tooltip>
    );
  }

  showConfirmationDialog(assetId) {
    this.setState({ isShowConfirmationDialog: true, deletingAssetId: assetId });
  }

  closeConfirmationDialog() {
    this.setState({ isShowConfirmationDialog: false, deletingAssetId: null });
  }

  renderConfirmationDialog() {
    const { isShowConfirmationDialog } = this.state;

    return (
      <Modal isOpen={isShowConfirmationDialog} style={defaultModalSmallStyles} contentLabel="Modal">
        <div className="modal-body">
          <b>{ I18n.t("react.assets_manager.sliding_pane_form.modal_delete.message") }</b>
        </div>
        <div className="modal-footer">
          <button className="btn btn-secondary" onClick={this.closeConfirmationDialog}>
            { I18n.t("cancel") }
          </button>
          <button className="btn btn-primary" onClick={this.deleteAsset} autoFocus>
            { I18n.t("remove") }
          </button>
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        {this.renderGrid()}
        {this.renderConfirmationDialog()}
      </div>
    );
  }
}

AssetsGrid.propTypes = {
  acceptedFiles: PropTypes.array,
  assetId: PropTypes.string,
  assets: PropTypes.array.isRequired,
  assetsFolderId: PropTypes.string.isRequired,
  createAsset: PropTypes.func.isRequired,
  deleteAsset: PropTypes.func.isRequired,
  onSelectAsset: PropTypes.func,
  selectionMode: PropTypes.string.isRequired,
  updateAsset: PropTypes.func.isRequired,
};

export default AssetsGrid;
