import { ActionTypes, CALL_API } from "../constants/Constants";
import { stringify } from "querystring";

export function fetchThematics(eventId, options = {}, byEventId = null) {
  const { DEV_NULL, RECEIVE_THEMATICS_REQUEST, RECEIVE_THEMATICS_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [RECEIVE_THEMATICS_REQUEST, RECEIVE_THEMATICS_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/thematics.json?${stringify(options)}`
    },
    byEventId
  };
}

export function createThematic(eventId, name, color, parentThematicId = null, temporaryId = null) {
  const { CREATE_THEMATIC_REQUEST, CREATE_THEMATIC_SUCCESS, THEMATIC_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_THEMATIC_REQUEST, CREATE_THEMATIC_SUCCESS, THEMATIC_FAILURE],
      method: "POST",
      body: { thematic: { name, color, parent_thematic_id: parentThematicId } },
      endpoint: `/api/v1/events/${eventId}/thematics.json`
    },
    temporaryId
  };
}

export function updateThematic(eventId, thematicId, name, color) {
  const { UPDATE_THEMATIC_REQUEST, UPDATE_THEMATIC_SUCCESS, THEMATIC_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_THEMATIC_REQUEST, UPDATE_THEMATIC_SUCCESS, THEMATIC_FAILURE],
      method: "PUT",
      body: { thematic: { name, color } },
      endpoint: `/api/v1/events/${eventId}/thematics/${thematicId}.json`
    },
    name,
    color,
    thematicId
  };
}

export function deleteThematic(eventId, thematicId) {
  const { DEV_NULL, REMOVE_THEMATIC_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, REMOVE_THEMATIC_SUCCESS, DEV_NULL],
      method: "DELETE",
      endpoint: `/api/v1/events/${eventId}/thematics/${thematicId}.json`
    },
    thematicId
  };
}

export function setSelectedThematicIds(ids) {
  return {
    type: ActionTypes.SET_SELECTED_THEMATIC_IDS,
    objectIds: ids
  };
}

export function clearThematicErrors() {
  return dispatch => {
    dispatch({
      type: ActionTypes.CLEAR_THEMATIC_ERRORS,
    });
  };
}
