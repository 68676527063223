import { ActionTypes } from "../constants/Constants";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";

const defaultState = {
  data: null,
  pendingWriteRequest: false,
  errors: null,
  selectedColumnsSetId: null
};

function columnsSets(state = defaultState, action) {
  switch (action.type) {
  case ActionTypes.FETCH_COLUMNS_SETS_REQUEST:
    return { ...state, errors: null, data: null };
  case ActionTypes.FETCH_COLUMNS_SETS_SUCCESS:
    return { ...state, errors: null, data: action.data, selectedColumnsSetId: state.selectedColumnsSetId || action.data[0] && action.data[0]._id };
  case ActionTypes.FETCH_COLUMNS_SETS_FAILURE:
    return { ...state, errors: action.data, data: null };
  case ActionTypes.CHANGE_SELECTED_COLUMNS_SET_ID:
    return { ...state, selectedColumnsSetId: action.data, errors: null };
  case ActionTypes.UPDATE_COLUMNS_SET_REQUEST:
  case ActionTypes.CREATE_COLUMNS_SET_REQUEST:
  case ActionTypes.DELETE_COLUMNS_SET_REQUEST:
    return { ...state, pendingWriteRequest: true, errors: null };
  case ActionTypes.UPDATE_COLUMNS_SET_SUCCESS:
  case ActionTypes.CREATE_COLUMNS_SET_SUCCESS: {
    const data = insertOrUpdateObject((state.data || []), action.data, "_id");
    return { ...state, errors: null, data, pendingWriteRequest: false, selectedColumnsSetId: action.data._id };
  }
  case ActionTypes.DELETE_COLUMNS_SET_SUCCESS: {
    const data = state.data.filter(item => item._id !== action.columnSetId);
    return { ...state, errors: null, data, pendingWriteRequest: false, selectedColumnsSetId: data[0] && data[0]._id };
  }
  case ActionTypes.UPDATE_COLUMNS_SET_FAILURE:
  case ActionTypes.CREATE_COLUMNS_SET_FAILURE:
  case ActionTypes.DELETE_COLUMNS_SET_FAILURE:
    return { ...state, errors: action.data, pendingWriteRequest: false };
  default:
    return state;
  }
}

export default columnsSets;
