import { useState, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { urlEventId } from "../../utils/pathUtils";
import { GuestProductCollection } from "../../types/GuestProductCollection";
import {
  deleteGuestProductCollection,
  fetchItemsCollectionCount,
  clearGuestProductCollectionErrors,
  exportItemsCollection
} from "../../actions/GuestProductCollectionActionCreators";
import DeleteConfirmationModal from "../../components/shared/DeleteConfirmationModal.react";
import HelpSection from "../../components/shared/HelpSection.react";
import ErrorMessage from "../../components/shared/ErrorMessage.react";

interface Props {
  guestProductCollections: GuestProductCollection[];
  errors?: string[];
  notice?: string;

  toggleSlidingPane(): void;
  setCurrentGuestProductCollection(collection: GuestProductCollection): void;
  setGuestProductCollectionName(name: string): void;
}

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`events.edit_advanced.guest_product_collections.${key}`, options);
};

const GuestProductCollectionsTable: FC<Props> = ({
  guestProductCollections,
  errors,
  notice,
  toggleSlidingPane,
  setCurrentGuestProductCollection,
  setGuestProductCollectionName,
}) => {
  const itemsCollectionCount: number = useSelector((state: any) => state.guestProductCollection.itemsCollectionCount);
  const exportNotice: boolean = useSelector((state: any) => state.guestProductCollection.exportNotice);
  const [collectionToDestroy, setCollectionToDestroy] = useState(null);

  const dispatch = useDispatch();

  const openDeleteCollectionModal = (guestProductCollection: GuestProductCollection): any => {
    setCurrentGuestProductCollection(guestProductCollection);
    setCollectionToDestroy(guestProductCollection);
    dispatch(fetchItemsCollectionCount(urlEventId(), guestProductCollection._id));
  };

  const exportCollection = (guestProductCollection: GuestProductCollection): any => {
    setCurrentGuestProductCollection(guestProductCollection);
    const id = guestProductCollection._id || "default";
    dispatch(exportItemsCollection(urlEventId(), id));
  };

  const closeDeleteCollectionModal = (): void => {
    setCollectionToDestroy(null);
  };

  const handleDelete = (guestProductCollection: GuestProductCollection): any => {
    return (): void => {
      dispatch(deleteGuestProductCollection(urlEventId(), guestProductCollection._id));
      closeDeleteCollectionModal();
    };
  };

  const clearAlerts = (currentGuestProductCollection: GuestProductCollection): void => {
    if (errors || notice) {
      dispatch(clearGuestProductCollectionErrors());
      // Remove unsaved data
      currentGuestProductCollection.collection_fields = currentGuestProductCollection.collection_fields?.filter(field => field._id);
    }

    setCurrentGuestProductCollection(currentGuestProductCollection);
  };

  const openSlidingPane = (currentGuestProductCollection: GuestProductCollection): void => {
    clearAlerts(currentGuestProductCollection);
    setGuestProductCollectionName(currentGuestProductCollection.name);
    toggleSlidingPane();
  };

  const renderDeleteCollectionModal = (): JSX.Element => {
    if (!collectionToDestroy) return null;

    return <DeleteConfirmationModal
      isVisible={!!collectionToDestroy}
      type="collection"
      count={itemsCollectionCount}
      i18nPrefix="events.edit_advanced.guest_product_collections.modal_confirmation_delete"
      onConfirm={handleDelete(collectionToDestroy)}
      onClose={closeDeleteCollectionModal} />;
  };

  const renderShowButton = (guestProductCollection: GuestProductCollection): JSX.Element => {
    return <button type="button" className="btn btn-sm btn-secondary" onClick={(): void => openSlidingPane(guestProductCollection)}>
      <i className="fa-regular fa-eye"></i> {i18n("show")}
    </button>;
  };

  const renderEditButton = (guestProductCollection: GuestProductCollection): JSX.Element => {
    return <button type="button" className="btn btn-sm btn-secondary" onClick={(): void => openSlidingPane(guestProductCollection)}>
      <i className="fa-regular fa-pencil"></i> {I18n.t("edit")}
    </button>;
  };

  const renderConfigButton = (guestProductCollection: GuestProductCollection): JSX.Element => {
    return guestProductCollection.isDefault ? renderShowButton(guestProductCollection) : renderEditButton(guestProductCollection);
  };

  const renderExportButton = (guestProductCollection: GuestProductCollection): JSX.Element => {
    return <button type="button" className="btn btn-sm btn-secondary ml-5" onClick={(): void => exportCollection(guestProductCollection)}>
      <i className="fa-regular fa-upload"></i> {I18n.t("export")}
    </button>;
  };
  const renderDeleteButton = (guestProductCollection: GuestProductCollection): JSX.Element => {
    if (guestProductCollection.isDefault || !window.ReactGlobalProps.super_admin) return null;

    return <button type="button" className="btn btn-sm btn-danger ml-5" onClick={(): void => openDeleteCollectionModal(guestProductCollection)}>
      <i className="fa-regular fa-trash-can"></i> {I18n.t("destroy")}
    </button>;
  };

  const renderTableRows = (): JSX.Element[] => {
    return guestProductCollections.map((guestProductCollection, index) => {
      return <tr key={index}>
        <td>{guestProductCollection.name} {guestProductCollection.isDefault && <span className="form-text">({i18n("default_collection")})</span>} </td>
        <td>
          {renderConfigButton(guestProductCollection)}
          {renderExportButton(guestProductCollection)}
          {renderDeleteButton(guestProductCollection)}
        </td>
      </tr>;
    });
  };

  const renderHintMsg = (): JSX.Element => {
    return <HelpSection help={i18n("collections_help")} classNames="d-inline-block" />;
  };

  const renderNotice = (): JSX.Element => {
    if (!exportNotice && !errors) return;

    return <ErrorMessage
      timeout={5000}
      model="guest_category"
      errorType={errors ? "danger" : "info"}
      errors={errors || I18n.t("export_will_be_sent_by_email")}
    />;
  };

  const renderTableCollections = (): JSX.Element => {
    if (guestProductCollections.length === 0) return null;

    return <div className="table-responsive table-container">
      <table className="table table-light table-hover table-bordered">
        <thead>
          <tr>
            <th className="col-md-8">{ I18n.t("name") }</th>
            <th className="col-md-4">{ I18n.t("actions") }</th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows()}
        </tbody>
      </table>
    </div>;
  };

  return <>
    {renderDeleteCollectionModal()}
    {renderHintMsg()}
    {renderNotice()}
    {renderTableCollections()}
  </>;
};

export default GuestProductCollectionsTable;
