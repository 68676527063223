import { Component } from "react";
import { connect } from "react-redux";
import querystring from "querystring";
import { requestEvent } from "../../actions/ImportActionCreators";
import AccesspointForm from "../../components/shared/AccesspointForm.react";
import { fetchStreamingArchives } from "../../actions/StreamingArchivesManagerActionCreator";
import { fetchAccesspoint, createAccesspoint, updateAccesspoint, fetchAccesspointsCustomValuesForSessions } from "../../actions/AccesspointActionCreators";
import { fetchGuestCategories } from "../../actions/GuestCategoryActionCreators";
import { showNotice } from "../../actions/NoticeActionCreators";
import { pathToAccesspointIndex, pathToSessionsList, pathToProductsList, pathToAccesspointEdit, pathToAccommodationsList, pathToMeetingsList, pathToBundlesList } from "../../utils/pathUtils";

class AccesspointFormContainer extends Component {
  constructor(props) {
    super(props);
    const methods = [
      "onSubmit"
    ];
    methods.forEach((item) => {
      this[item] = this[item].bind(this);
    });
  }

  componentDidMount() {
    const { fetchAccesspoint, fetchGuestCategories, fetchAccesspointsCustomValuesForSessions, match, requestEvent } = this.props;
    const { params } = match;

    requestEvent();
    if (params.accesspoint_id) {
      fetchAccesspoint(params.event_id, params.accesspoint_id, ["roles", "live_video_started_at", "accesspoint_gates", "accesspoint_bundle", "website_live_page_url"]);
    }
    fetchAccesspointsCustomValuesForSessions(params.event_id);
    fetchGuestCategories(params.event_id);
  }

  onSubmit(accesspointFormData, type, redirectAfterSave = true, editUrlQueryString = "") {
    const { createAccesspoint, match, updateAccesspoint } = this.props;
    const { params } = match;
    const isEdit = !!params.accesspoint_id;
    const redirectTo = redirectAfterSave ? this.afterSubmitPath(type) : (isEdit ? null : `${pathToAccesspointEdit(":id")}${editUrlQueryString}`);
    const notificationOptions = { notice: I18n.t("react.accesspoint.form.saved_successfully"), noticeType: "success" };
    const include = ["roles", "accesspoint_gates", "website_live_page_url"];
    const urlOptions = { locale: params.locale, "include[]": type === "bundle" ? [...include, "accesspoint_bundle"] : include };

    if (!isEdit) {
      createAccesspoint(params.event_id, accesspointFormData, urlOptions, true, redirectTo, notificationOptions);
    } else {
      updateAccesspoint(params.event_id, params.accesspoint_id, accesspointFormData, urlOptions, true, redirectTo, notificationOptions);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  afterSubmitPath(type) {
    if (type === "session")
      return pathToSessionsList();
    else if (type === "product")
      return pathToProductsList();
    else if (type === "accommodation")
      return pathToAccommodationsList();
    else if (type === "meeting")
      return pathToMeetingsList();
    else if (type === "bundle")
      return pathToBundlesList();
    else
      return pathToAccesspointIndex();
  }

  render() {
    const { accesspointErrors, match, location, guestCategories, customSessionTypes, customLocations,
      notice, noticeType, customRoomTypes, event, currentAccesspoint, streamingArchives, isFetchingStreamingArchives,
      fetchStreamingArchives } = this.props;
    const { params } = match;
    const queryParams = querystring.parse(location.search.substring(1));
    const accesspoint = currentAccesspoint || {};

    return <AccesspointForm accesspoint={accesspoint}
      isModal={false}
      errors={accesspointErrors}
      onSubmit={this.onSubmit}
      isEdit={!!params.accesspoint_id}
      guestCategories={guestCategories}
      customSessionTypes={customSessionTypes}
      customLocations={customLocations}
      customRoomTypes={customRoomTypes}
      defaultType={queryParams.type || accesspoint.type}
      queryParams={queryParams}
      notice={notice}
      noticeType={noticeType}
      event={event}
      streamingArchives={streamingArchives}
      isFetchingStreamingArchives={isFetchingStreamingArchives}
      fetchStreamingArchives={fetchStreamingArchives}
    />;
  }
}

function mapStateToProps(state) {
  const { streamingArchives, isFetching } = state.streamingArchives;

  return {
    accesspointErrors: state.accesspoints.errors,
    customSessionTypes: state.accesspoints.customSessionTypes,
    customLocations: state.accesspoints.customLocations,
    customRoomTypes: state.accesspoints.customRoomTypes,
    guestCategories: state.guestCategories.data,
    notice: state.notifications.currentNotice,
    noticeType: state.notifications.noticeType,
    event: state.event,
    currentAccesspoint: state.accesspoints.currentAccesspoint,
    streamingArchives,
    isFetchingStreamingArchives: isFetching,
  };
}

const mapDispatchToProps = {
  fetchAccesspoint,
  createAccesspoint,
  updateAccesspoint,
  fetchAccesspointsCustomValuesForSessions,
  showNotice,
  fetchGuestCategories,
  requestEvent,
  fetchStreamingArchives,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccesspointFormContainer);
