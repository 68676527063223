import { Component } from "react";

import DirectEmailModal from "./DirectEmailModal.react";

class DirectEmail extends Component {
  constructor(props) {
    super(props);

    this.triggerModal = this.triggerModal.bind(this);

    this.state = {
      showModal: false
    };
  }

  triggerModal() {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal
    });
  }

  render() {
    const { showModal } = this.state;
    const { children } = this.props;
    return (
      <>
        <div onClick={this.triggerModal}>
          {children}
        </div>
        <DirectEmailModal {...this.props} isVisible={showModal} close={this.triggerModal} />
      </>
    );
  }
}

export default DirectEmail;
