import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import CreateGuestCategoryModal from "../shared/CreateGuestCategoryModal.react";
import Loader from "../shared/Loader.react";
import GuestCategoryForm from "../shared/GuestCategoryForm.react";
import isEqual from "lodash/isEqual";

class GuestCategoryModal extends Component {
  constructor(props) {
    super(props);
    [
      "renderSelectOfGuestCategories",
      "toogleOption",
      "onChangeGuestCategory",
      "submit",
      "displayCreateGuestCategoryModal",
      "convertMappingToServerData",
      "submitCreateGuestCategory",
      "renderCreateGuestCategory",
      "renderDistinctValues",
      "onCloseCreateGuestCategory"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = { overrideGuestCategoryOfExistingGuests: false, mappingGuestCategory: {}, showCreateGuestCategoryModal: false, currentField: null };
  }

  componentDidMount() {
    const { baseMapping } = this.props;
    if (baseMapping) {
      let mappingGuestCategory = {};
      Object.keys(baseMapping).forEach(key => {
        baseMapping[key].forEach(value => {
          mappingGuestCategory[value] = key;
        });
      });
      this.setState({ mappingGuestCategory: mappingGuestCategory });
    }
  }

  componentDidUpdate(prevProps) {
    const { distinctValues, guestCategories } = this.props;
    if (!prevProps.useBaseMapping && !isEqual(prevProps.distinctValues, distinctValues)) {
      //fill the mapping guest category
      let newMappingGuestCategory = {};
      if (distinctValues) {
        distinctValues.forEach((value) => {
          let id = value;
          let name = value;
          if (value.constructor == Array) {
            id = value[0];
            name = value[1];
          }
          const existingGuestCategory = guestCategories.find(gc => name.toLowerCase() === gc.name.toLowerCase());
          if (existingGuestCategory) {
            newMappingGuestCategory[id] = existingGuestCategory._id;
          }
        });
      }
      this.setState({ mappingGuestCategory: newMappingGuestCategory });
    }
  }

  displayCreateGuestCategoryModal(field) {
    return () => {
      this.setState({ showCreateGuestCategoryModal: true, currentField: field });
    };
  }

  renderDistinctValues() {
    const { distinctValues } = this.props;
    return distinctValues.map((value) => {
      let id = value;
      let name = value;
      if (value.constructor == Array) {
        id = value[0];
        name = value[1];
      }
      return (
        <li key={id} className="row">
          <div className="value d-flex align-items-center justify-content-end col-md-3">
            <span>
              {name} <i className="fa-regular fa-arrow-right map-to ml-10"></i>
            </span>
          </div>

          {this.renderSelectOfGuestCategories(id)}

          <div className="d-flex align-items-center col-md-4">
            <strong>{I18n.t("or")}</strong>
            <a className="ml-10" href="#" onClick={this.displayCreateGuestCategoryModal(id)}>{I18n.t("guest_import_operations.modal_guest_category.create_category")}</a>
          </div>
        </li>
      );
    });
  }

  submitCreateGuestCategory(guestCategory) {
    const { mappingGuestCategory, currentField } = this.state;
    const newMappingGuestCategory = Object.assign({}, mappingGuestCategory);
    newMappingGuestCategory[currentField] = guestCategory._id;
    this.setState({ mappingGuestCategory: newMappingGuestCategory });
    this.onCloseCreateGuestCategory();
  }

  onCloseCreateGuestCategory() {
    this.setState({ showCreateGuestCategoryModal: false, currentField: null });
  }


  renderCreateGuestCategory() {
    const { showCreateGuestCategoryModal, currentField } = this.state;
    const { distinctValues, event } = this.props;
    let currentValue = "";
    distinctValues.find(value => {
      if (value.constructor == Array) {
        if (currentField == value[0]) {
          currentValue = value[1];
          return true;
        }
      } else if (currentField == value) {
        currentValue = value;
        return true;
      }
    });
    return <CreateGuestCategoryModal
      isVisible={showCreateGuestCategoryModal}
      event={event}
      onCloseFunction={this.onCloseCreateGuestCategory}
      updateParent={this.submitCreateGuestCategory}
      proposedName={currentValue}
      render={ (state) => <GuestCategoryForm {...state} /> }
    />;
  }

  renderSelectOfGuestCategories(field) {
    const { guestCategories } = this.props;
    const { mappingGuestCategory } = this.state;
    const options = guestCategories.map(gc => {
      return (<option value={gc._id} key={gc._id}>{gc.name}</option>);
    });
    return (
      <div className="col-md-5" style={{ paddingRight: 0 }}>
        <select className="form-select available-guest-categories" name="guest-categories" value={mappingGuestCategory[field]} onChange={this.onChangeGuestCategory(field)}>
          <option value="" key="default">{I18n.t("guest_import_operations.modal_guest_category.prompt")}</option>
          {options}
        </select>
      </div>
    );
  }

  onChangeGuestCategory(field) {
    const { mappingGuestCategory } = this.state;
    return (e) => {
      let newMappingGuestCategory = Object.assign({}, mappingGuestCategory);
      if (e.target.value) {
        newMappingGuestCategory[field] = e.target.value;
      } else {
        delete newMappingGuestCategory[field];
      }

      const { dynamicallyUpdateParent } = this.props;
      this.setState({ mappingGuestCategory: newMappingGuestCategory }, () =>
        dynamicallyUpdateParent && dynamicallyUpdateParent(this.convertMappingToServerData())
      );
    };
  }

  convertMappingToServerData() {
    const { mappingGuestCategory } = this.state;
    let data = {};
    Object.keys(mappingGuestCategory).forEach(field => {
      const guestCategoriesId = mappingGuestCategory[field];
      if (!data[guestCategoriesId]) {
        data[guestCategoriesId] = [field];
      } else {
        let fieldArray = data[guestCategoriesId].slice();
        fieldArray.push(field);
        data[guestCategoriesId] = fieldArray;
      }
    });
    return data;
  }

  toogleOption() {
    const { overrideGuestCategoryOfExistingGuests } = this.state;
    this.setState({ overrideGuestCategoryOfExistingGuests: !overrideGuestCategoryOfExistingGuests });
  }

  renderSubmitButton() {
    const { distinctValues, submitLabel, hideSubmitButton } = this.props;
    if (hideSubmitButton) return null;

    const { mappingGuestCategory } = this.state;
    const extraProps = {};
    if (Object.keys(mappingGuestCategory).length !== distinctValues.length) {
      extraProps["disabled"] = "disabled";
    }
    const label = submitLabel ? submitLabel : I18n.t("guest_import_operations.modal_guest_category.use_button");
    return (
      <button type="submit" className="btn btn-primary" id="submit-guest-categories" {...extraProps}>
        {label}
      </button>
    );
  }

  submit(e) {
    e.preventDefault();
    const { updateParent, clearStateOnSubmit } = this.props;
    const { overrideGuestCategoryOfExistingGuests } = this.state;
    updateParent(overrideGuestCategoryOfExistingGuests, this.convertMappingToServerData());
    // clear state in case of new mapping for a different column
    if (clearStateOnSubmit) this.setState({ mappingGuestCategory: {} });
  }

  render() {
    const { onCloseFunction, isVisible, displayMode, distinctValues } = this.props;
    const { overrideGuestCategoryOfExistingGuests } = this.state;
    if (!distinctValues) return <Loader />;
    if (displayMode === "classic") {
      return (
        <form onSubmit={this.submit}>
          <div id="choose-guest-category">
            <ul id="guest-categories" className="ps-0 d-flex flex-column">
              { this.renderDistinctValues() }
            </ul>
            { this.renderSubmitButton() }
            { this.renderCreateGuestCategory() }
          </div>
        </form>
      );
    } else {
      return (
        <div>
          <Modal show={isVisible} onHide={onCloseFunction} size="lg">
            <Modal.Header>
              <Modal.Title>{I18n.t("guest_import_operations.modal_guest_category.title")}</Modal.Title>
              <button type="button" onClick={onCloseFunction} className="btn-close" aria-label={I18n.t("close")}></button>
            </Modal.Header>
            <form onSubmit={this.submit}>
              <Modal.Body id="choose-guest-category">
                <p>{I18n.t("guest_import_operations.modal_guest_category.paragraph")}</p>

                <ul id="guest-categories">
                  {this.renderDistinctValues()}
                </ul>

                <div className="form-check">
                  <label className="form-check-label">
                    <input type="checkbox" className="form-check-input" value={overrideGuestCategoryOfExistingGuests} onClick={this.toogleOption}></input>
                    {I18n.t("guest_import_operations.modal_guest_category.override_guest_category_of_existing_guests")}
                  </label>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {this.renderSubmitButton()}
              </Modal.Footer>
            </form>
          </Modal>
          {this.renderCreateGuestCategory()}
        </div>
      );
    }
  }
}

export default GuestCategoryModal;

GuestCategoryModal.propTypes = {
  isVisible: PropTypes.bool,
  guestCategories: PropTypes.array.isRequired,
  distinctValues: PropTypes.array,
  onCloseFunction: PropTypes.func,
  updateParent: PropTypes.func,
  event: PropTypes.object,
  baseMapping: PropTypes.object,
  clearStateOnSubmit: PropTypes.bool,
  displayMode: PropTypes.string,
  dynamicallyUpdateParent: PropTypes.func,
  hideSubmitButton: PropTypes.bool,
};

GuestCategoryModal.defaultProps = {
  distinctValues: [],
  guestCategories: [],
  clearStateOnSubmit: true,
  displayMode: null,
  hideSubmitButton: false
};
