import { Component } from "react";
import { connect } from "react-redux";
import Loader from "../components/shared/Loader.react";

const WAIT_TIMES = [3, 3, 3, 3, 3, 3, 3, 6, 12, 24, 48, 96];
const BADGE_GENERATION_CURSORS = [4, 9];

class BadgeLoader extends Component {
  constructor(props) {
    super(props);
    this.tick = this.tick.bind(this);
    this.waitTimeCursor = 0;
    this.timer = null;
    this.autoPrint = window.ReactGlobalProps.auto_print;
    this.badgeUrl = window.ReactGlobalProps.badge_url;
  }

  componentDidMount() {
    if (this.autoPrint && this.badgeUrl.length > 0) {
      this.printBadgePdf();
    } else {
      this.tick();
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  canRetry() {
    return this.waitTimeCursor < WAIT_TIMES.length;
  }

  tick() {
    if (!this.canRetry()) {
      return;
    }

    if (BADGE_GENERATION_CURSORS.includes(this.waitTimeCursor)) {
      this.triggerBadgeGeneration();
    }

    this.timer = setTimeout(() => {
      const { location } = this.props;
      const search = location.search.replace("auto_print=true", "");
      const req = $.ajax({
        url: `${location.pathname}${search}`,
        type: "GET",
        contentType: "application/json",
        dataType: "json"
      });

      req.done(data => {
        const badgeUrl = data["badge_url"];

        if (!badgeUrl) {
          this.tick();
          return;
        }

        if (this.autoPrint) {
          this.badgeUrl = data["proxied_badge_url"];
          this.printBadgePdf();
        } else {
          window.location = badgeUrl;
        }
      });

      req.fail(() => {
        this.tick();
      });
    }, WAIT_TIMES[this.waitTimeCursor] * 1000);

    this.waitTimeCursor += 1;
  }

  printBadgePdf() {
    const iframe = this.printingIframe();

    $(iframe).on("load", function () {
      $(iframe).focus();
      iframe.contentWindow.print();
    });
  }

  printingIframe() {
    const iframe = document.createElement("iframe");

    iframe.id = "badgePrintingIframe";
    $("#react").html(iframe);
    iframe.src = this.badgeUrl;

    return iframe;
  }

  triggerBadgeGeneration() {
    const { match, location } = this.props;
    const { event_id, guest_id } = match.params;
    $.ajax({
      url: `/public/events/${event_id}/guests/${guest_id}/generate_badge.json${location.search.substring()}`,
      type: "PUT",
      dataType: "json"
    });
  }

  render() {
    const content = this.canRetry() ? (
      <span>
        <p> { I18n.t("react.badge_loader.wait_message") } </p>
        <div style={{ display: "inline-block" }}>
          <Loader />
        </div>
      </span>
    ) : (
      <p> { I18n.t("react.badge_loader.failed_message") } </p>
    );

    return (
      <div style={{ textAlign: "center", marginTop: "100px" }}>
        { content }
      </div>
    );
  }
}

export default connect()(BadgeLoader);