import { Component } from "react";
import { Tooltip, OverlayTrigger, Dropdown } from "react-bootstrap";
import Icons from "../../constants/Icons";
import { isEnabled } from "../../utils/featureSetUtils";
import { MULTIPLE_REGISTRATION } from "../../constants/FeaturesSet";

class AddBoxButton extends Component {

  constructor(props) {
    super(props);
    [
      "renderAddBoxButton"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = { clicked: false };
  }

  renderAddBoxButton(itemType, faIcon, text, eventKey) {
    const { createItem } = this.props;
    return (
      <Dropdown.Item eventKey={eventKey} onClick={createItem(itemType)}>
        <i className={`fa-regular fa-${faIcon}`} aria-hidden="true" style={{ paddingRight: "5px" }}></i> {text}
      </Dropdown.Item>
    );
  }

  render() {
    const { createStep } = this.props;
    let tooltip = <Tooltip id="tooltip-add-element">{ I18n.t("react.registration_form.add_element") }</Tooltip>;

    return (
      <Dropdown>
        <OverlayTrigger placement="bottom" overlay={tooltip}>
          <Dropdown.Toggle variant="secondary" id="btn-sidebar" className="btn-sm"><i className="fa-regular fa-plus"></i></Dropdown.Toggle>
        </OverlayTrigger>
        <Dropdown.Menu align="end">
          { this.renderAddBoxButton("text_field", "question-circle", I18n.t("react.registration_form.add_question"), 1) }
          { this.renderAddBoxButton("paragraph", "font", I18n.t("react.registration_form.add_paragraph"), 2) }
          { this.renderAddBoxButton("image", "file-image", I18n.t("react.registration_form.add_image"), 3) }
          { isEnabled(MULTIPLE_REGISTRATION) && this.renderAddBoxButton("subform", "clone", I18n.t("react.registration_form.add_subform"), 4) }
          <Dropdown.Divider />
          <Dropdown.Item eventKey={4} onClick={createStep} >{ Icons.new_form_step() } { I18n.t("react.registration_form.add_step") }</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}


export default AddBoxButton;
