import { Component } from "react";
import { connect } from "react-redux";
import pull from "lodash/pull";
import difference from "lodash/difference";
import isEmpty from "lodash/isEmpty";

import { showNotice } from "../actions/NoticeActionCreators";

import { updateUI } from "../actions/UIAppearanceActionCreators";
import { clearLabelStats, createLabel } from "../actions/LabelsActionCreators";

import { guestCountFromQS } from "../actions/SearchQueryActionCreators";
import { fetchLabelsStats } from "../actions/LabelsActionCreators.js";
import { updateGuestsLabels } from "../actions/GuestUpdatesActionCreators";

import { urlEventId, pathToLabelsConfiguration } from "../utils/pathUtils";
import { queryStringAndSelectedGuests } from "../utils/QueryStringUtils";

import Filter from "../components/Filter.react";
import Loader from "../components/shared/Loader.react";
import withModal from "./withModal.react";
import { COLORS } from "../constants/Constants";

class ChangeLabel extends Component {
  constructor(props) {
    super(props);
    [
      "_submit",
      "onUpdateState",
      "injectStatsInState",
      "handleCreate",
      "items"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = { selectedItemIds: null, intermediateItemIds: null, itemIdsToPush: [], itemIdsToPull: [], searchValue: null, itemSorted: null };
  }

  componentDidMount() {
    const { updateUI, guestCountFromQS, fetchLabelsStats, location, selectedGuests } = this.props;
    const updates = {
      "modalTitle": I18n.t("react.reports.change_label")
    };
    updateUI(updates);
    const query = queryStringAndSelectedGuests(location, selectedGuests);
    guestCountFromQS(query, true);
    fetchLabelsStats(urlEventId(), query || "");
  }

  componentDidUpdate(prevProps) {
    const prevPropsKO = prevProps.labelStats == null || isEmpty(prevProps.labelStats) || prevProps.selectedGuestCount === null;
    const propsOK = this.props.labelStats != null && !isEmpty(this.props.labelStats) && this.props.selectedGuestCount != null;
    if (propsOK && prevPropsKO || prevProps.labels != this.props.labels) {
      this.injectStatsInState(this.props);
    }
  }

  injectStatsInState(nextProps) {
    const { selectedGuestCount, labelStats } = nextProps;
    let selectedItemIds = [];
    let intermediateItemIds = [];
    Object.keys(labelStats).forEach((key) => {
      const value = labelStats[key];
      if (value >= selectedGuestCount) {
        selectedItemIds.push(key);
      } else if (value > 0 && value < selectedGuestCount) {
        intermediateItemIds.push(key);
      }
    });
    this.sortItems(nextProps, selectedItemIds, intermediateItemIds);
    this.setState({ selectedItemIds, intermediateItemIds });
  }

  onUpdateState(stateValue) {
    const { selectedItemIds, intermediateItemIds, itemIdsToPush, itemIdsToPull } = this.state;
    if (!Object.keys(stateValue).includes("selectedItemIds") && !Object.keys(stateValue).includes("intermediateItemIds")) {
      return this.setState(stateValue);
    }

    let newItemIdsToPush = itemIdsToPush.slice();
    let newItemIdsToPull = itemIdsToPull.slice();
    let newItem = difference(stateValue.selectedItemIds, selectedItemIds);
    if (!isEmpty(newItem)) {
      newItemIdsToPush = newItemIdsToPush.concat(newItem);
      pull(newItemIdsToPull, newItem[0]);
    } else {
      newItem = difference(selectedItemIds, stateValue.selectedItemIds);
      newItemIdsToPull = newItemIdsToPull.concat(newItem);
      pull(newItemIdsToPush, newItem[0]);
    }

    newItem = difference(intermediateItemIds, stateValue.intermediateItemIds);
    if (!isEmpty(newItem)) {
      newItemIdsToPull = newItemIdsToPull.concat(newItem);
    }
    this.setState(Object.assign({}, stateValue, { itemIdsToPush: newItemIdsToPush, itemIdsToPull: newItemIdsToPull }));
  }

  componentWillUnmount() {
    const { clearLabelStats } = this.props;
    clearLabelStats();
  }

  _submit() {
    const { closeModal, updateGuestsLabels, showNotice, location, selectedGuests } = this.props;
    const { itemIdsToPush, itemIdsToPull } = this.state;
    const query = queryStringAndSelectedGuests(location, selectedGuests);
    updateGuestsLabels(urlEventId(), query, itemIdsToPush, itemIdsToPull);
    showNotice(I18n.t("react.change_label.notification_labels"), "success");
    closeModal();
  }

  items() {
    const { labels } = this.props;
    const { itemSorted } = this.state;
    return itemSorted ? itemSorted : labels.items;
  }

  sortItems(nextProps, selectedItemIds, intermediateItemIds) {
    const { labels } = nextProps;
    if (!labels || !labels.items) return;

    const sortFn = (a, b) => {
      const aInSelectedItemIds = selectedItemIds.includes(a._id);
      const aIinIntermediateItemIds = intermediateItemIds.includes(a._id);
      const bInSelectedItemIds = selectedItemIds.includes(b._id);
      const bIinIntermediateItemIds = intermediateItemIds.includes(b._id);
      if (aInSelectedItemIds && bIinIntermediateItemIds) {
        return -1;
      } else if (bInSelectedItemIds && aIinIntermediateItemIds) {
        return 1;
      } else if (aInSelectedItemIds && !bInSelectedItemIds && !bIinIntermediateItemIds) {
        return -1;
      } else if (bInSelectedItemIds && !aInSelectedItemIds && !aIinIntermediateItemIds) {
        return 1;
      } else if (aIinIntermediateItemIds && !bInSelectedItemIds && !bIinIntermediateItemIds) {
        return -1;
      } else if (bIinIntermediateItemIds && !aInSelectedItemIds && !aIinIntermediateItemIds) {
        return 1;
      }
      return 0;
    };
    this.setState({ itemSorted: labels.items.sort(sortFn) });
  }

  labelNameAlreadyExists(name) {
    const { labels } = this.props;

    return labels && labels.items && labels.items.find(label => label.name === name);
  }

  handleCreate(name) {
    return () => {
      this.props.createLabel(urlEventId(), name, COLORS[Math.floor(Math.random() * COLORS.length)]);
    };
  }

  render() {
    const { labels, labelStats, selectedGuestCount } = this.props;
    if (!labels || !labelStats || !selectedGuestCount) return <Loader />;
    return isEmpty(this.items()) ? this.renderNoItems() : this.renderItems();
  }

  renderItems() {
    const { closeModal } = this.props;
    const { selectedItemIds, intermediateItemIds, searchValue } = this.state;
    return (
      <div>
        <div className="container-modal">
          <Filter
            items={this.items()}
            selectedItemIds={selectedItemIds || []}
            intermediateItemIds={intermediateItemIds || []}
            searchValue={searchValue}
            translationKey="labels"
            onChange={this.onUpdateState}
            closeParent={closeModal}
            itemIdKey={"_id"}
            itemColorKey="color"
            hasSelectAll={true}
            hasSearch={true}
            hasCreate={!this.labelNameAlreadyExists(searchValue)}
            onCreate={this.handleCreate}
          />
        </div>
        <div>
          <hr />
          <button type="button" className="btn btn-primary float-end" onClick={this._submit}>
            {I18n.t("react.export_columns_filter.submit")}
          </button>
        </div>
      </div>
    );
  }

  renderNoItems() {
    return (
      <div className="card nothing-yet mt-30">
        <div>
          <a href={pathToLabelsConfiguration()} className="btn btn-primary">
            <i className="fa-regular fa-plus"></i> { I18n.t("react.change_label.create_label") }
          </a>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    labels: state.labels,
    labelStats: state.labelStats,
    selectedGuestCount: state.appearance.selectedGuestCount,
    selectedGuests: state.guests.selectedGuests
  };
}

const mapDispatchToProps = {
  updateUI,
  guestCountFromQS,
  fetchLabelsStats,
  clearLabelStats,
  updateGuestsLabels,
  createLabel,
  showNotice
};

export default connect(mapStateToProps, mapDispatchToProps)(withModal(ChangeLabel));
