import { createSelector } from "reselect";
import union from "lodash/union";

const allDaysStatsKey = "all_days";

const guestsStatistics = (state) => {
  if (state.guestsStatistics && state.guestsStatistics.selectedValueIds) {
    return filteredStats(state.guestsStatistics);
  }
  return state.guestsStatistics;
};

export const attendanceDerivedData = createSelector(
  guestsStatistics,
  (guestsStatistics) => {
    let result = {};
    Object.keys(guestsStatistics).forEach(eventId => {
      const eventGuestsStatistics = guestsStatistics[eventId];
      Object.keys(eventGuestsStatistics).forEach(field => {
        const stats = eventGuestsStatistics[field];
        const dates = union(Object.keys(stats || {}), Object.keys(stats || {}));
        dates.forEach(date => {
          result[eventId] = result[eventId] || {};
          result[eventId][field] = result[eventId][field] || {};
          result[eventId][field][date] = result[eventId][field][date] || {};
          const data = computeDerivedData(stats[date]["data"]);
          const total = computeTotal(data);
          result[eventId][field][date]["total"] = total;
          result[eventId][field][date]["data"] = computePercentColumn(data, total);
          result[eventId][field][allDaysStatsKey] = computeGlobalData(result[eventId][field]);
        });
      });
    });
    return result;
  }
);

function computeDerivedData(stats) {
  return stats.map(stat => {
    const visits = safeValue(stat.new_visits) + safeValue(stat.returning);
    const no_shows = safeValue(stat.count) - safeValue(visits);
    return statWithtransformationRate(Object.assign({}, stat, { no_shows, visits }));
  });
}

export const emptyStat = {
  count: 0,
  new_visits: 0,
  returning: 0,
  no_shows: 0,
  visits: 0,
  transformation_rate: 0
};

export function computeTotal(stats) {
  let result = { ...emptyStat };
  stats.forEach(stat => {
    const updatedResult = {
      count: result.count + stat.count,
      new_visits: result.new_visits + stat.new_visits,
      returning: result.returning + stat.returning,
      visits: result.visits + stat.visits,
      no_shows: result.no_shows + stat.no_shows
    };
    result = updatedResult;
  });
  return statWithtransformationRate(result, true);
}

function computePercentColumn(stats, total) {
  return stats.map(stat => {
    const count_percent = total.count > 0 ? stat.count / total.count : 1;
    return Object.assign({}, stat, { count_percent });
  });
}

function computeGlobalData(stats) {
  const dates = Object.keys(stats).filter(date => { return date !== allDaysStatsKey; });
  if (dates.length === 0) {
    return { dates: [], data: [] };
  }
  let result = {};
  result.dates = dates.slice();
  result.data = stats[dates.shift()]["data"].slice();
  dates.forEach(date => {
    stats[date]["data"].forEach(stat => {
      const acc = result.data.find(e => { return e._id === stat._id; });
      if (acc) {
        const updatedStat = statWithtransformationRate({
          _id: acc._id,
          count: acc.count,
          returning: acc.returning + stat.returning,
          new_visits: acc.new_visits + stat.new_visits,
          no_shows: acc.no_shows + stat.no_shows,
          visits: acc.visits + stat.visits
        }, true);
        const idx = result.data.indexOf(acc);
        result.data.splice(idx, 1, updatedStat);
      }
    });
  });
  result.data = result.data.map(e => {
    return Object.assign({}, e, { no_shows: e.count - e.new_visits });
  });
  result.total = computeTotal(result.data);
  result.data = computePercentColumn(result.data, result.total);
  return result;
}

function statWithtransformationRate(stat, isGlobal = false) {
  const source = isGlobal ? stat.new_visits : stat.visits;
  const transformation_rate = safeValue(stat.count) === 0 ? 0 : source / stat.count;
  return Object.assign({}, stat, { transformation_rate });
}

function safeValue(a) {
  return a || 0;
}

function filteredStats(guestsStatistics) {
  const { selectedValueIds } = guestsStatistics;
  const onlyStats = { ...guestsStatistics };
  delete onlyStats.selectedValueIds;
  let result = {};
  Object.keys(onlyStats).forEach(eventId => {
    const eventGuestsStatistics = guestsStatistics[eventId];
    Object.keys(eventGuestsStatistics).filter(field => {
      const fieldStat = eventGuestsStatistics[field];
      result[eventId] = result[eventId] || {};
      result[eventId][field] = {};
      Object.keys(fieldStat).forEach(date => {
        const stat = fieldStat[date];
        result[eventId][field][date] = {};
        result[eventId][field][date].data = stat.data.filter(item => {
          return selectedValueIds.includes(item._id);
        });
      });
    });
  });
  return result;
}
