import { Component } from "react";
import { connect } from "react-redux";

import Loader from "../../components/shared/Loader.react";
import { requestEvent } from "../../actions/ImportActionCreators";
import { fetchGuestCategories } from "../../actions/GuestCategoryActionCreators";
import requiresProps from "../../utils/requiresProps";
import { EventStoreProps } from "../../types/Event";
import { updateEvent } from "../../actions/EventActionCreators";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import { redirectIfUnauthorized } from "../../utils/aclUtils";
import { GuestCategory } from "../../types/GuestCategory";
import NotificationAlert, { Notification } from "../../components/shared/NotificationAlert.react";
import FormSectionPanel from "../../components/engagement/FormSectionPanel.react";
import NotificationSettings from "../../components/engagement/meetings/NotificationSettings.react";
import LocationsSettings from "../../components/engagement/meetings/LocationsSettings.react";
import TypeSettings from "../../components/engagement/meetings/TypeSettings.react";
import DefaultAvailabilitiesSettings from "../../components/engagement/meetings/DefaultAvailabilitiesSettings.react";
import { MeetingLocation, isMeetingLocationPersisted } from "../../types/MeetingLocation";

interface StoreProps {
  event: EventStoreProps;
  guestCategories: GuestCategory[];
  notification: Notification;
}

interface DispatchProps {
  updateEvent: (id: string, params: any, redirectTo: string|null, notificationsOptions: any) => void;
  requestEvent: () => void;
  fetchGuestCategories: (eventId: string) => void;
}

interface Props extends StoreProps, DispatchProps {}

interface State {
  networkingMeetingsType: "physical"|"virtual"|"physical_or_virtual";
  defaultSlotDurationMinutes?: number;
  meetingLocations: MeetingLocation[];
  networkingMeetingsImportedByOrganizer: boolean;
  smsMeetingNotificationEnabled: boolean;
  emailMeetingNotificationEnabled: boolean;
  simultaneousSlotsEnabled: boolean;
  awaitingMeetingRequestsLimit: number;
}

class Meetings extends Component<Props, State> {
  private scrollToTopAfterUpdateEventRequest: boolean;

  constructor(props: Props) {
    redirectIfUnauthorized("configuration", "manage");
    super(props);
    this.scrollToTopAfterUpdateEventRequest = false;
    this.state = this.stateFromProps(props);

    [
      "submit",
      "updateLocations",
      "onRadioChange",
      "onCheckboxChange",
      "onDefaultSlotDurationChange",
      "onAwaitingMeetingRequestsLimitChange",
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  componentDidUpdate(prevProps: Props): void {
    const { event } = this.props;
    if (!prevProps.event.fetched && event.fetched) {
      this.setState(this.stateFromProps());
    } else if (prevProps.event.pendingRequest && !event.pendingRequest) {
      this.onEventSaved();
      this.scrollToTop();
    }
  }

  onEventSaved(): void {
    const { event } = this.props;
    if (!event.errors) {
      // event saved successfully remove from the state the deleted locations
      this.setState(this.stateFromProps());
    }
  }

  scrollToTop(): void {
    if (!this.scrollToTopAfterUpdateEventRequest) return;

    window.scrollTo({ top: 0, behavior: "smooth" });
    this.scrollToTopAfterUpdateEventRequest = false;
  }

  stateFromProps(props = this.props): State {
    const { event } = props;
    return {
      networkingMeetingsType: event.networking_meetings_type as "physical"|"virtual"|"physical_or_virtual",
      meetingLocations: event.meeting_locations,
      networkingMeetingsImportedByOrganizer: event.networking_meetings_imported_by_organizer,
      smsMeetingNotificationEnabled: event.meeting_sms_notification_enabled,
      emailMeetingNotificationEnabled: event.meeting_email_notification_enabled,
      defaultSlotDurationMinutes: event.meeting_default_slot_duration,
      simultaneousSlotsEnabled: event.meeting_simultaneous_slots_enabled,
      awaitingMeetingRequestsLimit: event.awaiting_meeting_requests_limit
    };
  }

  i18n(key: string): string {
    return I18n.t(`react.networking.meetings.${key}`);
  }

  submit(e: React.MouseEvent): void {
    e.preventDefault();
    const { event, updateEvent } = this.props;

    const meetingLocationsAttributes = this.state.meetingLocations.filter(location => location.name !== "").map(location => {
      const meetingLocation = { ...location, event_id: event.id, _id: null };
      if (isMeetingLocationPersisted(location)) {
        meetingLocation["_id"] = location._id;
      } else {
        delete meetingLocation._id;
      }

      return meetingLocation;
    });

    const payload = {
      meeting_sms_notification_enabled: this.state.smsMeetingNotificationEnabled,
      meeting_email_notification_enabled: this.state.emailMeetingNotificationEnabled,
      networking_meetings_type: this.state.networkingMeetingsType,
      meeting_locations_attributes: meetingLocationsAttributes,
      networking_meetings_imported_by_organizer: this.state.networkingMeetingsImportedByOrganizer,
      meeting_default_slot_duration: this.state.defaultSlotDurationMinutes,
      meeting_simultaneous_slots_enabled: this.state.simultaneousSlotsEnabled,
      awaiting_meeting_requests_limit: this.state.awaitingMeetingRequestsLimit
    };

    const notifications = { notice: I18n.t("successfully_saved"), noticeType: "success" };
    updateEvent(event._id, payload, null, notifications);
    this.scrollToTopAfterUpdateEventRequest = true;
  }

  onCheckboxChange(key: string): void {
    this.setState({ [key]: !this.state[key] } as Pick<State, "smsMeetingNotificationEnabled" | "emailMeetingNotificationEnabled" | "simultaneousSlotsEnabled">);
  }

  onRadioChange(key: string, value: string): void {
    this.setState({ [key]: value } as Pick<State, "networkingMeetingsType">);
  }

  onDefaultSlotDurationChange(value: number): void {
    this.setState({ defaultSlotDurationMinutes: value } as Pick<State, "defaultSlotDurationMinutes">);
  }

  updateLocations(locations: MeetingLocation[]): void {
    this.setState({ meetingLocations: locations } as Pick<State, "meetingLocations">);
  }

  onAwaitingMeetingRequestsLimitChange(value: number): void {
    this.setState({ "awaitingMeetingRequestsLimit": value } as Pick<State, "awaitingMeetingRequestsLimit">);
  }

  missingMeetingLocation(): boolean {
    const { networkingMeetingsType, meetingLocations, networkingMeetingsImportedByOrganizer } = this.state;

    if (networkingMeetingsImportedByOrganizer) return false;
    if (networkingMeetingsType === "virtual") return false;
    if (!meetingLocations || meetingLocations.length === 0) return false;

    return meetingLocations[0] === null;
  }

  renderMeetingTypePanel(): JSX.Element {
    const {
      networkingMeetingsImportedByOrganizer,
      networkingMeetingsType,
      defaultSlotDurationMinutes,
      simultaneousSlotsEnabled,
      awaitingMeetingRequestsLimit
    } = this.state;

    return <FormSectionPanel title={this.i18n("type_title")}>
      <TypeSettings
        networkingMeetingsImportedByOrganizer={networkingMeetingsImportedByOrganizer}
        networkingMeetingsType={networkingMeetingsType}
        onCheckboxChange={this.onCheckboxChange}
        onRadioChange={this.onRadioChange}
        onDefaultSlotDurationChange={this.onDefaultSlotDurationChange}
        defaultSlotDurationMinutes={defaultSlotDurationMinutes || 0}
        simultaneousSlotsEnabled={simultaneousSlotsEnabled || false}
        awaitingMeetingRequestsLimit={awaitingMeetingRequestsLimit}
        onAwaitingMeetingRequestsLimitChange={this.onAwaitingMeetingRequestsLimitChange}
      />
    </FormSectionPanel>;
  }

  renderLocationsSettingsPanel(): JSX.Element {
    const {
      networkingMeetingsType,
      meetingLocations,
      networkingMeetingsImportedByOrganizer
    } = this.state;

    const { event } = this.props;

    if (networkingMeetingsType === "virtual" || networkingMeetingsImportedByOrganizer) return null;

    return <FormSectionPanel title={this.i18n("locations_title")}>
      <LocationsSettings
        meetingLocations={meetingLocations}
        updateLocations={this.updateLocations}
        eventErrors={event.errors}
      />
    </FormSectionPanel>;
  }

  renderNotificationSettingsPanel(): JSX.Element {
    const {
      smsMeetingNotificationEnabled,
      emailMeetingNotificationEnabled
    } = this.state;

    return <FormSectionPanel title={this.i18n("notifications_title")}>
      <NotificationSettings
        smsMeetingNotificationEnabled={smsMeetingNotificationEnabled}
        emailMeetingNotificationEnabled={emailMeetingNotificationEnabled}
        onCheckboxChange={this.onCheckboxChange}
      />
    </FormSectionPanel>;
  }

  renderDefaultAvailabilitiesSettingsPanel(): JSX.Element {
    const {
      networkingMeetingsImportedByOrganizer,
      networkingMeetingsType
    } = this.state;

    if (networkingMeetingsImportedByOrganizer) return null;

    const { event, guestCategories } = this.props;

    return <FormSectionPanel title={this.i18n("default_availabilities_title")}>
      <DefaultAvailabilitiesSettings
        event={event}
        guestCategories={guestCategories}
        networkingMeetingsType={networkingMeetingsType}
      />
    </FormSectionPanel>;
  }

  renderErrors(): JSX.Element {
    const { errors } = this.props.event;

    if (!errors) return null;

    // we remove meeting_availabilities errors because they are submitted on their own, not as part
    // of this form. But they may still appear here.
    // we also remove meeting_locations detailed errors they are handled by the LocationsSettings
    // component
    const cleanedUpErrors = {};
    Object.entries(errors).forEach(([key, value]) => {
      if (!key.startsWith("meeting_availabilities[") && !key.startsWith("meeting_locations[")) {
        cleanedUpErrors[key] = value;
      }
    });

    return <ErrorMessage errors={cleanedUpErrors} model={"event"}/>;
  }

  render(): JSX.Element {
    const { event, notification } = this.props;
    if (!event?.fetched) return <Loader />;

    const disabled = event.pendingRequest || this.missingMeetingLocation();

    return <div className="row">
      <div className="col-12">
        <NotificationAlert notification={notification} />
        {this.renderErrors()}
        {this.renderMeetingTypePanel()}
        {this.renderLocationsSettingsPanel()}
        {this.renderNotificationSettingsPanel()}
        {this.renderDefaultAvailabilitiesSettingsPanel()}

        <button className="btn btn-primary btn-lg float-end" onClick={this.submit} disabled={disabled}>
          {this.i18n("save")}
        </button>
      </div>
    </div>;
  }
}

function mapStateToProps(state: any): StoreProps {
  return {
    event: state.event,
    guestCategories: state.guestCategories.data,
    notification: state.notifications
  };
}

const mapDispatchToProps: DispatchProps = {
  updateEvent,
  requestEvent,
  fetchGuestCategories
};

export default connect<StoreProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(requiresProps(Meetings, {
  requirements: {
    event: {
      fn: ({ requestEvent }) => {
        requestEvent();
      },
      desiredState: "has_id"
    },
    guestCategories: {
      waitFor: ["event"],
      fn: ({ fetchGuestCategories, event }) => {
        fetchGuestCategories(event.id);
      },
      statePath: "guestCategories.data"
    }
  }
}));
