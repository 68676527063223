import { Component } from "react";
import { connect } from "react-redux";
import querystring from "querystring";
import { createFromEvent } from "../../actions/EventActionCreators";
import TargetEventSelector from "../../components/TargetEventSelector.react";
import AsyncDuplicationRunning from "./EventDuplicationRunning.react";
import { DuplicationOperation } from "../../types/DuplicationOperation";

interface Props {
  createFromEvent(accountId: string, body: any): any;
  match: any;
  location: any;
  history: any;
  duplicationOperation: DuplicationOperation;
  overrides: any;
}

interface State {
  duplicationRunning: boolean;
}

class EventDuplication extends Component<Props, State> {
  state = {
    duplicationRunning: this.defaultDuplicationRunning()
  };

  defaultDuplicationRunning(): boolean {
    const { location } = this.props;
    const searchParams = querystring.parse(location.search.substring(1));

    return !!searchParams.duplication_operation_id;
  }

  componentDidUpdate(prevProps: any): void {
    const { history, location, duplicationOperation } = this.props;

    if (!prevProps.duplicationOperation && duplicationOperation) {
      history.push({ ...location, search: `duplication_operation_id=${duplicationOperation._id}` });
    }
  }

  onEventSourceSelected = (eventId: string): void => {
    const { createFromEvent, match, overrides } = this.props;

    this.setState({ duplicationRunning: true });
    createFromEvent(match.params.account_id, { event_id: eventId, overrides });
  };

  renderEventsList(): JSX.Element {
    return <div>
      <h3>{ I18n.t("react.events.duplication_modal_title") }</h3>
      <TargetEventSelector
        onEventSelected={this.onEventSourceSelected}
        confirmOnSelection={true}
      />
    </div>;
  }

  renderDuplicationRunning(): JSX.Element {
    const { location, match } = this.props;
    const query = querystring.parse(location.search.substring(1));

    return <AsyncDuplicationRunning
      duplicationOperationId={query.duplication_operation_id as string}
      match={match}
    />;
  }

  render(): JSX.Element {
    const { duplicationRunning } = this.state;

    if (duplicationRunning)
      return this.renderDuplicationRunning();

    return this.renderEventsList();
  }
}

function mapStateToProps(state: any): Pick<Props, "duplicationOperation"> {
  return {
    duplicationOperation: state.duplicationOperation.data
  };
}

const mapDispatchToProps = {
  createFromEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDuplication);
