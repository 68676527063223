import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { GuestStatusRegistered, GuestStatusImported } from "../../constants/Constants.js";
import { isEnabled } from "../../utils/featureSetUtils";
import { CONFIRMATION_EMAIL } from "../../constants/FeaturesSet";

class GuestStatusModal extends Component {
  constructor(props) {
    super(props);
    [
      "toggleOption",
      "submit"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = { overrideGuestStatus: GuestStatusImported };
  }

  toggleOption(e) {
    this.setState({ overrideGuestStatus: e.target.value });
  }

  submit() {
    const { updateParent } = this.props;
    const { overrideGuestStatus } = this.state;
    updateParent(overrideGuestStatus);
  }

  render() {
    const { onCloseFunction, isVisible } = this.props;
    const { overrideGuestStatus } = this.state;

    return (
      <Modal show={isVisible} onHide={onCloseFunction} contentLabel="Modal">
        <Modal.Header>
          <Modal.Title>{I18n.t("guest_import_operations.modal_guests_status.title")}</Modal.Title>
          <button type="button" onClick={onCloseFunction} className="btn-close" aria-label={I18n.t("close")}></button>
        </Modal.Header>
        <Modal.Body >
          <div className="form-check">
            <label className="form-check-label">
              <input type="radio" className="form-check-input" checked={overrideGuestStatus == GuestStatusImported} value={GuestStatusImported} onChange={this.toggleOption}></input>
              {I18n.t("guest_import_operations.modal_guests_status.inviting")}
              <div className="form-text">{I18n.t("guest_import_operations.modal_guests_status.inviting_help")}</div>
            </label>
          </div>
          { isEnabled(CONFIRMATION_EMAIL) &&
            <div className="form-check">
              <label className="form-check-label">
                <input type="radio" className="form-check-input" checked={overrideGuestStatus == GuestStatusRegistered} value={GuestStatusRegistered} onChange={this.toggleOption}></input>
                {I18n.t("guest_import_operations.modal_guests_status.registering")}
                <div className="form-text">{I18n.t("guest_import_operations.modal_guests_status.registering_help")}</div>
              </label>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" id="submit-guest-status" onClick={this.submit}>
            {I18n.t("guest_import_operations.modal_guests_status.continue")}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default GuestStatusModal;

GuestStatusModal.propTypes = {
  updateParent: PropTypes.func.isRequired,
  onCloseFunction: PropTypes.func.isRequired,
  isVisible: PropTypes.bool
};


GuestStatusModal.defaultProps = {
};
