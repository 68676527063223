import { ActionTypes } from "../constants/Constants";
import { mergeLocalChanges } from "../utils/templatesBuilderUtils";

const defaultState = {
  data: null,
  menus: null,
  sections: {},
  generalSettings: {},
  pagesSections: {},
  hasChanges: false,
  duplicated: null,
  errors: {},
  globalChanges: {}, // only changes for settings data
  otherChanges: {}, // changes outside of settings data
  blocksToRemove: {},
  sectionsToRemove: [],
  pagesToRemove: [],
  reloadingTheme: false,
  reloadThemeError: null,
  isPendingRequest: false,
  pendingDuplicationInPageId: null
};

function website(state = defaultState, action) {
  let settings_data, newSections, newPagesSections, newSectionsToRemove, newBlocksToRemove, localChanges, newGlobalChanges, newOtherChanges;
  switch (action.type) {
  case ActionTypes.RECEIVE_WEBSITE_SUCCESS:
    settings_data = action.data.settings_data;
    delete action.data.settings_data;
    return {
      data: action.data,
      sections: settings_data.sections,
      generalSettings: settings_data.general,
      pagesSections: settings_data.pages,
      errors: {},
      hasChanges: false,
      globalChanges: {},
      otherChanges: {},
      blocksToRemove: {},
      sectionsToRemove: [],
      pagesToRemove: [],
      isPendingRequest: false
    };
  case ActionTypes.UPDATE_WEBSITE_REQUEST:
    return {
      ...state,
      errors: {},
      isPendingRequest: true
    };
  case ActionTypes.UPDATE_WEBSITE_SUCCESS:
    settings_data = action.data.settings_data;
    delete action.data.settings_data;
    return {
      data: action.data,
      sections: settings_data.sections,
      generalSettings: settings_data.general,
      pagesSections: settings_data.pages,
      errors: {},
      hasChanges: false,
      globalChanges: {},
      otherChanges: {},
      blocksToRemove: {},
      sectionsToRemove: [],
      pagesToRemove: [],
      isPendingRequest: false
    };
  case ActionTypes.DUPLICATE_WEBSITE_PENDING:
    return Object.assign({}, state, {
      errors: {},
      duplicationErrors: {},
      duplicated: null
    });
  case ActionTypes.DUPLICATE_WEBSITE_SUCCESS:
    return Object.assign({}, state, {
      errors: {},
      duplicationErrors: {},
      duplicated: action.data
    });
  case ActionTypes.DUPLICATE_WEBSITE_FAILURE:
    return Object.assign({}, state, { duplicationErrors: action.data });
  case ActionTypes.UPDATE_PAGE_SECTIONS:
    newPagesSections = { ...state.pagesSections, [action.pageKey]: action.objects };
    localChanges = {
      pages: { [action.pageKey]: action.objects }
    };
    newGlobalChanges = mergeLocalChanges(state.globalChanges, localChanges);
    return { ...state, pagesSections: newPagesSections, globalChanges: newGlobalChanges, hasChanges: true };
  case ActionTypes.UPDATE_SECTION:
    newSections = { ...state.sections, [action.sectionKey]: action.settings };
    localChanges = {
      sections: { [action.sectionKey]: action.changes }
    };
    newGlobalChanges = mergeLocalChanges(state.globalChanges, localChanges);
    return { ...state, sections: newSections, globalChanges: newGlobalChanges };
  case ActionTypes.CREATE_NEW_SECTION:
    newSections = { ...state.sections, [action.sectionKey]: action.sectionData };
    newPagesSections = { ...state.pagesSections };
    newPagesSections[action.pageFilename] = newPagesSections[action.pageFilename] || [];
    newPagesSections[action.pageFilename].push(action.sectionKey);
    localChanges = {
      sections: { [action.sectionKey]: action.sectionData },
      pages: { [action.pageFilename]: newPagesSections[action.pageFilename] }
    };
    newGlobalChanges = mergeLocalChanges(state.globalChanges, localChanges);
    return { ...state,
      sections: newSections,
      pagesSections: newPagesSections,
      globalChanges: newGlobalChanges,
      hasChanges: true
    };
  case ActionTypes.REMOVE_SECTION:
    newSections = { ...state.sections };
    delete newSections[action.sectionKey];
    newPagesSections = { ...state.pagesSections };
    newPagesSections[action.pageFilename].splice(
      newPagesSections[action.pageFilename].indexOf(action.sectionKey),
      1
    );
    newSectionsToRemove = [...state.sectionsToRemove, action.sectionKey];
    localChanges = {
      pages: { [action.pageFilename]: newPagesSections[action.pageFilename] }
    };
    newGlobalChanges = mergeLocalChanges(state.globalChanges, localChanges);
    return { ...state,
      sections: newSections,
      pagesSections: newPagesSections,
      sectionsToRemove: newSectionsToRemove,
      globalChanges: newGlobalChanges,
      hasChanges: true
    };
  case ActionTypes.CREATE_NEW_BLOCK:
    newSections = { ...state.sections };
    newSections[action.sectionKey]["blocks"][action.blockKey] = action.blockData;
    newSections[action.sectionKey]["blocks_order"].push(action.blockKey);
    return { ...state,
      sections: newSections,
      hasChanges: true
    };
  case ActionTypes.REMOVE_BLOCK:
    newBlocksToRemove = { ...state.blocksToRemove };
    if (!newBlocksToRemove[action.sectionKey]) {
      newBlocksToRemove[action.sectionKey] = [];
    }
    newBlocksToRemove[action.sectionKey].push(action.blockKey);
    return { ...state, blocksToRemove: newBlocksToRemove, hasChanges: true };
  case ActionTypes.RECEIVE_WEBSITE_CHANGES:
    return Object.assign({}, state, { hasChanges: true });
  case ActionTypes.UPDATE_APPEARANCE:
    localChanges = { general: action.changes };
    newGlobalChanges = mergeLocalChanges(state.globalChanges, localChanges);
    return { ...state,
      generalSettings: { ...action.settings },
      globalChanges: newGlobalChanges
    };
  case ActionTypes.UPDATE_APP_HOME_SCREEN:
    localChanges = {
      app_home_screen_config_attributes: action.changes
    };
    newOtherChanges = mergeLocalChanges(state.otherChanges, localChanges);
    return { ...state,
      data: { ...state.data, app_home_screen_config: action.settings },
      otherChanges: newOtherChanges,
      hasChanges: true
    };
  case ActionTypes.ADD_PAGE_TO_SETTINGS:
    // after page duplication, no need to empty page sections config
    if (Object.keys(state.pagesSections).includes(action.pageFilename)) {
      return state;
    }
    state.pagesSections[action.pageFilename] = [];
    return Object.assign({}, state);
  case ActionTypes.REMOVE_PAGE_FROM_SETTINGS:
    newPagesSections = Object.assign({}, state.pagesSections);
    newSections = Object.assign({}, state.sections);
    newPagesSections[action.pageFilename].forEach(sectionKey => {
      delete newSections[sectionKey];
      state.sectionsToRemove.push(sectionKey);
    });
    delete newPagesSections[action.pageFilename];
    state.pagesToRemove.push(action.pageFilename);
    return Object.assign({}, state, { sections: newSections, pagesSections: newPagesSections });
  case ActionTypes.UPDATE_WEBSITE_ERROR:
    return Object.assign({}, state, {
      errors: action.errors,
      hasChanges: true,
      isPendingRequest: false
    });
  case ActionTypes.RECEIVE_WEBSITE_FAILURE:
    return state;
  case ActionTypes.DUPLICATE_WEBSITE_PAGE_SUCCESS:
    settings_data = action.data.settings_data;
    return { ...state,
      sections: settings_data.sections,
      generalSettings: settings_data.general,
      pagesSections: settings_data.pages
    };
  case ActionTypes.DUPLICATE_WEBSITE_PAGE_SECTION_PENDING:
    return {
      ...state,
      errors: {},
      pendingDuplicationInPageId: action.pageId
    };
  case ActionTypes.DUPLICATE_WEBSITE_PAGE_SECTION_SUCCESS:
    settings_data = action.data.settings_data;
    return {
      ...state,
      errors: {},
      pendingDuplicationInPageId: null,
      sections: settings_data.sections,
      generalSettings: settings_data.general,
      pagesSections: settings_data.pages
    };
  case ActionTypes.DUPLICATE_WEBSITE_PAGE_SECTION_FAILURE:
    return {
      ...state,
      errors: action.errors,
      pendingDuplicationInPageId: null
    };
  case ActionTypes.RELOAD_WEBSITE_THEME_REQUEST:
    return { ...state,
      isPendingRequest: true,
      reloadingTheme: true,
      reloadThemeError: null
    };
  case ActionTypes.RELOAD_WEBSITE_THEME_SUCCESS: {
    const { updated_at, theme_name, reloaded_at, reload_mean } = action.data;
    return { ...state,
      data: { ...state.data, updated_at, theme_name, reloaded_at, reload_mean },
      reloadingTheme: false,
      isPendingRequest: false
    };
  }
  case ActionTypes.RELOAD_WEBSITE_THEME_FAILURE:
    return { ...state,
      isPendingRequest: false,
      reloadingTheme: false,
      reloadThemeError: action.data.error
    };
  default:
    return state;
  }
}

export default website;
