import { useState } from "react";
import Loader from "./Loader.react";

interface Props {
  value: string;
  scale?: number;
}

const QRCode: React.FC<Props> = ({
  value,
  scale
}) => {
  const [loaded, setLoaded] = useState(false);
  const params = {
    value
  };

  if (scale) {
    params["scale"] = scale;
  }

  const query = new URLSearchParams(params).toString();

  return <>
    {!loaded && <Loader />}
    <img
      src={`/qrcode?${query}`}
      onLoad={(): void => setLoaded(true)}
      alt={value}
    />
  </>;
};

export default QRCode;
