import { ChangeEvent } from "react";
import { GuestProductCollectionField } from "../../../types/GuestProductCollectionField";
import { GuestProductCollectionFieldPossibleValues, TEMPORARY_ID_SUFFIX } from "../../../types/GuestProductCollectionFieldPossibleValues";
import { DragTypes } from "../../../constants/Constants";
import Sortable from "../../../components/Sortable.react";
import "./style.css";

interface Props {
  fieldIndex: number;
  guestProductCollectionField: GuestProductCollectionField;

  onFieldParamsChange(e: ChangeEvent<HTMLInputElement>, guestProductCollectionField: GuestProductCollectionField, fieldIndex: number): void;
  onFieldPossibleValuesChange(guestProductCollectionField: GuestProductCollectionField, possible_values: GuestProductCollectionFieldPossibleValues[], fieldIndex: number): void;
}

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`events.edit_advanced.guest_product_collections.${key}`, options);
};

const GuestProductCollectionFieldEdit: React.FC<Props> = ({
  fieldIndex,
  guestProductCollectionField,
  onFieldParamsChange,
  onFieldPossibleValuesChange
}) => {
  const currentPossibleValues = guestProductCollectionField.possible_values || [];
  const sortablePossibleValues = currentPossibleValues?.map((possibeValue, index) => {
    return { ...possibeValue, rank: index };
  });

  const renderField = (): JSX.Element => {
    if (guestProductCollectionField.type === "values_list") {
      return renderValuesListField();
    } else {
      return renderSingleField();
    }
  };

  const renderValuesListField = (): JSX.Element => {
    return <div className="mb-3">
      <input
        type="text"
        className="form-control mb-3"
        value={guestProductCollectionField.name}
        onChange={(e): void => onFieldParamsChange(e, guestProductCollectionField, fieldIndex)}
      />
      <label className="form-label">{ i18n("possible_values") }</label>
      <Sortable itemIdKey="rank"
        itemIndexKey="rank"
        dragType={DragTypes.GUEST_PRODUCT_COLLECTION_FIELD_POSSIBLE_VALUES}
        items={sortablePossibleValues}
        handleDrop={handlePossibleValueDrop}
        handlePosition="left"
      >
        { renderPossibleValueOptions() }
      </Sortable>
      { renderAddPossibleValueButton() }
    </div>;
  };

  const renderPossibleValueOptions = (): JSX.Element[] => {
    return currentPossibleValues?.map((possibleValue, index) => {
      if (possibleValue._destroy === true) {
        return null;
      }

      return <div className="row" key={index}>
        <div className="col-md-8 col-10">
          <input type="text" className="form-control" value={possibleValue.value} onChange={onChangePossibleValueValue(possibleValue._id)} />
        </div>
        <div className="col-2">
          { renderRemovePossibleValueButton(possibleValue._id) }
        </div>
      </div>;
    });
  };

  const renderSingleField = (): JSX.Element => {
    if (guestProductCollectionField.type === "values_list") return;

    return <>
      <label className="form-label">{ i18n("label") }</label>
      <input
        type="text"
        className="form-control"
        value={guestProductCollectionField.name}
        onChange={(e): void => onFieldParamsChange(e, guestProductCollectionField, fieldIndex)}
      />
    </>;
  };

  const renderRequireCheckBox = (): JSX.Element => {
    return <div className="form-check text-end mt-3">
      <label className="form-check-label">
        <input
          type="checkbox"
          className="form-check-input"
          checked={guestProductCollectionField.is_required}
          onChange={(e): void => onFieldParamsChange(e, guestProductCollectionField, fieldIndex)}
        />
        { i18n("required") }
      </label>
    </div>;
  };

  const handlePossibleValueDrop = (_a: any, _b: any, _c: any, _d: any, sortedItems: any): void => {
    const newPossibleValues = sortedItems.map((item, index) => {
      const sortedItem = currentPossibleValues[item.id];
      sortedItem.rank = index;
      return sortedItem;
    });
    onFieldPossibleValuesChange(guestProductCollectionField, newPossibleValues, fieldIndex);
  };

  const onChangePossibleValueValue = (_id: string): ((e: ChangeEvent<HTMLInputElement>) => void) => {
    return (e: ChangeEvent<HTMLInputElement>): void => {
      e.stopPropagation();
      currentPossibleValues.find(val => val._id === _id).value = e.target.value;
      onFieldPossibleValuesChange(guestProductCollectionField, currentPossibleValues, fieldIndex);

    };
  };

  const removePossibleValueHandler = (_id: string): (() => void) => {
    return (): void => {
      const newPossibleValues = [...currentPossibleValues];
      newPossibleValues.find(val => val._id === _id)._destroy = true;
      onFieldPossibleValuesChange(guestProductCollectionField, newPossibleValues, fieldIndex);
    };
  };

  const renderRemovePossibleValueButton = (_id: string): JSX.Element => {
    return <button className="btn btn-secondary" onClick={removePossibleValueHandler(_id)}>
      <i className="fa-regular fa-trash-can" aria-hidden="true"></i>
    </button>;
  };

  const renderAddPossibleValueButton = (): JSX.Element => {
    return <button
      type="button"
      className='add_possible_values_button btn btn-link'
      onClick={addPossibleValueHandler}
    >
      <i className="fa fa-plus" aria-hidden="true"></i> { i18n("add_option") }
    </button>;
  };

  const addPossibleValueHandler = (): void => {
    const newPossibleValues = [
      ...currentPossibleValues,
      {
        _id: `${TEMPORARY_ID_SUFFIX + currentPossibleValues.length}`,
        value: "",
        _destroy: false,
        rank: currentPossibleValues.length,
        isTemporary: true
      }
    ];
    onFieldPossibleValuesChange(guestProductCollectionField, newPossibleValues, fieldIndex);
  };

  return <div className={`card focused ${guestProductCollectionField.hidden ? "d-none" : ""}`}>
    <div className="card-header">
      <h4 className="card-title">{ guestProductCollectionField.name }</h4>
      <div className="form-text">{ i18n(`type_${guestProductCollectionField.type}`) }</div>
    </div>
    <div className="card-body">
      {renderField()}
      {renderRequireCheckBox()}
    </div>
  </div>;
};

export default GuestProductCollectionFieldEdit;
