import { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { urlWithQuery, urlEventLocale } from "../../utils/pathUtils";

interface Props {
  stats: any[];
  dates: Date[];
  query: string;
}

class SummaryReportTable extends Component<Props> {
  i18n(key, opts = {}): string {
    return I18n.t(`react.event_reports.report_table.${key}`, opts);
  }

  renderRow(stats: any, date: Date, key: string): JSX.Element {
    const baseQuery = this.props.query || "status:registered";
    const fDate = moment(date).format("YYYY-MM-DD");
    const stat = stats[fDate];
    const title = moment(date).locale(urlEventLocale()).format("LL");

    return (
      <tr key={key}>
        <td>{title}</td>
        <td>
          {stat.count > 0 ? (
            <Link to={urlWithQuery(baseQuery, "guests")}>{stat.count}</Link>
          ) : stat.count}
        </td>
        <td>
          {stat.new_visits > 0 ? (
            <Link to={urlWithQuery(`${baseQuery} first_attended_date:${fDate}`, "guests")}>{stat.new_visits}</Link>
          ) : stat.new_visits}
        </td>
        <td>
          {stat.returning > 0 ? (
            <Link to={urlWithQuery(`${baseQuery} attended_date:${fDate} -first_attended_date:${fDate}`, "guests")}>{stat.returning}</Link>
          ) : stat.returning}
        </td>
        <td>
          {stat.visits > 0 ? (
            <Link to={urlWithQuery(`${baseQuery} attended_date:${fDate}`, "guests")}>{stat.visits}</Link>
          ) : stat.visits}
        </td>
        <td>
          {stat.no_shows > 0 ? (
            <Link to={urlWithQuery(`${baseQuery} -attended_date:${fDate}`, "guests")}>{stat.no_shows}</Link>
          ) : stat.no_shows}
        </td>
        <td>{(stat.transformation_rate * 100).toFixed(2)} %</td>
      </tr>
    );
  }

  renderTotalRow(stat: any): JSX.Element {
    return (
      <tr key="total" className="info">
        <td>{this.i18n("total")}</td>
        <td>{stat.count}</td>
        <td>{stat.new_visits}</td>
        <td>{stat.returning}</td>
        <td>{stat.visits}</td>
        <td>{stat.no_shows}</td>
        <td>{(stat.transformation_rate * 100).toFixed(2)} %</td>
      </tr>
    );
  }

  renderRows(): JSX.Element[] {
    const { dates, stats } = this.props;
    const rows = dates.map((date, index) => {
      return this.renderRow(stats, date, index.toString());
    });
    rows.push(this.renderTotalRow(stats["total"]));
    return rows;
  }

  render(): JSX.Element {
    return (
      <div className="table-responsive table-container">
        <table className="table table-light table-hover table-sm">
          <thead>
            <tr>
              <th>{I18n.t("react.event_reports.event_reports.day")}</th>
              <th>{this.i18n("registered")}</th>
              <th>{this.i18n("unique_visitors")}</th>
              <th>{this.i18n("returning")}</th>
              <th>{this.i18n("total")}</th>
              <th>{this.i18n("no_shows")}</th>
              <th>{this.i18n("transformation_rate")}</th>
            </tr>
          </thead>
          <tbody>
            { this.renderRows() }
          </tbody>
        </table>
      </div>
    );
  }
}

export default SummaryReportTable;
