"use strict";
import { Component } from "react";
import { exportSortOptions } from "../constants/Constants";
import Checkbox from "./shared/Checkbox.react";

const documentationHost = process.env.APP_DOCUMENTATION_HOST;

class ExportSortOptions extends Component {
  constructor(props) {
    super(props);
    [
      "_isOtherOption",
      "_handlerSubmitButton",
      "_renderSortOptions",
      "_renderSubmitButton",
      "_selectOption",
      "badgesOptionsIfNeeded"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = {
      currentValue: props.defaultValue || "last_name",
      manyBadgesPerPage: false,
    };
  }

  _handlerSubmitButton(e) {
    const { handlerSubmitButton, sheet } = this.props;
    const { currentValue, manyBadgesPerPage } = this.state;
    sheet === "badges" ? handlerSubmitButton(currentValue, !manyBadgesPerPage) : handlerSubmitButton(currentValue);
    e.preventDefault();
  }

  _renderSubmitButton() {
    const { handlerSubmitButton } = this.props;
    return handlerSubmitButton != null ? (
      <button type="button" className="btn btn-primary" onClick={ this._handlerSubmitButton }>
        {I18n.t("react.confirmable_button.default_confirm")}
      </button>
    ) : null;
  }

  _renderCancelButton() {
    const { handlerCancelButton } = this.props;
    const fn = () => { handlerCancelButton(); };
    return handlerCancelButton != null ? (
      <button type="button" className="btn btn-secondary ml-10" onClick={fn}>
        {I18n.t("react.confirmable_button.default_cancel")}
      </button>
    ) : null;
  }

  _selectOption(e) {
    let currentValue = e.target.value;
    if (currentValue == "other") currentValue = "{{ guest.last_name }} {{ guest.first_name }}";
    this.setState({ currentValue: currentValue });
  }

  // return true if other is selected
  _isOtherOption(currentValue = this.state.currentValue) {
    return !exportSortOptions.includes(currentValue);
  }

  badgesOptionsIfNeeded() {
    const { sheet } = this.props;
    if (sheet !== "badges") return null;

    const { manyBadgesPerPage } = this.state;
    return <div className="form-group mt-10">
      <label>{I18n.t("react.export_sort_options.many_badges_per_page_label")}</label>
      <Checkbox
        text={I18n.t("react.export_sort_options.many_badges_per_page_desc")}
        onChange={e => this.setState({ manyBadgesPerPage: e.target.checked })}
        checked={manyBadgesPerPage}
      />
    </div>;
  }

  _renderSortOptions() {
    const { currentValue } = this.state;
    let otherOptionCurrentlySelected = false;
    const options = exportSortOptions.map(sortOption => {
      let checked = false;
      if (currentValue == sortOption) checked = true;
      if (this._isOtherOption() && sortOption == "other") {
        otherOptionCurrentlySelected = true;
        checked = true;
      }
      return (
        <div key={sortOption} className="form-check">
          <label className="form-check-label">
            <input type="radio" className="form-check-input" name="sortOptions" value={sortOption} checked={ checked } onChange={this._selectOption} />
            {I18n.t(`react.export_sort_options.${sortOption}`)}
          </label>
        </div>
      );
    });
    const otherAdditionalInput = otherOptionCurrentlySelected ? (
      <div className="mt-10">
        <input type="text" className="form-control" value={currentValue} onChange={this._selectOption} />
        <div className="form-text">
          {I18n.t("react.export_sort_options.help_1")}
          <code>{"{{ guest }}"}</code>
          {I18n.t("react.export_sort_options.help_2")}
          <a href={`${documentationHost}/liquid/confirmation-email#guest-`} target="_blank">
            {I18n.t("react.export_sort_options.documentation")}
          </a>
        </div>
      </div>
    ) : null;

    return <div>
      {options}
      {otherAdditionalInput}
    </div>;
  }

  render() {
    return (
      <div>
        <label>{I18n.t("react.export_sort_options.sub_title")}</label>

        { this._renderSortOptions() }
        { this.badgesOptionsIfNeeded() }
        <div className="mt-10">
          { this._renderSubmitButton() }
          { this._renderCancelButton() }
        </div>
      </div>
    );
  }
}

export default ExportSortOptions;
