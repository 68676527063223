import { ActionTypes, CALL_API } from "../constants/Constants";
import { stringify } from "querystring";

export function fetchEventContactImportErrors(eventId, options = {}) {
  const { DEV_NULL, FETCH_EVENT_CONTACT_IMPORT_ERRORS_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, FETCH_EVENT_CONTACT_IMPORT_ERRORS_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/custom_errors.json?${stringify(options)}`
    }
  };
}
