import { ActionTypes, CALL_API } from "../constants/Constants";

export function fetchEmailTemplates(eventId) {
  const { DEV_NULL, RECEIVE_EMAIL_TEMPLATES_SUCCESS, RECEIVE_EMAIL_TEMPLATES_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_EMAIL_TEMPLATES_SUCCESS, RECEIVE_EMAIL_TEMPLATES_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/email_templates.json`
    }
  };
}

export function fetchEmailTemplatesFromEvent(eventId) {
  const { DEV_NULL, FETCH_EMAIL_TEMPLATES_FROM_EVENT_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, FETCH_EMAIL_TEMPLATES_FROM_EVENT_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/email_templates.json`
    },
    eventId
  };
}

export function fetchEmailTemplate(eventId, id) {
  const { DEV_NULL, RECEIVE_EMAIL_TEMPLATE_SUCCESS, RECEIVE_EMAIL_TEMPLATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_EMAIL_TEMPLATE_SUCCESS, RECEIVE_EMAIL_TEMPLATE_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/email_templates/${id}.json`
    }
  };
}

export function createEmailTemplate(eventId, emailName, redirectTo = null) {
  const { DEV_NULL, CREATE_EMAIL_TEMPLATE_SUCCESS, CREATE_EMAIL_TEMPLATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, CREATE_EMAIL_TEMPLATE_SUCCESS, CREATE_EMAIL_TEMPLATE_FAILURE],
      method: "POST",
      body: { email_template: { name: emailName } },
      endpoint: `/api/v1/events/${eventId}/email_templates.json`,
      redirectTo
    }
  };
}

export function updateEmailTemplate(eventId, id, emailParams = {}) {
  const { UPDATE_EMAIL_TEMPLATE_REQUEST, UPDATE_EMAIL_TEMPLATE_SUCCESS, UPDATE_EMAIL_TEMPLATE_ERROR } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_EMAIL_TEMPLATE_REQUEST, UPDATE_EMAIL_TEMPLATE_SUCCESS, UPDATE_EMAIL_TEMPLATE_ERROR],
      method: "PUT",
      body: { email_template: emailParams },
      endpoint: `/api/v1/events/${eventId}/email_templates/${id}.json`
    }
  };
}

export function fetchEmailTemplateSectionTypes(eventId, id) {
  const { DEV_NULL, RECEIVE_SECTION_TYPES_SUCCESS, RECEIVE_SECTION_TYPES_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_SECTION_TYPES_SUCCESS, RECEIVE_SECTION_TYPES_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/email_templates/${id}/section_types.json`
    },
    builderType: "email_template"
  };
}

export function duplicateEmailTemplate(eventId, emailTemplateId, params, duplicateInCurrentEvent = false) {
  const { DUPLICATE_EMAIL_TEMPLATE_PENDING, DUPLICATE_EMAIL_TEMPLATE_SUCCESS, DUPLICATE_EMAIL_TEMPLATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DUPLICATE_EMAIL_TEMPLATE_PENDING, DUPLICATE_EMAIL_TEMPLATE_SUCCESS, DUPLICATE_EMAIL_TEMPLATE_FAILURE],
      method: "POST",
      body: params,
      endpoint: `/api/v1/events/${eventId}/email_templates/${emailTemplateId}/duplicate.json`
    },
    duplicateInCurrentEvent: duplicateInCurrentEvent || eventId == params.target_event_id
  };
}

export function deleteEmailTemplate(eventId, emailTemplateId) {
  const { DEV_NULL, DELETE_EMAIL_TEMPLATE_SUCCESS, DELETE_EMAIL_TEMPLATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, DELETE_EMAIL_TEMPLATE_SUCCESS, DELETE_EMAIL_TEMPLATE_FAILURE],
      method: "DELETE",
      endpoint: `/api/v1/events/${eventId}/email_templates/${emailTemplateId}.json`
    },
    emailTemplateId
  };
}

export function updateEmailSelectedId(selectedTemplateId) {
  return {
    type: ActionTypes.UPDATE_EMAIL_SELECTED_ID,
    data: selectedTemplateId
  };
}

export function updateEmailSections(emailSections) {
  return {
    type: ActionTypes.UPDATE_EMAIL_SECTIONS,
    objects: emailSections
  };
}

export function updateSection(sectionKey, sectionSettings, sectionChanges) {
  return {
    type: ActionTypes.UPDATE_SECTION,
    sectionKey: sectionKey,
    settings: sectionSettings,
    changes: sectionChanges
  };
}

export function sendChangesNotification() {
  return {
    type: ActionTypes.RECEIVE_EMAIL_TEMPLATE_CHANGES
  };
}

export function createNewSection(sectionKey, sectionData) {
  return {
    type: ActionTypes.CREATE_NEW_EMAIL_SECTION,
    sectionKey,
    sectionData
  };
}

export function removeSection(sectionKey) {
  return {
    type: ActionTypes.REMOVE_EMAIL_SECTION,
    sectionKey
  };
}

export function createNewBlock(blockKey, blockData, sectionKey) {
  return {
    type: ActionTypes.CREATE_NEW_BLOCK,
    blockKey,
    blockData,
    sectionKey
  };
}

export function removeBlock(blockKey, sectionKey) {
  return {
    type: ActionTypes.REMOVE_BLOCK,
    blockKey,
    sectionKey
  };
}

export function reloadTheme(eventId, emailTemplateId, templateProjectRoot) {
  const {
    RELOAD_EMAIL_TEMPLATE_THEME_REQUEST,
    RELOAD_EMAIL_TEMPLATE_THEME_SUCCESS,
    RELOAD_EMAIL_TEMPLATE_THEME_FAILURE
  } = ActionTypes;

  return {
    [CALL_API]: {
      types: [RELOAD_EMAIL_TEMPLATE_THEME_REQUEST, RELOAD_EMAIL_TEMPLATE_THEME_SUCCESS, RELOAD_EMAIL_TEMPLATE_THEME_FAILURE],
      method: "PUT",
      body: { template_project_root: templateProjectRoot },
      endpoint: `/api/v1/events/${eventId}/email_templates/${emailTemplateId}/reload_theme.json`,
      nextAction: fetchEmailTemplateSectionTypes(eventId, emailTemplateId)
    }
  };
}

export function clearEmailTemplate() {
  return {
    type: ActionTypes.CLEAR_EMAIL_TEMPLATE
  };
}
