import { ActionTypes, CALL_API } from "../constants/Constants";

export function fetchTicketing(eventId, ticketingId) {
  const { DEV_NULL, RECEIVE_TICKETING_SUCCESS, RECEIVE_TICKETING_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_TICKETING_SUCCESS, RECEIVE_TICKETING_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/ticketing/${ticketingId}.json`
    }
  };
}

export function createTicketing(eventId, ticketingParams, redirectTo, redirectToOptions = {}) {
  const { CREATE_TICKETING_REQUEST, CREATE_TICKETING_SUCCESS, CREATE_TICKETING_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [CREATE_TICKETING_REQUEST, CREATE_TICKETING_SUCCESS, CREATE_TICKETING_FAILURE],
      method: "POST",
      body: { ticketing: ticketingParams },
      endpoint: `/api/v1/events/${eventId}/ticketing.json`,
      redirectTo,
      redirectToOptions
    }
  };
}

export function updateTicketing(eventId, ticketingId, ticketingParams, redirectTo, notificationsOptions = {}) {
  const { UPDATE_TICKETING_REQUEST, UPDATE_TICKETING_SUCCESS, UPDATE_TICKETING_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_TICKETING_REQUEST, UPDATE_TICKETING_SUCCESS, UPDATE_TICKETING_FAILURE],
      method: "PUT",
      body: { ticketing: ticketingParams },
      endpoint: `/api/v1/events/${eventId}/ticketing/${ticketingId}.json`,
      redirectTo,
      notificationsOptions
    }
  };
}