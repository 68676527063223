import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import truncate from "lodash/truncate";
import classNames from "classnames";
import Modal from "react-modal";
import { fetchGuestCategory, updateGuestCategory, fetchGuestCategories } from "../actions/GuestCategoryActionCreators";
import { fetchRegistrationForms, createRegistrationForm } from "../actions/RegistrationFormsActionCreators";
import GuestCategoriesAndTicketsLinked from "../components/shared/GuestCategoriesAndTicketsLinked.react";
import Loader from "../components/shared/Loader.react";
import ErrorMessage from "../components/shared/ErrorMessage.react";
import HelpSection from "../components/shared/HelpSection.react";
import { urlEventId } from "../utils/pathUtils";
import { defaultModalSmallStyles } from "../constants/Style";

class SelectRegistrationFormModal extends Component {

  constructor(props) {
    super(props);
    [
      "loadData",
      "linkFormToGuestCategory",
      "createRegistrationForm"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      pendingRequestForRegistrationFormId: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { pendingRequestForRegistrationFormId } = this.state;
    const { registrationForms, afterAttachingToForm, guestCategoryErrors, newRegistrationFormId, guestCategory } = this.props;

    if (pendingRequestForRegistrationFormId && pendingRequestForRegistrationFormId == guestCategory.registration_form_id) {
      this.setState({ pendingRequestForRegistrationFormId: null }, () => afterAttachingToForm());
    }

    if (Object.keys(prevProps.guestCategoryErrors).length == 0 && Object.keys(guestCategoryErrors).length > 0) {
      this.setState({ pendingRequestForRegistrationFormId: null });
    }

    if (prevProps.registrationForms.length < registrationForms.length && newRegistrationFormId) {
      this.linkFormToGuestCategory(newRegistrationFormId)();
    }
  }

  loadData() {
    const { guestCategory, guestCategoryId, fetchGuestCategory, fetchRegistrationForms, fetchGuestCategories } = this.props;

    if ((!guestCategory || Object.keys(guestCategory).length === 0) && guestCategoryId)
      fetchGuestCategory(urlEventId(), guestCategoryId);
    fetchRegistrationForms(urlEventId(), 1, 100);
    fetchGuestCategories(urlEventId());
  }

  linkFormToGuestCategory(registrationFormId) {
    return () => {
      this.setState({ pendingRequestForRegistrationFormId: registrationFormId });
      this.updateGuestCategory({ registration_form_id: registrationFormId, custom_registration_form_enabled: true });
    };
  }

  createRegistrationForm() {
    const { createRegistrationForm } = this.props;

    createRegistrationForm(urlEventId());
  }

  updateGuestCategory(params, redirectTo = null) {
    const { guestCategory, updateGuestCategory } = this.props;

    updateGuestCategory(urlEventId(), guestCategory._id, params, redirectTo);
  }

  i18n(key) {
    return I18n.t(`react.guest_category.attach_to_registration_form_modal.${key}`);
  }

  renderErrors() {
    const { guestCategoryErrors } = this.props;
    return <ErrorMessage errors={guestCategoryErrors}
      errorsHeaderMessage={this.i18n("cannot_attach_to_form")}
      model="guest_category" />;
  }

  renderRegistrationFormsRows() {
    const { registrationForms, guestCategories } = this.props;

    return registrationForms.map((registrationForm, i) => {
      return (
        <div key={`registration-form-${i}`} className="list-item">
          <div className="row align-items-center">
            <div className="col-9">
              <span className="mr-5">{truncate(registrationForm.title, { "length": 45 })}</span>
              <GuestCategoriesAndTicketsLinked form={registrationForm} guestCategories={guestCategories} labelLinkable={false} />
            </div>
            <div className="col-3 text-end">
              { this.renderLinkButton(registrationForm) }
            </div>
          </div>
        </div>
      );
    });
  }

  renderLinkButton(registrationForm) {
    const { pendingRequestForRegistrationFormId } = this.state;
    const { guestCategory } = this.props;

    if (pendingRequestForRegistrationFormId || registrationForm._id == guestCategory.registration_form_id) {
      return (
        <button className={classNames("btn", "btn-secondary", "unlinkable-link")} disabled="disabled">
          { pendingRequestForRegistrationFormId == registrationForm._id
            ? <span><Loader size="small" /> { this.i18n("attach_pending") }</span>
            : <span><i className="fa-regular fa-link"/> { registrationForm._id == guestCategory.registration_form_id ? this.i18n("selected") : this.i18n("select") }</span>
          }
        </button>
      );
    }

    return (
      <button className={classNames("btn", "btn-secondary")} onClick={this.linkFormToGuestCategory(registrationForm._id)}>
        <i className="fa-regular fa-link"/> { this.i18n("select") }
      </button>
    );
  }

  renderModalContent() {
    const { closeModal, guestCategory } = this.props;

    if (!guestCategory)
      return <Loader size="small" />;

    return [
      <div className="modal-header" key="modal-header">
        <h4 className="modal-title">
          { guestCategory.registration_form_id ? this.i18n("title_change") : this.i18n("title_enable") }
        </h4>
        <button type="button" onClick={closeModal} className="btn-close" aria-label={I18n.t("close")}></button>
      </div>,
      <div className="modal-body modal-list-with-buttons" key="modal-body">
        <HelpSection help={this.i18n("help_message")} />
        { this.renderErrors() }
        <div className="list-item">
          <a className="btn btn-primary col-12" onClick={this.createRegistrationForm}><i className="fa-regular fa-plus" /> { this.i18n("create_new_form") }</a>
        </div>
        { this.renderRegistrationFormsRows() }
      </div>
    ];
  }

  render() {
    const { isOpen, closeModal } = this.props;

    return (
      <Modal isOpen={isOpen}
        onAfterOpen={this.loadData}
        onRequestClose={closeModal}
        style={defaultModalSmallStyles}
        contentLabel="Modal">
        { this.renderModalContent() }
      </Modal>
    );
  }
}

SelectRegistrationFormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  guestCategory: PropTypes.object,
  guestCategoryId: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  afterAttachingToForm: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    guestCategory: state.guestCategory.data,
    guestCategoryErrors: state.guestCategory.errors,
    registrationForms: state.registrationForms.data,
    newRegistrationFormId: state.registrationForms.newRegistrationFormId,
    guestCategories: state.guestCategories.data
  };
}

const mapDispatchToProps = {
  fetchGuestCategory,
  fetchRegistrationForms,
  updateGuestCategory,
  fetchGuestCategories,
  createRegistrationForm
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectRegistrationFormModal);
