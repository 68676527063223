import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMessages } from "../../actions/MessageActionCreator";
import Loader from "../../components/shared/Loader.react";
import ReportHeader from "../../components/event_reports/ReportHeader.react";
import { isAuthorized } from "../../utils/aclUtils";
import DateRangePicker from "../../components/DateRangePicker.react";
import moment from "moment";

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`react.event_reports.messages.${key}`, options);
};

const Messages: React.FC = () => {
  const currentEvent = useSelector((state: any) => state.event);
  const dataFetched = useSelector((state: any) => state.messagesStatistics.messages);
  const isFetching = useSelector((state: any) => state.messagesStatistics.isFetching);
  const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  if (!isAuthorized("reports", "read")) return null;

  useEffect(() => {
    if (!currentEvent.id) return;

    dispatch(fetchMessages(currentEvent.id, null, null));
  }, [currentEvent]);

  useEffect(() => {
    if (!dataFetched) return;

    setResult(dataFetched);
  }, [dataFetched]);

  useEffect(() => {
    if (currentEvent.id) {
      dispatch(fetchMessages(currentEvent.id, startDate, endDate));
    }
  }, [startDate, endDate]);

  const renderHeader = (): JSX.Element => {
    return <ReportHeader
      title={I18n.t("react.event_reports.event_reports.messages_sent_on_this_event")}
    />;
  };

  const renderTimeRangeSelector = (): JSX.Element => {
    if (!currentEvent.id) return null;

    return (
      <div className="datepicker-group mt-3">
        <DateRangePicker
          startDate={startDate ? moment(startDate) : null }
          endDate={endDate ? moment(endDate) : null }
          onDateRangeChanged={(start, end): void => {
            setStartDate(start?.toDate());
            setEndDate(end?.toDate());
          }}
          showClearButton={true}
          withTimePicker={true} />
      </div>
    );
  };

  const renderTableHeader = (): JSX.Element => {
    return (
      <tr>
        <th>{I18n.t("react.event_reports.report_table.messages.type")}</th>
        <th>{I18n.t("react.event_reports.report_table.messages.number")}</th>
      </tr>
    );
  };

  const renderTable = (): JSX.Element => {
    if (!dataFetched) return null;
    if (result.length === 0) return null;

    return <div className="table-responsive table-container">
      <table className="table table-light table-hover table-sm">
        <thead>
          {renderTableHeader()}
        </thead>
        <tbody>
          { renderTableBody()}
        </tbody>
      </table>
    </div>;
  };

  const renderTableBody = (): JSX.Element[] => {
    return Object.keys(result).map((counter, index) => {
      return (
        <tr key={index}>
          <td>
            {i18n(counter)}
          </td>
          <td>{result[counter]}</td>
        </tr>
      );
    });
  };

  return <>
    { renderHeader() }
    { renderTimeRangeSelector() }
    { isFetching ? <Loader/> : renderTable() }
  </>;
};

export default Messages;
