import { Component } from "react";
import SectionView from "../../components/templates_builder/SectionView.react";

class AppearanceSubsection extends Component {
  constructor(props) {
    super(props);
    [
      "close"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
  }

  close() {
    this.props.toggleView();
  }

  render() {
    const { subsectionKey, templateId, event, hasChanges } = this.props;
    if (!subsectionKey) {
      return <SectionView />;
    }
    const { mainSettingsSchema, mainSettingsData, detectChanges, sendChanges } = this.props;
    const appearanceTypeSchema = mainSettingsSchema.find(s => s.id == subsectionKey);

    return (
      <SectionView sectionKey={subsectionKey}
        schema={appearanceTypeSchema}
        configuration={mainSettingsData}
        hide={this.close}
        detectChanges={detectChanges}
        sendChanges={sendChanges}
        deletable={false}
        builderType="website"
        templateId={templateId}
        noVisibilitySettings={true}
        event={event}
        hasChanges={hasChanges}
        withSidebar={true} />
    );
  }
}

export default AppearanceSubsection;
