import withProgramFilter from "../withProgramFilter.react";
import InputLabelWithVisibilityButton from "../InputLabelWithVisibilityButton.react";
import { FilterProps } from "../../../types/Program";

const AttendanceTypeFilter: React.FC<FilterProps> = ({ programFilter, onChangeLabelOptions }) => {
  return <InputLabelWithVisibilityButton
    label={I18n.t("react.programs.label")}
    value={programFilter.label}
    placeholder={I18n.t("react.programs.attendance_type_filter.title")}
    name="attendance_type_label"
    isDisplayed={programFilter.label_displayed}
    onChangeHandler={onChangeLabelOptions}
  />;
};

export default withProgramFilter(AttendanceTypeFilter, "ui");
