import { useRef, useState } from "react";
import { OverlayTrigger, Button } from "react-bootstrap";
import { addTooltip } from "../../utils/templatesBuilderUtils";
import { urlEventId, pathToGuestCategoryBadgeTemplatesList } from "../../utils/pathUtils";
import Icons from "../../constants/Icons";
import { FabricEditorFunctions } from "./fabricjs/types";
import { BadgeImage } from "../../types/BadgeTemplate";

interface Props {
  eventId: string;
  badgeTemplateId: string;
  guestCategoryId: string;
  title: string;
  isEditMode: boolean;
  editor?: FabricEditorFunctions;
  badgeImages: BadgeImage[];
  onChangeImage(badgeImage: BadgeImage): void;
}

function i18n(key, opts = {}): string {
  return I18n.t(`react.badge_builder.badge_builder_nav_bar.${key}`, opts);
}

const BadgeBuilderNavBar: React.FC<Props> = ({
  eventId,
  badgeTemplateId,
  guestCategoryId,
  title,
  isEditMode,
  editor,
  badgeImages,
  onChangeImage
}) => {
  const inputFileRef = useRef(null);

  const [isRegenerating, setIsRegenerating] = useState<boolean>(false);

  const addText = (): void => {
    editor?.addText(I18n.t("react.badge_builder.fabric_editor.default_text_value"));
  };

  const addQRCode = (): void => {
    editor?.addQRCode();
  };

  const triggerInputFile = (): void => {
    inputFileRef.current.click();
  };

  const handleInputChange = () => (e): any => {
    const img = new Image();
    img.src = window.URL.createObjectURL(e.target.files[0]);
    img.onload = (): void => {
      onChangeImage({
        position: e.target.name,
        url: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
        width: img.naturalWidth.toString(),
        height: img.naturalHeight.toString()
      });
    };
  };

  const regenerateTemplate = () : void => {
    setIsRegenerating(true);
    fetch(`/api/v1/events/${eventId}/guest_categories/${guestCategoryId}/badge_templates/${badgeTemplateId}/regenerate_template.json`, {
      method: "PUT"
    })
      .then(response => {
        setIsRegenerating(false);
        alert(response.ok ? i18n("template_ready") : i18n("regeneration_error_message"));
      })
      .catch((error) => {
        setIsRegenerating(false);
        alert(i18n("regeneration_error_message"));
        console.error(error);
      });
  };

  const renderCloseButton = (): JSX.Element => {
    return <OverlayTrigger placement="right" overlay={addTooltip(I18n.t("react.builders.close_builder"))}>
      <a href={pathToGuestCategoryBadgeTemplatesList(urlEventId(), guestCategoryId)} className="d-flex">{ Icons.close() }</a>
    </OverlayTrigger>;
  };

  const renderTitle = (): JSX.Element => {
    return <span className="ml-15">
      { isEditMode ? title : I18n.t("react.badge_builder.badge_template_settings_modal.name") }
    </span>;
  };

  const renderToolbarButton = (tooltip: string, icon: string, fn = null): JSX.Element => {
    return <OverlayTrigger placement="bottom" overlay={addTooltip(i18n(`add_${tooltip}`))}>
      <Button variant="secondary" onClick={fn}>
        <i className={`fa-regular fa-${icon}`} aria-hidden="true" />
      </Button>
    </OverlayTrigger>;
  };

  const renderHiddenInputFile = (): JSX.Element => {
    return <input
      type="file"
      accept="image/*"
      name={`image_${badgeImages.length}`}
      hidden={true}
      ref={inputFileRef}
      onChange={handleInputChange()}
    />;
  };

  const renderToolbar = (): JSX.Element => {
    return <div className="btn-group">
      {renderToolbarButton("text", "font", addText)}
      {renderToolbarButton("qrcode", "qrcode", addQRCode)}
      {renderToolbarButton("image", "image", triggerInputFile)}
    </div>;
  };

  return <nav className={`navbar navbar-expand navbar-light bg-light navbar-static-top ${editor ? "" : "disabled-navbar" }`}>
    <div className="container-fluid">
      <div className="col-3 navbar-text d-flex align-items-center">
        {renderCloseButton()}
        {renderTitle()}
      </div>
      <div className="col-3 d-flex align-items-center justify-content-center">
        {renderToolbar()}
        {renderHiddenInputFile()}
      </div>
      <div className="col-3 d-flex align-items-center justify-content-end">
        <Button variant="primary" className="mr-5" onClick={regenerateTemplate} disabled={isRegenerating}>
          {isRegenerating ? (
            i18n("please_wait")
          ) : (
            <>
              <i className="fa-regular fa-redo-alt" aria-hidden="true" /> {i18n("regenerate_template")}
            </>
          )}
        </Button>
        <Button variant="secondary">
          <i className="fa-regular fa-eye" aria-hidden="true" /> { i18n("preview") }
        </Button>
      </div>
    </div>
  </nav>;
};

export default BadgeBuilderNavBar;
