import { Component } from "react";
import { CONFIRMATION_PAGE_REGEX, OFFLINE_COLOR } from "../../constants/Constants";
import classNames from "classnames";
import sortBy from "lodash/sortBy";
import { OverlayTrigger } from "react-bootstrap";
import { addTooltip } from "../../utils/templatesBuilderUtils";
import { searchFromSearchableKeywords } from "../../utils/searchUtils.js";

class WebsitePagesList extends Component {
  constructor(props) {
    super(props);
    [
      "close",
      "onPageClick",
      "setSearch"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = { search: "" };
  }

  close() {
    this.props.toggleView();
  }

  onPageClick(page) {
    return () => {
      this.props.switchCurrentPage(page, true);
    };
  }

  homepageIcon(page) {
    if (!page.is_homepage) return;

    return <div className="text-muted ml-10">
      <OverlayTrigger placement="top" overlay={addTooltip(I18n.t("react.website.homepage"))}>
        <i className="fa-regular fa-home"></i>
      </OverlayTrigger>
    </div>;
  }

  pageOfflineIcon(page) {
    if (page.online)
      return;

    return <div className="ml-10">
      <OverlayTrigger placement="top" overlay={addTooltip(I18n.t("react.website.page_offline"))}>
        <i className="fa-solid fa-circle" style={{ color: OFFLINE_COLOR }}></i>
      </OverlayTrigger>
    </div>;
  }

  pageLockedIcon(page) {
    if (!page.locked)
      return;

    return <div className="text-muted ml-10">
      <OverlayTrigger placement="top" overlay={addTooltip(I18n.t("react.website.page_locked"))}>
        <i className="fa-regular fa-lock"></i>
      </OverlayTrigger>
    </div>;
  }

  setSearch(e) {
    this.setState({ search: e.target.value });
  }

  filteredPages() {
    const { pages } = this.props;
    const { search } = this.state;

    return sortBy(searchFromSearchableKeywords(pages, search), [function(p) { return p.name.toLowerCase(); }]).filter(page => {
      return !page.path_name.match(CONFIRMATION_PAGE_REGEX);
    });
  }

  itemClasses(page) {
    const { currentPage } = this.props;

    return classNames({
      "list-group-item d-flex justify-content-between": true,
      "active": page.filename == currentPage.filename
    });
  }

  render() {
    const { isShown } = this.props;
    if (!isShown) {
      return <div className="website-switch-page"></div>;
    }

    const { search } = this.state;

    return (
      <div className="website-switch-page in">
        <div className="sidebar-header">
          <p className="lead">
            { I18n.t("react.website.pages") }
          </p>
        </div>

        <div className="sidebar-body">
          <div className="mb-3">
            <input type="text" className="form-control" placeholder={I18n.t("react.website.search_page") } value={search} onChange={this.setSearch} />
          </div>
          <div className="card">
            <div className="card-header">
              <a onClick={ this.props.displayNewPageForm }>
                <i className="fa-regular fa-plus-circle"></i> { I18n.t("react.website.new_page") }
              </a>
            </div>
            <ul className="list-group">
              { this.filteredPages().map(page => {
                return (<li className={this.itemClasses(page)} onClick={this.onPageClick(page)} key={page.filename}>
                  { page.name } { this.homepageIcon(page) } { this.pageLockedIcon(page) } { this.pageOfflineIcon(page) }
                </li>);
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default WebsitePagesList;
