import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { isEnabled } from "../../utils/featureSetUtils";
import { NOTIFICATION } from "../../constants/FeaturesSet";

const renderBody = (props) => {
  const { onSelected } = props;
  const proposeMessageEnabled = isEnabled(NOTIFICATION);
  const numberOfMedium = 2 + (proposeMessageEnabled ? 1 : 0);

  const squareStyle = {
    width: `${(100 / numberOfMedium).toString()}%`,
    height: "100%",
    paddingLeft: "10px",
    paddingRight: "10px"
  };

  return (
    <div className="row">
      <div className="col-4" style={squareStyle}>
        <Button
          variant="secondary"
          onClick={() => onSelected("use_email")}
          style={{ width: "100%", height: "18vh" }}>
          <div>
            <i className="fa-regular fa-envelope fa-3x"></i>
          </div>
          {I18n.t("react.guest_campaign.popup.use_email")}
        </Button>
      </div>
      <div className="col-4" style={squareStyle}>
        <Button
          variant="secondary"
          onClick={() => onSelected("use_sms")}
          style={{ width: "100%", height: "18vh" }}>
          <div>
            <i className="fa-regular fa-mobile fa-3x"></i>
          </div>
          {I18n.t("react.guest_campaign.popup.use_sms")}
        </Button>
      </div>
      { proposeMessageEnabled && <div className="col-4" style={squareStyle}>
        <Button
          variant="secondary"
          onClick={() => onSelected("use_message")}
          style={{ width: "100%", height: "18vh" }}>
          <div>
            <i className="fa-regular fa-bell fa-3x"></i>
          </div>
          {I18n.t("react.guest_campaign.popup.use_message")}
        </Button>
      </div>}
    </div>
  );
};

const GuestCampaignSelectMediumModal = props => {
  const { isVisible, onCanceled } = props;
  const modalStyles = {
    top: "50%",
    transform: "translateY(-50%)"
  };
  return (
    <Modal show={isVisible} style={modalStyles}>
      <Modal.Header>
        <Modal.Title>{I18n.t("react.guest_campaign.popup.title")}</Modal.Title>
        <button type="button" onClick={onCanceled} className="btn-close" aria-label={I18n.t("close")}></button>
      </Modal.Header>
      <Modal.Body>
        {renderBody(props)}
      </Modal.Body>
    </Modal>
  );
};

GuestCampaignSelectMediumModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onSelected: PropTypes.func.isRequired,
  onCanceled: PropTypes.func.isRequired
};

export default GuestCampaignSelectMediumModal;
