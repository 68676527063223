import { Component, MouseEvent } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { fetchGuestEngagementHistoryItems, fetchHistoryItemsWithEndpoint, fetchThematicScoringHistoryItems, clearHistoryItems } from "../actions/HistoryItemsActionCreators";
import { Guest } from "../types/Guest";
import { HistoryItem } from "../types/HistoryItem";
import { Accesspoint } from "../types/Accesspoint";
import { ThematicScoring } from "../types/ThematicScoring";
import { Thematic } from "../types/Thematic";
import { Event } from "../types/Event";
import { GuestCategory } from "../types/GuestCategory";
import Loader from "../components/shared/Loader.react";
import HalfCircleCounter from "../components/shared/HalfCircleCounter.react";
import { PRIMARY_COLOR } from "../constants/Constants";

function i18n(key, opts = {}): string {
  return I18n.t(`react.guests.history_items.${key}`, opts);
}

interface Props {
  accesspoints?: Accesspoint[];
  clearHistoryItems?(): void;
  event?: Event;
  fetchGuestEngagementHistoryItems?(guest: Guest, initialCall?: boolean): any;
  fetchHistoryItemsWithEndpoint?(endpoint: string, initialCall?: boolean);
  fetchThematicScoringHistoryItems?(thematicScoring: ThematicScoring, initialCall?: boolean): void;
  guest: Guest;
  guestCategories?: GuestCategory[];
  historyItems?: HistoryItem[];
  isOpen: boolean;
  nextURL?: string;
  thematics?: Thematic[];
  thematicScoring: ThematicScoring;
  toggleModal(): void;
}

interface State {
  currentProgress: number;
}

class EngagementHistoryModal extends Component<Props, State> {
  constructor(props) {
    super(props);
    [
      "toggleModal",
      "onShow",
      "fetchNextURL"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = { currentProgress: 0 };
  }

  scoringDeltaSpan(historyItem, scoreField): JSX.Element {
    const oldScore = historyItem.item_changes[scoreField][0] || 0;
    const newScore = historyItem.item_changes[scoreField][1] || 0;
    const diff = newScore - oldScore;
    return diff > 0 ? <span className="text-success">+{diff}</span> : <span className="text-danger">{diff}</span>;
  }

  historyItemRelatedInstanceName(historyItem: HistoryItem): JSX.Element {
    if (["register_session", "unregister_session", "checkin_session", "uncheckin_session"].includes(historyItem.action)) {
      const { accesspoints } = this.props;
      const accesspoint = accesspoints.find(acc => acc._id === historyItem.message);
      if (!accesspoint) return;

      return <p>{ accesspoint.name }</p>;
    } else if (["open_email", "click_email"].includes(historyItem.action)) {
      const name = I18n.t(`guests.emails_history.${historyItem.message}`, { defaultValue: "" });
      return name === "" ? null : <p>{ name }</p>;
    }
    return;
  }

  historyItemsLine(historyItem, scoreField): JSX.Element {
    if (!historyItem.action) return;
    if (!historyItem.item_changes[scoreField]) return;

    return <li key={historyItem._id} className="list-group-item">
      <div className="float-end"><small>{ I18n.l("time.formats.default", historyItem.created_at) }</small></div>
      <h4 className="list-group-item-heading">{ I18n.t(`thematic_scoring_actions.${historyItem.action}`) }</h4>
      <div className="list-group-item-text">
        { this.historyItemRelatedInstanceName(historyItem) }
        <div style={{ fontSize: "16px" }} className="mt-10">
          <strong>{ historyItem.item_changes[scoreField][0] }</strong> <small>{ this.scoringDeltaSpan(historyItem, scoreField) }</small>
        </div>
      </div>
    </li>;
  }

  scoreFieldName(): string {
    const { thematicScoring } = this.props;

    return thematicScoring ? "score" : "engagement_score";
  }

  renderContent(): JSX.Element {
    const { historyItems } = this.props;

    if (!historyItems) return <Loader />;
    if (historyItems.length == 0) return I18n.t("history_items.index.no_history");

    return <ul className="list-group" id="item-list">
      { historyItems.map(historyItem => this.historyItemsLine(historyItem, this.scoreFieldName())) }
    </ul>;
  }

  toggleModal(): void {
    const { toggleModal, clearHistoryItems, isOpen } = this.props;
    toggleModal();
    if (isOpen) setTimeout(clearHistoryItems, 50);
  }

  updateProgressBar(currentProgress = 0, delay = 1): void {
    const timeout = currentProgress < 100 ? (): number => setTimeout((): void => this.updateProgressBar(currentProgress + 1), delay) : null;
    this.setState({ currentProgress }, timeout);
  }

  onShow(): void {
    const { fetchGuestEngagementHistoryItems, fetchThematicScoringHistoryItems, guest, thematicScoring } = this.props;

    if (thematicScoring) {
      fetchThematicScoringHistoryItems(thematicScoring);
    } else {
      fetchGuestEngagementHistoryItems(guest);
    }

    this.updateProgressBar(0, 1000);
  }

  fetchNextURL(e: MouseEvent<HTMLAnchorElement>): void {
    e.preventDefault();
    const { nextURL, fetchHistoryItemsWithEndpoint } = this.props;

    fetchHistoryItemsWithEndpoint(nextURL, false);
  }

  renderLoadMore(): JSX.Element {
    const { nextURL } = this.props;

    if (!nextURL) return;

    return <a href="#" onClick={this.fetchNextURL}>
      { I18n.t("history_items.load_more.load_more") }
    </a>;
  }

  thematic(): Thematic {
    const { thematicScoring, thematics } = this.props;

    return thematicScoring && thematics.find(th => th._id === thematicScoring.thematic_id);
  }

  titleText(): string {
    const { thematicScoring, guest } = this.props;

    if (thematicScoring) {
      return i18n("thematic_title", { identity: guest.identity, thematic_name: this.thematic().name });
    } else {
      return i18n("engagement_title", { identity: guest.identity });
    }
  }

  score(): number {
    const { thematicScoring, guest } = this.props;

    return thematicScoring ? thematicScoring.score : guest.engagement_score;
  }

  population(): string {
    const { guest, guestCategories } = this.props;
    const category = guestCategories.find(cat => cat._id === guest.guest_category_id);

    return category.population_type;
  }

  maxScore(): number {
    const { thematicScoring, event } = this.props;

    if (thematicScoring) {
      if (!this.thematic() || !this.thematic().max_score) return 0;
      return this.thematic().max_score[this.population()];
    } else if (event.fetched) {
      return event.engagement_avg[this.population()] + event.engagement_std_dev[this.population()] * 2;
    } else {
      return 0;
    }
  }

  scoreInProgress(): number {
    return Math.floor(this.score() * this.state.currentProgress / 100);
  }

  displayQuartile(): string {
    const { thematicScoring, guest } = this.props;

    if (thematicScoring) {
      return I18n.t(`guests.table_guest.guest_quartiles.${guest.thematics_quartile[this.thematic()._id] || 0}_value`);
    } else {
      return I18n.t(`guests.table_guest.guest_quartiles.${guest.engagement_quartile || 0}_value`);
    }
  }

  svgScore(): JSX.Element {
    const { isOpen } = this.props;
    if (!isOpen) return;

    return <div className="card-thematic-scoring">
      <div className="card-body" style={{ maxWidth: "50%", margin: "0 auto" }}>
        <HalfCircleCounter
          count={this.scoreInProgress()}
          max={this.maxScore()}
          foregroundColor={ PRIMARY_COLOR }
          backgroundColor="#eeeeee"
          subtitle={this.displayQuartile()}
        />
      </div>
    </div>;
  }

  render(): JSX.Element {
    const { isOpen } = this.props;

    return <Modal show={isOpen} onHide={this.toggleModal} onShow={this.onShow}>
      <Modal.Header>
        <Modal.Title>{ this.titleText() }</Modal.Title>
        <button type="button" onClick={this.toggleModal} className="btn-close" aria-label={I18n.t("close")}></button>
      </Modal.Header>
      <Modal.Body style={{ overflow: "auto", maxHeight: "80vh" }}>
        { this.svgScore() }
        <div className="row" >
          <div className="col-md-12">
            { this.renderContent() }
            { this.renderLoadMore() }
          </div>
        </div>
      </Modal.Body>
    </Modal>;
  }
}

function mapStateToProps(state): any {
  return {
    historyItems: state.historyItems.data,
    nextURL: state.historyItems.nextURL,
    accesspoints: state.accesspoints.data,
    thematics: state.thematics.items,
    event: state.event,
    guestCategories: state.guestCategories.data
  };
}

const mapDispatchToProps = {
  fetchGuestEngagementHistoryItems,
  fetchHistoryItemsWithEndpoint,
  fetchThematicScoringHistoryItems,
  clearHistoryItems
};

export default connect(mapStateToProps, mapDispatchToProps)(EngagementHistoryModal);
