import { Component } from "react";
import ColoredObjectRow from "./ColoredObjectRow.react";
import ConfirmationModal from "./shared/ConfirmationModal.react";

const EMPTY_CHILD_ITEM_ID = "temp_id";

class ColoredOjectsTable extends Component {
  constructor(props) {
    super(props);
    [
      "openConfirmationModal",
      "closeConfirmationModal",
      "handleDelete",
      "handleUpdate",
      "changeFocusedItem",
      "setupEmptyChildItemHandler"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      itemToDestroy: null,
      focusedItem: null,
      temporaryItem: null
    };
  }

  componentDidUpdate(prevProps) {
    const { lastUpdatedItemId, errors } = this.props;
    if (!lastUpdatedItemId && !errors) return null;

    if (prevProps.lastUpdatedItemId !== lastUpdatedItemId && !errors) {
      this.setState({ focusedItem: null, temporaryItem: null });
    }
  }

  openConfirmationModal(item) {
    this.setState({ itemToDestroy: item });
  }

  closeConfirmationModal() {
    this.setState({ itemToDestroy: null });
  }

  handleDelete(item) {
    return () => {
      this.props.handleDelete(item);
      this.setState({ itemToDestroy: null });
    };
  }

  handleUpdate(item, newValue, newColor) {
    const { temporaryItem } = this.state;
    const { handleUpdate, handleCreate, parentItemIdKey } = this.props;
    if (temporaryItem && temporaryItem._id == item._id) {
      // saving the temporary item, calling create
      handleCreate(newValue, newColor, temporaryItem[parentItemIdKey], item._id);
    } else {
      handleUpdate(item, newValue, newColor);
    }
  }

  changeFocusedItem(item) {
    this.setState({
      focusedItem: item,
      temporaryItem: null
    });
    if (!item) {
      const { handleCancel } = this.props;
      handleCancel && handleCancel();
    }
  }

  setupEmptyChildItemHandler(parentItem) {
    return () => {
      const temporaryItem = {
        _id: EMPTY_CHILD_ITEM_ID,
        name: "",
        [this.props.parentItemIdKey]: parentItem._id
      };

      this.setState({
        temporaryItem,
        focusedItem: temporaryItem
      });
    };
  }

  i18n(key, options = {}) {
    return I18n.t(`react.${this.props.i18nKey}.${key}`, options);
  }

  itemHasChildItems(item) {
    const { items, parentItemIdKey } = this.props;
    return items.find(i => i[parentItemIdKey] === item._id) !== undefined;
  }

  renderAreYouSureModal() {
    const { itemToDestroy } = this.state;
    if (!itemToDestroy) return null;

    const { name, color } = itemToDestroy;

    return <ConfirmationModal
      isVisible={!!itemToDestroy}
      onClose={this.closeConfirmationModal}
      onConfirm={this.handleDelete(itemToDestroy)}
      title={this.i18n("move_to_trash_title")}
      confirmationQuestion={this.i18n("confirm_move_to_trash", { name, color })}
      confirmButtonClasses="btn-danger"
      confirmationWarning={this.itemHasChildItems(itemToDestroy) ? this.i18n("delete_sub_items_warning") : null}
    />;
  }

  tableRow(item, depthLevel = 0, parentItem = null) {
    const { lastUpdatedItemId, errors, event, i18nKey, modelName, selectedPopulation, parentItemIdKey } = this.props;
    const { focusedItem } = this.state;
    const errorMessage = errors && lastUpdatedItemId == item._id && (errors.name || errors[parentItemIdKey] || errors.base);

    return <ColoredObjectRow
      key={item._id}
      item={item}
      handleDelete={this.openConfirmationModal}
      handleUpdate={this.handleUpdate}
      handleFocus={this.changeFocusedItem}
      updating={focusedItem && focusedItem._id == item._id}
      errorMessage={errorMessage}
      i18nKey={i18nKey}
      modelName={modelName}
      selectedPopulation={selectedPopulation}
      event={event}
      allowSubItemCreation={!!parentItemIdKey}
      depthLevel={depthLevel}
      parentItem={parentItem}
      setupEmptyChildItemHandler={this.setupEmptyChildItemHandler}
    />;
  }

  buildItemsTree(rootItem = null, depthLevel = 0) {
    const { items, parentItemIdKey } = this.props;
    const parentKey = rootItem ? rootItem["_id"] : null;

    return [this.state.temporaryItem || [], ...items]
      .filter(item => item[parentItemIdKey] === parentKey)
      .map(item => {
        return {
          item,
          children: this.buildItemsTree(item, depthLevel + 1),
          parentItem: rootItem,
          depthLevel
        };
      });
  }

  tableBody() {
    const { items, parentItemIdKey } = this.props;
    if (parentItemIdKey) {
      // working on data with sub items
      return this.tableRows(this.buildItemsTree());
    }

    // working on flat data
    return items.map(item => this.tableRow(item));
  }

  tableRows(items) {
    if (!items) return [];

    return items.flatMap(({ item, parentItem, depthLevel, children }) => {
      return [this.tableRow(item, depthLevel, parentItem), ...this.tableRows(children)];
    });
  }

  render() {
    return <div className="table-responsive table-container">
      <table className="table table-light table-bordered table-hover">
        <thead>
          <tr><th>{this.i18n("list_count", { count: this.props.items.length })}</th></tr>
        </thead>
        <tbody>
          {this.tableBody()}
        </tbody>
      </table>
      {this.renderAreYouSureModal()}
    </div>;
  }
}

export default ColoredOjectsTable;
