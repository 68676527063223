import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../components/shared/Loader.react";
import $ from "jquery";


const WAIT_TIMES: number[] = [3, 3, 3, 3, 3, 3, 3, 6, 12, 24, 48, 96];
const GENERATION_CURSORS: number[] = [4, 9];

interface ZohoInvoicesLoader {
  waitTimeCursor: number;
  timer: any;
}
interface Props {
  match: any;
  location: any;
}

const ZohoInvoicesLoader: React.FC<Props> = (props) => {
  const [waitTimeCursor, setWaitTimeCursor] = useState(0);
  let timer = null;

  useEffect(() => {
    tick();
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const canRetry = (): boolean => {
    return waitTimeCursor < WAIT_TIMES.length;
  };

  const tick = (): void => {
    if (!canRetry()) {
      return;
    }

    if (GENERATION_CURSORS.includes(waitTimeCursor)) {
      createZohoInvoicePdf();
    }

    timer = setTimeout(() => {
      const { location } = props;
      const req = $.ajax({
        url: `${location.pathname}${location.search}&async=true`,
        type: "GET",
        contentType: "application/json",
        dataType: "json"
      });

      req.done((data: { invoices_url?: string }) => {
        const invoicesUrl = data["invoices_url"];

        if (!invoicesUrl) {
          tick();
          return;
        }

        window.location.reload();
      });

      req.fail(() => {
        tick();
      });
    }, WAIT_TIMES[waitTimeCursor] * 1000);
    setWaitTimeCursor(waitTimeCursor + 1);
  };

  const createZohoInvoicePdf = (): void => {
    const { location } = props;
    $.ajax({
      url: `${location.pathname}${location.search}&async=false`,
      type: "GET",
      dataType: "json"
    });
  };

  const content = (): JSX.Element => {
    return canRetry() ? (
      <span>
        <p> {I18n.t("react.zoho_invoice_loader.wait_message")} </p>
        <div style={{ display: "inline-block" }}>
          <Loader />
        </div>
      </span>
    ) : (
      <p> {I18n.t("react.zoho_invoice_loader.failed_message")} </p>
    );
  };

  return (
    <div style={{ textAlign: "center", marginTop: "100px" }}>
      {content()}
    </div>
  );
};

export default connect()(ZohoInvoicesLoader);
