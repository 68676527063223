import { Component } from "react";
import FormItemOption from "./FormItemOption.react";
import CountrySelect from "../shared/CountrySelect.react";
import Countries from "../../constants/Countries.yml";

class CountryType extends Component {

  constructor(props) {
    super(props);
    [
      "updateDefaultValue",
      "updateFirstCountry"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  updateFirstCountry(e) {
    const { updateFormItem } = this.props;
    updateFormItem({
      options: { first: e.target.value }
    });
  }

  updateDefaultValue(e) {
    const { updateFormItem } = this.props;
    updateFormItem({
      options: { default_value: e.target.value }
    });
  }

  renderCountriesDropdown(value, onChangeFn) {
    const { locale } = this.props;
    return <CountrySelect value={value} locale={locale} onChange={onChangeFn} />;
  }

  countriesDataRead() {
    const { formItem, locale } = this.props;
    const { options } = formItem;
    let countries = [];
    if (options.first) {
      const countryObj = Countries.find(country => country.code === options.first);
      countries.push({ key: countryObj.code, label: countryObj[locale] });
    }
    Countries.forEach((countryObj) => {
      if (countryObj.code === options.first) {
        return;
      }
      countries.push({ key: countryObj.code, label: countryObj[locale] });
    });
    return countries;
  }

  renderEdit() {
    const { formItem } = this.props;
    const { options } = formItem;

    if (options.display_as == "hidden_field") {
      return (
        <div className="mb-3 row">
          <label className="col-sm-3 col-form-label">{I18n.t("react.form_items.form_country.default")}</label>
          <div className="col-sm-9">
            {this.renderCountriesDropdown(options.default_value, this.updateDefaultValue)}
          </div>
        </div>
      );
    }

    return (
      <div className="mb-3 row">
        <label className="col-sm-3 col-form-label">{I18n.t("react.form_items.form_country.first")}</label>
        <div className="col-sm-9">
          {this.renderCountriesDropdown(options.first, this.updateFirstCountry)}
        </div>
      </div>
    );
  }

  renderRead() {
    const { formItem, mode } = this.props;
    const { options } = formItem;

    if (options.display_as == "hidden_field") {
      const defaultValue = options.default_value || "";

      return defaultValue.length ? <p>{ defaultValue }</p> : <p><em>{ I18n.t("react.form_hidden_field.empty_value") }</em></p>;
    }

    const formItemOptions = this.countriesDataRead().map((itemOption, index) => {
      return (
        <FormItemOption formItem={formItem}
          key={index}
          itemOption={itemOption}
          keyInputType="text"
          mode={mode}
          readStyle={{ marginLeft: 30 }}>
          <span>{ index + 1 }.</span>
        </FormItemOption>
      );
    });

    return (
      <div>
        {formItemOptions.slice(0, 5)}
      </div>
    );
  }

  render() {
    const { mode } = this.props;

    if (mode === "read")
      return this.renderRead();

    return this.renderEdit();
  }
}

export default CountryType;
