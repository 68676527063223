import React, { FC } from "react";
import { useSelector } from "react-redux";
import Menu from "./Menu.react";
import Groups from "./Groups.react";
import { pathToCustomIntegrations } from "../../utils/pathUtils.js";
import Loader from "../shared/Loader.react";

const i18n = (key: string): string => {
  return I18n.t(`react.integrations.${key}`);
};

const IntegrationsIndex: FC = () => {
  const event = useSelector((state: any) => state.event);
  if (!event.id) return <Loader size="large" inline={false} containerHeight="100%" /> ;

  return (
    <div className="feature-set row justify-content-center" id="integration-set">
      <div className="col-xl-10 col-lg-11 col-md-12">
        <div className="header-page">
          <div className="header-page-content row">
            <div className="header-page-title col-md-6">
              <h1>{i18n("integrations")}</h1>
            </div>
            <div className="header-page-btn col-md-6">
              <div>
                <a className="btn btn-secondary" href={ pathToCustomIntegrations() }>{i18n("custom_integrations")}</a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <Menu i18n={i18n} />
          </div>
          <div className="col-lg-9 mb-50 d-flex flex-column">
            <Groups i18n={i18n} integrationConfigurations={event.integration_configurations}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationsIndex;
