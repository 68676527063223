import { ChangeEvent, useState } from "react";

/*
  Create a form input to fill a hash. Keys are selected from a select and values are text inputs.
  Sample usage in an ERB form:

  <%
    props = {
      keys: @account.contact_fields.pluck(:key),
      currentMapping: @sso_provider.response_custom_attributes_mapping || {},
      label: SsoProvider.human_attribute_name(:response_custom_attributes_mapping),
      inputName: "sso_provider[response_custom_attributes_mapping]"
    }
  %>
  <div class="react-anchor" data-react-component="KeyValueInputErb" data-react-component-props="<%= props.to_json %>"></div>

  Note that when all entries are removed from the component, the form will submit "" for the input. This should be handled
  in the controller to nullify or empty the hash.
*/

interface Props {
  inputName: string;
  currentMapping: Record<string, string>;
  keys: string[];
  label: string;
}

type Entry = {
  key: string;
  value: string;
}

const KeyValueInputErb: React.FC<Props> = ({
  inputName,
  keys,
  currentMapping,
  label
}) => {

  if (keys.length === 0) {
    return null;
  }

  const hashToEntries = (hash: Record<string, string>): Entry[] => {
    return Object.entries(hash || {}).map(([key, value]) => {
      return { key, value };
    });
  };

  const [entries, setEntries] = useState(hashToEntries(currentMapping));

  const replaceInEntry = (field: "key"|"value", key: string, newValueOrKey: string): void => {
    const newEntries = entries.map(entry => {
      if (entry.key === key) {
        const e = { ...entry };
        e[field] = newValueOrKey;
        return e;
      }
      return entry;
    });

    setEntries(newEntries);
  };

  const destroyEntry = (key: string): void => {
    const newEntries = entries.filter(entry => entry.key !== key);
    setEntries(newEntries);
  };

  const findEntry = (key: string): Entry => {
    return entries.find(entry => entry.key === key);
  };

  const entriesIncludesKey = (key: string): boolean => {
    return findEntry(key) !== undefined;
  };

  const selectOptions = [
    <option value="" key="empty">{I18n.t("please_choose")}</option>,
    ...keys.map(fieldKey => (
      <option key={fieldKey} value={fieldKey} disabled={entriesIncludesKey(fieldKey)}>
        {fieldKey}
      </option>
    ))
  ];

  const renderSelect = (key: string): JSX.Element => {
    return (
      <select className="form-select mr-5" style={{ maxWidth: "250px" }} value={key} key={`select-${key}`} onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
        replaceInEntry("key", key, e.target.value);
      }}>
        {selectOptions}
      </select>
    );
  };

  const renderInput = (key: string): JSX.Element => {
    const value = findEntry(key)?.value || "";

    return (
      <input type="text" className="form-control mr-5" value={value} name={`${inputName}[${key}]`} onChange={(e: ChangeEvent<HTMLInputElement>): void => {
        replaceInEntry("value", key, e.target.value);
      }} />
    );
  };

  const renderDestroyButton = (key: string): JSX.Element => {
    return <button className="btn btn-danger" onClick={(e): void => {
      e.preventDefault();
      destroyEntry(key);
    }}>
      <i className="fa-regular fa-trash-can"></i>
    </button>;
  };

  const renderEntry = ({ key }): JSX.Element => {
    return (
      <div key={`input-${key}`} className="mb-10" style={{ display: "flex" }}>
        {renderSelect(key)}
        {renderInput(key)}
        {renderDestroyButton(key)}
      </div>
    );
  };

  const addNewEntry = (): void => {
    setEntries([...entries, { key: "", value: "" }]);
  };

  const canAddNewEntry = (): boolean => {
    return !entriesIncludesKey("") && entries.length < keys.length;
  };

  const entryEmpty = entries.length === 0;

  return <div className="mb-3">
    <label className="form-label">{label}</label>
    {entries.map(entry => renderEntry(entry))}
    {entryEmpty && (
      // input that resets the input to no values
      <input type="hidden" name={inputName} value="" />
    )}
    {canAddNewEntry() && (
      <>
        {entryEmpty && <br />}
        <button type="button" className="btn btn-secondary" onClick={addNewEntry}>
          <i className="fa-regular fa-plus"></i>
        </button>
      </>
    )}
  </div>;
};

export default KeyValueInputErb;
