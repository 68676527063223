export function normalizeId(obj) {
  const prevId = obj._id;
  if (prevId) {
    obj.id = prevId;
  }
  return Object.assign({}, obj);
}

export function normalizeIdInArray(list) {
  return list.map((item) => {
    return normalizeId(item);
  });
}

export function objectsFromIds (ids) {
  return ids.map((id) => {
    return {
      id: id,
      name: I18n.t(`react.${id}`)
    };
  });
}

export function ShowedUpStatusesFromIds (ids) {
  return ids.map((id) => {
    return {
      id: id.value,
      name: I18n.t(`react.${id.name}`)
    };
  });
}

export function generateRandomIdentifier() {
  return Math.floor((Math.random() * 1000000));
}
