import { ActionTypes } from "../constants/Constants";

const defaultState = {
  items: null,
  errors: {},
  saveQueryStatus: null
};

function websiteMenuItems(state = defaultState, action) {
  switch (action.type) {
  case ActionTypes.RECEIVE_MENU_SUCCESS: {
    return Object.assign({}, state, { items: action.data.menu_items || [], errors: {} });
  }
  case ActionTypes.UPDATE_MENU_ITEMS_OFFLINE: {
    return Object.assign({}, state, { items: action.data.slice() || [] });
  }
  case ActionTypes.CREATE_MENU_SUCCESS:
  case ActionTypes.UPDATE_MENU_SUCCESS: {
    return Object.assign({}, state, { items: action.data.menu_items, saveQueryStatus: true, errors: {} });
  }
  case ActionTypes.CREATE_MENU_FAILURE:
  case ActionTypes.UPDATE_MENU_FAILURE: {
    return Object.assign({}, state, { items: action.data.menu_items, saveQueryStatus: false, errors: action.data.errors.menu_items || {} });
  }
  case ActionTypes.CLEAR_SAVE_QUERY_STATUS: {
    return Object.assign({}, state, { saveQueryStatus: null });
  }
  case ActionTypes.CLEAR_MENU_STORE:
  case ActionTypes.DELETE_MENU_SUCCESS: {
    return defaultState;
  }
  default:
    return state;
  }
}

export default websiteMenuItems;
