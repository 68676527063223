import { useState } from "react";
import Modal from "react-modal";
import ExportSortOptions from "../../components/ExportSortOptions.react";
import Notice from "../../components/features/Notice.react";

import { defaultModalStyles } from "../../constants/Style";

interface Props {
  eventId: string;
  buttonLabel: string;
  buttonClassName?: string;
}

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`react.visit_routes.stand_qr_codes_export_button.${key}`, options);
};

const StandQrCodesExportButton: React.FC<Props> = ({ eventId, buttonClassName, buttonLabel }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [errors, setErrors] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [noticeType, setNoticeType] = useState("success");

  const toggleModal = (e?: any): void => {
    if (e) e.preventDefault();

    setModalOpen(!isModalOpen);
  };

  const submitButtonHandler = (fileNaming): void => {
    window.$.post(`/events/${eventId}/exhibitors/download_exhibitors_visit_route_qr_code.json`, { file_naming: fileNaming })
      .success((): void => {
        setNoticeType("success");
        setSuccessMessage(i18n("success"));
      }).fail((): void => {
        setNoticeType("alert");
        setErrors(i18n("an_error_occurred"));
      });
  };

  const renderModal = (): JSX.Element => {
    return <Modal className="modal-content"
      isOpen={isModalOpen}
      onRequestClose={toggleModal}
      style={defaultModalStyles}
      contentLabel="Modal"
    >
      <div className="modal-header">
        <h4 className="modal-title">{i18n("modal_title")}</h4>
        <button onClick={toggleModal} type="button" className="btn btn-close"></button>
      </div>
      <div className="modal-body">
        <Notice errors={errors} notice={successMessage} noticeType={noticeType} className="mt-10" />
        <ExportSortOptions
          sheet={"stand_qr_codes"}
          defaultValue="company_name"
          handlerSubmitButton={submitButtonHandler}
        />
      </div>
    </Modal>;
  };

  return <div>
    { renderModal() }
    <a href="#" className={ buttonClassName || "btn btn-secondary mt-10" } onClick={toggleModal}>
      <span><i className="fa-regular fa-qrcode fa-fw"></i> { buttonLabel }</span>
    </a>
  </div>;
};

export default StandQrCodesExportButton;
