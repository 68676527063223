import { Component } from "react";
import { connect } from "react-redux";
import AccessPrivilegeRow from "../components/AccessPrivilegeRow.react";
import AccessPrivilegeDropdown from "../components/AccessPrivilegeDropdown.react";
import Paginate from "../components/Paginate.react";
import ErrorMessage from "../components/shared/ErrorMessage.react";
import HelpSection from "../components/shared/HelpSection.react";

import { fetchAccesspoints } from "../actions/AccesspointActionCreators";
import { fetchGuestCategory } from "../actions/GuestCategoryActionCreators";
import { fetchGuest, updateAccessPrivileges } from "../actions/GuestListActionCreators";
import requiresProps from "../utils/requiresProps";
import { accesspointFromBundles } from "../utils/bundleUtils";
import { urlEventId } from "../utils/pathUtils";
import { extractPaginationPageNumbers } from "../utils/QueryStringUtils";

const accesspointsSearchFilters = (guest) => {
  return {
    searchFilters: {
      guest_id: guest._id,
      exclude_exit_accesspoint: true,
      exclude_accesspoint_accredited_by_guest_category_id: guest.guest_category_id
    }
  };
};

class GuestAccessPrivileges extends Component {
  constructor(props) {
    super(props);

    [
      "deleteAccessPrivilege",
      "handleSelectAllPaginatedAccesspoints",
      "handleSelectOneAccesspoint",
      "massDeleteAccessPrivileges",
      "massUpdateOrCreateAccessPrivilege",
      "updateOrCreateAccessPrivilege",
    ].forEach(method => this[method] = this[method].bind(this));

    this.state = {
      allPaginatedAccesspointsSelected: false,
      selectedAccesspointIds: [],
      paginatedAccesspoints: [],
      previousPageNumber: null,
      nextPageNumber: null,
      bundlesReceived: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { guestPending, accesspointsFetched, guest } = this.props;
    if (!prevProps.guestPending && guestPending) {
      this.refreshAfterBatchAction();
    }

    const { bundlesReceived } = this.state;
    if (!bundlesReceived && !prevProps.accesspointsFetched && accesspointsFetched) {
      this.setState({ bundlesReceived: true });
      const { fetchAccesspoints } = this.props;
      fetchAccesspoints(urlEventId(), "", 1, accesspointsSearchFilters(guest));
      return;
    }

    if (!prevProps.accesspointsFetched && accesspointsFetched) {
      this.populatePaginatedAccesspoints();
    }
  }

  refreshAfterBatchAction() {
    this.setState({
      allPaginatedAccesspointsSelected: false,
      selectedAccesspointIds: [],
    });
  }

  i18n(key, options = {}) {
    return I18n.t(`guests.extra_privileges_only_when_edit.${key}`, options);
  }

  renderCheckbox(name, checked, onChangeHandler) {
    return <input type="checkbox" className="form-check-input" checked={checked} name={name} onChange={onChangeHandler} />;
  }

  goToPage(pageNumber) {
    return (e) => {
      e.preventDefault();
      if (!pageNumber) return;

      const { fetchAccesspoints, guest } = this.props;
      fetchAccesspoints(urlEventId(), "", pageNumber, accesspointsSearchFilters(guest));
    };
  }

  updateAccessPrivilegesWithParams(accessPrivileges, params, accesspointId) {
    const { guest } = this.props;
    const existedAccessPrivilege = guest.access_privileges.find(priv => { return priv.accesspoint_id == accesspointId;});
    if (existedAccessPrivilege) {
      const { max_allowed_accesses, too_many_accesses_message } = params;
      existedAccessPrivilege.accesspoint_id = accesspointId;
      if (max_allowed_accesses) existedAccessPrivilege.max_allowed_accesses = parseInt(max_allowed_accesses);
      if (too_many_accesses_message) existedAccessPrivilege.too_many_accesses_message = too_many_accesses_message;
    } else {
      accessPrivileges.push(Object.assign({}, params, { accesspoint_id: accesspointId }));
    }
    return accessPrivileges;
  }

  massUpdateOrCreateAccessPrivilege(params) {
    const { selectedAccesspointIds } = this.state;
    const { guest, updateAccessPrivileges, match } = this.props;
    const { guest_id } = match.params;
    let accessPrivileges = guest.access_privileges.slice();

    selectedAccesspointIds.forEach(accesspointId => {
      accessPrivileges = this.updateAccessPrivilegesWithParams(accessPrivileges, params, accesspointId);
    });
    updateAccessPrivileges(urlEventId(), guest_id, accessPrivileges);
  }

  updateOrCreateAccessPrivilege(accesspointId) {
    return (params) => {
      const { guest, updateAccessPrivileges, match } = this.props;
      const { guest_id } = match.params;
      let accessPrivileges = guest.access_privileges.slice();

      accessPrivileges = this.updateAccessPrivilegesWithParams(accessPrivileges, params, accesspointId);
      updateAccessPrivileges(urlEventId(), guest_id, accessPrivileges);
    };
  }

  removeAccesspointIdInAccessPrivileges(accessPrivileges, accesspointId) {
    return accessPrivileges.map(item => {
      if (item.accesspoint_id == accesspointId) {
        return Object.assign({}, item, { active: false });
      } else {
        return item;
      }
    });
  }

  massDeleteAccessPrivileges() {
    const { updateAccessPrivileges, match, guest } = this.props;
    const { guest_id } = match.params;
    const { selectedAccesspointIds } = this.state;
    let accessPrivileges = guest.access_privileges.slice();

    selectedAccesspointIds.forEach(accesspointId => {
      accessPrivileges = this.removeAccesspointIdInAccessPrivileges(accessPrivileges, accesspointId);
    });
    updateAccessPrivileges(urlEventId(), guest_id, accessPrivileges);
  }

  deleteAccessPrivilege(accesspointId) {
    return () => {
      const { updateAccessPrivileges, guest, match } = this.props;
      const { guest_id } = match.params;

      updateAccessPrivileges(urlEventId(), guest_id, this.removeAccesspointIdInAccessPrivileges(guest.access_privileges, accesspointId));
    };
  }

  handleSelectAllPaginatedAccesspoints({ target }) {
    const { checked } = target;
    const { paginatedAccesspoints } = this.state;
    this.setState({
      selectedAccesspointIds: checked ? paginatedAccesspoints.map(accesspoint => accesspoint.id) : [],
      allPaginatedAccesspointsSelected: checked,
    });
  }

  handleSelectOneAccesspoint(accesspointId) {
    return ({ target }) => {
      const { checked } = target;
      const { selectedAccesspointIds, paginatedAccesspoints } = this.state;
      let newSelectedAccesspointIds = selectedAccesspointIds;
      if (checked && !selectedAccesspointIds.includes(accesspointId)) {
        newSelectedAccesspointIds = [...selectedAccesspointIds, accesspointId];
      } else if (selectedAccesspointIds.includes(accesspointId)) {
        newSelectedAccesspointIds = [...selectedAccesspointIds.filter(id => id !== accesspointId)];
      }
      this.setState({
        selectedAccesspointIds: newSelectedAccesspointIds,
        allPaginatedAccesspointsSelected: newSelectedAccesspointIds.length === paginatedAccesspoints.length,
      });
    };
  }

  renderAccesspointLineCheckbox(accesspoint) {
    const { selectedAccesspointIds } = this.state;
    return this.renderCheckbox("selectedResourceIds", selectedAccesspointIds.includes(accesspoint.id), this.handleSelectOneAccesspoint(accesspoint.id));
  }

  renderMassAccessPrivilegeDropdown() {
    const { selectedAccesspointIds } = this.state;
    if (!selectedAccesspointIds.length) return null;

    return <AccessPrivilegeDropdown
      resource={null}
      forceNoAccessEnabled={true}
      handleRemove={this.massDeleteAccessPrivileges}
      handleValue={this.massUpdateOrCreateAccessPrivilege}
    />;
  }

  renderAccesspointLine(privilege, accesspoint, index) {
    const { selectedAccesspointIds } = this.state;
    const { guest, bundles } = this.props;

    const itemFromBundles = accesspointFromBundles(bundles || [], accesspoint);
    const bundlesPrivileges = itemFromBundles.filter(bundle => {
      return guest.access_privileges.find(priv => priv.accesspoint_id == bundle.id);
    });

    return <tr key={index}>
      <td className="bulk-cell">
        {this.renderAccesspointLineCheckbox(accesspoint)}
      </td>
      <td>
        <AccessPrivilegeRow
          resource={privilege}
          displayName={accesspoint.name}
          capacity={accesspoint.capacity}
          nbReserved={accesspoint.nb_reserved}
          showUrl="#"
          handleValue={this.updateOrCreateAccessPrivilege(accesspoint.id)}
          handleRemove={this.deleteAccessPrivilege(accesspoint.id)}
          dropdownDisplayed = {!selectedAccesspointIds.includes(accesspoint.id)}
          bundles={itemFromBundles}
          bundlesPrivileges={bundlesPrivileges}
        />
      </td>
    </tr>;
  }

  populatePaginatedAccesspoints() {
    const { accesspoints, previousURL, nextURL } = this.props;
    const [ previousPageNumber, nextPageNumber ] = extractPaginationPageNumbers(previousURL, nextURL);

    this.setState({
      paginatedAccesspoints: accesspoints,
      allPaginatedAccesspointsSelected: false,
      selectedAccesspointIds: [],
      previousPageNumber,
      nextPageNumber,
    });
  }

  renderAccesspointsList() {
    const { guest } = this.props;
    const { paginatedAccesspoints } = this.state;
    let privilege = null;
    return paginatedAccesspoints.map((accesspoint, index) => {
      privilege = (guest.access_privileges || []).find(priv => {
        return priv.accesspoint_id == accesspoint.id;
      });
      return this.renderAccesspointLine(privilege, accesspoint, index);
    });
  }

  renderSelectedAccesspointsCountLabel() {
    const { selectedAccesspointIds } = this.state;
    if (!selectedAccesspointIds.length) return null;

    return <strong>{this.i18n("selected_accesspoints", { count: selectedAccesspointIds.length })}</strong>;
  }

  renderPagination() {
    const { previousPageNumber, nextPageNumber } = this.state;
    if (!previousPageNumber && !nextPageNumber) {
      return <div style={{ paddingTop: "20px" }}></div>;
    }
    return <Paginate
      handlePrevious={this.goToPage(previousPageNumber)}
      handleNext={this.goToPage(nextPageNumber)}
      previousEnabled={previousPageNumber}
      nextEnabled={nextPageNumber}
      pull="end"
    />;
  }

  renderErrorMessage() {
    const { errors } = this.props;
    return <ErrorMessage errors={errors} model={"guest"}/>;
  }

  render() {
    const { guest, guestCategory } = this.props;
    if (!guest || !guestCategory || !guestCategory.guest_category_accesspoints) {
      return null;
    }

    const { allPaginatedAccesspointsSelected } = this.state;
    const points = this.renderAccesspointsList();
    return (
      <div>
        {this.renderErrorMessage()}
        <fieldset>
          <HelpSection help={this.i18n("message_extra_privileges_help")} badgeText={I18n.t("react.guest_category_accesspoints.help")} />

          {points.length > 0 ? (
            <>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {this.renderSelectedAccesspointsCountLabel()}
                <div className="collection-pagination" style={{ flexGrow: "1" }}>
                  {this.renderPagination()}
                </div>
              </div>
              <div className="table-container">
                <table className="table table-light table-bordered table-hover">
                  <thead>
                    <tr>
                      <th className="bulk-cell">
                        {this.renderCheckbox("allPaginatedAccesspointsSelected", allPaginatedAccesspointsSelected, this.handleSelectAllPaginatedAccesspoints)}
                      </th>
                      <th className="text-end">{this.renderMassAccessPrivilegeDropdown()}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {points}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <em>{this.i18n("no_available_accreditations")}</em>)
          }
        </fieldset>
      </div>
    );
  }
}

const mapDispatchToProps = {
  fetchGuest,
  updateAccessPrivileges,
  fetchGuestCategory,
  fetchAccesspoints,
};

function mapStateToProps(state, ownProps) {
  return {
    accesspoints: state.accesspoints.data,
    guest: state.guests.guests.find(g => ownProps.match.params.guest_id === g._id),
    guestPending: state.guests.isPending,
    errors: state.guests.errors,
    accesspointsFetched: state.accesspoints.fetched,
    previousURL: state.accesspoints.previousURL,
    nextURL: state.accesspoints.nextURL,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  requiresProps(GuestAccessPrivileges, {

    requirements: {
      guest: {
        fn: ({ match, fetchGuest }) => {
          fetchGuest(urlEventId(), match.params.guest_id);
        },
        reducer: (state, current, ownProps) => {
          current.guest = state.guests.guests.find(g => ownProps.match.params.guest_id === g._id);
          return current;
        }
      },
      guestCategory: {
        waitFor: ["guest"],
        fn: ({ guest, fetchGuestCategory }) => {
          fetchGuestCategory(urlEventId(), guest.guest_category_id, { guest_category_accesspoints: true });
        },
        statePath: "guestCategory.data"
      },
      bundles: {
        waitFor: ["guestCategory"],
        fn: ({ fetchAccesspoints }) => {
          fetchAccesspoints(urlEventId(), "", 0, { include: ["accesspoint_bundle"], uniqueKeyInSiloedData: "bundles", searchFilters: { type: ["bundle"] } });
        },
        statePath: "accesspoints.siloedData.bundles"
      },
    }
  })
);
