import _ from "lodash";
import { WorkflowStep, Condition, Context } from "../types/Workflow";

const PATH_REGEXP = /{{([\w.]+)}}/;

const checkPathMissing = (value: string, context: Context): boolean => {
  const path = extractPath(value);
  return path && !checkPathExists(path, context);
};

export const extractPath = (value: string): string => {
  const match = value.match(PATH_REGEXP);

  if (!match) return null;

  return match[1];
};

export const checkPathExists = (path: string, context: Context): boolean => {
  return path.split(".").reduce((object, key) => {
    return object && key in object ? object[key] : undefined;
  }, context) !== undefined;
};

export const settingsHaveMissingPath = (settings: any, context: Context): boolean => {
  return Object.values(settings).some((settingValue: string | any) => {
    if (typeof(settingValue) === "string" && checkPathMissing(settingValue, context)) {
      return true;
    }
    return false;
  });
};

export const conditionsHaveMissingPath = (conditions: Condition[][], context: Context): boolean => {
  return _.flatten(conditions).some((condition: Condition) => {
    if (condition.field && checkPathMissing(condition.field, context)) {
      return true;
    }
    if (condition.value && checkPathMissing(condition.value, context)) {
      return true;
    }
    return false;
  });
};

export const workflowStepHasMissingPath = (workflowStep: WorkflowStep, context: Context): boolean => {
  if (workflowStep.type === "action") {
    return settingsHaveMissingPath(workflowStep.settings, context);
  } else if (workflowStep.type === "filter") {
    return conditionsHaveMissingPath(workflowStep.conditions, context);
  }

  return false;
};
