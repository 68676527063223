import { Component } from "react";
import { pathToTranslation } from "../../utils/pathUtils";
import Loader from "../shared/Loader.react";
import { Link } from "react-router-dom";
import { Website } from "../../types/Website";

interface Props {
  website: Website;
  type: string;

  fetchWebsite(): void;
}

class WebsiteTranslationsIndex extends Component<Props> {
  componentDidMount(): void {
    this.props.fetchWebsite();
  }

  componentDidUpdate(prevProps: Props): void {
    const { type, fetchWebsite } = this.props;
    if (type !== prevProps.type) fetchWebsite();
  }

  renderTable(): JSX.Element {
    const { website } = this.props;
    return <div className="table-responsive table-container mb-10" style={{ marginTop: "0px" }}>
      <table className="table table-light table-bordered table-hover">
        <thead>
          <tr key="O"><th className={"col-md-12"}>{I18n.t("react.translations.index.name")}</th></tr>
        </thead>
        <tbody>
          <tr><td><Link to={pathToTranslation("website", website)} className="btn btn-link"><i className="badge rounded-pill bg-secondary"></i>{I18n.t("react.translations.index.main_settings")}</Link></td></tr>
          {website.app_home_screen_config && <tr><td><Link to={pathToTranslation("app_home_screen_config", website.app_home_screen_config)} className="btn btn-link"><i className="badge rounded-pill bg-secondary"></i>{I18n.t("react.translations.index.app_home_screen_config")}</Link></td></tr>}
        </tbody>
      </table>
    </div>;
  }

  render(): JSX.Element {
    const { website } = this.props;

    if (!website) return <Loader />;

    return this.renderTable();
  }
}

export default WebsiteTranslationsIndex;
