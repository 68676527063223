import { useEffect } from "react";
import { connect } from "react-redux";
import SlidingPane from "react-sliding-pane";
import Icons from "../../constants/Icons";
import NoticeBlock from "../../components/templates_builder/NoticeBlock.react";
import TranslationTable from "../translations/TranslationTable.react";
import { urlEventId } from "../../utils/pathUtils";

interface Props {
  match: any;
  location: any;
  history: any;
  collectionId: string;
  isPaneOpen: boolean;
  notice?: string;
  noticeType?: string;

  toggleSlidingPane(): void;
}

const GuestProductCollectionTranslations: React.FC<Props> = ({
  match,
  location,
  history,
  collectionId,
  isPaneOpen,
  notice,
  noticeType,
  toggleSlidingPane
}) => {

  useEffect(() => {
    if (notice) setTimeout(() => { toggleSlidingPane(); }, 800);
  }, [notice]);

  return <SlidingPane
    isOpen={isPaneOpen}
    title={I18n.t("react.registration_form.translations_title")}
    from="right"
    width='90%'
    onRequestClose={toggleSlidingPane}
    className="width-100-xs width-90-sm"
    closeIcon={Icons.close()}
  >
    <div style={{ padding: "0 20px" }}>
      <NoticeBlock notice={notice} noticeType={noticeType} />
      <TranslationTable
        match={match}
        location={location}
        history={history}
        type="guest_product_collections"
        eventId={urlEventId()}
        id={collectionId}
        inSlidingPane={true}
        onlySimpleValidateButton={true}
      />
    </div>
  </SlidingPane>;
};

function mapStateToProps(state: any): any {
  return {
    notice: state.notifications.currentNotice,
    noticeType: state.notifications.noticeType
  };
}

export default connect(mapStateToProps)(GuestProductCollectionTranslations);
