import { useState, MouseEvent, ChangeEvent, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ErrorMessage from "../shared/ErrorMessage.react";
import { generatePathSlug } from "../../utils/StringUtils";

interface Props {
  errors: any;
  isVisible: boolean;
  initialTitle: string;
  initialWebsitePathSlug: string;

  onClose: () => void;
  onSubmit: (title: string, websitePathSlug: string) => void;
}

const i18n = (key: string): string => {
  return I18n.t(`react.blog_articles.blog_article_duplication_input_modal.${key}`);
};

const BlogArticleDuplicationInputModal: React.FC<Props> = ({
  errors,
  isVisible,
  initialTitle,
  initialWebsitePathSlug,
  onSubmit,
  onClose,
}) => {
  const [title, setTitle] = useState<string>("");
  const [websitePathSlug, setwebsitePathSlug] = useState<string>("");

  useEffect(() => {
    if (!isVisible) return;

    setTitle(initialTitle);
    setwebsitePathSlug(initialWebsitePathSlug);
  }, [isVisible]);

  const submit = (e: MouseEvent): void => {
    e.preventDefault();
    onSubmit(title, websitePathSlug);
  };

  const renderHeader = () : JSX.Element => {
    return <Modal.Header>
      <Modal.Title>{i18n("modal_title")}</Modal.Title>
      <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
    </Modal.Header>;
  };

  const prefillPathSlug = ({ target }): void => {
    if (websitePathSlug === "") {
      setwebsitePathSlug(generatePathSlug(target.value));
    }
  };

  const renderBody = () : JSX.Element => {
    return <Modal.Body>
      <ErrorMessage errors={errors}/>
      <div className="mb-3">
        <label className="form-label">{i18n("title")}</label>
        <input
          type="text"
          value={title}
          className="form-control"
          onChange={(e: ChangeEvent<HTMLInputElement>): void => setTitle(e.target.value) }
          onBlur={prefillPathSlug}
        />
        <div className="form-text">
          <i className="fa-regular fa-info-circle"></i> {i18n("hint")}
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">{i18n("website_path_slug")}</label>
        <input
          type="text"
          value={websitePathSlug || ""}
          className="form-control"
          onChange={(e: ChangeEvent<HTMLInputElement>): void => setwebsitePathSlug(e.target.value)}
        />
      </div>
    </Modal.Body>;
  };

  const renderFooter = (): JSX.Element => {
    return <Modal.Footer>
      <button type="submit" className="btn btn-secondary" onClick={onClose}>
        {i18n("cancel")}
      </button>
      <button type="submit" className="btn btn-primary" onClick={submit}>
        {i18n("submit")}
      </button>
    </Modal.Footer>;
  };

  return <Modal show={isVisible} onHide={onClose} size="lg">
    {renderHeader()}
    <form>
      {renderBody()}
      {renderFooter()}
    </form>
  </Modal>;
};

BlogArticleDuplicationInputModal.defaultProps = {
  isVisible: false,
};

export default BlogArticleDuplicationInputModal;
