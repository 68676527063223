import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import querystring from "querystring";
import withModal from "./withModal.react";
import { updateUI } from "../actions/UIAppearanceActionCreators";
import { urlWithQuery } from "../utils/pathUtils";
import { isEnabled } from "../utils/featureSetUtils";
import { ADVANCED_GUEST_FIELDS, CHECKIN, CONFIRMATION_EMAIL } from "../constants/FeaturesSet";
class TriggerAction extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const updates = {
      "modalTitle": I18n.t("react.reports.trigger_actions")
    };
    dispatch(updateUI(updates));
  }

  redirect(route) {
    const { q } = querystring.parse(this.props.location.search.substring(1));
    return urlWithQuery(q, route);
  }

  render() {
    const { event } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <h4 className="trigger-action-title">{I18n.t("react.reports.fields")}</h4>
            <p>
              <Link to={this.redirect("guests/modal/guest_fields")}>
                <i className="fa-regular fa-plus-square"></i>
                <span className="ml-5">{I18n.t("react.reports.guest_field_batch_update")}</span>
              </Link>
            </p>
            <p>
              <Link to={this.redirect("guests/modal/change_status")}>
                <i className="fa-regular fa-plus-square"></i>
                <span className="ml-5">{I18n.t("react.reports.guest_field_batch_update_status")}</span>
              </Link>
            </p>
            <p>
              <Link to={this.redirect("guests/modal/change_guest_category")}>
                <i className="fa-regular fa-plus-square"></i>
                <span className="ml-5">{I18n.t("react.reports.change_guest_category")}</span>
              </Link>
            </p>
            { isEnabled(ADVANCED_GUEST_FIELDS) &&
              <p>
                <Link to={this.redirect("guests/modal/calculate_guest_field")}>
                  <i className="fa-regular fa-plus-square"></i>
                  <span className="ml-5">{I18n.t("react.reports.calculate_guest_field")}</span>
                </Link>
              </p>
            }
            <p>
              <Link to={this.redirect("guests/modal/change_label")}>
                <i className="fa-regular fa-plus-square"></i>
                <i className="fa-regular fa-minus-square" style={{ marginLeft: "2px" }}></i>
                <span className="ml-5">{I18n.t("react.reports.change_label")}</span>
              </Link>
            </p>
          </div>
          <div className="col-md-4">
            <h4 className="trigger-action-title">{I18n.t("react.reports.messages")}</h4>
            { event.has_confirmation_email && isEnabled(CONFIRMATION_EMAIL) ? (
              <p>
                <Link to={this.redirect("guests/modal/send_confirmation_email")}>
                  <i className="fa-regular fa-envelope"></i>
                  <span className="ml-5">{I18n.t("react.trigger_actions.resend_confirmation_email")}</span>
                </Link>
              </p>
            ) : null }
            <p>
              <Link to={this.redirect("guests/modal/send_direct_email")}>
                <i className="fa-regular fa-paper-plane"></i>
                <span className="ml-5">{I18n.t("react.trigger_actions.send_direct_email")}</span>
              </Link>
            </p>
          </div>
          <div className="col-md-4">
            <h4 className="trigger-action-title">{I18n.t("react.reports.others")}</h4>
            { event.any_webhook ? (
              <p>
                <Link to={this.redirect("guests/modal/resend_webhooks")}>
                  <i className="fa-regular fa-link"></i>
                  <span className="ml-5">{I18n.t("react.trigger_actions.resend_webhooks")}</span>
                </Link>
              </p>
            ) : null }
            { isEnabled(CHECKIN) &&
              <p>
                <Link to={this.redirect("guests/modal/change_access_controls")}>
                  <i className="fa-regular fa-plus-square"></i>
                  <i className="fa-regular fa-minus-square" style={{ marginLeft: "2px" }}></i>
                  <span className="ml-5">{`${I18n.t("react.reports.checkin")}/${I18n.t("react.reports.uncheckin")}`}</span>
                </Link>
              </p>
            }
            { event.account_contacts_enabled && window.ReactGlobalProps.super_admin ? (
              <Link to={this.redirect("guests/modal/synchronize_guests_into_account")}>
                <i className="fa-regular fa-users"></i>
                <span className="ml-5">{I18n.t("react.trigger_actions.synchronize_guests_into_account")}</span>
              </Link>
            ) : null }
          </div>
        </div>
        <div className="row mt-20">
          <div className="col-md-4">
            <h4 className="trigger-action-title">{I18n.t("react.reports.danger")}</h4>
            <p>
              <Link to={this.redirect("guests/modal/delete_guests")} className="link-danger">
                <i className="fa-regular fa-trash-can"></i>
                <span className="ml-5">{I18n.t("react.reports.delete_guests.link")}</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    event: state.event
  };
}

export default connect(mapStateToProps)(withModal(TriggerAction));
export { TriggerAction };
