import { Component } from "react";

class FileType extends Component {

  render() {
    const { mode } = this.props;
    if (mode == "edit") {
      return (<div></div>);
    } else if (mode == "read") {
      return (
        <div className="mb-3 row">
          <div className="col-sm-7">
            <input type="text" className="form-control form-control-sm" placeholder={ I18n.t("react.form_file.answer") } readOnly/>
          </div>
        </div>
      );
    }
  }
}

export default FileType;
