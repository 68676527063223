import { Component } from "react";
import { FormItemTypesInQuestionFormItem, FormItemsWithDefaultValueOption, FormItemGrouped, FormItemWithoutGuestField,
  FormItemsWithFile, FormItemTypeHiddenField, ExpectedGuestFieldsByFormItem, ValueList, CountryGuestField,
  FormItemsWithDataValidation, SuggestedGuestFieldsRegardlessType, StaticGuestFieldsWithFile, GuestFieldTypesWithFile,
  DefaultUneditableFields, CheckboxGuestFields, CountryTypeDisplayAsOptions } from "../../constants/Constants";
import { Dropdown } from "react-bootstrap";
import Icons from "../../constants/Icons";
import { isEnabled } from "../../utils/featureSetUtils";
import { PAYMENT, PRODUCTS, PROMO_CODES, ROOMING } from "../../constants/FeaturesSet";

import { sortAccesspoints } from "../../utils/CheckinpointUtils.js";
import { nbGuestFieldByType, injectDefaultsGuestFields, isAddGuestFieldKey } from "../../utils/guestFieldsUtils.js";
import conditionalDisplay from "../../utils/conditionalDisplayUtils.js";

import GuestFieldsModal from "../guest_field/GuestFieldsModal.react";
import TextFieldType from "./TextFieldType.react";
import PromoCodeType from "./PromoCodeType.react";
import TextFieldValidation from "./TextFieldValidation.react";
import TextFieldCompletion from "./TextFieldCompletion.react";
import TextAreaType from "./TextAreaType.react";
import DatePickerComponent from "./DatePickerComponent.react";
import DropdownType from "./DropdownType.react";
import CheckboxGroupType from "./CheckboxGroupType.react";
import AccesspointGroupTypeCommon from "./AccesspointGroupTypeCommon.react";
import FileType from "./FileType.react";
import HiddenFieldType from "./HiddenFieldType.react";
import MerchantType from "./MerchantType.react";
import CountryType from "./CountryType.react";
import ThematicsType from "./ThematicsType.react";
import BottomOptions from "./BottomOptions.react";

const optionsFilter = {
  "default_value": "",
  "validations": [],
  "normalizations": [],
  "override_label": {},
  "override_quantity": {},
  "display_illustration": false,
  "search_enabled": false,
  "display_option_description": true,
  "display_text_completion": false,
  "order": {},
  "filter": null
};

const FormItemsWithValueList = ["radio_group", "dropdown"];

class QuestionFormItem extends Component {

  constructor(props) {
    super(props);

    [
      "_handleDefaultValueChanges",
      "_handleDefaultValueOnBlur",
      "_handleGuestField",
      "_handleHelpChanges",
      "_handleHelpOnBlur",
      "_handleLabelChanges",
      "_handleLabelOnBlur",
      "_handleCountryDisplayAsChange",
      "_hasNormalizations",
      "_hasOverrideLabel",
      "_hasValidations",
      "_hasPrefillCompanyFields",
      "_hasConditionalDisplay",
      "closeCreateGuestFieldModal",
      "getNewGuestField",
      "handleOptionsOrderChange",
      "openCreateGuestFieldModal",
      "updateDisplay",
      "handleMultipleOptionChange",
      "fetchThematics"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    const { formItem } = props;
    this.state = {
      showCreateGuestFieldModal: false,
      expectedGuestFieldType: null,
      currentLabel: formItem.label,
      currentHelp: formItem.options.help,
      currentDefaultValue: formItem.options.default_value || "",
      labelChanged: false,
      display: {
        conditional_display: this._hasConditionalDisplay(),
        default_value: formItem.options.default_value && formItem.options.default_value != "",
        description: formItem.options.help != "",
        filter: this._hasFilterByFilter(),
        order: this._hasOrderFilter(),
        override_label: this._hasOverrideLabel(),
        override_quantity: this._hasOverrideQuantity(),
        display_illustration: formItem.options.display_illustration,
        search_enabled: formItem.options.search_enabled,
        display_option_description: formItem.options.display_option_description,
        display_text_completion: formItem.options.display_text_completion,
        quota: formItem.options.display_quota,
        display_label: formItem.options.display_label,
        display_remaining_capacity: formItem.options.display_remaining_capacity,
        quantity_range: this._hasQuantityRangeFilter(),
        validations: this._hasValidations() || this._hasNormalizations() || this._hasPrefillCompanyFields() || this._hasPrefillAddressFields()
      }
    };
  }

  _optionIsPresent(option) {
    const { options } = this.props.formItem;
    if (!options[option]) return false;
    return options[option] === true || options[option] && options[option].length > 0;
  }

  _hasPrefillCompanyFields() {
    const { formItem } = this.props;
    return formItem.key == "company_name" && this._optionIsPresent("prefill_company_fields");
  }

  _hasPrefillAddressFields() {
    const { formItem } = this.props;
    return formItem.key == "address" && this._optionIsPresent("prefill_address_fields");
  }

  _hasValidations() {
    return this._optionIsPresent("validations");
  }

  _hasNormalizations() {
    return this._optionIsPresent("normalizations");
  }

  _hasOverrideLabel() {
    if (!this.props.formItem.options.override_label) {
      return false;
    }
    return Object.keys(this.props.formItem.options.override_label).length > 0;
  }

  _hasOverrideQuantity() {
    const { override_quantity } = this.props.formItem.options;
    if (!override_quantity) {
      return false;
    }
    return Object.keys(override_quantity).length > 0;
  }

  _hasFilterByFilter() {
    if (!this.props.formItem.options.filter) {
      return false;
    }
    return Object.keys(this.props.formItem.options.filter).length > 0;
  }

  _hasOrderFilter() {
    if (!this.props.formItem.options.order) {
      return false;
    }
    return Object.keys(this.props.formItem.options.order).length > 0;
  }

  _hasQuantityRangeFilter() {
    const quantityRange = this.props.formItem.options.quantity_range;
    if (!quantityRange) return false;

    return quantityRange.min !== "-1" || quantityRange.max !== "-1";
  }

  _hasConditionalDisplay() {
    return this.props.formItem.display_conditions.length > 0;
  }

  _handleInputType(type) {
    return () => {
      const { changeTypeHandler, formItem } = this.props;
      if (formItem.type !== type) {
        changeTypeHandler(type);
      }
    };
  }

  _handleLabelOnBlur(e) {
    const newLabel = e.target.value;
    this.setState({ labelChanged: true });
    const { updateHandler } = this.props;
    updateHandler({
      label: newLabel
    });
  }

  _handleHelpOnBlur(e) {
    const newHelp = e.target.value;
    const { formItem, updateHandler } = this.props;
    const options = Object.assign({}, formItem.options, { help: newHelp });
    updateHandler({
      options
    });
  }

  _handleDefaultValueOnBlur(e) {
    const newDefaultValue = e.target.value;
    const { formItem, updateHandler } = this.props;
    const options = Object.assign({}, formItem.options, { default_value: newDefaultValue });
    updateHandler({
      options
    });
  }

  _handleLabelChanges(e) {
    const newLabelValue = e.target.value;
    this.setState({ currentLabel: newLabelValue, labelChanged: true });
  }

  _handleHelpChanges(e) {
    this.setState({ currentHelp: e.target.value });
  }

  _handleDefaultValueChanges(e) {
    this.setState({ currentDefaultValue: e.target.value });
  }

  _handleCountryDisplayAsChange(displayAs) {
    return () => {
      const { formItem, updateHandler } = this.props;

      if (displayAs == "hidden_field") {
        this.setState({ currentHelp: "", display: { ...this.state.display, conditional_display: false, description: false } });
        updateHandler({
          options: { ...formItem.options, display_as: displayAs, required: false, help: "" },
          display_conditions: []
        });
      } else {
        updateHandler({
          options: { ...formItem.options, display_as: displayAs }
        });
      }
    };
  }

  renderCountryDisplayAsSelect() {
    const { formItem } = this.props;
    const { options, key } = formItem;
    const displayAs = options.display_as || "dropdown";

    const menuOptions = CountryTypeDisplayAsOptions.map(displayAsOption => {
      return <Dropdown.Item key={`item-type-${displayAsOption}`} onClick={this._handleCountryDisplayAsChange(displayAsOption)}>
        { Icons[`formItem_${displayAsOption}`]() } { I18n.t(`react.input_type_${displayAsOption}`) }
      </Dropdown.Item>;
    });

    return (
      <div className="col-auto">
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id={`${key}-type-select`}>
            <span>{Icons[`formItem_${displayAs}`]()}</span> { I18n.t(`react.input_type_${displayAs}`) }
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            {menuOptions}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  renderInputTypeSelect() {
    const { formItem, availableGuestFields, allItems } = this.props;
    const { type, key } = formItem;

    if (formItem.key === CountryGuestField) {
      return this.renderCountryDisplayAsSelect();
    }

    const options = FormItemTypesInQuestionFormItem.map(currentType => {
      if (FormItemsWithFile.includes(currentType) && nbGuestFieldByType(availableGuestFields, currentType) <= 0) {
        return;
      }

      if (currentType == "accommodation" && !isEnabled(ROOMING)) return;
      if (currentType == "product_group" && !isEnabled(PRODUCTS)) return;
      if (currentType == "promo_code" && !isEnabled(PROMO_CODES)) return;
      if (currentType == "merchant_type" && !isEnabled(PAYMENT)) return;

      // some types should be uniq in whole form
      const uniqTypes = ["promo_code", "merchant_type"];
      if (currentType != type && uniqTypes.includes(currentType) && allItems.find(item => item.type == currentType))
        return;

      return (
        <Dropdown.Item key={`item-type-${currentType}`} onClick={this._handleInputType(currentType)}>
          { Icons[`formItem_${currentType}`]() } { I18n.t(`react.input_type_${currentType}`) }
        </Dropdown.Item>
      );
    });

    return (
      <div className="col-auto">
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id={`${key}-type-select`}>
            <span>{Icons[`formItem_${type}`]()}</span> { I18n.t(`react.input_type_${type}`) }
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            {options}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  _handleInputUniq(value) {
    return () => {
      const { updateHandler, formItem } = this.props;
      const options = Object.assign({}, formItem.options, { uniq_option: value });
      updateHandler({ options });
    };
  }

  _triggerAdvancedMode(value) {
    return () => {
      const { updateHandler, formItem } = this.props;
      let newValues = { advanced_mode: value };
      if (!value) {
        //clear filters
        const keys = Object.keys(optionsFilter);
        keys.forEach(option => {
          newValues[option] = optionsFilter[option];
        });
      }
      const options = Object.assign({}, formItem.options, newValues);
      updateHandler({ options });
    };
  }

  renderMultipleOption() {
    const { type, options } = this.props.formItem;
    if (!["checkin_point_group"].includes(type)) return "";
    const { uniq_option } = options;
    const key = uniq_option ? "uniq" : "multiple";
    return (
      <div className="col-auto">
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="uniq-option">
            <span>{Icons[`${key}_option`]()}</span> { I18n.t(`react.checkin_point_group_${key}`) }
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            <Dropdown.Item onClick={this._handleInputUniq(true)} >
              { Icons["uniq_option"]() } { I18n.t("react.checkin_point_group_uniq") }
            </Dropdown.Item>
            <Dropdown.Item onClick={this._handleInputUniq(false)} >
              { Icons["multiple_option"]() } { I18n.t("react.checkin_point_group_multiple") }
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  renderManagingModeButton() {
    const { type, options } = this.props.formItem;

    if (!["checkin_point_group", "product_group", "accommodation"].includes(type))
      return null;

    const key = options.advanced_mode ? "advanced_mode" : "default_mode";

    return (
      <div className="col-auto">
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="advanced-mode">
            { I18n.t(`react.checkin_point_group_${key}`) }
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            <Dropdown.Item onClick={this._triggerAdvancedMode(true)} >
              { I18n.t("react.checkin_point_group_advanced_mode") }
            </Dropdown.Item>
            <Dropdown.Item onClick={this._triggerAdvancedMode(false)} >
              { I18n.t("react.checkin_point_group_default_mode") }
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  updateDisplay(newDisplay) {
    this.setState({ display: newDisplay });
  }

  openCreateGuestFieldModal(formItem) {
    this.setState({ showCreateGuestFieldModal: true, expectedGuestFieldType: ExpectedGuestFieldsByFormItem[formItem.type] });
  }

  closeCreateGuestFieldModal() {
    this.setState({ showCreateGuestFieldModal: false, expectedGuestFieldType: null });
  }

  hasLabel() {
    const { formItem } = this.props;

    if ([FormItemTypeHiddenField, "accommodation"].includes(formItem.type))
      return false;

    return !(formItem.type == "country" && formItem.options.display_as == FormItemTypeHiddenField);
  }

  renderLabelInput() {
    if (!this.hasLabel())
      return null;

    return (
      <div className="mb-3">
        <input type="text" className="form-control form-control-lg" rel="field-label" value={this.state.currentLabel} placeholder={ I18n.t("react.form_question_item.question") } onBlur={this._handleLabelOnBlur} onChange={this._handleLabelChanges} />
      </div>
    );
  }

  renderContent() {
    const { formItem, mode, updateHandler, destroyFormItemOptionHandler, accesspoints, guestFieldsTypes, guestFieldErrors,
      eventId, event, availableGuestFields, locale, hasInvalidAccessPrivileges, thematics } = this.props;
    const { display, currentDefaultValue } = this.state;

    switch (formItem.type) {
    case "text_field":
      return <TextFieldType defaultValue={ currentDefaultValue } />;
    case "text_area":
      return <TextAreaType defaultValue={ currentDefaultValue } />;
    case "dropdown":
      return <DropdownType formItem={formItem} updateFormItem={updateHandler} mode={mode} guestFields={availableGuestFields} defaultValue={ currentDefaultValue }/>;
    case "checkbox_group":
      return <CheckboxGroupType event={event} formItem={formItem} updateFormItem={updateHandler} mode={mode} guestFields={this.filteredGuestFieldsCheckboxGroup()} destroyFormItemOption={destroyFormItemOptionHandler} guestFieldsTypes={guestFieldsTypes} guestFieldErrors={guestFieldErrors} />;
    case "radio_group":
      return <DropdownType formItem={formItem} updateFormItem={updateHandler} mode={mode} guestFields={availableGuestFields} defaultValue={ currentDefaultValue } />;
    case "checkin_point_group":
    case "product_group": {
      const displayedAccesspoints = accesspoints.filter(accesspoint => [`${accesspoint.type}_group`, "checkin_point_group"].includes(formItem.type));
      return <AccesspointGroupTypeCommon eventId={eventId} locale={locale} formItem={formItem} updateFormItem={updateHandler} mode={mode} accesspoints={sortAccesspoints(displayedAccesspoints, formItem)} filters={display} hasInvalidAccessPrivileges={hasInvalidAccessPrivileges}/>;
    }
    case "accommodation": {
      const displayedAccesspoints = accesspoints.filter(accesspoint => accesspoint.type == "accommodation");
      return <AccesspointGroupTypeCommon eventId={eventId} locale={locale} formItem={formItem} updateFormItem={updateHandler} mode={mode} accesspoints={sortAccesspoints(displayedAccesspoints, formItem)} filters={display} hasInvalidAccessPrivileges={hasInvalidAccessPrivileges}/>;
    }
    case "promo_code":
      return <PromoCodeType formItem={formItem} />;
    case "merchant_type":
      return <MerchantType formItem={formItem} />;
    case "file":
      return <FileType formItem={formItem} updateHandler={updateHandler} mode={mode} />;
    case FormItemTypeHiddenField:
      return <HiddenFieldType mode={mode} updateHandler={updateHandler} defaultValue={formItem.options.default_value}/>;
    case "country":
      return <CountryType formItem={formItem} mode={mode} updateFormItem={updateHandler} locale={locale} />;
    case "date_picker":
      return <DatePickerComponent formItem={formItem} mode={mode} updateHandler={updateHandler} />;
    case "thematics":
      return <ThematicsType formItem={formItem} mode={mode} updateFormItem={updateHandler} thematics={thematics} fetchThematics={this.fetchThematics} />;
    default:
      return <div> { formItem.type } not fully implemented </div>;
    }
  }

  fetchThematics() {
    this.props.fetchThematics(this.props.eventId);
  }

  renderValidations() {
    const { formItem, allItems, allGuestFields, updateHandler, createFormItems, createGuestField, locale, event } = this.props;
    const { display } = this.state;
    const shouldBeDisplayed = display.validations && FormItemsWithDataValidation.includes(formItem.type) || this._hasPrefillCompanyFields() || this._hasPrefillAddressFields();

    return <TextFieldValidation
      formItem={formItem}
      updateFormItem={updateHandler}
      shouldBeDisplayed={shouldBeDisplayed}
      allItems={allItems}
      createFormItems={createFormItems}
      createGuestField={createGuestField}
      allGuestFields={allGuestFields}
      locale={locale}
      accountId={event.account_id}
    />;
  }

  renderTextCompletion() {
    const { formItem, updateHandler } = this.props;
    const { display } = this.state;

    if (!display.display_text_completion) {
      return null;
    }

    return <TextFieldCompletion
      formItem={formItem}
      updateFormItem={updateHandler}
    />;
  }

  _handleGuestField(formItem) {
    return (e) => {
      const { updateHandler, availableGuestFields, changeTypeAndUpdateHandler } = this.props;
      if (isAddGuestFieldKey(e.target.value)) {
        this.openCreateGuestFieldModal(formItem);
        return;
      }
      const guestField = availableGuestFields.find(field => { return field.key == e.target.value; });
      if (guestField == undefined) {
        return; // hitting the pick a value in the select, just do nothing
      }

      if (guestField.key === CountryGuestField) {
        //change the type to render CountryType
        const formItemUpdated = Object.assign({}, formItem, { key: guestField.key, label: guestField.label });
        changeTypeAndUpdateHandler("country", formItemUpdated);
        return;
      } else if (formItem.key === CountryGuestField) {
        const formItemUpdated = Object.assign({}, formItem, { key: guestField.key, label: guestField.label });
        changeTypeAndUpdateHandler("text_field", formItemUpdated, true);
        return;
      } else if (StaticGuestFieldsWithFile.includes(guestField.key) || GuestFieldTypesWithFile.includes(guestField.type)) {
        // choosing a file/image guest field automatically sets the form item type as file
        const formItemUpdated = Object.assign({}, formItem, { key: guestField.key, label: guestField.label });
        changeTypeAndUpdateHandler("file", formItemUpdated, true);
        return;
      }

      const options = DefaultUneditableFields.includes(guestField.key) ? Object.assign({}, formItem.options, { readonly: "edit_only" }) : formItem.options;

      if (this.state.labelChanged) {
        updateHandler({
          key: guestField.key,
          options
        });
      } else {
        this.setState({ currentLabel: guestField.label });
        updateHandler({
          key: guestField.key,
          label: guestField.label,
          options
        });
      }
    };
  }

  noNeedGuestField() {
    const { formItem } = this.props;
    return FormItemGrouped.includes(formItem.type) || FormItemWithoutGuestField.includes(formItem.type);
  }

  renderGuestFieldSelect() {
    const { availableGuestFields, formItem } = this.props;
    if (this.noNeedGuestField()) {
      return null;
    }
    const filteringFunction = FormItemsWithValueList.includes(formItem.type) ?
      (guestField) => { return SuggestedGuestFieldsRegardlessType.includes(guestField.key) || guestField.type === ValueList; } :
      (guestField) => { return SuggestedGuestFieldsRegardlessType.includes(guestField.key) || (guestField.type !== ValueList && !CheckboxGuestFields.includes(guestField.key)); };
    const filteredGuestFields = formItem.type == "hidden_field" ? availableGuestFields : availableGuestFields.filter(filteringFunction);
    const options = injectDefaultsGuestFields(filteredGuestFields).map(field => {
      const keyInfo = ["default", "add-guest-field"].includes(field.key) ? "" : `(${field.key})`;
      return <option key={ field.key } value={ field.key }> { `${field.label} ${keyInfo}` } </option>;
    });
    return (
      <div className="col-md-6">
        <select className="form-select" value={formItem.key} onChange={this._handleGuestField(formItem)}>
          {options}
        </select>
      </div>
    );
  }

  getNewGuestField(guestField) {
    const { updateHandler } = this.props;
    updateHandler({
      key: guestField.key,
      label: guestField.label
    });
  }

  filteredGuestFieldsCheckboxGroup() {
    const { availableGuestFields } = this.props;
    return availableGuestFields.filter(guestField => guestField.type !== ValueList);
  }

  renderModal() {
    const { event } = this.props;
    const { showCreateGuestFieldModal, expectedGuestFieldType } = this.state;
    if (!event._id) return null;

    return <GuestFieldsModal
      event={event}
      isVisible={showCreateGuestFieldModal}
      onCloseFunction={this.closeCreateGuestFieldModal}
      updateParent={this.getNewGuestField}
      guestFieldTypeAllowed={expectedGuestFieldType}
    />;
  }

  formItemAllowsMultipleValues() {
    const { formItem, availableGuestFields } = this.props;
    if (formItem && formItem.type == "thematics")
      return true;

    const guestField = availableGuestFields.find(field => field.key == formItem.key);
    return guestField && guestField.allow_multiple_values;
  }

  guestField() {
    const { formItem, availableGuestFields } = this.props;

    return formItem.key && availableGuestFields.find(gf => gf.key === formItem.key);
  }

  handleOptionsOrderChange(optionsOrder) {
    const { formItem, updateHandler } = this.props;

    return () => updateHandler({ options: { ...formItem.options, options_order: optionsOrder } });
  }

  renderValueListOptionsOrder() {
    const { formItem } = this.props;
    const guestField = this.guestField();

    if (!guestField || guestField.type !== ValueList || (formItem && formItem.type == "hidden_field")) {
      return null;
    }

    const optionsOrder = formItem.options.options_order || "rank";

    const menuItems = ["rank", "label"].map(orderValue => {
      return <Dropdown.Item key={`order-by-${orderValue}`} onClick={this.handleOptionsOrderChange(orderValue)}>
        { Icons[`optionsOrder_${orderValue}`]() } { I18n.t(`react.options_order_${orderValue}`) }
      </Dropdown.Item>;
    });

    const dropdownTitle = <span> {Icons[`optionsOrder_${optionsOrder}`]()} { I18n.t(`react.options_order_${optionsOrder}`) } </span>;
    return <Dropdown>
      <Dropdown.Toggle variant="secondary" id={`${formItem._id}-options-order`}>{ dropdownTitle }</Dropdown.Toggle>
      <Dropdown.Menu align="end">
        {menuItems}
      </Dropdown.Menu>
    </Dropdown>;
  }

  renderMultipleValueOptions() {
    if (!this.formItemAllowsMultipleValues())
      return null;

    const { formItem } = this.props;
    const valueOption = formItem && formItem.options.multiple_value_limit && formItem.options.multiple_value_limit != -1;
    const inputLimit = valueOption && (<div className="col-sm-12 mt-10">
      <div className="row">
        <div className="col-sm-6">
          <input type="number" defaultValue={formItem.options.multiple_value_limit} className="form-control" onBlur={this.handleMultipleOptionChange} />
        </div>
      </div>
    </div>);

    return <div className="mb-3">
      <label className="form-label">{I18n.t("react.form_items.multiple_value_list.option_label")}</label>
      <div>
        <div className="form-check">
          <label className="form-check-label">
            <input className="form-check-input" checked={valueOption} type="checkbox" onChange={this.handleMultipleOptionChange} />
            { I18n.t("react.form_items.multiple_value_list.limited") }
          </label>
        </div>
      </div>
      { inputLimit }
    </div>;
  }

  handleMultipleOptionChange(e) {
    const { updateHandler, formItem } = this.props;
    const value = e.target.type == "checkbox" ? (e.target.checked ? 1 : -1) : e.target.value;
    const options = { ...formItem.options, multiple_value_limit: value };
    updateHandler({ options });
  }

  render() {
    const { mode, formItem, allGuestFields, destroyHandler, updateHandler, renderConditionalDisplay } = this.props;
    const { display, currentDefaultValue, currentHelp } = this.state;
    const helpClass = display.description ? "" : "d-none";
    const multipleValueOptions = ["dropdown", "thematics"].includes(formItem.type) && this.renderMultipleValueOptions();
    const defaultValueClass = FormItemsWithDefaultValueOption.includes(formItem.type) && display.default_value ? "" : "d-none";

    if (mode == "edit") {
      return (
        <div>
          {this.renderModal()}
          <form>
            <div className="mb-3 row justify-content-end">
              {this.renderGuestFieldSelect()}
              <div className="col-md-6">
                <div className="row d-flex flex-row justify-content-end g-2">
                  {this.renderManagingModeButton()}
                  {this.renderInputTypeSelect()}
                  {this.renderMultipleOption()}
                </div>
              </div>
            </div>
            {this.renderLabelInput()}
            <div className={`mb-3 ${helpClass}`}>
              <input type="text" className="form-control form-control-sm" rel="field-help" value={currentHelp} placeholder={ I18n.t("react.form_question_item.help") } onBlur={this._handleHelpOnBlur} onChange={this._handleHelpChanges} />
            </div>
            <div className={`mb-3 ${defaultValueClass}`}>
              <input type="text" className="form-control form-control-sm" rel="field-help" value={currentDefaultValue} placeholder={ I18n.t("react.form_question_item.default_value") } onBlur={this._handleDefaultValueOnBlur} onChange={this._handleDefaultValueChanges} />
            </div>
            <div className="mb-3">
              { this.renderContent() }
            </div>
            { this.renderValidations() }
            { this.renderValueListOptionsOrder() }
            { this.renderTextCompletion() }
            { multipleValueOptions }
            { display.conditional_display && renderConditionalDisplay() }
            <BottomOptions
              formItem={formItem}
              display={display}
              guestFields={allGuestFields}
              destroyHandler={destroyHandler}
              updateHandler={updateHandler}
              updateParentDisplay={this.updateDisplay} />
          </form>
        </div>
      );
    } else if (mode == "read") {
      return (
        <form>
          <div className="mb-3">
            <div id="helpBlock" className="form-text"> {formItem.label} </div>
            {this.renderContent()}
            <div className="form-text">{formItem.options.help}</div>
          </div>
        </form>
      );
    }
  }
}

export default conditionalDisplay(QuestionFormItem);
