import { ActionTypes, CALL_API } from "../constants/Constants";
import { stringify } from "querystring";

const fetchDefaultOptions = {
  stat_types: "count", // possible values: count/count,new_visits/count,new_visits,returning
  field: "guest_category_id", // any guest fields available in the event or in the guest
  limit: null, // limit, only if type == count
  date: null, // date, only if type == new_visits or returning, should be the date of the day
  q: "" // query to scope statistics
};

export function fetchGuestsStatistics(eventId, options = fetchDefaultOptions) {
  const finalOptions = Object.assign({}, fetchDefaultOptions, options);
  const { DEV_NULL, GUESTS_STATISTICS_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, GUESTS_STATISTICS_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guests/statistics.json?${stringify(finalOptions)}`
    },
    field: finalOptions.field,
    date: finalOptions.date,
    eventId
  };
}

export function clearGuestStatistics() {
  return {
    type: ActionTypes.GUESTS_STATISTICS_CLEAR
  };
}

export function selectValuesGuestStatistics(selectedValueIds) {
  return {
    type: ActionTypes.GUESTS_STATISTICS_FILTERED,
    selectedValueIds
  };
}

export function fetchGuestsPaymentStatistics(eventId, options = { stats_type: "count_paid", start_date: null, end_date: null, q: null }) {
  const { DEV_NULL, GUESTS_PAYMENT_STATISTICS_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, GUESTS_PAYMENT_STATISTICS_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guests/payment_statistics.json?${stringify(options)}`
    },
    stats_type: options.stats_type
  };
}

export function clearGuestsPaymentStatistics() {
  return {
    type: ActionTypes.GUESTS_PAYMENT_STATISTICS_CLEAR
  };
}

export function fetchCartItemsStatistics(eventId, options = { start_date: null, end_date: null, q: null, exclude_free_items: false }) {
  const { DEV_NULL, CART_ITEMS_STATISTICS_SUCCESS, CART_ITEMS_STATISTICS_ERROR } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, CART_ITEMS_STATISTICS_SUCCESS, CART_ITEMS_STATISTICS_ERROR],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guests/cart_items_sales.json?${stringify(options)}`
    }
  };
}

export function clearCartItemsStatistics() {
  return {
    type: ActionTypes.CART_ITEMS_STATISTICS_CLEAR
  };
}
