import { Component } from "react";
import { connect } from "react-redux";
import { queryStringAndSelectedGuests } from "../../utils/QueryStringUtils";
import * as DirectEmailActionCreators from "../../actions/DirectEmailActionCreators";
import EmailConfiguration from "../../components/EmailConfiguration.react";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import Loader from "../../components/shared/Loader.react";
import { requestCountGuests } from "../../actions/ImportActionCreators";
import { requestEvent } from "../../actions/ImportActionCreators";
import { fetchEmailTemplates } from "../../actions/EmailBuilderActionCreators";


class SendDirectEmail extends Component {

  constructor(props) {
    super(props);
    [
      "createDirectEmail",
      "deliverDirectEmail"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      replyToEmailAdress: "",
      subject: "",
      templateId: "",
      fromName: ""
    };
  }

  componentDidMount() {
    const { requestCountGuests, requestEvent, fetchEmailTemplates, selectedTemplateId } = this.props;
    if (this.isBatchEmailing()) {
      requestCountGuests(this.queryString(), "selectedGuestCount", true);
    }
    if (!this.hasEvent()) {
      requestEvent();
    } else {
      this.prefillStateFromProps();
    }
    if (!selectedTemplateId) {
      fetchEmailTemplates(this.props.match.params.event_id);
    }
  }

  componentDidUpdate(prevProps) {
    const { directEmail, directEmailErrors, directEmailDelivered } = this.props;
    if (directEmail && !prevProps.directEmail && !directEmailErrors) {
      // email just created, deliver it !
      this.deliverDirectEmail();
    }
    if (!this.hasEvent(prevProps) && this.hasEvent()) {
      this.prefillStateFromProps();
    }
    if (!prevProps.directEmailDelivered && directEmailDelivered) {
      // single email just delivered, clearing state and closing modal
      const { closeModal, clearState } = this.props;
      closeModal();
      clearState();
    }
  }

  hasEvent(props = this.props) {
    return props.event._id;
  }

  prefillStateFromProps(props = this.props) {
    const { event } = props;
    this.setState({
      replyToEmailAdress: event.direct_email_last_reply_to_email || event.reply_to_email || "",
      subject: event.direct_email_last_subject || event.title || "",
      fromName: event.direct_email_last_from_name || event.organizer || ""
    });
  }

  createDirectEmail() {
    const { createDirectEmail, match } = this.props;
    const { event_id } = match.params;
    const { replyToEmailAdress, subject, templateId, fromName } = this.state;

    createDirectEmail(event_id, {
      email_template_id: templateId,
      subject,
      reply_to: replyToEmailAdress,
      from_name: fromName
    });
  }

  deliverDirectEmail(props = this.props) {
    this.isBatchEmailing() ? this.deliverBacth(props) : this.deliverSingle(props);
  }

  deliverSingle(props = this.props) {
    const { deliverDirectEmail, deliverDirectEmailAndReject, directEmail, match, rejectionEmail } = props;
    const { event_id, guest_id } = match.params;
    if (rejectionEmail) {
      deliverDirectEmailAndReject(event_id, directEmail._id, guest_id);
    } else {
      deliverDirectEmail(event_id, directEmail._id, guest_id);
    }
  }

  deliverBacth(props = this.props) {
    const { directEmail, match, deliverBatchDirectEmail, closeModal } = props;
    const { event_id } = match.params;
    const count = this.guestCount();
    deliverBatchDirectEmail(
      event_id,
      directEmail._id,
      this.queryString(),
      { notice: I18n.t("react.direct_email.batch_deliver_notice", { count }), noticeType: "success" }
    );
    closeModal();
  }

  queryString() {
    const { selectedGuests, location } = this.props;
    return queryStringAndSelectedGuests(location, selectedGuests);
  }

  isBatchEmailing() {
    const { guest_id } = this.props.match.params;
    return !guest_id;
  }

  isReadyToSend() {
    const { replyToEmailAdress, subject, templateId } = this.state;
    return [replyToEmailAdress, subject, templateId].every(input => input.length > 0);
  }

  isSending() {
    const { directEmailCreating, directEmailDelivering } = this.props;
    return directEmailCreating || directEmailDelivering;
  }

  guestCount() {
    const { selectedGuestCount } = this.props;
    return selectedGuestCount || 0;
  }

  renderGuestCount() {
    if (!this.isBatchEmailing()) {
      return null;
    }

    const count = this.guestCount();

    return (
      <div>
        <hr />
        <strong>{count}</strong> {I18n.t("react.reports.emails_will_be_sent", { count })}
      </div>
    );
  }

  renderSendButton() {
    const { directEmailDelivered, rejectionEmail } = this.props;
    if (directEmailDelivered) {
      return <strong><i className="fa-regular fa-check-circle text-success"></i> {I18n.t("react.direct_email.email_sent")}</strong>;
    }

    const btnType = rejectionEmail ? "danger" : "primary";
    const title = rejectionEmail ? I18n.t("react.direct_email.send_and_reject") : I18n.t("react.direct_email.deliver");

    return (
      <button className={`btn btn-${btnType}`} onClick={this.createDirectEmail} disabled={this.isSending() || !this.isReadyToSend()}>
        {this.isSending() ? (
          <Loader size="small" color="white" />
        ) : null}
        <i className="fa-regular fa-paper-plane"></i> {title}
      </button>
    );
  }

  render() {
    const { directEmailErrors, match, selectedTemplateId } = this.props;
    const { locale, event_id, guest_id } = match.params;
    const { replyToEmailAdress, subject, templateId, fromName } = this.state;
    return (
      <div>
        { directEmailErrors ? (
          <ErrorMessage errors={directEmailErrors} model="direct_email"/>
        ) : null}
        <EmailConfiguration
          locale={locale}
          eventId={event_id}
          replyToEmailAdress={replyToEmailAdress}
          subject={subject}
          templateId={templateId}
          fromName={fromName}
          onTemplateIdChange={(value) => this.setState({ templateId: value })}
          onSubjectChange={(value) => this.setState({ subject: value })}
          onFromNameChange={(value) => this.setState({ fromName: value })}
          onReplyToEmailAdressChange={(value) => this.setState({ replyToEmailAdress: value })}
          guestId={guest_id}
          displayFromNameInput={true}
          selectedTemplateId={selectedTemplateId}
        />
        {this.renderGuestCount()}
        <hr />
        <div className="float-end">
          { this.renderSendButton() }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { event, directEmail, guests, appearance, emailTemplate } = state;
  return {
    event: event,
    directEmail: directEmail.data,
    directEmailErrors: directEmail.errors,
    directEmailDelivered: directEmail.delivered,
    directEmailCreating: directEmail.creating,
    directEmailDelivering: directEmail.delivering,
    selectedGuests: guests.selectedGuests,
    selectedGuestCount: appearance.selectedGuestCount,
    selectedTemplateId: emailTemplate.selectedTemplateId
  };
}

const mapDispatchToProps = Object.assign({}, DirectEmailActionCreators, {
  requestCountGuests,
  requestEvent,
  fetchEmailTemplates
});

export default connect(mapStateToProps, mapDispatchToProps)(SendDirectEmail);
