import { ChangeEvent } from "react";
import DatePicker from "./shared/DatePicker.react";
import moment from "moment";

const DEFAULT_DATE_FORMAT = "YYYY-MM-DD HH:mm";

interface Props {
  calendarPlacement?: string;
  dateFormat?: string;
  endDate: moment.Moment;
  maxDaysRange?: number;
  onDateRangeChanged: (startDate: moment.Moment, endDate: moment.Moment) => void;
  showClearButton?: boolean;
  startDate: moment.Moment;
  withTimePicker?: boolean;
  wrapperClasses?: string;
}
interface TimePickerProps {
  date: moment.Moment;
  dateFormat?: string;
  onChange: (date: string | moment.Moment) => void;
}

const TimePicker: React.FC<TimePickerProps> = ({ date, dateFormat, onChange }) => {
  const timeChange = (attribute: "hour" | "minute", value: string): void => {
    if (date === null) return;

    const newValue = parseInt(value);
    const change = {};
    change[attribute] = newValue;
    const newDate = date.clone().set(change);
    onChange(newDate.format(dateFormat));
  };

  const handleChange = (attribute: "hour" | "minute") => (value: string): void => {
    return timeChange(attribute, value);
  };

  return <>
    <HourPicker date={date} dateFormat={dateFormat} onChange={handleChange("hour")}/>
    <MinutePicker date={date} dateFormat={dateFormat} onChange={handleChange("minute")} />
  </>;
};

const HourPicker: React.FC<TimePickerProps> = ({ date, onChange }) => {
  const hours = Array.from({ length: 24 }, (v, k) => k);
  const options = hours.map(value => {
    return <option value={value} key={value}>{value}h</option>;
  });
  return (
    <div className="col-auto">
      <select className="form-select" value={date?.hour()} onChange={ (e: ChangeEvent<HTMLSelectElement>): any => onChange(e.target.value) }>
        {options}
      </select>
    </div>
  );
};

const MinutePicker: React.FC<TimePickerProps> = ({ date, onChange }) => {
  const hours = Array.from({ length: 60 }, (v, k) => k);
  const options = hours.map(value => {
    return <option value={value} key={value}>{value}</option>;
  });
  return (
    <div className="col-auto">
      <select className="form-select" value={date?.minute()} onChange={ (e: ChangeEvent<HTMLSelectElement>): any => onChange(e.target.value) }>
        {options}
      </select>
    </div>
  );
};

const DateRangePicker: React.FC<Props> = ({
  calendarPlacement,
  dateFormat,
  endDate,
  maxDaysRange,
  onDateRangeChanged,
  showClearButton,
  startDate,
  withTimePicker,
}) => {
  const onDateChanged = (newStartDate: string | moment.Moment, newEndDate: string | moment.Moment, fieldChanged = "start"): void => {
    let momentStartDate = newStartDate ? moment(newStartDate) : null;
    let momentEndDate = newEndDate ? moment(newEndDate) : null;

    if (!momentStartDate || !momentEndDate) {
      onDateRangeChanged(momentStartDate, momentEndDate);
      return;
    }

    if (momentStartDate.isAfter(momentEndDate)) [momentStartDate, momentEndDate] = [momentEndDate, momentStartDate];

    if (maxDaysRange && momentEndDate.diff(momentStartDate, "days") > maxDaysRange) {
      if (fieldChanged === "start") momentEndDate = momentStartDate.clone().add(maxDaysRange, "days");
      else momentStartDate = momentEndDate.clone().subtract(maxDaysRange, "days");
    }

    onDateRangeChanged(momentStartDate, momentEndDate);
  };

  const onStartDateChange = (newStartDate: string | moment.Moment): void => { onDateChanged(newStartDate, endDate); };
  const onEndDateChange = (newEndDate: string | moment.Moment): void => { onDateChanged(startDate, newEndDate, "end"); };

  return <div className="row g-2 align-items-center">
    <div className="col-auto">
      <DatePicker
        selectedDate={startDate ? new Date(startDate.format()) : null}
        onChange={onStartDateChange}
        isClearable={showClearButton}
        calendarPlacement={calendarPlacement}
        maxDate={endDate ? new Date(endDate.format()) : null}
      />
    </div>
    { withTimePicker && <TimePicker date={startDate} dateFormat={dateFormat} onChange={onStartDateChange} /> }
    <span className="col-auto">
      { I18n.t("to") }
    </span>
    <div className="col-auto">
      <DatePicker
        selectedDate={endDate ? new Date(endDate.format()) : null}
        onChange={onEndDateChange}
        isClearable={showClearButton}
        calendarPlacement={calendarPlacement}
        minDate={startDate ? new Date(startDate.format()) : null}
      />
    </div>
    { withTimePicker && <TimePicker date={endDate} dateFormat={dateFormat} onChange={onEndDateChange} /> }
  </div>;
};

DateRangePicker.defaultProps = {
  dateFormat: DEFAULT_DATE_FORMAT,
  calendarPlacement: "right-start",
  showClearButton: true,
  withTimePicker: false
};

export default DateRangePicker;
