import { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router";
import { Link } from "react-router-dom";
import classNames from "classnames";
import trim from "lodash/trim";
import min from "lodash/min";
import floor from "lodash/floor";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import querystring from "querystring";

import { fillStateFromSearchQuery, guestCountFromQS, updateGuestCount } from "../actions/SearchQueryActionCreators";
import { requestGuests } from "../actions/ImportActionCreators";
import { fetchSavedSearches } from "../actions/SavedSearchActionCreators";
import { fetchGuestProductCollections } from "../actions/GuestProductCollectionsActionCreators";
import { triggerBadgesExport, triggerQRCodesExport } from "../actions/ExportsActionCreators";
import { toggleGuest, sampleGuestCreation } from "../actions/GuestListActionCreators";
import { launchBulkAction } from "../actions/GuestUpdatesActionCreators";
import { setSelectedGuestSortOptions } from "../actions/GuestSortActionCreators";
import { setSelectedGuestCategoryIds } from "../actions/GuestCategoryActionCreators";
import { fetchCurrentSavedSearch, clearCurrentSavedSearch } from "../actions/SavedSearchActionCreators";
import { setGuestPage } from "../actions/GuestPageActionCreators";
import { fetchUserSettings, updateUserSettings } from "../actions/UserSettingsActionCreators";
import { createColumnsSet, updateColumnsSet, deleteColumnsSet } from "../actions/ColumnsSetsActionCreators";
import { fetchDocumentTemplates } from "../actions/DocumentTemplatesActionCreators";

import connectToQueryString from "../utils/connectToQueryString";

import AdvancedSearch from "./AdvancedSearch.react";
import ChangeAccessControls from "./ChangeAccessControls.react";
import ChangeGuestCategory from "./ChangeGuestCategory.react";
import ChangeGuestField from "./ChangeGuestField.react";
import CalculateGuestField from "./CalculateGuestField.react";
import ChangeLabel from "./ChangeLabel.react";
import ChangeStatus from "./ChangeStatus.react";
import ResendWebhooks from "./ResendWebhooks.react";
import DeleteGuests from "./DeleteGuests.react";
import ExportsList from "./ExportsList.react";
import LabelsList from "./LabelsList.react";
import Exports from "./Exports.react";
import GuestFields from "./GuestFields.react";
import SavedSearches from "./SavedSearches.react";
import EditSavedSearchModal from "./EditSavedSearchModal.react";
import SaveReport from "./SaveReport.react";
import SendConfirmationEmail from "./SendConfirmationEmail.react";
import SynchronizeGuestsIntoAccount from "./SynchronizeGuestsIntoAccount.react";
import TriggerAction from "./TriggerAction.react";
import BatchDirectEmailModal from "./direct_emails/BatchDirectEmailModal.react";
import SynchronizeContactsIntoEventModal from "./contacts/SynchronizeContactsIntoEventModal.react";

import SearchBar from "../components/search_bar/SearchBar.react";
import GuestsIndexControlBar from "../components/GuestsIndexControlBar.react";
import GuestsTable from "../components/GuestsTable.react";
import GuestMoreActionsDropdown from "../components/GuestMoreActionsDropdown.react";
import GuestAddDropdown from "../components/GuestAddDropdown.react";
import GuestsTableColumnsPicker from "../components/GuestsTableColumnsPicker.react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { getAcl, redirectIfUnauthorized, isAuthorized } from "../utils/aclUtils";
import { urlWithQuery } from "../utils/pathUtils";
import { DefaultGuestsListQuery } from "../constants/Constants";
import DeleteSavedSearchModal from "./DeleteSearchModal.react";

const START_SEARCH_DELAY = 200;

class Guests extends Component {
  constructor(props) {
    redirectIfUnauthorized("guest", "read");
    super(props);
    this.timeout = null;
    [
      "_onSearchBarChange",
      "_clearFilters",
      "_goToPage",
      "_toggleGuest",
      "_launchBulkAction",
      "_downloadQRCodeArchive",
      "_countAllGuests",
      "_downloadBadges",
      "_enhancedGuests",
      "_pagination",
      "_setSavedSearch",
      "_openColumnsPickerModal",
      "_closeColumnsPickerModal",
      "_updateUserSettings",
      "setFilteredGuestCategories",
      "_appendSearchBar",
      "launchSampleGuestsCreation",
      "saveColumnsSet",
      "deleteColumnsSet"
    ].forEach((item) => {
      this[item] = this[item].bind(this);
    });
    this.state = {
      searchQuery: querystring.parse(props.location.search.substring(1)).q,
      cancelSearchBarUpdateAction: false,
      displayColumnsPickerModal: false,
      guestsFetched: false
    };
  }

  componentDidMount() {
    const { location, match, fetchUserSettings, guestCountFromQS, fetchSavedSearches, fetchGuestProductCollections, fetchDocumentTemplates, fetchCurrentSavedSearch } = this.props;
    const eventId = match.params.event_id;
    const q = querystring.parse(location.search.substring(1)).q;
    fetchUserSettings(eventId);
    guestCountFromQS(q);
    fetchSavedSearches(eventId);
    fetchDocumentTemplates(eventId);
    fetchCurrentSavedSearch(eventId, q);
    fetchGuestProductCollections(eventId);
  }

  componentDidUpdate(prevProps) {
    const { updateGuestCount, requestGuests, fetchCurrentSavedSearch, reloadGuestsPage } = this.props;
    if (reloadGuestsPage) window.location.reload();

    const { searchQuery, guestsFetched } = this.state;
    const completeSearchQuery = querystring.parse(this.props.location.search.substring(1)).q;

    if (!this.props.guestPage.isFillingfilters && ((searchQuery || "") != (completeSearchQuery || "") || !guestsFetched)) {
      this.setState({ searchQuery: completeSearchQuery, guestsFetched: true });
      clearTimeout(this.timeout);
      this.timeout = setTimeout(
        () => {
          updateGuestCount("guestsCount");
          requestGuests(completeSearchQuery, this.props.guestPage.currentPage, { thematic_scorings: true });
          fetchCurrentSavedSearch(prevProps.match.params.event_id, completeSearchQuery);
        },
        START_SEARCH_DELAY
      );
    }
  }

  _launchBulkAction(e) {
    const value = e.currentTarget.getAttribute("value");
    if (confirm(I18n.t(`guests.bulk_action_confirm.${value}`))) {
      const { launchBulkAction, location } = this.props;
      const { page } = querystring.parse(location.search.substring(1));
      launchBulkAction(value, querystring.parse(location.search.substring(1)).q, page || 1);
    }
  }

  _setSavedSearch(savedSearch) {
    if (savedSearch) {
      this._onSearchBarChange(savedSearch.search_query);
    }
  }

  _clearFilters() {
    this._onSearchBarChange("");
    this.props.clearCurrentSavedSearch();
  }

  _appendSearchBar(value) {
    const completeSearchQuery = querystring.parse(this.props.location.search.substring(1)).q;
    if (completeSearchQuery.includes(value)) {
      return;
    }
    this._onSearchBarChange(completeSearchQuery + " " + value);
  }

  _onSearchBarChange(value) {
    const { fillStateFromSearchQuery } = this.props;
    if (this.state.searchQuery != trim(value)) {
      fillStateFromSearchQuery(value, 1);
    }
  }

  _toggleGuest(ids, forceCheck) {
    this.props.toggleGuest(ids, forceCheck);
  }

  _countAllGuests() {
    this.props.updateGuestCount("guestsCount", true);
  }

  _renderGuestCount() {
    const { event, appearance, guestPage } = this.props;
    const maxSize = appearance.guestsCount;
    const count = maxSize == 1 ? I18n.t("react.reports.one") : maxSize;
    const label = maxSize == 1 ? I18n.t("react.reports.guest") : I18n.t("react.reports.guests");
    let count_label = (
      <span><strong className="count-result">{count}</strong> {label}</span>
    );
    if (count == event.guests_quota) {
      count_label = (
        <a href="#" onClick={this._countAllGuests}>
          <strong> {I18n.t("react.reports.a_large_number")}</strong> {label}
        </a>
      );
    }
    const paginateCounter = () => {
      if (!maxSize || this.props.guests == undefined || this.props.guests == "" || event.guest_pagination == undefined) {
        return "";
      } else if (floor((maxSize - 1) / event.guest_pagination) == 0) {
        `1 - ${maxSize}`;
      } else {
        const page = guestPage.currentPage;
        const first = 1 + event.guest_pagination * (page - 1);
        const last = maxSize <= first ? page * event.guest_pagination : min([page * event.guest_pagination, maxSize]);
        const lastLabel = first != last ? ` - ${last}` : "";
        return `${first} ${lastLabel} ${I18n.t("paginate_of")}`;
      }
    };
    let pagination_span_content = "";
    if (paginateCounter() != "" && count_label != undefined && label != "") {
      pagination_span_content = (
        <span>
          {paginateCounter()} {count_label}
        </span>
      );
    }
    return (
      <div className="count">
        {pagination_span_content}
      </div>
    );
  }

  _downloadBadges() {
    this.props.triggerBadgesExport(querystring.parse(this.props.location.search.substring(1)).q);
  }

  _downloadQRCodeArchive() {
    this.props.triggerQRCodesExport(querystring.parse(this.props.location.search.substring(1)).q);
  }

  _goToPage(action) {
    const { history, setGuestPage, requestGuests, guestPage, location, previousURL, nextURL } = this.props;
    const nextPage = action == "next" ? guestPage.currentPage + 1 : guestPage.currentPage - 1;
    const { q } = querystring.parse(location.search.substring(1));
    const newPath = urlWithQuery(q, `guests?page=${nextPage}`);
    if ((action == "previous" && previousURL) || (action == "next" && nextURL)) {
      if (history) {
        history.replace(newPath);
      }
      setGuestPage(nextPage);
      requestGuests(q, nextPage, { thematic_scorings: true });
    }
  }

  _enhancedGuests() {
    const { guests, selectedGuests, guestCategories } = this.props;
    return map(guests, guest => {
      guest.checked = find(selectedGuests, g => {return g.id == guest.id;});
      const guestCategory = find(guestCategories, category => {
        return category.id == guest.guest_category_id;
      });
      if (!guestCategory) { return guest; }
      const extraData = {
        confirmation_email_enabled: guestCategory.confirmation_email_enabled,
        moderation_email_enabled: guestCategory.moderation_email_enabled,
        guest_category_color: guestCategory.label_color,
        guest_category_show_path: guestCategory.show_path,
        guest_category_name: guestCategory.name,
        badgeTemplates: guestCategory.badge_templates
      };
      return Object.assign({}, guest, extraData);
    });
  }

  _pagination() {
    const { previousURL, nextURL } = this.props;
    if (!previousURL && !nextURL) return null;

    return <div className="pagination">
      <ul className="pagination">
        <li className={classNames({ "page-item": true, disabled: previousURL == null })}>
          <a className="page-link" onClick={this._goToPage.bind(this, "previous")}>
            <i className="fa-regular fa-long-arrow-left"></i>
          </a>
        </li>
        <li className={classNames({ "page-item": true, disabled: nextURL == null })}>
          <a className="page-link" onClick={this._goToPage.bind(this, "next")}>
            <i className="fa-regular fa-long-arrow-right"></i>
          </a>
        </li>
      </ul>
    </div>;
  }

  _openColumnsPickerModal() {
    this.setState({ displayColumnsPickerModal: true });
  }

  _closeColumnsPickerModal() {
    this.setState({ displayColumnsPickerModal: false });
  }

  _updateUserSettings(update) {
    const { match, updateUserSettings } = this.props;
    updateUserSettings(match.params.event_id, update);
    this.setState({ displayColumnsPickerModal: false });
  }

  saveColumnsSet(name, columns, columnsSetId = null) {
    const { createColumnsSet, updateColumnsSet, match } = this.props;
    const { event_id } = match.params;
    if (columnsSetId) {
      updateColumnsSet(event_id, columnsSetId, { columns });
    } else {
      createColumnsSet(event_id, { name, columns });
    }
  }

  deleteColumnsSet(columnsSetId) {
    const { deleteColumnsSet, match } = this.props;
    const { event_id } = match.params;
    deleteColumnsSet(event_id, columnsSetId);
  }

  setFilteredGuestCategories(guestCategoryIds) {
    const { setSelectedGuestCategoryIds } = this.props;
    setSelectedGuestCategoryIds(guestCategoryIds);
  }

  launchSampleGuestsCreation() {
    const { sampleGuestCreation, match } = this.props;
    const eventId = match.params.event_id;
    sampleGuestCreation(eventId);
  }

  render() {
    if (!isAuthorized("guest", "read")) return null;

    const { guestCategories, guestFields, guestProductCollections, userSettings, documentTemplates, match, location, unexpectedApiError, columnsSets, thematics } = this.props;
    const { setSelectedGuestSortOptions, setSelectedGuestCategoryIds, selectedGuestCategoryIds, selectedGuestSortOptions, currentSavedSearch, selectedGuests } = this.props;
    const { event, appearance, acl, previousURL, nextURL } = this.props;
    const { savedSearches } = this.props;
    const { searchQuery } = this.state;

    const { guest_ids } = querystring.parse(location.search.substring(1));

    if (!event) return <span></span>;
    const enhancedGuests = this._enhancedGuests();
    const pagination = this._pagination();
    const tooltip = <Tooltip id="advanced-search-tooltip">{I18n.t("guests.index.advanced_search")}</Tooltip>;
    const selectedSavedSearchId = currentSavedSearch ? currentSavedSearch._id : null;
    const moreActionDropdown = isAuthorized("guest", "mass_actions") ? (
      <GuestMoreActionsDropdown
        event={event}
        searchQuery={searchQuery}
        guestIds={guest_ids}
        downloadQRCodeArchive={this._downloadQRCodeArchive}
        downloadBadges={this._downloadBadges}
      />
    ) : null;

    return (
      <div>
        <div className="header-page">
          <div className="header-page-content">
            <div className="header-page-search-bar d-flex flex-row">
              <div className="search-container">
                <SearchBar
                  placeholder={I18n.t("shared.guest_filter.search_placeholder")}
                  value={this.state.searchQuery}
                  handleSubmit={this._onSearchBarChange}
                  handleClear={this._clearFilters}
                  forceClearButtonDisplayed={!isEmpty(this.state.searchQuery)}
                  clearTooltip={I18n.t("guests.index.clear_search")}
                />
              </div>
              <OverlayTrigger overlay={tooltip} placement="top" delay={500}>
                <Link to={urlWithQuery(searchQuery, "guests/modal/advanced_search")} className="btn btn-secondary d-flex flex-column justify-content-center ml-5">
                  <i className="fa-regular fa-sliders fa-lg fa-fw"></i>
                </Link>
              </OverlayTrigger>
            </div>
            <div className="header-page-btn">
              <GuestAddDropdown guestCategories={guestCategories} acl={acl} />
              {moreActionDropdown}
            </div>
          </div>
        </div>

        <div className="mt-15 guest-actions">
          <div className="control-bar">
            <GuestsIndexControlBar
              setSelectedGuestSortOptions={setSelectedGuestSortOptions}
              setSelectedGuestCategoryIds={setSelectedGuestCategoryIds}
              guestCategories={guestCategories}
              selectedGuestCategoryIds={selectedGuestCategoryIds}
              selectedGuestSortOptions={selectedGuestSortOptions}
              selectedSavedSearchId={selectedSavedSearchId}
              savedSearches={savedSearches}
              setSelectedSaveSearch={this._setSavedSearch}
              openColumnsPickerModal={this._openColumnsPickerModal}
              searchQuery={searchQuery}
              guestIds={(selectedGuests || []).map(g => g.id)}
              launchBulkAction={this._launchBulkAction}
              selectedGuests={selectedGuests}
              event={event}
            />
          </div>
          <div className="guest-pagination collection-pagination">
            {this._renderGuestCount()}
            {pagination}
          </div>
          <div className="clearfix"></div>
        </div>
        <GuestsTableColumnsPicker
          isOpen={this.state.displayColumnsPickerModal}
          close={this._closeColumnsPickerModal}
          updateUserSettings={this._updateUserSettings}
          saveColumnsSet={this.saveColumnsSet}
          deleteColumnsSet={this.deleteColumnsSet}
          userSettings={userSettings}
          guestFields={guestFields.guestFields}
          guestFieldsFetched={guestFields.fetched}
          documentTemplates={documentTemplates}
          columnsSets={columnsSets}
          event={event}
          thematics={thematics.items}
        />
        <GuestsTable
          isDefaultQuery={searchQuery == DefaultGuestsListQuery}
          guests={enhancedGuests}
          guestCategories={guestCategories}
          guestProductCollections={guestProductCollections}
          event={event}
          toggleGuest={this._toggleGuest}
          guestsCount={appearance.guestsCount}
          unexpectedApiError={unexpectedApiError}
          labels={this.props.labels || []}
          columns={userSettings.guests_columns}
          guestFields={guestFields.guestFields}
          documentTemplates={documentTemplates}
          setFilteredGuestCategories={this.setFilteredGuestCategories}
          updateSearchBar={this._appendSearchBar}
          launchSampleGuestsCreation={this.launchSampleGuestsCreation}
          thematics={thematics.items}
        />
        { (previousURL || nextURL) &&
          <div className="guest-actions mt-10" style={{ justifyContent: "flex-end" }}>
            <div className="guest-pagination collection-pagination">
              {this._renderGuestCount()}
              {pagination}
            </div>
            <div className="clearfix"></div>
          </div>
        }
        <Route path={`${match.path}/modal`} render={({ match }) => (
          <div>
            <Route path={`${match.path}/advanced_search`} component={AdvancedSearch}/>
            <Route path={`${match.path}/change_label`} component={ChangeLabel}/>
            <Route path={`${match.path}/change_status`} component={ChangeStatus}/>
            <Route path={`${match.path}/change_guest_category`} component={ChangeGuestCategory}/>
            <Route path={`${match.path}/change_access_controls`} component={ChangeAccessControls}/>
            <Route path={`${match.path}/delete_guests`} component={DeleteGuests}/>
            <Route path={`${match.path}/send_direct_email`} component={BatchDirectEmailModal}/>
            <Route path={`${match.path}/exports`} component={Exports}/>
            <Route path={`${match.path}/exports_list`} component={ExportsList}/>
            <Route path={`${match.path}/labels_list`} component={LabelsList}/>
            <Route exact path={`${match.path}/guest_fields`} component={GuestFields}/>
            <Route path={`${match.path}/guest_fields/:key`} component={ChangeGuestField}/>
            <Route path={`${match.path}/resend_webhooks`} component={ResendWebhooks}/>
            <Route path={`${match.path}/calculate_guest_field`} component={CalculateGuestField}/>
            <Route path={`${match.path}/saved_searches`} component={SavedSearches}/>
            <Route path={`${match.path}/save_search`} component={SaveReport}/>
            <Route path={`${match.path}/send_confirmation_email`} component={SendConfirmationEmail}/>
            <Route path={`${match.path}/synchronize_guests_into_account`} component={SynchronizeGuestsIntoAccount}/>
            <Route path={`${match.path}/trigger_actions`} component={TriggerAction}/>
            <Route path={`${match.path}/synchronize_contacts_into_event`} component={SynchronizeContactsIntoEventModal}/>
            <Route path={`${match.path}/edit_saved_search/:saved_search_id`} component={EditSavedSearchModal}/>
            <Route path={`${match.path}/delete_saved_search/:saved_search_id`} component={DeleteSavedSearchModal}/>
          </div>
        )}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const acl = getAcl();
  return {
    appearance: state.appearance,
    event: state.event,
    guestCategories: state.guestCategories.data.filter(guestCategory => {
      if (!acl) return false;
      if (acl.guest.filtered_guest_category_ids == "all") return true;
      return acl.guest.filtered_guest_category_ids.includes(guestCategory.id);
    }),
    guestPage: state.guestPage,
    guests: state.guests.guests,
    nextURL: state.guests.nextURL,
    previousURL: state.guests.previousURL,
    selectedGuestCategoryIds: state.selectedGuestCategoryIds,
    selectedGuests: state.guests.selectedGuests,
    selectedGuestSortOptions: state.selectedGuestSortOptions,
    labels: state.labels.items,
    savedSearches: state.savedSearches.data,
    currentSavedSearch: state.currentSavedSearch,
    userSettings: state.userSettings,
    guestFields: state.guestFields,
    guestProductCollections: state.guestProductCollections.data,
    documentTemplates: state.documentTemplates.data,
    unexpectedApiError: state.unexpectedApiError,
    columnsSets: state.columnsSets,
    acl,
    reloadGuestsPage: state.guests.reloadGuestsPage,
  };
}

const mapDispatchToProps = {
  clearCurrentSavedSearch,
  createColumnsSet,
  deleteColumnsSet,
  fetchCurrentSavedSearch,
  fetchGuestProductCollections,
  fetchDocumentTemplates,
  fetchUserSettings,
  fillStateFromSearchQuery,
  guestCountFromQS,
  launchBulkAction,
  requestGuests,
  fetchSavedSearches,
  sampleGuestCreation,
  setGuestPage,
  setSelectedGuestCategoryIds,
  setSelectedGuestSortOptions,
  toggleGuest,
  triggerBadgesExport,
  triggerQRCodesExport,
  updateColumnsSet,
  updateGuestCount,
  updateUserSettings
};

export default connectToQueryString(connect(mapStateToProps, mapDispatchToProps)(withRouter(Guests)));
export { Guests };
