import { ActionTypes } from "../constants/Constants";
import { GuestInvitationStatsState, GuestInvitationStat } from "../types/GuestInvitationStats";

const defaultState: GuestInvitationStatsState = {
  errors: null,
  isFetching: false,
  data: null,
  invitationCategoryIds: []
};

export function guestInvitationStatistics(state = defaultState, action: any): GuestInvitationStatsState {
  switch (action.type) {
  case ActionTypes.FETCH_GUEST_INVITATION_STATS_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.FETCH_GUEST_INVITATION_STATS_SUCCESS:
    return { ...state, data: action.data, invitationCategoryIds: invitationCategoryIds(action.data), isFetching: false, errors: null };
  case ActionTypes.FETCH_GUEST_INVITATION_STATS_FAILURE:
    return { ...state, data: [], isFetching: false, errors: action.data };
  default:
    return state;
  }
}

const invitationCategoryIds = (data: GuestInvitationStat[]): string[] => {
  if (!data || data.length === 0) return [];
  if (data[0].invitations_sent_by_guest_category === undefined) return [];

  return Object.keys(data[0].invitations_sent_by_guest_category);
};

export default guestInvitationStatistics;
