import { Component } from "react";
import { OverlayTrigger, Tooltip, FormGroup, FormCheck } from "react-bootstrap";
import moment from "moment";
import { urlEventLocale } from "../../utils/pathUtils";
import { SegmentPicker } from "../../containers/shared/SegmentPicker.react";
import DateTimePicker from "../shared/DateTimePicker.react";

const CAMPAIGN_MEDIA = ["use_email", "use_sms"];

class Form extends Component {

  constructor(props) {
    super(props);
    const privateMethods = [
      "_informationTooltip",
      "_onInputBlur",
      "_onInputChange",
      "_onSelectedSavedSearchChange",
      "_renderInfoSpan",
      "_onMediumCheckboxChange",
      "_onScheduleCheckboxChange",
      "_onScheduledDateChange",
      "_onTimeChange",
      "_onResetDatePicker",
      "_onValidateScheduledDate"
    ];
    privateMethods.forEach((item) => { this[item] = this[item].bind(this); });
    this.state = {
      name: "",
      from_name: "",
      reply_to_email: "",
      subject: "",
      saved_search_id: "",
      use_email: false,
      use_sms: false,
      scheduled_at: ""
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.guestCampaign != prevProps.guestCampaign) {
      const { name, from_name, reply_to_email, subject, saved_search_id, use_email, use_sms, scheduled_at } = this.props.guestCampaign;
      this.setState({
        name,
        from_name,
        reply_to_email,
        subject,
        saved_search_id,
        use_email,
        use_sms,
        scheduled_at
      });
    }
  }

  _onInputBlur(e) {
    const { updateCampaign } = this.props;
    updateCampaign({ [e.target.name]: e.target.value });
  }

  _onInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  _onSelectedSavedSearchChange(search) {
    const { updateCampaign } = this.props;
    updateCampaign({ saved_search_id: search._id });
  }

  _informationTooltip(message) {
    return (
      <Tooltip id="tooltip">{message}</Tooltip>
    );
  }

  _onMediumCheckboxChange(medium) {
    return (e) => {
      const { updateCampaign } = this.props;
      updateCampaign({ [medium]: e.target.checked });
    };
  }

  _onScheduleCheckboxChange() {
    const { scheduled_at } = this.state;
    const { updateCampaign } = this.props;
    const newScheduledAt = scheduled_at ? null : moment().add(1, "hours").format();
    updateCampaign(Object.assign(this.state, { scheduled_at: newScheduledAt }));
  }

  _onResetDatePicker(e) {
    e.preventDefault();
    this._onScheduleCheckboxChange();
  }

  _onTimeChange(timeUnit) {
    return (e) => {
      const { scheduled_at } = this.state;
      let newDate = moment(scheduled_at);
      newDate[timeUnit](parseInt(e.target.value));
      const fDate = moment(newDate).format();
      this.setState({ scheduled_at: fDate });
    };
  }

  _onScheduledDateChange(date) {
    const fDate = date ? moment(date).format() : null;
    this.setState({ scheduled_at: fDate });
  }

  _onValidateScheduledDate() {
    const { updateCampaign } = this.props;
    updateCampaign(this.state);
  }

  _renderInfoSpan(infoMessage) {
    if (infoMessage === "") {
      return;
    }
    return (
      <span className="form-label d-none d-sm-inline ml-5">
        <OverlayTrigger overlay={this._informationTooltip(infoMessage)}>
          <i className="fa-regular fa-info-circle"></i>
        </OverlayTrigger>
      </span>
    );
  }

  _renderInput(type, label, value, name, infoMessage) {
    return (
      <div className="mb-3">
        <label htmlFor={name} className="form-label">{label}</label>
        { this._renderInfoSpan(infoMessage) }
        <input type={type} className="form-control" placeholder={label} value={value || ""} name={name} id={name} onBlur={this._onInputBlur} onChange={this._onInputChange}/>
      </div>
    );
  }

  _renderSavedSearchSelect() {
    const { saved_search_id } = this.state;
    const { savedSearches } = this.props;
    return (
      <div className="mb-10">
        <label className="form-label"> { I18n.t("react.guest_campaign.form.pick_a_segment") } </label>
        <div className="row">
          <div className="col-md-12">
            <SegmentPicker onSelectSegment={this._onSelectedSavedSearchChange} defaultSegmentId={saved_search_id} segments={savedSearches} />
          </div>
        </div>
      </div>
    );
  }

  _renderCampaignMediumCard(medium) {
    const mediumValue = !!this.state[medium];
    return (
      <div className="col-md-6 mt-10" key={`${medium}`}>
        <div className="card">
          <div className="card-header text-center">
            <div className="card-title text-center">
              <strong>{ I18n.t(`react.guest_campaign.form.medium_card_title.${medium}`) }</strong>
            </div>
          </div>
          <div className="card-body d-flex flex-column justify-content-center" style={{ minHeight: "70px" }}>
            <FormGroup controlId={`medium_card_body_${medium}`}>
              <FormCheck type="checkbox" checked={mediumValue} onChange={this._onMediumCheckboxChange(medium)} label={ I18n.t(`react.guest_campaign.form.medium_card_body.${medium}`) } />
            </FormGroup>
          </div>
        </div>
      </div>
    );
  }

  renderFromNameIfNeeded() {
    const { use_email, use_sms, from_name } = this.state;
    if (!use_email && !use_sms) {
      return;
    }
    return (
      <div>
        { this._renderInput("text", I18n.t("react.guest_campaign.form.from_name"), from_name, "from_name", I18n.t("react.guest_campaign.form.info_from_name")) }
      </div>
    );
  }

  renderEmailSubJectAndReplyToIfNeeded() {
    const { use_email, use_sms, reply_to_email, subject } = this.state;
    if (!use_email || use_email && use_sms) {
      return;
    }
    return (
      <div>
        { this._renderInput("email", I18n.t("react.guest_campaign.guest_campaign_notification.email_panel.reply_to"), reply_to_email, "reply_to_email", "")}
        { this._renderInput("text", I18n.t("react.guest_campaign.guest_campaign_notification.email_panel.subject"), subject, "subject", "")}
      </div>
    );
  }

  _renderScheduledDateTimePicker() {
    const { scheduled_at } = this.state;
    if (!scheduled_at) return null;
    return <DateTimePicker
      title={ I18n.t("react.guest_campaign.form.scheduled_at") }
      selectedDate={moment(scheduled_at)}
      onChangeDate={this._onScheduledDateChange}
      onChangeHour={this._onTimeChange("hours")}
      onChangeMinute={this._onTimeChange("minutes")}
      locale={urlEventLocale()}
      reset={this._onResetDatePicker}
      onValidate={this._onValidateScheduledDate}
      calendarPlacement="top"
    />;
  }

  _renderScheduler() {
    const { scheduled_at } = this.state;
    return <div>
      <div className="row">
        <div className="col-md-11">
          <div className="form-check">
            <label className="form-check-label">
              <input className="form-check-input" type="checkbox" checked={scheduled_at != null} onChange={this._onScheduleCheckboxChange}/> {I18n.t("react.guest_campaign.form.schedule_campaign")}
            </label>
          </div>
        </div>
      </div>
      { this._renderScheduledDateTimePicker() }
    </div>;
  }

  render() {
    const { name } = this.state;
    return (
      <form>
        { this._renderInput("text", I18n.t("react.guest_campaign.form.name"), name, "name", "") }
        { this._renderSavedSearchSelect() }
        <div className="row">
          { CAMPAIGN_MEDIA.map(medium => this._renderCampaignMediumCard(medium)) }
        </div>
        { this.renderFromNameIfNeeded() }
        { this.renderEmailSubJectAndReplyToIfNeeded() }
        { this._renderScheduler() }
      </form>
    );
  }
}

export default Form;
