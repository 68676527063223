import url from "url";
import { StreamingArchive } from "../types/StreamingArchive";

export function archiveFileName(archive: StreamingArchive): string {
  const uri = url.parse(archive.public_url);
  const fileName = uri.pathname.split("/").pop();
  const ext = fileName.split(".").pop();
  const regex = new RegExp(`_[^_]+(\\.${ext})`);

  return fileName.replace(regex, "$1");
}
