import { Component } from "react";
import { connect } from "react-redux";
import indexOf from "lodash/indexOf";
import { OverlayTrigger, Button } from "react-bootstrap";
import classNames from "classnames";
import { urlEventId, pathToBlogArticle } from "../../utils/pathUtils";
import { addTooltip } from "../../utils/templatesBuilderUtils";
import { redirectIfUnauthorized } from "../../utils/aclUtils";
import withTemplatesBuilder from "../withTemplatesBuilder.react";
import BlogArticleForm from "../../components/blog_articles/BlogArticleForm.react";
import SectionsList from "../../components/templates_builder/SectionsList.react";
import SectionsTypesList from "../../components/templates_builder/SectionsTypesList.react";
import SectionView from "../../components/templates_builder/SectionView.react";
import Loader from "../../components/shared/Loader.react";
import NoticeBlock from "../../components/templates_builder/NoticeBlock.react";
import { fetchBlogArticle, createBlogArticle, updateBlogArticle } from "../../actions/BlogArticleActionCreators";
import * as CommonBuilderActionCreators from "../../actions/CommonBuilderActionCreators";
import { fetchWebsiteSectionTypes } from "../../actions/WebsiteActionCreators";
import { fetchSavedSearches } from "../../actions/SavedSearchActionCreators";
import { BlogArticle } from "../../types/BlogArticle";
import { CommonBuilderProps, BlogArticleBuilderMode } from "../../types/Builder";

interface Props extends CommonBuilderProps {
  mode: BlogArticleBuilderMode;
  blogArticleId: string;
  blogArticle: BlogArticle;
  errors: any;
  isFetching: boolean;
  isSaving: boolean;

  fetchBlogArticle: (eventId: string, blogArticleId: string) => any;
  createBlogArticle: (eventId: string, blogArticleAttributes: any, sendFile?: boolean, notificationOptions?: any) => any;
  updateBlogArticle: (eventId: string, blogArticleId: string, blogArticleAttributes: any, sendFile?: boolean, notificationsOptions?: any) => any;
}

interface State {
  mode: BlogArticleBuilderMode;
  showBlogArticleFormView: boolean;
  iframeLoading: boolean;
}

function i18n(key, opts = {}): string {
  return I18n.t(`react.blog_articles.blog_article_builder.${key}`, opts);
}

class BlogArticleBuilder extends Component<Props, State> {
  private previewIframe: HTMLIFrameElement;

  constructor(props: Props) {
    redirectIfUnauthorized("configuration", "manage");
    super(props);
    [
      "toggleBlogArticleForm",
      "createOrUpdateBlogArticle",
      "updateSectionsOrder",
      "createNewSection",
      "removeSection",
      "receiveNewChanges",
      "receiveSectionChanges",
      "removeBlock",
      "saveBlogArticleChanges",
      "removeLoader"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      mode: props.mode,
      showBlogArticleFormView: props.mode === "new",
      iframeLoading: true
    };
  }

  componentDidMount(): void {
    const { fetchBlogArticle, mode, sectionTypes, fetchWebsiteSectionTypes, pages, fetchWebsitePages,
      segments, fetchSavedSearches } = this.props;

    if (mode === "edit") {
      fetchBlogArticle(urlEventId(), this.blogArticleId());
    }
    if (sectionTypes.length === 0) {
      fetchWebsiteSectionTypes(urlEventId(), "blog_article");
    }
    if (pages.length == 0) {
      fetchWebsitePages(urlEventId());
    }
    if (segments.length == 0) {
      fetchSavedSearches(urlEventId());
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { isSaving, blogArticle, errors, history, location } = this.props;

    if (prevProps.isSaving && !isSaving) {
      // after create
      if (!prevProps.blogArticle && blogArticle) {
        this.setState({ showBlogArticleFormView: false, mode: "edit" });
        history.push({ ...location, pathname: pathToBlogArticle(blogArticle._id) });
      }
      // reload iframe after successful changes
      if (Object.keys(errors).length === 0) {
        this.forceReloadPreview();
      }
    }
  }

  blogArticleId(): string {
    const { blogArticleId, blogArticle } = this.props;
    return blogArticleId || (blogArticle && blogArticle._id);
  }

  toggleBlogArticleForm(): void {
    const { showBlogArticleFormView } = this.state;
    this.props.resetAndChangeParentView({});
    this.setState({ showBlogArticleFormView: !showBlogArticleFormView });
  }

  createOrUpdateBlogArticle(blogArticleAttributes: any, sendFile = true): void {
    const { createBlogArticle, updateBlogArticle } = this.props;
    const { mode } = this.state;

    if (mode === "new") {
      const notificationsOptions = { notice: i18n("article_created_successfully"), noticeType: "success" };
      createBlogArticle(urlEventId(), blogArticleAttributes, sendFile, notificationsOptions);
    } else {
      const notificationsOptions = { notice: i18n("article_updated_successfully"), noticeType: "success" };
      updateBlogArticle(urlEventId(), this.blogArticleId(), blogArticleAttributes, sendFile, notificationsOptions);
    }
  }

  saveBlogArticleChanges(): void {
    const { globalChanges, sectionsToRemove, blocksToRemove } = this.props;
    const attributes = {
      remove_sections: sectionsToRemove,
      remove_blocks: blocksToRemove,
      settings_data: globalChanges
    };
    this.createOrUpdateBlogArticle(attributes, false);
  }

  updateSectionsOrder(orderedSections: any): any {
    const { updateSectionsOrder } = this.props;
    updateSectionsOrder(orderedSections);
  }

  createNewSection(sectionId: string, sectionData: any): any {
    const { createNewSection } = this.props;
    createNewSection(sectionId, sectionData);
  }

  removeSection(): void {
    const { removeSection, toggleSectionView, sectionViewForKey } = this.props;
    toggleSectionView();
    removeSection(sectionViewForKey);
  }

  receiveNewChanges(): void {
    const { sendChangesNotification, hasChanges } = this.props;

    if (!hasChanges) {
      sendChangesNotification();
    }
  }

  receiveSectionChanges(key: string, newConfig: any, changes: any): void {
    const { updateSection } = this.props;
    updateSection(key, newConfig, changes);
  }

  removeBlock(blockKey: string, sectionKey: string): void {
    const { removeBlock } = this.props;
    removeBlock(blockKey, sectionKey);
  }

  forceReloadPreview(): void {
    if (!this.previewIframe) {
      return;
    }
    const rand = Math.floor((Math.random() * 1000000));
    this.previewIframe.src += "&uid=" + rand;
    this.setState({ iframeLoading: true });
  }

  removeLoader(): void {
    this.setState({ iframeLoading: false });
  }

  previewURL(): string {
    return `${window.location.origin}/events/${urlEventId()}/blog_articles/${this.blogArticleId()}/preview?uid=0`;
  }

  withSidebar(): boolean {
    const { event } = this.props;
    if (!this.blogArticleId()) return false;

    return event && event.available_frontend_locales && event.available_frontend_locales.length > 0;
  }

  renderBlogArticleForm(): JSX.Element {
    const { blogArticle, errors, isFetching, isSaving, event } = this.props;
    const { showBlogArticleFormView, mode } = this.state;
    const formProps = {
      event: event,
      shown: showBlogArticleFormView,
      blogArticle: mode === "edit" ? blogArticle : null,
      errors,
      isFetching,
      isSaving,
      withSidebar: this.withSidebar(),
      hide: this.toggleBlogArticleForm,
      onSubmit: this.createOrUpdateBlogArticle
    };

    return <BlogArticleForm {...formProps} />;
  }

  renderSectionsConfig(): JSX.Element {
    const { sectionViewForKey } = this.props;

    if (!sectionViewForKey) {
      return <SectionView />;
    }

    const { blogArticle, sections, sectionTypes, createNewBlock, orderedSections, event,
      toggleSectionView, highlightBlockInIframe, liquidTemplatePendingRequest,
      updateLiquidTemplate, createLiquidTemplate, liquidTemplates, liquidTemplateErrors, clearErrors,
      pages, segments, assets, hasChanges } = this.props;
    const sectionLocalConfiguration = sections[sectionViewForKey];
    const sectionTypeKey = sectionLocalConfiguration["type"];
    const sectionType = sectionTypes.find(s => s.filename == sectionTypeKey);

    return (
      <SectionView sectionKey={sectionViewForKey}
        schema={sectionType.schema}
        configuration={sectionLocalConfiguration}
        hide={toggleSectionView}
        detectChanges={this.receiveNewChanges}
        sendChanges={this.receiveSectionChanges}
        addBlock={createNewBlock}
        removeBlock={this.removeBlock}
        highlightBlockInIframe={highlightBlockInIframe}
        deletable={indexOf(orderedSections, sectionViewForKey) != -1}
        removeSection={this.removeSection}
        guestCategories={blogArticle.event_guest_categories}
        guestCategoriesByPopulation={blogArticle.event_guest_categories_by_population}
        documentNames={blogArticle.document_templates_names}
        accesspointsTraits={blogArticle.accesspoints_traits}
        websitePages={pages}
        guestFields={[]}
        segments={segments}
        updateLiquidTemplate={updateLiquidTemplate}
        createLiquidTemplate={createLiquidTemplate}
        liquidTemplates={liquidTemplates}
        liquidTemplateErrors={liquidTemplateErrors}
        liquidTemplatePendingRequest={liquidTemplatePendingRequest}
        accountId={event.account_id}
        builderType="blog_article"
        clearErrors={clearErrors}
        templateId={blogArticle._id}
        saveBuilder={this.saveBlogArticleChanges}
        event={event}
        assets={assets}
        hasChanges={hasChanges}
        withSidebar={this.withSidebar()} />
    );
  }

  render(): JSX.Element {
    const { sidebarMenuClasses, displayTranslationsPane, translationsPaneShown, notice, noticeType, blogArticle, event,
      sectionTypes, sections, orderedSections, toggleSectionView, showSectionsList, sectionsListShown, hideSectionsList,
      hasChanges } = this.props;
    const { iframeLoading, mode } = this.state;
    const sidebarClasses = classNames({
      "website-builder-sidebar": true,
      "col-lg-3 col-md-4 col-sm-5 col-6": mode === "edit",
      "col-12 no-preview": mode === "new"
    });

    return (
      <div className="row website-builder-row">
        <div className={sidebarClasses}>
          { this.withSidebar() &&
            <div className="sidebar-menu">
              <ul className="list-group">
                <OverlayTrigger placement="right" overlay={addTooltip(I18n.t("react.registration_form.manage_translations"))}>
                  <li className={sidebarMenuClasses("translations")} onClick={ (e: any): (state: any, menuKey?: string) => void => displayTranslationsPane(e, this.blogArticleId()) }><i className="fa-regular fa-language"></i></li>
                </OverlayTrigger>
              </ul>
            </div>
          }

          <div className={`sidebar-page-sections ${this.withSidebar() ? "" : "no-sidebar"}`}>
            <div className="sidebar-header">
              <OverlayTrigger placement="bottom" overlay={addTooltip(i18n("edit_blog_article"))}>
                <a onClick={ this.toggleBlogArticleForm } className="btn btn-secondary btn-sm float-end mr-5"><i className="fa-regular fa-gear"></i></a>
              </OverlayTrigger>
              <p className="lead">{ blogArticle ? blogArticle.title : "" }</p>
            </div>

            <div className="sidebar-body">
              { blogArticle &&
                <SectionsList layoutStaticSections={["content"]}
                  templateStaticSections={[]}
                  dynamicSectionsNames={orderedSections}
                  sectionTypes={sectionTypes}
                  sectionsConfiguration={sections}
                  showEditSection={toggleSectionView}
                  showSectionsList={showSectionsList}
                  updateSectionsOrder={this.updateSectionsOrder} />
              }
            </div>
          </div>

          <div className="sidebar-footer">
            <Button variant="primary" disabled={!hasChanges} onClick={ this.saveBlogArticleChanges }>{ i18n("save") }</Button>
          </div>

          { this.renderBlogArticleForm() }
          { translationsPaneShown || <NoticeBlock notice={notice} noticeType={noticeType} /> }

          { blogArticle && sectionTypes &&
            <SectionsTypesList layoutStaticSections={["content"]}
              sectionsGroups={blogArticle.sections_groups}
              sectionTypes={sectionTypes}
              sections={sections}
              isShown={sectionsListShown}
              hideComponent={hideSectionsList}
              createNewSection={this.createNewSection}
              showEditSection={toggleSectionView}
              event={event}
              withSidebar={this.withSidebar()}
            />
          }

          { blogArticle && this.renderSectionsConfig() }
        </div>

        { mode === "edit" &&
          <div className="col-lg-9 col-md-8 col-sm-7 col-6 website-builder-preview">
            { iframeLoading ?
              <Loader size="large" inline={false} withOverlay={true} message={I18n.t("react.loader.preview")} />
              : null }
            <iframe src={this.previewURL()} frameBorder="0" id="template-preview-iframe" ref={(ref): any => this.previewIframe = ref} onLoad={this.removeLoader} />
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state: any): any {
  return {
    blogArticle: state.blogArticle.data,
    errors: state.blogArticle.errors,
    isFetching: state.blogArticle.isFetching,
    isSaving: state.blogArticle.isSaving,
    sections: state.builder.sections,
    orderedSections: state.builder.orderedSections,
    hasChanges: state.builder.hasChanges,
    globalChanges: state.builder.globalChanges,
    sectionsToRemove: state.builder.sectionsToRemove,
    blocksToRemove: state.builder.blocksToRemove,
    sectionTypes: state.sectionTypes.items,
    pages: state.websitePages.items,
    segments: state.savedSearches.data,
    assets: state.assetsManager.assets
  };
}

const mapDispatchToProps = {
  ...CommonBuilderActionCreators,
  fetchWebsiteSectionTypes,
  fetchSavedSearches,
  fetchBlogArticle,
  createBlogArticle,
  updateBlogArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTemplatesBuilder(BlogArticleBuilder, "blog_article"));
