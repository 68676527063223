import React, { FC } from "react";
import { Link } from "react-router-dom";
import { pathToIntegrationsList } from "../../utils/pathUtils";
import IntegrationsData from "../../../config/integration_set.yml";
import { IMAGE_URLS } from "../../constants/ImageUrls";
import { UI_COLORS } from "../../../js/constants/Constants.js";
import TextWithLineBreaks from "../shared/TextWithLineBreaks.react";

const i18n = (key: string): string => {
  return I18n.t(`react.integrations.${key}`);
};

interface Props {
  integrationKey: string;
}

const IntegrationHeader: FC<Props> = ({ integrationKey }) => {
  const integration = IntegrationsData.integrations[integrationKey];

  const renderDocumentationButton = ():JSX.Element => {
    const documentationUrl = i18n(`documentation.${integrationKey}`);
    if (documentationUrl.length === 0) return null;

    return <a href={documentationUrl} target="_blank" className="btn btn-secondary d-flex align-items-center">
      {i18n("documentation_btn_title")} <i className="fa-regular fa-external-link ml-5" style={{ fontSize: "14px" }}></i>
    </a>;
  };

  return (
    <div className="col-md-8 offset-md-2">
      <div className="header-page mb-3 py-3">
        <div className="header-page-title">
          <h1>
            <Link to={pathToIntegrationsList()}>
              <i className="fa-regular fa-chevron-left fa-fw fa-xs"></i>
            </Link>
            {i18n("integrations")}
          </h1>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center">
          <div className="card mb-0 integration-icon" style={{ backgroundImage: `url(${IMAGE_URLS[integrationKey]})` }}></div>
          <div className="d-flex flex-column pl-5 ml-5">
            <h4 className="m-0">{integration.name}</h4>
            <span style={{ color: UI_COLORS.grey600 }}>{i18n(integration.group)}</span>
          </div>
        </div>
        {renderDocumentationButton()}
      </div>

      <div className="mb-4 card" style={{ overflow: "hidden" }}>
        <div className="card-body">
          <h2>{i18n("overview")}</h2>
          <p><TextWithLineBreaks text={i18n(`description.${integrationKey}`)} /></p>
        </div>
      </div>
    </div>
  );
};

export default IntegrationHeader;
