import { DEFAULT_GUEST_PRODUCT_COLLECTION } from "./GuestProductCollectionsList.react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";

interface Props {
  defaultCollection: boolean;
}

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`events.edit_advanced.guest_product_collections.${key}`, options);
};

const GuestProductCollectionDefaultFields: React.FC<Props> = ({ defaultCollection }) => {
  const hasThematics = useSelector((state: any) => state.event.has_thematics);
  const defaultCollectionFields = (): any[] => {
    const fields = DEFAULT_GUEST_PRODUCT_COLLECTION.collection_fields;
    const allFields = hasThematics
      ? fields
      : fields.filter(field => field.name !== i18n("default_fields.thematics"));


    return defaultCollection ? allFields : allFields.filter(field => field.isDefault);
  };

  const tooltip = <Tooltip>{ i18n("default_tooltip_msg") }</Tooltip>;

  return <>
    {defaultCollectionFields().map(field => {
      return <div key={field.name} className="list-group-item item-default">
        <span className="title-item">{field.name} <i className="form-text">({ i18n("default") })</i></span>
        <OverlayTrigger placement="top" overlay={tooltip}>
          <button className="btn btn-link float-end">
            <i className="fa-regular fa-circle-info"></i>
          </button>
        </OverlayTrigger>
      </div>;
    })}
  </>;
};

export default GuestProductCollectionDefaultFields;
