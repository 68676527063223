import { ActionTypes } from "../constants/Constants";

const initialState = {
  items: null,
  errors: null
};

function cartItemsStatistics(state = initialState, action) {
  switch (action.type) {
  case ActionTypes.CART_ITEMS_STATISTICS_SUCCESS:
    return { ...state, items: action.data };
  case ActionTypes.CART_ITEMS_STATISTICS_ERROR:
    return { ...state, errors: action.data.error };
  case ActionTypes.CART_ITEMS_STATISTICS_CLEAR:
    return {};
  default:
    return state;
  }
}

export default cartItemsStatistics;
