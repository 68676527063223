import { Component, ChangeEvent } from "react";
import moment from "moment";
import DateTimePicker from "../shared/DateTimePicker.react";
import { CURRENT_TIME_KEY } from "./ConditionalDisplayOption.react";
import ContextSelector from "../../containers/automation/ContextSelector.react";
import { urlEventLocale } from "../../utils/pathUtils";
import { WorkflowStep } from "../../types/Workflow";
import { AVAILABLE_OPERATORS_FOR_FORM_BUILDER, AVAILABLE_OPERATORS_FOR_AUTOMATION } from "../../constants/Constants";

const CURRENT_TIME_FORMAT = "YYYY-MM-DD HH:mm";
const OPERATIONS_WITHOUT_VALUE = ["boolean_true", "boolean_false", "misc_exists", "misc_doesnotexist"];

interface Props {
  condition: any;
  orIndex: number;
  andIndex: number;
  options: JSX.Element[];
  isDisabled: boolean;
  context: string;
  workflowStep?: WorkflowStep;
  onChangeCondition(key: string, orIndex: number, andIndex: number, value: string): void;
  onChangeTextField(orIndex: number, andIndex: number, value: string): void;
  onRemoveAndCondition(orIndex: number, andIndex: number): void;
}

class ConditionalDisplayRow extends Component<Props> {

  constructor(props: Props) {
    super(props);
    [
      "onChangeCondition",
      "onChangeDate",
      "onChangeHours",
      "onChangeMinutes",
      "onChangeTextField",
      "onRemoveCondition"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  onChangeCondition(key: string): ((e: ChangeEvent<any>) => void) {
    return (e: ChangeEvent<any>): void => {
      const { onChangeCondition, orIndex, andIndex } = this.props;
      return onChangeCondition(key, orIndex, andIndex, e.target.value);
    };
  }

  onChangeContextSelector(key: string): (value: any) => void {
    return (value: any): void => {
      const { onChangeCondition, orIndex, andIndex } = this.props;
      return onChangeCondition(key, orIndex, andIndex, value);
    };
  }

  onChangeTextField(e: ChangeEvent<any>): void {
    const { onChangeTextField, orIndex, andIndex } = this.props;
    return onChangeTextField(orIndex, andIndex, e.target.value);
  }

  onRemoveCondition(): void {
    const { onRemoveAndCondition, orIndex, andIndex } = this.props;
    return onRemoveAndCondition(orIndex, andIndex);
  }

  onChangeDate(condition: any): any {
    const { onChangeCondition, orIndex, andIndex } = this.props;

    return (date: any) => {
      const newTime = moment(date);
      const oldTime = moment(condition.value || moment());
      newTime.hours(oldTime.hours()).minutes(oldTime.minutes());
      onChangeCondition("value", orIndex, andIndex, newTime.format(CURRENT_TIME_FORMAT));
    };
  }

  onChangeHours(condition: any): any {
    const { onChangeCondition, orIndex, andIndex } = this.props;

    return (e: ChangeEvent<any>) => {
      const newTime = moment(condition.value || moment()).hours(e.target.value);
      onChangeCondition("value", orIndex, andIndex, newTime.format(CURRENT_TIME_FORMAT));
    };
  }

  onChangeMinutes(condition: any): any {
    const { onChangeCondition, orIndex, andIndex } = this.props;

    return (e: ChangeEvent<any>) => {
      const newTime = moment(condition.value || moment()).minutes(e.target.value);
      onChangeCondition("value", orIndex, andIndex, newTime.format(CURRENT_TIME_FORMAT));
    };
  }

  availableOperators(): any {
    const { context } = this.props;

    if (context === "automation") {
      return AVAILABLE_OPERATORS_FOR_AUTOMATION;
    }
    return AVAILABLE_OPERATORS_FOR_FORM_BUILDER;
  }

  renderConditionField(): JSX.Element {
    const { condition, options, context, workflowStep, isDisabled } = this.props;

    if (context === "automation") {
      return <ContextSelector
        settingValue={condition.field}
        workflowStep={workflowStep}
        creatable={false}
        isDisabled={isDisabled}
        onChange={this.onChangeContextSelector("field")}
      />;
    }

    return <select className="form-select" value={condition.field} onChange={this.onChangeCondition("field")}>
      {options}
    </select>;
  }

  renderConditionValue(): JSX.Element {
    const { condition, context, workflowStep, isDisabled } = this.props;

    if (context === "automation") {
      if (OPERATIONS_WITHOUT_VALUE.includes(condition.operation)) return null;

      return <ContextSelector
        settingValue={condition.value}
        workflowStep={workflowStep}
        creatable={true}
        isDisabled={isDisabled}
        onChange={this.onChangeContextSelector("value")}
      />;
    } else if (condition.field === CURRENT_TIME_KEY) {
      const time = moment.utc(condition.value || moment());
      return <DateTimePicker
        title={ time.format(CURRENT_TIME_FORMAT) }
        selectedDate={time}
        onChangeDate={this.onChangeDate(condition)}
        onChangeHour={this.onChangeHours(condition)}
        onChangeMinute={this.onChangeMinutes(condition)}
        locale={urlEventLocale()}
        calendarPlacement="top"
        extraClass="col-12"
      />;
    } else {
      return <input type="text" className="form-control" value={condition.value} onChange={this.onChangeTextField} onBlur={this.onChangeCondition("value")} />;
    }
  }

  renderConditionOperationSelect(): JSX.Element {
    const { condition, isDisabled } = this.props;

    if (isDisabled) {
      return <input type="text" className="form-control" value={I18n.t(`react.conditional_operators.${this.availableOperators()[condition.operation].translationKey}`)} disabled={isDisabled} />;
    }

    const options = Object.entries<any>(this.availableOperators()).map(([operator, operatorMetadata], index) => {
      return <option value={operator} key={index}> { I18n.t(`react.conditional_operators.${operatorMetadata.translationKey}`) } </option>;
    });

    return <select className="form-select" value={condition.operation} onChange={this.onChangeCondition("operation")}>
      { options }
    </select>;
  }

  renderRemoveConditionButton(): JSX.Element {
    const { isDisabled } = this.props;

    if (isDisabled) return;

    return <button type="button" className="btn btn-secondary" onClick={this.onRemoveCondition}>
      <i className="fa-regular fa-xmark"></i>
    </button>;
  }

  render(): JSX.Element {
    const { context } = this.props;

    if (context === "automation") {
      return <div className="mb-10 d-flex">
        <div className="flex-grow-1">
          <div className="mb-1">
            { this.renderConditionField() }
          </div>
          <div className="mb-1">
            { this.renderConditionOperationSelect() }
          </div>
          <div className="mb-1">
            { this.renderConditionValue() }
          </div>
        </div>
        <div className="ms-1">
          { this.renderRemoveConditionButton() }
        </div>
      </div>;
    }

    return (
      <div className="row mb-10">
        <div className="col-3">
          { this.renderConditionField() }
        </div>
        <div className="col-2">
          { this.renderConditionOperationSelect() }
        </div>
        <div className="col-3">
          { this.renderConditionValue() }
        </div>
        <div className="col-1">
          { this.renderRemoveConditionButton() }
        </div>
      </div>
    );
  }
}

export default ConditionalDisplayRow;
