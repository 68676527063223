import { CALL_API, ActionTypes } from "../constants/Constants";

export function fetchGuestProductCollections(eventId: string): any {
  const { FETCH_GUEST_PRODUCT_COLLECTIONS_REQUEST, FETCH_GUEST_PRODUCT_COLLECTIONS_SUCCESS, DEV_NULL } = ActionTypes;

  const action = {
    [CALL_API]: {
      types: [FETCH_GUEST_PRODUCT_COLLECTIONS_REQUEST, FETCH_GUEST_PRODUCT_COLLECTIONS_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/guest_product_collections.json`,
    }
  };

  return action;
}
