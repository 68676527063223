import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AtlasChart from "../../components/AtlasChart.react";
import HeaderStats from "../../components/dashboard/HeaderStats.react";
import DashboardNavTab from "../../components/dashboard/DashboardNavTab.react";
import { anyEnabled } from "../../utils/featureSetUtils";
import { STREAMING, WEBINAR, VIDEO_CONFERENCE } from "../../constants/FeaturesSet";
import withDatabaseDefaultRegionRestriction from "../../components/shared/WithDatabaseDefaultRegionRestriction.react";

const SESSION_TYPES_CHART_ID = "635293a8-e6f4-4b93-876e-475aa0f6dc87";
const VIRTUAL_PHYSICAL_CHART_ID = "d0563e12-47e7-4496-8e98-b5c3ac192077";
const LIVE_SESSION_TYPES_CHART_ID = "d16af66d-5e03-4c47-97ea-9f2dffaf3936";
const LIVE_REPLAY_CHART_ID = "635aa339-202e-4727-85a5-5418d8bf8305";

const i18n = (key: string, options = {}): string => {
  return I18n.t(`react.dashboard.sessions.${key}`, options);
};

const SessionsDashboard: React.FC = () => {
  const event = useSelector((state: any) => state.event);

  const [sessionsCount, setSessionsCount] = useState(null);
  const [speakersCount, setSpeakersCount] = useState(null);
  const [sessionsMessagesCount, setSessionsMessagesCount] = useState(null);
  useEffect(() => {
    if (!event._id) return;

    fetch(`/api/v1/events/${event._id}/accesspoints/sessions_kpis.json`)
      .then(res => res.json())
      .then(result => {
        setSessionsCount(result.total);
        setSpeakersCount(result.speakers_count);
        setSessionsMessagesCount(result.messages_count);

      });
  }, [event._id]);

  const renderHeader = (): JSX.Element => {
    return <HeaderStats
      dataFetched={true}
      headers={
        [
          { i18nKey: "sessions_count", value: sessionsCount, nbCols: 4 },
          { i18nKey: "speakers_count", value: speakersCount, nbCols: 4 },
          { i18nKey: "session_messages_count", value: sessionsMessagesCount, nbCols: 4 }
        ]
      }
      tabKey="sessions"
    />;
  };

  const renderSessionTypesChart = (): JSX.Element => {
    return <div className="card">
      <AtlasChart
        eventId={event._id}
        title={i18n("session_types")}
        chartId={ SESSION_TYPES_CHART_ID }
        height="400px"
      />
    </div>;
  };

  const renderVirtualPhysicalRepartitionChart = (): JSX.Element => {
    return <div className="card">
      <AtlasChart
        eventId={event._id}
        title={i18n("virtual_or_physical")}
        chartId={ VIRTUAL_PHYSICAL_CHART_ID }
        height="400px"
      />
    </div>;
  };

  const renderLiveSessionTypesChartId = (): JSX.Element => {
    return <div className="card">
      <AtlasChart
        eventId={event._id}
        title={i18n("live_session_types")}
        chartId={ LIVE_SESSION_TYPES_CHART_ID }
        height="400px"
      />
    </div>;
  };

  const renderLiveReplayChart = (): JSX.Element => {
    return <div className="card">
      <AtlasChart
        eventId={event._id}
        title={i18n("live_replay")}
        chartId={ LIVE_REPLAY_CHART_ID }
        height="400px"
      />
    </div>;
  };

  const renderLiveRelatedCharts = (): JSX.Element => {
    if (!anyEnabled([STREAMING, WEBINAR, VIDEO_CONFERENCE])) return null;

    return <div className="row">
      <div className="col-md-4">
        { renderVirtualPhysicalRepartitionChart() }
      </div>
      <div className="col-md-4">
        { renderLiveSessionTypesChartId() }
      </div>
      <div className="col-md-4">
        { renderLiveReplayChart() }
      </div>
    </div>;
  };

  if (!event._id) return null;

  return <div>
    <DashboardNavTab active="sessions" event_has_exits={event.has_exits} />
    { renderHeader() }
    { renderSessionTypesChart() }
    { renderLiveRelatedCharts() }
  </div>;
};

export default withDatabaseDefaultRegionRestriction(SessionsDashboard);
