import { Component } from "react";

class NoticeBlock extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { notice, noticeType } = this.props;
    let blockClasses = "builder-notice";
    let messageClasses = "notice-block";

    if (notice && notice.length > 0) {
      blockClasses += " in";
      messageClasses += ` ${noticeType}-block`;
    }

    return (
      <div className={ blockClasses }>
        <p className={ messageClasses }>{ notice }</p>
      </div>
    );
  }
}

export default NoticeBlock;
