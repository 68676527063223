import { ActionTypes } from "../constants/Constants";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";

function registrationFormSections(state = [], action) {
  switch (action.type) {
  case ActionTypes.REGISTRATION_FORM_SUCCESS: {
    const { formSections } = action.data.entities;
    return Object.keys(formSections || []).map(key => {
      return formSections[key];
    });
  }
  case ActionTypes.REGISTRATION_FORM_STEP_CREATE_SUCCESS: {
    const { formSections } = action.data.entities;
    return insertOrUpdateObject(state, formSections[Object.keys(formSections)[0]], "_id");
  }
  case ActionTypes.REGISTRATION_FORM_ITEM_NEW:
  case ActionTypes.REGISTRATION_FORM_ITEM_CREATE_SUCCESS: {
    const formItem = action.formItem || action.data;
    const section = Object.assign({}, state.find(section => { return section._id === formItem.form_section_id; }));
    section.form_items.push(formItem._id);
    const newState = insertOrUpdateObject(state, section, "_id");
    if (action.commitForTemporaryId) {
      return newState.filter(section => { return section._id !== action.commitForTemporaryId; });
    }
    return newState;
  }
  default:
    return state;
  }
}

export default registrationFormSections;
