import { translatedTextFromJson } from "../../utils/templatesBuilderUtils";
import { SegmentPicker } from "../../containers/shared/SegmentPicker.react";
import { SavedSearch } from "../../types/SavedSearch";

interface Props {
  componentConfig: any;
  value: any;
  segments: SavedSearch[];
  sectionLocked: boolean;
  blockKey?: string;

  notifyChange(key: any, value: any, blockItemKey?: any): void;
}

const SegmentsPicker: React.FC<Props> = ({
  componentConfig,
  value,
  segments,
  sectionLocked,
  blockKey,
  notifyChange
}) => {
  const onSelectedSegmentChange = (key: string): (segment: SavedSearch) => void => {
    return (segment: SavedSearch) => {
      notifyChange(key, [{ segment_id: segment?._id }], blockKey);
    };
  };

  const helpMessage = (): JSX.Element => {
    if (!componentConfig.info) return null;

    return <div dangerouslySetInnerHTML={{ __html: translatedTextFromJson(componentConfig, "info") }} className="form-text" />;
  };

  const renderSegmentsPicker = (): JSX.Element => {
    // Do not display old section visibility fields
    if (componentConfig.id == "segments" && !componentConfig.backoffice_option) return null;

    const locked = sectionLocked && componentConfig.lockable;

    return <div className="mb-3" key={ componentConfig.id }>
      <label className="form-label">{ translatedTextFromJson(componentConfig, "label") }</label>
      <div>
        <SegmentPicker
          disabled={locked}
          onSelectSegment={ onSelectedSegmentChange(componentConfig.id) }
          defaultSegmentId={value && value[0]?.segment_id}
          segments={segments}
        />
      </div>
      { helpMessage() }
    </div>;
  };

  return renderSegmentsPicker();
};

export default SegmentsPicker;
