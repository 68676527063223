import { ActionTypes } from "../constants/Constants";

function websitePages(state = { items: [], errors: {}, lastCreatedPageId: null }, action) {
  let pages;
  switch (action.type) {
  case ActionTypes.RECEIVE_WEBSITE_PAGES_SUCCESS: {
    return { items: action.data || [], errors: {} };
  }
  case ActionTypes.WEBSITE_PAGE_FAILURE: {
    const items = state.items.slice();
    const errors = Object.assign({}, action.data);
    return { items: items, errors: errors };
  }
  case ActionTypes.CREATE_WEBSITE_PAGE_SUCCESS:
  case ActionTypes.DUPLICATE_WEBSITE_PAGE_SUCCESS: {
    pages = state.items.slice();
    let newPageData = { ...action.data };
    delete newPageData.settings_data;
    pages.push(newPageData);
    return { items: pages, errors: {}, lastCreatedPageId: action.data._id };
  }
  case ActionTypes.UPDATE_WEBSITE_PAGE_SUCCESS: {
    const { data, pageId } = action;
    const newPagesAfterUpdate = state.items.map(page => {
      if (page._id == pageId) {
        return Object.assign({}, data);
      }
      return page;
    });
    return { items: newPagesAfterUpdate, errors: {} };
  }
  case ActionTypes.RECEIVE_WEBSITE_PAGES_FAILURE: {
    return state;
  }
  case ActionTypes.DESTROY_WEBSITE_PAGE_SUCCESS: {
    const { pageId } = action;
    const newPagesAfterDestroy = state.items.filter(page => {
      return page._id != pageId;
    });
    return { items: newPagesAfterDestroy, errors: {} };
  }
  default:
    return state;
  }
}

export default websitePages;
