import { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import TCFPurposes from "../../../../config/tcf_purposes.yml";
import { Purpose } from "../../../types/Purpose";

interface Props {
  readonly?: boolean;
  customPurposes?: Purpose[];
  selectedPurposeIds: string[];
  onChange?: (purposeIds: string[]) => void;
}

const PurposesPicker: React.FC<Props> = ({
  readonly,
  customPurposes,
  selectedPurposeIds,
  onChange
}) => {

  const propertyValue = (purpose: Purpose, key: string): string => {
    if (!purpose[key]) {
      return I18n.t(`purposes.${purpose.id}.${key}`);
    }

    return (typeof purpose[key] === "string") ? purpose[key] : purpose[key][I18n.locale];
  };

  const purposeTooltip = (purpose: Purpose): JSX.Element => {
    return (
      <Tooltip id={purpose.id}>
        <p>{propertyValue(purpose, "description")}</p>
      </Tooltip>
    );
  };

  const purposes = useMemo(() => {
    return TCFPurposes.concat(customPurposes || []);
  }, [customPurposes]);

  return <div className="row">
    {purposes.map(purpose => {
      if ((readonly && !selectedPurposeIds.includes(purpose.id)) || !purpose.name) {
        return null;
      }

      return <div className="col-md-6" key={purpose.id}>
        <div className="form-check" style={{ marginTop: "5px", marginBottom: "5px" }}>
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input" checked={selectedPurposeIds.includes(purpose.id)} disabled={readonly} onChange={(e): void => {
              const nextPurposeIds = e.target.checked ? (
                [...selectedPurposeIds, purpose.id]
              ) : (
                selectedPurposeIds.filter(id => id !== purpose.id)
              );
              onChange(nextPurposeIds);
            }}/>
            {propertyValue(purpose, "name")}
            {purpose.description && (
              <OverlayTrigger placement="top" overlay={purposeTooltip(purpose)}>
                <i style={{ marginLeft: "5px" }} className="fa-regular fa-info-circle" aria-hidden="true"></i>
              </OverlayTrigger>
            )}
          </label>
        </div>
      </div>;
    })}
  </div>;
};

export default PurposesPicker;
