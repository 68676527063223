import Select, { components } from "react-select";
import { multiValueRemoveStyle } from "../../constants/Style";
import { FilterOption } from "../../types/Program";

const SELECTED_OPTIONS_BG_COLOR = "#777777";

interface Props {
  options: FilterOption[];
  values: FilterOption[];
  placeholder: string;
  styles?: any;

  onChange(options: FilterOption[]): void;
}

const SelectMultiValueContainer = (props: any): JSX.Element => {
  const innerProps = { ...props.innerProps, className: props.innerProps.className.concat(" badge rounded-pill") };
  const newProps = { ...props, innerProps };
  return <components.MultiValueContainer {...newProps} />;
};

const SelectMultiValueLabel = (props: any): JSX.Element => {
  return <span>{props.data.label}</span>;
};

const customStyles = {
  multiValue: (base: any): any => ({
    ...base,
    backgroundColor: SELECTED_OPTIONS_BG_COLOR,
    fontSize: "100%"
  }),
  multiValueRemove: (base: any): any => ({
    ...base,
    ...multiValueRemoveStyle
  })
};

const MultiSelect: React.FC<Props> = ({ options, values, placeholder, styles, onChange }) => {
  const props = {
    className: "react-select",
    classNamePrefix: "react-select",
    isMulti: true,
    closeMenuOnSelect: false,
    options: options,
    onChange: onChange,
    value: values,
    components: {
      MultiValueContainer: SelectMultiValueContainer,
      MultiValueLabel: SelectMultiValueLabel
    },
    styles: { ...customStyles, ...styles },
    hideSelectedOptions: false,
    isClearable: true,
    placeholder
  };

  return <Select {...props} />;
};

export default MultiSelect;
