import { Component } from "react";
import classNames from "classnames";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import { OverlayTrigger } from "react-bootstrap";
import { OFFLINE_COLOR } from "../../constants/Constants";
import { addTooltip, translatedTextFromJson } from "../../utils/templatesBuilderUtils";
import { searchFromSearchableKeywords } from "../../utils/searchUtils.js";
import { WebsitePage } from "../../types/WebsitePage";

interface Props {
  isShown: boolean;
  toggleView(): void;
  duplicatePageSection(pageSectionKey: string, page: WebsitePage): void;
  pages: WebsitePage[];
  currentPage: WebsitePage;
  pageSectionKey: string;
  sectionTypes: any;
  sectionsConfiguration: any;
  pendingDuplicationInPageId: string;
}

interface State {
  search: string;
}

class PageSectionDuplicationView extends Component<Props, State> {
  constructor(props) {
    super(props);
    [
      "close",
      "onPageClickHandler",
      "setSearch"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = { search: "" };
  }

  close(): void {
    this.props.toggleView();
  }

  onPageClickHandler(page: WebsitePage): () => void {
    const { duplicatePageSection, pageSectionKey, pendingDuplicationInPageId } = this.props;

    return (): void => {
      if (pendingDuplicationInPageId) return; // duplicate one page section at a time

      if (confirm(I18n.t("confirm"))) {
        duplicatePageSection(pageSectionKey, page);
      }
    };
  }

  sectionName(): JSX.Element {
    const { pageSectionKey, sectionTypes, sectionsConfiguration } = this.props;
    const sectionKey = get(sectionsConfiguration, [pageSectionKey, "type"]);
    const section = sectionTypes.find(s => s.filename === sectionKey);

    return translatedTextFromJson(section.schema, "name");
  }

  homepageIcon(page: WebsitePage): JSX.Element {
    if (!page.is_homepage) return;

    return <div className="float-end text-muted ml-10">
      <OverlayTrigger placement="top" overlay={addTooltip(I18n.t("react.website.homepage"))}>
        <i className="fa-regular fa-home"></i>
      </OverlayTrigger>
    </div>;
  }

  pageOfflineIcon(page: WebsitePage): JSX.Element {
    if (page.online) return;

    return <div className="float-end ml-10">
      <OverlayTrigger placement="top" overlay={addTooltip(I18n.t("react.website.page_offline"))}>
        <i className="fa-solid fa-circle" style={{ color: OFFLINE_COLOR }}></i>
      </OverlayTrigger>
    </div>;
  }

  pageLockedIcon(page: WebsitePage): JSX.Element {
    if (!page.locked) return;

    return <div className="float-end text-muted ml-10">
      <OverlayTrigger placement="top" overlay={addTooltip(I18n.t("react.website.page_locked"))}>
        <i className="fa-regular fa-lock"></i>
      </OverlayTrigger>
    </div>;
  }

  spinnerIcon(page: WebsitePage): JSX.Element {
    const { pendingDuplicationInPageId } = this.props;

    if (pendingDuplicationInPageId !== page._id) return;

    return <div className="float-end text-muted ml-10">
      <i className="fa-regular fa-spinner fa-spin"></i>
    </div>;
  }

  setSearch(e): void {
    this.setState({ search: e.target.value });
  }

  filteredPages(): WebsitePage[] {
    const { pages, currentPage } = this.props;
    const { search } = this.state;

    return sortBy(searchFromSearchableKeywords(pages, search), [(p): WebsitePage[] => p.name]).filter(page => {
      return page.path_name !== currentPage.path_name;
    });
  }

  itemClasses(page: WebsitePage): string {
    const { currentPage } = this.props;

    return classNames({
      "list-group-item": true,
      "active": page.filename === currentPage.filename
    });
  }

  renderPageItem(page: WebsitePage): JSX.Element {
    return <li className={this.itemClasses(page)} onClick={this.onPageClickHandler(page)} key={page.filename}>
      { page.name } { this.homepageIcon(page) } { this.pageLockedIcon(page) } { this.pageOfflineIcon(page) } { this.spinnerIcon(page) }
    </li>;
  }

  render(): JSX.Element {
    const { isShown, currentPage } = this.props;

    if (!isShown) {
      return <div className="website-page-section-duplication"></div>;
    }

    const { search } = this.state;

    return (
      <div className="website-page-section-duplication in">
        <div className="sidebar-header">
          <p className="lead">
            <a onClick={ this.close } className="btn-back mr-5 text-center"><i className="fa-regular fa-angle-left"></i></a>
            { I18n.t("react.website.duplicate_section_name", { name: this.sectionName() }) }
          </p>
        </div>

        <div className="sidebar-body">
          <h5>{ I18n.t("react.website.duplication_section_in_current_page") }</h5>
          <div className="card">
            <ul className="list-group">
              { this.renderPageItem(currentPage) }
            </ul>
          </div>
          <h5>{ I18n.t("react.website.duplication_section_in_another_page") }</h5>
          <div className="mb-3">
            <input type="text" className="form-control" placeholder={I18n.t("react.website.search_page") } value={search} onChange={this.setSearch} />
          </div>
          { this.filteredPages().length > 0 &&
            <div className="card">
              <ul className="list-group">
                { this.filteredPages().map(page => this.renderPageItem(page))}
              </ul>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default PageSectionDuplicationView;
