import { createStore, applyMiddleware } from "redux";

import thunk from "redux-thunk";
import rootReducer from "../reducers";
import notificationsMiddleware from "../middlewares/notificationsMiddleware";
import apiMiddleware from "../middlewares/api";
import multiMiddleware from "../middlewares/multi";

const configureStore = preloadedState => createStore(
  rootReducer,
  preloadedState,
  //applying apiMiddleware twice so we can chain up to 2 API calls
  applyMiddleware(multiMiddleware, thunk, apiMiddleware, apiMiddleware, notificationsMiddleware)
);

export default configureStore;
