import { Purpose } from "../../../types/Purpose";
import PurposeForm from "./PurposeForm.react";

const generatePurposeId = (): string => {
  return `custom-purpose-${(Math.random() + 1).toString(36).substring(7)}`;
};

const i18n = (key: string, opts = {}): string => {
  return I18n.t(`react.consent_notice.purposes_manager.${key}`, opts);
};

interface Props {
  customPurposes: Purpose[];
  onPurposeChange: (purpose: Purpose) => void;
  removePurpose: (purpose: Purpose) => void;
}

const CustomPurposes: React.FC<Props> = ({
  customPurposes,
  onPurposeChange,
  removePurpose
}) => {

  const createEmptyPurpose = (): Purpose => {
    return {
      id: generatePurposeId(),
      name: "",
      description: ""
    };
  };

  return <>
    {customPurposes.map(purpose => {
      return <PurposeForm
        key={purpose.id}
        onChange={onPurposeChange}
        removePurpose={removePurpose}
        purpose={purpose}
      />;
    })}

    <button className="btn btn-secondary" onClick={(e): void => {
      e.preventDefault();
      onPurposeChange(createEmptyPurpose());
    }}><i className="fa-regular fa-plus"></i> {i18n("create_one")}</button>
  </>;
};

export default CustomPurposes;
