import { useState } from "react";
import {
  GuestMeetingAvailability,
  sortAndIndexAvailabilities,
  parseRawAvailabilities,
} from "../../../guest_meeting_availabilities/types/GuestMeetingAvailability";
import { HasMeetingAvailabilitiesType } from "../../../types/HasMeetingAvailabilities";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

interface Props {
  meetingAvailabilities: GuestMeetingAvailability[];
  type: HasMeetingAvailabilitiesType;
}

const i18n = (key: string, opt = {}): string => {
  return I18n.t(`react.networking.meetings.meeting_availabilities_summary.${key}`, opt);
};

const renderHelpOverlay = (help: string, id: string): JSX.Element => {
  return <OverlayTrigger placement="top" overlay={<Tooltip id={id}>{help}</Tooltip>}>
    <i className="fa-regular fa-info-circle"></i>
  </OverlayTrigger>;
};

const DEFAULT_DISPLAYED_ROWS_COUNT = 7;

const MeetingAvailabilitiesSummary: React.FC<Props> = ({
  meetingAvailabilities,
  type
}) => {
  const [showAllAvailabilities, setShowAllAvailabilities] = useState(false);

  const messageWhenNullAvailabilities = (): string => {
    switch (type) {
    case "event":
      return i18n("no_availability");
    case "guest_category":
      return i18n("will_inherit_from_parent", { parent: i18n("event") });
    default:
      return i18n("will_inherit_from_parent", { parent: i18n("guest_category_or_event") });
    }
  };

  if (!meetingAvailabilities) {
    // meeting availabilities are nil (not empty)
    return <p className="mt-10">{messageWhenNullAvailabilities()}</p>;
  }

  if (meetingAvailabilities.length === 0) {
    // no availabilities by default and because not nil, won't inherit defaults
    return <p className="mt-10">{i18n("no_availability")}</p>;
  }


  const sortedMeetingAvailabilities = sortAndIndexAvailabilities(parseRawAvailabilities(meetingAvailabilities));

  const renderShowMoreRow = (): JSX.Element => {
    if (sortedMeetingAvailabilities.length <= DEFAULT_DISPLAYED_ROWS_COUNT) return null;

    return (
      <tr onClick={toggleRows} style={{ cursor: "pointer" }}>
        <td colSpan={6}>
          <a href="#" onClick={toggleRows}>
            {showAllAvailabilities ? (
              <>
                {i18n("show_less")} <i className="fa-regular fa-chevron-up"></i>
              </>
            ) : (
              <>
                {i18n("show_more")} <i className="fa-regular fa-chevron-down"></i>
              </>
            )}
          </a>
        </td>
      </tr>
    );
  };

  const toggleRows = (e: React.MouseEvent): void => {
    e.preventDefault();

    setShowAllAvailabilities(!showAllAvailabilities);
  };

  const availabilitiesToDisplay = showAllAvailabilities ? (
    sortedMeetingAvailabilities
  ) : (
    sortedMeetingAvailabilities.slice(0, DEFAULT_DISPLAYED_ROWS_COUNT)
  );

  return (
    <div className="meeting-availabilities-summary">
      <p><em className="text-muted">{i18n("availability_count", { count: meetingAvailabilities.length })}</em></p>
      <div className="table-responsive table-container">
        <table className="table table-light table-hover">
          <thead style={{ backgroundColor: "transparent" }}>
            <tr>
              <th className="text-center">{i18n("day")}</th>
              <th className="text-center">{i18n("time_range")}</th>
              <th className="text-center">{i18n("slot_duration")} {renderHelpOverlay(i18n("slot_duration_help"), "help-slot-duration")}</th>
              <th className="text-center">{i18n("slot_capacity")} {renderHelpOverlay(i18n("slot_capacity_help"), "help-slot-capacity")}</th>
              <th className="text-center">{i18n("type")}</th>
              <th className="text-center">{i18n("simultaneous_slots_count")} {renderHelpOverlay(i18n("simultaneous_slots_count_help"), "help-simultaneous-slots")}</th>
            </tr>
          </thead>
          <tbody>
            {availabilitiesToDisplay.map((availability, index) => {
              return (
                <tr key={index}>
                  <td><strong>{availability.startDate.format("ddd DD MMMM")}</strong></td>
                  <td className="text-center">{availability.startDate.format("HH:mm")} - {availability.endDate.format("HH:mm")}</td>
                  <td className="text-center">{availability.slotDurationMinutes}min</td>
                  <td className="text-center">{availability.capacityPerSlot === -1 ? "∞" : availability.capacityPerSlot}</td>
                  <td className="text-center">{i18n(availability.type)}</td>
                  <td className="text-center">{availability.simultaneousSlotsCount}</td>
                </tr>
              );
            })}
            {renderShowMoreRow()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MeetingAvailabilitiesSummary;
