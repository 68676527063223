import { Component } from "react";
import { connect } from "react-redux";

import { urlEventId, urlGuestId } from "../utils/pathUtils";

import FilterDropdown from "../components/FilterDropdown.react";

import * as LabelsActionCreators from "../actions/LabelsActionCreators";
import GuestUpdatesActionCreators from "../actions/GuestUpdatesActionCreators";
import GuestListActionCreators from "../actions/GuestListActionCreators";

class GuestLabels extends Component {
  constructor(props) {
    super(props);
    const privateMethods = ["_handleLabelsToGuest", "_displayLabels"];
    privateMethods.forEach((item) => { this[item] = this[item].bind(this); });
    this.state = { displayLabels: null };
  }

  componentDidMount() {
    const { fetchLabels, fetchGuest } = this.props;
    fetchLabels(urlEventId());
    fetchGuest(urlEventId(), urlGuestId());
  }

  componentDidUpdate(prevProps) {
    const { guest } = this.props;
    if (!prevProps.guest && guest && guest.label_ids && guest.label_ids.length > 0) {
      this.setState({ displayLabels: true });
    }
  }

  _handleLabelsToGuest() {
    const { updateGuest } = this.props;
    return (labelIds) => {
      const params = { label_ids: labelIds };
      updateGuest(urlEventId(), urlGuestId(), params);
      if (labelIds.length < 1) {
        this.setState({ displayLabels: null });
      }
    };
  }

  _displayLabels(e) {
    e.preventDefault();
    this.setState({ displayLabels: true });
  }

  render() {
    const { labels, guest } = this.props;
    const { displayLabels } = this.state;

    let labelIds = [];
    if (guest) {
      labelIds = guest.label_ids;
    }

    let content = displayLabels ? (
      <div style={{ display: "inline-block", marginBottom: "2px" }}>
        <FilterDropdown
          id="labels"
          items={labels}
          selectedItemIds={labelIds}
          translationKey="labels"
          onChange={this._handleLabelsToGuest()}
          itemIdKey="_id"
        />
      </div>
    ) : (
      <a href="#" onClick={this._displayLabels}>
        <i className="fa-regular fa-plus-square fa-fw"></i> {I18n.t("react.labels.add_labels")}
      </a>
    );

    if (labels && labels.length < 1) {
      content = null;
    }

    return <div>{ content }</div>;
  }
}

function mapStateToProps(state) {
  return {
    labels: state.labels.items || [],
    guest: state.guests.guests[0]
  };
}

const mapDispatchToProps = Object.assign({}, LabelsActionCreators, GuestUpdatesActionCreators, GuestListActionCreators);
export default connect(mapStateToProps, mapDispatchToProps)(GuestLabels);
