import { Component } from "react";
import { Link } from "react-router-dom";
import { relativePath } from "../../utils/pathUtils";
import { TranslationsParentTypes } from "../../constants/Constants.js";
import { anyEnabled } from "../../utils/featureSetUtils";
import "./style.scss";
import camelCase from "lodash/camelCase";

function i18n(key) {
  return I18n.t(`react.translations.parent_navigation.${key}`);
}

const WEBSITE_GROUP_PREFIXES = ["website", "blog"];
const EMAILS_GROUP_PREFIXES = ["email", "guest_campaigns"];
const REGISTRATION_GROUP_PREFIXES = ["registration", "guest", "document_templates"];
const SCHEDULE_GROUP_PREFIXES = ["program", "session"];

function pathToTranslationType(type) {
  return (relativePath(`translations/${type}`));
}

function groupedParentTypes() {
  return Object.entries(TranslationsParentTypes).reduce((acc, [type, metadata]) => {
    if (!anyEnabled(metadata.requiredFeatures)) return acc;

    if (typeContainPrefix(type, WEBSITE_GROUP_PREFIXES)) acc.website.push(type);
    else if (typeContainPrefix(type, EMAILS_GROUP_PREFIXES)) acc.emails_and_campaigns.push(type);
    else if (typeContainPrefix(type, REGISTRATION_GROUP_PREFIXES) && !["guest_campaigns", "guest_product_collections"].includes(type)) acc.registration.push(type);
    else if (typeContainPrefix(type, SCHEDULE_GROUP_PREFIXES)) acc.schedule.push(type);
    else { acc.single_types.push(type); }
    return acc;
  }, {
    single_types: [],
    website: [],
    emails_and_campaigns: [],
    registration: [],
    schedule: []
  });
}

function typeContainPrefix(type, prefixesArray) {
  return prefixesArray.some(prefix => type.startsWith(prefix));
}

const INITIAL_SATE = {
  websiteGroupOpened: false,
  emailsAndCampaignsGroupOpened: false,
  registrationGroupOpened: false,
  scheduleGroupOpened: false,
};

class TranslationsNavigation extends Component {
  constructor(props) {
    super();

    ["toggleIcon"].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = INITIAL_SATE;

    const { currentType, history } = props;
    if (!currentType) {
      const arrayOfTypes = Object.values(groupedParentTypes());
      if (arrayOfTypes) {
        const defaultCurrentType = arrayOfTypes[0][0];
        history.push({ ...location, pathname: pathToTranslationType(defaultCurrentType) });
      }
    } else {
      if (typeContainPrefix(currentType, WEBSITE_GROUP_PREFIXES)) {
        this.state = { ...INITIAL_SATE, websiteGroupOpened: true };
      } else if (typeContainPrefix(currentType, EMAILS_GROUP_PREFIXES)) {
        this.state = { ...INITIAL_SATE, emailsAndCampaignsGroupOpened: true };
      } else if (typeContainPrefix(currentType, REGISTRATION_GROUP_PREFIXES) && currentType !== "guest_campaigns") {
        this.state = { ...INITIAL_SATE, registrationGroupOpened: true };
      } else if (typeContainPrefix(currentType, SCHEDULE_GROUP_PREFIXES)) {
        this.state = { ...INITIAL_SATE, scheduleGroupOpened: true };
      }
    }
  }

  renderItem(type) {
    const { currentType } = this.props;

    return <li role="presentation" className={`list-group-item list-group-item-action ${currentType === type ? "active" : ""}`} key={type}>
      <Link to={pathToTranslationType(type)}>
        { I18n.t(`react.translations.parent_navigation.${type}`) }
      </Link>
    </li>;
  }

  keyInState(groupKey) {
    return `${camelCase(groupKey)}GroupOpened`;
  }

  toggleIcon(groupKey) {
    const keyInState = this.keyInState(groupKey);
    this.setState({ [keyInState]: !this.state[keyInState] });
  }

  renderSubList(groupKey, subList) {
    const opened = this.state[this.keyInState(groupKey)];
    return <li role="presentation" key={groupKey} className="list-group-item list-group-item-action">
      <a href="#" role="button" data-bs-toggle="collapse" data-bs-target={`#${groupKey}`} aria-expanded="false" aria-controls={groupKey} onClick={() => this.toggleIcon(groupKey)}>
        {i18n(groupKey)} <i className={`fa-regular ${ opened ? "fa-angle-up" : "fa-angle-down" } float-end`}></i>
      </a>
      <div className="collapse" id={groupKey}>
        <ul className="nav">
          {subList.map(type => this.renderItem(type))}
        </ul>
      </div>
    </li>;
  }

  renderNavigationList() {
    return Object.entries(groupedParentTypes()).map(([key, subList]) => {
      if (subList.length === 0) {
        return null;
      } else if (key === "single_types") {
        return subList.map(type => this.renderItem(type));
      } else {
        return this.renderSubList(key, subList);
      }
    });
  }

  render() {
    return <ul className="translation-nav list-group">
      { this.renderNavigationList() }
    </ul>;
  }
}

export default TranslationsNavigation;
