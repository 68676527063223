import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { nextFormStepBehaviour } from "../constants/Constants";

import ConfirmableButton from "../components/shared/ConfirmableButton.react";
import ErrorMessage from "../components/shared/ErrorMessage.react";
import {
  updateRegistrationFormStep,
  cleanRegistrationFormStepErrors,
  destroyRegistrationFormStep
} from "../actions/RegistrationFormStepActionCreators";
import MetadataForm from "../components/shared/MetadataForm.react";

class RegistrationFormStep extends Component {

  constructor(props) {
    super(props);
    [
      "inputOnChange",
      "inputOnBlur",
      "destroyStep",
      "toggleAdvancedOptions",
      "onChangeNextStepBehaviour",
      "onChangeNextStepId",
      "copyToClipboard",
      "updateTraits",
      "updateTraitsState"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    const { step } = props;
    this.state = {
      title: step.title || "",
      submit_label: step.submit_label || "",
      showNextStepIdDropdown: step.next_step_behaviour == "custom",
      showAdvancedOptions: false,
      traits: this.stepTraits(step.traits)
    };
  }

  componentDidUpdate(prevProps) {
    const { step, cleanRegistrationFormStepErrors } = this.props;
    if (step.errors) {
      setTimeout(() => {
        cleanRegistrationFormStepErrors(step._id);
      }, 5000);
    }
    if (prevProps.step !== step) {
      this.setState({
        title: this.props.step.title || "",
        showNextStepIdDropdown: this.props.step.next_step_behaviour == "custom",
        traits: this.stepTraits(step.traits)
      });
    }
  }

  stepTraits(traits) {
    if (Array.isArray(traits)) return traits;

    return Object.entries(traits).map(([key, value]) => {
      return { key, value };
    });
  }

  inputOnChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  inputOnBlur(e) {
    const { step, updateRegistrationFormStep } = this.props;
    const value = this.state[e.target.name];
    if (step[e.target.name] !== value) {
      updateRegistrationFormStep(step.registration_form_id, step._id, { [e.target.name]: value });
    }
  }

  destroyStep(e) {
    e.stopPropagation();
    const { destroyRegistrationFormStep, step } = this.props;
    destroyRegistrationFormStep(step.registration_form_id, step._id);
  }

  toggleAdvancedOptions(e) {
    e.preventDefault();
    const { showAdvancedOptions } = this.state;
    this.setState({ showAdvancedOptions: !showAdvancedOptions });
  }

  onChangeNextStepBehaviour(e) {
    const { step, updateRegistrationFormStep } = this.props;
    const value = e.target.value;
    updateRegistrationFormStep(step.registration_form_id, step._id, { next_step_behaviour: value, custom_next_step_id: null });
  }

  onChangeNextStepId(e) {
    const { step, updateRegistrationFormStep } = this.props;
    const value = e.target.value;
    updateRegistrationFormStep(step.registration_form_id, step._id, { next_step_behaviour: "custom", custom_next_step_id: value });
  }

  renderHeader() {
    const { stepsCount } = this.props;

    return (
      <div className="d-flex">
        <div className="col-8 no-margin">
          { this.renderInput("title", I18n.t("react.registration_form.step_form_title")) }
        </div>
        <div className="col-4 d-flex flex-column justify-content-end ml-10">
          {stepsCount > 1 &&
            <ConfirmableButton
              btnType="secondary"
              confirmBtnType="link"
              cancelBtnType="link"
              faIcon="trash-can"
              onConfirm={this.destroyStep}
              tooltipPlacement="left"
              tooltipMessage={I18n.t("react.registration_form.remove_step_tooltip")}
            />}
        </div>
      </div>
    );
  }

  renderAdvancedOptionsTitle() {
    const { showAdvancedOptions } = this.state;

    return (
      <a href="#" onClick={this.toggleAdvancedOptions}>
        { I18n.t("react.registration_form.advanced_options") } <i className={`fa-regular fa-caret-right-${ showAdvancedOptions ? "up" : "down" }`}></i>
      </a>
    );
  }

  updateTraitsState(traits) {
    this.setState({ traits });
  }

  updateTraits() {
    const { step, updateRegistrationFormStep } = this.props;
    const { traits } = this.state;
    updateRegistrationFormStep(step.registration_form_id, step._id, { traits: traits });
  }

  renderMetadata() {
    const { traits } = this.state;

    return <div className="mb-3">
      {this.renderInputLabel(I18n.t("metadata"), "meta-key")}
      <MetadataForm traits={traits} updateParent={this.updateTraitsState} updateTraits={this.updateTraits} markDestroy={true} displayLegend={false}/>
    </div>;
  }

  renderAdvancedOptions() {
    const { step, steps, stepsCount } = this.props;
    const { showAdvancedOptions, showNextStepIdDropdown } = this.state;

    if (!showAdvancedOptions) {
      return;
    }

    const typesOptions = nextFormStepBehaviour.map(type => {
      return (<option key={type} value={type}> { I18n.t(`react.registration_form.step_type.${type}`) } </option>);
    });

    const stepsOptions = steps.map(step => {
      return (<option key={step._id} value={step._id}> { step.title } </option>);
    });

    return (
      <div className="mt-3">
        <div className="row">
          <div className="col-sm-6">
            { this.renderInput("submit_label", I18n.t("react.registration_form.submit_step_button_title"), I18n.t("react.rendered_registration_form.submit")) }
            { this.renderMetadata() }
          </div>
        </div>
        { stepsCount > 1 ?
          <div className="mb-3 row">
            <div className="col-sm-6">
              { this.renderInputLabel(I18n.t("react.registration_form.next_step"), "next_step_behaviour") }
              <select className="form-select" id="next_step_behaviour" value={step.next_step_behaviour} onChange={this.onChangeNextStepBehaviour}>
                {typesOptions}
              </select>
            </div>
            { showNextStepIdDropdown ?
              <div className="col-sm-6">
                <label className="form-label" style={{ height: "15px" }}></label>
                <select className="form-select" id="custom_next_step_id" value={step.custom_next_step_id || ""} onChange={this.onChangeNextStepId}>
                  <option value="">{I18n.t("please_choose")}</option>
                  {stepsOptions}
                </select>
              </div>
              : null
            }
          </div>
          : null
        }
        { stepsCount > 1 ?
          <OverlayTrigger trigger="click" placement="right" rootClose overlay={this.renderDirectLinkPopover()} ref="overlay">
            <button className="btn btn-link p-0">{ I18n.t("react.registration_form.url_to_next_step") } <i className="fa-regular fa-info-circle"></i></button>
          </OverlayTrigger>
          : null
        }
      </div>
    );
  }

  renderDirectLinkPopover() {
    const { step } = this.props;

    return (
      <Popover id="theme-reloader-popover" style={{ padding: "10px", maxWidth: "550px" }}>
        <div style={{ textAlign: "left" }}>
          <p>
            <strong>{ I18n.t("react.registration_form.url_to_next_step") }</strong><br />
            <small>
              { I18n.t("react.registration_form.url_to_next_step_guest_only") }
              { " " }
              { I18n.t("react.registration_form.url_to_next_step_replace") }
            </small>
          </p>
          <div className="input-group input-group-sm has-success">
            {
              document.queryCommandSupported("copy") &&
              <OverlayTrigger placement="top" overlay={this.renderTooltip(I18n.t("react.registration_form.copy_to_clipboard"))}>
                <button className="btn btn-primary" onClick={this.copyToClipboard}>
                  <i className="fa-regular fa-clipboard"></i>
                </button>
              </OverlayTrigger>
            }
            <input ref="direct-link" className="form-control form-control-sm" type="text" value={`http://FORM_URL?guest_id=GUEST_ID&secret=GUEST_SECRET&step=${step._id}`} readOnly={true} />
          </div>
        </div>
      </Popover>
    );
  }

  renderTooltip(message) {
    return <Tooltip id="info">{ message }</Tooltip>;
  }

  copyToClipboard(e) {
    this.refs["direct-link"].select();
    document.execCommand("copy");
    e.target.focus();
  }

  renderInput(key, label, placeholder) {
    const value = this.state[key];
    return (
      <div className="mb-3">
        { this.renderInputLabel(label, key) }
        <input type="text" className="form-control" name={key} id={key} value={value} placeholder={placeholder} onBlur={this.inputOnBlur} onChange={this.inputOnChange} />
      </div>
    );
  }

  renderInputLabel(label, forKey = "") {
    return <label className="form-label" htmlFor={forKey}>{ label }</label>;
  }

  renderFooter() {
    const { itemsCount } = this.props;
    if (itemsCount === 0) {
      return (
        <div className="form-text text-center mt-10">{ I18n.t("react.registration_form.no_item_in_step") }</div>
      );
    }
    return null;
  }

  render() {
    const { step } = this.props;

    return (
      <div className="row" style={{ marginBottom: "20px", marginTop: "20px" }}>
        <div className="mb-3 col-md-12">
          { this.renderHeader() }
        </div>
        <div className="col-md-12">
          <ErrorMessage errors={step.errors} model="form_step" noLabelKeys={["key"]} additionalClasses="mt-10" />
        </div>
        <div className="col-md-12">
          { this.renderAdvancedOptionsTitle() }
          { this.renderAdvancedOptions() }
        </div>
        <div className="col-md-12">
          { this.renderFooter() }
        </div>
      </div>
    );
  }
}

RegistrationFormStep.propTypes = {
  step: PropTypes.object.isRequired,
  stepsCount: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired
};

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {
  cleanRegistrationFormStepErrors,
  destroyRegistrationFormStep,
  updateRegistrationFormStep
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationFormStep);
