import { Component } from "react";
import { connect } from "react-redux";
import remove from "lodash/remove";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

function requiresProps(OriginalComponent, requirements) {
  class RequiresPropsEnhancedComponent extends Component {
    constructor(props) {
      super(props);
      this.waitingRequirements = [];
      Object.keys(requirements.requirements).forEach(name => {
        const options = requirements.requirements[name];
        this.waitingRequirements.push(Object.assign({}, options, { name }));
      });
      this.runningRequirements = [];
      this.metRequirements = [];
    }

    componentDidMount() {
      this.meetNextRequirements(this.props);
    }

    componentDidUpdate(prevProps) {
      if (this.props === prevProps) return;
      const prevMetCount = this.metRequirements.length;
      this.runningRequirements.forEach(requirement => {
        if (this.requirementMet(requirement, this.props)) {
          this.moveRequirement(requirement, "runningRequirements", "metRequirements");
        }
      });
      if (prevMetCount < this.metRequirements.length) {
        this.meetNextRequirements(this.props);
      }
    }

    moveRequirement(requirement, fromArray, toArray) {
      remove(this[fromArray], (req) => {
        return req.name === requirement.name;
      });
      this[toArray].push(requirement);
    }

    meetNextRequirements(props) {
      const launchableRequirements = this.waitingRequirements.filter(requirement => {
        const dependencies = requirement.waitFor || [];
        if (dependencies.length === 0) {
          return true;
        }
        const unmetDependencies = dependencies.filter(dep => {
          return this.metRequirements.find(requirement => {
            return requirement.name === dep;
          }) === undefined;
        });
        if (unmetDependencies.length === 0) {
          return true;
        }
        return false;
      });

      if (launchableRequirements.length === 0) {
        return;
      }

      let requirementAlreadyMetOrCancelledCount = 0;
      launchableRequirements.forEach(requirement => {
        //set default values
        if (!requirement.options) {
          requirement.options = {};
        }
        if (!requirement.desiredState) {
          requirement.desiredState = "not_empty";
        }

        if (requirement.options.force) { // fetch even if prop is in the desired state
          if (!this.startRequirement(requirement, props)) {
            requirementAlreadyMetOrCancelledCount += 1;
          }
          return;
        }

        if (!this.requirementMet(requirement, props)) {
          if (!this.startRequirement(requirement, props)) {
            requirementAlreadyMetOrCancelledCount += 1;
          }
        } else {
          this.moveRequirement(requirement, "waitingRequirements", "metRequirements");
          requirementAlreadyMetOrCancelledCount += 1;
        }
      });
      if (requirementAlreadyMetOrCancelledCount > 0) {
        this.meetNextRequirements(props);
      }
    }

    startRequirement(requirement, props) {
      this.moveRequirement(requirement, "waitingRequirements", "runningRequirements");
      const status = requirement.fn(props);
      if (status && status.cancelled === true) {
        this.moveRequirement(requirement, "runningRequirements", "metRequirements");
        return false;
      }
      return true;
    }

    requirementMet(requirement, props) {
      const prop = props[requirement.propName || requirement.name];
      switch (requirement.desiredState) {
      case "empty":
        return this.isEmpty(prop);
      case "not_empty":
        return !this.isEmpty(prop);
      case "has_underscore_id":
        return prop._id;
      case "has_id":
        return prop.id;
      case "present":
        return prop != null;
      default:
        return true;
      }
    }

    isEmpty(something) {
      const isArray = Array.isArray(something);
      const isObject = something !== null && typeof something === "object";
      if (isArray || isObject) {
        return isEmpty(something);
      }
      return (something === null || something === undefined);
    }

    render() {
      return <OriginalComponent {...this.props} allRequirementsMet={this.waitingRequirements.length == 0 && this.runningRequirements.length == 0} />;
    }
  }

  function mapStateToProps(state, ownProps) {
    let map = {};
    Object.keys(requirements.requirements).forEach(name => {
      const req = requirements.requirements[name];
      if (req.reducer) {
        map = req.reducer(state, map, ownProps);
      } else {
        map[name] = get(state, req.statePath || req.propName || name);
      }
    });
    return map;
  }

  return connect(mapStateToProps)(RequiresPropsEnhancedComponent);
}

export default requiresProps;
