import { ActionTypes } from "../constants/Constants";
import { AccesspointsOccupancyState } from "../types/Accesspoint";

const defaultState: AccesspointsOccupancyState = {
  occupancies: [],
  fetchPending: false
};

function accesspointsOccupancy(state = defaultState, action: any): AccesspointsOccupancyState {
  switch (action.type) {
  case ActionTypes.FETCH_ACCESSPOINTS_OCCUPANCY_REQUEST:
    return { ...state, fetchPending: true };
  case ActionTypes.FETCH_ACCESSPOINTS_OCCUPANCY_SUCCESS:
    return { ...state, occupancies: action.data, fetchPending: false };
  case ActionTypes.FETCH_ACCESSPOINTS_OCCUPANCY_FAILURE:
    return { ...state, occupancies: [], fetchPending: false };
  default:
    return state;
  }
}

export default accesspointsOccupancy;
