import { useState } from "react";

/*
  Create a form input to fill an array of strings

  <%
    props = {
      currentValues: values || [],
      label: UserEnterpise.human_attribute_name(:domains),
      inputName: "user_enterpise[domains]"
    }
  %>
  <div class="react-anchor" data-react-component="ArrayInputErb" data-react-component-props="<%= props.to_json %>"></div>
*/

interface Props {
  inputName: string;
  currentValues: string[];
  label: string;
  labelExtraClass?: string;
  inputsWrapperDivClass?: string;
}

const ArrayInputErb: React.FC<Props> = ({
  inputName,
  currentValues,
  label,
  labelExtraClass,
  inputsWrapperDivClass
}) => {
  const [values, setValues] = useState(currentValues || []);

  const addNewValue = (): void => {
    setValues([...values, ""]);
  };

  const deleteInput = (index: number): void => {
    const nextValues = [...values];
    nextValues.splice(index, 1);
    setValues(nextValues);
  };

  const updateValue = (index: number, value: string): void => {
    const nextValues = [...values];
    nextValues[index] = value;
    setValues(nextValues);
  };

  const renderValueInput = (value: string, index: number): JSX.Element => {
    return (
      <div className="input-group mb-10" key={`${inputName}_${index}`}>
        <input className="form-control" type="text" value={value} name={`${inputName}[]`} onChange={(e): void => updateValue(index, e.target.value) }/>
        <div className="btn btn-danger" style={{ cursor: "pointer" }} onClick={(): void => deleteInput(index)}>
          <i className="fa-regular fa-trash-can"></i>
        </div>
      </div>
    );
  };

  return <div className="mb-3 row">
    <label className={`col-form-label ${labelExtraClass || "col-12"}`}>{label}</label>
    <div className={inputsWrapperDivClass || "col-12"}>
      {values.map((value, i) => {
        return renderValueInput(value, i);
      })}
      {values.length === 0 && (
        <input type="hidden" name={`${inputName}[]`} value="" />
      )}
      <button type="button" className="btn btn-secondary" onClick={addNewValue}>
        <i className="fa-regular fa-plus"></i>
      </button>
    </div>
  </div>;
};

export default ArrayInputErb;
