import { Component } from "react";
import { connect } from "react-redux";
import Paginate from "../../components/Paginate.react";
import { Link } from "react-router-dom";
import { urlWithQuery, pathToGuests, urlEventLocale } from "../../utils/pathUtils";
import { fetchGuestCampaigns, fetchGuestCampaignsPage, replicateGuestCampaign } from "../../actions/GuestCampaignsActionCreators";
import { fetchWebsite } from "../../actions/WebsiteActionCreators";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import { deleteGuestCampaign } from "../../actions/GuestCampaignActionCreators";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { GuestCampaignStatusLocked } from "../../constants/Constants";
import ConfirmableButton from "../../components/shared/ConfirmableButton.react";
import Loader from "../../components/shared/Loader.react";
import moment from "moment";
import { redirectIfUnauthorized, isAuthorized } from "../../utils/aclUtils";
import { fetchSavedSearches } from "../../actions/SavedSearchActionCreators";
import GuestCampaignSelectMediumModal from "../../components/guest_campaign/GuestCampaignSelectMediumModal.react";
import { isEnabled } from "../../utils/featureSetUtils";
import { CAMPAIGNS_AND_TRACKING } from "../../constants/FeaturesSet";
import UnpublishedEventAlertMessage from "../../components/UnpublishedEventAlertMessage.react";
import { hasAvailableFeature } from "../../utils/availableFeature";

const PER_PAGE = 20;

const LABEL_TYPE_STATUS_MAPPING = {
  draft: "warning",
  sending: "info",
  scheduled: "info",
  sent: "success",
  blocked: "danger"
};

const MEDIUM_ICONS = {
  use_email: "fa-envelope",
  use_sms: "fa-mobile",
  use_message: "fa-bell"
};

class GuestCampaignList extends Component {

  constructor(props) {
    redirectIfUnauthorized("campaigns", "manage");
    super(props);
    [
      "goToPage",
      "replicateCampaign",
      "deleteCampaign",
      "sortCampaigns",
      "onShowSelectMedium",
      "onSelectedMedium",
      "onCanceledMedium"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    moment.locale(urlEventLocale());

    this.state = {
      sortSelector: "sent_at",
      sortDirection: "desc",
      isShowGuestCampaignSelectTypeModal: false
    };
  }

  componentDidMount() {
    const { fetchGuestCampaigns, fetchSavedSearches } = this.props;
    fetchGuestCampaigns(this.eventId(), 1, PER_PAGE);
    fetchSavedSearches(this.eventId());
  }

  componentDidUpdate(prevProps) {
    const { fetchWebsite, event } = this.props;
    if (!prevProps.event._id && event._id && event.website_enabled) {
      fetchWebsite(this.eventId());
    }
  }

  goToPage(url) {
    const { fetchGuestCampaignsPage } = this.props;
    return (e) => {
      e.preventDefault();
      fetchGuestCampaignsPage(url);
    };
  }

  eventId() {
    return this.props.match.params.event_id;
  }

  replicateCampaign(guestCampaign) {
    const { replicateGuestCampaign } = this.props;
    return () => {
      const path = `campaign/:id/${guestCampaign.use_email && !guestCampaign.email_template_id ? "information" : "edit"}`;
      replicateGuestCampaign(this.eventId(), guestCampaign._id, path);
    };
  }

  deleteCampaign(guestCampaign) {
    return () => {
      const { deleteGuestCampaign, match } = this.props;
      deleteGuestCampaign(match.params.event_id, guestCampaign._id);
    };
  }

  renderCreateCampaignButton() {
    const { isShowGuestCampaignSelectTypeModal } = this.state;

    return (
      <>
        <GuestCampaignSelectMediumModal
          isVisible={isShowGuestCampaignSelectTypeModal}
          onSelected={this.onSelectedMedium}
          onCanceled={this.onCanceledMedium}
        />
        <Button variant="primary" onClick={this.onShowSelectMedium}>
          <i className="fa-regular fa-plus"></i>{" "}
          { I18n.t("react.guest_campaigns.new_campaign_link") }
        </Button>
      </>
    );
  }

  onShowSelectMedium() {
    this.setState({
      isShowGuestCampaignSelectTypeModal: true
    });
  }

  onSelectedMedium(medium) {
    this.setState({
      isShowGuestCampaignSelectTypeModal: false
    }, () => {
      if (medium)
        this.props.history.push(urlWithQuery("", `campaigns/new?medium=${medium}`));
    });
  }

  onCanceledMedium() {
    this.setState({
      isShowGuestCampaignSelectTypeModal: false
    });
  }

  renderDateLabel(date) {
    moment.locale(I18n.currentLocale());
    const words = moment(date).fromNow();
    const actualDate = moment(date).format("LLL");
    return (
      <span title={actualDate}>
        ({words})
      </span>
    );
  }

  renderFormattedDate(date) {
    moment.locale(I18n.currentLocale());
    return moment(date).format("lll");
  }

  renderLabel(guestCampaign) {
    const endPath = `campaign/${guestCampaign._id}/${guestCampaign.use_email && !guestCampaign.email_template_id ? "information" : "edit"}`;
    const campaignLink = <Link to={urlWithQuery(guestCampaign.search_query, endPath)} className="text-default fw-bold">{ guestCampaign.name }</Link>;
    if (!GuestCampaignStatusLocked.includes(guestCampaign.status)) { return campaignLink; }
    const tooltip = <Tooltip id="no_guest_field_tooltip">{I18n.t("react.guest_campaign.campaign_locked") }</Tooltip>;
    return (
      <OverlayTrigger placement="right" overlay={tooltip}>
        <span> { campaignLink } </span>
      </OverlayTrigger>
    );
  }

  renderCampaignErrors(guestCampaign) {
    if (!guestCampaign.schedule_errors) return null;

    return guestCampaign.schedule_errors.map(messageKey => {
      return <p className="badge rounded-pill bg-danger">{ I18n.t(`react.guest_campaigns.${messageKey}`) }</p>;
    });
  }

  renderSegmentInfo(guestCampaign) {
    const { savedSearches } = this.props;
    const savedSearch = savedSearches.find(ss => ss._id == guestCampaign.saved_search_id);
    if (savedSearch) {
      const guestCount = guestCampaign.final_guest_count && I18n.t("react.guest_campaigns.guest_count", { count: guestCampaign.final_guest_count });
      return `${savedSearch.name} ${guestCount || ""}`;
    }
    return null;
  }

  renderType(guestCampaign) {
    const usedMedium = ["use_email", "use_sms", "use_message"].find(medium => guestCampaign[medium]);
    return usedMedium ? <span><i className={`fa-regular ${MEDIUM_ICONS[usedMedium]}`}></i> { I18n.t(`react.guest_campaign.popup.${usedMedium}`) }</span> : null;
  }

  renderSentAt(guestCampaign) {
    let sentAtInfo = "-";
    if (guestCampaign.sent_at) {
      sentAtInfo = <span>{this.renderFormattedDate(guestCampaign.sent_at)} <br></br> {this.renderDateLabel(guestCampaign.sent_at)}</span>;
    } else if (guestCampaign.scheduled_at) {
      sentAtInfo = <span>{this.renderFormattedDate(guestCampaign.scheduled_at)} <br></br> {this.renderDateLabel(guestCampaign.scheduled_at)}</span>;
    }

    return sentAtInfo;
  }

  renderTableRow(guestCampaign) {
    const { use_email } = guestCampaign;
    const createdAt = I18n.t("mongoid.attributes.guest_campaign.created_at") + " " + this.renderFormattedDate(guestCampaign.created_at);
    const deleteBtn = guestCampaign.sent_at ? null : <ConfirmableButton onConfirm={this.deleteCampaign(guestCampaign)} cancelBtnType="secondary" />;
    const statusClasses = `badge rounded-pill bg-${LABEL_TYPE_STATUS_MAPPING[guestCampaign.status]}`;
    const statsBtn = guestCampaign.sent_at ? (
      <Link to={urlWithQuery("", `campaign/${guestCampaign._id}/statistics`)} className="btn btn-secondary" style={{ marginRight: "5px" }}>
        <i className="fa-regular fa-chart-column"></i> { I18n.t("react.guest_campaigns.statistics.stats") }
      </Link>
    ) : null;

    return (
      <tr key={guestCampaign._id}>
        <td>{ this.renderLabel(guestCampaign) }<br></br><div className="form-text">{createdAt}</div></td>
        <td>{ this.renderType(guestCampaign) }</td>
        <td>
          <p className={statusClasses}>{ I18n.t(`react.guest_campaigns.status_${guestCampaign.status}`) }</p>
          {this.renderCampaignErrors(guestCampaign)}
        </td>
        <td> { this.renderSegmentInfo(guestCampaign) } </td>
        <td>{this.renderSentAt(guestCampaign)}</td>
        <td className="col-md-4">
          { use_email && statsBtn }
          <a href="#" className="btn btn-secondary mr-5" onClick={this.replicateCampaign(guestCampaign) }>
            <i className="fa-regular fa-clone"></i> {I18n.t("react.guest_campaigns.duplicate")}
          </a>
          { deleteBtn }
        </td>
      </tr>
    );
  }

  renderSortLink(selector) {
    const { sortDirection, sortSelector } = this.state;
    let arrow = "angle-down";
    if (selector === sortSelector) {
      arrow = sortDirection === "desc" ? "angle-up" : "angle-down";
    }
    const classes = `fa-regular fa-${arrow}`;
    let styles = {};
    if (sortSelector !== selector) {
      styles["opacity"] = "0.45";
    }
    return <a href="#" onClick={this.sortCampaigns(selector)}>{I18n.t(`mongoid.attributes.guest_campaign.${selector}`)} <i className={classes} style={ styles }></i></a>;
  }

  sortCampaigns(sortSelector) {
    return (e) => {
      e.preventDefault();
      const { sortDirection } = this.state;
      const newSortDirection = sortDirection == "desc" ? "asc" : "desc";
      this.setState({ sortSelector, sortDirection: newSortDirection });
      this.props.fetchGuestCampaigns(this.eventId(), 1, PER_PAGE, sortSelector, newSortDirection);
    };
  }

  renderTableHeader() {
    return (
      <tr key="O">
        <th>{I18n.t("react.guest_campaigns.name")}</th>
        <th>{I18n.t("react.guest_campaigns.type")}</th>
        <th>{this.renderSortLink("status")}</th>
        <th>{I18n.t("react.guest_campaigns.saved_search")}</th>
        <th>{this.renderSortLink("sent_at")}</th>
        <th>{I18n.t("react.guest_campaigns.actions")}</th>
      </tr>
    );
  }

  renderTable() {
    const { guestCampaigns } = this.props;
    const rows = guestCampaigns.map(guestCampaign => {
      return this.renderTableRow(guestCampaign);
    });

    return (
      <div className="table-responsive table-container">
        <table className="table table-light table-bordered table-hover">
          <thead>
            { this.renderTableHeader() }
          </thead>
          <tbody>
            { rows }
          </tbody>
        </table>
      </div>
    );
  }

  renderNoCampaign() {
    return (
      <div>
        <div className="header-page">
          <div className="header-page-title">
            <h1>{ I18n.t("react.guest_campaigns.campaign_list_title") }</h1>
          </div>
        </div>
        <div className="card nothing-yet">
          <div>
            <i className="fa-regular fa-paper-plane fa-xl"></i>
          </div>
          <h4>{ I18n.t("react.guest_campaigns.no_campaigns") }</h4>
          <div>
            {this.renderCreateCampaignButton()}
          </div>
          <p style={{ fontSize: "14px" }}>{I18n.t("react.guest_campaigns.how_to_campaigns")} <Link to={pathToGuests()}> { I18n.t("react.guest_campaigns.guest_view_link") } </Link></p>
        </div>
        {this.renderLiquidCampaignButton()}
      </div>
    );
  }

  renderLiquidCampaignButton() {
    const { ReactGlobalProps } = window;
    if (!ReactGlobalProps.super_admin && !hasAvailableFeature("liquid_campaign")) {
      return null;
    }

    return (
      <div className="float-end">
        <Link to={urlWithQuery("", "campaign/information")} className="btn btn-secondary btn-wrap mt-10" >
          <i className="fa-regular fa-plus"></i>{" "}
          { I18n.t("react.guest_campaigns.new_liquid_campaign_link") }{" "}
          <span className="text-warning">
            ({ I18n.t("admin_only") })
          </span>
        </Link>
      </div>
    );
  }

  renderCampaigns() {
    const { nextURL, previousURL, guestCampaignsError } = this.props;
    return (
      <div>
        <ErrorMessage errors={guestCampaignsError}/>
        <div>
          <div className="header-page">
            <div className="header-page-content row">
              <div className="header-page-title col-md-6">
                <h1>{I18n.t("react.guest_campaigns.campaign_list_title")}</h1>
              </div>
              <div className="header-page-btn col-md-6">
                {this.renderCreateCampaignButton()}
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-10 mt-10">
          <div className="collection-pagination col-12 d-flex align-items-center justify-content-end">
            <Paginate
              handlePrevious={this.goToPage(previousURL)}
              handleNext={this.goToPage(nextURL)}
              previousEnabled={previousURL != null}
              nextEnabled={nextURL != null}
              pull="end"
            />
          </div>
        </div>

        {this.renderTable()}
        {this.renderLiquidCampaignButton()}
      </div>
    );
  }

  render() {
    const { event, guestCampaigns, loading } = this.props;

    if (!isEnabled(CAMPAIGNS_AND_TRACKING)) return null;

    if (!isAuthorized("campaigns", "manage") || loading) {
      return <Loader />;
    }

    return <>
      <UnpublishedEventAlertMessage event={event} />
      { guestCampaigns.length > 0 ? this.renderCampaigns() : this.renderNoCampaign() }
    </>;
  }

}

function mapStateToProps(state) {
  const { guestCampaigns } = state;
  return {
    guestCampaigns: guestCampaigns.data,
    loading: guestCampaigns.loading,
    guestCampaignsError: guestCampaigns.errors,
    nextURL: guestCampaigns.nextURL,
    previousURL: guestCampaigns.previousURL,
    savedSearches: state.savedSearches.data,
    event: state.event,
    website: state.website
  };
}

const mapDispatchToProps = {
  fetchGuestCampaigns,
  fetchGuestCampaignsPage,
  replicateGuestCampaign,
  deleteGuestCampaign,
  fetchSavedSearches,
  fetchWebsite
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestCampaignList);
