import { ChangeEvent, ReactElement } from "react";
import { DropdownButton, DropdownItem } from "react-bootstrap";
import Checkbox from "../../components/shared/Checkbox.react";
import SessionTypeFilter from "./ui_filters/SessionTypeFilter.react";
import AttendanceTypeFilter from "./ui_filters/AttendanceTypeFilter.react";
import ThematicsFilter from "./ui_filters/ThematicsFilter.react";
import SessionRoomFilter from "./ui_filters/SessionRoomFilter.react";
import DateRangeFilter from "./ui_filters/DateRangeFilter.react";
import TraitFilter from "./ui_filters/TraitFilter.react";
import BookmarksOnlyFilter from "./ui_filters/BookmarksOnlyFilter.react";
import MissedSessionsFilter from "./ui_filters/MissedSessionsFilter.react";
import SortByRecommendationFilter from "./ui_filters/SortByRecommendationFilter.react";
import Sortable from "../../components/Sortable.react";
import { DragTypes, ProgramFilterTypes } from "../../constants/Constants";
import { Program, ProgramFilter, FilterContext } from "../../types/Program";
import { availableFilterTypes } from "../../utils/programUtils";
import { Event } from "../../types/Event";
import HelpSection from "../shared/HelpSection.react";

interface Props {
  program: Program;
  event: Event;

  onChangeConfiguration(program: Program): void;
  addFilter(type: string, context: FilterContext): void;
  updateFilter(programFilter: ProgramFilter, filterKey: string): void
  removeFilter(programFilter: ProgramFilter, filterKey: string, context: FilterContext): void;
}

function i18n(key: string, opts = {}): string {
  return I18n.t(`react.programs.${key}`, opts);
}

const ProgramUIFilters: React.FC<Props> = ({ program, event, onChangeConfiguration, addFilter, updateFilter, removeFilter }) => {
  const textSearchEnabledChanged = (e: ChangeEvent<HTMLInputElement>): void => {
    onChangeConfiguration({ ...program, text_search_enabled: e.target.checked });
  };

  const noResultsLabelChanged = (e: ChangeEvent<HTMLInputElement>): void => {
    onChangeConfiguration({ ...program, no_results_label: e.target.value });
  };

  const renderFilterDropdownOptions = (): JSX.Element[] => {
    return availableFilterTypes(program, "ui").map((filterType, index) => {
      if (filterType === "sort_by_recommendation" && event?.scoring_and_engagement_enabled === false) return;

      return <DropdownItem eventKey={index} key={filterType} onClick={(): void => addFilter(filterType, "ui")}>
        {i18n(`filter_type.${filterType}`)}
      </DropdownItem>;
    });
  };

  const handleDrop = (previousItemId: string, itemId: string | number, nextItemId: string, estimatedIndex: number): void => {
    const droppedItemKey = Object.keys(program.program_filters).find(key => {
      // itemId contains filter _id if exists in DB or its rank otherwise
      return program.program_filters[key]._id === itemId || program.program_filters[key].rank === itemId;
    });
    onChangeConfiguration({ ...program, program_filters: {
      ...program.program_filters,
      [droppedItemKey]: { ...program.program_filters[droppedItemKey], rank: estimatedIndex }
    } });
  };

  const renderFilters = (): ReactElement<any, any> => {
    const displayedFiltersWithKeys = Object.entries(program.program_filters).sort((a, b) => a[1].rank - b[1].rank).filter(filter => {
      return !filter[1]._destroy && filter[1].displayed_to_user;
    });

    const displayedFilters = displayedFiltersWithKeys.map(filter => filter[1]);

    const renderedFilters = displayedFiltersWithKeys.map(([filterKey, filter], index) => {
      const filterProps = {
        key: index,
        filterKey,
        programFilter: filter,
        removeFilter,
        updateFilter
      };

      switch (filter._type) {
      case ProgramFilterTypes.SESSION_TYPE:
        return <SessionTypeFilter {...filterProps} />;
      case ProgramFilterTypes.ATTENDANCE_TYPE:
        return <AttendanceTypeFilter {...filterProps} />;
      case ProgramFilterTypes.THEMATIC:
        return <ThematicsFilter {...filterProps} />;
      case ProgramFilterTypes.SESSION_ROOM:
        return <SessionRoomFilter {...filterProps} />;
      case ProgramFilterTypes.DATE_RANGE:
        return <DateRangeFilter {...filterProps} />;
      case ProgramFilterTypes.TRAIT:
        return <TraitFilter {...filterProps} />;
      case ProgramFilterTypes.BOOKMARKS_ONLY:
        return <BookmarksOnlyFilter {...filterProps} />;
      case ProgramFilterTypes.MISSED_SESSIONS:
        return <MissedSessionsFilter {...filterProps} />;
      case ProgramFilterTypes.SORT_BY_RECOMMENDATION:
        return <SortByRecommendationFilter {...filterProps} />;
      }
    }).map((renderedFilter, index) => <div key={index}>{renderedFilter}</div>);

    return <Sortable
      itemIdKey="_id"
      itemIndexKey="rank"
      dragType={DragTypes.PROGRAM_FILTERS}
      items={displayedFilters}
      handleDrop={handleDrop}
      fullyDraggable={true}>
      {renderedFilters}
    </Sortable>;
  };

  return <div className="card">
    <div className="card-header">
      <div className="card-title">{i18n("ui_section.title")}</div>
    </div>
    <div className="card-body">
      <HelpSection help={i18n("ui_section.help")} />
      <div className="mb-3">
        <Checkbox
          checked={program.text_search_enabled}
          text={i18n("text_search_enabled.checkbox_label")}
          label={i18n("text_search_enabled.group_label")}
          onChange={textSearchEnabledChanged} />
      </div>
      <div className="mb-3">
        <label className="form-label">{i18n("no_results_label.group_label")}</label>
        <div className="row g-2 align-items-center">
          <span className="col-auto">{i18n("no_results_label.input_label")}</span>
          <div className="col-auto">
            <input
              type="text"
              value={program.no_results_label || ""}
              className="form-control"
              placeholder={i18n("no_results_label.placeholder")}
              onChange={noResultsLabelChanged} />
          </div>
        </div>
      </div>
      { renderFilters() }
      <DropdownButton drop="up" variant="secondary" title={<span><i className="fa-regular fa-plus"></i> {i18n("ui_filter_dropdown.title")}</span>} id="UIFilterDropdown">
        {renderFilterDropdownOptions()}
      </DropdownButton>
    </div>
  </div>;
};

export default ProgramUIFilters;
