import { CALL_API, ActionTypes } from "../constants/Constants";

export function fetchExhibitorsByNumberOfInterests(eventId: string, sortBy: string): any {
  const { FETCH_EXHIBITORS_STATISTICS_REQUEST, FETCH_EXHIBITORS_STATISTICS_SUCCESS, FETCH_EXHIBITORS_STATISTICS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_EXHIBITORS_STATISTICS_REQUEST, FETCH_EXHIBITORS_STATISTICS_SUCCESS, FETCH_EXHIBITORS_STATISTICS_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/exhibitors/statistics.json?type=exhibitors_by_number_of_interests&sort_by=${sortBy}`
    }
  };
}

export function fetchExhibitorByNumberOfLeads(eventId: string, sortBy: string, statsDate = ""): any {
  const { FETCH_EXHIBITORS_LEADS_STATISTICS_REQUEST, FETCH_EXHIBITORS_LEADS_STATISTICS_SUCCESS, FETCH_EXHIBITORS_LEADS_STATISTICS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_EXHIBITORS_LEADS_STATISTICS_REQUEST, FETCH_EXHIBITORS_LEADS_STATISTICS_SUCCESS, FETCH_EXHIBITORS_LEADS_STATISTICS_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/exhibitors/statistics.json?type=exhibitors_by_number_of_leads&sort_by=${sortBy}&stats_date=${statsDate}`
    }
  };
}
