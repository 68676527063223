import { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { generateRandomIdentifier } from "../../utils/identifierUtils";

interface Props {
  value?: string;
  rows?: number;
}

class CopyToClipboardTextArea extends Component<Props> {
  refName: string;

  constructor(props: Props) {
    super(props);
    [
      "copyToClipboard"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.refName = generateRandomIdentifier();
  }

  renderTooltip(message: string): JSX.Element {
    return <Tooltip id="info">{ message }</Tooltip>;
  }

  copyToClipboard(): void {
    (this.refs[this.refName] as HTMLInputElement).select();
    document.execCommand("copy");
  }

  renderCopyToClipBoardButton(): JSX.Element {
    return (
      document.queryCommandSupported("copy") &&
      <OverlayTrigger placement="top" overlay={this.renderTooltip(I18n.t("react.registration_form.copy_to_clipboard"))}>
        <button className="btn btn-secondary" onClick={this.copyToClipboard}>
          <i className="fa-regular fa-clipboard"></i>
        </button>
      </OverlayTrigger>
    );
  }

  render(): JSX.Element {
    const { value, rows } = this.props;
    return (
      <div className="input-group input-group-sm" key="input-code">
        <textarea ref={this.refName} className="form-control textarea-code" value={value} rows={rows} readOnly={true}></textarea>
        {this.renderCopyToClipBoardButton()}
      </div>
    );
  }
}

export default CopyToClipboardTextArea;
