import HelpSection from "../../shared/HelpSection.react";
import { MeetingLocation, newMeetingLocation, UNLIMITED_CAPACITY, DEFAULT_LIMITED_CAPACITY } from "../../../types/MeetingLocation";
import { insertOrUpdateObject } from "../../../utils/StateOperationUtils";

interface Props {
  meetingLocations?: MeetingLocation[];
  eventErrors?: any;

  updateLocations: (e: MeetingLocation[]) => void;
}

const i18n = (key: string): string => {
  return I18n.t(`react.networking.meetings.locations.${key}`);
};

const LocationsSettings: React.FC<Props> = ({
  meetingLocations,
  updateLocations,
  eventErrors
}) => {
  const EMPTY_LOCATION: MeetingLocation = newMeetingLocation();
  const locations = !meetingLocations || meetingLocations.length === 0 ? [EMPTY_LOCATION] : meetingLocations;

  const isRadioButtonChecked = (location: MeetingLocation, i18nKey: string): boolean => {
    return i18nKey === "unlimited" && location.capacity === -1 || i18nKey === "limited" && location.capacity > -1;
  };

  const renderRadio = (location: MeetingLocation, value: number, i18nKey: string): JSX.Element => {
    return <div className="col-auto">
      <div className="form-check">
        <label className="form-check-label">
          <input
            type="radio"
            className="form-check-input"
            checked={isRadioButtonChecked(location, i18nKey)}
            onChange={(): void => {
              const newLocations = insertOrUpdateObject(locations, { ...location, capacity: value }, "_id");
              updateLocations(newLocations);
            }}
          /> {i18n(i18nKey)}
        </label>
      </div>
    </div>;
  };

  const renderLocationCapacityField = (locations: MeetingLocation[], location: MeetingLocation): JSX.Element => {
    return <div className="col-auto">
      <input
        type="text"
        className="form-control"
        placeholder={i18n("capacity_placeholder")}
        required={true}
        value={location.capacity}
        disabled={location.capacity === -1}
        onChange={(e): void => {
          let capacityValue = e.target.value;
          if (parseInt(e.target.value) < -1) capacityValue = "-1";
          const newLocations = insertOrUpdateObject(locations, { ...location, capacity: capacityValue }, "_id");
          updateLocations(newLocations);
        }}
      />
    </div>;
  };

  return <>
    <HelpSection help={i18n("help")} />

    <div className="row mb-10">
      <div className="col-md-4" style={{ fontWeight: "bold" }}>{i18n("locations")}</div>
      <div className="col-md-8" style={{ fontWeight: "bold" }}>{i18n("location_capacity")}</div>
    </div>

    {locations.filter(location => !location._destroy).map((location, i) => {
      const errors = (eventErrors || {})[`meeting_locations[${i}].name`];
      const locationClass = errors ? "has-error" : "";

      return (
        <div key={`location-${location._id}`} className="mb-3 row">
          <div className={`col-md-4 ${locationClass}`}>
            <input
              type="text"
              className="form-control"
              placeholder={i18n("placeholder")}
              required={true}
              value={location.name}
              onChange={(e): void => {
                const newLocations = insertOrUpdateObject(locations, { ...location, name: e.target.value }, "_id");
                updateLocations(newLocations);
              }}
            />
            {errors && <span className="text-danger">{errors.join(", ")}</span>}
          </div>
          <div className="col-md-8">
            <div className="row g-3 align-items-center">
              {renderRadio(location, UNLIMITED_CAPACITY, "unlimited")}
              {renderRadio(location, DEFAULT_LIMITED_CAPACITY, "limited")}
              {renderLocationCapacityField(locations, location)}

              {location._id !== locations[0]._id && (
                <div className="col-auto">
                  <a
                    className="btn btn-danger"
                    onClick={(e): void => {
                      e.preventDefault();
                      const newLocations = insertOrUpdateObject(locations, { ...location, _destroy: true }, "_id");
                      updateLocations(newLocations);
                    }}
                  >
                    <i className="fa-regular fa-trash-can"></i>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    })}

    {locations[0] === null && (
      <p className="text-danger">{i18n("error")}</p>
    )}

    <a href="#"
      onClick={(e): void => {
        e.preventDefault();
        updateLocations([...meetingLocations, EMPTY_LOCATION]);
      }}
    >
      <i className="fa-regular fa-plus"></i> {i18n("add")}
    </a>
  </>;
};

export default LocationsSettings;
