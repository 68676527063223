import { Component } from "react";
import { Event } from "../types/Event";

interface Props {
  event: Event;
  wrapperClass?: string;
}

class UnpublishedEventAlertMessage extends Component<Props> {
  render(): JSX.Element {
    const { event, wrapperClass } = this.props;

    if (!event || !event.fetched) return null;
    if (event.published) return null;

    return <div className={wrapperClass}>
      <div className="alert alert-warning mb-0">
        <i className="fa-regular fa-info-circle mr-10"></i>{I18n.t("events.unpublished_alert.message")}</div>
    </div>;
  }
}

export default UnpublishedEventAlertMessage;
