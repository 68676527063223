import { ActionTypes } from "../constants/Constants";

module.exports = {
  updateSelectedOptin(filter, value) {
    return {
      type: ActionTypes.SET_SELECTED_OPTINS,
      filter,
      value
    };
  }
};
