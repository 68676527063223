import EventTradeshowImg from "images/event-tradeshow.png";
import EventCongressImg from "images/event-congress.png";
import EventCorporateImg from "images/event-corporate.png";
import EventAgencyImg from "images/event-agency.png";
import EventEducationImg from "images/event-education.png";
import EventPhysicalImg from "images/event-physical.png";
import EventVirtualImg from "images/event-virtual.png";
import EventHybridImg from "images/event-hybrid.png";
import RsvpFormImg from "images/rsvp-form.png";
import TicketingFormImg from "images/ticketing-form.png";
import RegistrationFormImg from "images/registration-form.png";
import StandardThemeImg from "images/standard-theme.png";
import PremimumThemeImg from "images/premium-theme.png";
import DeluxeThemeImg from "images/deluxe-theme.png";
import HasWebsiteQuestionImg from "images/has_website_question.png";
import HasVisitorSpaceQuestionImg from "images/has_visitor_space_question.png";
import HasExhibitorSpaceQuestionImg from "images/has_exhibitor_space_question.png";
import HasProgramQuestionImg from "images/has_program_question.png";
import AssistantBackgroundImg from "images/event-assistant-background.svg";
import AssistantEnvironmentImg from "images/event-assistant-environment.png";
import FeatureDeckBanner from "images/feature-deck-banner.png";
import Bewi from "../../app/assets/images/integrations/bewi.png";
import Expose from "../../app/assets/images/integrations/expose.png";
import Groupcorner from "../../app/assets/images/integrations/groupcorner.png";
import Interprefy from "../../app/assets/images/integrations/interprefy.png";
import MobilActif from "../../app/assets/images/integrations/mobilactif.png";
import Odiho from "../../app/assets/images/integrations/odiho.png";
import Ogone from "../../app/assets/images/integrations/ogone.png";
import Ouispeak from "../../app/assets/images/integrations/ouispeak.png";
import Planexpo from "../../app/assets/images/integrations/planexpo.png";
import Revolugo from "../../app/assets/images/integrations/revolugo.png";
import Salesforce from "../../app/assets/images/integrations/salesforce.png";
import SeatsIo from "../../app/assets/images/integrations/seatsio.png";
import Sparkup from "../../app/assets/images/integrations/sparkup.png";
import Stripe from "../../app/assets/images/integrations/stripe.png";
import Paybox from "../../app/assets/images/integrations/paybox.png";
import Wemap from "../../app/assets/images/integrations/wemap.png";
import ZohoInvoice from "../../app/assets/images/integrations/zoho.png";
import Ezymob from "../../app/assets/images/integrations/ezymob.png";
import Zapier from "../../app/assets/images/integrations/zapier.png";
import LinkedinCommunityBuilder from "../../app/assets/images/integrations/linkedin_community_builder.png";
import Memento from "../../app/assets/images/integrations/memento.png";
import Slido from "../../app/assets/images/integrations/slido.png";
import Beekast from "../../app/assets/images/integrations/beekast.png";
import Kahoot from "../../app/assets/images/integrations/kahoot.png";

export const IMAGE_URLS = {
  corporate: EventCorporateImg,
  congress: EventCongressImg,
  tradeshow: EventTradeshowImg,
  // TO DO : ONBOARDING change agency & education pictures
  agency: EventAgencyImg,
  education: EventEducationImg,
  physical: EventPhysicalImg,
  virtual: EventVirtualImg,
  hybrid: EventHybridImg,
  rsvpForm: RsvpFormImg,
  ticketingForm: TicketingFormImg,
  registrationForm: RegistrationFormImg,
  standardTheme: StandardThemeImg,
  premiumTheme: PremimumThemeImg,
  deluxeTheme: DeluxeThemeImg,
  has_website_question: HasWebsiteQuestionImg,
  has_visitor_space_question: HasVisitorSpaceQuestionImg,
  has_exhibitor_space_question: HasExhibitorSpaceQuestionImg,
  has_program_question: HasProgramQuestionImg,
  backgroundAssistant: AssistantBackgroundImg,
  imageEnvironment: AssistantEnvironmentImg,
  featureDeckBanner: FeatureDeckBanner,
  // integrations
  bewi: Bewi,
  expose: Expose,
  groupcorner: Groupcorner,
  interprefy: Interprefy,
  mobilactif: MobilActif,
  odiho: Odiho,
  ogone: Ogone,
  ouispeak: Ouispeak,
  planexpo: Planexpo,
  revolugo: Revolugo,
  salesforce: Salesforce,
  seatsio: SeatsIo,
  sparkup: Sparkup,
  stripe: Stripe,
  paybox: Paybox,
  wemap: Wemap,
  zoho: ZohoInvoice,
  memento: Memento,
  linkedin_community_builder: LinkedinCommunityBuilder,
  zapier: Zapier,
  ezymob: Ezymob,
  slido: Slido,
  beekast: Beekast,
  kahoot: Kahoot
};

export default IMAGE_URLS;
