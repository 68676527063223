import { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import IdentityCell from "./guest_cells/IdentityCell.react";
import TextCell from "./guest_cells/TextCell.react";
import CategoryCell from "./guest_cells/CategoryCell.react";
import BadgesCell from "./guest_cells/BadgesCell.react";
import ConfirmationEmailCell from "./guest_cells/ConfirmationEmailCell.react";
import PaymentCell from "./guest_cells/PaymentCell.react";
import RegistrationFormCell from "./guest_cells/RegistrationFormCell.react";
import ContactModalCell from "./guest_cells/ContactModalCell.react";
import QuartileCell from "./guest_cells/QuartileCell.react";
import StandardizedScoreCell from "./guest_cells/StandardizedScoreCell.react";

import {
  IDENTITY_CELL,
  CATEGORY_CELL,
  EMAIL_CELL,
  BADGES_CELL,
  PRINT_BADGES_CELL,
  PAYMENT_CELL,
  CONTACT_MODAL_CELL,
  COLUMN_STANDARD_FIELD_TYPE,
  COLUMN_LINK_TYPE,
  REGISTRATION_FORM_CELL,
  COLUMN_QUARTILE_THEMATIC_TYPE,
  COLUMN_STANDARD_SCORE_THEMATIC_TYPE
} from "../constants/Constants";

class GuestCell extends Component {
  constructor(props) {
    super(props);
    this.quotaValue = this.quotaValue.bind(this);
  }

  timeAgoInWords(date) {
    if (date) {
      moment.locale(I18n.currentLocale());
      if (moment().diff(date, "days") < 7) {
        return moment(date).calendar();
      } else {
        return moment(date).format("lll");
      }
    } else {
      return (<center> - </center>);
    }
  }

  localizedDate(date) {
    if (date) {
      moment.locale(I18n.currentLocale());
      return moment(date).format("LLL");
    } else {
      return ("");
    }
  }

  quotaValue(number) {
    return parseInt(number) === -1 ? I18n.t("unlimited") : number;
  }

  standardFieldCell() {
    const { guest, identifier, labels, setFilteredGuestCategories, updateSearchBar, event } = this.props;
    const DEFAULT_CAMPAIGN_OPTIN_TYPE = "default";
    switch (identifier) {
    case "event_campaign_optin": {
      let field = null;
      if (event.campaign_optin_type === DEFAULT_CAMPAIGN_OPTIN_TYPE) {
        field = guest.gdpr_event_campaign_optin;
      } else {
        if (event.campaign_optin_field_key === "event_campaign_optin") {
          field = guest.event_campaign_optin;
        } else {
          let meta = guest.guest_metadata.find(metadata => metadata.name === event.campaign_optin_field_key);
          field = meta && meta.value.toLowerCase() === "true";
        }
      }
      return <TextCell guest={guest} directValue={`${event.campaign_optin_field_value === field}`} updateSearchBar={updateSearchBar} />;
    }
    case IDENTITY_CELL:
      return <IdentityCell guest={guest} labels={labels || []} />;
    case CATEGORY_CELL:
      return <CategoryCell guest={guest} setFilteredGuestCategories={setFilteredGuestCategories} />;
    case PAYMENT_CELL:
      return <PaymentCell guest={guest} />;
    case "status":
      return <TextCell guest={guest} field={identifier} options={{ translateFn: (str) => { return I18n.t(str); }, labeled: true }} updateSearchBar={updateSearchBar}/>;
    case "company_name":
    case "company_id_number":
      return <TextCell guest={guest} field={identifier} options={{ truncateLength: 18 }} updateSearchBar={updateSearchBar}/>;
    case "registered_at":
    case "created_at":
    case "updated_at":
      return <TextCell guest={guest} field={identifier} options={{ translateFn: this.timeAgoInWords, title: this.localizedDate(guest[identifier]) }} updateSearchBar={updateSearchBar}/>;
    case CONTACT_MODAL_CELL:
      return <ContactModalCell guest={guest} event={event} />;
    case "engagement_quartile":
      return this.quartileCell();
    case "engagement_std_score":
      return this.standardizedScoreCell();
    case "invitations_quota":
      return <TextCell guest={guest} field={identifier} options={{ translateFn: this.quotaValue }} />;
    default:
      if (identifier.startsWith("invitations_quota_mapping")) {
        return this.invitationsQuotaMappingCell();
      } else if (identifier.startsWith("collections_quota_")) {
        return this.collectionsQuotaCell();
      } else {
        return <TextCell guest={guest} field={identifier} updateSearchBar={updateSearchBar} />;
      }
    }
  }

  guestFieldCell() {
    const { guest, identifier, updateSearchBar, allowMultipleValues } = this.props;
    return <TextCell guest={guest} field={identifier} updateSearchBar={updateSearchBar} allowMultipleValues={allowMultipleValues}/>;
  }

  linkCell() {
    const { guest, identifier, guestCategories, updateSearchBar } = this.props;

    if (guest.takeout) return <td></td>;

    switch (identifier) {
    case BADGES_CELL:
      return <BadgesCell guest={guest} guestCategories={guestCategories} />;
    case PRINT_BADGES_CELL:
      return <BadgesCell guest={guest} guestCategories={guestCategories} autoPrint={true} />;
    case EMAIL_CELL:
      return <ConfirmationEmailCell guest={guest} />;
    case REGISTRATION_FORM_CELL:
      return <RegistrationFormCell guest={guest} guestCategories={guestCategories} />;
    }
    // else might be a doc
    let docURL = (guest.documents || []).find(docURL => docURL.includes(`document_templates/${identifier}`));
    return <TextCell guest={guest} directValue={docURL} updateSearchBar={updateSearchBar}/>;
  }

  quartileCell(thematicField = false) {
    const { identifier, guest } = this.props;
    let quartile = guest[identifier];
    let absoluteScore = guest.engagement_score;
    let thematicScoring;

    if (thematicField) {
      quartile = guest["thematics_quartile"] ? guest["thematics_quartile"][identifier.split("_")[1]] : null;
      thematicScoring = (guest.thematic_scorings || []).find(ts => ts.thematic_id === identifier.split("_")[1]);
      absoluteScore = thematicScoring ? thematicScoring.score : null;
    }
    if (quartile) {
      return <QuartileCell absoluteScore={absoluteScore} quartileValue={quartile} guest={guest} thematicScoring={thematicScoring} label={I18n.t(`guests.table_guest.guest_quartiles.${quartile.toString()}_value`)} />;
    } else {
      return <td className="cell-default d-none d-sm-table-cell"></td>;
    }
  }

  invitationsQuotaMappingCell() {
    const { identifier, guest } = this.props;

    const guestCategoryId = identifier.split("_").pop();
    const directValue = guest.invitations_quota_mapping[guestCategoryId];
    return <TextCell field={"invitations_quota_mapping"} directValue={ directValue ? directValue.toString() : undefined } options={{ translateFn: this.quotaValue }} />;
  }

  collectionsQuotaCell() {
    const { identifier, guest } = this.props;

    const collectionId = identifier.split("_").pop();
    const directValue = guest.collections_quota[collectionId];
    return <TextCell field={"collections_quota"} directValue={ directValue ? directValue.toString() : undefined } options={{ translateFn: this.quotaValue }} />;
  }

  standardizedScoreCell(thematicField = false) {
    const { identifier, guest } = this.props;
    let value = guest[identifier];
    let absoluteValue = guest[identifier.replace("std_", "")];
    let thematicScoring;

    if (thematicField) {
      thematicScoring = guest.thematic_scorings && guest.thematic_scorings.find(ts => ts.thematic_id === identifier.split("_")[1]);
      value = thematicScoring ? thematicScoring.std_score : null;
      absoluteValue = thematicScoring ? thematicScoring.score : null;
    }

    if (value) {
      return <StandardizedScoreCell value={value} absoluteValue={absoluteValue} guest={guest} thematicScoring={thematicScoring}/>;
    } else {
      return <td className="cell-default d-none d-sm-table-cell"></td>;
    }
  }

  render() {
    const { type } = this.props;
    switch (type) {
    case COLUMN_STANDARD_SCORE_THEMATIC_TYPE:
      return this.standardizedScoreCell(true);
    case COLUMN_QUARTILE_THEMATIC_TYPE:
      return this.quartileCell(true);
    case COLUMN_STANDARD_FIELD_TYPE:
      return this.standardFieldCell();
    case COLUMN_LINK_TYPE:
      return this.linkCell();
    default:
      return this.guestFieldCell();
    }
  }
}

GuestCell.defaultProps = {
  labels: [],
  guestCategories: []
};

GuestCell.propTypes = {
  guest: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired, // guest_fields, standard_guest_fields, document
  identifier: PropTypes.string.isRequired, // guest_field key, predefinepd id, document _id
  event: PropTypes.object.isRequired,
  labels: PropTypes.arrayOf(PropTypes.object),
  guestCategories: PropTypes.arrayOf(PropTypes.object),
  setFilteredGuestCategories: PropTypes.func.isRequired
};

export default GuestCell;
