import { useEffect, useState } from "react";
import Select, { createFilter } from "react-select";
import { Partner } from "../../../types/Partner";
import Loader from "../../shared/Loader.react";

interface Props {
  eventId: string;
  excludeSdkIds: (string|number)[]
  style?: React.CSSProperties;
  onSelect: (partner: Partner) => void;
}

type Option = {
  label: string;
  value: string|number;
}

const DidomiPartnersPicker: React.FC<Props> = ({
  eventId,
  style,
  excludeSdkIds,
  onSelect
}) => {
  const [partners, setPartners] = useState([]);
  const [options, setOptions] = useState([]);
  const [partnersMapping, setPartnersMapping] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchPartners();
  }, []);

  useEffect(() => {
    const result = { partnersMapping: {}, options: [] };

    partners.filter(p => !excludeSdkIds.includes(p.sdk_id)).forEach(partner => {
      result.partnersMapping[partner.sdk_id] = partner;
      result.options.push(partnerToOption(partner));
    });

    setOptions(result.options);
    setPartnersMapping(result.partnersMapping);
  }, [partners, excludeSdkIds]);

  const fetchPartners = (): void => {
    fetch(`/api/v1/events/${eventId}/website/didomi_partners.json`)
      .then(res => res.json())
      .then(({ partners }) => {
        setPartners(partners);
        setLoading(false);
      });
  };

  const partnerToOption = ({ sdk_id, name }): Option => {
    return {
      value: sdk_id,
      label: name
    };
  };

  if (loading) return <Loader size="small" />;

  return <div style={style}>
    <Select
      style={style}
      className="react-select"
      classNamePrefix="react-select"
      isMulti={false}
      value={null}
      placeholder={I18n.t("please_choose")}
      options={options}
      filterOption={createFilter({ ignoreAccents: false })} // keep this for performance
      onChange={(option): void => {
        onSelect(partnersMapping[option.value]);
      }}
    />
  </div>;
};

export default DidomiPartnersPicker;
