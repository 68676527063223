import { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import Modal from "react-modal";
import { defaultModalBigStyles } from "../../constants/Style";
import EmailTemplates from "../email_templates/EmailTemplates.react.js";

const modalBodyHeight = "65vh";
const toolbarHeight = "40px";
const searchbarHeight = "50px";
const borderStyle = "1px solid #E3E3E3";

class EmailTemplatePickerModal extends Component {
  constructor(props) {
    super(props);
    [
      "submit"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  refreshPage() {
    window.frames["template-preview-iframe"].contentWindow.location.reload();
  }

  submit() {
    const { onSelect, selectedTemplateId } = this.props;
    onSelect(selectedTemplateId);
  }

  renderFooter() {
    const { templates } = this.props;

    if (!templates || templates.length == 0) return null;

    return (
      <div>
        <Button onClick={ this.refreshPage } variant="secondary mr-5">
          <i className='fa-regular fa-arrows-rotate mr-5'></i>
          { I18n.t("react.email_template_picker_modal.refresh") }
        </Button>

        <Button onClick={ this.submit } variant="primary">
          { I18n.t("react.email_template_picker_modal.use_this_email") }
        </Button>
      </div>
    );
  }

  render() {
    const { isVisible, onClose, locale, eventId, selectedTemplateId, guestId } = this.props;

    return (
      <Modal isOpen={isVisible} onRequestClose={onClose} style={defaultModalBigStyles} contentLabel="Modal">
        <div className="modal-header" key="modal-header">
          <h4 className="modal-title">{ I18n.t("react.email_template_picker_modal.choose_email") }</h4>
          <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
        </div>
        <div className="modal-body" key="modal-body" style={{ padding: "0px" }}>
          <EmailTemplates
            locale={locale}
            eventId={eventId}
            borderStyle={borderStyle}
            listBodyHeight={modalBodyHeight}
            toolbarHeight={toolbarHeight}
            searchbarHeight={searchbarHeight}
            selectedTemplateId={selectedTemplateId}
            inModal={true}
            iframeAutoResize={false}
            loaderContainerHeight={modalBodyHeight}
            enableDelete={false}
            enableEdit={true}
            defaultCreateButton={true}
            guestId={guestId}
          />
        </div>
        <div className="modal-footer" key="modal-footer">
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }
}

EmailTemplatePickerModal.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.object),
  selectedTemplateId: PropTypes.string,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  guestId: PropTypes.string
};

EmailTemplatePickerModal.defaultProps = {
  isVisible: false
};

export default EmailTemplatePickerModal;
