import { Component } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import Loader from "../shared/Loader.react";

import { pathToPromoCodes, pathToEventSmtpConfig, pathToGuestCategoryShow, pathToGuestCategoriesList } from "../../utils/pathUtils";
import { isEnabled } from "../../utils/featureSetUtils";
import { hoursOptions, minutesOptions } from "../../utils/DateUtils.js";
import { CATEGORY_METADATA, CHECKIN, PROMO_CODES, EXHIBITOR_INVITATIONS } from "../../constants/FeaturesSet";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import ColorPicker from "../ColorPicker.react";

import ErrorMessage from "./ErrorMessage.react";
import MetadataForm from "./MetadataForm.react";
import DatePicker from "./DatePicker.react";
import { populationI18n } from "../../utils/miscUtils";
import { COLORS } from "../../constants/Constants";
import LimitSelector from "../shared/LimitSelector.react";
import HelpSection from "../shared/HelpSection.react";


const isSuperAdmin = window["ReactGlobalProps"]["super_admin"];

class GuestCategoryForm extends Component {

  constructor(props) {
    super(props);
    [
      "changeDateSelector",
      "colorPicked",
      "createOrUpdateGuestCategory",
      "onPopulationTypeChange",
      "toggleCheckboxHandler",
      "updateField",
      "updateDefaultInvitationsQuota",
      "updateDefaultExhibitorInvitationsQuota",
      "updateDefaultProductsQuota",
      "updateDefaultCollectionsQuota",
      "updateTraits"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      name: this.props.proposedName || "",
      labelColor: COLORS[Math.floor(COLORS.length * Math.random())],
      uniqueEmails: false,
      registrationsClosed: false,
      editRegistrationsClosed: false,
      promoCodeRequired: false,
      traits: [],
      pendingQuery: false,
      populationType: null,
      temporaryPopulationTypes: [],
      defaultCategory: false,
      defaultForPopulation: false,
      momentRegistrationsStartDate: moment().hours(0).minutes(0),
      momentRegistrationsEndDate: moment().hours(0).minutes(0).add(1, "years"),
      momentEditRegistrationsStartDate: moment().hours(0).minutes(0),
      momentEditRegistrationsEndDate: moment().hours(0).minutes(0).add(1, "years"),
      invitationsEnabled: false,
      sendInvitationsEnabled: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { guestCategory, errors } = this.props;
    if (isEmpty(prevProps.errors) && !isEmpty(errors)) {
      this.setState({ pendingQuery: false });
    }
    if (prevProps.guestCategory && Object.keys(prevProps.guestCategory).length > 0 && Object.keys(guestCategory).length === 0) {
      // if the url change to guest_fields/:id to guest_fields/new
      this.setState(Object.assign({}, prevProps.defaultState, { showImportModal: false }));
    } else if (!isEmpty(guestCategory) && isEmpty(prevProps.guestCategory)) {
      this.setState(this.guestCategoryParamsToSetState(guestCategory));
    }
  }

  guestCategoryParamsToSetState(guestCategory) {
    const { registrations_start_date_to_timezone, registrations_end_date_to_timezone, edit_registrations_start_date_to_timezone,
      edit_registrations_end_date_to_timezone, name, label_color, unique_emails, registrations_closed, edit_registrations_closed,
      promo_code_required, traits, population_type, default_category, default_for_population, invitations_enabled, default_invitations_quota,
      default_products_quota, default_exhibitor_invitations_quota, send_invitations_enabled, default_collections_quota } = guestCategory;

    const traitObjectToArray = Object.entries(traits).map(([key, value]) => {
      return { key, value };
    });

    const momentRegistrationsStartDate = ((registrations_start_date_to_timezone && moment(registrations_start_date_to_timezone, `${I18n.t("datetime_picker_js_format")} HH:mm`)) || moment());
    const momentRegistrationsEndDate = ((registrations_end_date_to_timezone && moment(registrations_end_date_to_timezone, `${I18n.t("datetime_picker_js_format")} HH:mm`)) || moment());
    const momentEditRegistrationsStartDate = ((edit_registrations_start_date_to_timezone && moment(edit_registrations_start_date_to_timezone, `${I18n.t("datetime_picker_js_format")} HH:mm`)) || moment());
    const momentEditRegistrationsEndDate = ((edit_registrations_end_date_to_timezone && moment(edit_registrations_end_date_to_timezone, `${I18n.t("datetime_picker_js_format")} HH:mm`)) || moment());

    return {
      name,
      default_products_quota,
      default_collections_quota,
      labelColor: label_color,
      uniqueEmails: unique_emails,
      registrationsClosed: registrations_closed,
      momentRegistrationsStartDate,
      momentRegistrationsEndDate,
      momentEditRegistrationsStartDate,
      momentEditRegistrationsEndDate,
      editRegistrationsClosed: edit_registrations_closed,
      promoCodeRequired: promo_code_required,
      traits: traitObjectToArray,
      populationType: population_type,
      defaultCategory: default_category,
      defaultForPopulation: default_for_population,
      invitationsEnabled: invitations_enabled,
      defaultInvitationsQuota: default_invitations_quota,
      defaultExhibitorInvitationsQuota: default_exhibitor_invitations_quota,
      sendInvitationsEnabled: send_invitations_enabled,
    };
  }

  createOrUpdateGuestCategory(e) {
    e.preventDefault();
    e.stopPropagation();
    const { onSubmit, guestCategory } = this.props;
    const { name, labelColor, uniqueEmails, momentRegistrationsStartDate, momentRegistrationsEndDate, momentEditRegistrationsStartDate,
      momentEditRegistrationsEndDate, promoCodeRequired, traits, populationType, defaultCategory, defaultForPopulation, invitationsEnabled,
      defaultInvitationsQuota, default_products_quota, default_collections_quota, defaultExhibitorInvitationsQuota, sendInvitationsEnabled } = this.state;

    const traitsCleared = {};
    traits.forEach(trait => {
      traitsCleared[trait.key] = trait.value;
    });
    let data = {
      name,
      default_products_quota,
      default_collections_quota,
      label_color: labelColor,
      unique_emails: uniqueEmails,
      promo_code_required: promoCodeRequired,
      traits: traitsCleared,
      population_type: populationType,
      default_category: defaultCategory,
      default_for_population: defaultForPopulation,
      invitations_enabled: invitationsEnabled,
      default_invitations_quota: defaultInvitationsQuota,
      default_exhibitor_invitations_quota: defaultExhibitorInvitationsQuota,
      send_invitations_enabled: sendInvitationsEnabled,
    };
    if (guestCategory) {
      data.local_registrations_start_date = momentRegistrationsStartDate.utcOffset(0, true).format();
      data.local_registrations_end_date = momentRegistrationsEndDate.utcOffset(0, true).format();
      data.local_edit_registrations_start_date = momentEditRegistrationsStartDate.utcOffset(0, true).format();
      data.local_edit_registrations_end_date = momentEditRegistrationsEndDate.utcOffset(0, true).format();
    }
    onSubmit(data);
    this.setState({ pendingQuery: true });
  }

  colorPicked(color) {
    this.setState({ labelColor: color });
  }

  updateField(key) {
    return (e) => {
      this.setState({ [key]: e.target.value });
    };
  }

  updateDefaultInvitationsQuota(quota) {
    this.setState({ defaultInvitationsQuota: quota });
  }

  updateDefaultExhibitorInvitationsQuota(quota) {
    this.setState({ defaultExhibitorInvitationsQuota: quota });
  }

  updateDefaultProductsQuota(quota) {
    this.setState({ default_products_quota: quota });
  }

  updateDefaultCollectionsQuota(collection_id) {
    return (quota) => {
      this.setState({ default_collections_quota: { ...this.state.default_collections_quota, [collection_id]: quota } });
    };
  }

  toggleCheckboxHandler(key) {
    return () => {
      const current = this.state[key];
      this.setState({
        [key]: !current
      });
    };
  }

  updateTraits(traits) {
    this.setState({ traits });
  }

  availablePopulationTypes() {
    const { populationTypes } = this.props;
    const { temporaryPopulationTypes } = this.state;
    return populationTypes.concat(temporaryPopulationTypes);
  }

  onPopulationTypeChange(change) {
    const populationType = change ? change.value : null;
    const update = {
      populationType
    };
    if (populationType && !this.availablePopulationTypes().includes(populationType)) {
      update["temporaryPopulationTypes"] = this.state.temporaryPopulationTypes.concat([populationType]);
    }
    this.setState(update);
  }

  renderTitle() {
    const { isModal, isEdit, guestCategoryId } = this.props;

    if (isModal) return null;

    return <div className="header-page">
      <div className="header-page-title">
        <h1>
          <a href={isEdit ? pathToGuestCategoryShow(guestCategoryId) : pathToGuestCategoriesList()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></a>
          { isEdit ? I18n.t("guest_categories.edit.edit_guest_category") : I18n.t("guest_categories.new.new_guest_category") }
        </h1>
      </div>
    </div>;
  }

  renderSaveBtn() {
    const { isEdit, isModal } = this.props;
    const value = isEdit ? I18n.t("react.guest_category.form.save_changes") : I18n.t("react.guest_category.form.create");
    return (
      <div>
        <input type="submit" className={`btn btn-primary ${isModal && "float-end"}`} value={value}></input>
      </div>
    );
  }

  changeDateSelector(field) {
    return (value) => {
      const date = this.state[field];
      const hours = date.hours();
      const minutes = date.minutes();
      const newDate = moment(value).hours(hours).minutes(minutes);
      this.setState({ [field]: newDate });
    };
  }

  updateTime(field, key = "hours") {
    return (e) => {
      const newState = { ...this.state };
      const fieldValue = newState[field];
      if (key == "hours") newState[field] = fieldValue.hours(e.target.value);
      else newState[field] = fieldValue.minutes(e.target.value);
      this.setState(newState);
    };
  }

  renderDatePicker(field) {
    const momentDate = this.state[field];
    return <div className="g-2 row align-items-center">
      <div className="col-auto">
        <DatePicker
          selectedDate={new Date(momentDate.format())}
          onChange={this.changeDateSelector(field)}
        />
      </div>
      <div className="col-auto">
        <div className="g-2 row align-items-center time-part">
          <div className="col-auto">
            <select id="start_hour" className="form-select" onChange={this.updateTime(field)} value={momentDate.hours()}>
              {hoursOptions()}
            </select>
          </div>
          <div className="col-auto">
            <select id="start_minute" className="form-select" onChange={this.updateTime(field, "minutes")} value={Math.round(momentDate.minutes() / 5) * 5}>
              {minutesOptions()}
            </select>
          </div>
        </div>
      </div>
    </div>;
  }

  renderRegistrationForm() {
    const { guestCategory } = this.props;

    if (guestCategory && !guestCategory.custom_registration_form_enabled) {
      return null;
    }

    return <div className="mb-10 card">
      <div className="card-header">
        <h4 className="card-title">{I18n.t("guest_categories.form.registrations_dates")}</h4>
      </div>
      <div className="card-body">
        <div className="mb-3 row">
          <div className="col-md-6">
            <label className="form-label">{I18n.t("guest_categories.form.registrations_start_date")}</label>
            {this.renderDatePicker("momentRegistrationsStartDate")}
          </div>
          <div className="col-md-6">
            <label className="form-label">{I18n.t("guest_categories.form.registrations_end_date")}</label>
            {this.renderDatePicker("momentRegistrationsEndDate")}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">{I18n.t("guest_categories.form.edit_registrations_start_date")}</label>
            {this.renderDatePicker("momentEditRegistrationsStartDate")}
          </div>
          <div className="col-md-6">
            <label className="form-label">{I18n.t("guest_categories.form.edit_registrations_end_date")}</label>
            {this.renderDatePicker("momentEditRegistrationsEndDate")}
          </div>
        </div>
      </div>
    </div>;
  }

  renderPopulationTypePicker() {
    const { populationType } = this.state;
    const populationName = populationType && { value: populationType, label: populationI18n(populationType) };
    const options = this.availablePopulationTypes().map(opt => {
      return { value: opt, label: populationI18n(opt) };
    });

    return (
      <CreatableSelect
        className={"react-select"}
        classNamePrefix={"react-select"}
        value={populationName}
        options={options}
        isClearable={true}
        onChange={this.onPopulationTypeChange}
        formatCreateLabel={(label) => I18n.t("guest_categories.form.population_type_prompt_text_creator", { label: label })}
        placeholder={I18n.t("guest_categories.form.population_type_placeholder")} />
    );
  }

  renderPopulationSection() {
    const { isModal } = this.props;
    const { defaultCategory, populationType, defaultForPopulation } = this.state;

    return <div className="mb-10 card">
      <div className="card-header">
        <h4 className="card-title">{I18n.t("guest_categories.form.population")}</h4>
      </div>
      <div className="card-body">
        <div className="mb-3 row">
          <div className={isModal ? "col-md-12" : "col-lg-8 col-md-9"}>
            { this.renderPopulationTypePicker() }
          </div>
        </div>
        <div className="form-check">
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input" checked={defaultCategory} onChange={this.toggleCheckboxHandler("defaultCategory")}/>
            {I18n.t("guest_categories.form.default_category_help")}
          </label>
        </div>
        { populationType ? (
          <div className="form-check">
            <label className="form-check-label">
              <input type="checkbox" className="form-check-input" checked={defaultForPopulation} onChange={this.toggleCheckboxHandler("defaultForPopulation")}/>
              {I18n.t("guest_categories.form.default_for_population_help", { populationType: populationI18n(populationType) })}
            </label>
          </div>
        ) : null}
      </div>
    </div>;
  }

  renderExhibitorInvitationSection() {
    const { event } = this.props;
    const { invitationsEnabled, defaultInvitationsQuota, defaultExhibitorInvitationsQuota, sendInvitationsEnabled } = this.state;
    if (!isEnabled(EXHIBITOR_INVITATIONS)) return null;
    if (!event.custom_smtp_settings_enabled && !isSuperAdmin) return null;

    const helpMessage = <span dangerouslySetInnerHTML={{ __html: I18n.t("guest_categories.form.default_invitations_quota_help") }} />;

    return <div className="mb-10 card">
      <div className="card-header">
        <h4 className="card-title">{I18n.t("guest_categories.form.exhibitor_invitations")}</h4>
      </div>
      <div className="card-body">
        <HelpSection help={helpMessage} />

        <div className="form-check">
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input" checked={sendInvitationsEnabled} onChange={this.toggleCheckboxHandler("sendInvitationsEnabled")} />
            {I18n.t("guest_categories.form.send_invitations_enabled")}
            {sendInvitationsEnabled && this.renderSmtpConfigLink()}
          </label>
        </div>

        {sendInvitationsEnabled && (
          <div className="mt-3">
            <LimitSelector
              value={defaultExhibitorInvitationsQuota}
              onChange={this.updateDefaultExhibitorInvitationsQuota}
              controlLabel={I18n.t("mongoid.attributes.guest_category.default_exhibitor_invitations_quota")}
              i18nRootPath="guest_categories.form"
            />
          </div>
        )}

        <div className="form-check">
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input" checked={invitationsEnabled} onChange={this.toggleCheckboxHandler("invitationsEnabled")} />
            {I18n.t("guest_categories.form.invitations_enabled")}
            {invitationsEnabled && this.renderSmtpConfigLink()}
          </label>
        </div>

        {invitationsEnabled && (
          <div className="mt-3 no-margin">
            <LimitSelector
              value={defaultInvitationsQuota}
              onChange={this.updateDefaultInvitationsQuota}
              controlLabel={I18n.t("mongoid.attributes.guest_category.default_invitations_quota")}
              i18nRootPath="guest_categories.form"
            />
          </div>
        )}
      </div>
    </div>;
  }

  renderExhibitorProductSection() {
    const { default_products_quota } = this.state;

    return <div className="mb-10 card">
      <div className="card-header">
        <h4 className="card-title">
          {I18n.t("guest_categories.form.exhibitor_products") + " "}
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-bar-step">{I18n.t("guest_categories.form.default_products_quota_help")}</Tooltip>}>
            <i className="fa-regular fa-info-circle" aria-hidden="true"/>
          </OverlayTrigger>
        </h4>
      </div>
      <div className="card-body no-margin">
        <LimitSelector
          value={default_products_quota}
          onChange={this.updateDefaultProductsQuota}
          controlLabel={I18n.t("mongoid.attributes.guest_category.default_products_quota")}
          i18nRootPath="guest_categories.form"
        />
      </div>
    </div>;
  }

  renderExhibitorCollectionsSections() {
    const { guestProductCollections } = this.props;

    if (!guestProductCollections) return null;

    return guestProductCollections.map(collection => this.renderExhibitorCollectionSection(collection));
  }

  renderExhibitorCollectionSection(collection) {
    const { isModal } = this.props;
    const { default_collections_quota } = this.state;
    if (!default_collections_quota) return null;

    const default_collection_quota = default_collections_quota[collection._id] || 0;

    return <div key={collection._id} className={`up-md card ${isModal ? "card-modal" : ""}`}>
      <div className="card-header">
        <h4 className="card-title">
          {collection.name}
          { " " }
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-bar-step">{I18n.t("guest_categories.form.default_collection_quota_help", { collection_name: collection.name })}</Tooltip>}>
            <i className="fa-regular fa-info-circle" aria-hidden="true"/>
          </OverlayTrigger>
        </h4>
      </div>
      <div className="card-body">
        <LimitSelector
          value={default_collection_quota}
          onChange={this.updateDefaultCollectionsQuota(collection._id)}
          controlLabel={I18n.t("guest_categories.form.default_collection_quota")}
          i18nRootPath="guest_categories.form"
        />
      </div>
    </div>;
  }

  renderGuestCategoryDetails() {
    const { name, uniqueEmails, labelColor } = this.state;
    return (
      <div className="mb-10 card">
        <div className="card-header">
          <h4 className="card-title">{I18n.t("guest_categories.form.guest_category_details")}</h4>
        </div>
        <div className="card-body">
          <div className="mb-3 row">
            <div className="col-md-6">
              <label className="form-label">{I18n.t("mongoid.attributes.guest_category.name")}</label>
              <input className="form-control" type="text" value={name} onChange={this.updateField("name")}/>
            </div>
            <div className="col-md-6">
              <label className="form-label">{I18n.t("mongoid.attributes.guest_category.label_color")}</label><br />
              <ColorPicker setColor={this.colorPicked} selectedColor={labelColor}/>
            </div>
          </div>
          <div>
            <label className="form-label">{I18n.t("guest_categories.form.unique_emails_label")}</label>
            <div className="form-check">
              <label className="form-check-label">
                <input type="checkbox" className="form-check-input" checked={uniqueEmails} onChange={this.toggleCheckboxHandler("uniqueEmails")}/>
                {I18n.t("guest_categories.form.unique_emails_help")}
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDiscountCode() {
    if (!isEnabled(PROMO_CODES)) return null;

    const { promoCodeRequired } = this.state;

    const helpMessage = <>{I18n.t("guest_categories.form.promo_code_page")} <a href={pathToPromoCodes()} target="_blank">{I18n.t("guest_categories.form.this_page")}</a>.</>;

    return <div className="mb-10 card">
      <div className="card-header">
        <h4 className="card-title">{I18n.t("guest_categories.form.promo_codes")}</h4>
      </div>
      <div className="card-body">
        <HelpSection help={helpMessage} />
        <div>
          <div className="form-check">
            <label className="form-check-label">
              <input type="checkbox" className="form-check-input" checked={promoCodeRequired} onChange={this.toggleCheckboxHandler("promoCodeRequired")}/>
              {I18n.t("mongoid.attributes.guest_category.promo_code_required")}
            </label>
          </div>
        </div>
      </div>
    </div>;
  }

  renderMetadata() {
    if (!isEnabled(CATEGORY_METADATA)) return;

    const { traits } = this.state;

    return <div className="mb-10 card">
      <div className="card-header">
        <h4 className="card-title">{I18n.t("guest_categories.form.metadata")}</h4>
      </div>
      <div className="card-body">
        <MetadataForm traits={traits} updateParent={this.updateTraits} markDestroy={true} displayLegend={false} />
      </div>
    </div>;
  }

  renderErrorsMessage() {
    return <ErrorMessage errors={ this.props.errors } model="guest_category" />;
  }

  renderSmtpConfigLink() {
    if (!isSuperAdmin) return null;

    const { event } = this.props;
    return (
      <span className="pl-15">
        <a href={pathToEventSmtpConfig(event.id)} className="" target="_blank">{I18n.t("guest_categories.form.configure_smtp")}</a>
      </span>
    );
  }

  render() {
    const { pendingQuery } = this.state;
    const { accesspoints, guestCategory, isEdit } = this.props;

    if (pendingQuery || (isEdit && isEmpty(guestCategory))) {
      return <Loader />;
    }

    if (!isEdit && accesspoints.length === 0 && isEnabled(CHECKIN)) {
      return <div className="alert alert-danger alert-dismissible">
        {I18n.t("guest_categories.form.no_accesspoint")}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label={I18n.t("close")}></button>
      </div>;
    }

    return <form className="guest-category-form form-horizontal" onSubmit={this.createOrUpdateGuestCategory}>
      { this.renderTitle() }
      { this.renderErrorsMessage() }
      { this.renderGuestCategoryDetails() }
      { this.renderRegistrationForm() }
      { this.renderDiscountCode() }
      { this.renderPopulationSection() }
      { this.renderExhibitorInvitationSection() }
      { this.renderExhibitorProductSection() }
      { this.renderExhibitorCollectionsSections() }
      { this.renderMetadata() }
      { this.renderSaveBtn() }
    </form>;
  }
}

export default GuestCategoryForm;

GuestCategoryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isModal: PropTypes.bool,
  accesspoints: PropTypes.array.isRequired,
  errors: PropTypes.object,
  proposedName: PropTypes.string,
  guestCategory: PropTypes.object,
  populationTypes: PropTypes.arrayOf(PropTypes.string),
  isSuperAdmin: PropTypes.bool
};


GuestCategoryForm.defaultProps = {
  isModal: false,
  isEdit: false
};
