import { Component } from "react";
import { connect } from "react-redux";
import AccessPrivilegeRow from "../components/AccessPrivilegeRow.react";
import * as guestCategoryAccesspointActionCreators from "../actions/GuestCategoryAccesspointActionCreators";
import { fetchAccesspoints, fetchAccesspoint } from "../actions/AccesspointActionCreators";
import { fetchGuestCategory } from "../actions/GuestCategoryActionCreators";
import { importGuestCategories } from "../actions/ImportGuestCategoryActionCreators";
import { urlBase, pathToGuestCategoryShow, pathToAccesspointEdit } from "../utils/pathUtils";
import { accesspointFromBundles } from "../utils/bundleUtils";
import AccessPrivilegeDropdown from "../components/AccessPrivilegeDropdown.react";
import { urlEventId } from "../utils/pathUtils";
import Paginate from "../components/Paginate.react";
import ErrorMessage from "../components/shared/ErrorMessage.react";
import HelpSection from "../components/shared/HelpSection.react";
import { extractPaginationPageNumbers } from "../utils/QueryStringUtils";

class GuestCategoryAccesspoints extends Component {
  constructor(props) {
    super(props);

    [
      "deleteGuestCategoryAccesspoint",
      "handleSelectAllPaginatedResources",
      "handleSelectOneResource",
      "updateOrCreateGuestCategoryAccesspoint",
      "massUpdateOrCreateGuestCategoryAccesspoints",
      "massDeleteGuestCategoryAccesspoints",
    ].forEach(method => this[method] = this[method].bind(this));

    this.state = {
      allPaginatedResourcesSelected: false,
      selectedResourceIds: [],
      paginatedResources: [],
      previousPageNumber: null,
      nextPageNumber: null,
      bundlesReceived: false
    };
  }

  componentDidMount() {
    const { fetchAccesspoints, fetchAccesspoint, fetchGuestCategoryAccesspoints, importGuestCategories, fetchGuestCategory, match, options } = this.props;
    const { params } = match;
    const { mode } = options;
    if (mode === "accesspoints") {
      fetchAccesspoints(params.event_id, "", 0, { include: ["accesspoint_bundle"], uniqueKeyInSiloedData: "bundles", searchFilters: { type: ["bundle"] } });
      fetchGuestCategory(params.event_id, params.guest_category_id);
      fetchGuestCategoryAccesspoints(params.event_id, null, params.guest_category_id);
    } else if (mode === "guest_categories") {
      fetchAccesspoint(urlEventId(), params.accesspoint_id);
      importGuestCategories(1);
      fetchGuestCategoryAccesspoints(params.event_id, params.accesspoint_id, null);
    }
  }

  componentDidUpdate(prevProps) {
    const { performingMassAction, options, accesspointsFetched, match } = this.props;
    if (!prevProps.performingMassAction && performingMassAction) {
      this.refreshAfterBatchCreateOrUpdate();
    }

    const { bundlesReceived } = this.state;
    const { params } = match;
    if (!bundlesReceived && options.mode === "accesspoints" && !prevProps.accesspointsFetched && accesspointsFetched) {
      this.setState({ bundlesReceived: true });
      const { fetchAccesspoints } = this.props;
      fetchAccesspoints(params.event_id, "", 1);
      return;
    }

    if (this.accesspointsReceived(prevProps) || this.guestCategoriesReceived(prevProps)) {
      this.populatePaginatedResources();
    }
  }

  accesspointsReceived(prevProps) {
    const { accesspointsFetched, options } = this.props;
    return options.mode === "accesspoints" && !prevProps.accesspointsFetched && accesspointsFetched;
  }

  guestCategoriesReceived(prevProps) {
    const { guestCategoriesFetched, options } = this.props;
    return options.mode === "guest_categories" && !prevProps.guestCategoriesFetched && guestCategoriesFetched;
  }

  refreshAfterBatchCreateOrUpdate() {
    this.setState({
      allPaginatedResourcesSelected: false,
      selectedResourceIds: [],
    });
  }

  i18n(key, withMode = false, options = {}) {
    return I18n.t(`react.guest_category_accesspoints.${withMode ? `${this.props.options.mode}.${key}` : key}`, options);
  }

  bundles() {
    const { siloedData } = this.props;
    return siloedData["bundles"] || [];
  }

  goToPage(pageNumber) {
    return (e) => {
      e.preventDefault();
      if (!pageNumber) return;

      const { fetchAccesspoints, importGuestCategories, options, match } = this.props;
      if (options.mode === "accesspoints") {
        const { params } = match;
        fetchAccesspoints(params.event_id, "", pageNumber);
      } else {
        importGuestCategories(pageNumber);
      }
    };
  }

  renderCheckbox(name, checked, onChangeHandler) {
    return <input type="checkbox" className="form-check-input" checked={checked} name={name} onChange={onChangeHandler} />;
  }

  populatePaginatedResources() {
    const { previousURL, nextURL } = this.props;
    const [ previousPageNumber, nextPageNumber ] = extractPaginationPageNumbers(previousURL, nextURL);

    this.setState({
      paginatedResources: this.resources().filter(resource => resource.entrance_accesspoint_id == null),
      allPaginatedResourcesSelected: false,
      selectedResourceIds: [],
      previousPageNumber,
      nextPageNumber,
    });
  }

  resources() {
    const { options } = this.props;
    return this.props[options.mode];
  }

  updateOrCreateGuestCategoryAccesspoint(objectId, gcAccesspointId) {
    const { updateGuestCategoryAccesspoint, createGuestCategoryAccesspoint, match } = this.props;
    const { mode } = this.props.options;
    return (apParams) => {
      if (gcAccesspointId) {
        updateGuestCategoryAccesspoint(match.params.event_id, gcAccesspointId, apParams);
      } else {
        if (mode === "accesspoints") {
          const foreignKeyId = match.params.guest_category_id;
          createGuestCategoryAccesspoint(match.params.event_id, objectId, foreignKeyId, apParams);
        } else {
          const foreignKeyId = match.params.accesspoint_id;
          createGuestCategoryAccesspoint(match.params.event_id, foreignKeyId, objectId, apParams);
        }
      }
    };
  }

  massUpdateOrCreateGuestCategoryAccesspoints(apParams) {
    const { selectedResourceIds } = this.state;
    const { batchCreateOrUpdateGuestCategoryAccesspoints, match, options } = this.props;
    let guestCategoryAccesspoint = null;
    const guestCategoryAccesspointList = selectedResourceIds.map(resourceId => {
      guestCategoryAccesspoint = this.findGuestCategoryAccesspoint(resourceId) || {};
      if (options.mode === "accesspoints") {
        guestCategoryAccesspoint.guest_category_id = match.params.guest_category_id;
        guestCategoryAccesspoint.accesspoint_id = resourceId;
      } else {
        guestCategoryAccesspoint.guest_category_id = resourceId;
        guestCategoryAccesspoint.accesspoint_id = match.params.accesspoint_id;
      }
      guestCategoryAccesspoint.max_allowed_accesses = apParams["max_allowed_accesses"];
      guestCategoryAccesspoint.too_many_accesses_message = apParams["too_many_accesses_message"];
      return guestCategoryAccesspoint;
    });
    batchCreateOrUpdateGuestCategoryAccesspoints(urlEventId(), guestCategoryAccesspointList);
  }

  massDeleteGuestCategoryAccesspoints() {
    const { selectedResourceIds } = this.state;
    const selectedGuestCategoryAccesspointIds = selectedResourceIds.reduce((acc, resourceId) => {
      const guestCategoryAccesspoint = this.findGuestCategoryAccesspoint(resourceId);
      if (guestCategoryAccesspoint) {
        acc.push(guestCategoryAccesspoint._id);
      }
      return acc;
    }, []);
    const { deleteGuestCategoryAccesspoints } = this.props;
    deleteGuestCategoryAccesspoints(urlEventId(), selectedGuestCategoryAccesspointIds);
  }

  deleteGuestCategoryAccesspoint(gcAccesspointId) {
    const { deleteGuestCategoryAccesspoint, match } = this.props;
    const { params } = match;
    return () => {
      deleteGuestCategoryAccesspoint(params.event_id, gcAccesspointId);
    };
  }

  handleSelectAllPaginatedResources({ target }) {
    const { checked } = target;
    const { paginatedResources } = this.state;
    this.setState({
      allPaginatedResourcesSelected: checked,
      selectedResourceIds: checked ? paginatedResources.map(resource => resource.id) : [],
    });
  }

  handleSelectOneResource(resourceId) {
    return ({ target }) => {
      const { checked } = target;
      const { selectedResourceIds, paginatedResources } = this.state;
      let nextSelectedResourceIds = selectedResourceIds;
      if (checked && !selectedResourceIds.includes(resourceId)) {
        nextSelectedResourceIds = [...selectedResourceIds, resourceId];
      } else if (selectedResourceIds.includes(resourceId)) {
        nextSelectedResourceIds = [...selectedResourceIds.filter(id => id !== resourceId)];
      }
      this.setState({
        selectedResourceIds: nextSelectedResourceIds,
        allPaginatedResourcesSelected: nextSelectedResourceIds.length === paginatedResources.length,
      });
    };
  }

  renderResourcesList() {
    const { paginatedResources } = this.state;
    return paginatedResources.map((resource, index) => {
      return this.renderResourceLine(resource, index);
    });
  }

  renderResourceLineCheckbox(resource) {
    const { selectedResourceIds } = this.state;
    return this.renderCheckbox("selectedResourceIds", selectedResourceIds.includes(resource.id), this.handleSelectOneResource(resource.id));
  }

  resourceShowUrl(resource) {
    const { options } = this.props;
    const { mode } = options;
    if (mode === "accesspoints") {
      return urlBase(`accesspoints/${resource.id}/edit`);
    } else if (mode === "guest_categories") {
      return urlBase(`guest_categories/${resource.id}`);
    }
  }

  findGuestCategoryAccesspoint(resourceId) {
    const { guest_category_accesspoints, options } = this.props;
    if (!guest_category_accesspoints) return null;

    const { mode } = options;
    return guest_category_accesspoints.find(gcap => {
      if (mode === "accesspoints") {
        return gcap.accesspoint_id === resourceId;
      } else if (mode === "guest_categories") {
        return gcap.guest_category_id === resourceId;
      }
    });
  }

  renderResourceLine(resource, index) {
    const { options, guest_category_accesspoints } = this.props;
    const { mode } = options;
    const guestCategoryAccesspoint = this.findGuestCategoryAccesspoint(resource.id);
    const gcAccesspointId = guestCategoryAccesspoint ? guestCategoryAccesspoint._id : null;
    const { selectedResourceIds } = this.state;

    let itemFromBundles = null;
    let bundlesPrivileges = null;
    if (mode == "accesspoints") {
      itemFromBundles = accesspointFromBundles(this.bundles(), resource);
      bundlesPrivileges = itemFromBundles.filter(bundle => {
        return guest_category_accesspoints && guest_category_accesspoints.find(gcap => gcap.accesspoint_id == bundle.id);
      });
    }

    return (
      <tr key={index}>
        <td className="bulk-cell">
          {this.renderResourceLineCheckbox(resource)}
        </td>
        <td>
          <AccessPrivilegeRow
            resource={guestCategoryAccesspoint}
            displayName={resource.name}
            capacity={resource.capacity}
            nbReserved={resource.nb_reserved}
            showUrl={this.resourceShowUrl(resource)}
            handleValue={this.updateOrCreateGuestCategoryAccesspoint(resource.id, gcAccesspointId)}
            handleRemove={this.deleteGuestCategoryAccesspoint(gcAccesspointId)}
            dropdownDisplayed = {!selectedResourceIds.includes(resource.id)}
            bundles={itemFromBundles}
            bundlesPrivileges={bundlesPrivileges}
          />
        </td>
      </tr>
    );
  }

  entityLabel() {
    const { options } = this.props;
    const { mode } = options;

    if (mode === "accesspoints") {
      const { guest_category } = this.props;
      if (guest_category) {
        return (
          <span className="badge rounded-pill guest-category big" style={{ backgroundColor: guest_category.label_color }}>{guest_category.name}</span>
        );
      }
    } else if (mode === "guest_categories") {
      const { accesspoints, match } = this.props;
      const accesspoint = accesspoints.find(acc => acc.id === match.params.accesspoint_id);
      if (accesspoint) {
        return (
          <span className="badge rounded-pill bg-secondary accesspoint">{accesspoint.name}</span>
        );
      }
    }
  }

  renderSubtitlePage() {
    return <div className="subtitle-page row">
      <div className="subtitle-page-title col-md-9">
        <h2>{this.i18n("title", true)} <i className="fa-regular fa-info-circle" data-bs-toggle="tooltip" title={this.i18n("message_help", true)} data-bs-placement="right"></i></h2>
        <div>
          <HelpSection
            help={this.i18n("warning_message", true)}
            badgeText={I18n.t("warning")}
            type="danger"
            customIcon="fa-triangle-exclamation"
          />
        </div>
      </div>
    </div>;
  }

  renderSelectedResourcesCountLabel() {
    const { selectedResourceIds } = this.state;
    if (!selectedResourceIds.length) return null;

    return <strong>{this.i18n("selected_resources", true, { count: selectedResourceIds.length })}</strong>;
  }

  renderMassAccessPrivilegeDropdown() {
    const { selectedResourceIds } = this.state;
    if (!selectedResourceIds.length) return null;

    return <AccessPrivilegeDropdown
      resource={null}
      forceNoAccessEnabled={true}
      handleRemove={this.massDeleteGuestCategoryAccesspoints}
      handleValue={this.massUpdateOrCreateGuestCategoryAccesspoints}
    />;
  }

  renderPagination() {
    const { previousPageNumber, nextPageNumber } = this.state;
    if (!previousPageNumber && !nextPageNumber) {
      return;
    }

    return <Paginate
      handlePrevious={this.goToPage(previousPageNumber)}
      handleNext={this.goToPage(nextPageNumber)}
      previousEnabled={previousPageNumber}
      nextEnabled={nextPageNumber}
      pull="end"
    />;
  }

  renderErrorMessage() {
    const { errorsMassAction, options } = this.props;
    if (!errorsMassAction || errorsMassAction === {}) return null;

    const prettyErrors = errorsMassAction.reduce((acc, error) => {
      const idToSearch = options.mode === "accesspoints" ? error.accesspoint_id : error.guest_category_id;
      const resource = this.resources().find(resource => resource._id === idToSearch);
      acc[resource ? resource.name : idToSearch] = error.message;
      return acc;
    }, {});
    return <ErrorMessage errors={prettyErrors} />;
  }

  render() {
    const { allPaginatedResourcesSelected } = this.state;
    const { options, match } = this.props;
    const { mode } = options;

    return (
      <div>
        {this.renderErrorMessage()}
        <div className="header-page">
          <div className="header-page-title">
            <h1>
              <a href={mode == "accesspoints" ? pathToGuestCategoryShow(match.params.guest_category_id) : pathToAccesspointEdit(match.params.accesspoint_id)}>
                <i className="fa-regular fa-chevron-left fa-fw fa-xs"></i>
              </a>
              {this.i18n("header", true)} {this.entityLabel()}
            </h1>
          </div>
        </div>

        <fieldset>
          {this.renderSubtitlePage()}
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {this.renderSelectedResourcesCountLabel()}
            <div className="collection-pagination" style={{ flexGrow: "1" }}>
              {this.renderPagination()}
            </div>
          </div>
          <div className="table-responsive table-container">
            <table className="table table-light table-bordered table-hover">
              <thead>
                <tr>
                  <th className="bulk-cell">
                    {this.renderCheckbox("allPaginatedResourcesSelected", allPaginatedResourcesSelected, this.handleSelectAllPaginatedResources)}
                  </th>
                  <th className="text-end">{this.renderMassAccessPrivilegeDropdown()}</th>
                </tr>
              </thead>
              <tbody>
                {this.renderResourcesList()}
              </tbody>
            </table>
          </div>
        </fieldset>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    accesspoints: state.accesspoints.data,
    guest_categories: state.guestCategories.data,
    guest_category_accesspoints: state.guestCategoryAccesspoints.data,
    guest_category: state.guestCategory.data,
    nextURL: state.accesspoints.nextURL || state.guestCategories.nextURL,
    performingMassAction: state.guestCategoryAccesspoints.performingMassAction,
    errorsMassAction: state.guestCategoryAccesspoints.errorsMassAction,
    previousURL: state.accesspoints.previousURL || state.guestCategories.previousURL,
    accesspointsFetched: state.accesspoints.fetched,
    guestCategoriesFetched: state.guestCategories.fetched,
    siloedData: state.accesspoints.siloedData,
  };
}

const mapDispatchToProps = {
  ...guestCategoryAccesspointActionCreators,
  fetchAccesspoints,
  fetchAccesspoint,
  importGuestCategories,
  fetchGuestCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestCategoryAccesspoints);
