import { ActionTypes } from "../constants/Constants";
import includes from "lodash/includes";
import pull from "lodash/pull";

const defaultOptions = ["guest_list"];

function exportOptions(state = defaultOptions, action) {
  switch (action.type) {
  case ActionTypes.UPDATE_SHEETS:
    state = state.slice(); //clone the state first
    if (action.value == true) {
      if (!includes(state, action.key)) {
        state.push(action.key);
      }
    } else {
      state = pull(state, action.key);
    }
    return state;
  default:
    return state;
  }
}

export default exportOptions;
