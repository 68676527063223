import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  fetchPrograms,
  createProgram,
  updateProgram,
  deleteProgram,
  fetchFilterMetadata
} from "../actions/ProgramsActionCreators";
import { requestEvent } from "../actions/ImportActionCreators";
import { urlEventId } from "../utils/pathUtils";
import Loader from "../components/shared/Loader.react";
import ProgramsList from "../components/programs/ProgramsList.react";
import ProgramForm from "../components/programs/ProgramForm.react";
import NewProgramModal from "../components/programs/NewProgramModal.react";
import { Program, ProgramApiAttributes, ProgramFilter, FilterType } from "../types/Program";
import { isEnabled } from "../utils/featureSetUtils";
import { SCHEDULE } from "../constants/FeaturesSet";
import { urlBase } from "../utils/pathUtils";

interface Props {
  inPane?: boolean;
  programId?: string;
}

const ProgramsContainer: React.FC<Props> = ({ inPane, programId }) => {
  if (!isEnabled(SCHEDULE)) return null;

  const [newProgramModalVisible, setNewProgramModalVisible] = useState(false);
  const [currentProgramId, setCurrentProgramId] = useState<string | null>(null);
  const [currentProgram, setCurrentProgram] = useState(null);

  const isFetching = useSelector((state: any) => state.programs.isFetching);
  const isSaving = useSelector((state: any) => state.programs.isSaving);
  const programs = useSelector((state: any) => state.programs.data);
  const metadata = useSelector((state: any) => state.programs.metadata);
  const errors = useSelector((state: any) => state.programs.errors);
  const lastAction = useSelector((state: any) => state.programs.lastAction);
  const notice = useSelector((state: any) => state.notifications.currentNotice);
  const noticeType = useSelector((state: any) => state.notifications.noticeType);
  const event = useSelector((state: any) => state.event);

  const dispatch = useDispatch();

  const formContainerRef = useRef<HTMLDivElement>(null);

  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (!programs) dispatch(fetchPrograms(urlEventId()));
    dispatch(requestEvent());
  }, []);

  useEffect(() => {
    if (lastAction === "create") { // after create
      toggleNewProgramModal();
      selectLastProgram();
    }
    if (lastAction === "update") { // after update reload current program to get fresh DB info
      setCurrentProgram(findCurrentProgram());
    }
    if (lastAction === "delete") { // after delete
      selectLastProgram();
    }
  }, [lastAction]);

  useEffect(() => {
    if (isFetching) return;

    if (programs?.length > 0) {
      const programId = match.params.program_id || programs[0]._id;
      refreshProgramIdInUrl(programId);
      setCurrentProgramId(programId);
    }
  }, [isFetching]);

  useEffect(() => {
    if (isSaving) return;

    if (inPane) {
      formContainerRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [isSaving]);

  useEffect(() => {
    if (!currentProgramId) return;

    if (programs) {
      const program = findCurrentProgram();
      fetchFilterMetadataForProgram(program);
      setCurrentProgram(program);
    }
  }, [currentProgramId]);

  useEffect(() => {
    if (!programId) return;

    setCurrentProgramId(programId);
  }, [programId]);

  const findCurrentProgram = (): Program => {
    return programs.find((program: Program) => program._id === currentProgramId);
  };

  const changeProgramConfiguration = (program: Program): void => {
    setCurrentProgram(program);
  };

  const toggleNewProgramModal = (): void => {
    setNewProgramModalVisible(!newProgramModalVisible);
  };

  const refreshProgramIdInUrl = (programId: string): void => {
    if (inPane) return;

    history.push(urlBase("programs/" + programId));
  };

  const selectLastProgram = (): void => {
    if (programs?.length > 0) {
      const programId = programs[programs.length - 1]._id;
      refreshProgramIdInUrl(programId);
      setCurrentProgramId(programId);
    }
  };

  const fetchFilterMetadataForProgram = (program: Program): void => {
    const filterTypes = Object.values(program.program_filters)
      .map((programFilter: ProgramFilter) => programFilter._type)
      .filter((filterType: FilterType) => !metadata[filterType]);

    if (program.traits_displayed.length > 0 && !filterTypes.includes("trait")) {
      filterTypes.push("trait");
    }

    if (filterTypes.length === 0) return;

    dispatch(fetchFilterMetadata(urlEventId(), filterTypes));
  };

  const updateProgramName = (name: string): void => {
    dispatch(updateProgram(urlEventId(), currentProgramId, { name }));
  };

  const currentProgramAttributes = (): ProgramApiAttributes => {
    const { name, program_filters, navigation_by_date_enabled, group_sessions_by_starting_dates, text_search_enabled, nb_lines_for_description, nb_sessions_per_line, no_results_label, search_bar_position, sessions_date_displayed, sessions_time_displayed,
      sessions_location_displayed, sessions_attendance_type_displayed, sessions_type_displayed, sessions_thematics_displayed, sessions_description_displayed,
      sessions_thematics_display_level, speakers_information_displayed, speakers_label, speakers_label_displayed, speakers_illustration_displayed,
      speakers_illustration_field, speakers_name_displayed, speakers_company_name_displayed, speakers_position_displayed, exhibitors_information_displayed,
      exhibitors_label, exhibitors_label_displayed, exhibitors_illustration_displayed, exhibitors_illustration_field, exhibitors_name_displayed,
      exhibitors_company_name_displayed, exhibitors_position_displayed, traits_displayed, speakers_display_options_rank,
      exhibitors_display_options_rank, moderators_information_displayed, moderators_display_options_rank, moderators_label, moderators_label_displayed,
      moderators_illustration_displayed, moderators_illustration_field, moderators_name_displayed, moderators_company_name_displayed, moderators_position_displayed
    } = currentProgram;
    return {
      name,
      program_filters_attributes: Object.values(program_filters),
      navigation_by_date_enabled,
      group_sessions_by_starting_dates,
      text_search_enabled,
      nb_lines_for_description,
      nb_sessions_per_line,
      no_results_label,
      search_bar_position,
      sessions_date_displayed,
      sessions_time_displayed,
      sessions_location_displayed,
      sessions_attendance_type_displayed,
      sessions_type_displayed,
      sessions_thematics_displayed,
      sessions_description_displayed,
      sessions_thematics_display_level,
      speakers_information_displayed,
      speakers_display_options_rank,
      speakers_label,
      speakers_label_displayed,
      speakers_illustration_displayed,
      speakers_illustration_field,
      speakers_name_displayed,
      speakers_company_name_displayed,
      speakers_position_displayed,
      exhibitors_information_displayed,
      exhibitors_display_options_rank,
      exhibitors_label,
      exhibitors_label_displayed,
      exhibitors_illustration_displayed,
      exhibitors_illustration_field,
      exhibitors_name_displayed,
      exhibitors_company_name_displayed,
      exhibitors_position_displayed,
      moderators_information_displayed,
      moderators_display_options_rank,
      moderators_label,
      moderators_label_displayed,
      moderators_illustration_displayed,
      moderators_illustration_field,
      moderators_name_displayed,
      moderators_company_name_displayed,
      moderators_position_displayed,
      traits_displayed,
    };
  };

  const renderWhenNoProgram = (): JSX.Element => {
    return <div className="card nothing-yet mt-20">
      <div>
        <i className="fa-regular fa-calendar fa-xl"></i>
      </div>
      <h4>{ I18n.t("react.programs.no_program") }</h4>
      <div>
        <a onClick={toggleNewProgramModal} className="btn btn-primary">
          <i className="fa-regular fa-plus"></i> { I18n.t("react.programs.create_program") }
        </a>
      </div>
    </div>;
  };

  const renderNotice = (): JSX.Element => {
    if (inPane || !notice || notice.length === 0) return null;

    return <div className={`alert alert-${noticeType} mt-20`}>
      {notice}
    </div>;
  };

  const renderWhenSomePrograms = (): JSX.Element => {
    const renderedForm = <ProgramForm
      program={currentProgram}
      onChangeConfiguration={changeProgramConfiguration}
      onSubmit={(): void => {
        const notificationsOptions = { notice: I18n.t("react.programs.saved_successfully"), noticeType: "success" };
        dispatch(updateProgram(urlEventId(), currentProgramId, currentProgramAttributes(), notificationsOptions));
      }}
      onProgramNameChange={updateProgramName}
      errors={errors}
      isSaving={isSaving}
      displayTitle={!inPane}
      event={event}
    />;

    if (inPane) {
      return <div ref={formContainerRef} style={{ padding: "0 20px 20px" }}>
        {renderedForm}
      </div>;
    }

    return <div className="row mt-20">
      <div className="col-sm-3">
        <ProgramsList
          programs={programs}
          currentProgramId={currentProgramId}
          setCurrentProgramId={(programId: string): void => {
            refreshProgramIdInUrl(programId);
            setCurrentProgramId(programId);
          }}
          deleteProgram={(programId: string): void => dispatch(deleteProgram(urlEventId(), programId))}
          toggleNewProgramModal={toggleNewProgramModal}
        />
      </div>
      <div className="col-sm-9">
        {renderedForm}
      </div>
    </div>;
  };

  const renderNewProgramModal = (): JSX.Element => {
    if (inPane) return null;

    return <NewProgramModal
      show={newProgramModalVisible}
      onClose={toggleNewProgramModal}
      onSubmit={(name: string): void => dispatch(createProgram(urlEventId(), { name: name, no_results_label: I18n.t("react.programs.no_results_label.placeholder") }))}
      errors={errors}
    />;
  };

  if (isFetching || !programs) {
    return <Loader />;
  }

  if (programs.length === 0) {
    return <>
      { renderWhenNoProgram() }
      { renderNewProgramModal() }
    </>;
  }

  return <>
    { renderNotice() }
    { renderWhenSomePrograms() }
    { renderNewProgramModal() }
  </>;
};

export default ProgramsContainer;
