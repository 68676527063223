import { ActionTypes, CALL_API } from "../constants/Constants";

module.exports = {
  fetchGuestCategoryAccesspoints(eventId, accesspointId, guestCategoryId) {
    const { DEV_NULL, FETCH_GUEST_CATEGORY_ACCESSPOINTS_SUCCESS } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, FETCH_GUEST_CATEGORY_ACCESSPOINTS_SUCCESS, DEV_NULL],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/guest_category_accesspoints.json`,
        body: { accesspoint_id: accesspointId, guest_category_id: guestCategoryId }
      }
    };
  },

  createGuestCategoryAccesspoint(eventId, accesspointId, guestCategoryId, params) {
    const { DEV_NULL, CREATE_GUEST_CATEGORY_ACCESSPOINT_SUCCESS, CREATE_GUEST_CATEGORY_ACCESSPOINT_FAILURE } = ActionTypes;
    const body = { guest_category_accesspoint: { ...params, accesspoint_id: accesspointId, guest_category_id: guestCategoryId } };
    return {
      [CALL_API]: {
        types: [DEV_NULL, CREATE_GUEST_CATEGORY_ACCESSPOINT_SUCCESS, CREATE_GUEST_CATEGORY_ACCESSPOINT_FAILURE],
        method: "POST",
        endpoint: `/api/v1/events/${eventId}/guest_category_accesspoints.json`,
        body
      },
      accesspointId,
      guestCategoryId
    };
  },

  updateGuestCategoryAccesspoint(eventId, id, params) {
    const { DEV_NULL, UPDATE_GUEST_CATEGORY_ACCESSPOINT_SUCCESS } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, UPDATE_GUEST_CATEGORY_ACCESSPOINT_SUCCESS, DEV_NULL],
        method: "PUT",
        endpoint: `/api/v1/events/${eventId}/guest_category_accesspoints/${id}.json`,
        body: { guest_category_accesspoint: params }
      },
      guest_category_accesspoint_id: id,
      params
    };
  },

  deleteGuestCategoryAccesspoint(eventId, id) {
    const { DEV_NULL, DELETE_GUEST_CATEGORY_ACCESSPOINT_SUCCESS } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, DELETE_GUEST_CATEGORY_ACCESSPOINT_SUCCESS, DEV_NULL],
        method: "DELETE",
        endpoint: `/api/v1/events/${eventId}/guest_category_accesspoints/${id}.json`
      },
      guest_category_accesspoint_id: id
    };
  },

  batchCreateOrUpdateGuestCategoryAccesspoints(eventId, guestCategoryAccesspointList) {
    const {
      CREATE_OR_UPDATE_GUEST_CATEGORY_ACCESSPOINTS_REQUEST,
      CREATE_OR_UPDATE_GUEST_CATEGORY_ACCESSPOINTS_SUCCESS,
      CREATE_OR_UPDATE_GUEST_CATEGORY_ACCESSPOINTS_FAILURE,
    } = ActionTypes;

    return {
      [CALL_API]: {
        types: [CREATE_OR_UPDATE_GUEST_CATEGORY_ACCESSPOINTS_REQUEST, CREATE_OR_UPDATE_GUEST_CATEGORY_ACCESSPOINTS_SUCCESS, CREATE_OR_UPDATE_GUEST_CATEGORY_ACCESSPOINTS_FAILURE],
        method: "POST",
        body: { guest_category_accesspoints: guestCategoryAccesspointList },
        endpoint: `/api/v1/events/${eventId}/guest_category_accesspoints/batch_create_or_update.json}`,
      }
    };
  },

  deleteGuestCategoryAccesspoints(eventId, guestCategoryAccesspointIds) {
    const {
      DELETE_GUEST_CATEGORY_ACCESSPOINTS_REQUEST,
      DELETE_GUEST_CATEGORY_ACCESSPOINTS_SUCCESS,
      DELETE_GUEST_CATEGORY_ACCESSPOINTS_FAILURE,
    } = ActionTypes;

    return {
      [CALL_API]: {
        types: [DELETE_GUEST_CATEGORY_ACCESSPOINTS_REQUEST, DELETE_GUEST_CATEGORY_ACCESSPOINTS_SUCCESS, DELETE_GUEST_CATEGORY_ACCESSPOINTS_FAILURE],
        method: "DELETE",
        body: { ids: guestCategoryAccesspointIds },
        endpoint: `/api/v1/events/${eventId}/guest_category_accesspoints/batch_destroy.json}`,
      },
      guestCategoryAccesspointIds: guestCategoryAccesspointIds
    };
  }
};
