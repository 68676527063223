import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { urlWithQuery, pathToGuestCategoryNotification, pathToGuestCategoryManageGuestCategoryAccesspoints, pathToGuestCategoryHistory,
  pathToGuestCategoriesList, urlEventId, pathToGuestCategoryOnDuplicatedEmail } from "../utils/pathUtils";
import { enrichQuery } from "../utils/QueryStringUtils";
import { isEnabled } from "../utils/featureSetUtils";
import { ACCREDITATIONS, NOTIFICATIONS, NOTIFICATIONS_ON_CHECKIN, ONSITE_PAYMENT_APP } from "../constants/FeaturesSet";
import Icons from "../constants/Icons";
import { fetchEventsFromUrl } from "../actions/EventActionCreators";
import { requestEvent } from "../actions/ImportActionCreators";
import { copyGuestCategory, fetchIfGuestCategoryIsValid, destroyGuestCategory, deleteAllPeople, countPeople,
  fetchGuestCategories } from "../actions/GuestCategoryActionCreators";

import TargetEventModal from "../components/duplication_modals/TargetEventModal.react";
import AskModelNameModal from "../components/duplication_modals/AskModelNameModal.react";
import DuplicationRunningModal from "../components/duplication_modals/DuplicationRunningModal.react";
import DeleteConfirmationModal from "../components/shared/DeleteConfirmationModal.react";
import BeforeDestroyCategoryModal from "../components/guest_category/BeforeDestroyCategoryModal.react";
import OnsitePaymentAppFlashConfigModal from "../components/guest_category/OnsitePaymentAppFlashConfigModal.react";
import SlidingPane from "react-sliding-pane";
import TranslationTable from "./translations/TranslationTable.react";
import NoticeBlock from "../components/templates_builder/NoticeBlock.react";

class GuestCategoryMoreAction extends Component {
  constructor(props) {
    super(props);
    [
      "copyGuestCategory",
      "onEventTargetSelected",
      "duplicateIntoEvent",
      "countPeople",
      "destroyGuestCategory",
      "deleteAllPeople",
      "fetchMoreEvents",
      "toggleDeletePeopleConfirmationModal",
      "toggleBeforeDestroyModal",
      "onEnterBeforeDestroy",
      "toggleTranslationsPane",
      "toggleOnsitePaymentAppFlashConfigModal"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      showEventPickerModal: false,
      showNewCategoryNameModal: false,
      showDuplicationRunningModal: false,
      duplicateTargetEventId: null,
      showDeletePeopleConfirmationModal: false,
      showBeforeDestroyModal: false,
      displayTranslationsPane: false,
      showOnsitePaymentAppFlashConfigModal: false
    };
  }

  componentDidMount() {
    const { fetchIfGuestCategoryIsValid, requestEvent, event, eventId, guestCategory, guestCategoryId } = this.props;

    if (!guestCategory || Object.keys(guestCategory).length == 0)
      fetchIfGuestCategoryIsValid(eventId, guestCategoryId);
    if (!event) requestEvent();
  }

  componentDidUpdate(prevProps) {
    const { duplicationErrors } = this.props;
    if (Object.keys(prevProps.duplicationErrors).length === 0 && Object.keys(duplicationErrors).length > 0) {
      this.setState({
        showNewCategoryNameModal: true,
        showDuplicationRunningModal: false
      });
    }
  }

  copyGuestCategory() {
    this.setState({ showEventPickerModal: true });
  }

  onEventTargetSelected(targetEventId) {
    this.setState({
      showEventPickerModal: false,
      showNewCategoryNameModal: true,
      duplicateTargetEventId: targetEventId
    });
  }

  duplicateIntoEvent(newName) {
    this.setState({
      showNewCategoryNameModal: false,
      showDuplicationRunningModal: true
    });

    const { copyGuestCategory, eventId, guestCategoryId } = this.props;
    const { duplicateTargetEventId } = this.state;

    const guestCategoryParam = {
      new_name: newName,
      target_event_id: duplicateTargetEventId
    };
    copyGuestCategory(eventId, guestCategoryId, guestCategoryParam);
  }

  toggleDeletePeopleConfirmationModal() {
    const { showDeletePeopleConfirmationModal } = this.state;

    this.setState({ showDeletePeopleConfirmationModal: !showDeletePeopleConfirmationModal });
  }

  toggleBeforeDestroyModal() {
    const { showBeforeDestroyModal } = this.state;

    this.setState({ showBeforeDestroyModal: !showBeforeDestroyModal });
  }

  toggleTranslationsPane(e) {
    e && e.preventDefault();
    this.setState({ displayTranslationsPane: !this.state.displayTranslationsPane });
  }

  toggleOnsitePaymentAppFlashConfigModal(e) {
    e && e.preventDefault();
    this.setState({ showOnsitePaymentAppFlashConfigModal: !this.state.showOnsitePaymentAppFlashConfigModal });
  }

  countPeople() {
    const { guestCategory, countPeople } = this.props;

    countPeople(guestCategory.event_id, guestCategory._id);
  }

  onEnterBeforeDestroy() {
    const { guestCategory, fetchGuestCategories } = this.props;

    this.countPeople();
    fetchGuestCategories(guestCategory.event_id);
  }

  destroyGuestCategory(e, newGuestCategoryId) {
    const { guestCategory, destroyGuestCategory } = this.props;

    this.setState({ showBeforeDestroyModal: false });

    if (newGuestCategoryId) {
      destroyGuestCategory(guestCategory.event_id, guestCategory._id, { new_guest_category_id: newGuestCategoryId }, pathToGuestCategoriesList());
    } else if (confirm(I18n.t("guest_categories.action_menu_on_member.confirm")))
      destroyGuestCategory(guestCategory.event_id, guestCategory._id, {}, pathToGuestCategoriesList());
  }

  deleteAllPeople() {
    const { guestCategory, deleteAllPeople, peopleCount } = this.props;

    deleteAllPeople(guestCategory.event_id, guestCategory._id, { notice: I18n.t("all_guests_about_to_be_deleted", { count: peopleCount }), noticeType: "success" });
    this.toggleDeletePeopleConfirmationModal();
  }

  fetchMoreEvents() {
    const { fetchEventsFromUrl, eventsNextURL } = this.props;
    fetchEventsFromUrl(eventsNextURL);
  }

  renderCopyModals() {
    const { duplicationErrors, event, duplicatedCategory, eventsNextURL } = this.props;
    const { showEventPickerModal, showNewCategoryNameModal, duplicateTargetEventId, showDuplicationRunningModal } = this.state;
    return (
      <div>
        <TargetEventModal
          isVisible={showEventPickerModal}
          onClose={() => { this.setState({ showEventPickerModal: false }); }}
          currentEvent={event}
          fetchMoreEvents={eventsNextURL ? this.fetchMoreEvents : null}
          targetReadableName={I18n.t("guest_categories.show.target_name")}
          onEventSelected={this.onEventTargetSelected}/>

        <AskModelNameModal
          isVisible={showNewCategoryNameModal}
          onClose={() => { this.setState({ showNewCategoryNameModal: false }); }}
          onSubmit={this.duplicateIntoEvent}
          i18nRootKey="guest_category"
          errors={duplicationErrors}/>

        <DuplicationRunningModal
          isVisible={showDuplicationRunningModal}
          onClose={() => { this.setState({ showDuplicationRunningModal: false }); }}
          model="guest_category"
          currentEventId={event._id}
          targetEventId={duplicateTargetEventId}
          duplicatedData={duplicatedCategory}/>
      </div>
    );
  }

  renderDeleteCategoryButtons() {
    const { guestCategory } = this.props;

    if (!guestCategory.has_people) {
      return <Dropdown.Item eventKey="6" onClick={this.destroyGuestCategory}>
        <i className="fa-regular fa-trash-can fa-fw"></i> {I18n.t("guest_categories.action_menu_on_member.delete")}
      </Dropdown.Item>;
    }

    return [
      <Dropdown.Item key="7" eventKey="7" onClick={this.toggleDeletePeopleConfirmationModal}>
        <i className="fa-regular fa-trash-can fa-fw"></i> {I18n.t("guest_categories.action_menu_on_member.destroy_all_guests_from_category")}
      </Dropdown.Item>,
      <Dropdown.Item key="8" eventKey="8" onClick={this.toggleBeforeDestroyModal}>
        <i className="fa-regular fa-trash-can fa-fw"></i> {I18n.t("guest_categories.action_menu_on_member.delete")}
      </Dropdown.Item>
    ];
  }

  renderDeletePeopleConfirmationModal() {
    const { showDeletePeopleConfirmationModal } = this.state;
    const { peopleCount } = this.props;

    return <DeleteConfirmationModal isVisible={showDeletePeopleConfirmationModal}
      type="guests"
      count={peopleCount}
      i18nPrefix="guest_categories.modal_confirmation_delete_guests"
      onEnter={this.countPeople}
      onConfirm={this.deleteAllPeople}
      onClose={this.toggleDeletePeopleConfirmationModal} />;
  }

  renderBeforeDestroyModal() {
    const { showBeforeDestroyModal } = this.state;
    const { guestCategory, peopleCount, guestCategories } = this.props;

    return <BeforeDestroyCategoryModal isVisible={showBeforeDestroyModal}
      guestCategory={guestCategory}
      count={peopleCount}
      guestCategories={guestCategories}
      i18nPrefix="guest_categories.before_destroy"
      onEnter={this.onEnterBeforeDestroy}
      onConfirm={this.destroyGuestCategory}
      onClose={this.toggleBeforeDestroyModal} />;
  }

  renderTranslations() {
    const { match, location, history, notice, noticeType, guestCategory } = this.props;
    const { displayTranslationsPane } = this.state;
    const { _id } = guestCategory;

    return displayTranslationsPane && <SlidingPane
      isOpen={true}
      title={I18n.t("react.registration_form.translations_title")}
      from="right"
      width='90%'
      onRequestClose={this.toggleTranslationsPane}
      className="width-100-xs width-90-sm"
      closeIcon={Icons.close()}
    >
      <div style={{ padding: "0 20px 20px" }}>
        <NoticeBlock notice={notice} noticeType={noticeType} />
        <TranslationTable
          match={match}
          location={location}
          history={history}
          type="guest_categories"
          eventId={urlEventId()}
          id={_id}
          inSlidingPane={true}
          onlySimpleValidateButton={true}
        />
      </div>
    </SlidingPane>;
  }

  renderOnsitePaymentAppFlashConfigModal() {
    const { showOnsitePaymentAppFlashConfigModal } = this.state;
    const { guestCategory } = this.props;

    return <OnsitePaymentAppFlashConfigModal
      show={showOnsitePaymentAppFlashConfigModal}
      categoryId={guestCategory._id}
      onClose={this.toggleOnsitePaymentAppFlashConfigModal}
    />;
  }

  render() {
    const { guestCategory, errors, defaultHtmlContent, event } = this.props;
    const { _id } = guestCategory;

    if (!guestCategory || Object.keys(guestCategory).length === 0) {
      return <div dangerouslySetInnerHTML={{ __html: defaultHtmlContent }}></div>;
    }

    const isCategoryDuplicable = Object.keys(errors).length == 0;
    const shouldDisplayTranslateMenuItem = event && event.available_frontend_locales && event.available_frontend_locales.length > 0;
    const shouldDisplayOnDuplicatedEmail = (event && event.unique_emails) || guestCategory.unique_emails;

    return (
      <div>
        <DropdownButton variant="secondary" align="end" title={I18n.t("more_settings")} id="GuestCategoryDropdownMoreActions">
          { isEnabled(ACCREDITATIONS) &&
            <Dropdown.Item eventKey="1" href={pathToGuestCategoryManageGuestCategoryAccesspoints(_id)}>
              <i className="fa-regular fa-lock fa-fw"></i> {I18n.t("guest_categories.show.manage_guest_category_accesspoints")}
            </Dropdown.Item>
          }
          <Dropdown.Item eventKey="2" onClick={isCategoryDuplicable ? this.copyGuestCategory : null} disabled={!isCategoryDuplicable}>
            <i className="fa-regular fa-clone fa-fw"></i> {I18n.t("guest_categories.show.copy")}
          </Dropdown.Item>
          <Dropdown.Item eventKey="3" href={urlWithQuery(enrichQuery([guestCategory], "guest_category", null, true), "guests")} >
            <i className="fa-regular fa-user fa-fw"></i> {I18n.t("guest_categories.show.view_guests")}
          </Dropdown.Item>
          { (isEnabled(NOTIFICATIONS) || isEnabled(NOTIFICATIONS_ON_CHECKIN)) &&
            <Dropdown.Item eventKey="4" href={pathToGuestCategoryNotification(_id)}>
              <i className="fa-regular fa-star fa-fw"></i> {I18n.t("guest_categories.show.email_and_sms_notifications")}
            </Dropdown.Item>
          }
          { shouldDisplayOnDuplicatedEmail && (
            <Dropdown.Item href={pathToGuestCategoryOnDuplicatedEmail(_id)}>
              <i className="fa-regular fa-at fa-fw"></i> {I18n.t("guest_categories.show.email_live_check")}
            </Dropdown.Item>
          )}
          <Dropdown.Item eventKey="5" href={pathToGuestCategoryHistory(_id)}>
            <i className="fa-regular fa-list fa-fw"></i> {I18n.t("guest_categories.show.history")}
          </Dropdown.Item>

          {isEnabled(ONSITE_PAYMENT_APP) && guestCategory.can_use_onsite_payment_app && (
            <Dropdown.Item eventKey="6" onClick={this.toggleOnsitePaymentAppFlashConfigModal}>
              <i className="fa-regular fa-qrcode fa-fw"></i> {I18n.t("react.guest_category.onsite_payment_app.menu_entry")}
            </Dropdown.Item>
          )}

          { shouldDisplayTranslateMenuItem &&
            <Dropdown.Item eventKey="7" onClick={this.toggleTranslationsPane}>
              <i className="fa-regular fa-language fa-fw"></i> { I18n.t("react.registration_form.manage_translations") }
            </Dropdown.Item>
          }
          <Dropdown.Divider />
          { this.renderDeleteCategoryButtons() }
        </DropdownButton>
        { this.renderCopyModals() }
        { this.renderDeletePeopleConfirmationModal() }
        { this.renderBeforeDestroyModal() }
        { this.renderTranslations() }
        { this.renderOnsitePaymentAppFlashConfigModal() }
      </div>
    );
  }
}

GuestCategoryMoreAction.propTypes = {
  eventId: PropTypes.string.isRequired,
  guestCategoryId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    guestCategory: state.guestCategory.data,
    duplicatedCategory: state.guestCategory.duplicated,
    duplicationErrors: state.guestCategory.duplicationErrors,
    errors: state.guestCategory.errors,
    peopleCount: state.guestCategory.peopleCount,
    eventsNextURL: state.events.nextURL,
    event: state.event,
    guestCategories: state.guestCategories.data,
    notice: state.notifications.currentNotice,
    noticeType: state.notifications.noticeType
  };
}

const mapDispatchToProps = {
  fetchIfGuestCategoryIsValid,
  requestEvent,
  fetchEventsFromUrl,
  copyGuestCategory,
  destroyGuestCategory,
  deleteAllPeople,
  countPeople,
  fetchGuestCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestCategoryMoreAction);
