import { featureLink, featureSetTranslation } from "../../utils/featureSetUtils";

interface Props {
  featureKey: string;
}

const ReadMoreLink: React.FC<Props> = ({ featureKey }) => {
  const link = featureLink(featureKey);

  if (!link) return null;

  return <div>
    <a href={ link } target="_blank">{ featureSetTranslation("read_more") }</a>
  </div>;
};

export default ReadMoreLink;
