import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/shared/Loader.react";
import { isAuthorized } from "../../utils/aclUtils";
import { LeadQualificationField } from "../../types/LeadQualificationField";
import { urlEventId } from "../../utils/pathUtils";
import { fetchMobinetworkConfiguration, leadQualificationFieldsSoftUpdate, updateMobinetworkConfiguration } from "../../actions/MobinetworkConfigurationActionCreators";
import Icons from "../../constants/Icons";
import SlidingPane from "react-sliding-pane";
import LeadQualificationFields from "../../components/lead_qualification_fields/LeadQualificationFields.react";

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`react.default_lead_qualification_fields.${key}`, options);
};

const DefaultLeadQualificationFields: React.FC = () => {
  if (!isAuthorized("configuration", "manage")) return null;

  const dispatch = useDispatch();
  const leadQualificationFields: LeadQualificationField[] = useSelector((state: any) => state.mobinetworkConfiguration.data?.lead_qualification_fields);
  const errors: string[] = useSelector((state: any) => state.mobinetworkConfiguration.errors);
  const successfullySaved: boolean = useSelector((state: any) => state.mobinetworkConfiguration.successfullySaved);

  const [isSlidingPaneOpen, setIsSlidingPaneOpen] = useState<boolean>(false);

  useEffect(() => { dispatch(fetchMobinetworkConfiguration(urlEventId())); }, []);
  const notice = successfullySaved ? i18n("successfully_saved") : null;

  if (!leadQualificationFields) return <Loader size="small"/>;

  const togglePane = (): void => {
    setIsSlidingPaneOpen(!isSlidingPaneOpen);
  };

  const onLeadQualificationFieldsChange = (currentLeadQualificationFields: LeadQualificationField[]): void => {
    dispatch(leadQualificationFieldsSoftUpdate(currentLeadQualificationFields));
  };

  const onSave = (newLeadQualificationFields: LeadQualificationField[]): void => {
    newLeadQualificationFields = newLeadQualificationFields.map(field => {
      if (field.hidden) field._destroy = true;
      return field;
    });
    dispatch(
      updateMobinetworkConfiguration(
        urlEventId(),
        { lead_qualification_fields_attributes: newLeadQualificationFields },
      )
    );
  };

  const renderPane = ():JSX.Element => {
    return <SlidingPane
      isOpen={isSlidingPaneOpen}
      width="90%"
      title={i18n("sliding_pane_title")}
      onRequestClose={togglePane}
      className="width-100-xs width-100-sm width-transition"
      closeIcon={Icons.close()}
    >
      <div className="fields-form-builder lead-qualification-fields">
        <LeadQualificationFields
          leadQualificationFields={leadQualificationFields}
          onChange={onLeadQualificationFieldsChange}
          onSave={onSave}
          errors={errors}
          notice={notice}
        />
      </div>
    </SlidingPane>;
  };

  return <>
    <button className="btn btn-secondary" onClick={togglePane}>
      <i className="fa-regular fa-pen-field"></i> { i18n("edit_default_lead_qualification_fields") }
    </button>
    { renderPane() }
  </>;
};

export default DefaultLeadQualificationFields;
