import { Component } from "react";
import PropTypes from "prop-types";

class FormFileDrop extends Component {

  constructor(props) {
    super(props);
    ["_handleFileSelect"].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = { currentFile: props.file };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.file !== prevState.currentFile) {
      return { currentFile: nextProps.file };
    }
    return null;
  }

  _handleFileSelect(e) {
    const { dropHandler } = this.props;
    dropHandler(e.target.files[0]);
  }

  renderPreview() {
    const { currentFile } = this.state;

    if (!currentFile)
      return this.renderIcon();

    const { display_thumbnail } = this.props;
    if (!display_thumbnail)
      return [this.renderIcon(), <a href={currentFile}>{currentFile.substr(currentFile.lastIndexOf("/") + 1)}</a>];

    return <img src={currentFile} width="500" />;
  }

  renderIcon() {
    const { dropFontAwesomeClass } = this.props;
    let iconClass = dropFontAwesomeClass ? `fa-regular ${dropFontAwesomeClass} fa-3x` : "fa-regular fa-file-image fa-3x";

    return <i className={ iconClass } aria-hidden="true"></i>;
  }

  render() {
    const { acceptedFiles } = this.props;

    return (
      <div id="dropzone" className="card" style={{ backgroundColor: "white" }}>
        <div className="card-body dropzone">
          <p className="dropzone-preview">{ this.renderPreview() }</p>
          <p className="dropzone-description upload-button mb-0">
            <input onChange={this._handleFileSelect} accept={acceptedFiles} type="file" id="inputFile" />
            <label for="inputFile" className="select-file mb-0"> {I18n.t("react.registration_forms.drag_and_drop")}</label>
          </p>
        </div>
      </div>
    );
  }
}

FormFileDrop.propTypes = {
  display_thumbnail: PropTypes.bool
};

FormFileDrop.defaultProps = {
  display_thumbnail: true
};

export default FormFileDrop;
