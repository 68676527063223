import { ActionTypes } from "../constants/Constants";

function sectionTypes(state = { items: [], errors: {}, builderType: null }, action) {
  switch (action.type) {
  case ActionTypes.RECEIVE_SECTION_TYPES_SUCCESS:
    return { items: action.data || [], errors: {}, builderType: action.builderType };

  case ActionTypes.RECEIVE_SECTION_TYPES_FAILURE:
    return state;

  default:
    return state;
  }
}

export default sectionTypes;
