import { Component } from "react";
import { connect } from "react-redux";
import NoticeBlock from "../components/templates_builder/NoticeBlock.react";
import RegistrationFormPreviewDropdown from "../components/RegistrationFormPreviewDropdown.react";
import RegistrationFormMoreDropdown from "../components/RegistrationFormMoreDropdown.react";
import SideBarRegistrationForm from "../components/registration_form/SideBarRegistrationForm.react";
import DestroyRegistrationFormMenuItem from "../components/DestroyRegistrationFormMenuItem.react";
import { importGuestCategories } from "../actions/ImportGuestCategoryActionCreators";
import { updateGuestCategory } from "../actions/GuestCategoryActionCreators";
import { fetchTicketing } from "../actions/TicketingActionCreators";
import { pathToRegistrationForms, urlEventLocale, urlEventId } from "../utils/pathUtils.js";
import { addTooltip } from "../utils/templatesBuilderUtils";
import { accesspointsNotIncludedInGuestCategory } from "../utils/CheckinpointUtils.js";
import { itemsByStep, stepBeingEdited, itemBeingEdited } from "../utils/itemsBySectionUtils.js";
import pick from "lodash/pick";
import isEmpty from "lodash/isEmpty";
import SlidingPane from "react-sliding-pane";
import RegistrationFormItems from "./RegistrationFormItems.react";
import TranslationTable from "./translations/TranslationTable.react";
import Loader from "../components/shared/Loader.react";
import Icons from "../constants/Icons";
import { OverlayTrigger } from "react-bootstrap";

import {
  removeCustomCssRegistrationForm,
  updateRegistrationForm,
  removeRegistrationForm,
  fetchRegistrationForm
} from "../actions/RegistrationFormsActionCreators";

import { updateRegistrationFormItem, newRegistrationFormItem, destroyRegistrationFormItem } from "../actions/RegistrationFormItemsActionCreators";

import { createRegistrationFormStep } from "../actions/RegistrationFormStepActionCreators";

const DEFAULT_NAV_BAR_HEIGHT = 50;

class RegistrationForm extends Component {

  constructor(props) {
    super(props);
    [
      "attachFormToGuestCategory",
      "backToRegistrationFormList",
      "calculateNavBarHeight",
      "canUnfocusItem",
      "createFormStep",
      "createItem",
      "destroyRegistrationForm",
      "handleFormTitleChanges",
      "handleResize",
      "handleTitleSubmit",
      "hasInvalidAccessPrivileges",
      "isDestroyable",
      "toggleFormTitleUpdate",
      "toggleTranslationsPane",
      "updateRegistrationForm"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      updatingFormTitle: false,
      currentFormTitle: "",
      navBarHeight: DEFAULT_NAV_BAR_HEIGHT,
      displayTranslationsPane: false
    };
  }

  componentDidMount() {
    const { fetchRegistrationForm, importGuestCategories } = this.props;
    fetchRegistrationForm(this.registrationFormId());
    importGuestCategories();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps) {
    const registrationForm = this.registrationForm(this.props);
    if (!this.registrationForm(prevProps) && registrationForm) {
      this.setState({ currentFormTitle: registrationForm.title });
    }

    if (prevProps.guestCategories.length == 0 && this.props.guestCategories.length > 0 && registrationForm && registrationForm.is_ticketing_form) {
      const ticketingCategory = this.props.guestCategories.find(category => {
        return category.registration_form_id == registrationForm._id && category.type == "ticketing";
      });
      if (ticketingCategory) {
        this.props.fetchTicketing(urlEventId(), ticketingCategory._id);
      }
    }

    this.calculateNavBarHeight();
  }

  handleResize() {
    this.calculateNavBarHeight();
  }

  calculateNavBarHeight() {
    const navBar = this.refs["navBar"];
    if (!navBar) {
      return;
    }

    const { height } = navBar.getBoundingClientRect();
    const { navBarHeight } = this.state;
    if (height != navBarHeight) {
      this.setState({ navBarHeight: height });
    }
  }

  registrationFormId() {
    const { registrationFormId, match } = this.props;
    return registrationFormId || match.params.registration_form_id;
  }

  stepUniqueKey() {
    const { registrationFormSteps } = this.props;
    let unique = false;
    let cpt = 1;
    while (!unique) {
      const key = `step-${cpt}`;
      if (!registrationFormSteps.find(step => { return step.key === key; })) {
        return key;
      }
      cpt ++;
    }
  }

  createFormStep() {
    const { registrationFormSteps, createRegistrationFormStep } = this.props;
    const title = `${I18n.t("react.registration_form.step")} ${registrationFormSteps.length + 1}`;
    const key = this.stepUniqueKey();
    let rank = 1000;
    if (registrationFormSteps.length > 0) {
      rank = registrationFormSteps[registrationFormSteps.length - 1].rank + 1000;
    }
    createRegistrationFormStep(this.registrationFormId(), { title, key, rank });
  }

  destroyRegistrationForm(registrationId, redirectTo) {
    const { removeRegistrationForm } = this.props;
    return () => {
      removeRegistrationForm(registrationId, redirectTo);
    };
  }

  isDestroyable() {
    const { guestCategories } = this.props;
    const registrationForm = guestCategories.find(guestCategory => {
      return guestCategory.registration_form_id == this.registrationFormId();
    });
    return (registrationForm == null);
  }

  attachFormToGuestCategory() {
    return (guestCategory) => {
      const { updateGuestCategory } = this.props;
      const payload = {
        registration_form_id: this.registrationFormId(),
        custom_registration_form_enabled: true
      };
      updateGuestCategory(urlEventId(), guestCategory.id, payload);
    };
  }

  registrationForm(props = this.props) {
    const { registrationForms } = props;
    return registrationForms.find(item => item._id === this.registrationFormId());
  }

  toggleFormTitleUpdate() {
    const { updatingFormTitle, currentFormTitle } = this.state;
    this.setState({ updatingFormTitle: !updatingFormTitle });
    if (updatingFormTitle) {
      //title just changed
      this.updateRegistrationForm({ title: currentFormTitle });
    }
  }

  handleTitleSubmit(e) {
    e.preventDefault();
    this.toggleFormTitleUpdate();
  }

  updateRegistrationForm(update) {
    const { updateRegistrationForm } = this.props;
    updateRegistrationForm(this.registrationFormId(), update);
  }

  handleFormTitleChanges(e) {
    this.setState({ currentFormTitle: e.target.value });
  }

  backToRegistrationFormList(e) {
    //required to reload layout
    e.preventDefault();
    window.location = pathToRegistrationForms();
  }

  renderFormTitle() {
    const { updatingFormTitle, currentFormTitle } = this.state;

    if (!updatingFormTitle) {
      return <p className="d-flex flex-wrap ml-15 mr-5 mb-0 d-none d-sm-inline">
        { I18n.t("react.registration_form.customization") }{ " " }
        <span onClick={this.toggleFormTitleUpdate} style={{ fontWeight: "bold" }}>
          { currentFormTitle }
        </span>
      </p>;
    }

    return <form className="row g-2 align-items-center ml-15" onSubmit={this.handleTitleSubmit}>
      <div className="col-auto d-none d-sm-inline">
        { I18n.t("react.registration_form.customization") }{ " " }
      </div>
      <div className="col-auto">
        <input type="text"
          className="form-control"
          value={ currentFormTitle }
          onBlur={ this.toggleFormTitleUpdate }
          onChange={this.handleFormTitleChanges}
          autoFocus="true"
          style={{ height: "28px", padding: "5px" }} />
      </div>
    </form>;
  }

  hasInvalidAccessPrivileges(accessPrivileges, type) {
    const { guestCategories } = this.props;
    const linkedGuestCategories = guestCategories.filter(category => category.registration_form_id === this.registrationForm()._id);
    let gcsError = [];
    linkedGuestCategories.forEach(gc => {
      if (accesspointsNotIncludedInGuestCategory(accessPrivileges, gc.id).length !== accessPrivileges.length) {
        gcsError.push(gc.name);
      }
    });
    return !isEmpty(gcsError) ? I18n.t(`react.registration_form.error_${type}`, { guest_category_name: gcsError.toString() }) : null;
  }

  canUnfocusItem() {
    const { registrationFormItems } = this.props;
    const itemBeingEditedVar = itemBeingEdited(registrationFormItems);
    if (itemBeingEditedVar && itemBeingEditedVar.error) {
      return false;
    }
    return true;
  }

  createItem(type) {
    return () => {
      if (!this.canUnfocusItem()) {
        return;
      }

      const { newRegistrationFormItem, registrationFormItems, registrationFormSteps } = this.props;

      const activeStep = stepBeingEdited(registrationFormSteps);
      if (!activeStep) {
        return; //should never happen
      }
      const activeSectionId = activeStep.form_sections[0]; //assume only one section per step for now

      //calculate rank
      let rank = 1000;
      let rank_before_id = null;
      let rank_after_id = null;
      const itemBeingEditedVar = itemBeingEdited(registrationFormItems);
      if (itemBeingEditedVar) {
        //rank will be after the item being edited in the same section
        const sectionItems = registrationFormItems.filter(item => { return item.form_section_id === activeSectionId; });
        const afterItem = sectionItems[sectionItems.indexOf(itemBeingEditedVar) + 1];
        if (afterItem) {
          rank = itemBeingEditedVar.rank + Math.floor((afterItem.rank - itemBeingEditedVar.rank) / 2);
          if (rank === itemBeingEditedVar.rank) {
            rank_before_id = itemBeingEditedVar._id;
            rank_after_id = afterItem._id;
          }
        } else {
          //last item
          rank = itemBeingEditedVar.rank + 1000;
        }
      }
      newRegistrationFormItem(type, activeSectionId, activeStep.registration_form_id, { rank, rank_before_id, rank_after_id });
    };
  }

  renderNavbar() {
    const { guestCategories, guestCategoriesErrors } = this.props;
    const registrationForm = this.registrationForm();

    return (
      <nav className="navbar navbar-expand navbar-light bg-light navbar-static-top" ref="navBar">
        <div className="container-fluid">
          <div className="navbar-text d-flex align-items-center">
            <OverlayTrigger placement="right" overlay={addTooltip(I18n.t("react.builders.close_builder"))}>
              <a href="#" className="d-flex" onClick={this.backToRegistrationFormList}>{ Icons.close() }</a>
            </OverlayTrigger>

            { this.renderFormTitle() }
          </div>

          <div className="navbar-end">
            <ul className="nav">
              <RegistrationFormPreviewDropdown guestCategories={guestCategories}
                guestCategoriesErrors={guestCategoriesErrors}
                registrationForm={registrationForm}
                handleLinkToCategory={this.attachFormToGuestCategory} />
              <RegistrationFormMoreDropdown>
                <DestroyRegistrationFormMenuItem registrationForm={registrationForm} destroyRegistrationForm={this.destroyRegistrationForm} isDestroyable={this.isDestroyable()}/>
              </RegistrationFormMoreDropdown>
            </ul>
          </div>
        </div>
      </nav>
    );
  }

  toggleTranslationsPane(e) {
    e && e.preventDefault();
    this.setState({ displayTranslationsPane: !this.state.displayTranslationsPane });
  }

  renderTranslations() {
    const { match, location, history, notice, noticeType } = this.props;
    const { displayTranslationsPane } = this.state;

    return displayTranslationsPane && <SlidingPane
      isOpen={true}
      title={I18n.t("react.registration_form.translations_title")}
      from="right"
      width='90%'
      onRequestClose={this.toggleTranslationsPane}
      className="width-100-xs width-90-sm"
      closeIcon={Icons.close()}
    >
      <div style={{ padding: "0px 20px 20px" }}>
        <NoticeBlock notice={notice} noticeType={noticeType} />
        <TranslationTable
          match={match}
          location={location}
          history={history}
          type="registration_forms"
          eventId={urlEventId()}
          id={this.registrationFormId()}
          inSlidingPane={true}
          onlySimpleValidateButton={true}
        />
      </div>
    </SlidingPane>;
  }

  render() {
    const { guestCategories, registrationFormSteps, registrationFormSections, registrationFormItems,
      updateRegistrationFormItem, slidingPane, loading, event, destroyRegistrationFormItem,
      ticketingCategory } = this.props;
    const { navBarHeight, addBoxTopPosition } = this.state;

    if (loading)
      return <Loader size="large" inline={false} message={I18n.t("react.loader.loading")} />;

    return (
      <div className={`form-builder ${slidingPane ? "sliding-pane" : ""}`}>
        {slidingPane ? null : this.renderNavbar()}

        <div className="container-fluid">
          <div className="row">
            <SideBarRegistrationForm
              itemsForCurrentStep={itemsByStep(registrationFormItems, registrationFormSections, stepBeingEdited(registrationFormSteps), true)}
              items={registrationFormItems}
              steps={registrationFormSteps}
              formId={this.registrationFormId()}
              updateRegistrationFormItem={updateRegistrationFormItem}
              createStep={this.createFormStep}
              createItem={this.createItem}
              paddingTopSideBar = {navBarHeight}
              addBoxTopPosition = {addBoxTopPosition}
              toggleTranslationsPane={this.toggleTranslationsPane}
              event={event}
              destroyRegistrationFormItem={destroyRegistrationFormItem}
            />
            <RegistrationFormItems
              formId={this.registrationFormId()}
              items={registrationFormItems}
              sections={registrationFormSections}
              steps={registrationFormSteps}
              navBarHeight={navBarHeight}
              createStep={this.createFormStep}
              eventId={urlEventId()}
              locale={urlEventLocale()}
              hasInvalidAccessPrivileges={this.hasInvalidAccessPrivileges}
              guestCategories={guestCategories}
              ticketingCategory={ticketingCategory}
            />
            { this.renderTranslations() }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let res = pick(state,
    "registrationFormItems",
    "registrationFormSections",
    "registrationFormSteps",
    "event"
  );
  res.guestCategories = state.guestCategories.data;
  res.guestCategoriesErrors = state.guestCategories.errors;
  res.registrationForms = state.registrationForms.data;
  res.loading = state.registrationForms.loading;
  res.notice = state.notifications.currentNotice;
  res.noticeType = state.notifications.noticeType;
  res.ticketingCategory = state.ticketingCategory.data;
  return res;
}

const mapDispatchToProps = {
  removeCustomCssRegistrationForm,
  updateRegistrationForm,
  updateRegistrationFormItem,
  removeRegistrationForm,
  fetchRegistrationForm,
  createRegistrationFormStep,
  importGuestCategories,
  updateGuestCategory,
  newRegistrationFormItem,
  destroyRegistrationFormItem,
  fetchTicketing
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
