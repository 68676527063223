import { Component, ChangeEvent } from "react";
import { CreateNewAccesspointId } from "../../constants/Constants";
import { FormItemOption } from "../../types/FormItemOption";
import { Accesspoint } from "../../types/Accesspoint";

interface Props {
  accesspoints: Accesspoint[];
  itemOption: FormItemOption;
  updateHandler(params: any): void;
  destroyHandler(id: string): void;
  allowDestroy: boolean;
  mode: string;
  shouldBeDisplayed: boolean;
  radioStyle: boolean;
}

interface State {
  label: string;
  key: string;
  value: string;
  minQuantity: number;
  maxQuantity: number;
}

class ProductOption extends Component<Props, State> {
  constructor(props) {
    super(props);
    ["onfieldChanged", "keyChanged", "onFieldBlur", "destroy"].forEach(item => {
      this[item] = this[item].bind(this);
    });
    const { itemOption } = props;
    this.state = {
      label: itemOption.label,
      key: itemOption.key,
      value: itemOption.value,
      minQuantity: itemOption.min_quantity,
      maxQuantity: itemOption.max_quantity
    };
  }

  onfieldChanged(fieldKey: string): (e: ChangeEvent<any>) => void {
    return (e: ChangeEvent<any>): void => {
      this.setState({
        [fieldKey]: e.target.value
      } as Pick<State, "label" | "minQuantity" | "maxQuantity">);
    };
  }

  keyChanged(e: ChangeEvent<any>): void {
    const { updateHandler, accesspoints, itemOption } = this.props;
    const accesspoint = accesspoints.find(accesspoint => accesspoint.id == e.target.value);
    if (!accesspoint) {
      return; // hitting the pick a value in the select, just do nothing
    }
    const key = accesspoint.id;
    const label = accesspoint.display_name;
    updateHandler({ ...itemOption, key, label });

    // dont need to change the state with this value
    if (CreateNewAccesspointId !== e.target.value) {
      this.setState({ key, label });
    }
  }

  onFieldBlur(stateKey: string, optionKey: string): () => void {
    return (): void => {
      const stateValue = this.state[stateKey];
      const { itemOption, updateHandler } = this.props;
      if (itemOption[optionKey] === stateValue) {
        return;
      }

      updateHandler({ ...itemOption, [optionKey]: stateValue });
    };
  }

  renderAccesspoints(disabledClass = ""): JSX.Element {
    const { accesspoints } = this.props;
    const { key } = this.state;
    const options = accesspoints.map(accesspoint => {
      return <option key={ accesspoint.id } value={ accesspoint.id }>{ accesspoint.name }</option>;
    });
    return <select className={`form-select ${disabledClass}`} value={key} onChange={this.keyChanged}>
      <option key="default" value=""> { I18n.t("react.form_question_item.pick_a_product") }</option>
      {options}
    </select>;
  }

  destroy(): void {
    const { itemOption, destroyHandler } = this.props;
    destroyHandler(itemOption._id);
  }

  render(): JSX.Element {
    const { allowDestroy, mode, shouldBeDisplayed } = this.props;
    const { label, maxQuantity, minQuantity } = this.state;
    const disabledClass = shouldBeDisplayed ? "" : "disabled";
    const destroyButton = (mode == "edit" && allowDestroy) ? (
      <a className="btn btn-secondary" onClick={this.destroy} style={ { cursor: "pointer" } }>
        <i className="fa-regular fa-close"></i>
      </a>
    ) : null;

    return <div className="row mb-10">
      <div className="col-4">
        <input type="text" className={`form-control ${disabledClass}`} value={label} onChange={this.onfieldChanged("label")} onBlur={this.onFieldBlur("label", "label")}/>
      </div>
      <div className="col-2">
        <input type="number" className={`form-control ${disabledClass}`} value={minQuantity} onChange={this.onfieldChanged("minQuantity")} onBlur={this.onFieldBlur("minQuantity", "min_quantity")} min="0" />
      </div>
      <div className="col-2">
        <input type="number" className={`form-control ${disabledClass}`} value={maxQuantity} onChange={this.onfieldChanged("maxQuantity")} onBlur={this.onFieldBlur("maxQuantity", "max_quantity")} min="0" />
      </div>
      <div className="col-3">
        { this.renderAccesspoints(disabledClass) }
      </div>
      <div className="col-1">
        { destroyButton }
      </div>
    </div>;
  }
}

export default ProductOption;
