import { Component } from "react";
import { injectDefaultsGuestFields, isAddGuestFieldKey } from "../../utils/guestFieldsUtils.js";
import { optinsFilters } from "../../constants/Constants";
import GuestFieldsModal from "../guest_field/GuestFieldsModal.react";
import FormItemOption from "./FormItemOption.react";
import FormItemWithOptions, { nextOptionRank } from "./FormItemWithOptions.react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class CheckboxGroupType extends Component {

  constructor(props) {
    super(props);
    [
      "createOption",
      "availableGuestFields",
      "renderFooter",
      "checkedByDefault",
      "checkboxTooltip",
      "renderModal",
      "openCreateGuestFieldModal",
      "closeCreateGuestFieldModal",
      "updateFormItemOption",
      "getNewGuestField",
      "optionsFocusedFn"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = { showCreateGuestFieldModal: false, focusedOption: null };
  }

  createOption(e) {
    e.preventDefault();
    const { updateFormItem, formItem } = this.props;
    const rank = nextOptionRank(formItem);
    const guestField = this.availableGuestFields()[0];
    updateFormItem({
      form_item_options: [{ label: guestField.label, key: guestField.key, rank }]
    });
  }

  //TODO move outside
  destroyOption(optionId) {
    const { destroyFormItemOption } = this.props;
    destroyFormItemOption(optionId);
  }

  availableGuestFields(optionItem = null) {
    const { guestFields, formItem } = this.props;
    if (!formItem.form_item_options) {
      return guestFields;
    }

    const usedGuestFieldKeys = formItem.form_item_options.map(option => {
      return option.key;
    });

    return guestFields.filter(field => {
      if (optionItem != null && optionItem.key == field.key) {
        return true;
      }
      return usedGuestFieldKeys.indexOf(field.key) == -1;
    });
  }

  checkedByDefault(option) {
    return (e) => {
      const value = e.target.checked;
      if (option.value === value) {
        return;
      }
      const valueCleared = value ? value : "";
      const { updateFormItem } = this.props;
      updateFormItem({
        form_item_options: [Object.assign({}, option, { value: valueCleared })]
      });
    };
  }

  checkboxTooltip(checked) {
    const checkedMessage = checked ? I18n.t("react.form_checkbox_group.checked_by_default") : I18n.t("react.form_checkbox_group.unchecked_by_default");
    return (
      <Tooltip id="tooltip">{checkedMessage}</Tooltip>
    );
  }

  renderFooter() {
    let handleOption = <a href="#" onClick={this.createOption}>{ I18n.t("react.form_checkbox_group.add_checkbox") } </a>;
    if (this.availableGuestFields().length <= 0) {
      handleOption = (
        <div className="alert alert-warning" role="alert">
          <p className="alert-link">{I18n.t("react.form_checkbox_group.no_more_guest_field")}</p>
        </div>
      );
    }
    return handleOption;
  }

  closeCreateGuestFieldModal() {
    this.setState({ showCreateGuestFieldModal: false });
  }

  openCreateGuestFieldModal() {
    this.setState({ showCreateGuestFieldModal: true });
  }

  updateFormItemOption(formItemOptionsModified) {
    const { updateFormItem } = this.props;
    if (!formItemOptionsModified.form_item_options) {
      return;
    }
    const key = formItemOptionsModified.form_item_options[0].key;
    if (isAddGuestFieldKey(key)) {
      this.openCreateGuestFieldModal();
      return;
    } else if (optinsFilters.includes(key)) {
      formItemOptionsModified.form_item_options[0]["value"] = ""; // force uncheck option for optins
    }
    return updateFormItem(formItemOptionsModified);
  }

  getNewGuestField(guestField) {
    const { updateFormItem } = this.props;
    const { focusedOption } = this.state;
    if (!focusedOption) {
      return;
    }
    const formItemOptionModified = Object.assign({}, focusedOption, { key: guestField.key, label: guestField.label });
    updateFormItem({
      form_item_options: [formItemOptionModified]
    });
  }

  // being notified by formItemWithOption which formItemOption is focused
  optionsFocusedFn(formItemOption) {
    this.setState({ focusedOption: formItemOption });
  }

  isOptinField(formItemOption) {
    return optinsFilters.includes(formItemOption.key);
  }

  renderModal() {
    const { event } = this.props;
    const { showCreateGuestFieldModal } = this.state;
    if (!event._id) return null;
    return (<GuestFieldsModal event={event} isVisible={showCreateGuestFieldModal} onCloseFunction={this.closeCreateGuestFieldModal} updateParent={this.getNewGuestField} />);
  }

  render() {
    const { mode, formItem } = this.props;

    const options = formItem.form_item_options.map(option => {
      return (
        <FormItemOption formItem={formItem} key={option._id} mode={mode} itemOption={option} keyInputType="dropdown" keyInputData={injectDefaultsGuestFields(this.availableGuestFields(option))}>
          <OverlayTrigger placement="top" overlay={this.checkboxTooltip(option.value)}>
            <input type="checkbox" className="form-check-input" checked={option.value} onChange={this.checkedByDefault(option)} disabled={this.isOptinField(option)} />
          </OverlayTrigger>
        </FormItemOption>
      );
    });

    return (
      <div className="row">
        {this.renderModal()}
        <FormItemWithOptions mode={mode} formItem={formItem} updateFormItem={this.updateFormItemOption} footer={this.renderFooter()} optionsFocusedCallback={this.optionsFocusedFn}>
          { options }
        </FormItemWithOptions>
      </div>
    );
  }

}

export default CheckboxGroupType;
