import { Component } from "react";
import { connect } from "react-redux";
import map from "lodash/map";
import { updateUI } from "../actions/UIAppearanceActionCreators";
import { GuestStatuses } from "../constants/Constants";
import { launchBatchUpdate } from "../actions/GuestUpdatesActionCreators";
import { requestCountGuests } from "../actions/ImportActionCreators";
import { fetchGuestFieldsMostUsedValues } from "../actions/GuestFieldsActionCreators";
import UpdateGuestFormComponent from "../components/UpdateGuestFormComponent.react";
import { statusQueryWithoutRegistered, queryStringAndSelectedGuests } from "../utils/QueryStringUtils";
import withModal from "./withModal.react";
import { urlEventId } from "../utils/pathUtils";

const GUEST_STATUS_KEY = "status";

class ChangeStatus extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { location, selectedGuests, fetchGuestFieldsMostUsedValues, requestCountGuests, updateUI } = this.props;
    const updates = {
      "modalTitle": I18n.t("react.reports.choose_status")
    };
    updateUI(updates);
    fetchGuestFieldsMostUsedValues(urlEventId(), "", GUEST_STATUS_KEY);
    fetchGuestFieldsMostUsedValues(urlEventId(), queryStringAndSelectedGuests(location, selectedGuests), GUEST_STATUS_KEY);
    requestCountGuests(this.queryWithStatusUpdatable(), "nbGuestsWillBeUpdated", true);
  }

  queryWithStatusUpdatable() {
    const { location, selectedGuests } = this.props;
    const query = queryStringAndSelectedGuests(location, selectedGuests);
    return query ? `${query} ${statusQueryWithoutRegistered()}` : statusQueryWithoutRegistered();
  }

  submit(value) {
    const { launchBatchUpdate, closeModal } = this.props;
    //update fields
    launchBatchUpdate(urlEventId(), this.queryWithStatusUpdatable(), GUEST_STATUS_KEY, value.toLowerCase());
    closeModal();
  }

  statusesKeysToObjects(keys) {
    return map(keys, key => {
      return { _id: key, name: I18n.t(key) };
    });
  }

  render() {
    const { selectedGuestCount, eventFieldMostUsedValues, searchFieldMostUsedValues } = this.props;
    const options = map(GuestStatuses, status => {
      return { value: status, label: I18n.t(status) };
    });

    return (
      <div>
        <div className="alert alert-warning">
          <strong>{I18n.t("react.reports.warning")} !</strong> {I18n.t("react.reports.warning_status")}
        </div>
        <UpdateGuestFormComponent
          options={options}
          eventFieldMostUsedValues={this.statusesKeysToObjects(eventFieldMostUsedValues)}
          searchFieldMostUsedValues={this.statusesKeysToObjects(searchFieldMostUsedValues)}
          selectedGuestCount={selectedGuestCount}
          fieldLabel={I18n.t("mongoid.attributes.guest.status")}
          selectPlaceholder={I18n.t("react.reports.choose_status")}
          submitAction={this.submit}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    eventFieldMostUsedValues: state.guestFieldStatistics.eventFieldMostUsedValues,
    searchFieldMostUsedValues: state.guestFieldStatistics.searchFieldMostUsedValues,
    selectedGuestCount: state.appearance.nbGuestsWillBeUpdated,
    selectedGuests: state.guests.selectedGuests
  };
}

const mapDispatchToProps = {
  fetchGuestFieldsMostUsedValues,
  launchBatchUpdate,
  requestCountGuests,
  updateUI
};

export default connect(mapStateToProps, mapDispatchToProps)(withModal(ChangeStatus));
export { ChangeStatus };
