import { ActionTypes } from "../constants/Constants";
import { ExhibitorsStatisticsState } from "../types/ExhibitorStatistics";

const defaultState: ExhibitorsStatisticsState = {
  errors: null,
  isFetching: false,
  byNumberOfInterests: null,
  byNumberOfLeads: null,
};

export function exhibitorsStatistics(state = defaultState, action: any): ExhibitorsStatisticsState {
  switch (action.type) {
  case ActionTypes.FETCH_EXHIBITORS_STATISTICS_REQUEST:
  case ActionTypes.FETCH_EXHIBITORS_LEADS_STATISTICS_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.FETCH_EXHIBITORS_STATISTICS_SUCCESS:
    return { ...state, byNumberOfInterests: action.data, isFetching: false, errors: null };
  case ActionTypes.FETCH_EXHIBITORS_STATISTICS_FAILURE:
    return { ...state, byNumberOfInterests: [], isFetching: false, errors: action.data };
  case ActionTypes.FETCH_EXHIBITORS_LEADS_STATISTICS_SUCCESS:
    return { ...state, byNumberOfLeads: action.data, isFetching: false, errors: null };
  case ActionTypes.FETCH_EXHIBITORS_LEADS_STATISTICS_FAILURE:
    return { ...state, byNumberOfLeads: [], isFetching: false, errors: action.data };
  default:
    return state;
  }
}

export default exhibitorsStatistics;
