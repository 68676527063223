"use strict";
import snakeCase from "lodash/snakeCase";
import { Component, MouseEvent, ChangeEvent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withModal from "./withModal.react";
import { queryStringAndSelectedGuests } from "../utils/QueryStringUtils";
import { updateUI } from "../actions/UIAppearanceActionCreators";
import { updateSavedSearch } from "../actions/SavedSearchActionCreators";
import { urlWithQuery } from "../utils/pathUtils";
import ErrorMessage from "../components/shared/ErrorMessage.react";

function i18n(key, params = {}): string {
  return I18n.t(`react.edit_saved_search_modal.${key}`, params);
}

const FORM_ATTRIBUTES = ["search_query", "name"];

interface Props {
  savedSearches: any[];
  errors: any;
  updateUI(params: any): void;
  updateSavedSearch(eventId: string, id: string, updatedParams: any, locale: string, redirectUrl: string, notificationParams: any): void;
  match: any;
}

interface State {
  name: string;
  searchQuery: string;
  currentSearchQuery: string;
  searchLoaded: boolean;
}

class EditSavedSearchModal extends Component<Props, State> {
  constructor(props) {
    super(props);

    [
      "onChangeField",
      "replaceSearchQuery",
      "onSubmit"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      searchLoaded: false,
      name: null,
      searchQuery: null,
      currentSearchQuery: queryStringAndSelectedGuests(props.location)
    };
  }

  componentDidUpdate(): void {
    const { updateUI } = this.props;
    const { searchLoaded } = this.state;
    const savedSearch = this.savedSearch(this.props);

    if (!savedSearch || searchLoaded) return;

    const modalTitle = i18n("title", { name: savedSearch.name });
    updateUI({ modalTitle });
    this.setState({ searchLoaded: true, name: savedSearch.name, searchQuery: savedSearch.search_query });
  }

  savedSearch(props: Props = this.props): any {
    const { match, savedSearches } = props;

    return savedSearches && savedSearches.find(savedSearch => {
      return savedSearch._id == match.params.saved_search_id;
    });
  }

  onChangeField(key: string): (e: ChangeEvent<any>) => void {
    return (e): void => this.setState({ [key]: e.target.value } as Pick<State, "searchQuery" | "name">);
  }

  renderInput(key, labelExtra = null): JSX.Element {
    const value = this.state[key];

    return <div className="mb-3">
      <label className="form-label">
        {I18n.t(`mongoid.attributes.saved_search.${snakeCase(key)}`)}
      </label>
      { " " }
      { labelExtra }
      <input className="form-control" type="text" value={value} onChange={this.onChangeField(key)}/>
    </div>;
  }

  shouldShowReplaceSearchQueryButton(): boolean {
    const { searchQuery, currentSearchQuery } = this.state;

    return searchQuery == currentSearchQuery;
  }

  replaceSearchQuery(e: MouseEvent<any>): void {
    e.preventDefault();
    const { currentSearchQuery } = this.state;
    this.setState({ searchQuery: currentSearchQuery });
  }

  renderReplaceSearchQueryByCurrentSearchLink(): JSX.Element {
    if (this.shouldShowReplaceSearchQueryButton()) return;

    return <a href="#" onClick={this.replaceSearchQuery}>
      <i className="fa-regular fa-clipboard"></i> { i18n("replace_by_current_search_query") }
    </a>;
  }

  onSubmit(e: MouseEvent<any>): void {
    e.preventDefault();

    const { updateSavedSearch, match } = this.props;
    const { currentSearchQuery } = this.state;

    const savedSearchAttributes = Object.keys(this.state)
      .filter(key => FORM_ATTRIBUTES.includes(snakeCase(key)))
      .reduce((obj, key) => {
        if (this.state[key]) obj[snakeCase(key)] = this.state[key];
        return obj;
      }, {});

    updateSavedSearch(this.savedSearch().event_id, this.savedSearch()._id, savedSearchAttributes, match.params.locale, urlWithQuery(currentSearchQuery, "guests"), { notice: i18n("saved_search_successfully_saved"), noticeType: "success" });
  }

  renderBackToSegmentsListButton(): JSX.Element {
    const { currentSearchQuery } = this.state;

    return <Link to={urlWithQuery(currentSearchQuery, "guests/modal/saved_searches")} className="btn btn-secondary float-end mr-10">
      <i className="fa-regular fa-chevron-left fa-fw fa-xs"></i> { i18n("back_to_saved_searches_list") }
    </Link>;
  }

  renderSaveSearchButton(): JSX.Element {
    return <button type="submit" className="btn btn-primary float-end">
      { I18n.t("save") }
    </button>;
  }

  render(): JSX.Element {
    if (!this.savedSearch()) return null;

    const { errors } = this.props;

    return <div>
      <ErrorMessage errors={errors} model="saved_search"/>
      <form onSubmit={this.onSubmit}>
        { this.renderInput("name") }
        { this.renderInput("searchQuery", this.renderReplaceSearchQueryByCurrentSearchLink()) }
        <hr />
        <p className="text-end">
          {this.renderSaveSearchButton()}
          {this.renderBackToSegmentsListButton()}
        </p>
      </form>
    </div>;
  }
}

function mapStateToProps(state): any {
  return {
    savedSearches: state.savedSearches.data,
    errors: state.savedSearch && state.savedSearch.errors
  };
}

const mapDispatchToProps = {
  updateUI,
  updateSavedSearch
};

export default connect(mapStateToProps, mapDispatchToProps)(withModal(EditSavedSearchModal));
