import { Component } from "react";
import { connect } from "react-redux";

import moment from "moment";
import DashboardNavTab from "../../components/dashboard/DashboardNavTab.react";
import HeaderStats from "../../components/dashboard/HeaderStats.react";

import { redirectIfUnauthorized, isAuthorized, authorizedCategories } from "../../utils/aclUtils";
import { isEnabled } from "../../utils/featureSetUtils";
import { CHECKIN } from "../../constants/FeaturesSet";
import AtlasChart from "../../components/AtlasChart.react";
import { DASHBOARD_HEADER_NO_VALUE_STR } from "../../constants/Constants";
import DateRangePicker from "../../components/DateRangePicker.react";
import { setSelectedGuestCategoryIds } from "../../actions/GuestCategoryActionCreators";
import { fetchEventAtlasJwtToken } from "../../actions/EventAtlasJwtTokenActionCreator";
import FilterDropdown from "../../components/FilterDropdown.react";
import { importGuestCategories } from "../../actions/ImportGuestCategoryActionCreators";
import { fetchAccesspoints } from "../../actions/AccesspointActionCreators";
import PopulationTypePicker from "../../components/PopulationTypePicker.react";
import chunk from "lodash/chunk";
import { buildFilter } from "../../utils/AtlasChartUtils";
import { urlEventId } from "../../utils/pathUtils";
import withDatabaseDefaultRegionRestriction from "../../components/shared/WithDatabaseDefaultRegionRestriction.react";

const CHECKINS_BY_HOUR_CHART_ID = "6343dadd-4445-4a88-88d8-f92a8e280c67";
const CUMULATIVE_VISITOR_BY_HOUR_CHART_ID = "680941fa-1899-4982-85e7-ccb070ee5e48";
const DAY_STATE_KEY_FORMAT = "YYYY-MM-DD";
const MAX_DAYS = 15;

const ACCESS_STATUSES_PICKER_ITEMS = [
  { id: "0", name: I18n.t("access_granted") },
  { id: "1", name: I18n.t("guest_unknown") },
  { id: "2", name: I18n.t("accesspoint_not_accessible") },
  { id: "3", name: I18n.t("too_many_access") },
];

const ACCESS_TYPE_PICKER_ITEMS = [
  { id: "virtual", name: i18n("virtual") }, { id: "physical", name: i18n("physical") }
];

function i18n(key, opts = {}) {
  return I18n.t(`react.dashboard.checkin.${key}`, opts);
}

class CheckinDashboard extends Component {
  constructor(props) {
    redirectIfUnauthorized("reports", "read");
    super(props);
    [
      "onDateRangeChanged",
      "onSelectedItemsChange",
      "onChartDataChangedHandler"
    ].forEach(fn => this[fn] = this[fn].bind(this));
    moment.locale(I18n.currentLocale());
    this.state = {
      startDate: null,
      endDate: null,
      selectedAccesspointIds: [],
      selectedAccessTypes: [],
      selectedAccessStatuses: [],
      chartData: {}
    };
  }

  componentDidMount() {
    const { match, importGuestCategories, fetchAccesspoints, fetchEventAtlasJwtToken } = this.props;
    fetchEventAtlasJwtToken(match.params.event_id);
    fetchAccesspoints(urlEventId());
    importGuestCategories();
  }

  endDate() {
    const { endDate } = this.state;
    if (endDate) return moment(endDate);

    const { event } = this.props;
    if (!event._id) return null;

    return moment(event.end_date);
  }

  startDate() {
    const { startDate } = this.state;
    if (startDate) return moment(startDate);

    const { event } = this.props;
    if (!event._id) return null;

    return moment(event.start_date).add(MAX_DAYS, "days").isBefore(this.endDate()) ? this.endDate().clone().subtract(MAX_DAYS, "days") : moment(event.start_date);
  }

  onSelectedItemsChange(stateKey) {
    return (selectedIds) => {
      this.setState({ [stateKey]: selectedIds });
    };
  }

  onDateRangeChanged(startDate, endDate) {
    this.setState({ startDate, endDate });
  }

  jwtToken() {
    const { event, jwtTokens } = this.props;

    return event._id ? jwtTokens[event._id] : null;
  }

  // Due to concurrency issues with multi charts, keep this method as fast as possible, storing data but no computing
  onChartDataChangedHandler(day) {
    const key = day.format(DAY_STATE_KEY_FORMAT);
    return (chartData) => this.setState({ chartData: { ...this.state.chartData, [key]: chartData } });
  }

  onVisitorChartDataChangedHandler() {
    return (chartData) => {
      if (!chartData) return;

      this.setState({ visitorChartData: chartData });
    };
  }

  dayKpis(day) {
    const { chartData } = this.state;

    const date = day.format(DAY_STATE_KEY_FORMAT);
    const data = chartData[date];
    if (!data) return null;

    return data.documents.reduce((kpis, document) => {
      kpis.checkinsCount += document.y;
      if (document.y > kpis.topHourCount) {
        kpis.topHour = moment(`${date}T${document.x}`);
        kpis.topHourCount = document.y;
      }
      return kpis;
    }, { checkinsCount: 0, topHour: DASHBOARD_HEADER_NO_VALUE_STR, topHourCount: 0 });
  }

  days() {
    const days = [];

    const firstDate = moment(this.startDate()).startOf("day");
    const lastDate = moment(this.endDate()).endOf("day");

    for (var day = moment(firstDate); day.isBefore(lastDate); day.add(1, "days")) {
      days.push(day.clone());
    }

    return days;
  }

  visitorKpi() {
    const { visitorChartData } = this.state;
    if (!visitorChartData) return null;

    // sum all visitorChartData.documents y
    const visitors = visitorChartData.documents.reduce((visitors, document) => {
      visitors += document.y;
      return visitors;
    }, 0);
    return visitors;
  }

  kpis() {
    return this.days().reduce((kpis, day) => {
      const dayKpis = this.dayKpis(day);
      if (!dayKpis) return kpis;

      if (dayKpis.checkinsCount !== DASHBOARD_HEADER_NO_VALUE_STR) {
        kpis.checkinsCount += dayKpis.checkinsCount;
        if (dayKpis.topHourCount > kpis.topHourCount) {
          kpis.topHourCount = dayKpis.topHourCount;
          kpis.topHour = dayKpis.topHour;
        }
        if (kpis.topDayCount < dayKpis.checkinsCount) {
          kpis.topDayCount = dayKpis.checkinsCount;
          kpis.topDay = moment(day);
        }
      }
      return kpis;
    }, { checkinsCount: 0, topHour: null, topHourCount: 0, topDay: null, topDayCount: 0 });
  }

  customDayFilter(day) {
    const { selectedGuestCategoryIds } = this.props;
    const { selectedAccesspointIds, selectedAccessTypes, selectedAccessStatuses } = this.state;
    const filters = [
      { type: "startDate", field: "access_stamp", value: day },
      { type: "endDate", field: "access_stamp", value: day },
      { type: "idsIn", field: "guest_data.guest_category_id", value: selectedGuestCategoryIds },
      { type: "idsIn", field: "accesspoint_id", value: selectedAccesspointIds },
      { type: "valuesIn", field: "access_type", value: selectedAccessTypes },
      { type: "valuesIn", field: "access_status", value: selectedAccessStatuses.map(i => parseInt(i)) },
    ];
    return buildFilter(filters);
  }

  customFilter(startDate = null, endDate = null) {
    const { selectedGuestCategoryIds } = this.props;
    const filters = [
      { type: "startDate", field: "arrival_date", value: startDate || this.startDate() },
      { type: "endDate", field: "arrival_date", value: endDate || this.endDate() },
      { type: "idsIn", field: "guest_category_id", value: selectedGuestCategoryIds },
    ];
    return buildFilter(filters);
  }

  dataFetched() {
    const { chartData, visitorChartData } = this.state;

    return !!Object.values(chartData || {}).find(value => value !== null) && visitorChartData !== null;
  }

  dayTotal(day) {
    return (this.dayKpis(day) || {}).checkinsCount || DASHBOARD_HEADER_NO_VALUE_STR;
  }

  chartsPerLine() {
    if (this.days().length <= 1) return 1;

    return this.days().length > 4 ? 3 : 2;
  }

  chartsHaveNoData() {
    if (!this.dataFetched()) return false;

    return this.kpis().checkinsCount === 0;
  }

  renderCheckinChartsChunk(days) {
    const { event } = this.props;

    return days.map(day => {
      return <div className={ `col-md-${12 / this.chartsPerLine()}`} key={day}>
        <div className="card">
          <AtlasChart
            eventId={event._id}
            title={i18n("checkins_by_hour_chart_title", { day: day.clone().format("ll"), total: this.dayTotal(day) })}
            onChartDataChanged={ this.onChartDataChangedHandler(day) }
            chartId={ CHECKINS_BY_HOUR_CHART_ID }
            height="300px"
            customFilter={this.customDayFilter(day)}
            exportOptions={{
              columnsMapping: { hour: { documentKey: "x" }, count: { documentKey: "y", total: true } },
              type: "checkins_by_hour"
            }}
          />
        </div>
      </div>;
    });
  }

  renderHeaderStats() {
    const kpis = this.kpis();
    const visitorKpi = this.visitorKpi();

    return (
      <HeaderStats
        dataFetched={this.dataFetched()}
        headers={
          [
            { i18nKey: "total", value: kpis.checkinsCount, nbCols: 3 },
            { i18nKey: "top_hour", value: kpis.topHourCount, labelInfoValue: kpis.topHour && kpis.topHour.clone().format("lll"), nbCols: 3 },
            { i18nKey: "top_day", value: kpis.topDayCount, labelInfoValue: kpis.topDay && kpis.topDay.clone().format("ll"), nbCols: 3 },
            { i18nKey: "max_visitors", value: visitorKpi, labelInfoValue: "", nbCols: 3 }
          ]
        }
        tabKey="checkin"
      />
    );
  }

  renderCumulativeVisitorCharts() {
    const { event } = this.props;
    return (
      <div className="card">
        <AtlasChart
          eventId={event._id}
          title={i18n("cumulative_visitors_by_day_title")}
          onChartDataChanged={ this.onVisitorChartDataChangedHandler() }
          chartId={ CUMULATIVE_VISITOR_BY_HOUR_CHART_ID }
          height="500px"
          customFilter={this.customFilter()}
          exportOptions={{
            columnsMapping: { day: { documentKey: "x" }, visitors: { documentKey: "y", total: true } },
            type: "new_visitor_by_day"
          }}
        />
      </div>
    );
  }

  renderCheckinCharts() {
    return chunk(this.days(), this.chartsPerLine()).map(days => {
      return <div className="row" key={days.join()}>
        {this.renderCheckinChartsChunk(days)}
      </div>;
    });
  }

  renderNoCheckins() {
    if (!this.chartsHaveNoData()) return null;

    return <div className="card nothing-yet mt-30">
      <h4>{ I18n.t("react.dashboard.checkin.no_checkins") }</h4>
    </div>;
  }

  renderGuestCategoriesPicker() {
    const { guestCategories, selectedGuestCategoryIds, setSelectedGuestCategoryIds } = this.props;

    return (
      <div className="col-auto">
        <FilterDropdown
          id="guest_category"
          items={guestCategories}
          selectedItemIds={selectedGuestCategoryIds}
          translationKey="guest_category"
          onChange={setSelectedGuestCategoryIds}
          showCells={false}
          itemColorKey="label_color"
          showBadgeNbItems={true}
        />
      </div>
    );
  }

  renderPopulationPicker() {
    const { guestCategories, selectedGuestCategoryIds, setSelectedGuestCategoryIds } = this.props;

    return <div className="col-auto">
      <PopulationTypePicker
        guestCategories={guestCategories}
        selectedGuestCategoryIds={selectedGuestCategoryIds}
        setSelectedGuestCategoryIds={setSelectedGuestCategoryIds}
        showCells={false}
        showBadgeNbItems={true}/>
    </div>;
  }

  renderAccesspointsPicker() {
    const { accesspoints } = this.props;
    const { selectedAccesspointIds } = this.state;
    if (!accesspoints || accesspoints.length === 0) return null;

    return <div className="col-auto">
      <FilterDropdown
        id="checked_in_at"
        items={accesspoints}
        selectedItemIds={selectedAccesspointIds}
        translationKey="checked_in_at"
        onChange={this.onSelectedItemsChange("selectedAccesspointIds")}
        showCells={false}
        showBadgeNbItems={true}
      />
    </div>;
  }

  renderAccessTypePicker() {
    const { selectedAccessTypes } = this.state;

    return <div className="col-auto">
      <FilterDropdown
        id="access_types"
        items={ACCESS_TYPE_PICKER_ITEMS}
        selectedItemIds={selectedAccessTypes}
        translationKey="access_types"
        onChange={this.onSelectedItemsChange("selectedAccessTypes")}
        showCells={false}
        showBadgeNbItems={true}
        hasSearch={false}
        hasSelectAll={false}
      />
    </div>;
  }

  renderAccessStatusesPicker() {
    const { selectedAccessStatuses } = this.state;

    return <div className="col-auto">
      <FilterDropdown
        id="access_statuses"
        items={ACCESS_STATUSES_PICKER_ITEMS}
        selectedItemIds={selectedAccessStatuses}
        translationKey="access_statuses"
        onChange={this.onSelectedItemsChange("selectedAccessStatuses")}
        showCells={false}
        showBadgeNbItems={true}
        hasSearch={false}
        hasSelectAll={false}
      />
    </div>;
  }

  renderPeriodDatePicker() {
    return <div className="col-auto">
      <DateRangePicker
        startDate={this.startDate()}
        endDate={this.endDate()}
        onDateRangeChanged={this.onDateRangeChanged}
        maxDaysRange={MAX_DAYS}
      />
    </div>;
  }


  renderFilters() {
    return <div className="mb-3 row g-2 align-items-center">
      { this.renderPeriodDatePicker()}
      { this.renderGuestCategoriesPicker()}
      { this.renderPopulationPicker() }
      { this.renderAccesspointsPicker() }
      { this.renderAccessTypePicker() }
      { this.renderAccessStatusesPicker() }
    </div>;
  }

  render() {
    if (!isAuthorized("reports", "read") || !isEnabled(CHECKIN)) return <div />;
    if (!this.jwtToken()) return null;

    return (
      <div>
        <DashboardNavTab active="checkin" event_has_exits={this.props.event.has_exits} />
        { this.renderHeaderStats() }
        { this.renderFilters() }
        { this.renderNoCheckins() }
        <div className={this.chartsHaveNoData() ? "d-none" : null} >
          { this.renderCumulativeVisitorCharts() }
          { this.renderCheckinCharts() }
        </div>
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    event: state.event,
    guestCategories: authorizedCategories(state.guestCategories.data),
    selectedGuestCategoryIds: state.selectedGuestCategoryIds,
    guestCategoriesFetched: state.guestCategories.fetched,
    accesspoints: state.accesspoints.data,
    eventsJwtTokensIsFetching: state.eventAtlasJwtTokens.isFetching,
    jwtTokens: state.eventAtlasJwtTokens.data
  };
}

const mapDispatchToProps = {
  setSelectedGuestCategoryIds,
  importGuestCategories,
  fetchAccesspoints,
  fetchEventAtlasJwtToken
};

export default withDatabaseDefaultRegionRestriction(connect(mapStateToProps, mapDispatchToProps)(CheckinDashboard));
