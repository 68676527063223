import { useContext } from "react";
import { featureName, featureDescription, featureMetadata, isSingleFeature, childFeatures, enabledByDefault } from "../../utils/featureSetUtils";
import FeatureStatus from "./FeatureStatus.react";
import Dependencies from "./Dependencies.react";
import FeatureActionButton from "./FeatureActionButton.react";
import { FeatureDeckContext } from "../../containers/features/FeatureSetFormContainer.react";
import ReadMoreLink from "./ReadMoreLink.react";
interface Props {
  groupKey: string;
}

const GroupFeatures: React.FC<Props> = ({ groupKey }) => {
  const { displayFeaturePane, enabledGroupFeatures, featureBeingEnabled, featureAlreadyEnabled, featureBeingToggled } = useContext(FeatureDeckContext);

  const subGroups = childFeatures(groupKey).map((featureKey) => {
    const metadata = featureMetadata(featureKey);
    const activeClass = enabledGroupFeatures(metadata.key).length > 0 || featureBeingEnabled(metadata.key) || (featureAlreadyEnabled(metadata.key) && !featureBeingToggled(metadata.key)) || enabledByDefault(metadata.key) ? "active" : "";
    const singleClass = isSingleFeature(metadata.key) ? "single" : "";

    return <div key={ metadata.key } className="col-md-4 col-sm-12">
      <div className="d-flex flex-column" onClick={ (): void => { if (!isSingleFeature(metadata.key)) displayFeaturePane(metadata.key); }}>
        <div className={`card ${ singleClass } ${ activeClass }`}>
          <div className="card-body">
            <div className="d-flex flex-row justify-content-between">
              <span className="icon-feature d-flex flex-column justify-content-center"><i className={`fa-regular fa-${ metadata["icon"] || "puzzle-piece" }`} aria-hidden="true"></i></span>
              <div className="d-flex flex-column justify-content-center">
                <FeatureActionButton featureKey={metadata.key} />
              </div>
            </div>
            <h3>{ featureName(metadata.key) }</h3>
            <div className="d-flex flex-row">
              <FeatureStatus featureKey={metadata.key} />
              <Dependencies featureKey={metadata.key} />
            </div>
            { isSingleFeature(metadata.key) ?
              <>
                <p>{ featureDescription(metadata.key) }</p>
                <ReadMoreLink featureKey={metadata.key} />
              </>
              :
              null
            }
          </div>
        </div>
      </div>
    </div>;
  });

  return <div className="row">
    { subGroups }
  </div>;
};

export default GroupFeatures;
