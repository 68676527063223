import { Component } from "react";
import { connect } from "react-redux";
import querystring from "querystring";
import Breadcrumb from "../../components/shared/Breadcrumb.react";
import { urlWithQuery } from "../../utils/pathUtils";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import { CAMPAIGN_STEPS } from "../../constants/Constants";
import { campaignStepBreadcrumbLinks } from "../../utils/campaignStepUtils";
import { fetchGuestCampaign, updateGuestCampaign } from "../../actions/GuestCampaignActionCreators";
import EmailConfiguration from "../../components/EmailConfiguration.react";

const SMS_MAX_NB_CHAR = 160;

class GuestCampaignNotification extends Component {

  constructor(props) {
    super(props);
    [
      "handleInputChange",
      "onInputChangeHandler",
      "handleSmsContentChange",
      "updateGuestCampaignAndGoToFinalStep"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      replyToEmailAdress: "",
      emailSubject: "",
      emailTemplateId: null,
      smsContent: ""
    };
  }

  componentDidMount() {
    const { fetchGuestCampaign, match } = this.props;
    const { event_id, guest_campaign_id } = match.params;
    fetchGuestCampaign(event_id, guest_campaign_id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.guestCampaign !== this.props.guestCampaign) {
      const { reply_to_email, subject, email_template_id, sms_content } = this.props.guestCampaign;
      this.setState({
        replyToEmailAdress: reply_to_email || "",
        emailSubject: subject || "",
        emailTemplateId: email_template_id || "",
        smsContent: sms_content || ""
      });
    }
  }

  queryString() {
    return querystring.parse(this.props.location.search.substring(1)).q;
  }

  urlGuestList() {
    return urlWithQuery(this.queryString(), "guests");
  }

  urlGuestCampaignSummary() {
    return urlWithQuery(this.queryString(), "campaign/:id/summarize");
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onInputChangeHandler(stateKey) {
    return (value) => {
      this.setState({ [stateKey]: value });
    };
  }

  handleSmsContentChange(e) {
    if (e.target.value.length > SMS_MAX_NB_CHAR) {
      return;
    }
    this.setState({ smsContent: e.target.value });
  }

  updateGuestCampaignAndGoToFinalStep() {
    const { match, updateGuestCampaign } = this.props;
    const { event_id, guest_campaign_id } = match.params;
    const { replyToEmailAdress, emailSubject, emailTemplateId, smsContent } = this.state;

    updateGuestCampaign(
      event_id,
      guest_campaign_id,
      {
        reply_to_email: replyToEmailAdress,
        subject: emailSubject,
        email_template_id: emailTemplateId,
        body_url: null,
        liquid_template_id: null,
        sms_content: smsContent
      },
      false,
      this.urlGuestCampaignSummary()
    );
  }

  renderPanelHeading(icon, title) {
    return (
      <div className="card-header text-center">
        <h4 className="card-title">
          <i className={ icon } style={{ marginRight: "5px" }}></i>
          { title }
        </h4>
      </div>
    );
  }

  renderTextArea(name, value, label, method) {
    return (
      <div className="mb-3">
        <label htmlFor={ name } className="form-label">{ label }</label>
        <textarea name={ name} value={ value } onChange={ method } className="form-control" rows="4" style={{ resize: "none" }} />
      </div>
    );
  }

  renderSMSPanel() {
    const { use_sms } = this.props.guestCampaign;
    if (!use_sms) {
      return;
    }
    const { smsContent } = this.state;
    let classes = "float-end";
    classes = smsContent.length < SMS_MAX_NB_CHAR ? classes : `${classes} text-danger`;
    return (
      <div className="card">
        { this.renderPanelHeading("fa-regular fa-comments", I18n.t("react.guest_campaign.guest_campaign_notification.sms_panel.title")) }
        <div className="card-body">
          { this.renderTextArea("smsContent", smsContent, I18n.t("react.guest_campaign.guest_campaign_notification.sms_panel.sms_content"), this.handleSmsContentChange) }
          <p>
            <a target="_blank" href={process.env.APP_DOCUMENTATION_HOST}>{I18n.t("react.guest_campaign.guest_campaign_notification.see_liquid_doc")}</a>
            <strong className={classes}>{ smsContent.length } / { SMS_MAX_NB_CHAR }</strong>
          </p>
        </div>
      </div>
    );
  }

  renderEmailPanel() {
    const { use_email } = this.props.guestCampaign;
    if (!use_email) {
      return;
    }
    const { replyToEmailAdress, emailSubject, emailTemplateId } = this.state;
    const { match } = this.props;
    return (
      <div className="card">
        { this.renderPanelHeading("fa-regular fa-paper-plane", I18n.t("react.guest_campaign.guest_campaign_notification.email_panel.title")) }
        <div className="card-body">
          <EmailConfiguration
            locale={match.params.locale}
            eventId={match.params.event_id}
            replyToEmailAdress={replyToEmailAdress}
            subject={emailSubject}
            templateId={emailTemplateId}
            onTemplateIdChange={this.onInputChangeHandler("emailTemplateId")}
            onSubjectChange={this.onInputChangeHandler("emailSubject")}
            onReplyToEmailAdressChange={this.onInputChangeHandler("replyToEmailAdress")}
          />
        </div>
      </div>
    );
  }

  render() {
    const { guestCampaign, errors, history, match } = this.props;
    const { use_email, use_sms } = this.props.guestCampaign;
    const nextUrl = this.updateGuestCampaignAndGoToFinalStep;
    const nextName = I18n.t("react.guest_campaign.go_to_final_step");
    const errorsParams = (!errors || errors.length == 0 ? {} : errors);
    if (use_email && !use_sms) {
      history.replace(`${urlWithQuery("", `campaign/${match.params.guest_campaign_id}/email/templates`)}`);
    }
    return (
      <div>
        <div className="col-md-8 offset-md-2 mt-30">
          <ErrorMessage errors={errorsParams} model="guest_campaign" />
          { this.renderEmailPanel() }
          { this.renderSMSPanel() }
        </div>
        <Breadcrumb
          steps={campaignStepBreadcrumbLinks(guestCampaign, {
            guestCampaignIsPersisted: true,
            searchQuery: this.queryString()
          })}
          activeStep={CAMPAIGN_STEPS[1]}
          backUrl={this.urlGuestList()}
          nextUrl={nextUrl}
          nextName={nextName}
          backName={I18n.t("react.guest_campaign.people_list")}
          modalIsOpen={false}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    guestCampaign: state.guestCampaign.guestCampaign,
    errors: state.guestCampaign.errors
  };
}

function mapDispatchToProps() {
  return {
    fetchGuestCampaign,
    updateGuestCampaign
  };
}

export default connect(mapStateToProps, mapDispatchToProps())(GuestCampaignNotification);
