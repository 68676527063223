import { ActionTypes, CALL_API } from "../constants/Constants";
import { stringify } from "querystring";

export function fetchCustomEmails(eventId, params = {}) {
  const { FETCH_CUSTOM_EMAILS_REQUEST, FETCH_CUSTOM_EMAILS_SUCCESS, FETCH_CUSTOM_EMAILS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_CUSTOM_EMAILS_REQUEST, FETCH_CUSTOM_EMAILS_SUCCESS, FETCH_CUSTOM_EMAILS_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/custom_emails.json?${stringify(params)}`
    }
  };
}
