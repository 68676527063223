import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { fetchWorkflows } from "../../actions/WorkflowsActionCreators";
import { urlEventId } from "../../utils/pathUtils";
import { multiValueRemoveStyle } from "../../constants/Style";

const customStyles = {
  multiValueRemove: (base: any): any => ({
    ...base,
    ...multiValueRemoveStyle
  })
};

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.workflow_runs.dropdown.${key}`, opts);
}

interface Props {
  selectedValue: string;

  onChange(value: any): void;
}

const WorkflowsDropdown: React.FC<Props> = ({ selectedValue, onChange }) => {
  const workflows = useSelector((state: any) => state.workflows.data);

  const dispatch = useDispatch();

  useEffect(() => {
    if (workflows) return;

    dispatch(fetchWorkflows(urlEventId()));
  }, []);

  const workflowsOptions = (): any[] => {
    if (!workflows) return [];

    return workflows.map((workflow) => {
      return {
        value: workflow._id,
        label: workflow.name
      };
    });
  };

  const options = workflowsOptions();
  const selectedOption = selectedValue ? options.find((option) => option.value === selectedValue) : "";

  const props = {
    className: "react-select",
    classNamePrefix: "react-select",
    closeMenuOnSelect: true,
    hideSelectedOptions: false,
    isClearable: true,
    isMulti: false,
    onChange: (selectedOption): void => onChange(selectedOption),
    options,
    placeholder: i18n("all_workflows"),
    styles: customStyles,
    value: selectedOption
  };

  return <Select {...props} />;
};

export default WorkflowsDropdown;
