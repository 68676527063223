import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AtlasChart from "../../components/AtlasChart.react";
import HeaderStats from "../../components/dashboard/HeaderStats.react";
import DashboardNavTab from "../../components/dashboard/DashboardNavTab.react";
import { isEnabled } from "../../utils/featureSetUtils";
import { ENGAGEMENT_SCORING, THEMATIC_SCORING } from "../../constants/FeaturesSet";
import withDatabaseDefaultRegionRestriction from "../../components/shared/WithDatabaseDefaultRegionRestriction.react";

const SCORING_VISITOR_CHART_ID = "acb3ff2b-75b8-47e4-81a1-07a84b300d5a";
const SCORING_EXHIBITOR_CHART_ID = "63501133-847c-424a-8b3b-75906315326b";
const ENGAGEMENT_CHART_ID = "0112e04a-878b-4132-9f03-7092e31ce089";

const i18n = (key: string, options = {}): string => {
  return I18n.t(`react.dashboard.scoring.${key}`, options);
};

const ScoringDashboard: React.FC = () => {
  const event = useSelector((state: any) => state.event);

  const [topVisitorThematicScore, setTopVisitorThematicScore] = useState(null);
  const onChartDataChangedVisitor = (data: { documents: any[] } | null): void => {
    if (data === null) {
      setTopVisitorThematicScore(null);
      return;
    }
    setTopVisitorThematicScore(data.documents[0]?.x || 0);
  };

  const [topExhibitorThematicScore, setTopExhibitorThematicScore] = useState(null);
  const onChartDataChangedExhibitor = (data: { documents: any[] } | null): void => {
    if (data === null) {
      setTopExhibitorThematicScore(null);
      return;
    }
    setTopExhibitorThematicScore(data.documents[0]?.x || 0);
  };

  const [thematicsCount, setThematicsCount] = useState(null);
  useEffect(() => {
    if (!event._id) return;

    fetch(`/api/v1/events/${event._id}/thematics/count.json`)
      .then(res => res.json())
      .then(result => setThematicsCount(result.total));
  }, [event._id]);

  const renderHeader = (): JSX.Element => {
    const headers = [{ i18nKey: "nb_thematics", value: thematicsCount, nbCols: 4 }];
    if (isEnabled(THEMATIC_SCORING)) {
      headers.push(
        { i18nKey: "top_visitor_thematic_score", value: topVisitorThematicScore, nbCols: 4 },
        { i18nKey: "top_exhibitor_thematic_score", value: topExhibitorThematicScore, nbCols: 4 }
      );
    }

    return <HeaderStats
      dataFetched={thematicsCount !== null}
      headers={headers}
      tabKey="scoring"
    />;
  };

  const renderEngagement = (): JSX.Element => {
    if (!isEnabled(ENGAGEMENT_SCORING)) return null;

    return <div className="card" >
      <AtlasChart
        eventId={event._id}
        title={i18n("engagement_score")}
        chartId={ENGAGEMENT_CHART_ID}
        exportOptions={{
          columnsMapping: { score: { documentKey: "x" }, count: { documentKey: "y", total: true } },
          type: "engagement_score"
        }}
      />
    </div>;
  };

  const renderThematicsGraphs = (): JSX.Element => {
    if (!isEnabled(THEMATIC_SCORING) || thematicsCount === null) return null;

    return <div className="row">
      <div className="col-md-6">
        <div className={`card ${thematicsCount === 0 ? "d-none" : null}`} >
          <AtlasChart
            eventId={event._id}
            title={i18n("total_score_by_thematic")}
            subtitle={i18n("visitor")}
            chartId={SCORING_VISITOR_CHART_ID}
            onChartDataChanged={onChartDataChangedVisitor}
            height="100%"
            chartWrapperHeight="600px"
            exportOptions={{
              columnsMapping: { thematic: { documentKey: "y" }, count: { documentKey: "x", total: true } },
              type: "visitor_total_score_by_thematic"
            }}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className={`card ${thematicsCount === 0 ? "d-none" : null}`} >
          <AtlasChart
            eventId={event._id}
            title={i18n("total_score_by_thematic")}
            subtitle={i18n("exhibitor")}
            chartId={SCORING_EXHIBITOR_CHART_ID}
            onChartDataChanged={onChartDataChangedExhibitor}
            height="100%"
            chartWrapperHeight="600px"
            exportOptions={{
              columnsMapping: { thematic: { documentKey: "y" }, count: { documentKey: "x", total: true } },
              type: "exhibitor_total_score_by_thematic"
            }}
          />
        </div>
      </div>
    </div>;
  };

  if (!event._id) return null;

  return <>
    <DashboardNavTab active="scoring" event_has_exits={event.has_exits} />
    { renderHeader() }
    { renderEngagement() }
    { renderThematicsGraphs() }
  </>;
};

export default withDatabaseDefaultRegionRestriction(ScoringDashboard);
