import { createRef, Component } from "react";
class SearchKeyInput extends Component {
  constructor(props) {
    super(props);
    this._handleChange = this._handleChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);

    this.searchKeyInputRef = createRef();
  }

  _handleChange(e) {
    this.props.onChange(e.target.value);
  }

  componentDidMount() {
    if (this.searchKeyInputRef.current) {
      this.searchKeyInputRef.current.focus();
    }
  }

  _handleSubmit(e) {
    e.preventDefault();
    const { validate } = this.props;
    validate();
  }

  render() {
    const { value, field } = this.props;
    return (
      <form className="mt-10 row" onSubmit={this._handleSubmit} onBlur={this._handleSubmit}>
        <div className="col-md-6">
          <div className="input-group">
            <div className="input-group-text">{field}</div>
            <input ref={this.searchKeyInputRef} className="form-control" onChange={this._handleChange} value={value}></input>
          </div>
        </div>
      </form>
    );
  }
}

export default SearchKeyInput;

