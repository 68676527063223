import { Component } from "react";
import ColorPicker from "./ColorPicker.react";

/*
  This component is meant to bring the react colors picker into an ERB form.
  Usage (in ERB template) within a form tag :

  <% props = { selectedColor: @account.primary_color || '#000000', inputName: 'account[primary_color]' } %>
  <div class="react-anchor" data-react-component="ErbFormColorInput" data-react-component-props="<%= props.to_json %>"></div>
*/

class ErbFormColorInput extends Component {
  constructor(props) {
    super(props);

    const { selectedColor, inputName } = this.props;
    this.state = {
      selectedColor,
      inputName
    };
  }

  render() {
    const { selectedColor, inputName } = this.state;
    return (
      <div>
        <ColorPicker setColor={ (color) => this.setState({ selectedColor: color }) } selectedColor={ selectedColor } placement="right"/>
        <input type="hidden" value={ selectedColor } name={ inputName }/>
      </div>
    );
  }
}

export default ErbFormColorInput;
