import { Component } from "react";
import startsWith from "lodash/startsWith";
import get from "lodash/get";
import { OverlayTrigger } from "react-bootstrap";
import { DragTypes } from "../../constants/Constants";
import { translatedTextFromJson, addTooltip } from "../../utils/templatesBuilderUtils";
import Sortable from "../../components/Sortable.react";

class SectionsList extends Component {
  constructor(props) {
    super(props);
    [
      "showEditSection",
      "handleDrop",
      "duplicatePageSectionHandler"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
  }

  showEditSection(key) {
    return () => {
      this.props.showEditSection(key);
    };
  }

  handleDrop(previousItemId, id, nextItemId, estimatedIndex, itemsByIndex) {
    const { updateSectionsOrder } = this.props;

    const orderedSections = itemsByIndex.map(object => {
      return object.item.sortableItemId;
    });

    updateSectionsOrder(orderedSections);
  }

  duplicatePageSectionHandler(pageSectionKey) {
    return (e) => {
      e.stopPropagation();
      this.props.showPageSectionDuplicationView(pageSectionKey);
    };
  }

  sectionName(sectionKey, settingsKey = null) {
    const { sectionTypes, sectionsConfiguration } = this.props;
    const section = sectionTypes.find(s => s.filename === sectionKey);
    const displayProperty = get(section, ["schema", "display_property"], "title");
    const displayPropertyValue = get(sectionsConfiguration, [settingsKey, "settings", displayProperty], "");
    if (displayPropertyValue !== "") return displayPropertyValue;
    if (!section || !section.schema) return sectionKey;

    return translatedTextFromJson(section.schema, "name");
  }

  sectionIcon(sectionKey) {
    const { sectionTypes } = this.props;
    const section = sectionTypes.find(s => s.filename === sectionKey);
    const displayIcon = get(section, ["schema", "icon"]);

    return (
      <span><i className={`${displayIcon} mr-10`}></i></span>
    );
  }

  duplicationButton(sectionKey) {
    const { builderType } = this.props;
    if (builderType !== "website" || startsWith(sectionKey, "form-builder")) return;

    return (
      <OverlayTrigger placement="left" overlay={addTooltip(I18n.t("react.website.duplicate_section"))}>
        <i className="fa-regular fa-clone" onClick={this.duplicatePageSectionHandler(sectionKey)}></i>
      </OverlayTrigger>
    );
  }

  renderDynamicSections() {
    const { dynamicSectionsNames, sectionsConfiguration, showSectionsList } = this.props;

    const selectedItems = dynamicSectionsNames.map((dynamicSectionName, i) => {
      return { name: sectionsConfiguration[dynamicSectionName]["type"], rank: i, sortableItemId: dynamicSectionName };
    });

    const selectedItemsHtml = selectedItems.map(item => {
      return (
        <li className="list-group-item list-section" key={item["sortableItemId"]} onClick={this.showEditSection(item.sortableItemId)} style={{ paddingRight: "5px" }}>
          {this.sectionIcon(item.name)}
          { " " }
          <span className="title-item mr-10">{this.sectionName(item.name, item.sortableItemId)}</span>
          { " " }
          <span className="float-end">{this.duplicationButton(item.sortableItemId)}</span>
          <span className="float-end"><i className="fa-solid fa-grip-dots-vertical mr-5"></i></span>
        </li>
      );
    });

    return (
      <div className="card" key="dynamic-sections">
        {selectedItems.length > 0 &&
          <div className="list-group">
            <Sortable itemIdKey="sortableItemId"
              itemIndexKey="rank"
              dragType={DragTypes.WEBSITE_SECTIONS}
              items={selectedItems}
              handleDrop={this.handleDrop}
              fullyDraggable={true}>
              {selectedItemsHtml}
            </Sortable>
          </div>
        }
        <div className="card-footer">
          <a onClick={ showSectionsList }>
            <i className="fa-regular fa-plus-circle"></i> { I18n.t("react.website.new_section") }
          </a>
        </div>
      </div>
    );
  }

  renderStaticSection(key) {
    return (
      <div className="card" key={`section-${key}`}>
        <ul className="list-group">
          <li className="list-group-item" onClick={this.showEditSection(key)}>{this.sectionName(key)}</li>
        </ul>
      </div>
    );
  }

  renderSections() {
    const { layoutStaticSections, templateStaticSections } = this.props;

    return layoutStaticSections.map(sectionKey => {
      if (sectionKey == "content") {
        if (templateStaticSections.length > 0) {
          return templateStaticSections.map(pageSectionKey => {
            if (pageSectionKey == "dynamic_content_for_page") {
              return this.renderDynamicSections();
            } else {
              return this.renderStaticSection(pageSectionKey);
            }
          });
        } else {
          return this.renderDynamicSections();
        }
      } else {
        return this.renderStaticSection(sectionKey);
      }
    });
  }

  render() {
    return (
      <div>
        { this.renderSections() }
      </div>
    );
  }
}

export default SectionsList;
