import PropTypes from "prop-types";
import { Provider } from "react-redux";
import Routes from "../routes";
import { Router } from "react-router";
import history from "../utils/getHistory";

const Root = ({ store, component, defaultHtmlContent, componentProps }) => (
  <Provider store={store}>
    <Router history={history}>
      <Routes component={component} defaultHtmlContent={defaultHtmlContent} componentProps={componentProps} />
    </Router>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired
};

export default Root;
