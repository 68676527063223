import { Component, MouseEvent } from "react";
import conditionalDisplay from "../../utils/conditionalDisplayUtils.js";
import { FormItem } from "../../types/FormItem";
import { FormItemOption } from "../../types/FormItemOption";
import { Accesspoint } from "../../types/Accesspoint";
import { DragTypes } from "../../constants/Constants";
import Sortable from "../Sortable.react";
import ShowAllLink from "./ShowAllLink.react";
import ProductOption from "./ProductOption.react";
import ProductAdvancedOption from "./ProductAdvancedOption.react";
import { shouldBeDisplayed } from "../../utils/CheckinpointUtils.js";
import CreateAccesspointModal from "../mapping_step/CreateAccesspointModal.react";

const TRUNCATE_LIMIT = 5;

interface Props {
  updateHandler(item: FormItem, sendFile?: boolean, optimistic?: boolean): void;
  accesspoints: Accesspoint[];
  accesspointList(): Accesspoint[];
  formItem: FormItem;
  mode: string;
  showingAll: boolean;
  updateOption(params: any): void;
  destroyOption(id: string): void;
  injectCreateAccesspointKey(accesspoints: Accesspoint[]): Accesspoint[];
  handleDrop(): void;
  triggerShowAll(e: MouseEvent<HTMLAnchorElement>): void;
  availableAccesspoints(optionItem?: FormItemOption): Accesspoint[];
  renderAddAccesspointOptions(): JSX.Element;
  showCreateAccesspointModal: boolean;
  eventId: string;
  locale: string;
  closeCreateAccesspointModal(): void;
  getNewAccesspoint(): Accesspoint;
  showErrorAccesspoint(): JSX.Element;
  renderOptionsFilter(advancedMode: boolean): JSX.Element;
}

class ProductGroupType extends Component<Props> {

  i18n(key: string, options: any = {}): string {
    return I18n.t(`react.form_items.product.${key}`, options);
  }

  renderProductsTableRead(): JSX.Element {
    const { accesspointList, formItem } = this.props;

    const list = formItem.options.advanced_mode ? accesspointList() : formItem.form_item_options.map(itemOption => accesspointList().find(accesspoint => accesspoint._id == itemOption.key));

    return <div className="table-container">
      <table className="table table-light">
        <thead>
          <tr>
            <th></th>
            <th>{ this.i18n("price") }</th>
            <th>{ this.i18n("quantity") }</th>
          </tr>
        </thead>
        <tbody>
          { list.map(product => {
            return this.renderProductLineRead(product);
          }) }
        </tbody>
      </table>
    </div>;
  }

  renderProductLineRead(product: Accesspoint): JSX.Element {
    if (!product) return;
    return <tr key={product._id}>
      <td>{ product.display_name }</td>
      <td>{ product.price }</td>
      <td>
        <select className="form-select form-select-sm" disabled>
          <option value="0">0</option>
        </select>
      </td>
    </tr>;
  }

  renderAdvancedEditModeOptions(): JSX.Element {
    const { accesspoints, formItem, mode, accesspointList, showingAll, triggerShowAll } = this.props;
    const overrideLabel = formItem.options.override_label || [];
    const productList = accesspointList().map(accesspoint => {
      return (
        <ProductAdvancedOption
          key={accesspoint.id}
          formItem={formItem}
          mode={mode}
          accesspoint={accesspoint}
          overrideLabel={overrideLabel}
          shouldBeDisplayed={shouldBeDisplayed(accesspoints, formItem, accesspoint.id)}
        />
      );
    });
    const truncate = showingAll ? productList.length : TRUNCATE_LIMIT;
    return (
      <div>
        {productList.slice(0, truncate)}
        <ShowAllLink
          nbItems={productList.length}
          onClickHandler={triggerShowAll}
          limit={TRUNCATE_LIMIT}
        />
      </div>
    );
  }

  renderEditModeOptions(): JSX.Element {
    const {
      accesspoints, formItem, mode, showingAll, updateOption, destroyOption,
      injectCreateAccesspointKey, availableAccesspoints, handleDrop, triggerShowAll
    } = this.props;

    const formItemOptions = formItem.form_item_options || [];

    const productOptions = formItemOptions.map((option) => {
      return <ProductOption
        mode={mode}
        shouldBeDisplayed={shouldBeDisplayed(accesspoints, formItem, option.key) as boolean}
        itemOption={option}
        updateHandler={updateOption}
        destroyHandler={destroyOption}
        allowDestroy={formItem.form_item_options.length > 1}
        accesspoints={injectCreateAccesspointKey(availableAccesspoints(option))}
        radioStyle={formItem.options.uniq_option}
        key={option.key}
      />;
    });

    const truncate = showingAll ? formItemOptions.length : TRUNCATE_LIMIT;
    return (
      <div>
        <div style={{ minWidth: "3%" }} className="float-start"> &nbsp; </div>
        <div style={{ width: "97%" }} className="float-start">
          <div className="row">
            <label className="col-4 form-label">{ this.i18n("label") }</label>
            <label className="col-2 form-label">{ this.i18n("min_quantity") }</label>
            <label className="col-2 form-label">{ this.i18n("max_quantity") }</label>
            <label className="col-3 form-label">{ this.i18n("product") }</label>
          </div>
        </div>
        <Sortable
          itemIdKey="_id"
          itemIndexKey="rank"
          dragType={DragTypes.FORM_ITEM_OPTION}
          items={formItemOptions.slice(0, truncate)}
          handleDrop={handleDrop}
          handlePosition="left"
        >
          { productOptions.slice(0, truncate) }
        </Sortable>
        <ShowAllLink
          nbItems={formItemOptions.length}
          onClickHandler={triggerShowAll}
          limit={TRUNCATE_LIMIT}
        />
      </div>
    );
  }

  renderProductsEditMode(): JSX.Element {
    const { formItem, eventId, locale, showCreateAccesspointModal, showErrorAccesspoint, renderOptionsFilter, renderAddAccesspointOptions, closeCreateAccesspointModal, getNewAccesspoint } = this.props;
    const items = formItem.options.advanced_mode ? this.renderAdvancedEditModeOptions() : this.renderEditModeOptions();

    return <div>
      <CreateAccesspointModal isVisible={showCreateAccesspointModal} eventId={eventId} locale={locale} onCloseFunction={closeCreateAccesspointModal} updateParent={getNewAccesspoint} defaultType="product"/>
      <div className="row">
        <div className="col-sm-12">
          <div className="mt-10">
            {showErrorAccesspoint()}
            {items}
            {renderAddAccesspointOptions()}
          </div>
          { renderOptionsFilter(formItem.options.advanced_mode) }
        </div>
      </div>
    </div>;
  }

  render(): JSX.Element {
    const { mode } = this.props;

    if (mode == "edit")
      return this.renderProductsEditMode();

    return this.renderProductsTableRead();
  }
}

export default conditionalDisplay(ProductGroupType);
