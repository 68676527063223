import { Component } from "react";
import { Link } from "react-router-dom";
import FilterDropdown from "./FilterDropdown.react";

import { objectsFromIds } from "../utils/identifierUtils";
import { GuestSortOptions, ONLINE_COLOR } from "../constants/Constants";

import { SegmentPicker } from "../containers/shared/SegmentPicker.react";

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import { urlWithQuery } from "../utils/pathUtils";
import GuestCounter from "../containers/shared/GuestCounter.react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { allEnabled } from "../utils/featureSetUtils";

class GuestsIndexControlBar extends Component {
  constructor(props) {
    super(props);
    this.renderButtonsOnSelectedGuestIds = this.renderButtonsOnSelectedGuestIds.bind(this);
  }

  renderButtonsOnSelectedGuestIds() {
    const { searchQuery, launchBulkAction, selectedGuests } = this.props;
    const pendingGuestsChecked = find(selectedGuests, guest => {
      return guest.status == "pending_registration";
    });
    const registeredGuestsChecked = find(selectedGuests, guest => {
      return guest.status == "registered";
    });
    const moderation = pendingGuestsChecked && !registeredGuestsChecked;
    return (
      <div>
        <span style={{ marginRight: "15px" }}><strong> {I18n.t("guests.index.selected_guests", { count: selectedGuests.length })}</strong></span>
        <div className="btn-group" role="group">
          { moderation ? (
            [
              <button onClick={launchBulkAction} key={2} type="button" className="btn btn-sm btn-secondary" value='validate'>
                <i className="fa-regular fa-check" aria-hidden="true"></i> {I18n.t("guests.table_guest.validate")}
              </button>,
              <button onClick={launchBulkAction} key={3} type="button" className="btn btn-sm btn-secondary" value='reject'>
                <i className="fa-regular fa-ban" aria-hidden="true"></i> {I18n.t("guests.table_guest.reject")}
              </button>
            ]) : null
          }

          <button className="btn btn-danger btn-sm" onClick={launchBulkAction} value="delete">
            <i className="fa-regular fa-trash-can" aria-hidden="true"></i> {I18n.t("guests.table_guest.delete")}
          </button>
          <Link to={urlWithQuery(searchQuery, "guests/modal/trigger_actions")} className="btn btn-secondary btn-sm" style={{ color: "#333333", textDecoration: "none" }} >
            {I18n.t("guests.action_dropdown.more")} <i className="fa-solid fa-caret-down" aria-hidden="true"></i>
          </Link>
        </div>
      </div>
    );
  }

  render() {
    const { guestCategories, selectedGuestCategoryIds, setSelectedGuestCategoryIds, selectedSavedSearchId, openColumnsPickerModal, event } = this.props;
    const { selectedGuestSortOptions, setSelectedGuestSortOptions } = this.props;
    const { setSelectedSaveSearch, savedSearches, selectedGuests } = this.props;
    const enabledGuestSortOptions = Object.keys(GuestSortOptions).filter(key => {
      return GuestSortOptions[key] ? allEnabled(GuestSortOptions[key].requiredFeatures) : true;
    });

    if (!isEmpty(selectedGuests)) {
      return this.renderButtonsOnSelectedGuestIds();
    }

    return (
      <div className="row g-2 align-items-center">
        <div className="col-auto">
          <SegmentPicker onSelectSegment={setSelectedSaveSearch} segments={savedSearches} defaultSegmentId={selectedSavedSearchId} />
        </div>

        <div className="col-auto">
          <FilterDropdown
            id="guest_category"
            items={guestCategories}
            selectedItemIds={selectedGuestCategoryIds}
            translationKey="guest_category"
            onChange={setSelectedGuestCategoryIds}
            showCells={false}
            itemColorKey="label_color"
          />
        </div>

        <div className="col-auto">
          <FilterDropdown
            id="sort"
            items={objectsFromIds(enabledGuestSortOptions)}
            selectedItemIds={selectedGuestSortOptions}
            translationKey="sort"
            onChange={setSelectedGuestSortOptions}
            showCells={false}
            hasSearch={false}
            hasSelectAll={false}
            multipleSelect={false}
            sortItems={false}
            selectedFirst={false}
          />
        </div>

        <div className="col-auto">
          <a onClick={openColumnsPickerModal} className="btn btn-secondary">
            <i className="fa-regular fa-table" aria-hidden="true"></i> { I18n.t("react.reports.display") }
          </a>
        </div>
        {event._id &&
          <GuestCounter eventId={event._id} queryString="is_online:true" keyStore="online" render={(count) => {
            if (count === 0) {
              return null;
            }
            const tooltip = <Tooltip id="online-guest-help">{I18n.t("guests.table_guest.guests_online_help")}</Tooltip>;
            const help = (
              <OverlayTrigger overlay={tooltip}>
                <i className="fa-regular fa-question-circle"></i>
              </OverlayTrigger>
            );
            return (
              <div key={`${count}-online-guests`} className="col-auto">
                <i className="fa-solid fa-circle" style={{ color: ONLINE_COLOR }}></i> {I18n.t("guests.table_guest.guests_online", { count })} {help}
              </div>
            );
          }} />
        }
      </div>
    );
  }
}

export default GuestsIndexControlBar;
