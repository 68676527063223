"use strict";
import { Component } from "react";
import { connect } from "react-redux";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";

import ImportWizard from "../components/mapping_step/ImportWizard.react";
import Preview from "../components/mapping_step/Preview.react";
import GuestCategoryModal from "../components/mapping_step/GuestCategoryModal.react";
import ValueListMappingModal from "../components/mapping_step/ValueListMappingModal.react";
import MapGuestCategoryModal from "../components/mapping_step/MapGuestCategoryModal.react";
import AccesspointsModal from "../components/mapping_step/AccesspointsModal.react";
import GuestStatusModal from "../components/mapping_step/GuestStatusModal.react";
import GuestFieldsModal from "../components/guest_field/GuestFieldsModal.react";

import { fetchEventGuestFields } from "../actions/GuestFieldsActionCreators";
import { fetchGuestImportOperations, updateGuestImportOperations, fetchDistinctValuesAtColumn } from "../actions/GuestImportOperationsActionCreators";
import { fetchAccesspoints } from "../actions/AccesspointActionCreators";
import { fetchGuestProductCollections } from "../actions/GuestProductCollectionsActionCreators";
import { importGuestCategories } from "../actions/ImportGuestCategoryActionCreators";

import { pathToNewGuestImportOperation, pathMappingStepToWaitingForVerificationStep } from "../utils/pathUtils.js";

import { EtCaetera, GuestStatusImported, StaticGuestFieldsImport, CalculatedType, LazyCalculatedType } from "../constants/Constants.js";
import Loader from "../components/shared/Loader.react";

const mapCategoryKey = "guest_category";
const doNotImportKey = "do_not_import";

class MappingStep extends Component {

  constructor(props) {
    super(props);
    [
      "submit",
      "sendData",
      "mappingGuestCategory",
      "mappingCheckinPoint",
      "createGuestField",
      "fillCurrentMapping",
      "resetMapping",
      "autoMapping",
      "mappingChanged",
      "renderAccesspointModal",
      "renderGuestCategoryModal",
      "renderMapGuestCategoryModal",
      "renderGuestStatusModal",
      "renderCreateGuestField",
      "renderNbColumnMapped",
      "onCloseMapGuestCategoryMappingModal",
      "onCloseGuestCategoryMappingModal",
      "onCloseAccesspointsModal",
      "onCloseStatusModal",
      "onCloseCreateGuestField",
      "onWheel",
      "submitMapGuestCategoryModal",
      "submitGuestCategoryModal",
      "submitAccesspointsModal",
      "submitGuestStatusModal",
      "submitCreateGuestField",
      "renderPreviews",
      "getDataFromRow",
      "valueListMapping",
      "onSubmitValueListMappingModal",
      "onCloseValueListMappingModal"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = {
      currentMapping: {},
      mappingFirstLine: 1,
      overrideGuestCategoryOfExistingGuests: false,
      overrideGuestStatus: GuestStatusImported,
      resetMappingAccesspoint: true,
      mappingGuestCategory: {},
      mappingAccesspoints: {},
      valueListMapping: {},
      showMapGuestCategoryModal: false,
      showGuestCategoryModal: false,
      showAccesspointModal: false,
      showOverrideGuestStatus: false,
      showCreateGuestFieldModal: false,
      currentColumnProcessing: -1,
      uniqueGuestCategory: null,
      showValueListMappingModal: false
    };
  }

  componentDidMount() {
    const { fetchGuestImportOperations, fetchEventGuestFields, importGuestCategories, fetchAccesspoints, fetchGuestProductCollections, match } = this.props;
    fetchGuestImportOperations(match.params.event_id, match.params.guest_import_operation, { with_automapping_data: true });
    fetchEventGuestFields(match.params.event_id, { import: true });
    fetchGuestProductCollections(match.params.event_id);
    importGuestCategories();
    fetchAccesspoints(match.params.event_id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { guestImportOperation, guestFields, accesspoints } = this.props;
    const { currentMapping, mappingFirstLine } = this.state;
    const okGuestImportOperation = !isEmpty(guestImportOperation) || !isEmpty(nextProps.guestImportOperation);
    const okGuestFields = !isEmpty(guestFields) || !isEmpty(nextProps.guestFields);
    const okAccesspoints = !isEmpty(accesspoints) || !isEmpty(nextProps.accesspoints);
    if (okGuestFields && okGuestImportOperation && okAccesspoints && isEmpty(currentMapping)) {
      this.fillCurrentMapping(nextProps);
    }
    if (isEmpty(guestImportOperation) && !isEmpty(nextProps.guestImportOperation)) {
      const mappingAccesspoints = this.getMappingAccesspoint(nextProps.guestImportOperation);
      const mappingGuestCategory = nextProps.guestImportOperation.mapping_guest_category_field || {};
      let mappingState = { mappingGuestCategory, mappingAccesspoints };
      if (mappingFirstLine == 1) {
        mappingState["mappingFirstLine"] = nextProps.guestImportOperation.mapping_first_line - 1;
      }
      this.setState(mappingState);
    }
  }

  getMappingAccesspoint(guestImportOperation, accesspointsMappingKey = "mapping_accesspoints") {
    const accesspointsMapping = guestImportOperation[accesspointsMappingKey];
    if (isEmpty(accesspointsMapping)) {
      return {};
    }
    let accesspointMappingResult = {};
    Object.keys(accesspointsMapping).forEach(accesspointId => {
      accesspointMappingResult[accesspointId] = parseInt(accesspointsMapping[accesspointId]) - 1;
    });
    return accesspointMappingResult;
  }

  resetMapping(e) {
    e.preventDefault();
    this.setState({ currentMapping: {}, mappingGuestCategory: {}, mappingAccesspoints: {} });
  }

  buildCurrentMappingFrom(props, autoMapping = false) {
    const { guestFields, guestImportOperation, accesspoints } = props;
    const mappingKeyPrefix = autoMapping ? "auto_" : "";
    const mapping = guestImportOperation[`${mappingKeyPrefix}mapping_fields`];
    let currentMapping = {};
    Object.keys(mapping || {}).forEach(gf => {
      //make sure column is not negative, if so data provided from the api is invalid
      const column = mapping[gf] - 1;
      const guestField = guestFields.find(g => { return gf == g.key; });
      if (guestField && (!autoMapping || guestField.type !== "value_list")) {
        currentMapping[column] = { key: guestField.key, value: guestField.label };
      }
    });

    //add accesspoints in mapping even if it will be blacklisted on submit
    const mappingAccesspoints = this.getMappingAccesspoint(guestImportOperation, `${mappingKeyPrefix}mapping_accesspoints`);
    Object.keys(mappingAccesspoints).forEach(accesspointId => {
      const column = mappingAccesspoints[accesspointId];
      const accesspoint = accesspoints.find(acc => { return acc._id == accesspointId; });
      if (accesspoint) {
        currentMapping[column] = { key: accesspoint._id, value: accesspoint.name };
      }
    });

    const valueListMapping = guestImportOperation[`${mappingKeyPrefix}value_list_mapping`];

    return { currentMapping, mappingAccesspoints, valueListMapping };
  }

  autoMapping(e) {
    e.preventDefault();
    if (!confirm(I18n.t("guest_import_operations.mapping_step.auto_mapping_confirm_message"))) return;
    this.setState(this.buildCurrentMappingFrom(this.props, true));
  }

  fillCurrentMapping(nextProps) {
    this.setState(this.buildCurrentMappingFrom(nextProps));
  }

  sortedArrayAlphabetically(array) {
    let newArray = array.slice();
    newArray.sort(function(a, b) {
      if (a.label > b.label) {
        return 1;
      }
      if (b.label > a.label) {
        return -1;
      }
      return 0;
    });
    return newArray;
  }

  valueListMapping(guestField) {
    return (mappingColumn) => {
      this.setState({ showValueListMappingModal: true, currentColumnProcessing: mappingColumn, valueListMappingModalGuestField: guestField });
    };
  }

  mappingPrototype() {
    const { guestFields, accesspoints, guestCategories, guestProductCollections } = this.props;
    const { mappingAccesspoints } = this.state;
    const fillGuestFields = () => {
      let staticGuestFields = [];
      let customGuestFields = [];
      let valuesListGuestFields = [];
      guestFields.forEach(gf => {
        let field = { key: gf.key, value: `${gf.label} (${gf.key})` };
        if (StaticGuestFieldsImport.includes(gf.key))
          staticGuestFields.push(field);
        else if (gf.type == "value_list") {
          field.onClick = this.valueListMapping(gf);
          valuesListGuestFields.push(field);
        } else customGuestFields.push(field);
      });
      let invitationCategories = [];
      invitationCategories = guestCategories.filter(gc => gc.invitations_enabled).map(gcInvitationEnabled => {
        return { key: `invitations_quota_mapping_${gcInvitationEnabled._id}`, value: `${gcInvitationEnabled.name}` };
      });
      let collectionsQuota = (guestProductCollections || []).map(collection => {
        return { key: `collections_quota_mapping_${collection._id}`, value: `${collection.name}` };
      });
      staticGuestFields = this.sortedArrayAlphabetically(staticGuestFields);
      customGuestFields = this.sortedArrayAlphabetically(customGuestFields);
      valuesListGuestFields = this.sortedArrayAlphabetically(valuesListGuestFields);
      invitationCategories = this.sortedArrayAlphabetically(invitationCategories);
      collectionsQuota = this.sortedArrayAlphabetically(collectionsQuota);

      let mappingSections = [{
        title: I18n.t("guest_import_operations.mapping_step.static_guest_field"),
        data: staticGuestFields
      }];

      if (customGuestFields && customGuestFields.length) {
        mappingSections.push({
          title: I18n.t("guest_import_operations.mapping_step.custom_guest_field"),
          data: customGuestFields
        });
      }

      if (valuesListGuestFields && valuesListGuestFields.length) {
        mappingSections.push({
          title: I18n.t("guest_import_operations.mapping_step.value_list_guest_field"),
          data: valuesListGuestFields
        });
      }

      if (invitationCategories && invitationCategories.length) {
        mappingSections.push({
          title: I18n.t("guest_import_operations.mapping_step.invitations_quota_mapping_field"),
          data: invitationCategories
        });
      }

      if (collectionsQuota && collectionsQuota.length) {
        mappingSections.push({
          title: I18n.t("guest_import_operations.mapping_step.collections_quota"),
          data: collectionsQuota
        });
      }

      return mappingSections;
    };
    const fillAccesspoints = () => {
      const checkinpointArray = [];
      Object.keys(mappingAccesspoints).forEach(accesspointId => {
        const accesspoint = accesspoints.find(acc => { return acc._id == accesspointId; });
        if (accesspoint) {
          checkinpointArray.push({ key: accesspoint._id, value: accesspoint.name });
        }
      });
      if (checkinpointArray.length > 0) {
        return {
          title: I18n.t("guest_import_operations.mapping_step.accesspoint_used"),
          data: checkinpointArray
        };
      }
    };

    let mapping = [
      {
        title: "Actions",
        data: [
          {
            key: doNotImportKey,
            value: I18n.t("guest_import_operations.mapping_step.do_not_import"),
            onClick: null
          }, {
            key: mapCategoryKey,
            value: I18n.t("mongoid.models.guest_category"),
            onClick: this.mappingGuestCategory
          }, {
            key: "pick_an_accesspoint",
            value: I18n.t("guest_import_operations.mapping_step.pick_an_accesspoint"),
            allowMultiple: true,
            onClick: this.mappingCheckinPoint
          }, {
            key: "create_guest_field",
            value: I18n.t("guest_import_operations.mapping_step.create_guest_field"),
            allowMultiple: true,
            onClick: this.createGuestField
          }
        ]
      }
    ];
    mapping.push(...fillGuestFields());
    const accesspointsPrototype = fillAccesspoints();
    if (accesspointsPrototype) {
      mapping.push(accesspointsPrototype);
    }
    return mapping;
  }

  mappingGuestCategory(column) {
    const { currentMapping } = this.state;
    const { fetchDistinctValuesAtColumn, match } = this.props;

    fetchDistinctValuesAtColumn(match.params.event_id, match.params.guest_import_operation, column + 1);
    let newCurrentMapping = Object.assign({}, currentMapping);
    newCurrentMapping[column] = { key: mapCategoryKey, value: I18n.t("mongoid.models.guest_category") };
    this.setState({ showGuestCategoryModal: true, currentMapping: newCurrentMapping });
  }

  mappingCheckinPoint(column) {
    this.setState({ showAccesspointModal: true, currentColumnProcessing: column });
  }

  mappingChanged(key, column, mappingFirstLine = null) {
    const { currentMapping, mappingAccesspoints } = this.state;
    if (mappingFirstLine) {
      this.setState({ mappingFirstLine });
      return;
    }

    const { guestFields, guestCategories, guestProductCollections } = this.props;
    let newCurrentMapping = Object.assign({}, currentMapping);
    const guestField = guestFields.find(gf => { return gf.key == key; });
    const isInvitationsQuotaMapping = key.includes("invitations_quota_mapping_");
    const guestCategory = isInvitationsQuotaMapping && guestCategories.find(gc => gc._id === key.split("_").pop());
    const isCollectionQuotaMapping = key.includes("collections_quota_mapping_");
    const collection = isCollectionQuotaMapping && guestProductCollections.find(collection => collection._id === key.split("_").pop());
    if (guestField) {
      newCurrentMapping[column] = { key: guestField.key, value: guestField.label };
    } else if (guestCategory) {
      newCurrentMapping[column] = { key: key, value: guestCategory.name };
    } else if (collection) {
      newCurrentMapping[column] = { key: key, value: collection.name };
    } else {
      delete newCurrentMapping[column];
    }

    //clear the column mapped as guest category if it becomes not de import
    if (currentMapping[column] && currentMapping[column].key === mapCategoryKey && key === doNotImportKey) {
      delete newCurrentMapping[column];
      this.setState({ mappingGuestCategory: {} });
    }

    //check if the column modified was an accesspoint
    const accesspointId = Object.keys(mappingAccesspoints).find(accesspointId => {
      return mappingAccesspoints[accesspointId] === column;
    });
    //clear the accesspoint previously set
    if (key && key === doNotImportKey) {
      let newMappingAccesspoint = Object.assign({}, mappingAccesspoints);
      delete newMappingAccesspoint[accesspointId];
      this.setState({ mappingAccesspoints: newMappingAccesspoint });
    }

    this.setState({ currentMapping: newCurrentMapping });
  }

  createGuestField(column) {
    this.setState({ showCreateGuestFieldModal: true, currentColumnProcessing: column });
  }

  getCurrentMappingKeys() {
    const { currentMapping } = this.state;
    return Object.values(currentMapping).map(data => data.key);
  }

  submit(e) {
    e.preventDefault();
    const { mappingGuestCategory } = this.state;
    if (isEmpty(mappingGuestCategory) || !this.getCurrentMappingKeys().includes("guest_category")) {
      this.setState({ showMapGuestCategoryModal: true });
      return;
    } else {
      this.setState({ showOverrideGuestStatus: true });
    }
  }

  sendData() {
    const { currentMapping, mappingFirstLine, overrideGuestCategoryOfExistingGuests, resetMappingAccesspoint, mappingGuestCategory, mappingAccesspoints, uniqueGuestCategory, overrideGuestStatus, valueListMapping } = this.state;
    const { updateGuestImportOperations, match } = this.props;
    let mappingFields = {};
    //increment column and blacklist data from mappingAccesspoints
    Object.keys(currentMapping).forEach(column => {
      const key = currentMapping[column].key;
      if (mappingAccesspoints[key] === undefined) {
        mappingFields[key] = (parseInt(column) + 1);
      }
    });
    //increment column to
    let mapping_accesspoints = {};
    Object.keys(mappingAccesspoints).forEach(accesspointId => {
      mapping_accesspoints[accesspointId] = (parseInt(mappingAccesspoints[accesspointId]) + 1);
    });
    const paramsGuestImportOperation = {
      mapping_fields: mappingFields,
      mapping_first_line: mappingFirstLine + 1,
      override_guest_category_of_existing_guests: overrideGuestCategoryOfExistingGuests,
      reset_mapping_accesspoints: resetMappingAccesspoint,
      mapping_guest_category_field: mappingGuestCategory,
      mapping_accesspoints,
      unique_guest_category: uniqueGuestCategory,
      guests_status: overrideGuestStatus,
      value_list_mapping: valueListMapping
    };
    updateGuestImportOperations(
      match.params.event_id,
      match.params.guest_import_operation,
      paramsGuestImportOperation,
      pathMappingStepToWaitingForVerificationStep(match.params.guest_import_operation)
    );
  }

  submitGuestCategoryModal(overrideGuestCategoryOfExistingGuests, mappingGuestCategory) {

    this.setState({ overrideGuestCategoryOfExistingGuests, mappingGuestCategory, showGuestCategoryModal: false });
  }

  submitMapGuestCategoryModal(overrideGuestCategoryOfExistingGuests, guestCategoryId) {
    this.setState({ overrideGuestCategoryOfExistingGuests, uniqueGuestCategory: guestCategoryId, showMapGuestCategoryModal: false, showOverrideGuestStatus: true });
  }

  submitGuestStatusModal(overrideGuestStatus) {
    this.setState({ overrideGuestStatus, showOverrideGuestStatus: false }, () => { this.sendData(); });
  }

  submitCreateGuestField(guestField) {
    const { currentMapping, currentColumnProcessing } = this.state;
    let newCurrentMapping = Object.assign({}, currentMapping);
    newCurrentMapping[currentColumnProcessing] = { key: guestField.key, value: guestField.label };

    this.setState({ showCreateGuestFieldModal: false });
    if (guestField.type === "value_list") {
      this.valueListMapping(guestField)(currentColumnProcessing);
    } else {
      this.setState({ currentMapping: newCurrentMapping, currentColumnProcessing: -1 });
    }
  }

  submitAccesspointsModal(accesspointId) {
    const { accesspoints } = this.props;
    const { currentMapping, mappingAccesspoints, currentColumnProcessing } = this.state;
    let newMappingAccesspoint = Object.assign({}, mappingAccesspoints);
    Object.keys(newMappingAccesspoint).forEach(index => {
      if (newMappingAccesspoint[index] == currentColumnProcessing) {
        delete newMappingAccesspoint[index];
      }
    });
    newMappingAccesspoint[accesspointId] = currentColumnProcessing;

    let newCurrentMapping = Object.assign({}, currentMapping);
    const accesspoint = accesspoints.find(accesspoint => { return accesspoint._id == accesspointId; });
    newCurrentMapping[currentColumnProcessing] = { key: accesspoint._id, value: accesspoint.name };

    this.setState({ mappingAccesspoints: newMappingAccesspoint, currentMapping: newCurrentMapping, currentColumnProcessing: -1 });
    this.onCloseAccesspointsModal();
  }

  renderGuestCategoryModal() {
    const { guestCategories, guestImportOperation, event } = this.props;
    const { showGuestCategoryModal } = this.state;
    return (<GuestCategoryModal event={event} guestCategories={guestCategories} isVisible={showGuestCategoryModal} onCloseFunction={this.onCloseGuestCategoryMappingModal} distinctValues={guestImportOperation.distinctValues} updateParent={this.submitGuestCategoryModal}/>);
  }

  onSubmitValueListMappingModal(guestFieldMapping) {
    const { currentMapping, valueListMappingModalGuestField, valueListMapping, currentColumnProcessing } = this.state;
    let newCurrentMapping = Object.assign({}, currentMapping);
    let newValueListMapping = Object.assign({}, valueListMapping);
    newCurrentMapping[currentColumnProcessing] = { key: valueListMappingModalGuestField.key, value: valueListMappingModalGuestField.label };
    newValueListMapping[valueListMappingModalGuestField.key] = guestFieldMapping;
    this.setState({ showValueListMappingModal: false, currentColumnProcessing: -1, valueListMappingModalGuestField: null, currentMapping: newCurrentMapping, valueListMapping: newValueListMapping });
  }

  onCloseValueListMappingModal(e) {
    e.preventDefault();
    this.setState({ showValueListMappingModal: false, currentColumnProcessing: -1, valueListMappingModalGuestField: null });
  }

  renderValueListMappingModal() {
    const { showValueListMappingModal, currentColumnProcessing, valueListMappingModalGuestField } = this.state;
    const { fetchDistinctValuesAtColumn, match, guestImportOperation } = this.props;
    const { event_id, guest_import_operation } = match.params;

    return <ValueListMappingModal
      isOpen={showValueListMappingModal}
      fetchDistinctValuesAtColumn={fetchDistinctValuesAtColumn}
      onSubmit={this.onSubmitValueListMappingModal}
      eventId={event_id}
      guestField={valueListMappingModalGuestField}
      mappingColumn={currentColumnProcessing}
      guestImportOperationId={guest_import_operation}
      distinctValues={guestImportOperation.distinctValues}
      distinctValuesPendingRequest={guestImportOperation.distinctValuesPendingRequest}
      onClose={ this.onCloseValueListMappingModal }
    />;

  }

  renderMapGuestCategoryModal() {
    const { guestCategories } = this.props;
    const { showMapGuestCategoryModal } = this.state;
    return (<MapGuestCategoryModal guestCategories={guestCategories} isVisible={showMapGuestCategoryModal} onCloseFunction={this.onCloseMapGuestCategoryMappingModal} updateParent={this.submitMapGuestCategoryModal}/>);
  }

  renderAccesspointModal() {
    const { showAccesspointModal, mappingAccesspoints } = this.state;
    const { accesspoints, match } = this.props;
    return (<AccesspointsModal eventId={match.params.event_id} locale={match.params.locale} isVisible={showAccesspointModal} onCloseFunction={this.onCloseAccesspointsModal} accesspoints={accesspoints} updateParent={this.submitAccesspointsModal} mappingAccesspoints={mappingAccesspoints}/>);
  }

  renderGuestStatusModal() {
    const { showOverrideGuestStatus } = this.state;
    return (<GuestStatusModal isVisible={showOverrideGuestStatus} onCloseFunction={this.onCloseStatusModal} updateParent={this.submitGuestStatusModal}/>);
  }

  renderCreateGuestField() {
    const { showCreateGuestFieldModal } = this.state;
    const { event } = this.props;
    return (<GuestFieldsModal event={event} isVisible={showCreateGuestFieldModal} onCloseFunction={this.onCloseCreateGuestField} updateParent={this.submitCreateGuestField}/>);
  }

  onCloseGuestCategoryMappingModal() {
    const { currentMapping } = this.state;
    //remove guest category key set in  mappingChanged function
    let newCurrentMapping = Object.assign({}, currentMapping);
    Object.keys(currentMapping).some(column => {
      if (currentMapping[column].key == mapCategoryKey) {
        delete newCurrentMapping[column];
        return true;
      }
    });
    this.setState({ showGuestCategoryModal: false, currentMapping: newCurrentMapping });
  }

  onCloseMapGuestCategoryMappingModal() {
    const { currentMapping } = this.state;
    //remove guest category key set in  mappingChanged function
    let newCurrentMapping = Object.assign({}, currentMapping);
    Object.keys(currentMapping).some(column => {
      if (currentMapping[column].key == mapCategoryKey) {
        delete newCurrentMapping[column];
        return true;
      }
    });
    this.setState({ showMapGuestCategoryModal: false, currentMapping: newCurrentMapping });
  }

  onCloseAccesspointsModal() {
    this.setState({ showAccesspointModal: false, currentColumnProcessing: -1 });
  }

  onCloseStatusModal() {
    this.setState({ showOverrideGuestStatus: false });
  }

  onCloseCreateGuestField() {
    this.setState({ showCreateGuestFieldModal: false, currentColumnProcessing: -1 });
  }

  onWheel(/*e*/) {
    // NEED REACT SUPPORT : https://github.com/facebook/react/issues/6436
    // const parentsCannotBeScrolled = (direction) => {
    //   let iterator = e.target;
    //   let count = 0;
    //   while (iterator.parentElement) {
    //     if (direction == "left") {
    //       if ($(iterator).scrollLeft() > 0) {
    //         count = count + 1;
    //         break;
    //       }
    //     } else {
    //       const childrenOuterWidth = $(iterator).children().outerWidth();
    //       const ownWidth = iterator.clientWidth;
    //       const scrollLeft = $(iterator).scrollLeft();
    //       if (ownWidth && childrenOuterWidth > ownWidth && childrenOuterWidth - scrollLeft > ownWidth) {
    //         count = count + 1;
    //         break;
    //       }
    //     }
    //     iterator = iterator.parentElement;
    //   }
    //   return count === 0;
    // };
    // const preventLeft = e.deltaX < 0 && parentsCannotBeScrolled("left");
    // const preventRight = e.deltaX > 0 && parentsCannotBeScrolled("right");
    // if (preventLeft || preventRight) {
    //   e.preventDefault();
    // }
  }

  getDataFromRow(line) {
    const { currentMapping, mappingGuestCategory } = this.state;
    const { guestImportOperation, guestCategories } = this.props;
    const rows = guestImportOperation.first_ten_rows || [];

    const mappedGuestCategory = (line, column) => {
      let guestCategoryLabel = "";
      Object.keys(mappingGuestCategory).some(guestCategoryId => {
        if (rows[line][column] == mappingGuestCategory[guestCategoryId]) {
          const guestCategory = guestCategories.find(gc => { return guestCategoryId == gc._id; });
          guestCategoryLabel = guestCategory ? guestCategory.name : "";
          return;
        }
      });
      return guestCategoryLabel;
    };

    const propsPreview = {};
    Object.keys(currentMapping).forEach(column => {
      const key = currentMapping[column].key;
      if (line < 0 || line >= rows.length) {
        propsPreview[key] = EtCaetera;
      } else {
        if (key != mapCategoryKey) {
          propsPreview[key] = rows[line][column] || " ";
        } else {
          propsPreview[key] = mappedGuestCategory(line, column);
        }
      }
    });
    return propsPreview;
  }

  renderPreviews() {
    const { mappingFirstLine } = this.state;
    return (
      <div>
        <Preview {...this.getDataFromRow(mappingFirstLine)} index={mappingFirstLine + 1}/>
        <Preview {...this.getDataFromRow(mappingFirstLine + 1)} index={mappingFirstLine + 2}/>
      </div>
    );
  }

  renderNbColumnMapped() {
    const { currentMapping } = this.state;
    const { guestImportOperation } = this.props;
    const rows = guestImportOperation.first_ten_rows;
    if (!rows || isEmpty(rows)) {
      return null;
    }
    return (<h4> {Object.keys(currentMapping).length} / {rows[0].length} {I18n.t("guest_import_operations.mapping_step.nb_columns_mapped")}</h4>);
  }


  render() {
    const { guestImportOperation, event } = this.props;

    if (!event || !event._id) return <Loader />;

    const { currentMapping, mappingFirstLine } = this.state;
    const rows = guestImportOperation.first_ten_rows || [];
    return (
      <div onWheel={this.onWheel}>
        {this.renderGuestCategoryModal()}
        {this.renderAccesspointModal()}
        {this.renderMapGuestCategoryModal()}
        {this.renderGuestStatusModal()}
        {this.renderCreateGuestField()}
        {this.renderValueListMappingModal()}
        <div className="row">
          <div className="col-md-12">
            <div>
              <div className="header-page">
                <div className="header-page-content">
                  <div className="header-page-title">
                    <h1>
                      {I18n.t("guest_import_operations.mapping_step.title") + " "}
                      <i className="fa-regular fa-info-circle" data-bs-toggle="tooltip" title={I18n.t("guest_import_operations.mapping_step.instant_help_html")} data-bs-placement="right"></i>
                    </h1>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 d-flex justify-content-between flex-wrap">
                  {this.renderNbColumnMapped()}
                  <div className="row g-2 align-items-center">
                    <div className="col-auto">
                      <a href={pathToNewGuestImportOperation()} className="btn btn-secondary">
                        <i className="fa-regular fa-long-arrow-left"></i> {I18n.t("guest_import_operations.mapping_step.create_new")}
                      </a>
                    </div>
                    <div className="col-auto">
                      <button className="btn btn-secondary" onClick={this.resetMapping}>
                        <i className="fa-regular fa-arrows-rotate"></i> {I18n.t("guest_import_operations.mapping_step.reset_mapping_field")}
                      </button>
                    </div>
                    <div className="col-auto">
                      <button className="btn btn-secondary" onClick={this.autoMapping}>
                        <i className="fa-regular fa-wand-magic-sparkles"></i> {I18n.t("guest_import_operations.mapping_step.auto_map")}
                      </button>
                    </div>
                    <div className="col-auto">
                      <button className="btn btn-secondary" onClick={this.submit}>
                        {I18n.t("guest_import_operations.mapping_step.test_import")} <i className="fa-regular fa-long-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <ImportWizard rows={rows} mappingPrototype={this.mappingPrototype()} currentMapping={currentMapping} defaultOption={doNotImportKey} onChange={this.mappingChanged} mappingFirstLine={mappingFirstLine}/>
              <div className="row">
                {this.renderPreviews()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    guestImportOperation: state.guestImportOperation,
    guestFields: filter(state.guestFields.guestFields, field => ![CalculatedType, LazyCalculatedType].includes(field.type)),
    guestCategories: state.guestCategories.data,
    accesspoints: state.accesspoints.data,
    guestProductCollections: state.guestProductCollections.data,
    event: state.event,
  };
}

const mapDispatchToProps = {
  fetchGuestImportOperations,
  fetchEventGuestFields,
  updateGuestImportOperations,
  importGuestCategories,
  fetchDistinctValuesAtColumn,
  fetchAccesspoints,
  fetchGuestProductCollections
};

export default connect(mapStateToProps, mapDispatchToProps)(MappingStep);
