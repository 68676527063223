import { ActionTypes } from "../constants/Constants";
import { AccesspointsLiveConsumptionState } from "../types/Accesspoint";

const defaultState: AccesspointsLiveConsumptionState = {
  accesspoints: [],
  fetchPending: false,
  nextURL: null,
  previousURL: null
};

function accesspointsLiveConsumption(state = defaultState, action: any): AccesspointsLiveConsumptionState {
  switch (action.type) {
  case ActionTypes.FETCH_LIVE_CONSUMPTION_REQUEST:
    return { ...state, fetchPending: true };
  case ActionTypes.FETCH_LIVE_CONSUMPTION_SUCCESS:
    return { ...state, accesspoints: action.data, fetchPending: false, nextURL: action.nextURL, previousURL: action.previousURL };
  case ActionTypes.FETCH_LIVE_CONSUMPTION_FAILURE:
    return { ...state, accesspoints: [], fetchPending: false, nextURL: null, previousURL: null };
  default:
    return state;
  }
}

export default accesspointsLiveConsumption;
