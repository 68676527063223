import { ActionTypes } from "../constants/Constants";

function guestImportOperation(state = { distinctValuesPendingRequest: false }, action) {
  switch (action.type) {
  case ActionTypes.GUEST_IMPORT_OPERATION_RECEIVE_SUCCESS:
    return Object.assign({}, action.data);
  case ActionTypes.FETCH_GUEST_IMPORT_OPERATION_DISTINCT_VALUES:
    return Object.assign({}, state, { distinctValues: null, distinctValuesPendingRequest: true });
  case ActionTypes.GUEST_IMPORT_OPERATION_DISTINCT_VALUES_SUCCESS: {
    let newState = insertDistinctValues(state, action);
    newState.distinctValuesPendingRequest = false;
    return newState;
  }
  default:
    return state;
  }
}

function insertDistinctValues(state, action) {
  if (!action.column && action.column < 0) {
    return state;
  }
  return Object.assign({}, state, { distinctValues: action.data });
}

export default guestImportOperation;
