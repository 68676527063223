import { Component } from "react";
import PropTypes from "prop-types";
import truncate from "lodash/truncate";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { pathToGuestCategoryShow } from "../../utils/pathUtils";

class GuestCategoryLabel extends Component {
  renderLabelContent() {
    const { guestCategory, cssClasses, truncateLenght, isLink, link } = this.props;

    const labelStyle = { backgroundColor: guestCategory.label_color };
    const labelSpan = <span className={`badge rounded-pill ${cssClasses}`} style={labelStyle}>
      { truncate(guestCategory.name, { length: truncateLenght }) }
    </span>;

    if (isLink)
      return <a href={ link || pathToGuestCategoryShow(guestCategory._id)} className="label-link d-inline" data-turbo="false">{ labelSpan }</a>;

    return labelSpan;
  }

  renderTooltip(message) {
    return <Tooltip id="tooltip">{message}</Tooltip>;
  }

  render() {
    const { guestCategory, hasTooltip, tooltip } = this.props;

    if (!guestCategory)
      return null;

    if (hasTooltip) {
      return <OverlayTrigger placement="top" overlay={this.renderTooltip(tooltip || guestCategory.name)}>
        { this.renderLabelContent() }
      </OverlayTrigger>;
    }

    return this.renderLabelContent();
  }
}

GuestCategoryLabel.propTypes = {
  guestCategory: PropTypes.object,
  truncateLenght: PropTypes.number,
  cssClasses: PropTypes.string,
  isLink: PropTypes.bool,
  link: PropTypes.string,
  hasTooltip: PropTypes.bool
};

GuestCategoryLabel.defaultProps = {
  truncateLenght: 60,
  cssClasses: "",
  isLink: true,
  hasTooltip: true,
  tooltip: null
};

export default GuestCategoryLabel;
