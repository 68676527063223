import { Component } from "react";
import PropTypes from "prop-types";
import every from "lodash/every";
import SlidingPane from "react-sliding-pane";
import Loader from "../shared/Loader.react";
import { GuestCategoryFeatures } from "../../constants/Constants";
import Icons from "../../constants/Icons";
import { allEnabled } from "../../utils/featureSetUtils";
import FeatureSetFormContainerPane from "../../components/features/FeatureSetFormContainerPane.react";

class FeaturesListPane extends Component {

  constructor(props) {
    super(props);
    [
      "enableFeature",
      "toggleFeatureSetFormContainerPane"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      isFeatureSetFormContainerPaneOpen: false
    };
  }

  disableBodyScroll() {
    document.body.style.overflow = "hidden";
  }

  enableBodyScroll() {
    document.body.removeAttribute("style");
  }

  enableFeature(feature) {
    return (e) => {
      e.preventDefault();
      const { onEnableFeature } = this.props;
      onEnableFeature(feature.field, feature.key);
    };
  }

  featureAvailable(feature) {
    const { guestCategory } = this.props;

    if (feature.alwaysDisplayed && feature.alwaysDisplayed(guestCategory))
      return false;

    if (feature.displayedIf && !feature.displayedIf(guestCategory)) {
      return false;
    }

    if ("adminOnly" in feature && !window.ReactGlobalProps.super_admin)
      return false;

    if (feature.requiredFeatures && !allEnabled(feature.requiredFeatures))
      return false;

    if (!("availability" in feature))
      return true;

    return feature.availability.includes(guestCategory.type);
  }

  allFeatureDependenciesEnabled(feature) {
    if (!("dependencies" in feature))
      return true;

    const { guestCategory } = this.props;
    return every(feature.dependencies.map(field => guestCategory[field]));
  }

  renderFeatures() {
    const { guestCategory } = this.props;

    return GuestCategoryFeatures.filter(feature => {
      return !guestCategory[feature.field] && this.featureAvailable(feature) && this.allFeatureDependenciesEnabled(feature);
    }).map(feature => {
      return this.renderFeatureCard(feature);
    });
  }

  renderFeatureCard(feature) {
    const { featureBeingAdded } = this.props;

    return <a href="#" className="list-group-item list-group-item-action" key={feature.name} onClick={ this.enableFeature(feature) }>
      <div className="feature-icon text-center d-none d-sm-inline">
        { feature.key == "invoice" ? <i className="fa-regular fa-calculator" /> : <i className={feature.icon} /> }
      </div>
      <div className="feature-desc">
        <h5 className="list-group-item-heading mb-5">
          {feature.name} {feature.adminOnly && <small><span className="text-warning">(admin only)</span></small> }
        </h5>
        <span className="list-group-item-text">{feature.description}</span>
      </div>
      <div className={`feature-action ${featureBeingAdded == feature.field && "feature-loading"}`}>
        { featureBeingAdded == feature.field ? <Loader /> : <i className="fa-regular fa-plus" /> }
      </div>
    </a>;
  }

  toggleFeatureSetFormContainerPane() {
    const { isFeatureSetFormContainerPaneOpen } = this.state;
    this.setState({ isFeatureSetFormContainerPaneOpen: !isFeatureSetFormContainerPaneOpen });
  }

  render() {
    const { isOpen, onRequestClose } = this.props;
    const { isFeatureSetFormContainerPaneOpen } = this.state;

    return (
      <SlidingPane
        isOpen={ isOpen }
        width="30%"
        title={I18n.t("guest_categories.show.add_feature")}
        onAfterOpen={ this.disableBodyScroll }
        onRequestClose={ () => {
          onRequestClose();
          this.enableBodyScroll();
        }}
        className="width-80-xs width-50-sm width-40-md"
        closeIcon={Icons.close()}>
        <div className="list-group features-list">
          {this.renderFeatures()}

          <a href="#" className="list-group-item list-group-item-action" onClick={this.toggleFeatureSetFormContainerPane}>
            <div className="feature-icon text-center d-none d-sm-inline">
              <i className="fa-regular fa-grid-2-plus fa-flip-vertical"></i>
            </div>
            <div className="feature-desc">
              <h5 className="list-group-item-heading mb-0">
                {I18n.t("shared.global_menu.features")}
              </h5>
            </div>
          </a>
          <FeatureSetFormContainerPane
            isOpen={isFeatureSetFormContainerPaneOpen}
            hide={this.toggleFeatureSetFormContainerPane} />
        </div>
      </SlidingPane>
    );
  }
}

FeaturesListPane.propTypes = {
  guestCategory: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  featureBeingAdded: PropTypes.string,
  onEnableFeature: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired
};

FeaturesListPane.defaultProps = {
  isOpen: false,
  featureBeingAdded: null
};

export default FeaturesListPane;
