import { Component } from "react";
import Modal from "react-modal";
import classNames from "classnames";
import { pathToNewRegistration, urlBase } from "../utils/pathUtils";
import { defaultModalSmallStyles } from "../constants/Style";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import Loader from "./shared/Loader.react";
import truncate from "lodash/truncate";

class RegistrationFormPreviewDropdown extends Component {

  constructor(props) {
    super(props);
    this.state = { modalIsOpen: false };
    const privateFunctions = [
      "_openModal",
      "_closeModal",
      "_handleModalSelect",
      "_isUnlinkable",
      "_unlinkableMessage",
      "_renderTopMessage"
    ];
    privateFunctions.forEach(fn => {
      this[fn] = this[fn].bind(this);
    });
    this.state = { pendingRequestForCategoryId: null };
  }

  componentDidUpdate() {
    const { pendingRequestForCategoryId } = this.state;
    if (pendingRequestForCategoryId) {
      this.props.guestCategories.find(category => {
        if (pendingRequestForCategoryId == category.id && category.custom_registration_form_enabled) {
          this.setState({ pendingRequestForCategoryId: null });
          return true;
        }
      });
    }
  }

  _linkableCategories() {
    const { guestCategories } = this.props;
    return guestCategories.filter(category => { return !category.custom_registration_form_enabled; });
  }

  _isUnlinkable(category) {
    return category.custom_registration_form_enabled;
  }

  _unlinkableMessage(category) {
    if (this._isUnlinkable(category)) {
      if (category.registration_form_id) {
        return I18n.t("react.registration_form_preview_dropdown.linked_with_form_builder");
      }
      return I18n.t("react.registration_form_preview_dropdown.linked_with_liquid_form");
    }
    return I18n.t("react.registration_form_preview_dropdown.available");
  }

  _linkedCategories() {
    const { guestCategories, registrationForm } = this.props;
    return guestCategories.filter(category => { return category.registration_form_id == registrationForm._id; });
  }

  _tooltipMessage(name) {
    return (
      <Tooltip id="tooltip">{name}</Tooltip>
    );
  }

  _openModal(e) {
    e.preventDefault();
    this.setState({ modalIsOpen: true });
  }

  _closeModal() {
    this.setState({ modalIsOpen: false });
  }

  _handleModalSelect(category) {
    return (e) => {
      e.preventDefault();
      const { handleLinkToCategory } = this.props;
      this.setState({ pendingRequestForCategoryId: category.id });
      handleLinkToCategory()(category);
    };
  }

  _renderLinkedGuestCategories() {
    const categories = this._linkedCategories();

    if (!categories || categories.length == 0) {
      return <li key={0} className="dropdown-header"><i className="text-muted"> { I18n.t("react.registration_form_preview_dropdown.not_linked_to_any_category") }</i></li>;
    }
    return categories.map(category => {
      return (
        <Dropdown.Item eventKey={category.id} href={pathToNewRegistration(category)} target="_blank">
          <OverlayTrigger placement="top" overlay={this._tooltipMessage(category.name)}>
            <span className="badge rounded-pill guest-category" style={{ backgroundColor: category.label_color }}>
              {truncate(category.name, { "length": 45 })}
            </span>
          </OverlayTrigger>
        </Dropdown.Item>
      );
    });
  }

  _renderLinkToCategorySection() {
    const { registrationForm, guestCategories } = this.props;
    if (registrationForm.is_ticketing_form || !guestCategories || guestCategories.length == 0) {
      return null;
    }

    return <>
      <Dropdown.Divider />
      <Dropdown.Item eventKey="open-modal" onClick={this._openModal}>
        <i className="fa-regular fa-link"/> { I18n.t("react.registration_form_preview_dropdown.link_to_guest_category") }
      </Dropdown.Item>
    </>;
  }

  renderLinkButton(category, unlinkableLinkStyle) {
    const { pendingRequestForCategoryId } = this.state;

    if (pendingRequestForCategoryId) {
      if (pendingRequestForCategoryId == category.id) {
        return (
          <button className={classNames(unlinkableLinkStyle, "btn", "btn-secondary")} disabled="disabled">
            <Loader size="small"/> { I18n.t("react.registration_form_attach_to_guest_category.link_pending") }
          </button>
        );
      } else {
        return (
          <button className={classNames(unlinkableLinkStyle, "btn", "btn-secondary")} disabled="disabled">
            <i className="fa-regular fa-link"/> { I18n.t("react.registration_form_preview_dropdown.link") }
          </button>
        );
      }
    }

    return (
      <button className={classNames(unlinkableLinkStyle, "btn", "btn-secondary")} onClick={this._handleModalSelect(category)}>
        <i className="fa-regular fa-link"/> { I18n.t("react.registration_form_preview_dropdown.link") }
      </button>
    );
  }

  _renderCategoryRows() {
    const { guestCategories } = this.props;

    const rows = guestCategories.map(category => {
      let unlinkableWrapperStyle = null;
      let unlinkableLinkStyle = null;
      if (this._isUnlinkable(category)) {
        unlinkableWrapperStyle = "unlinkable-wrapper";
        unlinkableLinkStyle = "unlinkable-link";
      }

      return (
        <div key={category.name} className="list-item d-flex justify-content-between">
          <OverlayTrigger placement="top" overlay={this._tooltipMessage(category.name)}>
            <div className="d-flex align-items-center">
              <span className="badge rounded-pill big guest-category" style={{ backgroundColor: category.label_color }}>
                {truncate(category.name, { "length": 45 })}
              </span>
            </div>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={this._tooltipMessage(this._unlinkableMessage(category))}>
            <div className={unlinkableWrapperStyle}>
              { this.renderLinkButton(category, unlinkableLinkStyle) }
            </div>
          </OverlayTrigger>
        </div>
      );
    });

    return rows;
  }

  renderErrors() {
    const { guestCategoriesErrors } = this.props;
    if (guestCategoriesErrors && guestCategoriesErrors["registration_form"] !== undefined) {
      return <div className="alert alert-danger">
        <strong>{I18n.t("react.registration_form_preview_dropdown.cannot_link_to_category")}</strong><br />
        { guestCategoriesErrors["registration_form"] }
      </div>;
    }
  }

  _renderTopMessage() {
    const allCategoriesAreLinked = this._linkableCategories().length == 0;
    let message = null;
    if (allCategoriesAreLinked) {
      message = (
        <p>
          <i className="fa-regular fa-exclamation-triangle"></i>
          {I18n.t("react.registration_form_preview_dropdown.all_categories_are_linked")}
          <a href={urlBase("guest_categories/new")} target="_blank"> {I18n.t("react.registration_form_preview_dropdown.here")}</a>
        </p>
      );
    }
    return message;
  }

  _renderModalContent() {
    return (
      <div>
        <div className="modal-header">
          <h4 className="modal-title">{ I18n.t("react.registration_form_preview_dropdown.pick_guest_category") }</h4>
          <button type="button" onClick={ this._closeModal } className="btn-close" aria-label={I18n.t("close")}></button>
        </div>
        <div className="modal-body modal-list-with-buttons">
          { this.renderErrors() }
          { this._renderTopMessage() }
          { this._renderCategoryRows() }
        </div>
      </div>
    );
  }

  render() {
    const { modalIsOpen } = this.state;

    return (
      <Dropdown>
        <Dropdown.Toggle variant="secondary" className="top-bar-link">
          <i className="d-none d-sm-inline fa-regular fa-eye"></i>
          <p className="d-inline d-sm-none">{ I18n.t("react.registration_form_preview_dropdown.preview") }</p>
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          { this._renderLinkedGuestCategories() }
          { this._renderLinkToCategorySection() }
        </Dropdown.Menu>
        <Modal isOpen={modalIsOpen} onRequestClose={this._closeModal} style={defaultModalSmallStyles} contentLabel="Modal">
          { this._renderModalContent() }
        </Modal>
      </Dropdown>
    );
  }
}

export default RegistrationFormPreviewDropdown;
