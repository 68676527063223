import { Component } from "react";
import { connect } from "react-redux";
import ExportLine from "../components/ExportLine.react";
import Table from "../components/Table.react";
import { updateUI } from "../actions/UIAppearanceActionCreators";
import { requestExports } from "../actions/ImportActionCreators";
import isEmpty from "lodash/isEmpty";
import withModal from "./withModal.react";

class ExportsList extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const updates = {
      "modalTitle": I18n.t("react.exports_list.exports_list")
    };
    dispatch(updateUI(updates));
    dispatch(requestExports());
  }

  componentWillUnmount() {
    this.props.dispatch(updateUI({ "hideSideBar": false }));
  }

  render() {
    let exportList = "";
    if (isEmpty(this.props.exports)) {
      exportList = I18n.t("react.exports_list.no_export_to_display");
    } else {
      const exportNodes = this.props.exports.map(export_data =>
        <ExportLine key={export_data._id} export_data={export_data}/>
      );

      if (exportNodes.length > 0) {
        const headers = [
          I18n.t("react.exports_list.type"),
          I18n.t("react.exports_list.user"),
          I18n.t("react.exports_list.asked"),
          I18n.t("react.exports_list.status"),
          I18n.t("react.exports_list.progress"),
          I18n.t("react.exports_list.download")
        ];
        exportList = (
          <Table headers={headers} >
            {exportNodes}
          </Table>
        );
      }
    }

    return (
      <div>
        { exportList }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    exports: state.exports
  };
}

export default connect(mapStateToProps)(withModal(ExportsList));
