import indexOf from "lodash/indexOf";
import last from "lodash/last";
import includes from "lodash/includes";

function _pathComponents() {
  return window.location.pathname.split("/");
}

function _pathComponentsWithModal() {
  return "modal/" + last(_pathComponents());
}

function _urlLocale() {
  return _pathComponents()[1];
}

function _urlElement(number) {
  const components = _pathComponents();
  const isPublic = components.indexOf("public") > 0;
  const isReactPath = components.indexOf("r") > 0;
  let index = number;
  if (isPublic) index++;
  if (isReactPath) index++;
  return components[index];
}

function _urlEventId() {
  return _urlElement(3);
}

function _basePath() {
  return includes(_pathComponents(), "r") ? _basePathWithReact() : _basePathWithoutReact();
}

function _basePathWithoutReact(eventId) {
  const id = eventId || _urlEventId();
  return `/${_urlLocale()}/events/${id}/`;
}

function _basePathWithReact(eventId) {
  const id = eventId || _urlEventId();
  return `/${_urlLocale()}/r/events/${id}/`;
}

function _publicBasePathWithoutReact() {
  return `/${_urlLocale()}/public/events/${_urlEventId()}/`;
}

function _pathHasParameters(path) {
  return includes(path, "?") && indexOf("?") != path.length - 1;
}

// return search option between baseUrl and searchQuery
function _preQueryPart(options = { withModal: false }) {
  const lastUrlComponent = options["withModal"] ? _pathComponentsWithModal() : last(_pathComponents());
  const searchQueryStartIndex = lastUrlComponent.indexOf("q=");
  if (searchQueryStartIndex > 0) {
    return lastUrlComponent.slice(0, searchQueryStartIndex - 1);
  } else {
    return lastUrlComponent;
  }
}

function _searchQueryUrlFormatter(searchQuery) {
  return `q=${encodeURIComponent(searchQuery)}&utf8=✓`;
}

function _urlWithQuery(searchQuery, specificPreQuery = "", options = { withModal: false, withReact: true }) {
  let queryString = "";
  let preQueryPart = _preQueryPart(options);
  if (specificPreQuery.length > 0) {
    preQueryPart = specificPreQuery;
  }
  if (searchQuery && searchQuery.length > 0) {
    if (_pathHasParameters(preQueryPart)) {
      queryString += "&";
    } else {
      queryString += "?";
    }
    queryString += _searchQueryUrlFormatter(searchQuery);
  }
  return options.withReact ? `${_basePathWithReact()}${preQueryPart}${queryString}` : `${_basePathWithoutReact()}${preQueryPart}${queryString}`;
}

module.exports = {

  relativePath(path) {
    return _basePath() + path;
  },

  lastElementFromCurrentPath() {
    return _pathComponents()[_pathComponents().length - 1];
  },

  removeSchemeAndHost(url) {
    return url.replace(/^.*\/\/[^/]+/, "");
  },

  urlEventId() {
    return _urlEventId();
  },

  urlEventLocale() {
    return _urlLocale();
  },

  urlGuestId() {
    return _urlElement(5);
  },

  secondLevelId() { // return second-resource-id /fr/first-resource/first-resource-id/second-resource/second-resource-id/(...)
    return _urlElement(5);
  },

  urlBase(path, eventId = null) {
    return _basePathWithoutReact(eventId) + path;
  },

  searchQueryUrlFormatter(searchQuery) {
    return _searchQueryUrlFormatter(searchQuery);
  },

  urlWithQuery(searchQuery, specificPreQuery = "", options = { withModal: false, withReact: true }) {
    return _urlWithQuery(searchQuery, specificPreQuery, options);
  },

  pathToEvent(eventId) {
    return _basePathWithoutReact(eventId);
  },

  pathToEventSmtpConfig(eventId) {
    return _basePathWithoutReact(eventId) + "edit_advanced#smtp_config";
  },

  pathToNewGuest() {
    return _basePathWithoutReact() + "guests/new";
  },

  pathToGuests() {
    return _basePathWithReact() + "guests";
  },

  pathToFeatures() {
    return _basePathWithReact() + "features";
  },

  pathToNewRegistration(guestCategory) {
    return `${_publicBasePathWithoutReact()}guest_categories/${guestCategory._id}/registrations/new`;
  },

  pathToNewEventGuestImportOperation() {
    return _basePathWithoutReact() + "guest_import_operations/new";
  },

  pathToCreateQRCodeArchive() {
    return _basePathWithoutReact() + "guests/create_qr_code_archive";
  },

  pathToResendConfirmationEmails() {
    return _basePathWithoutReact() + "resend_confirmation_emails_modal";
  },

  pathToArchiveBadgeModal() {
    return _basePathWithoutReact() + "archive_badges_modal";
  },

  pathToCreateDocumentsArchive() {
    return _basePathWithoutReact() + "guests/create_documents_archive";
  },

  pathToGuestShow(guestId) {
    return _basePathWithoutReact() + `guests/${guestId}`;
  },

  pathToGuestEmail(guest, emailType) {
    return _basePathWithoutReact() + `guests/${guest._id}/email_guest?secret=${guest.secret}&type=${emailType}`;
  },

  pathToGuestEdit(guest) {
    return _basePathWithoutReact() + `guests/${guest._id}/edit`;
  },

  pathToGuestCategoriesNew() {
    return _basePathWithoutReact() + "guest_categories/new";
  },

  pathToGuestCategoriesList() {
    return _basePathWithoutReact() + "guest_categories";
  },

  pathToGuestCategoryEdit(id) {
    return _basePathWithoutReact() + `guest_categories/${id}/edit`;
  },

  pathToGuestCategoryShow(guest_category_id) {
    return _basePathWithReact() + `guest_categories/${guest_category_id}`;
  },

  pathToGuestCategoryBadgeTemplatesList(eventId, guestCategoryId) {
    return `/${_urlLocale()}/events/${eventId}/badge_templates?guest_category_id=${guestCategoryId}`;
  },

  pathToGuestCategoryOnDuplicatedEmail(guest_category_id) {
    return _basePathWithReact() + `guest_categories/${guest_category_id}/email_live_check`;
  },

  pathToGuestCategoryShowWithAnotherEvent(eventId, guestCategoryId) {
    return `/${_urlLocale()}/events/${eventId}/guest_categories/${guestCategoryId}`;
  },

  pathToRegistrationFormFromAnotherEvent(eventId, registrationFormId) {
    return `/${_urlLocale()}/events/${eventId}/registration_forms/${registrationFormId}`;
  },

  pathToEmailTemplateShowWithAnotherEvent(eventId, emailTemplateId) {
    return `/${_urlLocale()}/events/${eventId}/emails/${emailTemplateId}/builder`;
  },

  pathToDocumentBuilderWithAnotherEvent(eventId, documentTemplateId) {
    return `/${_urlLocale()}/events/${eventId}/document_templates/${documentTemplateId}/builder`;
  },

  pathToEmailTemplateIndex(eventId) {
    return `/${_urlLocale()}/events/${eventId}/emails`;
  },

  pathToWebsiteBuilderShowWithAnotherEvent(eventId) {
    return `/${_urlLocale()}/events/${eventId}/website/builder`;
  },

  pathToAccesspointIndex() {
    return _basePathWithoutReact() + "accesspoints";
  },

  pathToAccesspointEdit(accesspointId) {
    return _basePathWithoutReact() + `accesspoints/${accesspointId}/edit`;
  },

  pathToSessionsList() {
    return _basePathWithoutReact() + "accesspoints/sessions";
  },

  pathToSessionsListFilteredBySessionType(sessionTypeId) {
    return _basePathWithoutReact() + `accesspoints/sessions?session_type_id=${sessionTypeId}`;
  },

  pathToProductsList() {
    return _basePathWithoutReact() + "accesspoints/products";
  },

  pathToBundlesList() {
    return _basePathWithoutReact() + "accesspoints/bundles";
  },

  pathToAccommodationsList() {
    return _basePathWithoutReact() + "accesspoints/accommodations";
  },

  pathToMeetingsList() {
    return _basePathWithoutReact() + "accesspoints/meetings";
  },

  pathToMeetingsConfiguration() {
    return _basePathWithoutReact() + "meetings";
  },

  pathToGuestNew() {
    return _basePathWithoutReact() + "guests/new";
  },

  pathToGuestsImport() {
    return _basePathWithoutReact() + "guest_import_operations/new";
  },

  pathToSavedSearchesShow() {
    return _basePath() + "guests/modal/saved_searches";
  },

  pathToRegistrationForms() {
    return _basePathWithReact() + "registration_forms";
  },

  pathToEventDocuments() {
    return _basePathWithReact() + "document_templates";
  },

  pathToGuestCampaigns() {
    return _basePathWithReact() + "campaigns";
  },

  pathToGuestCategoryLiquidField(guestCategoryId, type = "registration_form") {
    return `${_basePathWithoutReact()}guest_categories/${guestCategoryId}/liquid_field?type=${type}`;
  },

  pathToGuestCategoryNotification(guestCategoryId) {
    return `${_basePathWithoutReact()}guest_categories/${guestCategoryId}/notifications`;
  },

  pathToGuestCategoryManageGuestCategoryAccesspoints(guestCategoryId) {
    return `${_basePathWithoutReact()}guest_categories/${guestCategoryId}/manage_guest_category_accesspoints`;
  },

  pathToGuestBadgePermalink(guest, badgeTemplateId) {
    return `${_publicBasePathWithoutReact()}guests/${guest.id}/badge?badge_template_id=${badgeTemplateId}&secret=${guest.secret}`;
  },

  pathToGuestCategoryBadgeBuilder(eventId, badgeTemplateId, guestCategoryId) {
    return `/${_urlLocale()}/events/${eventId}/badge_templates/${badgeTemplateId}/builder?guest_category_id=${guestCategoryId}`;
  },

  pathToPreviewEmail(guest_campaign_id, refreshParam = "") {
    return `${_basePathWithoutReact()}guest_campaigns/${guest_campaign_id}/email_iframe?refresh_param=${refreshParam}`;
  },

  pathToGuestCampaignEmail(guest_campaign_id) {
    return `${_basePath()}campaign/${guest_campaign_id}/email`;
  },

  pathToRegistrationForm(formId) {
    return `${_basePathWithoutReact()}registration_forms/${formId}`;
  },

  pathToBadgeConfiguration(guestCategoryId) {
    return `${_basePathWithoutReact()}guest_categories/${guestCategoryId}/badge`;
  },

  pathToWalletPassConfiguration(guestCategoryId) {
    return `${_basePathWithoutReact()}guest_categories/${guestCategoryId}/wallet_pass`;
  },

  pathToConfirmationEmailConfiguration(guestCategoryId) {
    return `${_basePathWithoutReact()}guest_categories/${guestCategoryId}/email?type=confirmation`;
  },

  pathToModerationEmailConfiguration(guestCategoryId) {
    return `${_basePathWithoutReact()}guest_categories/${guestCategoryId}/email?type=moderation`;
  },

  pathToEditionLinkEmailConfiguration(guestCategoryId) {
    return `${_basePathWithoutReact()}guest_categories/${guestCategoryId}/email?type=edition_link`;
  },

  pathToUnsubscribeEmailConfiguration(guestCategoryId) {
    return `${_basePathWithoutReact()}guest_categories/${guestCategoryId}/email?type=unsubscribe`;
  },

  pathToCustomEmailByType(guestCategoryId, emailType) {
    return `${_basePathWithoutReact()}guest_categories/${guestCategoryId}/email?type=${emailType}`;
  },

  pathToGuestCategoryDocuments(guestCategoryId) {
    return `${_basePathWithoutReact()}guest_categories/${guestCategoryId}/document_templates`;
  },

  pathToGuestCategoryHistory(guestCategoryId) {
    return `${_basePathWithoutReact()}guest_categories/${guestCategoryId}/history_items`;
  },

  pathToLeadsEmailTemplate() {
    return `${_basePathWithoutReact()}exhibitors/email_template`;
  },

  pathToOnsitePaymentAppFlashConfig(guestCategoryId) {
    return `${_basePathWithoutReact()}guest_categories/${guestCategoryId}/onsite_payment_app_flash_config_qrcode`;
  },

  pathToLabelsConfiguration() {
    return `${_basePathWithoutReact()}labels`;
  },

  pathToReport(reportType) {
    return `${_basePath()}reports/${reportType}`;
  },

  pathToReports() {
    return `${_basePath()}reports`;
  },

  pathToNewGuestImportOperation() {
    return `${_basePathWithoutReact()}guest_import_operations/new`;
  },

  pathMappingStepToWaitingForVerificationStep(guestImportOperationId) {
    return `${_basePathWithoutReact()}guest_import_operations/${guestImportOperationId}/waiting_for_verification_step?react_view=true`;
  },

  pathToRegistrationFormListTranslations() {
    return `${_basePathWithReact()}translations/registration_forms/`;
  },

  pathToRegistrationFormTranslations(form) {
    return `${_basePathWithReact()}translations/registration_forms/${form._id}`;
  },

  pathToTranslation(type, obj) {
    return `${_basePathWithReact()}translations/${type}/${obj.id || obj._id}`;
  },

  pathToGuestFields() {
    return `${_basePathWithoutReact()}guest_fields`;
  },

  pathToGuestField(guestFieldsId) {
    return `${_basePathWithReact()}guest_fields/${guestFieldsId}`;
  },

  pathToGuestFieldValuesListExport(guestFieldsId) {
    return `${_basePathWithoutReact()}guest_fields/${guestFieldsId}/export_value_list`;
  },

  pathToPromoCodes() {
    return `${_basePathWithoutReact()}promo_codes`;
  },

  pathToExhibitor(exhibitorId) {
    return `${_basePathWithoutReact()}exhibitors/${exhibitorId}`;
  },

  pathToGuestCategoryPayment(guestCategoryId) {
    return `${_basePathWithoutReact()}guest_categories/${guestCategoryId}/payment`;
  },

  pathToGuestCategoryModeration(guestCategoryId) {
    return `${_basePathWithoutReact()}guest_categories/${guestCategoryId}/moderation`;
  },

  pathToGuestCategoryZohoConfiguration(guestCategoryId) {
    return `${_basePathWithoutReact()}guest_categories/${guestCategoryId}/zoho_invoice`;
  },

  pathToExhibitors() {
    return `${_basePathWithoutReact()}exhibitors`;
  },

  pathToTicketing() {
    return `${_basePathWithoutReact()}ticketing`;
  },

  pathToTicketingNew() {
    return `${_basePathWithoutReact()}ticketing/new`;
  },

  pathToTicketingEdit(ticketingId) {
    return `${_basePathWithoutReact()}ticketing/${ticketingId}/edit`;
  },

  pathToWebsiteConfig() {
    return `${_basePathWithoutReact()}website`;
  },

  pathToWebsiteBuilder() {
    return `${_basePathWithoutReact()}website/builder`;
  },

  pathToWebsiteBuilderPage(pageId) {
    return `${_basePathWithoutReact()}website/builder/page/${pageId}`;
  },

  pathToEmailTemplatePreview(emailTemplateId, guestId = null) {
    const query = guestId ? `?guest_id=${guestId}` : "";
    return `${_basePathWithoutReact()}emails/${emailTemplateId}/preview${query}`;
  },

  pathToDocumentTemplatePreview(documentTemplateId, guestId = null) {
    const query = guestId ? `?guest_id=${guestId}` : "?uid=0";
    return `${_basePathWithoutReact()}document_templates/${documentTemplateId}/preview${query}`;
  },

  pathToEmailBuilder(emailTemplateId) {
    return `${_basePathWithoutReact()}emails/${emailTemplateId}/builder`;
  },

  pathToDocumentBuilder(documentTemplateId) {
    return `${_basePathWithoutReact()}document_templates/${documentTemplateId}/builder`;
  },

  pathToWorkflowBuilder(workflowId) {
    return `${_basePathWithoutReact()}workflows/${workflowId}/builder`;
  },

  pathToWorkflows() {
    return `${_basePathWithReact()}workflows`;
  },

  pathToWorkflowRuns(workflowId = null) {
    if (workflowId) {
      return `${_basePathWithReact()}workflow_runs?workflow_id=${workflowId}`;
    } else {
      return `${_basePathWithReact()}workflow_runs`;
    }
  },

  pathToWorkflowRun(workflowRunId) {
    return `${_basePathWithReact()}workflow_runs/${workflowRunId}`;
  },

  pathToWebsiteChangeDomainName() {
    return `${_basePathWithoutReact()}website/edit`;
  },

  pathToWebsiteConfigurePwa() {
    return `${_basePathWithoutReact()}website/edit_pwa`;
  },

  pathToWebsiteSeo() {
    return `${_basePathWithoutReact()}website/seo`;
  },

  pathToWebsiteGdpr() {
    return `${_basePathWithoutReact()}website/gdpr`;
  },

  pathToWebsiteConsentNotice(options = {}) {
    const query = options.migratingFromLegacyNotice ? "?migrating_from_legacy_notice=true" : "";
    return `${_basePathWithoutReact()}website/consent_notice${query}`;
  },

  pathToWebsiteWebPushNotifications() {
    return `${_basePathWithoutReact()}website/web_push_notifications`;
  },

  pathToHelpRegistrationAfterSignin() {
    return `${_basePathWithoutReact()}website/help_registration_after_signin`;
  },

  pathToWebsiteConfigureMenu() {
    return `${_basePathWithoutReact()}website/menus`;
  },

  pathToWebsiteView(websiteFallBackDomain, currentPagePathName = "") {
    if (window.location.port != "") { // development
      return "http://" + websiteFallBackDomain + ":" + window.location.port + "/" + currentPagePathName;
    }

    return "https://" + websiteFallBackDomain + "/" + currentPagePathName;
  },

  pathToPublicRegistrationForm(guest) {
    return `/public/events/${guest.event_id}/guests/${guest.id}/edit?secret=${guest.secret}&register=true&persisted=true`;
  },

  pathToEventsList(accountId) {
    return `/${_urlLocale()}/accounts/${accountId}/events`;
  },

  pathToSessionRoomsList() {
    return _basePathWithoutReact() + "session_rooms";
  },

  pathToSessionRoomEdit(sessionRoomId) {
    return _basePathWithReact() + `session_rooms/${sessionRoomId}/edit`;
  },

  pathToBlogArticlesList() {
    return _basePathWithReact() + "blog_articles";
  },

  pathToBlogArticle(blogArticleId) {
    return _basePathWithReact() + `blog_articles/${blogArticleId}`;
  },

  pathToBlogArticleWithAnotherEvent(eventId, blogArticleId) {
    return _basePathWithReact(eventId) + `blog_articles/${blogArticleId}`;
  },

  pathToCustomIntegrations() {
    return `${_basePathWithoutReact()}custom_integrations`;
  },

  pathToIntegrationsList() {
    return `${_basePathWithReact()}integrations`;
  },

  pathToNewOrEditIntegration(endpoint) {
    return _basePathWithoutReact() + endpoint;
  },

  pathToReactEditIntegration(endpoint) {
    return _basePathWithReact() + endpoint;
  },

  pathToZapierIntegration() {
    return `${_basePathWithoutReact()}connect`;
  }
};
