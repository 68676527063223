import { ActionTypes } from "../constants/Constants";

const defaultState = {
  data: null,
  errors: {},
  persisted: false
};

function websiteMenu(state = defaultState, action) {
  switch (action.type) {
  case ActionTypes.UPDATE_MENU_SUCCESS:
  case ActionTypes.CREATE_MENU_SUCCESS:
  case ActionTypes.RECEIVE_MENU_SUCCESS: {
    let res = Object.assign({}, action.data);
    delete res["menu_items"];
    delete res["errors"];
    return Object.assign({}, state, { data: res, errors: {}, persisted: true });
  }
  case ActionTypes.CREATE_MENU_FAILURE:
  case ActionTypes.UPDATE_MENU_FAILURE: {
    let res = Object.assign({}, action.data);
    delete res["menu_items"];
    delete res["errors"];
    return Object.assign({}, state, { data: res, errors: action.data.errors });
  }
  case ActionTypes.CREATE_MENU_OFFLINE:
    return Object.assign({}, state, { data: Object.assign({}, action.data), persisted: false });
  case ActionTypes.UPDATE_MENU_OFFLINE:
    return Object.assign({}, state, { data: Object.assign({}, action.data) });
  case ActionTypes.CLEAR_MENU_STORE:
  case ActionTypes.DELETE_MENU_SUCCESS: {
    return defaultState;
  }
  default:
    return state;
  }
}

export default websiteMenu;
