import { ActionTypes } from "../constants/Constants";

module.exports = {
  setSelectedEngagementLevels(objectIds) {
    return {
      type: ActionTypes.SET_SELECTED_ENGAGEMENT_LEVELS,
      objectIds
    };
  }

};
