import { CALL_API, ActionTypes } from "../constants/Constants";
import { removeSchemeAndHost } from "../utils/pathUtils";
import { schema } from "normalizr";

function fetchActionWithEndpoint(endpoint) {
  const { REGISTRATION_FORMS_REQUEST, REGISTRATION_FORMS_SUCCESS, REGISTRATION_FORMS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [REGISTRATION_FORMS_REQUEST, REGISTRATION_FORMS_SUCCESS, REGISTRATION_FORMS_FAILURE],
      method: "GET",
      endpoint
    }
  };
}

export function fetchRegistrationForms(eventId, page = 1, per_page = 20) {
  return fetchActionWithEndpoint(`/api/v1/events/${eventId}/registration_forms.json?page=${page}&per_page=${per_page}`);
}

export function fetchRegistrationFormsFromEvent(eventId) {
  const { DEV_NULL, FETCH_REGISTRATION_FORMS_FROM_EVENT_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, FETCH_REGISTRATION_FORMS_FROM_EVENT_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/registration_forms.json`
    },
    eventId
  };
}

export function fetchRegistrationFormsPage(pageURL) {
  return fetchActionWithEndpoint(removeSchemeAndHost(pageURL));
}

function registrationFormsSchemas() {
  const registrationForm = new schema.Entity("registrationForm", {}, { idAttribute: "_id" });

  registrationForm.define({
    form_steps: [ registrationFormStepsSchemas() ]
  });

  return registrationForm;
}

export function registrationFormStepsSchemas() {
  const formSteps = new schema.Entity("formSteps", {}, { idAttribute: "_id" });
  const formSections = new schema.Entity("formSections", {}, { idAttribute: "_id" });
  const formItems = new schema.Entity("formItems", {}, { idAttribute: "_id" });
  formSteps.define({
    form_sections: [ formSections ]
  });
  formSections.define({
    form_items: [ formItems ]
  });
  return formSteps;
}

export function fetchRegistrationForm(registrationFormId) {
  const { REGISTRATION_FORM_REQUEST, REGISTRATION_FORM_SUCCESS, REGISTRATION_FORM_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [REGISTRATION_FORM_REQUEST, REGISTRATION_FORM_SUCCESS, REGISTRATION_FORM_FAILURE],
      method: "GET",
      endpoint: `/api/v1/registration_forms/${registrationFormId}.json`,
      schema: registrationFormsSchemas()
    }
  };
}

export function createRegistrationForm(eventId, redirectTo = null) {
  const { REGISTRATION_FORM_CREATE_REQUEST, REGISTRATION_FORM_CREATE_SUCCESS, REGISTRATION_FORM_CREATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [REGISTRATION_FORM_CREATE_REQUEST, REGISTRATION_FORM_CREATE_SUCCESS, REGISTRATION_FORM_CREATE_FAILURE],
      method: "POST",
      body: { registration_form: { event_id: eventId } },
      endpoint: `/api/v1/events/${eventId}/registration_forms.json`,
      redirectTo
    }
  };
}

export function removeRegistrationForm(registrationFormId, redirectTo = null) {
  const { REGISTRATION_FORM_DELETE_REQUEST, REGISTRATION_FORM_DELETE_SUCCESS, REGISTRATION_FORM_DELETE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [REGISTRATION_FORM_DELETE_REQUEST, REGISTRATION_FORM_DELETE_SUCCESS, REGISTRATION_FORM_DELETE_FAILURE],
      method: "DELETE",
      endpoint: `/api/v1/registration_forms/${registrationFormId}.json`,
      redirectTo
    },
    registrationFormId
  };
}

export function updateRegistrationForm(registrationFormId, params, sendFile = false) {
  const { REGISTRATION_FORM_UPDATE_REQUEST, REGISTRATION_FORM_UPDATE_SUCCESS, REGISTRATION_FORM_UPDATE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [REGISTRATION_FORM_UPDATE_REQUEST, REGISTRATION_FORM_UPDATE_SUCCESS, REGISTRATION_FORM_UPDATE_FAILURE],
      method: "PUT",
      body: sendFile ? params : { registration_form: params },
      sendFile: sendFile,
      endpoint: `/api/v1/registration_forms/${registrationFormId}.json`
    }
  };
}

export function removeCustomCssRegistrationForm(registrationFormId) {
  const { REGISTRATION_FORM_DELETE_CUSTOM_CSS_REQUEST, REGISTRATION_FORM_DELETE_CUSTOM_CSS_SUCCESS, REGISTRATION_FORM_DELETE_CUSTOM_CSS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [REGISTRATION_FORM_DELETE_CUSTOM_CSS_REQUEST, REGISTRATION_FORM_DELETE_CUSTOM_CSS_SUCCESS, REGISTRATION_FORM_DELETE_CUSTOM_CSS_FAILURE],
      method: "PUT",
      body: {},
      endpoint: `/api/v1/registration_forms/${registrationFormId}/destroy_custom_css.json`
    }
  };
}

export function duplicateRegistrationForm(registrationFormId, params = {}, redirectTo = null) {
  const { REGISTRATION_FORM_DUPLICATE_SUCCESS, REGISTRATION_FORM_DUPLICATE_FAILURE, DEV_NULL } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, REGISTRATION_FORM_DUPLICATE_SUCCESS, REGISTRATION_FORM_DUPLICATE_FAILURE],
      method: "POST",
      body: params,
      endpoint: `/api/v1/registration_forms/${registrationFormId}/duplicate.json`,
      redirectTo
    }
  };
}

export function fetchSubFormItems(registrationFormId, redirectTo = null) {
  const { REGISTRATION_FORM_ITEMS_FETCH_REQUEST, REGISTRATION_FORM_ITEMS_FETCH_SUCCESS, REGISTRATION_FORM_ITEMS_FETCH_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [REGISTRATION_FORM_ITEMS_FETCH_REQUEST, REGISTRATION_FORM_ITEMS_FETCH_SUCCESS, REGISTRATION_FORM_ITEMS_FETCH_FAILURE],
      method: "GET",
      endpoint: `/api/v1/registration_forms/${registrationFormId}/form_items/all_for_subform.json`,
      redirectTo
    }
  };
}
