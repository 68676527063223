import { Fragment, Component } from "react";
import classNames from "classnames";
import { FORM_PAGE_REGEX, CONFIRMATION_PAGE_REGEX } from "../../constants/Constants";
import { urlEventId } from "../../utils/pathUtils";
import { translatedTextFromJson } from "../../utils/templatesBuilderUtils";
import { Button } from "react-bootstrap";
import { SegmentPicker } from "../../containers/shared/SegmentPicker.react";
import AccesspointSearchDropdown from "../../containers/AccesspointSearchDropdown.react";
import ConditionalDisplay from "../templates_builder/ConditionalDisplay.react";
import { isEnabled } from "../../utils/featureSetUtils";
import { CHECKIN, SEO, MOBILE_APP } from "../../constants/FeaturesSet";
import TranslatableInput from "../../components/shared/TranslatableInput.react";
import moment from "moment";
import GuestProductCollectionPicker from "../templates_builder/GuestProductCollectionPicker.react";
import ScheduleDisplay from "../templates_builder/ScheduleDisplay.react";

const MOMENT_DATE_FORMAT = "YYYY-MM-DD";
const PWA_CACHE_STRATEGIES = ["network_first", "cache_first", "stale_while_revalidate"];
const AUTHORIZED_PAGE_PARAMS = ["_id", "name", "path_name", "path_name_translations", "online", "hide_on_app",
  "seo_title", "seo_description", "page_template_id", "layout_id", "segments", "is_private", "public_only",
  "resource_based", "resource_name", "guest_product_collection_id", "guests_segments", "linked_accesspoint_id",
  "pwa_cache_strategy", "is_homepage", "online_start_date", "online_end_date", "evaluated_online_start_date",
  "evaluated_online_end_date", "online_schedule_type"];
const AUTHORIZED_PAGE_PARAMS_FOR_DUPLICATION = ["_id", "name", "path_name", "path_name_translations", "online",
  "hide_on_app", "seo_title", "seo_description", "layout_id", "is_homepage", "online_start_date", "online_end_date",
  "evaluated_online_start_date", "evaluated_online_end_date", "online_schedule_type"];

class WebsitePageForm extends Component {
  constructor(props) {
    super(props);
    [
      "savePage",
      "hideView",
      "prefillPathName",
      "onSelectedSegmentChange",
      "onChangeVisibilitySettings",
      "onChangeResourceBasedCheckbox",
      "onChangeLinkedToAccesspointCheckbox",
      "onSelectLinkedAccesspointId",
      "onChangeHomepageCheckbox",
      "onDateChange"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });

    this.state = {
      ...props.defaultState,
      showPathTranslations: false
    };
  }

  componentDidUpdate(prevProps) {
    const { mode, layouts, templates, fetchWebsiteLayouts, fetchWebsitePageTemplates } = this.props;
    const { layout_id, page_template_id, evaluated_online_start_date, evaluated_online_end_date } = this.state;
    let newState = {};

    if (prevProps.mode != mode) {
      if (this.props.mode == "new") {
        newState = { ...this.props.defaultState };
        this.setState(newState);
      } else if (mode == "edit") {
        newState = {
          ...this.filterPageParamsFrom(this.props.page, mode),
          locked: this.props.page.locked,
          linked_to_accesspoint: !!this.props.page.linked_accesspoint_id
        };
        this.setState(newState);
      } else if (mode == "duplicate") {
        newState = {
          ...this.filterPageParamsFrom(this.props.page, mode),
          ...this.generateNewValuesBeforeDuplication(this.props.page),
          locked: this.props.page.locked
        };
        this.setState(newState);
      }
    }

    if (!prevProps.layouts && !prevProps.mode && mode) {
      fetchWebsiteLayouts(urlEventId()); // load layouts if not already loaded
    }

    if (!prevProps.templates && !prevProps.mode && mode) {
      fetchWebsitePageTemplates(urlEventId()); // load page templates if not already loaded
    }

    if ((layout_id == "" || newState.layout_id == "") && layouts) {
      newState.layout_id = this.props.layouts.find(layout => layout.filename == "theme")._id;
      this.setState(newState);
    }

    if ((page_template_id == "" || newState.page_template_id == "") && templates) {
      newState.page_template_id = templates.find(template => template.filename == "page.inner")._id;
      this.setState(newState);
    }

    if (this.props.page && this.props.page.evaluated_online_start_date !== evaluated_online_start_date) {
      newState.evaluated_online_start_date = this.props.page.evaluated_online_start_date;
      this.setState(newState);
    }
    if (this.props.page && this.props.page.evaluated_online_end_date !== evaluated_online_end_date) {
      newState.evaluated_online_end_date = this.props.page.evaluated_online_end_date;
      this.setState(newState);
    }
  }

  filterPageParamsFrom(page, mode) {
    const fields = mode == "duplicate" ? AUTHORIZED_PAGE_PARAMS_FOR_DUPLICATION : AUTHORIZED_PAGE_PARAMS;

    return fields.reduce((acc, field) => {
      if (["seo_title", "seo_description"].includes(field) && !isEnabled(SEO)) return acc;

      acc[field] = page[field];
      return acc;
    }, {});
  }

  generateNewValuesBeforeDuplication(page) {
    const { websitePages, event } = this.props;

    let newName, newPathname, newFilename;
    let newPathNameTranslations = {};
    let currentTranslations = {};
    let foundN = false;
    let n = 2;

    while (!foundN) {
      newName = `${page.name} (${n})`;
      if (page.resource_based) {
        newPathname = `${page.path_name.split("/__id__")[0]}-${n}/__id__`;
        newFilename = `${page.filename.split("/__id__")[0]}_${n}/__id__`;
      } else {
        newPathname = `${page.path_name}-${n}`;
        newFilename = `${page.filename}_${n}`;
      }
      event.available_frontend_locales.forEach(locale => {
        if (page.path_name_translations[locale]) {
          newPathNameTranslations[locale] = `${page.path_name_translations[locale]}-${n}`;
          currentTranslations[locale] = websitePages.map(websitePage => websitePage.path_name_translations[locale]).filter(e => e);
        }
      });
      if (websitePages.find(websitePage => websitePage.name == newName ||
        websitePage.path_name == newPathname ||
        websitePage.filename == newFilename ||
        event.available_frontend_locales.some(locale => currentTranslations[locale] && currentTranslations[locale].includes(newPathNameTranslations[locale]))
      )) {
        n += 1;
      } else {
        foundN = true;
      }
    }

    return { name: newName, path_name: newPathname, path_name_translations: newPathNameTranslations };
  }

  changeStandardField(field) {
    return (e) => {
      this.setState({ [field]: e.target.value });
    };
  }

  changeScheduleDisplayField(field, value) {
    this.setState({ [field]: value });
  }

  changeCheckbox(field) {
    return (e) => {
      this.setState({ [field]: e.target.checked });
    };
  }

  onChangeLinkedToAccesspointCheckbox(e) {
    const { linked_accesspoint_id } = this.state;
    const { checked } = e.target;

    this.setState({
      "linked_to_accesspoint": checked,
      "linked_accesspoint_id": checked ? linked_accesspoint_id : null
    });
  }

  onChangeVisibilitySettings(e) {
    const name = e.target.name;
    const value = e.target.value;
    let newState = { conditional_display: true, is_private: false, public_only: false, segments: [{}] };

    if (name == "conditional_display") newState.conditional_display = e.target.checked;
    if (value == "private") newState.is_private = true;
    if (value == "public_only") newState.public_only = true;

    this.setState(newState);
  }

  onSelectedSegmentChange(fieldName) {
    return (segment) => {
      const segments = segment ? [{ segment_id: segment._id }] : [{}];
      this.setState({ [fieldName]: segments });
    };
  }

  onChangeResourceBasedCheckbox(e) {
    const resourceBased = e.target.checked;
    const resourceName = resourceBased ? "guest" : "";

    this.setState({ resource_based: resourceBased, resource_name: resourceName });
  }

  onChangeHomepageCheckbox(e) {
    this.setState({ is_homepage: e.target.checked });
  }

  onSelectLinkedAccesspointId(data) {
    this.setState({ linked_accesspoint_id: data[0]._id });
  }

  onDateChange(dateFieldName, date) {
    const newDate = date ? moment(date).format(MOMENT_DATE_FORMAT) : null;
    this.setState({ [dateFieldName]: newDate });
  }

  prefillPathName(e) {
    const { path_name } = this.state;

    if (path_name == "") {
      const normalizePath = e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      const newPathName = normalizePath.replace(/[^a-zA-Z0-9]+/g, "-");

      this.setState({ path_name: newPathName });
    }
  }

  savePage(e) {
    e.preventDefault();
    const { onSubmit, mode } = this.props;
    onSubmit(this.filterPageParamsFrom(this.state, mode), mode);
  }

  hideView() {
    const { hide } = this.props;
    hide();
  }

  renderLayoutsOptions() {
    const { layouts } = this.props;
    return layouts && layouts.map(layout => {
      return <option value={layout._id} key={layout._id}>{translatedTextFromJson(layout, "name")}</option>;
    });
  }

  renderPageTemplatesOptions() {
    const { templates } = this.props;
    return templates && templates.filter(t => t.filename.match(/page\.[a-z._-]+/)).map(template => {
      return <option value={template._id} key={template._id}>{translatedTextFromJson(template, "name")}</option>;
    });
  }

  renderResourceNameOptions() {
    const resources = ["guest", "session", "meeting", "guest_product", "blog_article"];
    return resources.map(resourceName => {
      return <option value={resourceName} key={resourceName}>{I18n.t(`react.website.resource_name_${resourceName}`)}</option>;
    });
  }

  renderPwaCacheStrategiesOptions() {
    return PWA_CACHE_STRATEGIES.map(strategy => {
      return <option value={strategy} key={strategy}>{I18n.t(`react.website.pwa_cache_strategy_${strategy}`)}</option>;
    });
  }

  renderSegmentsPicker(segmentField, basedOnField, displayedOnValue, label) {
    const { savedSearches } = this.props;
    const { [segmentField]: segmentsValue, [basedOnField]: basedOnFieldValue } = this.state;
    const hiddenClass = basedOnFieldValue == displayedOnValue ? "" : "d-none";
    const segment = segmentsValue && segmentsValue[0];
    const segmentId = segment && segment.segment_id;

    return <div key="segments-picker" className={hiddenClass}>
      <div className="mb-3">
        <label className="form-label">{ label }</label>
        <div>
          <SegmentPicker
            onSelectSegment={this.onSelectedSegmentChange(segmentField) }
            defaultSegmentId={segmentId}
            segments={savedSearches}
          />
        </div>
      </div>
    </div>;
  }

  renderGuestProductCollectionResourcePicker() {
    const { resource_based, resource_name, guest_product_collection_id } = this.state;
    if (!resource_based || resource_name !== "guest_product") return null;

    return <GuestProductCollectionPicker
      key={ "guest_product_collection_id" }
      value={guest_product_collection_id}
      label={I18n.t("react.website.collection_picker_label")}
      helpMessage={I18n.t("react.website.collection_picker_help_message")}
      onChange={(values) => this.setState({ guest_product_collection_id: values[0] })}
    />;
  }

  renderDatePicker() {
    const { online_start_date, online_end_date, online_schedule_type, evaluated_online_start_date,
      evaluated_online_end_date } = this.state;

    return <ScheduleDisplay
      key={"schedule-display"}
      startDate={online_start_date}
      startDateEvaluated={evaluated_online_start_date}
      endDate={online_end_date}
      endDateEvaluated={evaluated_online_end_date}
      scheduleType={online_schedule_type}
      updateSettingsData={(field, value) => this.changeScheduleDisplayField(field, value)}
    />;
  }

  renderVisibilitySettings() {
    const { is_private, public_only, conditional_display } = this.state;
    return <ConditionalDisplay
      key="visibility-settings"
      isPrivate={is_private}
      isPublicOnly={public_only}
      conditionalDisplay={conditional_display || is_private || public_only}
      onChangeVisibilitySettings={this.onChangeVisibilitySettings}
    />;
  }

  renderLinkedAccesspointOption() {
    if (!isEnabled(CHECKIN)) return null;

    const { linked_to_accesspoint, linked_accesspoint_id } = this.state;

    return (
      <div key="linked-accesspoint-option">
        <div className="form-check mb-0">
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input" checked={linked_to_accesspoint} onChange={ this.onChangeLinkedToAccesspointCheckbox } /> { I18n.t("react.website.linked_to_accesspoint") }
          </label>
        </div>
        { linked_to_accesspoint &&
          <div className="mt-3">
            <AccesspointSearchDropdown onSelectAccesspoint={this.onSelectLinkedAccesspointId} selectedAccesspointsIds={linked_accesspoint_id} />
          </div>
        }
      </div>
    );
  }

  fieldError(field) {
    const { pageErrors } = this.props;

    if (!pageErrors[field]) return null;

    return pageErrors[field];
  }

  renderFieldError(field) {
    const error = this.fieldError(field);
    if (!error) return null;

    return <span className="text-danger" style={{ "marginTop": "5px", "display": "inline-block" }}>{ error }</span>;
  }

  renderSeoWarning() {
    return <div className="alert alert-warning mt-3 mb-0">
      <i className="fa-regular fa-triangle-exclamation" /> { I18n.t("react.website.page_seo_warning") }
    </div>;
  }

  renderHomepageOption() {
    const { is_homepage, resource_based, online, conditional_display, path_name } = this.state;

    if (FORM_PAGE_REGEX.test(path_name) || CONFIRMATION_PAGE_REGEX.test(path_name)) return;
    if (resource_based || !online || conditional_display) return;

    const isCurrentlyHomepage = this.props.page ? this.props.page.is_homepage : is_homepage;

    return <div key="is-homepage-option">
      <div className="form-check">
        <label className="form-check-label">
          <input type="checkbox" className="form-check-input" checked={is_homepage} disabled={isCurrentlyHomepage} onChange={ this.onChangeHomepageCheckbox } /> { I18n.t("react.website.homepage_option_label") }
        </label>
      </div>
    </div>;
  }

  renderResourceOptions() {
    const { resource_based, resource_name, path_name } = this.state;

    if (FORM_PAGE_REGEX.test(path_name) || CONFIRMATION_PAGE_REGEX.test(path_name)) return;

    return <div key="resource-options">
      <div className="form-check mb-3">
        <label className="form-check-label">
          <input type="checkbox" className="form-check-input" checked={resource_based} onChange={ this.onChangeResourceBasedCheckbox } /> { I18n.t("react.website.resource_based") }
        </label>
        <div className="form-text">{ I18n.t("react.website.page_path_with_id") }</div>
      </div>
      <div className={classNames({ "mb-3": true, "d-none": !resource_based })}>
        <label className="form-label">{ I18n.t("react.website.resource_name") }</label>
        <select className="form-select" value={resource_name || ""} onChange={ this.changeStandardField("resource_name") }>
          { this.renderResourceNameOptions() }
        </select>
      </div>
      { this.renderSegmentsPicker("guests_segments", "resource_name", "guest", I18n.t("react.website.guests_based_segment_filter")) }
      { this.renderGuestProductCollectionResourcePicker() }
    </div>;
  }

  viewTitle() {
    const { mode } = this.props;

    if (mode == "edit") {
      return I18n.t("react.website.edit_page");
    } else if (mode == "duplicate") {
      return I18n.t("react.website.duplicate_page");
    } else {
      return I18n.t("react.website.new_page");
    }
  }

  render() {
    const { mode, isSubmitting, pwaEnabled, seoEnabled, event } = this.props;

    if (!mode) {
      return <div><div className="website-page-form"></div></div>;
    }

    const {
      name,
      path_name,
      online,
      hide_on_app,
      seo_title,
      seo_description,
      page_template_id,
      layout_id,
      locked,
      pwa_cache_strategy,
      path_name_translations
    } = this.state;

    const buttonLabel = mode == "edit" ? I18n.t("react.website.save_page") : I18n.t("react.website.create_page");

    return (
      <div>
        <div className="website-page-form in">
          <div className="sidebar-header">
            <p className="lead">
              <a onClick={ this.hideView } className="btn-back mr-5 text-center"><i className="fa-regular fa-angle-left"></i></a>
              { this.viewTitle() }
            </p>
          </div>

          <div className="sidebar-body">
            <h5>{ I18n.t("react.website.page_main_config") }</h5>
            <div className="card">
              <div className="card-body">
                <div className="mb-3">
                  <label className="form-label">{ I18n.t("react.website.page_name") }</label>
                  <input type="text" className="form-control" value={name || ""} onChange={ this.changeStandardField("name") } onBlur={ this.prefillPathName } />
                  { this.renderFieldError("name") }
                </div>
                <>
                  <TranslatableInput
                    label={I18n.t("react.website.page_path_name")}
                    value={path_name || ""}
                    error={this.fieldError("path_name")}
                    preventTranslations={locked}
                    availableLocales={event.available_frontend_locales}
                    translationsLabel={I18n.t("react.website.path_name_translations")}
                    translationsValues={path_name_translations}
                    translationErrors={this.fieldError("path_name_translations")}
                    onChange={(path_name) => this.setState({ path_name })}
                    disabled={locked}
                    onTranslationChanged={(locale, value) => {
                      this.setState({
                        path_name_translations: { ...path_name_translations, [locale]: value }
                      });
                    }}
                  />
                </>
                {this.renderHomepageOption()}
                <div className="mb-3">
                  <label className="form-label">{ I18n.t("react.website.layout") }</label>
                  <select className="form-select" value={layout_id || ""} onChange={ this.changeStandardField("layout_id") }>
                    { this.renderLayoutsOptions() }
                  </select>
                </div>
                { mode != "duplicate" && [
                  path_name != "index" && <div key="page-template-id" className="mb-3">
                    <label className="form-label">{ I18n.t("react.website.page_template") }</label>
                    <select className="form-select" value={page_template_id || ""} onChange={ this.changeStandardField("page_template_id") }>
                      { this.renderPageTemplatesOptions() }
                    </select>
                  </div>,
                  this.renderResourceOptions(),
                  this.renderLinkedAccesspointOption(),
                  pwaEnabled && <div key="pwa-cache-strategy" className="mt-3">
                    <label className="form-label">{I18n.t("react.website.pwa_cache_strategy")}</label>
                    <select className="form-select" value={pwa_cache_strategy} onChange={this.changeStandardField("pwa_cache_strategy")}>
                      {this.renderPwaCacheStrategiesOptions()}
                    </select>
                  </div>
                ] }
              </div>
            </div>

            <h5>{ I18n.t("react.website.visibility") }</h5>
            <div className="card">
              <div className="card-body">
                <div className="form-check">
                  <label className="form-check-label">
                    <input type="checkbox" className="form-check-input" checked={online} onChange={ this.changeCheckbox("online") } /> { I18n.t("react.website.page_online") }
                  </label>
                </div>
                {online && this.renderDatePicker() }
                {[
                  this.renderVisibilitySettings(),
                  this.renderSegmentsPicker("segments", "is_private", true, I18n.t("react.website.private_segment_filter"))
                ]}
                { isEnabled(MOBILE_APP) &&
                  <div className="form-check">
                    <label className="form-check-label">
                      <input type="checkbox" className="form-check-input" checked={!!hide_on_app} onChange={ this.changeCheckbox("hide_on_app") } /> { I18n.t("react.website.page_hide_on_app") }
                    </label>
                  </div>
                }
              </div>
            </div>

            { isEnabled(SEO) &&
              <Fragment>
                <h5>{ I18n.t("react.website.page_seo") }</h5>
                <div className="card">
                  <div className="card-body">
                    <div className="mb-3">
                      <label className="form-label">{ I18n.t("react.website.page_seo_title") }</label>
                      <input type="text" className="form-control" value={seo_title || ""} onChange={ this.changeStandardField("seo_title") } />
                    </div>
                    <div>
                      <label className="form-label">{ I18n.t("react.website.page_seo_description") }</label>
                      <input type="text" className="form-control" value={seo_description || ""} onChange={ this.changeStandardField("seo_description") } />
                    </div>
                    { seoEnabled || this.renderSeoWarning() }
                  </div>
                </div>
              </Fragment>
            }
          </div>
        </div>

        <div className="sidebar-footer">
          <Button variant="primary" onClick={ this.savePage } disabled={ isSubmitting }>{ buttonLabel }</Button>
        </div>
      </div>
    );
  }
}

export default WebsitePageForm;

WebsitePageForm.defaultProps = {
  mode: "new",
  defaultState: {
    _id: null,
    name: "",
    path_name: "",
    path_name_translations: {},
    online: true,
    online_start_date: null,
    online_end_date: null,
    evaluated_online_start_date: null,
    evaluated_online_end_date: null,
    seo_title: "",
    seo_description: "",
    page_template_id: "",
    layout_id: "",
    locked: false,
    segments: [],
    conditional_display: false,
    is_private: false,
    public_only: false,
    resource_based: false,
    resource_name: "",
    online_schedule_type: "",
    guest_product_collection_id: null,
    guests_segments: [],
    linked_to_accesspoint: false,
    linked_accesspoint_id: null,
    pwa_cache_strategy: PWA_CACHE_STRATEGIES[0],
    is_homepage: false,
  }
};
