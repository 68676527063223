import { Purpose } from "../../../types/Purpose";

interface Props {
  purpose?: Purpose;
  onChange: (purpose: Purpose) => void;
  removePurpose: (purpose: Purpose) => void;
}

const i18n = (key: string, opts = {}): string => {
  return I18n.t(`react.consent_notice.purpose_form.${key}`, opts);
};

const PurposeForm: React.FC<Props> = ({
  purpose,
  onChange,
  removePurpose
}) => {
  return <>
    <div className="card">
      <div className="card-body">
        <div className="mb-3">
          <label className="form-label">{i18n("name")}</label>
          <input type="text" className="form-control" value={purpose.name} onChange={(e): void => {
            onChange({ ...purpose, name: e.target.value });
          }}/>
        </div>
        <div className="mb-3">
          <label className="form-label">{i18n("description")}</label>
          <textarea className="form-control" value={purpose.description} onChange={(e): void => {
            onChange({ ...purpose, description: e.target.value });
          }}/>
        </div>

        <button className="float-end btn btn-secondary" onClick={(e): void => {
          e.preventDefault();
          removePurpose(purpose);
        }}>
          <i className="fa-regular fa-trash-can"></i>
        </button>
      </div>
    </div>
  </>;
};

export default PurposeForm;
