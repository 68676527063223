import { Component } from "react";

class ExportLine extends Component {
  render() {
    const { export_data } = this.props;
    let downloadLink = "";
    if (export_data.file_uploaded) {
      downloadLink = <a href={export_data.file_url} className="btn btn-primary btn-sm"> { I18n.t("react.exports_list.download") }</a>;
    }
    return (
      <tr>
        <td> { export_data.translated_type } </td>
        <td> { export_data.requester_email } </td>
        <td> { export_data.translated_created_at } </td>
        <td> { export_data.translated_status } </td>
        <td>
          <div className="progress">
            <div className="progress-bar" role="progressbar" aria-valuenow="{ export_data.progress }" aria-valuemin="0" aria-valuemax="100" style={{ width: export_data.progress + "%" }}></div>
          </div>
        </td>
        <td> { downloadLink } </td>
      </tr>
    );
  }
}

export default ExportLine;

