import { ActionTypes, CALL_API } from "../constants/Constants";
import { stringify } from "querystring";

export function fetchWebsite(eventId, options = {}) {
  const { DEV_NULL, RECEIVE_WEBSITE_SUCCESS, RECEIVE_WEBSITE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_WEBSITE_SUCCESS, RECEIVE_WEBSITE_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/website.json?${stringify(options)}`
    }
  };
}

export function fetchWebsiteMenus(eventId) {
  const { DEV_NULL, RECEIVE_WEBSITE_MENUS_SUCCESS, RECEIVE_WEBSITE_MENUS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_WEBSITE_MENUS_SUCCESS, RECEIVE_WEBSITE_MENUS_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/website/menus.json`
    }
  };
}

export function updateWebsite(eventId, websiteParams = {}, sendFile = false, redirectTo = null, notificationsOptions = {}) {
  const { UPDATE_WEBSITE_REQUEST, UPDATE_WEBSITE_SUCCESS, UPDATE_WEBSITE_ERROR } = ActionTypes;
  return {
    [CALL_API]: {
      types: [UPDATE_WEBSITE_REQUEST, UPDATE_WEBSITE_SUCCESS, UPDATE_WEBSITE_ERROR],
      method: "PUT",
      body: sendFile ? websiteParams : { website: websiteParams },
      sendFile,
      endpoint: `/api/v1/events/${eventId}/website.json`,
      redirectTo,
      notificationsOptions
    }
  };
}

export function disableWebsite(eventId, redirectTo = null) {
  const { DEV_NULL, DISABLE_WEBSITE_SUCCESS, DISABLE_WEBSITE_ERROR } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, DISABLE_WEBSITE_SUCCESS, DISABLE_WEBSITE_ERROR],
      method: "PUT",
      body: {},
      endpoint: `/api/v1/events/${eventId}/website/disable.json`,
      redirectTo
    }
  };
}

export function fetchWebsiteSectionTypes(eventId, builderType) {
  const { DEV_NULL, RECEIVE_SECTION_TYPES_SUCCESS, RECEIVE_SECTION_TYPES_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_SECTION_TYPES_SUCCESS, RECEIVE_SECTION_TYPES_FAILURE],
      method: "GET",
      body: { builder_type: builderType },
      endpoint: `/api/v1/events/${eventId}/website/section_types.json`
    },
    builderType
  };
}

export function fetchWebsitePageTemplates(eventId) {
  const { DEV_NULL, RECEIVE_WEBSITE_PAGE_TEMPLATES_SUCCESS, RECEIVE_WEBSITE_PAGE_TEMPLATES_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_WEBSITE_PAGE_TEMPLATES_SUCCESS, RECEIVE_WEBSITE_PAGE_TEMPLATES_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/website/page_templates.json`
    }
  };
}

export function fetchWebsiteLayouts(eventId) {
  const { DEV_NULL, RECEIVE_WEBSITE_LAYOUTS_SUCCESS, RECEIVE_WEBSITE_LAYOUTS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_WEBSITE_LAYOUTS_SUCCESS, RECEIVE_WEBSITE_LAYOUTS_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/website/layouts.json`
    }
  };
}

export function duplicateWebsite(sourceEventId, targetEventId) {
  const { DUPLICATE_WEBSITE_PENDING, DUPLICATE_WEBSITE_SUCCESS, DUPLICATE_WEBSITE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DUPLICATE_WEBSITE_PENDING, DUPLICATE_WEBSITE_SUCCESS, DUPLICATE_WEBSITE_FAILURE],
      method: "POST",
      body: { target_event_id: targetEventId },
      endpoint: `/api/v1/events/${sourceEventId}/website/duplicate.json`
    }
  };
}

export function updatePageSections(pageName, pageSections) {
  return {
    type: ActionTypes.UPDATE_PAGE_SECTIONS,
    pageKey: pageName,
    objects: pageSections
  };
}

export function updateSection(sectionKey, sectionSettings, sectionChanges) {
  return {
    type: ActionTypes.UPDATE_SECTION,
    sectionKey: sectionKey,
    settings: sectionSettings,
    changes: sectionChanges
  };
}

export function sendChangesNotification() {
  return {
    type: ActionTypes.RECEIVE_WEBSITE_CHANGES
  };
}

export function createNewSection(sectionKey, sectionData, pageFilename) {
  return {
    type: ActionTypes.CREATE_NEW_SECTION,
    sectionKey,
    sectionData,
    pageFilename
  };
}

export function removeSection(sectionKey, pageFilename) {
  return {
    type: ActionTypes.REMOVE_SECTION,
    sectionKey,
    pageFilename
  };
}

export function createNewBlock(blockKey, blockData, sectionKey) {
  return {
    type: ActionTypes.CREATE_NEW_BLOCK,
    blockKey,
    blockData,
    sectionKey
  };
}

export function removeBlock(blockKey, sectionKey) {
  return {
    type: ActionTypes.REMOVE_BLOCK,
    blockKey,
    sectionKey
  };
}

export function addPageToSettings(pageFilename) {
  return {
    type: ActionTypes.ADD_PAGE_TO_SETTINGS,
    pageFilename
  };
}

export function removePageFromSettings(pageFilename) {
  return {
    type: ActionTypes.REMOVE_PAGE_FROM_SETTINGS,
    pageFilename
  };
}

export function updateAppHomeScreen(settings, changes) {
  return {
    type: ActionTypes.UPDATE_APP_HOME_SCREEN,
    settings: settings,
    changes: changes
  };
}

export function reloadTheme(eventId, templateProjectRoot, options = {}) {
  const { RELOAD_WEBSITE_THEME_REQUEST, RELOAD_WEBSITE_THEME_SUCCESS, RELOAD_WEBSITE_THEME_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [RELOAD_WEBSITE_THEME_REQUEST, RELOAD_WEBSITE_THEME_SUCCESS, RELOAD_WEBSITE_THEME_FAILURE],
      method: "PUT",
      body: { template_project_root: templateProjectRoot },
      endpoint: `/api/v1/events/${eventId}/website/reload_theme.json`,
      ...options
    }
  };
}

export function duplicatePageSection(eventId, pageSectionKey, targetPageId) {
  const { DUPLICATE_WEBSITE_PAGE_SECTION_PENDING, DUPLICATE_WEBSITE_PAGE_SECTION_SUCCESS, DUPLICATE_WEBSITE_PAGE_SECTION_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DUPLICATE_WEBSITE_PAGE_SECTION_PENDING, DUPLICATE_WEBSITE_PAGE_SECTION_SUCCESS, DUPLICATE_WEBSITE_PAGE_SECTION_FAILURE],
      method: "POST",
      body: { key: pageSectionKey, target_page_id: targetPageId },
      endpoint: `/api/v1/events/${eventId}/website/page_sections/duplicate.json`
    },
    pageId: targetPageId
  };
}
