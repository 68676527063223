function _getAcl() {
  const globalProps = window.ReactGlobalProps || {};
  return globalProps && globalProps.user_role && globalProps.user_role.acl;
}

function _isAuthorized(rightType, authorizationType) {
  const acl = _getAcl();
  return acl && acl[rightType] && acl[rightType][authorizationType];
}

export function getAcl() {
  return _getAcl();
}

export function isAuthorized(rightType, authorizationType) {
  return _isAuthorized(rightType, authorizationType);
}

export function redirectIfUnauthorized(rightType, authorizationType) {
  if (!_isAuthorized(rightType, authorizationType)) window.location.href = "/?redirection_reason=unauthorized";
}

export function isSuperAdmin() {
  return window.ReactGlobalProps && window.ReactGlobalProps.super_admin;
}

export function authorizedCategories(categories) {
  const acl = getAcl();
  if (!acl) return [];
  if (acl.guest.filtered_guest_category_ids == "all") return categories;

  return categories.filter(category => acl.guest.filtered_guest_category_ids.includes(category.id));
}
