import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { urlEventId } from "../utils/pathUtils";
import ErrorMessage from "../components/shared/ErrorMessage.react";
import ColumnsFilter from "../components/ColumnsFilter.react";
import Filter from "../components/Filter.react";
import Loader from "../components/shared/Loader.react";
import { fetchEventGuestFields } from "../actions/GuestFieldsActionCreators";
import { changeSelectedColumnsSetId, createColumnsSet, updateColumnsSet, deleteColumnsSet } from "../actions/ColumnsSetsActionCreators";
import { fetchDocumentTemplates } from "../actions/DocumentTemplatesActionCreators";
import { fetchAccesspoints } from "../actions/AccesspointActionCreators";
import { fetchThematics } from "../actions/ThematicsActionCreators";
import {
  COLUMN_SECTION_STANDARD_TYPE,
  COLUMN_SECTION_GUEST_FIELD_TYPE,
  COLUMN_SECTION_LINK_TYPE,
  COLUMN_SECTION_ACCESSPOINT_TYPE,
  COLUMN_SECTION_QUARTILES_THEMATIC_TYPE,
  COLUMN_SECTION_STANDARD_SCORE_THEMATIC_TYPE
} from "../constants/Constants";
import { enabledStandardFields, availableStandardFieldColumns, availableGuestFieldColumns, availableLinkColumns,
  availableAccesspointColumns, thematicQuartileColumns, thematicStdScoreColumns } from "../utils/columnsSetUtils";

const SECTIONS = [COLUMN_SECTION_STANDARD_TYPE, COLUMN_SECTION_GUEST_FIELD_TYPE, COLUMN_SECTION_LINK_TYPE, COLUMN_SECTION_ACCESSPOINT_TYPE, COLUMN_SECTION_QUARTILES_THEMATIC_TYPE, COLUMN_SECTION_STANDARD_SCORE_THEMATIC_TYPE];

const listBodyHeight = "100vh";
const toolbarHeight = "54px";
const searchBarHeight = "50px";
const buttonsBarHeight = "75px";
const extraHeight = "65px"; // padding + help message

const ColumnsSetsManager: React.FC = () => {
  const [search, setSearch] = useState(null);
  const event = useSelector((state: any) => state.event);
  const guestFields = useSelector((state: any) => state.guestFields);
  const documentTemplates = useSelector((state: any) => state.documentTemplates.data);
  const accesspoints = useSelector((state: any) => state.accesspoints);
  const columnsSets = useSelector((state: any) => state.columnsSets);
  const thematics = useSelector((state: any) => state.thematics.items);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!guestFields.fetched) {
      dispatch(fetchEventGuestFields(urlEventId()));
    }
    if (!documentTemplates) {
      dispatch(fetchDocumentTemplates(urlEventId()));
    }
    if (!accesspoints.fetched) {
      dispatch(fetchAccesspoints(urlEventId()));
    }
    if (!thematics) {
      dispatch(fetchThematics(urlEventId()));
    }
  }, []);

  const onFilterChange = (result): void => {
    const { searchValue, selectedItemIds } = result;

    if (searchValue !== undefined) { // we need to enter this condition when "" (which is falsy)
      setSearch(searchValue.length > 0 ? searchValue : null);
    }

    if (selectedItemIds) {
      dispatch(changeSelectedColumnsSetId(selectedItemIds[0]));
    }
  };

  const onSaveColumnsSet = (name: string, columns: string[], columnsSetId: string = null): void => {
    if (columnsSetId) {
      dispatch(updateColumnsSet(urlEventId(), columnsSetId, { columns }));
    } else {
      dispatch(createColumnsSet(urlEventId(), { name, columns }));
    }
  };

  const onDeleteColumnsSet = (columnsSetId: string): void => {
    dispatch(deleteColumnsSet(urlEventId(), columnsSetId));
  };

  const isColumnsFilterDisplayable = (): boolean => {
    return guestFields.fetched && accesspoints.fetched && documentTemplates && thematics;
  };

  const { errors, pendingWriteRequest, selectedColumnsSetId } = columnsSets;
  const standardFields = enabledStandardFields();
  const standardFieldCells = availableStandardFieldColumns(standardFields, event);
  const guestFieldCells = availableGuestFieldColumns(guestFields.guestFields, standardFields);
  const linkCells = availableLinkColumns(documentTemplates);
  const accesspointCells = availableAccesspointColumns(accesspoints.data);
  const thematicQuartileCells = thematicQuartileColumns(event, thematics);
  const thematicStdScoreCells = thematicStdScoreColumns(event, thematics);

  const selectedColumnsSet = columnsSets.data.find(columnsSet => columnsSet._id === selectedColumnsSetId);
  const paneColsHeight = `calc(${listBodyHeight} - ${toolbarHeight})`;
  const exportColsHeight = `calc(${listBodyHeight} - ${toolbarHeight} - ${buttonsBarHeight} - ${extraHeight})`;

  return <div className="row" style={{ marginRight: 0 }}>
    <div className="col-3" style={{ height: paneColsHeight, overflow: "hidden", borderRight: "1px solid #E3E3E3", paddingRight: 0 }}>
      <Filter
        translationKey="columns_set"
        items={columnsSets.data || []}
        selectedItemIds={[selectedColumnsSetId]}
        showCells={false}
        hasSelectAll={false}
        multipleSelect={false}
        sortItems={false}
        selectedFirst={false}
        itemIdKey="_id"
        onChange={onFilterChange}
        searchValue={search}
        allowUnselect={false}
        hasSearch={true}
        maxHeight={`calc(${listBodyHeight} - (${toolbarHeight} + ${searchBarHeight})`}
      />
    </div>
    <div className="col-9" style={{ height: paneColsHeight, overflow: "auto", paddingTop: "10px", paddingBottom: "20px" }}>
      <ErrorMessage errors={errors} model="columns_set" />
      { isColumnsFilterDisplayable() ?
        <ColumnsFilter
          sections={SECTIONS}
          standardFields={standardFieldCells}
          guestFields={guestFieldCells}
          links={linkCells}
          accesspoints={accesspointCells}
          helpText={I18n.t("react.reports.columns_set.help")}
          event={event}
          eventId={urlEventId()}
          saveColumnsSet={onSaveColumnsSet}
          deleteColumnsSet={onDeleteColumnsSet}
          savingColumnsSet={pendingWriteRequest}
          initialColumns={null}
          thematics={thematics}
          quartile_thematics={thematicQuartileCells}
          std_thematics={thematicStdScoreCells}
          selectedColumnsSet={selectedColumnsSet}
          columnsSetPicker={false}
          height={exportColsHeight}
        />
        : <Loader size="xlarge" inline={false} containerHeight={exportColsHeight} />
      }
    </div>
  </div>;
};

export default ColumnsSetsManager;
