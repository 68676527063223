import { Component, MouseEvent } from "react";
import SectionView from "../templates_builder/SectionView.react";
import AppHomeScreenSchema from "../../../lib/builders/app_home_screen_schema.json";
import { Event } from "../../types/Event";
import { GuestField } from "../../types/GuestField";
import { Website } from "../../types/Website";
import { SavedSearch } from "../../types/SavedSearch";
import { WebsitePage } from "../../types/WebsitePage";

interface Props {
  isShown: boolean;
  hasChanges: boolean;
  event: Event;
  website: Website;
  websitePages: WebsitePage[];
  guestFields: GuestField[];
  segments: SavedSearch[];

  toggleView(): void;
  detectChanges(): void;
  sendChanges(_key, newConfig: any, changes: any): void;
  removeBlock(blockKey: string, sectionKey: string): void;
  displayTranslationsPane(e: MouseEvent<HTMLElement>, id: string, table: string, menuKey: string): void;
}

interface State {
  saveButtonForNewAppEnabled: boolean;
}

class MobileAppHomeScreen extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    [
      "close",
    ].forEach(fn => this[fn] = this[fn].bind(this));
    this.state = { saveButtonForNewAppEnabled: false };
  }

  componentDidUpdate(): void {
    const { website, isShown, sendChanges } = this.props;
    const { saveButtonForNewAppEnabled } = this.state;

    if (!isShown || saveButtonForNewAppEnabled || website.app_home_screen_config?.created_at) return;

    this.setState({ saveButtonForNewAppEnabled: true });
    sendChanges(null, this.configuration(), this.configuration());
  }

  close(): void {
    this.props.toggleView();
  }

  configuration(): any {
    const { website } = this.props;

    if (!website.app_home_screen_config) {
      return { "settings": {} };
    }

    return website.app_home_screen_config;
  }

  render(): JSX.Element {
    const { isShown, hasChanges, detectChanges, sendChanges, removeBlock, event, websitePages,
      displayTranslationsPane, website, guestFields, segments } = this.props;

    if (!isShown) {
      return <SectionView />;
    }

    return (
      <SectionView
        sectionKey="app_home_screen"
        schema={AppHomeScreenSchema}
        configuration={this.configuration()}
        hide={this.close}
        detectChanges={detectChanges}
        sendChanges={sendChanges}
        removeBlock={removeBlock}
        deletable={false}
        builderType="website"
        noVisibilitySettings={true}
        event={event}
        guestFields={guestFields}
        websitePages={websitePages}
        hasChanges={hasChanges}
        withSidebar={true}
        displayBlocksVisibilitySettings={true}
        translationTableId={website.app_home_screen_config._id}
        segments={segments}
        displayTranslationsPane={displayTranslationsPane} />
    );
  }
}

export default MobileAppHomeScreen;
