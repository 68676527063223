import { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import { CreateNewAccesspointId } from "../../constants/Constants";

class CheckinpointOption extends Component {

  constructor(props) {
    super(props);
    ["labelChanged", "keyChanged", "labelOnBlur", "destroy", "renderAccesspoints", "_checkboxValueOnChanged", "checkboxTooltip"].forEach(item => {
      this[item] = this[item].bind(this);
    });
    const { itemOption } = props;
    this.state = {
      label: itemOption.label,
      key: itemOption.key,
      value: itemOption.value
    };
  }

  labelChanged(e) {
    this.setState({
      label: e.target.value
    });
  }

  keyChanged(e) {
    const { updateHandler, accesspoints, itemOption } = this.props;
    const accesspoint = accesspoints.find(accesspoint => { return accesspoint.id == e.target.value; });
    if (!accesspoint) {
      return; // hitting the pick a value in the select, just do nothing
    }
    const key = accesspoint.id;
    const label = accesspoint.display_name;
    updateHandler(Object.assign({}, itemOption, { key, label }));

    // don't need to change the state with this value
    if (CreateNewAccesspointId !== e.target.value) {
      this.setState({
        key: key,
        label: label
      });
    }
  }

  _checkboxValueOnChanged(e) {
    const { itemOption, checkboxValueOnChanged } = this.props;
    checkboxValueOnChanged(itemOption.key, e.target.checked);
  }

  labelOnBlur() {
    const { label } = this.state;
    const { itemOption, updateHandler } = this.props;
    if (itemOption.label === label) {
      return;
    }
    updateHandler(Object.assign({}, itemOption, { label }));
  }

  renderAccesspoints(disabledClass = "") {
    const { accesspoints } = this.props;
    const { key } = this.state;
    const options = accesspoints.map(accesspoint => {
      return (<option key={ accesspoint.id } value={ accesspoint.id }>{ accesspoint.name }</option>);
    });

    return (
      <select className={`form-select ${disabledClass}`} value={key} onChange={this.keyChanged}>
        <option key="default" value=""> { I18n.t("react.form_question_item.pick_an_accesspoint") }</option>
        {options}
      </select>
    );
  }

  destroy() {
    const { itemOption, destroyHandler } = this.props;
    destroyHandler(itemOption._id);
  }

  checkboxTooltip(checked) {
    const checkedMessage = checked ? I18n.t("react.form_checkbox_group.checked_by_default") : I18n.t("react.form_checkbox_group.unchecked_by_default");
    return (
      <Tooltip id="tooltip">{checkedMessage}</Tooltip>
    );
  }

  render() {
    const { allowDestroy, mode, shouldBeDisplayed, checked, checkboxValueOnBlur, radioStyle } = this.props;
    const { label } = this.state;
    const disabledClass = shouldBeDisplayed ? "" : "disabled";
    const destroyButton = (mode == "edit" && allowDestroy) ? (
      <a className="btn btn-secondary" onClick={this.destroy} style={ { cursor: "pointer" } }>
        <i className="fa-regular fa-close"></i>
      </a>
    ) : "";
    const accesspointsOptions = (mode == "edit") ? this.renderAccesspoints(disabledClass) : null;
    const inputType = radioStyle ? "radio" : "checkbox";

    return (
      <div className="row mb-10">
        <div className="col-8">
          <div className="input-group">
            <div className="btn btn-secondary">
              <OverlayTrigger placement="top" overlay={this.checkboxTooltip(checked)}>
                <input type={inputType} checked={checked} onChange={this._checkboxValueOnChanged} onBlur={checkboxValueOnBlur} className={`form-check-input ${disabledClass}`} />
              </OverlayTrigger>
            </div>
            <input type="text" className={`form-control ${disabledClass}`} value={label} onChange={this.labelChanged} onBlur={this.labelOnBlur}/>
          </div>
        </div>
        <div className="col-3">
          { accesspointsOptions }
        </div>
        <div className="col-1">
          { destroyButton }
        </div>
      </div>
    );
  }
}

CheckinpointOption.propTypes = {
  accesspoints: PropTypes.array.isRequired,
  itemOption: PropTypes.object.isRequired,
  updateHandler: PropTypes.func.isRequired,
  destroyHandler: PropTypes.func.isRequired
};

export default CheckinpointOption;
