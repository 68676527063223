import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { Dataset, toChartJsDataset } from "../../../types/ChartDataset";

interface Props {
  dataset: Dataset;
  comparedDataset?: Dataset;
}

const DATASET_OVERRIDE = {
  borderRadius: 5
};

const BarChart: React.FC<Props> = ({
  dataset,
  comparedDataset
}) => {
  const chartCanvas = useRef<HTMLCanvasElement>(null);
  const chart = useRef<Chart>(null);

  useEffect(() => {
    renderChart();
  }, [dataset, comparedDataset]);

  const renderChart = (): void => {
    if (chart.current) chart.current.destroy();

    const { yAxesValueFormatter } = dataset;
    let { labels } = dataset;

    const multiDatasets = !!comparedDataset;

    const datasets = [toChartJsDataset(dataset, multiDatasets)];
    if (multiDatasets) {
      datasets.push(toChartJsDataset(comparedDataset, multiDatasets));
      labels = dataset.multiDatasetsLabels.length > comparedDataset.multiDatasetsLabels.length ? dataset.multiDatasetsLabels : comparedDataset.multiDatasetsLabels;
    }

    const ctx = chartCanvas.current;

    chart.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels,
        datasets: datasets.map(dataset => ({ ...dataset, ...DATASET_OVERRIDE }))
      },
      options: {
        plugins: {
          legend: {
            display: !!comparedDataset,
            labels: {
              usePointStyle: true,
              pointStyle: "rectRounded",
            }
          },
          tooltip: {
            callbacks: {
              label: ({ raw }): string => {
                const value = multiDatasets ? raw["y"] : raw;
                return yAxesValueFormatter ? yAxesValueFormatter(value) : value.toString();
              }
            }
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: (tickValue): string => {
                return yAxesValueFormatter ? yAxesValueFormatter(tickValue) : tickValue.toString();
              }
            }
          },
          x: {
            grid: {
              display: false
            }
          }
        }
      },
    });
  };

  return <canvas ref={chartCanvas}></canvas>;
};

export default BarChart;
