export function jsonToFormData(data, baseKey) {
  const formData = new FormData();
  buildFormData(formData, data, baseKey);
  return formData;
}

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
    if (data instanceof Array) {
      for (var i = 0; i < data.length; i++) {
        if (typeof data[i] === "object") {
          buildFormData(formData, data[i], `${parentKey}[]`);
        } else {
          formData.append(`${parentKey}[]`, data[i]);
        }
      }
    } else {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    }
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
}