import { allEnabled, isEnabled } from "./featureSetUtils";
import { THEMATIC_SCORING } from "../constants/FeaturesSet";
import {
  StandardGuestFieldsInColumns,
  StandardLinkColumns,
  EngagementColumns,
  PAYMENT_CELL,
  CONTACT_MODAL_CELL
} from "../constants/Constants";
import { Event } from "../types/Event";
import { GuestField } from "../types/GuestField";
import { Thematic } from "../types/Thematic";
import { Accesspoint } from "../types/Accesspoint";
import { DocumentTemplate } from "../types/DocumentTemplate";

function availableStandardFields(standardFields: string[], event: Event): string[] {
  return standardFields.filter((key: string) => {
    if (key === PAYMENT_CELL && !event.has_payment) {
      return false;
    } else if (key === CONTACT_MODAL_CELL && !event.account_contacts_enabled) {
      return false;
    } else if (EngagementColumns.includes(key) && !event.scoring_and_engagement_enabled) {
      return false;
    }
    return true;
  });
}

function thematicsEnabled(event: Event, thematics: Thematic[]): boolean {
  return event.scoring_and_engagement_enabled && thematics && isEnabled(THEMATIC_SCORING);
}

export function enabledStandardFields(): string[] {
  return Object.keys(StandardGuestFieldsInColumns).filter((key: string) => {
    return StandardGuestFieldsInColumns[key] ? allEnabled(StandardGuestFieldsInColumns[key].requiredFeatures) : true;
  });
}

export function availableStandardFieldColumns(fields: string[], event: Event): any[] {
  return availableStandardFields(fields, event).map(key => {
    return {
      key,
      sortableItemId: key,
      label: I18n.t(`mongoid.attributes.guest.${key}`)
    };
  });
}

export function availableGuestFieldColumns(guestFields: GuestField[], standardFields: string[]): any[] {
  return guestFields.filter((field: GuestField) => {
    return !standardFields.includes(field.key);
  }).map((field: GuestField) => {
    return {
      ...field,
      sortableItemId: field.key
    };
  });
}

export function availableLinkColumns(documentTemplates: DocumentTemplate[]): any[] {
  const standardLinkCells = Object.keys(StandardLinkColumns).filter((key: string) => {
    return allEnabled(StandardLinkColumns[key].requiredFeatures);
  }).map((identifier: string) => {
    return {
      identifier,
      sortableItemId: identifier,
      name: I18n.t(`mongoid.attributes.guest.${identifier}`)
    };
  });

  const documentTemplateCells = (documentTemplates || []).map((template: DocumentTemplate) => {
    return {
      ...template,
      identifier: template._id,
      sortableItemId: template._id
    };
  });

  return standardLinkCells.concat(documentTemplateCells);
}

export function availableAccesspointColumns(accesspoints: Accesspoint[]): any[] {
  return accesspoints.map((accesspoint: Accesspoint) => {
    return {
      ...accesspoint,
      sortableItemId: accesspoint._id
    };
  });
}

export function thematicQuartileColumns(event: Event, thematics: Thematic[]): any[] {
  if (!thematicsEnabled(event, thematics)) return [];

  return thematics.map((thematic: Thematic) => {
    return {
      ...thematic,
      sortableItemId: `quartile_${thematic._id}`,
      identifier: `quartile_${thematic._id}`
    };
  });
}

export function thematicStdScoreColumns(event: Event, thematics: Thematic[]): any[] {
  if (!thematicsEnabled(event, thematics)) return [];

  return thematics.map((thematic: Thematic) => {
    return {
      ...thematic,
      sortableItemId: `std_${thematic._id}`,
      identifier: `std_${thematic._id}`
    };
  });
}
