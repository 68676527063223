interface Props {
  foregroundColor?: string;
  backgroundColor?: string;
  count?: number;
  max?: number;
  percent?: number;
  subtitle: string;
}

const RADIUS = 550;

const HalfCircleCounter = ({ foregroundColor, backgroundColor, count, max, subtitle, percent }: Props): JSX.Element => {
  let percentToUse = percent || count / max;
  percentToUse = percentToUse > 1 ? 1 : percentToUse;
  const offset = Math.floor(RADIUS - RADIUS * percentToUse);
  return (
    <svg className="score" viewBox="-25 -25 400 250">
      <path d="M 175,175 m -175,0 a 175,175 0 1 1 350,0" stroke={backgroundColor} strokeWidth="20" fillOpacity="0"></path>
      <path d="M 175,175 m -175,0 a 175,175 0 1 1 350,0" strokeWidth="20" stroke={foregroundColor} fill="none" style={{ strokeDashoffset: offset, strokeDasharray: `${RADIUS},${RADIUS}` }} ></path>
      <text style={{ fontWeight: "bold", fontSize: "5.5em" }} x="50%" y="65%" dx="-25" textAnchor="middle" fill="#000">{ count }</text>
      <text style={{ fontWeight: "normal", fontSize: "2em", fill: "#999999" }} x="50%" y="80%" dx="-25" textAnchor="middle" fill="#000">{ subtitle }</text>
    </svg>
  );
};

HalfCircleCounter.defaultProps = {
  foregroundColor: "black",
  backgroundColor: "grey"
};

export default HalfCircleCounter;
