import { CALL_API, ActionTypes } from "../constants/Constants";

export function fetchCurrentSavedSearch(eventId, q) {
  const { CURRENT_SAVED_SEARCH_REQUEST, CURRENT_SAVED_SEARCH_SUCCESS, CURRENT_SAVED_SEARCH_FAILURE } = ActionTypes;
  const search_query = q || "";
  return {
    [CALL_API]: {
      types: [CURRENT_SAVED_SEARCH_REQUEST, CURRENT_SAVED_SEARCH_SUCCESS, CURRENT_SAVED_SEARCH_FAILURE],
      method: "GET",
      endpoint: `/events/${eventId}/saved_searches.json?q=${search_query}`
    }
  };
}

export function changeCurrentSavedSearch(savedSearch) {
  const { CHANGE_CURRENT_SAVED_SEARCH } = ActionTypes;
  return { type: CHANGE_CURRENT_SAVED_SEARCH, data: savedSearch };
}

export function clearCurrentSavedSearch() {
  const { CLEAR_CURRENT_SAVED_SEARCH } = ActionTypes;
  return { type: CLEAR_CURRENT_SAVED_SEARCH };
}

export function createSavedSearch(eventId, name, search_query, locale, redirectTo = null) {
  const { DEV_NULL, CREATE_SAVED_SEARCH_SUCCESS, CREATE_SAVED_SEARCH_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, CREATE_SAVED_SEARCH_SUCCESS, CREATE_SAVED_SEARCH_FAILURE],
      method: "POST",
      endpoint: `/${locale}/events/${eventId}/saved_searches.json`,
      body: { saved_search: { name, search_query } },
      redirectTo
    }
  };
}
export function updateSavedSearch(eventId, savedSearchId, savedSearchParams, locale, redirectTo = null, notificationsOptions = {}) {
  const { DEV_NULL, UPDATE_SAVED_SEARCH_SUCCESS, UPDATE_SAVED_SEARCH_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, UPDATE_SAVED_SEARCH_SUCCESS, UPDATE_SAVED_SEARCH_FAILURE],
      method: "PUT",
      endpoint: `/${locale}/events/${eventId}/saved_searches/${savedSearchId}.json`,
      body: { saved_search: savedSearchParams },
      redirectTo,
      notificationsOptions
    }
  };
}

export function fetchSavedSearch(eventId, savedSearchId, includeOptions = []) {
  const { DEV_NULL, SAVED_SEARCH_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, SAVED_SEARCH_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/events/${eventId}/saved_searches/${savedSearchId}.json`,
      body: includeOptions.length > 0 ? { include: includeOptions } : {}
    }
  };
}

export function clearSavedSearchCreation() {
  const { CREATE_SAVED_SEARCH_CLEAR } = ActionTypes;
  return { type: CREATE_SAVED_SEARCH_CLEAR };
}

export function deleteSavedSearch(eventId, id, name, forceDelete) {
  const { DEV_NULL, DELETE_SAVED_SEARCH_SUCCESS, DELETE_SAVED_SEARCH_FAILURE } = ActionTypes;
  const notifications = { notice: `${name} ${I18n.t("react.reports.report_deleted")}`, noticeType: "success" };
  return {
    [CALL_API]: {
      types: [DEV_NULL, DELETE_SAVED_SEARCH_SUCCESS, DELETE_SAVED_SEARCH_FAILURE],
      method: "DELETE",
      endpoint: `/events/${eventId}/saved_searches/${id}/delete_report`,
      notificationsOptions: notifications,
      body: { "saved_search": { "force_delete": forceDelete } }
    },
    id
  };
}

export function fetchSavedSearches(eventId, includeOptions = []) {
  const { DEV_NULL, FETCH_SAVED_SEARCH_SUCCESS, FETCH_SAVED_SEARCH_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, FETCH_SAVED_SEARCH_SUCCESS, FETCH_SAVED_SEARCH_FAILURE],
      method: "GET",
      endpoint: `/events/${eventId}/saved_searches.json`,
      body: includeOptions.length > 0 ? { include: includeOptions } : {}
    }
  };
}

export function sortSavedSearches(property, ascending) {
  return {
    type: ActionTypes.SORT_SAVED_SEARCHES,
    property,
    ascending
  };
}
