import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AtlasChart from "../../components/AtlasChart.react";
import HeaderStats from "../../components/dashboard/HeaderStats.react";
import { isEnabled } from "../../utils/featureSetUtils";
import DashboardNavTab from "../../components/dashboard/DashboardNavTab.react";
import { ONE_TO_ONE_CHAT_AND_CONNECTIONS, SCHEDULED_1TO1_MEETING, EXHIBITOR_MEETINGS } from "../../constants/FeaturesSet";
import DateRangePicker from "../../components/DateRangePicker.react";
import { buildFilter } from "../../utils/AtlasChartUtils";
import withDatabaseDefaultRegionRestriction from "../../components/shared/WithDatabaseDefaultRegionRestriction.react";

const MEETINGS_CHART_ID = "68020b52-eb9f-4999-85a2-5290d084a7e8";
const DIRECT_MESSAGES_CHART_ID = "634e756e-d3ad-44d1-87c3-cf034abbf6bb";

const i18n = (key, options = {}): string => {
  return I18n.t(`react.dashboard.networking.${key}`, options);
};

const NetworkingDashboard: React.FC = () => {
  const event = useSelector((state: any) => state.event);

  const [messagesCount, setMessagesCount] = useState(null);
  const onMessagesChartDataChanged = (data: { documents: any[] }): void => {
    setMessagesCount(data ? data.documents.reduce((partialSum, document) => partialSum + document.y, 0) : null);

  };

  const [meetingRequestsCount, setMeetingRequestsCount] = useState(null);
  const [confirmedMeetingRequestsCount, setConfirmedMeetingRequestsCount] = useState(null);
  useEffect(() => {
    if (!event._id) return;

    fetch(`/api/v1/events/${event._id}/accesspoints/meeting_requests_count.json`)
      .then(res => res.json())
      .then(result => {
        setMeetingRequestsCount(result.total);
        setConfirmedMeetingRequestsCount(result.confirmed);
      });
  }, [event._id]);

  const renderHeader = (): JSX.Element => {
    return <HeaderStats
      dataFetched={meetingRequestsCount !== null && messagesCount !== null}
      headers={
        [
          { i18nKey: "meeting_requests", value: meetingRequestsCount, nbCols: 4, hintI18nKey: "meeting_requests_hint" },
          { i18nKey: "confirmed_meeting_requests", value: confirmedMeetingRequestsCount, nbCols: 4, hintI18nKey: "confirmed_meeting_requests_hint" },
          { i18nKey: "messages_count", value: messagesCount, nbCols: 4, hintI18nKey: "messages_count_hint" }
        ]
      }
      tabKey="networking"
    />;
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const renderFilters = (): JSX.Element => {
    return <div className="mb-3">
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onDateRangeChanged={(start, end): void => { setStartDate(start); setEndDate(end); }}
      />
    </div>;
  };

  const buildCustomDateFilter = (field: string): any => {
    const filters = [
      { type: "startDate", field, value: startDate },
      { type: "endDate", field, value: endDate },
    ];

    return buildFilter(filters);
  };

  const renderMeetingsChart = (): JSX.Element => {
    if (!isEnabled(EXHIBITOR_MEETINGS) && !isEnabled(SCHEDULED_1TO1_MEETING)) return null;

    return <div className="card">
      <AtlasChart
        eventId={event._id}
        title={i18n("meetings_by_hour")}
        chartId={ MEETINGS_CHART_ID }
        height="400px"
        exportOptions={{
          columnsMapping: { hour: { documentKey: "x" }, count: { documentKey: "y", total: true } },
          type: "meetings_by_hour"
        }}
        customFilter={buildCustomDateFilter("start_date")}
      />
    </div>;
  };

  const renderDirectMessagesChart = (): JSX.Element => {
    if (!isEnabled(ONE_TO_ONE_CHAT_AND_CONNECTIONS)) return null;

    return <div className="card">
      <AtlasChart
        eventId={event._id}
        title={i18n("messages_by_day")}
        onChartDataChanged={ onMessagesChartDataChanged }
        chartId={ DIRECT_MESSAGES_CHART_ID }
        height="400px"
        exportOptions={{
          columnsMapping: { hour: { documentKey: "x" }, count: { documentKey: "y", total: true } },
          type: "messages_by_day"
        }}
        customFilter={buildCustomDateFilter("created_at")}
      />
    </div>;
  };

  if (!event._id) return null;

  return <div>
    <DashboardNavTab active="networking" event_has_exits={event.has_exits} />
    { renderHeader() }
    { renderFilters() }
    { renderDirectMessagesChart() }
    { renderMeetingsChart() }
  </div>;
};

export default withDatabaseDefaultRegionRestriction(NetworkingDashboard);
