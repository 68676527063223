import { ActionTypes, CALL_API } from "../constants/Constants";

export function fetchDocumentTemplates(eventId) {
  const { DOCUMENT_TEMPLATES_REQUEST, DOCUMENT_TEMPLATES_SUCCESS, DOCUMENT_TEMPLATES_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DOCUMENT_TEMPLATES_REQUEST, DOCUMENT_TEMPLATES_SUCCESS, DOCUMENT_TEMPLATES_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/document_templates.json`
    }
  };
}

export function fetchDocumentTemplatesFromEvent(eventId) {
  const { DEV_NULL, FETCH_DOCUMENT_TEMPLATES_FROM_EVENT_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, FETCH_DOCUMENT_TEMPLATES_FROM_EVENT_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/document_templates.json`
    },
    eventId
  };
}

export function updateDocumentSelectedId(selectedTemplateId) {
  return {
    type: ActionTypes.UPDATE_DOCUMENT_SELECTED_ID,
    data: selectedTemplateId
  };
}

export function fetchDocumentsPresetsList(eventId) {
  const { DEV_NULL, FETCH_DOCUMENT_TEMPLATES_PRESETS_LIST_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, FETCH_DOCUMENT_TEMPLATES_PRESETS_LIST_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/document_templates/presets_list.json`
    }
  };
}
