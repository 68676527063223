import { CALL_API, ActionTypes } from "../constants/Constants";

export function fetchEventAtlasJwtToken(eventId: string): any {
  const { FETCH_EVENT_ATLAS_JWT_TOKEN_REQUEST, FETCH_EVENT_ATLAS_JWT_TOKEN_SUCCESS, DEV_NULL } = ActionTypes;

  return {
    [CALL_API]: {
      types: [FETCH_EVENT_ATLAS_JWT_TOKEN_REQUEST, FETCH_EVENT_ATLAS_JWT_TOKEN_SUCCESS, DEV_NULL],
      method: "POST",
      endpoint: `/api/v1/events/${eventId}/mongodb_atlas_chart_jwt_token.json`,
      body: {}
    },
    eventId
  };
}
