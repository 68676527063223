import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { GuestProductCollectionField } from "../../types/GuestProductCollectionField";
import GuestProductCollectionSortableFields from "./GuestProductCollectionSortableFields.react";
import DefaultGuestProductCollectionFields from "./DefaultGuestProductCollectionFields.react";

interface Props {
  translationsEnabled: boolean;
  defaultCollection: boolean;
  guestProductCollectionFields: GuestProductCollectionField[];

  onSave(): void;
  onFieldsChange(newGuestProductCollectionFields: GuestProductCollectionField[]): void;
  onFieldFocus(index: number): void;
  toggleSlidingPaneTranslations(): void;
}

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`events.edit_advanced.guest_product_collections.${key}`, options);
};

enum FieldType {
  Text = "text",
  TextArea = "text_area",
  File = "file",
  Image = "image",
  ValuesList = "values_list"
}

const GuestProductCollectionFormSidebar: React.FC<Props> = ({
  translationsEnabled,
  defaultCollection,
  guestProductCollectionFields,
  onSave,
  onFieldsChange,
  onFieldFocus,
  toggleSlidingPaneTranslations
}) => {
  const noFieldsDisplayed = !guestProductCollectionFields || guestProductCollectionFields?.filter(field => !field.hidden).length === 0;

  const addNewField = (type = "text"): (() => void) => {
    return () => {
      const newField = { name: i18n("new_field_label"), type, rank: 1000 + (guestProductCollectionFields?.length || 0) * 1000 } as GuestProductCollectionField;
      const newFields = guestProductCollectionFields ? [...guestProductCollectionFields, newField] : [newField];
      const index = guestProductCollectionFields?.length || 0;

      onFieldFocus(index);
      onFieldsChange(newFields);
    };
  };

  const renderDropdownAddField = (): JSX.Element => {
    if (defaultCollection) return null;

    return <Dropdown>
      <Dropdown.Toggle>
        <i className="fa-regular fa-plus"></i> <span className="d-none d-md-inline"> {i18n("add_field")}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={addNewField(FieldType.Text)}><i className="fa-regular fa-text fa-fw"></i> {i18n(`type_${FieldType.Text}`)}</Dropdown.Item>
        <Dropdown.Item onClick={addNewField(FieldType.TextArea)}><i className="fa-regular fa-paragraph fa-fw"></i> {i18n(`type_${FieldType.TextArea}`)}</Dropdown.Item>
        <Dropdown.Item onClick={addNewField(FieldType.File)}><i className="fa-regular fa-file fa-fw"></i> {i18n(`type_${FieldType.File}`)}</Dropdown.Item>
        <Dropdown.Item onClick={addNewField(FieldType.Image)}><i className="fa-regular fa-file-image fa-fw"></i> {i18n(`type_${FieldType.Image}`)}</Dropdown.Item>
        <Dropdown.Item onClick={addNewField(FieldType.ValuesList)}><i className="fa-regular fa-list fa-fw"></i> {i18n(`type_${FieldType.ValuesList}`)}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>;
  };

  const renderTranslationsButton = (): JSX.Element => {
    if (!translationsEnabled || defaultCollection) return null;

    const translationsTooltip = <Tooltip id="tooltip-add-element">{ I18n.t("react.registration_form.manage_translations") }</Tooltip>;

    return <div>
      <OverlayTrigger placement="bottom" overlay={translationsTooltip}>
        <button id="translations-btn" onClick={toggleSlidingPaneTranslations} className="fa-regular fa-language btn btn-secondary" />
      </OverlayTrigger>
    </div>;
  };

  const renderDNDHelp = (): JSX.Element => {
    if (defaultCollection) return null;
    if (noFieldsDisplayed) return <div className="mt-50"></div>;

    return <span className="fields-form-builder-sidebar-help"><i className="fa-regular fa-hand"></i> { i18n("dnd_help") }</span>;
  };

  const renderSaveButton = (): JSX.Element => {
    if (defaultCollection) return null;

    return <button type="button" className="btn btn-primary" onClick={onSave}>{ i18n("save") }</button>;
  };

  return <div className="col-3 fields-form-builder-sidebar">
    <div className="fields-form-builder-sidebar-card">
      <div className="sidebar-header d-flex flex-column justify-content-between">
        <div className="d-flex justify-content-between flex-wrap" style={{ gap: "8px 0" }}>
          { renderDropdownAddField() }
          { renderTranslationsButton() }
        </div>
        { renderDNDHelp() }
      </div>
      <div className="sidebar-body">
        <div className="card">
          <div className="list-group">
            <DefaultGuestProductCollectionFields defaultCollection={defaultCollection} />

            {!noFieldsDisplayed && !defaultCollection &&
              <GuestProductCollectionSortableFields
                guestProductCollectionFields={guestProductCollectionFields}
                onFieldsChange={onFieldsChange}
                onFieldFocus={onFieldFocus}
              />}
          </div>
        </div>
      </div>
      <div className="card-footer">{ renderSaveButton() }</div>
    </div>
  </div>;
};

export default GuestProductCollectionFormSidebar;
