import { Component } from "react";
import { connect } from "react-redux";

import { updateUI } from "../actions/UIAppearanceActionCreators";
import { requestCountGuests } from "../actions/ImportActionCreators";
import { synchronizeGuestsIntoAccount } from "../actions/GuestListActionCreators";
import { showNotice } from "../actions/NoticeActionCreators";
import { queryStringAndSelectedGuests } from "../utils/QueryStringUtils";
import { urlEventId } from "../utils/pathUtils";
import withModal from "./withModal.react";

class SynchronizeGuestsIntoAccount extends Component {
  constructor(props) {
    super(props);
    [
      "submit"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  componentDidMount() {
    const { requestCountGuests, updateUI } = this.props;
    updateUI({
      "modalTitle": I18n.t("react.trigger_actions.synchronize_guests_into_account")
    });
    requestCountGuests(this.queryWithSelected(), "selectedGuestCount", true);
  }

  queryWithSelected() {
    const { location, selectedGuests } = this.props;
    return queryStringAndSelectedGuests(location, selectedGuests);
  }

  submit(e) {
    e.preventDefault();
    const { synchronizeGuestsIntoAccount, closeModal, showNotice } = this.props;
    synchronizeGuestsIntoAccount(urlEventId(), this.queryWithSelected());
    showNotice(I18n.t("react.synchronize_guests_into_account.notice"), "success");
    closeModal();
  }

  render() {
    const { selectedGuestCount } = this.props;
    return (
      <div>
        <p>{ I18n.t("react.synchronize_guests_into_account.help_count", { count: selectedGuestCount || 0 }) }</p>
        <hr />
        <button type="button" className="btn btn-primary float-end" onClick={this.submit}>
          <i className="fa-regular fa-arrows-rotate"></i> { I18n.t("react.synchronize_guests_into_account.submit") }
        </button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedGuestCount: state.appearance.selectedGuestCount,
    selectedGuests: state.guests.selectedGuests
  };
}

const mapDispatchToProps = {
  requestCountGuests,
  updateUI,
  synchronizeGuestsIntoAccount,
  showNotice
};

export default connect(mapStateToProps, mapDispatchToProps)(withModal(SynchronizeGuestsIntoAccount));
