import { cloneElement, Children, Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import Sortable from "../../components/Sortable.react";
import { DragTypes } from "../../constants/Constants";

import ShowAllLink from "./ShowAllLink.react.js";

const TRUNCATE_LIMIT = 5; // if more than 5 options only display the 5 first and propose a button to display everything

class FormItemWithOptions extends Component {

  constructor(props) {
    super(props);
    ["renderOptions", "updateOption", "destroyOption", "handleDrop", "focusOption", "triggerShowAll"].forEach(item => {
      this[item] = this[item].bind(this);
    });
    this.state = {
      focusedOptionId: null,
      showingAll: false
    };
  }

  componentDidMount() {
    const { formItem, optionsFocusedCallback } = this.props;
    if (formItem.mode === "edit" && formItem.form_item_options && formItem.form_item_options.length > 0) {
      optionsFocusedCallback(formItem.form_item_options[0]);
      this.setState({ focusedOptionId: formItem.form_item_options[0]._id });
    }
  }

  componentDidUpdate(prevProps) {
    const { formItem, optionsFocusedCallback } = this.props;
    if (!prevProps.formItem.form_item_options || !formItem.form_item_options) {
      return;
    }
    if (formItem.form_item_options.length > prevProps.formItem.form_item_options.length) {
      const length = formItem.form_item_options.length;
      const lastFormItemOption = formItem.form_item_options[length - 1];
      optionsFocusedCallback(lastFormItemOption);
      this.setState({
        focusedOptionId: lastFormItemOption._id,
        showingAll: true
      });
    }
  }

  handleDrop(previousItemId, itemId, nextItemId, estimatedIndex) {
    let params = {};
    if (previousItemId) {
      params["rank_after_id"] = previousItemId;
    }
    if (nextItemId) {
      params["rank_before_id"] = nextItemId;
    }

    params["rank"] = estimatedIndex;
    params["_id"] = itemId;
    const { updateFormItem } = this.props;
    updateFormItem({
      form_item_options: [params]
    }, true);
  }

  updateOption(params) {
    const { updateFormItem } = this.props;
    updateFormItem({
      form_item_options: [params]
    });
  }

  destroyOption(optionId) {
    const { updateFormItem } = this.props;
    updateFormItem({
      form_item_options: [{ _id: optionId, _destroy: "1" }]
    });
  }

  triggerShowAll(showingAll) {
    if (!showingAll) {
      //ensure element stay visible
      const component = this.refs["formItemOptionsDiv"];
      const domNode = ReactDOM.findDOMNode(component);
      domNode.scrollIntoView();
    }
    this.setState({ showingAll });
  }

  focusOption(focusedOption) {
    const { optionsFocusedCallback } = this.props;
    return () => {
      if (this.state.focusedOptionId === focusedOption._id) {
        return;
      }
      optionsFocusedCallback(focusedOption);
      this.setState({
        focusedOptionId: focusedOption._id
      });
    };
  }

  renderOption(child, mode, destroyable) {
    const option = child.props.itemOption;
    if (!option) {
      throw new Error("Passing a children to FormItemWithOptions which doesn't have a itemOption props");
    }
    const onClick = mode === "edit" ? this.focusOption(option) : null;
    return (
      <div key={option._id} onClick={onClick}>
        {cloneElement(child, {
          mode,
          updateHandler: this.updateOption,
          destroyHandler: this.destroyOption,
          allowDestroy: destroyable
        })}
      </div>
    );
  }

  renderOptions() {
    const { children, mode, formItem } = this.props;
    const optionsCount = formItem.form_item_options.length;

    const enhancedChildren = Children.map(children, (child) => {
      return this.renderOption(child, mode, optionsCount > 1);
    });
    const truncate = this.state.showingAll ? optionsCount : TRUNCATE_LIMIT;

    return (
      <div>
        <Sortable itemIdKey="_id"
          itemIndexKey="rank"
          dragType={DragTypes.FORM_ITEM_OPTION + formItem._id}
          items={formItem.form_item_options.slice(0, truncate)}
          handleDrop={this.handleDrop}
          handlePosition="left">
          { enhancedChildren.slice(0, truncate) }
        </Sortable>
        <ShowAllLink
          nbItems={optionsCount}
          onClickHandler={this.triggerShowAll}
          limit={TRUNCATE_LIMIT}
        />
      </div>
    );
  }

  render() {
    const { footer, mode } = this.props;
    return (
      <div className="col-sm-12" ref="formItemOptionsDiv">
        { this.renderOptions() }
        <div style={{ marginLeft: "1%" /* options will get margin lefted by the sortable component by 3%. Adding 1% aligns.*/ }}>
          { mode === "edit" ? footer : null }
        </div>
      </div>
    );
  }
}

FormItemWithOptions.propTypes = {
  updateFormItem: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired, // edit | read
  formItem: PropTypes.object.isRequired,
  optionsFocusedCallback: PropTypes.func
};

FormItemWithOptions.defaultProps = {
  optionsFocusedCallback: () => {}
};

export default FormItemWithOptions;

// helpers
export function nextOptionRank(formItem) {
  let rank = 1000;
  if (formItem.form_item_options && formItem.form_item_options.length > 0) {
    rank = formItem.form_item_options[formItem.form_item_options.length - 1].rank + 1000;
  }
  return rank;
}
