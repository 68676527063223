import { Component } from "react";
import TemplateSettings from "../templates_builder/TemplateSettings.react";
import { GuestCategory } from "../../types/GuestCategory";
import { WebsiteMenuItem } from "../../types/WebsiteMenuItem";
import { findNestedMenuItem } from "../../utils/websiteMenuUtils";
import { replaceResourceIdWithPlaceholderId } from "../../utils/templatesBuilderUtils";

interface Props {
  guestCategories: GuestCategory[];
  menuItems: WebsiteMenuItem[];
  documentNames: string[];
  websitePages: any;
  menuSettings: any;
  onUpdateOption(optionKey: string, optionValue: any): void;
  pathToMenuItem: number[];
}

class MenuItemOptionsForm extends Component<Props> {

  render(): JSX.Element {
    const { pathToMenuItem, menuItems, guestCategories, documentNames, websitePages, menuSettings, onUpdateOption } = this.props;

    if (!pathToMenuItem) return null;

    const menuItem = findNestedMenuItem(pathToMenuItem, menuItems);
    const options = menuItem.options;
    options["button_type"] = menuItem.type;
    options["button_link"] = menuItem.link;
    options["button_label"] = menuItem.name;
    const websitePageUrl = menuItem.page_id ? websitePages.find(page => page._id == menuItem.page_id).path_name : "";
    if (websitePageUrl !== replaceResourceIdWithPlaceholderId(options["website_page_url"])) {
      options["website_page_url"] = websitePageUrl;
    }

    return <TemplateSettings settingsData={options}
      settingComponentsSchema={menuSettings}
      notifyChange={onUpdateOption}
      guestCategories={guestCategories}
      documentNames={documentNames}
      websitePages={websitePages}
      builderType="website" />;
  }
}

export default MenuItemOptionsForm;
