import { Component } from "react";
import PropTypes from "prop-types";
import EngagementHistoryModal from "../../containers/EngagementHistoryModal.react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const QUARTILE_ICONS = {
  1: "square null-green",
  2: "square low-green",
  3: "square mid-green",
  4: "square high-green"
};

class QuartileCell extends Component {
  constructor(props) {
    super(props);
    [
      "toggleGuestEngagementHistoryModal"
    ].forEach((fn) => { this[fn] = this[fn].bind(this); });
    this.state = { showGuestEngagementHistoryModal: false };
  }

  renderIcon() {
    const { quartileValue } = this.props;
    let stars = [];
    for (let index = 1; index <= quartileValue; index++) {
      stars.push(
        <span key={index}>
          <i className={`fa-solid fa-${QUARTILE_ICONS[index]}`} style={{ fontSize: "14px", marginRight: "2px" }}></i>
        </span>
      );
    }
    return stars;
  }

  toggleGuestEngagementHistoryModal() {
    const { showGuestEngagementHistoryModal } = this.state;
    this.setState({ showGuestEngagementHistoryModal: !showGuestEngagementHistoryModal });
  }

  render() {
    const { label, absoluteScore, guest, thematicScoring } = this.props;
    const { showGuestEngagementHistoryModal } = this.state;
    const quartileTooltip = <Tooltip id="tooltip">{`${absoluteScore || 0} (${label})`}</Tooltip>;

    return (
      <td className="cell-default d-none d-sm-table-cell" onClick={this.toggleGuestEngagementHistoryModal} style={{ cursor: "pointer" }}>
        <OverlayTrigger placement="top" overlay={quartileTooltip}>
          <div style={{ textAlign: "left", margin: "0 auto", width: "64px" }}>
            { this.renderIcon() }
          </div>
        </OverlayTrigger>
        <EngagementHistoryModal
          isOpen={showGuestEngagementHistoryModal}
          toggleModal={this.toggleGuestEngagementHistoryModal}
          guest={guest}
          thematicScoring={thematicScoring}
        />
      </td>
    );
  }
}

QuartileCell.propTypes = {
  quartileValue: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired
};

export default QuartileCell;
