import { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import indexOf from "lodash/indexOf";
import { DOCUMENTS } from "../../constants/FeaturesSet";
import { isEnabled } from "../../utils/featureSetUtils";
import { OverlayTrigger, Button } from "react-bootstrap";
import { urlEventId } from "../../utils/pathUtils";
import { addTooltip } from "../../utils/templatesBuilderUtils";
import { redirectIfUnauthorized } from "../../utils/aclUtils";
import withTemplatesBuilder from "../withTemplatesBuilder.react";
import DocumentTemplateForm from "./DocumentTemplateForm.react";
import SectionsList from "../../components/templates_builder/SectionsList.react";
import SectionsTypesList from "../../components/templates_builder/SectionsTypesList.react";
import SectionView from "../../components/templates_builder/SectionView.react";
import AppearanceCategories from "../../components/templates_builder/AppearanceCategories.react";
import AppearanceSubsection from "../../components/templates_builder/AppearanceSubsection.react";
import ThemeReloader from "../../components/templates_builder/ThemeReloader.react";
import Loader from "../../components/shared/Loader.react";
import NoticeBlock from "../../components/templates_builder/NoticeBlock.react";
import * as DocumentBuilderActionCreators from "../../actions/DocumentTemplateActionCreators";
import * as CommonBuilderActionCreators from "../../actions/CommonBuilderActionCreators";
import { fetchEventGuestFields } from "../../actions/GuestFieldsActionCreators";
import { fetchSavedSearches } from "../../actions/SavedSearchActionCreators";
import { DocumentTemplate } from "../../types/DocumentTemplate";
import { CommonBuilderProps } from "../../types/Builder";
import { Event } from "../../types/Event";

interface Props extends CommonBuilderProps {
  documentTemplateId: string;
  event: Event;
}

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.document_templates.document_template_builder.${key}`, opts);
}

const DocumentBuilder: React.FC<Props> = forwardRef(({
  event,
  documentTemplateId,
  sectionViewForKey,
  appearanceShown,
  appearanceViewForKey,
  translationsPaneShown,
  sectionsListShown,
  liquidTemplates,
  liquidTemplatePendingRequest,
  liquidTemplateErrors,
  notice,
  noticeType,
  sidebarMenuClasses,
  resetAndChangeParentView,
  toggleAppearanceView,
  toggleAppearanceSubsectionView,
  receiveAppearanceChanges,
  displayTranslationsPane,
  toggleSectionView,
  showSectionsList,
  hideSectionsList,
  highlightBlockInIframe,
  clearErrors,
  updateLiquidTemplate,
  createLiquidTemplate,
  showNotice
// eslint-disable-next-line @typescript-eslint/no-unused-vars
}, _ref) => {
  if (!isEnabled(DOCUMENTS)) return null;

  redirectIfUnauthorized("configuration", "manage");

  const documentTemplate = useSelector((state: any): DocumentTemplate => state.documentTemplate.data);
  const errors = useSelector((state: any) => state.documentTemplate.errors);
  const isFetching = useSelector((state: any): boolean => state.documentTemplate.isFetching);
  const isSaving = useSelector((state: any): boolean => state.documentTemplate.isSaving);
  const generalSettings = useSelector((state: any) => state.documentTemplate.generalSettings);
  const sections = useSelector((state: any) => state.builder.sections);
  const orderedSections = useSelector((state: any) => state.builder.orderedSections);
  const hasChanges = useSelector((state: any) => state.builder.hasChanges);
  const globalChanges = useSelector((state: any) => state.builder.globalChanges);
  const sectionsToRemove = useSelector((state: any) => state.builder.sectionsToRemove);
  const blocksToRemove = useSelector((state: any) => state.builder.blocksToRemove);
  const sectionTypes = useSelector((state: any) => state.sectionTypes.items);
  const reloadingTheme = useSelector((state: any) => state.documentTemplate.reloadingTheme);
  const reloadThemeError = useSelector((state: any) => state.documentTemplate.reloadThemeError);
  const guestFields = useSelector((state: any) => state.guestFields.guestFields);
  const segments = useSelector((state: any) => state.savedSearches.data);
  const assets = useSelector((state: any) => state.assetsManager.assets);

  const dispatch = useDispatch();

  const [showDocumentTemplateFormView, setShowDocumentTemplateFormView] = useState<boolean>(false);
  const [iframeLoading, setIframeLoading] = useState<boolean>(true);

  const previewIframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    dispatch(DocumentBuilderActionCreators.fetchDocumentTemplate(urlEventId(), documentTemplateId));

    if (sectionTypes.length === 0) {
      dispatch(DocumentBuilderActionCreators.fetchDocumentTemplateSectionTypes(urlEventId(), documentTemplateId));
    }
    if (guestFields.length == 0) {
      dispatch(fetchEventGuestFields(urlEventId(), { website: true }));
    }
    if (segments.length == 0) {
      dispatch(fetchSavedSearches(urlEventId()));
    }
  }, []);

  useEffect(() => {
    if (isSaving) return;

    if (isEmpty(errors)) {
      forceReloadPreview(); // reload iframe after successful changes
    } else {
      dispatch(showNotice(I18n.t("react.website.an_error_occurred"), "error", 2500));
    }
  }, [isSaving]);

  const themeUpdatedAt = documentTemplate?.theme_updated_at;

  useEffect(() => {
    if (!themeUpdatedAt) return null;

    forceReloadPreview(); // reload iframe after theme reload
  }, [themeUpdatedAt]);

  const toggleDocumentTemplateForm = (): void => {
    resetAndChangeParentView({}, "edit");
    setShowDocumentTemplateFormView(!showDocumentTemplateFormView);
  };

  const toggleAppearanceViewHandler = (): void => {
    setShowDocumentTemplateFormView(false);
    toggleAppearanceView();
  };

  const save = (documentTemplateAttributes: any): void => {
    const notificationsOptions = { notice: i18n("document_updated_successfully"), noticeType: "success" };

    dispatch(DocumentBuilderActionCreators.updateDocumentTemplate(urlEventId(), documentTemplateId, documentTemplateAttributes, notificationsOptions));
  };

  const saveDocumentTemplateChanges = (): void => {
    const attributes = {
      remove_sections: sectionsToRemove,
      remove_blocks: blocksToRemove,
      settings_data: globalChanges
    };
    save(attributes);
  };

  const updateSectionsOrder = (orderedSections: any): any => {
    dispatch(CommonBuilderActionCreators.updateSectionsOrder(orderedSections));
  };

  const createNewSection = (sectionId: string, sectionData: any): any => {
    dispatch(CommonBuilderActionCreators.createNewSection(sectionId, sectionData));
  };

  const removeSection = (): void => {
    toggleSectionView();
    dispatch(CommonBuilderActionCreators.removeSection(sectionViewForKey));
  };

  const receiveNewChanges = (): void => {
    if (hasChanges) return;

    dispatch(CommonBuilderActionCreators.sendChangesNotification());
  };

  const receiveSectionChanges = (key: string, newConfig: any, changes: any): void => {
    dispatch(CommonBuilderActionCreators.updateSection(key, newConfig, changes));
  };

  const createNewBlock = (blockKey: string, blockData: any, sectionKey: string): void => {
    dispatch(CommonBuilderActionCreators.createNewBlock(blockKey, blockData, sectionKey));
  };

  const removeBlock = (blockKey: string, sectionKey: string): void => {
    dispatch(CommonBuilderActionCreators.removeBlock(blockKey, sectionKey));
  };

  const reloadTheme = (templateProjectRoot: string): void => {
    const notificationsOptions = { notice: i18n("theme_reloaded_successfully"), noticeType: "success" };

    dispatch(DocumentBuilderActionCreators.reloadTheme(urlEventId(), documentTemplate._id, templateProjectRoot, notificationsOptions));
  };

  const forceReloadPreview = (): void => {
    if (!previewIframeRef || !previewIframeRef.current) {
      return;
    }
    const rand = Math.floor((Math.random() * 1000000));
    previewIframeRef.current.src += "&uid=" + rand;
    setIframeLoading(true);
  };

  const removeLoader = (): void => {
    setIframeLoading(false);
  };

  const previewURL = (): string => {
    return `${window.location.origin}/events/${urlEventId()}/document_templates/${documentTemplateId}/preview?uid=0`;
  };

  const renderDocumentTemplateForm = (): JSX.Element => {
    if (!documentTemplate) return;

    return <DocumentTemplateForm
      shown={showDocumentTemplateFormView}
      documentTemplate={documentTemplate}
      errors={errors}
      isSaving={isSaving}
      onHide={toggleDocumentTemplateForm}
      onSubmit={save}
    />;
  };

  const renderSectionsConfig = (): JSX.Element => {
    if (!sectionViewForKey) {
      return <SectionView />;
    }

    const sectionLocalConfiguration = sections[sectionViewForKey];
    const sectionTypeKey = sectionLocalConfiguration["type"];
    const sectionType = sectionTypes.find(s => s.filename == sectionTypeKey);

    return (
      <SectionView sectionKey={sectionViewForKey}
        schema={sectionType.schema}
        configuration={sectionLocalConfiguration}
        hide={toggleSectionView}
        detectChanges={receiveNewChanges}
        sendChanges={receiveSectionChanges}
        addBlock={createNewBlock}
        removeBlock={removeBlock}
        highlightBlockInIframe={highlightBlockInIframe}
        deletable={indexOf(orderedSections, sectionViewForKey) != -1}
        removeSection={removeSection}
        guestCategories={[]}
        guestCategoriesByPopulation={[]}
        documentNames={[]}
        accesspointsTraits={documentTemplate.accesspoints_traits}
        websitePages={[]}
        guestFields={guestFields}
        segments={segments}
        updateLiquidTemplate={updateLiquidTemplate}
        createLiquidTemplate={createLiquidTemplate}
        liquidTemplates={liquidTemplates}
        liquidTemplateErrors={liquidTemplateErrors}
        liquidTemplatePendingRequest={liquidTemplatePendingRequest}
        accountId={event.account_id}
        builderType="document"
        clearErrors={clearErrors}
        templateId={documentTemplate._id}
        saveBuilder={saveDocumentTemplateChanges}
        event={event}
        assets={assets}
        hasChanges={hasChanges}
        withSidebar={true} />
    );
  };

  if (isFetching || !documentTemplate || sectionTypes.length == 0) {
    return <Loader size="large" inline={false} message={I18n.t("react.loader.loading")} />;
  }

  const translationsEnabled = event && event.available_frontend_locales && event.available_frontend_locales.length > 0;

  return (
    <div className="row website-builder-row">
      <div className="col-lg-3 col-md-4 col-sm-5 col-6 website-builder-sidebar">
        <div className="sidebar-menu">
          <ul className="list-group">
            <OverlayTrigger placement="right" overlay={addTooltip(i18n("edit_document_template"))}>
              <li className={sidebarMenuClasses("edit")} onClick={toggleDocumentTemplateForm}><i className="fa-regular fa-pencil"></i></li>
            </OverlayTrigger>
            <OverlayTrigger placement="right" overlay={addTooltip(I18n.t("react.website.edit_general_appearance"))}>
              <li className={sidebarMenuClasses("appearance")} onClick={toggleAppearanceViewHandler}><i className="fa-regular fa-paintbrush-pencil"></i></li>
            </OverlayTrigger>
            { translationsEnabled &&
              <OverlayTrigger placement="right" overlay={addTooltip(I18n.t("react.registration_form.manage_translations"))}>
                <li className={sidebarMenuClasses("translations")} onClick={ (e: any): (state: any, menuKey?: string) => void => displayTranslationsPane(e, documentTemplateId) }><i className="fa-regular fa-language"></i></li>
              </OverlayTrigger>
            }
          </ul>
        </div>

        <div className="sidebar-page-sections">
          <div className="sidebar-header">
            <p className="lead">{ documentTemplate ? documentTemplate.name : "" }</p>
          </div>

          <div className="sidebar-body">
            { documentTemplate &&
              <SectionsList
                layoutStaticSections={["content"]}
                templateStaticSections={[]}
                dynamicSectionsNames={orderedSections}
                sectionTypes={sectionTypes}
                sectionsConfiguration={sections}
                showEditSection={toggleSectionView}
                showSectionsList={showSectionsList}
                updateSectionsOrder={updateSectionsOrder}
              />
            }
          </div>
        </div>

        <div className="sidebar-footer">
          <Button variant="primary" disabled={!hasChanges} onClick={saveDocumentTemplateChanges}>{ i18n("save") }</Button>
          { documentTemplate && window.ReactGlobalProps.super_admin &&
            <ThemeReloader
              className="float-end"
              reloadTheme={reloadTheme}
              reloading={reloadingTheme}
              reloadError={reloadThemeError}
              hasThemeLoader={documentTemplate}
              resourceType="document_template"
            />
          }
        </div>

        { renderDocumentTemplateForm() }
        { translationsPaneShown || <NoticeBlock notice={notice} noticeType={noticeType} /> }

        { documentTemplate && sectionTypes &&
          <SectionsTypesList
            layoutStaticSections={["content"]}
            sectionsGroups={documentTemplate.sections_groups}
            sectionTypes={sectionTypes}
            sections={sections}
            isShown={sectionsListShown}
            hideComponent={hideSectionsList}
            createNewSection={createNewSection}
            showEditSection={toggleSectionView}
            event={event}
            withSidebar={true}
          />
        }

        { documentTemplate && renderSectionsConfig() }

        { documentTemplate && <>
          <AppearanceCategories
            isShown={appearanceShown}
            mainSettingsSchema={documentTemplate.main_settings_schema}
            toggleView={toggleAppearanceViewHandler}
            onSelectAppearanceCategory={toggleAppearanceSubsectionView}
          />
          <AppearanceSubsection
            subsectionKey={appearanceViewForKey}
            mainSettingsSchema={documentTemplate.main_settings_schema}
            mainSettingsData={generalSettings}
            toggleView={toggleAppearanceSubsectionView}
            detectChanges={receiveNewChanges}
            sendChanges={receiveAppearanceChanges}
            event={event}
            hasChanges={hasChanges}
          />
        </>}
      </div>

      <div className="col-lg-9 col-md-8 col-sm-7 col-6 website-builder-preview">
        { iframeLoading ?
          <Loader size="large" inline={false} withOverlay={true} message={I18n.t("react.loader.preview")} />
          : null }
        <iframe src={previewURL()} style={{ border: 0 }} id="template-preview-iframe" ref={previewIframeRef} onLoad={removeLoader} />
      </div>
    </div>
  );
});

export default withTemplatesBuilder(DocumentBuilder, "document");
