import { ActionTypes } from "../constants/Constants";

//catch errors form api
function UnexpectedApiError(state = [], action) {
  switch (action.type) {
  case ActionTypes.UPDATE_UI_ERROR:
  case ActionTypes.DOCUMENT_TEMPLATES_FAILURE:
  case ActionTypes.CURRENT_SAVED_SEARCH_FAILURE:
  case ActionTypes.RECEIVE_GUESTS_ERROR:
  case ActionTypes.RECEIVE_ACCESSPOINTS_FAILURE:
  case ActionTypes.RECEIVE_GUEST_CATEGORIES_FAILURE:
    state = state.slice(); //clone the state first
    state = [...state, createDefaultError()];
    return state;

  case ActionTypes.UPDATE_UI:
  case ActionTypes.DOCUMENT_TEMPLATES_REQUEST:
  case ActionTypes.CURRENT_SAVED_SEARCH_REQUEST:
  case ActionTypes.RECEIVE_GUESTS:
  case ActionTypes.RECEIVE_ACCESSPOINTS_SUCCESS:
  case ActionTypes.RECEIVE_GUEST_CATEGORIES:
    return [];
  default:
    return state;
  }
}

export default UnexpectedApiError;

function createDefaultError() {
  return { date: Date.now(), message: I18n.t("api_error_default_message") };
}

export { createDefaultError };
