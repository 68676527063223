import { CALL_API, ActionTypes } from "../constants/Constants";
import { stringify } from "querystring";

module.exports = {
  fetchGuestImportOperations(eventId, guestImportOperationId, options = {}) {
    const { DEV_NULL, GUEST_IMPORT_OPERATION_RECEIVE_SUCCESS, GUEST_IMPORT_OPERATION_RECEIVE_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, GUEST_IMPORT_OPERATION_RECEIVE_SUCCESS, GUEST_IMPORT_OPERATION_RECEIVE_FAILURE],
        method: "GET",
        endpoint: `/events/${eventId}/guest_import_operations/${guestImportOperationId}.json?${stringify(options)}`
      }
    };
  },

  updateGuestImportOperations(eventId, guestImportOperationId, params, redirectTo, extraParams) {
    const { DEV_NULL, GUEST_IMPORT_OPERATION_UPDATE_SUCCESS, GUEST_IMPORT_OPERATION_UPDATE_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, GUEST_IMPORT_OPERATION_UPDATE_SUCCESS, GUEST_IMPORT_OPERATION_UPDATE_FAILURE],
        method: "PUT",
        body: Object.assign({}, { guest_import_operation: params }, extraParams),
        endpoint: `/events/${eventId}/guest_import_operations/${guestImportOperationId}.json`,
        redirectTo
      }
    };
  },

  fetchDistinctValuesAtColumn(eventId, guestImportOperationId, column) {
    const { FETCH_GUEST_IMPORT_OPERATION_DISTINCT_VALUES, GUEST_IMPORT_OPERATION_DISTINCT_VALUES_SUCCESS, GUEST_IMPORT_OPERATION_DISTINCT_VALUES_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [FETCH_GUEST_IMPORT_OPERATION_DISTINCT_VALUES, GUEST_IMPORT_OPERATION_DISTINCT_VALUES_SUCCESS, GUEST_IMPORT_OPERATION_DISTINCT_VALUES_FAILURE],
        method: "GET",
        endpoint: `/events/${eventId}/guest_import_operations/${guestImportOperationId}/distinct_values_at_column.json?column=${column}`
      }
    };
  }

};
