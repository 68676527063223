import { ActionTypes } from "../constants/Constants";
import { updateLiquidField, GuestCategoryName } from "../utils/LiquidFieldUtils";

const defaultState = {
  data: {},
  errors: {},
  duplicated: null,
  duplicationErrors: {},
  peopleCount: null,
  customEmail: null,
  pending: false,
  // this must match with AccountService::DEFAULT_POPULATION_TYPES
  populationTypes: ["visitor", "exhibitor", "exhibitor_badge", "press", "vip", "staff", "speaker", "supplier"]
};

function guestCategory(state = defaultState, action) {
  switch (action.type) {
  case ActionTypes.GUEST_CATEGORY_SUCCESS:
  case ActionTypes.GUEST_CATEGORY_VALID_SUCCESS:
    return Object.assign({}, state, { data: action.data, errors: {}, pending: false });
  case ActionTypes.GUEST_CATEGORY_COPY_SUCCESS:
    return Object.assign({}, state, { duplicated: action.data, duplicationErrors: {} });
  case ActionTypes.GUEST_CATEGORY_UPDATE_REQUEST:
  case ActionTypes.GUEST_CATEGORY_REQUEST:
    return Object.assign({}, state, { errors: {}, pending: true });
  case ActionTypes.GUEST_CATEGORY_FAILURE:
  case ActionTypes.GUEST_CATEGORY_UPDATE_FAILURE:
  case ActionTypes.GUEST_CATEGORY_VALID_FAILURE:
  case ActionTypes.GUEST_CATEGORY_CREATE_FAILURE:
    return Object.assign({}, state, { errors: action.data, pending: false });
  case ActionTypes.OFFLINE_UPDATE_RICHTEXT_EDITOR_SUCCESS:
    return updateLiquidField(state, action, GuestCategoryName);
  case ActionTypes.GUEST_CATEGORY_COPY_FAILURE:
    return Object.assign({}, state, { duplicationErrors: action.data });
  case ActionTypes.GUEST_CATEGORY_UPDATE_SUCCESS:
    return Object.assign({}, state, { data: Object.assign({}, action.data, { updated_at: new Date() }), errors: {}, pending: false });
  case ActionTypes.GUEST_CATEGORY_COUNT_PEOPLE_SUCCESS:
    return Object.assign({}, state, { peopleCount: action.data.count });
  case ActionTypes.GUEST_CATEGORY_CUSTOM_EMAIL_SUCCESS:
    return Object.assign({}, state, { customEmail: action.data || {} });
  case ActionTypes.GUEST_CATEGORY_CLEAR_CUSTOM_EMAIL:
    return Object.assign({}, state, { customEmail: null });
  case ActionTypes.GUEST_CATEGORY_ADD_OFFLINE_ERRORS:
    return Object.assign({}, state, { errors: action.errors });
  case ActionTypes.GUEST_CATEGORY_DESTROY_SUCCESS:
    return defaultState;
  case ActionTypes.FETCH_POPULATION_TYPES_SUCCESS:
    return Object.assign({}, state, { populationTypes: action.data });
  default:
    return state;
  }
}

export default guestCategory;
