import { ActionTypes } from "../constants/Constants";

function ticketingCategory(state = { data: {}, errors: {}, isBeingCreated: false, isBeingUpdated: false }, action) {
  switch (action.type) {
  case ActionTypes.CREATE_TICKETING_SUCCESS:
    return Object.assign({}, state, { data: action.data, errors: {}, isBeingCreated: false });
  case ActionTypes.RECEIVE_TICKETING_SUCCESS:
  case ActionTypes.UPDATE_TICKETING_SUCCESS:
    return Object.assign({}, state, { data: action.data, errors: {}, isBeingUpdated: false });
  case ActionTypes.RECEIVE_TICKETING_FAILURE:
  case ActionTypes.CREATE_TICKETING_FAILURE:
  case ActionTypes.UPDATE_TICKETING_FAILURE:
    return Object.assign({}, state, { errors: action.data, isBeingCreated: false, isBeingUpdated: false });
  case ActionTypes.CREATE_TICKETING_REQUEST:
    return Object.assign({}, state, { isBeingCreated: true });
  case ActionTypes.UPDATE_TICKETING_REQUEST:
    return Object.assign({}, state, { isBeingUpdated: true });
  default:
    return state;
  }
}

export default ticketingCategory;
