import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";

import Paginate from "../../components/Paginate.react";
import StatisticsTable from "../../components/guest_campaign/StatisticsTable.react";

import { fetchGuestCampaign, fetchGuestCampaignGlobalStatistics } from "../../actions/GuestCampaignActionCreators";
import { fetchReceiverActivities, fetchReceievrActivitiesAtPage } from "../../actions/ReceiverActivitiesActionCreators";
import { requestGuests } from "../../actions/ImportActionCreators";
import { triggerGuestsListExport } from "../../actions/ExportsActionCreators";

import { pathToGuestCampaigns } from "../../utils/pathUtils";
import { redirectIfUnauthorized, isAuthorized } from "../../utils/aclUtils";

const EMAIL_EVENTS = ["enqueued", "sent", "opened", "clicked", "bounced", "blocked", "spammed", "unsubscribed"];

class GuestCampaignStatistics extends Component {

  constructor(props) {
    redirectIfUnauthorized("campaigns", "manage");
    super(props);
    [
      "goToPage",
      "fetchGuests",
      "guestForReceiverActivity",
      "onEmailEventSelected",
      "launchExport",
      "renderGlobalStatsRow"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.state = {
      selectedEmailEvent: null
    };
  }

  componentDidMount() {
    const { match, fetchReceiverActivities, fetchGuestCampaign, fetchGuestCampaignGlobalStatistics } = this.props;
    const { event_id, guest_campaign_id } = match.params;
    fetchReceiverActivities(event_id, guest_campaign_id);
    fetchGuestCampaign(event_id, guest_campaign_id);
    fetchGuestCampaignGlobalStatistics(event_id, guest_campaign_id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.receiverActivities != prevProps.receiverActivities) {
      this.fetchGuests(this.props);
    }
  }

  goToPage(url) {
    return (e) => {
      e.preventDefault();
      const { fetchReceievrActivitiesAtPage } = this.props;
      fetchReceievrActivitiesAtPage(url);
    };
  }

  fetchGuests(props = this.props) {
    const ids = props.receiverActivities.map(activity => activity.guest_id);
    const qs = ids.join(" ");
    props.requestGuests(qs);
  }

  guestForReceiverActivity(activity) {
    return this.props.guests.find(guest => guest.id === activity.guest_id);
  }

  onEmailEventSelected(selectedEmailEvent) {
    return (e) => {
      e.preventDefault();
      if (this.state.selectedEmailEvent !== selectedEmailEvent) {
        this.setState({
          selectedEmailEvent
        });
        const { fetchReceiverActivities, match } = this.props;
        const { event_id, guest_campaign_id } = match.params;
        fetchReceiverActivities(event_id, guest_campaign_id, selectedEmailEvent);
      }
    };
  }

  launchExport() {
    const { triggerGuestsListExport, match } = this.props;
    triggerGuestsListExport(["campaign_statistics"], "xls", null, { guest_campaign_id: match.params.guest_campaign_id });
  }

  i18n(key) {
    return I18n.t(`react.guest_campaigns.statistics.${key}`);
  }

  renderHeader() {
    const { guestCampaign } = this.props;
    const campaignSentAt = guestCampaign.sent_at ? `(${I18n.t("react.guest_campaigns.statistics.sent_on")} ${I18n.l("time.formats.long", guestCampaign.sent_at)})` : null;
    return <h2 className="d-flex flex-wrap">
      <Link to={pathToGuestCampaigns()}><i className="fa-regular fa-chevron-left fa-fw fa-xs"></i></Link>
      { guestCampaign.name }
      <small className="form-text d-flex align-items-center ml-10">{ campaignSentAt }</small>
    </h2>;
  }

  renderEmailEventSelector() {
    const { selectedEmailEvent } = this.state;
    const title = selectedEmailEvent ? I18n.t(`react.guest_campaigns.statistics.email_event.${selectedEmailEvent}`) : I18n.t("react.guest_campaigns.statistics.all");
    const options = EMAIL_EVENTS.map(emailEvent => {
      return (
        <Dropdown.Item eventKey={emailEvent} onClick={this.onEmailEventSelected(emailEvent)}>
          { I18n.t(`react.guest_campaigns.statistics.email_event.${emailEvent}`) }
        </Dropdown.Item>
      );
    });
    return (
      <Dropdown>
        <Dropdown.Toggle variant="secondary" aria-haspopup="true">{ title }</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey="1" onClick={this.onEmailEventSelected(null)}>
            <em>{ I18n.t("react.guest_campaigns.statistics.all") }</em>
          </Dropdown.Item>
          { options }
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  renderPercentStat(nb_emails, stat_from_event) {
    const value = nb_emails === 0 ? 0 : ((stat_from_event / nb_emails) * 100).toFixed(2);
    return `${value}%`;
  }

  renderEventInfo(event) {
    return <span>
      { I18n.t(`react.guest_campaigns.statistics.email_event.${event}`).toUpperCase() }
      { " " }
      { this.renderEventTooltip(event) }
    </span>;
  }

  renderEventTooltip(event, placement) {
    const tooltip = <Tooltip id="info">{I18n.t(`react.guest_campaigns.statistics.email_event_info.${event}`)}</Tooltip>;

    return <OverlayTrigger trigger="click" placement={placement || "top"} overlay={tooltip} rootClose={true}>
      <i className="fa-regular fa-info-circle" aria-hidden="true" />
    </OverlayTrigger>;
  }

  renderMainEventStat(event) {
    const { globalStats } = this.props;

    return <div className="col-sm-4 card-global-stat">
      <p className="main">{ this.renderPercentStat(globalStats.activity, globalStats[event]) }</p>
      <p className="detail">{ globalStats[event] }</p>
      <p className={`event ${event}-color`}>{ this.renderEventInfo(event) }</p>
    </div>;
  }

  renderSideEventStat(event) {
    const { globalStats } = this.props;

    return <div>
      <span className={`${event}-color`}>{ this.i18n(`email_event.${event}`).toUpperCase() }</span>
      <span className="float-end">
        { this.renderPercentStat(globalStats.activity, globalStats[event]) }
        { " " }
        { this.renderEventTooltip(event, "left") }
      </span>
    </div>;
  }

  renderGlobalStatsRow() {
    const { globalStats, guestCampaign } = this.props;

    if (!globalStats) return null;

    return (
      <div className="row mt-30">
        <div className="col-md-2 col-sm-3">
          <div className="card card-sm card-global-stat">
            <p className="main">{ globalStats.activity }</p>
            <p className="detail">{ this.renderPercentStat(guestCampaign.final_guest_count, globalStats.activity) }</p>
            <p className="event">{ this.renderEventInfo("enqueued") }</p>
          </div>
        </div>
        <div className="col-md-8 col-sm-9">
          <div className="card card-sm">
            <div className="row">
              { this.renderMainEventStat("sent") }
              { this.renderMainEventStat("opened") }
              { this.renderMainEventStat("clicked") }
            </div>
          </div>
        </div>
        <div className="col-md-2 col-6">
          { this.renderSideEventStat("blocked") }
          { this.renderSideEventStat("bounced") }
          { this.renderSideEventStat("unsubscribed") }
          { this.renderSideEventStat("spammed") }
        </div>
      </div>
    );
  }

  render() {
    if (!isAuthorized("campaigns", "manage")) return;
    const { receiverActivities, previousURL, nextURL } = this.props;
    const { selectedEmailEvent } = this.state;
    let activities = receiverActivities;
    if (selectedEmailEvent) {
      activities = activities.filter(activity => activity[selectedEmailEvent] > 0);
    }
    const receiverActivitiesWithGuests = activities.map(activity => {
      return Object.assign({}, activity, { guest: this.guestForReceiverActivity(activity) });
    });
    return (
      <div>
        <div className="subtitle-page">
          <div className="subtitle-page-title d-flex align-items-center flex-wrap">
            { this.renderHeader() }
            <div>
              <button className="btn btn-link p-0 ml-5" onClick={this.launchExport}>
                <i className="fa-regular fa-upload"></i> { I18n.t("react.guest_campaigns.statistics.export") }
              </button>
            </div>
          </div>
        </div>
        { this.renderGlobalStatsRow() }
        <div className="d-flex justify-content-between">
          <div className="mr-5">{ this.renderEmailEventSelector() }</div>
          <Paginate
            handlePrevious={this.goToPage(previousURL)}
            handleNext={this.goToPage(nextURL)}
            previousEnabled={previousURL}
            nextEnabled={nextURL}
            classNames="m-0"
            pull="end"
          />
        </div>
        <StatisticsTable receiverActivitiesWithGuests={receiverActivitiesWithGuests} emailEvents={EMAIL_EVENTS}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    receiverActivities: state.receiverActivities.data,
    nextURL: state.receiverActivities.nextURL,
    previousURL: state.receiverActivities.previousURL,
    guestCampaign: state.guestCampaign.guestCampaign,
    guests: state.guests.guests,
    globalStats: state.guestCampaign.globalStatistics
  };
}

const mapDispatchToProps = {
  fetchReceiverActivities,
  fetchReceievrActivitiesAtPage,
  fetchGuestCampaign,
  requestGuests,
  triggerGuestsListExport,
  fetchGuestCampaignGlobalStatistics
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestCampaignStatistics);
