import { ActionTypes } from "../constants/Constants";

const initialState = {
  errors: null,
  pending: false
};

function customEmail(state = initialState, action): any {
  switch (action.type) {
  case ActionTypes.CLEAR_CUSTOM_EMAIL_STATE:
    return initialState;
  case ActionTypes.CREATE_CUSTOM_EMAIL_REQUEST:
  case ActionTypes.UPDATE_CUSTOM_EMAIL_REQUEST:
    return { errors: null, pending: true };
  case ActionTypes.CREATE_CUSTOM_EMAIL_SUCCESS:
  case ActionTypes.UPDATE_CUSTOM_EMAIL_SUCCESS:
    return { errors: null, pending: false };
  case ActionTypes.CREATE_CUSTOM_EMAIL_FAILURE:
  case ActionTypes.UPDATE_CUSTOM_EMAIL_FAILURE:
    return { errors: action.data, pending: false };
  default:
    return state;
  }
}

export default customEmail;
