import { ActionTypes } from "../constants/Constants";
import { MeetingsStatisticsState } from "../types/MeetingsStatistics";


const defaultState: MeetingsStatisticsState = {
  errors: null,
  isFetching: false,
  byNumberOfAppointments: null,
};

export function meetingsStatistics(state = defaultState, action: any): MeetingsStatisticsState {
  switch (action.type) {
  case ActionTypes.FETCH_MEETING_APPOINTMENTS_STATISTICS_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.FETCH_MEETING_APPOINTMENTS_STATISTICS_SUCCESS:
    return { ...state, byNumberOfAppointments: action.data, isFetching: false, errors: null };
  case ActionTypes.FETCH_MEETING_APPOINTMENTS_STATISTICS_FAILURE:
    return { ...state, byNumberOfAppointments: [], isFetching: false, errors: action.data };
  default:
    return state;
  }
}

export default meetingsStatistics;
