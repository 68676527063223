export const GuestCategoryName = "guest_category";
export const LiquidTemplateName = "account";
export const DocumentTemplateName = "document_template";
export const ModelName = [GuestCategoryName, LiquidTemplateName, DocumentTemplateName];

export function updateLiquidField(state, action, modelName) {
  if (action.modelName !== modelName) {
    return state;
  } else {
    return Object.assign({}, state, { data: action.data });
  }
}