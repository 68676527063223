module.exports = {
  createReducer(receiveAllActionName, toggleOneActionName = null) {
    return (state = [], action) => {
      switch (action.type) {
      case receiveAllActionName:
        state = action.objectIds.slice();
        return state;
      case toggleOneActionName: {
        const count = state.length;
        state = state.filter((item) => {
          return item != action.objectId;
        });
        if (state.length == count) {
          state.push(action.objectId);
        }
        return state;
      }
      default:
        return state;
      }
    };
  }
};
