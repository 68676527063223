import { Component } from "react";
import { connect } from "react-redux";

import { updateUI } from "../actions/UIAppearanceActionCreators";
import { requestCountGuests } from "../actions/ImportActionCreators";
import { queryStringAndSelectedGuests } from "../utils/QueryStringUtils";
import { urlEventId } from "../utils/pathUtils";
import { resendWebhooks } from "../actions/GuestListActionCreators";
import { showNotice } from "../actions/NoticeActionCreators";
import withModal from "./withModal.react";


class ResendWebhook extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { requestCountGuests, updateUI } = this.props;
    const updates = {
      "modalTitle": I18n.t("react.trigger_actions.resend_webhooks")
    };
    updateUI(updates);
    requestCountGuests(this.queryWithSelected(), "selectedGuestCount", true);
  }

  queryWithSelected() {
    const { location, selectedGuests } = this.props;
    return queryStringAndSelectedGuests(location, selectedGuests);
  }

  submit() {
    const { resendWebhooks, closeModal, showNotice } = this.props;
    resendWebhooks(urlEventId(), this.queryWithSelected());
    showNotice(I18n.t("react.resend_webhooks.notice"), "success");
    closeModal();
  }

  render() {
    const { selectedGuestCount } = this.props;
    return (
      <div>
        <p>{ I18n.t("react.resend_webhooks.help_count", { count: selectedGuestCount || 0 }) }</p>
        <hr />
        <button type="button" className="btn btn-primary float-end" onClick={this.submit}>
          { I18n.t("react.resend_webhooks.submit_count", { count: selectedGuestCount || 0 }) }
        </button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedGuestCount: state.appearance.selectedGuestCount,
    selectedGuests: state.guests.selectedGuests
  };
}

const mapDispatchToProps = {
  requestCountGuests,
  updateUI,
  resendWebhooks,
  showNotice
};

export default connect(mapStateToProps, mapDispatchToProps)(withModal(ResendWebhook));
