import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterDropdown from "../FilterDropdown.react";
import { fetchEventGuestFields } from "../../actions/GuestFieldsActionCreators";
import { urlEventId } from "../../utils/pathUtils";

interface Props {
  label: string;
  selectedValue: string;

  onSelect: (value: string) => void;
}

const GuestFieldPicker: React.FC<Props> = ({ label, selectedValue, onSelect }) => {
  const guestFields = useSelector((state: any) => state.guestFields.guestFields);

  const dispatch = useDispatch();

  useEffect(() => {
    if (guestFields.length === 0) dispatch(fetchEventGuestFields(urlEventId(), { website: true }));
  }, []);

  const dropFields = guestFields.map(field => {
    return { id: field.key, name: field.label };
  });

  const title = guestFields.find(field => field.key === selectedValue)?.label;
  return <div className="row mb-3">
    <label className="form-label" htmlFor="illustration_field">{label}</label>
    <FilterDropdown
      id="guest_fields"
      translationKey="guest_fields"
      items={dropFields}
      selectedItemIds={[selectedValue]}
      showCells={false}
      hasSelectAll={false}
      multipleSelect={false}
      sortItems={false}
      itemIdKey="id"
      onChange={(value): void => onSelect(value[0])}
      title={title}
    />
  </div>;
};

export default GuestFieldPicker;
