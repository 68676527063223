import { useState } from "react";
import TranslatableInput, { Props } from "./shared/TranslatableInput.react";

/*
  This component is meant to bring the react translatable input into an ERB form.
  Usage (in ERB template) within a form tag :

  <% props = {
    label: 'website path slug',
    name: 'guest[website_path_slug]',
    value: @guest.website_path_slug,
    availableLocales: @event.available_frontend_locales,
    translationsValues: @guest.website_path_slug_translations,
    translationsLabel: 'slug translations',
    translationsName: 'guest[website_path_slug_translations]',
    divWrapperClass: 'col-sm-8',
    labelClass: 'col-sm-4'
  } %>

  <div class="react-anchor" data-react-component="ErbTranslatableInput" data-react-component-props="<%= props.to_json %>"></div>
*/


const ErbTranslatableInput: React.FC<Props> = (props: Props) => {
  const [value, setValue] = useState(props.value);
  const [translationsValues, setTranslationsValues] = useState(props.translationsValues);

  const fullProps = {
    ...props,
    value,
    translationsValues,
    onChange: (value: string): void => setValue(value),
    onTranslationChanged: (locale: string, value: string): void => setTranslationsValues({ ...translationsValues, [locale]: value })
  };

  return <TranslatableInput {...fullProps} />;
};

export default ErbTranslatableInput;
