import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { components, OptionProps, SingleValueProps, MultiValueContainerProps,
  MultiValueLabelProps } from "react-select";
import Loader from "../../components/shared/Loader.react";

import { fetchAccesspoints } from "../../actions/AccesspointActionCreators";

import { urlEventId } from "../../utils/pathUtils";
import { multiValueRemoveStyle } from "../../constants/Style";

const customStyles = {
  multiValueRemove: (base: any): any => ({
    ...base,
    ...multiValueRemoveStyle
  })
};

const Option = (props: OptionProps): JSX.Element => {
  const { data } = props;

  return <components.Option {...props}>
    <span className="badge rounded-pill bg-secondary">{data.label}</span>
  </components.Option>;
};

const SingleValue = (props: SingleValueProps): JSX.Element => {
  const { data } = props;

  return <components.SingleValue {...props}>
    <span className="badge rounded-pill bg-secondary">{data.label}</span>
  </components.SingleValue>;
};

const AccesspointMultiValueContainer = (props: MultiValueContainerProps): JSX.Element => {
  const innerProps = { ...props.innerProps, className: props.innerProps.className.concat(" badge rounded-pill bg-secondary") };
  const newProps = { ...props, innerProps };
  return <components.MultiValueContainer {...newProps} />;
};

const AccesspointMultiValueLabel = (props: MultiValueLabelProps): JSX.Element => {
  return <span>{props.data.label}</span>;
};

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.accesspoints_dropdown.${key}`, opts);
}

interface Props {
  selectedValue: string[];
  isMulti?: boolean;
  isDisabled?: boolean;

  onChange(values: string[]): void;
}

const AccesspointDropdown: React.FC<Props> = ({ selectedValue, isMulti, isDisabled, onChange }) => {
  const accesspoints = useSelector((state: any) => state.accesspoints.data);
  const fetching = useSelector((state: any) => state.accesspoints.fetching);
  const fetched = useSelector((state: any) => state.accesspoints.fetched);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!fetching && !fetched) {
      dispatch(fetchAccesspoints(urlEventId()));
    }
  }, []);

  const accesspointsOptions = (): any[] => {
    return accesspoints.map((accesspoint) => {
      return {
        value: accesspoint._id,
        label: accesspoint.name
      };
    });
  };

  if (fetching || !fetched) {
    return <div><Loader /></div>;
  }

  const options = accesspointsOptions();
  const selectedAccesspoints = selectedValue ? options.filter((accesspoint) => selectedValue.includes(accesspoint.value)) : [];

  const props = {
    className: "react-select",
    classNamePrefix: "react-select",
    closeMenuOnSelect: !isMulti,
    components: {
      Option,
      SingleValue,
      MultiValueContainer: AccesspointMultiValueContainer,
      MultiValueLabel: AccesspointMultiValueLabel
    },
    hideSelectedOptions: false,
    isClearable: true,
    isMulti,
    isDisabled,
    noOptionsMessage: (): string => i18n("no_options"),
    onChange: (selectedOption): void => onChange(selectedOption),
    options,
    placeholder: i18n("select_placeholder"),
    styles: customStyles,
    value: selectedAccesspoints
  };

  return <Select {...props} />;
};

export default AccesspointDropdown;
