import { Component } from "react";
import isEmpty from "lodash/isEmpty";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "./shared/Loader.react";
import { pathToGuestCategoryShowWithAnotherEvent, pathToRegistrationFormFromAnotherEvent, pathToEmailTemplateShowWithAnotherEvent,
  pathToWebsiteBuilderShowWithAnotherEvent, urlBase, pathToBlogArticleWithAnotherEvent, pathToDocumentBuilderWithAnotherEvent } from "../utils/pathUtils";
import ErrorMessage from "./shared/ErrorMessage.react";

interface Props {
  model: string;
  duplicatedData: any;
  currentEventId: string;
  targetEventId: string;
  duplicationWarnings: any;
  duplicationErrors: any;
  onClose?(): void;
  onContinue?(): void;
  pendingRequest?: boolean;
  keepDuplicateOnError?: boolean;
}

class DuplicationRunning extends Component<Props> {
  static defaultProps = {
    duplicatedData: null,
    pendingRequest: false,
    keepDuplicateOnError: false
  };

  i18n(key: string): string {
    const { model } = this.props;
    return I18n.t(`react.${model}.duplication_running_modal.${key}`);
  }

  pathToShowWithAnotherEvent(): string {
    const { model, duplicatedData, targetEventId } = this.props;

    switch (model) {
    case "website":
      return pathToWebsiteBuilderShowWithAnotherEvent(targetEventId);
    case "registration_form":
      return pathToRegistrationFormFromAnotherEvent(targetEventId, duplicatedData._id);
    case "guest_category":
      return pathToGuestCategoryShowWithAnotherEvent(targetEventId, duplicatedData._id);
    case "email_templates":
      return pathToEmailTemplateShowWithAnotherEvent(targetEventId, duplicatedData._id);
    case "events":
      return urlBase("edit", targetEventId);
    case "blog_articles":
      return pathToBlogArticleWithAnotherEvent(targetEventId, duplicatedData._id);
    case "document_templates":
      return pathToDocumentBuilderWithAnotherEvent(targetEventId, duplicatedData._id);
    default:
      return "";
    }
  }

  useReactRouter(): boolean {
    const { model, currentEventId, targetEventId } = this.props;

    if (model === "blog_articles" && currentEventId === targetEventId) {
      return true;
    }

    return false;
  }

  renderGoToNewDuplicateButton(): JSX.Element {
    const { targetEventId, onClose } = this.props;

    if (!targetEventId) return;

    if (this.useReactRouter()) {
      return <Link to={this.pathToShowWithAnotherEvent()} className="btn btn-primary" onClick={onClose}>
        {this.i18n("go_to_new_duplicate")}
      </Link>;
    }

    return <Button href={this.pathToShowWithAnotherEvent()}>
      {this.i18n("go_to_new_duplicate")}
    </Button>;
  }

  render(): JSX.Element {
    const { duplicatedData, onClose, duplicationWarnings, duplicationErrors, onContinue, pendingRequest } = this.props;

    if (pendingRequest || !duplicatedData && isEmpty(duplicationErrors))
      return <Loader size="large" inline={false} containerHeight="500px" message={this.i18n("copying")} />;

    if (!isEmpty(duplicationErrors))
      return <ErrorMessage errors={duplicationErrors}/>;

    return <div>
      <ErrorMessage errors={duplicationWarnings} errorType="warning"/>
      <div className="d-flex flex-column nothing-yet">
        <div>
          <span className="fa-stack fa-xl text-success">
            <i className="fa-solid fa-circle fa-stack-2x"></i>
            <i className="fa-regular fa-check fa-stack-1x fa-inverse"></i>
          </span>
        </div>
        <h4>{ this.i18n("copied") }</h4>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          { this.renderGoToNewDuplicateButton() }
          { (onContinue || onClose) ?
            <Button
              variant="secondary"
              onClick={onContinue || onClose}>
              {this.i18n("continue")}
            </Button>
            : null
          }
        </div>
      </div>
    </div>;
  }
}

export default DuplicationRunning;
