import { ActionTypes } from "../constants/Constants";
import { GuestProductsStatisticsState } from "../types/GuestProductsStatistics";

const defaultState: GuestProductsStatisticsState = {
  errors: null,
  isFetching: false,
  byNumberOfInterests: null,
  byNumberOfInformationRequests: null,
  guestsByNumberOfInformationRequests: null,
};

export function guestProductsStatistics(state = defaultState, action: any): GuestProductsStatisticsState {
  switch (action.type) {
  case ActionTypes.FETCH_GUEST_PRODUCTS_STATISTICS_REQUEST:
    return { ...state, isFetching: true, errors: null };
  case ActionTypes.FETCH_GUEST_PRODUCTS_STATISTICS_SUCCESS:
    return { ...state, byNumberOfInterests: action.data, isFetching: false, errors: null };
  case ActionTypes.FETCH_GUEST_PRODUCTS_STATISTICS_FAILURE:
    return { ...state, byNumberOfInterests: [], isFetching: false, errors: action.data };
  case ActionTypes.FETCH_GUEST_PRODUCTS_INFORMATION_REQUESTS_STATISTICS_REQUEST:
    return { ...defaultState, isFetching: true, errors: null };
  case ActionTypes.FETCH_GUEST_PRODUCTS_INFORMATION_REQUESTS_STATISTICS_SUCCESS:
    return { ...state, byNumberOfInformationRequests: action.data, isFetching: false, errors: null };
  case ActionTypes.FETCH_GUEST_PRODUCTS_INFORMATION_REQUESTS_STATISTICS_FAILURE:
    return { ...state, byNumberOfInformationRequests: [], isFetching: false, errors: action.data };
  case ActionTypes.FETCH_GUESTS_INFORMATION_REQUESTS_STATISTICS_REQUEST:
    return { ...defaultState, isFetching: true, errors: null };
  case ActionTypes.FETCH_GUESTS_INFORMATION_REQUESTS_STATISTICS_SUCCESS:
    return { ...state, guestsByNumberOfInformationRequests: action.data, isFetching: false, errors: null };
  case ActionTypes.FETCH_GUESTS_INFORMATION_REQUESTS_STATISTICS_FAILURE:
    return { ...state, guestsByNumberOfInformationRequests: [], isFetching: false, errors: action.data };
  default:
    return state;
  }
}

export default guestProductsStatistics;
