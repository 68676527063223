import { useEffect, useRef } from "react";
import { fabric } from "fabric";

interface Props {
  canvasHeight: number;
  canvasWidth: number;
  customStyle?: any;
  className?: string;
  onReady?: (canvas: fabric.Canvas) => void;
}

const FabricCanvas: React.FC<Props> = ({
  canvasHeight,
  canvasWidth,
  customStyle,
  className,
  onReady
}) => {
  const canvasEl = useRef(null);

  useEffect(() => {
    if (!canvasWidth || !canvasHeight) return;

    const newCanvas = new fabric.Canvas(canvasEl.current, {
      preserveObjectStacking: true,
    });

    onReady && onReady(newCanvas);

    return () => {
      newCanvas.dispose();
    };
  }, [canvasWidth || canvasHeight]);

  return <div className={className} style={customStyle}>
    <canvas ref={canvasEl} width={canvasWidth} height={canvasHeight} style={{ border: "1px solid black" }}/>
  </div>;
};

export default FabricCanvas;
