import { Component, MouseEvent } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import url from "url";
import { urlEventId } from "../../utils/pathUtils";
import VideoPlayer from "../shared/VideoPlayer.react";
import ConfirmableButton from "../shared/ConfirmableButton.react";
import GenericS3Uploader from "../shared/GenericS3Uploader.react";
import Sortable from "../Sortable.react";
import { DragTypes } from "../../constants/Constants";
import { dragIcon } from "../../constants/Icons";
import { StreamingArchive, StreamingArchiveUpdateAttributes } from "../../types/StreamingArchive";
import { Accesspoint } from "../../types/Accesspoint";


interface Props {
  accesspointId: string;
  accesspoint: Accesspoint;
  createStreamingArchive(eventId: string, accesspointId: string, params: any): any;
  deleteStreamingArchive(eventId: string, accesspointId: string, streamingArchiveId: string): any;
  isFetching: boolean;
  onClose(): void;
  streamingArchives: StreamingArchive[];
  updateStreamingArchive(eventId: string, accesspointId: string, streamingArchiveId: string, params: StreamingArchiveUpdateAttributes): any;
}

interface State {
  streamingArchives: StreamingArchive[];
  archivePreviewed?: StreamingArchive;
}

const STREAMING_ARCHIVE_UPLOADED_STATUS = "uploaded";

class StreamingArchivesForm extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    [
      "addStreamingArchive",
      "close",
      "handleDrop",
      "togglePublishHandler",
      "playArchiveHandler",
      "hideArchivePlayer"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    moment.locale(I18n.currentLocale());

    const { streamingArchives } = this.props;
    this.state = {
      streamingArchives
    };
  }

  componentDidUpdate(prevProps: Props): void {
    const { isFetching, streamingArchives } = this.props;
    if (prevProps.isFetching && !isFetching) {
      this.setState({ streamingArchives });
    }
  }

  addStreamingArchive(signedUrl: string): void {
    const { accesspointId, createStreamingArchive } = this.props;
    const uri = url.parse(signedUrl);
    const streamingArchiveAttributes = {
      status: STREAMING_ARCHIVE_UPLOADED_STATUS,
      path: uri.pathname.replace(/\//, "")
    };
    createStreamingArchive(urlEventId(), accesspointId, streamingArchiveAttributes);
  }

  close(): void {
    const { onClose } = this.props;
    onClose();
  }

  handleDrop(previousItemId: string, itemId: string, nextItemId: string, estimatedIndex: number, newSortedList: any[]): void {
    const reorderedStreamingArchives = newSortedList.map(listItem => listItem.item);
    const itemIndex = reorderedStreamingArchives.findIndex(a => a._id === itemId);
    reorderedStreamingArchives[itemIndex] = { ...reorderedStreamingArchives[itemIndex], rank: estimatedIndex };
    this.setState({ streamingArchives: reorderedStreamingArchives });

    const { updateStreamingArchive, accesspointId } = this.props;
    const streamingArchiveAttributes = {
      rank: estimatedIndex,
      rank_after_id: previousItemId,
      rank_before_id: nextItemId
    };
    updateStreamingArchive(urlEventId(), accesspointId, itemId, streamingArchiveAttributes);
  }

  togglePublishHandler(streamingArchive: StreamingArchive): (e: MouseEvent) => void {
    return (e: MouseEvent): void => {
      e.preventDefault();
      const { updateStreamingArchive, accesspointId } = this.props;
      const attributes = { published: !streamingArchive.published };

      updateStreamingArchive(urlEventId(), accesspointId, streamingArchive._id, attributes);
    };
  }

  playArchiveHandler(archive: StreamingArchive): (e: MouseEvent) => void {
    return (e: MouseEvent): void => {
      e.preventDefault();
      this.setState({
        archivePreviewed: archive
      });
    };
  }

  hideArchivePlayer(e: MouseEvent): void {
    e.preventDefault();
    this.setState({
      archivePreviewed: undefined
    });
  }

  archiveInPlayer(archive: StreamingArchive): boolean {
    const { archivePreviewed } = this.state;
    return archivePreviewed && archivePreviewed._id === archive._id;
  }

  renderTooltip(message: string): JSX.Element {
    return <Tooltip id="info">{ message }</Tooltip>;
  }

  renderTooltipInformation(info: string): JSX.Element {
    return (
      <OverlayTrigger placement="top" overlay={ this.renderTooltip(info) }>
        <i className="fa-regular fa-info-circle ml-5" aria-hidden="true"></i>
      </OverlayTrigger>
    );
  }

  renderRankCell(): JSX.Element {
    return (
      <a style={{ cursor: "grab" }} className="btn btn-link mb-5">
        { dragIcon() }
      </a>
    );
  }

  renderPublishedIcon(streamingArchive: StreamingArchive): JSX.Element {
    if (streamingArchive.published) {
      return <i className="fa-regular fa-check text-success"></i>;
    }

    return <i className="fa-regular fa-xmark text-danger"></i>;
  }

  renderViewButton(streamingArchive: StreamingArchive): JSX.Element {
    const { isFetching } = this.props;
    const disabled = (streamingArchive.status !== STREAMING_ARCHIVE_UPLOADED_STATUS) || isFetching;
    const classes = `btn btn-secondary mr-5 ${disabled ? "disabled" : ""}`;

    if (this.archiveInPlayer(streamingArchive)) {
      return (
        <a className={classes} href="#" onClick={this.hideArchivePlayer}>
          <i className="fa-regular fa-eye-slash" aria-hidden="true" /> { I18n.t("front_office.react.streaming_archives_manager.form.buttons.hide") }
        </a>
      );
    }

    return (
      <a className={classes} href="#" onClick={this.playArchiveHandler(streamingArchive)}>
        <i className="fa-regular fa-eye" aria-hidden="true" /> { I18n.t("front_office.react.streaming_archives_manager.form.buttons.view") }
      </a>
    );
  }

  renderPublishButton(streamingArchive: StreamingArchive): JSX.Element {
    const { isFetching } = this.props;
    const buttonI18nKey = streamingArchive.published ? "unpublish" : "publish";
    const buttonClasses = `btn btn-secondary mr-5 ${isFetching ? "disabled" : ""}`;
    const iconClasses = streamingArchive.published ? "fa-regular fa-xmark" : "fa-regular fa-check";

    return (
      <a className={buttonClasses} href="#" onClick={this.togglePublishHandler(streamingArchive)}>
        <i className={iconClasses} aria-hidden="true" /> { I18n.t(`front_office.react.streaming_archives_manager.form.buttons.${buttonI18nKey}`) }
      </a>
    );
  }

  renderDeleteButton(streamingArchive: StreamingArchive): JSX.Element {
    const { isFetching, accesspointId, accesspoint, deleteStreamingArchive } = this.props;
    const usedForLive = streamingArchive._id === accesspoint.streaming_archive_used_for_live_from_file_id;

    return (
      <ConfirmableButton
        disabled={ isFetching || usedForLive }
        onConfirm={ (): void => deleteStreamingArchive(urlEventId(), accesspointId, streamingArchive._id) }
        tooltipMessage={ usedForLive ? I18n.t("front_office.react.streaming_archives_manager.form.buttons.not_destroyable_tooltip") : I18n.t("remove")}
      />
    );
  }

  renderDownloadButton(streamingArchive: StreamingArchive): JSX.Element {
    if (streamingArchive.extension === "m3u8") return null;

    const { isFetching } = this.props;
    const disabled = (streamingArchive.status !== STREAMING_ARCHIVE_UPLOADED_STATUS) || isFetching;
    const link = disabled ? "" : streamingArchive.download_url;

    return (
      <a className={ `btn btn-secondary mr-5 ${disabled ? "disabled" : ""}`} href={ link }>
        <i className="fa-regular fa-download" aria-hidden="true" /> { I18n.t("front_office.react.streaming_archives_manager.form.buttons.download") }
      </a>
    );
  }

  renderStreamingArchiveItem(streamingArchive: StreamingArchive): JSX.Element {
    return (
      <div className="card mb-10" key={ `replay-item-${streamingArchive._id}` }>
        <div className="card-body row" style={{ padding: "8px" }}>
          <div className="row align-items-center gx-2 flex-nowrap">
            <div className="col-auto text-center" style={{ minWidth: "90px" }}>{ this.renderRankCell() }</div>
            <div className="col-2">
              { I18n.t(`front_office.react.streaming_archives_manager.form.statuses.${streamingArchive.status}`) }
              { this.renderTooltipInformation(I18n.t(`front_office.react.streaming_archives_manager.form.statuses.${streamingArchive.status}_info`)) }
            </div>
            <div className="col-1 text-center">{ this.renderPublishedIcon(streamingArchive) }</div>
            <div className="col-2">{ moment(streamingArchive.updated_at).format("lll") }</div>
            <div className="col-1">{ streamingArchive.extension }</div>
            <div className="col-5">
              { this.renderViewButton(streamingArchive) }
              { this.renderDownloadButton(streamingArchive) }
              { this.renderPublishButton(streamingArchive) }
              { this.renderDeleteButton(streamingArchive) }
            </div>
          </div>
        </div>
        { this.renderArchivePlayer(streamingArchive) }
      </div>
    );
  }

  renderArchivePlayer(archive: StreamingArchive): JSX.Element {
    if (!this.archiveInPlayer(archive)) return null;

    const videoJsOptions = {
      autoplay: true,
      controls: true,
      sources: [{
        src: archive.public_url,
        type: archive.extension === "mp4" ? "video/mp4" : "application/x-mpegurl"
      }],
      width: 320,
      height: 240
    };

    return (
      <div>
        <VideoPlayer {...videoJsOptions} />
      </div>
    );
  }

  s3UploadURL(): string {
    const { accesspointId } = this.props;
    return `/api/v1/events/${urlEventId()}/accesspoints/${accesspointId}/streaming_archives/s3_upload_url`;
  }

  renderAddNewButton(): JSX.Element {
    const { isFetching } = this.props;

    return (
      <GenericS3Uploader
        acceptedFiles={ [".mp4"] }
        classNameUploader="streaming-archives-uploader-zone btn btn-primary"
        disabled={ isFetching }
        onFinishUpload={ this.addStreamingArchive }
        publicRead={ false }
        s3UploadURL={ this.s3UploadURL() }
        styleUploader={ { display: "flex", alignItems: "center", justifyContent: "center" } }
      >
        <div>
          <i className="fa-regular fa-plus" /> { I18n.t("front_office.react.streaming_archives_manager.form.buttons.add_archive") }
        </div>
      </GenericS3Uploader>
    );
  }

  renderHeader(): JSX.Element {
    return (
      <thead>
        <tr className="row align-items-center flex-nowrap no-margin" style={{ width: "100%" }}>
          <th className="col-auto" style={{ minWidth: "90px" }}>{ I18n.t("front_office.react.streaming_archives_manager.form.columns.rank") }</th>
          <th className="col-2">{ I18n.t("front_office.react.streaming_archives_manager.form.columns.status") }</th>
          <th className="col-1 text-center">{ I18n.t("front_office.react.streaming_archives_manager.form.columns.published") }</th>
          <th className="col-2">{ I18n.t("front_office.react.streaming_archives_manager.form.columns.updated_at") }</th>
          <th className="col-1">{ I18n.t("front_office.react.streaming_archives_manager.form.columns.extension") }</th>
          <th className="col-5">{ I18n.t("front_office.react.streaming_archives_manager.form.columns.actions") }</th>
        </tr>
      </thead>
    );
  }

  render(): JSX.Element {
    const { isFetching } = this.props;
    const { streamingArchives } = this.state;
    const sortedStreamingArchives = streamingArchives && streamingArchives.sort((a, b) => a.rank - b.rank);
    const rows = sortedStreamingArchives && sortedStreamingArchives.map(streamingArchive => this.renderStreamingArchiveItem(streamingArchive));
    const disabled = isFetching ? "disabled" : "";

    return (
      <div className={ disabled } style={{ padding: "20px", overflow: "hidden" }}>
        <table className="table table-streaming-archives mt-10">
          { this.renderHeader() }
        </table>
        <div style={{ overflow: "hidden" }}>
          <Sortable itemIdKey="_id"
            itemIndexKey="rank"
            dragType={ DragTypes.STREAMING_ARCHIVE }
            items={ sortedStreamingArchives }
            handleDrop={ this.handleDrop }
            handlePosition="left"
            fullyDraggable={ true }>
            { rows }
          </Sortable>
        </div>
        { this.renderAddNewButton() }
      </div>
    );
  }
}

export default StreamingArchivesForm;
