import { useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "../../shared/DatePicker.react";
import withProgramFilter from "../withProgramFilter.react";
import { FilterProps } from "../../../types/Program";

const MOMENT_DATE_FORMAT = "YYYY-MM-DD";

const DateRangeFilter: React.FC<FilterProps> = ({ programFilter, onChangeFilterPresets }) => {
  const metadata = useSelector((state: any) => state.programs.metadata);

  const onChangeDatePicker = (field: string): (value: string) => void => {
    return (value): void => {
      const selectedDate = value ? moment(value).format(MOMENT_DATE_FORMAT) : null;
      onChangeFilterPresets(field, selectedDate ? selectedDate.substring(0, 10) : null);
    };
  };

  const metadatum = (key: string): string => {
    if (!metadata[programFilter._type]) return "";

    return metadata[programFilter._type][key];
  };

  const renderDatePicker = (field: string): JSX.Element => {
    const selectedDate = programFilter["preset_args"][field];
    return <DatePicker
      selectedDate={selectedDate ? new Date(selectedDate) : null}
      onChange={onChangeDatePicker(field)}
      minDate={metadatum("min_date") ? new Date(metadatum("min_date")) : null}
      maxDate={metadatum("max_date") ? new Date(metadatum("max_date")) : null}
    />;
  };

  return <div className="row g-2 align-items-center">
    <div className="col-auto">
      <div className="row g-2 align-items-center">
        <label className="col-form-label col-auto">{I18n.t("react.programs.date_range_filter.from")}</label>
        <div className="col-auto" style={{ minWidth: "185px" }}>
          {renderDatePicker("min_date")}
        </div>
      </div>
    </div>
    <div className="col-auto">
      <div className="row g-2 align-items-center">
        <label className="col-form-label col-auto">{I18n.t("react.programs.date_range_filter.to")}</label>
        <div className="col-auto" style={{ minWidth: "185px" }}>
          {renderDatePicker("max_date")}
        </div>
      </div>
    </div>
  </div>;
};

export default withProgramFilter(DateRangeFilter, "selection");
