import { ActionTypes, CALL_API } from "../constants/Constants";

export function fetchWebsitePages(eventId) {
  const { DEV_NULL, RECEIVE_WEBSITE_PAGES_SUCCESS, RECEIVE_WEBSITE_PAGES_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_WEBSITE_PAGES_SUCCESS, RECEIVE_WEBSITE_PAGES_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/website/pages.json`
    }
  };
}

export function fetchWebsitePage(eventId, id) {
  const { DEV_NULL, RECEIVE_WEBSITE_PAGE_SUCCESS, RECEIVE_WEBSITE_PAGE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVE_WEBSITE_PAGE_SUCCESS, RECEIVE_WEBSITE_PAGE_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/website/pages/${id}.json`
    }
  };
}

export function createWebsitePage(eventId, pageParams) {
  const { DEV_NULL, CREATE_WEBSITE_PAGE_SUCCESS, WEBSITE_PAGE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, CREATE_WEBSITE_PAGE_SUCCESS, WEBSITE_PAGE_FAILURE],
      method: "POST",
      body: { website_page: pageParams },
      endpoint: `/api/v1/events/${eventId}/website/pages.json`
    }
  };
}

export function updateWebsitePage(eventId, pageId, pageParams) {
  const { DEV_NULL, UPDATE_WEBSITE_PAGE_SUCCESS, WEBSITE_PAGE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, UPDATE_WEBSITE_PAGE_SUCCESS, WEBSITE_PAGE_FAILURE],
      method: "PUT",
      body: { website_page: pageParams },
      endpoint: `/api/v1/events/${eventId}/website/pages/${pageId}.json`
    },
    pageId
  };
}

export function destroyWebsitePage(eventId, pageId) {
  const { DEV_NULL, DESTROY_WEBSITE_PAGE_SUCCESS, WEBSITE_PAGE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, DESTROY_WEBSITE_PAGE_SUCCESS, WEBSITE_PAGE_FAILURE],
      method: "DELETE",
      endpoint: `/api/v1/events/${eventId}/website/pages/${pageId}.json`
    },
    pageId
  };
}

export function duplicateWebsitePage(eventId, pageId, pageParams) {
  const { DEV_NULL, DUPLICATE_WEBSITE_PAGE_SUCCESS, WEBSITE_PAGE_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, DUPLICATE_WEBSITE_PAGE_SUCCESS, WEBSITE_PAGE_FAILURE],
      method: "POST",
      body: { website_page: pageParams },
      endpoint: `/api/v1/events/${eventId}/website/pages/${pageId}/duplicate.json`
    }
  };
}
