import { ActionTypes } from "../constants/Constants";

const defaultState = {
  isFetching: false,
  data: null,
  successfullySaved: false,
  errors: null,
};

function mobinetworkConfiguration(state = defaultState, action) {
  switch (action.type) {
  case ActionTypes.FETCH_MOBINETWORK_CONFIGURATION_REQUEST:
    return { ...state, isFetching: true };
  case ActionTypes.FETCH_MOBINETWORK_CONFIGURATION_SUCCESS:
    return { ...state, data: action.data, isFetching: false };
  case ActionTypes.LEAD_QUALIFICATION_FIELDS_SOFT_UPDATE:
    return { ...state, data: { ...state.data, lead_qualification_fields: action.data.lead_qualification_fields } };
  case ActionTypes.UPDATE_MOBINETWORK_CONFIGURATION_REQUEST:
    return { ...state, successfullySaved: false, errors: null };
  case ActionTypes.UPDATE_MOBINETWORK_CONFIGURATION_SUCCESS:
    return { ...state, data: { ...state.data, lead_qualification_fields: action.data.lead_qualification_fields }, successfullySaved: true };
  case ActionTypes.UPDATE_MOBINETWORK_CONFIGURATION_ERROR:
    return { ...state, errors: action.data, successfullySaved: false };
  default:
    return state;
  }
}

export default mobinetworkConfiguration;
