import { Modal } from "react-bootstrap";
import SessionRoomForm from "./SessionRoomForm.react";

interface Props {
  sessionRoomId: string;
  isVisible: boolean;

  onClose: () => void;
}

const SessionRoomFormModal: React.FC<Props> = ({ sessionRoomId, isVisible, onClose }) => {
  return (
    <Modal show={isVisible} size="lg" onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{I18n.t("react.session_room.form.edit_session_room")}</Modal.Title>
        <button type="button" onClick={onClose} className="btn-close" aria-label={I18n.t("close")}></button>
      </Modal.Header>
      <Modal.Body>
        <SessionRoomForm inModal={true} sessionRoomId={sessionRoomId} afterSessionRoomUpdated={onClose}/>
      </Modal.Body>
    </Modal>
  );
};

export default SessionRoomFormModal;
