import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import Loader from "../../components/shared/Loader.react";
import EmailTemplatePickerModal from "../../components/shared/EmailTemplatePickerModal.react";

class EmailTemplatePicker extends Component {
  constructor(props) {
    super(props);
    const privateMethods = [
      "onSelectTemplate",
      "toggleModal"
    ];
    privateMethods.forEach(item => this[item] = this[item].bind(this));
    this.state = {
      selectedTemplate: null,
      displayModal: false
    };
  }

  componentDidUpdate(prevProps) {
    const { templates } = this.props;
    const { selectedTemplate } = this.state;
    if (templates && templates.length > 0 && prevProps.defaultTemplateId && !selectedTemplate) {
      // set default template if none has been selected
      const template = templates.find(template => template._id === prevProps.defaultTemplateId);
      this.setState({ selectedTemplate: template });
    }
  }

  onSelectTemplate(templateId) {
    const { templates } = this.props;
    const selectedTemplate = templates.find(template => template._id === templateId);
    this.setState({
      selectedTemplate,
      displayModal: false
    });
    this.props.onSelectTemplate(selectedTemplate);
  }

  toggleModal() {
    this.setState({ displayModal: !this.state.displayModal });
  }

  render() {
    const { displayModal, selectedTemplate } = this.state;
    const { defaultTemplateId, templates, locale, eventId, guestId, selectedTemplateId } = this.props;

    if (!templates) return <Loader />;

    return (
      <Dropdown>
        <Dropdown.Toggle onClick={this.toggleModal} variant="secondary">
          {selectedTemplate ? selectedTemplate.name : I18n.t("react.email_template_picker.choose_email")}
        </Dropdown.Toggle>
        <EmailTemplatePickerModal
          templates={templates}
          isVisible={displayModal}
          onClose={this.toggleModal}
          locale={locale}
          eventId={eventId}
          onSelect={this.onSelectTemplate}
          guestId={guestId}
          selectedTemplateId={selectedTemplateId || defaultTemplateId}
        />
      </Dropdown>
    );
  }
}

function mapStateToProps(state) {
  return {
    templates: state.emailTemplate.templates
  };
}

const mapDispatchToProps = {};

EmailTemplatePicker.defaultProps = {
  defaultTemplateId: null,
  templates: []
};

EmailTemplatePicker.propTypes = {
  onSelectTemplate: PropTypes.func.isRequired,
  defaultTemplateId: PropTypes.string,
  modalPicker: PropTypes.bool,
  eventId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  guestId: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplatePicker);
