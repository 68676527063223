import { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import GuestFieldForm from "../../components/guest_field/GuestFieldForm.react";

import { showNotice } from "../../actions/NoticeActionCreators";
import { fetchGuestFieldTypes, receiveGuestField, createGuestField, updateGuestField, calculateFieldValue } from "../../actions/GuestFieldsActionCreators";


class GuestFieldFormContainer extends Component {

  constructor(props) {
    super(props);
    const methods = [
      "getGuestField",
      "onSubmit"
    ];
    methods.forEach((item) => {
      this[item] = this[item].bind(this);
    });
  }

  componentDidUpdate(prevProps) {
    const { showNotice, hasChanges } = this.props;
    if (!prevProps.hasChanges && hasChanges) {
      showNotice(I18n.t("react.guest_field.form.guest_field_modified_notice"), "success", 2500);
    }
  }

  componentDidMount() {
    const { fetchGuestFieldTypes, receiveGuestField, match } = this.props;
    fetchGuestFieldTypes(match.params.event_id);
    if (match.params.guest_field_id) {
      receiveGuestField(match.params.event_id, match.params.guest_field_id);
    }
  }

  getGuestField() {
    const { guestFields, match } = this.props;
    if (match.params.guest_field_id && !isEmpty(guestFields)) {
      const guestField = guestFields.find(gf => gf._id === match.params.guest_field_id);
      if (!guestField) {
        return {};
      }
      return guestField;
    }
    return {};
  }

  onSubmit() {
    const { match, createGuestField, updateGuestField } = this.props;
    const { params } = match;
    return (guestField, needToRedirect = true, path = null) => {
      if (!guestField.persisted) {
        createGuestField(params.event_id, guestField, path);
      } else {
        // when updating, prevent key and type to be modified
        delete params["key"];
        delete params["type"];
        const redirectTo = needToRedirect ? path : null;
        updateGuestField(params.event_id, params.guest_field_id, guestField, redirectTo);
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }

  render() {
    const { guestFieldsTypes, errors, match, calculateFieldValue, calculatedFieldPreview, calculatedFieldPreviewIsLoading, event } = this.props;
    const { event_id } = match.params;
    return <GuestFieldForm
      errors={errors}
      eventId={event_id}
      event={event}
      guestField={this.getGuestField()}
      guestFieldsTypes={guestFieldsTypes}
      calculateFieldValue={calculateFieldValue}
      previewCalculatedField={calculatedFieldPreview}
      calculatedFieldPreviewIsLoading={calculatedFieldPreviewIsLoading}
      onSubmit={this.onSubmit()}/>;
  }
}

const mapDispatchToProps = {
  fetchGuestFieldTypes,
  createGuestField,
  receiveGuestField,
  updateGuestField,
  showNotice,
  calculateFieldValue
};

function mapStateToProps(state) {
  return {
    guestFieldsTypes: state.guestFields.types,
    guestFields: state.guestFields.guestFields,
    event: state.event,
    errors: state.guestFields.errors,
    hasChanges: state.guestFields.hasChanges,
    calculatedFieldPreview: state.guestFields.calculatedFieldPreview,
    calculatedFieldPreviewIsLoading: state.guestFields.pendingRequest
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestFieldFormContainer);
