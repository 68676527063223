import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import Loader from "../../components/shared/Loader.react";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import { fetchWorkflows, createWorkflow, deleteWorkflow } from "../../actions/WorkflowsActionCreators";
import { urlEventId, pathToWorkflowBuilder, pathToWorkflowRuns } from "../../utils/pathUtils";
import { Workflow } from "../../types/Workflow";
import { redirectIfUnauthorized, isSuperAdmin } from "../../utils/aclUtils";
import { hasAvailableFeature } from "../../utils/availableFeature";

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.workflows.index.${key}`, opts);
}

const Workflows: React.FC = () => {
  redirectIfUnauthorized("configuration", "manage");

  // TODO : remove this condition once released
  if (!isSuperAdmin() && !hasAvailableFeature("automation")) {
    return null;
  }

  const workflows = useSelector((state: any) => state.workflows.data);
  const errors = useSelector((state: any) => state.workflows.errors);
  const isPendingRequest = useSelector((state: any) => state.workflows.isPendingRequest);
  const newWorkflowId = useSelector((state: any) => state.workflows.newWorkflowId);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWorkflows(urlEventId()));
  }, []);

  useEffect(() => {
    if (newWorkflowId) {
      return window.location.href = pathToWorkflowBuilder(newWorkflowId);
    }
  }, [newWorkflowId]);

  const onCreateHandler = (): void => {
    dispatch(createWorkflow(urlEventId(), {}, { notice: i18n("workflow_created"), noticeType: "success" }));
  };

  const onDeleteHandler = (workflowId: string): (() => void) => {
    return (): void => {
      if (confirm(i18n("deletion_confirm"))) {
        dispatch(deleteWorkflow(urlEventId(), workflowId, { notice: i18n("workflow_deleted"), noticeType: "success" }));
      }
    };
  };

  const renderCreationButton = (): JSX.Element => {
    if (isPendingRequest) {
      return <Link to="#" onClick={null} className="btn btn-primary disabled" tabIndex="-1" role="button" aria-disabled="true">
        <i className="fa-regular fa-plus"></i> {i18n("create_workflow")}
      </Link>;
    }

    return <Link to="#" onClick={onCreateHandler} className="btn btn-primary" role="button">
      <i className="fa-regular fa-plus"></i> {i18n("create_workflow")}
    </Link>;
  };

  const renderDeletionButton = (workflow: Workflow): JSX.Element => {
    if (workflow.enabled) {
      const tooltipMsg = <Tooltip id="tooltip-delete-program">{i18n("cant_delete_tooltip")}</Tooltip>;

      return <OverlayTrigger placement="top" overlay={tooltipMsg}>
        <span>
          <Link to="#" onClick={null} className="btn btn-danger btn-sm disabled" tabIndex="-1" role="button" aria-disabled="true">
            <i className="fa-regular fa-trash-can"></i>
          </Link>
        </span>
      </OverlayTrigger>;
    }

    if (isPendingRequest) {
      return <Link to="#" onClick={null} className="btn btn-danger btn-sm disabled" tabIndex="-1" role="button" aria-disabled="true">
        <i className="fa-regular fa-trash-can"></i>
      </Link>;
    }

    return <Link to="#" onClick={onDeleteHandler(workflow._id)} className="btn btn-danger btn-sm" role="button">
      <i className="fa-regular fa-trash-can"></i>
    </Link>;
  };

  const renderWorkflowRunsHistoryButton = (workflowId = null): JSX.Element => {
    const url = workflowId ? pathToWorkflowRuns(workflowId) : pathToWorkflowRuns();
    const btnClasses = classNames({
      "btn btn-secondary": true,
      "btn-sm": !!workflowId,
      "me-1": !!workflowId
    });

    return <Link to={url} className={btnClasses} tabIndex="-1" role="button" aria-disabled="true">
      <i className="fa-regular fa-clock-rotate-left"></i> {i18n("history")}
    </Link>;
  };

  const renderWorkflowStatus = (workflow: Workflow): JSX.Element => {
    if (workflow.enabled) {
      return <span className="badge rounded-pill bg-success"><i className="fa fa-regular fa-check"></i> {i18n("active")}</span>;
    } else {
      return <span className="badge rounded-pill bg-secondary"><i className="fa fa-regular fa-xmark"></i> {i18n("inactive")}</span>;
    }
  };

  const renderWorkflowDescription = (workflow: Workflow): JSX.Element => {
    if (workflow.online_version_id) {
      return <div className="mt-1">{I18n.t(`automation.triggers.full.${workflow.trigger_event}`)}</div>;
    } else {
      return <div className="mt-1"><em>{i18n("no_published_version")}</em></div>;
    }
  };

  const renderWorkflowsList = (): JSX.Element => {
    return <>
      <div className="collection-pagination">
        <div className="count">
          <span id="count-label" dangerouslySetInnerHTML={{ __html: i18n("workflows_count", { count: workflows.length }) }}></span>
        </div>
      </div>
      <div className="table-container">
        <table className="table table-light table-bordered">
          <thead>
            <tr>
              <th>{i18n("name")}</th>
              <th>{i18n("creation_date")}</th>
              <th>{i18n("status")}</th>
              <th>{i18n("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {workflows.map((workflow: Workflow) => {
              return <tr key={workflow._id}>
                <td>
                  <a href={pathToWorkflowBuilder(workflow._id)} className="text-default fw-bold">{workflow.name}</a>
                  {renderWorkflowDescription(workflow)}
                </td>
                <td>{moment(workflow.created_at).format("LLL")}</td>
                <td>{renderWorkflowStatus(workflow)}</td>
                <td>
                  {renderWorkflowRunsHistoryButton(workflow._id)}
                  {renderDeletionButton(workflow)}
                </td>
              </tr>;
            })}
          </tbody>
        </table>
      </div>
    </>;
  };

  const renderNoWorkflows = (): JSX.Element => {
    return <div className="card nothing-yet">
      <div>
        <i className="fa-regular fa-bolt fa-xl"></i>
      </div>
      <h4>{i18n("no_workflows")}</h4>
      <div>{renderCreationButton()}</div>
    </div>;
  };

  if (!workflows) {
    return <Loader size="large" inline={false} containerHeight="100%" />;
  }

  return (
    <div className="row workflows-index">
      <div className="col-12">
        <div className="header-page">
          <div className="header-page-content row">
            <div className="header-page-title col-sm-9">
              <h1>{i18n("title")}</h1>
            </div>
            <div className="header-page-btn col-sm-3">
              {workflows.length > 0 ? renderCreationButton() : null}
              {workflows.length > 0 ? renderWorkflowRunsHistoryButton() : null}
            </div>
          </div>
        </div>
        <ErrorMessage errors={errors} model="workflow" />
        { workflows.length > 0 ? renderWorkflowsList() : renderNoWorkflows() }
      </div>
    </div>
  );
};

export default Workflows;
