import { ActionTypes } from "../constants/Constants";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";

const defaultState = {
  data: [],
  duplicationErrors: {},
  duplicated: null,
  duplicationWarnings: [],
  nextURL: null,
  previousURL: null,
  loading: true,
  newRegistrationFormId: null
};

function registrationForms(state = defaultState, action) {
  switch (action.type) {
  case ActionTypes.REGISTRATION_FORMS_REQUEST:
    return Object.assign({}, state, { loading: true });
  case ActionTypes.REGISTRATION_FORMS_SUCCESS:
    state = Object.assign({}, state, { data: action.data, nextURL: action.nextURL, previousURL: action.previousURL, loading: false }); // data is injected in the action by the api middleware
    return state;
  case ActionTypes.REGISTRATION_FORM_CREATE_SUCCESS: {
    const data = insertOrUpdateObject(state.data, action.data, "_id", false);
    return Object.assign({}, state, { data, duplicationErrors: {}, duplicationWarnings: [], newRegistrationFormId: action.data._id });
  }
  case ActionTypes.REGISTRATION_FORM_DUPLICATE_SUCCESS: {
    const currentEventId = state.data && state.data[0] && state.data[0].event_id;
    const actionEventId = action.data && action.data.event_id;

    // If the form is duplicated to another event, we don't want to insert it in the current event forms list
    const data = currentEventId === actionEventId ? insertOrUpdateObject(state.data, action.data, "_id", false) : [...state.data];

    return Object.assign({}, state, { data, duplicationErrors: {}, duplicationWarnings: action.data.duplication_warnings || [], duplicated: action.data });
  }
  case ActionTypes.REGISTRATION_FORM_DUPLICATE_FAILURE:
    return Object.assign({}, state, { duplicationErrors: action.data, duplicationWarnings: [] });
  case ActionTypes.REGISTRATION_FORM_REQUEST:
    return Object.assign({}, state, { loading: true });
  case ActionTypes.REGISTRATION_FORM_SUCCESS: {
    // data received here went through a schema normalization
    const data = insertOrUpdateObject(state.data, action.data.entities.registrationForm[action.data.result], "_id");
    return Object.assign({}, state, { data, loading: false });
  }
  case ActionTypes.REGISTRATION_FORM_DELETE_REQUEST: {
    const data = state.data.filter(registrationForm => {
      return registrationForm._id != action.registrationId;
    });
    return Object.assign({}, state, { data });
  }
  case ActionTypes.REGISTRATION_FORM_UPDATE_SUCCESS:
  case ActionTypes.REGISTRATION_FORM_DELETE_CUSTOM_CSS_SUCCESS: {
    const data = insertOrUpdateObject(state.data, action.data, "_id");
    return Object.assign({}, state, { data });
  }
  default:
    return state;
  }
}

export default registrationForms;
