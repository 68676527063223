import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createGuestProductCollection } from "../../actions/GuestProductCollectionActionCreators";
import { urlEventId } from "../../utils/pathUtils";

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`events.edit_advanced.guest_product_collections.${key}`, options);
};

const CreateNewGuestProductCollectionForm: React.FC = () => {
  const isSaving = useSelector((state: any) => state.guestProductCollection.isSaving);
  const successfullyCreated: boolean = useSelector((state: any) => state.guestProductCollection.successfullyCreated);

  const [isInputCreateCollectionDisplay, setIsInputCreateCollectionDisplay] = useState<boolean>(false);
  const [newCollectionName, setNewCollectionName] = useState<string>("");

  useEffect(() => {
    if (successfullyCreated) setIsInputCreateCollectionDisplay(!isInputCreateCollectionDisplay);
  }, [successfullyCreated]);

  const dispatch = useDispatch();

  const onCreateCollection = (): void => {
    dispatch(createGuestProductCollection(urlEventId(), { guest_product_collection: { name: newCollectionName } }));
  };

  const toggleInputCreateCollection = (): void => {
    setIsInputCreateCollectionDisplay(!isInputCreateCollectionDisplay);
  };

  const renderAddNewCollectionButton = (): JSX.Element => {
    if (isInputCreateCollectionDisplay) return null;

    return <button type="button" className="btn btn-secondary mt-3" onClick={toggleInputCreateCollection}>
      <i className="fa-regular fa-plus"></i> { i18n("add_new_collection") }
    </button>;
  };

  const onSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    onCreateCollection();
  };

  const renderInputNewCollection = (): JSX.Element => {
    if (!isInputCreateCollectionDisplay) return null;

    return <form className="row align-items-center g-2 mt-3" onSubmit={onSubmit}>
      <div className="col-auto mt-0">
        <input
          type="text"
          className="form-control"
          placeholder={i18n("collection_name")}
          onChange={(e): void => setNewCollectionName(e.target.value)}
          autoFocus={true}
        />
      </div>
      <div className="col-auto mt-0">
        <input
          type="submit"
          className="btn btn-primary"
          disabled={isSaving}
          value={ I18n.t("add") }
        />
        <button className="btn btn-secondary ml-5" onClick={toggleInputCreateCollection}>{ I18n.t("cancel") }</button>
      </div>
    </form>;
  };

  return <div>
    {renderInputNewCollection()}
    {renderAddNewCollectionButton()}
  </div>;
};

export default CreateNewGuestProductCollectionForm;
