import { ActionTypes } from "../constants/Constants";
import sortBy from "lodash/sortBy";

function subFormItems(state = { data: null, requestPending: false, errorMessage: null }, action) {
  switch (action.type) {
  case ActionTypes.REGISTRATION_FORM_ITEMS_FETCH_REQUEST:
    return Object.assign({}, {
      data: null,
      requestPending: true,
      errorMessage: null
    });
  case ActionTypes.REGISTRATION_FORM_ITEMS_FETCH_SUCCESS:
    return Object.assign({}, {
      data: sortBy(action.data, ["rank"]),
      requestPending: false,
      errorMessage: null
    });
  case ActionTypes.REGISTRATION_FORM_ITEMS_FETCH_FAILURE:
    return Object.assign({}, {
      data: [],
      requestPending: false,
      errorMessage: I18n.t("react.form_items.subform.copy_fields_error")
    });
  default:
    return state;
  }
}

export default subFormItems;
