import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchExhibitorByNumberOfLeads } from "../../actions/ExhibitorActionCreators";
import Loader from "../../components/shared/Loader.react";
import ReportHeader from "../../components/event_reports/ReportHeader.react";
import { isAuthorized } from "../../utils/aclUtils";
import Select from "react-select";
import { Dropdown } from "react-bootstrap";
import uniqBy from "lodash/uniqBy";

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`react.event_reports.exhibitors_by_number_of_leads.${key}`, options);
};

const ExhibitorsByNumberOfLeads: React.FC = () => {
  const eventId = useSelector((state: any) => state.event.id);
  const dataFetched = useSelector((state: any) => state.exhibitorsStatistics.byNumberOfLeads);
  const isFetching = useSelector((state: any) => state.exhibitorsStatistics.isFetching);
  const dispatch = useDispatch();
  const [exhibitorSelected, setExhibitorSelected] = useState(null);
  const [leadSourceSelected, setLeadSourceSelected] = useState(null);
  const [result, setResult] = useState([]);
  const [sortField, setSortField] = useState("nb_leads");

  if (!isAuthorized("reports", "read")) return null;

  useEffect(() => {
    if (!eventId) return;
    dispatch(fetchExhibitorByNumberOfLeads(eventId, sortField));
  }, [eventId, sortField]);

  useEffect(() => {
    if (!dataFetched) return;
    setResult(dataFetched);
  }, [dataFetched]);

  useEffect(() => {
    if (!exhibitorSelected && !leadSourceSelected) {
      dataFetched && setResult(dataFetched);
      return;
    }

    const resultFiltered = dataFetched.filter(item => {
      let res = exhibitorSelected ? item.company_name === exhibitorSelected : true;
      res &&= leadSourceSelected ? item.lead_source === leadSourceSelected : true;
      return res;
    });
    setResult(resultFiltered);
  }, [exhibitorSelected, leadSourceSelected]);


  const exporterProps = (): any => {
    return {
      data: {
        data: result.map(
          line => {
            return {
              exhibitor: line.company_name,
              lead_source: line.lead_source,
              nb_leads: line.nb_leads,
            };
          }
        )
      },
      options: { excludeTotal: true },
      columns: ["exhibitor", "lead_source", "nb_leads"]
    };
  };

  const renderHeader = (): JSX.Element => {
    return <ReportHeader
      title={I18n.t("react.event_reports.event_reports.exhibitors_by_number_of_leads")}
      exporterToExcelProps={result?.length > 0 ? exporterProps() : null}
      exportType="exhibitors_by_number_of_leads"
    />;
  };

  const renderSortDropdown = (): JSX.Element => {
    const rows = ["nb_leads", "lead_source", "company_name"].map(sort => {
      return <Dropdown.Item key={sort} onClick={(): void => setSortField(sort)}>{i18n(`sort_by_${sort}`)}</Dropdown.Item>;
    });

    return <div className="float-end">
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-sort">
          {i18n(`sort_by_${sortField}`)}
        </Dropdown.Toggle>
        <Dropdown.Menu
          align="end"
          popperConfig={{
            strategy: "fixed",
            onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll"))
          }}
        >
          {rows}
        </Dropdown.Menu>
      </Dropdown>
    </div>;
  };

  const renderExhibitorsSelect = (): JSX.Element => {
    const exhibitors = uniqBy(
      result.map((line) => ({
        value: line.company_name,
        label: line.company_name,
      })),
      function (object) {
        return object.value;
      }
    );

    return <Select
      className="react-select"
      classNamePrefix="react-select"
      options={exhibitors}
      value={exhibitors?.filter((exhibitor): any => exhibitorSelected === exhibitor.value)}
      placeholder={i18n("select_an_exhibitor")}
      noOptionsMessage={(): any => i18n("no_exhibitor")}
      onChange={(option): any => setExhibitorSelected(option?.value)}
      isClearable={true}
      menuPosition="fixed"
      styles={{
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        menuPortal: ({ left, ...provided }, state) => ({
          ...provided
        })
      }}
    />;
  };

  const renderLeadSourcesSelect = (): JSX.Element => {
    const lead_sources = uniqBy(
      result.map((line) => ({
        value: line.lead_source,
        label: line.lead_source
      })),
      function (object) {
        return object.value;
      }
    );

    return <Select
      className="react-select"
      classNamePrefix="react-select"
      options={lead_sources}
      value={lead_sources?.filter(leadSource => leadSourceSelected?.includes(leadSource.value))}
      placeholder={i18n("select_a_lead_source")}
      noOptionsMessage={(): any => i18n("no_lead_source")}
      onChange={(option): any => setLeadSourceSelected(option?.value)}
      isClearable={true}
      menuPosition="fixed"
      styles={{
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        menuPortal: ({ left, ...provided }, state) => ({
          ...provided
        })
      }}
    />;
  };

  const renderTableHeader = (): JSX.Element => {
    return (
      <>
        <tr>
          <th style={{ fontWeight: "normal" }}>
            {renderExhibitorsSelect()}
          </th>
          <th style={{ fontWeight: "normal" }}>
            {renderLeadSourcesSelect()}
          </th>
          <th>{renderSortDropdown()}</th>
        </tr>
        <tr>
          <th>{I18n.t("react.event_reports.report_table.exhibitor")}</th>
          <th>{I18n.t("react.event_reports.report_table.lead_source")}</th>
          <th>{I18n.t("react.event_reports.report_table.nb_leads")}</th>
        </tr>
      </>
    );
  };

  const renderTable = (): JSX.Element => {
    if (!dataFetched) return null;
    if (result?.length === 0) return renderEmptyMsg();

    return <div className="row">
      <div className="col-12">
        <div className="table-responsive table-container">
          <table className="table table-light table-hover table-sm">
            <thead>
              {renderTableHeader()}
            </thead>
            <tbody>
              {renderTableBody()}
            </tbody>
          </table>
        </div>
      </div>
    </div>;
  };

  const renderTableBody = (): JSX.Element[] => {
    return result.map((line, index) => {
      return (
        <tr key={index}>
          <td>
            {line.company_name}
          </td>
          <td>{line.lead_source}</td>
          <td>{line.nb_leads}</td>
        </tr>
      );
    });
  };

  const renderEmptyMsg = (): JSX.Element => {
    return <div className="card nothing-yet">
      <h4>{i18n("no_data")}</h4>
    </div>;
  };

  return <>
    { renderHeader() }
    { isFetching ? <Loader/> : renderTable() }
  </>;
};

export default ExhibitorsByNumberOfLeads;
