import { ActionTypes } from "../constants/Constants";

const defaultState = {
  data: null,
  selectedTemplateId: null,
  isPendingRequest: false,
  errors: {},
  duplicatedTemplate: null,
  duplicationErrors: {},
  presetsList: null
};

function documentTemplates(state = defaultState, action) {
  switch (action.type) {
  case ActionTypes.DOCUMENT_TEMPLATES_SUCCESS:
    return { ...state, data: action.data, selectedTemplateId: state.selectedTemplateId || action.data[0] && action.data[0]._id };
  case ActionTypes.CREATE_DOCUMENT_TEMPLATE_SUCCESS: {
    return { ...state, data: [...state.data, action.data], errors: {}, selectedTemplateId: action.data._id, isPendingRequest: false };
  }
  case ActionTypes.DELETE_DOCUMENT_TEMPLATE_SUCCESS: {
    const data = state.data.filter(t => t._id !== action.documentTemplateId);
    return { ...state, data, selectedTemplateId: data[0] && data[0]._id, isPendingRequest: false };
  }
  case ActionTypes.CREATE_DOCUMENT_TEMPLATE_PENDING:
  case ActionTypes.DELETE_DOCUMENT_TEMPLATE_PENDING:
    return { ...state, isPendingRequest: true, errors: {} };
  case ActionTypes.CREATE_DOCUMENT_TEMPLATE_FAILURE:
  case ActionTypes.DELETE_DOCUMENT_TEMPLATE_FAILURE:
    return { ...state, isPendingRequest: false, errors: action.data.errors };
  case ActionTypes.DUPLICATE_DOCUMENT_TEMPLATE_PENDING:
    return { ...state, errors: {}, duplicationErrors: {}, duplicatedTemplate: null };
  case ActionTypes.DUPLICATE_DOCUMENT_TEMPLATE_SUCCESS: {
    if (action.duplicateInCurrentEvent) {
      return { ...state, data: [...state.data, action.data], errors: {}, duplicationErrors: {}, duplicatedTemplate: action.data, selectedTemplateId: action.data._id };
    }
    return { ...state, errors: {}, duplicationErrors: {}, duplicatedTemplate: action.data };
  }
  case ActionTypes.DUPLICATE_DOCUMENT_TEMPLATE_FAILURE:
    return { ...state, duplicationErrors: action.data };
  case ActionTypes.UPDATE_DOCUMENT_SELECTED_ID:
    return { ...state, selectedTemplateId: action.data };
  case ActionTypes.FETCH_DOCUMENT_TEMPLATES_PRESETS_LIST_SUCCESS:
    return { ...state, presetsList: action.data };
  default:
    return state;
  }
}

export default documentTemplates;
