import { ActionTypes } from "../constants/Constants";

const initialState = {
  data: null,
  fetching: false,
  errors: null,
  historyItems: [],
  historyItemsNextURL: null,
  fetchingHistory: false,
  activities: [],
  activitiesNextURL: null,
  fetchingActivities: false,
  emailConfirmationLink: null
};

function contact(state = initialState, action) {
  switch (action.type) {
  case ActionTypes.FETCH_CONTACT_REQUEST:
    return Object.assign({}, state, { fetching: true, historyItems: [] });
  case ActionTypes.FETCH_CONTACT_SUCCESS:
    return Object.assign({}, state, { data: action.data, fetching: false, errors: null });
  case ActionTypes.FETCH_CONTACT_FAILURE:
    return Object.assign({}, state, { data: null, fetching: false, errors: action.data });
  case ActionTypes.FETCH_CONTACT_HISTORY_REQUEST:
    return Object.assign({}, state, { fetchingHistory: true, errors: null });
  case ActionTypes.FETCH_CONTACT_HISTORY_SUCCESS: {
    const items = action.resetContactHistory ? action.data : state.historyItems.concat(action.data);
    return Object.assign({}, state, { historyItems: items, historyItemsNextURL: action.nextURL, fetchingHistory: false });
  }
  case ActionTypes.FETCH_CONTACT_HISTORY_FAILURE:
    return Object.assign({}, state, { fetchingHistory: false, errors: action.data });
  case ActionTypes.FETCH_CONTACT_ACTIVITIES_REQUEST:
    return Object.assign({}, state, { fetchingActivities: true, errors: null });
  case ActionTypes.FETCH_CONTACT_ACTIVITIES_SUCCESS: {
    const activities = action.resetContactActivities ? action.data : state.activities.concat(action.data);
    return Object.assign({}, state, { activities: activities, activitiesNextURL: action.nextURL, fetchingActivities: false });
  }
  case ActionTypes.FETCH_CONTACT_ACTIVITIES_FAILURE:
    return Object.assign({}, state, { fetchingActivities: false, errors: action.data });
  case ActionTypes.CLEAR_CONTACT_STATE:
    return initialState;
  case ActionTypes.GENERATE_EMAIL_CONFIRMATION_LINK_SUCCESS:
    return { ...state, emailConfirmationLink: action.data.contact_confirmation_url };
  default:
    return state;
  }
}

export default contact;
