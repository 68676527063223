import { Tooltip } from "react-bootstrap";
import mergeWith from "lodash/mergeWith";
import { PATH_NAME_RESOURCE_ID_REGEX } from "../constants/Constants";

export function translatedTextFromJson(component, key, locale = I18n.locale) {
  return component[`${key}_translations`] && component[`${key}_translations`][locale] || component[key];
}

export function addTooltip(message) {
  return (<Tooltip id="tooltip">{ message }</Tooltip>);
}

export function mergeLocalChanges(globalChanges, localChanges) {
  return mergeWith({}, globalChanges, localChanges, (objValue, srcValue) => {
    if (srcValue instanceof Array)
      return srcValue; // replace array instead of merging it (default behavior) which would give wrong values
  });
}

export function addSection(sectionTypeKey, sections, sectionTypes, createNewSection, locale, newSectionAddedTo = null) {
  const sectionType = sectionTypes.find(s => s.filename == sectionTypeKey);
  const sectionComponents = sectionType.schema.settings || [];
  // Generate new section ID
  let randomId;
  do {
    randomId = sectionTypeKey + "-" + Math.floor(Math.random() * 100000);
  } while (sections[randomId]);

  // Initialize default settings data for new section
  const defaultSettings = sectionComponents.reduce((acc, component) => {
    if (component.id === undefined) return acc;
    acc[component.id] = translatedTextFromJson(component, "default", locale);
    if (acc[component.id] === undefined) acc[component.id] = "";
    return acc;
  }, {});
  // Initialize default blocks from schema if necessary
  let blocksSettings;
  if (sectionType.schema.blocks && sectionType.schema.presets && sectionType.schema.presets[0].blocks) {
    blocksSettings = sectionType.schema.blocks.reduce((acc, block) => {
      sectionType.schema.presets[0].blocks.filter(b => b.type == block.type).map(() => {
        const randomBlockId = "bloc-" + Math.floor(Math.random() * 100000);
        const defaultBlockSettings = block.settings.reduce((acc2, component) => {
          acc2[component.id] = translatedTextFromJson(component, "default", locale);
          if (acc2[component.id] === undefined) acc2[component.id] = "";
          return acc2;
        }, {});
        acc[randomBlockId] = { type: block.type, settings: defaultBlockSettings };
        return acc;
      });
      return acc;
    }, {});
  }
  const sectionData = { type: sectionTypeKey, settings: defaultSettings };
  if (blocksSettings) {
    sectionData.blocks = blocksSettings;
    sectionData.blocks_order = Object.keys(blocksSettings);
  }

  newSectionAddedTo ? createNewSection(randomId, sectionData, newSectionAddedTo) : createNewSection(randomId, sectionData);

  return randomId;
}

export function replaceResourceIdWithPlaceholderId(valueWithResourceId) {
  if (!valueWithResourceId) return null;
  return valueWithResourceId.replace(PATH_NAME_RESOURCE_ID_REGEX, "__id__");
}

export function replacePlaceholderIdWithResourceId(value, resourceId) {
  if (!value || !resourceId) return null;
  return value.replace("__id__", resourceId);
}
