import { Component } from "react";
import { connect } from "react-redux";
import { fetchGuestProducts, removeGuestProductInSiloedData } from "../actions/GuestProductActionCreators";
import ResourceSearchDropdown from "../components/ResourceSearchDropdown.react";
import { GuestProduct } from "../types/GuestProduct";

interface Props {
  allowMultipleSelect?: boolean;
  fetchGuestProducts(eventId: string, searchQuery: string, page: number, options?: any);
  guestProducts: GuestProduct[];
  onSelectGuestProduct(guestProducts: GuestProduct[]): void;
  removeGuestProductInSiloedData(uniqueKeyInSiloedData: string): void;
  selectedGuestProducts: GuestProduct[];
  selectedGuestProductsIds?: string[];
  siloedData: any;
}

class GuestProductSearchDropdown extends Component<Props> {

  static defaultProps = {
    allowMultipleSelect: false
  };

  constructor(props: Props) {
    super(props);
    [
      "fetchGuestProductFromIds"
    ].forEach((item) => {
      this[item] = this[item].bind(this);
    });
  }

  fetchGuestProductFromIds(eventId: string, searchQuery: string, page: number, options?: any): void {
    const { fetchGuestProducts } = this.props;
    fetchGuestProducts(eventId, searchQuery, page, options);
  }

  render(): JSX.Element {
    const { fetchGuestProducts, guestProducts, onSelectGuestProduct, selectedGuestProducts, selectedGuestProductsIds,
      allowMultipleSelect, siloedData, removeGuestProductInSiloedData } = this.props;
    return (
      <ResourceSearchDropdown
        className="react-select"
        classNamePrefix="react-select"
        allowMultipleSelect={allowMultipleSelect}
        cleanUniqueKeyInSiloedData={removeGuestProductInSiloedData}
        onSelectResource={onSelectGuestProduct}
        requestResources={fetchGuestProducts}
        requestResourcesFromIds={this.fetchGuestProductFromIds}
        resources={guestProducts}
        selectedResources={selectedGuestProducts}
        selectedResourcesIds={selectedGuestProductsIds}
        siloedData={siloedData} />
    );
  }
}

const mapStateToProps = (state: any): any => {
  return {
    guestProducts: state.guestProducts.guestProducts,
    siloedData: state.guestProducts.siloedData
  };
};

const mapDispatchToProps = {
  fetchGuestProducts,
  removeGuestProductInSiloedData
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestProductSearchDropdown);
