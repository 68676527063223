import { useEffect } from "react";
import { Event } from "../../types/Event";
import { GuestCategory } from "../../types/GuestCategory";
import GuestCategoryModal from "../mapping_step/GuestCategoryModal.react";
import ConfirmableButton from "../../components/shared/ConfirmableButton.react";
import CollapsablePanel from "../shared/CollapsablePanel.react";

interface Props {
  event: Event,
  targetEvent: Event,
  guestCategories: GuestCategory[];
  guestCategoryMapping: Record<string, string[]>;
  mappingIncomplete: boolean;

  fetchGuestCategoriesFromTargetEvent: (targetEventId: string) => void;
  updateGuestCategoriesMapping: (targetEventId: string, newMapping: Record<string, string[]>) => void;
  removeTargetEvent: (targetEventId: string) => void;
}

const ExtractFromOtherEventItem: React.FC<Props> = ({
  event,
  targetEvent,
  guestCategoryMapping,
  guestCategories,
  mappingIncomplete,
  fetchGuestCategoriesFromTargetEvent,
  updateGuestCategoriesMapping,
  removeTargetEvent,
}) => {

  useEffect(() => {
    fetchGuestCategoriesFromTargetEvent(targetEvent._id);
  }, []);

  const renderGuestCategoryMapping = (): JSX.Element => {
    const distinctValues = targetEvent.guestCategories ? targetEvent.guestCategories.map(gc => [gc._id, gc.name]) : null;
    return <GuestCategoryModal
      guestCategories={guestCategories}
      isVisible={true}
      displayMode="classic"
      event={event}
      distinctValues={distinctValues}
      baseMapping={guestCategoryMapping}
      useBaseMapping={true}
      clearStateOnSubmit={false}
      hideSubmitButton={true}
      dynamicallyUpdateParent={(newMapping: any): void => updateGuestCategoriesMapping(targetEvent._id, newMapping)}
    />;
  };

  const renderDeleteButton = (): JSX.Element => {
    return <div className="float-end">
      <ConfirmableButton onConfirm={(): void => removeTargetEvent(targetEvent._id)} />
    </div>;
  };

  return (
    <CollapsablePanel
      title={`${targetEvent.title} (${Object.keys(guestCategoryMapping).length})`}
      displayed={Object.keys(guestCategoryMapping).length === 0}
      warningText={mappingIncomplete ? I18n.t("react.extract_from_other_event_item.warning_message") : null}
    >
      {renderGuestCategoryMapping()}
      {renderDeleteButton()}
    </CollapsablePanel>
  );
};

export default ExtractFromOtherEventItem;
