import { ActionTypes, CALL_API } from "../constants/Constants";

function menuFinalParams(menuParams, menuItems) {
  return Object.assign({}, { name: menuParams.name }, { menu_items_attributes: menuItemsFinalParams(menuItems) });
}

function menuItemsFinalParams(menuItems) {
  return menuItems.map(item => {
    // If we try to create an item with errors, api will return _id and next request will try to update an item from this _id,
    // so we remove this attributes sent if item not persisted.
    if (!item.created_at)
      delete item._id;

    // Other attributes must be removed because they're not in strong params and don't need to be
    delete item.created_at;
    delete item.updated_at;
    delete item.menu_item_parent_id;
    delete item.menu_item_parent_type;

    if (item.menu_items.length > 0)
      item.menu_items_attributes = menuItemsFinalParams(item.menu_items);
    delete item.menu_items;

    return item;
  });
}

module.exports = {
  clearSaveQueryStatus() {
    return {
      type: ActionTypes.CLEAR_SAVE_QUERY_STATUS
    };
  },

  clearMenuStore() {
    return {
      type: ActionTypes.CLEAR_MENU_STORE
    };
  },

  fetchMenu(eventId, menuId) {
    const { DEV_NULL, RECEIVE_MENU_SUCCESS } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, RECEIVE_MENU_SUCCESS, DEV_NULL],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/website/menus/${menuId}.json`
      }
    };
  },

  updateMenuItemsOffline(data) {
    return {
      type: ActionTypes.UPDATE_MENU_ITEMS_OFFLINE,
      data
    };
  },

  createMenuOffline() {
    return {
      type: ActionTypes.CREATE_MENU_OFFLINE,
      data: { name: `Navigation ${Math.floor(Math.random() * 100)}` }
    };
  },

  updateMenuOffline(data) {
    return {
      type: ActionTypes.UPDATE_MENU_OFFLINE,
      data
    };
  },

  updateMenu(eventId, menuId, menuParams, menuItems) {
    const { DEV_NULL, UPDATE_MENU_SUCCESS, UPDATE_MENU_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, UPDATE_MENU_SUCCESS, UPDATE_MENU_FAILURE],
        method: "PUT",
        endpoint: `/api/v1/events/${eventId}/website/menus/${menuId}.json`,
        body: { website_menu: menuFinalParams(menuParams, menuItems) }
      }
    };
  },

  createMenu(eventId, menuParams, menuItems) {
    const { DEV_NULL, CREATE_MENU_SUCCESS, CREATE_MENU_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, CREATE_MENU_SUCCESS, CREATE_MENU_FAILURE],
        method: "POST",
        endpoint: `/api/v1/events/${eventId}/website/menus.json`,
        body: { website_menu: menuFinalParams(menuParams, menuItems) }
      }
    };
  },

  deleteMenu(eventId, menuId, notificationsOptions = {}) {
    const { DEV_NULL, DELETE_MENU_SUCCESS } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, DELETE_MENU_SUCCESS, DEV_NULL],
        method: "DELETE",
        endpoint: `/api/v1/events/${eventId}/website/menus/${menuId}.json`,
        notificationsOptions
      },
      menuId
    };
  }

};
