import { Component } from "react";
import { connect } from "react-redux";
import { createSavedSearch, clearSavedSearchCreation, changeCurrentSavedSearch } from "../actions/SavedSearchActionCreators";
import { updateUI } from "../actions/UIAppearanceActionCreators";
import { urlWithQuery } from "../utils/pathUtils";
import { showNotice } from "../actions/NoticeActionCreators";
import querystring from "querystring";
import withModal from "./withModal.react";

import { queryStringAndSelectedGuests } from "../utils/QueryStringUtils";

export class SaveReport extends Component {
  constructor(props) {
    super(props);
    this._onChangeName = this._onChangeName.bind(this);
    this._saveQuery = this._saveQuery.bind(this);
    this._renderDetailsMessage = this._renderDetailsMessage.bind(this);
    this.state = { queryName: "", errors: null, redirectTo: null };
  }

  componentDidMount() {
    const { clearSavedSearchCreation } = this.props;
    const updates = {
      "modalTitle": I18n.t("react.reports.save_search")
    };
    clearSavedSearchCreation();
    this.setState({ queryName: "", errors: null, redirectTo: null });
    this.props.updateUI(updates);
  }

  componentDidUpdate() {
    const { savedSearchCreation, changeCurrentSavedSearch, clearSavedSearchCreation } = this.props;
    if (savedSearchCreation) {
      if (savedSearchCreation.persisted) {
        const { location, showNotice, history } = this.props;
        const { redirectTo } = this.state;
        changeCurrentSavedSearch(savedSearchCreation.savedSearch);
        clearSavedSearchCreation();
        this.setState({ queryName: "", errors: null });
        const { q } = querystring.parse(location.search.substring(1));
        if (!redirectTo) history.push(urlWithQuery(q, "guests"));
        showNotice(I18n.t("react.saved_report_form_component.notification_report"));
      } else if (!this.state.errors && savedSearchCreation.errors && Object.keys(savedSearchCreation.errors).length > 0) {
        this.setState({ errors: savedSearchCreation.errors });
      }
    }
  }

  _isCreatingSavedSearchForCampaign() {
    const { query } = this.props.location || {};
    if (!query) {
      return false;
    }
    return query.go_to === "campaign";
  }

  _saveQuery(e) {
    e.preventDefault();
    const { createSavedSearch, match, location, selectedGuests } = this.props;
    const { queryName } = this.state;

    const { q } = querystring.parse(location.search.substring(1));
    let redirectTo = null;
    if (this._isCreatingSavedSearchForCampaign()) {
      redirectTo = urlWithQuery(q, "campaign/information");
    }

    createSavedSearch(match.params.event_id, queryName, queryStringAndSelectedGuests(location, selectedGuests), match.params.locale, redirectTo);
    this.setState({ errors: null, redirectTo: redirectTo });
  }

  _onChangeName(event) {
    this.setState({ queryName: event.target.value });
  }

  _renderDetailsMessage() {
    if (this._isCreatingSavedSearchForCampaign()) {
      return (
        <p> { I18n.t("react.saved_report_form_component.campaign_detail_text")} </p>
      );
    }
    return null;
  }

  render() {
    const { errors, queryName } = this.state;
    let errorClass, errorMessage = null;
    if (errors && errors["name"]) {
      errorClass = "has-error";
      errorMessage = errors["name"].join(", ");
    }
    return (
      <div>
        <form onSubmit={this._saveQuery}>
          <div className={`mb-3 ${errorClass}`}>
            { this._renderDetailsMessage() }
            <label className="form-label">{I18n.t("react.saved_report_form_component.header_create_saved_report")}</label>
            <input
              type="text"
              className="form-control"
              placeholder={I18n.t("react.saved_report_form_component.report_name_placeholder")}
              onChange={this._onChangeName}
              value={queryName}
            ></input>
            <div className="form-text">{errorMessage}</div>
          </div>
          <div className="modal-footer pb-0">
            <input type="submit" className="btn btn-primary" value={I18n.t("react.saved_report_form_component.save_report")} />
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appearance: state.appearance,
    savedSearchCreation: state.savedSearchCreation,
    selectedGuests: state.guests.selectedGuests
  };
}

const mapDispatchToProps = {
  createSavedSearch,
  clearSavedSearchCreation,
  changeCurrentSavedSearch,
  updateUI,
  showNotice
};

export default connect(mapStateToProps, mapDispatchToProps)(withModal(SaveReport));
