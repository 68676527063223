import { Component } from "react";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import { DragTypes, FormItemTypeHiddenField, CountryType } from "../../constants/Constants";
import Sortable from "../../components/Sortable.react";
import AddBoxButton from "./AddBoxButton.react";
import Icons from "../../constants/Icons";
import { itemBeingEdited } from "../../utils/itemsBySectionUtils.js";
import { urlEventLocale } from "../../utils/pathUtils.js";
import { isTicket } from "../../utils/formBuilderUtils.ts";
import HelpSection from "../shared/HelpSection.react";

import findIndex from "lodash/findIndex";
import orderBy from "lodash/orderBy";


const OVERFLOW_TEXT = "...";
class SideBarRegistrationForm extends Component {

  constructor(props) {
    super(props);
    [
      "handleDrop",
      "updateItem",
      "moveToDropdown",
      "moveItem",
      "onClickDNDItem",
      "destroyFormItemHandler"
    ].forEach(item => {
      this[item] = this[item].bind(this);
    });
  }

  updateItem(item, sendFile = false, optimistic = false) {
    const { formId, updateRegistrationFormItem } = this.props;
    return (updateParams) => {
      updateRegistrationFormItem(formId, item, updateParams, sendFile, optimistic);
    };
  }

  handleDrop(previousItemId, itemId, nextItemId, estimatedIndex) {
    const { steps, itemsForCurrentStep } = this.props;
    const item = itemsForCurrentStep.find(item => { return item._id == itemId; });

    const previousIsStep = steps.find(step => { return step._id === previousItemId; });
    const nextIsStep = steps.find(step => { return step._id === nextItemId; });

    let params = {};

    if (previousIsStep && nextIsStep) {
      params = {
        form_section_id: previousIsStep.form_sections[0], // assume only one section
        rank: 1000
      };
    } else if (previousIsStep) {
      params["form_section_id"] = previousIsStep.form_sections[0];
      if (nextItemId) {
        params["rank_before_id"] = nextItemId;
        params["rank"] = parseInt(itemsForCurrentStep.find(item => { return item._id === nextItemId; }).rank / 2);
      } else {
        params["rank"] = 1000;
      }
    } else if (nextIsStep) {
      if (previousItemId) {
        params["rank_after_id"] = previousItemId;
        const previousItem = itemsForCurrentStep.find(item => { return item._id === previousItemId; });
        params["form_section_id"] = previousItem.form_section_id;
        params["rank"] = previousItem.rank + 1000;
      } else {
        params["rank"] = 1000;
      }
    } else {
      if (previousItemId) {
        params["rank_after_id"] = previousItemId;
        const previousItem = itemsForCurrentStep.find(item => { return item._id === previousItemId; });
        params["form_section_id"] = previousItem.form_section_id;
      } else {
        params["rank_after_id"] = null;
      }
      if (nextItemId) {
        params["rank_before_id"] = nextItemId;
        const nextItem = itemsForCurrentStep.find(item => { return item._id === nextItemId; });
        params["form_section_id"] = nextItem.form_section_id;
      } else {
        params["rank_before_id"] = null;
      }
      params["rank"] = estimatedIndex;
    }
    params["mode"] = "edit";
    this.updateItem(item, false, true)(params);
  }

  moveItem(item, step) {
    return (e) => {
      e.stopPropagation();
      const { items } = this.props;
      // we assume that a step has only one section
      const itemByNewSection = items.filter(item => item.form_section_id === step.form_sections[0]);
      const itemsByNewSectionSorted = orderBy(itemByNewSection, [(item) => item.rank], ["desc"]);
      const rank = itemsByNewSectionSorted.length > 0 ? itemsByNewSectionSorted[0].rank + 1000 : 1000;
      const params = { form_section_id: step.form_sections[0], rank };
      this.updateItem(item, false, true)(params);
    };
  }

  moveToDropdown(item) {
    const styleStepTitle = {
      fontStyle: "italic"
    };
    const styleHeader = {
      cursor: "default"
    };
    const { steps } = this.props;
    const { createStep } = this.props;
    const stepNumber = findIndex(steps, (step) => step.focused);
    const menuItems = steps.map((step, index) => {
      let stepTitle = step.title;
      if (stepTitle.length > 35) {
        stepTitle = stepTitle.substring(0, 35 - OVERFLOW_TEXT.length) + OVERFLOW_TEXT;
      }
      if (index !== stepNumber) {
        return <Dropdown.Item key={index} eventKey={index} onClick={this.moveItem(item, step)}>{`${index + 1} : `} <span style={styleStepTitle}>{`${stepTitle}`}</span></Dropdown.Item>;
      }
    });

    return (
      <Dropdown id="dropdown-custom-1" className="float-end">
        <Dropdown.Toggle variant="link" style={{ cursor: "pointer", color: "black" }} >
          <i className="fa-regular fa-ellipsis"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {[<Dropdown.Header key={-1} style={styleHeader}>{I18n.t("react.registration_form.hint_move_to_step")}</Dropdown.Header>,
            ...menuItems]
          }
          <Dropdown.Divider />
          <Dropdown.Item onClick={createStep} >{ Icons.new_form_step() } { I18n.t("react.registration_form.add_step") }</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  onClickDNDItem(item) {
    const { itemsForCurrentStep } = this.props;
    return (e) => {
      e.stopPropagation();
      const editedItem = itemBeingEdited(itemsForCurrentStep);
      if (editedItem && editedItem.error) {
        // error is diplayed in RegistrationFormItems.react
        return;
      }
      this.updateItem(item, false, true)({ mode: "edit" });
    };
  }

  destroyFormItemHandler(item) {
    return (e) => {
      e.stopPropagation();
      const { destroyRegistrationFormItem, formId } = this.props;
      const locale = urlEventLocale();

      if (confirm(I18n.t("confirm"))) {
        destroyRegistrationFormItem(formId, item, locale);
      }
    };
  }

  isItemHidden(item) {
    const { type, options } = item;

    return type == FormItemTypeHiddenField || (type == CountryType && options.display_as == "hidden_field");
  }

  elementOption(item) {
    return <span className="float-end">
      {this.moveToDropdown(item)}
      {this.renderRemoveItemIcon(item)}
    </span>;
  }

  renderRemoveItemIcon(item) {
    if (item.type === "tickets_selector" || (item.type === "subform" && isTicket(item.options.subforms)))
      return;

    return <a href="#" className="float-end btn btn-link" onClick={this.destroyFormItemHandler(item)}>
      <i className="fa-regular fa-trash-can"></i>
    </a>;
  }

  renderItems() {
    const { itemsForCurrentStep } = this.props;

    const selectedItemsHtml = itemsForCurrentStep.map(item => {
      let label;
      if (item.type == "paragraph") {
        const label_text = item.options.content.replace(/<[^>]*>/g, "");
        label = (label_text || I18n.t(`react.input_type_${item.type}`));
      } else {
        label = (item.label || I18n.t(`react.input_type_${item.type}`));
      }
      if (label.length > 15) {
        label = label.substring(0, 20 - OVERFLOW_TEXT.length) + OVERFLOW_TEXT;
      }
      const labelIcon = this.isItemHidden(item) && <OverlayTrigger placement="top" overlay={<Tooltip id="hidden-field-tooltip">{I18n.t("react.hidden_field_tooltip")}</Tooltip>}>
        <i className="fa-regular fa-eye-slash"></i>
      </OverlayTrigger>;

      return (
        <div key={item._id} className="list-group-item">
          <span><i className="fa-solid fa-grip-dots-vertical mr-5"></i></span>
          <span className="title-item">
            {label} {labelIcon}
          </span>
          {this.elementOption(item)}
        </div>
      );
    });

    return (
      <div className="sidebar-body">
        <div className="card">
          <div className="list-group">
            <Sortable itemIdKey="_id"
              itemIndexKey="rank"
              dragType={DragTypes.FORM_ITEM}
              items={itemsForCurrentStep}
              handleDrop={this.handleDrop}
              fullyDraggable={true}
              onClick={this.onClickDNDItem}>
              {selectedItemsHtml}
            </Sortable>
          </div>
        </div>
      </div>);
  }

  renderNoItems() {
    return (
      <div className="sidebar-body">
        <p>{I18n.t("react.registration_form.no_item_dnd")}</p>
      </div>);
  }

  render() {
    const { createStep, createItem, itemsForCurrentStep, toggleTranslationsPane, event } = this.props;
    let translationsTooltip = <Tooltip id="tooltip-add-element">{ I18n.t("react.registration_form.manage_translations") }</Tooltip>;

    return (
      <div className='col-3 form-builder-sidebar'>
        <div className="form-builder-sidebar-card">
          <div className="sidebar-header">
            <p className="lead float-start">{I18n.t("react.registration_form.panel_heading_dnd")}</p>
            <div className="row g-1 align-items-center justify-content-end">
              {
                event && event.available_frontend_locales && event.available_frontend_locales.length > 0 && <div className="col-auto">
                  <OverlayTrigger placement="bottom" overlay={translationsTooltip}>
                    <button onClick={toggleTranslationsPane} className="fa-regular fa-language btn btn-secondary" />
                  </OverlayTrigger>
                </div>
              }
              <div className="col-auto">
                <AddBoxButton createStep={createStep}
                  createItem={createItem} />
              </div>
            </div>
            <div className="form-builder-sidebar-help"><i className="fa-regular fa-hand"></i> {I18n.t("react.registration_form.hint_dnd")}</div>
          </div>
          {itemsForCurrentStep.length > 0 ? this.renderItems() : this.renderNoItems()}
          <div className="card-footer">
            <HelpSection help={I18n.t("react.registration_form.help_message")} classNames="d-none d-sm-block" />
          </div>
        </div>
      </div>
    );
  }
}

export default SideBarRegistrationForm;
