import { ActionTypes } from "../constants/Constants";
import update from "immutability-helper";


/*
reducer format:

{
  event_id: {
    guest_category_id: {
      count: {
        data: [ statistics ]
      },
      new_visits: {
        12 septembre 2016: {
          data: [ statistics ]
        },
        13 septembre 2016: {
          date: [ statistics ]
        }
      }
      returning: {

      }
    }
  }
}
*/

function guestsStatistics(state = {}, action) {
  switch (action.type) {
  case ActionTypes.GUESTS_STATISTICS_SUCCESS: {
    const { field, data, date, eventId } = action;
    const nextState = assignAndEnsureObjectPath(state, `${eventId}.${field}.${date}`);
    return update(nextState, {
      [eventId]: {
        [field]: {
          [date]: {
            data: { $set: data }
          }
        }
      }
    });
  }
  case ActionTypes.GUESTS_STATISTICS_FILTERED: {
    const newState = Object.assign({}, state, { selectedValueIds: action.selectedValueIds });
    return newState;
  }
  case ActionTypes.GUESTS_STATISTICS_CLEAR:
    return {};
  default:
    return state;
  }
}

function assignAndEnsureObjectPath(obj, path) {
  let currentObj = Object.assign({}, obj);
  const ref = currentObj;
  path.split(".").forEach(comp => {
    currentObj[comp] = currentObj[comp] || {};
    currentObj = currentObj[comp];
  });
  return ref;
}

export default guestsStatistics;
