import { Component } from "react";
import moment from "moment";
import DatePicker from "../shared/DatePicker.react";

const Format = "YYYY-MM-DD";

class DatePickerComponent extends Component {
  constructor(props) {
    super(props);
    const methods = [
      "changeDateSelector",
      "toggleDisplayRange"
    ];
    methods.forEach(item => {
      this[item] = this[item].bind(this);
    });
    const { options } = props.formItem;
    const displayRange = !!(options.date_min || options.date_max);
    this.state = { displayRange: displayRange };
  }

  changeDateSelector(type) {
    const { updateHandler, formItem } = this.props;
    const { options } = formItem;
    return (value) => {
      const newOptions = Object.assign({}, options, { [type]: value });
      updateHandler({ options: newOptions });
    };
  }

  toggleDisplayRange(e) {
    this.setState({ displayRange: e.target.checked });
  }

  renderDates() {
    const { displayRange } = this.state;
    if (!displayRange) {
      return (<div></div>);
    } else {
      const { formItem } = this.props;
      const date_min = formItem.options.date_min ? new Date(moment(formItem.options.date_min).format(Format)) : null;
      const date_max = formItem.options.date_max ? new Date(moment(formItem.options.date_max).format(Format)) : null;
      return (
        <div className="mb-3 row">
          <div className="col-auto">
            <div className="row g-2 align-items-center">
              <label className="col-form-label col-auto">{I18n.t("react.form_date_picker.date_min")}</label>
              <div className="col-auto">
                <DatePicker
                  selectedDate={date_min}
                  onChange={this.changeDateSelector("date_min")}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
          <div className="col-auto">
            <div className="row g-2 align-items-center">
              <label className="col-form-label col-auto">{I18n.t("react.form_date_picker.date_max")}</label>
              <div className="col-auto">
                <DatePicker
                  selectedDate={date_max}
                  onChange={this.changeDateSelector("date_max")}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderContraint() {
    const { displayRange } = this.state;
    const { formItem } = this.props;
    let date_min = formItem.options.date_min ? moment(formItem.options.date_min).format(I18n.t("datetime_picker_js_format")) : null;
    let date_max = formItem.options.date_max ? moment(formItem.options.date_max).format(I18n.t("datetime_picker_js_format")) : null;
    if (!displayRange || (!date_max && !date_min)) {
      return (<div></div>);
    } else {
      let wording = "";
      if (date_min && date_max) {
        wording = I18n.t("react.form_date_picker.interval_error", { date_min: date_min, date_max: date_max });
      } else if (date_min) {
        wording = I18n.t("react.form_date_picker.date_before_min_date_error", { date_min: date_min });
      } else if (date_max) {
        wording = I18n.t("react.form_date_picker.date_after_max_date_error", { date_max: date_max });
      }

      return (
        <div className="alert alert-info">
          <p>{wording}</p>
        </div>
      );
    }
  }

  render() {
    const { mode } = this.props;
    const { displayRange } = this.state;
    if (mode === "read") {
      return (
        <div>
          <div className="col-sm-2">
            {I18n.t("react.registration_date.day")}
            <select className="form-select" readOnly>
              <option>{moment().format("DD")}</option>
            </select>
          </div>
          <div className="col-sm-2">
            {I18n.t("react.registration_date.month")}
            <select className="form-select" readOnly>
              <option>{moment().format("MM")}</option>
            </select>
          </div>
          <div className="col-sm-3">
            {I18n.t("react.registration_date.year")}
            <select className="form-select" readOnly>
              <option>{moment().format("YYYY")}</option>
            </select>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div>
          <div className="form-check">
            <label className="form-check-label">
              <input type="checkbox" className="form-check-input" checked={displayRange} onChange={this.toggleDisplayRange} />
              {I18n.t("react.form_date_picker.enable_date_limit")}
            </label>
          </div>
        </div>
        {this.renderDates()}
        {this.renderContraint()}
      </div>
    );
  }
}

export default DatePickerComponent;
