import React from "react";
import Group from "./Group.react";
import IntegrationsData from "../../../config/integration_set.yml";
import { IntegrationConfigurations } from "./types/IntegrationTypes";

interface GroupsProps {
  integrationConfigurations: IntegrationConfigurations;
  i18n: (key: string) => string;
}

type IntegrationsGroups = { [key: string]: string[] }

const Groups: React.FC<GroupsProps> = ({ integrationConfigurations, i18n }) => {
  return <> {
    Object.entries((IntegrationsData.groups || []) as IntegrationsGroups).map(([groupKey, integrationsGroup]) => {
      return <div key={groupKey} className="mt-3">
        <h2 id={groupKey}>{i18n(groupKey)}</h2>
        <Group integrationsGroup={integrationsGroup} i18n={i18n} integrationConfigurations={integrationConfigurations}/>
      </div>;
    })
  }
  </>;
};

export default Groups;
