import { CALL_API, ActionTypes } from "../constants/Constants";
import { removeSchemeAndHost } from "../utils/pathUtils";

function fetchActionWithEndpoint(endpoint) {
  const { DEV_NULL, RECEIVER_ACTIVITIES_SUCCESS } = ActionTypes;
  return {
    [CALL_API]: {
      types: [DEV_NULL, RECEIVER_ACTIVITIES_SUCCESS, DEV_NULL],
      method: "GET",
      endpoint
    }
  };
}

export function fetchReceiverActivities(eventId, campaignId, filter = null) {
  const query = filter ? `&event_filter=${filter}` : "";
  const ep = `/api/v1/events/${eventId}/guest_campaigns/${campaignId}/receiver_activities.json?per_page=20${query}`;
  return fetchActionWithEndpoint(ep);
}

export function fetchReceievrActivitiesAtPage(pageURL) {
  return fetchActionWithEndpoint(removeSchemeAndHost(pageURL));
}
