import { ChangeEvent } from "react";

interface Props {
  label: string;
  value: any;
  name: string;
  isDisplayed: boolean;
  placeholder?: string;

  onChangeHandler: (label: string, isDisplayed: boolean) => void;
}

const InputLabelWithVisibilityButton: React.FC<Props> = ({ label, value, isDisplayed, placeholder, name, onChangeHandler }) => {
  return <div key={name}>
    <label htmlFor={name} className="form-label">{label}</label>
    <div className="row g-2 align-items-center">
      <div className="col-md-4 col-11">
        <input
          type="text"
          className="form-control"
          value={value || ""}
          placeholder={placeholder || ""}
          name={name}
          id={name}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => onChangeHandler(e.target.value, isDisplayed)}
          disabled={!isDisplayed}
        />
      </div>
      <span className="col-auto" onClick={(): void => onChangeHandler(value, !isDisplayed)} style={{ cursor: "pointer" }}>
        <i className={`fa-regular ${isDisplayed ? "fa-eye-slash" : "fa-eye"}`} style={{ paddingTop: "8px" }}></i>
      </span>
    </div>
  </div>;
};

export default InputLabelWithVisibilityButton;
