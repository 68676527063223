import { Component } from "react";
import { connect } from "react-redux";
import Loader from "../components/shared/Loader.react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Event } from "../types/Event";
import { EmailTemplate } from "../types/EmailTemplate";
import Icons from "../constants/Icons";
import { fetchLeadsExportCustomEmail, clearCustomEmailExport, updateEvent, addOfflineErrors } from "../actions/EventActionCreators";
import { requestEvent } from "../actions/ImportActionCreators";
import { pathToLeadsEmailTemplate } from "../utils/pathUtils";
import LeadsExportEmailForm from "./LeadsExportEmailForm.react";
import UnpublishedEventAlertMessage from "../components/UnpublishedEventAlertMessage.react";

interface Props {
  label: string;
  event: Event;
  errors: any[];
  match: any;
  localCustomEmail: EmailTemplate;
  updatePending: boolean;
  defaultLiquidBodyUrl: string;

  requestEvent(): void;
  fetchLeadsExportCustomEmail(eventId: string): void;
  clearCustomEmailExport(): void
  updateEvent(eventId: string, eventParams: any, redirectTo?: any, notificationsOptions?: any): void;
  addOfflineErrors(errors: any): void;
}

interface State {
  isLeadsExportEmailOpen: boolean;
  custom: boolean;
  urlEmailInfo: { sender: string; subject: string; reply_to_email: string; };
}

export class LeadsExportEmailFeature extends Component<Props, State> {
  constructor(props) {
    super(props);
    [
      "renderSlidingPane",
      "disableBodyScroll",
      "closeEmailConfigForm",
      "enableBodyScroll",
      "openEmailConfigForm"
    ].forEach((item) => { this[item] = this[item].bind(this); });

    this.state = {
      isLeadsExportEmailOpen: false,
      custom: false,
      urlEmailInfo: {
        sender: null,
        subject: null,
        reply_to_email: null
      }
    };
  }

  componentDidMount(): void {
    const { requestEvent } = this.props;
    requestEvent();

    // After switch from Liquid to Email Template
    const urlParams = new URLSearchParams(window.location.search);
    const urlEmailInfo = {
      sender: urlParams.get("sender"),
      subject: urlParams.get("subject"),
      reply_to_email: urlParams.get("reply_to_email"),
    };
    if (urlEmailInfo.sender || urlEmailInfo.subject || urlEmailInfo.reply_to_email) {
      this.setState({ isLeadsExportEmailOpen: true, urlEmailInfo });
    }
  }

  componentDidUpdate(_prevProps, prevState): void {
    const { event, fetchLeadsExportCustomEmail } = this.props;
    const { isLeadsExportEmailOpen } = this.state;

    if (!prevState.isLeadsExportEmailOpen && isLeadsExportEmailOpen && event._id) {
      fetchLeadsExportCustomEmail(event._id);
    }
  }

  openEmailConfigForm(): void {
    this.setState({ isLeadsExportEmailOpen: true });
  }

  disableBodyScroll() : void {
    document.body.style.overflow = "hidden";
  }

  closeEmailConfigForm(): void {
    const { clearCustomEmailExport } = this.props;

    this.setState({ isLeadsExportEmailOpen: false });
    this.enableBodyScroll();
    clearCustomEmailExport();
  }

  enableBodyScroll(): void {
    document.body.removeAttribute("style");
  }

  submitForm(eventId: string, eventParams: any, custom: boolean, updateEvent: any): void {
    updateEvent(eventId, eventParams, custom ? pathToLeadsEmailTemplate() : null);
    this.closeEmailConfigForm();
  }

  renderSlidingPane(): JSX.Element {
    const { event, errors, match, localCustomEmail, updatePending, defaultLiquidBodyUrl, addOfflineErrors, updateEvent } = this.props;
    const { isLeadsExportEmailOpen, custom, urlEmailInfo } = this.state;

    return (
      <SlidingPane isOpen={isLeadsExportEmailOpen}
        width='60%'
        title={I18n.t("react.leads_export_email.sliding_pane_email_title")}
        onAfterOpen={this.disableBodyScroll}
        onRequestClose={this.closeEmailConfigForm}
        className="width-100-xs width-80-sm"
        closeIcon={Icons.close()}>

        <UnpublishedEventAlertMessage event={event} wrapperClass="bg-gray pr-15 pl-15 pt-15"/>
        <LeadsExportEmailForm
          event={event}
          errors={errors}
          match={match}
          localCustomEmail={localCustomEmail}
          submitForm={(eventId: string, eventParams: any, custom: boolean): void => this.submitForm(eventId, eventParams, custom, updateEvent)}
          addOfflineErrors={addOfflineErrors}
          updatePending={updatePending}
          isCustom={custom}
          defaultLiquidBodyUrl={defaultLiquidBodyUrl}
          changeCustom={(newState: any): void => {
            this.setState(newState);
          }}
          infoFromUrlParams={urlEmailInfo}
        />
      </SlidingPane>
    );
  }

  render(): JSX.Element {
    const { label, event } = this.props;

    if (!event._id) return <Loader size="small" />;

    return <>
      <div className="btn btn-secondary" onClick={this.openEmailConfigForm}>
        <i className="fa-regular fa-envelope fa-fw"></i> {label}
      </div>
      {this.renderSlidingPane()}
    </>;
  }
}

const mapStateToProps = (state: any): any => {
  return {
    event: state.event,
    errors: state.event.errors,
    localCustomEmail: state.event.leadsExportEmail,
    updatePending: state.event.pendingRequest,
  };
};

const mapDispatchToProps = {
  requestEvent,
  fetchLeadsExportCustomEmail,
  clearCustomEmailExport,
  updateEvent,
  addOfflineErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadsExportEmailFeature);
