import { CALL_API, ActionTypes } from "../constants/Constants";

export function fetchGuestInvitationSentStats(eventId: string, body: any = {}): any {
  const { FETCH_GUEST_INVITATION_STATS_REQUEST, FETCH_GUEST_INVITATION_STATS_SUCCESS, FETCH_GUEST_INVITATION_STATS_FAILURE } = ActionTypes;

  const action = {
    [CALL_API]: {
      types: [FETCH_GUEST_INVITATION_STATS_REQUEST, FETCH_GUEST_INVITATION_STATS_SUCCESS, FETCH_GUEST_INVITATION_STATS_FAILURE],
      method: "GET",
      body,
      endpoint: `/api/v1/events/${eventId}/guest_invitations/stats.json`,
    }
  };

  return action;
}
