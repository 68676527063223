import { ChangeEvent, useEffect, useState } from "react";
import { Accesspoint, BundledAccesspoint } from "../types/Accesspoint";
import AccesspointSearchDropdown from "../containers/AccesspointSearchDropdown.react";

interface Props {
  accesspoints: Accesspoint[];
  creationMode?: boolean;
  item: BundledAccesspoint;
  selectedAccesspointIds: string[];

  onDeleted: (item: BundledAccesspoint) => void;
  onChanged: (item: BundledAccesspoint) => void;
}

const BundledAccesspointItem: React.FC<Props> = ({ item, onDeleted, onChanged, creationMode, selectedAccesspointIds, accesspoints }) => {
  const [accesspoint, setAccesspoint] = useState(null);

  useEffect(() => {
    if (!accesspoints || !item) return;

    setAccesspoint(accesspoints.find(ap => ap._id === item.accesspoint_id));
  }, [accesspoints, item.accesspoint_id]);

  const quantityHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    item.quantity = e.target.value && parseInt(e.target.value);
    onChanged(item);
  };

  const accesspointHandler = (accesspoints: Accesspoint[]): void => {
    item.accesspoint_id = accesspoints[0]._id;
    onChanged(item);
  };

  const renderTypeSelect = (): JSX.Element => {
    if (creationMode) {
      return <AccesspointSearchDropdown
        onSelectAccesspoint={accesspointHandler}
        selectedAccesspointsIds={[item.accesspoint_id]}
        allSelectedAccesspointsIds={selectedAccesspointIds}
        displaySelectedAccesspointOnList={false}
        searchFilters={{ type: ["product", "session", "checkin_point", "accommodation"] }}
      />;
    }

    return <div>
      { accesspoint?.name }
    </div>;
  };

  const renderQuantity = (): JSX.Element => {
    if (accesspoint?.type !== "product") return null;

    return (
      <input type="text" name="quantity" className="form-control right" value={item.quantity} onChange={quantityHandler} />
    );
  };

  return (
    <div key={item.index} className="card">
      <div className="card-body row align-items-center">
        <div className="col-md-9">{renderTypeSelect()}</div>
        <div className="col-md-2">{renderQuantity()}</div>
        <div className="text-end col-md-1">
          <a className="btn btn-danger" onClick={(): void => {onDeleted(item);}}>
            <i className="fa-regular fa-trash-can" aria-hidden="true" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BundledAccesspointItem;
