import { ActionTypes } from "../constants/Constants";
import { WorkflowRunsStore } from "../types/Workflow";

const defaultState: WorkflowRunsStore = {
  data: null,
  isPendingRequest: false,
  isPendingBatchRequest: false,
  nextURL: null,
  previousURL: null,
  totalCount: 0,
  selectedWorkflowRun: null,
  countedAll: false
};

function workflowRuns(state = defaultState, action: any): WorkflowRunsStore {
  switch (action.type) {
  case ActionTypes.FETCH_WORKFLOW_RUNS_REQUEST:
    return { ...state, isPendingRequest: true };
  case ActionTypes.FETCH_WORKFLOW_RUNS_SUCCESS:
    return { ...state, data: action.data, isPendingRequest: false, nextURL: action.nextURL, previousURL: action.previousURL, totalCount: state.countedAll ? state.totalCount : action.totalCount };
  case ActionTypes.FETCH_WORKFLOW_RUNS_COUNT_SUCCESS:
    return { ...state, totalCount: action.data.count, countedAll: true };
  case ActionTypes.FETCH_WORKFLOW_RUN_REQUEST:
    return { ...state, selectedWorkflowRun: null, isPendingRequest: true };
  case ActionTypes.FETCH_WORKFLOW_RUN_SUCCESS:
    return { ...state, selectedWorkflowRun: action.data, isPendingRequest: false };
  case ActionTypes.RESET_WORKFLOW_RUNS_COUNTED_ALL:
    return { ...state, countedAll: false };
  case ActionTypes.RESET_SELECTED_WORKFLOW_RUN:
  case ActionTypes.DELETE_WORKFLOW_RUN_SUCCESS:
    return { ...state, selectedWorkflowRun: null };
  case ActionTypes.REPLAY_WORKFLOW_RUN_SUCCESS:
    return { ...state, selectedWorkflowRun: action.data };
  case ActionTypes.BATCH_DELETE_WORKFLOW_RUNS_PENDING:
  case ActionTypes.BATCH_REPLAY_WORKFLOW_RUNS_PENDING:
    return { ...state, isPendingBatchRequest: true };
  case ActionTypes.BATCH_DELETE_WORKFLOW_RUNS_SUCCESS:
  case ActionTypes.BATCH_REPLAY_WORKFLOW_RUNS_SUCCESS:
    return { ...state, isPendingBatchRequest: false };
  default:
    return state;
  }
}

export default workflowRuns;
