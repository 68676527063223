import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { fabric } from "fabric";
import { FabricEditorFunctions } from "./fabricjs/types";
import { BadgeImage } from "../../types/BadgeTemplate";
import { BADGE_IMAGE_POSITIONS } from "./Constants";

interface Props {
  badgeImages: BadgeImage[];
  selectedObjects?: fabric.Object[];
  editor?: FabricEditorFunctions;
  imageFormatSettings: any;
  setNewBackgroundKey(position: string): void;
  toggleBadgeSettingsModal(): void;
}

function i18n(key, opts = {}): string {
  return I18n.t(`react.badge_builder.badge_builder_items_list.${key}`, opts);
}

const BadgeBuilderItemsList: React.FC<Props> = ({
  badgeImages,
  editor,
  selectedObjects,
  imageFormatSettings,
  setNewBackgroundKey,
  toggleBadgeSettingsModal,
}) => {
  const [selectedElementKey, setSelectedElementKey] = useState(null);

  useEffect(() => {
    const imageAdded = badgeImages?.find(badgeImage => badgeImage.position === selectedElementKey);
    if (selectedObjects[0]?.type === "image") {
      setSelectedElementKey(selectedObjects[0].imageKey);
      setNewBackgroundKey("");
    } else if (selectedObjects[0]?.type === "textbox" || selectedObjects.length === 0 && imageAdded) {
      setSelectedElementKey("");
      setNewBackgroundKey("");
    }
  }, [selectedObjects]);

  const renderSettingsButton = (): JSX.Element => {
    return <div className="text-end mt-10">
      <Button variant="secondary" onClick={toggleBadgeSettingsModal}>
        <i className="fa-regular fa-gear" aria-hidden="true" />
      </Button>
    </div>;
  };

  const handleElementItemSelected = (key: string) :void => {
    setSelectedElementKey(key);
  };

  const handleBackgroundItemSelected = (position: string): void => {
    if (!editor?.selectObject(position)) {
      // force display of the properties panel to add a new image
      setNewBackgroundKey(position);
    } else {
      setNewBackgroundKey("");
    }
  };

  const renderBackgroundItem = (position: string): JSX.Element => {
    return <Button
      key={position}
      variant={`link text-start ${selectedElementKey === position && "active"}`}
      onClick={(): void => {
        handleBackgroundItemSelected(position);
        handleElementItemSelected(position);
      }}>
      <i className="fa-regular fa-image" aria-hidden="true" /> {i18n(position)}
    </Button>;
  };

  const renderBackgroundItemsList = (): JSX.Element[] => {
    if (!imageFormatSettings && !badgeImages) return null;

    let backgroundPositions = [];
    if (imageFormatSettings && Object.keys(imageFormatSettings).length) {
      backgroundPositions = Object.keys(imageFormatSettings);
    } else {
      const backgroundImages = badgeImages.filter(badgeImage => BADGE_IMAGE_POSITIONS.includes(badgeImage.position));
      backgroundPositions = backgroundImages.map(backgroundImage => backgroundImage.position);
    }

    return backgroundPositions.map((position) => <li key={position}>
      {renderBackgroundItem(position)}
    </li>);
  };

  const renderItemsList = (): JSX.Element => {
    return <div>
      <ul className="list-group">
        {renderBackgroundItemsList()}
      </ul>
    </div>;
  };

  return <div>
    {renderSettingsButton()}
    {renderItemsList()}
  </div>;
};

export default BadgeBuilderItemsList;
