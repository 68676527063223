import { ChangeEvent } from "react";

interface Props {
  allSenderEmailAddresses: string[];
  customEmailSenderEmail: string;
  label: string,
  name: string,
  disabled?: boolean,
  onSelectSenderEmail(senderEmail: string): void
}

const SenderEmailAddressesField: React.FC<Props> = ({
  allSenderEmailAddresses,
  customEmailSenderEmail,
  label,
  name,
  disabled,
  onSelectSenderEmail
}) => {
  if (allSenderEmailAddresses.length === 0) return null;

  const options = allSenderEmailAddresses.map(value => {
    return <option value={value} key={value}>{value}</option>;
  });

  return <div className="mb-3">
    <label htmlFor={name} className="form-label">{label}</label>
    <select
      className="form-control"
      value={customEmailSenderEmail || ""}
      name={name}
      onChange={(e: ChangeEvent<HTMLSelectElement>): void => onSelectSenderEmail(e.target.value)}
      disabled={disabled || allSenderEmailAddresses.length <= 1}
    >
      {options}
    </select>
  </div>;
};

export default SenderEmailAddressesField;
