import { Component } from "react";
import { formatLongDate } from "../../utils/DateUtils";
import { fetchContactHistory, fetchContactHistoryPage } from "../../actions/ContactsActionCreators";
import { contactRowStyle } from "../../constants/Style";
import contactDetailsList from "./ContactDetailsList.react";

const CHANGE_TO_IGNORE = ["searchable_keywords", "updated_at"];

const i18n = (key, opts = {}) => {
  return I18n.t(`react.contacts.contact_history.${key}`, opts);
};
class ContactHistory extends Component {
  valueOrBlank(value) {
    return (!value || value === "") ? <em>{ I18n.t("blank") }</em> : value.toString();
  }

  renderChange(key, label, before, after) {
    return <li key={key}>
      { label }: <span className="diff diff-old">{ this.valueOrBlank(before) }</span> → <span className="diff diff-new">{ this.valueOrBlank(after) }</span>
    </li>;
  }

  renderMetadataChanges(before, after) {
    let changes = {};
    Object.keys(before || {}).forEach(sha1 => {
      const metadata = before[sha1];
      changes[metadata.name] = { before: metadata.value, after: after && after[sha1] && after[sha1].value };
    });
    Object.keys(after || {}).forEach(sha1 => {
      const metadata = after[sha1];
      changes[metadata.name] = { before: before && before[sha1] && before[sha1].value, after: metadata.value };
    });
    return Object.keys(changes).map(metadataKey => {
      return this.renderChange(metadataKey, metadataKey, changes[metadataKey].before, changes[metadataKey].after);
    });
  }

  renderItemChanges(item) {
    const changes = Object.keys(item.item_changes).map(key => {
      const change = item.item_changes[key];
      if (CHANGE_TO_IGNORE.includes(key) || change.length !== 2) {
        return null;
      }
      const [before, after] = change;
      if (key == "guest_metadata") return this.renderMetadataChanges(before, after);
      return this.renderChange(`${item._id}${key}`, key, before, after);
    });

    if (changes.length === 0) {
      return <em>{ i18n("no_changes") }</em>;
    }

    return (
      <ul className="list-unstyled">
        { changes }
      </ul>
    );
  }

  renderHistoryItem(item) {
    const { eventIdNameMapping } = this.props;
    const event = eventIdNameMapping[item.event_id];
    const onEvent = event ? i18n("on_event", { event }) : null;

    return (
      <div key={item._id} style={contactRowStyle}>
        <p><strong style={{ marginBottom: "5px" }}>{ formatLongDate(item.created_at) }</strong> <em className="text-muted">{ onEvent }</em></p>
        { this.renderItemChanges(item) }
      </div>
    );
  }

  render() {
    const { items } = this.props;
    return <div>{items.map(item => this.renderHistoryItem(item))}</div>;
  }
}

function mapStateToProps(state) {
  return {
    items: state.contact.historyItems,
    itemsNextURL: state.contact.historyItemsNextURL,
    fetching: state.contact.fetchingHistory
  };
}

const mapDispatchToProps = {
  fetchItems: fetchContactHistory,
  fetchItemsPage: fetchContactHistoryPage
};

export default contactDetailsList(ContactHistory, mapStateToProps, mapDispatchToProps, i18n);