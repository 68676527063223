import { CALL_API, ActionTypes } from "../constants/Constants";

export function fetchMeetingByNumberOfAppointments(eventId: string, sortBy: string): any {
  const { FETCH_MEETING_APPOINTMENTS_STATISTICS_REQUEST, FETCH_MEETING_APPOINTMENTS_STATISTICS_SUCCESS, FETCH_MEETING_APPOINTMENTS_STATISTICS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_MEETING_APPOINTMENTS_STATISTICS_REQUEST, FETCH_MEETING_APPOINTMENTS_STATISTICS_SUCCESS, FETCH_MEETING_APPOINTMENTS_STATISTICS_FAILURE],
      method: "GET",
      endpoint: `/api/v1/events/${eventId}/meetings/statistics.json?sort_by=${sortBy}`
    }
  };
}
