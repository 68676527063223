import { Component } from "react";
import Modal from "react-modal";
import { defaultModalStyles } from "../constants/Style";
import { pathToRegistrationForms } from "../utils/pathUtils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class DestroyRegistrationFormMenuItem extends Component {

  constructor(props) {
    super(props);
    this.state = { modalIsOpen: false };
    ["_openModal", "_closeModal"].forEach(fn => {
      this[fn] = this[fn].bind(this);
    });
  }

  _openModal(e) {
    e.preventDefault();
    this.setState({ modalIsOpen: true });
  }

  _closeModal() {
    this.setState({ modalIsOpen: false });
  }

  _renderDestroyRegistrationFormModal() {
    const { registrationForm, destroyRegistrationForm } = this.props;
    return (
      <div>
        <div className="modal-header">
          <h4 className="modal-title">{ I18n.t("react.registration_form_more_dropdown.destroy") }</h4>
          <button type="button" onClick={ this._closeModal } className="btn-close" aria-label={I18n.t("close")}></button>
        </div>
        <div className="modal-body" style={{ maxHeight: "600px", overflow: "auto" }}>
          <p>{I18n.t("react.registration_form_more_dropdown.destroy_content")}</p>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-danger" onClick={destroyRegistrationForm(registrationForm._id, pathToRegistrationForms())} >
            { I18n.t("react.registration_form_more_dropdown.destroy_action") }
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { modalIsOpen } = this.state;
    const { isDestroyable } = this.props;
    const className = isDestroyable ? null : "disabled-danger disabled";
    const tooltip = (
      <Tooltip id="destroy-tooltip">{I18n.t("react.registration_form_more_dropdown.destroy_invalid_content")}</Tooltip>
    );
    const trigger = isDestroyable ? (this._openModal) : null;
    const color = isDestroyable ? "text-danger" : "disabled-danger";
    const deleteLink = (
      <a href={isDestroyable ? "#" : null} className={`dropdown-item ${className}`} onClick={trigger}>
        <i className={"fa-regular fa-trash-can " + color}></i>
        <span className={color}> { I18n.t("react.registration_form_more_dropdown.destroy") }</span>
      </a>
    );
    const destroyFormLink = isDestroyable ? deleteLink : (
      <OverlayTrigger placement="bottom" overlay={tooltip}>
        {deleteLink}
      </OverlayTrigger>
    );

    return (
      <>
        {destroyFormLink}
        <Modal isOpen={modalIsOpen} onRequestClose={this._closeModal} style={defaultModalStyles} contentLabel="Modal">
          { this._renderDestroyRegistrationFormModal() }
        </Modal>
      </>
    );
  }
}

export default DestroyRegistrationFormMenuItem;
