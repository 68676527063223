"use strict";
import { Component } from "react";
import difference from "lodash/difference";
import ColorPicker from "../components/ColorPicker.react";
import { COLORS } from "../constants/Constants";

class ColoredObjectForm extends Component {
  constructor(props) {
    super(props);
    [
      "onInputChange",
      "setColor",
      "handleSubmit"
    ].forEach((item) => { this[item] = this[item].bind(this); });

    this.state = {
      inputValue: props.name || "",
      color: props.color || this.randomColor()
    };
  }

  componentDidUpdate(prevProps) {
    // after update or create of this specific element
    const { errorMessage, fetching } = this.props;
    if (!errorMessage && prevProps.fetching && !fetching && this.state.inputValue != "") {
      this.setState({ inputValue: "", color: this.randomColor() });
    }
  }

  onInputChange(e) {
    if (this.props.onInputChange) this.props.onInputChange(e.target.value);
    this.setState({ inputValue: e.target.value });
  }

  setColor(color) {
    this.setState({ color });
  }

  randomColor() {
    const availableColors = difference(COLORS, ["#FFFFFF"]);
    return availableColors[Math.floor(Math.random() * availableColors.length)];
  }

  handleSubmit(e) {
    e.preventDefault();
    const { handleSave } = this.props;
    const { inputValue, color } = this.state;
    handleSave(inputValue, color);
  }

  moveCaretAtEnd(e) {
    const temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  }

  renderCancelButton() {
    const { handleCancel } = this.props;
    return handleCancel && <div className="btn btn-secondary mr-10" onClick={ handleCancel }>{ I18n.t("cancel") }</div>;
  }

  renderActionButtons() {
    const { submitButtonName } = this.props;

    return <div className="col-auto">
      <div>
        {this.renderCancelButton()}
        <input className="btn btn-primary" type="submit" value={submitButtonName} />
      </div>
    </div>;
  }

  renderErrorMessage() {
    const { errorMessage } = this.props;
    return errorMessage && <div className="mb-3 text-danger">{ errorMessage }</div>;
  }

  render() {
    const { color, inputValue } = this.state;
    const { i18nKey, actionButtonsRight, allowColorEdition } = this.props;

    return <form onSubmit={this.handleSubmit} className={`d-flex ${actionButtonsRight ? "justify-content-between" : ""}`}>
      <div className={`row g-2 align-items-center ${actionButtonsRight ? "" : "mr-10" }`}>
        <div className="col-auto">
          <input type="text" className="form-control new-label-name" value={inputValue} placeholder={I18n.t(`react.${i18nKey}.name_placeholder`)} onChange={this.onInputChange} autoFocus onFocus={this.moveCaretAtEnd} />
        </div>
        <div className="col-auto">
          {allowColorEdition && <ColorPicker setColor={this.setColor} selectedColor={color} />}
        </div>
      </div>
      { actionButtonsRight || this.renderActionButtons() }
      {this.renderErrorMessage()}
      { actionButtonsRight && this.renderActionButtons() }
    </form>;
  }
}

ColoredObjectForm.defaultProps = {
  submitButtonName: I18n.t("create"),
  actionButtonsRight: false,
  allowColorEdition: true
};

export default ColoredObjectForm;
