import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import uniq from "lodash/uniq";
import isEqual from "lodash/isEqual";
import truncate from "lodash/truncate";
import SlidingPane from "react-sliding-pane";
import DocumentTemplates from "../document_templates/DocumentTemplates.react";
import ErrorMessage from "../../components/shared/ErrorMessage.react";
import Loader from "../../components/shared/Loader.react";
import Icons from "../../constants/Icons";
import { updateGuestCategory } from "../../actions/GuestCategoryActionCreators";
import { fetchDocumentTemplates, updateDocumentSelectedId } from "../../actions/DocumentTemplatesActionCreators";
import { GuestCategory } from "../../types/GuestCategory";
import { urlEventId, pathToGuestCategoryDocuments } from "../../utils/pathUtils";

const listBodyHeight = "calc(100vh - 54px)";
const toolbarHeight = "54px";
const searchBarHeight = "50px";
const borderStyle = "1px solid #E3E3E3";

interface Props {
  guestCategory: GuestCategory;
  featureEnabledField: string;
  match: any;
  location: any;
  history: any;
  afterGuestCategoryUpdate(): void;
}

function i18n(key: string, opts: any = {}): string {
  return I18n.t(`react.guest_category.documents.${key}`, opts);
}

const GuestCategoryDocuments: React.FC<Props> = (({ guestCategory, match, location, history }) => {
  const [isDocumentSelectorOpen, setIsDocumentSelectorOpen] = useState<boolean>(false);

  const documentTemplates = useSelector((state: any) => state.documentTemplates.data);
  const selectedTemplateId = useSelector((state: any) => state.documentTemplates.selectedTemplateId);
  const errors = useSelector((state: any) => state.guestCategory.errors);
  const event = useSelector((state: any) => state.event);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!documentTemplates) {
      dispatch(fetchDocumentTemplates(urlEventId()));
    }
  }, []);

  const openDocumentsSelector = (): void => {
    setIsDocumentSelectorOpen(true);
  };

  const closeDocumentsSelector = (): void => {
    setIsDocumentSelectorOpen(false);
    enableBodyScroll();

    if (!selectedTemplateId) {
      return;
    }

    const currentDocumentTemplateIds = guestCategory.document_template_ids || [];
    const newDocumentTemplateIds = uniq([...currentDocumentTemplateIds, selectedTemplateId]);

    if (isEqual(currentDocumentTemplateIds, newDocumentTemplateIds)) {
      return;
    }

    dispatch(updateGuestCategory(urlEventId(), guestCategory._id, { "document_template_ids": newDocumentTemplateIds }));
  };

  const guestCategoryHasDocuments = (): boolean => {
    return guestCategory.document_template_ids && guestCategory.document_template_ids.length > 0;
  };

  const handleEdit = (documentTemplateId: string): (() => void) => {
    return (): void => {
      dispatch(updateDocumentSelectedId(documentTemplateId));
      openDocumentsSelector();
    };
  };

  const handleDelete = (documentTemplateId: string): (() => void) => {
    return (): void => {
      if (confirm(I18n.t("confirm"))) {
        const newDocumentTemplateIds = guestCategory.document_template_ids.filter(id => id !== documentTemplateId);
        dispatch(updateGuestCategory(urlEventId(), guestCategory._id, { "document_template_ids": newDocumentTemplateIds }));
      }
    };
  };

  const disableBodyScroll = (): void => {
    document.body.style.overflow = "hidden";
  };

  const enableBodyScroll = (): void => {
    document.body.removeAttribute("style");
  };

  const renderSlidingPaneTitle = (): JSX.Element => {
    return (
      <div className="d-flex">
        <span className="d-flex align-items-center mr-5">
          { I18n.t("react.guest_category.documents.add_document") } { " " }
        </span>
        <span className="badge rounded-pill big guest-category" style={{ backgroundColor: guestCategory.label_color }}>
          {truncate(guestCategory.name, { "length": 45 })}
        </span>
      </div>
    );
  };

  const renderDocumentTemplateSelector = (): JSX.Element => {
    return (
      <SlidingPane
        isOpen={isDocumentSelectorOpen}
        width='90%'
        title={renderSlidingPaneTitle()}
        onAfterOpen={disableBodyScroll}
        onRequestClose={closeDocumentsSelector}
        className="width-100-xs width-100-sm"
        closeIcon={Icons.close()}>
        <DocumentTemplates
          selectedTemplateId={selectedTemplateId}
          eventId={urlEventId()}
          event={event}
          enableEdit={true}
          builderInSlidingPane={true}
          loaderContainerHeight={listBodyHeight}
          listBodyHeight={listBodyHeight}
          toolbarHeight={toolbarHeight}
          searchBarHeight={searchBarHeight}
          borderStyle={borderStyle}
          iframeAutoResize={false}
          inModal={true}
          slidingPaneTitle={renderSlidingPaneTitle()}
          enableDelete={false}
          match={match}
          location={location}
          history={history} />
      </SlidingPane>
    );
  };

  const renderDocumentLine = (documentTemplateId: string): JSX.Element => {
    const documentTemplate = documentTemplates.find((documentTemplate) => documentTemplate._id === documentTemplateId);

    if (!documentTemplate) return null;

    const tooltip = <Tooltip id="delete-document-warning">{i18n("delete_tooltip")}</Tooltip>;
    const deletionButton = <a className="btn btn-sm btn-danger" onClick={ handleDelete(documentTemplateId) } >
      <i className="fa-regular fa-trash-can"></i> {I18n.t("remove")}
    </a>;

    return <tr key={documentTemplateId}>
      <td>
        <div className="d-flex justify-content-between d-flex align-items-center">
          <span className="mr-10">
            {documentTemplate.name }
          </span>
          <div className="d-flex">
            <a className="btn btn-sm btn-secondary mr-10" onClick={ handleEdit(documentTemplateId) } >
              <i className="fa-regular fa-pen-to-square"></i> {I18n.t("edit")}
            </a>

            {documentTemplate.uses_count > 1 ? <OverlayTrigger placement="top" overlay={tooltip}>{deletionButton}</OverlayTrigger> : deletionButton}
          </div>
        </div>
      </td>
    </tr>;
  };

  const renderDocuments = (): JSX.Element => {
    if (!guestCategoryHasDocuments()) {
      return <div className="card nothing-yet">
        <h4>{ i18n("no_documents") }</h4>
        <div>
          {renderAddDocumentButton()}
        </div>
      </div>;
    }

    return <div className="table-responsive table-container mb-3">
      <table className="table table-light table-bordered">
        <tbody>
          {guestCategory.document_template_ids.map((documentTemplateId) => renderDocumentLine(documentTemplateId))}
        </tbody>
      </table>
    </div>;
  };

  const renderAddDocumentButton = (): JSX.Element => {
    return <a onClick={openDocumentsSelector} className="btn btn-primary">
      {i18n("add_document")}
    </a>;
  };

  const renderOldDocumentsButton = (): JSX.Element => {
    if (!guestCategory.may_have_old_document_templates) {
      return null;
    }

    return <a href={pathToGuestCategoryDocuments(guestCategory._id)} className="btn btn-secondary float-end">
      {i18n("manage_old_document")}
    </a>;
  };

  if (!documentTemplates) {
    return <Loader size="large" inline={false} containerHeight={listBodyHeight} />;
  }

  return <div style={{ padding: "15px" }}>
    <ErrorMessage errors={errors} noLabelKeys={["document_template_ids"]} model="guest_category" />
    {renderDocuments()}
    {guestCategoryHasDocuments() && renderAddDocumentButton()}
    {renderOldDocumentsButton()}
    {renderDocumentTemplateSelector()}
  </div>;
});

export default GuestCategoryDocuments;
