import { ActionTypes } from "../constants/Constants";
import { GuestProductCollectionsState } from "../types/GuestProductCollection";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";
import { TEMPORARY_ID_SUFFIX } from "../types/GuestProductCollectionFieldPossibleValues";
import { GuestProductCollectionField } from "../types/GuestProductCollectionField";

const defaultState: GuestProductCollectionsState = {
  errors: null,
  isFetching: false,
  data: null,
};

function guestProductCollections(state = defaultState, action: any): GuestProductCollectionsState {
  switch (action.type) {
  case ActionTypes.FETCH_GUEST_PRODUCT_COLLECTIONS_REQUEST:
    return { ...state, ...defaultState, isFetching: true };
  case ActionTypes.FETCH_GUEST_PRODUCT_COLLECTIONS_SUCCESS:
    return { ...state, data: action.data, isFetching: false, errors: null };
  case ActionTypes.UPDATE_GUEST_PRODUCT_COLLECTION_SUCCESS: {
    const sortedCollectionFieldsAndPossibleValues = action.data.collection_fields.sort((a, b) => a.rank < b.rank ? -1 : 1).map(field => {
      if (!field.possible_values) return field;

      const sortedValues = field.possible_values.sort((a, b) => a.rank < b.rank ? -1 : 1);
      return { ...field, possible_values: sortedValues };
    });
    const actionData = { ...action.data, collection_fields: sortedCollectionFieldsAndPossibleValues };
    const data = state.data ? insertOrUpdateObject(state.data, actionData, "_id", false) : [action.data];

    return { ...state, data };
  }
  case ActionTypes.UPDATE_GUEST_PRODUCT_COLLECTION_ERROR: {
    const fieldsAndPossibleValuesWithId = addIdProperty(action.currentGuestProductCollection.collection_fields);
    const actionData = { ...action.data, collection_fields: fieldsAndPossibleValuesWithId };
    const data = state.data ? insertOrUpdateObject(state.data, actionData, "_id", false) : [action.data];
    return { ...state, data };
  }
  case ActionTypes.CREATE_GUEST_PRODUCT_COLLECTION_SUCCESS:
    return { ...state, data: [...state.data, action.data], isFetching: false, errors: null };
  case ActionTypes.DELETE_GUEST_PRODUCT_COLLECTION_SUCCESS: {
    const data = state.data.filter(item => item._id !== action.guestProductCollectionId);
    return { ...state, data };
  }
  case ActionTypes.CREATE_GUEST_PRODUCT_COLLECTION_ERROR:
    return { ...state, errors: action.data };
  default:
    return state;
  }
}

const addIdProperty = (collection_fields: GuestProductCollectionField[]) : GuestProductCollectionField[] => {
  collection_fields.forEach(field => {
    if (field.possible_values) {
      field.possible_values.forEach((value, index) => {
        if (value.isTemporary) {
          value._id = `${TEMPORARY_ID_SUFFIX + index}`;
        }
      });
    }
  });
  return collection_fields;
};

export default guestProductCollections;
