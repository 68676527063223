import { ActionTypes } from "../constants/Constants";
import isEmpty from "lodash/isEmpty";

const defaultState = {
  errors: null,
  warnings: null,
  data: null,
  pendingRequest: false,
  fetched: false
};

function duplicationOperation(state = defaultState, action): any {
  switch (action.type) {
  case ActionTypes.CREATE_FROM_EVENT_REQUEST:
  case ActionTypes.FETCH_DUPLICATION_OPERATION_REQUEST:
    return { ...defaultState, pendingRequest: true };
  case ActionTypes.CREATE_FROM_EVENT_SUCCESS:
  case ActionTypes.FETCH_DUPLICATION_OPERATION_SUCCESS: {
    const warnings = isEmpty(action.data.duplication_errors) ? null : { base: action.data.duplication_errors };
    return { ...defaultState, data: action.data, warnings, fetched: true };
  }
  case ActionTypes.CREATE_FROM_EVENT_FAILURE:
  case ActionTypes.FETCH_DUPLICATION_OPERATION_ERROR:
    return { ...defaultState, data: {}, errors: { base: action.data.error }, fetched: true };
  default:
    return state;
  }
}

export default duplicationOperation;
