import { ActionTypes } from "../constants/Constants";

module.exports = {
  updateUI(updates) {
    return {
      type: ActionTypes.UPDATE_UI,
      updates
    };
  },

  errorUpdateUI() {
    return {
      type: ActionTypes.UPDATE_UI_ERROR
    };
  }
};
