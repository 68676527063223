import { ActionTypes } from "../constants/Constants";
import { BlogArticlesStore } from "../types/BlogArticle";
import { insertOrUpdateObject } from "../utils/StateOperationUtils";

const defaultState: BlogArticlesStore = {
  data: null,
  errors: {},
  isFetching: false,
  nextURL: null,
  previousURL: null,
  totalCount: null,
  duplicationErrors: {},
  duplicated: null,
};

function blogArticles(state = defaultState, action: any): BlogArticlesStore {
  switch (action.type) {
  case ActionTypes.FETCH_BLOG_ARTICLES_PENDING:
    return { ...state, data: null, isFetching: true, errors: {} };
  case ActionTypes.FETCH_BLOG_ARTICLES_SUCCESS:
    return { ...state, data: action.data, isFetching: false, nextURL: action.nextURL, previousURL: action.previousURL };
  case ActionTypes.CREATE_BLOG_ARTICLE_SUCCESS: {
    const data = state.data;
    data.unshift(action.data);
    return { ...state, data };
  }
  case ActionTypes.UPDATE_BLOG_ARTICLE_SUCCESS: {
    const data = state.data ? insertOrUpdateObject(state.data, action.data, "_id", false) : [action.data];
    return { ...state, data };
  }
  case ActionTypes.DELETE_BLOG_ARTICLE_SUCCESS: {
    const data = state.data.filter(item => item._id !== action.blogArticleId);
    return { ...state, data };
  }
  case ActionTypes.FETCH_BLOG_ARTICLES_COUNT_SUCCESS:
    return { ...state, totalCount: action.data.count };
  case ActionTypes.DUPLICATE_BLOG_ARTICLE_PENDING:
    return { ...state, errors: {}, duplicationErrors: {}, duplicated: null };
  case ActionTypes.DUPLICATE_BLOG_ARTICLE_SUCCESS: {
    if (action.duplicateInCurrentEvent) {
      const newBlogArticles = [action.data, ...state.data];
      return { ...state, data: newBlogArticles, errors: {}, duplicationErrors: {}, duplicated: action.data };
    }
    return { ...state, errors: {}, duplicationErrors: {}, duplicated: action.data };
  }
  case ActionTypes.DUPLICATE_BLOG_ARTICLE_FAILURE:
    return { ...state, duplicationErrors: action.data };
  case ActionTypes.CLEAR_BLOG_ARTICLE_DUPLICATION_ERRORS:
    return { ...state, duplicationErrors: {} };
  default:
    return state;
  }
}

export default blogArticles;
