import { Component } from "react";
import { connect } from "react-redux";
import uniqBy from "lodash/uniqBy";
import requiresProps from "../../utils/requiresProps";
import { Link, withRouter } from "react-router-dom";
import querystring from "querystring";

import { CAMPAIGN_STEPS, NavTabGuestCampaign, GuestCampaignStatusLocked, StaticGuestFieldToExport, EventDropFields } from "../../constants/Constants";
import { urlWithQuery, pathToPreviewEmail, urlEventId, lastElementFromCurrentPath, pathToEmailTemplatePreview, pathToEmailBuilder } from "../../utils/pathUtils";
import { campaignStepBreadcrumbLinks } from "../../utils/campaignStepUtils";

import { guestCountFromQS } from "../../actions/SearchQueryActionCreators";
import { fetchGuestCampaign, fetchStandardTheme, fetchAccountTemplates, fetchEventEmailTemplates,
  updateGuestCampaign, deleteFileEditableElement, offlineUpdateGuestCampaign } from "../../actions/GuestCampaignActionCreators";
import { fetchSavedSearch } from "../../actions/SavedSearchActionCreators";
import { fetchEventGuestFields } from "../../actions/GuestFieldsActionCreators";

import Breadcrumb from "../../components/shared/Breadcrumb.react";
import NavTab from "../../components/guest_campaign/NavTab.react";
import TemplateList from "../../components/guest_campaign/TemplateList.react";
import EditableFieldsForm from "../../components/guest_campaign/EditableFieldsForm.react";
import Title from "../../components/guest_campaign/Title.react";
import { redirectIfUnauthorized, isAuthorized, getAcl } from "../../utils/aclUtils";

class GuestCampaignEmail extends Component {

  constructor(props) {
    redirectIfUnauthorized("campaigns", "manage");
    super(props);
    const methods = [
      "_queryString",
      "_urlGuestList",
      "_urlFinalStep",
      "_changeTab",
      "_renderEmailInIframe",
      "_renderTab",
      "_updateEditableField",
      "_updateImageEditableField",
      "_changeImageEditableField",
      "_updateTemplateUrl",
      "_updateGuestCampaign",
      "_updateGuestCampaignAndGoToPreview",
      "getSelectedTemplate",
      "_deleteFileEditableField",
      "_renderAccountTemplatesList",
      "_renderDefaultTemplatesList"
    ];
    this.state = {
      selectedTab: NavTabGuestCampaign[0],
      images: []
    };
    methods.forEach((item) => { this[item] = this[item].bind(this); });
  }

  componentDidMount() {
    const { location, fetchStandardTheme, fetchEventEmailTemplates } = this.props;
    const query = querystring.parse(location.search.substring(1));

    fetchStandardTheme();
    fetchEventEmailTemplates(urlEventId());

    if (NavTabGuestCampaign.includes(query.tab)) {
      this.setState({ selectedTab: query.tab });
    }
  }

  _queryString() {
    return querystring.parse(this.props.location.search.substring(1)).q;
  }

  _urlGuestList() {
    return urlWithQuery(this._queryString(), "guests");
  }

  _urlFinalStep() {
    const { match } = this.props;
    return urlWithQuery(this._queryString(), `campaign/${match.params.guest_campaign_id}/summarize`);
  }

  _changeTab() {
    const { history, match, fetchGuestCampaign } = this.props;
    return (newSelectedTab) => {
      if (newSelectedTab == "fields") {
        fetchGuestCampaign(urlEventId(), match.params.guest_campaign_id);
      } else {
        this._updateGuestCampaign();
      }
      history.replace(`${urlWithQuery("", `campaign/${match.params.guest_campaign_id}/email`)}?tab=${newSelectedTab}`);
      this.setState({ selectedTab: newSelectedTab });
    };
  }

  _renderEmailInIframe() {
    const { match, guestCampaign } = this.props;
    if (!guestCampaign) return null;

    return (
      <div>
        <iframe className="campaign-email-iframe mt-10" src={this.iframeUrl()}></iframe>
        <Link to={urlWithQuery(this._queryString(), `campaign/${match.params.guest_campaign_id}/email/templates?tab=template`)}>
          { I18n.t("react.guest_campaign.choose_another_template")}
        </Link>
        { this.linkToEmailEdition() }
      </div>
    );
  }

  iframeUrl() {
    const { match, guestCampaign } = this.props;
    if (guestCampaign.email_template_id != null) {
      return pathToEmailTemplatePreview(guestCampaign.email_template_id);
    }

    let mostRecentUpdatedEditableElement = [];
    if (guestCampaign.editable_elements && guestCampaign.editable_elements.length > 0) {
      mostRecentUpdatedEditableElement = guestCampaign.editable_elements.sort((x, y) => {
        return (x.updated_at < y.updated_at ? 1 : -1);
      })[0];
    }
    const lastUpdatedAt = mostRecentUpdatedEditableElement == [] ? "" : mostRecentUpdatedEditableElement.updated_at;
    return pathToPreviewEmail(match.params.guest_campaign_id, `${guestCampaign.updated_at}-${lastUpdatedAt}`);
  }

  linkToEmailEdition() {
    const { match, guestCampaign } = this.props;
    if (guestCampaign.email_template_id != null) {
      return (
        <Link className="float-end" to={pathToEmailBuilder(guestCampaign.email_template_id)} target="_blank">
          { I18n.t("react.guest_campaign.go_to_email_builder") }
        </Link>
      );
    }

    return (
      <Link className="float-end" to={urlWithQuery(this._queryString(), `campaign/${match.params.guest_campaign_id}/email/codeit`)}>
        { I18n.t("react.guest_campaign.code_it") }
      </Link>
    );
  }

  _updateEditableField(params) {
    const { guestCampaign, offlineUpdateGuestCampaign } = this.props;
    const editable_elements_attributes = guestCampaign.editable_elements_attributes || [];
    offlineUpdateGuestCampaign(guestCampaign, { editable_elements_attributes: editable_elements_attributes.concat(params) });
  }

  _updateImageEditableField(parameters) {
    const { updateGuestCampaign, match } = this.props;
    updateGuestCampaign(match.params.event_id, match.params.guest_campaign_id, parameters, true);
  }

  _changeImageEditableField(parameters, id) {
    const newImages = this.state.images.filter(image => image.id !== id);
    newImages.push({ id, parameters });
    this.setState({ images: newImages });
  }

  _updateTemplateUrl(parameters) {
    const { updateGuestCampaign, match } = this.props;
    updateGuestCampaign(match.params.event_id, match.params.guest_campaign_id, parameters);
  }

  _updateGuestCampaign(parameters = {}) {
    const { updateGuestCampaign, guestCampaign, match } = this.props;
    if (guestCampaign.body && guestCampaign.body_url) {
      delete guestCampaign.body; // Prioritize body_url
    }
    updateGuestCampaign(match.params.event_id, match.params.guest_campaign_id, Object.assign({}, guestCampaign, parameters));
    this.state["images"].forEach(image => this._updateImageEditableField(image.parameters));
  }

  _updateGuestCampaignAndGoToPreview() {
    this._changeTab()("template");
  }

  _deleteFileEditableField(parameters) {
    const { deleteFileEditableElement, match } = this.props;
    deleteFileEditableElement(urlEventId(), match.params.guest_campaign_id, parameters);
  }

  getSelectedTemplate(templates, options = { from: "url" }) {
    const { guestCampaign } = this.props;
    if (!guestCampaign) return null;
    const selectedTemplate = templates ? templates.find(template => {
      if (options.from === "url") {
        return guestCampaign.body_url === template.url;
      } else if (options.from === "liquid_template_id") {
        return guestCampaign.liquid_template_id === template._id;
      } else if (options.from === "email_template_id") {
        return guestCampaign.email_template_id == template._id;
      } else {
        return guestCampaign.body === template.body;
      }
    }) : null;
    return selectedTemplate;
  }

  _renderEventEmailTemplatesList() {
    const { eventTemplates, match } = this.props;
    if (!eventTemplates || eventTemplates.length < 1) { return; }
    return (
      <div className="mb-15">
        <p><strong>{ I18n.t("react.guest_campaign.templates.my_templates") }</strong></p>
        <TemplateList
          changeTab={this._changeTab()}
          onChange={this._updateTemplateUrl}
          templates={eventTemplates}
          selected={this.getSelectedTemplate(eventTemplates, { from: "email_template_id" })}
          keyName="name"
          guestCampaignId={match.params.guest_campaign_id}
          queryString={this._queryString()}
          isEventEmailTemplate={true} />
      </div>
    );
  }

  _renderAccountTemplatesList() {
    const { accountTemplates, match } = this.props;
    if (!accountTemplates || accountTemplates.length < 1) { return; }
    return (
      <div className="mb-15">
        <p><strong>{ I18n.t("react.guest_campaign.templates.accounts_templates") }</strong></p>
        <TemplateList
          changeTab={this._changeTab()}
          onChange={this._updateTemplateUrl}
          templates={accountTemplates}
          selected={this.getSelectedTemplate(accountTemplates, { from: "liquid_template_id" })}
          keyName="name"
          guestCampaignId={match.params.guest_campaign_id}
          queryString={this._queryString()}
          isAccountTemplate={true}
        />
      </div>
    );
  }

  _renderDefaultTemplatesList() {
    const { templates, match } = this.props;
    if (!templates || templates.length < 1) { return; }
    return (
      <div className="mb-15">
        <p><strong>{ I18n.t("react.guest_campaign.templates.default_templates") }</strong></p>
        <TemplateList
          templates={templates}
          onChange={this._updateTemplateUrl}
          selected={this.getSelectedTemplate(templates)}
          keyName="displayed_name"
          guestCampaignId={match.params.guest_campaign_id}
          queryString={this._queryString()}
        />
      </div>
    );
  }

  _renderTab() {
    const { selectedTab } = this.state;
    const { guestCampaign, guestFields } = this.props;
    // NavTabGuestCampaign
    switch (selectedTab) {
    case "template": {
      if (lastElementFromCurrentPath() == "templates") {
        return (
          <div className="mt-10">
            { this._renderEventEmailTemplatesList() }
            { this._renderAccountTemplatesList() }
            { this._renderDefaultTemplatesList() }
          </div>
        );
      } else {
        return this._renderEmailInIframe();
      }
    }
    case "fields": {
      if (!guestCampaign) return null;
      let dropFields = [];
      StaticGuestFieldToExport.forEach(field => { dropFields.push({ id: `guest.${field}`, name: `guest.${field}` }); });
      EventDropFields.forEach(field => { dropFields.push({ id: `event.${field}`, name: `event.${field}` }); });
      guestFields.forEach(field => { dropFields.push({ id: `guest.${field.key}`, name: `guest.${field.key}` }); });
      dropFields.push({ id: "email_url", name: "email_url" });

      return (
        <form className="mt-10">
          <EditableFieldsForm
            dropFields={uniqBy(dropFields, "id")}
            errors={guestCampaign.errors}
            editableElements={guestCampaign.editable_elements}
            updateEditableField={this._updateEditableField}
            deleteFile={this._deleteFileEditableField}
            updateImageEditableField={this._changeImageEditableField}
          />
        </form>
      );
    }
    default:
      return (<div>Nothing to display</div>);
    }
  }

  render() {
    if (!isAuthorized("campaigns", "manage")) return;
    const { guestCount, guestCampaign } = this.props;
    const { selectedTab } = this.state;
    const nextUrl = (guestCampaign && GuestCampaignStatusLocked.includes(guestCampaign.status)) ? null : (selectedTab === "fields" ? this._updateGuestCampaignAndGoToPreview : this._urlFinalStep());
    const nextName = selectedTab === "fields" ? I18n.t("react.guest_campaign.save_and_preview") : I18n.t("react.guest_campaign.go_to_final_step");
    const navbarItems = (guestCampaign && guestCampaign.email_template_id != null) ? ["template"] : NavTabGuestCampaign;
    return (
      <div style={{ marginBottom: "50px" }}>
        <div className="row">
          <div className="col-md-8 offset-md-2 mt-30">
            <Title nbGuest={guestCount} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 offset-md-2 mt-30">
            <NavTab selected={selectedTab} items={navbarItems} onChange={this._changeTab()} />
            {this._renderTab()}
          </div>
        </div>
        <Breadcrumb
          steps={campaignStepBreadcrumbLinks(guestCampaign, {
            guestCampaignIsPersisted: true,
            searchQuery: this._queryString()
          })}
          activeStep={CAMPAIGN_STEPS[1]}
          backUrl={this._urlGuestList()}
          nextUrl={nextUrl}
          nextName={nextName}
          backName={I18n.t("react.guest_campaign.people_list")}
          modalIsOpen={false}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    guestCount: state.appearance.selectedGuestCountAll,
    guestCampaign: state.guestCampaign.guestCampaign,
    guestFields: state.guestFields.guestFields,
    templates: state.guestCampaign.templates,
    accountTemplates: state.guestCampaign.accountTemplates,
    eventTemplates: state.guestCampaign.eventTemplates,
    isUpdating: state.guestCampaign.isUpdating,
    savedSearch: state.savedSearch,
    event: state.event,
    acl: getAcl()
  };
}

const mapDispatchToProps = {
  fetchGuestCampaign,
  fetchStandardTheme,
  fetchAccountTemplates,
  fetchEventEmailTemplates,
  fetchSavedSearch,
  updateGuestCampaign,
  offlineUpdateGuestCampaign,
  deleteFileEditableElement,
  guestCountFromQS,
  fetchEventGuestFields
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  requiresProps(GuestCampaignEmail, {
    requirements: {
      event: {
        fn: () => {},
        desiredState: "has_underscore_id"
      },
      guestCampaign: {
        fn: (props) => {
          const { match, fetchGuestCampaign } = props;
          fetchGuestCampaign(match.params.event_id, match.params.guest_campaign_id);
        },
        options: {
          force: true
        },
        statePath: "guestCampaign.guestCampaign"
      },
      savedSearch: {
        waitFor: ["guestCampaign"],
        fn: (props) => {
          const { guestCampaign, fetchSavedSearch, match } = props;
          fetchSavedSearch(match.params.event_id, guestCampaign.saved_search_id);
        },
        options: {
          force: true
        }
      },
      guestCount: {
        waitFor: ["savedSearch"],
        fn: (props) => {
          const { savedSearch, guestCountFromQS } = props;
          guestCountFromQS(savedSearch.search_query, true);
        },
        statePath: "appearance.selectedGuestCountAll"
      },
      accountTemplates: {
        waitFor: ["event"],
        fn: (props) => {
          const { fetchAccountTemplates, event } = props;
          fetchAccountTemplates(event.account_id);
        },
        statePath: "guestCampaign.accountTemplates"
      },
      guestField: {
        waitFor: ["event"],
        fn: (props) => {
          const { fetchEventGuestFields, event } = props;
          fetchEventGuestFields(event.id);
        }
      }
    }
  })
));
