import { ActionTypes, CALL_API } from "../constants/Constants";

export function fetchContactFields(accountId) {
  const { FETCH_CONTACT_FIELDS_REQUEST, FETCH_CONTACT_FIELDS_SUCCESS, FETCH_CONTACT_FIELDS_FAILURE } = ActionTypes;
  return {
    [CALL_API]: {
      types: [FETCH_CONTACT_FIELDS_REQUEST, FETCH_CONTACT_FIELDS_SUCCESS, FETCH_CONTACT_FIELDS_FAILURE],
      method: "GET",
      endpoint: `/api/v1/accounts/${accountId}/contact_fields.json`
    }
  };
}
