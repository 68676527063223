import { ActionTypes } from "../constants/Constants";

export function setSelectedThematicsIdsInGuestsThematicsQuartiles(ids: string[]): any {
  return {
    type: ActionTypes.SET_IN_GUESTS_THEMATICS_QUARTILES_SELECTED_THEMATICS_IDS,
    objectIds: ids
  };
}

export function setSelectedQuartilesIdsInGuestsThematicsQuartiles(ids: string[]): any {
  return {
    type: ActionTypes.SET_IN_GUESTS_THEMATICS_QUARTILES_SELECTED_QUARTILES_IDS,
    objectIds: ids
  };
}