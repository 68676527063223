import { combineReducers } from "redux";
import reduceReducers from "reduce-reducers";

import registrationFormFocus from "./registrationFormFocus";

import notifications from "./notifications";
import appearance from "./uiAppearance";
import exportOptions from "./exportOptions";
import savedSearches from "./savedSearches";

//FIXME: exports is a reserved word in javascript.
import exports from "./exports";
import acceptedAtAccesspointIds from "./acceptedAtAccesspointIds";
import accessControlCount from "./accessControlCount";
import accesspoints from "./accesspoints";
import accesspointsLiveConsumption from "./accesspointsLiveConsumption";
import accesspointsOccupancy from "./accesspointsOccupancy";
import accesspointsStats from "./accesspointsStats";
import account from "./account";
import assetsManager from "./assetsManager";
import blogArticle from "./blogArticle";
import blogArticles from "./blogArticles";
import builder from "./builder";
import checkedInAtAccesspointIds from "./checkedInAtAccesspointIds";
import cartItemsStatistics from "./cartItemsStatistics";
import columnsSets from "./columnsSets";
import contact from "./contact";
import contactFields from "./contactFields";
import currentSavedSearch from "./currentSavedSearch";
import customEmail from "./customEmail";
import customEmails from "./customEmails";
import directEmail from "./directEmail";
import documentTemplate from "./documentTemplate";
import documentTemplates from "./documentTemplates";
import duplicationOperation from "./duplicationOperation";
import emailActivities from "./emailActivities";
import emailTemplate from "./emailTemplate";
import event from "./event";
import eventAtlasJwtTokens from "./eventAtlasJwtTokens";
import events from "./events";
import expectedAtAccesspointIds from "./expectedAtAccesspointIds";
import featureSet from "./featureSet";
import guestCampaign from "./guestCampaign";
import guestCampaigns from "./guestCampaigns";
import guestCategories from "./guestCategories";
import guestCategory from "./guestCategory";
import guestCategoryAccesspoints from "./guestCategoryAccesspoints";
import guestCount from "./guestCount";
import guestFields from "./guestFields";
import guestFieldStatistics from "./guestFieldStatistics";
import guestFieldsToExport from "./guestFieldsToExport";
import guestImportOperation from "./guestImportOperation";
import guestPage from "./guestPage";
import guests from "./guests";
import guestsPaymentStatistics from "./guestsPaymentStatistics";
import guestsStatistics from "./guestsStatistics";
import historyItems from "./historyItems";
import labels from "./labels";
import labelStats from "./labelStats";
import liquidTemplate from "./liquidTemplate";
import preconfiguredEvents from "./preconfiguredEvents";
import programs from "./programs";
import customPreconfiguredEvents from "./customPreconfiguredEvents";
import receiverActivities from "./receiverActivities";
import registrationDate from "./registrationDate";
import registrationFormItems from "./registrationFormItems";
import registrationForms from "./registrationForms";
import registrationFormSections from "./registrationFormSections";
import registrationFormSteps from "./registrationFormSteps";
import rejectedAtAccesspointIds from "./rejectedAtAccesspointIds";
import reportConfigurations from "./reportConfigurations";
import savedSearch from "./savedSearch";
import savedSearchCreation from "./savedSearchCreation";
import sectionTypes from "./sectionTypes";
import selectedEngagementLevels from "./selectedEngagementLevels";
import selectedGuestCategoryIds from "./selectedGuestCategoryIds";
import selectedGuestLabelIds from "./selectedGuestLabelIds";
import selectedGuestSortOptions from "./selectedGuestSortOptions";
import selectedGuestStatuses from "./selectedGuestStatuses";
import selectedSSOStatuses from "./selectedSSOStatuses";
import selectedOptins from "./selectedOptins";
import selectedPaymentStatuses from "./selectedPaymentStatuses";
import selectedShowedUpStatuses from "./selectedShowedUpStatuses";
import selectedThematicIds from "./selectedThematicIds";
import selectedQuartilesIdsInGuestsThematicsQuartiles from "./selectedQuartilesIdsInGuestsThematicsQuartiles.ts";
import selectedThematicsIdsInGuestsThematicsQuartiles from "./selectedThematicsIdsInGuestsThematicsQuartiles.ts";
import simpleSearchQuery from "./simpleSearchQuery";
import subFormItems from "./subFormItems";
import thematics from "./thematics";
import ticketingCategory from "./ticketingCategory";
import translations from "./translations";
import unexpectedApiError from "./unexpectedApiError";
import userSettings from "./userSettings";
import website from "./website";
import websitePages from "./websitePages";
import websiteLayouts from "./websiteLayouts";
import websiteMenuItems from "./websiteMenuItems";
import websiteMenu from "./websiteMenu";
import websiteMenus from "./websiteMenus";
import websitePageTemplates from "./websitePageTemplates";
import workflowVersionBuilder from "./workflowVersionBuilder";
import workflows from "./workflows";
import workflowRuns from "./workflowRuns";
import streamingArchives from "./streamingArchives";
import guestProducts from "./guestProducts";
import guestProductsStatistics from "./guestProductsStatistics";
import sessionsStatistics from "./sessionsStatistics";
import exhibitorsStatistics from "./exhibitorsStatistics";
import guestInvitationStats from "./guestInvitationStats";
import guestPromoCodeInvitationStats from "./guestPromoCodeInvitationStats";
import meetingsStatistics from "./meetingsStatistics";
import messagesStatistics from "./messagesStatistics";
import sessionRoom from "./sessionRoom";
import sessionRooms from "./sessionRooms";
import sessionTypes from "./sessionTypes";
import meetingLocations from "./meetingLocations";
import mobinetworkConfiguration from "./mobinetworkConfiguration";
import guestProductCollections from "./guestProductCollections";
import guestProductCollection from "./guestProductCollection";
import badgeTemplate from "./badgeTemplate";

const combinedReducer = combineReducers({
  acceptedAtAccesspointIds,
  accessControlCount,
  accesspoints,
  accesspointsLiveConsumption,
  accesspointsOccupancy,
  accesspointsStats,
  account,
  appearance,
  assetsManager,
  badgeTemplate,
  blogArticle,
  blogArticles,
  builder,
  cartItemsStatistics,
  checkedInAtAccesspointIds,
  columnsSets,
  contact,
  contactFields,
  currentSavedSearch,
  customEmail,
  customEmails,
  customPreconfiguredEvents,
  directEmail,
  documentTemplate,
  documentTemplates,
  duplicationOperation,
  emailActivities,
  emailTemplate,
  event,
  eventAtlasJwtTokens,
  events,
  exhibitorsStatistics,
  expectedAtAccesspointIds,
  exportOptions,
  exports,
  featureSet,
  guestCampaign,
  guestCampaigns,
  guestCategories,
  guestCategory,
  guestCategoryAccesspoints,
  guestCount,
  guestFields,
  guestFieldStatistics,
  guestFieldsToExport,
  guestImportOperation,
  guestInvitationStats,
  guestPromoCodeInvitationStats,
  guestPage,
  guestProducts,
  guestProductCollection,
  guestProductCollections,
  guestProductsStatistics,
  guests,
  guestsPaymentStatistics,
  guestsStatistics,
  historyItems,
  labels,
  labelStats,
  liquidTemplate,
  meetingsStatistics,
  messagesStatistics,
  meetingLocations,
  mobinetworkConfiguration,
  notifications,
  preconfiguredEvents,
  programs,
  receiverActivities,
  registrationDate,
  registrationFormItems,
  registrationForms,
  registrationFormSections,
  registrationFormSteps,
  rejectedAtAccesspointIds,
  reportConfigurations,
  savedSearch,
  savedSearchCreation,
  savedSearches,
  sectionTypes,
  selectedEngagementLevels,
  selectedGuestCategoryIds,
  selectedGuestLabelIds,
  selectedGuestSortOptions,
  selectedGuestStatuses,
  selectedSSOStatuses,
  selectedOptins,
  selectedPaymentStatuses,
  selectedQuartilesIdsInGuestsThematicsQuartiles,
  selectedShowedUpStatuses,
  selectedThematicIds,
  selectedThematicsIdsInGuestsThematicsQuartiles,
  sessionRoom,
  sessionRooms,
  sessionsStatistics,
  sessionTypes,
  simpleSearchQuery,
  streamingArchives,
  subFormItems,
  thematics,
  ticketingCategory,
  translations,
  unexpectedApiError,
  userSettings,
  website,
  websiteLayouts,
  websiteMenu,
  websiteMenuItems,
  websiteMenus,
  websitePages,
  websitePageTemplates,
  workflowVersionBuilder,
  workflows,
  workflowRuns
});

export default reduceReducers(combinedReducer, registrationFormFocus);
