import { CALL_API, ActionTypes } from "../constants/Constants";
import { stringify } from "querystring";

module.exports = {
  receiveEvent(event) {
    return {
      type: ActionTypes.RECEIVE_EVENT,
      event
    };
  },

  updateEvent(eventId, eventParams, redirectTo = null, notificationsOptions = {}) {
    const { UPDATE_EVENT_REQUEST, UPDATE_EVENT_SUCCESS, UPDATE_EVENT_FAILURE } = ActionTypes;
    return {
      [CALL_API]: {
        types: [UPDATE_EVENT_REQUEST, UPDATE_EVENT_SUCCESS, UPDATE_EVENT_FAILURE],
        method: "PUT",
        body: { event: eventParams },
        endpoint: `/api/v1/events/${eventId}.json`,
        redirectTo,
        notificationsOptions
      }
    };
  },

  createFromEvent(accountId, body, redirectTo = null) {
    const { CREATE_FROM_EVENT_REQUEST, CREATE_FROM_EVENT_SUCCESS, CREATE_FROM_EVENT_FAILURE } = ActionTypes;

    return {
      [CALL_API]: {
        types: [CREATE_FROM_EVENT_REQUEST, CREATE_FROM_EVENT_SUCCESS, CREATE_FROM_EVENT_FAILURE],
        method: "POST",
        body,
        endpoint: `/api/v1/accounts/${accountId}/events/create_from.json`,
        redirectTo
      }
    };
  },

  fetchEvents(options = {}) {
    const { FETCH_EVENTS_REQUEST, FETCH_EVENTS_SUCCESS, DEV_NULL } = ActionTypes;
    const { page, perPage, published, accountId, search } = options;
    const params = {};
    if (page && perPage) {
      params["page"] = page;
      params["per_page"] = perPage;
    }
    if (published) params["published"] = published;
    if (accountId) params["account_id"] = accountId;
    if (search) params["search"] = search;

    return {
      [CALL_API]: {
        types: [FETCH_EVENTS_REQUEST, FETCH_EVENTS_SUCCESS, DEV_NULL],
        method: "GET",
        endpoint: `/api/v1/events.json?${stringify(params)}`
      }
    };
  },

  fetchEventsFromUrl(url) {
    const { FETCH_EVENTS_REQUEST, FETCH_EVENTS_SUCCESS, DEV_NULL } = ActionTypes;

    return {
      [CALL_API]: {
        types: [FETCH_EVENTS_REQUEST, FETCH_EVENTS_SUCCESS, DEV_NULL],
        method: "GET",
        endpoint: url
      },
      concatEvents: true
    };
  },

  synchronizeContactsIntoEvent(eventId, params = {}, redirectTo, notificationsOptions = {}) {
    const { DEV_NULL } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, DEV_NULL, DEV_NULL],
        method: "POST",
        body: params,
        endpoint: `/api/v1/events/${eventId}/synchronize_contacts_into_event.json`,
        notificationsOptions,
        redirectTo
      }
    };
  },

  fetchLeadsExportCustomEmail(eventId) {
    const { DEV_NULL, FETCH_LEADS_EXPORT_EMAIL_SUCCESS } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, FETCH_LEADS_EXPORT_EMAIL_SUCCESS, DEV_NULL],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/leads_export_custom_email.json`
      }
    };
  },

  fetchVisitRouteDigestEmail(eventId) {
    const { DEV_NULL, FETCH_VISIT_ROUTE_DIGEST_EMAIL_SUCCESS } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, FETCH_VISIT_ROUTE_DIGEST_EMAIL_SUCCESS, DEV_NULL],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/visit_route_digest_custom_email.json`
      }
    };
  },

  fetchVisitRouteExportEmail(eventId) {
    const { DEV_NULL, FETCH_VISIT_ROUTE_EXPORT_EMAIL_SUCCESS } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, FETCH_VISIT_ROUTE_EXPORT_EMAIL_SUCCESS, DEV_NULL],
        method: "GET",
        endpoint: `/api/v1/events/${eventId}/visit_route_export_custom_email.json`
      }
    };
  },

  updateVisitRoute(eventId, body, notificationsOptions = {}) {
    const { DEV_NULL, UPDATE_VISIT_ROUTE_SUCCESS } = ActionTypes;
    return {
      [CALL_API]: {
        types: [DEV_NULL, UPDATE_VISIT_ROUTE_SUCCESS, DEV_NULL],
        method: "PUT",
        endpoint: `/api/v1/events/${eventId}.json`,
        body,
        notificationsOptions
      }
    };
  },

  addOfflineErrors(errors) {
    return {
      type: ActionTypes.EVENT_ADD_OFFLINE_ERRORS,
      errors: errors
    };
  },

  clearCustomEmailExport() {
    return {
      type: ActionTypes.CLEAR_LEADS_EXPORT_EMAIL
    };
  }
};
