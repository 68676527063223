import { Component } from "react";
import { connect } from "react-redux";
import { fetchAccesspoints, removeAccesspointInSiloedData } from "../actions/AccesspointActionCreators";
import ResourceSearchDropdown from "../components/ResourceSearchDropdown.react";
import { Accesspoint } from "../types/Accesspoint";

interface StoreProps {
  accesspoints: Accesspoint[];
  siloedData: any;
}

interface DispatchProps {
  fetchAccesspoints(eventId: string, searchQuery: string, page: number, options?: any);
  removeAccesspointInSiloedData(uniqueKeyInSiloedData: string): void;
}

interface OwnProps {
  allowMultipleSelect?: boolean;
  selectedAccesspoints?: Accesspoint[];
  selectedAccesspointsIds?: string[];
  searchFilters?: any;
  allSelectedAccesspointsIds?: string[];
  displaySelectedAccesspointOnList?: boolean;
  onSelectAccesspoint(accesspoints: Accesspoint[]): void;
}

interface Props extends StoreProps, DispatchProps, OwnProps {}

class AccesspointSearchDropdown extends Component<Props> {
  static defaultProps = {
    allowMultipleSelect: false
  };

  constructor(props: Props) {
    super(props);
    [
      "fetchAccesspoints",
      "fetchAccesspointsFromIds"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  fetchAccesspoints(eventId: string, searchQuery: string, page: number, options?: any): void {
    const { fetchAccesspoints, searchFilters } = this.props;
    if (searchFilters) {
      options = options || {};
      options["searchFilters"] = searchFilters;
    }
    fetchAccesspoints(eventId, searchQuery, page, options);
  }

  fetchAccesspointsFromIds(eventId: string, searchQuery: string, page: number, options?: any): void {
    const { fetchAccesspoints } = this.props;
    fetchAccesspoints(eventId, searchQuery, page, options);
  }

  render(): JSX.Element {
    const { accesspoints, onSelectAccesspoint, selectedAccesspoints, selectedAccesspointsIds, allowMultipleSelect,
      siloedData, removeAccesspointInSiloedData, displaySelectedAccesspointOnList, allSelectedAccesspointsIds } = this.props;

    return (
      <ResourceSearchDropdown
        className="react-select"
        classNamePrefix="react-select"
        allowMultipleSelect={allowMultipleSelect}
        cleanUniqueKeyInSiloedData={removeAccesspointInSiloedData}
        onSelectResource={onSelectAccesspoint}
        requestResources={this.fetchAccesspoints}
        requestResourcesFromIds={this.fetchAccesspointsFromIds}
        resources={accesspoints}
        idsToExclude={displaySelectedAccesspointOnList == false ? allSelectedAccesspointsIds : null}
        selectedResources={selectedAccesspoints}
        selectedResourcesIds={selectedAccesspointsIds}
        siloedData={siloedData} />
    );
  }
}

const mapStateToProps = (state: any): StoreProps => {
  return {
    accesspoints: state.accesspoints.data,
    siloedData: state.accesspoints.siloedData
  };
};

const mapDispatchToProps: DispatchProps = {
  fetchAccesspoints,
  removeAccesspointInSiloedData
};

export default connect<StoreProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(AccesspointSearchDropdown);
